import { Modal, notification } from "antd"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Api } from "../../../api/api"
import { EJobProgress, IJob } from "../../../api/types"
import { Msg } from "../../../ui/Text"
import { WOContext } from "../WorkOrderContext"

type TModalProps = {
   isError?: boolean
}

const StyledModal = styled(Modal)<TModalProps>`
   .ant-modal-header .ant-modal-title {
      color: ${(props) => (props.isError ? "#FB4D4F" : "#202020")};
   }
`

const ModalMsg = styled(Msg)`
   font-size: 18px;
   font-weight: 300;
`

type TProps = {
   visible: boolean
   jobs: IJob[]
   onClose: () => void
   loadData: () => void
}

const CompleteJobModal: React.FC<TProps> = ({ visible, onClose, jobs, loadData }) => {
   const [isError, setIsError] = useState(false)
   const [unfinishedJobs, setUnfinishedJobs] = useState<IJob[]>([])
   const Context = useContext(WOContext)

   useEffect(() => {
      const _jobs = jobs.filter((j) => j.progress !== EJobProgress.Complete)
      setUnfinishedJobs(_jobs)
      setIsError(!_jobs.length || _jobs[0].progress === EJobProgress.Start)
   }, [jobs])

   const onOk = async () => {
      if (!isError && unfinishedJobs.length) {
         Context.requestComplete(true)
         onClose()
         return
      }
      onClose()
   }

   const onCancel = () => {
      onClose()
   }

   const renderText = () => {
      if (!isError) {
         return <ModalMsg>Are you sure want to complete current job?</ModalMsg>
      }
      if (!unfinishedJobs.length) {
         return <ModalMsg>All jobs in service plan are already completed</ModalMsg>
      }
      if (unfinishedJobs[0].progress === EJobProgress.Start) {
         return (
            <ModalMsg>
               You can't complete current job, because we didn’t receive at least one "Technician time clock"{" "}
            </ModalMsg>
         )
      }
      return null
   }

   return (
      <StyledModal
         visible={visible}
         onOk={onOk}
         onCancel={onCancel}
         title={isError ? "Error “Complete job” " : "Complete Job"}
         isError={isError}
         width={374}
      >
         {renderText()}
      </StyledModal>
   )
}

export default CompleteJobModal
