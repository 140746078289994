import { Form, Input, Modal, notification, Select, Spin, Tabs } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useState, useEffect, useCallback, memo } from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled from "styled-components";
import { Api } from "../../api/api";
import { ESendType, ICreateEstimate, IMessageTemplate, ITemplateSettings, IWorkOrder, IWorkOrderWithJobs } from "../../api/types";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser, getConstant } from "../../config/reducers/user/selectors";
import { MessagesTemplateModal } from "../../containers/messages/MessagesTemplateModal";
import { CheckboxTab, StyledCBTabs, TextMessagingWarning } from "../../modals/Messages/CheckboxTab";
import Flex from "../../noui/Flex";
import { TCallback, TOption } from "../../types";
import { EmptyButton } from "../../ui/Button";
import { useLoading } from "../../utils/hooks";
import { getReplacedTemplateMessage, mapOptions } from "../../utils/utils";
import { TextEditor } from "../../components/TextEditor/TextEditor"

const Title = styled.h2`
   font-weight: 900;
   font-size: 15px;
   margin-bottom: 10px;
   line-height: 20px;
   text-transform: uppercase;
`

const ModalWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: stretch;
   ${down("xs")} {
      flex-direction: column;
   }
`
const StyledForm = styled(Form)`
   .template-item label {
      width: 100%;
   }
`
const FormContainer = styled.div`
   padding: 16px;
//    width: calc(50% + 20px);
   ${down("xs")} {
      width: 100%;
      .ant-checkbox-group {
         width: 100%;
         display: flex;
         justify-content: space-between;
      }
   }
`

const StyledModal = styled(Modal)`
   max-width: 360px;
   .ant-modal-body {
      padding: 0;
   }
`

const Label = styled.h4`
   font-weight: bold;
   font-size: 13px;
   line-height: 18px;
`

const TextEditorWrapper = styled.div`
   margin-bottom: 20px;
`

type TRPMTemplateModalProps = {
    visible: boolean
    onOk: (data: any) => void
    templateData: any | null
    onClose: TCallback
    workOrder?: IWorkOrderWithJobs | IWorkOrder | null
    jobs?: any[]
}

const RPMTemplateModal: React.FC<TRPMTemplateModalProps> = ({visible, onOk, onClose, workOrder, jobs, templateData}) => {
    const [templates, setTemplates] = useState<{
        data: IMessageTemplate[]
        mapped: TOption[]
     }>({ data: [], mapped: [] })
     const [form] = Form.useForm<ICreateEstimate>()
     const [messagesTemplateVisible, setMessagesTemplateVisible] = useState<boolean>(false)
     const [loading, onCall] = useLoading()
     const tags = useAppSelector(getConstant("template_keys"))
     const user = useAppSelector(getCurrentUser)
     const isXs = useBreakpoint(down("xs"))
     const [messageContent, setMessageContent] = useState<any>()
     const [messageContentHtml, setMessageContentHtml] = useState<any>()
     const [templateSettings, setTemplateSettings] = useState<ITemplateSettings[]>([])
     const [currentTab, setCurrentTab] = useState<string>(ESendType.E)
     const [sendType, setSendType] = useState([ESendType.E])
  
     useEffect(() => {
        if (visible && !templateData) {
           form.resetFields()
        } else if (visible && templateData) {
            form.setFieldsValue(templateData)
            const _content = templateData?.content;
            if (_content) {
                setMessageContent(_content)
            }
        }
     }, [visible, form, templateData])
  
     const loadTemplates = useCallback(() => {
        if (!templates.data.length) {
           onCall(
              Api.templates.getAll({ ordering: "title" }).then(({ data: dt }) => {
                 const data = dt as IMessageTemplate[]
                 setTemplates({ data, mapped: mapOptions(data, "title") })
              })
           ).finally()
        }
     }, [templates, onCall])
  
     useEffect(() => {
        loadTemplates()
     }, [loadTemplates])
  
     const loadTemplateSettings = async () => {
        const { data } = await Api.company.getTemplateSettings()
        setTemplateSettings(data)
     }

     useEffect(() => {
        loadTemplateSettings()
     }, [])
  
     const handleCloseManageTemplate = () => {
        loadTemplates()
        setMessagesTemplateVisible(false)
     }
  
     const handleChangeTemplate = (templateId: string) => {
        const template = templates.data.find((t) => t.id === Number(templateId))
        if (template && user) {
           const content = getReplacedTemplateMessage(
              template.content,
              tags,
              user,
              workOrder?.customer,
              undefined,
              workOrder
           )
           const subject = getReplacedTemplateMessage(
              template.subject,
              tags,
              user,
              workOrder?.customer,
              undefined,
              workOrder
           )
           const sms_content = getReplacedTemplateMessage(
              template.sms_content,
              tags,
              user,
              workOrder?.customer,
              undefined,
              workOrder
           )
           setMessageContent(content)
           form.setFieldsValue({ content, subject, sms_content })
        }
     }
  
     const updateMessage = (value: string) => {
        setMessageContentHtml(value)
     }
  
     useEffect(() => {
        form.setFieldsValue({ content: messageContentHtml })
     }, [messageContentHtml])
  
     const formatData = (fields: ICreateEstimate) => {
        const content = form.getFieldValue("content")
        const sms_content = form.getFieldValue("sms_content")
        const subject = form.getFieldValue("subject")
        return {
           ...fields,
           content,
           sms_content,
           subject,
           send_type: sendType,
           template: fields.template,
           id: workOrder?.id || 0,
        }
     }
  
     const sendEstimate = async () => {
        const fields = await form.validateFields()
        const data = formatData(fields)
        if (!data.send_type.length) {
            notification.error({message: "Please choose message type"})
            return;
        }
        onOk(data)
     }
  
     const setTab = (key: string) => {
        setCurrentTab(key)
     }
  
     const handleTabCheck = (e: CheckboxChangeEvent, key: ESendType) => {
        if (e.target.checked) {
           setSendType((prev) => [...prev, key])
           return
        }
        setSendType((prev) => prev.filter((i) => i !== key))
     }
  
     const renderTabContent = () => {
        return (
           <Flex flexDirection="column" height="500px">
              <Form.Item
                 name="template"
                 className="fullWidthLabel"
                 label={
                    <Flex justifyContent="space-between" width={"100%"}>
                       <Label>Template</Label>
                       <EmptyButton
                          fontSize="14px"
                          style={{ lineHeight: "18px" }}
                          fontWeight="700"
                          color="#109CF1"
                          width="auto"
                          onClick={() => setMessagesTemplateVisible(true)}
                       >
                          Manage list
                       </EmptyButton>
                    </Flex>
                 }
                 style={{ flexDirection: isXs ? "row" : "column" }}
              >
                 <Select
                    onChange={handleChangeTemplate}
                    options={templates.mapped}
                    placeholder="Select a template"
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                 />
              </Form.Item>
              {currentTab !== ESendType.S && (
                 <Form.Item name="subject" label="Subject" style={{ flexDirection: isXs ? "row" : "column" }}>
                    <Input placeholder="Type here" />
                 </Form.Item>
              )}
              {currentTab === ESendType.E ? (
                 <>
                    <Label style={{ marginBottom: "0px" }}>Message text</Label>
                    <TextEditorWrapper>
                       <TextEditor initialValue={messageContent} onChange={updateMessage} />
                    </TextEditorWrapper>
                 </>
              ) : (
                 <Form.Item name="sms_content" label="Message text" style={{ flexDirection: isXs ? "row" : "column" }}>
                    <Input.TextArea
                       rows={isXs ? 2 : 5}
                       placeholder="Type here"
                       showCount={{
                          formatter: ({ count, maxLength }) => `${count}/${maxLength} characters`,
                       }}
                       maxLength={120}
                    />
                 </Form.Item>
              )}
           </Flex>
        )
     }
  
     return (
           <StyledModal
              width={isXs ? "100%" : 660}
              visible={visible}
              onCancel={onClose}
              onOk={sendEstimate}
              destroyOnClose
            //   afterClose={() => setAmount(undefined)}
           >
              <StyledForm
                 labelCol={{ style: { fontSize: 13, fontWeight: "bold" } }}
                 requiredMark={false}
                 layout="vertical"
                 form={form}
                 onFinish={sendEstimate}
              >
                 <ModalWrapper>
                    <FormContainer>
                       <Spin spinning={loading}>
                          <Title>
                            Edit Customer Message
                          </Title>
                          <StyledCBTabs
                             onChange={setTab}
                             type="card"
                             activeKey={currentTab}
                             tabBarExtraContent={
                                !user?.company?.messaging_status && <TextMessagingWarning onClose={onClose} />
                             }
                          >
                             <Tabs.TabPane
                                tab={
                                   <CheckboxTab
                                      checked={sendType.includes(ESendType.E)}
                                      onChange={(e) => handleTabCheck(e, ESendType.E)}
                                      title="Email"
                                   />
                                }
                                key={ESendType.E}
                             >
                                {renderTabContent()}
                             </Tabs.TabPane>
                             <Tabs.TabPane
                                tab={
                                   <CheckboxTab
                                      checked={sendType.includes(ESendType.S)}
                                      disabled={!user?.company?.messaging_status}
                                      onChange={(e) => handleTabCheck(e, ESendType.S)}
                                      title="SMS"
                                   />
                                }
                                disabled={!user?.company?.messaging_status}
                                key={ESendType.S}
                             >
                                {renderTabContent()}
                             </Tabs.TabPane>
                          </StyledCBTabs>
                       </Spin>
                    </FormContainer>
                    </ModalWrapper>
              </StyledForm>
              <MessagesTemplateModal visible={messagesTemplateVisible} onClose={handleCloseManageTemplate} />
           </StyledModal>
     )
}

export default memo(RPMTemplateModal)