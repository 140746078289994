import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Select, Form, Input, Table, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import styled from "styled-components"

import { Api } from "../../../api/api"
import { filterColumns, formatTime } from "../../../utils/utils"
import { EUserRole, ICreateJob, ITimeClock, IUser } from "../../../api/types"
import { Msg } from "../../../ui/Text"
import Flex from "../../../noui/Flex"
import { EmptyButton } from "../../../ui/Button"
import { AddBtn, TableSelect, ExportBtn } from "../../../ui/TableUtils"
import { ColumnsType } from "antd/es/table/Table"
import { AddTimeManually } from "../../../modals/AddTimeManually"
import { TimeInput } from "../../../ui/TimeInput"
import { usePagination, usePrevious } from "../../../utils/hooks"
import { getColumns } from "../../../config/reducers/columns/selectors"
import { ColumnsSelector } from "../../../modals/ColumnsSelector/ColumnsSelector"
import { Columns } from "../../../constants"
import { useSelector } from "react-redux"

import Filter from "../../../assets/images/Filter.svg"
import moment from "moment"
import { FormInstance, notification } from "antd/es"

const CustomFormItem = styled(Form.Item)`
   width: 100%;
`

const AddManuallyBtn = styled(EmptyButton)`
   color: #1c99fc;
   border-bottom: 1px dashed #1c99fc;
   width: fit-content;
   border-radius: 0px;
`

type TProps = {
   form: FormInstance<ICreateJob>
   estimateId?: number | undefined
   after?: boolean | undefined
}

export const TimeClockTab: React.FC<TProps> = ({ estimateId }) => {
   const { data, loading, onPromise, requestPagination, pagination, onChange } = usePagination<ITimeClock>()
   const [technicians, setTechnicians] = useState<IUser[]>([])
   const [addTimeManually, setAddTimeManually] = useState<boolean>(false)
   const [selectColumns, setSelectColumns] = useState<boolean>(false)
   const [started, setStarted] = useState<boolean>(false)
   const [timeValue, setTimeValue] = useState<number>(0)
   const [form] = Form.useForm()

   const filtered = useSelector(getColumns(Columns.TimeClock))

   const columns: ColumnsType<ITimeClock> = useMemo(
      () => [
         {
            title: "Entry #",
            sorter: true,
            key: "order_number",
            dataIndex: "order_number",
            render: (_, record) => `#${record.job.order_number}`,
         },
         {
            title: "Technician",
            key: "technician",
            dataIndex: "technician",
            sorter: true,
            render: (_, record) => record.user.full_name,
         },
         {
            title: "First Name",
            key: "first_name",
            dataIndex: "first_name",
            sorter: true,
            render: (_, record) => record.job.customer_data.first_name,
         },
         {
            title: "Last Name",
            key: "last_name",
            dataIndex: "last_name",
            sorter: true,
            render: (_, record) => record.job.customer_data.last_name,
         },
         {
            title: "Vessel",
            key: "name",
            dataIndex: "name",
            sorter: true,
            render: (_, record) => record.job.vessel_data.name,
         },
         {
            title: "Start Date + Time",
            key: "start",
            dataIndex: "start",
            sorter: true,
            render: (_, record) => moment(record.start).format("L, hh:mm a"),
         },
         {
            title: "End Date + Time",
            key: "stop",
            dataIndex: "stop",
            sorter: true,
            render: (_, record) => record.stop && moment(record.stop).format("L, hh:mm a"),
         },
         {
            title: "Activity Name",
            key: "activity_name",
            dataIndex: "activity_name",
            render: (_, record) => "General",
         },
         {
            title: "Activity Type",
            key: "activity_type",
            dataIndex: "activity_type",
            render: (_, record) => "General",
         },
      ],
      []
   )

   const loadCards = async () => {
      onPromise(Api.timeClocks.getAll({ ...requestPagination, job: estimateId }))
      const staff = await Api.user.getAll({})
      setTechnicians(staff.data as IUser[])
   }

   useEffect(() => {
      loadCards().finally()
   }, [requestPagination, onPromise])

   useEffect(() => {
      if (started) {
         setTimeout(() => {
            setTimeValue(timeValue + 1)
         }, 1000)
      } else {
         setTimeValue(0)
      }
   })

   const handleStart = async () => {
      try {
         const data = await form.validateFields()
         if (!data.technician) {
            notification.error({
               message: "You must specify technician first",
            })
         } else {
            await Api.timeClocks.create({
               job: estimateId,
               user: data.technician,
               start: new Date().toISOString(),
            })
            setStarted(true)
            await loadCards()
         }
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const handleStop = async () => {
      try {
         const activeTimeClock = data.find((t) => t.stop === null)
         await Api.timeClocks.stop(activeTimeClock?.id ?? data[0].id)
         setStarted(false)
         setTimeValue(0)
         await loadCards()
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   return (
      <Flex flexDirection="column">
         <Msg fontSize={16} fontWeight={700} mb="16px">
            Timers
         </Msg>
         <Form form={form}>
            <Flex justifyContent="space-between" alignItems="center" mb="14px">
               <Flex flexDirection="column" style={{ width: "50%" }}>
                  <Flex style={{ gap: "16px" }}>
                     <CustomFormItem name="technician">
                        <Select placeholder="Select technician">
                           {technicians.map((t) => (
                              <Select.Option value={t.id}>{t.full_name}</Select.Option>
                           ))}
                        </Select>
                     </CustomFormItem>
                     <CustomFormItem name="activity">
                        <Select placeholder="General">
                           <Select.Option value="General">General</Select.Option>
                        </Select>
                     </CustomFormItem>
                  </Flex>
                  <CustomFormItem name="note" style={{ marginBottom: 0 }}>
                     <Input.TextArea style={{ resize: "none" }} placeholder="Note" />
                  </CustomFormItem>
               </Flex>
               <Flex alignItems="center" justifyContent="flex-end">
                  <TimeInput
                     active={started}
                     step="1"
                     value={started ? formatTime(timeValue, true) : "--:--"}
                     type="time"
                     name="time"
                     bordered={false}
                     style={{ width: "fit-content" }}
                  />
                  <Button
                     type="primary"
                     danger={started}
                     onClick={started ? handleStop : handleStart}
                     style={{ borderRadius: "6px", height: "34px", minWidth: "118px" }}
                  >
                     Clock {started ? "Out" : "In"}
                  </Button>
               </Flex>
            </Flex>
         </Form>
         <AddTimeManually
            loadCards={loadCards}
            displayJobs={false}
            estimateId={estimateId}
            visible={addTimeManually}
            handleClose={() => setAddTimeManually(false)}
            technicians={technicians}
         />
         <AddManuallyBtn onClick={() => setAddTimeManually(true)}>Add time manually </AddManuallyBtn>
         <Flex flexDirection="column" mt="16px">
            <Flex justifyContent="space-between" alignItems="center">
               <Flex alignItems="center">
                  <EmptyButton>
                     <img src={Filter} alt="Filter" />
                  </EmptyButton>
                  <AddBtn mx="20px">
                     <Msg fontWeight={600} fontSize={13} mr={20} color="#ffffff">
                        Add Filter
                     </Msg>
                     <PlusOutlined />
                  </AddBtn>
                  <Button style={{ height: "34px" }} onClick={() => setSelectColumns(true)}>
                     Add Columns <PlusOutlined />
                  </Button>
               </Flex>
               <Flex>
                  <ExportBtn mr={20}>Export</ExportBtn>
                  <TableSelect value="Columns" />
               </Flex>
            </Flex>
            <Flex mt="16px">
               <ColumnsSelector
                  name={Columns.TimeClock}
                  columns={columns}
                  visible={selectColumns}
                  onClose={() => setSelectColumns(false)}
               />
               <Table
                  className="antd-table-modified"
                  columns={filterColumns(columns, filtered)}
                  scroll={{
                     x: true,
                  }}
                  dataSource={data}
                  loading={loading}
                  pagination={pagination}
                  onChange={onChange}
                  locale={{ emptyText: "No timesheets results were found" }}
               />
            </Flex>
         </Flex>
      </Flex>
   )
}
