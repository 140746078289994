import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IJob } from "../../api/types";
import { Api } from "../../api/api";
import moment from "moment";

import {
  Calendar as BigCalendar,
  DateLocalizer,
  momentLocalizer,
  NavigateAction,
  View,
} from "react-big-calendar";
import { Spin } from "antd";
import { useLoading } from "../../utils/hooks";
import { WeekCalendarHeader } from "./components/WeekCalendarHeader";
import { HeaderComponent } from "./components/HeaderComponent";
import { DayCalendarHeader } from "./components/DayCalendarHeader";
import { useAppSelector } from "../../config/hooks";
import { getCallendarSettings } from "../../config/reducers/user/selectors";
import CustomWeek from "./components/CustomWeek";
import { CalendarWrapper } from "./components/styled";
import { useHistory } from "react-router-dom";
import routes from "../../routes/routes";
import axios from "axios";
import { formatWeatherRequest } from "../../api";
import { MonthDateHeader } from "./components/MonthDateHeader";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";

import { getCurrentUser } from "../../config/reducers/user/selectors";
//@ts-ignore

import Day from "react-big-calendar/lib/Day";
import MobileMonthEvent from "./components/MobileMonthEvent";
import { bottom } from "styled-system";
import MobileWeekEvent from "./components/MobileWeekEvent";
import DayEvent from "./components/DayEvent";

const getStartEnd = (date: moment.Moment): [string, string] => {
  return [
    moment(date).startOf("month").subtract(5, "days").toISOString(),
    moment(date).endOf("month").add(5, "days").toISOString(),
  ];
};

export const Calendar = () => {
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [date, setDate] = useState<moment.Moment>(moment());
  const dateRef = useRef(date);
  const [dateRange, setRange] = useState(getStartEnd(date));
  const [loading, onAction] = useLoading();
  const [localizer, setLocalizer] = useState<DateLocalizer>(
    momentLocalizer(moment)
  );
  const settings = useAppSelector(getCallendarSettings);
  const [monthSaturday, setMonthSaturday] = useState(0);
  const [monthSunday, setMonthSunday] = useState(0);
  const history = useHistory();
  const [weather, setWeather] = useState<any>({});
  const isLg = useBreakpoint(down("lg"));
  const [currentView, setCurrentView] = useState<View>("month");
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (user) {
      axios.get(formatWeatherRequest(user)).then(({ data }) => {
        setWeather(data.forecast);
      });
    }
  }, [user]);

  useEffect(() => {
    if (!dateRef.current.isSame(date, "month")) {
      setRange(getStartEnd(date));
      dateRef.current = date;
    }
  }, [date]);

  useEffect(() => {
    if (settings.weekStart === "monday") {
      settings.daysToExclude.includes("saturday")
        ? setMonthSaturday(6)
        : setMonthSaturday(0);
      settings.daysToExclude.includes("sunday")
        ? setMonthSunday(7)
        : setMonthSunday(0);
    } else {
      settings.daysToExclude.includes("saturday")
        ? setMonthSaturday(7)
        : setMonthSaturday(0);
      settings.daysToExclude.includes("sunday")
        ? setMonthSunday(1)
        : setMonthSunday(0);
    }
  }, [settings.daysToExclude]);

  useEffect(() => {
    moment.locale("en", {
      week: {
        dow: settings.weekStart === "monday" ? 1 : 7,
      },
    });
    setLocalizer(momentLocalizer(moment));
  }, [settings.weekStart]);

  useEffect(() => {
    onAction(
      Api.jobs
        .getAll({
          scheduled_after: dateRange[0],
          scheduled_before: dateRange[1],
        })
        .then((res) => {
          const data = res.data as IJob[];

          setJobs(data);
        })
    ).finally();
  }, [dateRange, onAction]);

  const handleDateChange = (date: Date, view: View, action: NavigateAction) => {
    setDate(moment(date));
  };

  type TNewEvents = {
    date: Date;
    count: number;
    allDates: Date[];
  };

  const jobsToJobsCounts = (jobs: IJob[]) => {
    const newEvents: TNewEvents[] = [];
    jobs.forEach((job) => {
      const startDate = moment(job.schedules[0].start).startOf("day").toDate();
      const event = newEvents.find(
        (e) => e.date.toString() === startDate.toString()
      );
      if (event) {
        event.count++;
        event.allDates.push(
          ...job.schedules.map((s) => moment(s.end).toDate())
        );
        return;
      }
      newEvents.push({
        date: startDate,
        allDates: [...job.schedules.map((s) => moment(s.end).toDate())],
        count: 1,
      });
    });
    return newEvents;
  };

  const getView = (view: any) => {
    setCurrentView(view);
  };

  const checkIsMobileMonthView = () => isLg && currentView === "month";

  return (
    <Spin wrapperClassName={"spin-full-height"} spinning={loading}>
      <CalendarWrapper
        sunday={monthSunday}
        saturday={monthSaturday}
        view={currentView}
      >
        {checkIsMobileMonthView() ? (
          <BigCalendar
            localizer={localizer}
            startAccessor={(e) => e.date}
            endAccessor={(e) => e.date}
            onNavigate={handleDateChange}
            titleAccessor={(e) => e.count.toString()}
            formats={{
              //@ts-ignore
              weekdayFormat: (date, culture, localizer) => {
                //@ts-ignore
                const dateName = localizer.format(date, "ddd", culture);
                return isLg ? dateName[0] : dateName;
              },
            }}
            components={{
              toolbar: (props) => (
                <HeaderComponent {...props} getView={getView} />
              ),
              month: {
                dateHeader: (props) => (
                  <MonthDateHeader {...props} weather={weather} />
                ),
              },
            }}
            min={new Date(settings.dayStart)}
            max={new Date(settings.dayEnd)}
            views={{
              day: true,
              week: true,
              month: true,
            }}
            view={currentView}
            onView={getView}
            showAllEvents
            events={jobsToJobsCounts(jobs)}
            date={date.toDate()}
            eventPropGetter={(event, start, end, isSelected) => {
              let newStyle = {
                backgroundColor: "#109CF1",
                color: "white",
                borderRadius: "4px",
                border: "1px solid white",
                width: "calc(100% - 10px)",
                margin: "5px",
              };
              if (isSelected) {
                setCurrentView("day");
              }
              if (
                new Date(
                  Math.max.apply(
                    null,
                    event.allDates.map((d) => d.getTime())
                  )
                ) < moment().toDate()
              ) {
                newStyle.backgroundColor = "#F0F0F0";
                newStyle.color = "black";
              }

              return {
                className: "",
                style: newStyle,
              };
            }}
          />
        ) : (
          <BigCalendar
            localizer={localizer}
            startAccessor={(job) => moment(job.schedules[0].start).toDate()}
            endAccessor={(job) => moment(job.schedules[0].end).toDate()}
            tooltipAccessor={(event: IJob) => ""}
            onNavigate={handleDateChange}
            date={date.toDate()}
            view={currentView}
            onView={getView}
            components={{
              toolbar: (props) => (
                <HeaderComponent {...props} getView={getView} />
              ),
              month: {
                dateHeader: (props) => (
                  <MonthDateHeader {...props} weather={weather} />
                ),
                event: (props) => <MobileMonthEvent {...props} />,
              },
              week: {
                header: (props) =>
                  isLg ? (
                    <DayCalendarHeader {...props} weather={weather} />
                  ) : (
                    <WeekCalendarHeader {...props} weather={weather} />
                  ),
                event: (props) => <MobileWeekEvent {...props} />,
              },
              day: {
                header: (props) => (
                  <DayCalendarHeader {...props} weather={weather} />
                ),
                event: (props) => <DayEvent {...props} />,
              },
            }}
            step={60}
            min={new Date(settings.dayStart)}
            max={new Date(settings.dayEnd)}
            views={{
              day: true,
              week: isLg ? Day : (CustomWeek as any),
              month: true,
            }}
            // showMultiDayTimes
            // showAllEvents
            events={jobs}
            onSelectEvent={(event) => {
              if (event.work_order) {
                history.push(
                  routes.workOrder.UpdateWorkOrder.replace(
                    ":id",
                    `${event.work_order}`
                  ) + `?job=${event.id}`
                );
              }
            }}
            messages={{ showMore: (n) => `+ ${n} job${n > 1 ? "s" : ""}` }}
            eventPropGetter={(event, start, end, isSelected) => {
              const endDate = new Date(event.schedules[0].end);
              const today = new Date();
              const eventItsPast = endDate < today;
              const newStyle = {
                backgroundColor: eventItsPast ? "#EDEDED" : "#109CF1",
                color: eventItsPast ? "black" : "white",
                borderRadius: "4px",
                border: eventItsPast ? "1px solid #C2CFE0" : "1px solid white",
                padding: "5px",
                marginTop: "3px",
                marginBottom: "0px",
                width: "calc(100% - 10px)",
              };

              if (isSelected) {
                setCurrentView("day");
              }

              return {
                className: "",
                style: newStyle,
              };
            }}
          />
        )}
      </CalendarWrapper>
    </Spin>
  );
};
