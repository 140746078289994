import React, { useState, useEffect } from "react"
import { notification, Spin } from "antd"
import Slider from "react-slick"
import Flex from "../../../../noui/Flex"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { Msg } from "../../../../ui/Text"
import ScheduledCard from "./ScheduledCard"
import Completed from "./completedcards/Completed"
//import ToBeScheduledCard from "./ToBeScheduledCard"
import { IEmployeeStats } from "../../../../api/types"
import { Api } from "../../../../api/api"
import { TCompletedItem } from "./types"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const JobsContainer = styled(Flex)`
   // max-width: 636px;
   width: 100%;
   padding: 10px 20px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   overflow: hidden;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
   ${down("lg")} {
      grid-column-start: 1;
      grid-column-end: 3;
      max-width: 100%;
   }
   & .slick-slider {
      & .slick-slide {
         > div {
            display: flex;
            justify-content: center;
         }
      }

      & .slick-next {
         right: -10px;
         border-right: 2px solid #757575;
         border-bottom: 2px solid #757575;

         &:before {
            content: none;
         }
      }
      & .slick-prev {
         left: -10px;
         border-left: 2px solid #757575;
         border-top: 2px solid #757575;

         &:before {
            content: none;
         }
      }
      & .slick-prev,
      & .slick-next {
         width: 12px;
         height: 12px;
         box-sizing: border-box;
         transform: rotate(-45deg);
         border-radius: 0;
         &.slick-disabled {
            border-color: #ccc;
         }
         &:hover {
            border-color: #40a9ff;
         }
      }
      button {
         &:before {
            color: #757575;
         }
      }
   }
`

const MyStats: React.FC = () => {
   const [jobs, setJobs] = useState<IEmployeeStats>()
   const [loading, setLoading] = useState<boolean>(false)

   const completedArr: TCompletedItem[] = [
      {
         title: "this week",
         count: jobs?.completed_week,
      },
      {
         title: "this month",
         count: jobs?.completed_month,
      },
      {
         title: "this year",
         count: jobs?.completed_year,
      },
   ]

   const componentsCard = [
      <ScheduledCard scheduledCount={jobs?.scheduled} key={7} />,
      ...completedArr.map((el: any, idx: number) => <Completed cardInfo={el} key={idx + 1} />),
      //   <ToBeScheduledCard toBeScheduled={jobs?.to_be_scheduled} key={8} />,
   ]

   useEffect(() => {
      setLoading(true)
      Api.jobs
         .employeeStats()
         .then(({ data }) => {
            setJobs(data as IEmployeeStats)
         })
         .catch((e) => {
            notification.error(e)
         })
         .finally(() => {
            setLoading(false)
         })
   }, [])

   const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1380,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 900,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               arrows: false,
            },
         },
      ],
   }

   return (
      <JobsContainer flexDirection="column" height="222px">
         <Flex justifyContent="space-between" mb="9px">
            <Msg fontWeight={900} fontSize={16} color="#151F2B" mr="11px">
               MY STATUS
            </Msg>
         </Flex>
         {!loading ? <Slider {...settings}>{componentsCard}</Slider> : <Spin />}
      </JobsContainer>
   )
}

export default MyStats
