import React from "react";
import { Modal, Form, Select, Checkbox} from "antd";
import Flex from "../noui/Flex";
import styled from "styled-components";
import { useState } from "react";
import { TSettingsData } from "../api/types";
import { setCallendarSettings } from "../config/reducers/user/actions";
import { useAppDispatch } from "../config/hooks";
const { Option } = Select;

type TCalendarSettingsProps = {
    visible: boolean
    handleClose: (visible: boolean) => void
}; 

const ModalFlexWrapper = styled.div`
    .ant-form-item {
        width: 100%;
    }
`;

const CalendarSettings: React.FC<TCalendarSettingsProps> = ({visible, handleClose}) => {
    const [form] = Form.useForm();
    const [checkboxValues, setCheckboxValues] = useState([]);
    const dispatch = useAppDispatch();
    const excludeOptions = [
        {label: 'Saturday', value: 'saturday'},
        {label: 'Sunday', value: 'sunday'}
    ];

    const handleCheckboxChange = (checkedValues: any) => {
        setCheckboxValues(checkedValues);        
    } 


    const handleSave = () => {
        const settingsData:TSettingsData = {
            weekStart: form.getFieldValue('weekStart'),
            daysToExclude: checkboxValues,
            dayStart: form.getFieldValue('dayStart'),
            dayEnd: form.getFieldValue('dayEnd'),
        };
        dispatch(setCallendarSettings(settingsData));
        localStorage.setItem('calendarConfig', JSON.stringify(settingsData));
        handleClose(false);
    }

    return (
        <Modal 
            title="CALENDAR SETTINGS"
            okText="Save"
            cancelText="Cancel"
            visible={visible}
            onOk={handleSave}
            onCancel={()=>{handleClose(false)}}
        >
            <ModalFlexWrapper>
            <Form
                form={form}
                layout={"vertical"}
                initialValues={{
                    ["weekStart"]: "monday",
                    ["dayStart"]: "2021-01-01T07:00:00",
                    ["dayEnd"]: "2021-01-01T19:00:00",
                }}
            > 
                <Flex flexDirection="column" justifyContent="space-between">
                    <Form.Item
                        name="weekStart"
                        label="Week Starts"
                    >
                        <Select>
                            <Option value="monday">Monday</Option>
                            <Option value="sunday">Sunday</Option>
                        </Select>
                    </Form.Item>
                    {/* <Form.Item
                        name="exclude"
                        label="Exclude"
                    >
                        <Checkbox.Group options={excludeOptions} onChange={handleCheckboxChange} />
                    </Form.Item> */}
                    </Flex>
                    <Flex flexDirection="column" justifyContent="space-between">
                        <Form.Item
                            name="dayStart"
                            label="Day Starts"
                        >
                            <Select>
                                <Option value="2021-01-01T06:00:00">6:00 AM</Option>
                                <Option value="2021-01-01T07:00:00">7:00 AM</Option>
                                <Option value="2021-01-01T08:00:00">8:00 AM</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="dayEnd"
                            label="Day Ends"
                        >
                            <Select>
                                <Option value="2021-01-01T18:00:00">5:00 PM</Option>
                                <Option value="2021-01-01T19:00:00">6:00 PM</Option>
                                <Option value="2021-01-01T20:00:00">7:00 PM</Option>
                            </Select>
                        </Form.Item>
                    </Flex>                 
                </Form>
            </ModalFlexWrapper>            
        </Modal>
    );
}

export default CalendarSettings;