import { useCallback, useState } from "react"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import styled from "styled-components"
import { EmptyButton } from "../../../ui/Button"
import { PrimaryTooltip } from "../../../ui/Tooltip"
import DotsImg from "../../../assets/images/CanbanBoard/dots.svg"
import BoatImg from "../../../assets/images/CanbanBoard/boat.svg"
import PersonImg from "../../../assets/images/CanbanBoard/person.svg"
import PlaceImg from "../../../assets/images/CanbanBoard/place.svg"
import EventImg from "../../../assets/images/CanbanBoard/event.svg"
import NoteImg from "../../../assets/images/CanbanBoard/note.svg"
import { useHistory } from "react-router-dom"
import routes from "../../../routes/routes"
import moment from "moment"
import { CardDetails } from "./CardDetails"
import { Dropdown } from "antd"
import { getFirstCharsOfFullName } from "../../../utils/utils"
import { AppointmentKanbanIcon, NotesIcon } from "../../../assets/icons"

const ColumnTask = styled(Flex)`
   width: 304px;
   min-height: 188px;
`

const DotsButton = styled(EmptyButton)`
   height: 33px;
   width: 34px;
   margin-top: -6px;
`

const DotsImage = styled.img`
   width: 100%;
   height: 100%;
`

const IconsImage = styled.img`
   width: 15px;
   height: 15px;
`

const ToolTipsImage = styled.img`
   width: 20px;
   height: 20px;
`

const Initials = styled.div`
   width: 28px;
   height: 28px;
   background-color: #e8e8e8;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 10px;
`

const DropD = styled(Dropdown)`
   position: relative;
   width: 34px;
   height: 35px;
   background: #ffffff;
   & .EllipseGreen {
      position: absolute;
      right: 6px;
      top: 11px;
   }
`

const ToolTp = styled(PrimaryTooltip)`
   margin-right: 10px;
`

export const SimpleJob = ({ jobData, loadCards, techInfo, userInfo }) => {
   const [detailsVisible, setDetailsVisible] = useState(false)

   const history = useHistory()

   const handleEdit = useCallback(
      (id, work_order) => () => {
         history.push(routes.workOrder.UpdateWorkOrder.replace(":id", work_order) + `?job=${id}`)
      },
      [history]
   )

   console.log("jobData", jobData)

   return (
      <ColumnTask className="little-cart" pr="12px" pl="12px" pt="8px" pb="8px" mt="12px" flexDirection="column">
         <Flex justifyContent="space-between" width="100%" mb="12px" position="relative">
            <EmptyButton width="fit-content" onClick={handleEdit(jobData.id, jobData.work_order)}>
               <PrimaryTooltip title={jobData.title}>
                  <Msg
                     fontSize="15px"
                     fontWeight="600"
                     color="#109CF1"
                     width="200px"
                     textAlign="left"
                     className="short-text"
                  >
                     {jobData.title}
                  </Msg>
               </PrimaryTooltip>
            </EmptyButton>
            <DropD
               getPopupContainer={(trigger) => trigger.parentElement}
               overlay={() => (
                  <CardDetails
                     id={jobData.id}
                     work_order={jobData.work_order}
                     loadCards={loadCards}
                     closeDropdown={() => setDetailsVisible(false)}
                  />
               )}
               trigger="click"
               placement="bottomRight"
            >
               <DotsButton>
                  <DotsImage src={DotsImg}></DotsImage>
               </DotsButton>
            </DropD>
         </Flex>
         <Flex width="100%" justifyContent="flex-start" mb="10px">
            <IconsImage src={BoatImg}></IconsImage>
            <PrimaryTooltip title={jobData?.vessel_data?.name}>
               <Msg fontSize="13px" fontWeight="400" ml="12px" className="short-text">
                  {jobData?.vessel_data?.name}
               </Msg>
            </PrimaryTooltip>
         </Flex>
         <Flex width="100%" justifyContent="flex-start" mb="10px">
            <IconsImage src={PersonImg}></IconsImage>
            <Msg fontSize="13px" fontWeight="400" ml="12px">
               {jobData.customer_data.first_name} {jobData.customer_data.last_name}
            </Msg>
         </Flex>
         <Flex width="100%" justifyContent="flex-start" mb="25px">
            <IconsImage src={PlaceImg}></IconsImage>
            <Msg fontSize="13px" fontWeight="400" ml="12px">
               Address
            </Msg>
         </Flex>
         <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
               <Initials>
                  <Msg fontSize="12px" fontWeight="600">
                     {techInfo?.full_name
                        ? getFirstCharsOfFullName(techInfo.full_name)
                        : getFirstCharsOfFullName(userInfo.full_name)}
                  </Msg>
               </Initials>
               <ToolTp title="No Notes">
                  <div>
                     <NotesIcon color="#8B8B8B" />
                  </div>
               </ToolTp>
               <ToolTp title={moment(jobData.created).format("h:mm a on dddd, MMMM D, YYYY")}>
                  <div>
                     <AppointmentKanbanIcon />
                  </div>
               </ToolTp>
            </Flex>
            <Msg fontSize="14px" fontWeight="800">
               ${jobData.total.toFixed(2)}
            </Msg>
         </Flex>
      </ColumnTask>
   )
}
