import styled from "styled-components"
import { Button, Steps, Input, Select } from "antd"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"

export const ImportSteps = styled(Steps)`
    .ant-steps-item-active {
        .ant-steps-item-icon {
            background-color: #FFFFFF !important;
            border: 2px solid #0496FF;           
        }
        .ant-steps-item-title {
            color: #0496FF !important;
        }
        .ant-steps-item-title::after {
            background-color: #ACE0FF !important;
        }
    }   
    .ant-steps-item-wait {
        .ant-steps-item-icon {
            background: #ACE0FF !important;
            border: 2px solid #FFFFFF;         
        }
        .ant-steps-item-title {
            color: #ACE0FF !important;
        }
        .ant-steps-item-title::after {
            background-color: #ACE0FF !important;
        }
    }
    .ant-steps-item-finish {
        .ant-steps-item-icon {
            background: #0496FF !important;
            border: 2px solid #FFFFFF;         
        }
        .ant-steps-item-title {
            color: #0496FF !important;
        }
        .ant-steps-item-title::after {
            background-color: #0496FF !important;
        }
    }
    .ant-steps-item {
        padding-left: 0 !important;
        width: 250px;
        overflow: visible;
    }
    .ant-steps-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ant-steps-item-icon {
        width: 44px !important;
        height: 44px !important; 
    }
    .ant-steps-item-icon, .ant-steps-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-steps-item-content {
        width: 100%;
    }
    .ant-steps-item-title {
        width: 100%;
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        padding: 0;
        margin-top: 4px;
    }
    .ant-steps-item-title::after {
        width: 100% !important;
        top: -26px !important;
        left: 58% !important;
        height: 2px !important;
    }
`

export const Container = styled(Flex)`
    width: 100%;
    min-height: 450px;
    padding: 24px 48px 48px;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    flex-direction: column;
`

export const HeaderLabel = styled(Msg)`
    font-weight: 900;
    font-size: 18px;
    text-transform: uppercase;
    color: #202020;
`

export const TableContainer = styled(Flex)<{isEditable?: boolean}>`
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
       width: 8px;   
    }
    &::-webkit-scrollbar-track {
       background: #EDEDED;
       border-radius: 8px;
       border: 1px solid #ededed;      
    }
    &::-webkit-scrollbar-thumb {
       background: #C2CFE0;
       border-radius: 8px;   
    }
    &::-webkit-scrollbar-thumb:hover {
       background: #C2CFE0;
    }
    .ant-table-wrapper {
        width: 100%;
    }
    .ant-table {
        .ant-table-thead > tr > th {
            background: #FFFFFF;
            color: #494949;
            font-size: 13px;
            font-weight: 700;
        }
        .ant-table-tbody > tr > td {
            min-width: ${props => props.isEditable ? '240px' : '160px'};
        }
    }
`

export const Content = styled(Flex)`
    width: 100%;
    min-height: 300px;
    .ant-spin-nested-loading {
        width: 100%;
    }
`

export const DownloadButton = styled(Button)`
    border: none;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #0496FF;
    display: flex;
    align-items: center;    
    margin-top: 16px;
    >span {
        margin-left: 8px;
    }
`

export const DraggerWrapper = styled(Flex)`
    width: 100%;
    height: 400px;
    >span {
        width: 100%;
        height: 100%;
        .ant-upload {
            background: #FBFBFF;
            border: 1px solid #EDEDED;
            border-radius: 4px;
        }
    }
`

export const DraggerFile = styled(Flex)`
    max-width: 550px;
    width: 100%;
    height: 200px;
    padding: 24px;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 4px;
    position: relative;
`

export const CloseButton = styled(Button)`
    padding: 0;
    border: none;
    position: absolute;
    top: 24px;
    right: 24px;
`

export const HeaderSelect = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    min-width: 320px;
`

export const StyledInput = styled(Input)<{isError?: boolean}>`
    ${props => props.isError && `
        .ant-input {
            color: red;
        }
        border-color: red;
        &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color: red
        }
    `}
`

export const DataSelect = styled(Select)<{isError?: boolean}>`
    ${props => props.isError && `
        .ant-select-selector {
            color: red;
            border-color: red !important;
        }
        .ant-select-arrow {
            width: 16px;
            height: 16px;
            top: 43%;
        }
    `}
`

export const StyledSelect  = styled(Select)`
    min-width: 200px;
    text-align: start;
    .ant-select-selection-item {
        font-weight: 400;
    }
`;

export const StyledTable = styled.table`
    display: block;
    padding: 0 8px;
    max-height: 400px;
    min-height: 400px;
    border: 1px solid #EDEDED;
    border-collapse: separate;
    border-spacing: 0 12px;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
       width: 8px;   
    }
    &::-webkit-scrollbar-track {
       background: #EDEDED;
       border-radius: 8px;
       border: 1px solid #ededed;      
    }
    &::-webkit-scrollbar-thumb {
       background: #0496FF;
       border-radius: 8px;   
    }
    &::-webkit-scrollbar-thumb:hover {
       background: #0496FF;
    }
    thead {
        position: sticky;
        top: 0px;
        background: #FFFFFF;
        z-index: 1;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
    thead tr th {
        padding: 12px;
        position: relative;
        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 1.6em;
            background-color: rgba(0, 0, 0, 0.06);
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: '';
        }
        &:last-child::before {
            display: none;
        }
    }
    tbody tr {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        td {
            padding: 15px 12px;
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
`

export const FinalStepContainer = styled(Flex)`
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
`;

export const TypeRowBlock = styled(Flex)`
    padding: 14px 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 100%;
    margin-top: 12px;
    justify-content: space-between;
    &:first-child {
        margin-top: 0;
    }
`;