import styled from 'styled-components';
import {
  minWidth, MinWidthProps,
  minHeight, MinHeightProps,
  maxHeight, MaxHeightProps,
  maxWidth, MaxWidthProps,
  textAlign, TextAlignProps,
  borders, BordersProps,
  fontSize, FontSizeProps,
  fontWeight, FontWeightProps,
  color, ColorProps,
  display, DisplayProps,
  top, TopProps,
  bottom, BottomProps,
  left, LeftProps,
  right, RightProps,
  lineHeight, LineHeightProps,
  space, SpaceProps,
  flex, FlexProps,
  alignItems, AlignItemsProps,
  justifyContent, JustifyContentProps,
  flexDirection, FlexDirectionProps,
  verticalAlign, VerticalAlignProps,
  flexWrap, FlexWrapProps,
  height, HeightProps,
  width, WidthProps,
  margin, MarginProps,
  borderRight, BorderRightProps,
  borderBottom, BorderBottomProps,
  typography, TypographyProps,
  position, PositionProps
} from 'styled-system';


type TCustomProps = {
  column?: boolean;
  inline?: boolean;
  center?: boolean;
}
export type TFlexProps =
  & TCustomProps
  & SpaceProps
  & FlexProps
  & AlignItemsProps
  & JustifyContentProps
  & FlexDirectionProps
  & VerticalAlignProps
  & FlexWrapProps
  & HeightProps
  & WidthProps
  & MarginProps
  & BorderRightProps
  & BorderBottomProps
  & TypographyProps
  & PositionProps
  & MinWidthProps
  & MinHeightProps
  & MaxWidthProps
  & MaxHeightProps
  & TextAlignProps
  & BordersProps
  & FontSizeProps
  & FontWeightProps
  & ColorProps
  & DisplayProps
  & TopProps
  & BottomProps
  & LeftProps
  & RightProps
  & LineHeightProps

const Flex = styled.div.attrs<TFlexProps>(props => ({
    justifyContent: props.justifyContent || (props.center && 'center'),
    alignItems: props.alignItems || (props.center && 'center'),
    flexDirection: props.column ? 'column' : props.flexDirection,
    display: props.inline ? 'inline-flex' : 'flex'
  }))<TFlexProps>`
  ${space}
  ${margin}
  ${flex}
  ${alignItems}
  ${justifyContent}
  ${verticalAlign}
  ${flexDirection}
  ${flexWrap}
  ${height}
  ${width}
  ${borderRight}
  ${borderBottom}
  ${typography}
  ${position}
  ${minWidth}
  ${maxWidth}
  ${minHeight}
  ${maxHeight}
  ${textAlign}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${display}
  ${top}
  ${bottom}
  ${left}
  ${right}
  ${lineHeight}
 `;

export default Flex;
