import styled from "styled-components";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";

type TProps = {
    trial_period: number|null
}

const TrialBlock = styled(Flex)`
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #FB4D4F;
    position: absolute;
    right: -8px;
    top: 40px;
    padding: 5px 10px;
    border-radius: 7px;
`;

const TrialHeader = styled(Msg)`
    font-size: 13px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: uppercase;
`;

const TrialSubText = styled(Msg)`
    font-size: 11px;
    font-weight: 500;
    color: #FFFFFF;
`;

const TrialLabel:React.FC<TProps> = ({trial_period}) => {
    if (!trial_period) {
        return null;
    }

    return <TrialBlock>
        <TrialHeader>trial period</TrialHeader>
        <TrialSubText>{trial_period} days</TrialSubText>
    </TrialBlock>
}

export default TrialLabel;