import styled from "styled-components"
import { Checkbox } from "antd"

export const SwitchButton = styled(Checkbox)`
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   display: inline-flex;
   padding: 0 14px;
   background: #fff;
   justify-content: space-between;
   border: none;
   align-items: center;
   height: 40px;
   width: 124px;
   border-radius: 4px;
   & > span {
      padding: 0;
      font-size: 13px;
      color: #484848;
   }
   .ant-checkbox {
      top: 0;
   }
   &.ant-checkbox-wrapper-checked {
      font-weight: bold;
      color: #0496ff;
      > span {
         color: #0496ff;
      }
   }
   &:after {
      content: none;
   }
`
