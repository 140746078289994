import React, { useState, useEffect } from "react"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { CompanyInfoContainer, ContainerTitle, SaveBtn, SaveBtnWrapper } from "./styled"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import "./Editor.scss"
import styled from "styled-components"
import { Button, notification } from "antd"
import { TextEditor } from "./TextEditor"
import { useAppDispatch, useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { Api } from "../../api/api"
import { getErrors, showNonFieldsErrors } from "../../utils/utils"
import { setCurrentUser } from "../../config/reducers/user/actions"

const ControlPanel = styled(Flex)`
   background: #fbfbff;
   border: 1px solid #ededed;
   box-sizing: border-box;
   border-radius: 0px 0px 4px 4px;
   padding: 7px 20px;
`

type TEditorButtonProps = {
   isActive?: boolean
}

const EditorButton = styled(Button)<TEditorButtonProps>`
   margin-right: 9px;
   border: none;
   padding: 8px 16px;
   background: ${(props) => (props.isActive ? "rgba(4, 150, 255, 0.1)" : "#FFFFFF")};
   &:hover {
      background: rgba(4, 150, 255, 0.1);
      svg path,
      svg rect {
         fill: #0496ff;
      }
   }
   svg path,
   svg rect {
      fill: ${(props) => (props.isActive ? "#0496ff" : "#494949")};
   }
`

const VerticalHR = styled.div`
   height: 100%;
   width: 1px;
   background: #ededed;
   margin-right: 9px;
`

const FontSizeControl = styled(Button)`
   border: 1px solid #ededed;
   border-radius: 1px;
   background: #fbfbff;
`

const FontSizeText = styled.div`
   width: 45px;
   border: 1px solid #ededed;
   display: flex;
   justify-content: center;
   align-items: center;
`

type TProps = {
   profile: any
}

export const CustomFooters: React.FC<TProps> = ({ profile }) => {
   const isXS = useBreakpoint(down("xs"))
   const dispatch = useAppDispatch()
   const [initialFooterValue, setInitialFooterValue] = useState(profile?.company?.custom_email_footer || "<p></p>")
   const initialTermsValue = profile?.company?.custom_terms_conditions || "<p></p>"
   const [customFooter, setCustomFooter] = useState(initialFooterValue)
   const [terms, setTerms] = useState(initialTermsValue)
   const [isLoading, setIsLoading] = useState(false)
   const [isDirty, setIsDirty] = useState(false)

   const handleSave = async () => {
      try {
         setIsLoading(true)
         await Api.company.patch({
            id: profile?.company?.id ?? 0,
            custom_email_footer: customFooter,
            custom_terms_conditions: terms,
         })
         const { data: user } = await Api.user.profile()
         dispatch(setCurrentUser(user))
         setIsDirty(false)
      } catch (err) {
         showNonFieldsErrors(getErrors(err))
      } finally {
         setIsLoading(false)
      }
   }

   useEffect(() => {
      setInitialFooterValue(profile?.company?.custom_email_footer)
   }, [profile?.company?.custom_email_footer])

   const updateFooter = (value: string) => {
      setCustomFooter(value)
      if (!isDirty) {
         setIsDirty(true)
      }
   }

   const updateTerms = (value: string) => {
      setTerms(value)
      if (!isDirty) {
         setIsDirty(true)
      }
   }

   return (
      <CompanyInfoContainer p={16}>
         <Flex mb={isXS ? "0px" : "22px"} flexDirection="column" width="100%">
            <ContainerTitle>Custom-Branded Email</ContainerTitle>
            <Msg fontWeight={400} fontSize={13} color="#000000" mb="16px">
               Custom-branded email appear on email notifications.
            </Msg>
            <Msg fontWeight={700} fontSize={15}>
               Terms & Conditions in Custom Branded Emails
            </Msg>
            <TextEditor initialValue={initialTermsValue} onChange={updateTerms} />
            <Msg fontWeight={700} fontSize={15}>
               Footer
            </Msg>
            <TextEditor initialValue={initialFooterValue} onChange={updateFooter} />
            <SaveBtnWrapper>
               <SaveBtn disabled={!isDirty} loading={isLoading} onClick={handleSave} style={{ marginLeft: "auto" }}>
                  Save
               </SaveBtn>
            </SaveBtnWrapper>
         </Flex>
      </CompanyInfoContainer>
   )
}
