import React, {useEffect, useState} from 'react';
import {NotificationsShortView} from "./NotificationsShortView";
import {NotificationPreferences} from "./NotificationPreferences";
import {TCallback} from "../../types";
import {NotificationsFull} from "./NotificationsFull";


type TView = 'short' | 'full' | 'preferences';
type TProps = {
  onClose: TCallback;
  visible: boolean;
}
export const NotificationsContent: React.FC<TProps> = ({onClose, visible}) => {
  const [view, setView] = useState<TView>('short');

  useEffect(() => {
    if (visible) {
      setView("short");
    }
  }, [visible])

  const getContent = () => {
    switch (view) {
      case 'preferences':
        return <NotificationPreferences
          onBack={() => setView('short')}
        />
      case "full":
        return <NotificationsFull
          onClose={onClose}
          onPreferences={() => setView('preferences')}
        />
      case "short":
      default:
        return <NotificationsShortView
          onClose={onClose}
          visible={visible}
          onPreferences={() => setView('preferences')}
          onViewAll={() => setView('full')}
        />
    }
  }

  return getContent();
}