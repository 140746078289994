import React from 'react';
import { Msg } from "../../ui/Text";
import styled from "styled-components";
import Flex from "../../noui/Flex";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentData, paymentSlice} from "../../config/reducers/payment/paymentSlice";
import {down} from "styled-breakpoints";

type TProps = {
    fromStart?: boolean,
}
const AnnualContainer = styled.div<TProps>`
  display: flex;
  align-items: ${(props) => props.fromStart ? "flex-start" : "center"};
  flex-flow: column nowrap;
  margin-bottom: ${(props) => props.fromStart ? "0" : "30px"} ;
`;
const ButtonsContainer = styled(Flex)<TProps>`
  width: 372px;
  margin-top: ${(props) => props.fromStart ? "0" : "15px"};
  .ant-btn-default {
    background-color: #EDEDED;
    border: 1px solid #EDEDED;
  }
  ${down("md")} {
    flex-direction: column;
    width: 100%;
    }
`;
const AnnualButton = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    flex: 1 0 50%;
    &:first-child {
    margin-right: 12px;
   }
  ${down("md")} {
    &:last-child {
      margin-top: 5px;
    }
  }
`;

const AnnualSelect:React.FC<TProps> = ({fromStart})  => {
    const {payment_period} = useSelector(getPaymentData)
    const dispatch = useDispatch()
    const buttonColorMonthly = payment_period==="M"?"white":"black"
    const buttonColorAnnually = payment_period==="A"?"white":"black"
    const {setPaymentPeriod} = paymentSlice.actions
    const handleSetPaymentPeriod = (period: string) => {
        dispatch(setPaymentPeriod(period))
    }
    return (
        <AnnualContainer fromStart={fromStart}>
            <Msg color="#0F497B" fontWeight={700} fontSize="22px" textAlign="center">Billing type:</Msg>
                <ButtonsContainer fromStart={fromStart}>
                    <AnnualButton  type={payment_period==="M"?"primary":"default"} onClick={()=>{handleSetPaymentPeriod("M")}}>
                        <Msg color={buttonColorMonthly} fontSize={fromStart?"13px":"15px"}>Monthly</Msg>
                        <Msg color={buttonColorMonthly} fontSize="11px">Include Onboarding Fee</Msg>
                    </AnnualButton>
                    <AnnualButton type={payment_period==="A"?"primary":"default"} onClick={()=>{handleSetPaymentPeriod("A")}}>
                        <Msg color={buttonColorAnnually} fontSize={fromStart?"13px":"15px"}>Annually</Msg>
                        <Msg color={buttonColorAnnually} fontSize="11px">{fromStart?"Save 10% on Annual Plans":"Save 10%"}</Msg>
                    </AnnualButton>
                </ButtonsContainer>
        </AnnualContainer>
    );
};


export default AnnualSelect;