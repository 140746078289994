import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IJob } from "../../../api/types";
import Flex from "../../../noui/Flex";
import { Tooltip } from "antd";
import { Msg } from "../../../ui/Text";
import { InfoCircleOutlined } from "@ant-design/icons";
type TMobileDayEventProps = {
  event: IJob;
  title: string;
};
interface Props {
  isInLine?: boolean;
  durationOfWork?: number;
  paddingLeft?: number;
  isMinHeight?: boolean;
}

const EventWrapper = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => (props.isInLine ? "" : "column")};
  justify-content: ${(props) => (props.isInLine ? "space-between" : "")};
  width: 100%;
  height: 100%;
  margin-top: ${(props) =>
    props.durationOfWork === 30 && props.isInLine ? "3px" : ""};
`;

const TooltipWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  color: black;
  margin-top: 5px;
`;
const EventItem = styled.div`
  margin-bottom: 2px;
`;
const EventHourTittle = styled(EventItem)<Props>`
  display: ${(props) => (props.isInLine ? "flex" : "")};
  margin-bottom: 5px;

  order: ${(props) => (props.isInLine ? "2" : "1")}; ;
`;
const EventItemInfoItem = styled(EventItem)<Props>`
  font-size: 16px;
  margin-top: ${(props) => (props.isMinHeight ? "-3.5px" : "0")};
  padding-left: ${(props) => props.paddingLeft}px;
  order: ${(props) => (props.isInLine ? "1" : "2")}; ;
`;
const EventTittleItem = styled.div`
  white-space: nowrap;
`;
const EventSubTittle = styled.div`
  order: 3;
`;
const MobileWeekEvent: React.FunctionComponent<TMobileDayEventProps> = ({
  event,
}) => {
  const startDate = new Date(event.schedules[0].start);
  const endDate = new Date(event.schedules[0].end);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const startDateNumber = startDate as unknown as number;
  const endDateNumber = endDate as unknown as number;
  const durationOfWork =
    Math.abs(startDateNumber - endDateNumber) / (1000 * 60);
  const isMinHeight = height < 15;
  function formatAMPM(date: Date) {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  const showEventTittle = width > 70 || durationOfWork > 30;
  const tooltipContent = (
    <TooltipWrapper>
      <EventItem>
        <Msg fontWeight={"700"}>
          {formatAMPM(startDate) + "-" + formatAMPM(endDate)}
        </Msg>
      </EventItem>
      <EventItem>{event?.vessel_data?.name}</EventItem>
      <EventItem>{`${event?.customer_data?.first_name} ${event?.customer_data?.last_name}`}</EventItem>
      <EventItem>{event?.title}</EventItem>
      <EventItem>{event?.address?.address1}</EventItem>
    </TooltipWrapper>
  );

  return (
    <EventWrapper
      isInLine={(width > 155 && height < 75) || height <= 35}
      durationOfWork={durationOfWork}
      ref={ref}
    >
      {showEventTittle && (
        <EventHourTittle
          isInLine={(width > 155 && height < 75) || height <= 35}
          durationOfWork={durationOfWork}
        >
          <EventTittleItem> {formatAMPM(startDate)}-</EventTittleItem>
          <EventTittleItem>{formatAMPM(endDate)}</EventTittleItem>
        </EventHourTittle>
      )}
      {height < 75 || width < 170 ? (
        <EventItemInfoItem
          paddingLeft={showEventTittle ? 0 : (width + 10) * 0.15}
          durationOfWork={durationOfWork}
          isInLine={(width > 155 && height < 75) || height <= 35}
          isMinHeight={isMinHeight}
        >
          <Tooltip color={"white"} title={tooltipContent}>
            <InfoCircleOutlined />
          </Tooltip>
        </EventItemInfoItem>
      ) : (
        <EventSubTittle>
          <EventItem>{event?.title}</EventItem>
          <EventItem>{event?.vessel_data?.name}</EventItem>
          <EventItem>{event?.address?.address1}</EventItem>
          <EventItem>{`${event?.customer_data?.first_name} ${event?.customer_data?.last_name}`}</EventItem>
        </EventSubTittle>
      )}
    </EventWrapper>
  );
};

export default MobileWeekEvent;
