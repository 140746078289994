import React, {useEffect, useState} from 'react';
import {AddButton} from "../AddButton";
import styled from "styled-components";
import {Form, FormInstance, Input, AutoComplete} from "antd";
import {Api} from "../../../api/api";
import {mapOptions} from "../../../utils/utils";
import {MinusCircleOutlined} from "@ant-design/icons";
import {TArgCallback, TOption} from "../../../types";
import {ICreateJob, IMaterial, IPaginatedResponse} from "../../../api/types";
import {FormListFieldData} from "antd/es/form/FormList";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 6fr 1fr 1.5fr .5fr;
    grid-gap: 16px;
    min-width: 0;
    > .ant-row {
      min-width: 0;
    }
`;

type TRowProps = {
  onRemove: TArgCallback<number|number[]>;
  form: FormInstance;
  field: FormListFieldData;
}
const MaterialRow: React.FC<TRowProps> = ({onRemove, form, field: {key, ...field}}) => {
  const [options, setOptions] = useState<{data: IMaterial[], mapped: TOption[]}>({mapped: [], data: []});

  useEffect(() => {
    Api.materials.getAll({
      pageSize: 5, page: 1
    })
      .then(({data}) => {
        data = data as IPaginatedResponse<IMaterial[]>;
        const filteredData = data.results.filter(item=>item.active)
        setOptions({data: filteredData, mapped: mapOptions(filteredData, "name")});
      })
  }, []);

  const handleSearch = (search: string) => {
    Api.materials.getAll({
      pageSize: 5, page: 1, search
    })
      .then(({data}) => {
        data = data as IPaginatedResponse<IMaterial[]>
        const filteredData = data.results.filter(item=>item.active)
        setOptions({data: filteredData, mapped: mapOptions(filteredData, "name")});
      })
  }
  const handleSelect = (id: string, val: object) => {
    const value = val as TOption;
    const el = options.data.find(e => e.id.toString() === id);
    if (el) {
      const {price} = el;
      const {name: key} = field;
      const materials = form.getFieldValue("materials");
      materials[key] = {...materials[key], unit_price: price, qty: 1, name: value.label};
      form.setFieldsValue({materials});
    }
  }
  return <Wrapper>
    <Form.Item
      hidden
      {...field}
      name={[field.name, "id"]}
      fieldKey={[field.name, "id"]}
      >
        <Input type="hidden" />
    </Form.Item>
    <Form.Item
      {...field}
      name={[field.name, "name"]}
      fieldKey={[field.fieldKey, "name"]}
      rules={[{required: true, message: "Please select a material"}]}
    >
      <AutoComplete
        showSearch
        placeholder="Select service item"
        filterOption={false}
        options={options.mapped}
        onSelect={handleSelect}
        onSearch={handleSearch}
        notFoundContent={null}>
        <Input />
      </AutoComplete>
    </Form.Item>
    <Form.Item
      {...field}
      name={[field.name, "qty"]}
      fieldKey={[field.fieldKey, "qty"]}
      rules={[{required: true, message: "Quantity is required"}]}
    >
      <Input type="number" />
    </Form.Item>
    <Form.Item
      {...field}
      name={[field.name, "unit_price"]}
      fieldKey={[field.fieldKey, "unit_price"]}
      rules={[{required: true, message: "Price is required"}]}
    >
      <Input type="number" />
    </Form.Item>
    <Form.Item {...field}>
      <MinusCircleOutlined onClick={() => onRemove(field.name)}/>
    </Form.Item>
  </Wrapper>;
}

type TProps = {
  form: FormInstance<ICreateJob>
};
export const MaterialsBlock: React.FC<TProps> = ({form}) => {
  return <div>
    <Wrapper>
      <span>Materials</span>
      <span>Qty</span>
      <span>Price</span>
      <span/>
    </Wrapper>
    <Form.List name="materials">
      {(fields, {add, remove}) => (
        <>
          {fields.map(field => (
            <MaterialRow
              form={form}
              field={field}
              key={field.key}
              onRemove={remove}
            />
          ))}
          <Form.Item>
            <AddButton onClick={() => add()}>
              Add Materials item
            </AddButton>
          </Form.Item>
        </>
      )}
    </Form.List>
  </div>
};