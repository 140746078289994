import {ModalWindow} from '../../ui/ModalWindow';
import React from "react";
import {NotificationsContent} from "./NotificationsContent";
import {TCallback} from "../../types";

type TProps = {
  onClose: TCallback;
  visible: boolean;
}
export const NotificationsModal: React.FC<TProps> = ({onClose, visible}) => (
  <ModalWindow>
    <NotificationsContent onClose={onClose} visible={visible} />
  </ModalWindow>
);