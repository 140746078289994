import React, {useState} from 'react';
import classes from "../styles.module.scss";
import {DefaultTimeFormat, EstimateStatuses} from "../../../constants";
import {DatePicker, Spin} from "antd";
import {MoreButton} from "../components";
import moment from "moment";
import {IJob} from "../../../api/types";
import {TArgCallback, TDateRangeValue} from "../../../types";
import {invertedRange} from "../../../utils/utils";
import {CalendarModal} from "./CalendarModal";

const { RangePicker } = DatePicker;


type TProps = {
  estimate?: IJob
  saving: boolean;
  onSchedule: TArgCallback<TDateRangeValue>;
  onChangeCalendar: TArgCallback<boolean>;
  calendarOpened: boolean;
}
export const ScheduleEstimate: React.FC<TProps> = ({estimate, onChangeCalendar, calendarOpened, onSchedule, saving}) => {
  const [dates, setDates] = useState<TDateRangeValue>(null);

  if (!estimate || estimate.status !== EstimateStatuses.ToBeScheduled) {
    return null;
  }
  const handleChange: TArgCallback<TDateRangeValue> = (dates) => {
    setDates(dates);
  }
  const handleSave = () => {
    onSchedule(dates);
  }
  const disabledDate = (val: moment.Moment) => {
    return moment(val).isBefore(moment(), "day");
  }
  const disabledTime = () => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => invertedRange(0, 60, 15),
      disabledSeconds: () => []
    }
  }

  return <div className={classes.schedule}>
    <h4 className={classes.title}>Schedule</h4>
    {!saving ? <><RangePicker
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      style={{width: "100%"}}
      showTime={{
        hideDisabledOptions: true,
        format: DefaultTimeFormat
      }}
      value={dates}
      onChange={handleChange} format={"M/D/yyyy h:mm a"}/>
      <div style={{flexGrow: 1}} />
      <MoreButton disabled={!dates || !dates.length} type="link" onClick={handleSave}>Schedule</MoreButton>
      <MoreButton onClick={() => onChangeCalendar(true)} type="link">Open Calendar</MoreButton></> : <Spin />}
    <CalendarModal visible={calendarOpened} onClose={() => onChangeCalendar(false)} />
  </div>
};