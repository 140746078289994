import React, { useEffect, useState } from "react"
import { Modal, Form, Select, Input } from "antd"
import { IJob, ITimeClock, IUser } from "../api/types"
import { TCallback } from "../types"
import Flex from "../noui/Flex"
import { notification } from "antd/es"
import { Api } from "../api/api"
import { getErrors } from "../utils/utils"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import moment from "moment"
import { useAppSelector } from "../config/hooks"
import { getCurrentUser } from "../config/reducers/user/selectors"
import { track } from "../utils/analytics"
import { ClockIcon } from "../assets/icons"
import { CalendarPicker } from "../components/CalendarPicker"
import "react-datepicker/dist/react-datepicker.css"

type TProps = {
   visible: boolean
   handleClose: TCallback
   technicians?: IUser[]
   technician?: IUser
   estimateId?: number
   displayJobs: boolean
   selectedTimeClock?: ITimeClock | null
   jobs?: IJob[]
   loadCards: TCallback
}

export const MobileWrapper = styled.div`
   ${down("lg")} {
      .ant-picker-datetime-panel {
         flex-direction: column !important;
      }
   }
   h3 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
   }
   .errorMessage {
      color: #ff4d4f;
      font-size: 13px;
   }
`
export const CalendarWrapper = styled.div`
   .ant-form-item-control-input {
      min-height: 0;
   }
`
export const Duration = styled(Flex)`
   svg {
      margin-right: 10px;
   }
`

export const AddTimeManually: React.FC<TProps> = ({
   visible,
   technicians,
   handleClose,
   estimateId,
   displayJobs,
   jobs,
   loadCards,
   technician,
   selectedTimeClock,
}) => {
   const [duration, setDuration] = useState(" Select Time in and Time out ")
   const [form] = Form.useForm()
   const [startDate, setStartDate] = useState<any>(null)
   const [endDate, setEndDate] = useState<any>(null)
   const [error24, setError24] = useState<string>("")
   const profile = useAppSelector(getCurrentUser)

   const clearModal = () => {
      form.resetFields()
      handleClose()
      setDuration("Select Time in and Time out")
      setStartDate(null)
      setEndDate(null)
   }

   useEffect(() => {
      if (startDate) {
         form.setFieldsValue({ start: moment(startDate) })
      }
   }, [startDate])

   useEffect(() => {
      if (endDate) {
         form.setFieldsValue({ stop: moment(endDate) })
      }
   }, [endDate])

   useEffect(() => {
      calcDuration()
   }, [startDate, endDate])

   const handleSubmit = async () => {
      try {
         if (!error24) {
            const { user, start, stop, job } = await form.validateFields()

            if (moment(start).isAfter(moment(stop))) {
               notification.error({ message: "Start time should be before stop time!" })
               return
            }

            const { data } = await Api.timeClocks.create({
               job: job || estimateId,
               user,
               start,
               stop,
            })

            track("Admin Added Time Manually", {
               workorderId: data.job.work_order,
               adminID: profile?.id,
               vesselId: data.job?.vessel.id,
               technician: data.user.id,
               jobId: data.job.id,
               timeLoggedTimestamp: data.start,
               timeLoggedHours: moment(data.stop).diff(moment(data.start), "hours"),
            })
            clearModal()

            await loadCards()
         }
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors)
         notification.error({
            message: "Please fill in all fields",
         })
      }
   }

   const handleEdit = async () => {
      const { start, stop } = await form.validateFields()
      if (selectedTimeClock && stop) {
         const {
            id,
            job: { id: job },
            user: { id: user },
         } = selectedTimeClock
         await Api.timeClocks.update({ id, start, stop, job, user })
         clearModal()
         await loadCards()
      }
   }

   const handleSave = () => {
      if (selectedTimeClock) {
         handleEdit()
         return
      }
      handleSubmit()
   }

   useEffect(() => {
      form.setFieldsValue({
         user: selectedTimeClock?.user.id,
         job: selectedTimeClock?.job.title,
         start: moment(selectedTimeClock?.start),
         ...(selectedTimeClock?.stop && { stop: moment(selectedTimeClock?.stop) }),
      })
      if (selectedTimeClock) {
         setStartDate(new Date(selectedTimeClock?.start))
         setEndDate(new Date(selectedTimeClock?.stop))
      }
   }, [selectedTimeClock])

   const calcDuration = () => {
      const { start, stop } = form.getFieldsValue()
      let result: any = " Select Time in and Time out "

      if (start && stop) {
         if (stop < start) {
            result = "Start date can't be after end date"
            setError24("")
         } else {
            const timeInMilliseconds = moment.duration(stop.diff(start)).asMilliseconds()
            const h = Math.floor(timeInMilliseconds / 1000 / 60 / 60)
            const m = Math.floor((timeInMilliseconds / 1000 / 60 / 60 - h) * 60)
            if (h > 24 || (h === 24 && m > 0)) {
               setError24("The duration can't be more than 24 hours")
            } else {
               setError24("")
            }
            result = `${h} hours ${m} mins`
         }
      }

      setDuration(result)
      return result
   }

   const isValidEndTime = (value: any) => {
      try {
         if (!value) return Promise.reject("End time is required")
         const start = form.getFieldValue("start")
         if (value < start) {
            return Promise.reject("Start date can't be after end date")
         }
         if (moment.duration(value.diff(start)).asSeconds() > 86400) {
            return Promise.reject("The duration can't be more than 24 hours")
         }
         return Promise.resolve()
      } catch (error) {
         return
      }
   }
   const isValidStartTime = (value: any) => {
      try {
         if (!value) return Promise.reject("Start time is required")
         const stop = form.getFieldValue("stop")
         if (moment.duration(stop.diff(value)).asSeconds() > 86400) {
            return Promise.reject("The duration can't be more than 24 hours")
         }
         return Promise.resolve()
      } catch (error) {
         return
      }
   }

   return (
      <Modal
         visible={visible}
         onCancel={clearModal}
         title={selectedTimeClock ? "Edit time manually" : "Add time manually "}
         okText="Save"
         onOk={handleSave}
         width={400}
      >
         <MobileWrapper>
            <Form layout="vertical" requiredMark={false} form={form} onValuesChange={calcDuration}>
               <Form.Item
                  shouldUpdate
                  name="user"
                  label="Technician"
                  initialValue={technician?.id}
                  rules={[
                     {
                        required: true,
                        message: "This field is required",
                     },
                  ]}
               >
                  <Select
                     placeholder="Select technician"
                     getPopupContainer={(trigger: any) => trigger.parentElement}
                     disabled={!!selectedTimeClock || !!(!technicians?.length && technician)}
                  >
                     {technicians && technicians.length ? (
                        technicians.map((t) => (
                           <Select.Option key={t.id} value={t.id}>
                              {t.full_name}
                           </Select.Option>
                        ))
                     ) : technician ? (
                        <Select.Option key={technician.id} value={technician.id}>
                           {technician.full_name}
                        </Select.Option>
                     ) : null}
                  </Select>
               </Form.Item>
               {displayJobs && (
                  <Form.Item
                     name="job"
                     label="Job list"
                     rules={[
                        {
                           required: true,
                           message: "This field is required",
                        },
                     ]}
                  >
                     <Select
                        disabled={!!selectedTimeClock}
                        placeholder="Select job"
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                     >
                        {jobs?.map((j) => (
                           <Select.Option value={j.id} key={j.id}>{`Estimate #${j.order_number}`}</Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               )}
               <Form.Item shouldUpdate name="activity" label="Activity">
                  <Select
                     disabled={!!selectedTimeClock}
                     placeholder="General"
                     getPopupContainer={(trigger: any) => trigger.parentElement}
                  >
                     <Select.Option value="General">General</Select.Option>
                  </Select>
               </Form.Item>
               <Flex justifyContent="space-between" alignItems="start">
                  <CalendarWrapper>
                     <CalendarPicker label="Time in" date={startDate} setDate={setStartDate} />
                     <Form.Item
                        shouldUpdate
                        name="start"
                        rules={[
                           {
                              required: true,
                              message: "This field is required",
                           },
                        ]}
                        style={{ minHeight: 0, height: 0 }}
                     ></Form.Item>
                  </CalendarWrapper>
                  <div
                     style={{
                        height: "1px",
                        backgroundColor: "rgba(130, 130, 130, 1)",
                        width: "8px",
                        margin: "37px 8px 0",
                     }}
                  ></div>
                  <CalendarWrapper>
                     <CalendarPicker label="Time out" date={endDate} setDate={setEndDate} />
                     <Form.Item
                        shouldUpdate
                        name="stop"
                        rules={[
                           {
                              required: true,
                              message: "This field is required",
                           },
                        ]}
                        style={{ minHeight: 0, height: 0 }}
                     ></Form.Item>
                  </CalendarWrapper>
               </Flex>
               {error24 && <p className="errorMessage">{error24}</p>}
               <Form.Item shouldUpdate label="Duration">
                  <Duration alignItems="center">
                     <ClockIcon /> {duration}
                  </Duration>
               </Form.Item>
               <Form.Item shouldUpdate name="notes" label="Notes">
                  <Input.TextArea style={{ resize: "none" }} />
               </Form.Item>
            </Form>
         </MobileWrapper>
      </Modal>
   )
}
