import styled from "styled-components";
import { Spin, Empty, Select } from "antd";
import Flex from "../../noui/Flex";
import { useState, useEffect, useCallback } from "react";
import { Msg } from "../../ui/Text";
import { PrimaryTooltip } from "../../ui/Tooltip";
import routes from "../../routes/routes";
import moment from "moment";
import { Api } from "../../api/api";
import { NavLink, useHistory } from "react-router-dom";
import { down } from "styled-breakpoints";
import { TitleWrapper } from "./TitleWrapper";
import { ScrollWrapper } from "./ScrollWrapper";
import { IInvoice, IStats } from "../../api/types";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { DefaultDateFormat } from "../../constants";
import { TableWrapper } from "./TableWrapper";

const JobsContainer = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #ffffff;
  & .ant-row.ant-form-item {
    margin-bottom: 0;
  }
  ${down("lg")} {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const SelectWrapper = styled(Select)`
  border-radius: 4px;
  font-weight: 400;
  font-size: 13px;
  &.ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
  }
  & .ant-select-selection-item {
    color: #0f497b;
  }
  .ant-select-option-item {
    width: 112px;
    height: 34px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    color: #42c57a;
  }
`;

const BackButton = styled(NavLink)`
  color: #0099ff;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
`;

type TDateSelector = "w" | "m" | "y";

const PaidInvoicesWidget = () => {
  const [invoicesList, setInvoivesList] = useState<IInvoice[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [dateForStats, setDateForStats] = useState<moment.Moment>(
    moment().startOf("week")
  );
  const [showType, setShowType] = useState<TDateSelector>("w");
  const history = useHistory();
  const isXS = useBreakpoint(down("xs"));
  const isMD = useBreakpoint(down("md"));

  const loadCards = useCallback(async () => {
    setLoading(true);
    try {
      await Api.invoices.getAll({ completed: true }).then(({ data }) => {
        setInvoivesList(data as IInvoice[]);
      });
      await Api.stats
        .getAll({
          time_before: moment().toISOString(),
          time_after: dateForStats.toISOString(),
        })
        .then(({ data: rd }) => {
          const data = rd as IStats;
          setTotalCount(data.invoices_paid);
        });
    } finally {
      setLoading(false);
    }
  }, [dateForStats]);

  useEffect(() => {
    loadCards().finally();
  }, [loadCards]);

  const changeDate = (rv: any) => {
    const value = rv as TDateSelector;
    switch (value) {
      case "m":
        setShowType("m");
        setDateForStats(moment().startOf("month"));
        break;
      case "y":
        setShowType("y");
        setDateForStats(moment().startOf("year"));
        break;
      case "w":
      default:
        setShowType("w");
        setDateForStats(moment().startOf("week"));
    }
  };

  const MobileRowContent: React.FC<IInvoice> = (data) => (
    <Flex flexDirection="column" alignItems="start" width="100%">
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          width={isXS ? "70px" : "33.333%"}
        >
          <Msg
            fontSize="12px"
            fontWeight="700"
            color="#484848"
            className="short-text"
          >
            #{data.work_order.title}
          </Msg>
          <Msg
            fontSize="12px"
            fontWeight="400"
            color="#484848"
            className="short-text"
          >
            {moment(data.created).format("MM/DD/YY")}
          </Msg>
          <Msg
            fontSize="12px"
            fontWeight="700"
            color="#484848"
            className="short-text"
          >
            {data.work_order.customer.first_name +
              " " +
              data.work_order.customer.last_name}
          </Msg>
          <Msg
            fontSize="12px"
            fontWeight="400"
            color="#484848"
            className="short-text"
          >
            {data.work_order?.vessel.name}
          </Msg>
        </Flex>
        <Flex
          flexDirection="column"
          textAlign="center"
          justifyContent="end"
          width={isXS ? "70px" : "33.333%"}
        >
          <Msg
            fontSize="12px"
            fontWeight="400"
            color="#484848"
            className="short-text"
          >
            {data.id}
          </Msg>
        </Flex>
        <Flex
          flexDirection="column"
          textAlign="right"
          justifyContent="space-between"
          width={isXS ? "70px" : "33.333%"}
        >
          <Msg fontSize="12px" fontWeight="400" color="#42C57A">
            Paid {moment(data.completed_time).format("MM/DD/YY")}
          </Msg>
          <Msg fontSize="12px" fontWeight="900" color="#109CF1">
            ${data?.total?.toFixed(2)}
          </Msg>
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <JobsContainer>
      <TitleWrapper>
        <Msg fontWeight={900} fontSize={16} color="#151F2B" mr="11px">
          PAID INVOICES
        </Msg>
        <Flex alignItems="center">
          <Msg fontWeight={400} fontSize={13} color="#151F2B">
            Show:
          </Msg>
          <SelectWrapper defaultValue="w" onChange={changeDate}>
            <Select.Option value="w">This week</Select.Option>
            <Select.Option value="m">This month</Select.Option>
            <Select.Option value="y">This year</Select.Option>
          </SelectWrapper>
        </Flex>
      </TitleWrapper>
      <ScrollWrapper>
        <TableWrapper>
          {!loading ? (
            invoicesList.length > 0 ? (
              <Flex
                flexDirection="column"
                height="340px"
                justifyContent="space-between"
              >
                <Flex flexDirection="column">
                  {invoicesList
                    .filter(
                      (invoice) =>
                        invoice.completed_time &&
                        moment(invoice.completed_time) > moment(dateForStats)
                    )
                    .slice(0, 4)
                    .map((invoice) => (
                      <Flex
                        p="12px 16px"
                        justifyContent="space-between"
                        className="little-cart"
                        mb="12px"
                        mr="2px"
                        ml="2px"
                        mt="2px"
                        flexDirection={isMD ? "column" : "row"}
                        key={invoice.id}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            routes.workOrder.UpdateWorkOrder.replace(
                              ":id",
                              String(invoice.work_order.id)
                            )
                          )
                        }
                      >
                        {isMD ? (
                          <MobileRowContent {...invoice} />
                        ) : (
                          <>
                            <Flex
                              flexDirection="column"
                              justifyContent="space-between"
                            >
                              <Msg
                                fontSize="13px"
                                fontWeight="700"
                                color="#484848"
                              >
                                {invoice.work_order.title}
                              </Msg>
                              <Msg
                                fontSize="15px"
                                fontWeight="400"
                                color="#484848"
                              >
                                {moment(invoice.created).format("MM/DD/YY")}
                              </Msg>
                            </Flex>
                            <Flex
                              flexDirection="column"
                              justifyContent="space-between"
                            >
                              <PrimaryTooltip
                                title={
                                  invoice.work_order.customer.first_name +
                                  " " +
                                  invoice.work_order.customer.last_name
                                }
                                width="100px"
                              >
                                <Msg
                                  fontSize="15px"
                                  fontWeight="700"
                                  color="#484848"
                                  width="20px"
                                  className="short-text"
                                >
                                  {invoice.work_order.customer.first_name +
                                    " " +
                                    invoice.work_order.customer.last_name}
                                </Msg>
                              </PrimaryTooltip>
                              <PrimaryTooltip
                                title={invoice.work_order?.vessel.name}
                                width="100px"
                              >
                                <Msg
                                  fontSize="15px"
                                  fontWeight="400"
                                  color="#484848"
                                  width="20px"
                                  className="short-text"
                                >
                                  {invoice.work_order?.vessel.name}
                                </Msg>
                              </PrimaryTooltip>
                            </Flex>
                            <Flex flexDirection="column">
                              <Msg
                                fontSize="15px"
                                fontWeight="400"
                                color="#42C57A"
                                mb="5px"
                              >
                                {invoice.completed_time
                                  ? `Paid ${moment(
                                      invoice.completed_time
                                    ).format(DefaultDateFormat)}`
                                  : ""}{" "}
                              </Msg>
                              <Msg
                                fontSize="15px"
                                fontWeight="900"
                                color="#109CF1"
                                textAlign="end"
                              >
                                ${invoice?.total?.toFixed(2)}
                              </Msg>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    ))}
                </Flex>
              </Flex>
            ) : (
              <Empty />
            )
          ) : (
            <Spin spinning={loading} />
          )}
        </TableWrapper>
      </ScrollWrapper>
      <Flex alignItems="center" justifyContent="space-between" p="0px 20px">
        <BackButton to={routes.business.Invoices}>All invoices</BackButton>
        <Flex height="45px" alignItems="center">
          <Msg fontSize="13px" fontWeight="400" color="#484848" mr="10px">
            Paid this{" "}
            {showType === "w" ? "week" : showType === "m" ? "month" : "year"}:
          </Msg>
          <Msg
            fontSize={isMD ? "16px" : "18px"}
            fontWeight="900"
            color="#109CF1"
            textAlign={isMD ? undefined : "end"}
          >
            ${totalCount.toFixed(2)}
          </Msg>
        </Flex>
      </Flex>
    </JobsContainer>
  );
};

export default PaidInvoicesWidget;
