import React, {useLayoutEffect, useRef, useState} from 'react';
import {Button, Dropdown, Menu, Spin} from "antd";
import {DownOutlined, EllipsisOutlined, LoadingOutlined} from "@ant-design/icons";
import routes from "../../../routes/routes";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {TCallback} from "../../../types";
import {IJob} from "../../../api/types";

const StyledMenu = styled(Menu)`
  li {
    white-space: normal;
  }
`;
const StyledButton = styled(Dropdown.Button)`
  flex-grow: 1;
  width: 100%;
  button:first-child {
    width: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  > button: {
    font-size: 11px;
  }
`;

type TProps = {
  onAction: TCallback;
  actionLabel: string;
  estimate?: IJob;
  onProceed: TCallback;
  onArchive: TCallback;
  onSaveAndClose: TCallback;
  saving: boolean;
  onRecordPayment: TCallback;
}
export const Actions: React.FC<TProps> =
  ({onAction, actionLabel, estimate, onProceed, onArchive,
     onSaveAndClose, saving, onRecordPayment}) => {
  const wrpRef = useRef<HTMLDivElement>(null);
  const [w, setW] = useState(0);

  useLayoutEffect(() => {
    const width = wrpRef.current?.clientWidth;
    if (width) {
      setW(width);
    }
  }, []);
  const actions = <StyledMenu style={{width: w || undefined}}>
    <Menu.Item key="1" onClick={onSaveAndClose}>Save & Close</Menu.Item>
    <Menu.Item key="2" onClick={onProceed}>Proceed without approval</Menu.Item>
  </StyledMenu>;

  const history = useHistory();

  const estimatePdf = estimate?.estimate_pdf;

  const handleOpenPreview = () => {
    history.push(routes.customers.CustomerEstimateView.replace(":id",
      String(estimate?.send_config?.uuid??""))
    )
  }

  const handleOpenPdf = () => {
    window.open(estimatePdf);
  }

  return <Container>
    <div style={{flexGrow: 1}} ref={wrpRef}>
      <StyledButton
        onClick={onAction}
        type="primary"
        overlay={actions}
        disabled={saving}
        icon={saving
          ? <Spin indicator={<LoadingOutlined/>}/>
          : <DownOutlined/>
        }>
        {actionLabel}
      </StyledButton>
    </div>

    {estimate?.id ? <Button onClick={onRecordPayment}>
      Record Payment Manually
    </Button> : null}

    <Dropdown
      overlay={<Menu>
        <Menu.Item disabled={!estimate?.id} onClick={onArchive}>{estimate?.is_archived ? "Unarchive" : "Archive"}</Menu.Item>
        <Menu.Item onClick={handleOpenPdf} disabled={!estimatePdf}>View PDF</Menu.Item>
        <Menu.Item onClick={handleOpenPreview} disabled={!estimatePdf}>Preview</Menu.Item>
      </Menu>}
    >
      <Button icon={<EllipsisOutlined/>}/>
    </Dropdown>
  </Container>
};