import { createStore, combineReducers, applyMiddleware, AnyAction } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"

import { userReducer } from "./reducers/user/user"

import thunkMiddleware, { ThunkAction } from "redux-thunk"
import { columnsReducer } from "./reducers/columns/columnsReducer"
import { filtersReducer } from "./reducers/filters/filtersReducer"
import { notificationsReducer } from "./reducers/notifications/notificationsReducer"
import { pdfCheckboxesReducer } from "./reducers/pdfCheckboxes/pdfCheckboxesReducer"
import { menuClickReducer } from "./reducers/menuClickReducer/menuClickReducer"
import paymentReducer from "./reducers/payment/paymentSlice"

const rootReducer = combineReducers({
   user: userReducer,
   columns: columnsReducer,
   filters: filtersReducer,
   notifications: notificationsReducer,
   pdfCheckboxes: pdfCheckboxesReducer,
   payment: paymentReducer,
   menuClickItem: menuClickReducer,
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
