import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { TRoute } from "./types"
import routes from "../routes/routes"
import { Login } from "../containers/login/Login"
import { CustomerEstimateLayout } from "./CustomerLayout/CustomerEstimateLayout"
import ResetPassword from "../containers/resetpassword/component/ResetPassword"
import { RegistrationStepOne } from "../containers/registration/firstStep/RegistrationStepOne"
import { RegistrationStepTwo } from "../containers/registration/secondStep/RegistrationStepTwo"
import ForgotPassword from "../containers/forgotpassword/ForgotPassword"
import CheckEmailLayout from "../layouts/CheckEmailLayout"
import { RegistrationStepThree } from "../containers/registration/thirdStep/RegistrationStepThree"
import { CustomerRecurringLayout } from "./CustomerLayout/CustomerRecurringLayout"
import { ScheduleViewLayout } from "./CustomerLayout/ScheduleViewLayout"
import { CustomerTermsLayout } from "./CustomerLayout/CustomerTermsLayout"
import { StatementOfWorkLayout } from "./CustomerLayout/StatementOfWorkLayout"

const routesList: TRoute[] = [
   { path: routes.registration.Base, component: RegistrationStepOne, exact: true },
   { path: routes.registration.Step2, component: RegistrationStepTwo },
   { path: routes.registration.Step3, component: RegistrationStepThree },
   { path: routes.auth.ForgotPassword, component: ForgotPassword, exact: true },
   { path: routes.auth.ResetPassword, component: ResetPassword, exact: true },
   { path: routes.auth.SetNewPassword, component: ResetPassword, exact: true },
   { path: routes.customers.CustomerEstimateView, component: CustomerEstimateLayout },
   { path: routes.customers.CustomerInvoiceView, component: CustomerEstimateLayout },
   { path: routes.customers.CustomerSubscriptionView, component: CustomerRecurringLayout },
   { path: routes.customers.CustomerTermsView, component: CustomerTermsLayout },
   { path: routes.auth.Login, component: Login },
   { path: routes.auth.CheckEmail, component: CheckEmailLayout },
   { path: routes.customers.ScheduleView, component: ScheduleViewLayout },
   { path: routes.customers.StatementOfWorkView, component: StatementOfWorkLayout },
]

export const ClearLayout: React.FC = () => {
   return (
      <Switch>
         {routesList.map(({ private: _, ...route }) => (
            <Route key={route.path} {...route} />
         ))}
         <Redirect to={routes.auth.Login} />
      </Switch>
   )
}
