import React, { useEffect, useCallback, useState, useContext } from "react";
import {
  Form,
  Input,
  FormInstance,
  Dropdown,
  Menu,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  InputNumber,
  notification,
  Button,
  Popover,
} from "antd";
import { SelectCard, SelectCardTitle, SuccessButton } from "../styled";
import { EmptyButton } from "../../../ui/Button";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { TCallback, TArgCallback } from "../../../types";
import {
  EJobProgress,
  ERecurringStatus,
  IJob,
  IWorkOrder,
  IWorkOrderWithJobs,
} from "../../../api/types";
import styled from "styled-components";
import { PaymentsModalList } from "./PaymentsModalList";
import PaymentsModal from "../../../modals/Payments/PaymentsModal";
import { SendEstimateModal } from "../../jobs/SendEstimateModal";
import { DownOutlined } from "@ant-design/icons";
import { useResponsive } from "../../../utils/hooks";
import { Api, TRecurringPricing } from "../../../api/api";
import moment from "moment";
import {
  formatToUSD,
  getErrors,
  showErrors,
  showNonFieldsErrors,
} from "../../../utils/utils";
import CompleteJobModal from "./CompleteJobModal";
import ShareSettingsModal from "./ShareSettingsModal";
import { RecurringBillingWarningModal } from "../../jobs/RecurringBillingWarningModal";
import { getCurrentUser } from "../../../config/reducers/user/selectors";
import { useAppSelector } from "../../../config/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../../config/reducers/user/actions";
import { useLocation, useHistory } from "react-router-dom";
import { WOContext } from "../WorkOrderContext";
import {
  EditIcon,
  WithoutApprovalIcon,
  RecordPaymentIcon,
  DraftIcon,
  ManageSharingIcon,
  PrintIcon,
  RequestApprovalIcon,
  RequestPaymentIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "../../../assets/icons";
import { order } from "styled-system";
import { RequestPaymentModal } from "../../jobs/RequestPaymentModal";
import { RPMWarningModal } from "../../jobs/RPMWarningModal";
import { checkIsPaymentsActive } from "../../jobs/EstimateConfig";
import {
  BillingIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
} from "../../../assets/icons/workOrderMenuBtns";
import BillingHistory from "../../jobs/BillingHistory";
import routes from "../../../routes/routes";
const { TextArea } = Input;

type TProps = {
  form: FormInstance;
  recurringWOForm: FormInstance;
  formSP: FormInstance;
  onAddJob: TCallback;
  saving: boolean;
  workOrder: IWorkOrder | null;
  jobs: IJob[];
  loadData: any;
  technicianRole: boolean;
  handlePrint: TCallback;
  // isRecurring: boolean;
  openRecurringModal: () => void;
  setWorkOrder: TArgCallback<any>;
  setTitle: TArgCallback<any>;
  handleSaveWorkOrder: TArgCallback<any>;
  isCreatePage: boolean;
  orderNumber: string | "";
  title: string;
  proceedWithoutApproval: () => void;
  showSaveModal: (value: boolean) => void;
  saveChangesModal: boolean;
};

const StyledMenu = styled(Menu)`
  li {
    white-space: normal;
  }
`;
const StyledDropdown = styled(Dropdown.Button)`
  flex-grow: 1;
  width: auto;
  border-radius: 6px;
  .ant-btn.ant-btn-primary {
    height: 40px;
    &:first-child {
      width: 100%;
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
`;
const PrintBtn = styled(Button)`
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
`;
const Details = styled.div`
  margin-bottom: 15px;
`;
const DetailsItem = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  & .title {
    color: #494949;
    font-weight: normal;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  overflow: hidden;
`;
const DetailsDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #ededed;
  margin: 10px 0;
`;
const StyledTextArea = styled(TextArea)`
  max-height: 200px;
  overflow-y: auto;
  resize: none;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #109cf1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const ViewAllBtn = styled(EmptyButton)`
  color: #0496ff;
  margin-right: 7px;
  height: auto;
  > span {
    text-decoration: underline;
    font-weight: bold;
  }
`;
const ButtonGreen = styled(EmptyButton)`
  color: #fff;
  background: #42c77b;
  border: 1px solid #42c77b;
  padding: 4px 15px;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
  }
  &:hover,
  &:focus {
    background: #42c77b !important;
    border: 1px solid #42c77b !important;
    color: #fff !important;
  }
`;
const StyledPopover = styled.div`
  position: relative;
  & .ant-popover {
    z-index: 1 !important;
  }
  & .ant-popover-inner-content {
    padding: 0;
  }
  & .ant-popover-arrow {
    display: none;
  }
  & .ant-popover-placement-bottomRight {
    padding-top: 0;
  }
`;
const ButtonsMenu = styled(Flex)`
  border: 1px solid #0496ff;
  border-radius: 4px;
  overflow: hidden;
  button {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #0496ff;
    height: 38px;
    color: #0496ff;
    svg {
      margin-right: 5px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;
const SaveNoteButton = styled(Msg)<TSaveNoteButtonProps>`
  text-align: right;
  cursor: pointer;
  color: ${(props) => (props.isDisabled ? "#ACE0FF" : "#0496FF")};
`;

const SaveTitleButton = styled(EmptyButton)`
  color: #0496ff;
  font-weight: 600;
`;

type TSaveNoteButtonProps = {
  isDisabled: boolean;
};

enum EDepositTypes {
  None,
  Quarter,
  Half,
  Custom,
}

const checkIsDepositPaid = (workOrder: IWorkOrder | null) =>
  !!workOrder?.required_deposit_amount &&
  workOrder?.deposited >= workOrder?.required_deposit_amount;

const JobInfo: React.FC<{
  workOrder: IWorkOrder;
  loadData: any;
  technicianRole: boolean;
  isRecurring: boolean;
}> = ({ workOrder, loadData, technicianRole, isRecurring }) => {
  const {
    notes,
    required_deposit_amount,
    estimated_duration,
    deposited,
    total,
    additional_payment_amount,
  } = workOrder;
  const userData = useAppSelector(getCurrentUser);
  const defaultDeposit = userData?.company?.default_deposit;
  const [visible, setVisible] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(
    defaultDeposit ? defaultDeposit.amount : null
  );
  const dispatch = useDispatch();
  const [isEdited, setIsEdited] = useState(false);
  const [notesText, setNotesText] = useState(notes || "");
  const [isDefaultDeposit, setIsDefaultDeposit] = useState<boolean>(false);
  const [pricing, setPricing] = useState<TRecurringPricing>({
    payment: 0,
    repeats: 0,
    total: 0,
  });
  const [depositType, setDepositType] = useState<EDepositTypes>(() => {
    if (defaultDeposit?.percentage === 0 && defaultDeposit?.amount === 0) {
      return EDepositTypes.None;
    } else if (defaultDeposit?.percentage === 50) {
      return EDepositTypes.Half;
    } else if (defaultDeposit?.percentage === 25) {
      return EDepositTypes.Quarter;
    } else if (defaultDeposit?.amount && defaultDeposit?.amount > 0) {
      setAmount(defaultDeposit?.amount);
      return EDepositTypes.Custom;
    }
    return EDepositTypes.Half;
  });

  const handleChangeDepositType = (type: RadioChangeEvent) => {
    setDepositType(type.target.value);
    switch (type.target.value) {
      case EDepositTypes.None:
        setPercentage(0);
        setAmount(null);
        break;
      case EDepositTypes.Quarter:
        setPercentage(25);
        setAmount(null);
        break;
      case EDepositTypes.Half:
        setPercentage(50);
        setAmount(null);
        break;
      case EDepositTypes.Custom:
        setPercentage(null);
        break;
      default:
        break;
    }
  };
  const setPrice = async () => {
    if (workOrder?.recurring_config) {
      const { data: _data } = await Api.workOrders.getRecurringCalculations({
        ...workOrder.recurring_config,
        id: workOrder.id,
      });
      setPricing(_data);
    }
  };
  console.log(pricing);
  const handleSubmit = async () => {
    if (isDefaultDeposit) {
      try {
        await Api.workOrders.setDefaultDeposit({ amount, percentage });
        const newUser = await (await Api.user.profile()).data;
        await dispatch(setCurrentUser(newUser));
      } catch (e: any) {
        notification.error({ message: getErrors(e)[0].errors.join(",") });
      }
    }
    try {
      await Api.workOrders.setDeposit({ amount, percentage, id: workOrder.id });
      setVisible(false);
      await loadData();
    } catch (e: any) {
      notification.error({ message: getErrors(e)[0].errors.join(",") });
    }
  };

  const handeTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value !== notes && !isEdited) {
      setIsEdited(true);
    } else if (e.target.value === notes && isEdited) {
      setIsEdited(false);
    }
    setNotesText(e.target.value);
  };

  const handleSaveNotes = async () => {
    if (isEdited) {
      await Api.workOrders.saveNotes({ id: workOrder.id, note: notesText });
      setIsEdited(false);
    }
  };
  useEffect(() => {
    setPrice().then();
  }, [workOrder]);

  return (
    <div>
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <SelectCardTitle>Project details</SelectCardTitle>
        {!technicianRole && (
          <SaveNoteButton isDisabled={!isEdited} onClick={handleSaveNotes}>
            Save
          </SaveNoteButton>
        )}
      </Flex>
      <Flex flexDirection="column">
        {technicianRole ? (
          <p>{notesText}</p>
        ) : (
          <StyledTextArea
            rows={isRecurring ? 9 : 4}
            value={notesText}
            onChange={handeTextAreaChange}
            placeholder="No notes provided"
          />
        )}

        <Flex justifyContent="space-between" alignItems="center">
          <Msg fontSize="18px" fontWeight="700" color="#828282">
            Estimated Duration
          </Msg>
          <Msg
            fontSize="18px"
            fontWeight="700"
            color="#109CF1"
            style={{ textDecoration: "underline" }}
          >
            {estimated_duration ?? "TBD"}
          </Msg>
        </Flex>
        {!technicianRole && (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginY="5px"
            >
              <Msg color="#828282" fontWeight="700" fontSize="22px">
                Grand Total
              </Msg>
              <Msg fontWeight={700} fontSize="22px">
                {formatToUSD(isRecurring ? pricing.total : total)}
              </Msg>
            </Flex>
            {!isRecurring && (
              <>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="12px"
                >
                  <Msg color="#828282">Paid to-Date</Msg>
                  <Msg fontWeight={700}>{formatToUSD(deposited)}</Msg>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="12px"
                >
                  <Msg color="#828282">Currently Due</Msg>
                  <Msg fontWeight={700}>
                    {formatToUSD(additional_payment_amount ?? 0)}
                  </Msg>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="12px"
                >
                  <Msg color="#828282">Remaining</Msg>
                  <Msg fontWeight={700}>{formatToUSD(total - deposited)}</Msg>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="12px"
                >
                  <Modal
                    visible={visible}
                    onOk={handleSubmit}
                    onCancel={() => setVisible(false)}
                    title="AdD Deposit amount"
                    width="384px"
                    okText="Save"
                    footer={
                      <>
                        <Radio
                          onClick={() => {
                            setIsDefaultDeposit(!isDefaultDeposit);
                          }}
                          checked={isDefaultDeposit}
                          style={{
                            marginRight: "85px",
                            color: !isDefaultDeposit ? "#0496FF" : "#C2CFE0",
                            textDecoration: "underline",
                          }}
                        >
                          Set as default
                        </Radio>

                        <Button onClick={() => setVisible(false)}>
                          Cancel
                        </Button>
                        <Button type={"primary"} onClick={handleSubmit}>
                          Save
                        </Button>
                      </>
                    }
                  >
                    <div>
                      <Radio.Group
                        value={depositType}
                        onChange={handleChangeDepositType}
                      >
                        <Space direction="vertical">
                          <Radio value={EDepositTypes.None}>
                            None{" "}
                            {defaultDeposit?.percentage === 0 &&
                              defaultDeposit?.amount === 0 && <>(Default)</>}
                          </Radio>
                          <Radio value={EDepositTypes.Quarter}>
                            25%
                            {defaultDeposit?.percentage === 25 && (
                              <>(Default)</>
                            )}
                          </Radio>
                          <Radio value={EDepositTypes.Half}>
                            50%
                            {defaultDeposit?.percentage === 50 && (
                              <>(Default)</>
                            )}
                          </Radio>
                          <Radio value={EDepositTypes.Custom}>
                            Custom amount{" "}
                            {defaultDeposit?.amount &&
                              defaultDeposit?.amount > 0 && <>(Default)</>}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </div>
                    <InputNumber
                      disabled={depositType !== EDepositTypes.Custom}
                      value={`${amount ?? 0}`}
                      onChange={(e) => setAmount(+e)}
                      min="0"
                      formatter={(value) => `$ ${value}`}
                      step="0.01"
                      stringMode
                      style={{ width: "260px", margin: "8px 0 16px" }}
                    />
                  </Modal>

                  <Flex flexDirection="row">
                    {checkIsDepositPaid(workOrder) ? (
                      <Msg color="#8B8B8B" fontWeight="400" marginRight="15px">
                        (Deposit)
                      </Msg>
                    ) : (
                      <Msg
                        color="#0496FF"
                        fontWeight="700"
                        marginRight="12px"
                        onClick={() => setVisible(true)}
                      >
                        (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Deposit
                        </span>
                        )
                      </Msg>
                    )}
                    {/* {required_deposit_amount ? (
                                 deposited < required_deposit_amount ? (
                                    <Msg color="#FF8283">(Unpaid)</Msg>
                                 ) : (
                                    <Msg color="#42C57A">{`(paid${
                                       deposit_paid_at ? ` - ${moment(deposit_paid_at).format("DD/MM/YY")}` : ""
                                    })`}</Msg>
                                 )
                              ) : null} */}
                  </Flex>

                  <Msg
                    color={
                      deposited < required_deposit_amount
                        ? "#FF8283"
                        : "#42C57A"
                    }
                    fontWeight={700}
                  >
                    ${required_deposit_amount.toFixed(2) || 0}
                  </Msg>
                </Flex>
                {workOrder.processing_intent && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb="12px"
                  >
                    <Msg color="#FF7A00">Processing Payment</Msg>
                    <Msg color="#FF7A00" fontWeight={700}>
                      ${workOrder.processing_intent.toFixed(2) || 0}
                    </Msg>
                  </Flex>
                )}
                {/* <Flex justifyContent="space-between" alignItems="center">
                           <Msg color="#828282">Additional Payments</Msg>
                           <Msg fontWeight={700}>
                              $
                              {(deposited > required_deposit_amount ? deposited - required_deposit_amount : 0).toFixed(
                                 2
                              )}
                           </Msg>
                        </Flex> */}
              </>
            )}
          </>
        )}
      </Flex>
    </div>
  );
};

export const AddJob: React.FC<TProps> = ({
  form,
  formSP,
  recurringWOForm,
  onAddJob,
  saving,
  workOrder,
  jobs,
  loadData,
  technicianRole,
  handlePrint,
  // isRecurring,
  openRecurringModal,
  setWorkOrder,
  handleSaveWorkOrder,
  isCreatePage,
  orderNumber,
  setTitle,
  title,
  proceedWithoutApproval,
  showSaveModal,
  saveChangesModal,
}) => {
  const [paymentsModalVisible, setPaymentsModalVisible] = useState(false);
  const [sendEstimateModalVisible, setSendEstimateModalVisible] =
    useState(false);
  const [requestPaymentModalVisible, setRequestPaymentModalVisible] =
    useState(false);
  const [billingHistoryModalVisible, setBillingHistoryModalVisible] =
    useState(false);
  const [RPMWarningVisible, setRPMWarningVisible] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [shareSettingsModal, setShareSettingsModal] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const userData = useSelector(getCurrentUser);
  const { width } = useResponsive();
  const location = useLocation();
  const history = useHistory();
  const user = useAppSelector(getCurrentUser);
  const [jobTitle, setJobTitle] = useState(title);

  const Context = useContext(WOContext);

  useEffect(() => {
    const type = location?.state?.type;
    if (!(type && workOrder && jobs)) return;
    if (type === "approval") {
      setSendEstimateModalVisible(true);
    } else if (type === "request_payment") {
      const isActive = checkIsPaymentsActive(user);
      if (!isActive) {
        setRPMWarningVisible(true);
        history.replace();
        return;
      }
      openRequestPaymentModal();
    } else if (type === "record_payment") {
      setSendEstimateModalVisible(true);
    } else if (type === "print") {
      handlePrint();
    } else if (type === "manage_sharing") {
      setShareSettingsModal(true);
    } else if (type === "proceed_without_approval") {
      proceedWithoutApproval();
    } else if (type.includes('tab_')) {
      return;
   }
    history.replace();
  }, [location, workOrder, jobs]);

  const changeTitle = async () => {
    if (jobTitle && workOrder?.id && jobTitle !== workOrder?.title) {
      const res = await Api.workOrders.changeTitle({
        id: workOrder.id,
        title: jobTitle,
      });
      const wo = (await res.data) as IWorkOrderWithJobs;
      setWorkOrder(wo);
      setTitle(jobTitle);
    } else if (location.pathname === routes.workOrder.CreateWorkOrder) {
      setTitle(jobTitle)
    }
    setIsEditing(false);
  };

  const isAllJobsApproved = () =>
    jobs.some((j) => j.progress === EJobProgress.Estimate);
  const isReadyToRequest = () =>
    jobs.every((j) => j.progress !== EJobProgress.Estimate) && !!jobs.length;
  const isAllJobsCompleted = () =>
    jobs.every((j) => j.progress === EJobProgress.Complete);

  const checkBillingDate = () => {
    const billingDate = workOrder?.recurring_config?.first_billing_date;
    const startDate = workOrder?.recurring_config?.first_appointment_date;
    if (!billingDate || !startDate) {
      return false;
    }
    return moment(startDate).isBefore(billingDate);
  };

  const handleSendToClient = () => {
    if (checkBillingDate()) {
      setShowBillingModal(true);
      return;
    }
    setSendEstimateModalVisible(true);
  };

  const handleBillingModalOk = () => {
    setShowBillingModal(false);
    setSendEstimateModalVisible(true);
  };

  const handleBillingModalCancel = () => {
    setShowBillingModal(false);
  };

  const DynamicButton: React.FC<{
    text: string;
    onClickHandler: () => any;
    disabled?: boolean;
  }> = ({ text, onClickHandler, disabled }) => {
    return (
      <StyledDropdown
        overlay={() => (
          <StyledMenu>
            {workOrder?.is_recurring &&
              workOrder.recurring_status === ERecurringStatus.Approved && (
                <Menu.Item key="stop_recurring" onClick={openRecurringModal}>
                  Stop Service Plan
                </Menu.Item>
              )}
            {!workOrder?.is_recurring && (
              <Menu.Item
                key="record_payment"
                onClick={() => {
                  setPaymentsModalVisible(true);
                }}
              >
                Record Payment
              </Menu.Item>
            )}
            {isRequest && !workOrder?.is_recurring && (
              <Menu.Item
                key="request_payment"
                onClick={() => {
                  setRequesting(true);
                  setSendEstimateModalVisible(true);
                }}
              >
                Request Payment
              </Menu.Item>
            )}
            <Menu.Item key="print_work_order" onClick={handlePrint}>
              Print Work Order
            </Menu.Item>
            <Menu.Item
              key="manage_share_settings"
              onClick={() => setShareSettingsModal(true)}
            >
              Manage Sharing
            </Menu.Item>
          </StyledMenu>
        )}
        type="primary"
        onClick={onClickHandler}
        icon={<DownOutlined />}
        disabled={!!disabled}
      >
        {text}
      </StyledDropdown>
    );
  };

  useEffect(() => {
    const _isRequest =
      checkIsDepositPaid(workOrder) ||
      (isReadyToRequest() && workOrder?.required_deposit_amount === 0) ||
      (isAllJobsCompleted() &&
        (workOrder?.deposited ?? 0) < (workOrder?.total ?? 0));
    setIsRequest(_isRequest);
  }, [workOrder, jobs]);

  const getButtons = () => {
    if (!jobs.length && !workOrder?.is_recurring) return null;
    if (workOrder?.is_recurring) {
      if (workOrder.recurring_status === ERecurringStatus.Approved) {
        return (
          <DynamicButton
            text="Complete job"
            onClickHandler={() => setCompleteModalVisible(true)}
          />
        );
      }
      return (
        <DynamicButton
          text="Send to client"
          disabled={workOrder.recurring_status !== ERecurringStatus.Draft}
          onClickHandler={handleSendToClient}
        />
      );
    }
    if (isAllJobsApproved()) {
      return (
        <DynamicButton
          text="Send Estimate"
          onClickHandler={() => {
            setRequesting(false);
            setIsInvoice(false);
            setSendEstimateModalVisible(true);
          }}
        />
      );
    }
    if (isAllJobsCompleted()) {
      return (
        <DynamicButton
          text="Send Invoice"
          onClickHandler={() => {
            setRequesting(false);
            setIsInvoice(true);
            setSendEstimateModalVisible(true);
          }}
        />
      );
    }
    return (
      <DynamicButton
        text="Edit Estimate"
        onClickHandler={() => {
          setRequesting(false);
          setIsInvoice(false);
          setSendEstimateModalVisible(true);
        }}
      />
    );
  };

  const openRequestPaymentModal = () => {
    setRequestPaymentModalVisible(true);
  };
  const handleAction = (actionType: any) => {
    if (actionType.type === "record_payment") {
      setPaymentsModalVisible(true);
    }
    if (actionType.type === "approval") {
      setSendEstimateModalVisible(true);
    }
    if (actionType.type === "print") {
      handlePrint();
    }
    if (actionType.type === "manage_sharing") {
      setShareSettingsModal(true);
    }
    if (actionType.type === "proceed_without_approval") {
      proceedWithoutApproval();
    }

    Context.setJActionType({});
  };

  useEffect(() => {
    if (
      Context.actionType.type &&
      !saveChangesModal &&
      Context.jobs.length === 0
    ) {
      handleAction(Context.actionType);
    }
  }, [Context.actionType, saveChangesModal]);

  const closeRequestPaymentModal = useCallback(
    (update?: boolean) => {
      setRequestPaymentModalVisible(false);
      if (update) {
        loadData();
      }
    },
    [setRequestPaymentModalVisible]
  );

  const closeRPMWarningModal = useCallback(() => {
    setRPMWarningVisible(false);
  }, [setRPMWarningVisible]);

  const handleRequestApproval = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("approval");
    } else if (Context.jobs.length > 0) {
      showSaveModal(true);
      Context.setJActionType({
        type: "approval",
      });
    } else {
      setSendEstimateModalVisible(true);
    }
  };

  const handleRequestPayment = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("request_payment");
    } else {
      const isActive = checkIsPaymentsActive(user);
      if (!isActive) {
        setRPMWarningVisible(true);
        return;
      }
      openRequestPaymentModal();
    }
  };

  const handleRecordPayment = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("record_payment");
    } else if (Context.jobs.length > 0) {
      showSaveModal(true);
      Context.setJActionType({
        type: "record_payment",
      });
    } else {
      setPaymentsModalVisible(true);
    }
  };

  const handleSaveAsDraft = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("save_draft");
    } else if (Context.jobs.length > 0) {
      Context.setJActionType({
        type: "save_draft",
      });
    }
  };

  const handlePrintClick = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("print");
    } else if (Context.jobs.length > 0) {
      showSaveModal(true);
      Context.setJActionType({
        type: "print",
      });
    } else {
      handlePrint();
    }
  };

  const handleManageSharing = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("manage_sharing");
    } else if (Context.jobs.length > 0) {
      showSaveModal(true);
      Context.setJActionType({
        type: "manage_sharing",
      });
    } else {
      setShareSettingsModal(true);
    }
  };

  const handleProceedWithoutApproval = () => {
    if (isCreatePage) {
      handleSaveWorkOrder("proceed_without_approval");
    } else if (Context.jobs.length > 0) {
      showSaveModal(true);
      Context.setJActionType({
        type: "proceed_without_approval",
      });
    } else {
      proceedWithoutApproval();
    }
  };

  const openBillingHistory = () => {
    setBillingHistoryModalVisible(true);
  };

  const closeBillingHistory = () => {
    setBillingHistoryModalVisible(false);
  };

  const handleSave = async (onFinish: TCallback) => {
    try {
      await formSP.validateFields();
      let data = formSP.getFieldsValue();
      if (data?.estimated_duration) {
        data = {
          ...data,
          estimated_duration: moment(data.estimated_duration).format(
            "HH:mm:ss"
          ),
        };
      }
      if (!data.services?.length && data?.temp_service) {
        const { temp_service, ..._jobData } = data;
        const _services = !!temp_service.name ? [{ ...temp_service }] : [];
        data = { ..._jobData, services: _services };
      }

      if (!data.materials?.length && data?.temp_material) {
        const { temp_material, ..._jobData } = data;
        const _materials = !!temp_material.name ? [{ ...temp_material }] : [];
        data = { ..._jobData, materials: _materials };
      }
      recurringWOForm.submit();
      const fields = await recurringWOForm.validateFields();
      if (workOrder) {
        const {
          start_end_time,
          first_appointment_date,
          final_appointment_date,
          first_billing_date,
          ..._data
        } = fields;
        const formatedData = {
          ..._data,
          start_time: start_end_time[0]
            ? start_end_time[0].format("HH:mm")
            : start_end_time[0],
          end_time: start_end_time[1]
            ? start_end_time[1].format("HH:mm")
            : start_end_time[1],
          first_appointment_date: first_appointment_date.format("YYYY-MM-DD"),
          final_appointment_date: final_appointment_date.format("YYYY-MM-DD"),
          first_billing_date: first_billing_date.format("YYYY-MM-DD"),
          id: workOrder?.id ?? 0,
          ...(title && { title: title }),
        };
        await Api.workOrders.setRecurringConfig(formatedData);
        await Api.workOrders.patch({ ...data, id: workOrder?.id });
        notification.success({ message: "Successfully saved" });
        await loadData();
        await onFinish();
      }
    } catch (err: any) {
      if (err?.config?.url.includes("set_recurring_config")) {
        const configErrors = getErrors(err).map((e) =>
          !(e.name.includes("start_time") || e.name.includes("end_time"))
            ? e
            : { ...e, name: ["start_end_time"] }
        );
        recurringWOForm.setFields(configErrors);
        showNonFieldsErrors(configErrors);
        return;
      }
      showErrors(getErrors(err));
    }
  };

  const handleRequestApprovalSP = () => {
    handleSave(handleSendToClient);
  };

  const contentButtons = (
    <ButtonsMenu flexDirection="column">
      <Button onClick={handleProceedWithoutApproval}>
        <Flex alignItems="center">
          <WithoutApprovalIcon /> Proceed without Approval
        </Flex>
      </Button>
      <Button onClick={handleRecordPayment}>
        <Flex alignItems="center">
          <RecordPaymentIcon /> Record Payment Manually
        </Flex>
      </Button>
      <Button onClick={handleSaveAsDraft}>
        <Flex alignItems="center">
          <DraftIcon /> Save as a Draft
        </Flex>
      </Button>
      <Button onClick={handlePrintClick}>
        <Flex alignItems="center">
          <PrintIcon /> Print Project
        </Flex>
      </Button>
      <Button onClick={handleManageSharing}>
        <Flex alignItems="center">
          <ManageSharingIcon /> Manage Sharing
        </Flex>
      </Button>
    </ButtonsMenu>
  );

  const SPContentButtons = (
    <ButtonsMenu flexDirection="column">
      <Button onClick={openRecurringModal}>
        <Flex alignItems="center">
          {workOrder?.recurring_status === ERecurringStatus.Approved ? (
            <CloseIcon />
          ) : (
            <DeleteIcon />
          )}
          {workOrder?.recurring_status === ERecurringStatus.Approved
            ? "Stop Service Plan"
            : "Delete Service Plan"}
        </Flex>
      </Button>
      <Button onClick={handleSaveAsDraft}>
        <Flex alignItems="center">
          <DraftIcon /> Save as a Draft
        </Flex>
      </Button>
      <Button onClick={handlePrintClick}>
        <Flex alignItems="center">
          <PrintIcon /> Print Service Plan
        </Flex>
      </Button>
      <Button onClick={handleManageSharing}>
        <Flex alignItems="center">
          <ManageSharingIcon /> Manage Sharing
        </Flex>
      </Button>
      {workOrder?.recurring_status !== ERecurringStatus.Draft && (
        <Button onClick={openBillingHistory}>
          <Flex alignItems="center">
            <BillingIcon /> Billing History
          </Flex>
        </Button>
      )}
      {/* <Button onClick={handlePrintClick}>
            <Flex alignItems="center">
               <PrintIcon /> Print Subjob
            </Flex>
         </Button> */}
    </ButtonsMenu>
  );

  const getMainButton = () => {
    if (workOrder?.is_recurring) {
      if (workOrder.recurring_status === ERecurringStatus.Approved) {
        return (
          <Button
            onClick={() => setCompleteModalVisible(true)}
            type="primary"
            style={{ height: "38px", width: "100%" }}
            // disabled={workOrder.recurring_status !== ERecurringStatus.Draft}
          >
            <Flex alignItems="center" style={{ gap: "5px" }}>
              <CheckIcon /> Complete Job
            </Flex>
          </Button>
        );
      }
      return (
        <Button
          onClick={handleRequestApprovalSP}
          type="primary"
          style={{ height: "38px", width: "100%" }}
          // disabled={workOrder.recurring_status !== ERecurringStatus.Draft}
        >
          <Flex alignItems="center" style={{ gap: "5px" }}>
            <RequestApprovalIcon /> Request Approval
          </Flex>
        </Button>
      );
    }
    return (
      <Button
        onClick={handleRequestApproval}
        type="primary"
        style={{ height: "38px", width: "100%" }}
      >
        <Flex alignItems="center" style={{ gap: "5px" }}>
          <RequestApprovalIcon /> Request Approval
        </Flex>
      </Button>
    );
  };

  // console.log("form", form.getFieldsValue())
  return (
    <SelectCard justifyContent="space-between" style={{ alignSelf: "normal" }}>
      <>
        <SelectCardTitle>Details</SelectCardTitle>
        <Details>
          <DetailsItem>
            <div className="title" style={{ minWidth: "80px" }}>
              Job name:
            </div>

            <div>
              <Form form={form}>
                {!isEditing ? (
                  <Flex flexDirection="row" maxWidth="100%" alignItems="center">
                    {!technicianRole && (
                      <Button
                        type="text"
                        style={{
                          padding: "0",
                          marginRight: "5px",
                          height: "16px",
                        }}
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    )}

                    <div>{title}</div>
                  </Flex>
                ) : (
                  <Form.Item name="title">
                    <Input
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      suffix={
                        <SaveTitleButton onClick={changeTitle}>
                          Save
                        </SaveTitleButton>
                      }
                    />
                  </Form.Item>
                )}
              </Form>
            </div>
          </DetailsItem>
          <DetailsItem>
            <div className="title">Created Date:</div>
            <div>
              {workOrder?.created
                ? moment(workOrder.created).format("MM/D/YY")
                : moment(new Date()).format("MM/D/YY")}
            </div>
          </DetailsItem>
          <DetailsItem>
            <div className="title">Created by:</div>
            <div>
              {workOrder?.created_by
                ? workOrder?.created_by.full_name
                : userData?.full_name}
            </div>
          </DetailsItem>
          {!technicianRole && (
            <>
              <DetailsDivider />
              <DetailsItem>
                <div className="title">Project Total:</div>
                <div>{formatToUSD(workOrder?.total)}</div>
              </DetailsItem>
              <DetailsItem>
                <div className="title">Paid-to-Date:</div>
                <div style={{ textAlign: "right" }}>
                  {workOrder && (
                    <ViewAllBtn onClick={() => setShowPaymentsModal(true)}>
                      (View all)
                    </ViewAllBtn>
                  )}

                  {formatToUSD(workOrder?.deposited)}
                </div>
                <PaymentsModalList
                  workOrder={workOrder}
                  visible={showPaymentsModal}
                  onClose={() => setShowPaymentsModal(false)}
                />
              </DetailsItem>
            </>
          )}
        </Details>
      </>

      {!technicianRole && (
        <>
          <Flex
            flexWrap="wrap"
            marginTop="auto"
            alignItems={width > 576 ? "center" : "stretch"}
            justifyContent="space-between"
            flexDirection={width > 576 ? "row" : "column"}
            style={{ gap: "10px" }}
          >
            <Flex flexDirection="column" style={{ gap: "10px", width: "100%" }}>
              <Flex
                style={{ gap: "10px", width: "100%" }}
                justifyContent="space-between"
              >
                {getMainButton()}
                <StyledPopover id="popover_wrapper">
                  <Popover
                    placement="bottomRight"
                    content={
                      workOrder?.is_recurring
                        ? SPContentButtons
                        : contentButtons
                    }
                    trigger="click"
                    getPopupContainer={() =>
                      document.getElementById("popover_wrapper") as HTMLElement
                    }
                    onVisibleChange={(visible) => setOpenMenu(visible)}
                  >
                    <Button type="primary" style={{ height: "38px" }}>
                      {openMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Button>
                  </Popover>
                </StyledPopover>
              </Flex>
              {!workOrder?.is_recurring && (
                <ButtonGreen
                  style={{ height: "38px", width: "100%" }}
                  onClick={handleRequestPayment}
                >
                  <Flex alignItems="center" style={{ gap: "5px" }}>
                    <RequestPaymentIcon /> Request Payment
                  </Flex>
                </ButtonGreen>
              )}
            </Flex>
          </Flex>
          <PaymentsModal
            visible={paymentsModalVisible}
            onClose={() => setPaymentsModalVisible(false)}
            workOrder={workOrder}
            loadData={loadData}
          />
          <SendEstimateModal
            isInvoice={isInvoice}
            visible={sendEstimateModalVisible}
            onClose={() => setSendEstimateModalVisible(false)}
            workOrderNumber={workOrder?.id}
            workOrder={workOrder}
            isRequest={requesting}
            isEstimate={isAllJobsApproved()}
            isRecurring={!!workOrder?.is_recurring}
            jobs={jobs}
          />
          <RequestPaymentModal
            visible={requestPaymentModalVisible}
            onClose={closeRequestPaymentModal}
            workOrder={workOrder}
            jobs={jobs}
          />
          <RPMWarningModal
            visible={RPMWarningVisible}
            onClose={closeRPMWarningModal}
          />
          <CompleteJobModal
            jobs={jobs}
            onClose={() => setCompleteModalVisible(false)}
            visible={completeModalVisible}
            loadData={loadData}
          />
          {!!(workOrder && workOrder.recurring_config) && (
            <RecurringBillingWarningModal
              visible={showBillingModal}
              id={workOrder.id}
              billingDate={workOrder.recurring_config.first_billing_date}
              onCancel={handleBillingModalCancel}
              onOk={handleBillingModalOk}
            />
          )}
          <ShareSettingsModal
            visible={shareSettingsModal}
            onClose={() => setShareSettingsModal(false)}
            workOrderNumber={workOrder?.id}
          />
          {!!(
            workOrder?.is_recurring &&
            workOrder?.recurring_status !== ERecurringStatus.Draft
          ) && (
            <BillingHistory
              visible={billingHistoryModalVisible}
              onClose={closeBillingHistory}
              workOrderId={workOrder?.id}
            />
          )}
        </>
      )}
      {technicianRole && (
        <Flex alignItems="center" justifyContent="center">
          <PrintBtn onClick={handlePrint}>Print Work Order</PrintBtn>
        </Flex>
      )}
    </SelectCard>
  );
};
