import React from "react";
import styled from "styled-components";
import { PALETTE } from "../../constants";
import Flex from "../../noui/Flex";
import Hr from "../../ui/Hr";
import { Msg } from "../../ui/Text";
import { ReactComponent as CheckDefault } from "../../assets/images/CheckDefault.svg";
import { ReactComponent as CheckActive } from "../../assets/images/CheckActive.svg";
import { Button } from "antd";
import { down } from "styled-breakpoints";

import { useSelector } from "react-redux";
import { getPaymentData } from "../../config/reducers/payment/paymentSlice";

type TStyledProps = {
  isActive: boolean;
  isMonthly?: boolean;
};

const SubscriptionCardBlock = styled(Flex)`
  background: ${(props: TStyledProps) =>
    props.isActive ? PALETTE.Primary.Base : "#FFFFFF"};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: ${(props: TStyledProps) =>
    props.isMonthly ? "0 2% 24px" : "35px 2% 24px"};
  flex-direction: column;
  align-items: center;
  width: calc(22% + 24px);
  max-width: 340px;
  margin: 0 2% 0 0;
  <<<<<<< HEAD =======>>>>>>>dev &:last-child {
    margin: 0;
  }
  ${down("lg")} {
    margin: 0 0 16px;
    width: 100%;
  }
`;

export const SubscriptionCardTitle = styled(Msg)`
  color: ${(props: TStyledProps) =>
    props.isActive ? "#EBF1F6" : PALETTE.Text};
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const SubscriptionCardDefaultText = styled(Msg)`
  color: ${(props: TStyledProps) =>
    props.isActive ? "#FFFFFF" : PALETTE.Text};
`;

export const SubscriptionCardPriceText = styled(Msg)`
  color: ${(props: TStyledProps) =>
    props.isActive ? "#FFFFFF" : PALETTE.Primary.Base};
`;
export const SubscriptionCardPriceSubText = styled(Msg)`
  color: #ace0ff;
  font-size: 13px;
  margin-top: -20px;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;
    width: 200px;
    background: ${PALETTE.Secondary.Medium};
    border: ${PALETTE.Secondary.Medium};
    color: #FFFFFF;
    &:hover {
        background: ${PALETTE.Secondary.Medium};
        border: ${PALETTE.Secondary.Medium};
        color: #FFFFFF;
    }
    &:focus {
        background: #096DD9;
        color: #EBF1F6;
    }
    &[disabled], &[disabled]: hover {
        background: #096DD9;
        color: #EBF1F6;
    }
}
`;

export const SpinWrapper = styled(Flex)`
  width: 100%;
  > div {
    width: 100%;
  }
`;
export const MonthlyOnboardingContainer = styled.div`
  padding: 6px 19px;
  width: 202px;
  background: #fb4d4f;
  border-radius: 6px;
  color: #ffffff;
`;

type TProps = {
  isActive: boolean;
  title: string;
  monthly_price: number;
  annually_price: number;
  description: string;
  options: string;
  setActive: () => void;
  onboarding_fee: number;
};

const SubscriptionCard: React.FC<TProps> = ({
  title,
  monthly_price,
  description,
  annually_price,
  isActive,
  options,
  setActive,
  onboarding_fee,
}) => {
  const { payment_period } = useSelector(getPaymentData);
  return (
    <SubscriptionCardBlock
      isActive={isActive}
      isMonthly={payment_period === "M"}
    >
      {payment_period === "M" && (
        <MonthlyOnboardingContainer>
          Onboarding fee $
          {onboarding_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </MonthlyOnboardingContainer>
      )}

      <SubscriptionCardTitle isActive={isActive}>{title}</SubscriptionCardTitle>
      <Flex alignItems="baseline">
        <SubscriptionCardPriceText
          isActive={isActive}
          fontSize="22px"
          fontWeight="700"
        ></SubscriptionCardPriceText>
        <SubscriptionCardPriceText
          isActive={isActive}
          fontSize="52px"
          fontWeight="800"
        >
          $
          {payment_period === "M"
            ? monthly_price
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : (annually_price / 12)
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </SubscriptionCardPriceText>
      </Flex>
      <SubscriptionCardPriceSubText>
        Billed {payment_period === "M" ? "Monthly" : "Annually"}{" "}
      </SubscriptionCardPriceSubText>
      {description.split("\r\n").map((d) => (
        <SubscriptionCardDefaultText
          isActive={isActive}
          fontSize="13px"
          fontWeight="400"
          textAlign="center"
        >
          {d}
        </SubscriptionCardDefaultText>
      ))}
      <Hr marginTop="16px" />
      <Flex flexDirection="column">
        {!!options &&
          options.split("\r\n").map((option) => (
            <Flex marginTop="16px">
              <Flex marginRight="12px">
                {isActive ? <CheckActive /> : <CheckDefault />}
              </Flex>
              <SubscriptionCardDefaultText isActive={isActive} fontSize="15px">
                {option}
              </SubscriptionCardDefaultText>
            </Flex>
          ))}
      </Flex>
      <StyledButton disabled={isActive} onClick={setActive}>
        {isActive ? "Selected" : "Get Started"}
      </StyledButton>
    </SubscriptionCardBlock>
  );
};

export default SubscriptionCard;
