import React, { useState, useEffect, useMemo } from "react"
import { RemoveButton, ResponsiveFlex, ResponsiveTextarea } from "../styled"
import DatePicker from "react-datepicker"
import Flex from "../../../noui/Flex"
import { DatePicker as DateCustom, Form, Input, Button, Spin } from "antd"
import { TArgCallback } from "../../../types"
import { DefaultDateTimeFormat } from "../../../constants"
import { CloseCircleIcon, ArrowBack, ArrowNext, SmallDeleteIcon } from "../../../assets/icons"
import moment from "moment"
import { useLoading, useResponsive, usePrevious } from "../../../utils/hooks"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { CalendarPicker } from "../../../components/CalendarPicker"

import { Api, TSchedule } from "../../../api/api"
import { IJob, IUser } from "../../../api/types"
import Timeline from "../../dispatch/JobsTimeline/Timeline"

const MobileWrapper = styled.div`
   ${down("lg")} {
      .ant-picker-datetime-panel {
         flex-direction: column !important;
      }
   }
   & .ant-picker.ant-picker-disabled {
      border: none;
      background: none;
      color: #000;
      padding: 0;
      & .ant-picker-suffix {
         display: none;
      }
   }
   & .ant-picker-input > input[disabled] {
      color: #000;
   }
   .ant-picker-footer li.ant-picker-now {
      display: none;
   }
`
const StyledButton = styled(Button)`
   border: none;
   padding: 0px;
   margin-top: 20px;
   svg {
      vertical-align: middle !important;
   }
`

export const CalendarWrapper = styled.div`
   .ant-form-item-control-input {
      min-height: 0;
   }
`

type TProps = {
   field: any
   remove: TArgCallback<number | number[]>
   technicianRole: boolean
   job: IJob
   form: any
   setIsFormFieldChanged?: (value: boolean) => void
   technicians: string[]
}
export const ScheduleRow: React.FC<TProps> = ({
   field,
   remove,
   job,
   technicianRole,
   form,
   setIsFormFieldChanged,
   technicians,
}) => {
   const valuesForm = form.getFieldValue("schedules")
   const [startDate, setStartDate] = useState<any>(
      valuesForm[field.name] ? new Date(valuesForm[field.name].start) : null
   )
   const [endDate, setEndDate] = useState<any>(valuesForm[field.name] ? new Date(valuesForm[field.name].end) : null)

   const [event, setEvent] = useState<any>({
      start: moment(startDate).toString(),
      end: moment(endDate).toString(),
      id: 0,
      technicians: technicians,
      job: job,
      isScheduled: true,
   })
   const [timeView, setTimeView] = useState<string>("day")
   const [timeLineStartDate, setTimeLineStartDate] = useState<Date>(new Date())
   const [timeLineEndDate, setTimeLineEndDate] = useState<Date>(new Date())
   const [users, setUsers] = useState<IUser[]>([])
   const [schedules, setSchedules] = useState<TSchedule[]>([])
   const isXs = useBreakpoint(down("xs"))
   const { width } = useResponsive()

   const prevEndDate = usePrevious(endDate)
   const prevStartDate = usePrevious(startDate)

   const isPastTime = (value: any) => {
      if (!moment(value).isValid()) {
         return Promise.reject("This field is required")
      } else if (value < Date.now()) {
         return Promise.reject("Start time can't be in past")
      }
      return Promise.resolve()
   }
   const isValidEndTime = (value: any) => {
      const schedules = form.getFieldValue("schedules")

      try {
         if (!moment(value).isValid()) {
            return Promise.reject("This field is required")
         } else if (value < schedules[field.name].start) {
            return Promise.reject("Start date can't be after end date")
         }
         return Promise.resolve()
      } catch (error) {
         return
      }
   }

   const filteredEvents = useMemo(
      () =>
         [
            event,
            ...schedules?.filter((e) => {
               return (
                  moment(e.end).isAfter(moment(timeLineStartDate)) &&
                  moment(e.start).isBefore(moment(timeLineEndDate)) &&
                  e.job.id !== job.id
               )
            }),
         ] ?? [],
      [schedules, startDate, event, endDate]
   )

   const setTimeLineView = () => {
      const durationOfWork = Math.abs(
         ((startDate as unknown as number) - (endDate as unknown as number)) / (1000 * 3600 * 24)
      )

      switch (true) {
         case durationOfWork < 0.7:
            return setTimeView("day")
         case durationOfWork < 30:
            return setTimeView("week")
         case durationOfWork < 365:
            return setTimeView("month")
         default:
            return setTimeView("year")
      }
   }

   const setTimeLineSpan = () => {
      if (startDate && endDate) {
         const newStartDate = new Date(startDate.valueOf())
         const newEndDate = new Date(endDate.valueOf())
         switch (timeView) {
            case "day":
               newStartDate.setMinutes(0)
               newEndDate.setMinutes(0)
               newStartDate.setHours(newStartDate.getHours() - 4)
               newEndDate.setHours(newEndDate.getHours() + 4)
               break
            case "week":
               newStartDate.setHours(0)
               newEndDate.setHours(0)
               newStartDate.setDate(newStartDate.getDate() - 3)
               newEndDate.setDate(newEndDate.getDate() + 3)
               break
            case "month":
               newStartDate.setDate(0)
               newEndDate.setDate(0)
               newStartDate.setMonth(newStartDate.getMonth() - 2)
               newEndDate.setMonth(newEndDate.getMonth() + 3)

               break
         }

         setTimeLineStartDate(newStartDate)
         setTimeLineEndDate(newEndDate)
      }
   }

   // const values = form.getFieldValue("schedules")

   const getSchedules = async (start: any, end: any) => {
      await Api.schedules
         .getAll({
            time_after: moment(start).toISOString(),
            time_before: moment(end).toISOString(),
         })
         .then((res) => {
            const data = res.data as TSchedule[]
            setSchedules(data)
         })
   }

   useEffect(() => {
      const values = form.getFieldValue("schedules")
      if (values && values.length > 0) {
         if (values[field.name]) {
            getSchedules(new Date(values[field.name].start), new Date(values[field.name].end)).then()
         }
      }

      Api.user.getAll({}).then((res) => {
         const data = res.data as IUser[]
         setUsers(data)
      })
   }, [])

   useEffect(() => {
      getSchedules(timeLineStartDate, timeLineEndDate).then()
   }, [timeLineStartDate, timeLineEndDate])

   useEffect(() => {
      const scheduledEvent = {
         start: moment(startDate).toString(),
         end: moment(endDate).toString(),
         id: 0,
         technicians: technicians.map((item) => +item),
         job: job,
         isScheduled: true,
      }
      setEvent(scheduledEvent)
      setTimeLineSpan()
   }, [startDate, endDate, timeView, technicians])

   const checkIsValue = (type: string) => {
      //const values = form.getFieldValue("schedules")

      return moment(type === "start" ? startDate : endDate).isSame(
         moment(type === "start" ? prevStartDate : prevEndDate)
      )
         ? false
         : true
   }

   useEffect(() => {
      const values = form.getFieldValue("schedules")

      if (values && Array.isArray(values) && values.length && checkIsValue("start")) {
         values[field.name] = {
            ...values[field.name],
            start: moment(startDate),
         }
         form.setFieldsValue({ schedules: values })

         if (setIsFormFieldChanged) {
            setIsFormFieldChanged(true)
         }
      }
   }, [startDate])

   useEffect(() => {
      setTimeLineView()
      const values = form.getFieldValue("schedules")

      if (values && Array.isArray(values) && values.length) {
         values[field.name] = {
            ...values[field.name],
            end: moment(endDate),
         }

         form.setFieldsValue({ schedules: values })
         if (endDate && checkIsValue("end")) {
            if (setIsFormFieldChanged) {
               setIsFormFieldChanged(true)
            }
         }
      }
   }, [endDate, startDate])

   return (
      <MobileWrapper>
         <ResponsiveFlex gap={isXs ? 0 : 16} alignItems="flex-start">
            <Form.Item {...field} fieldKey={[field.fieldKey, "id"]} name={[field.name, "id"]} hidden>
               <Input hidden />
            </Form.Item>

            <CalendarWrapper>
               <Form.Item
                  {...field}
                  fieldKey={[field.fieldKey, "start"]}
                  name={[field.name, "start"]}
                  rules={[
                     { required: true, message: "This field is required" },
                     () => ({
                        validator(_, value) {
                           return isPastTime(value)
                        },
                     }),
                  ]}
               >
                  <CalendarPicker
                     label="Start date & time"
                     date={startDate}
                     setDate={setStartDate}
                     excludePastDays={true}
                  />
               </Form.Item>
            </CalendarWrapper>
            <CalendarWrapper>
               <Form.Item
                  {...field}
                  fieldKey={[field.fieldKey, "end"]}
                  name={[field.name, "end"]}
                  rules={[
                     { required: true, message: "This field is required" },
                     () => ({
                        validator(_, value) {
                           return isValidEndTime(value)
                        },
                     }),
                  ]}
               >
                  <CalendarPicker label="End date & time" date={endDate} setDate={setEndDate} excludePastDays={true} />
               </Form.Item>

               {/* <Form.Item
                  {...field}
                  fieldKey={[field.fieldKey, "end"]}
                  name={[field.name, "end"]}
                  rules={[
                     { required: true, message: "This field is required" },
                     () => ({
                        validator(_, value) {
                           return isValidEndTime(value)
                        },
                     }),
                  ]}
                  style={{ height: 0, minHeight: 0 }}

               ></Form.Item> */}
            </CalendarWrapper>

            {!technicianRole && (
               <>
                  {isXs && (
                     <Form.Item label="Arrival window" name={[field.name, "arrival_notes"]}>
                        <ResponsiveTextarea
                           onChange={() => {
                              if (setIsFormFieldChanged) {
                                 setIsFormFieldChanged(true)
                              }
                           }}
                           placeholder="This is where a message to the client can be left..."
                        />
                     </Form.Item>
                  )}
                  <Form.Item>
                     {width > 768 ? (
                        <StyledButton
                           onClick={() => {
                              if (setIsFormFieldChanged) {
                                 setIsFormFieldChanged(true)
                              }
                              remove(field.name)
                           }}
                        >
                           <CloseCircleIcon />
                        </StyledButton>
                     ) : (
                        <RemoveButton
                           type="text"
                           onClick={() => {
                              if (setIsFormFieldChanged) {
                                 setIsFormFieldChanged(true)
                              }
                              remove(field.name)
                           }}
                        >
                           <SmallDeleteIcon />
                           Remove Schedule
                        </RemoveButton>
                     )}
                  </Form.Item>
               </>
            )}
         </ResponsiveFlex>

         {startDate && endDate && technicians[0] && (
            <Timeline
               date={timeLineStartDate}
               dayStart={timeLineStartDate}
               dayEnd={timeLineEndDate}
               events={filteredEvents}
               employees={users}
               isScheduler
               currentView={timeView}
               search={technicians}
            />
         )}

         {!technicianRole && !isXs && (
            <Form.Item label="Arrival window" name={[field.name, "arrival_notes"]}>
               <ResponsiveTextarea
                  onChange={() => {
                     if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true)
                     }
                  }}
                  placeholder="This is where a message to the client can be left..."
               />
            </Form.Item>
         )}
      </MobileWrapper>
   )
}
