import {Api} from "../api/api";
import {ILogin, IToken} from "../api/types";
import {AxiosResponse} from "axios";

const LocalTokens = {
    authToken: "t",
    refreshToken: "r"
}

class AuthService {
    getLocalToken (): string {
        return localStorage.getItem(LocalTokens.authToken) || '';
    }
    getRefreshToken (): string {
        return localStorage.getItem(LocalTokens.refreshToken) || '';
    }

    setTokens ({access, refresh}: IToken) {
        localStorage.setItem(LocalTokens.authToken, access);
        localStorage.setItem(LocalTokens.refreshToken, refresh);
    }

    isAuthenticated (): boolean {
        return Boolean(this.getLocalToken());
    }

    async refresh(): Promise<void> {
        try {
            const data =  {refresh: this.getRefreshToken()};
            localStorage.removeItem(LocalTokens.authToken);
            const resp = await Api.token.refresh(data);
            this.setTokens(resp.data);
        } catch (e) {
            this.logout();
            throw e;
        }
    }

    async login(data: ILogin): Promise<AxiosResponse<IToken>> {
        try {
            const resp = await Api.token.get(data);
            this.setTokens(resp.data);
            return resp;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    logout (): void {
        localStorage.removeItem(LocalTokens.authToken);
        localStorage.removeItem(LocalTokens.refreshToken);
    }
}

export const authService = new AuthService();