import React, { useMemo } from "react"
import { Checkbox, Form, Input } from "antd"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import styled from "styled-components"
import { CompanyInfoContainer, SaveBtn, ContainerTitle, SaveBtnWrapper } from "./styled"
import { useSelector } from "react-redux"
import { getConstants } from "../../config/reducers/user/selectors"
import { mapConstants } from "../../utils/utils"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"

const FlexContainer = styled(Flex)`
   flex-direction: column;
   align-items: flex-start;
   width: 100%;
   flex-grow: 1;
   & > div {
      width: calc(100% - 10px);
      margin-bottom: 22px;
   }
`

const CheckBoxGroupColumn = styled(Checkbox.Group)`
   display: flex;
   flex-direction: column;
   ${down("xs")} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: start;
   }
`

const CheckBoxGroupColumnLarge = styled(Checkbox.Group)`
   display: flex;
   flex-direction: column;
   ${down("xs")} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      > label {
         margin-top: 10px;
      }
   }
`

const SettingsRow = styled(Flex)`
   flex-direction: column;
   width: 100%;
   ${down("xs")} {
      flex-direction: row;
      > div {
         flex-direction: row !important;
      }
   }
`

export const InvoiceForm = ({ form, onFinish, saving }) => {
   const c = useSelector(getConstants)
   const [epaOptions, taxOptions] = useMemo(() => {
      return [mapConstants(c?.epa_types), mapConstants(c?.tax_types)]
   }, [c])
   const isXs = useBreakpoint(down("xs"))
   return (
      <Form form={form} onFinish={onFinish} layout="vertical">
         <CompanyInfoContainer p={16}>
            <Flex mb="22px" flexDirection="column" width="100%">
               <ContainerTitle>Other fees</ContainerTitle>
               <Flex style={{ width: isXs ? "100%" : "80%" }} flexDirection={isXs ? "column" : "row"}>
                  <FlexContainer>
                     <SettingsRow>
                        <Form.Item initialValue={0} name={"epa"} label="EPA (%)">
                           <Input placeholder="0%" type={"number"} />
                        </Form.Item>
                        <div style={{ width: isXs ? "110px" : "auto", marginLeft: "10px" }}>
                           <Msg fontWeight={700} fontSize={13} color="#484848" mb="4px" mt="16px">
                              Include EPA on:
                           </Msg>
                           <Form.Item name="epa_includes">
                              <CheckBoxGroupColumn options={epaOptions} />
                           </Form.Item>
                        </div>
                     </SettingsRow>
                     <SettingsRow>
                        <Form.Item initialValue={0} name={"epa_amount"} label="EPA ($)">
                           <Input placeholder="0%" type={"number"} />
                        </Form.Item>
                        <div style={{ width: isXs ? "110px" : "auto", marginLeft: "10px" }}>
                           <Msg fontWeight={700} fontSize={13} color="#484848" mb="4px" mt="16px">
                              Include EPA on:
                           </Msg>
                           <Form.Item name="epa_amount_includes">
                              <CheckBoxGroupColumn options={epaOptions} />
                           </Form.Item>
                        </div>
                     </SettingsRow>
                  </FlexContainer>
                  <FlexContainer mb={isXs ? "0px" : "22px"}>
                     <SettingsRow>
                        <Form.Item initialValue={0} name="tax" label="Tax (%)" style={{ width: "100%" }}>
                           <Input placeholder="0%" type={"number"} />
                        </Form.Item>
                     </SettingsRow>
                     <div>
                        <Msg fontWeight={700} fontSize={13} color="#484848" mb="4px" mt="16px">
                           Include tax on:
                        </Msg>
                        <Form.Item name="tax_includes">
                           <CheckBoxGroupColumnLarge options={taxOptions} />
                        </Form.Item>
                     </div>
                  </FlexContainer>
               </Flex>
               <SaveBtnWrapper justifyContent="flex-end">
                  <Form.Item shouldUpdate>
                     {() => (
                        <SaveBtn disabled={!form.isFieldsTouched()} loading={saving} ml={16} htmlType="submit">
                           Save
                        </SaveBtn>
                     )}
                  </Form.Item>
               </SaveBtnWrapper>
            </Flex>
         </CompanyInfoContainer>
      </Form>
   )
}
