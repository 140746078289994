import React from "react";
import { TSchedule } from "../../../api/api";
import Flex from "../../../noui/Flex";
import styled from "styled-components";
import moment from "moment";
import { Msg } from "../../../ui/Text";
import { formatAddress } from "../../../utils/utils";

const PopoverContainer = styled(Flex)`
    width: 200px;
    padding: 16px;
    flex-direction: column;
`;

const MainText = styled(Msg)`
    font-weight: 800;
    font-size: 18px;
    color: #151F2B;
`;

const SupText = styled(Msg)`
    font-weight: 300;
    font-size: 16px;
    color: #151F2B;
`;

type TProps = {
    schedule: TSchedule;
}

export const MapPopover: React.FC<TProps> = ({schedule}) => {
    return <PopoverContainer>
        <Flex width="100%" justifyContent="space-between">
            <MainText>{moment(schedule.start).format('MM/DD/YY')}</MainText>
            <MainText>{moment(schedule.start).format('h:mm A')}</MainText>
        </Flex>
        <Flex flexDirection="column" margin="21px 0 29px">
            <MainText>{schedule.job?.vessel?.name}</MainText>
            <SupText>{schedule.job?.customer?.first_name + " " + schedule.job?.customer?.last_name}</SupText>
        </Flex>
        <SupText>{formatAddress(schedule.address)}</SupText>
    </PopoverContainer>
};