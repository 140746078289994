import { createContext, useState } from "react"

type TWOContext = {
   isCompleteRequested: boolean
   requestComplete: (value: boolean) => void

   updateJobs: (value: boolean) => void
   resetJobs: (value: boolean) => void
   jobsUpdate: boolean
   jobsReset: boolean
   jobs: any[]
   setJobs: (value: any[]) => void
   setJobsData: (id: number, tab: string) => void
   clearUpdateJobs: () => void

   actionType: any
   setJActionType: (value: any) => void
}

export const WOContext = createContext<TWOContext>({
   isCompleteRequested: false,
   requestComplete: () => {},

   updateJobs: () => {},
   resetJobs: () => {},
   jobsUpdate: false,
   jobsReset: false,
   jobs: [],
   setJobs: () => {},
   setJobsData: () => {},
   clearUpdateJobs: () => {},

   actionType: {},
   setJActionType: () => {},
})

export const WOContextStore = (props: any) => {
   const [isCompleteRequested, setIsCompleteRequested] = useState(false)

   const [jobs, setJobs] = useState<any[]>([])
   const [jobsUpdate, setJobsUpdate] = useState<boolean>(false)
   const [jobsReset, setJobsReset] = useState<boolean>(false)

   const [actionType, setJActionType] = useState<any>({})

   const setJobsData = (id: number, tab: string) => {
      let copyJobs = [...jobs]
      copyJobs = copyJobs.filter((item) => item.id !== id)

      let uniqueItems = [...copyJobs, { id, tab, update: false }]
      setJobs(uniqueItems)
   }

   const clearUpdateJobs = () => {
      setJobs([])
   }

   const state = {
      isCompleteRequested,
      requestComplete: setIsCompleteRequested,

      updateJobs: setJobsUpdate,
      jobsUpdate,
      resetJobs: setJobsReset,
      jobsReset,
      jobs,
      setJobs,
      setJobsData,
      clearUpdateJobs,

      actionType,
      setJActionType,
   }
   return <WOContext.Provider value={state}>{props.children}</WOContext.Provider>
}
