import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { notification, Form } from "antd"
import { IMaterialGroup, IMaterial, IMaterialInGroup } from "../../../api/types"
import { Api } from "../../../api/api"
import { usePagination } from "../../../utils/hooks"
import { useDebounce } from "use-debounce/lib"
import ServicesMaterials from "../../../layouts/ServicesMaterials"

const Materials = () => {
   const [form] = Form.useForm()
   const [group, setGroup] = useState<IMaterialGroup[]>([])
   const [activeGroup, setActiveGroup] = useState<IMaterialGroup | null>()
   const { data, onPromise, requestPagination, loading, pagination, onChange } = usePagination<IMaterial>()
   const [searchGroup, setSearchGroup] = useState<string>("")
   const [searchMaterial, setSearchMaterial] = useState<string>("")
   const [dbSearchGroup] = useDebounce(searchGroup, 1000)
   const [dbSearchMaterial] = useDebounce(searchMaterial, 1000)

   const columns = [
      {
         name: "name",
         label: "Material name",
      },
      {
         name: "sku",
         label: "SKU number",
      },
      {
         name: "description",
         label: "Description",
      },
      {
         name: "unit_cost",
         label: "Unit cost",
      },
      {
         name: "price",
         label: "Unit price",
      },
   ]

   useEffect(() => {
      loadGroup()
   }, [dbSearchGroup])

   useEffect(() => {
      if (activeGroup) {
         loadMaterials(activeGroup.id)
      }
   }, [requestPagination, activeGroup?.id, dbSearchMaterial])

   const loadMaterials = (id: number) => {
      onPromise(
         Api.materials.getAllMaterials({
            ...requestPagination,
            group: id,
            search: dbSearchMaterial,
         })
      )
   }

   const loadGroup = async () => {
      const res = await Api.materials.getAllGroups({
         search: dbSearchGroup,
      })
      const data = (await res.data) as IMaterialGroup[]
      setGroup(data)
      if (data && data.length > 0) {
         setActiveGroup(data[0])
      } else {
         setActiveGroup(null)
      }
   }

   const handleCreateGroupe = async (name: string) => {
      try {
         const res = await Api.materials.createMaterialGroup({ name })
         const data = (await res.data) as IMaterialGroup
         const newData = [...group, data]
         setGroup(newData)
         //setActiveGroup(newData[newData.length - 1])
         setSearchMaterial("")
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const handleActiveGroup = (data: any) => {
      setActiveGroup(data)
      handleChangePagination(1, 10)
      setSearchMaterial("")
   }

   const handleDeleteGroup = () => {
      if (activeGroup) {
         Api.materials.deleteMaterialGroup(activeGroup)
         let newDataGroup = [...group]
         newDataGroup = newDataGroup.filter((item) => item.id !== activeGroup.id)
         setGroup(newDataGroup)
         if (newDataGroup.length > 0) {
            setActiveGroup(newDataGroup[0])
         } else {
            setActiveGroup(null)
         }
         setSearchGroup("")
      }
   }

   const handleEditGroupName = (name: string) => {
      if (activeGroup) {
         const updateGroup = {
            name,
            id: activeGroup.id,
         }
         Api.materials.editMaterialGroup(updateGroup)
         let newDataGroup = [...group]
         newDataGroup = newDataGroup.map((item) => {
            if (item.id === activeGroup.id) {
               return {
                  ...item,
                  name,
               }
            }
            return item
         })

         setGroup(newDataGroup)
      }
   }

   const handleCreateMaterial = async (values: any) => {
      if (values.group) {
         try {
            setActiveGroup(values.group)
            const dataSend = {
               name: values.name,
               description: values.description,
               unit_cost: values.cost,
               price: values.price,
               group: values.group.id,
               active: values.active,
               sku: values.sku,
            } as IMaterialInGroup
            await Api.materials.createMaterial(dataSend)
            await loadMaterials(values.group.id)
         } catch (e) {
            notification.error({
               message: "Something went wrong",
            })
         } finally {
            form.resetFields()
         }
      }
   }

   const handleEditMaterial = async (data: any) => {
      if (data.group) {
         try {
            setActiveGroup(data.group)
            await Api.materials.editMaterial({ ...data, group: data.group.id })
            await loadMaterials(data.group.id)
         } catch (e) {
            notification.error({
               message: "Something went wrong",
            })
         }
      }
   }

   const handleChangePagination = (page: any, pageSize: any) => {
      onChange({ ...pagination, current: page, pageSize }, {}, {})
   }

   const handleDeleteMaterial = async (data: any) => {
      if (activeGroup) {
         await Api.materials.deleteMaterial(data)
         await loadMaterials(activeGroup.id)
      }
   }

   const handleDuplicateMaterial = async (values: any) => {
      if (activeGroup) {
         try {
            const dataSend = {
               name: `${values.name} copy`,
               description: values.description,
               unit_cost: values.unit_cost,
               price: values.price,
               group: activeGroup.id,
               active: values.active,
            } as IMaterialInGroup
            await Api.materials.createMaterial(dataSend)
            await loadMaterials(activeGroup.id)
         } catch (e) {
            notification.error({
               message: "Something went wrong",
            })
         }
      }
   }

   return (
      <>
         <Helmet>
            <title>Materials - DockWorks Pro</title>
         </Helmet>

         <ServicesMaterials
            handleCreateGroupe={handleCreateGroupe}
            handleActiveGroup={handleActiveGroup}
            handleDeleteGroup={handleDeleteGroup}
            handleEditGroupName={handleEditGroupName}
            handleCreateMaterial={handleCreateMaterial}
            handleEditMaterial={handleEditMaterial}
            handleChangePagination={handleChangePagination}
            handleDeleteMaterial={handleDeleteMaterial}
            handleDuplicateMaterial={handleDuplicateMaterial}
            group={group}
            activeGroup={activeGroup}
            data={data}
            pagination={pagination}
            setSearchGroup={setSearchGroup}
            setSearchMaterial={setSearchMaterial}
            columns={columns}
            loading={loading}
         />
      </>
   )
}
export default Materials
