import Settings from "./Settings"
import { Helmet } from "react-helmet-async"
import PaymentsGetStarted from "./PaymentsGetStarted"
import GetStartedMobile from "./GetStartedMobile"
import { Api } from "../../api/api"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { useAppDispatch, useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { useCallback, useEffect, useState } from "react"
import { setCurrentUser } from "../../config/reducers/user/actions"
import AfterOnBoarding from "./AfterOnBoarding"
import { Spin } from "antd"
import Flex from "../../noui/Flex"
import { IStripe } from "../../api/types"
import { track } from "../../utils/analytics"
import moment from "moment"

const SettingsPayments = () => {
   const user = useAppSelector(getCurrentUser)
   const isLg = useBreakpoint(down("lg"))
   const [stripe, setStripe] = useState<IStripe | null>(null)
   const [stripeSetupIsStarting, setStripeSetupIsStarting] = useState(false)

   const dispatch = useAppDispatch()

   useEffect(() => {
      if (user?.company) {
         setStripe(user.company.stripe)
      }
   }, [user])

   const redirectToStripe = useCallback(async (): Promise<void> => {
      let stripeData
      if (!!stripe || stripeSetupIsStarting) {
         stripeData = await Api.company.completeStripeOnboarding()
      } else {
         stripeData = await Api.company.turnOnPayments()
         await track("Payment Setup Started", {
            paymentStartedBy: user?.id,
            companyId: user?.company?.id,
            paymentSetupStartTimestamp: moment().toISOString(),
         })
      }
      window.open(
         user?.company && user?.company.stripe?.charges_enabled ? "https://stripe.com/" : stripeData?.data.url,
         "_blank",
         "noopener,noreferrer"
      )
      setStripeSetupIsStarting(true)
   }, [stripe, stripeSetupIsStarting])

   return (
      <>
         <Helmet>
            <title>Payment Settings - DockWorks Pro</title>
         </Helmet>

         <Settings>
            {user ? (
               <>
                  {isLg ? (
                     <GetStartedMobile mainText="DockWorks on your laptop or desktop computer to setup DockWorks Payments." />
                  ) : !stripe?.details_submitted ? (
                     <PaymentsGetStarted
                        onStartClick={redirectToStripe}
                        isStripeConnected={!!stripe}
                        stripeSetupIsStarting={stripeSetupIsStarting}
                        isAdmin={user.role_id === 3}
                     />
                  ) : (
                     <AfterOnBoarding status={stripe?.charges_enabled} makeChangeInStripe={redirectToStripe} />
                  )}
               </>
            ) : (
               <Flex height="400px" justifyContent="center" alignItems="center">
                  <Spin spinning={true} />
               </Flex>
            )}
         </Settings>
      </>
   )
}

export default SettingsPayments
