import React, { forwardRef, useMemo } from "react";
import { Button, Input, Select } from "antd";
import { SendOutlined, DownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Title } from "../../layouts/CustomerLayout/styled";
import {
  EAuthor,
  IExternalEstimate,
  IExternalRecurring,
  IJob,
  IMessage,
  IShortJob,
} from "../../api/types";
import moment from "moment";
import { TArgCallback, TCallback, TInputChangeHandler } from "../../types";
import { mapOptions } from "../../utils/utils";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";

interface IHeightProps {
  height: string | null;
}
const CommentsWrapper = styled.div<IHeightProps>`
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% + 32px);
  margin: 0 -16px;
  padding: 0 16px;
  align-items: center;
  //justify-content: end;
  // height: 400px;
  max-height: ${(props) => (props.height ? props.height : "calc(100% - 50px)")};
  height: calc(100% - 50px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #fbfbff;
    border-radius: 8px;
    border: 1px solid #ededed;
  }
  &::-webkit-scrollbar-thumb {
    background: #0496ff;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #40a9ff;
  }

  > :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
  }

  & > div + div {
    margin-top: 8px;
  }
  ${down("xs")} {
    max-height: 100px;
    min-height: 150px;
  }
`;

const CommentContainer = styled.div`
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 90%;
  h4 {
    font-weight: bold;
    margin: 0;
    font-size: 14px;
    line-height: 14px;
  }
  small {
    font-size: 11px;
    font-style: italic;
    margin-bottom: 12px;
    display: inline-block;
  }
  p {
    margin: 0;
    font-size: 15px;
  }
  &.T {
    background: #fbfdff;
    text-align: right;
    align-self: flex-end;
  }
  &.C {
    background: #109cf1;
    color: #fff;
    align-self: flex-start;
    text-align: left;
    h4 {
      color: #fff;
    }
  }
`;

const NewMessageContainer = styled.div`
  margin-top: 12px;
  display: flex;
  input {
    margin-right: 8px;
  }
`;

const SelectJobWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  .ant-select {
    flex-grow: 1;
  }
`;

const PdfButton = styled(Button)`
  height: 100%;
  width: 100%;
  &.ant-btn {
    padding: 10px !important;
  }
`;
const SubTittle = styled.p`
  //width: 68%;
`;

const PaddedContainer = styled(Flex)`
  height: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  ${down("xs")} {
    padding: 0;
  }
`;

type TMProps = {
  message: IMessage;
  reverse: boolean;
};

const Message: React.FC<TMProps> = ({ message, reverse = false }) => {
  const formatMessage = (msg: string) =>
    msg.split("\n").map((m) => <p style={{ wordBreak: "break-all" }}>{m}</p>);

  return (
    <CommentContainer
      className={
        !reverse
          ? message.author
          : message.author === EAuthor.T
          ? EAuthor.C
          : EAuthor.T
      }
    >
      <h4>{message.full_name}</h4>
      <small>{moment(message.created).format("LLL")}</small>
      {formatMessage(message.message)}
    </CommentContainer>
  );
};

type TProps = {
  message?: string;
  messages: IMessage[];
  onChangeMessage?: TInputChangeHandler;
  onSend?: TCallback;
  short?: boolean;
  jobs?: (IJob | IShortJob)[];
  selectedJob: string | null;
  onSelectJob?: TArgCallback<string>;
  estimate?: IExternalEstimate | IExternalRecurring;
  handlePrint?: TCallback;
  handleToggleSideBarComponent?: (info: string) => void;
  isHide?: boolean;
  maxHeight?: string;
  woWidget?: boolean;
};
export const Messages = forwardRef<Input, TProps>(
  (
    {
      messages,
      message,
      onChangeMessage,
      onSend,
      estimate,
      short = false,
      jobs,
      selectedJob,
      onSelectJob,
      handlePrint,
      handleToggleSideBarComponent,
      isHide,
      maxHeight,
      woWidget,
    },
    ref
  ) => {
    const messagesList = useMemo(() => {
      return short ? messages : messages.slice().reverse();
    }, [messages, short]);
    const jobOptions = useMemo(() => {
      if (jobs && jobs.length) {
        return mapOptions(jobs, "order_number");
      }
      return [];
    }, [jobs]);
    const isXs = useBreakpoint(down("xs"));

    return (
      <PaddedContainer>
        <Flex justifyContent={"space-between"}>
          {!short && <Title>Messages</Title>}
          {handleToggleSideBarComponent && (
            <Msg
              color={"#0496FF"}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "13px",
              }}
              onClick={() => {
                handleToggleSideBarComponent("messages");
              }}
            >
              {!isHide ? "Show" : "Hide"}
            </Msg>
          )}
        </Flex>

        {isHide && (
          <Flex flexDirection="column" style={{ height: "calc(100% - 30px)" }}>
            {messages.length === 0 && (
              <SubTittle>
                <Msg color="#8B8B8B" fontSize={"13px"}>
                  {woWidget
                    ? "Enter a new message that will be sent out to your customer..."
                    : "Type a message below to talk directly with a {estimate?.company.name} representative."}
                </Msg>
              </SubTittle>
            )}
            <CommentsWrapper height={maxHeight ? maxHeight : null}>
              {messages.length > 0 &&
                messages.map((message) => (
                  <Message key={message.id} message={message} reverse={short} />
                ))}
              <span />
            </CommentsWrapper>
            {onSend ? (
              <>
                {jobs?.length && jobs.length > 1 && onSelectJob ? (
                  <SelectJobWrapper>
                    <Select
                      value={selectedJob || ""}
                      options={jobOptions}
                      onSelect={onSelectJob}
                    >
                      {jobs.map((j) => (
                        <Select.Option value={j.id}>
                          {j.order_number}
                        </Select.Option>
                      ))}
                    </Select>
                  </SelectJobWrapper>
                ) : null}

                <NewMessageContainer>
                  <Input
                    ref={ref}
                    value={message}
                    onPressEnter={onSend}
                    onChange={onChangeMessage}
                  />
                  <Button
                    type="primary"
                    onClick={onSend}
                    disabled={!message}
                    icon={<SendOutlined />}
                  />
                </NewMessageContainer>
              </>
            ) : null}
          </Flex>
        )}
      </PaddedContainer>
    );
  }
);
