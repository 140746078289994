import React, { useCallback, useEffect, useState } from "react";
import { HeaderButton } from "../ui/Button";
import styled from "styled-components";
import { Dropdown, notification, Select } from "antd";
import Flex from "../noui/Flex";
import Plus from "../assets/images/headerIcons/Plus.svg";
import Bell from "../assets/images/headerIcons/Bell.svg";
import IconMessage from "../assets/images/IconMessage.svg";
import { PlusModal } from "../modals/PlusModal";
import { PersonalInfo } from "../modals/PersonalInfoModal";
import { NotificationsModal } from "../modals/Notifications/Notifications";
import { TasksModal } from "../modals/Tasks/TasksModal";
import { checkNotActive, getInitials } from "../utils/utils";
import { INotification, IUserProfile, IWSMessage } from "../api/types";
import { CustomerModal } from "../containers/customers/CustomerModal";
import { VesselsModal } from "../containers/vessels/VesselsModal";
import { MessagesModal } from "../modals/Messages/MessagesModal";
import { AppointmentModal } from "../modals/Appointment";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { MenuOutlined } from "@ant-design/icons";
import { SearchIcon } from "../assets/icons";
import { TCallback } from "../types";
import routes from "../routes/routes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewNotificationsExists } from "../config/reducers/notifications/selectors";
import {
  addNewNotification,
  getAllNotifications,
} from "../config/reducers/notifications/actions";
import {
  useWebSocket,
  useTechnicianRole,
  useSubscriptionStatus,
} from "../utils/hooks";
import PaymentWarningModal from "../modals/PaymentWarningMessage";
import moment from "moment";
import { Api } from "../api/api";
import { setCurrentUser } from "../config/reducers/user/actions";
import { track } from "../utils/analytics";

const DropD = styled(Dropdown)`
  position: relative;
  width: 45px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  .EllipseGreen,
  .EllipseRed {
    position: absolute;
    top: 8px;
    right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: #42c77b;
  }
  .EllipseRed {
    background: #fb4d4f;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  gap: 16px;
  ${down("xs")} {
    align-self: flex-end;
    margin-right: 0;
    width: 100%;
  }
`;

const HeaderBtn = styled(HeaderButton)`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > .wrapper {
    position: relative;
    & > .new {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fb4d4f;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const FlexField = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  & .ant-input-affix-wrapper {
    width: 60%;
  }
  ${down("xs")} {
    flex-direction: column-reverse;
    > div:first-child {
      margin-top: 16px;
    }
  }
  &.b {
    background-color: red;
  }
`;

const StyledFlex = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  width: 100%;
  > span {
    margin-right: 4px;
  }
  ${down("xs")} {
    background-color: white;
    border-radius: 3px;
    > span {
      margin: 0 2px 0 6px;
    }
    svg {
      margin: 0 5px;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: none !important;
  }
  ${down("xs")} {
    order: 2;
    margin: 0 auto;
    width: 100% !important;
  }
`;

const UserNameText = styled.h4`
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 900;
  line-height: 17, 73px;
  margin-bottom: -5px;
`;

const InitialsLetters = styled.div`
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
`;

const UserCompanyText = styled.span`
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 0.01em;
  line-height: 13, 96px;
`;

const IconButtonsWrap = styled.div`
  display: flex;
  margin-right:30px
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  & img {
    width: 18px;
    height: 18px;
  }
  ${down("xs")} {
    width: 100%;
  }
`;

const MenuWrapper = styled.div`
  font-size: 22px;
  margin-right: auto;
  transform: scaleX(1.2);
`;

type TProps = {
  userData: IUserProfile | null;
  onMenuOpen: TCallback;
};
export const HeaderContent: React.FC<TProps> = ({ userData, onMenuOpen }) => {
  const [customer, setCustomer] = useState<boolean>(false);
  const [vessel, setVessel] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<boolean>(false);
  const [personalInfo, setPersonalInfo] = useState<boolean>(false);
  const [plusModal, setPlusModal] = useState<boolean>(false);
  const [messageModal, setMessageModal] = useState<boolean>(false);
  const [notificationsVisible, setNotificationsVisible] =
    useState<boolean>(false);
  const unreadNotifications = useSelector(getNewNotificationsExists);
  const technicianRole = useTechnicianRole();
  const dispatch = useDispatch();
  const subscriptionStatus = useSubscriptionStatus();
  const [visible, setVisible] = useState(false);
  const [last4, setLast4] = useState("");
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    dispatch(getAllNotifications());
  }, [dispatch]);

  const handleNewNotification = useCallback(
    async (message: IWSMessage<INotification>) => {
      dispatch(addNewNotification(message.message));
      notification.info({ message: message.message.content, duration: 2 });
      if (message.message.category === "SN") {
        const { data } = await Api.user.profile();
        dispatch(setCurrentUser(data));
        await track("Payments Setup Completed", {
          paymentSetupBy: userData?.id,
          companyId: userData?.company?.id,
          paymentSetupFinishTimestamp: moment().toISOString(),
        });
      }
    },
    [dispatch]
  );
  useWebSocket("", "U", handleNewNotification);

  const isXS = useBreakpoint(down("xs"));
  const history = useHistory();

  const onCustomer = () => {
    setCustomer(true);
    setPlusModal(false);
  };
  const onVessel = () => {
    setVessel(true);
    setPlusModal(false);
  };
  const onMessage = () => {
    setMessageModal(true);
    setPlusModal(false);
  };
  const onAppointment = () => {
    setAppointment(true);
    setPlusModal(false);
  };

  const searchOptions = [
    {
      label: "Dashboard",
      value: routes.main.Dashboard,
    },
    {
      label: "Customers",
      value: routes.customers.List,
    },
    {
      label: "Vessels",
      value: routes.vessels.List,
    },
    {
      label: "Calendar",
      value: routes.jobs.Base + "/calendar",
    },
    {
      label: "Project List",
      value: routes.jobs.Base + "/statusboard",
    },
    {
      label: "Status Board",
      value: routes.jobs.Base + "/kanban",
    },
    {
      label: "Status Board",
      value: routes.jobs.Base + "/statusboard",
    },
    {
      label: "Map",
      value: routes.jobs.Base + "/map",
    },
    {
      label: "Timeclocks",
      value: routes.jobs.Base + "/timeclocks",
    },
    {
      label: "Dispatch",
      value: routes.dispatch.Base,
    },
    {
      label: "Projects",
      value: routes.business.Jobs,
    },
    {
      label: "Invoices",
      value: routes.business.Invoices,
    },
    {
      label: "Payments",
      value: routes.business.Payments,
    },
    {
      label: "Rate Card",
      value: routes.business.RateCard,
    },
    {
      label: "General Settings",
      value: routes.settings.General,
    },
    {
      label: "Users",
      value: routes.settings.Users,
    },
    {
      label: "Subscription",
      value: routes.settings.Subscription,
    },
    {
      label: "Bank Account",
      value: routes.settings.Payments,
    },
    {
      label: "Support",
      value: routes.help.Base,
    },
  ];

  const searchOptionsTechnician = [
    {
      label: "Dashboard",
      value: routes.main.Dashboard,
    },
    {
      label: "Calendar",
      value: routes.jobs.Base + "/calendar",
    },
    {
      label: "Project list",
      value: routes.jobs.Base + "/statusboard",
    },
    {
      label: "Map",
      value: routes.jobs.Base + "/map",
    },
    {
      label: "Support",
      value: routes.help.Base,
    },
  ];

  const findRoute = (route: any) => {
    history.push(route);
  };

  const handlePersonalInfo = () => setPersonalInfo(false);

  const handleModalClose = () => setVisible(false);

  const handleModalUpdate = () => {
    setVisible(false);
    history.push(routes.profile.MySubscription);
  };

  useEffect(() => {
    if (userData?.company?.stripe_subscription) {
      const { status, next_payment } = userData.company.stripe_subscription;
      const last_4 = userData?.company?.stripe_subscription.last_4;
      if (last_4) {
        setLast4(last_4);
      }
      if (next_payment) {
        const daysLeft =
          moment(next_payment)
            .startOf("day")
            .diff(moment().startOf("day"), "days") + 3;
        if (daysLeft > 0) {
          setDaysLeft(daysLeft);
        }
      }
      if (status === "past_due") {
        setVisible(true);
      }
    }
  }, [userData]);

  return (
    <FlexField>
      <PaymentWarningModal
        visible={visible}
        last_4={last4}
        days_left={daysLeft}
        onOk={handleModalUpdate}
        onCancel={handleModalClose}
      />
      <StyledFlex>
        <SearchIcon />
        <StyledSelect
          showSearch
          options={technicianRole ? searchOptionsTechnician : searchOptions}
          onSelect={findRoute}
          placeholder="Search here"
          suffixIcon={null}
          getPopupContainer={(trigger: any) => trigger.parentElement}
        />
      </StyledFlex>
      <Container id="header_wrapper">
        <IconButtonsWrap>
          {isXS ? (
            <MenuWrapper onClick={onMenuOpen}>
              <MenuOutlined />
            </MenuWrapper>
          ) : null}
          {technicianRole ? (
            <HeaderBtn onClick={() => onMessage()}>
              <img src={IconMessage} alt="Add message" />
            </HeaderBtn>
          ) : userData?.company ? (
            <DropD
              trigger={["click"]}
              visible={plusModal}
              onVisibleChange={() => setPlusModal(!plusModal)}
              getPopupContainer={() =>
                document.getElementById("header_wrapper") as HTMLElement
              }
              overlay={() => (
                <PlusModal
                  onCustomer={onCustomer}
                  onVessel={onVessel}
                  plusModal={plusModal}
                  onMessage={onMessage}
                  onAppointment={onAppointment}
                  setPlusModal={setPlusModal}
                />
              )}
              arrow
              placement="bottomCenter"
            >
              <HeaderBtn>
                <img src={Plus} alt="Plus" />
              </HeaderBtn>
            </DropD>
          ) : null}
          {userData?.company ? (
            <DropD
              trigger={["click"]}
              visible={notificationsVisible}
              onVisibleChange={() => setNotificationsVisible((v) => !v)}
              getPopupContainer={() =>
                document.getElementById("header_wrapper") as HTMLElement
              }
              overlay={() => (
                <NotificationsModal
                  visible={notificationsVisible}
                  onClose={() => setNotificationsVisible(false)}
                />
              )}
              arrow
            >
              <HeaderBtn>
                <span className={"wrapper"}>
                  <img src={Bell} alt="Bell" />
                  {unreadNotifications ? <span className="new" /> : null}
                </span>
              </HeaderBtn>
            </DropD>
          ) : null}
          {userData?.company ? <TasksModal /> : null}
          {!isXS ? (
            <DropD
              trigger={["click"]}
              visible={personalInfo}
              onVisibleChange={() => setPersonalInfo(!personalInfo)}
              overlay={() => (
                <PersonalInfo
                  profile={userData}
                  handleClose={handlePersonalInfo}
                  handlePasswordChange={handlePersonalInfo}
                />
              )}
              arrow
              getPopupContainer={() =>
                document.getElementById("header_wrapper") as HTMLElement
              }
            >
              <HeaderBtn onClick={() => setPersonalInfo(true)}>
                <InitialsLetters>{getInitials(userData)}</InitialsLetters>
                <span
                  className={
                    checkNotActive(subscriptionStatus)
                      ? "EllipseRed"
                      : "EllipseGreen"
                  }
                />
              </HeaderBtn>
            </DropD>
          ) : null}
        </IconButtonsWrap>
        {!isXS ? (
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            flexWrap="wrap"
            style={{ whiteSpace: "nowrap" }}
          >
            <UserNameText>{userData?.full_name ?? ""}</UserNameText>
            <UserCompanyText>{userData?.company?.name ?? ""}</UserCompanyText>
          </Flex>
        ) : null}
      </Container>
      <CustomerModal
        onSuccess={() => {}}
        visible={customer}
        onClose={() => setCustomer(false)}
      />
      <VesselsModal
        onSuccess={() => {}}
        visible={vessel}
        onClose={() => setVessel(false)}
      />
      <MessagesModal
        visible={messageModal}
        onClose={() => setMessageModal(false)}
      />
      <AppointmentModal
        visible={appointment}
        onClose={() => setAppointment(false)}
      />
    </FlexField>
  );
};
