import React, { useCallback, useEffect, useRef, useState } from "react"
import { FormInstance, Form, Button } from "antd"
import moment from "moment"
import { useSelector } from "react-redux"
import {
   SideContentWrapper,
   SideContentBlock,
   SelectCardTitle,
   SideContentLabel,
   UnderlineInfo,
   CustomDivider,
   BlockTitle,
   MessagesContainer,
   TotalContainer,
   PaddedContainer,
   ActivitiesContainer,
} from "../styled"
import { Msg } from "../../../ui/Text"
import Flex from "../../../noui/Flex"
import { Activities } from "../../jobs/content/Activities"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import { EJobPriority, ICreateJob, IJob, IJobMaterial, IJobService, IMessage, IWorkOrderWithJobs, IWSMessage } from "../../../api/types"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import NotificationClose from "../../../assets/images/NotificationClose.svg"
import WorkorderPanelOpen from "../../../assets/images/WorkorderPanelOpen.svg"
import { Api } from "../../../api/api"
import { TInputChangeHandler } from "../../../types"
import { useTechnicianRole, useWebSocket } from "../../../utils/hooks"
import { Messages } from "../../../components/Messages/Messages"
import { formatToUSD } from "../../../utils/utils"

type TProps = {
   form: FormInstance
   workOrder: IWorkOrderWithJobs | null
}

type TCalculations = {
   services: number
   materials: number
   deposit: number
   discount: number
   grand: number
}

export const calculateRows = <Item extends IJobService | IJobMaterial>(items: Item[]): number => {
   return items.map((s) => (s?.qty || 0) * (s?.unit_price || 0)).reduce((acc, i) => acc + i, 0)
}

type label = [string, keyof TCalculations]

const mainLabels: label[] = [
   ["Total Parts", "materials"],
   ["Total Labor", "services"],
]

export const RecurringSideContent: React.FC<TProps> = ({ form, workOrder }) => {
   const user = useSelector(getCurrentUser)
   const isXs = useBreakpoint(down("xs"))
   const isLg = useBreakpoint(down("lg"))

   const [showSummary, setShowSummary] = useState(false)
   const [showTotals, setShowTotals] = useState(false)
   const [showActivity, setShowActivity] = useState(false)
   const [isMessagesHide, setIsMessagesHide] = useState<boolean>(false)
   const [message, setMessage] = useState<string>("")
   const [messages, setMessages] = useState<IMessage[]>([])
   const [loading, setLoading] = useState(false)
   const messageRef = useRef<any>(null)
   const [isTotalHide, setIsTotalHide] = useState<boolean>(false)
   const [isActivitiesHide, setIsActivitiesHide] = useState<boolean>(true)
   const technicianRole = useTechnicianRole()

   const calculatePrices = (form: FormInstance<ICreateJob>): TCalculations => {
      const sVals = form.getFieldValue("services") || []
      const services = calculateRows(sVals)
      const mVals = form.getFieldValue("materials") || []
      const materials = calculateRows(mVals)
      const deposit = Number(form.getFieldValue("required_deposit") || 0)
      const discount: number = 0
      const grand: number = services + materials - discount

      return { services, materials, deposit, discount, grand }
   }

   const formatPrice = (price: number | undefined) =>
      price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

   const getFullPriority = (priority: string | null | EJobPriority | undefined) => {
      switch (priority) {
         case EJobPriority.Low:
            return "Low"
         case EJobPriority.Medium:
            return "Medium"
         case EJobPriority.High:
            return "High"
         case EJobPriority.Urgent:
            return "Urgent"
         default:
            return null
      }
   }

   useEffect(() => {
      loadData()
      form.resetFields()
   }, [form])

   const loadData = async () => {
      try {
         setLoading(true)
         const { data } = await Api.messages.getAll({ work_order: workOrder?.id })
         setMessages(data as IMessage[])
      } finally {
         setLoading(false)
      }
   }

   const handleMessage = useCallback((data: IWSMessage<IMessage>) => {
      setMessages((messages) => [data.message, ...messages])
   }, [])

   const handleChangeMessage: TInputChangeHandler = ({ target: { value } }) => {
      setMessage(value)
   }

   useWebSocket(String(workOrder?.id), "W", handleMessage)

   const handleSave = async () => {
      try {
         const { data } = await Api.messages.create({
            message,
            ...({ work_order: workOrder?.id }),
         })
         setMessages([data, ...messages])
         form.resetFields()
         setMessage("")
      } catch (e) {
      }
   }

   return (
      <SideContentWrapper>
         <SideContentBlock>
            {!technicianRole && (
               <TotalContainer isHide={isTotalHide} isFlex={!isTotalHide}>
                  <Flex justifyContent={"space-between"} style={{ width: "100%" }}>
                     <PaddedContainer style={{ width: "100%" }}>
                        <Flex justifyContent="space-between">
                           <SelectCardTitle>job totals</SelectCardTitle>
                           <Msg
                              color={"#0496FF"}
                              style={{
                                 cursor: "pointer",
                                 textDecoration: "underline",
                                 fontWeight: "bold",
                                 fontSize: "13px",
                              }}
                              onClick={() => setIsTotalHide((prev) => !prev)}
                           >
                              {isTotalHide ? "Show" : "Hide"}
                           </Msg>
                        </Flex>

                        {!isTotalHide && (
                           <>
                              <Form.Item shouldUpdate style={{ margin: 0 }}>
                                 {() => {
                                    const prices = calculatePrices(form)
                                    return (
                                       <>
                                          {!technicianRole &&
                                             mainLabels.map(([label, key]) => {
                                                return (
                                                   <Flex alignItems="center" justifyContent="space-between" key={key}>
                                                      <SideContentLabel>{label}</SideContentLabel>
                                                      <SideContentLabel>
                                                         {formatToUSD(parseFloat(String(prices[key])))}
                                                      </SideContentLabel>
                                                   </Flex>
                                                )
                                             })}
                                          {!technicianRole && (
                                             <>
                                                <CustomDivider />
                                                <Flex
                                                   flexDirection="column"
                                                   style={{ width: isLg ? "100%" : "60%", marginLeft: "auto" }}
                                                >
                                                   <Flex alignItems="center" justifyContent="space-between">
                                                      <SideContentLabel>Subtotal</SideContentLabel>
                                                      <SideContentLabel>
                                                         {formatToUSD(workOrder?.subtotal)}
                                                      </SideContentLabel>
                                                   </Flex>
                                                   <Flex alignItems="center" justifyContent="space-between">
                                                      <UnderlineInfo>Taxes</UnderlineInfo>
                                                      <SideContentLabel>
                                                         {formatToUSD(workOrder?.tax ?? 0)}
                                                      </SideContentLabel>
                                                   </Flex>
                                                   <Flex alignItems="center" justifyContent="space-between">
                                                      <UnderlineInfo>EPA Fees</UnderlineInfo>
                                                      <SideContentLabel>
                                                         {formatToUSD(workOrder?.epa ?? 0)}
                                                      </SideContentLabel>
                                                   </Flex>
                                                </Flex>
                                                <CustomDivider />
                                                <Flex alignItems="center" justifyContent="space-between">
                                                   <Msg fontSize={16} fontWeight={"bold"} color="#FB4D4F">
                                                      Remaining Balance
                                                   </Msg>
                                                   <Msg fontSize={16} fontWeight={"bold"} color="#FB4D4F">
                                                      {formatToUSD(workOrder?.total || prices.grand)}
                                                   </Msg>
                                                </Flex>
                                             </>
                                          )}
                                       </>
                                    )
                                 }}
                              </Form.Item>
                           </>
                        )}
                     </PaddedContainer>
                  </Flex>
               </TotalContainer>
            )}

            <MessagesContainer isHide={isMessagesHide} isFlex={!isMessagesHide}>
               <Messages
                  selectedJob={null}
                  ref={messageRef}
                  messages={messages}
                  message={message}
                  handleToggleSideBarComponent={() => setIsMessagesHide(!isMessagesHide)}
                  isHide={!isMessagesHide}
                  onSend={handleSave}
                  onChangeMessage={handleChangeMessage}
                  maxHeight={"300px"}
                  woWidget={true}
               />
            </MessagesContainer>

            <ActivitiesContainer isHide={isActivitiesHide} isFlex={!isActivitiesHide}>
               <Flex justifyContent={"space-between"}>
                  <PaddedContainer style={{ width: "100%" }}>
                     <Flex justifyContent={"space-between"} style={{ width: "100%" }}>
                        <SelectCardTitle>Job Activity</SelectCardTitle>
                        <Msg
                           color={"#0496FF"}
                           style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontWeight: "bold",
                              fontSize: "13px",
                           }}
                           onClick={() => setIsActivitiesHide((prev) => !prev)}
                        >
                           {isActivitiesHide ? "Show" : "Hide"}
                        </Msg>
                     </Flex>
                     {!isActivitiesHide && <Activities activities={[]} />}
                  </PaddedContainer>
               </Flex>
            </ActivitiesContainer>

         </SideContentBlock>
      </SideContentWrapper>
   )
}
