// My Business
export const pricePattern = new RegExp("\\d{1,}[,\\.]?(\\d{1,2})?")
// My customers
export const emailPattern =
   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phonePattern = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/
export const tagsReGex = /(#\w+#)/g
export const textInBrackets = /\{(.*?)\}/g
export const companyName = /[a-zA-Z]/g
export const phoneNumberPattern = /^\+?1?\d{9,15}$/
