import { useEffect, useState } from "react"
import { loadPayengine } from "payengine"
import { PayEnginePK } from "../../api"
import { useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"

const transactionStyles = `
.btn.Ripple-parent.btn-blue.pf-button-filter {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    background-color: #1C99FC !important;
    border-radius: 6px;
}
.btn.btn-sm.btn-primary.px-2.pf-filter-button-done {
    font-family: "Nunito Sans", sans-serif;
    font-size: 10px;
    font-weight: 600;
    background-color: #1C99FC !important;
    border-radius: 6px;
}
.btn.Ripple-parent.btn-outline-default {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #494949 !important;
    background-color: #EDEDED !important;
    border: 1px solid #C2CFE0 !important;
    border-radius: 4px;
}
.card-body {
    font-family: "Nunito Sans", sans-serif;
}
.page-item .page-link {
    border-radius: 0 !important;
}
.page-item .page-link:hover {
    border: 1px solid #1890ff;
}
.active.page-item .page-link {
    background-color: #1C99FC !important;
}
.tabulator-row {
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    margin-bottom: 8px;
}
.modal-content .modal-header {
    padding: 16px 24px 0;
}
.modal-content h4 {
    font-size: 28px;
}
.modal-content .modal-title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px !important;
    font-weight: bold;
}
.modal-content .btn.Ripple-parent.btn-outline-primary.btn-rounded {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #494949 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #C2CFE0 !important;
    border-radius: 4px;
}
.modal-content .btn.Ripple-parent.btn-primary {
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    background-color: #1C99FC !important;
    border-radius: 6px;
}
.custom-control-label {
    padding-top: 4px;
}
.custom-control.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #096DD9;
}
`

export const CreditCardPayments = () => {
   const user = useAppSelector(getCurrentUser)
   const [payengine, setPayengine] = useState(null)

   useEffect(() => {
      loadPayengine({
         publicKey: PayEnginePK,
      }).then((pe) => {
         setPayengine(new pe())
      })
   }, [])

   const isPaymentsReady = () => !!(user?.company && payengine && user.company.merchant_id && user.company.mid_hash)

   return (
      <>
         {isPaymentsReady() && (
            <>
               <style id="custom-transaction-style">{transactionStyles}</style>
               <pay-engine
                  id="pf-transactions"
                  css="#custom-transaction-style"
                  type="transactions"
                  merchant-id={user.company.merchant_id}
                  default-filter='{\"amount\":{\"from\":100,\"to\":500},\"statuses\":[\"succeeded\",\"incomplete\",\"uncaptured\"],\"method\":\"Credit Card\"}'
                  hash={user.company.mid_hash}
               ></pay-engine>
            </>
         )}
      </>
   )
}
