import { TArgCallback } from "../../types"

import TableRowMobile from "./TableRowMobile"
import MaterialsFormMobile from "./MaterialsFormMobile"

type TProps = {
   data: any
   handleEditMaterial: TArgCallback<any>
   deleteMaterial: TArgCallback<any>
   duplicateMaterial: TArgCallback<any>
   group: any
   groups: any
   handleCreateGroupe: TArgCallback<any>
   type?: string
}

const MaterialsTableMobile: React.FC<TProps> = ({
   data,
   handleEditMaterial,
   deleteMaterial,
   duplicateMaterial,
   group,
   groups,
   handleCreateGroupe,
   type,
}) => {
   return (
      <>
         {data &&
            data.length > 0 &&
            data.map((item: any) => {
               return (
                  <TableRowMobile
                     key={item.id}
                     item={item}
                     handleEditMaterial={handleEditMaterial}
                     deleteMaterial={deleteMaterial}
                     duplicateMaterial={duplicateMaterial}
                     group={group}
                     groups={groups}
                     handleCreateGroupe={handleCreateGroupe}
                     type={type}
                  />
               )
            })}
      </>
   )
}
export default MaterialsTableMobile
