import { Button, Modal, Switch } from "antd";
import Flex from "../../noui/Flex";
import {
  AfterOnBoardingBlock,
  AfterOnBoardingBlockHeader,
  PaymentsHeaderText,
} from "./styled";
import StartedCC from "../../assets/images/settings/StartedCC.svg";
import TDS from "../../assets/images/settings/3DS.svg";
import { Msg } from "../../ui/Text";
import { Api } from "../../api/api";
import { formatCompanyPaymentStatus } from "../../utils/utils";
import { useState } from "react";
import styled from "styled-components";

type TProps = {
  status?: boolean;
  isAdmin?: boolean;
  makeChangeInStripe: () => void;
};
const ModalContent = styled(Flex)`
  flex-direction: column;
  width: 292px;
  padding: 16px 44px 16px 16px;
`;

const AfterOnBoarding: React.FC<TProps> = ({
  status,
  isAdmin,
  makeChangeInStripe,
}) => {

  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <PaymentsHeaderText style={{ width: "50%" }}>
        {isAdmin
          ? "DockWorks Payments makes it easy for your business to accept credit cards-online and in-person-with the most competitive rates in the industry."
          : "Dockworks Payments makes accepting credit cards simple for you and your customers, whether in-person via a card reader or online form an invoice."}
      </PaymentsHeaderText>
      <AfterOnBoardingBlock>
        <Flex flexDirection="row" width="100%" justifyContent="space-between">
          <Flex alignItems="center">
            <img src={StartedCC} alt="Credit Card" />
            <AfterOnBoardingBlockHeader>
              Bank Account
            </AfterOnBoardingBlockHeader>
            <Msg color="#828282" fontSize="13px" marginRight="4px">
              Status:
            </Msg>
            <Msg color={status ? "#27AE60" : "#484848"} fontWeight={"bold"} fontSize="13px">
              {formatCompanyPaymentStatus(status)}
            </Msg>
          </Flex>
        </Flex>
        {isAdmin ? (
          <>
            <Flex flexDirection="column" margin="16px 0 24px">
              <Msg color="#484848" fontSize="13px" marginBottom="24px">
                Your business owner has submitted an application.
              </Msg>
              <Msg color="#484848" fontSize="13px">
                A DockWorks representative will respond within 2 business days.
              </Msg>
            </Flex>
          </>
        ) : (
          <>
            <Flex flexDirection="column" margin="16px 0 24px">
              {!status && (
                <Msg color="#484848" fontSize="13px" marginBottom="24px">
                  YOUR PAYMENTS ACCOUNT IS BEING CONFIGURED
                  <br />
                  You have successfully registered your company for online
                  payments through DockWorks. We are generating your account and
                  will notify you via email upon completion (typically 2-4
                  hours). Once your account is configured, you can begin
                  accepting payments via credit card, ACH, and more!
                </Msg>
              )}
            </Flex>
          </>
        )}
        <Flex justifyContent={"space-between"}>
          <Msg

              color="#109CF1"
              fontSize="13px"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={makeChangeInStripe}
          >
           Go to Stripe
          </Msg>
          <Msg
            fontSize="11px"
            color={"#494949"}
            width={"286px"}
            height={"34px"}
          >
            If you'd like to pause, update, or cancel your banking information
            email{" "}
            <Msg
              color="#109CF1"
              fontSize="13px"
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {" "}
           <a href={"mailto:support@dockworks.co"}>   support@dockworks.co</a>
            </Msg>
          </Msg>
        </Flex>
      </AfterOnBoardingBlock>
    </Flex>
  );
};

export default AfterOnBoarding;
