import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import DataImportChooseType from "./DataImportChooseType"
import DataImportMain from "./DataImportMain"
import GetStartedMobile from "../GetStartedMobile"
import Settings from "../Settings"

const DataImport = () => {
   const [importType, setImportType] = useState<string | null>(null)
   const isLg = useBreakpoint(down("lg"))

   const handleCancelUploading = () => {
      setImportType(null)
   }

   const renderContent = () => {
      if (isLg) {
         return <GetStartedMobile mainText="DockWorks on your laptop or desktop computer to import your data." />
      }
      if (!importType) {
         return <DataImportChooseType setImportType={setImportType} />
      }
      return <DataImportMain importType={importType} handleCancel={handleCancelUploading} />
   }

   return (
      <>
         <Helmet>
            <title>Import Data - DockWorks Pro</title>
         </Helmet>
         <Settings>{renderContent()}</Settings>
      </>
   )
}

export default DataImport
