import React, { useEffect } from "react"
import moment from "moment"
import Flex from "../../../noui/Flex"
import { Modal, Checkbox, Radio, Form, DatePicker } from "antd"
import styled from "styled-components"
import { TArgCallback, TCallback } from "../../../types"
import { EJobProgress } from "../../../api/types"
import { constants } from "zlib"

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 20px 0 0 0;

      h3 {
         text-transform: uppercase;
         padding: 0 15px;
         font-weight: 900;
         font-size: 15px;
      }
      h4 {
         padding: 0 15px;
         font-weight: 700;
         font-size: 16px;
         color: #494949;
         margin-bottom: 15px;
      }

      .text {
         padding: 0 15px;
         margin-bottom: 15px;
         p {
            padding: 15px 0;
            font-size: 18px;
            border-bottom: 1px solid #ededed;
            line-height: 1.2;
         }
      }
      .radioBnts {
         padding: 0 15px;
      }
   }
   .ant-modal-footer {
      padding: 15px 15px 15px 20px;
   }
`
const Group = styled.div`
   label {
      display: flex;
      padding: 15px 15px;
      border-top: 1px solid #ededed;

      span {
         font-size: 13px;
      }
      .ant-checkbox + span {
         padding-right: 8px;
         padding-left: 15px;
      }
   }
`
export const DateInputs = styled(Flex)`
   position: relative;

   @media (max-width: 800px) {
      width: 100%;
      margin-bottom: 10px;
      & .ant-form-item,
      & .ant-col,
      & .ant-picker {
         width: 100%;
      }
   }
   & .ant-picker-body {
      padding-bottom: 20px;
   }

   & .picker {
      position: relative;

      & .circle {
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         z-index: 10;
         cursor: default;
         & .circle__inner {
            width: 12px;
            height: 5px;
            bottom: -7px;
            left: 50%;
            margin-left: -6px;
            position: absolute;
            display: none;
            z-index: 1;
            &:before {
               position: absolute;
               bottom: 0;
               left: 0;
               content: "";
               width: 12px;
               height: 10px;
               background: #fff;
               transform: rotate(-45deg);
               box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.11);
            }
         }

         &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1px;
            left: 50%;
            margin-left: -2.5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
         }
         &:before {
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -55px;
            width: 110px;
            background: #fff;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            padding: 2px;
            font-size: 11px;
            display: none;
         }
         &:hover {
            &:before {
               display: block;
            }
         }
         &.green,
         &.blue,
         &.red,
         &.darkBlue {
            &:hover {
               .circle__inner {
                  display: block;
               }
            }
         }

         &.green {
            &:before {
               content: "• Сompleted";
               color: #42c77b;
            }
            &:after {
               background: #42c77b;
            }
         }
         &.blue {
            &:before {
               content: "• In progress";
               color: #0496ff;
            }
            &:after {
               background: #0496ff;
            }
         }
         &.red {
            &:before {
               content: "• Need to complete";
               color: #fb4d4f;
            }
            &:after {
               background: #fb4d4f;
            }
         }
         &.darkBlue {
            &:before {
               content: "• Upcoming";
               color: #257abf;
            }
            &:after {
               background: #257abf;
            }
         }
      }
   }
`

export const CustomDivider = styled.div`
   width: 8px;
   height: 1px;
   background: #8b8b8b;
   margin: 44px 5px 0 5px;
`

type TProps = {
   handlePrint: TCallback
   setPrintModalRecurring: TArgCallback<any>
   setPrintBlocks: TArgCallback<any>
   showPrintModalRecurring: boolean
   checkboxes: any
   technicianRole: boolean
   setRecurringPrintPeriod: TArgCallback<any>
   recurringPrintPeriod: any
   jobs: any
}

const ModalRecurringCheckboxes: React.FC<TProps> = ({
   handlePrint,
   setPrintModalRecurring,
   showPrintModalRecurring,
   checkboxes,
   setPrintBlocks,
   technicianRole,
   setRecurringPrintPeriod,
   recurringPrintPeriod,
   jobs,
}) => {
   const [period, setPeriod] = React.useState("all")
   const [form] = Form.useForm()
   const [canSubmit, setCanSubmit] = React.useState(false)

   const onCheckAllChange = () => {
      setPrintBlocks({
         woPricing: !checkboxes.checkAll,
         woPhotos: !checkboxes.checkAll,
         vesselPhoto: !checkboxes.checkAll,
         beforeAfterPhotos: !checkboxes.beforeAfterPhotos,
         checkAll: !checkboxes.checkAll,
      })
   }

   const onCheckChange = (e: any) => {
      const { name, checked } = e.target
      setPrintBlocks((prev: any) => {
         return {
            ...prev,
            [name]: checked,
         }
      })
   }

   const onChangePeriod = (e: any) => {
      setPeriod(e.target.value)
   }

   const onChange = (type: string, value: any) => {
      setRecurringPrintPeriod({
         ...recurringPrintPeriod,
         [type]: value,
      })
   }

   useEffect(() => {
      if (checkboxes.woPricing && checkboxes.woPhotos && checkboxes.vesselPhoto && checkboxes.beforeAfterPhotos) {
         setPrintBlocks((prev: any) => {
            return {
               ...prev,
               checkAll: true,
            }
         })
      } else {
         setPrintBlocks((prev: any) => {
            return {
               ...prev,
               checkAll: false,
            }
         })
      }
   }, [checkboxes.woPricing, checkboxes.woPhotos, checkboxes.vesselPhoto, checkboxes.beforeAfterPhotos])

   const isValidEndTime = (value: any) => {
      const from = form.getFieldValue("from")
      try {
         if (value < from) {
            setCanSubmit(false)
            return Promise.reject("Start date can't be after end date")
         }
         setCanSubmit(true)
         return Promise.resolve()
      } catch (error) {
         return
      }
   }

   const handlePrintServicePlan = () => {
      handlePrint()
      if (period === "period") {
         form.setFieldsValue({
            from: null,
            to: null,
         })
      }
      setPeriod("all")
      setPrintModalRecurring(false)
   }

   const renderJobType = (date: any) => {
      let type = ""
      if (jobs && jobs.length > 0) {
         jobs.forEach((job: any) => {
            const start = moment(job.schedules[0].start).format("L")
            const currentDay = moment(date).format("L")
            if (currentDay === start) {
               const progress = job?.progress
               const completed = job?.completed_at
               const start_date = moment(start)
               const today_date = moment()
               if (progress === EJobProgress.Complete) {
                  if (completed) {
                     type = "green"
                  }
               } else if (progress === EJobProgress.Work || progress === EJobProgress.UnFinished) {
                  type = "blue"
               } else if (start_date.isSameOrBefore(today_date, "days") && progress === EJobProgress.Start) {
                  type = "red"
               } else if (start_date.isAfter(today_date, "days")) {
                  type = "darkBlue"
               }
            }
         })
      }
      return type
   }

   const onLoad = () => {
      let table = document.querySelector(".ant-picker-content")
      if (table) {
         const rows = table.getElementsByTagName("td")

         for (let i = 0; i < rows.length; i++) {
            rows[i].title = ""
         }
      }
   }

   return (
      <StyledModal
         destroyOnClose
         centered
         visible={showPrintModalRecurring}
         onOk={handlePrintServicePlan}
         onCancel={() => setPrintModalRecurring(false)}
         okText="Print"
         okButtonProps={{
            disabled: period === "all" ? false : !canSubmit,
         }}
      >
         <Form form={form} layout="vertical" requiredMark={false}>
            <h3>Print service plan</h3>
            <h4>Choose Date Range</h4>
            <div className="radioBnts">
               <Radio.Group onChange={onChangePeriod} value={period}>
                  <Radio value={"period"}>Specific Period</Radio>
                  <Radio value={"all"}>View All</Radio>
               </Radio.Group>
               {period === "period" && (
                  <DateInputs>
                     <Form.Item
                        label="From"
                        name="from"
                        rules={[{ required: true, message: "This field is required" }]}
                     >
                        <DatePicker
                           onChange={(value: any) => onChange("from", value)}
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           showToday={false}
                           dateRender={(current) => {
                              const type = renderJobType(current)
                              onLoad()
                              return (
                                 <div className="picker">
                                    {current.date()}
                                    <div className={`circle ${type}`}>
                                       <div className="circle__inner"></div>
                                    </div>
                                 </div>
                              )
                           }}
                        />
                     </Form.Item>
                     <CustomDivider />
                     <Form.Item
                        label="To"
                        name="to"
                        rules={[
                           { required: true, message: "This field is required" },
                           () => ({
                              validator(_, value) {
                                 return isValidEndTime(value)
                              },
                           }),
                        ]}
                     >
                        <DatePicker
                           onChange={(value: any) => onChange("to", value)}
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           showToday={false}
                           dateRender={(current) => {
                              const type = renderJobType(current)
                              onLoad()
                              return (
                                 <div className="picker">
                                    {current.date()}
                                    <div className={`circle ${type}`}>
                                       <div className={`circle ${type}`}>
                                          <div className="circle__inner"></div>
                                       </div>
                                    </div>
                                 </div>
                              )
                           }}
                        />
                     </Form.Item>
                  </DateInputs>
               )}
            </div>
            <div className="text">
               <p>Select the information that you would like to include in your printable service plan</p>
            </div>
            <h4>Display Options</h4>
            <Group>
               {!technicianRole && (
                  <Checkbox onChange={onCheckChange} checked={checkboxes.woPricing} name="woPricing">
                     Service Plan Pricing
                  </Checkbox>
               )}

               <Checkbox onChange={onCheckChange} checked={checkboxes.woPhotos} name="woPhotos">
                  Service Plan Photos and Notes
               </Checkbox>
               <Checkbox onChange={onCheckChange} checked={checkboxes.vesselPhoto} name="vesselPhoto">
                  Vessel Profile Photo
               </Checkbox>
               <Checkbox onChange={onCheckChange} checked={checkboxes.beforeAfterPhotos} name="beforeAfterPhotos">
                  Before and After Photos
               </Checkbox>
               <Checkbox onChange={onCheckAllChange} checked={checkboxes.checkAll} name="checkAll">
                  Select all
               </Checkbox>
            </Group>
         </Form>
      </StyledModal>
   )
}
export default ModalRecurringCheckboxes
