import React, { useEffect, useState } from "react";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import Box from "../../noui/Box";
import { AutoComplete, Form, Modal, notification } from "antd";
import PlusInCircule from "../../assets/images/PlusInCircule.svg";
import { EmptyButton, PrimaryButton } from "../../ui/Button";
import styled from "styled-components";
import {
  ftToM,
  getErrors,
  getFullVesselType,
  mapOptions,
  setDefaultFields,
} from "../../utils/utils";
import { Api } from "../../api/api";
import { VesselForm } from "./VesselForm";
import { ICreateVessel, IPaginatedResponse, IVessel } from "../../api/types";
import { TArgCallback, TCallback, TOption } from "../../types";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import { track } from "../../utils/analytics";
import customers from "../customers/Customers";

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 16px;
  }

  & .ant-modal-footer {
    display: none;
  }
  & .ant-form-item {
    margin-bottom: 0;
  }
`;

const ModalCancelBtn = styled(PrimaryButton)`
  width: 92px;
  height: 34px;
  background-color: #ffffff;
  border: 0.7px solid #1d3443;
  color: #1d3443;
  &:hover {
    border: 0.7px solid #1d3443;
  }
`;

const ModalCreateBtn = styled(PrimaryButton)`
  width: 80px;
  height: 34px;
  border: 0.7px solid #1c99fc;
`;

const AddAnotherPhoneButton = styled(EmptyButton)`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  & img {
    margin-right: 8px;
  }
`;

const ArrowButton = styled(EmptyButton)`
  text-align: left;
  padding: 0;
  height: 20px;
`;

const ContainerMainButtons = styled(Flex)`
  border-top: 1px solid #e6e6e6;
`;

const SearchContainer = styled(Flex)`
  & .ant-row.ant-form-item {
    width: 100%;
  }
`;

const SearchVessels = styled(AutoComplete)`
  width: 100%;
  & .ant-row.ant-form-item {
    width: 100%;
  }
`;

const blankForm = [{ name: "engines", value: [{}], touched: false }];

type TProps = {
  payload?: IVessel;
  onClose: TCallback;
  onSuccess?: TArgCallback<IVessel>;
  visible: boolean;
};
export const VesselsModal: React.FC<TProps> = ({
  visible,
  payload,
  onClose,
  onSuccess,
}) => {
  const [saving, setSaving] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<number | null>(null);
  const [options, setOptions] = useState<TOption[]>([]);
  const [addVesselManually, setAddVesselManually] = useState(true);
  const [form] = Form.useForm<ICreateVessel & { lengthType?: string }>();
  const [searchForm] = Form.useForm();
  const profile = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      searchForm.resetFields();
      if (payload) {
        setDefaultFields(form, payload);
        setAddVesselManually(true);
      } else {
        form.setFields(blankForm);
        // setAddVesselManually(false);
      }
    }
  }, [visible, form, searchForm, payload]);

  const getVesselLabel = (vessel: IVessel) => {
    return `${vessel.name} - ${vessel.hin}`;
  };

  const onSearch = (searchText: string) => {
    setSearch(searchText);
    if (selected) {
      setSelected(null);
    }
    Api.vessels
      .getAll({
        pageSize: 5,
        page: 1,
        search,
      })
      .then(({ data: dt }) => {
        const data = dt as IPaginatedResponse<IVessel[]>;
        setOptions(mapOptions(data.results, getVesselLabel));
      });
  };
  const handleSelectVessel = (val: string, o: any) => {
    const option = o as TOption;
    setSearch(option.label);
    setSelected(Number(val));
    searchForm.setFields([{ name: "searchVessels", value: option.label }]);
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      const vesselsData = await form.getFieldsValue();
      const action = payload?.id ? Api.vessels.patch : Api.vessels.create;
      const { data } = await action({
        id: payload?.id as number,
        ...vesselsData,
        length:
          vesselsData?.lengthType === "m"
            ? (ftToM(vesselsData.length, true) as number)
            : vesselsData.length,
        beam:
          vesselsData?.beam && vesselsData?.beam?.toString() !== ""
            ? vesselsData?.beam
            : undefined,
        draft:
          vesselsData?.draft && vesselsData?.draft?.toString() !== ""
            ? vesselsData?.draft
            : undefined,
      });

      onSuccess && onSuccess(data);
      setSaving(false);
      onClose();
      const eventName = payload?.id ? "Vessel Edited" : "Vessel Added";
      await track(eventName, {
        vesselCreated_by: profile?.id,
        companyId: profile?.company?.id,
        customerId: data.customer?.id,
        vesselId: data?.id,
        vesselCity: data.address?.city,
        vesselState: data.address?.state,
        vesselLengthFeet: data.length,
        vesselType: getFullVesselType(data.vessel_type),
      });
    } catch (e) {
      const errors = getErrors(e);
      form.setFields(errors);
      setSaving(false);
    }
  };

  return (
    <StyledModal
      centered
      visible={visible}
      onOk={() => {}}
      onCancel={onClose}
      okText="Create"
      width={384}
    >
      <Flex flexDirection="column">
        <Msg fontWeight={900} fontSize={15} color="#151F2B">
          {!payload ? "NEW VESSEL" : "UPDATE VESSEL"}
        </Msg>
        {/*!addVesselManually ? <Box>
        <Form form={searchForm} onFinish={handleAssignVessel}>
          <SearchContainer flexDirection='column' alignItems='center' mt='16px' mb='12px'>
            <Msg fontWeight={700} fontSize={13} color="#484848">Select vessel by</Msg>
            <Form.Item
              name='searchVessels'
            >
              <SearchVessels
                placeholder='Enter Name, Make, Model, Hull ID'
                options={options}
                onSearch={onSearch}
                value={search}
                onSelect={handleSelectVessel}
              />
            </Form.Item>
          </SearchContainer>
          <AddAnotherPhoneButton height="20px" onClick={() => setAddVesselManually(true)} mb='20px'>
            <img src={PlusInCircule} alt="icon"/>
            <Msg fontWeight={700} fontSize={13} color="#109CF1">Add Vessel manually</Msg>
          </AddAnotherPhoneButton>
          <ContainerMainButtons justifyContent='flex-end' pt='16px'>
            <ModalCancelBtn onClick={onClose}><Msg fontWeight={600} fontSize={14}>Cancel</Msg></ModalCancelBtn>
            <ModalCreateBtn ml={16} htmlType="submit" disabled={!selected} loading={saving}>Save</ModalCreateBtn>
          </ContainerMainButtons>
        </Form>
      </Box> : !payload ? <ArrowButton onClick={() => setAddVesselManually(false)}>
        <Msg fontWeight={700} fontSize={13} color="#109CF1">
          {'<'} Back to new vessel
        </Msg>
      </ArrowButton> : null*/}
      </Flex>
      <VesselForm
        form={form}
        saving={saving}
        handleSubmit={handleSubmit}
        isOpen={addVesselManually}
        onClose={onClose}
      />
    </StyledModal>
  );
};
