import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {down} from "styled-breakpoints";
import {Msg} from "../../ui/Text";
import {ContentStyled, TableWrapper} from "../customers/pages/styled";
import {useBreakpoint} from "styled-breakpoints/react-styled";
import Flex from '../../noui/Flex';
import {SearchWithBorder} from "../../noui/Search";
import {useDebounce} from "use-debounce";
import {ICompany} from "../../api/types";
import {Api} from "../../api/api";
import {usePagination} from "../../utils/hooks";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {LinkButton} from "../../layouts/jobs/components";
import {useAppDispatch} from "../../config/hooks";
import {setCurrentUser} from "../../config/reducers/user/actions";
import {useHistory} from "react-router-dom";
import Routes from "../../routes/routes";
import LOGO from "../../assets/images/gear_logo.png";

const Label = styled(Msg)`
  text-transform: uppercase;
`;

const CompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ImgWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
`;

const TitleBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; 
`;

const StyledLinkButton = styled(LinkButton)`
  border: none;
  color: #0496FF;
`;

export const SuperUserDashboard: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const isXs = useBreakpoint(down('xs'));
  const isLg = useBreakpoint(down('lg'));
  const [dbSearch] = useDebounce(search, 1000);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {data, loading, onPromise, requestPagination, pagination, onChange} = usePagination<ICompany>()

  const columns: ColumnsType<ICompany> = useMemo(() => [
    {
      title: "Company Name",
      dataIndex: "name",
      sorter: true,
      ellipsis: true,
      className: "companyName",
      render: (_, record) => <CompanyWrapper>
        <ImgWrapper><img src={record.logo ?? LOGO} alt={`${record.name} logo`}/></ImgWrapper>
        <TitleBlockWrapper>
          <Msg fontSize={'15px'} fontWeight={700}>{record.name}</Msg>
          <Msg fontSize={'13px'} fontWeight={400}>Company Owner: {record.first_name} {record.last_name}</Msg>
        </TitleBlockWrapper>
      </CompanyWrapper>,
    },
    {
      title: "Location",
      dataIndex: "address",
      sorter: true,
      ellipsis: true,
      className: "address",
      render: (_, record) => (record.address?.city || record.address?.state) ?
        <TitleBlockWrapper>
          <Msg fontSize={'15px'} fontWeight={700}>Business location:</Msg>
          <Msg fontSize={'13px'} fontWeight={400}>{record.address.city} {record.address.state}</Msg>
        </TitleBlockWrapper>
        : null
    },
    {
      title: "",
      dataIndex: "id",
      sorter: false,
      ellipsis: false,
      className: "action",
      render: (_, record) => <StyledLinkButton onClick={() => {
        Api.company.impersonate(record.id).then(({data: user}) => {
          dispatch(setCurrentUser(user));
          history.replace(Routes.main.Dashboard);
        })
      }}>
        Impersonate Account
      </StyledLinkButton>
    }
  ], [dispatch, history]);

  const loadCompanies = useCallback(async () => {
    await onPromise(
      Api.company.getAll({
        ...requestPagination,
        search: dbSearch,
      })
    );
  }, [onPromise, requestPagination, dbSearch]);

  useEffect(() => {
    loadCompanies().finally();
  }, [loadCompanies]);


  return (
    <Flex p={isXs ? '14px' : '34px'} pt='15px' flexDirection='column'>
      <Msg fontWeight={800} fontSize={24} mb='18px' mt={isXs ? '14px' : '76px'}>Super Admin</Msg>
      <ContentStyled flexDirection="column" py="20px" px={isLg ? "14px" : isXs ? "4px" : "24px"}>
        <Label fontWeight={900} mb={"8px"} fontSize={15}>Search Account</Label>
        <SearchWithBorder placeholder="Search" onChange={({target: {value}}) => setSearch(value)}/>
        <TableWrapper>
          <Table
            loading={loading}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data}
            pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
            onChange={onChange}
            className="contained companies-table"
          />
        </TableWrapper>
      </ContentStyled>
    </Flex>
  );
};