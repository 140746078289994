import React, { useEffect, useState } from "react"
import Flex from "../../noui/Flex"
import { Title } from "../../components/Title"
import { Container } from "../../components/Container"
import classes from "./styles.module.scss"
import { SideContent } from "./content/SideContent"
import { MainContent } from "./content/MainContent"
import { SendEstimateModal } from "./SendEstimateModal"
import { VesselSelector } from "./selectors/VesselSelector"
import { CustomerSelector } from "./selectors/CustomerSelector"
import { blankPromise, getErrors, showErrors } from "../../utils/utils"
import { Api } from "../../api/api"
import { Form, notification } from "antd"
import { useHistory, useLocation, useParams } from "react-router-dom"
import PaymentsModal from "../../modals/Payments/PaymentsModal"
import routes from "../../routes/routes"
import { Content } from "../../components/Content"
import { EstimateStatuses } from "../../constants"
import { ScheduleEstimate } from "./content/ScheduleEstimate"
import { JobStatus } from "./content/JobStatus"
import { ICreateJob, ICustomer, IJob, IUpdate } from "../../api/types"
import { TArgCallback, TDateRangeValue } from "../../types"

export const CreateEstimate = () => {
   const [estimate, setEstimate] = useState<IJob | null>(null)
   const [calendarOpened, setCalendarOpen] = useState<boolean>(false)
   const [loading, setLoading] = useState<boolean>(false)
   const [processing, setProcessing] = useState<boolean>(false)
   const [cancelling, setCancelling] = useState<boolean>(false)
   const [scheduling, setScheduling] = useState<boolean>(false)
   const [form] = Form.useForm<ICreateJob & IUpdate>()
   const [visible, setVisible] = useState<boolean>(false)
   const [saving, setSaving] = useState<boolean>(false)
   const [modal, setModal] = useState<boolean>(true)
   const history = useHistory()
   const { id: pageEstimateId } = useParams<{ id?: string }>()
   const location = useLocation<{ customer?: ICustomer } | undefined>()

   useEffect(() => {
      form.resetFields()
      setEstimate(null)
      if (pageEstimateId) {
         setLoading(true)
         Api.jobs
            .get(Number(pageEstimateId))
            .then(({ data }) => {
               setEstimate(data)
               form.setFieldsValue(data)
            })
            .catch(() => {
               // TODO: Redirect back?
            })
            .finally(() => {
               setLoading(false)
            })
      }
   }, [form, pageEstimateId, location?.state?.customer])

   const handleSendEstimate = async () => {
      return await handleSaveEstimate().then((r) => {
         setVisible(true)
         return Promise.resolve(r)
      })
   }

   const handleArchive = async () => {
      if (estimate?.id) {
         try {
            const is_archived = !estimate.is_archived
            const { data } = await Api.jobs.patch({ id: estimate.id })
            setEstimate(data)
            notification.success({
               message: `#${estimate.order_number} successfully ${is_archived ? "archived" : "unarchived"}`,
            })
            // TODO: Redirect to?
         } catch (e) {
            showErrors(getErrors(e))
         }
      }
   }

   const handleProceedWithoutApproval = async () => {
      const {
         data: { id },
      } = await handleSaveEstimate()
      setSaving(true)
      try {
         await Api.jobs.proceed(id)
         history.push(routes.jobs.UpdateEstimate.replace(":id", String(id)))
      } catch (e) {
         getErrors(e)
      } finally {
         setSaving(false)
      }
   }

   const handleSaveEstimate = async () => {
      setSaving(true)
      try {
         const data = await form.validateFields()
         const action = data.id ? Api.jobs.patch : Api.jobs.create
         const resp = await action(data)
         setEstimate(resp.data)
         return resp
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors)
         throw e
      } finally {
         setSaving(false)
      }
   }

   const handleSaveAndClose = async () => {
      await handleSaveEstimate()
      // TODO: After header completed - get redirect url
      history.push("/")
   }

   const handleSchedule: TArgCallback<TDateRangeValue> = (dates) => {
      if (dates) {
         setScheduling(true)
         Api.jobs
            .schedule({
               id: estimate?.id ?? 0,
               start: dates[0]?.toISOString() ?? "",
               end: dates[1]?.toISOString() ?? "",
            })
            .then(({ data }) => {
               setEstimate(data)
            })
            .catch((e) => showErrors(getErrors(e)))
            .finally(() => {
               setScheduling(false)
            })
      }
   }

   const handleStartJob = () => Api.jobs.start(estimate?.id ?? 0)
   const handleStopJob = () => Api.jobs.stop(estimate?.id ?? 0)
   const handleFinishJob = () => Api.jobs.finish({ jobId: estimate?.id ?? 0 })
   const handleInvoice = () => Api.jobs.invoice(estimate?.id ?? 0)

   const getActionLabel = () => {
      switch (getActiveStep()) {
         case 0:
            return "Send estimate"
         case 1:
            return "Schedule"
         case 2:
            return "Start"
         case 3:
            return "Finish"
         case 4:
            return "Send Invoice"
         case 5:
            return "Complete Job"
         default:
            return ""
      }
   }

   const getCurrentAction = (): void => {
      let action
      setProcessing(true)
      switch (getActiveStep()) {
         case 0:
            action = handleSendEstimate
            break
         case 1:
            action = handleOpenCalendar
            break
         case 2:
            action = handleStartJob
            break
         case 3:
            action = handleFinishJob
            break
         case 4:
            action = handleInvoice
            break
         default:
            action = blankPromise(estimate)
      }
      action()
         .then(({ data }) => setEstimate(data))
         .catch((e) => {
            showErrors(getErrors(e))
         })
         .finally(() => {
            setProcessing(false)
         })
   }

   const handleOpenCalendar = () => {
      setCalendarOpen(true)
      return blankPromise(estimate)()
   }

   const getUndoAction = (): void => {
      let action
      setCancelling(true)
      switch (getActiveStep()) {
         case 3:
            action = handleStopJob
            break
         default:
            action = blankPromise(estimate)
      }
      action()
         .then(({ data }) => {
            setEstimate(data)
         })
         .finally(() => {
            setCancelling(false)
         })
   }

   const getActiveStep = (): number => {
      if (estimate?.id) {
         if (estimate?.status === EstimateStatuses.Invoice) {
            return 5
         } else if (estimate?.finished) {
            return 4
         } else if (estimate?.started) {
            return 3
         } else if (estimate?.schedules.length) {
            return 2
         } else if (estimate?.status === EstimateStatuses.ToBeScheduled) {
            return 1
         }
         return 0
      }
      return 0
   }

   const getTitle = (): string => {
      if (estimate) {
         if (estimate.status !== EstimateStatuses.Estimate) {
            return "Job Details"
         }
         return "Update Estimate"
      }
      return "Create Estimate"
   }
   const isChangeCustomerDisabled = (): boolean => {
      return Boolean(!estimate || (estimate && (estimate.send_config || estimate.status !== EstimateStatuses.Estimate)))
   }

   return (
      <>
         {/* <PaymentsModal visible={modal} onClose={() => setModal(false)} workOrder={estimate}/> */}
         <Form form={form} onFinish={handleSaveEstimate}>
            <Flex p="34px" pt="15px" flexDirection="column" mt={loading ? 76 : undefined}>
               <Content loading={loading}>
                  <Title>{getTitle()}</Title>
                  <Container padded>
                     <div className={classes.grid}>
                        <JobStatus
                           processing={processing}
                           cancelling={cancelling}
                           job={estimate || undefined}
                           activeStep={getActiveStep()}
                           onUndo={getUndoAction}
                           onAction={getCurrentAction}
                        />
                        <VesselSelector form={form} disabled={isChangeCustomerDisabled()} />
                        <CustomerSelector
                           form={form}
                           estimate={estimate || undefined}
                           disabled={isChangeCustomerDisabled()}
                        />
                        <ScheduleEstimate
                           estimate={estimate || undefined}
                           calendarOpened={calendarOpened}
                           onChangeCalendar={setCalendarOpen}
                           saving={scheduling}
                           onSchedule={handleSchedule}
                        />
                        <SideContent
                           onAction={getCurrentAction}
                           onArchive={handleArchive}
                           actionLabel={getActionLabel()}
                           saving={saving || processing}
                           onProceed={handleProceedWithoutApproval}
                           onSaveAndClose={handleSaveAndClose}
                           form={form}
                           estimate={estimate || undefined}
                           toggleModal={setModal}
                        />
                        <div className={classes.content}>
                           <MainContent form={form} estimate={estimate || undefined} />
                        </div>
                     </div>
                  </Container>
               </Content>
            </Flex>
         </Form>
         {/* <SendEstimateModal estimate={estimate||undefined} visible={visible} onClose={() => setVisible(false)}/> */}
      </>
   )
}
