import React, { useState, useEffect } from "react"
import { Modal, Form, Input } from "antd"

import { IJobService, IUser } from "../api/types"
import { TCallback, TArgCallback } from "../types"
import Flex from "../noui/Flex"
import { notification } from "antd/es"
import { Api } from "../api/api"
import { getErrors } from "../utils/utils"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import moment from "moment"
import { track } from "../utils/analytics"
import { ClockIcon } from "../assets/icons"
import { CalendarPicker } from "../components/CalendarPicker"
import "react-datepicker/dist/react-datepicker.css"

type TProps = {
   visible: boolean
   handleClose: TCallback
   technician?: IUser
   estimateId?: number
   jobs?: IJobService[]
   updateTimer: TArgCallback<any>
}

export const MobileWrapper = styled.div`
   ${down("lg")} {
      .ant-picker-datetime-panel {
         flex-direction: column !important;
      }
   }
   & .ant-form-item-explain.ant-form-item-explain-error {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      line-height: 1.1;
   }
   h3 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
   }
   .errorMessage {
      color: #ff4d4f;
      font-size: 13px;
   }
`
export const Duration = styled(Flex)`
   svg {
      margin-right: 10px;
   }
`
export const CalendarWrapper = styled.div`
   .ant-form-item-control-input {
      min-height: 0;
   }
`

export const AddTimeManuallyBoard: React.FC<TProps> = ({
   visible,
   handleClose,
   estimateId,
   jobs,
   technician,
   updateTimer,
}) => {
   const [form] = Form.useForm()
   const [duration, setDuration] = useState(" Select Time in and Time out ")
   const [startDate, setStartDate] = useState<any>(null)
   const [endDate, setEndDate] = useState<any>(null)
   const [error24, setError24] = useState<string>("")

   useEffect(() => {
      if (startDate) {
         form.setFieldsValue({ start: moment(startDate) })
      }
   }, [startDate])

   useEffect(() => {
      if (endDate) {
         form.setFieldsValue({ stop: moment(endDate) })
      }
   }, [endDate])

   useEffect(() => {
      calcDuration()
   }, [startDate, endDate])

   const clearModal = () => {
      form.resetFields()
      setDuration("Select Time in and Time out")
      handleClose()
   }

   const handleSubmit = async () => {
      const { start, stop, job } = await form.validateFields()
      try {
         if (moment(start).isAfter(moment(stop))) {
            notification.error({ message: "Start time should be before stop time!" })
            return
         }
         if (!error24) {
            const { data } = await Api.timeClocks.create({
               job: job || estimateId,
               user: technician?.id!,
               start,
               stop,
            })
            track("Tech Added Time Manually", {
               workorderId: data.job.work_order,
               vesselId: data.job?.vessel.id,
               technician: data.user.id,
               jobId: data.job.id,
               timeLoggedTimestamp: data.start,
               timeLoggedHours: moment(data.stop).diff(moment(data.start), "hours"),
            })
            updateTimer(data)
            setDuration("Select Time in and Time out")
            clearModal()
         }
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors)
         notification.error({
            message: "Fields 'Time In' and 'Time Out' are required",
         })
      }
   }

   const calcDuration = () => {
      const { start, stop } = form.getFieldsValue()
      let result: any = " Select Time in and Time out "

      if (start && stop) {
         if (stop < start) {
            result = "Start date can't be after end date"
            setError24("")
         } else {
            const timeInMilliseconds = moment.duration(stop.diff(start)).asMilliseconds()
            const h = Math.floor(timeInMilliseconds / 1000 / 60 / 60)
            const m = Math.floor((timeInMilliseconds / 1000 / 60 / 60 - h) * 60)
            if (h > 24 || (h === 24 && m > 0)) {
               setError24("The duration can't be more than 24 hours")
            } else {
               setError24("")
            }
            result = `${h} hours ${m} mins`
         }
      }

      setDuration(result)
      return result
   }

   return (
      <Modal
         visible={visible}
         onCancel={clearModal}
         title="Add time manually"
         okText="Save"
         onOk={handleSubmit}
         width={400}
      >
         <MobileWrapper>
            <Form layout="vertical" form={form} requiredMark={false} onValuesChange={calcDuration}>
               <Form.Item label="Technician" initialValue={technician?.id}>
                  <Input value={technician?.full_name} disabled />
               </Form.Item>

               <Form.Item name="job" label="Job list">
                  <Input placeholder={jobs && jobs.length > 0 ? jobs[0].name : "none"} disabled />
               </Form.Item>

               <Form.Item shouldUpdate name="activity" label="Activity">
                  <Input placeholder="General" disabled />
               </Form.Item>
               <Flex justifyContent="space-between" alignItems="center">
                  <CalendarWrapper>
                     <CalendarPicker label="Time in" date={startDate} setDate={setStartDate} />

                     <Form.Item
                        shouldUpdate
                        name="start"
                        rules={[
                           {
                              required: true,
                              message: "This field is required",
                           },
                        ]}
                        style={{ minHeight: 0, height: 0 }}
                     ></Form.Item>
                  </CalendarWrapper>

                  <div
                     style={{
                        height: "1px",
                        backgroundColor: "rgba(130, 130, 130, 1)",
                        width: "8px",
                        margin: "11px 8px 0",
                     }}
                  ></div>
                  <CalendarWrapper>
                     <CalendarPicker label="Time out" date={endDate} setDate={setEndDate} />
                     <Form.Item
                        shouldUpdate
                        name="stop"
                        rules={[
                           {
                              required: true,
                              message: "This field is required",
                           },
                        ]}
                        style={{ minHeight: 0, height: 0 }}
                     ></Form.Item>
                  </CalendarWrapper>
               </Flex>
               {error24 && <p className="errorMessage">{error24}</p>}

               <Form.Item shouldUpdate label="Duration">
                  <Duration alignItems="center">
                     <ClockIcon /> {duration}
                  </Duration>
               </Form.Item>
               <Form.Item shouldUpdate name="notes" label="Notes">
                  <Input.TextArea style={{ resize: "none" }} />
               </Form.Item>
            </Form>
         </MobileWrapper>
      </Modal>
   )
}
