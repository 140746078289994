import { forwardRef, useEffect, useState } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Msg } from "../../ui/Text"
import { renderAddress, mapOptions, formatToUSD } from "../../utils/utils"
import { servicesUnitTypes, vesselTypes } from "../../constants"
import { IUser, EUserRole, EJobPriority } from "../../api/types"
import { TOption } from "../../types"
import Flex from "../../noui/Flex"
import { Api } from "../../api/api"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import Photos from "./Photos"

import logo from "../../assets/images/dockworks_logo.png"
import parse from "html-react-parser";

const Wrapper = styled.div`
   background: #fff;
   table {
      width: 100%;
   }
   @page {
      margin: 30px 30px 2cm 30px;
   }

   @media all {
      .pagebreak {
         display: none;
      }
   }

   @media print {
      .pagebreak {
         page-break-before: always;
      }
      .noBreak {
         page-break-inside: avoid;
      }
      .topHeader {
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         height: 155px;
         overflow: hidden;
      }
      @page {
         margin: 30px 30px 2cm 30px;
         //counter-increment: page;
         //counter-reset: page 1;
         // @bottom-right-corner {
         //    content: "Page " counter(page);
         // }
         // @bottom-center {
         //    counter-increment: page;
         //    counter-reset: page 1;
         //    content: "Page " counter(page) " of " counter(pages);
         // }
         // .footer {
         //    position: fixed;
         //    bottom: 0;
         //    right: 20px;
         //    //counter-increment: page;
         //    &:after {
         //       counter-increment: page;
         //       content: "Page " counter(page);
         //    }
         // }
      }
   }
`
const Header = styled(Flex)`
   padding: 20px 0;

   p {
      font-size: 12px;
      margin-bottom: 0;
      span {
         font-weight: bold;
      }
   }

   h3 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 5px;
   }
   h4 {
      font-weight: 900;
      font-size: 13px;
      margin-bottom: 5px;
   }
   div {
      // width: 100%;
   }
   img {
      max-width: 150px;
      max-height: 90px;
      width: 100%;
      height: auto;
      object-fit: contain;
   }
`
const Info = styled(Flex)`
   gap: 30px;
   .infoBlock {
      width: 100%;
   }
   h4 {
      font-weight: bold;
      font-size: 13px;
      text-align: center;
   }
`
const HeaderInfo = styled.div`
   margin-left: 25px;
`
const WOInfo = styled(Flex)`
   width: 100%;
   padding: 7px 15px;
   color: #fff;
   background: #0f497b;
   h3 {
      font-weight: bold;
      font-size: 13px;
      color: #fff;
      margin: 0;
   }
   p {
      font-weight: 600;
      font-size: 12px;
      margin: 0;
   }
`
const WOTitle = styled(Flex)`
   width: 100%;
   padding: 15px;
   h3 {
      font-weight: 900;
      font-size: 13px;
      color: #202020;
      margin: 0;
   }
   p {
      font-weight: 900;
      font-size: 12px;
      margin: 0;
      span {
         color: #8b8b8b;
         font-weight: normal;
      }
   }
`
const EmptyHeader = styled.div`
   width: 100%;
   height: 130px;
   margin-bottom: 20px;
`
const Details = styled.div`
   margin: 24px 0;
   font-size: 12px;
   h4 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      text-align: left;
      text-decoration: underline;
   }
   ul {
      margin: 0 0 15px 0;
      padding-left: 25px;
      li {
         font-weight: bold;
         span {
            font-weight: normal;
         }
      }
   }
   h5 {
      color: #8b8b8b;
      font-weight: normal;
      font-size: 12px;
   }
`
const Customer = styled.div`
   width: 100%;

   min-width: 300px;
`
const CustomerInfo = styled(Flex)`
   width: 100%;
   justify-content: space-between;
   font-size: 12px;
   > div:first-child {
      width: 130px;
   }
   .title {
      font-weight: bold;
   }
   &.leftAligned {
      justify-content: flex-start;
      > div:first-child {
         width: 130px;
      }
   }
`
const Notes = styled.div`
   border: 1px solid #ededed;
   font-size: 12px;
   margin: 20px 0;
   h4 {
      background: #ededed;
      padding: 10px;
      font-weight: bold;
      font-size: 11px;
      margin: 0;
   }
   div {
      min-height: 60px;
      padding: 10px;
      background: #fbfbff;
   }
`
const JobTitle = styled(Flex)`
   margin-top: 20px;
   padding: 10px;
   border-bottom: 1px solid #ededed;
   h3 {
      font-weight: 900;
      font-size: 15px;
      text-transform: uppercase;
      margin: 0;
   }
   p {
      color: #494949;
      font-size: 13px;
      margin: 0;
   }
`
const JobInfo = styled(Flex)`
   margin-bottom: 20px;
   padding: 10px;
   font-size: 13px;
   span {
      font-weight: bold;
   }
`
const JobSummary = styled.div`
   h4 {
      font-weight: bold;
      font-size: 13px;
   }

   p {
      font-size: 12px;
      margin-bottom: 5px;
      span {
         font-weight: bold;
      }
   }
`
const Payments = styled.div`
   padding: 10px;
   border: 1px solid #ededed;
   margin-top: 25px;
`
const PaymentStatus = styled.div`
   border: 1px solid #ededed;
   margin-top: 25px;
   h3 {
      background: #0f497b;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
      margin: 0;
      padding: 7px;
   }
   .paymentBlock {
      width: 50%;
      padding: 10px;
      p {
         font-size: 12px;
         margin-bottom: 10px;
         
      }
   }
   .divider {
      width: 100%;
      height: 1px;
      background: #ededed;
      margin: 5px 0;
   }
`
const VesselHeader = styled.div`
   padding: 10px;
   background: #ededed;
`
const VesselBottom = styled.div`
   padding: 10px;
`
const VesselImg = styled.div`
   width: 100%;
   height: 135px;
   overflow: hidden;
   border-radius: 4px;
   margin-top: 10px;
   img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
   }
`
const Total = styled.div`
   text-align: right;
   h3 {
      font-weight: bold;
      font-size: 15px;
   }

   p {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
      color: #494949;
   }
`
const Table = styled.div`
   margin: 10px 0;
   box-sizing: border-box;
`
const TableItem = styled.div`
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
`
const Services = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 3fr 1fr 1.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 1fr 1.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Materials = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 4fr 1fr 1.5fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 2.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .description {
      padding: 8px;
      font-size: 12px;
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Technician = styled.div`
   background: #fbfbff;
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
   padding-bottom: 4px;
   & .grid {
      display: grid;
      grid-template-columns: 3fr 2fr 1.5fr 1fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;

      > div {
         padding: 4px 8px;
      }
      &.header {
         text-decoration: underline;
         background: #fbfbff;
         font-weight: normal;
      }
   }
`
const Scheduler = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 5.5fr 1.75fr 1.75fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`

const Summary = styled(Flex)`
   justify-content: space-between;
   align-items: flex-start;
   .disclaimers {
      margin-right: 10px;
      h3 {
         text-decoration: underline;
      }
   }
   .summary {
      min-width: 220px;
      padding: 4px 10px;
      border: 1px solid #ededed;
      background: #fbfbff;
      div {
         padding: 3px 0;
      }
   }

   //float: right;
   font-size: 12px;

   & .total {
      font-weight: bold;
      border-top: 1px solid #ededed;
   }
`
const TransactionInfo = styled.div`
   h4,
   p {
      margin: 0 !important;
   }
   h4 {
      font-size: 12px;
   }
`

type Props = {
   workOrder: any
   jobs: any
   userData: any
   technicianRole: boolean
   woPricing: boolean
   woPhotos: boolean
   vesselPhoto: boolean
   isInvoice: boolean
}
export type Ref = HTMLDivElement

const PrintWorkOrder = forwardRef<Ref, Props>(
   ({ workOrder, jobs, userData, technicianRole, woPricing, woPhotos, vesselPhoto, isInvoice }, ref) => {
      const [options, setOptions] = useState<IUser[]>([])


      const user = useSelector(getCurrentUser)
      const invoiceFooter = user?.company?.invoice_settings?.invoice_footer as string
      const estimateFooter = user?.company?.invoice_settings?.estimate_footer as string
      const paymentStatusWO = user?.company?.invoice_settings?.payment_status_wo as string
      const paymentStatusSP = user?.company?.invoice_settings?.payment_status_sp as string

      useEffect(() => {
         Api.user.getAll({}).then(({ data: d }) => {
            const results = d as IUser[]

            setOptions(results)
         })
      }, [])

      const getFullPriority = (priority: string | null | EJobPriority | undefined) => {
         switch (priority) {
            case EJobPriority.Low:
               return "Low"
            case EJobPriority.Medium:
               return "Medium"
            case EJobPriority.High:
               return "High"
            case EJobPriority.Urgent:
               return "Urgent"
            default:
               return null
         }
      }

      const calculateRows = (data: any): number => {
         const minutesToHours = moment.duration(data.estimated_hours).asHours()
         const num = (data.rate ?? 0) * minutesToHours
         return +num.toFixed(3)
      }

      const checkNotPayed = () => Number(workOrder.total.toFixed(2)) > Number(workOrder.deposited.toFixed(2))

      const formatPrice = (price: number | undefined) =>
         price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

      const checkIsReceipt = () => {
         if (isInvoice) {
            if (workOrder?.payments_info && !checkNotPayed()) {
               return true
            }
         } else {
            if (workOrder?.payments_info && workOrder?.required_deposit_amount === workOrder?.deposited) {
               return true
            }
         }
         return false
      }

      return (
         <Wrapper ref={ref}>
            <div className="footer" />
            <Header alignItems="center" className="topHeader">
               <Flex justifyContent="center">
                  <div style={{ width: "150px", height: "90px" }}>
                     <img src={user?.company?.logo ? user.company.logo : logo} alt="logo" />
                  </div>
               </Flex>
               <HeaderInfo>
                  <Flex>
                     <Flex flexDirection="column">
                        <h3>COMPANY</h3>
                        <h4>{userData?.company?.name}</h4>
                        <p>{`${userData?.company?.address?.address1} ${userData?.company?.address?.address2} ${userData?.company?.address?.city}, ${userData?.company?.address?.state}`}</p>
                        <p>{userData?.company?.phone}</p>
                        <p style={{ color: "#0496FF" }}>{userData?.company?.email}</p>
                     </Flex>

                     <Flex alignItems="flex-start" flexDirection="column" marginLeft="55px">
                        <h3>BILLING CONTACT</h3>
                        <h4>{workOrder?.customer?.full_name}</h4>
                        <p>{workOrder?.customer?.address && renderAddress(workOrder?.customer?.address)}</p>
                        <p>
                           {workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")
                              ?.phone || "No data"}
                        </p>
                        <p style={{ color: "#0496FF" }}>{workOrder?.customer?.email}</p>
                     </Flex>
                  </Flex>
               </HeaderInfo>
            </Header>
            <table>
               <thead>
                  <tr>
                     <th>
                        <EmptyHeader />
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        <div className="content">
                           <WOInfo alignItems="center" justifyContent="space-between">
                              <h3>
                                 WORK ORDER {checkIsReceipt() ? "PAYMENT RECEIPT" : isInvoice ? "INVOICE" : "ESTIMATE"}
                              </h3>
                              <p>Sent: {moment(workOrder?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p>
                           </WOInfo>
                           <WOTitle alignItems="center" justifyContent="space-between">
                              <h3>{workOrder?.title}</h3>
                              <p>
                                 <span>Work order:</span> #{workOrder?.order_number}
                              </p>
                           </WOTitle>

                           <Info justifyContent="normal">
                              <div className="infoBlock">
                                 <Customer>
                                    <CustomerInfo className="leftAligned">
                                       <div>Service Writer: </div>
                                       <div className="title">{workOrder?.created_by?.full_name}</div>
                                    </CustomerInfo>
                                    <CustomerInfo className="leftAligned">
                                       <div>Created on: </div>
                                       <div className="title">
                                          {workOrder?.created
                                             ? moment(workOrder.created).format("ddd, M/D/YYYY h:mm A")
                                             : "No data"}
                                       </div>
                                    </CustomerInfo>
                                 </Customer>
                                 <Details>
                                    <h4>Project Details</h4>
                                    <div>{workOrder?.notes || "No notes provided"}</div>
                                 </Details>
                                 <Details>
                                    <h4>SCOPE OF WORK</h4>
                                    <div>
                                       {jobs && jobs.length > 0 && (
                                          <ul>
                                             {jobs.map((job: any) => {
                                                return (
                                                   <li key={job?.id}>
                                                      {job.title} <span>({job.order_number})</span>
                                                   </li>
                                                )
                                             })}
                                          </ul>
                                       )}
                                    </div>
                                    <h5>(Full project summary listed below)</h5>
                                 </Details>
                                 <Customer>
                                    <CustomerInfo className="leftAligned">
                                       <div>Due Date: </div>
                                       <div className="title">
                                          {moment(workOrder?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo className="leftAligned">
                                       <div>Est. Duration: </div>
                                       <div className="title">{workOrder?.estimated_duration ?? "TBD"}</div>
                                    </CustomerInfo>
                                 </Customer>
                              </div>
                              <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
                                 <VesselHeader>
                                    <CustomerInfo>
                                       <div>Vessel:</div>
                                       <div className="title" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                          {workOrder?.vessel?.name}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Hull ID:</div>
                                       <div className="title"> {workOrder?.vessel?.hin}</div>
                                    </CustomerInfo>
                                 </VesselHeader>
                                 <VesselBottom>
                                    <CustomerInfo>
                                       <div>Vessel Type:</div>
                                       <div className="title">
                                          {vesselTypes.find((v) => v.value === workOrder?.vessel?.vessel_type)?.label ||
                                             "No data"}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Location:</div>
                                       <div className="title">{workOrder?.vessel?.location_name}</div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Address:</div>
                                       {workOrder?.vessel?.address && (
                                          <div className="title">{`${workOrder?.vessel?.address?.address1} ${workOrder?.vessel?.address?.address2} ${workOrder?.vessel?.address?.city}, ${workOrder?.vessel?.address?.state}`}</div>
                                       )}
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Slip #:</div>
                                       <div className="title">{workOrder?.vessel?.slip_number}</div>
                                    </CustomerInfo>

                                    {workOrder?.vessel?.image && (
                                       <VesselImg>{<img src={workOrder?.vessel?.image} alt="vessel" />}</VesselImg>
                                    )}
                                 </VesselBottom>
                              </div>
                           </Info>
                           {woPricing && !technicianRole && (
                              <PaymentStatus className="noBreak">
                                 <h3>PAYMENT STATUS</h3>
                                 <Flex>
                                    <div className="paymentBlock">
                                       {checkIsReceipt() ? (
                                          <TransactionInfo>
                                             <h4>THANK YOU FOR YOUR PAYMENT!</h4>
                                             <p style={{ color: "#42C77B", fontWeight: "bold" }}>
                                                Amount Paid: ${workOrder?.payments_info.amount}
                                             </p>
                                             <p>Date: {moment(workOrder?.payments_info.date).format("l LT")}</p>
                                             <p>
                                                Transaction ID: #
                                                {workOrder?.payments_info?.payment_intent?.intent_id
                                                   ? workOrder?.payments_info?.payment_intent?.intent_id.slice(3)
                                                   : "-"}
                                             </p>
                                             <p>
                                                Payment{" "}
                                                {workOrder?.payments_info?.payment_intent?.payment_details?.type ===
                                                "card"
                                                   ? "Card"
                                                   : "Bank Account"}
                                                : **** **** ****{" "}
                                                {workOrder?.payments_info?.payment_intent?.payment_details?.type ===
                                                "card"
                                                   ? workOrder?.payments_info?.payment_intent?.payment_details?.card
                                                        .last4
                                                   : workOrder?.payments_info?.payment_intent?.payment_details
                                                        ?.us_bank_account.last4}
                                             </p>
                                          </TransactionInfo>
                                       ) : workOrder?.is_recurring ? (
                                          <p>{parse(paymentStatusSP || "")}</p>
                                       ) : (
                                          <p>{parse(paymentStatusWO || "")}</p>
                                       )}
                                    </div>
                                    <div className="paymentBlock">
                                       <Customer>
                                          <CustomerInfo>
                                             <div>Work Order Total:</div>
                                             <div>{formatToUSD(workOrder?.total)}</div>
                                          </CustomerInfo>
                                          <CustomerInfo>
                                             <div style={{ fontWeight: "bold", color: "#42C77B" }}>Paid To-Date:</div>
                                             <div style={{ fontWeight: "bold", color: "#42C77B" }}>
                                                {formatToUSD(workOrder?.deposited)}
                                             </div>
                                          </CustomerInfo>
                                          <div className="divider" />
                                          <CustomerInfo>
                                             <div style={{ color: "#FB4D4F" }}>Payment Due (Deposit):</div>
                                             <div style={{ color: "#FB4D4F", fontWeight: "bold", fontSize: "15px" }}>
                                                {formatToUSD(workOrder?.additional_payment_amount ?? 0)}
                                             </div>
                                          </CustomerInfo>
                                          <CustomerInfo>
                                             <div style={{ color: "#8B8B8B" }}>Remaining after this payment: </div>
                                             <div style={{ color: "#8B8B8B" }}>
                                                {(workOrder && formatToUSD(workOrder.total - workOrder.deposited)) || 0}
                                             </div>
                                          </CustomerInfo>
                                       </Customer>
                                    </div>
                                 </Flex>
                              </PaymentStatus>
                           )}

                           {jobs &&
                              jobs.length > 0 &&
                              jobs.map((job: any) => {
                                 return (
                                    <div key={job?.id}>
                                       <div className="noBreak">
                                          <JobTitle justifyContent="space-between">
                                             <h3>{job.title}</h3>
                                             <p>(Job {job.order_number})</p>
                                          </JobTitle>
                                          <JobInfo alignItems="center" justifyContent="space-between">
                                             <div>
                                                Start Date & Time:{" "}
                                                <span>
                                                   {moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}
                                                </span>
                                             </div>
                                             <div>
                                                Estimated Duration: <span>{job?.estimated_duration ?? "TBD"}</span>
                                             </div>
                                          </JobInfo>
                                       </div>
                                       <Table>
                                          {job?.services && job.services.length > 0 && (
                                             <Services className="noBreak">
                                                <div
                                                   className={`grid header ${
                                                      technicianRole || !woPricing ? "tech" : ""
                                                   }`}
                                                >
                                                   <div className="name">LABOR</div>
                                                   <div className="desc">Description</div>
                                                   <div className="qty">Qty</div>
                                                   {woPricing && !technicianRole && <div className="rate">Rate</div>}

                                                   <div className="type">Type</div>
                                                   {woPricing && !technicianRole && <div className="total">Total</div>}
                                                </div>

                                                {job.services.map((item: any) => {
                                                   const type = servicesUnitTypes.find(
                                                      (s) => s.value === item.unit_type
                                                   )
                                                   return (
                                                      <TableItem key={item.id}>
                                                         <div
                                                            className={`grid ${
                                                               technicianRole || !woPricing ? "tech" : ""
                                                            }`}
                                                         >
                                                            <div className="name">{item.name}</div>
                                                            <div className="desc">{item.description}</div>
                                                            <div className="qty">{item.qty.toFixed(2)}</div>
                                                            {woPricing && !technicianRole && (
                                                               <div className="rate">{formatToUSD(item.unit_price)}</div>
                                                            )}

                                                            <div className="type">{type && type.label}</div>
                                                            {woPricing && !technicianRole && (
                                                               <div className="total" style={{ fontWeight: "bold" }}>
                                                                  {formatToUSD(item.unit_price * item.qty)}
                                                               </div>
                                                            )}
                                                         </div>
                                                      </TableItem>
                                                   )
                                                })}
                                                {job?.technicians_data && job.technicians_data.length > 0 && (
                                                   <Technician>
                                                      <div className={`grid header`}>
                                                         <div />
                                                         <div>Technician(s)</div>
                                                         <div>Estimated Hours</div>
                                                         <div />
                                                         <div />
                                                         <div />
                                                      </div>

                                                      {job.technicians_data.map((item: any) => {
                                                         return (
                                                            <div key={item.id} className={`grid`}>
                                                               <div />
                                                               <div>{item?.technician?.full_name}</div>
                                                               <div>{item.estimated_hours}</div>
                                                               <div />
                                                               <div />
                                                               <div />
                                                            </div>
                                                         )
                                                      })}
                                                   </Technician>
                                                )}
                                             </Services>
                                          )}
                                       </Table>
                                       <Table>
                                          {job?.materials && job.materials.length > 0 && (
                                             <Materials className="noBreak">
                                                <div
                                                   className={`grid header ${
                                                      technicianRole || !woPricing ? "tech" : ""
                                                   }`}
                                                >
                                                   <div className="name">MATERIALS</div>

                                                   <div className="desc">Description</div>
                                                   <div className="qty">Qty</div>
                                                   {woPricing && !technicianRole && (
                                                      <>
                                                         <div className="price">Unit price</div>
                                                         <div className="total">Total</div>
                                                      </>
                                                   )}
                                                </div>

                                                {job.materials.map((item: any) => {
                                                   return (
                                                      <TableItem key={item.id}>
                                                         <div
                                                            className={`grid ${
                                                               technicianRole || !woPricing ? "tech" : ""
                                                            }`}
                                                         >
                                                            <div className="name">{item.name}</div>

                                                            <div>{item.description}</div>
                                                            <div className="qty">{item.qty}</div>
                                                            {woPricing && !technicianRole && (
                                                               <>
                                                                  <div className="price">{formatToUSD(item.unit_price)}</div>
                                                                  <div className="total" style={{ fontWeight: "bold" }}>
                                                                     {formatToUSD(item.unit_price * item.qty)}
                                                                  </div>
                                                               </>
                                                            )}
                                                         </div>
                                                      </TableItem>
                                                   )
                                                })}
                                             </Materials>
                                          )}
                                       </Table>

                                       {woPricing && !technicianRole && (
                                          <div className="noBreak">
                                             <Summary>
                                                <div className="disclaimers">
                                                   <h3>DISCLAIMERS</h3>
                                                   {isInvoice ? (
                                                      <p>{parse(invoiceFooter || "")}</p>
                                                   ) : (
                                                      <p>{parse(invoiceFooter || "")}</p>
                                                   )}
                                                </div>
                                                <div className="summary">
                                                   <Flex justifyContent="space-between">
                                                      <div>Labor</div>
                                                      <div>{formatToUSD(job.total_labor)}</div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>Materials</div>
                                                      <div>{formatToUSD(job.total_materials)}</div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>Subtotal</div>
                                                      <div>
                                                         {formatToUSD(
                                                            job !== null ? job.total_labor + job.total_materials : 0
                                                         )}
                                                      </div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>Taxes</div>
                                                      <div>{formatToUSD(job !== null ? job.tax : 0)}</div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>EPA fees</div>
                                                      <div>{formatToUSD(job !== null ? job.epa : 0)}</div>
                                                   </Flex>
                                                   <Flex className="total" justifyContent="space-between">
                                                      <div>Job total</div>
                                                      <div>{formatToUSD(job?.total)}</div>
                                                   </Flex>
                                                </div>
                                             </Summary>
                                          </div>
                                       )}
                                       {woPhotos && <Photos job={job} />}
                                    </div>
                                 )
                              })}
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </Wrapper>
      )
   }
)

export default PrintWorkOrder
