import { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import routes from "../../routes/routes"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import ArrowImage from "../../assets/images/onboard/Arrow.svg"
import { NavLink } from "react-router-dom"
import CarouselContainer from "./CarouselContainer"
import { Modal } from "antd"
import { useAppDispatch } from "../../config/hooks"
import { setCurrentUser } from "../../config/reducers/user/actions"
import { Api } from "../../api/api"
import { EUserRole } from "../../api/types"
import { Animation } from "./Animation"
import { useTechnicianRole } from "../../utils/hooks"

const JobsContainer = styled(Flex)`
   position: relative;
   min-height: 635px;
   max-height: 635px;
   max-width: 604px;
   min-width: 604px;
   width: 100%;
   height: 100%;
   margin-bottom: 36px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   padding: 40px;
   justify-content: center;
   @media (max-width: 700px) {
      min-width: 100%;
      max-height: 100%;
      padding: 50px 20px 20px 20px;
   }
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
`

const StartButton = styled(PrimaryButton)`
   width: 200px;
   height: 40px;
   background: #0496ff;
   font-size: 15px;
   &:hover {
      border: 0.7px solid #0496ff;
      color: #0496ff;
   }
`

const ShowLaterButton = styled(EmptyButton)`
   color: #404040;
   font-weight: bold;
   font-size: 15px;
   &:hover {
      color: #109cf1;
   }
`

const NextButton = styled(PrimaryButton)`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 50%;
   right: -18px;
   width: 36px;
   height: 36px;
   border-radius: 50%;
   text-align: center;
   @media (max-width: 700px) {
      top: 15px;
      right: 15px;
   }
   &:hover {
      background: #109cf1;
   }
   &:focus {
      background: #109cf1;
   }
   &.ant-btn:hover {
      background: #109cf1;
   }
   &.ant-btn:focus {
      background: #109cf1;
   }
`

const PrevButton = styled(PrimaryButton)`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 50%;
   left: -18px;
   width: 36px;
   height: 36px;
   border-radius: 50%;
   text-align: center;
   transform: rotate(180deg);
   @media (max-width: 700px) {
      top: 15px;
      left: 15px;
   }
   &:hover {
      background: #109cf1;
   }
   &:focus {
      background: #109cf1;
   }
   &.ant-btn:hover {
      background: #109cf1;
   }
   &.ant-btn:focus {
      background: #109cf1;
   }
`

const HelpButton = styled(NavLink)`
   color: #0099ff;
   font-size: 18px;
   font-weight: 600;
   text-decoration: underline;
`
const StyledModal = styled(Modal)`
   & .ant-modal-body {
      padding: 0px;
   }
   & .ant-modal-footer {
      display: none;
   }
   & .ant-modal-close {
      display: none;
   }
   & .ant-form-item {
      margin-bottom: 0;
   }
`

const EntryScreen = () => {
   const userData = useSelector(getCurrentUser)
   const [nextSlide, setNextSlide] = useState(false)
   const [carouselIndex, setCarouselIndex] = useState(0)
   const [visible, setVisible] = useState(false)

   const dispatch = useAppDispatch()

   const slidesData = [
      {
         title: (
            <Msg fontWeight="900" fontSize="24px" textAlign="center" mt="40px" lineHeight="1.3">
               HI, {userData?.first_name}! <br />
               IT`S GREAT TO HAVE YOU ON BOARD.
            </Msg>
         ),
         description: (
            <Msg
               fontWeight="400"
               fontSize="18px"
               textAlign="center"
               mt="12px"
               lineHeight="1.3"
               display="block"
               style={{ maxWidth: "475px" }}
            >
               You just created a new account! We’ll show you a quick overview of Work Order creation.
            </Msg>
         ),
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="24px" textAlign="center" mt="40px">
               IF YOU HAVE
               <br /> ANY QUESTIONS
            </Msg>
         ),
         description: (
            <Msg fontWeight="400" fontSize="18px" textAlign="center" mt="12px" style={{ maxWidth: "350px" }}>
               Please, visit our <HelpButton to={routes.help.Base}>Help page</HelpButton>. <br />
               There you can find the most frequently asked questions. Thanks!
            </Msg>
         ),
      },
   ]
   const slidesDataTechnician = [
      {
         title: (
            <Msg fontWeight="900" fontSize="24px" textAlign="center" mt="40px" lineHeight="1.3">
               HI, {userData?.first_name}! <br />
               IT`S GREAT TO HAVE YOU ON BOARD.
            </Msg>
         ),
         description: (
            <Msg
               fontWeight="400"
               fontSize="18px"
               textAlign="center"
               mt="12px"
               lineHeight="1.3"
               display="block"
               style={{ maxWidth: "475px" }}
            >
               You have been invited by your employer to create your DockWorks account! We’ll show you how to interact
               with your technician dashboard.
            </Msg>
         ),
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="24px" textAlign="center" mt="40px">
               IF YOU HAVE
               <br /> ANY QUESTIONS
            </Msg>
         ),
         description: (
            <Msg fontWeight="400" fontSize="18px" textAlign="center" mt="12px" style={{ maxWidth: "350px" }}>
               Please, visit our <HelpButton to={routes.help.Base}>Help page</HelpButton>. <br />
               There you can find the most frequently asked questions. Thanks!
            </Msg>
         ),
      },
   ]

   const loadCards = useCallback(() => {
      if (userData && !userData.onboarding_completed) {
         setVisible(true)
      }
   }, [userData])

   useEffect(() => {
      loadCards()
   }, [loadCards])

   const ChangeSlide = () => {
      setNextSlide(!nextSlide)
      setCarouselIndex(1)
   }

   const onClose = async () => {
      // TODO: Save data
      try {
         const { data } = await Api.user.completeOnboarding()
         dispatch(setCurrentUser(data))
      } finally {
         setVisible(false)
      }
   }
   const onShowLater = () => {
      if (userData) {
         dispatch(setCurrentUser({ ...userData, onboarding_completed: true }))
         setVisible(false)
      }
   }
   const technicianRole = useTechnicianRole()
   const slides = technicianRole ? slidesDataTechnician : slidesData

   return (
      <StyledModal centered visible={visible} onOk={() => {}} onCancel={onClose} okText="Create" width="fit-content">
         {" "}
         {!nextSlide ? (
            <JobsContainer alignItems="center" flexDirection="column">
               <Animation />

               {!nextSlide && carouselIndex !== 5 ? slides[0].title : slides[1].title}
               {!nextSlide && carouselIndex !== 5 ? slides[0].description : slides[1].description}
               {!nextSlide && carouselIndex !== 5 ? (
                  <Flex flexDirection="column" mt="50px">
                     <StartButton mb="15px" onClick={ChangeSlide}>
                        Start tour
                     </StartButton>
                     <ShowLaterButton onClick={onShowLater}>Show later</ShowLaterButton>
                  </Flex>
               ) : (
                  <StartButton mt="30px" mb="20px" onClick={onClose}>
                     Got it
                  </StartButton>
               )}
               {!nextSlide && carouselIndex !== 5 ? (
                  <NextButton onClick={ChangeSlide}>
                     <img src={ArrowImage} alt="Arrow" />
                  </NextButton>
               ) : (
                  <PrevButton onClick={ChangeSlide}>
                     <img src={ArrowImage} alt="Arrow" />
                  </PrevButton>
               )}
            </JobsContainer>
         ) : (
            <CarouselContainer
               carouselIndex={carouselIndex}
               setCarouselIndex={setCarouselIndex}
               nextSlide={nextSlide}
               setNextSlide={setNextSlide}
               onClose={onClose}
               technician={technicianRole}
            />
         )}
      </StyledModal>
   )
}

export default EntryScreen
