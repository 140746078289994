import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {Form, Input, Modal} from "antd"

import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { CustomInput, CustomTextarea } from "../../ui/Input"
import { CustomSelect } from "../../ui/Select"
import { phoneEnums, states } from "../../constants"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import { emailPattern, phonePattern, phoneNumberPattern } from "../../utils/regex"

import PlusInCircule from "../../assets/images/PlusInCircule.svg"
import { MinusCircleOutlined } from "@ant-design/icons"
import { EPhoneType, ICreateCustomer, ICustomer, IUpdatePhone } from "../../api/types"
import { TArgCallback } from "../../types"
import { Api } from "../../api/api"
import {getErrors, phoneFormatter, updatePhoneOnBlur, validatePhone} from "../../utils/utils"
import { TMappedError } from "../../utils/types"
import { down } from "styled-breakpoints"
import PhoneInput from "react-phone-number-input"
import AddressWithAutoComplete from "./AddressWithAutocomplete"
import { useBreakpoint } from "styled-breakpoints/react-styled"

const { Option } = CustomSelect

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 16px;
   }
   .ant-modal-content {
      border-radius: 4px;
   }
`

const FullName = styled(Msg)`
   text-transform: uppercase;
`

const Label = styled(Msg)`
   font-size: 13px;
   font-weight: 700;
   margin-bottom: 8px;
`

const InfoContainer = styled(Flex)`
   width: 45%;
`

const CustomerNotes = styled(CustomTextarea)`
   height: 32px !important;
`

const ModalCancelBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
   &:hover {
      border: 0.7px solid #1d3443;
   }
`

const ModalCreateBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
`

const ContainerMainButtons = styled(Flex)``

const AddAnotherPhoneButton = styled(EmptyButton)`
   display: flex;
   justify-content: flex-start;
   padding: 0;
   & img {
      margin-right: 8px;
   }
`
const StyledInput = styled(CustomInput)`
   width: 168px;
`

const StyledInputForEmail = styled(CustomInput)`
   width: 100%;
`

const StyledSelect = styled(CustomSelect)`
   &.ant-select {
      width: 100px;
   }
   .ant-select-selector .ant-select-selection-search input {
      border: none !important;
   }
`

const StyledSelectState = styled(CustomSelect)`
   &.ant-select {
      width: calc(100% - 10px);
      margin-right: 10px;
   }
   input {
      text-transform: uppercase;
   }
   ${down("xs")} {
      &.ant-select {
         margin-right: 10px;
      }
   }
`

const StyledTextArea = styled(CustomTextarea)`
   width: 100%;
   min-height: 64px !important;
   height: 64px !important;
   margin: 0;
`

// const AddAnotherPhoneButton = styled(EmptyButton)`
//    display: flex;
//    justify-content: flex-start;
//    padding: 0;
//    & img {
//       margin-right: 8px;
//    }
// `

const FlexContainerWrapper = styled(Flex)`
   border-top: 1px solid #e6e6e6;
   border-bottom: 1px solid #e6e6e6;
`

// const ContainerMainButtons = styled(Flex)`
//    border-top: 1px solid #e6e6e6;
// `

const StyledPhoneNumber = styled(PhoneInput)`
   .PhoneInputCountry {
      display: flex;
      flex-direction: row-reverse;
      .PhoneInputCountrySelect {
         position: initial;
         opacity: initial;
         // width: 20%;
         height: initial;
         border: 1px solid #d9d9d9 !important;
         padding: 4px 11px;
         background-color: white;
         border-radius: 4px;
         outline: none;
         color: #494949;
      }
      .PhoneInputCountryIcon {
         margin-right: 5px;
         height: 32px;
         width: 35px;
         min-width: 35px;
         box-shadow: none !important;
         background: none !important;
         line-height: 32px;
         img {
            border: 1px solid #e6e6e6;
            height: auto;
            display: inline-block;
         }
      }
      .PhoneInputCountrySelectArrow {
         display: none;
      }
   }
   .PhoneInputInput {
      width: 100%;
      flex: initial;
      border: 1px solid #d9d9d9;
      padding: 4px 11px;
      background-color: white;
      border-radius: 4px;
      outline: none;
      &:focus-visible,
      &:hover,
      &:active {
         border: 1px solid #1c99fc !important;
      }
   }
   // display: flex;
   // flex-direction: row;
   // .PhoneInputCountry {
   //   display: flex;
   //   flex-direction: row-reverse;
   //   align-items: center;
   //   .PhoneInputCountryIcon {
   //     width: 32px;
   //     margin-right: 10px;
   //   }
   //   .PhoneInputCountrySelect {
   //     width: 80%;
   //   }
   //   margin-right: 10px;
   // }
`
export const StyledPhoneNumberSmall = styled(PhoneInput)`
   margin-left: 10px;
   .PhoneInputInput {
      width: 100%;
      margin-right: 5px;
      border: 1px solid #d9d9d9;
      padding: 4px 11px;
      background-color: white;
      border-radius: 4px;
      outline: none;

      &:focus-visible,
      &:hover,
      &:active {
         border: 1px solid #1c99fc !important;
      }
   }
   .PhoneInputCountryIcon {
      height: 32px;
      width: 35px;
      box-shadow: none !important;
      background: none !important;
      line-height: 32px;
      img {
         border: 1px solid #e6e6e6;
         height: auto;
         display: inline-block;
      }
   }
   ${down("xs")} {
      margin-left: 10px;
      .PhoneInputInput {
         width: 100%;
         margin-right: 5px;
      }
   }
`

const blankPhone: IUpdatePhone = {
   id: undefined,
   phone: "",
   phone_type: EPhoneType.M,
}

type TPhoneError = {
   phone: string[]
}

type TProps = {
   customer: ICustomer
   visible: boolean
   onSuccess?: TArgCallback<ICustomer>
   setVisible: TArgCallback<boolean>
}
export const EditCustomer: React.FC<TProps> = ({ customer, visible, setVisible, onSuccess }) => {
   const { id, first_name, last_name, company_name, job_title, phones, email, note } = customer
   const [value, setValue] = useState("")
   const [form] = Form.useForm<ICreateCustomer>()
   const [customerPhones, setCustomerPhones] = useState<IUpdatePhone[]>([])
   const [primaryPhone, setPrimaryPhone] = useState("")
   const [showMore, setShowMore] = useState(false)
   const isXs = useBreakpoint(down('xs'))

   useEffect(() => {
      form.resetFields()
      setCustomerPhones(phones.filter((p) => p.phone_type !== EPhoneType.P))
   }, [phones, form])

   const handleSubmit = async () => {
      try {
         const values = form.getFieldsValue()
         const user = {
            ...values,
            phones: [
               ...[...customerPhones]
                  .filter((p) => p.phone_type !== "P")
                  .map((p, i) => ({
                     id: String(p.id).includes("customId") ? undefined : p.id,
                     phone: values[`phone${p.id}` as keyof ICreateCustomer] as string,
                     phone_type: values[`phone_type${p.id}` as keyof ICreateCustomer] as EPhoneType,
                  })),
               {
                  id: undefined,
                  phone: values["primary_phone" as keyof ICreateCustomer] as string,
                  phone_type: EPhoneType.P,
               },
            ],
            address: {
               full_address: value ?? "",
               address1: values.address1 ?? "",
               address2: values.address2 ?? "",
               city: values.city ?? "",
               state: values.state ?? "",
               zip_code: values.zip_code ?? ""
            },
         }

         const { data } = await Api.customers.update({ id, ...user })
         unmountModal()
         if (onSuccess) {
            await onSuccess(data)
         }
      } catch (e: any) {
         form.setFields(getErrors(e))
         const phones = e?.response?.data?.phones
         if (!phones || !phones.length) return
         form.setFields(formatPhonesError(phones as TPhoneError[]))
      }
   }

   const formatPhonesError = (phones: TPhoneError[]): TMappedError[] =>
      phones.map((p, i, arr) => {
         const phoneName = i === arr.length - 1 ? "primary_phone" : `phone${i}`
         return { name: phoneName, errors: p.phone }
      })

   const unmountModal = () => {
      setVisible(false)
      setCustomerPhones(phones.filter((p) => p.phone_type !== "P"))
      form.resetFields()
   }

   const updatePhone = (value: any, el: any) => {
      let phones = [...customerPhones]

      phones = phones.map((item) => {
         if (el.id && item.id === el.id) {
            return {
               ...el,
               phone: value,
            }
         } else {
            return item
         }
      })
      setCustomerPhones(phones)
   }

   const changePhoneType = (value: any, index: any) => {
      const phones = [...customerPhones]
      phones[index] = { ...phones[index], phone_type: value }
      setCustomerPhones(phones)
   }

   const deletePhone = (id: any) => {
      let phones = [...customerPhones]

      phones = phones.filter((item) => item.id !== id)
      setCustomerPhones(phones)
   }

   return (
      <StyledModal
         visible={visible}
         onCancel={unmountModal}
         destroyOnClose
         width={384}
         footer={[
            <ContainerMainButtons justifyContent="flex-end" py="6px">
               <ModalCancelBtn onClick={unmountModal}>
                  <Msg fontWeight={600} fontSize={14}>
                     Cancel
                  </Msg>
               </ModalCancelBtn>
               <ModalCreateBtn ml={16} form="customer-form" onClick={() => handleSubmit()}>
                  Save
               </ModalCreateBtn>
            </ContainerMainButtons>,
         ]}
      >
         <Flex alignItems="center" mb="10px">
            <FullName fontSize={15} fontWeight={900}>{`${first_name} ${last_name}`}</FullName>
         </Flex>
         <Form layout={!isXs ? "vertical" : "horizontal"} form={form} onFinish={handleSubmit} id="customer-form">
            <Flex flexDirection="column">
               <Flex justifyContent="space-between" flexDirection="column">
                  <Flex justifyContent="space-between">
                     <Flex flexDirection="column">
                        <Form.Item
                           label="First name"
                           name="first_name"
                           rules={[
                              {
                                 required: true,
                                 message: "Please input first name!",
                              },
                           ]}
                           initialValue={first_name}
                        >
                           <StyledInput
                              placeholder="Type here"
                              name="first_name"
                              style={{ width: "calc(100% - 10px)" }}
                              //onChange={handleChange}
                           />
                        </Form.Item>
                     </Flex>
                     <Flex flexDirection="column">
                        <Form.Item
                           label="Last name"
                           name="last_name"
                           rules={[
                              {
                                 required: true,
                                 message: "Please input last name!",
                              },
                           ]}
                           initialValue={last_name}
                        >
                           <StyledInput
                              placeholder="Type here"
                              name="last_name"
                              style={{ width: "100%" }}
                              //onChange={handleChange}
                           />
                        </Form.Item>
                     </Flex>
                  </Flex>

                  <Flex width="100%">
                     <Flex flexDirection="column" width="100%">
                        <Form.Item
                           label="Primary Phone"
                           name="primary_phone"
                           rules={[
                              {
                                 required: true,
                                 message: "Please input primary phone number!",
                              },
                              () => ({
                                 validator(_, value) {
                                    return validatePhone(value);
                                 }
                              })
                           ]}
                           initialValue={phones.find((p) => p.phone_type === "P")?.phone}
                        >
                           <Input name="primary_phone" onBlur={updatePhoneOnBlur(form, 'primary_phone')} />
                        </Form.Item>
                     </Flex>
                  </Flex>
               </Flex>

               {customerPhones.map((el, index) => (
                  <Flex width="100%" key={index}>
                     <Flex flexDirection="column" width="100%">
                        <Flex justifyContent="space-between">
                           <Form.Item name={`phone_type${el.id}`} initialValue={el.phone_type}>
                              <StyledSelect
                                 placeholder="Mobile"
                                 value={el.phone_type}
                                 onChange={(e) => changePhoneType(e, el.id)}
                                 getPopupContainer={(trigger) => trigger.parentElement}
                              >
                                 {Object.entries(phoneEnums).map((el, idx) => (
                                    <Option value={el[0]} key={idx + 2}>
                                       {el[1]}
                                    </Option>
                                 ))}
                              </StyledSelect>
                           </Form.Item>
                           <Form.Item
                              name={`phone${el.id}`}
                              rules={[
                                 {
                                    required: true,
                                    message: "Please input  phone number!",
                                 },
                                 () => ({
                                    validator(_, value) {
                                       return validatePhone(value);
                                    }
                                 })
                              ]}
                              initialValue={el.phone}
                           >
                              <Input onBlur={updatePhoneOnBlur(form, `phone${el.id}`)} />
                           </Form.Item>
                           <Form.Item>
                              <MinusCircleOutlined onClick={() => deletePhone(el.id)} />
                           </Form.Item>
                        </Flex>
                     </Flex>
                  </Flex>
               ))}

               <AddAnotherPhoneButton
                  height="20px"
                  onClick={() =>
                     setCustomerPhones(
                        [
                           ...customerPhones,
                           {
                              ...blankPhone,
                              id: `customId-${Math.floor(Math.random() * 100)}`,
                           },
                        ].slice(0, 3)
                     )
                  }
               >
                  <img src={PlusInCircule} alt="icon" />
                  <Msg fontWeight={700} fontSize={13} color="#109CF1">
                     Add another phone number
                  </Msg>
               </AddAnotherPhoneButton>
            </Flex>
            <Flex flexDirection="column" mt="16px">
               <Label>Email</Label>
               <Form.Item
                  name="email"
                  rules={[
                     {
                        pattern: emailPattern,
                        message: "Enter a valid email address",
                     },
                     {
                        required: true,
                        message: "Please input email!",
                     },
                  ]}
                  initialValue={email}
               >
                  <CustomInput placeholder="Email" />
               </Form.Item>
            </Flex>
            <AddressWithAutoComplete form={form} address={customer.address} label="address" value={value} setValue={setValue}/>
            <Flex flexDirection="column">
               <Label>Notes (optional)</Label>
               <Form.Item name="note" initialValue={note}>
                  <StyledTextArea name="note" placeholder="Type here" autoSize={true} />
               </Form.Item>
            </Flex>
            {showMore && (
                  <Flex justifyContent="space-between">
                     <InfoContainer flexDirection="column">
                        <Label>Company Name</Label>
                        <Form.Item name={"company_name"} initialValue={company_name}>
                           <CustomInput placeholder="Company Name" />
                        </Form.Item>
                     </InfoContainer>
                     <InfoContainer flexDirection="column">
                        <Label>Job Title</Label>
                        <Form.Item name="job_title" initialValue={job_title}>
                           <CustomInput placeholder="Job Title" />
                        </Form.Item>
                     </InfoContainer>
                  </Flex>
            )} 
            <EmptyButton width="100%" height="45px" mt="-20px" onClick={() => setShowMore(!showMore)}>
               <Msg fontWeight={600} fontSize={14} color="#109CF1">
                  {showMore ? "Show less" : "Show more"}
               </Msg>
            </EmptyButton>
         </Form>
      </StyledModal>
   )
}
