import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Spin } from "antd";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Flex from "../../noui/Flex";
import { Search } from "../../noui/Search";
import { Msg } from "../../ui/Text";
import {
  CurrentDateButton,
  SettingsButton,
  StyledButtonGroup,
} from "../calendar/components/styled";
import moment from "moment";
import Timeline from "./JobsTimeline/Timeline";
import SettingsIcon from "../../assets/images/SettingsIcon.svg";
import TimelineSettings from "./JobsTimeline/TimelineSettings";
import { useEffect } from "react";
import { useLoading } from "../../utils/hooks";
import { Api, TSchedule } from "../../api/api";
import { TAssignedJobsProps, TSettingStorage } from "./types";
import { getTime } from "./utils";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import CalendarImage from "../../assets/images/CalendarImage.svg";

const AssignedJobsContainer = styled.div`
  flex: 5;
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  ${down("xs")} {
    padding: 14px;
  }
`;

const SmallSearch = styled(Search)`
  width: 282px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

const FixedSizeDateButton = styled(CurrentDateButton)`
  width: 250px;
  ${down("lg")} {
    width: 100%;
  }
`;

const DateControl = styled.div`
  ${down("lg")} {
    > div {
      width: 100%;
      border-radius: 7px;
      margin: 16px 0;
      > button {
        border-radius: 7px !important;
      }
    }
  }
`;

const StyledDatePicker: React.FunctionComponent<any> = styled(DatePicker)`
  width: 0px;
  height: 0px;
  padding: 0px;
  border: none;
  opacity: 0;
  span {
    display: none;
  }
`;

const AssignedJobs = ({ users }: TAssignedJobsProps) => {
  const [date, setDate] = useState(new Date());
  const [employeeSearch, setEmployeeSearch] = useState<string>("");
  const [dayStart, setDayStart] = useState(+getTime(true));
  const [dayEnd, setDayEnd] = useState(+getTime(false));
  const [timeLineStartDate, setTimeLineStartDate] = useState<Date>(new Date());
  const [timeLineEndDate, setTimeLineEndDate] = useState<Date>(new Date());
  const [visible, setVisible] = useState(false);
  const [events, setEvents] = useState<TSchedule[]>();
  const [loading, onAction] = useLoading();
  const isLg = useBreakpoint(down("lg"));
  const datePickerRef = useRef<any>(null);
  const [openPicker, setOpenPicker] = useState(false);

  const startDate = useMemo(() => moment(date).startOf("day"), [date]);
  const endDate = useMemo(() => moment(date).endOf("day"), [date]);
  const filteredEvents = useMemo(
    () =>
      events?.filter((e) => {
        return moment(e.end).isAfter(moment(startDate));
      }) ?? [],
    [events, startDate]
  );

  const handleLeftClick = () => {
    setDate(moment(date).subtract(1, "day").toDate());
  };

  const handleRightClick = () => {
    setDate(moment(date).add(1, "day").toDate());
  };

  const setTimeLineSpan = () => {
    const newStartDate = new Date(date);
    const newEndDate = new Date(date);
    newStartDate.setHours(dayStart);
    newEndDate.setHours(dayEnd);
    setTimeLineStartDate(newStartDate);
    setTimeLineEndDate(newEndDate);
  };
  useEffect(() => {
    setTimeLineSpan();
    onAction(
      Api.schedules
        .getAll({
          time_after: startDate.clone().subtract(1, "week").toISOString(),
          time_before: endDate.toISOString(),
        })
        .then((res) => {
          const data = res.data as TSchedule[];
          setEvents(data);
        })
    ).finally();
  }, [date, onAction, dayEnd, dayStart]);

  const renderDateControl = () => (
    <DateControl>
      <StyledButtonGroup>
        <Button onClick={handleLeftClick} icon={<LeftOutlined />} />
        <FixedSizeDateButton
          onClick={() => {
            setDate(new Date());
          }}
        >
          {moment(date).format("dddd, MMMM DD, YYYY")}
        </FixedSizeDateButton>
        <Button onClick={handleRightClick} icon={<RightOutlined />} />
      </StyledButtonGroup>
    </DateControl>
  );

  return (
    <>
      {users && (
        <AssignedJobsContainer>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Msg fontWeight={900} fontSize={16} color="#151F2B">
              ASSIGNED JOBS
            </Msg>
            {isLg && (
              <SettingsButton
                onClick={() => {
                  setVisible(true);
                }}
              >
                <img src={SettingsIcon} alt="settings" />
              </SettingsButton>
            )}
          </Flex>
          <TimelineSettings
            visible={visible}
            handleClose={setVisible}
            changeStartTime={setDayStart}
            changeEndTime={setDayEnd}
          />
          {!isLg ? (
            <Flex padding="24px 0" justifyContent="space-between">
              <SmallSearch
                placeholder="Search employee"
                onChange={(e: React.ChangeEvent<any>) =>
                  setEmployeeSearch(e.target.value)
                }
              />
              {renderDateControl()}
              <SettingsButton
                onClick={() => {
                  setVisible(true);
                }}
              >
                <img src={SettingsIcon} alt="settings" />
              </SettingsButton>
            </Flex>
          ) : (
            <Flex flexDirection="column" width="100%">
              {renderDateControl()}
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                marginBottom="10px"
              >
                <SmallSearch
                  placeholder=""
                  onChange={(e: React.ChangeEvent<any>) =>
                    setEmployeeSearch(e.target.value)
                  }
                />
                <Button
                  style={{ padding: "0 5px", marginLeft: "20px" }}
                  onClick={() => {
                    datePickerRef?.current.focus();
                  }}
                >
                  <StyledDatePicker
                    onFocus={() => setOpenPicker(true)}
                    onBlur={() => setOpenPicker(false)}
                    ref={datePickerRef}
                    open={openPicker}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    suffixIcon={null}
                    onChange={(e: any) => setDate(e.toDate())}
                  />
                  {<img src={CalendarImage} />}
                </Button>
              </Flex>
            </Flex>
          )}

          <Spin spinning={loading}>
            <Timeline
              date={date}
              dayStart={timeLineStartDate}
              dayEnd={timeLineEndDate}
              events={filteredEvents}
              employees={users}
              search={[employeeSearch]}
              currentView={"day"}
            />
          </Spin>
        </AssignedJobsContainer>
      )}
    </>
  );
};

export default AssignedJobs;
