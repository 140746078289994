import React from "react";
import ShortLogo from "../../assets/images/ShortLogo.svg";
import Logo from "../../assets/images/Logo.svg";
import { EUserRole } from "../../api/types";
import { NavLink, useHistory } from "react-router-dom";
import { HELP_PAGE_URL } from "../../constants";
import Hr from "../../ui/Hr";
import { ReactComponent as SettingsImg } from "../../assets/images/menuIcons/Settings.svg";
import routes from "../../routes/routes";
import { ReactComponent as HelpImg } from "../../assets/images/menuIcons/Support.svg";
import Flex from "../../noui/Flex";
import styled from "styled-components";
import { Menu, Spin } from "antd";
import CloseSideBarImg from "../../assets/images/menuIcons/CloseSideBar.svg";
import { Msg } from "../../ui/Text";

import { ReactComponent as ReportsImg } from "../../assets/images/menuIcons/ReportsImg.svg";
import { ReactComponent as DashboardImg } from "../../assets/images/menuIcons/Dashboard.svg";
import { ReactComponent as CustomersImg } from "../../assets/images/menuIcons/Customers.svg";
import { ReactComponent as Vessel } from "../../assets/images/menuIcons/Vessel.svg";
import { ReactComponent as Dispatch } from "../../assets/images/menuIcons/Dispatch.svg";
import { ReactComponent as JobsImg } from "../../assets/images/menuIcons/WorkOrders.svg";
import { ReactComponent as BusinessImg } from "../../assets/images/menuIcons/Management.svg";

import { TCallback } from "../../types";
import Sider from "antd/es/layout/Sider";

import { getCurrentUser } from "../../config/reducers/user/selectors";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { CloseOutlined } from "@ant-design/icons";
import { UserSidebarProfile } from "./UserSidebarProfile";
import { ADMIN_HEADER_HEIGHT } from "../MainLayout";

import { useDispatch, useSelector } from "react-redux";
import { getMenuItem } from "../../config/reducers/menuClickReducer/selectors";
import { setMenuItem } from "../../config/reducers/menuClickReducer/actions";

const MenuWr = styled(Menu)`
  background-color: #0f497b !important;
  &.Big {
    width: inherit !important;
  }
  &.Small {
    width: inherit !important;
  }
  li:last-of-type {
    margin-bottom: 53px !important;
  }
  .fill .active svg path,
  .fill.ant-menu-item-active svg path {
    fill: #0496ff;
  }
  .stroke .active svg path,
  .stroke.ant-menu-item-active svg path {
    stroke: #0496ff;
  }
  .ant-tooltip-inner {
    background-color: red;
  }
`;

const MenuIt = styled(Menu.Item)`
  background-color: #0f497b !important;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #c2cfe0;
  a {
    display: flex;
    align-items: center;
  }
  .active {
    color: #ffffff !important;
    font-weight: bold;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`;

type TMenuContainerProps = {
  isAdmin?: boolean;
};
const MenuContainer = styled(Sider)<TMenuContainerProps>`
position: fixed;
background-color: #0F497B;
flex: 0 0 256px ;
max-width: 256px ;
min-width: 256px ;
height: calc(100vh ${(props) =>
  props.isAdmin ? `- ${ADMIN_HEADER_HEIGHT}` : ""});
z-index: 5;
width: 256px !important;
overflow-y: auto;
${down("xs")} {
  padding-bottom: 0 !important;
}
&.Wide {
  // position: absolute;
  background-color: #0F497B;
  flex: 0 0 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
  width: 256px !important;
  ${down("xs")} {
    width: 100vw !important;
    max-width: 100vw !important;
    flex: 1 0 100vw !important;
    position: absolute;
    z-index: 1052;
  }
} 
&.Narrow {
  // position: absolute;
  background-color: #0F497B;
  flex: 0 0 68px !important;
  max-width: 68px !important;
  min-width: 68px !important;
  width: 68px !important;
} 
.ant-layout-sider-children {
  ${down("xs")} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
& .ant-layout-sider-trigger {
  // display: none;
  width: inherit !important;
  text-align: start;
  padding-left: 24px;
  // background: none !important;
  background: #0f497b;
  ${down("xs")} {
    display: none;
    padding-top: 10px;
  }
  transition: none;
  &:hover {
    & span 
    color: #ffffff;
  }
  &:focus {
    & span::after {
      display: none;
      visibility: hidden; 
    }
  }
  & span {
    // &:after {
    //   margin-left: 26px;
    //   content: 'Close sidebar';
    //   display: inline-block;
    //   width: 90px;
    // }
    
    line-height: 14px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(${CloseSideBarImg}) no-repeat center;
    & svg {
      display: none
    }
  }
}
`;

const StylizeFlex = styled(Flex)`
  &.Short {
    padding-top: 20px;
    padding-bottom: 53px;
  }
  &.Tall {
    padding-top: 20px;
    padding-bottom: 53px;
  }
`;
const AbsoluteText = styled(Msg)`
  position: fixed;
  z-index: 2;
  bottom: 24px;
  left: 50px;
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  & .Link {
    color: inherit;
  }
`;
const Spinner = styled(Spin)`
  width: 30px;
  margin: auto;
`;
const CloseIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #fff;
  font-size: 19px;
`;

type TProps = {
  imageName: any | undefined;
};

type TLink = {
  to: string;
  label: string;
  role?: EUserRole;
  exclude_role?: EUserRole[];
  icon: JSX.Element;
};
const links: TLink[] = [
  {
    to: routes.main.Dashboard,
    icon: <DashboardImg />,
    label: "Dashboard",
    exclude_role: [EUserRole.SuperUser],
  },
  {
    to: routes.main.SUDashboard,
    icon: <DashboardImg />,
    label: "Dashboard",
    role: EUserRole.SuperUser,
  },
  {
    to: routes.customers.List,
    icon: <CustomersImg />,
    label: "Customers",
    exclude_role: [EUserRole.SuperUser, EUserRole.Technician],
  },
  {
    to: routes.vessels.List,
    icon: <Vessel />,
    label: "Vessels",
    exclude_role: [EUserRole.SuperUser, EUserRole.Technician],
  },
  {
    to: routes.jobs.ProjectsList,
    icon: <JobsImg />,
    label: "Projects",
    exclude_role: [EUserRole.SuperUser],
  },
  {
    to: routes.dispatch.Base,
    icon: <Dispatch />,
    label: "Dispatch",
    exclude_role: [EUserRole.SuperUser, EUserRole.Technician],
  },
  {
    to: routes.business.Jobs,
    icon: <BusinessImg />,
    label: "Management",
    exclude_role: [EUserRole.SuperUser, EUserRole.Technician],
  },
  {
    to: routes.reports.Base,
    icon: <ReportsImg />,
    label: "Reports",
    exclude_role: [EUserRole.SuperUser, EUserRole.Technician],
  },

  // { to: routes.apps.Base, icon: <GenerateImage imageName={MyAppsImg} />, label: "Apps", exclude_role: [EUserRole.SuperUser, EUserRole.Technician] },
  {
    to: routes.settings.General,
    icon: <SettingsImg />,
    label: "Settings",
    exclude_role: [EUserRole.SuperUser, EUserRole.Technician],
  },
  { to: routes.help.Base, icon: <HelpImg />, label: "Support" },
];

type TSidebarProps = {
  collapsed: boolean;
  onCollapse: TCallback;
};
export const Sidebar: React.FC<TSidebarProps> = ({ collapsed, onCollapse }) => {
  const userData = useSelector(getCurrentUser);
  const menuClick = useSelector(getMenuItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const isXS = useBreakpoint(down("xs"));

  const handleMenuItemClick = () => {
    if (isXS) {
      onCollapse();
    }
  };
  const handleLinkClick = (
    e: any,
    route: string,
    anotherDomain?: null | boolean
  ) => {
    dispatch(setMenuItem({ menuRoute: route }));
    e.preventDefault();
    if (menuClick.showModal) {
      dispatch(setMenuItem({ actionClick: true }));
    } else {
      if (anotherDomain) {
        window.location.assign(route);
      } else {
        history.push(route);
      }
    }
  };

  return (
    <MenuContainer
      collapsible
      isAdmin={Boolean(userData?.is_staff && userData.company)}
      collapsed={collapsed}
      onCollapse={onCollapse}
      className={collapsed ? "Narrow" : "Wide"}
    >
      {isXS ? (
        <CloseIcon onClick={onCollapse}>
          <CloseOutlined />
        </CloseIcon>
      ) : null}
      <StylizeFlex
        pl={collapsed ? 0 : 22}
        justifyContent={collapsed ? "center" : "flex-start"}
        className={collapsed ? "Short" : "Tall"}
      >
        <NavLink to="/">
          <img src={collapsed ? ShortLogo : Logo} alt="logotype" />
        </NavLink>
      </StylizeFlex>
      {userData ? (
        <MenuWr
          theme="dark"
          mode="inline"
          className={collapsed ? "Small" : "Big"}
        >
          {links.map((l, idx) =>
            (l.exclude_role && l.exclude_role.includes(userData?.role_id)) ||
            (l.role && userData?.role_id !== l.role) ? null : (
              <React.Fragment key={l.label}>
                <MenuIt
                  onClick={handleMenuItemClick}
                  key={l.label}
                  className={l.label === "Dashboard" ? "stroke" : "fill"}
                  title={l.label}
                >
                  {l.label === "Support" ? (
                    <a
                      onClick={(e) => handleLinkClick(e, HELP_PAGE_URL, true)}
                      style={{ color: !collapsed ? "inherit" : "#FFFFFF" }}
                      href={HELP_PAGE_URL}
                    >
                      {l.icon}
                      {!collapsed && l.label}
                    </a>
                  ) : (
                    <NavLink
                      onClick={(e) => handleLinkClick(e, l.to)}
                      style={{ color: !collapsed ? "inherit" : "#FFFFFF" }}
                      to={l.to}
                    >
                      {l.icon}
                      {!collapsed && l.label}
                    </NavLink>
                  )}
                </MenuIt>
                {userData?.role_id === EUserRole.Technician && idx === 3 ? (
                  <Hr mt={isXS ? "14px" : "64px"} mb="25px" />
                ) : idx === 6 ? (
                  <Hr mt={isXS ? "14px" : "64px"} mb="25px" />
                ) : null}
              </React.Fragment>
            )
          )}

          {!collapsed && !isXS ? (
            <AbsoluteText fontSize={13} onClick={onCollapse}>
              <span className="Link">Close sidebar</span>
            </AbsoluteText>
          ) : null}
        </MenuWr>
      ) : (
        <Flex width="100%" height="50%" textAlign="center">
          <Spinner />
        </Flex>
      )}
      {userData && isXS && !collapsed ? (
        <UserSidebarProfile onClose={onCollapse} />
      ) : null}
    </MenuContainer>
  );
};
