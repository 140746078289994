import { Link } from "react-router-dom"
import styled from "styled-components"
import { IVessel } from "../../api/types"
import Flex from "../../noui/Flex"
import routes from "../../routes/routes"
import { Msg } from "../../ui/Text"
import { formatAddress } from "../../utils/utils"

const VesselDataBlock = styled(Flex)<{isActive?: boolean}>`
    width: 100%;
    padding: 8px 16px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    justify-content: space-between;
    >div {
        width: calc(70% - 8px);
    }
    >div:first-child {
        width: calc(30% - 8px)
    }
    cursor: pointer;
    ${props => props.isActive && `
        border: 1px solid #0496FF;
    `}
`



type TVesselMapDataProps = {
    vessel: IVessel
    makeActive: (id: number) => void
    isActive?: boolean
}

const VesselMapData: React.FC<TVesselMapDataProps> = ({vessel, makeActive, isActive}) => {
    const handleVesselDataBlockClick = () => {
        makeActive(vessel.id)
    }

    return (
        <VesselDataBlock onClick={handleVesselDataBlockClick} isActive={isActive}>
            <Flex flexDirection="column">
                {isActive ? (
                <Link to={routes.vessels.Page.replace(":id", String(vessel?.id))} style={{textDecoration: "underline", fontWeight: 700}}>
                    {vessel.name}
                </Link>
                ) : (
                <Msg color="#494949" fontSize="13px" fontWeight="700">
                    {vessel.name}
                </Msg>
                )}
                <Msg color="#8B8B8B" fontSize="13px" fontWeight="400">
                    {vessel.customer?.full_name}
                </Msg>
            </Flex>
            <Flex justifyContent="flex-end">
                <Msg color="#494949" textAlign="right">{formatAddress(vessel.address)}</Msg>
            </Flex>
        </VesselDataBlock>
    );
}

export default VesselMapData;