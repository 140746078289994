import styled from "styled-components";
import {down} from "styled-breakpoints";

export const ScrollWrapper = styled.div`
  height: 360px;
  overflow-y: auto;
  padding: 10px 20px;
  ${down('xs')} {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;