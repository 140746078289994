import {createReducer} from "@reduxjs/toolkit";
import {addNewNotification, getAllNotifications, readNotification, removeNotification, unreadNotification} from "./actions";
import {INotification} from "../../../api/types";


type TStatus = "loading" | "fulfilled" | "error";

type TState = {
  status: TStatus;
  notifications: INotification[];
}
const initialState: TState = {
  status: 'fulfilled',
  notifications: []
}

export const notificationsReducer = createReducer(initialState, builder => builder
  .addCase(getAllNotifications.pending, (state) => {
    return {...state, status: 'loading'};
  })
  .addCase(getAllNotifications.rejected, state => {
    return {...state, status: 'error'};
  })
  .addCase(getAllNotifications.fulfilled, (state, {payload}) => {
    return {...state, status: 'fulfilled', notifications: payload};
  })
  .addCase(addNewNotification, (state, {payload}) => {
    return {...state, notifications: [payload, ...state.notifications]};
  })
  .addCase(readNotification.fulfilled, (state, {payload}) => {
    return {...state, notifications: [...state.notifications.map(n => n.id === payload ? {...n, read: true} : n)]};
  })
  .addCase(unreadNotification.fulfilled, (state, {payload}) => {
    return {...state, notifications: [...state.notifications.map(n => n.id === payload ? {...n, read: false} : n)]};
  })
  .addCase(removeNotification.fulfilled, (state, {payload}) => {
    return {...state, notifications: state.notifications.filter(n => n.id !== payload)};
  })
);