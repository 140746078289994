import { Modal } from "antd"
import styled from "styled-components"
import { TCallback } from "../../types"
import { Msg } from "../../ui/Text"

const StyledModal = styled(Modal)`
    .ant-modal-title {
        color: #FB4D4F;
    }
    .ant-modal-body {
        padding: 16px 18px;
    }
`

type TProps = {
    visible: boolean
    onOk: TCallback
    onCancel: TCallback
}

const SaveWarningModal:React.FC<TProps> = ({visible, onOk, onCancel}) => {
    return (
        <StyledModal
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            title="Warning"
            okText="Save Now"
            cancelText="Keep Editing"
            width={374}
        >
            <Msg>
                In order to add details to the project, you must save it first. Do you want to save now?
            </Msg>
        </StyledModal>
    )
} 

export default SaveWarningModal;