import React from "react";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import { Button, Empty, Spin, Tooltip } from "antd";
import { EmptyButton } from "../../ui/Button";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { TCallback } from "../../types";
import { INotification, ENotificationStatus } from "../../api/types";
import routes from "../../routes/routes";
import {
  capitalize,
  getAgoTime,
  getNotificationsColor,
  getTimeSlotCategory,
} from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationsStatus,
  getUnreadNotifications,
} from "../../config/reducers/notifications/selectors";
import { getNotificationTagUrl } from "./utils";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import { CheckCircleIcon } from "../../assets/icons";
import { readNotification } from "../../config/reducers/notifications/actions";
import { StyleMessage } from "./NotificationsFull";

export const StyledLink = styled(NavLink)`
  text-decoration: underline;
`;
export const PrefButton = styled(Button)`
  margin-right: -12px;
  font-weight: bold;
  color: #0496ff;

  font-weight: 700;
  font-size: 13px;
  > span {
    text-decoration: underline;
  }
`;
export const StyledButton = styled(Button)`
  border: none;
  outline: none;
  margin: 5px;
  height: 16px;
  padding: 0;
`;
export const StyledFlex = styled(Flex)`
  width: 40%;
  word-wrap: break-word;
`;
const Wrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  max-height: 350px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fbfbff;
    border-radius: 8px;
    border: 1px solid #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background: #0496ff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #40a9ff;
  }
  > div {
    height: 100%;
    > div {
      height: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      .ant-empty {
        margin: auto;
      }
    }
  }
`;

const Message: React.FC<{
  notification: INotification;
  onClose: TCallback;
}> = ({ notification: { tags, ...notification }, onClose }) => {
  const dispatch = useDispatch();

  const handleRead = (id: number) => async () => {
    await dispatch(readNotification(id));
  };

  const isXs = useBreakpoint(down("xs"));
  const user = useAppSelector(getCurrentUser);
  const getLink = () => {
    let c: React.ReactNodeArray = [notification.content];
    return tags
      .slice()
      .sort((a, b) => a.content_type.localeCompare(b.content_type))
      .reduce((acc, tag) => {
        return reactStringReplace(acc, tag.tag, () => (
          <StyledLink
            onClick={onClose}
            to={getNotificationTagUrl(tag, tags, user)}
          >
            {tag.tag}
          </StyledLink>
        ));
      }, c);
  };

  const renderColorBtn = () => {
    let color = "";
    if (notification.priority !== ENotificationStatus.Medium) {
      color = getNotificationsColor(notification.priority);
    } else {
      color = !notification.read ? "#0496FF" : "#8B8B8B";
    }
    return color;
  };

  return (
    <Flex
      pt={14}
      pr={20}
      pb={14}
      pl={20}
      flexDirection="row"
      width={isXs ? "100%" : "402px"}
      alignItems="center"
      justifyContent="space-between"
      style={{ minHeight: "90px" }}
      borderBottom="0.5px solid rgba(0, 0, 0, 0.1);"
    >
      <StyleMessage
        flexDirection="column"
        notificationColor={getNotificationsColor(notification.priority)}
      >
        <Msg fontWeight={600} fontSize={13} mb={1}>
          {capitalize(getTimeSlotCategory(notification.created))}
        </Msg>
        <Msg fontWeight={600} fontSize={13} mb={1}>
          {getLink()}
        </Msg>
        <Msg fontWeight={300} fontSize={10} color="rgba(0, 0, 0, 0.65)">
          {getAgoTime(notification.created)}
        </Msg>
      </StyleMessage>
      <Tooltip
        title={`Mark as  ${!notification.read ? "read" : "unread"}`}
        placement="left"
        overlayInnerStyle={{
          minWidth: "30px",
          minHeight: " 22px",
          padding: "2px 4px",
        }}
        overlayStyle={{ fontSize: "12px" }}
      >
        <StyledButton onClick={handleRead(notification.id)}>
          <CheckCircleIcon color={renderColorBtn()} />
        </StyledButton>
      </Tooltip>
    </Flex>
  );
};

type TProps = {
  onPreferences: TCallback;
  onViewAll: TCallback;
  onClose: TCallback;
  visible: boolean;
};
export const NotificationsShortView: React.FC<TProps> = ({
  onPreferences,
  onViewAll,
  onClose,
}) => {
  const notificationsStatus = useSelector(getNotificationsStatus);
  const notifications = useSelector(getUnreadNotifications);
  const isXs = useBreakpoint(down("xs"));

  return (
    <Flex
      width={isXs ? "100vw" : "402px"}
      flexDirection="column"
      justifyContent="center"
    >
      <Flex
        p={16}
        width={isXs ? "100%" : "402px"}
        justifyContent="space-between"
        alignItems="center"
        height="56px"
        borderBottom="0.5px solid rgba(0, 0, 0, 0.1);"
      >
        <Msg fontWeight={900} fontSize={15}>
          NOTIFICATIONS
        </Msg>
        <PrefButton type="link" onClick={onPreferences}>
          Preferences
        </PrefButton>
      </Flex>
      <Wrapper>
        <Spin spinning={notificationsStatus === "loading"} className="grow">
          {notifications.length ? (
            notifications.map((notification) => {
              return (
                <Message
                  notification={notification}
                  key={notification.id}
                  onClose={onClose}
                />
              );
            })
          ) : (
            <Empty description="No unread notifications" />
          )}
        </Spin>
      </Wrapper>
      <EmptyButton height="54px" width="100%" onClick={onViewAll}>
        <Msg
          fontWeight={700}
          fontSize={13}
          color="#0496FF"
          style={{ textDecoration: "underline" }}
        >
          View more
        </Msg>
      </EmptyButton>
    </Flex>
  );
};
