import styled, {StyledComponent} from 'styled-components';
import {
  fontSize, FontSizeProps,
  space, SpaceProps,
  width, WidthProps,
  height, HeightProps,
  typography, TypographyProps
} from 'styled-system';
import Box, {TBoxProps} from '../noui/Box';


type TProps =
  & FontSizeProps
  & SpaceProps
  & WidthProps
  & HeightProps
  & TypographyProps;

export const ModalWindow = styled(Box)<TProps>`
background: #FFFFFF;
// border: 1px solid #D6DFE4;
box-sizing: border-box;
box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
border-radius: 4px;
  ${space};
  ${width};
  ${height}
  ${fontSize};
  ${typography};
` as StyledComponent<'div', any, TProps & TBoxProps>;