import React, { useEffect, useState, useCallback } from "react"
import { Modal, Form, Divider, Tag, Empty, Tooltip, Spin } from "antd"
import { Api } from "../../api/api"
import styled from "styled-components"
import { EmptyButton } from "../../ui/Button"
import { PrimaryButton } from "../../ui/Button"
import Flex from "../../noui/Flex"
import Box from "../../noui/Box"
import { Msg } from "../../ui/Text"
import { AddNewTemplateModal } from "./AddNewTemplateModal"
import { CreateTemplateBlock } from "./CreateTemplateBlock"
import { getErrors } from "../../utils/utils"
import reactStringReplace from "react-string-replace"
import { TCallback } from "../../types"
import { ICreateMessageTemplate, IMessageTemplate, ITemplateSettings } from "../../api/types"
import { useLoading } from "../../utils/hooks"
import { useSelector } from "react-redux"
import { getConstant } from "../../config/reducers/user/selectors"
import { tagsReGex } from "../../utils/regex"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { TemplateDelete, Disabled } from "../../assets/icons"

const Title = styled.h2`
   font-weight: 900;
   font-size: 15px;
   line-height: 20px;
   text-transform: uppercase;
`

const Row = styled.div<{ active?: boolean }>`
   margin-top: 18px;
   > .ant-select {
      width: 100%;
   }
   border: ${(props) => (props.active ? "2px solid #096DD9" : "none")};
`

const TemplateButton = styled(EmptyButton)<{ active?: boolean }>`
   width: 100%;
   & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }
   > div {
      overflow: hidden;
   }
   ${(props) =>
      props.active &&
      `
    border: 2px solid #096DD9;
  `}
`

const ModalWrap = styled(Flex)<{ templateId?: boolean }>`
   ${(props) =>
      props.templateId &&
      `
      height: 800px;
      overflow-y: auto;

      @media (max-height: 750px) {
         max-height: 660px;
      }
      @media (max-height: 690px) {
         max-height: 620px;
      }
      @media (max-height: 450px) {
         max-height: 400px;
      }

      &::-webkit-scrollbar {
         width: 8px;
      
      }
      &::-webkit-scrollbar-track {
         background: #fbfbff;
         border-radius: 8px;
         border: 1px solid #ededed;
       
      
      }

      &::-webkit-scrollbar-thumb {
         background: #0496ff;
         border-radius: 8px;
      
      }

      &::-webkit-scrollbar-thumb:hover {
         background: #40a9ff;
      }
`}
`
const EditBlock = styled(Flex)`
   padding: 8px 14px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
`

const ModalCancelBtn = styled(PrimaryButton)`
   width: 84px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
   &:hover {
      border: 0.7px solid #1d3443;
   }
`

const ModalCreateBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
`

const AddTemplateButton = styled(PrimaryButton)`
height: 32px;
margin-top: 16px;
`;

const DeleteButton = styled(EmptyButton)`
   color: #109cf1;
   font-weight: 600;
   font-size: 14px;
   &:disabled {
      background-color: #ffffff;
   }
`

const TagButton = styled(Tag)`
   display: inline-block;
   height: 18px !important;
   vertical-align: middle;
   margin: 0;
   padding: 3px 5px;
   border-radius: 3px;
`

const ScrollingBlock = styled(Flex)`
   height: 700px;
   overflow-y: auto;

   @media (max-height: 750px) {
      max-height: 555px;
   }
   @media (max-height: 690px) {
      max-height: 525px;
   }
   @media (max-height: 450px) {
      max-height: 350px;
   }
   &::-webkit-scrollbar {
      width: 8px;
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }

   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }

   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
   ${down("xs")} {
      height: auto;
      max-height: 400px;
   }
`

type TProps = {
   visible: boolean
   onClose: TCallback
   backLabel?: string
   updateTemplates?: (templates: IMessageTemplate[]) => void
}
export const MessagesTemplateModal: React.FC<TProps> = ({ visible, onClose, backLabel, updateTemplates }) => {
   const [addTemplateVisible, setAddTemplateVisible] = useState(false)
   const [messageTemplates, setMessageTemplates] = useState<IMessageTemplate[]>([])
   const [templateId, setTemplateId] = useState<number | null>(null)
   const [form] = Form.useForm<ICreateMessageTemplate>()
   const [loading, onCall] = useLoading()
   const [saving, onSave] = useLoading()
   const tags = useSelector(getConstant("template_keys"))
   const isXs = useBreakpoint(down("xs"))
   const [templateSettings, setTemplateSettings] = useState<ITemplateSettings[]>([])
   const [isDirty, setIsDirty] = useState(false)

   const loadCards = useCallback(async () => {
      if (visible) {
         const { data } = await onCall(Api.templates.getAll({}))
         setMessageTemplates(data as IMessageTemplate[])
      }
   }, [onCall, visible])

   useEffect(() => {
      loadCards().finally()
   }, [loadCards, visible])

   const handleSuccess = () => {
      setIsDirty(true)
      loadCards().finally()
   }

   const loadSettings = async () => {
      const { data } = await Api.company.getTemplateSettings()
      setTemplateSettings(data)
   }

   useEffect(() => {
      if (visible) {
         loadSettings()
      }
   }, [visible])

   const editMessageTemplate = async () => {
      if (templateId) {
         const data = await form.validateFields()
         try {
            await onSave(Api.templates.update({ ...data, id: templateId }))
            setIsDirty(true)
            setMessageTemplates(messageTemplates.map((t) => (t.id === templateId ? { ...data, id: templateId } : t)))
            form.resetFields()
            setTemplateId(null)
         } catch (e) {
            form.setFields(getErrors(e))
         }
      }
   }

   const openEditWindow = (template: IMessageTemplate) => {
      setTemplateId(template.id)
      form.setFieldsValue(template)
   }

   const handleDeleteTemplate = async () => {
      if (templateId) {
         try {
            await onSave(Api.templates.remove(templateId))
            setMessageTemplates(messageTemplates.filter((t) => t.id !== templateId))
            setTemplateId(null)
         } catch (e) {
            const errors = getErrors(e)
            form.setFields(errors)
         }
      }
   }
   const htmlToStr = (str: any) => {
      var parser = new DOMParser()
      var doc = parser.parseFromString(str, "text/html")
      return doc.body.innerText
   }
   const replaceString = (value: any): React.ReactNodeArray => {
      const str = htmlToStr(value)
      return reactStringReplace(str, tagsReGex, (match) =>
         tags && tags[match] ? (
            <TagButton color="#CEEBFF" className="tag">
               {tags[match]}
            </TagButton>
         ) : (
            match
         )
      )
   }

   const handleCloseCreationModal = () => {
      form.resetFields()
      setAddTemplateVisible(false)
   }

   const isDefault = (id: number) => {
      return templateSettings.some((t) => t.template === id)
   }

   const handleCloseModal = () => {
      if (isDirty) {
        updateTemplates?.(messageTemplates)
        setIsDirty(false)
      }
      onClose()
   }

   return (
      <Modal
         visible={visible}
         onCancel={handleCloseModal}
         confirmLoading={loading}
         closable={false}
         width={isXs ? 350 : 779}
         className="modal-window-without-main-buttons"
         cancelButtonProps={{ style: { display: "none" } }}
         okButtonProps={{ style: { display: "none" } }}
         centered
      >
         <ModalWrap templateId={Boolean(templateId)}>
            <Flex flexDirection="column" width="100%">
               <Flex justifyContent="space-between">
                  <Title>Messages Templates</Title>
                  {!isXs && (
                  <PrimaryButton height='32px' onClick={() => setAddTemplateVisible(true)}>
                    New Template
                  </PrimaryButton>
                  )}
               </Flex>
               <AddNewTemplateModal
                  visible={addTemplateVisible}
                  onClose={handleCloseCreationModal}
                  onSuccess={handleSuccess}
               />
               <EmptyButton fontWeight="700" fontSize="13px" color="#109CF1" onClick={handleCloseModal} width="fit-content">
                  {`< ${backLabel ?? "Back to Send Estimate"}`}
               </EmptyButton>
               <Flex width="100%" flexDirection={isXs ? "column" : "row"}>
                  <ScrollingBlock
                     flexDirection="column"
                     width={templateId && !isXs ? "50%" : "100%"}
                     mr={templateId && !isXs ? "24px" : "0px"}
                  >
                     {loading ? (
                        <Spin spinning={loading} />
                     ) : messageTemplates.length > 0 ? (
                        messageTemplates.map((el) => {
                           return (
                              <Row key={el.id} active={el.id === templateId}>
                                 <TemplateButton onClick={() => openEditWindow(el)}>
                                    <Flex
                                       alignItems="flex-start"
                                       flexDirection="column"
                                       p="8px"
                                       m="3px"
                                       className="little-cart"
                                    >
                                       <Flex
                                          width="100%"
                                          height="24px"
                                          justifyContent="space-between"
                                          alignItems="center"
                                       >
                                          <Msg fontSize="13px" fontWeight="700" color="#484848">
                                             {el.title}
                                          </Msg>
                                          {/* IF USED AS DEFAULT */}
                                          {isDefault(el.id) && (
                                             <Tooltip
                                                title="Used as default"
                                                placement="left"
                                                overlayInnerStyle={{
                                                   minWidth: "30px",
                                                   minHeight: "24px",
                                                   padding: "2px 4px",
                                                }}
                                                overlayStyle={{ fontSize: "12px" }}
                                             >
                                                <div>
                                                   <Disabled />
                                                </div>
                                             </Tooltip>
                                          )}
                                       </Flex>
                                       <Msg
                                          fontSize="13px"
                                          fontWeight="400"
                                          color="#828282"
                                          textAlign="left"
                                          style={{ whiteSpace: "normal" }}
                                       >
                                          {replaceString(el.subject)}
                                       </Msg>
                                       <Msg
                                          fontSize="13px"
                                          fontWeight="400"
                                          color="#828282"
                                          textAlign="left"
                                          style={{ whiteSpace: "normal" }}
                                       >
                                          {replaceString(el.content)}
                                       </Msg>
                                    </Flex>
                                 </TemplateButton>
                              </Row>
                           )
                        })
                     ) : (
                        <Empty />
                     )}
                  </ScrollingBlock>
                  {templateId ? (
                     <EditBlock width={isXs ? "100%" : "50%"} height="fit-content" mt="18px">
                        <Form onFinish={editMessageTemplate} form={form}>
                           <Box mb="38px">
                              <CreateTemplateBlock form={form} templateId={templateId} />
                           </Box>
                           <Divider />
                           <Flex justifyContent="space-between" alignItems="center">
                              <DeleteButton
                                 height="40px"
                                 onClick={handleDeleteTemplate}
                                 disabled={isDefault(templateId)}
                                 loading={saving}
                              >
                                 <TemplateDelete color={isDefault(templateId) ? "#ACE0FF" : "#0496FF"} />
                              </DeleteButton>
                              <Flex>
                                 <ModalCancelBtn onClick={() => setTemplateId(null)}>
                                    <Msg fontWeight={600} fontSize={14}>
                                       Cancel
                                    </Msg>
                                 </ModalCancelBtn>
                                 <ModalCreateBtn ml={16} htmlType="submit" loading={saving}>
                                    Save
                                 </ModalCreateBtn>
                              </Flex>
                           </Flex>
                        </Form>
                     </EditBlock>
                  ) : null}
               </Flex>
               {isXs && (
               <AddTemplateButton onClick={() => setAddTemplateVisible(true)}>
                  New Template
               </AddTemplateButton>
               )}
            </Flex>
         </ModalWrap>
      </Modal>
   )
}
