import { ColumnsType } from "antd/es/table";
import { IJob } from "../../api/types";

export const blankService = {
  qty: "",
  unit: "",
  price: "",
};
export const JobTableColumns: ColumnsType<IJob> = [
  {
    title: "#",
    width: "80px",
    dataIndex: "order_number",
    key: "order_number",
  },
  {
    title: "Job Name",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Customer",
    dataIndex: "customer_data",
    key: "customer",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Vessel",
    dataIndex: "vessel",
    key: "vessel",
  },
  {
    title: "Actions",
    key: "actions",
    dataIndex: "id",
  },
];
