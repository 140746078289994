import React, { useEffect, useState } from "react"
import { Msg } from "../../../ui/Text"
import { Form, Modal, notification, Select } from "antd"
import { CustomInput, CustomTextarea } from "../../../ui/Input"
import { CustomSelect } from "../../../ui/Select"
import Flex from "../../../noui/Flex"
import PlusInCircule from "../../../assets/images/PlusInCircule.svg"
import Box from "../../../noui/Box"
import { EmptyButton, PrimaryButton } from "../../../ui/Button"
import styled from "styled-components"
import { instance } from "../../../api"
import { getErrors } from "../../../utils/utils"
import { states, phoneEnums } from "../../../constants"
import { emailPattern, phonePattern, phoneNumberPattern } from "../../../utils/regex"
import { MinusCircleOutlined } from "@ant-design/icons"
import PhoneInput from "react-phone-number-input"
import { StyledPhoneNumberSmall } from "../../../containers/customers/pages/styled"
import { down } from "styled-breakpoints"
import { track } from "../../../utils/analytics"
import { useAppSelector } from "../../../config/hooks"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
const { Option } = Select

const StyledModal = styled(Modal)`
   & .ant-modal-body {
      padding: 16px 0;
   }

   & .ant-modal-footer {
      display: none;
   }
   @media screen and (max-width: 575px) {
      .ant-form .ant-form-item .ant-form-item-label,
      .ant-form .ant-form-item .ant-form-item-control {
         flex: inherit;
      }
   }
`

const ModalCancelBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
   &:hover {
      border: 0.7px solid #1d3443;
   }
`

const ModalCreateBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
`

const StyledInput = styled(CustomInput)`
   width: 168px;
`

const StyledInputForEmail = styled(CustomInput)`
   width: 100%;
`

const StyledSelect = styled(CustomSelect)`
   &.ant-select {
      width: 100px;
   }
   .ant-select-selector .ant-select-selection-search input {
      border: none !important;
   }
`

const StyledSelectState = styled(CustomSelect)`
   &.ant-select {
      width: calc(100% - 10px);
      margin-right: 10px;
   }
   input {
      text-transform: uppercase;
   }
   ${down("xs")} {
      &.ant-select {
         margin-right: 10px;
      }
   }
`

const StyledTextArea = styled(CustomTextarea)`
   width: 100%;
   min-height: 64px !important;
   height: 64px !important;
   margin: 0;
`

const AddAnotherPhoneButton = styled(EmptyButton)`
   display: flex;
   justify-content: flex-start;
   padding: 0;
   & img {
      margin-right: 8px;
   }
`

const FlexContainerWrapper = styled(Flex)`
   border-top: 1px solid #e6e6e6;
   border-bottom: 1px solid #e6e6e6;
`

const ContainerMainButtons = styled(Flex)`
   border-top: 1px solid #e6e6e6;
`

const StyledPhoneNumber = styled(PhoneInput)`
   .PhoneInputCountry {
      display: flex;
      flex-direction: row-reverse;
      .PhoneInputCountrySelect {
         position: initial;
         opacity: initial;
         // width: 20%;
         height: initial;
         border: 1px solid #d9d9d9 !important;
         padding: 4px 11px;
         background-color: white;
         border-radius: 4px;
         outline: none;
         color: #494949;
      }
      .PhoneInputCountryIcon {
         margin-right: 5px;
         height: 32px;
         width: 35px;
         min-width: 35px;
         box-shadow: none !important;
         background: none !important;
         line-height: 32px;
         img {
            border: 1px solid #e6e6e6;
            height: auto;
            display: inline-block;
         }
      }
      .PhoneInputCountrySelectArrow {
         display: none;
      }
   }
   .PhoneInputInput {
      width: 100%;
      flex: initial;
      border: 1px solid #d9d9d9;
      padding: 4px 11px;
      background-color: white;
      border-radius: 4px;
      outline: none;
      &:focus-visible,
      &:hover,
      &:active {
         border: 1px solid #1c99fc !important;
      }
   }
   // display: flex;
   // flex-direction: row;
   // .PhoneInputCountry {
   //   display: flex;
   //   flex-direction: row-reverse;
   //   align-items: center;
   //   .PhoneInputCountryIcon {
   //     width: 32px;
   //     margin-right: 10px;
   //   }
   //   .PhoneInputCountrySelect {
   //     width: 80%;
   //   }
   //   margin-right: 10px;
   // }
`

const blankPhone = {
   id: undefined,
   phone: "",
   phone_type: "M",
}

export const CustomerModal = ({ visible, onClose, onSuccess, handleSelect }) => {
   const [loading, setLoading] = useState(false)
   const [primaryPhone, setPrimaryPhone] = useState("")
   const [customerData, setCustomerData] = useState({
      email: "",
      company_name: "",
      job_title: "",
      phones: [{ ...blankPhone }],
      first_name: "",
      last_name: "",
      address: {
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip_code: "",
      },
      note: "",
   })
   const profile = useAppSelector(getCurrentUser)
   const [showMore, setShowMore] = useState(false)
   const [form] = Form.useForm()

   useEffect(() => {
      if (visible) {
         form.resetFields()
      }
   }, [visible, form])

   const handleChange = ({ target: { name, value } }) => {
      setCustomerData({ ...customerData, [name]: value })
   }

   const handleChangeAddress = ({ target: { name, value } }) => {
      setCustomerData({
         ...customerData,
         address: {
            ...customerData.address,
            [name]: value,
         },
      })
   }

   const changeStates = (value) => {
      setCustomerData({
         ...customerData,
         address: {
            ...customerData.address,
            state: value,
         },
      })
   }

   const changePhoneType = (value, index) => {
      const phones = [...customerData.phones]
      phones[index] = { ...phones[index], phone_type: value }
      setCustomerData({
         ...customerData,
         phones,
      })
   }

   const changePhone = (value, index) => {
      const phones = [...customerData.phones]
      phones[index] = { ...phones[index], phone: value }
      setCustomerData({
         ...customerData,
         phones,
      })
   }

   const handleAddPhone = () => {
      setCustomerData({
         ...customerData,
         phones: [...customerData.phones, { ...blankPhone }].slice(0, 3),
      })
   }

   const destroyModal = () => {
      onClose()
      setCustomerData({
         ...customerData,
         phones: [{ ...blankPhone }],
      })
   }

   const handleSubmit = async () => {
      setLoading(true)
      try {
         const { data } = await instance.post("/customers", {
            ...customerData,
            phones: [
               ...customerData.phones.filter((p) => p.phone && p.phone_type),
               { id: undefined, phone: primaryPhone, phone_type: "P" },
            ],
         })
         onSuccess && (await onSuccess(data))
         if (handleSelect) {
            handleSelect(data)
         }
         setLoading(false)
         destroyModal()
         track("Customer Added", {
            customerCreatedBy: profile?.id,
            companyId: profile?.company?.id,
            customerId: data?.id,
            customerCity: data?.address?.city,
            customerState: data?.address?.state,
         })
      } catch (e) {
         const errors = getErrors(e)
         if (errors.phone) {
            notification.error({ message: "Phone field is required" })
         }
         setLoading(false)
         form.setFields(errors)
      }
   }

   return (
      <StyledModal centered visible={visible} onOk={() => {}} onCancel={destroyModal} okText="Create" width={384}>
         <Msg fontWeight={900} fontSize={15} px="16px" color="#151F2B">
            NEW CUSTOMER
         </Msg>
         <Form onFinish={handleSubmit} layout="vertical" id="create-course-form" form={form}>
            <Flex flexDirection="column" px="16px">
               <Flex justifyContent="space-between" mt="16px" flexDirection="column">
                  <Flex justifyContent="space-between">
                     <Flex flexDirection="column">
                        <Form.Item
                           label="First name"
                           name="first_name"
                           rules={[
                              {
                                 required: true,
                                 message: "Please input first name!",
                              },
                           ]}
                        >
                           <StyledInput
                              placeholder="Type here"
                              name="first_name"
                              style={{ width: "calc(100% - 10px)" }}
                              onChange={handleChange}
                           />
                        </Form.Item>
                     </Flex>
                     <Flex flexDirection="column">
                        <Form.Item
                           label="Last name"
                           name="last_name"
                           rules={[
                              {
                                 required: true,
                                 message: "Please input last name!",
                              },
                           ]}
                        >
                           <StyledInput
                              placeholder="Type here"
                              name="last_name"
                              style={{ width: "100%" }}
                              onChange={handleChange}
                           />
                        </Form.Item>
                     </Flex>
                  </Flex>
               </Flex>
               <Flex flexDirection="column" width="100%">
                  <Flex width="100%">
                     <Flex flexDirection="column" width="100%">
                        <Form.Item
                           label="Primary Phone"
                           name="primary_phone"
                           rules={[
                              {
                                 pattern: phoneNumberPattern,
                                 message:
                                    "Phone number must be entered in the format: '+999999999'. Up to 15 digits allowed.",
                              },
                              {
                                 required: true,
                                 message: "Please input primary phone number!",
                              },
                           ]}
                        >
                           <StyledPhoneNumber
                              international
                              defaultCountry="US"
                              name="primary_phone"
                              onChange={setPrimaryPhone}
                              value={primaryPhone}
                           />
                        </Form.Item>
                     </Flex>
                  </Flex>
                  <Msg
                     fontWeight={700}
                     fontSize={13}
                     color="#484848"
                     style={{ display: customerData.phones.length ? "block" : "none" }}
                  >
                     Additional Phones
                  </Msg>
                  {customerData.phones.map((el, index) => (
                     <Flex width="100%" key={index}>
                        <Flex flexDirection="column" width="100%">
                           <Flex justifyContent="space-between">
                              <Form.Item name={`phone_type${index + 1}`}>
                                 <StyledSelect
                                    name={`phone_type${index}`}
                                    placeholder="Mobile"
                                    value={el.phone_type}
                                    onChange={(e) => changePhoneType(e, index)}
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                 >
                                    {Object.entries(phoneEnums).map((el, idx) => (
                                       <Option value={el[0]} key={idx + 2}>
                                          {el[1]}
                                       </Option>
                                    ))}
                                 </StyledSelect>
                              </Form.Item>
                              <Form.Item
                                 name={`phone${index}`}
                                 rules={[
                                    {
                                       pattern: phonePattern,
                                       message: "Invalid phone number",
                                    },
                                 ]}
                              >
                                 <StyledPhoneNumberSmall
                                    international
                                    defaultCountry="US"
                                    name="primary_phone"
                                    onChange={(e) => changePhone(e, index)}
                                    value={el.phone}
                                 />
                              </Form.Item>
                              <Form.Item>
                                 <MinusCircleOutlined
                                    style={{ color: "#8B8B8B" }}
                                    onClick={() =>
                                       setCustomerData({
                                          ...customerData,
                                          phones: [...customerData.phones].slice(0, customerData.phones.length - 1),
                                       })
                                    }
                                 />
                              </Form.Item>
                           </Flex>
                        </Flex>
                     </Flex>
                  ))}
                  <AddAnotherPhoneButton height="20px" onClick={handleAddPhone}>
                     <img src={PlusInCircule} alt="icon" />
                     <Msg fontWeight={700} fontSize={13} color="#109CF1">
                        Add another phone number
                     </Msg>
                  </AddAnotherPhoneButton>
               </Flex>
               <Flex flexDirection="column" mt="9px">
                  <Flex flexDirection="column">
                     <Msg fontWeight={700} fontSize={13} color="#484848">
                        Email
                     </Msg>
                     <Form.Item
                        name="email"
                        rules={[
                           {
                              required: true,
                              message: "Please input email!"
                           },
                           {
                              pattern: emailPattern,
                              message: "Invalid email address",
                           },
                        ]}
                     >
                        <StyledInputForEmail name="email" placeholder="Type here" onChange={handleChange} />
                     </Form.Item>
                  </Flex>
                  <Flex flexDirection="column">
                     <Msg fontWeight={700} fontSize={13} color="#484848">
                        Notes
                     </Msg>
                     <Form.Item name="note">
                        <StyledTextArea name="note" placeholder="Type here" autoSize={true} onChange={handleChange} />
                     </Form.Item>
                  </Flex>
               </Flex>
            </Flex>
            {showMore ? (
               <Box>
                  <FlexContainerWrapper justifyContent="space-between" mt="16px" pt="24px" px="16px" mb="24px">
                     <Flex flexDirection="column">
                        <Msg fontWeight={700} fontSize={13} color="#484848">
                           Company name
                        </Msg>
                        <Form.Item name="company_name">
                           <StyledInput
                              name="company_name"
                              placeholder="Type here"
                              style={{ width: "calc(100% - 10px)" }}
                              onChange={handleChange}
                           />
                        </Form.Item>
                     </Flex>
                     <Flex flexDirection="column">
                        <Msg fontWeight={700} fontSize={13} color="#484848">
                           Job title
                        </Msg>
                        <Form.Item name="job_title">
                           <StyledInput
                              name="job_title"
                              placeholder="Type here"
                              style={{ width: "100%" }}
                              onChange={handleChange}
                           />
                        </Form.Item>
                     </Flex>
                  </FlexContainerWrapper>
                  <Flex flexDirection="column" px="16px">
                     <Flex flexDirection="column">
                        <Flex justifyContent="space-between">
                           <Flex flexDirection="column">
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 Street
                              </Msg>
                              <Form.Item name="address1">
                                 <StyledInput
                                    name="address1"
                                    placeholder="Type here"
                                    style={{ width: "calc(100% - 10px)" }}
                                    onChange={handleChangeAddress}
                                 />
                              </Form.Item>
                           </Flex>
                           <Flex flexDirection="column">
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 City
                              </Msg>
                              <Form.Item name="city">
                                 <StyledInput
                                    name="city"
                                    placeholder="Type here"
                                    style={{ width: "100%" }}
                                    onChange={handleChangeAddress}
                                 />
                              </Form.Item>
                           </Flex>
                        </Flex>
                     </Flex>
                     <Flex flexDirection="column">
                        <Flex justifyContent="space-between">
                           <Flex flexDirection="column" width={"100%"}>
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 State
                              </Msg>
                              <Form.Item name="state">
                                 <StyledSelectState
                                    name="state"
                                    placeholder="Select"
                                    onChange={changeStates}
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                    showSearch
                                 >
                                    {states.map((el, idx) => (
                                       <Option value={el} key={idx + 1}>
                                          {el}
                                       </Option>
                                    ))}
                                 </StyledSelectState>
                              </Form.Item>
                           </Flex>
                           <Flex flexDirection="column" width={"100%"}>
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 Zip code
                              </Msg>
                              <Form.Item name="zip_code">
                                 <StyledInput
                                    name="zip_code"
                                    placeholder="Type here"
                                    style={{ width: "100%" }}
                                    onChange={handleChangeAddress}
                                 />
                              </Form.Item>
                           </Flex>
                        </Flex>
                     </Flex>
                  </Flex>
               </Box>
            ) : (
               ""
            )}
            <EmptyButton width="100%" height="45px" mt="-20px" onClick={() => setShowMore(!showMore)}>
               <Msg fontWeight={600} fontSize={14} color="#109CF1" style={{textDecoration: "underline"}}>
                  {showMore ? "Show less" : "Show more"}
               </Msg>
            </EmptyButton>
            <ContainerMainButtons justifyContent="flex-end" pt="16px" px="16px">
               <ModalCancelBtn onClick={destroyModal} style={{ width: "50%" }}>
                  <Msg fontWeight={600} fontSize={14}>
                     Cancel
                  </Msg>
               </ModalCancelBtn>
               <ModalCreateBtn loading={loading} ml={16} htmlType="submit" style={{ width: "50%" }}>
                  Save
               </ModalCreateBtn>
            </ContainerMainButtons>
         </Form>
      </StyledModal>
   )
}
