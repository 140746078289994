import React, {useMemo} from 'react';
import classes from "../styles.module.scss";
import moment from "moment";
import {Divider, Input, Form, FormInstance} from "antd";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {Activities} from "./Activities";
import {EstimateStatuses} from "../../../constants";
import {Actions} from "./Actions";
import {getCurrentUser} from "../../../config/reducers/user/selectors";
import {ICreateJob, IJob, IJobMaterial, IJobService} from "../../../api/types";
import {TArgCallback, TCallback} from "../../../types";
import {TechnicianSelector} from "../selectors/TechnicianSelector";

type label = [string, keyof TCalculations];
const labels: label[] = [
  ["Total Parts", "materials"],
  ["Total Labor", "services"],
  ["Required Deposit", "deposit"],
  ["Discount", "discount"],
  ["Grand Total", "grand"]
];
const DepositWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 14px;
    align-items: center;
`;
const TotalWrapper = styled.div`
    .row {
        color: #828282;
        padding: 4px 0;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const HelpText = styled.span`
    font-size: 13px;
    color: #828282;
`;
const calculateRows = <Item extends IJobService|IJobMaterial>(items: Item[]): number => {
  return items.map(
    s => (s?.qty || 0) * (s?.unit_price || 0)
  ).reduce((acc, i) => acc + i, 0);
}


type TCalculations = {
  services: number;
  materials: number;
  deposit: number;
  discount: number;
  grand: number;
}
type TProps = {
  form: FormInstance<ICreateJob>;
  toggleModal: TArgCallback<boolean>;
  onArchive: TCallback;
  estimate?: IJob;
  onAction: TCallback;
  onSaveAndClose: TCallback;
  onProceed: TCallback;
  actionLabel: string;
  saving: boolean;
}
export const SideContent: React.FC<TProps> = ({
  form,
  toggleModal,
  onArchive,
  estimate,
  onAction,
  onSaveAndClose,
  onProceed,
  actionLabel,
  saving
}) => {
  const siderClasses = useMemo(() => {
    return (estimate && estimate.status === EstimateStatuses.ToBeScheduled)
      ? [classes.siderWithSchedule, classes.sider].join(" ")
      : classes.sider;
  }, [estimate]);

  const user = useSelector(getCurrentUser);

  const calculatePrices = (form: FormInstance<ICreateJob>): TCalculations => {
    const sVals = form.getFieldValue("services") || [];
    const services = calculateRows(sVals);
    const mVals = form.getFieldValue("materials") || [];
    const materials = calculateRows(mVals);
    const deposit = Number(form.getFieldValue("required_deposit") || 0);
    const discount: number = 0;
    const grand: number = services + materials - discount;

    return {services, materials, deposit, discount, grand};
  }

  return <div className={siderClasses}>
    <ul>
      {estimate?.order_number ?
        <li><span className={classes.title}>Order number</span> #{estimate.order_number}</li> : null}
      {estimate?.schedules.length ? <li>
        <span className={classes.title}>Scheduled</span>
        <span className={classes.info} style={{textAlign: "right"}}>
                    {moment(estimate.schedules[0].start).format("LLL")}<br/>
          {moment(estimate.schedules[0].end).format("LLL")}
                </span>
      </li> : null}
      <li>
        <span className={classes.title}>Created by</span>
        <span className={classes.link}>{user ? user.full_name : "-"}</span>
      </li>
      <TechnicianSelector form={form} />
      <li>
        <span className={classes.title}>Created</span>
        <span className={classes.info}>{
          estimate ?
            moment(estimate.created).format("LLL") :
            moment().format("LLL")
        }</span>
      </li>
    </ul>
    <Divider className={classes.divider}/>
    <Actions
      onRecordPayment={() => toggleModal(true)}
      onArchive={onArchive}
      onSaveAndClose={onSaveAndClose}
      onProceed={onProceed}
      onAction={onAction}
      estimate={estimate||undefined}
      saving={saving}
      actionLabel={actionLabel}
    />
    <Divider className={classes.divider}/>
    <h4 className={classes.title} style={{color: "#42C57A"}}>Required deposit *</h4>
    <DepositWrapper>
      <Form.Item name="required_deposit" rules={[{required: true, message: "Deposit is required"}]}>
        <Input
          placeholder="Type here"
          type="number"
        />
      </Form.Item>
      <HelpText>* Type here the amount you need to get before job start</HelpText>
    </DepositWrapper>

    <Divider className={classes.divider}/>
    <h4 className={classes.title}>Total Info</h4>
    <TotalWrapper>
      <Form.Item shouldUpdate>
        {() => {
          const prices = calculatePrices(form);
          return (labels.map(([label, key]) => {
            return <div className="row" key={label}><span>{label}</span>
              <span>${parseFloat(String(prices[key])).toFixed(2)}</span></div>
          }));
        }}
      </Form.Item>
    </TotalWrapper>
    <Divider className={classes.divider}/>
    <h4 className={classes.title}>Job Activity</h4>
    <Activities activities={estimate?.activities || []}/>
  </div>;
};