import React, { useEffect, useState } from "react"
import { TCallback, TOptionDataList } from "../../types"
import { Form, Input, Modal, notification, Radio, Select, Tabs } from "antd"
import {ESendType, ICustomer, ICustomerShort, IJob, IMessageTemplate, ISendMessage} from "../../api/types"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import styled from "styled-components"
import { MessagesTemplateModal } from "../../containers/messages/MessagesTemplateModal"
import { Api } from "../../api/api"
import { clearFormErrors, getErrors, getReplacedTemplateMessage, mapOptions } from "../../utils/utils"
import { useSelector } from "react-redux"
import { getConstant, getCurrentUser } from "../../config/reducers/user/selectors"
import { useLoading } from "../../utils/hooks"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { TextEditor } from "../../components/TextEditor/TextEditor"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { CheckboxTab, StyledCBTabs, TextMessagingWarning } from "./CheckboxTab"
import {track} from "../../utils/analytics";
import moment from "moment";
import { Checkbox } from "antd/es"

export const SwitchButton = styled(Radio)`
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   display: inline-flex;
   padding: 0 14px;
   background: #fff;
   justify-content: flex-start;
   border: none;
   align-items: center;
   height: 40px;
   width: calc(50% - 8px);
   max-width: 95px;
   border-radius: 4px;
   .ant-radio {
      margin-bottom: 5px;
   }
   &:after {
      content: none;
   }
   .ant-radio-inner,
   .ant-radio-inner:after {
      border-radius: 0 !important;
   }
   .ant-radio-inner:after {
      border: none !important;
   }
   &.ant-radio-wrapper-disabled {
      cursor: not-allowed;
   }
`

const Label = styled.h4`
   font-weight: bold;
   font-size: 14px;
   line-height: 18px;
`

export const StyledModalWrapper = styled(Modal)`
   .ant-modal-footer {
      .ant-btn {
         width: calc(50% - 4px);
         max-width: 95px;
      }
   }
   top: 0px;
`
const TextEditorWrapper = styled.div`
   margin-bottom: 20px;

   @media (max-height: 750px) {
      & .editor__white {
         height: 130px;
      }
   }
   @media (max-height: 690px) {
      & .editor__white {
         height: 100px;
      }
   }
`

const CheckboxWrapper = styled(Flex)`
   flex-direction: column;
   padding: 16px 0;
   .ant-checkbox-wrapper >span~span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #494949;
   }
   .ant-select {
      margin-top: 8px;
      width: 275px;
   }
`

type TProps = {
   visible: boolean
   onClose: TCallback
   customer?: number
   onSubmit?: TCallback
}
export const MessagesModal: React.FC<TProps> = ({ visible, onClose, customer, onSubmit }) => {
   const [form] = Form.useForm<ISendMessage>()
   const [templatesVisible, setTemplatesVisible] = useState<boolean>(false)
   const [customers, setCustomers] = useState<TOptionDataList<ICustomerShort>>({ data: [], options: [] })
   const [jobs, setJobs] = useState<TOptionDataList<IJob>>({ data: [], options: [] })
   const [templates, setTemplates] = useState<TOptionDataList<IMessageTemplate>>({ data: [], options: [] })
   const [messageContent, setMessageContent] = useState<any>()
   const [messageContentHtml, setMessageContentHtml] = useState<any>()
   const [jobsLoading, onJobsLoad] = useLoading()
   const [sendType, setSendType] = useState([ESendType.E])
   const [currentTab, setCurrentTab] = useState<string>(ESendType.E)
   const [isWithDocument, setIsWithDocument] = useState(false)
   const [sending, onSend] = useLoading()
   const tags = useSelector(getConstant("template_keys"))
   const user = useSelector(getCurrentUser)
   const isXs = useBreakpoint(down("xs"))

   useEffect(() => {
      loadData()
   }, [visible, form])

   const loadData = async () => {
      if (visible) {
         form.resetFields()
         await handleLoadTemplates()
         await Api.templates.getAll({}).then(({ data: rd }) => {
            const data = rd as IMessageTemplate[]
            setTemplates({ data, options: mapOptions(data, "title") })
         })
      }
   }

   const handleLoadTemplates = () =>
      Api.customers.getShort({}).then(({ data: rd }) => {
         const data = rd as ICustomerShort[];
         setCustomers({ data, options: mapOptions(data, "full_name") })
      })

   const handleCloseTemplates = async () => {
      loadData().finally();
      setTemplatesVisible(false);
   }
   const handleChangeTemplate = async (templateId: string) => {
      const template = templates.data.find((t) => t.id === Number(templateId))
      if (template && user) {
         const customerId = form.getFieldValue("customer")
         form.setFieldsValue({ subject: template.subject })
         if (customerId) {
            const jobId = form.getFieldValue("job")
            const customer = customers.data.find((el) => el.id === Number(customerId))
            const job = jobs.data.find((el) => el.id === Number(jobId))
            let workOrder = null
            //console.log("j", job)
            if (job && job.work_order) {
               workOrder = await fetchWorkOrder(job.work_order)
            }
            const subject = getReplacedTemplateMessage(template.subject, tags, user, customer, job, workOrder)
            const content = getReplacedTemplateMessage(template.content, tags, user, customer, job, workOrder)
            const sms_content = getReplacedTemplateMessage(template.sms_content, tags, user, customer, job, workOrder)
            setMessageContent(content)
            form.setFieldsValue({ content, subject, sms_content })
         }
      }
   }

   const fetchWorkOrder = async (id: number) => {
      try {
         const { data } = await Api.workOrders.get(id)
         return data
      } catch (err) {
         console.log(err)
      }
   }

   const handleChangeJob = () => {
      const templateId = form.getFieldValue("template")
      if (templateId) {
         handleChangeTemplate(templateId)
      }
   }
   const handleChangeCustomer = (customerId: string) => {
      if (customerId) {
         onJobsLoad(
            Api.jobs
               .getAll({ customer: Number(customerId) })
               .then(({ data: rd }) => {
                  const data = rd as IJob[]
                  setJobs({ data, options: mapOptions(data, (j) => `${j.order_number} / ${j.title}`) })
               })
               .catch(() => {
                  setJobs({ data: [], options: [] })
               })
         ).finally()
         form.setFieldsValue({ job: undefined })
      }
      const templateId = form.getFieldValue("template")
      if (templateId) {
         handleChangeTemplate(templateId)
      }
   }

   const formatData = async () => {
      const data = await form.validateFields()
      const content = form.getFieldValue("content")
      const sms_content = form.getFieldValue("sms_content")
      const subject = form.getFieldValue("subject")
      return {
         ...data,
         content,
         ...(sendType.includes(ESendType.S) && {sms_content}),
         subject,
         send_type: sendType,
      }
   }

   const handleSubmit = async () => {
      try {
         if (!sendType.length) {
            notification.error({ message: "Please choose message type" })
            return
         }
         const eventAction = sendType[0]=== "E"?'Email Message Sent to EPC':'Text Message Sent to EPC'
         const data = await formatData()
         if (sendType.includes(ESendType.S) && data?.sms_content?.length && data.sms_content.length > 120) {
            setCurrentTab(ESendType.S)
         }
         try {
            clearFormErrors(form);
            await onSend(Api.customers.sendMessage(data))
           await track(eventAction, {
               textCreatedBy: user?.id,
               companyId: user?.company?.id,
               customerId: customer,
               textSentAt: moment().toISOString()
            });
            onSubmit && onSubmit()
            handleClose()
            await track(eventAction, {
               textCreatedBy: user?.id,
               companyId: user?.company?.id,
               customerId: customer,
               textSentAt: moment().toISOString()
            });
         } catch (e) {
            const errors = getErrors(e)
            const nonFieldError = errors[0].errors[0]
            if (nonFieldError) {
               notification.error({ message: nonFieldError })
            }
            form.setFields(errors)
         }
      } catch (e) {
         console.error(e)
      }
   }

   const updateMessage = (value: string) => {
      setMessageContentHtml(value)
   }

   const handleClose = () => {
      onClose()
      setMessageContent("")
      setMessageContentHtml("")
   }

   useEffect(() => {
      form.setFieldsValue({ content: messageContentHtml })
   }, [messageContentHtml])

   const setTab = (key: string) => {
      setCurrentTab(key)
   }

   const handleTabCheck = (e: CheckboxChangeEvent, key: ESendType) => {
      if (e.target.checked) {
         setSendType((prev) => [...prev, key])
         return
      }
      setSendType((prev) => prev.filter((i) => i !== key))
   }

   const renderContent = () => {
      return (
         <Flex flexDirection="column">
            <Form.Item
               name="customer"
               label="Recipient"
               initialValue={customer ? String(customer) : null}
               rules={[
                  {
                     required: true,
                     message: "Please select recipient!",
                  },
               ]}
            >
               <Select
                  onChange={handleChangeCustomer}
                  options={customers.options}
                  showSearch
                  filterOption={(input, option) =>
                     option ? option.text.toLowerCase().includes(input.toLowerCase()) : false
                  }
                  placeholder="Select a customer"
                  getPopupContainer={(trigger: any) => trigger.parentElement}
               />
            </Form.Item>
            {!customer && (
               <Form.Item 
                  name="job" 
                  label="Job"
                  rules={[
                     {
                        required: isWithDocument,
                        message: "Please select recipient!",
                     },
                  ]}
               >
                  <Select
                     loading={jobsLoading}
                     onChange={handleChangeJob}
                     options={jobs.options}
                     placeholder="Select a job"
                     getPopupContainer={(trigger: any) => trigger.parentElement}
                  />
               </Form.Item>
            )}
         <Form.Item
            name="template"
            className="fullWidthLabel"
            label={
               <Flex justifyContent="space-between" width={"100%"}>
                  <Label>Template</Label>
                  <EmptyButton
                     fontSize="14px"
                     style={{ lineHeight: "18px" }}
                     fontWeight="700"
                     color="#109CF1"
                     width="auto"
                     onClick={() => setTemplatesVisible(true)}
                  >
                     Manage list
                  </EmptyButton>
               </Flex>
            }
         >
            <Select
               onChange={handleChangeTemplate}
               options={templates.options}
               placeholder="Select a template"
               getPopupContainer={(trigger: any) => trigger.parentElement}
            />
         </Form.Item>
         {currentTab !== ESendType.S && 
         <Form.Item 
            name="subject" 
            label="Subject"
            rules={[
               {
                  required: true,
                  message: "Subject is required"
               }
            ]}
         >
            <Input placeholder="Type here" />
         </Form.Item>}
         {currentTab === ESendType.E ?
         <>
            <Form.Item 
               name="content" 
               label="Message text"
               rules={[
                  {
                     required: true,
                     message: "Message text is required"
                  }
               ]}
            >
               <TextEditor initialValue={messageContent} onChange={updateMessage} />
            </Form.Item>
         </> :
         <Form.Item 
            name="sms_content" 
            label="Message text"
            rules={[
               {
                  required: true,
                  message: "Message text is required"
               }
            ]}
            style={{ marginBottom: "36px" }}
         >
            <Input.TextArea 
               rows={isXs ? 2 : 5} 
               placeholder="Type here" 
               showCount={{formatter: ({count, maxLength}) => `${count}/${maxLength} characters`}}
               maxLength={120}
            />
         </Form.Item>
         }
         <CheckboxWrapper>
            <Checkbox 
               checked={isWithDocument} 
               onChange={e => setIsWithDocument(e.target.checked)}
            >
               Include project document
            </Checkbox>
            {isWithDocument && (
               <Form.Item 
                  name="action" 
                  rules={[
                     {
                        required: true,
                        message: "Select document type"
                     }
                  ]}
               >
                  <Select placeholder="Select document type">
                     <Select.Option value="statement_of_work">Statement of work</Select.Option>
                     <Select.Option value="estimate">Estimate</Select.Option>
                     <Select.Option value="invoice">Invoice</Select.Option>
                  </Select>
               </Form.Item>
            )}
         </CheckboxWrapper>
         </Flex>
      )
   }

   return (
      <StyledModalWrapper
         width={480}
         onOk={handleSubmit}
         confirmLoading={sending}
         title="Send a message"
         onCancel={handleClose}
         visible={visible}
         centered
      >
         <Form form={form} layout="vertical" onFinish={handleSubmit} requiredMark={false}>
            <StyledCBTabs 
               onChange={setTab} 
               type="card" 
               activeKey={currentTab} 
               tabBarExtraContent={!user?.company?.messaging_status && <TextMessagingWarning onClose={handleClose}/>}
            >
               <Tabs.TabPane 
                  tab={
                     <CheckboxTab
                        checked={sendType.includes(ESendType.E)}
                        onChange={(e) => handleTabCheck(e, ESendType.E)}
                        title="Email"
                     />
                  }
                  key={ESendType.E}
               >
                  {renderContent()}
               </Tabs.TabPane>
               <Tabs.TabPane
                  tab={
                     <CheckboxTab 
                        checked={sendType.includes(ESendType.S)} 
                        disabled={!user?.company?.messaging_status}
                        onChange={(e) => handleTabCheck(e, ESendType.S)}
                        title="SMS"
                     />
                  } 
                  disabled={!user?.company?.messaging_status}
                  key={ESendType.S}
               >
                  {renderContent()}
               </Tabs.TabPane>
            </StyledCBTabs>
         </Form>
         <MessagesTemplateModal
            backLabel="Back to Send Message"
            visible={templatesVisible}
            onClose={handleCloseTemplates}
         />
      </StyledModalWrapper>
   )
}
