import React, { useState, useEffect } from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { TArgCallback } from "../../types"
import { ArrowBack, ArrowNext } from "../../assets/icons"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { url } from "inspector"
import CalendarIcon from "../../assets/images/calendar-icon.svg"
import { TimePicker } from "./TimePicker"

const Wrapper = styled(Flex)`
   display: inline-flex;
   gap: 3px;
   outline: none;
   border: 1px solid #ccc;
   border-radius: 3px;
   width: 100%;
   align-items: center;
   & .separator {
      width: 10px;
      height: 1px;
      background: #ccc;
   }
   input {
      border: none !important;
      text-align: center;
   }
   &.disable {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      cursor: not-allowed;
      input {
         background: none !important;
      }
   }
`

type TProps = {
   date: any
   setDate: TArgCallback<any>
   disable?: boolean
}

export const TimePickerRange: React.FC<TProps> = ({ date, setDate, disable }) => {
   const setStartTime = (value: any) => {
      const copyDate = [...date]
      copyDate[0] = value
      setDate(copyDate)
   }
   const setEndTime = (value: any) => {
      const copyDate = [...date]
      copyDate[1] = value
      setDate(copyDate)
   }

   return (
      <Wrapper className={disable ? "disable" : ""}>
         <TimePicker date={date[0]} setDate={setStartTime} disable={disable} />
         <div className="separator" />
         <TimePicker date={date[1]} setDate={setEndTime} disable={disable} />
      </Wrapper>
   )
}
