import { Modal, Form, Select, notification } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../../../noui/Flex";
import moment from "moment";
import { TSettingStorage } from "../types";
import { getTime } from "../utils";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
const { Option } = Select;

type TTimelineSettings = {
  visible: boolean;
  handleClose: (b: boolean) => void;
  changeStartTime: React.Dispatch<React.SetStateAction<number>>;
  changeEndTime: React.Dispatch<React.SetStateAction<number>>;
};

const ModalFlexWrapper = styled.div`
  .ant-form-item {
    width: 45%;
  }
  ${down("xs")} {
    .ant-form-item {
      width: 100%;
    }
  }
`;

const TimelineSettings = ({
  visible,
  handleClose,
  changeStartTime,
  changeEndTime,
}: TTimelineSettings) => {
  const [form] = Form.useForm();
  const [timeArray] = useState(Array.from(Array(24).keys()));
  const isXs = useBreakpoint(down("xs"));

  const handleSave = () => {
    const start = form.getFieldValue("dayStart");
    const end = form.getFieldValue("dayEnd");
    if (end > start) {
      const saveData: TSettingStorage = { start, end };
      localStorage.setItem("timelineSettings", JSON.stringify(saveData));
      changeStartTime(start);
      changeEndTime(end);
      handleClose(false);
    } else {
      notification.error({
        message: `"Day Ends" must be greater than "Day Starts"`,
      });
    }
  };

  return (
    <Modal
      title="TIMELINE SETTINGS"
      okText="Save"
      cancelText="Cancel"
      visible={visible}
      onOk={handleSave}
      onCancel={() => {
        handleClose(false);
      }}
    >
      <ModalFlexWrapper>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            ["dayStart"]: +getTime(true),
            ["dayEnd"]: +getTime(false),
          }}
        >
          <Flex
            flexDirection={isXs ? "column" : "row"}
            justifyContent="space-between"
          >
            <Form.Item name="dayStart" label="Day Starts">
              <Select>
                {timeArray.map((hour) => (
                  <Option key={hour} value={hour}>
                    {moment().hour(hour).format("h A")}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="dayEnd" label="Day Ends">
              <Select>
                {timeArray.map((hour) => (
                  <Option key={hour} value={hour}>
                    {moment().hour(hour).format("h A")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>
        </Form>
      </ModalFlexWrapper>
    </Modal>
  );
};

export default TimelineSettings;
