import React, { useState } from "react"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { CompanyInfoContainer, ContainerTitle, SaveBtnWrapper, SaveBtn } from "./styled"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import './Editor.scss'
import styled from "styled-components"
import { Button, Form, Input } from "antd"
import { useAppDispatch, useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { Api } from "../../api/api"
import { getErrors, showNonFieldsErrors } from "../../utils/utils"
import { useForm } from "antd/es/form/Form"
import { setCurrentUser } from "../../config/reducers/user/actions"

const ReplyInput = styled(Input)`
    max-width: 472px;
    margin-top: 8px;
`

export const ReplyForm = () => {
    const isXS = useBreakpoint(down("xs"))
    const profile = useAppSelector(getCurrentUser)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = useForm()

    const handleSubmit = async () => {
        const { reply_to } = await form.validateFields()
        try {
            setIsLoading(true)
            await Api.company.patch({
                id: profile?.company?.id ?? 0,
                reply_to: reply_to
            })
            const { data } = await Api.user.profile()
            dispatch(setCurrentUser(data))
            form.resetFields();
        } catch (e) {
            const errors = getErrors(e)
            form.setFields(errors)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <CompanyInfoContainer p={16}>
            <Flex mb={isXS ? "0px" : "22px"} flexDirection="column" width="100%">
                <ContainerTitle>REPLY TO EMAIL ADDRESS</ContainerTitle>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item label="E-mail Address" name="reply_to" initialValue={profile?.company?.reply_to}>
                        <ReplyInput placeholder="Type here" />
                    </Form.Item>
                    <SaveBtnWrapper justifyContent="flex-end" marginTop={isXS ? "16px" : "0"}>
                    <Form.Item shouldUpdate>
                        {() => (
                            <SaveBtn loading={isLoading} disabled={!form.isFieldsTouched()} ml={16} htmlType="submit">
                            Save
                            </SaveBtn>
                        )}
                    </Form.Item>
                </SaveBtnWrapper>
                </Form>
            </Flex>
        </CompanyInfoContainer>
    )
}
