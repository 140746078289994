import styled from "styled-components";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import verifyError from "../../assets/images/verifyError.svg"
import verifySuccess from "../../assets/images/verifySuccess.svg"
import { Button } from "antd";
import { ITelgorithmBrandStatus } from "../../api/types";

const Container = styled(Flex)`
    width: 100%;
    max-width: 1000px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 32px 64px 64px;
    margin: 48px auto 64px;
`

const DefaultMsg = styled(Msg)`
    font-size: 18px;
    color: #202020;
    text-align: center;
`

const TitleMsg = styled(Msg)`
    font-size: 15px;
    font-weight: 900;
    color: #202020;
    text-align: center;
`

type TProps = {
    success: boolean
    handleContinue: () => void
    brandStatus?: ITelgorithmBrandStatus | null
}

export const TextMessagingAfterVerify: React.FC<TProps> = ({success, handleContinue, brandStatus}) => {

    const renderErrors = () => {
        if (!brandStatus) {
            return null;
        }
        return brandStatus?.category?.map(c => (<DefaultMsg>{`Error: ${c?.displayName} - ${c?.description}`}</DefaultMsg>))
    }

    return (
        <Container>
            <div style={{marginBottom: "32px"}}>
                <img src={success ? verifySuccess : verifyError} />
            </div>

            <TitleMsg>
                {success ? "YOUR MESSAGING ACCOUNT IS VERIFIED" : "YOUR MESSAGING ACCOUNT SETUP IS INCOMPLETE"}
            </TitleMsg>
            <Flex justifyContent="center" flexDirection="column" margin="24px 0 54px">
            {success ?
            <>             
                <DefaultMsg>Please proceed and register your business's new text messaging number to complete the last</DefaultMsg>
                <DefaultMsg>step of the registration.</DefaultMsg>
            </> :
            <>             
                {renderErrors()}
                <DefaultMsg>Please address the issue above to finish setting up your DockWorks Messaging number.</DefaultMsg>
                <DefaultMsg>If you have any questions or believe you have received this message in error,</DefaultMsg>
                <DefaultMsg>please contact <a href="mailto:support@dockworks.co">support@dockworks.co</a>.</DefaultMsg>
            </>}
            </Flex>
            <Button type="primary" onClick={handleContinue}>
                {success ? "Continue to Create Messaging Account" : "Edit Messaging Account Details" }
            </Button>
        </Container>
    );
}