import { DatePicker, Form, Select, TimePicker, Spin, Input, InputNumber, Button } from "antd"
import { CalendarIcon, ClockIcon, RecurringIcon } from "../../assets/icons/siteIcons"
import { DefaultDateFormat, DefaultTimeFormat } from "../../constants"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import styled from "styled-components"
import { useEffect, useMemo, useState } from "react"
import { ERecurringStatus, IUser, IWorkOrderWithJobs } from "../../api/types"
import { Api, TRecurringPricing } from "../../api/api"
import Hr from "../../ui/Hr"
import moment from "moment"
import { FormInstance } from "antd/es"
import { SelectValue } from "antd/lib/select"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { CalendarPicker } from "../../components/CalendarPicker"
import { TimePickerRange } from "../../components/CalendarPicker/TimePickerRange"
import { TCallback } from "../../types"
import { formatToUSD } from "../../utils/utils"

const Wrapper = styled(Flex)`
   flex-direction: column;
   padding: 24px 24px 0;
   width: 100%;
   ${down("lg")} {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 12px;
      margin-top: 24px;
   }
`

const ScheduleWrapper = styled(Flex)`
   > div:first-child {
      margin-right: 12px;
   }
   .ant-select-selection-item {
      width: 100px;
   }
   .ant-input-number {
      width: 50px;
   }
   ${down("lg")} {
      .ant-input-number {
         width: 50px !important;
      }
      div ~ div {
         width: 100%;
         .ant-select-selection-item {
            width: 100%;
         }
      }
   }
`

const FormColumnWrapper = styled(Flex)`
   flex-direction: row;
   > div:first-child {
      margin-right: 48px;
      > div {
         margin-right: 24px;
      }
   }
   @media screen and (max-width: 1500px) {
      flex-direction: column;
      > div:first-child {
         margin-right: 0;
         > div {
            margin-right: 24px;
         }
      }
   }
   ${down("lg")} {
      > div:first-child,
      > div:last-child {
         margin: 0px;
         > div {
            margin-right: 0;
         }
      }
      .ant-picker-range {
         .ant-picker-input:first-child {
            width: 65px;
            input {
               width: 65px;
            }
         }
      }
   }
`

const VerticalHR = styled.div`
   background: #ededed;
   width: 1px;
`

const FormLabel = styled(Msg)`
   font-size: 15px;
   font-weight: 700;
   color: #494949;
   padding: 8px 0 0;
`

const PricesBlock = styled(Flex)`
   border: 1px solid #ededed;
   border-radius: 4px;
   flex: 1;
   flex-direction: column;
   padding: 24px;
   > div,
   > span {
      margin-top: 8px;
   }
   > span:first-child {
      margin-top: 0;
   }
   > div {
      span ~ span {
         text-align: right;
      }
   }
   ${down("lg")} {
      span:nth-last-child(-n + 2) {
         text-align: left;
      }
   }
`

const PricesBlockTitle = styled(Msg)`
   font-size: 19px;
   font-weight: 900;
   color: #202020;
   text-transform: uppercase;
`

type TPricesBlockTextProps = {
   isMain?: boolean
   isBold?: boolean
}
const PricesBlockText = styled(Msg)<TPricesBlockTextProps>`
   color: ${(props) => (props.isMain ? "#494949" : "#8B8B8B")};
   font-size: 15px;
   font-weight: ${(props) => (props.isBold ? "700" : "400")};
`

const FormItem = styled.div`
   width: 190px;
   .ant-picker {
      width: 100%;
   }
   .ant-input-number {
      width: 100%;
   }
   .ant-picker-dropdown {
      z-index: 9;
   }
   .ant-select-dropdown {
      z-index: 9;
   }
   ${down("lg")} {
      width: 100%;
   }
   .ant-form-item-has-error #start_end_time > div {
      border-color: #FF4D4F;
   }
`

const StyledSelect = styled(Select)`
   .ant-select-selector {
      padding-left: 10px;
   }
   .ant-select-selection-overflow-item > span {
      margin-right: 5px;
      &::after {
         content: ",";
      }
   }
   .ant-select-selection-overflow-item:nth-last-child(-n + 2) > span::after {
      content: "";
   }
`

const MobileFieldRow = styled(Flex)`
   width: 100%;
   justify-content: space-between;
   > div {
      width: calc(50% - 8px);
   }
`
export const CalendarWrapper = styled.div`
   .ant-form-item-control-input {
      min-height: 0;
   }
`

type TOption = {
   label: string
   value: string
}

type TDoWOption = {
   label: string
   value: number
}

const billOptions: TOption[] = [
   { label: "Monthly", value: "M" },
   { label: "Annual", value: "A" },
]

const scheduleOptions: TOption[] = [
   { label: "per week", value: "W" },
   { label: "per month", value: "M" },
   { label: "per year", value: "Y" },
]

const dowOptions: TDoWOption[] = [
   { label: "Monday", value: 0 },
   { label: "Tuesday", value: 1 },
   { label: "Wednesday", value: 2 },
   { label: "Thursday", value: 3 },
   { label: "Friday", value: 4 },
   { label: "Saturday", value: 5 },
   { label: "Sunday", value: 6 },
]

type TProps = {
   workOrder: IWorkOrderWithJobs | null
   form: FormInstance<any>
   title?: string
   showMore: boolean
   setShowMore: (value: boolean) => void
   openRecurringModal: TCallback
   pricing: TRecurringPricing
}
type TTimeRange = null | any

const RecurringWorkOrder: React.FC<TProps> = ({ workOrder, form, title, showMore, setShowMore, openRecurringModal, pricing }) => {
   const [technicians, setTechnicians] = useState<IUser[]>([])
   const [selectedTech, setSelectedTech] = useState<number>(0)
   const [isLoading, setIsLoading] = useState(false)
   const [selectedDays, setSelectedDays] = useState<any>([])
   const [dayCount, setDayCount] = useState<number>(workOrder?.recurring_config?.times_per_period ?? 2)
   const isLg = useBreakpoint(down("lg"))
   const [schedulePeriod, setSchedulePeriod] = useState(workOrder?.recurring_config?.schedule_period ?? "W")
   const [firstAppointment, setFirstAppointment] = useState<any>(null)
   const [finalAppointment, setFinalAppointment] = useState<any>(null)
   const [firstBilling, setFirstBilling] = useState<any>(null)
   const [rangeTime, setRangeTime] = useState<TTimeRange[]>([null, null])

   const isDisabled = useMemo(
      () => workOrder?.recurring_status !== ERecurringStatus.Draft,
      [workOrder?.recurring_status]
   )

   useEffect(() => {
      if (selectedDays.length) {
         form.validateFields(["day_of_week"]).then()
      }
   }, [dayCount])

   useEffect(() => {
      if (firstAppointment) {
         form.setFieldsValue({ first_appointment_date: moment(firstAppointment) })
      }
   }, [firstAppointment])

   useEffect(() => {
      if (finalAppointment) {
         form.setFieldsValue({ final_appointment_date: moment(finalAppointment) })
      }
   }, [finalAppointment])

   useEffect(() => {
      if (firstBilling) {
         form.setFieldsValue({ first_billing_date: moment(firstBilling) })
      }
   }, [firstBilling])

   useEffect(() => {
      form.setFieldsValue({ start_end_time: [rangeTime[0] ? moment(rangeTime[0]) : rangeTime[0], rangeTime[1] ? moment(rangeTime[1]) : rangeTime[1]] })
   }, [rangeTime])

   useEffect(() => {
      form.validateFields(["times_per_period"]).then()
   }, [schedulePeriod])

   const loadData = async () => {
      try {
         setIsLoading(true)
         const res = await Api.user.getAll({})
         const data = res.data as IUser[]
         setTechnicians(data)
         if (data.length) {
            setSelectedTech(data[0].id)
         }
         fillForm()
      } finally {
         setIsLoading(false)
      }
   }

   const handleChangeTech = (id: number) => setSelectedTech(id)

   const getFormatedTime = (start: string, end: string) => {
      const currentDay = moment().format("YYYY-MM-DD")
      return [moment(`${currentDay} ${start}`), moment(`${currentDay} ${end}`)]
   }

   const fillForm = () => {
      const data = workOrder?.recurring_config
      if (data) {
         const formatedData = [
            { name: "first_appointment_date", value: moment(data.first_appointment_date) },
            { name: "final_appointment_date", value: moment(data.final_appointment_date) },
            { name: "first_billing_date", value: moment(data.first_billing_date) },
            { name: "start_end_time", value: getFormatedTime(data.start_time, data.end_time) },
            { name: "billing", value: data.billing },
            { name: "times_per_period", value: data.times_per_period },
            { name: "schedule_period", value: data.schedule_period },
            { name: "technician", value: data.technician },
            { name: "technician", value: data.technician },
            { name: "day_of_week", value: data.day_of_week },
            { name: "discount", value: data.discount },
         ]
         form.setFields(formatedData)

         data.first_appointment_date
            ? setFirstAppointment(new Date(data.first_appointment_date))
            : setFirstAppointment(null)

         data.final_appointment_date
            ? setFinalAppointment(new Date(data.final_appointment_date))
            : setFinalAppointment(null)

         data.first_billing_date ? setFirstBilling(new Date(data.first_billing_date)) : setFirstBilling(null)

         const times = getFormatedTime(data.start_time, data.end_time)
         data.start_time && data.end_time
            ? setRangeTime([new Date(times[0].format()), new Date(times[1].format())])
            : setRangeTime([null, null])

         setSelectedDays(data.day_of_week)
      }
   }

   const getPriceBeforeDiscount = (price: number) => {
      const discount = workOrder?.recurring_config?.discount ?? 0
      return (price / (100 - discount)) * 100
   }

   const getSchedulePeriod = () => {
      const period = workOrder?.recurring_config?.billing
      switch (period) {
         case "M":
            return "month"
         case "Y":
            return "year"
         default:
            return "week"
      }
   }

   const getMaxDayCount = () => {
      const period = form.getFieldValue("schedule_period")
      switch (period) {
         case "W":
            return 7
         case "M":
            return 31
         default:
            return 365
      }
   }

   const renderMobileFields = () => {
      return (
         <>
            <Flex flexDirection="column">
               <FormItem>
                  <FormLabel>First appointment date</FormLabel>

                  <CalendarWrapper>
                     <CalendarPicker
                        date={firstAppointment}
                        setDate={setFirstAppointment}
                        disableTime={true}
                        disable={isDisabled}
                     />
                     <Form.Item
                        name="first_appointment_date"
                        rules={[
                           {
                              required: true,
                              message: "Please choose first appointment date!",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Final appointment date</FormLabel>

                  <CalendarWrapper>
                     <CalendarPicker
                        date={finalAppointment}
                        setDate={setFinalAppointment}
                        disableTime={true}
                        disable={isDisabled}
                     />
                     <Form.Item
                        name="final_appointment_date"
                        rules={[
                           {
                              required: true,
                              message: "Please choose final appointment date!",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Start & End time</FormLabel>
                  <CalendarWrapper>
                     <TimePickerRange date={rangeTime} setDate={setRangeTime} disable={isDisabled} />
                     <Form.Item
                        name="start_end_time"
                        rules={[
                           {
                              required: true,
                              message: "Please choose start & end time!",
                           },
                        ]}
                     />
                  </CalendarWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Job frequency</FormLabel>
                  <ScheduleWrapper>
                     <Form.Item
                        initialValue={2}
                        name="times_per_period"
                        rules={[
                           {
                              required: true,
                              message: "Please input times per period!",
                           },
                           {
                              max: getMaxDayCount(),
                              type: "number",
                              message: "Maximum number of days exceeded",
                           },
                        ]}
                     >
                        <InputNumber
                           value={dayCount}
                           onChange={(value) => setDayCount(value)}
                           min={1}
                           max={getMaxDayCount()}
                           disabled={isDisabled}
                        />
                     </Form.Item>
                     <Form.Item
                        initialValue={scheduleOptions[0].value}
                        name="schedule_period"
                        rules={[
                           {
                              required: true,
                              message: "Please select schedule period!",
                           },
                        ]}
                     >
                        <Select
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           disabled={isDisabled}
                           onChange={(period) => setSchedulePeriod(period as string)}
                           value={schedulePeriod}
                        >
                           {scheduleOptions.map((option) => (
                              <Select.Option value={option.value}>{option.label}</Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </ScheduleWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Desired day(s) of week</FormLabel>
                  <Form.Item
                     name="day_of_week"
                     rules={[
                        {
                           required: true,
                           message: "Please select desired day(s) of week!",
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              const period = form.getFieldValue("schedule_period")
                              if (period === "W" && (!value || value.length === dayCount)) {
                                 return Promise.resolve()
                              }
                              if (period !== "W" && dayCount <= getMaxDayCount()) {
                                 return Promise.resolve()
                              }
                              return Promise.reject(
                                 new Error("Number of selected days does not correspond to times per week")
                              )
                           },
                        }),
                     ]}
                  >
                     <StyledSelect
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        placeholder="Select day(s)"
                        mode="multiple"
                        tagRender={(props: any) => <span>{props.label.slice(0, 3)}</span>}
                        onChange={(value) => setSelectedDays(value)}
                        value={selectedDays}
                        disabled={isDisabled}
                     >
                        {dowOptions.map((option) => (
                           <Select.Option
                              value={option.value}
                              disabled={selectedDays.length >= dayCount && !selectedDays.includes(option.value)}
                           >
                              {option.label}
                           </Select.Option>
                        ))}
                     </StyledSelect>
                  </Form.Item>
               </FormItem>
               <FormItem>
                  <FormLabel>Technician (Optional)</FormLabel>
                  <Form.Item name="technician">
                     <Select
                        value={selectedTech}
                        onChange={handleChangeTech}
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        disabled={isDisabled}
                     >
                        {technicians.map((tech) => (
                           <Select.Option value={tech.id}>{tech.full_name}</Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </FormItem>
               <Hr margin="16px 0" />
               <FormItem>
                  <FormLabel>First billing date</FormLabel>

                  <CalendarWrapper>
                     <CalendarPicker
                        date={firstBilling}
                        setDate={setFirstBilling}
                        disableTime={true}
                        disable={isDisabled}
                     />
                     <Form.Item
                        name="first_billing_date"
                        rules={[
                           {
                              required: true,
                              message: "Please choose first billing date!",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
               </FormItem>
               <MobileFieldRow>
                  <FormItem>
                     <FormLabel>Billing frequency</FormLabel>
                     <Form.Item
                        initialValue={billOptions[0].value}
                        name="billing"
                        rules={[
                           {
                              required: true,
                              message: "Please select billing type!",
                           },
                        ]}
                     >
                        <Select getPopupContainer={(trigger: any) => trigger.parentElement} disabled={isDisabled}>
                           {billOptions.map((option) => (
                              <Select.Option value={option.value}>{option.label}</Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </FormItem>
                  <FormItem>
                     <FormLabel>Discount (%)</FormLabel>
                     <Form.Item initialValue={0} name="discount">
                        <InputNumber min={0} max={100} disabled={isDisabled} />
                     </Form.Item>
                  </FormItem>
               </MobileFieldRow>
            </Flex>
         </>
      )
   }

   const renderDesktopFields = () => {
      return (
         <>
            <Flex flexDirection="column">
               <FormItem>
                  <FormLabel>First appointment date</FormLabel>

                  <CalendarWrapper>
                     <CalendarPicker
                        date={firstAppointment}
                        setDate={setFirstAppointment}
                        disableTime={true}
                        disable={isDisabled}
                     />
                     <Form.Item
                        name="first_appointment_date"
                        rules={[
                           {
                              required: true,
                              message: "Please choose first appointment date!",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Start & End time</FormLabel>
                     <Form.Item
                        name="start_end_time"
                        rules={[
                           {
                              required: true,
                              message: "Please choose start & end time!",
                           },
                        ]}
                     >
                        <CalendarWrapper>
                           <TimePickerRange date={rangeTime} setDate={setRangeTime} disable={isDisabled} />
                        </CalendarWrapper>
                     </Form.Item>
               </FormItem>
               <FormItem>
                  <FormLabel>Desired day(s) of week</FormLabel>
                  <Form.Item
                     name="day_of_week"
                     rules={[
                        {
                           required: true,
                           message: "Please select desired day(s) of week!",
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              const period = form.getFieldValue("schedule_period")
                              if (period === "W" && (!value || value.length === dayCount)) {
                                 return Promise.resolve()
                              }
                              if (period !== "W" && dayCount <= getMaxDayCount()) {
                                 return Promise.resolve()
                              }
                              return Promise.reject(
                                 new Error("Number of selected days does not correspond to times per week")
                              )
                           },
                        }),
                     ]}
                  >
                     <StyledSelect
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        placeholder="Select day(s)"
                        mode="multiple"
                        tagRender={(props: any) => <span>{props.label.slice(0, 3)}</span>}
                        onChange={(value) => setSelectedDays(value)}
                        value={selectedDays}
                        disabled={isDisabled}
                     >
                        {dowOptions.map((option) => (
                           <Select.Option
                              value={option.value}
                              disabled={selectedDays.length >= dayCount && !selectedDays.includes(option.value)}
                           >
                              {option.label}
                           </Select.Option>
                        ))}
                     </StyledSelect>
                  </Form.Item>
               </FormItem>
            </Flex>
            <Flex flexDirection="column">
               <FormItem>
                  <FormLabel>Final appointment date</FormLabel>

                  <CalendarWrapper>
                     <CalendarPicker
                        date={finalAppointment}
                        setDate={setFinalAppointment}
                        disableTime={true}
                        disable={isDisabled}
                     />
                     <Form.Item
                        name="final_appointment_date"
                        rules={[
                           {
                              required: true,
                              message: "Please choose final appointment date!",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Job frequency</FormLabel>
                  <ScheduleWrapper>
                     <Form.Item
                        initialValue={2}
                        name="times_per_period"
                        rules={[
                           {
                              required: true,
                              message: "Please input times per period!",
                           },
                           {
                              max: getMaxDayCount(),
                              type: "number",
                              message: "Maximum number of days exceeded",
                           },
                        ]}
                     >
                        <InputNumber
                           value={dayCount}
                           onChange={(value) => setDayCount(value)}
                           min={1}
                           max={getMaxDayCount()}
                           disabled={isDisabled}
                        />
                     </Form.Item>
                     <Form.Item
                        initialValue={scheduleOptions[0].value}
                        name="schedule_period"
                        rules={[
                           {
                              required: true,
                              message: "Please select schedule period!",
                           },
                        ]}
                     >
                        <Select
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           disabled={isDisabled}
                           onChange={(period) => setSchedulePeriod(period as string)}
                           value={schedulePeriod}
                        >
                           {scheduleOptions.map((option) => (
                              <Select.Option value={option.value}>{option.label}</Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </ScheduleWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Technician (Optional)</FormLabel>
                  <Form.Item name="technician">
                     <Select
                        value={selectedTech}
                        onChange={handleChangeTech}
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        disabled={isDisabled}
                     >
                        {technicians.map((tech) => (
                           <Select.Option value={tech.id}>{tech.full_name}</Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </FormItem>
            </Flex>
            <VerticalHR />
            <Flex flexDirection="column">
               <FormItem>
                  <FormLabel>First billing date</FormLabel>

                  <CalendarWrapper>
                     <CalendarPicker
                        date={firstBilling}
                        setDate={setFirstBilling}
                        disableTime={true}
                        disable={isDisabled}
                     />
                     <Form.Item
                        name="first_billing_date"
                        rules={[
                           {
                              required: true,
                              message: "Please choose first billing date!",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
               </FormItem>
               <FormItem>
                  <FormLabel>Billing frequency</FormLabel>
                  <Form.Item
                     initialValue={billOptions[0].value}
                     name="billing"
                     rules={[
                        {
                           required: true,
                           message: "Please select billing type!",
                        },
                     ]}
                  >
                     <Select getPopupContainer={(trigger: any) => trigger.parentElement} disabled={isDisabled}>
                        {billOptions.map((option) => (
                           <Select.Option value={option.value}>{option.label}</Select.Option>
                        ))}
                     </Select>
                  </Form.Item>
               </FormItem>
               <FormItem>
                  <FormLabel>Discount (%)</FormLabel>
                  <Form.Item initialValue={0} name="discount">
                     <InputNumber min={0} max={100} disabled={isDisabled} />
                  </Form.Item>
               </FormItem>
            </Flex>
         </>
      )
   }

   const StopSPButton = () => (
      <Flex>
         <RecurringIcon />
         <Msg
            style={{
               marginLeft: "6px",
               color: "#0496FF",
               textDecoration: "underline",
               fontWeight: 700,
               cursor: "pointer",
            }}
            onClick={openRecurringModal}
         >
            {workOrder?.recurring_status === ERecurringStatus.Approved
                     ? "Stop Service Plan"
                     : "Delete Service Plan"}
         </Msg>
      </Flex>
   )

   useEffect(() => {
      loadData().then()
   }, [workOrder])

   return (
      <Wrapper>
         {!isLg ? (
            <Flex width="100%" justifyContent="space-between" alignItems="center">
               <Msg fontWeight="800" fontSize="24px">
                  SERVICE PLAN SETUP
               </Msg>
               <StopSPButton />
            </Flex>
         ) : (
            <Flex justifyContent="space-between" width="100%" paddingBottom="24px">
               <Flex flexDirection="column">
                  <Msg fontWeight="800" fontSize="15px">
                     SERVICE PLAN SETUP
                  </Msg>
                  <StopSPButton />
               </Flex>
               <Button type="text" onClick={() => setShowMore(!showMore)}>
                  {!showMore ? <DownOutlined /> : <UpOutlined />}
               </Button>
            </Flex>
         )}
         {(showMore || !isLg) && (
            <Form form={form} layout="vertical" requiredMark={false} scrollToFirstError>
               <Spin spinning={isLoading}>
                  <FormColumnWrapper>
                     {isLg ? (
                        <Flex flexDirection="column">{renderMobileFields()}</Flex>
                     ) : (
                        <Flex flexDirection="row">{renderDesktopFields()}</Flex>
                     )}
                     <PricesBlock>
                        <PricesBlockTitle>Service plan prices</PricesBlockTitle>
                        <Flex justifyContent="space-between">
                           <PricesBlockText>Service plan price</PricesBlockText>
                           <PricesBlockText>
                              {formatToUSD(getPriceBeforeDiscount(pricing.payment))}/{getSchedulePeriod()}
                           </PricesBlockText>
                        </Flex>
                        <Flex justifyContent="space-between">
                           <PricesBlockText>Total overall price</PricesBlockText>
                           <PricesBlockText>
                              {formatToUSD(getPriceBeforeDiscount(pricing.total))} for {pricing.repeats} jobs
                           </PricesBlockText>
                        </Flex>
                        <Hr />
                        <Flex justifyContent="space-between">
                           <PricesBlockText>Discount</PricesBlockText>
                           <PricesBlockText>{workOrder?.recurring_config?.discount ?? 0}%</PricesBlockText>
                        </Flex>
                        <Hr />
                        <Flex flexDirection={isLg ? "column" : "row"} justifyContent="space-between">
                           <PricesBlockText isMain isBold>
                              Final overall price per period
                           </PricesBlockText>
                           <PricesBlockText isMain>
                              {formatToUSD(pricing.payment)}/{getSchedulePeriod()}
                           </PricesBlockText>
                        </Flex>
                        <Flex flexDirection={isLg ? "column" : "row"} justifyContent="space-between">
                           <PricesBlockText isMain isBold>
                              Final overall price for jobs
                           </PricesBlockText>
                           <PricesBlockText isMain>
                              {formatToUSD(pricing.total)} for {pricing.repeats} jobs
                           </PricesBlockText>
                        </Flex>
                     </PricesBlock>
                  </FormColumnWrapper>
               </Spin>
            </Form>
         )}
         {!isLg && <Hr style={{ backgroundColor: "#0F497B", height: "2px", marginTop: "12px" }} />}
      </Wrapper>
   )
}

export default RecurringWorkOrder
