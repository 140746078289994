import { useState, useEffect } from "react"
import { Msg } from "../../ui/Text"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { Modal, Radio, Menu, Dropdown, Button, Input, Form, InputNumber } from "antd"
import { DownOutlined, PlusOutlined } from "@ant-design/icons"
import { PrimaryButton } from "../../ui/Button"
import { CustomSelect } from "../../ui/Select"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { TArgCallback, TCallback } from "../../types"
import { useResponsive } from "../../utils/hooks"
import { servicesUnitTypes } from "../../constants"

const StyledModal = styled(Modal)`
   & .ant-modal-body {
      padding: 0;
   }

   & .ant-modal-footer {
      display: none;
   }
`
const FormHeader = styled.div`
   padding: 0 16px;
`
const FormFooter = styled.div`
   border-top: 1px solid #ddd;
   padding: 16px;
`
const ModalCancelBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
`
const ModalCreateBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   border: 0.7px solid #1c99fc;
`
const StyledMenu = styled(Menu)`
   padding: 10px 14px 20px 14px;
   font-size: 14px;
   border-radius: 4px;
   overflow: auto;
   max-height: 300px;

   &::-webkit-scrollbar {
      width: 8px;
   }

   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }

   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }

   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
`
const StyledMenuItem = styled(Flex)`
   margin: 0 0 12px 12px;
   cursor: pointer;
   color: #0496ff;
`
const Buttons = styled(Flex)`
   ${down("xs")} {
      button {
         width: 100%;
         max-width: 100%;
      }
   }
`
const Row = styled(Flex)`
   display: inline-flex;
   gap: 15px;
   @media (max-width: 640px) {
      gap: 0;
      flex-direction: column;
   }
   & .rowItem {
      width: 50%;
      @media (max-width: 640px) {
         width: 100%;
      }
   }
`
const FlexDropdown = styled(Flex)`
   padding: 4px 10px;
   border: 1px solid #d9d9d9;
   border-radius: 4px;
`
const CreateGroupInput = styled(Flex)`
   width: 100%;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   padding: 5px;
   height: 38px;
   position: relative;
   &.error {
      border: 1px solid #fb4d4f;
   }
   input {
      border: none;
      outline: none;
      box-shadow: none;
      &:focus {
         border: none;
         outline: none;
         box-shadow: none;
      }
   }
   button {
      border: none;
      color: #0496ff;
      font-weight: bold;
      font-size: 13px;
   }
`
const InputError = styled.div`
   position: absolute;
   left: 3px;
   top: 105%;
   color: #fb4d4f;
   font-size: 11px;
   width: 100%;
`
const StyledSelect = styled(CustomSelect)`
   &.ant-select {
      width: 142px;
      ${down("xs")} {
         width: 150px;
      }
   }
`

type TNewGroup = {
   name: string | ""
   error: string | ""
}
type TFormGroup = {
   name: string | ""
   description: string | ""
   price: number | ""
   unit_cost: number | ""
   sku: number | ""
   id: number
   unit: string | ""
}

type TProps = {
   visible: boolean
   clearModal: TCallback
   handleSubmit: TArgCallback<any>
   handleCreateGroupe: TArgCallback<any>
   activeGroup: any
   groups: any
   values?: TFormGroup
   edit?: boolean
   type?: string
}
const ModalFormEditAdd: React.FC<TProps> = ({
   visible,
   clearModal,
   handleSubmit,
   activeGroup,
   groups,
   handleCreateGroupe,
   values,
   edit,
   type,
}) => {
   const [form] = Form.useForm()
   const [dropdownVisible, setDropdownVisible] = useState(false)
   const [selectedGroup, setSelectedGroup] = useState(activeGroup)
   const [createGroup, setCreateGroup] = useState<boolean>(false)
   const [newGroup, setNewGroup] = useState<TNewGroup>({
      name: "",
      error: "",
   })

   const isXs = useBreakpoint(down("xs"))
   const { width } = useResponsive()

   useEffect(() => {
      setSelectedGroup(activeGroup)
   }, [activeGroup.id])

   const addGroupForm = () => (
      <CreateGroupInput alignItems="center" className={newGroup.error ? "error" : ""}>
         <Input value={newGroup.name} onChange={handleChange} />
         <Button onClick={handleCreate}>Add</Button>
         <InputError>{newGroup.error}</InputError>
      </CreateGroupInput>
   )

   const menu = (
      <StyledMenu>
         <Radio.Group name="group" defaultValue={selectedGroup?.id}>
            {groups.map((group: any) => (
               <StyledMenuItem key={group.id}>
                  <Radio
                     value={group.id}
                     onChange={() => {
                        setSelectedGroup(group)
                        setDropdownVisible(false)
                        setCreateGroup(false)
                     }}
                  >
                     {group.name}
                  </Radio>
               </StyledMenuItem>
            ))}
         </Radio.Group>

         {createGroup ? (
            addGroupForm()
         ) : (
            <StyledMenuItem alignItems="center" onClick={() => setCreateGroup(true)} style={{ margin: "0 0 0 12px" }}>
               <PlusOutlined
                  style={{
                     fontSize: "10px",
                     marginRight: "6px",
                     marginTop: "-2.5px",
                  }}
               />
               Create new Material Type
            </StyledMenuItem>
         )}
      </StyledMenu>
   )

   const handleChange = (e: any) => {
      setNewGroup({
         ...newGroup,
         name: e.target.value,
         error: "",
      })
   }

   const handleCreate = () => {
      if (newGroup.name.trim().length > 0 && newGroup.name.trim().length < 26) {
         handleCreateGroupe(newGroup.name)
         setCreateGroup(false)
         setNewGroup({
            name: "",
            error: "",
         })
      } else if (newGroup.name.trim().length === 0) {
         setNewGroup({
            ...newGroup,
            error: "Please, input name!",
         })
      } else if (newGroup.name.trim().length > 25) {
         setNewGroup({
            ...newGroup,
            error: "Ensure this field has no more than 25 characters!",
         })
      }
   }

   const handleClose = () => {
      clearModal()
      form.resetFields()
      setSelectedGroup(activeGroup)
   }

   const handleSubmitForm = (data: any) => {
      let initialData = null

      if (values) {
         initialData = {
            id: values.id,
         }
      }

      handleSubmit({
         ...data,
         group: selectedGroup,
         ...initialData,
      })
      form.resetFields()
      clearModal()
   }

   return type && type === "rateCard" ? (
      <StyledModal destroyOnClose centered visible={visible} width={isXs ? "100%" : 648} onCancel={handleClose}>
         <Msg fontWeight={900} fontSize={15} color="#151F2B" padding="16px 16px 10px 16px" display="block">
            {edit ? "EDIT" : "ADD NEW"} SERVICE
         </Msg>
         <Form
            form={form}
            onFinish={(values) => handleSubmitForm(values)}
            initialValues={{
               ["name"]: values ? values?.name : "",
               ["description"]: values ? values?.description : "",
               ["price"]: values ? values?.price : "",
               ["unit"]: values ? values?.unit : "",
            }}
         >
            <FormHeader>
               <Flex flexDirection={"column"} justifyContent="space-between" mt="16px">
                  <Row>
                     <Flex flexDirection="column" className="rowItem">
                        <Msg fontWeight={700} fontSize={13} color="#484848">
                           Service Name
                        </Msg>
                        <Form.Item
                           name="name"
                           rules={[
                              { whitespace: true, message: "The field is required." },
                              {
                                 required: true,
                                 message: "The field is required.",
                              },
                              { max: 255, message: "Ensure this field has no more than 255 characters!" },
                           ]}
                           style={{ minWidth: "190px" }}
                        >
                           <Input placeholder="Type here" />
                        </Form.Item>
                     </Flex>
                     <Flex style={{ display: "inline-flex", gap: "15px" }}>
                        <Flex flexDirection="column">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Unit
                           </Msg>
                           <Form.Item
                              name="unit"
                              rules={[
                                 {
                                    required: true,
                                    message: "The field is required.",
                                 },
                              ]}
                           >
                              <StyledSelect
                                 placeholder="Select type"
                                 options={servicesUnitTypes}
                                 getPopupContainer={(trigger: any) => trigger.parentElement}
                              />
                           </Form.Item>
                        </Flex>
                        <Flex flexDirection="column">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Price
                           </Msg>
                           <Form.Item
                              name="price"
                              rules={[
                                 {
                                    required: true,
                                    message: "The field is required.",
                                 },
                              ]}
                           >
                              <InputNumber
                                 placeholder="$0.00"
                                 style={{ width: width > 640 ? "140px" : "100%", borderRadius: "4px" }}
                                 step="0.01"
                                 stringMode
                                 min={0}
                              />
                           </Form.Item>
                        </Flex>
                     </Flex>
                  </Row>
                  <Row>
                     {width > 640 && (
                        <Flex flexDirection="column" className="rowItem">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Description
                           </Msg>
                           <Form.Item
                              name="description"
                              rules={[{ max: 1000, message: "Ensure this field has no more than 1000 characters!" }]}
                           >
                              <Input.TextArea style={{ height: "105px" }} placeholder="Type here" />
                           </Form.Item>
                        </Flex>
                     )}

                     <Flex flexDirection="column" className="rowItem">
                        <Flex flexDirection="column">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Service Group
                           </Msg>
                           <Form.Item name="group" style={{ width: "100%" }}>
                              <Dropdown
                                 overlay={menu}
                                 placement="bottomCenter"
                                 trigger={["click"]}
                                 onVisibleChange={() => {
                                    setDropdownVisible(!dropdownVisible)
                                    setCreateGroup(false)
                                 }}
                                 visible={dropdownVisible}
                              >
                                 <FlexDropdown alignItems="center" justifyContent="space-between">
                                    <div style={{ width: "100%" }}>{selectedGroup?.name && selectedGroup.name}</div>

                                    <DownOutlined />
                                 </FlexDropdown>
                              </Dropdown>
                           </Form.Item>
                        </Flex>
                     </Flex>
                     {width < 640 && (
                        <Flex flexDirection="column" className="rowItem">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Description
                           </Msg>
                           <Form.Item
                              name="description"
                              rules={[{ max: 1000, message: "Ensure this field has no more than 1000 characters!" }]}
                           >
                              <Input.TextArea style={{ height: "105px" }} placeholder="Type here" />
                           </Form.Item>
                        </Flex>
                     )}
                  </Row>
               </Flex>
            </FormHeader>
            <FormFooter>
               <Buttons justifyContent="flex-end">
                  <ModalCancelBtn onClick={handleClose}>Cancel</ModalCancelBtn>
                  <ModalCreateBtn ml={16} htmlType="submit">
                     {edit ? "Save" : "Create"}
                  </ModalCreateBtn>
               </Buttons>
            </FormFooter>
         </Form>
      </StyledModal>
   ) : (
      <StyledModal destroyOnClose centered visible={visible} width={isXs ? "100%" : 648} onCancel={handleClose}>
         <Msg fontWeight={900} fontSize={15} color="#151F2B" padding="16px 16px 10px 16px" display="block">
            {edit ? "EDIT" : "ADD NEW"} MATERIAL
         </Msg>
         <Form
            form={form}
            onFinish={(values) => handleSubmitForm(values)}
            initialValues={{
               ["name"]: values ? values?.name : "",
               ["description"]: values ? values?.description : "",
               ["cost"]: values ? values?.unit_cost : "",
               ["price"]: values ? values?.price : "",
               ["sku"]: values ? values?.sku : "",
            }}
         >
            <FormHeader>
               <Flex flexDirection={"column"} justifyContent="space-between" mt="16px">
                  <Row>
                     <Flex flexDirection="column" className="rowItem">
                        <Msg fontWeight={700} fontSize={13} color="#484848">
                           Material Name
                        </Msg>
                        <Form.Item
                           name="name"
                           rules={[
                              { whitespace: true, message: "The field is required." },
                              {
                                 required: true,
                                 message: "The field is required.",
                              },
                              { max: 255, message: "Ensure this field has no more than 255 characters!" },
                           ]}
                           style={{ minWidth: "190px" }}
                        >
                           <Input placeholder="Type here" />
                        </Form.Item>
                     </Flex>
                     <Flex flexDirection="column" className="rowItem">
                        <Msg fontWeight={700} fontSize={13} color="#484848">
                           SKU Number
                        </Msg>
                        <Form.Item name="sku" style={{ maxWidth: width > 640 ? "210px" : "100%" }}>
                           <Input placeholder="Type here" />
                        </Form.Item>
                     </Flex>
                  </Row>
                  <Row>
                     {width > 640 && (
                        <Flex flexDirection="column" className="rowItem">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Description
                           </Msg>
                           <Form.Item
                              name="description"
                              rules={[{ max: 1000, message: "Ensure this field has no more than 1000 characters!" }]}
                           >
                              <Input.TextArea style={{ height: "105px" }} placeholder="Type here" />
                           </Form.Item>
                        </Flex>
                     )}

                     <Flex flexDirection="column" className="rowItem">
                        <Flex style={{ display: "inline-flex", gap: "15px" }}>
                           <Flex flexDirection="column">
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 Unit Cost
                              </Msg>
                              <Form.Item
                                 name="cost"
                                 rules={[
                                    {
                                       required: true,
                                       message: "The field is required.",
                                    },
                                 ]}
                              >
                                 <InputNumber
                                    placeholder="$0.00"
                                    style={{ width: width > 640 ? "140px" : "100%", borderRadius: "4px" }}
                                    step="0.01"
                                    stringMode
                                    min={0}
                                 />
                              </Form.Item>
                           </Flex>
                           <Flex flexDirection="column">
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 Unit Price
                              </Msg>
                              <Form.Item
                                 name="price"
                                 rules={[
                                    {
                                       required: true,
                                       message: "The field is required.",
                                    },
                                 ]}
                              >
                                 <InputNumber
                                    placeholder="$0.00"
                                    style={{ width: width > 640 ? "140px" : "100%", borderRadius: "4px" }}
                                    step="0.01"
                                    stringMode
                                    min={0}
                                 />
                              </Form.Item>
                           </Flex>
                        </Flex>
                        <Flex flexDirection="column">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Material Group
                           </Msg>
                           <Form.Item name="group" style={{ width: "100%" }}>
                              <Dropdown
                                 overlay={menu}
                                 placement="bottomCenter"
                                 trigger={["click"]}
                                 onVisibleChange={() => {
                                    setDropdownVisible(!dropdownVisible)
                                    setCreateGroup(false)
                                 }}
                                 visible={dropdownVisible}
                              >
                                 <FlexDropdown alignItems="center" justifyContent="space-between">
                                    <div style={{ width: "100%" }}>{selectedGroup?.name && selectedGroup.name}</div>

                                    <DownOutlined />
                                 </FlexDropdown>
                              </Dropdown>
                           </Form.Item>
                        </Flex>
                     </Flex>
                     {width < 640 && (
                        <Flex flexDirection="column" className="rowItem">
                           <Msg fontWeight={700} fontSize={13} color="#484848">
                              Description
                           </Msg>
                           <Form.Item
                              name="description"
                              rules={[{ max: 1000, message: "Ensure this field has no more than 1000 characters!" }]}
                           >
                              <Input.TextArea style={{ height: "105px" }} placeholder="Type here" />
                           </Form.Item>
                        </Flex>
                     )}
                  </Row>
               </Flex>
            </FormHeader>
            <FormFooter>
               <Buttons justifyContent="flex-end">
                  <ModalCancelBtn onClick={handleClose}>Cancel</ModalCancelBtn>
                  <ModalCreateBtn ml={16} htmlType="submit">
                     {edit ? "Save" : "Create"}
                  </ModalCreateBtn>
               </Buttons>
            </FormFooter>
         </Form>
      </StyledModal>
   )
}

export default ModalFormEditAdd
