import styled from "styled-components"
import { down } from "styled-breakpoints"
import Flex from "../../noui/Flex"
import { PrimaryButton } from "../../ui/Button"
import { Button, Input, Upload } from "antd"
import { Msg } from "../../ui/Text"
import UploadImg from "../../assets/images/UploadPhotoBig.svg"

export const ContentStyled = styled(Flex)`
   background-color: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
   border-radius: 4px;
`

export const PersonalInfoContainer = styled(Flex)`
   max-width: 928px;
   width: 100%;
   margin: auto;
   margin-bottom: 36px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
   @media screen and (max-width: 1285px) {
      box-shadow: none;
      margin-bottom: 0;
   }
`

export const UploadWrapper = styled(Button)`
   position: relative;
   display: flex;
   flex-wrap: wrap;
   height: 312px;
   flex-direction: column;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   width: 100%;
   // width: 192px;
   padding: 0;
   text-align: center;
   outline: 0 !important;
   align-items: center;
   justify-content: center;
   &.ant-btn > span {
      display: flex;
   }

   &.ant-row.ant-form-item {
      width: 100%;
   }

   & span {
      display: inline-block !important;
      // white-space: pre-wrap
      // width: 192px;
      // margin-top: 24px;
      text-align: center;
   }

   &:focus {
      outline: 0 !important;
   }
`

export const UploadImage = styled(Input)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 312px;
   //   max-width: 440px;
   //   width: 100%;
   padding: 0px 29px;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   & input {
      font-weight: 400px;
      font-size: 15px;
      color: #9aa1aa;
      width: 192px;
   }
   & ::value {
      font-weight: 400px;

      font-size: 15px;
      color: #9aa1aa;
   }
   & :focus {
      outline: 0 !important;
   }
   & .ant-input-prefix {
      width: 26px;
      display: inline-block;
      margin-right: 19px;
   }
   &.ant-input-affix-wrapper > input.ant-input {
      width: 192px;
      height: 40px;
   }
`

export const ImageContainer = styled.div`
   width: 100%;
   height: 312px;
   background-image: ${(props) => `url(${props.image})`};
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
`

export const MainUpload = styled(Upload)`
   width: 100%;
   display: block;
   & button {
      // max-width: 440px;
      width: 100%;
   }
   text-align: center;
   margin: auto;
   .ant-upload-list {
      display: none;
   }
`

export const UploadContainer = styled(Flex)`
   max-width: 440px;
   width: 100%;
   & .ant-upload.ant-upload-select.ant-upload-select-text {
      width: 100%;
   }
   & .ant-row.ant-form-item.ant-form-item-has-success {
      width: 100%;
      text-align: center;
   }
   text-align: center;
   & .ant-row.ant-form-item {
      width: 100%;
   }
   & .ant-col.ant-form-item-label {
      display: none;
   }
`

export const UploadtContainerAfter = styled(Flex)`
   max-width: 440px;
   width: 100%;
   ${down("md")} {
      margin: 0 auto;
   }
`

export const UploadtContainerAfterMsg = styled(Msg)`
   // max-width: 440px;
   width: 312px;
   margin-top: 12px;
   ${down("xs")} {
      width: 100%;
   }
`

export const AddAttachment = styled(Msg)`
   // max-width: 440px;
   width: 100px;
   border-bottom: 1px dashed #1c99fc;
`

export const ImageWrapperFlex = styled(Flex)`
   & div {
      width: 100%;
      text-align: center;
      position: relative;
   }
   & img {
      flex: 0 0 440px;
   }
   & button {
      position: absolute;
      right: 3px;
      color: red;
      &:hover {
         background-color: 0;
         background: 0;
      }
   }
`

export const CloseLogoButton = styled(PrimaryButton)`
   padding: 0;
   background-color: 0;
   background: 0;
   color: #ffffff;
   &.ant-btn.cancelButton {
      height: 20px;
      border: none;
      span {
         color: red;
      }
   }
`

export const MainInfoContainer = styled(Flex)`
   max-width: 440px;
   width: 100%;
   ${down("md")} {
      margin: 0 auto;
   }
`

export const PersonalInfoWrapper = styled(Flex)`
   ${down("md")} {
      flex-direction: column-reverse;
   }
`

const UploadPhoto = (props) => <img src={UploadImg} alt="Envelope" />
export const UploadtContent = styled(UploadPhoto)`
   display: inline-block;
   width: 26px;
`

export const CancelBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
   margin-right: 16px;
   ${down("xs")} {
      width: calc(50% - 8px);
   }
`

export const SaveBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
   ${down("xs")} {
      width: calc(50% - 8px);
   }
`
