import { Switch } from "antd"
import styled from "styled-components"
import { ICreateEstimate } from "../../api/types"
import Flex from "../../noui/Flex"
import { TArgCallback } from "../../types"
import { down } from "styled-breakpoints"


const Card = styled.div`
   padding: 12px;
   width: 266px;
   border-radius: 4px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   font-size: 13px;
   background: #ffffff;
   display: flex;
   flex-direction: column;
   gap: 4px;
   align-items: center;
   justify-content: stretch;
   p {
      padding: 0;
      margin: 0;
   }
   h4 {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 17px;
   }
   a {
      align-self: end;
   }
   > .ant-form-item {
      margin-bottom: 0;
   }
   .card_content {
      position: relative;
   }
   ${down("xs")} {
      width: 100%;
      .card_content {
         width: 100%;
         justify-content: space-between;
      }
   }
`

type TCardItem = {
    title: string
    description: string
    name: keyof ICreateEstimate
 }

type TCardProps = {
    item: TCardItem
    handleCheck: TArgCallback<any>
    checked: boolean
}

export const CardSwitch: React.FC<TCardProps> = ({ item, checked, handleCheck }) => {
    return (
       <Card>
          <Flex flexDirection="row" className="card_content">
             <div className="content">
                <h4>{item.title}</h4>                
                <p>{item.description}</p>
             </div> 
             <Switch
                checked={checked}
                onChange={(checked) => handleCheck({ name: item.name, value: checked })}
             />
          </Flex>
       </Card>
    )
}