import styled from "styled-components";
import {Button} from "antd";

export const LinkButton = styled(Button)`
  font-weight: bold;
  > span {
    text-decoration: underline;
  }
`;

export const EditButton = styled(LinkButton)`
  position: absolute;
  top: 6px;
  right: 0;
`;
export const MoreButton = styled(LinkButton)`
  align-self: center;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  > p {
    margin: 0; 
  }
`;

export const Title = styled.h4`
  font-weight: bold;
  font-size: 16px;
  margin: 12px 0 0;
`;