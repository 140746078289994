import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { Form, DatePicker, Select, Button } from "antd"
import { Msg } from "../../ui/Text"
import { IReportsRequest, IUser } from "../../api/types"
import { reportType } from "../../constants"
import { ExportBtn } from "../../ui/TableUtils"
import { TArgCallback, TOption } from "../../types"
import { mapOptions } from "../../utils/utils"
import { useLoading } from "../../utils/hooks"
import SearchIcon from "../../assets/images/searchIcon.svg"
import NoDataIcon from "../../assets/images/Icon_No_data.svg"
import { Api } from "../../api/api"
import { downloadFile } from "../../utils/utils"
import { CalendarPicker } from "../../components/CalendarPicker"
import {
   FlexRow,
   CustomDivider,
   ReportForm,
   DateInputs,
   DateInputsError,
   GenerateReportBlock,
   CalendarWrapper,
} from "./styled"

type TProps = {
   data?: any
   handleGenerateReport: TArgCallback<any>
   setData: TArgCallback<any>
   handleChangeType: TArgCallback<any>
}

const ReportsForm: React.FC<TProps> = ({ data, handleGenerateReport, setData, handleChangeType }) => {
   const [form] = Form.useForm()
   const [formErrors, setFormErrors] = useState({ invalidReportTime: "" })
   const [options, setOptions] = useState<{
      data: IUser[]
      mapped: TOption[]
   }>({ data: [], mapped: [] })
   const [startDate, setStartDate] = useState<any>(null)
   const [endDate, setEndDate] = useState<any>(null)

   const [saving, onSave] = useLoading()

   useEffect(() => {
      Api.user.getAll({}).then(({ data: d }) => {
         const results = d as IUser[]
         setOptions({ data: results, mapped: [{ value: "all", label: "All" }, ...mapOptions(results, "full_name")] })
      })
   }, [])

   useEffect(() => {
      if (startDate) {
         form.setFieldsValue({ dateStart: moment(startDate) })
      }
   }, [startDate])

   useEffect(() => {
      if (endDate) {
         form.setFieldsValue({ dateEnd: moment(endDate) })
      }
   }, [endDate])

   const onChange = () => {
      setFormErrors({
         ...formErrors,
         invalidReportTime: "",
      })
      setData(null)
   }

   const handleSubmit = useCallback(
      (values: any) => {
         if (values.dateEnd < values.dateStart) {
            setFormErrors({ ...formErrors, invalidReportTime: "End Date can not be earlier than Start Date" })
         } else {
            setFormErrors({ ...formErrors, invalidReportTime: "" })
            handleGenerateReport(values)
         }
      },
      [formErrors]
   )

   const handleExport = async () => {
      const values = form.getFieldsValue()
      let dataSend = {
         start_after: moment(values.dateStart._d).format("YYYY-M-DD"),
         start_before: moment(values.dateEnd._d).format("YYYY-M-DD"),
      } as IReportsRequest

      if (values.employee !== "all") {
         dataSend = {
            ...dataSend,
            user: values.employee,
         }
      }
      const { data } = await onSave(Api.timeClocks.downloadReports(dataSend))
      const findUser = options.mapped.find((item) => item.value === values.employee)
      const name = findUser ? findUser.label : ""
      downloadFile(
         data,
         `Reports_${name}_${moment(values.dateStart._d).format("YYYY-M-DD")}_${moment(values.dateEnd._d).format(
            "YYYY-M-DD"
         )}.xlsx`
      )
   }

   const disableExport = () => {
      const { employee, dateStart, dateEnd } = form.getFieldsValue()
      if (!employee || !dateEnd || !dateStart || dateEnd < dateStart) {
         return true
      }

      return false
   }

   const isXs = useBreakpoint(down("xs"))

   return (
      <ReportForm>
         <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            initialValues={{
               ["workType"]: reportType[0].value,
            }}
            onFinish={handleSubmit}
         >
            <FlexRow>
               <Form.Item
                  label="Employee"
                  name="employee"
                  rules={[
                     {
                        required: true,
                        message: "The field is required.",
                     },
                  ]}
               >
                  <Select
                     placeholder="Select employee"
                     onChange={onChange}
                     filterOption={false}
                     options={options.mapped}
                     //style={{ width: 208 }}
                  >
                     {options.mapped.map((r) => (
                        <Select.Option value={r.value} key={r.value}>
                           {r.label}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <DateInputs>
                  <CalendarWrapper>
                     <CalendarPicker label="Start Date" date={startDate} setDate={setStartDate} disableTime={true} />
                     <Form.Item
                        name="dateStart"
                        rules={[
                           {
                              required: true,
                              message: "The field is required.",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
                  <CustomDivider />
                  <CalendarWrapper>
                     <CalendarPicker label="End Date" date={endDate} setDate={setEndDate} disableTime={true} />
                     <Form.Item
                        name="dateEnd"
                        rules={[
                           {
                              required: true,
                              message: "The field is required.",
                           },
                        ]}
                     ></Form.Item>
                  </CalendarWrapper>
                  {formErrors && formErrors?.invalidReportTime && (
                     <DateInputsError>{formErrors?.invalidReportTime}</DateInputsError>
                  )}
               </DateInputs>
            </FlexRow>
            <FlexRow justifyContent="space-between" width="100%">
               <Form.Item name="workType">
                  <Select
                     getPopupContainer={(trigger: any) => trigger.parentElement}
                     onChange={(v) => handleChangeType(v)}
                  >
                     {reportType.map((r) => (
                        <Select.Option value={r.value} key={r.value}>
                           {r.label}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               {!isXs && (
                  <ExportBtn loading={saving} onClick={handleExport} disabled={disableExport()}>
                     Export
                  </ExportBtn>
               )}
            </FlexRow>

            {!data ? (
               <GenerateReportBlock flexDirection="column" alignItems="center" justifyContent="center">
                  <img src={SearchIcon} alt="search" />
                  <Msg
                     fontSize="13px"
                     color="#202020"
                     fontWeight="bold"
                     textAlign="center"
                     margin="15px 0"
                     style={{ maxWidth: "282px" }}
                  >
                     You need to refresh “Data” when you choose employee or date
                  </Msg>
                  <Button type="primary" htmlType="submit">
                     Generate report
                  </Button>
               </GenerateReportBlock>
            ) : (
               data &&
               data.length === 0 && (
                  <GenerateReportBlock flexDirection="column" alignItems="center" justifyContent="center">
                     <img src={NoDataIcon} alt="no data" />
                     <Msg
                        fontSize="13px"
                        color="#202020"
                        fontWeight="bold"
                        textAlign="center"
                        margin="15px 0"
                        style={{ maxWidth: "225px" }}
                     >
                        No data, please select the employee and enter the time period!
                     </Msg>
                  </GenerateReportBlock>
               )
            )}
         </Form>
      </ReportForm>
   )
}
export default ReportsForm
