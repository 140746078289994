import { Button, Form, Input, InputNumber, Modal, notification, Spin } from "antd"
import {
   AuBankAccountElement,
   CardCvcElement,
   CardExpiryElement,
   CardNumberElement,
   FpxBankElement,
   IbanElement,
   IdealBankElement,
   PaymentElement,
   useElements,
   useStripe,
} from "@stripe/react-stripe-js"
import React, { FormEvent, useEffect, useState } from "react"
import { Msg } from "../../../ui/Text"
import { ModalWrapper, TPaymentModalProps } from "../../../layouts/CustomerLayout/PaymentModal"
import { Api, TPaymentStripe } from "../../../api/api"
import { IExternalEstimate, IExternalRecurring } from "../../../api/types"
import { TCallback } from "../../../types"
import { useForm } from "antd/es/form/Form"
import { track } from "../../../utils/analytics"
import moment from "moment"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { PrimaryButton } from "../../../ui/Button"
import { PaymentIntent, SetupIntent } from "@stripe/stripe-js"
import SaveCardItem from "./SaveCardItem"

export type TCheckoutForm = {
   intentStripeQuery: TPaymentStripe
   onCancel: () => void
   onSubmit: () => void
   onPending: (value: boolean) => void
   disabledPay: boolean
   onApprove: TCallback
   estimate: IExternalEstimate | IExternalRecurring
   isInvoice: boolean
   paymentIntentId?: string
   clientSecret: string
}
interface Props {
   onInputFocus: boolean
}
export const SaveCardsWrapper = styled.div`
   overflow: auto;
   height: 200px;
`
export type FirstAmountType = Parameters<typeof Form>[0]["about"]
export type SizeType = Parameters<typeof Form>[0]["size"]

const CheckoutForm: React.FC<TCheckoutForm> = ({
   intentStripeQuery,
   estimate,
   onApprove,
   disabledPay,
   onCancel,
   onSubmit,
   onPending,
   isInvoice,
   clientSecret,
}) => {
   const elements = useElements()
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const [clientSecretRes, setClientSecretRes] = useState<string | null>(null)

   const [isVisibleVerifyBankAcModal, setIsVisibleVerifyBankAcModal] = useState<boolean>(false)
   const [form] = useForm()
   const stripe = useStripe()
   const cardList = estimate?.payment_methods
   const [selectedCardId, setSelectedCardId] = useState<string>(cardList[0]?.id)
   const [paymentButSavedCard, setPaymentButSavedCard] = useState<boolean>(cardList?.length > 0)
   const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      if (!stripe || !elements) {
         return
      }
      setIsLoading(true)
      const { error, paymentIntent } = paymentButSavedCard
         ? await stripe.confirmCardPayment(clientSecret, {
              payment_method: selectedCardId,
           })
         : await stripe.confirmPayment({
              elements,
              confirmParams: {
                 return_url: `${window.location.origin}`,
              },
              redirect: "if_required",
           })

      if (error) {
         notification.error({ message: error.message })
         setIsLoading(false)
         return
      } else if (paymentIntent && paymentIntent.status === "requires_action") {
         setClientSecretRes(paymentIntent.client_secret)
         setIsVisibleVerifyBankAcModal(true)
         return
      } else if (paymentIntent && paymentIntent.status === "processing") {
         onPending(true)
         onCancel()
         notification.success({ message: "Your payment is pending" })
         return
      }
      notification.success({ message: "Successfully paid" })
      if (!isInvoice) {
         await onApprove()
      } else {
         const paymentType = paymentIntent?.payment_method as string
         await track("Payment Received Online", {
            customerId: estimate.work_order.customer.id,
            workorderName: estimate.work_order.title,
            workorderId: estimate.work_order.id,
            vesselId: estimate.work_order?.vessel.id,
            vesselCity: estimate.work_order.current_address?.city,
            vesselState: estimate.work_order.current_address?.state,
            vesselType: estimate.work_order?.vessel.vessel_type,
            jobCreatedBy: estimate.work_order.created_by.id,
            workorderCreatedBy: estimate.work_order.created_by.id,
            workorderTotal: estimate.work_order.total,
            amountRequested: intentStripeQuery.amount,
            invoicePaid: intentStripeQuery.amount,
            invoicePaidTimestamp: moment().toISOString(),
            paymentType: paymentType,
            receiptID: paymentIntent?.id,
         })
      }
      onSubmit()
      onCancel()
   }

   const verifyOnFinish = async (values: any) => {
      const { firstAmount, secondAmount } = values
      if (!stripe || !elements || !clientSecretRes) {
         return
      }

      const { error, paymentIntent } = await stripe.verifyMicrodepositsForPayment(clientSecretRes, {
         amounts: [firstAmount, secondAmount],
      })
      if (error) {
         notification.error({ message: error.message })
         setIsLoading(false)
         setIsVisibleVerifyBankAcModal(false)
      } else {
         if (!isInvoice) {
            await onApprove()
         } else {
            const paymentType = paymentIntent?.payment_method as string
            await track("Payment Received Online", {
               customerId: estimate.work_order.customer.id,
               workorderName: estimate.work_order.title,
               workorderId: estimate.work_order.id,
               vesselId: estimate.work_order?.vessel.id,
               vesselCity: estimate.work_order.current_address?.city,
               vesselState: estimate.work_order.current_address?.state,
               vesselType: estimate.work_order?.vessel.vessel_type,
               jobCreatedBy: estimate.work_order.created_by.id,
               workorderCreatedBy: estimate.work_order.created_by.id,
               workorderTotal: estimate.work_order.total,
               amountRequested: intentStripeQuery.amount,
               invoicePaid: intentStripeQuery.amount,
               invoicePaidTimestamp: moment().toISOString(),
               paymentType: paymentType,
               receiptID: paymentIntent?.id,
            })
         }
         notification.success({ message: "Successfully paid" })
         onSubmit()
         onCancel()
      }
   }

   return stripe && elements ? (
      <>
         <Form onFinish={handleSubmit} layout="vertical">
            <Spin spinning={isLoading}>
               {paymentButSavedCard ? (
                  <>
                     <SaveCardsWrapper>
                        {cardList.map((item) => {
                           const str = item.card.brand + " " + item.card.last4
                           const cardItemTittle = str[0].toUpperCase() + str.slice(1)
                           return (
                              <div onClick={() => setSelectedCardId(item.id)}>
                                 <SaveCardItem
                                    key={item.id}
                                    onSelected={selectedCardId === item.id}
                                    itemCardTittle={cardItemTittle}
                                    isCardComponent
                                 />
                              </div>
                           )
                        })}
                     </SaveCardsWrapper>
                     <div
                        onClick={() => {
                           setPaymentButSavedCard(false)
                        }}
                     >
                        <SaveCardItem onSelected={false} itemCardTittle={"Add new card or use bank account"} />
                     </div>
                  </>
               ) : (
                  <Form.Item>
                     <PaymentElement />
                  </Form.Item>
               )}
               <Form.Item>
                  <PrimaryButton
                     type={"primary"}
                     htmlType={"submit"}
                     disabled={disabledPay}
                     style={{
                        width: "100%",
                        marginTop: "24px",
                        height: "40px",
                        marginBottom: "24px",
                     }}
                  >
                     Pay {isInvoice ? "Invoice" : "Estimate"}
                  </PrimaryButton>
               </Form.Item>
            </Spin>
         </Form>
         <Modal
            title={"Verify your bank account"}
            centered
            visible={isVisibleVerifyBankAcModal}
            okText={"Verify and pay"}
            onCancel={() => {
               setIsVisibleVerifyBankAcModal(false)
               setIsLoading(false)
            }}
            onOk={() => {
               form.submit()
            }}
         >
            <Msg fontSize={"15px"} fontWeight={700}>
               Stripe sends two, non-unique microdeposits to the customer’s bank account, with a statement descriptor
               that reads AMTS: (amount1),(amount2). Your customer uses the deposit amounts to verify their bank
               account.
            </Msg>
            <Flex justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
               <Msg display={"flex"} textAlign={"center"}>
                  Enter code
               </Msg>
               <Form form={form} layout={"vertical"} onFinish={verifyOnFinish}>
                  <Form.Item style={{ marginBottom: 0 }}>
                     <Form.Item
                        name={"firstAmount"}
                        label={"Amount-1"}
                        rules={[{ required: true, message: "" }]}
                        style={{ display: "inline-block", width: "calc(50% )" }}
                     >
                        <InputNumber />
                     </Form.Item>
                     <Form.Item
                        name={"secondAmount"}
                        label={"Amount-2"}
                        rules={[{ required: true, message: "" }]}
                        style={{ display: "inline-block", width: "calc(50% )" }}
                     >
                        <InputNumber />
                     </Form.Item>
                  </Form.Item>
               </Form>
            </Flex>
         </Modal>
      </>
   ) : (
      <Flex height="400px" justifyContent="center" alignItems="center">
         <Spin spinning={true} />
      </Flex>
   )
}

export default CheckoutForm
