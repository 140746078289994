import { useEffect, useState } from "react"
import styled from "styled-components"
import { IInspection } from "../../../api/types"
import Flex from "../../../noui/Flex"
import { Api } from "../../../api/api"

const PhotosBlock = styled.div`
   h3 {
      font-size: 10px;
      font-weight: bold;
      & span.red {
         color: #fb4d4f;
      }
      & span.green {
         color: #42c77b;
      }
   }

   p {
      font-size: 9px;
   }

   @media print {
      .pagebreak {
         page-break-before: always;
      }
      .noBreak {
         page-break-inside: avoid;
      }
   }
`
export const ImageFlex = styled(Flex)`
   align-items: center;
   flex-wrap: wrap;
   margin-top: 5px;

   .img {
      width: 116px;
      height: 98px;
      border-radius: 5px;
      margin: 5px;
   }
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
   }
`
export const Inspection = styled(Flex)`
   align-items: normal;
   border-bottom: 1px solid #ededed;
   padding-bottom: 10px;
   margin-bottom: 10px;

   &:last-child {
      border-bottom: none;
      margin-bottom: 0;
   }
   h4 {
      color: #109cf1;
      font-size: 9px;
      font-weight: bold;
      margin-right: 5px;
   }
   h3 {
      font-size: 9px;
      font-weight: bold;
   }

   .notes {
      font-size: 9px;
   }
`
export const InspectionInfo = styled.div`
   width: 100%;
`

type TProps = { job: any }

const Photos: React.FC<TProps> = ({ job }) => {
   const [inspectionsBefore, setInspectionsBefore] = useState<IInspection[] | null>([])
   const [inspectionsAfter, setInspectionsAfter] = useState<IInspection[] | null>([])

   const loadCards = async () => {
      try {
         const { data: before } = await Api.inspections.getAll({ job: job?.id, category: "B" })
         const { data: after } = await Api.inspections.getAll({ job: job?.id, category: "A" })
         setInspectionsBefore(before as IInspection[])
         setInspectionsAfter(after as IInspection[])
      } catch (e) {}
   }

   useEffect(() => {
      loadCards()
   }, [job])

   return (
      <>
         <PhotosBlock>
            <h3>Before: Photos and notes</h3>
            {inspectionsBefore && inspectionsBefore.length > 0 ? (
               inspectionsBefore.map((i, idx) => (
                  <Inspection className="noBreak" key={idx}>
                     <h4>{`#${idx + 1}`}</h4>

                     <InspectionInfo>
                        {/* <h3>{i.name}</h3> */}

                        {i.notes && (
                           <div className="notes">
                              <p>{i.notes}</p>
                           </div>
                        )}

                        <ImageFlex>
                           {i.images.map((i) => (
                              <div className="img" key={i.image}>
                                 <img src={i?.image.replace(/^http:\/\//i, "https://")} />
                              </div>
                           ))}
                        </ImageFlex>
                     </InspectionInfo>
                  </Inspection>
               ))
            ) : (
               <p>No data</p>
            )}
         </PhotosBlock>
         <PhotosBlock>
            <h3>During/After: Photos and notes</h3>
            {inspectionsAfter && inspectionsAfter.length > 0 ? (
               inspectionsAfter.map((i, idx) => (
                  <Inspection className="noBreak" key={idx}>
                     <h4>{`#${idx + 1}`}</h4>

                     <InspectionInfo>
                        {/* <h3>{i.name}</h3> */}
                        {i.notes && (
                           <div className="notes">
                              <p>{i.notes}</p>
                           </div>
                        )}

                        <ImageFlex>
                           {i.images.map((i) => (
                              <div className="img" key={i.image}>
                                 <img src={i?.image.replace(/^http:\/\//i, "https://")} />
                              </div>
                           ))}
                        </ImageFlex>
                     </InspectionInfo>
                  </Inspection>
               ))
            ) : (
               <p>No data</p>
            )}
         </PhotosBlock>
      </>
   )
}

export default Photos
