import Settings from "./Settings";
import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Msg } from "../../ui/Text";
import styled from "styled-components";
import Flex from "../../noui/Flex";
import {
  Select,
  Form,
  Input,
  Modal,
  Table,
  Checkbox,
  Dropdown,
  Menu,
  Popconfirm,
  Tooltip,
  Button,
  Pagination,
  Spin,
  InputNumber,
} from "antd";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { EditIcon, DeleteIcon } from "../../assets/icons";
import { EmptyButton, PrimaryButton } from "../../ui/Button";
import { instance } from "../../api";
import { Api } from "../../api/api";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import { EUserRole } from "../../api/types";
import moment from "moment";
import routes from "../../routes/routes";
import {
  filterColumns,
  getCurrentHost,
  downloadFile,
  getErrors,
  updatePhoneOnBlur,
} from "../../utils/utils";
import { useLoading, usePagination } from "../../utils/hooks";
import { Columns, DefaultPhoneNumber, userTypes } from "../../constants";
import { ColumnsSelector } from "../../modals/ColumnsSelector/ColumnsSelector";
import { ColumnsButton } from "../../components/ColumnsButton";
import { getColumns } from "../../config/reducers/columns/selectors";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { TableCard } from "../../components/TableCard";
import { useHistory } from "react-router-dom";
import { track } from "../../utils/analytics";
const { Option } = Select;

const roles = ["3", "4"];

const SelectFilter = styled(Select)`
  height: 34px;
  width: 112px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    font-weight: 600;
    font-size: 14px;
    width: 112px;
    & .ant-select-selection-item {
      line-height: 32px;
    }
  }
`;

const CustomersHeader = styled(Flex)`
  padding: 0px 18px;
  ${down("xs")} {
    padding 0;
  }
`;

const ExportBtn = styled(PrimaryButton)`
  width: 92px;
  height: 34px;
  width: 94px;
  background: #f0f1f6;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  color: #0f497b;
  & button {
    width: 94px;
  }
  margin-right: 16px;
  ${down("lg")} {
    margin-left: 16px;
    width: 100%;
    font-size: 16px;
    height: 32px;
  }
`;

const AddCustomerBtn = styled(PrimaryButton)`
  height: 34px;
  width: auto;
  border: 0.7px solid #1c99fc;
  &:hover {
    background-color: #ffffff;
    & span {
      color: #40a9ff;
    }
  }
  &:hover {
    background-color: #ffffff;
    & span {
      color: #40a9ff;
    }
  }
  & button {
    width: 186px;
  }
  &.ant-btn:focus {
    background-color: #0496ff !important;
    color: #ffffff;
    & span {
      color: #ffffff;
    }
  }
  ${down("lg")} {
    width: 100%;
    margin-top: 20px;
  }
`;

const CustomersTable = styled(Table)`
  position: relative;
  & .ant-table {
    overflow-y: auto;
    & td {
      width: 100%;
    }
  }
  & .ant-table table {
    padding: 0px 6px;
    border-spacing: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ant-table-thead,
  .ant-table-tbody {
    display: flex;
    flex-direction: column;
    td,
    th {
      min-width: 100px;
    }
    td:first-child,
    th:first-child,
    td:last-child,
    th:last-child {
      min-width: 80px !important;
      max-width: 80px !important;
    }
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      min-width: 120px !important;
      max-width: 120px !important;
    }
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 150px !important;
      max-width: 150px !important;
    }
    th:nth-child(5),
    td:nth-child(5) {
      min-width: 250px !important;
      max-width: 250px !important;
    }
  }
  &.table-striped-rows tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 12px;
    border-spacing: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    @media screen and (max-width: 1470px) {
      box-shadow: none;
    }
  }
  &.table-striped-rows tr td:nth-child(1) {
    padding-left: 28px;
  }
  &.table-striped-rows thead tr {
    background-color: #ffffff;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    & span {
      font-weight: 700;
      font-size: 13px;
    }
  }
  &.table-striped-rows th {
    background-color: #ffffff;
    border: none;
    font-weight: 700;
    font-size: 13px;
    width: 100%;
  }
  & .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    padding: 0px 12px;
  }
  .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    font-weight: 700;
    text-align: center;
    z-index: 1;
  }
  .ant-table-content .ant-table-tbody .ant-table-placeholder {
    justify-content: center;
  }
  .ant-table-content
    .ant-table-tbody
    .ant-table-placeholder
    .ant-table-cell
    .ant-empty.ant-empty-normal {
    width: 100px;
  }
  .registrationDate {
    min-width: 110px !important;
  }
`;

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 16px;
  }
  & .ant-modal-footer {
    display: none;
  }
  & .ant-form-item {
    margin-bottom: 0;
  }
`;

const ModalCancelBtn = styled(PrimaryButton)`
  width: 92px;
  height: 34px;
  background-color: #ffffff;
  border: 0.7px solid #1d3443;
  color: #1d3443;
  &:hover {
    border: 0.7px solid #1d3443;
  }
`;

const ModalCreateBtn = styled(PrimaryButton)`
  width: 80px;
  height: 34px;
  border: 0.7px solid #1c99fc;
`;

const StyledInput = styled(Input)`
  width: 300px;
  ${down("xs")} {
    width: 100%;
  }
`;

const StyledSelectState = styled(Select)`
  &.ant-select {
    width: 300px;
  }
  & .ant-select-selection-placeholder {
    padding: 0;
    border-right: 1px solid #d9d9d9;
    margin-right: 18px;
  }
  ${down("xs")} {
    width: 100%;
    &.ant-select {
      width: 100%;
    }
  }
`;

const ContainerMainButtons = styled(Flex)`
  border-top: 1px solid #e6e6e6;
`;

const MsgWrap = styled(Msg)`
  line-height: 30px;
`;

const OptionButton = styled(EmptyButton)`
  margin-right: 25px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
`;

export const StyledMenu = styled(Menu)`
  padding: 15px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;

const StyledInputNumber = styled(InputNumber)`
  width: 300px;
  ${down("xs")} {
    width: 100%;
  }
`;

const FlexRow = styled(Flex)`
  align-items: start;
  > div {
    width: calc(50% - 8px);
  }
  > div:first-child {
    margin-right: 16px;
  }
`;

const Users = () => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [visible, setVisible] = useState(false);
  const [limitModalVisible, setLimitModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [listOfUsers, setListOfUsers] = useState([]);
  const [dropdown, setDropdown] = useState(null);
  const [edit, setEdit] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectColumns, setSelectColumns] = useState(false);
  const [saving, onSave] = useLoading();
  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    role: "4",
    redirect_url: `${getCurrentHost()}${routes.auth.SetNewPassword}`,
  });
  const isXs = useBreakpoint(down("xs"));
  const isLg = useBreakpoint(down("lg"));
  const profile = useSelector(getCurrentUser);
  const filtered = useSelector(getColumns(Columns.Users));
  const { pagination, onChange } = usePagination();
  const data = listOfUsers.map((el) => {
    return {
      id: el.id,
      email: el.email,
      first_name: el.first_name,
      last_name: el.last_name,
      phone: el.phone,
      role: el.role,
      role_id: el.role_id,
      rate: el.rate,
      active: <Checkbox checked={el.is_active} />,
      date_joined: moment(new Date(el.date_joined)).format("MM/DD/YYYY"),
    };
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const loadData = async () => {
    try {
      setIsLoading(true);
      const users = await Api.user.getAll({});
      setListOfUsers(users.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleChangeRate = (e) => {
    setUserData({ ...userData, rate: e });
  };

  const changeNumberField = (value, field) => {
    setUserData({
      ...userData,
      [field]: value,
    });
  };
  const handleSubmit = async () => {
    try {
      const res = await instance.post("/users/invite_user", userData);
      const user = await res.data;
      loadData();
      form.resetFields();
      track("Pro User Added", {
        techCreated_by: profile?.id,
        companyId: profile?.company?.id,
        proId: user?.id,
        newUserTotal: data.length + 1,
        proType: userTypes[user.role],
      });
      return setVisible(false);
    } catch (e) {
      const errors = getErrors(e);
      form.setFields(errors);
    }
  };

  const deleteItems = async (id) => {
    await instance.delete(`/users/${id}`);
    await loadData();
    setDropdown(null);
  };

  const handleExport = async () => {
    const { data } = await onSave(Api.user.download());
    downloadFile(data, `UsersList ${moment().format("LLL")}.xlsx`);
  };

  const handleAddNewUser = () => {
    const usersCount = profile?.company?.plan?.number_of_users;
    if (usersCount && usersCount <= listOfUsers.length) {
      setLimitModalVisible(true);
      return;
    }
    setSelected(null);
    setVisible(true);
  };

  const handleUpdateSubscription = () => {
    history.push(routes.settings.Subscription);
  };

  const EditUser = () => {
    const [form] = Form.useForm();

    useEffect(() => {
      if (selected) {
        form.setFieldsValue({
          first_name: selected.first_name,
          last_name: selected.last_name,
          email: selected.email,
          role: selected.role,
          rate: selected.rate,
          phone: selected.phone,
        });
      }
    }, [selected]);

    const editUser = async () => {
      try {
        const values = form.getFieldValue();
        await instance.put(`/users/${selected.id}`, {
          ...values,
          role_id: +values.role,
        });
        loadData();
        return setEdit(false);
      } catch (e) {
        const errors = getErrors(e);
        form.setFields(errors);
        // setEdit(false);
      }
    };

    return (
      <StyledModal
        centered
        visible={edit}
        okText="Save"
        width={648}
        onCancel={() => {
          setEdit(false);
        }}
      >
        <Msg fontWeight={900} fontSize={15} color="#151F2B">
          EDIT USER
        </Msg>
        <Form id="create-course-form" form={form} onFinish={editUser}>
          <Flex justifyContent="space-between" flexDirection="column">
            <FlexRow>
              <Form.Item
                name="first_name"
                label="First name"
                rules={[
                  {
                    required: true,
                    message: "Please input First name",
                  },
                ]}
              >
                <StyledInput
                  placeholder="Type here"
                  name="first_name"
                ></StyledInput>
              </Form.Item>
              <Form.Item
                name="last_name"
                label="Last name"
                rules={[
                  {
                    required: true,
                    message: "Please input Last name",
                  },
                ]}
              >
                <StyledInput
                  placeholder="Type here"
                  name="last_name"
                ></StyledInput>
              </Form.Item>
            </FlexRow>
          </Flex>
          <Flex justifyContent="space-between" flexDirection="column">
            <FlexRow>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                ]}
              >
                <StyledInput
                  disabled
                  placeholder="Type here"
                  name="email"
                ></StyledInput>
              </Form.Item>
              <Form.Item name="phone" label="Phone">
                <StyledInput
                  onBlur={updatePhoneOnBlur(form, "phone")}
                  placeholder={DefaultPhoneNumber}
                  name="phone"
                />
              </Form.Item>
            </FlexRow>
          </Flex>
          <Flex justifyContent="space-between" flexDirection="column" mb="16px">
            <FlexRow>
              <Form.Item
                name="role"
                label="User Type"
                rules={[
                  {
                    required: true,
                    message: "Please select User Type",
                  },
                ]}
                style={{ width: isXs ? "100%" : "auto" }}
              >
                <StyledSelectState
                  name="role"
                  placeholder="Select present"
                  disabled={
                    profile.role_id === EUserRole.Owner &&
                    selected.role_id === EUserRole.Owner
                  }
                >
                  {roles.map((el, idx) => (
                    <Option
                      disabled={profile?.role_id === 3 && el === "3"}
                      value={el}
                      key={idx + 1}
                    >
                      {el === "3" ? "Admin" : "Technician"}
                    </Option>
                  ))}
                </StyledSelectState>
              </Form.Item>
              <Form.Item name="rate" label="Rate">
                <StyledInputNumber min={0} placeholder="Type here" />
              </Form.Item>
            </FlexRow>
          </Flex>
          <ContainerMainButtons justifyContent="flex-end" pt="16px">
            <ModalCancelBtn
              onClick={() => {
                setEdit(false);
              }}
            >
              <Msg fontWeight={600} fontSize={14}>
                Cancel
              </Msg>
            </ModalCancelBtn>
            <ModalCreateBtn ml={16} htmlType="submit">
              Save
            </ModalCreateBtn>
          </ContainerMainButtons>
        </Form>
      </StyledModal>
    );
  };

  const columns = [
    {
      title: "Active",
      key: "active",
      dataIndex: "active",
      render: (_, record) => record.active,
    },
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, record) => record.first_name,
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
      render: (_, record) => record.last_name,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (_, record) => record.phone,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => record.email,
    },
    {
      title: "Type",
      dataIndex: "role",
      key: "role",
      render: (_, record) => record.role,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (_, record) => record.rate,
    },
    {
      title: "Registration Date",
      dataIndex: "date_joined",
      key: "date_joined",
      className: "registrationDate",
      render: (_, record) => record.date_joined,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      render: (_, record) => (
        <Flex justifyContent="center" style={{ width: "100%" }}>
          <Dropdown
            trigger={["click"]}
            onVisibleChange={() => {
              if (dropdown) {
                setDropdown(null);
              }
            }}
            overlay={() => (
              <StyledMenu>
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  style={{ gap: "16px" }}
                >
                  <OptionButton
                    onClick={() => {
                      setEdit(true);
                      setDropdown(null);
                    }}
                  >
                    <Flex alignItems="center">
                      <EditIcon />
                      <Msg marginLeft="9px">Edit</Msg>
                    </Flex>
                  </OptionButton>
                  {record.role_id !== EUserRole.Owner && (
                    <Popconfirm
                      title="Are you sure to delete this user?"
                      onConfirm={() => deleteItems(record.id)}
                      onCancel={() => setDropdown(null)}
                    >
                      <OptionButton>
                        <Flex alignItems="center">
                          <DeleteIcon width="15px" verticalAlign="middle" />
                          <Msg marginLeft="9px">Delete</Msg>
                        </Flex>
                      </OptionButton>
                    </Popconfirm>
                  )}
                </Flex>
              </StyledMenu>
            )}
            visible={dropdown === record.id}
            placement="bottomRight"
            arrow
          >
            <Tooltip
              visible={alert === record.id}
              placement="left"
              title="You don't have rights to modify this user"
              onVisibleChange={() => setAlert(false)}
            >
              <EmptyButton
                onClick={() => {
                  // if (record.role_id > profile?.role_id) {
                  //    setDropdown(record.id)
                  //    setSelected(record)
                  // } else {
                  //    //setAlert(record.id)
                  //    //setSelected(null)
                  // }
                  setDropdown(record.id);
                  setSelected(record);
                }}
              >
                <EllipsisOutlined
                  style={{ fontSize: "18px", marginTop: "5px" }}
                />
              </EmptyButton>
            </Tooltip>
          </Dropdown>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Users - DockWorks Pro</title>
      </Helmet>

      <Settings>
        <CustomersHeader justifyContent="space-between">
          <Flex width="100%" justifyContent="space-between">
            <Flex style={{ gap: "16px" }}>
              <ColumnsButton onClick={() => setSelectColumns(true)} />
            </Flex>
            {!isLg && (
              <Flex
                justifyContent="space-between"
                width={isLg ? "100%" : "auto"}
              >
                <ExportBtn loading={saving} onClick={handleExport}>
                  Export
                </ExportBtn>
                <AddCustomerBtn
                  disabled={profile?.role_id === 4}
                  width="118px"
                  onMouseEnter={() => setMouseEnter(true)}
                  onMouseLeave={() => setMouseEnter(false)}
                  loading={isLoading}
                  onClick={handleAddNewUser}
                >
                  <Msg fontWeight={600} fontSize={13} color="#ffffff">
                    Add New User
                  </Msg>
                </AddCustomerBtn>
              </Flex>
            )}
          </Flex>
          {selected && <EditUser />}
          <Modal
            centered
            visible={limitModalVisible}
            onCancel={() => setLimitModalVisible(false)}
            onOk={handleUpdateSubscription}
            okText="Update"
            width={384}
            title="Limit of users"
          >
            <Msg fontSize={18} fontWeight={15} color="#202020">
              You achieved limit of users. If you want to add more - update your
              plan.
            </Msg>
          </Modal>
          <StyledModal
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => {
              form.resetFields();
              setVisible(false);
            }}
            okText="Create"
            width={648}
          >
            <Msg fontWeight={900} fontSize={15} color="#151F2B">
              ADD NEW USER
            </Msg>

            <Form onFinish={handleSubmit} id="create-course-form" form={form}>
              <Flex
                justifyContent="space-between"
                mt="16px"
                flexDirection="column"
              >
                <FlexRow>
                  <Flex flexDirection="column">
                    <Msg fontWeight={700} fontSize={13} color="#484848">
                      First name
                    </Msg>
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input First name",
                        },
                      ]}
                    >
                      <StyledInput
                        placeholder="Type here"
                        name="first_name"
                        onChange={handleChange}
                      ></StyledInput>
                    </Form.Item>
                  </Flex>
                  <Flex flexDirection="column">
                    <Msg fontWeight={700} fontSize={13} color="#484848">
                      Last name
                    </Msg>
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Last name",
                        },
                      ]}
                    >
                      <StyledInput
                        placeholder="Type here"
                        name="last_name"
                        onChange={handleChange}
                      ></StyledInput>
                    </Form.Item>
                  </Flex>
                </FlexRow>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="16px"
                mb="16px"
                flexDirection="column"
              >
                <FlexRow>
                  <Flex flexDirection="column">
                    <Msg fontWeight={700} fontSize={13} color="#484848">
                      Email
                    </Msg>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input Email",
                        },
                      ]}
                    >
                      <StyledInput
                        placeholder="Type here"
                        name="email"
                        onChange={handleChange}
                      ></StyledInput>
                    </Form.Item>
                  </Flex>
                  <Flex flexDirection="column">
                    <Msg fontWeight={700} fontSize={13} color="#484848">
                      Phone
                    </Msg>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input Phone number",
                        },
                      ]}
                    >
                      <StyledInput
                        placeholder="(555) 505-5505"
                        name="phone"
                        onChange={handleChange}
                      ></StyledInput>
                    </Form.Item>
                  </Flex>
                </FlexRow>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="16px"
                mb="16px"
                flexDirection="column"
              >
                <Flex
                  justifyContent="space-between"
                  style={{ gap: isXs ? "10px" : "0px" }}
                >
                  <Flex flexDirection="column">
                    <Msg fontWeight={700} fontSize={13} color="#484848">
                      User Type
                    </Msg>
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: "Please select User Type",
                        },
                      ]}
                      style={{ width: isXs ? "100%" : "auto" }}
                    >
                      <StyledSelectState
                        name="role"
                        placeholder="Select present"
                        disabled={
                          profile.role_id === EUserRole.Owner &&
                          selected &&
                          selected.role_id === EUserRole.Owner
                        }
                        onChange={(value) => changeNumberField(value, "role")}
                      >
                        {roles.map((el, idx) => (
                          <Option
                            disabled={profile?.role_id === 3 && el === "3"}
                            value={el}
                            key={idx + 1}
                          >
                            {el === "3" ? "Admin" : "Technician"}
                          </Option>
                        ))}
                      </StyledSelectState>
                    </Form.Item>
                  </Flex>
                  <Flex flexDirection="column">
                    <Msg fontWeight={700} fontSize={13} color="#484848">
                      Rate
                    </Msg>
                    <Form.Item name="rate">
                      <StyledInputNumber
                        name="rate"
                        min={0}
                        placeholder="Type here"
                        onChange={handleChangeRate}
                      />
                    </Form.Item>
                  </Flex>
                </Flex>
              </Flex>
              <ContainerMainButtons justifyContent="flex-end" pt="16px">
                <ModalCancelBtn
                  onClick={() => {
                    form.resetFields();
                    setVisible(false);
                  }}
                >
                  <Msg fontWeight={600} fontSize={14}>
                    Cancel
                  </Msg>
                </ModalCancelBtn>
                <ModalCreateBtn ml={16} htmlType="submit">
                  Save
                </ModalCreateBtn>
              </ContainerMainButtons>
            </Form>
          </StyledModal>
        </CustomersHeader>
        <ColumnsSelector
          name={Columns.Users}
          columns={columns}
          visible={selectColumns}
          onClose={() => setSelectColumns(false)}
        />
        {!isLg ? (
          <CustomersTable
            columns={filterColumns(columns, filtered)}
            loading={!data.length}
            dataSource={data}
            className="table-striped-rows"
            pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
          />
        ) : (
          <Spin spinning={isLoading}>
            <Flex mt="20px" flexWrap="wrap">
              {data.map((d) => (
                <TableCard key={d.id}>
                  {filterColumns(columns, filtered)
                    // .filter(c => c.title !== 'Actions' && c.title !== 'Active')
                    .map((c, i) => (
                      <Flex key={i} justifyContent="space-between">
                        <Msg fontWeight={700}>{c.title}</Msg>
                        <Msg style={{ textAlign: "end" }}>
                          {c.render && c.render(d, d, i)}
                        </Msg>
                      </Flex>
                    ))}
                </TableCard>
              ))}

              {!!pagination?.total && pagination.total > 10 && (
                <Pagination
                  current={pagination.current}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  onChange={(p) => {
                    onChange({ ...pagination, current: p }, {}, {});
                  }}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "20px",
                  }}
                  size="small"
                />
              )}
              <AddCustomerBtn
                disabled={profile?.role_id === 4}
                width="100%"
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
                loading={isLoading}
                onClick={handleAddNewUser}
              >
                <Msg fontWeight={600} fontSize={16} color="#ffffff">
                  Add New User
                </Msg>
              </AddCustomerBtn>
            </Flex>
          </Spin>
        )}
      </Settings>
    </>
  );
};

export default Users;
