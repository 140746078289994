import React, { useState, useRef, useMemo, useEffect } from "react"
import { Form, FormInstance, Input, Tag } from "antd"
import Flex from "../../noui/Flex"
import styled from "styled-components"
import { Msg } from "../../ui/Text"
import { EmptyButton } from "../../ui/Button"
import { ICreateMessageTemplate } from "../../api/types"
import { useSelector } from "react-redux"
import { getConstant } from "../../config/reducers/user/selectors"
import { mapConstants } from "../../utils/utils"
import { TMappedConstant } from "../../utils/types"
import { TextAreaRef } from "antd/es/input/TextArea"
import { TextEditor } from "../../components/TextEditor/TextEditor"

const { TextArea } = Input

const Label = styled.h4`
   font-weight: bold;
   font-size: 13px;
   line-height: 18px;
`
const Row = styled.div`
   margin-top: 18px;
   margin-bottom: 8px;
   > .ant-select {
      width: 100%;
   }
`
const StyledTextArea = styled(TextArea)`
   height: 144px !important; ;
`
const TagsContainer = styled(Flex)`
   height: auto !important;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   flex-wrap: wrap;
   padding: 9px;
`
const TagButton = styled(EmptyButton)`
   height: 13px;
   line-height: 12px;
   margin-bottom: 8px;
`

type TProps = {
   form: FormInstance
   templateId?: number
}
export const CreateTemplateBlock: React.FC<TProps> = ({ form, templateId }) => {
   const subjectField = useRef<Input>(null)
   const smsContentField = useRef<TextAreaRef>(null)
   const [focus, setFocus] = useState<keyof ICreateMessageTemplate>("content")
   const [subjectLastPosition, setSubjectLastPosition] = useState<number | null>(null)
   const [smsContentLastPosition, setSMSContentLastPosition] = useState<number | null>(null)
   const [initialValue, setInitialValue] = useState<string>(form.getFieldValue("content") || "<p></p>")
   const [message, setMessage] = useState<string>(initialValue)
   const [tag, setTag] = useState<string>("")
   const tagConstant = useSelector(getConstant("template_keys"))
   const tags: TMappedConstant[] = useMemo(() => {
      return mapConstants(tagConstant)
   }, [tagConstant])

   const handleInsertTag = (tag: TMappedConstant) => {
      let idx: number | null
      if (focus !== "content") {
         if (focus === "subject") {
            idx = subjectField.current?.input?.selectionStart || (subjectLastPosition ?? null)
            let value: string = form.getFieldValue(focus) ?? ""
            idx = idx !== null ? idx : value.length - 1
            value = value.slice(0, idx) + tag.value + value.slice(idx, value.length)
            form.setFieldsValue({ [focus]: value })
            setSubjectLastPosition(null)
            return;
         } 
         idx = smsContentField.current?.resizableTextArea?.textArea?.selectionStart || (smsContentLastPosition ?? null)
         let value: string = form.getFieldValue(focus) ?? ""
         idx = idx !== null ? idx : value.length - 1
         value = value.slice(0, idx) + tag.value + value.slice(idx, value.length)
         form.setFieldsValue({ [focus]: value })
         setSMSContentLastPosition(null)         
      } else {
         setTag(tag.value)
      }
   }

   const updateMessage = (value: string) => {
      setMessage(value)
   }

   useEffect(() => {
      setInitialValue(form.getFieldValue("content") || "<p></p>")
   }, [templateId])

   //console.log(form.getFieldValue("content"))

   useEffect(() => {
      form.setFieldsValue({ content: message })
   }, [message])

   return (
      <div>
         <Flex flexDirection="column">
            <Label>Template Name</Label>
            <Form.Item
               name="title"
               className="form-item"
               rules={[
                  {
                     required: true,
                     message: "Please input Template Name",
                  },
               ]}
            >
               <Input placeholder="Type here" className="input-element" />
            </Form.Item>
         </Flex>
         <Row>
            <Flex flexDirection="column">
               <Label>Subject</Label>
               <Form.Item
                  name="subject"
                  className="form-item"
                  rules={[
                     {
                        required: true,
                        message: "Please input Subject",
                     },
                  ]}
               >
                  <Input
                     onFocus={() => setFocus("subject")}
                     onBlur={(e) => setSubjectLastPosition(e.target.selectionStart)}
                     placeholder="Type here"
                     className="input-element"
                     ref={subjectField}
                  />
               </Form.Item>
            </Flex>
         </Row>
         <Row>
            <Flex flexDirection="column">
               <Label>Email Message</Label>
               <Form.Item
                  name="content"
                  className="form-item"
                  rules={[
                     {
                        required: true,
                        message: "Please input Message",
                     },
                  ]}
               >
                  {/* <StyledTextArea
                     onFocus={() => setFocus("content")}
                     onBlur={(e) => setContentLastPosition(e.target.selectionStart)}
                     className="input-element"
                     autoSize
                     ref={contentField}
                     placeholder="Type here"
                  /> */}{" "}
                  <TextEditor
                     initialValue={initialValue}
                     onChange={updateMessage}
                     onFocusText={() => setFocus("content")}
                     tag={tag}
                     setTag={setTag}
                  />
               </Form.Item>
            </Flex>
         </Row>
         <Row>
            <Flex flexDirection="column">
               <Label>SMS Message</Label>
               <Form.Item
                  name="sms_content"
                  className="form-item"
                  rules={[
                     {
                        required: true,
                        message: "Please input Message",
                     },
                  ]}
               >
                  <TextArea
                     onFocus={() => setFocus("sms_content")}
                     onBlur={(e) => setSMSContentLastPosition(e.target.selectionStart)}
                     className="input-element"
                     rows={3}
                     ref={smsContentField}
                     showCount={{formatter: ({count, maxLength}) => `${count}/${maxLength} characters`}}
                     maxLength={120}
                     placeholder="Type here"
                  />
               </Form.Item>
            </Flex>
         </Row>
         <Row>
            <Flex flexDirection="column">
               <Label>Attribute Tags</Label>
               <Msg fontSize="13px" fontWeight="400" color="#828282">
                  Click on the tag to insert into the Subject line or Message body or type it
               </Msg>
            </Flex>
         </Row>
         <TagsContainer>
            {tags.map((tag) => (
               <TagButton key={tag.key} onClick={() => handleInsertTag(tag)}>
                  <Tag color="#CEEBFF" className="tag">
                     {tag.label}
                  </Tag>
               </TagButton>
            ))}
         </TagsContainer>
      </div>
   )
}
