import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import routes from "../../../../routes/routes"
import Flex from "../../../../noui/Flex"
import styled from "styled-components"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { Button } from "antd"
import { Msg } from "../../../../ui/Text"
import { IMyJob } from "../../../../api/types"
import moment from "moment"
import Appointment from "../../../../assets/images/headerIcons/modalIcons/Appointment.svg"
import Vessel from "../../../../assets/images/VesselDark.svg"
import Weather from "./Weather"
import { ChangeJobTechnician } from "../../../../modals/ChangeJobTechnician"
import { TArgCallback } from "../../../../types"
import { formatAddress } from "../../../../utils/utils"

const WelcomeTextBlock = styled(Flex)`
   z-index: 1;
   width: 100%;
   height: 100%;
   button {
      &:hover {
         background: none;
      }
      &:disabled {
         opacity: 0.6;
      }
   }
`
const JobDate = styled.div`
   font-size: 15px;
   margin-right: 8px;
   padding-right: 8px;
   position: relative;
   border-right: 1px solid #ccc;
   img {
      margin-right: 14px;
   }
`
const JobTime = styled.div`
   font-size: 15px;
   color: #42c57a;
   font-weight: bold;
`
const JobTimeDivider = styled.div`
   margin: 0 5px;
   width: 7px;
   height: 1px;
   background: #ccc;
`
const GoogleMapLink = styled.a`
   font-size: 15px;
   font-weight: bold;
   color: #109cf1;
   margin: 0 10px 0 0;
   text-decoration: underline;
   &.disabled {
      pointer-events: none;
      opacity: 0.6;
   }
`
const WorkOrder = styled.span`
   font-size: 15px;
   font-weight: bold;
   color: #109cf1;
   margin: 0 10px 0 0;
   text-decoration: underline;
   cursor: pointer;
   &:hover {
      text-decoration: none;
   }
`
type TCProps = {
   nextJob: IMyJob | undefined
   jobs: IMyJob[] | undefined
   setNextJob: TArgCallback<any>
}

const WelcomeInfo: React.FC<TCProps> = ({ jobs, nextJob, setNextJob }) => {
   const [showModal, setShowModal] = useState<boolean>(false)
   const history = useHistory()
   const isXS = useBreakpoint(down("xs"))

   const changeJob = (id: number) => {
      const findItem = jobs?.find((item) => item.job.id === id)
      if (findItem) {
         setNextJob(findItem)
      }
   }

   const renderDate = () => {
      if (!nextJob) {
         return (
            <Flex>
               <JobDate>
                  <img src={Appointment} alt="Appointment" /> --/--/----
               </JobDate>
               <JobTime>--:--AM - --:--PM</JobTime>
            </Flex>
         )
      } else if (nextJob && moment(nextJob?.start).format("L") === moment(nextJob?.end).format("L")) {
         return (
            <Flex>
               <JobDate>
                  <img src={Appointment} alt="Appointment" /> {moment(nextJob?.start).format("MM/DD/YY")}
               </JobDate>
               <JobTime>{`${moment(nextJob?.start).format("LT")} - ${moment(nextJob?.end).format("LT")} `}</JobTime>
            </Flex>
         )
      } else {
         return (
            <Flex alignItems="center">
               <JobDate>
                  <img src={Appointment} alt="Appointment" />
                  {moment(nextJob?.start).format("MM/DD/YY")}
               </JobDate>
               <JobTime>{moment(nextJob?.start).format("LT")} </JobTime>
               <JobTimeDivider />
               <JobDate>{moment(nextJob?.end).format("MM/DD/YY")}</JobDate>
               <JobTime>{moment(nextJob?.end).format("LT")}</JobTime>
            </Flex>
         )
      }
   }

   const renderTitle = () => {
      let title = "NEXT JOB"
      const dateNow = new Date().toISOString()
      if (nextJob && nextJob.start < dateNow && nextJob.end < dateNow) {
         title = "PAST JOB"
      }
      return title
   }

   // const renderWeatherDate = () => {
   //    const dateNow = new Date().toISOString()

   //    if (nextJob && nextJob?.start < dateNow && nextJob?.end > dateNow) {
   //       return moment(dateNow).format("L")
   //    }
   //    return moment(nextJob?.start).format("L")
   // }

   return (
      <WelcomeTextBlock flexDirection="column" justifyContent="space-between">
         <Msg fontWeight={900} fontSize={16} color="#151F2B" mr="11px" mb="10px">
            {renderTitle()}
         </Msg>
         {renderDate()}
         <Flex alignItems={isXS ? "flex-start" : "center"} flexDirection={isXS ? "column" : "row"} margin="8px 0">
            <Msg fontSize="15px" fontWeight="600" color="#109CF1" margin="0 10px 0 0" style={{ minWidth: "115px" }}>
               Job{" "}
               {nextJob ? (
                  <WorkOrder
                     onClick={() =>
                        history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(nextJob.job.work_order)))
                     }
                  >
                     {nextJob?.job?.title}
                  </WorkOrder>
               ) : (
                  "#----"
               )}
            </Msg>
            <Flex alignItems="center">
               <Msg fontSize="15px" fontWeight="bold" margin="0 5px 0 0" lineHeight={1.2}>
                  {nextJob && nextJob.job.services.length > 0 && nextJob?.job?.services[0].name}
               </Msg>
               <Button type="text" onClick={() => setShowModal(true)} style={{ padding: 0, height: "auto" }}>
                  <Msg fontSize="15px" fontWeight="bold">
                     (
                  </Msg>
                  <Msg fontSize="15px" fontWeight="bold" color="#109CF1" style={{ textDecoration: "underline" }}>
                     Change
                  </Msg>
                  <Msg fontSize="15px" fontWeight="bold">
                     )
                  </Msg>
               </Button>
            </Flex>

            <ChangeJobTechnician
               visible={showModal}
               handleClose={() => setShowModal(false)}
               jobs={jobs}
               activeJob={nextJob}
               changeJob={changeJob}
            />
         </Flex>

         <Msg fontWeight="bold" fontSize="15px" margin="0 0 5px 0">
            <Flex style={{ display: "inline-flex", gap: "10px" }}>
               <img src={Vessel} alt="Vessel" style={{ marginRight: "5px" }} />
               {nextJob && nextJob?.job?.vessel?.name}{" "}
               {isXS && (
                  <Msg fontSize="15px" fontWeight="normal">
                     {formatAddress(nextJob?.address)}
                  </Msg>
               )}
            </Flex>
         </Msg>

         <Flex alignItems="normal">
            <Flex marginRight="35px" flexDirection="column" justifyContent="space-between">
               {!isXS && (
                  <Msg fontSize="15px">
                     {formatAddress(nextJob?.address)}
                  </Msg>
               )}

               <Flex>
                  {nextJob?.address && (
                     <GoogleMapLink
                        href={`https://www.google.com/maps?saddr=My+Location&daddr=${nextJob.address.lat},${nextJob.address.lng}`}
                        target="_blank"
                        className={!nextJob ? "disabled" : ""}
                     >
                        Directions
                     </GoogleMapLink>
                  )}

                  {/* <Msg fontSize="15px" fontWeight="bold" color="#109CF1">
                           Boat Status
                        </Msg> */}
               </Flex>
            </Flex>

            {nextJob && (
               <Weather
                  address={nextJob?.address}
                  nextJob={moment(nextJob?.start).format("L")}
                  fullStart={nextJob.start}
               />
            )}
         </Flex>
      </WelcomeTextBlock>
   )
}

export default WelcomeInfo
