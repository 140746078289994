import React from "react"
import { Steps } from "antd"
import moment from "moment"
import styled from "styled-components"
import { IActivity } from "../../../api/types"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { textInBrackets } from "../../../utils/regex"
import { DefaultDateTimeFormat, PALETTE } from "../../../constants"

const StyledSteps = styled(Steps)`
   max-height: 500px;
   overflow-y: auto;
   &::-webkit-scrollbar {
      width: 4px;
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }
   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }
   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
   .ant-steps-item-active {
      .ant-steps-item-icon {
         left: 2px;
         .ant-steps-icon-dot {
            background: #0496ff !important;
         }
      }
   }
   .ant-steps-item-tail {
      top: 6px !important;
      &:after {
         background: #ededed !important;
      }
   }
   .ant-steps-item-icon {
      margin-top: 12px !important;
      .ant-steps-icon-dot {
         background: #42c77b !important;
      }
   }
   .ant-steps-item-title {
      font-size: 13px;
      line-height: 20px !important;
      ${down("xs")} {
         font-size: 15px;
         font-weight: bold;
      }
   }
   .ant-steps-item-description {
      font-size: 11px;
      ${down("xs")} {
         font-size: 15px;
         color: #828282 !important;
      }
   }
   ${down("lg")} {
      grid-gap: 0px;
   }
   ${down("xs")} {
      margin-top: 10px;
   }
`

type TProps = {
   activities: IActivity[]
}

const formatActivityMessage = (message: string) => {
   const date = message.match(textInBrackets)
   if (!date) {
      return message
   }
   const formatedDate = moment(date[0].slice(1, date[0].length - 1)).format("MM/DD/YY [at] hh:mm A")
   return message.replace(date[0], formatedDate)
}

export const Activities: React.FC<TProps> = ({ activities }) => {
   const isXs = useBreakpoint(down("xs"))
   // console.log("activities", activities)
   if (!activities.length) {
      return <span>There is no activities yet</span>
   }
   return (
       <StyledSteps progressDot direction="vertical">
          {activities.map((activity) => (
              <Steps.Step
                  key={activity.created}
                  title={formatActivityMessage(activity.message)}
                  description={
                     isXs
                         ? moment(activity.created).calendar().split("at")[0]
                         : moment(activity.created).format(DefaultDateTimeFormat)
                  }
              />
          ))}
       </StyledSteps>
   )
}

