import React, { useState, useEffect, useCallback } from "react"
import { notification } from "antd"
import moment from "moment"

import styled from "styled-components"
import Flex from "../noui/Flex"
import { Msg } from "../ui/Text"

import { Api } from "../api/api"
import { IPaymentRecord } from "../api/types"
import { Content } from "../components/Content"

const PaymentItem = styled(Flex)`
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   margin-bottom: 16px;
   padding: 8px;
   font-size: 13px;
`

const PaymentContainer = styled(Flex)`
   max-height: 75vh;
   overflow-y: auto;
   &::-webkit-scrollbar {
      width: 10px;
   }

   ::-webkit-scrollbar {
      width: 6px;
      background-color: #fff;
   }
   ::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 3px;
   }
`

export const NewPayment: React.FC = () => {
   const [payments, setPayments] = useState<IPaymentRecord[]>([])
   const [loading, setLoading] = useState<boolean>(false)

   const loadCards = useCallback(async () => {
      setLoading(true)
      try {
         const { data } = await Api.paymentRecords.getAll({ ordering: "-date", is_error: false })
         setPayments(data as IPaymentRecord[])
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      } finally {
         setLoading(false)
      }
   }, [])

   useEffect(() => {
      loadCards().finally()
   }, [loadCards])

   return (
      <PaymentContainer flexDirection="column" p="16px">
         <Msg fontSize={15} fontWeight={900} mb="26px">
            RECORD PAYMENT
         </Msg>
         <Content loading={loading} noItems={!payments.length} noItemsLabel="No payments yet">
            {payments
               .filter((d) => moment().diff(moment(d.date), "days") <= 7)
               .map((m) => (
                  <PaymentItem justifyContent="space-between" alignItems="center">
                     <Flex flexDirection="column">
                        <Msg fontSize={13}>
                           <Msg fontWeight={700} fontSize={13}>{`#${m.work_order.order_number}`}</Msg> -{" "}
                           {m.work_order.title}
                        </Msg>
                        <Msg fontSize={13}>
                           {`${m.work_order.customer.first_name + " " + m.work_order.customer.last_name} | ${
                              m.work_order?.vessel.name
                           }`}
                        </Msg>
                     </Flex>
                     <Flex flexDirection="column" alignItems="flex-end">
                        <Msg fontWeight={700} color="#FA4D4F" fontSize={13}>{`Due ${moment(m.date)
                           .add(7, "days")
                           .format("L")}`}</Msg>
                        <Msg fontWeight={900} fontSize={13}>{`$${m.amount.toFixed(2)}`}</Msg>
                     </Flex>
                  </PaymentItem>
               ))}
         </Content>
      </PaymentContainer>
   )
}
