import styled from "styled-components"
import Flex from "../../noui/Flex"

export const Table = styled.table`
   width: 100%;
   td,
   th {
      border: 1px solid #ededed;
      padding: 5px;
      min-width: 88px;
      height: 45px;
      &:first-child {
         border-right: none;
         border-left: none;
         min-width: 100px;
         max-width: 100px;
         width: 100px;
      }
      &:nth-child(2) {
         border-left: none;
         min-width: 300px;
         max-width: 350px;
      }
   }
   th {
      font-size: 15px;
      border-top: none;
   }
   td {
      font-size: 15px;
      color: #202020;
      &:first-child {
         font-size: 13px;
      }
      &.job {
         font-size: 15px;
         font-weight: bold;
      }
      &.job-link {
         cursor: pointer;
         text-decoration: underline;
         font-weight: bold;
         &:hover {
            color: #40a9ff;
         }
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
      }
   }
   & .fixed {
      position: sticky;
      width: 88px;
      min-width: 88px;
      max-width: 88px;
      right: 0px;
      background: #ededed;
      text-align: center;
      font-weight: bold;
   }
   th {
      span {
         display: block;
         font-size: 13px;
         font-weight: normal;
         &.day {
            color: #494949;
         }
      }
      &.job {
         text-align: left;
      }
   }
   .weekend {
      background: #fbfbff;
   }
   & tr:last-child {
      background: #fbfbff;
      td {
         border: none;
         height: 40px;
         font-weight: bold;
      }
      & .total {
         background: #fbfbff;
      }
   }
`
export const MainLayoutInner = styled.div`
   width: 100%;
   overflow-x: auto;
   position: relative;
   padding: 0;

   &::-webkit-scrollbar {
      width: 10px;
   }

   &::-webkit-scrollbar-track {
      background: #ebf1f6;
   }

   &::-webkit-scrollbar-thumb {
      background-color: #0496ff;
      border-radius: 8px;
      border: 4px solid #ebf1f6;
   }
   & {
      scrollbar-width: auto;
      scrollbar-color: #0496ff #ebf1f6;
      scrollbar-width: thin;
   }
`

export const MainLayout = styled(Flex)`
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   min-height: 100%;
`
export const FlexRow = styled(Flex)`
   & .ant-select {
      width: 208px;
      margin-right: 18px;
      @media (max-width: 800px) {
         width: 100%;
      }
   }
   @media (max-width: 800px) {
      flex-direction: column;
   }
`
export const CustomDivider = styled.div`
   width: 8px;
   height: 1px;
   background: #8b8b8b;
   margin: 37px 5px 0 5px;
`
export const ReportForm = styled.div`
   & .ant-select {
      width: 208px;
      margin-right: 18px;
      @media (max-width: 800px) {
         width: 100%;
      }
   }
   @media (max-width: 800px) {
      & .ant-form-vertical .ant-form-item {
         flex-flow: row wrap !important;
      }
   }
`
export const DateInputs = styled(Flex)`
   position: relative;
   padding: 8px 0 0;

   @media (max-width: 800px) {
      width: 100%;
      margin-bottom: 10px;
      & .ant-form-item,
      & .ant-col,
      & .ant-picker {
         width: 100%;
      }
   }
`
export const DateInputsError = styled.div`
   position: absolute;
   bottom: -10px;
   left: 0;
   color: red;
`
export const GenerateReportBlock = styled(Flex)`
   width: 100%;
   height: 100%;
   border: 1px solid #ededed;
   border-radius: 8px;
   margin-top: 25px;
   padding: 20px;
   min-height: 500px;
   @media (max-width: 600px) {
      min-height: 250px;
   }
`
export const CalendarWrapper = styled.div`
   .ant-form-item-control-input {
      min-height: 0;
   }
`
