import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { SettingFilled } from "@ant-design/icons"

import Flex from "../noui/Flex"
import { TCallback } from "../types"

type TProps = {
   onClick: TCallback
}

const StyledButton = styled(Button)`
   svg {
      font-size: 16px;
      vertical-align: bottom;
      color: #757575;
   }
   &:hover {
      svg {
         color: #0496ff;
      }
   }
`

export const ColumnsButton: React.FC<TProps> = ({ onClick }) => {
   return (
      <Flex alignItems="center">
         <StyledButton onClick={onClick}>
            Columns <SettingFilled />
         </StyledButton>
      </Flex>
   )
}
