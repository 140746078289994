import { down } from "styled-breakpoints"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { Button } from "antd"

export const Title = styled.h1`
   font-style: normal;
   font-weight: 900;
   font-size: 16px;
   line-height: 22px;
   letter-spacing: 0.01em;
   text-transform: uppercase;
   color: #151f2b;
`

export const PaddedContainer = styled.div`
   padding: 16px;
   background-color: white;
   ${down("xs")} {
      padding: 0px;
      margin-top: 10px;
   }
`
export const ApproveRow = styled.div`
   display: flex;
   margin: 30px -15px 0 -15px;
   gap: 12px;
   width: calc(100% + 30px);
   align-items: center;
   justify-content: stretch;
   background: #fff;
   padding: 15px;
   position: sticky;
   bottom: 0;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 0px 0px 4px 4px;

   p {
      margin: 0;
      padding-right: 8px;
   }
   a {
      width: 40px;
      height: 38px;
      border: none;
   }
   ${down("xs")} {
      flex-direction: column;
      margin-top: 36px;
      button {
         width: 100%;
         font-size: 15px;
         height: 40px;
      }
   }
`

export const DetailsContainer = styled.div`
   padding: 16px 16px 0 16px;
   display: flex;
   height: 100%;
   flex-flow: column nowrap;
   > .ant-divider {
      width: calc(100% + 32px);
      margin: 32px -16px 0 !important;
   }
`

export const ActionButton = styled(Button)`
   font-size: 14px;
   ${down("xs")} {
      font-size: 11px;
   }
`
/************ */

export const Header = styled(Flex)`
   padding: 20px 0;
   margin-bottom: 20px;

   p {
      font-size: 12px;
      margin-bottom: 0px;
      span {
         font-weight: bold;
      }
   }

   h3 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 10px;
   }
   h4 {
      font-weight: 900;
      font-size: 13px;
      margin-bottom: 5px;
   }
   div {
      // width: 100%;
   }
   img {
      max-width: 150px;
      max-height: 90px;
      width: 100%;
      height: auto;
      object-fit: contain;
   }
`
export const Info = styled(Flex)`
   gap: 30px;
   .infoBlock {
      width: 100%;
   }
   h4 {
      font-weight: bold;
      font-size: 13px;
      text-align: center;
   }
   ${down("xs")} {
      flex-direction: column;
   }
`
export const HeaderInfo = styled.div`
   margin-left: 25px;
   @media (min-width: 1450px) {
      margin-left: 13%;
   }
   @media (min-width: 1750px) {
      margin-left: 16%;
   }
   @media (min-width: 1800px) {
      margin-left: 20%;
   }
`
export const WOInfo = styled(Flex)`
   width: 100%;
   padding: 7px 15px;
   color: #fff;
   background: #0f497b;
   h3 {
      font-weight: bold;
      font-size: 13px;
      color: #fff;
      margin: 0;
   }
   p {
      font-weight: 600;
      font-size: 12px;
      margin: 0;
   }
`
export const PaymentBlock = styled(Flex)`
   ${down("xs")} {
      flex-direction: column;
   }
`
export const WOTitle = styled(Flex)`
   width: 100%;
   padding: 15px;
   h3 {
      font-weight: 900;
      font-size: 13px;
      color: #202020;
      margin: 0;
      text-transform: uppercase;
   }
   p {
      font-weight: 900;
      font-size: 12px;
      margin: 0;
      span {
         color: #8b8b8b;
         font-weight: normal;
      }
   }
   ${down("xs")} {
      padding: 20px 0;
      flex-direction: column;
      align-items: flex-start;
   }
`
export const DetailsDiv = styled.div`
   margin: 24px 0;
   font-size: 12px;
   h4 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      text-align: left;
      text-decoration: underline;
   }
   ul {
      margin: 0 0 15px 0;
      padding-left: 25px;
      li {
         font-weight: bold;
         span {
            font-weight: normal;
         }
      }
   }
   h5 {
      color: #8b8b8b;
      font-weight: normal;
      font-size: 12px;
   }
`
export const Customer = styled.div`
   width: 100%;
   min-width: 300px;
   ${down("xs")} {
      min-width: 0px;
   }
   div > div {
      width: 220px;
   }
`
export const CustomerInfo = styled(Flex)`
   width: 100%;
   justify-content: space-between;
   font-size: 12px;
   .title {
      font-weight: bold;
   }
`
export const JobTitle = styled(Flex)`
   margin-top: 20px;
   padding: 10px;
   border-bottom: 1px solid #ededed;
   h3 {
      font-weight: 900;
      font-size: 15px;
      text-transform: uppercase;
      margin: 0;
   }
   p {
      color: #494949;
      font-size: 13px;
      margin: 0;
   }
   ${down("xs")} {
      margin-top: 0;
      border: none;
   }
`
export const JobInfo = styled(Flex)`
   margin-bottom: 20px;
   padding: 10px;
   font-size: 13px;
   span {
      font-weight: bold;
   }
   ${down("xs")} {
      flex-direction: column;
      align-items: flex-start;
   }
`
export const PaymentStatus = styled.div`
   border: 1px solid #ededed;
   margin-top: 25px;
   h3 {
      background: #0f497b;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
      margin: 0;
      padding: 7px;
   }
   .paymentBlock {
      width: 50%;
      padding: 10px;
      p {
         font-size: 12px;
         margin-bottom: 10px;
      }
      ${down("xs")} {
         width: 100%;
         .right {
            text-align: right;
         }
      }
   }
   .divider {
      width: 100%;
      height: 1px;
      background: #ededed;
      margin: 5px 0;
   }
`
export const VesselHeader = styled.div`
   padding: 10px;
   background: #ededed;
`
export const VesselBottom = styled.div`
   padding: 10px;
`
export const VesselImg = styled.div`
   width: 100%;
   height: 135px;
   overflow: hidden;
   border-radius: 4px;
   margin-top: 10px;
   img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
   }
`
export const TableDiv = styled.div`
   margin: 10px 0;
   box-sizing: border-box;
`
export const TableItem = styled.div`
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
`
export const Services = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 3fr 1fr 1.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 1fr 1.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
export const Materials = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 4fr 1fr 1.5fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 2.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .description {
      padding: 8px;
      font-size: 12px;
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`

export const Summary = styled(Flex)`
   justify-content: space-between;
   align-items: flex-start;
   .disclaimers {
      margin-right: 10px;
      h3 {
         text-decoration: underline;
      }
   }
   .summary {
      min-width: 220px;
      padding: 4px 10px;
      border: 1px solid #ededed;
      background: #fbfbff;
      div {
         padding: 3px 0;
      }
      ${down("xs")} {
         min-width: 100%;
         margin-bottom: 15px;
      }
   }

   //float: right;
   font-size: 12px;

   & .total {
      font-weight: bold;
      border-top: 1px solid #ededed;
   }
   ${down("xs")} {
      flex-direction: column-reverse;
   }
`

export const DropDownButton = styled(Button)`
   border: none;
   width: 16px;
   padding: 0px;
`
export const JobDropDown = styled(Flex)`
   padding: 0px 16px;
   margin: 15px -16px;
   border-top: 1px solid #dedede;
   border-bottom: 1px solid #dedede;
`
export const MobileItem = styled(Flex)`
   flex-direction: column;
   .main {
      flex-direction: column;
      font-size: 14px;
      > div {
         display: flex;
         flex-direction: row;
         background-color: #fbfbff;
         border-bottom: 1px solid #ededed;
         border-left: 1px solid #ededed;
         border-right: 1px solid #ededed;
         > div {
            width: 100%;
            padding: 8px;
         }
         > div:first-child {
            width: 150px;
            border-right: 1px solid #ededed;
            font-weight: bold;
         }
      }
      > div:first-child {
         background-color: #ededed;
         border-top: 1px solid #ededed;
         align-items: center;
      }
   }
   .tech {
      display: flex;
      flex-direction: column;
      padding: 16px 8px;
      background-color: #fbfbff;
      border-bottom: 1px solid #ededed;
      border-right: 1px solid #ededed;
      border-left: 1px solid #ededed;
      > div {
         display: flex;
         width: 100%;
         justify-content: space-between;
         > div ~ div {
            width: 150px;
         }
      }
      > div:first-child div {
         text-decoration: underline;
      }
   }
`
export const Technician = styled.div`
   background: #fbfbff;
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
   padding-bottom: 4px;
   & .grid {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr 1.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      &.tech {
         grid-template-columns: 3fr 2fr 1fr 1.5fr;
      }

      > div {
         padding: 4px 8px;
      }
      &.header {
         text-decoration: underline;
         background: #fbfbff;
         font-weight: normal;
      }
   }
`
export const TransactionInfo = styled.div`
   h4,
   p {
      margin: 0 !important;
   }
`
