import { Tooltip } from "antd";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled from "styled-components";
import { TMFieldInfo } from "../../assets/icons";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";

export const FormRow = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    >div {
        width: calc(50% - 9px);
        .ant-row.ant-form-item {
            margin-bottom: 0;
            width: 100%;
        }
    }
    ${down('lg')} {
        flex-direction: column;
        >div {
            width: 100%;
        }
    }
`

type TFormItemProps = {
    children: JSX.Element;
    label: string;
    tooltip?: string;
    tooltipPaddingLeft?: string;
};

export const FormItem: React.FC<TFormItemProps> = ({ children, label, tooltip, tooltipPaddingLeft }) => {
    const isXS = useBreakpoint(down('xs'));
    return <Flex flexDirection="column" marginTop="16px">
        <Msg fontSize="13px" fontWeight="700" color="#494949" paddingLeft={tooltipPaddingLeft ?? "0"}>
            {label}
        </Msg>
        <Flex width="100%" marginTop="4px" justifyContent="space-between">
            <Flex width="calc(100% - 22px)">
                {children}
            </Flex>
            {!!tooltip && (
                <Flex paddingTop="8px">
                    <Tooltip color="#EDEDED" overlayInnerStyle={{color: '#494949'}} placement={isXS ? "bottomRight" : "bottom"} title={tooltip}>
                        <Flex height="18px">
                            <TMFieldInfo />
                        </Flex>
                    </Tooltip>
                </Flex>
            )}
        </Flex>
    </Flex>;
};
