import { ModalWindow } from "../ui/ModalWindow"
import React, {useState} from "react"
import { Msg } from "../ui/Text"
import Flex from "../noui/Flex"
import {NavLink} from "react-router-dom"
import Box from "../noui/Box"
import styled from "styled-components"
import { PrimaryButton } from "../ui/Button"
import routes from "../routes/routes"
import { IUserProfile } from "../api/types"
import ChangePassword from "./ChangePassword"
import { authService } from "../utils/authService"
import { useSubscriptionStatus, useTechnicianRole } from "../utils/hooks"
import { checkNotActive } from "../utils/utils"

const StylizePrimary = styled(PrimaryButton)`
   background-color: #ffffff;
   width: 100%;
   color: #252733;
   border: 0;

   &:focus {
      background-color: #109cf1;
      color: #ffffff;
   }
`

type TProps = {
   profile: IUserProfile | null
   handleClose: () => void
   handlePasswordChange: () => void
}

export const PersonalInfo: React.FC<TProps> = ({ profile, handleClose, handlePasswordChange }) => {
   const [visible, setVisible] = useState<boolean>(false)
   const subscriptionStatus = useSubscriptionStatus();
   const handleLogout = () => {
      authService.logout()
      window.location.href = "/login"
   }

   const technicianRole = useTechnicianRole()

   return (
      <ModalWindow>
         <ChangePassword visible={visible} handleClose={() => setVisible(false)} />
         <Flex height="227.67px" width="216px" flexDirection="column" justifyContent="center">
            <Flex
               width="inherit"
               justifyContent="center"
               alignItems="center"
               height="50px"
               borderBottom="1px solid rgba(0, 0, 0, 0.15);"
               style={{ wordBreak: "break-word" }}
            >
               <Msg fontWeight={900} fontSize={13} style={{ textTransform: "uppercase" }}>
                  {profile?.full_name}
               </Msg>
            </Flex>
            <Flex
               width="inherit"
               flexDirection="column"
               justifyContent="center"
               alignItems="flex-start"
               height="120px"
               borderBottom="1px solid rgba(0, 0, 0, 0.15);"
            >
               <Box p={12}>
                  {!technicianRole && profile?.company ? (
                     <NavLink to={routes.profile.MySubscription} onClick={handleClose}>
                        <Box height="40px" lineHeight="40px">
                           <Msg
                              fontWeight={600}
                              fontSize={13}
                              color={checkNotActive(subscriptionStatus) ? "#FB4D4F" : "#494949"}
                           >
                              My subscription
                           </Msg>
                        </Box>
                     </NavLink>
                  ) : null}

                  <NavLink to={routes.profile.UpdateProfile} onClick={handleClose}>
                     <Box height="40px" lineHeight="40px">
                        <Msg fontWeight={600} fontSize={13} color="#494949">
                           Update Personal Info
                        </Msg>
                     </Box>
                  </NavLink>
                  <Flex
                     style={{ cursor: "pointer" }}
                     onClick={() => {
                        setVisible(true)
                        handlePasswordChange()
                     }}
                  >
                     <Box height="40px" lineHeight="40px">
                        <Msg fontWeight={600} fontSize={13} color="#494949">
                           Change Password
                        </Msg>
                     </Box>
                  </Flex>
               </Box>
            </Flex>
            <Flex width="inherit" justifyContent="flex-start" alignItems="center" height="54px">
               <StylizePrimary onClick={handleLogout}>
                  <Box>
                     <Msg fontWeight={600} fontSize={13}>
                        Log Out
                     </Msg>
                  </Box>
               </StylizePrimary>
            </Flex>
         </Flex>
      </ModalWindow>
   )
}
