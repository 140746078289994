import { RootState } from "../../store"
import { IConstants, IUserProfile, TSettingsData } from "../../../api/types"
import { createSelector } from "@reduxjs/toolkit"

export const getCurrentUser = (state: RootState): IUserProfile | null => state.user.currentUser
export const getConstants = (state: RootState): IConstants | null => state.user.constants
export const getConstant = (k: keyof IConstants) =>
   createSelector(getConstants, (constants) => (constants ? constants[k] : null))
export const getCallendarSettings = (state: RootState): TSettingsData => state.user.callendarSettings
export const getSidebarCollapse = (state: RootState): boolean => state.user.sidebarCollapse
