import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { useSelector } from "react-redux"
import { Spin, notification } from "antd"
import { useBreakpoints } from "../../../../ui/Breakpoints"
import Flex from "../../../../noui/Flex"
import { Msg } from "../../../../ui/Text"
import { IMyJob, IUser } from "../../../../api/types"
import { Api } from "../../../../api/api"
import { getCurrentUser } from "../../../../config/reducers/user/selectors"
import TimerBlock from "./TimerBlock"
import WelcomeInfo from "./WelcomeInfo"

const WelcomeContainer = styled.div`
   // max-width: 636px;
   width: 100%;
   position: relative;
   padding: 10px 20px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
   ${down("lg")} {
      grid-column-start: 1;
      grid-column-end: 3;
      max-width: 100%;
   }
`

const Welcome: React.FC = () => {
   const [jobs, setJobs] = useState<IMyJob[]>()
   const [nextJob, setNextJob] = useState<IMyJob>()
   const [loading, setLoading] = useState<boolean>(false)
   const userData = useSelector(getCurrentUser) as IUser
   const bp = useBreakpoints()

   useEffect(() => {
      setLoading(true)
      Api.jobs
         .getJobs()
         .then(({ data }) => {
            const dateNow = new Date().toISOString()
            const jobs = data as IMyJob[]
            //console.log(jobs)
            const nextJob = jobs?.find((item) => (item.start < dateNow && item.end > dateNow) || item.start > dateNow)
            setJobs(jobs)
            //console.log(nextJob)
            setNextJob(nextJob)
         })
         .catch((e) => {
            notification.error(e)
         })
         .finally(() => {
            setLoading(false)
         })
   }, [])

   const flexDirection = bp.isMdDown ? "column" : "row"
   const alignItems = bp.isMdDown ? "center" : "flex-end"

   return (
      <WelcomeContainer>
         {!loading ? (
            <Flex justifyContent="space-between" alignItems={alignItems} flexDirection={flexDirection} height="100%">
               <WelcomeInfo nextJob={nextJob} jobs={jobs} setNextJob={setNextJob} />
               <TimerBlock nextJob={nextJob} technician={userData} />
            </Flex>
         ) : (
            <Spin style={{ width: "100%", marginTop: "16px" }} />
         )}
      </WelcomeContainer>
   )
}

export default Welcome
