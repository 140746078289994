import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../config/reducers/user/selectors";
import styled from "styled-components";
import {checkNotActive, getInitials} from "../../utils/utils";
import {PersonalInfo} from "../../modals/PersonalInfoModal";
import {Popover} from "antd";
import { TCallback } from '../../types';
import { useSubscriptionStatus } from '../../utils/hooks';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  padding: 24px;
`;

type TInitialsProps = {
  isRed: boolean
}

const Initials = styled.div<TInitialsProps>`
  width: 45px;
  height: 45px;
  background: #FFFFFF;
  border-radius: 6px;
  font-weight: 900;
  font-size: 18px;
  color: #151F2B;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 4px;
    position: absolute;
    background: ${props => props.isRed ? "#FB4D4F" : "#42C77B"} ;
    top: 12px;
    right: 7px;
    display: block;
  }
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  > h4, h5 {
    margin: 0;
    padding: 0;
    font-weight: 900;
    color: #FFFFFF;
    text-decoration: underline;
    font-size: 13px;
    line-height: 18px;
  }
  > h5 {
    font-weight: 300;
  }
`;

type TProps = {
  onClose: TCallback
}

export const UserSidebarProfile:React.FC<TProps> = ({onClose}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const user = useSelector(getCurrentUser);
  const handleVisibleChange = (v: boolean) => {
    setVisible(v);
  }
  const subscriptionStatus = useSubscriptionStatus();
  
  return <Popover
    overlayClassName={'transparentPopoverWrapper'}
    trigger="click"
    visible={visible}
    onVisibleChange={handleVisibleChange}
    content={<PersonalInfo 
      profile={user} 
      handleClose={() => { 
        setVisible(false); 
        onClose();
      }}
      handlePasswordChange={()=>setVisible(false)}
    />}>
    <Wrapper>
      <Initials isRed={checkNotActive(subscriptionStatus)}>{getInitials(user)}</Initials>
      <Content>
        <h4>{user?.full_name ?? ''}</h4>
        <h5>{user?.company?.name ?? ''}</h5>
      </Content>
    </Wrapper>
  </Popover>
};