import styled from "styled-components";
import Flex from "../../noui/Flex";
import { down } from "styled-breakpoints";
import { Msg } from "../../ui/Text";
import { PALETTE } from "../../constants";
import { StyledButton } from "../settings/SubscriptionCard";

export const SubscriptionsContainer = styled(Flex)`
    flex-direction: column;
    max-width: 688px;
    width: 100%;
    margin: auto;
    margin-bottom: 36px;
    padding: 24px 16px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    & .ant-row.ant-form-item {
        margin-bottom: 0;
    }
    ${down('xs')} {
      box-shadow: none;
      margin-bottom: 0;
    }
`;

export const CurrentSubscriptionBlock = styled(Flex)`
    flex-direction: column;
    padding: 16px 12px;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    width: 100%;
`;

type TCurrentSubscriptionBlockButtonProps = {
    disabled?: boolean
}

export const CurrentSubscriptionBlockButton = styled(Msg)<TCurrentSubscriptionBlockButtonProps>`
    text-decoration: ${props => props.disabled ? "none" : "underline"};
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    color: ${props => props.disabled ? "#8F8F8F" : "#0496FF"};
    font-weight: 700;
    font-size: 13px;
`;

export const SCCollapseWithButton = styled(Flex)`
    margin-top: 16px;
    background: "#FFFFFF";
    color: ${PALETTE.Text};
    padding: 9px 14px 9px 16px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    flex-direction: column;
    width: calc(50% - 8px);
    &:nth-child(even) {
        margin-left: 16px;
    }
    ${down('md')} {
        width: 100%;
      &:nth-child(even) {
        margin-left: 0px;
      }
        &:last-child {
            margin-left: 0px;
        }
    }
`;

export const ChangePlanButton = styled(StyledButton)`
    margin: 24px auto 0;
    ${down('xs')} {
        width: 100%;
    }
`;