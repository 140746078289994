import React, {useEffect} from 'react';
import DarkLogo from '../../assets/images/DarkLogo.svg';
import Flex from '../../noui/Flex';
import Box from '../../noui/Box';
import styled from 'styled-components';
import {Msg} from '../../ui/Text';
import {Form, Input} from 'antd';
import Envelope from '../../assets/images/loginicons/Envelope.svg';
import Lock from '../../assets/images/loginicons/Lock.svg';
import {NavLink, useHistory} from 'react-router-dom';
import {PrimaryButton} from '../../ui/Button';
import SmallBoatImg from '../../assets/images/SmallBoatImg.png';
import routes from "../../routes/routes";
import {Api} from "../../api/api";
import {authService} from "../../utils/authService";
import {setCurrentUser} from "../../config/reducers/user/actions";
import {useAppDispatch} from "../../config/hooks";
import {useBreakpoint} from "styled-breakpoints/react-styled";
import {down} from "styled-breakpoints";
import moment from 'moment';
import { identify, group } from '../../utils/analytics';
import { getIndustries } from '../../utils/utils';


const LogoContainer = styled(Box)`
& img {
    width: 140px;
    height: 24px;
}
${down('md')} {
  margin: 0px auto;
}
`;

const EnvelopeImg = () => <img src={Envelope} alt='Envelope'/>;
const LockImg = () => <img src={Lock} alt='Lock'/>;
export const StyledEnvelopeIcon = styled(EnvelopeImg)`
  color: black;
  cursor: pointer;
`;
export const StyledLockIcon = styled(LockImg)`
  color: black;
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  height: 40px;
  width: 374px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  border-radius: 4px;
  ${down('md')} {
    width: 100%;
  }
`;

const StyledPasswordInput = styled(Input.Password)`
  height: 40px;
  width: 374px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  border-radius: 4px;
  ${down('md')} {
    width: 100%;
  }
`;

const BtnPr = styled(PrimaryButton)`
&:hover {
  border: 1px solid #109CF1;
}
`

export const LoginFlowWrapper = styled(Flex)<{isStaff?: boolean}>`
  width: 55%;
  flex-direction: column;
  min-height: initial;
  height: ${({ isStaff }) => isStaff ? 'calc(100vh - 60px)' : '100vh'};
  ${down('md')} {
    width: 100%;
    min-height: 100vh;
    height: initial;
  }
`;

export const BoxWrapper = styled(Box)`
  width: 45%;
  min-height: initial;
  height: 100vh;
  ${down('md')} {
    min-height: 100vh;
    height: initial;
  }
`;

type TForm = {
  email: string;
  password: string;
}
export const Login = () => {
  const [form] = Form.useForm<TForm>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMd = useBreakpoint(down('md'));

  const handleSubmit = async (values: TForm) => {
    try {
      await authService.login(values);
      const {data} = await Api.user.profile();
      const industry = await getIndustries(data);
      dispatch(setCurrentUser(data));
      await identify(data.id, data.full_name, data.email, +moment(data.date_joined), data.company?.plan?.title?.toLowerCase())
      await group(data.company?.id, data.company?.name, data.company?.website, industry, data.company?.created, data.company?.plan?.number_of_users, data.company?.plan?.title?.toLowerCase())
      if (!data.company && !data.is_staff) {
        history.replace(routes.registration.Step2)
        return;
      }
      else if (data.company && data.role_id !== 4 && (data.company.plan === null || data.company.subscription_status === null || (data.company.plan && data.company.subscription_status?.status === "O"))) {
        history.replace(routes.registration.Step3)
        return;
      }
      if (data.is_staff && data.company === null) {
        history.replace(routes.main.SUDashboard);
      } else {
        history.replace(routes.main.Dashboard);
      }

    } catch (e) {
      const errors = [
        {
          errors: ['Invalid password or email'],
          name: ['password']
        }
      ];
      form.setFields(errors);
    }
  }


  return <Flex backgroundColor={!isMd ? "none" : "#FBFBFF"}>
    <LoginFlowWrapper>
      <LogoContainer p={35}>
        <img src={DarkLogo} alt='logotype'/>
      </LogoContainer>
      <Flex style={isMd ? {padding: "0 20px"} : undefined} flexDirection="column" justifyContent='flex-start' height="100%">
        <Box style={isMd ? {width: "100%"} : undefined} m="auto">
          <Flex flexDirection='column' justifyContent='space-between' maxWidth={isMd ? "400px" : "initial"} margin="auto">
            <Flex flexDirection='column'>
              <Box mb='30px' textCenter={!!isMd}>
                <Msg fontWeight={700} fontSize={22}>Welcome Back</Msg>
              </Box>
              <Form
                form={form}
                onFinish={handleSubmit}
              >
                <Box mb='8px'>
                  <Msg fontWeight={700} fontSize={15} color="#484848">E-mail</Msg>
                </Box>
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: 'The input is not valid E-mail!',
                    },
                  ]}>
                  <StyledInput
                    prefix={<StyledEnvelopeIcon />}
                    placeholder="user@gmail.com"
                  />
                </Form.Item>

                <Box mb='8px' mt="10px">
                  <Msg fontWeight={700} fontSize={15} color="#484848">Password</Msg>
                </Box>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <StyledPasswordInput 
                    placeholder="Password"
                    prefix={<StyledLockIcon />} 
                  />
                </Form.Item>
                <Box mt='30px' mb="15px">
                  <BtnPr
                    width={isMd ? "100%" : "100px"} height="40px"
                    fontWeight={600} fontSize={14}
                    htmlType="submit">
                    Sign in
                  </BtnPr>
                </Box>
              </Form>
              <NavLink to={routes.auth.ForgotPassword} style={{textAlign: isMd ? "center" : "start"}}>
                <Msg fontWeight={600} fontSize={16} color="#1C99FC" style={{textDecoration: "underline"}}>
                  Forgot Password?
                </Msg>
              </NavLink>
            </Flex>
          </Flex>
        </Box>
        <Box width={isMd ? "100%" : "374px"} m="auto" mb="30px" mt="0" textCenter={!!isMd}>
          <Msg
            fontWeight={600}
            fontSize={16}
            color="#BDBDBD">
            Don't have an account?
            &nbsp;
            <NavLink to={routes.auth.Register} style={{textDecoration: "underline"}}>
              Register
            </NavLink>
          </Msg>
        </Box>
      </Flex>
    </LoginFlowWrapper>
    {!isMd ? <BoxWrapper>
      <img src={SmallBoatImg} width="100%" height="100%" alt="background"/>
    </BoxWrapper> : null}
  </Flex>
}