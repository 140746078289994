import {Grid, Select} from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useAppSelector } from "../../../config/hooks"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { EmptyButton } from "../../../ui/Button"
import Box from "../../../noui/Box"
import moment from "moment"
import NextImg from "../../../assets/images/progressBar/next.svg"
import PrevImg from "../../../assets/images/progressBar/prev.svg"
import UpcomingJobs from "./UpcomingJobs"
import OpenInvoices from "./OpenInvoices"
import PaidInvoices from "./PaidInvoices"
import WeatherAlert from "./WeatherAlert"
import Revenue from "./Revenue"
import TopCustomers from "./TopCustomers"
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down, up } from "styled-breakpoints"
import { Api } from "../../../api/api"
import { IStats } from "../../../api/types"
import { TArgCallback } from "../../../types"
import { getSidebarCollapse } from "../../../config/reducers/user/selectors"

const { useBreakpoint: useAntdBreakpoint } = Grid;

const WidgetContainer = styled.div`
   grid-column-start: 1;
   grid-column-end: 3;
   position: relative;
   display: flex;
   flex-direction: column;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   padding: 12px;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
`

const NextBtn = styled(EmptyButton)`
   width: 28px;
   height: 28px;
   line-height: 20px;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 6px;
   opacity: ${(p) => (p.disabled ? 0.3 : 1)};
   img {
      vertical-align: middle;
      margin-top: -2px;
   }
`

const ArrowLeft = styled(EmptyButton)`
   width: 10.53px;
   height: 10.53px;
   border-left: 2px solid #757575;
   border-top: 2px solid #757575;
   box-sizing: border-box;
   transform: rotate(-45deg);
   border-radius: 0;
`

const ArrowRight = styled(EmptyButton)`
   width: 10.53px;
   height: 10.53px;
   border-right: 2px solid #757575;
   border-bottom: 2px solid #757575;
   box-sizing: border-box;
   transform: rotate(-45deg);
   border-radius: 0;
`

const WeekData = styled(Box)`
   box-sizing: border-box;
   padding: 6px;
   font-weight: 400;
   font-size: 13px;
   color: #494949;
   text-align: center;
` as typeof Box

const TitleContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`

const CardsContainer = styled.div`
   display: flex;
   align-items: normal;
   // margin-right: 10px;
   gap: 8px;
   padding: 8px;
   > div {
      margin: 6px;
      min-height: 242px;
   }
   ${down("xs")} {
      overflow-x: auto;
      > div {
         min-width: 170px;
         margin: 6px 10px;
      }
   }
`

const SelectWrapper = styled(Select)`
   border-radius: 4px;
   font-weight: 400;
   font-size: 13px;
   &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0;
      outline: 0;
      display: flex;
      align-items: center;
   }
   & .ant-select-selection-item {
      color: #42c57a;
   }
   .ant-select-item {
      width: 112px;
      height: 34px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      color: #42c57a;
   }
`

const ButtonsContainer = styled.div`
   display: flex;
   align-items: center;
   margin: auto 0 auto auto !important;
   flex-direction: column;
   gap: 8px;
`

type TDirection = "f" | "b"
type TView = "month" | "week"
type TMainContainerStatsProps = {
   weather: any | null
   changeWeatherLocation: TArgCallback<any>
}

const MainContainerStats: React.FC<TMainContainerStatsProps> = ({ weather, changeWeatherLocation }) => {
   const [currentDay, setCurrentDay] = useState(moment.utc().startOf("month"))
   const [statsData, setStatsData] = useState<IStats | null>(null)
   const [currentIndex, setIndex] = useState<number>(0)
   const sidebarCollapse = useAppSelector(getSidebarCollapse)
   const [view, setView] = useState<TView>("month")
   const isXS = useBreakpoint(down("xs"))
   const isMD = useBreakpoint(down("md"))
   const isXl = useBreakpoint(up("xl"))
   const isLg = useBreakpoint(down("lg"))
   const breakPoints = useAntdBreakpoint();

   const cards = useMemo(() => [UpcomingJobs, WeatherAlert, OpenInvoices, PaidInvoices, Revenue, TopCustomers], [])

   const cardsPerScreen = useMemo(() => {
      if (isXS) {
         return cards.length
      }
      if (isMD) {
         if (sidebarCollapse) {
            return 3
         } else {
            return 2
         }
      }
      if (isLg) {
         if (sidebarCollapse) {
            return 4
         } else {
            return 3
         }
      }
      if (breakPoints.xxl) {
         return 6;
      }
      if (isXl) {
         if (sidebarCollapse) {
            return 6;
         }
         return 5
      }
      return 4
   }, [isXS, isMD, isXl, isLg, breakPoints, cards, sidebarCollapse])

   useEffect(() => {
      setIndex(0)
   }, [cardsPerScreen])

   const handleChangeWeek = (direction: "f" | "b") => () => {
      if (direction === "f") {
         setCurrentDay(moment(currentDay).add(1, view))
      } else {
         setCurrentDay(moment(currentDay).subtract(1, view))
      }
   }

   useEffect(() => {
      Api.stats
         .getAll({
            time_after: currentDay.toISOString(),
            time_before: moment(currentDay).endOf(view).toISOString(),
         })
         .then(({ data }) => {
            setStatsData(data as IStats)
         })
   }, [currentDay, view])

   const canSwitch = (direction: TDirection): boolean =>
      (direction === "b" && currentIndex > 0) || (direction === "f" && currentIndex + cardsPerScreen < cards.length)

   const changeCard = (direction: TDirection) => () => {
      if (canSwitch(direction)) {
         setIndex(direction === "f" ? currentIndex + 1 : currentIndex - 1)
      }
   }

   const handleSelect = (v: any) => {
      const newView = v as TView
      setView(newView)
      if (newView === "week" && currentDay.month() === moment().month()) {
         setCurrentDay(moment.utc().startOf(newView))
      } else {
         setCurrentDay(moment(currentDay).startOf(newView))
      }
   }

   return (
      <WidgetContainer>
         <TitleContainer>
            <Msg fontWeight={900} fontSize={16} width="170px" color="#151F2B">
               TODAY'S STATS
            </Msg>
            <Flex alignItems="center" justifyContent="space-between" p="0px 16px">
               <ArrowLeft onClick={handleChangeWeek("b")}> </ArrowLeft>
               <WeekData>{`${currentDay.format(
                  view === "month" ? (isXS ? "MMM YYYY" : "MMMM YYYY") : "[Week] w, MMMM YYYY"
               )}`}</WeekData>
               <ArrowRight onClick={handleChangeWeek("f")}> </ArrowRight>
            </Flex>
            {!isXS ? (
               <Flex alignItems="center">
                  <Msg fontWeight={400} fontSize={13} color="#151F2B">
                     Show:
                  </Msg>
                  <SelectWrapper onSelect={handleSelect} defaultValue="Monthly">
                     <Select.Option value="month">Monthly</Select.Option>
                     <Select.Option value="week">Weekly</Select.Option>
                  </SelectWrapper>
               </Flex>
            ) : null}
         </TitleContainer>
         <CardsContainer>
            {cards.slice(currentIndex, cardsPerScreen + currentIndex).map((Card, idx) => (
               <Card
                  changeWeatherLocation={changeWeatherLocation}
                  key={idx}
                  statsData={statsData}
                  fullWeather={weather}
               />
            ))}
            {!isXS ? (
               <ButtonsContainer>
                  <NextBtn disabled={!canSwitch("f")} onClick={changeCard("f")}>
                     <img src={NextImg} alt={"next"} />
                  </NextBtn>
                  <NextBtn disabled={!canSwitch("b")} onClick={changeCard("b")}>
                     <img src={PrevImg} alt={"prev"} />
                  </NextBtn>
               </ButtonsContainer>
            ) : null}
         </CardsContainer>
      </WidgetContainer>
   )
}

export default MainContainerStats
