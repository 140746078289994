import React from 'react';


type TProps = {
    padded?: boolean;
}
export const Container: React.FC<React.PropsWithChildren<TProps>> = ({children, padded}) => {
    return <div className={`content-container ${padded ? "padded" : ""}`}>
        {children}
    </div>;
};