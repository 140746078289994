import { useState, useEffect, useCallback, useMemo, useContext } from "react";

import { NavLink, useLocation, useHistory } from "react-router-dom";
import { notification, Form, Button } from "antd";
import GoogleMapReact from "google-map-react";
import { Api } from "../../../api/api";
import { AutocompleteSelect } from "../../../components/Autocomplete/AutocompleteCustomer";
import { SelectCard, SelectCardTitle, AutocompleteAddButton } from "../styled";
import { VesselsModal } from "../../../containers/vessels/VesselsModal";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { MapWrap, Marker } from "../../../containers/customers/pages/styled";
import routes from "../../../routes/routes";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { GoogleMapsAPIKey } from "../../../api";
import { useSelector } from "react-redux";
import { getConstants } from "../../../config/reducers/user/selectors";
import { AutocompleteVessel } from "../../../components/Autocomplete/AutocompleteVessel";
import { WOContext } from "../WorkOrderContext";

export const CancelButton = styled(Button)`
  width: 80px;
  margin: auto 0 0 auto;
  ${down("xs")} {
    width: 100%;
    margin: 20px 0 0 auto;
  }
`;
export const StyledButton = styled(Button)`
  border: none;
  font-weight: 700;
  color: #109cf1;
  text-align: left;
  font-size: 16px;
  span {
    text-decoration: underline;
  }
`;

export const AddVessel = ({
  form,
  updateData,
  selectedVessel,
  setCustomerId,
  workOrder,
  customData,
  showSaveModal,
}) => {
  const [modal, setModal] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [vessel, setSelectedVessel] = useState(null);
  const location = useLocation();
  const isXs = useBreakpoint(down("xs"));
  const isLg = useBreakpoint(down("lg"));
  const [lastSelectedVessel, setLastSelectedVessel] = useState();
  const Context = useContext(WOContext);
  const history = useHistory();

  const loadVessels = async () => {
    try {
      const { data } = await Api.vessels.getAll({ ordering: "name" });
      const items = sortItems(data);

      setVessels(items);
    } catch (e) {
      notification.error({
        message: "Unable to upload vessels",
      });
    }
  };
  const sortItems = (data) => {
    return data.sort(function (a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };
  const [mapsOptions, setMapsOptions] = useState({
    center: {
      lat: 40,
      lng: -73,
    },
    zoom: 1,
    mapTypeControl: true,
  });

  useEffect(() => {
    if (selectedVessel) {
      setSelectedVessel(selectedVessel);
      form.setFieldsValue({ vessel: selectedVessel?.id });
    }
  }, [selectedVessel, form]);

  useEffect(() => {
    if (!customData.vessel) {
      setSelectedVessel(undefined);
    } else {
      const findVessel = vessels.find((item) => item.id === customData.vessel);

      setSelectedVessel(findVessel);
    }
  }, [customData.vessel, vessels]);

  useEffect(() => {
    if (vessel) {
      setCustomerId(vessel?.customer?.id);
      if (vessel?.address?.lat && vessel?.address?.lng) {
        setMapsOptions({
          center: {
            lat: vessel?.address?.lat ?? 40,
            lng: vessel?.address?.lng ?? -73,
          },
          zoom: 10,
          mapTypeControl: true,
        });
      }
    }
  }, [vessel]);

  useEffect(() => {
    if (workOrder?.current_address?.lat && workOrder?.current_address?.lng) {
      setMapsOptions({
        center: {
          lat: workOrder.current_address?.lat ?? 40,
          lng: workOrder.current_address?.lng ?? -73,
        },
        zoom: 10,
        mapTypeControl: true,
        fullscreenControl: false,
      });
    }
  }, [workOrder]);

  useEffect(() => {
    loadVessels();
  }, []);

  useEffect(() => {
    if (!customData.vessel && customData.customer) {
      const filteredVessels = vessels.filter(filterVesselsByCustomer);
      const vesselToSelect = filteredVessels.length ? filteredVessels[0] : null;
      if (vesselToSelect) {
        setSelectedVessel(vesselToSelect);
        updateData("vessel", vesselToSelect.id);
      }
    }
  }, [customData.customer]);

  const filteredVessels = useMemo(() => {
    if (!customData.customer) return vessels;
    return vessels.filter((v) => v?.customer?.id === customData.customer);
  }, [customData.customer, vessels]);

  const handleSelect = (v) => {
    setSelectedVessel(vessels.find((vessel) => vessel.id === v));
    updateData("vessel", v);
  };

  const handleEdit = () => {
    setLastSelectedVessel(vessel);
    setSelectedVessel(null);
    updateData("vessel", null);
  };

  const handleCancel = () => {
    setSelectedVessel(lastSelectedVessel);
    setLastSelectedVessel(undefined);
    updateData("vessel", lastSelectedVessel.id);
  };

  const handleNewVessel = (vessel) => {
    loadVessels().finally();
    setSelectedVessel(vessel);
    updateData("vessel", vessel.id);
  };

  const handleOpenNewVesselModal = useCallback(
    () => setModal(true),
    [setModal]
  );

  const filterVesselsByCustomer = (v) =>
    !customData.customer
      ? true
      : v?.customer?.id === customData.customer || vessel?.id === v?.id;

  const InfoOverlay = () => {
    const { id, name, length, vessel_type } = vessel;
    const c = useSelector(getConstants);

    const vesselDescription = useMemo(() => {
      return `${length}” ${c.vessel_types[vessel_type]}`;
    }, [length, vessel_type, c]);

    const handleShowVesselDetails = (e) => {
      e.preventDefault();
      if (Context.jobs.length > 0) {
        showSaveModal(true);
        Context.setJActionType({ type: "vessel_details", vesselId: id });
      } else {
        history.push({
          pathname: `/vessels/vessel/${id}`,
          state: { fromWO: true },
        });
      }
    };

    return (
      <>
        <Flex flexDirection="column">
          <Flex flexDirection="column" margin={isXs ? "12px 0 24px" : "0"}>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="end"
            >
              <Msg color="#494949" fontSize="18px" fontWeight={700}>
                {name}
              </Msg>
              {location.pathname === routes.workOrder.CreateWorkOrder && (
                <Msg
                  fontSize={16}
                  fontWeight={700}
                  onClick={handleEdit}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#0496FF",
                  }}
                >
                  Change
                </Msg>
              )}
            </Flex>
            <Msg>{vesselDescription}</Msg>
          </Flex>

          {!isXs && (
            <MapWrap style={{ height: "180px", margin: "8px 0" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
                defaultCenter={mapsOptions.center}
                defaultZoom={mapsOptions.zoom}
                mapTypeId="satellite"
                options={mapsOptions}
              >
                {vessel?.address?.lat != null &&
                  vessel?.address?.lng != null && (
                    <Marker
                      lat={vessel?.address?.lat || 0}
                      lng={vessel?.address?.lng || 0}
                    />
                  )}
                {workOrder?.current_address?.lat != null &&
                  workOrder?.current_address?.lng != null && (
                    <Marker
                      lat={workOrder?.current_address?.lat || 0}
                      lng={workOrder?.current_address?.lng || 0}
                    />
                  )}
              </GoogleMapReact>
            </MapWrap>
          )}
        </Flex>
        <StyledButton onClick={handleShowVesselDetails}>
          View vessel details
        </StyledButton>
      </>
    );
  };

  return (
    <SelectCard
      marginBottom={isLg ? "16px" : "0px"}
      style={{ alignSelf: "normal" }}
    >
      <VesselsModal
        visible={modal}
        onClose={() => setModal(false)}
        onSuccess={handleNewVessel}
      />
      <SelectCardTitle>Vessel</SelectCardTitle>
      {vessel ? (
        <InfoOverlay />
      ) : (
        <Form form={form}>
          <Form.Item name="vessel">
            {!!vessels && (
              <AutocompleteSelect
                options={vessels}
                handleSelect={handleSelect}
                fieldName="name"
                placeholder="Select a Vessel"
                footer={
                  <AutocompleteAddButton
                    onClick={handleOpenNewVesselModal}
                    text="Add new vessel"
                  />
                }
              />
            )}
          </Form.Item>
        </Form>
      )}
      {lastSelectedVessel && !vessel && (
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
      )}
    </SelectCard>
  );
};
