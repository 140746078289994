import React, { useMemo, useState } from "react";
import { Api } from "../../api/api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { TStatusView } from "./types";
import Flex from "../../noui/Flex";
import { PrimaryButton } from "../../ui/Button";
import { TArgCallback } from "../../types";
import styled from "styled-components";
import { Columns, PALETTE } from "../../constants";
import { CardIcon, ListIcon } from "../../components/Icons";
import { ColumnsSelector } from "../../modals/ColumnsSelector/ColumnsSelector";
import { ColumnsButton } from "../../components/ColumnsButton";
import { Button, Select } from "antd";
import { JobTableColumns } from "./utils";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getConstant } from "../../config/reducers/user/selectors";
import { mapConstants, downloadFile } from "../../utils/utils";
import { getJobFilters } from "../../config/reducers/filters/selectors";
import { setJobFilter } from "../../config/reducers/filters/actions";
import { getColumns } from "../../config/reducers/columns/selectors";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { useTechnicianRole, useLoading } from "../../utils/hooks";
import AddCustomerImg from "../../assets/images/addCustomer.svg";
import AddCustomerBlueImg from "../../assets/images/addCustomerBlue.svg";
import routes from "../../routes/routes";

const Filter = styled(Flex)``;
const Toggle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 66px;
  height: 34px;
  border: 1px solid ${PALETTE.TextDisabled};
  border-radius: 4px;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 34px;
    background: ${PALETTE.TextDisabled};
  }
  svg {
    min-width: 15px;
  }
`;
const ToggleButton = styled.span<{ selected?: boolean }>`
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.8;
  }
  svg {
    color: ${({ selected }) =>
      selected ? PALETTE.Secondary.Medium : PALETTE.TextDisabled};
    transition: color 0.2s;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;
  .ant-btn {
    height: 34px;
    border-radius: 4px;
  }
  .ant-select-selector {
    height: 34px !important;
    border-radius: 4px !important;
    min-width: 150px;
  }
  ${down("xs")} {
    justify-content: space-between;
  }
`;
export const ExportBtn = styled(PrimaryButton)`
  width: 92px;
  height: 34px;
  width: 94px;
  background: #f0f1f6;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  & button {
    width: 94px;
  }
`;
export const MobileAdd = styled(Button)`
  margin: 0;
  height: 34px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin-left: 10px;
  }
`;
type TOption = {
  icon: React.ReactNode;
  id: TStatusView;
};

const options: TOption[] = [
  { id: "table", icon: <ListIcon /> },
  { id: "board", icon: <CardIcon /> },
];

const ViewToggle: React.FC<TProps> = ({ view, onChange }) => {
  return (
    <Toggle>
      {options.map((o) => (
        <ToggleButton
          key={o.id}
          onClick={() => onChange(o.id)}
          selected={o.id === view}
        >
          {o.icon}
        </ToggleButton>
      ))}
    </Toggle>
  );
};

type TProps = {
  view: TStatusView;
  onChange: TArgCallback<TStatusView>;
  sorter?: any;
};
export const VesselFilters: React.FC<TProps> = ({ view, onChange, sorter }) => {
  const [visible, setVisible] = useState(false);
  const constants = useSelector(getConstant("job_statuses"));
  const filters = useSelector(getJobFilters);
  const filtered = useSelector(getColumns(Columns.JobStatus));
  const dispatch = useDispatch();
  const [saving, onSave] = useLoading();
  const isXs = useBreakpoint(down("xs"));
  const technicianRole = useTechnicianRole();
  const history = useHistory();

  const options = useMemo(() => {
    return mapConstants(constants);
  }, [constants]);

  const handleSelectStatus = (value: string) => {
    dispatch(setJobFilter({ status: value }));
  };

  const handleExport = async () => {
    let filteredData = [...filtered.filter((item) => item !== "actions")];
    filteredData = filteredData.map((item) => {
      if (item === "customer") {
        return "customer_data";
      }
      if (item === "status") {
        return "progress";
      }
      return item;
    });

    const fields = { ...filters, ...sorter, fields: filteredData };

    const { data } = await onSave(Api.jobs.download(fields));
    downloadFile(data, `Jobs ${moment().format("LLL")}.xlsx`);
  };

  const handleCreateJob = () => {
    history.push(routes.workOrder.CreateWorkOrder);
  };
  const tableColumns = technicianRole
    ? JobTableColumns.filter((item) => item.title !== "Total")
    : JobTableColumns;

  return (
    <Filter justifyContent="space-between">
      <div>
        <Wrapper>
          {/* {!isXs && <ViewToggle view={view} onChange={onChange} />} */}
          <ColumnsButton onClick={() => setVisible(true)} />
          <Select
            value={filters?.status || ""}
            onSelect={handleSelectStatus}
            placeholder={"All Jobs"}
          >
            <Select.Option value={""}>All Jobs</Select.Option>
            {options.map((option) => (
              <Select.Option value={option.value} key={option.key}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Wrapper>
        <ColumnsSelector
          name={Columns.JobStatus}
          columns={tableColumns}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      </div>
      <Flex justifyContent="space-between">
        <ExportBtn loading={saving} onClick={handleExport} mr="20px">
          Export
        </ExportBtn>
        <MobileAdd type="primary" onClick={handleCreateJob}>
          Create New Project
          <img
            src={onmouseenter ? AddCustomerBlueImg : AddCustomerImg}
            alt="icon"
          />
        </MobileAdd>
      </Flex>
    </Filter>
  );
};
