import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api, TApproveRecurring } from "../../../api/api";
import {
  ICompany,
  ISetPlan,
  IUpdateCard,
  IUpdatePlan,
} from "../../../api/types";

export const fetchSetPlan = createAsyncThunk<ISetPlan, ISetPlan>(
  "payment/fetchSetPlan",
  async (data, thunkAPI) => {
    try {
      const response = await Api.company.setPlan(data);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        e.response.data.non_field_error[0].split(":")[1]
      );
    }
  }
);
export const fetchUpdatePlan = createAsyncThunk<ICompany, IUpdatePlan>(
  "payment/fetchUpdatePlan",
  async (data, thunkAPI) => {
    try {
      const response = await Api.company.updatePlan(data);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue("Something went wrong!");
    }
  }
);
export const fetchUpdateCard = createAsyncThunk<IUpdateCard, IUpdateCard>(
  "payment/fetchUpdatePlan",
  async (data, thunkAPI) => {
    try {
      const response = await Api.company.updateCard(data);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue("Something went wrong!");
    }
  }
);
export const fetchApproveSubscriptions = createAsyncThunk<
  any,
  TApproveRecurring
>("payment/fetchUpdatePlan", async (data, thunkAPI) => {
  try {
    const response = await Api.workOrders.approveSubscriptions(data);
    console.log(response);
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
export const fetchUpdateCardForSubscriptions = createAsyncThunk<
  any,
  TApproveRecurring
>("payment/fetchUpdatePlan", async (data, thunkAPI) => {
  try {
    const response = await Api.workOrders.updateCardForSubscriptions(data);
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue("Something went wrong!");
  }
});
export const fetchValidatePromoCode = createAsyncThunk<
    string,
    string
    >("payment/fetchValidatePromoCode", async (coupon, thunkAPI) => {
    try {
        const response = await Api.company.validateCoupon(coupon);
        console.log(response.data)
        return  response.data.data[0];
    } catch (e) {
        return thunkAPI.rejectWithValue("Invalid promo code");
    }
});
