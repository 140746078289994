import React, { useCallback, useEffect, useState, createRef } from "react"
import { Checkbox, Form, Input, Modal, notification, Select, Tabs } from "antd"
import { jsPDF } from "jspdf"
import { Api } from "../../api/api"
import styled from "styled-components"
import { getErrors, getReplacedTemplateMessage, mapOptions, renderAddress, renderFormDataSend } from "../../utils/utils"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import { MessagesTemplateModal } from "../../containers/messages/MessagesTemplateModal"
import routes from "../../routes/routes"
import { useHistory } from "react-router-dom"
import { TCallback, TOption } from "../../types"
import {
   ETemplateActions,
   ICreateEstimate,
   IJob,
   IMessageTemplate,
   ITemplateSettings,
   IWorkOrderWithJobs,
   IWorkOrder,
   ESendType,
} from "../../api/types"
import { useLoading } from "../../utils/hooks"
import { checkIsPaymentsActive, EstimateConfig } from "./EstimateConfig"
import { useSelector } from "react-redux"
import { getConstant, getCurrentUser } from "../../config/reducers/user/selectors"
import { SwitchButton } from "../../ui/SwitchButton"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import PdfJob from "./pdfContent/PdfJob"
import { TextEditor } from "../../components/TextEditor/TextEditor"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { CheckboxTab, StyledCBTabs, TextMessagingWarning } from "../../modals/Messages/CheckboxTab"
import logo from "../../assets/images/dockworks_logo.png"

const StyledSwitchButtons = styled(SwitchButton)`
   border-radius: 8px;
   width: 100px;
   justify-content: start;
   .ant-checkbox {
      margin-right: 16px;
   }
   &.ant-checkbox-wrapper-disabled {
      span {
         color: #ccc;
      }
   }
   ${down("xs")} {
      width: 48%;
   }
`

const Title = styled.h2`
   font-weight: 900;
   font-size: 15px;
   margin-bottom: 10px;
   line-height: 20px;
   text-transform: uppercase;
`

const ModalWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: stretch;
   ${down("xs")} {
      flex-direction: column;
   }
`
const StyledForm = styled(Form)`
   .template-item label {
      width: 100%;
   }
`
const FormContainer = styled.div`
   padding: 16px;
   width: calc(50% + 20px);
   ${down("xs")} {
      width: 100%;
      .ant-checkbox-group {
         width: 100%;
         display: flex;
         justify-content: space-between;
      }
   }
`

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 0;
   }
`

const Label = styled.h4`
   font-weight: bold;
   font-size: 13px;
   line-height: 18px;
`

const LinkButton = styled(EmptyButton)`
   > span {
      text-decoration: underline;
   }
`
const HiddenPdfDoc = styled.div`
   position: absolute;
   top: 0px;
   left: 200px;
   width: 595px;
   height: 0;
   overflow: hidden;
`
const { TextArea } = Input

type TProps = {
   visible: boolean
   onClose: TCallback
   workOrderNumber?: number
   schedule?: number
   workOrder?: IWorkOrderWithJobs | IWorkOrder | null
   job?: any
}

export const SendScheduleModal: React.FC<TProps> = ({
   visible,
   onClose,
   workOrderNumber,
   schedule,
   workOrder,
   job,
}) => {
   const [templates, setTemplates] = useState<{ data: IMessageTemplate[]; mapped: TOption[] }>({ data: [], mapped: [] })
   const [form] = Form.useForm<ICreateEstimate>()
   const [messagesTemplateVisible, setMessagesTemplateVisible] = useState<boolean>(false)
   const history = useHistory()
   const [loading, onCall] = useLoading()
   const tags = useSelector(getConstant("template_keys"))
   const user = useSelector(getCurrentUser)
   const isXs = useBreakpoint(down("xs"))
   const [templateSettings, setTemplateSettings] = useState<ITemplateSettings[]>([])
   const [messageContent, setMessageContent] = useState<any>()
   const [messageContentHtml, setMessageContentHtml] = useState<any>()
   const [currentTab, setCurrentTab] = useState<string>(ESendType.E)
   const [sendType, setSendType] = useState([ESendType.E])
   const componentPrintRef = createRef<HTMLDivElement>()

   useEffect(() => {
      if (visible) {
         form.resetFields()
      }
   }, [visible, form])

   const loadTemplates = useCallback(() => {
      if (!templates.data.length) {
         onCall(
            Api.templates.getAll({ ordering: "title" }).then(({ data: dt }) => {
               const data = dt as IMessageTemplate[]
               setTemplates({ data, mapped: mapOptions(data, "title") })
            })
         ).finally()
      }
   }, [templates, onCall])

   useEffect(() => {
      loadTemplates()
   }, [loadTemplates])

   const handleCloseManageTemplate = () => {
      loadTemplates()
      setMessagesTemplateVisible(false)
   }

   const handleChangeTemplate = (templateId: string) => {
      const template = templates.data.find((t) => t.id === Number(templateId))
      if (template && user) {
         const message = getReplacedTemplateMessage(template.content, tags, user, workOrder?.customer, job, workOrder)
         const subject = getReplacedTemplateMessage(template.subject, tags, user, workOrder?.customer, job, workOrder)
         setMessageContent(message)
         form.setFieldsValue({ content: message, subject })
      }
   }

   const updateMessage = (value: string) => {
      setMessageContentHtml(value)
   }

   useEffect(() => {
      form.setFieldsValue({ content: messageContentHtml })
   }, [messageContentHtml])

   const loadTemplateSettings = async () => {
      const { data } = await Api.company.getTemplateSettings()
      setTemplateSettings(data)
   }

   const fillDefaultTemplate = () => {
      const actionType = ETemplateActions.ApproveAppointment
      const templateID = templateSettings.find((t) => t.action === actionType)?.template
      const template = templates.data.find((t) => t.id === templateID)
      if (templateID && template && user) {
         const message = getReplacedTemplateMessage(template.content, tags, user, workOrder?.customer, job, workOrder)
         const subject = getReplacedTemplateMessage(template.subject, tags, user, workOrder?.customer, job, workOrder)
         const sms_content = getReplacedTemplateMessage(
            template.sms_content,
            tags,
            user,
            workOrder?.customer,
            job,
            workOrder
         )
         form.setFieldsValue({ template: String(templateID) })
         setMessageContent(message)
         form.setFieldsValue({ content: message, subject, sms_content })
      }
   }

   useEffect(() => {
      loadTemplateSettings()
   }, [])

   useEffect(() => {
      const template = form.getFieldValue("template")
      if (visible && templateSettings.length && templates.data.length && !template) {
         fillDefaultTemplate()
      }
   }, [visible, user, templates, templateSettings])

   const formatData = (fields: ICreateEstimate) => {
      const content = form.getFieldValue("content")
      const sms_content = form.getFieldValue("sms_content")
      const subject = form.getFieldValue("subject")
      return {
         ...fields,
         content,
         subject,
         ...(sendType.includes(ESendType.S) && {sms_content}),
         send_type: sendType,
         template: +fields.template,
         id: workOrderNumber || 0,
         schedule: schedule || 0,
      }
   }

   const sendEstimate = async (sendSettings: any) => {
      if (workOrderNumber) {
         const fields = await form.validateFields()
         const formattedData = formatData(fields)
         if (sendType.includes(ESendType.S) && formattedData?.sms_content?.length && formattedData.sms_content.length > 120) {
            setCurrentTab(ESendType.S)
         }
         try {
            const data = await renderFormDataSend({ ...formattedData, ...sendSettings }, generatePdfDocument)
            await Api.workOrders.sendSchedule(data)
            form.resetFields()
            onClose()
            history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(workOrderNumber)))
         } catch (e) {
            const errors = getErrors(e)
            const nonFieldError = errors[0].errors[0]
            if (nonFieldError) {
               notification.error({ message: nonFieldError })
            }
            form.setFields(errors)
         }
      }
   }

   const generatePdfDocument = async () => {
      const string = componentPrintRef.current
      const doc = new jsPDF("p", "px", [595, 842])
      let blob: any = ""

      if (string) {
         await doc.html(string, {
            callback: async (pdf) => {
               //pdf.setFontSize(10)
               let totalPages = pdf.internal.pages.length
               renderHeader(doc, string)
               for (let i = totalPages; i >= 0; i--) {
                  renderHeader(doc, string)
                  pdf.setPage(i)
               }
               blob = await pdf.output("blob")

               //pdf.output("dataurlnewwindow")
               return blob
            },
            margin: [115, 20, 25, 20],
            autoPaging: "text",
         })
      }

      return blob
   }

   const renderHeader = async (doc: any, string: any) => {
      const img = string.querySelector(".pdfLogo") as HTMLElement
      const headerLogo = user?.company?.logo ? user.company.logo.replace(/^http:\/\//i, "https://") : logo
      const width = user?.company?.logo ? img.clientWidth : 60
      const height = user?.company?.logo ? img.clientHeight : 60

      const addressCompany = user?.company?.address ? renderAddress(user?.company?.address) : "-"
      const addressCustomer = workOrder?.customer?.address ? renderAddress(workOrder?.customer?.address) : "-"

      doc.addImage(headerLogo, "JPEG", 20, 20, width, height)

      doc.setFont("helvetica", "normal")
      doc.setTextColor("#202020")
      doc.text("COMPANY", 200, 35)
      doc.setFont("helvetica", "bold")
      doc.text(user?.company?.name, 200, 55, {
         maxWidth: 170,
      })
      doc.setFont("helvetica", "normal")
      doc.text(addressCompany, 200, 70, {
         maxWidth: 170,
         charSpace: 0,
      })
      doc.text(user?.company?.phone, 200, addressCompany.length > 35 ? 95 : 80)
      doc.setTextColor("#40a9ff")
      doc.text(user?.company?.email, 200, addressCompany.length > 35 ? 110 : 95, {
         maxWidth: 170,
      })

      doc.setTextColor("#202020")
      doc.text("BILLING CONTACT", 400, 35)
      doc.setFont("helvetica", "bold")
      doc.text(workOrder?.customer?.full_name, 400, 55, {
         maxWidth: 170,
      })
      doc.setFont("helvetica", "normal")
      doc.text(addressCustomer, 400, 70, {
         maxWidth: 170,
         charSpace: 0,
      })
      doc.text(
         workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")?.phone || "No data",
         400,
         addressCustomer.length > 35 ? 95 : 80
      )
      doc.setTextColor("#40a9ff")
      doc.text(workOrder?.customer?.email, 400, addressCustomer.length > 35 ? 110 : 95)
   }

   const updateTemplates = (data: IMessageTemplate[]) => {
      if (data && data.length) {
         setTemplates({ data, mapped: mapOptions(data, "title") })
      }
   }

   const setTab = (key: string) => {
      setCurrentTab(key)
   }

   const handleTabCheck = (e: CheckboxChangeEvent, key: ESendType) => {
      if (e.target.checked) {
         setSendType((prev) => [...prev, key])
         return
      }
      setSendType((prev) => prev.filter((i) => i !== key))
   }

   const renderTabContent = () => {
      return (
         <Flex flexDirection="column" height="500px">
            <Form.Item
               name="template"
               className="fullWidthLabel"
               label={
                  <Flex justifyContent="space-between" width={"100%"}>
                     <Label>Template</Label>
                     <EmptyButton
                        fontSize="14px"
                        style={{ lineHeight: "18px" }}
                        fontWeight="700"
                        color="#109CF1"
                        width="auto"
                        onClick={() => setMessagesTemplateVisible(true)}
                     >
                        Manage list
                     </EmptyButton>
                  </Flex>
               }
               style={{ flexDirection: isXs ? "row" : "column" }}
            >
               <Select
                  onChange={handleChangeTemplate}
                  options={templates.mapped}
                  placeholder="Select a template"
                  getPopupContainer={(trigger: any) => trigger.parentElement}
               />
            </Form.Item>
            {currentTab !== ESendType.S && (
               <Form.Item name="subject" label="Subject" style={{ flexDirection: isXs ? "row" : "column" }}>
                  <Input placeholder="Type here" />
               </Form.Item>
            )}
            {currentTab === ESendType.E ? (
               <>
                  <Label style={{ marginBottom: "0px" }}>Message text</Label>
                  <Form.Item name="content" style={{ flexDirection: isXs ? "row" : "column" }}>
                     <TextEditor initialValue={messageContent} onChange={updateMessage} />
                  </Form.Item>
               </>
            ) : (
               <Form.Item name="sms_content" label="Message text" style={{ flexDirection: isXs ? "row" : "column" }}>
                  <Input.TextArea
                     rows={isXs ? 2 : 5}
                     placeholder="Type here"
                     showCount={{ formatter: ({ count, maxLength }) => `${count}/${maxLength} characters` }}
                     maxLength={120}
                  />
               </Form.Item>
            )}
         </Flex>
      )
   }

   return (
      <StyledModal
         footer={null}
         width={isXs ? "100%" : 660}
         visible={visible}
         onCancel={onClose}
         onOk={sendEstimate}
         destroyOnClose
      >
         <StyledForm
            labelCol={{ style: { fontSize: 13, fontWeight: "bold" } }}
            requiredMark={false}
            layout="vertical"
            form={form}
            onFinish={sendEstimate}
         >
            <ModalWrapper>
               <FormContainer>
                  <Title>Send Schedule</Title>
                  <StyledCBTabs
                     onChange={setTab}
                     type="card"
                     activeKey={currentTab}
                     tabBarExtraContent={!user?.company?.messaging_status && <TextMessagingWarning onClose={onClose} />}
                  >
                     <Tabs.TabPane
                        tab={
                           <CheckboxTab
                              checked={sendType.includes(ESendType.E)}
                              onChange={(e) => handleTabCheck(e, ESendType.E)}
                              title="Email"
                           />
                        }
                        key={ESendType.E}
                     >
                        {renderTabContent()}
                     </Tabs.TabPane>
                     <Tabs.TabPane
                        tab={
                           <CheckboxTab
                              checked={sendType.includes(ESendType.S)}
                              disabled={!user?.company?.messaging_status}
                              onChange={(e) => handleTabCheck(e, ESendType.S)}
                              title="SMS"
                           />
                        }
                        disabled={!user?.company?.messaging_status}
                        key={ESendType.S}
                     >
                        {renderTabContent()}
                     </Tabs.TabPane>
                  </StyledCBTabs>
               </FormContainer>
               <EstimateConfig
                  onOk={sendEstimate}
                  saving={loading}
                  onClose={onClose}
                  isRecurring={false}
                  workOrderNumber={workOrderNumber}
               />
            </ModalWrapper>
         </StyledForm>
         <MessagesTemplateModal
            visible={messagesTemplateVisible}
            onClose={handleCloseManageTemplate}
            updateTemplates={updateTemplates}
         />

         <HiddenPdfDoc>
            <PdfJob ref={componentPrintRef} workOrder={workOrder} job={job} technicianRole={false} isInvoice={false} />
         </HiddenPdfDoc>
      </StyledModal>
   )
}
