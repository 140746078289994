import React, {useEffect, useState} from 'react';
import {Api} from "../../../api/api";
import {mapOptions} from "../../../utils/utils";
import classes from "../styles.module.scss";
import {Form, FormInstance, Select} from "antd";
import {AddButton} from "../AddButton";
import {VesselsModal} from "../../../containers/vessels/VesselsModal";
import {DataContainer, EditButton, MoreButton, Title} from "../components";
import routes from "../../../routes/routes";
import {useHistory} from "react-router-dom";
import {IPaginatedResponse, IVessel} from "../../../api/types";
import {TArgCallback, TCallback, TOption} from "../../../types";



type TVesselDisplayProps = {
  vessel?: IVessel;
  onEdit: TCallback;
  disabled: boolean;
}
export const VesselDisplay: React.FC<TVesselDisplayProps> = ({vessel}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(routes.customers.VesselPage.replace(":id", String(vessel?.id ?? 0)))
  }

  return <DataContainer>
    <Title>{vessel?.name}, {vessel?.year}</Title>
    <p>{vessel?.hailing_port}</p>
    <span>{vessel?.hin}</span>
    <MoreButton type="link" onClick={handleClick}>Open vessel page</MoreButton>
  </DataContainer>;
}


type TEditProps = {
  visible: boolean;
  options: TOption[];
  onSearch: TArgCallback<string>;
  onSuccess: TArgCallback<IVessel>;
  onClose: TCallback;
  onOpen: TCallback;
}
export const VesselEdit: React.FC<TEditProps> = ({visible, options, onSearch, onSuccess, onClose, onOpen}) => {
  return <>
    <Form.Item name="vessel" style={{marginTop: 24}} rules={[{required: true, message: "Vessel is required"}]}>
      <Select
        showSearch
        placeholder="Select a Vessel"
        filterOption={false}
        options={options}
        onSearch={onSearch}
        notFoundContent={null}
      />
    </Form.Item>
    <AddButton onClick={onOpen}>Add new Vessel</AddButton>
    <VesselsModal visible={visible} onClose={onClose} onSuccess={onSuccess}/>
  </>
}

type TProps = {
  form: FormInstance;
  vessel?: IVessel;
  disabled: boolean;
}
export const VesselSelector: React.FC<TProps> = ({form, vessel, disabled}) => {
  const [options, setOptions] = useState<TOption[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  useEffect(() => {
    Api.vessels.getAll({
      page: 1, pageSize: 5
    })
      .then(({data}) => {
        const dt = data as IPaginatedResponse<IVessel[]>;
        let options = mapOptions(dt.results, getVesselName);
        if (vessel) {
          if (!options.find(el => el.value.toString() === vessel.id.toString())) {
            options = [...mapOptions([vessel], getVesselName), ...options];
          }
        }
        setOptions(options);
      })
  }, [vessel]);

  const getVesselName = (vessel: IVessel) => `${vessel.name} - ${vessel.hin}`;
  const handleSearch = (search: string) => {
    Api.vessels.getAll({
      page: 1, pageSize: 5, search
    })
      .then(({data}) => {
        const dt = data as IPaginatedResponse<IVessel[]>
        setOptions(mapOptions(dt.results, getVesselName))
      })
  }
  const handleSuccess = (vessel: IVessel) => {
    form.setFieldsValue({vessel: vessel.id});
    setOptions([...options, ...mapOptions([vessel], getVesselName)])
  }

  const handleToggle = () => {
    setEdit(e => {
      if (e && form.getFieldValue("vessel") !== vessel?.id.toString()) {
        form.setFieldsValue({vessel: vessel?.id.toString()});
      }
      return !e;
    });
  }

  return <div className={classes.vessels}>
    <h4 className={classes.title}>Vessel</h4>
    {!disabled
      ? <EditButton onClick={handleToggle} type="link">
        {edit ? "Cancel" : "Change vessel"}
      </EditButton>
      : null}
    {!vessel || edit
      ? <VesselEdit
        options={options}
        onSearch={handleSearch}
        onClose={() => setVisible(false)}
        onOpen={() => setVisible(true)}
        onSuccess={handleSuccess}
        visible={visible}
      />
      : <VesselDisplay
        vessel={vessel}
        onEdit={() => setEdit(true)}
        disabled={disabled}
      />
    }
  </div>;
};