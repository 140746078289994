import styled from "styled-components"
import { IConstant, ITelgorithmBrand, ITelgorithmBrandResponse } from "../../api/types"
import { TMFieldInfo } from "../../assets/icons";
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text";
import { Button, Form, Input, Modal, Select, Tooltip } from "antd";
import { TMDelete, TMEdit, TMNumberActive, TMNumberPending, TMPhone, TMWarning } from "../../assets/icons/textMessaging";
import moment from "moment";
import { useAppSelector } from "../../config/hooks";
import { getConstants } from "../../config/reducers/user/selectors";
import { useEffect, useState } from "react";
import { FormItem, FormRow } from "./FormItem";
import { StyledPhoneNumberSmall } from "../customers/pages/styled";
import { formatPhoneNumber } from "../../utils/utils";

const Container = styled(Flex)`
    width: 100%;
    max-width: 930px;
    margin: 0 auto;
    flex-direction: column;
    padding: 32px 0;
`;

const Block = styled(Flex)`
    width: 100%;
    flex-direction: column;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 16px;
    margin-top: 32px;
    &:first-child {
        margin-top: 0;
    }
`

const PhoneRow = styled(Flex)`
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 24px;
    align-items: center;
    margin: 16px 0;
`

const BlockRowItem = styled(Flex)`
    flex: 1;
    >span:first-child {
        width: 180px;
    }
`

const Title = styled(Msg)`
    font-weight: 900;
    font-size: 15px;
    text-transform: uppercase;
    color: #202020;
    margin-right: 12px;
`

const EditButton = styled(Button)`
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #40A9FF;
`

const ResubmitButton = styled(Button)`
    border-radius: 4px;
    color: #FFFFFF;
    margin-right: 8px;
    &:disabled {
        border-color: #ACE0FF;
        background: #ACE0FF;
        color: #FFFFFF !important;
    }
`

const DeleteButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 4px;
`

const DefaultText = styled(Msg)`
    font-size: 13px;
    font-weight: 400;
    color: #494949;
    margin-right: 4px;
`

const BoldText = styled(Msg)<{color?: string}>`
    font-size: 13px;
    font-weight: 700;
    color: ${props => !!props.color ? props.color : "#494949"};
`

const PhoneText = styled(Msg)`
    font-size: 15px;
    font-weight: 400;
    color: #494949;
    >a {
        text-decoration: underline;
        font-weight: 700;
    }
`

const NumberText = styled(Msg)`
    font-weight: 700;
    font-size: 18px;
    color: #0F497B;
    margin-left: 24px;
`

const WarningWrapper = styled.div`
    display: inline-block;
    margin-right: 4px;
    vertical-align: text-top;
`

const CompanyNameWrapper = styled.div`
    width: 100%;
    .ant-row.ant-form-item {
        width: 100%;
    }
`

type TModalProps = {
    visible: boolean
    onOk: () => void
    onCancel: () => void
}

type TEditBrandModalProps = {
    visible: boolean
    onOk: (data: any) => void
    onCancel: () => void
    sendData: any
}

const DeleteBrandModal: React.FC<TModalProps> = ({visible, onOk, onCancel}) => {
    return (
        <Modal
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            width={350}
            okText="Delete"
        >
            <Flex width="275px" flexDirection="column">
                <Flex width="100%" paddingTop="13px">
                    <Msg fontSize="15px" fontWeight={700}>
                        Are you sure you want to delete this number and remove SMS messaging from your account?
                    </Msg>
                </Flex>    
                <div style={{margin: "36px 0 16px"}}>
                    <WarningWrapper>
                        <TMWarning />
                    </WarningWrapper>
                    <Msg fontSize="15px" fontWeight={700}>WARNING: </Msg>
                    <Msg fontSize="15px" fontWeight={300}>Once you delete your number, it will be released for use and may be purchased by another business. Proceed with caution.</Msg>
                </div>  
            </Flex>                  
        </Modal>
    )
}

const CompanyNameEditModal: React.FC<TEditBrandModalProps> = ({visible, onOk, onCancel, sendData}) => {
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        const data = await form.getFieldsValue();
        onOk(data)
    }

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(sendData)
            console.log(sendData)
        }
    }, [visible])

    return (
        <Modal
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            okText="Save"
            title="Legal company name"
        >
            <Form 
                form={form}
                layout="vertical"
            >
                <CompanyNameWrapper>
                <FormItem label="Legal Company Name">
                    <Form.Item name="companyName">
                        <Input  />
                    </Form.Item>
                </FormItem>
                </CompanyNameWrapper>                
            </Form>   
        </Modal>
    )
}

const ContactInfoEditModal: React.FC<TEditBrandModalProps> = ({visible, onOk, onCancel, sendData}) => {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState("")

    const handleSubmit = async () => {
        const data = await form.getFieldsValue();
        onOk(data)
    }
    
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(sendData)
            console.log(sendData)
        }
    }, [visible])

    return (
        <Modal
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            width={700}
            okText="Save"
            title="contact details"
        >
            <Form 
                form={form}
                layout="vertical"
                style={{marginBottom: "16px"}}
            >
                <FormRow>
                    <FormItem label="Support Email Address*">
                        <Form.Item 
                            name="email"
                            rules={[
                                {
                                required: true,
                                message: "Please input email!",
                                },
                            ]}
                        >
                            <Input placeholder="Enter email"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Phone" tooltipPaddingLeft="60px">
                        <Form.Item 
                            name="phone"
                            rules={[
                                {
                                required: true,
                                message: "Please input phone!",
                                },
                            ]}
                        >
                            <StyledPhoneNumberSmall
                                international
                                defaultCountry="US"
                                name="phone"
                                value={phone}
                                onChange={(val) => setPhone(val)}
                                fullWidth
                            />
                        </Form.Item>
                    </FormItem>
                </FormRow>
            </Form>   
        </Modal>
    )
}

const BrandEditModal: React.FC<TEditBrandModalProps> = ({visible, onOk, onCancel, sendData}) => {
    const [form] = Form.useForm();
    const [businessType, setBusinessType] = useState("NONE")
    const [formType, setFormType] = useState("PUBLIC_PROFIT")
    const c = useAppSelector(getConstants)

    const getBrandStatus = () => {
        if (formType === 'PUBLIC_PROFIT') {
            return 'first'
        } else if (formType === 'SOLE_PROPRIETOR') {
            return 'third'
        }
        return 'second'
    }

    const handleSubmit = async () => {
        const data = await form.getFieldsValue();
        onOk(data)
    }

    const getOptions = (constant?: IConstant | null) => {
        if (!constant) return null;
        return Object.entries(constant).map(opt => (
            <Select.Option key={opt[0]} value={opt[0]}>{opt[1]}</Select.Option>
        ))
    }
    
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(sendData)
            setBusinessType(sendData?.altBusinessIdType ?? 'NONE')
            setFormType(sendData?.entityType ?? 'PUBLIC_PROFIT')
            console.log(sendData)
        }
    }, [visible])

    return (
        <Modal
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            width={700}
            okText="Save"
            title="company details"
        >
            <Form 
                form={form}
                layout="vertical"
                style={{marginBottom: "16px"}}
            >
                <FormRow>
                    <FormItem label="Company Name or DBA*" tooltip="Enter company name">
                        <Form.Item 
                            name="displayName" 
                            rules={[
                                {
                                required: true,
                                message: "Please input company name!",
                                },
                            ]}
                        >
                            <Input placeholder="Enter DBA or Brand Name" disabled={getBrandStatus() === 'third'}/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Vertical Type*">
                        <Form.Item 
                            name="vertical"
                            rules={[
                                {
                                required: true,
                                message: "Please select your industry vertical",
                                },
                            ]}
                        >
                            <Select placeholder="Select vertical type">
                                {getOptions(c?.verticals)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="What type of legal form is the organization?*">
                        <Form.Item 
                        name="entityType" 
                        initialValue="PUBLIC_PROFIT"
                        rules={[
                            {
                            required: true,
                            message: "Please select a legal entity type",
                            },
                        ]}
                        >
                            <Select 
                                placeholder="What type of legal form is the organization?" 
                                onChange={(val) => setFormType(val as string)}
                            >
                                {getOptions(c?.entity_types)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Country of Registration*" tooltip="Select country">
                        <Form.Item name="country" initialValue="US">
                            <Select 
                                placeholder="Enter country of registration*" 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getOptions(c?.countries)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Tax Number/DI/EIN*">
                        <Form.Item 
                            name="ein"
                            rules={[
                                {
                                required: true,
                                message: "Please enter EIN  !",
                                },
                            ]}
                        >
                            <Input placeholder="Enter Tax Number/DI/EIN" disabled={getBrandStatus() === 'third'}/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Tax Number/ID/EIN Issuing Country" tooltip="Enter Country">
                        <Form.Item name="einIssuingCountry">
                            <Select 
                                placeholder="Enter Tax Number/ID/EIN issuing country" 
                                disabled={getBrandStatus() === 'third'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getOptions(c?.countries)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="DUNS or GIIN or LEI Number" tooltip="Select DUNS">
                        <Form.Item 
                        name="altBusinessIdType"
                        rules={[
                            {
                            required: true,
                            message: "Please select business id!",
                            },
                        ]}
                        >
                            <Select 
                                placeholder="Select DUNS or GIIN or LEI Number" 
                                disabled={getBrandStatus() === 'third'} 
                                onChange={(val) => setBusinessType(val as string)}
                            >
                                {getOptions(c?.alt_business_id_types)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="DUNS or GIIN or LEI Number" tooltip="Enter DUNS">
                        <Form.Item name="altBusinessId">
                            <Input placeholder="Enter DUNS or GIIN or LEI Number" disabled={getBrandStatus() === 'third' || businessType === 'NONE'}/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Address/Street*">
                        <Form.Item 
                            name="street"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a street address",
                                },
                            ]}
                        >
                            <Input placeholder="Enter address/street"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="City*">
                        <Form.Item 
                            name="city"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a city",
                                },
                            ]}
                        >
                            <Input placeholder="Enter city"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="State/Region*">
                        <Form.Item 
                            name="state"
                            rules={[
                                {
                                required: true,
                                message: "Please select a state/region",
                                },
                            ]}
                        >
                            <Input placeholder="Enter state/region"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Postal Code/ZIP Code*">
                        <Form.Item 
                            name="postalCode"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a zip code",
                                },
                            ]}
                        >
                            <Input placeholder="Enter Postal Code/ZIP Code"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Stock Symbol">
                        <Form.Item name="stockSymbol">
                            <Input 
                                placeholder="Enter stock symbol"
                                disabled={getBrandStatus() === 'second' || getBrandStatus() === 'third'}
                            />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Stock Exchange">
                        <Form.Item name="stockExchange">
                            <Select 
                                placeholder="Select stock exchange"
                                disabled={getBrandStatus() === 'second' || getBrandStatus() === 'third'}
                            >
                                {getOptions(c?.stock_exchanges)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Website">
                        <Form.Item 
                            name="website"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a company URL",
                                },
                            ]}
                        >
                            <Input placeholder="Enter website"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Reference ID">
                        <Form.Item name="referenceId">
                            <Input placeholder="Enter reference ID"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
            </Form>   
        </Modal>
    )
}

const NotificationModal: React.FC<TModalProps> = ({visible, onOk, onCancel}) => {
    return (
        <Modal
            visible={visible}
            width={350}
            onCancel={onCancel}
            footer={
                <Button type="primary" onClick={onOk}>
                    Continue
                </Button>
            }
        >
            <Flex width="270px" padding="16px 0">
                <Msg fontSize="15px" fontWeight={700}>
                    If you want to apply changes you need to click button “Resubmit Company” and waiting for approve!
                </Msg>
            </Flex>
        </Modal>
    )
}

type TTooltipProps = {
    text: string
}

const TooltipItem: React.FC<TTooltipProps> = ({text}) => {
    return (
        <Flex>
            <Tooltip color="#EDEDED" overlayInnerStyle={{color: '#494949'}} placement="bottom" title={text}>
                <Flex alignItems="center">
                    <TMFieldInfo/>
                </Flex>
            </Tooltip>
        </Flex>
    )
}

type TProps = {
    brand: ITelgorithmBrandResponse | null
    deleteBrand: () => void
    updateBrand: (data: any) => void
}

export const TextMessagingFinal: React.FC<TProps> = ({brand, deleteBrand, updateBrand}) => {
    const c = useAppSelector(getConstants)
    const [isDirty, setIsDirty] = useState(false)
    const [deleteBrandModalVisible, setDeleteBrandModalVisible] = useState(false)
    const [editCompanyNameVisible, setEditCompanyNameVisible] = useState(false)
    const [editContactInfoVisible, setEditContactInfoVisible] = useState(false)
    const [editBrandVisible, setEditBrandVisible] = useState(false)
    const [notificationVisible, setNotificationVisible] = useState(false)
    const [sendData, setSendData] = useState<any>(brand)

    const formatStatus = () => {
        if (brand?.identityStatus && brand.identityStatus === 'VERIFIED') {
            return <BoldText color="#42C77B">Verified</BoldText>
        }
        return <BoldText color="#FB4D4F">Unverified</BoldText>
    }

    const formatPhoneStatus = () => {
        // if active
        if (brand?.corporate_phone_status === 'Active') {
            return <>
                <TMNumberActive/>
                <DefaultText fontWeight={600} marginLeft="8px">Active</DefaultText>
            </>
        }
        return <>
            <TMNumberPending/>
            <DefaultText fontWeight={600} marginLeft="8px">Pending</DefaultText>
        </>
    }

    const formatCreateDate = () => {
        if (!brand?.createDate) {
            return null;
        }
        return <BoldText>{moment(brand.createDate).format('MM/DD/YYYY')}</BoldText>
    }

    const formatEntityType = () => {
        if (!brand?.entityType || !c?.entity_types) {
            return null;
        }
        return <BoldText>{c.entity_types[brand.entityType]}</BoldText>
    }

    const formatVerticals = () => {
        if (!brand?.vertical || !c?.verticals) {
            return null;
        }
        return <BoldText>{c.verticals[brand.vertical]}</BoldText>
    }

    const formatBusinessIdTitle = () => {
        if (brand?.altBusinessIdType === "GIIN") return "GIIN Number:"
        else if (brand?.altBusinessIdType === "LEI") return "LEI Number:"
        return "DUNS Number:"
    }

    const editBrand = (data: any) => {
        if (!isDirty) {
            setIsDirty(true)
        }
        setSendData((prev: any) => ({...prev, ...data}))
        setNotificationVisible(true)
    }

    const openDeleteBrandModal = () => {
        setDeleteBrandModalVisible(true)
    }

    const handleDeleteModalCancel = () => {
        setDeleteBrandModalVisible(false)
    }

    const handleDeleteModalOk = () => {
        setDeleteBrandModalVisible(false)
        deleteBrand()
    }

    const handleOpenEditCompanyName = () => {
        setEditCompanyNameVisible(true)
    }

    const handleOkEditCompanyName = (data: any) => {
        setEditCompanyNameVisible(false)
        editBrand(data)
    }

    const handleCancelEditCompanyName = () => {
        setEditCompanyNameVisible(false)
    }

    const handleOpenEditContactInfo = () => {
        setEditContactInfoVisible(true)
    }

    const handleOkEditContactInfo = (data: any) => {
        setEditContactInfoVisible(false)
        editBrand(data)
    }

    const handleCancelEditContactInfo = () => {
        setEditContactInfoVisible(false)
    }

    const handleOpenEditBrand = () => {
        setEditBrandVisible(true)
    }

    const handleOkEditBrand = (data: any) => {
        setEditBrandVisible(false)
        editBrand(data)
    }

    const handleCancelEditBrand = () => {
        setEditBrandVisible(false)
    }

    const handleCloseNotificationModal = () => {
        setNotificationVisible(false)
    }

    const hanleResubmit = () => {
        updateBrand(sendData)
        setIsDirty(false)
    }

    useEffect(() => {
        if (brand) {
            setSendData(brand)
        }
    }, [brand])

    return (
        <Container>
            <DeleteBrandModal 
                visible={deleteBrandModalVisible} 
                onOk={handleDeleteModalOk}
                onCancel={handleDeleteModalCancel}
            />
            <CompanyNameEditModal 
                visible={editCompanyNameVisible}
                onOk={handleOkEditCompanyName}
                onCancel={handleCancelEditCompanyName}
                sendData={sendData}
            />
            <ContactInfoEditModal 
                visible={editContactInfoVisible}
                onOk={handleOkEditContactInfo}
                onCancel={handleCancelEditContactInfo}
                sendData={sendData}
            />
            <BrandEditModal 
                visible={editBrandVisible}
                onOk={handleOkEditBrand}
                onCancel={handleCancelEditBrand}
                sendData={sendData}
            />
            <NotificationModal 
                visible={notificationVisible}
                onOk={handleCloseNotificationModal}
                onCancel={handleCloseNotificationModal}
            />
            <Block>
                <Title>BUSINESS NUMBERS</Title>
                <PhoneRow>
                    <Flex flex="1" alignItems="center">
                        <TMPhone />
                        <NumberText>
                            {formatPhoneNumber(brand?.corporate_phone)}
                        </NumberText>
                    </Flex>
                    <Flex flex="1" alignItems="center">
                        <Msg fontSize="15px" fontWeight="700">Status of number:</Msg>
                        <Flex alignItems="center" marginLeft="36px">
                            {formatPhoneStatus()}
                        </Flex>
                    </Flex>
                </PhoneRow>
                <PhoneText>
                    If you have an issue with your number or need to request a change, 
                    contact <a href="mailto:support@dockworks.co">support@dockworks.co</a>
                </PhoneText>
            </Block>
            <Block>
                <Flex justifyContent="space-between" width="100%">
                    <Flex alignItems="center">
                        <Flex alignItems="baseline">
                            <Title>Legal company name:</Title>
                            <Msg fontSize="18px" fontWeight={700} color="#202020">
                                {brand?.companyName}
                            </Msg>
                        </Flex>
                        <Flex marginLeft="20px">
                            <TooltipItem text="Legaly registered company name." />
                            <EditButton style={{marginLeft: "8px"}} onClick={handleOpenEditCompanyName}>
                                <TMEdit />
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Flex>
                        <ResubmitButton type="primary" disabled={!isDirty} onClick={hanleResubmit}>
                            Resubmit Company
                        </ResubmitButton>
                        <DeleteButton onClick={openDeleteBrandModal}>
                            <TMDelete />
                        </DeleteButton>
                    </Flex>
                </Flex>
                <Flex marginTop="32px">
                    <Flex flexDirection="column" flex="1">
                        <Flex>
                            <DefaultText>Company ID:</DefaultText>
                            <BoldText>{brand?.cspId}</BoldText>
                        </Flex>
                        <Flex>
                            <DefaultText>Universal EIN:</DefaultText>
                            <BoldText marginRight="8px">{`${brand?.einIssuingCountry}_${brand?.ein}`}</BoldText>
                            <TooltipItem text="A unique identifier generated by TCR and composed of the country code and the EIN/Tax Number" />
                        </Flex>
                    </Flex>
                    <Flex flexDirection="column" flex="1">
                        <Flex>
                            <DefaultText>Identity Status:</DefaultText>
                            <BoldText>{formatStatus()}</BoldText>
                        </Flex>
                        <Flex>
                            <DefaultText>Brand Relationship:</DefaultText>
                            <BoldText>Key accounts</BoldText>
                        </Flex>
                    </Flex>
                    <Flex flexDirection="column" flex="1">
                        <Flex>
                            <DefaultText>Tax Exempt Status:</DefaultText>
                            <BoldText>N/A</BoldText>
                        </Flex>
                        <Flex>
                            <DefaultText>Government Entity:</DefaultText>
                            <BoldText>N/A</BoldText>
                        </Flex>
                    </Flex>
                </Flex>
            </Block>
            <Block>
                <Flex alignItems="center">
                    <Title>company details</Title>
                    <EditButton style={{marginLeft: "8px"}} onClick={handleOpenEditBrand}>
                        <TMEdit />
                        Edit
                    </EditButton>
                </Flex>
                <Flex marginTop="12px">
                    <BlockRowItem>
                        <DefaultText>Company Name or DBA:</DefaultText>
                        <BoldText>{brand?.displayName}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Registred on:</DefaultText>
                        <BoldText>{formatCreateDate()}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>Entry Type:</DefaultText>
                        <BoldText>{formatEntityType()}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Address/Street:</DefaultText>
                        <BoldText>{brand?.street}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>EIN:</DefaultText>
                        <BoldText>{brand?.ein}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>City:</DefaultText>
                        <BoldText>{brand?.city}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>EIN Issuing Country:</DefaultText>
                        <BoldText>{brand?.einIssuingCountry}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>State:</DefaultText>
                        <BoldText>{brand?.state}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>{formatBusinessIdTitle()}</DefaultText>
                        <BoldText>{brand?.altBusinessId ?? "N/A"}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Postal Code:</DefaultText>
                        <BoldText>{brand?.postalCode}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>Vertical:</DefaultText>
                        <BoldText>{formatVerticals()}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Country:</DefaultText>
                        <BoldText>{brand?.country}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>Stock Symbol:</DefaultText>
                        <BoldText>{brand?.stockSymbol ?? "N/A"}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Website:</DefaultText>
                        <BoldText>{brand?.website}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>Stock Enchange:</DefaultText>
                        <BoldText>{brand?.stockExchange ?? "N/A"}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Reference ID:</DefaultText>
                        <BoldText>{"N/A"}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>First Name:</DefaultText>
                        <BoldText>{"N/A"}</BoldText>
                    </BlockRowItem>
                </Flex>
                <Flex marginTop="4px">
                    <BlockRowItem>
                        <DefaultText>Last Name:</DefaultText>
                        <BoldText>{"N/A"}</BoldText>
                    </BlockRowItem>
                </Flex>
            </Block>
            <Block>
                <Flex alignItems="center">
                    <Title>contact details</Title>
                    <EditButton style={{marginLeft: "8px"}} onClick={handleOpenEditContactInfo}>
                        <TMEdit />
                        Edit
                    </EditButton>
                </Flex>
                <Flex marginTop="12px">
                    <BlockRowItem>
                        <DefaultText>Support Email Address:</DefaultText>
                        <BoldText>{brand?.email}</BoldText>
                    </BlockRowItem>
                    <BlockRowItem>
                        <DefaultText>Support Phone Number:</DefaultText>
                        <BoldText>{brand?.phone}</BoldText>
                    </BlockRowItem>
                </Flex>
            </Block>
        </Container>
    )
}