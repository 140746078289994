import { RcFile } from "antd/lib/upload";
import { ImportDelete, ImportDocument, ImportUploaded } from "../../../assets/icons";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { convertFileSize } from "../../../utils/utils";
import { CloseButton, DraggerFile } from "./styled";

type TFileDraggerContentProps = {
    file: RcFile
    deleteFile: () => void
}

export const FileDraggerContent: React.FC<TFileDraggerContentProps> = ({ file, deleteFile }) => {
    const fileSize = convertFileSize(file.size, 2);
    const fileName = file.name;

    const handleButtonClick = (e: React.MouseEvent<HTMLElement> | undefined) => {
        e?.stopPropagation();
        deleteFile();
    };

    return (
        <DraggerFile>
            <ImportDocument />
            <Flex flexDirection="column" alignItems="start" marginLeft="12px">
                <Msg color="#494949" fontSize="18px" fontWeight={800}>{fileName}</Msg>
                <Flex>
                    <ImportUploaded />
                    <Msg color="#8B8B8B">{fileSize}</Msg>
                </Flex>
            </Flex>
            <CloseButton onClick={handleButtonClick}>
                <ImportDelete />
            </CloseButton>
        </DraggerFile>
    );
};
