import styled from "styled-components"
import { Button, Tabs } from "antd"
import React, { useState, useEffect } from "react"
import { Msg } from "../../../ui/Text"
import JobsListImg from "../../../assets/images/JobsList.svg"
import PlaceImg from "../../../assets/images/Place.svg"
import CalendarImg from "../../../assets/images/Calendar.svg"
import { EmptyButton } from "../../../ui/Button"
import CalendarView from "./CalendarView"
import MapView from "./MapView"
import { UpcomingJobs } from "./UpcomingJobs"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { TitleWrapper } from "../TitleWrapper"
import { ActionContainer } from "../ActionContainer"
import { useHistory } from "react-router-dom"
import routes from "../../../routes/routes"
import { IJob } from "../../../api/types"
import { Api } from "../../../api/api"
import { useTechnicianRole } from "../../../utils/hooks"

const JobsContainer = styled.div`
   position: relative;
   min-height: 473px;
   padding: 0;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   display: flex;
   flex-direction: column;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
   ${down("lg")} {
      grid-column-start: 1;
      grid-column-end: 3;
   }
`

const WidgetsButton = styled(EmptyButton)`
   width: 28px;
   height: 28px;
   line-height: 10px;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   & img {
      vertical-align: middle;
   }
`
const TBSContainer = styled.div`
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
   gap: 12px;
   text-align: right;
   ${down("xs")} {
      justify-content: center;
   }
`
const ScheduledText = styled.span`
   font-size: 13px;
   color: #109cf1;
   font-weight: 700;
   text-decoration: underline;
`

const TabsWrapper = styled.div`
   display: flex;
   .ant-tabs-nav-list {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      .ant-tabs-tab {
         margin: 0;
         padding: 0;
         &.last {
            margin-right: none;
         }
      }
   }
   .ant-tabs-ink-bar {
      display: none;
   }
   .ant-tabs-nav {
      margin: 0 !important;
      &::before {
         display: none;
      }
   }
   .ant-tabs-tab {
      img {
         opacity: 0.2;
         transition: opacity 0.2s;
      }
   }
   .ant-tabs-tab-active {
      img {
         transition: opacity 0.2s;
         opacity: 1;
      }
   }
   .ant-tabs {
      width: 100%;
   }
`

const tabs = [
   // { title: JobsListImg, component: UpcomingJobs },
   { title: PlaceImg, component: MapView },
   { title: CalendarImg, component: CalendarView },
]

type TMainWidgetContainerProps = {
   weather: any | null;
   isUpcoming?: boolean;
}

const MainWidgetContainer: React.FC<TMainWidgetContainerProps> = ({ weather, isUpcoming }) => {
   const [unscheduledJobs, setUnscheduledJobs] = useState<number>(0)
   const [activeTab, setActiveTab] = useState<string>(tabs[0].title);
   const isXS = useBreakpoint(down("xs"))
   const technicianRole = useTechnicianRole()

   const history = useHistory()

   const renderUnscheduledJobs = () => (
      <TBSContainer>
         {technicianRole ? (
            <ScheduledText>Jobs To-Be Scheduled:</ScheduledText>
         ) : (
            <ScheduledText
               style={{ cursor: "pointer" }}
               onClick={() => {
                  history.push(routes.dispatch.Base)
               }}
            >
               Jobs To-Be Scheduled:
            </ScheduledText>
         )}
         <Msg fontWeight={700} fontSize={20} color="#F84C4E" lineHeight="24px">
            {unscheduledJobs}
         </Msg>
      </TBSContainer>
   )

   const renderTabBar = (props: any, DefaultTabBar: React.ComponentType) => {
      const title = "UPCOMING";
      return (
         <TitleWrapper>
            <Msg fontWeight={900} fontSize={16} color="#151F2B" mr="11px">
               {isUpcoming
                 ? `${title} JOBS LIST`
                 : activeTab === tabs[0].title
                   ? `${title} JOBS MAP`
                   : `${title} WEATHER`}
            </Msg>
            <DefaultTabBar {...props} />
            {!isXS && renderUnscheduledJobs()}
         </TitleWrapper>
      )
   }

   useEffect(() => {
      Api.jobs.getAll({ unscheduled: true, is_approved: true }).then(({ data }) => {
         setUnscheduledJobs((data as IJob[]).length)
      })
   }, [])

   const handleCreateJob = () => {
      history.push(routes.workOrder.CreateWorkOrder)
   }

   return (
      <JobsContainer>
         <TabsWrapper>
            <Tabs renderTabBar={renderTabBar} activeKey={activeTab} onChange={(t) => setActiveTab(t)}>
               {isUpcoming ? <UpcomingJobs /> : tabs.map((t) => {
                  const C = t.component
                  return (
                     <Tabs.TabPane
                        key={t.title}
                        tab={
                           <WidgetsButton>
                              <img src={t.title} alt={t.title} />
                           </WidgetsButton>
                        }
                     >
                        <C weather={weather?.forecast?.daily} hourlyWeather={weather?.forecast?.hourly} />
                     </Tabs.TabPane>
                  )
               })}
            </Tabs>
         </TabsWrapper>
         {isXS ? (
            <>
               <ActionContainer>
                  {renderUnscheduledJobs()}
                  {!technicianRole && (
                     <Button type="primary" onClick={handleCreateJob}>
                        Create Work Order
                     </Button>
                  )}
               </ActionContainer>
            </>
         ) : null}
      </JobsContainer>
   )
}

export default MainWidgetContainer
