import { Progress, Dropdown } from "antd"
import React, { useState, useEffect, useCallback, useMemo } from "react"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { ProgressBar } from "../../../modals/ProgressBar"
import { EmptyButton } from "../../../ui/Button"
import SingleCard from "./SingleCard"
import MoreImg from "../../../assets/images/progressBar/more.svg"
import NextImg from "../../../assets/images/progressBar/next.svg"
import PrevImg from "../../../assets/images/progressBar/prev.svg"
import CreditCardImg from "../../../assets/images/progressBar/payment.svg"
import BookingImg from "../../../assets/images/progressBar/booking.svg"
import PriceImg from "../../../assets/images/progressBar/prices.svg"
import CustomerListImg from "../../../assets/images/progressBar/customerlist.svg"
import { useBreakpoints } from "../../../ui/Breakpoints"
import { ICompletions } from "../../../api/types"
import { Api } from "../../../api/api"
import { ICard } from "./types"
import { down } from "styled-breakpoints"

const WidgetContainer = styled.div`
   position: relative;
   grid-column-start: 1;
   grid-column-end: 3;
   padding: 0;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   padding: 12px;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
`

const WidgetDataContainer = styled.div`
   display: flex;
   align-items: center;
   padding: 8px;
   ${down("sm")} {
      overflow-y: auto;
   }
`

const FuncContainer = styled.div`
   display: flex;
   align-items: center;
   flex-grow: 1;
   margin-right: 10px;
`

const DropD = styled(Dropdown)`
   text-align: start;
   // .ant-dropdown {
   //   z-index: 9;
   // }
   span {
      font-weight: 600;
      font-size: 13px;
      color: #109cf1;
   }
`

const NextBtn = styled(EmptyButton)`
   width: 28px;
   height: 28px;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 6px;
   opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`

const ButtonsContainer = styled.div`
   display: flex;
   align-items: center;
   margin-left: auto;
   flex-direction: column;
   gap: 8px;
`
const DetailsFlex = styled(Flex)`
   flex-direction: column;
   width: 102px;
   margin: 13px 25px 0 16px;
   align-items: flex-start;
   position: relative;
   @media (max-width: 800px) {
      margin: 13px 8px 0 16px;
   }
   @media (max-width: 600px) {
      margin: 13px 5px 0 6px;
   }
`

const data: { [K in keyof ICompletions]: ICard } = {
   bank: {
      title: "Connect your bank",
      description: "Start taking credit cards",
      img: CreditCardImg,
   },
   // quick_books: {
   //   title: 'Setup online booking',
   //   description: 'Get more jobs',
   //   img: BookingImg,
   // },
   prices: {
      title: "Add your prices",
      description: "Own your pricing",
      img: PriceImg,
   },
   customers: {
      title: "Import customer list",
      description: "Get more jobs",
      img: CustomerListImg,
   },
   job: {
      title: "Create first order",
      description: "Create first order",
      img: CustomerListImg,
   },
   invites: {
      title: "Invite managers and admin",
      description: "Invite managers and admin",
      img: CustomerListImg,
   },
   info: {
      title: "Company info",
      description: "Company info",
      img: CustomerListImg,
   },
   logo: {
      title: "Add your shop logo",
      description: "Add your shop logo",
      img: CustomerListImg,
   },
}

type TDirection = "f" | "b"

const AddFunctionality = () => {
   const [functionalityStatus, setFunctionalityStatus] = useState<ICompletions>({} as ICompletions)
   const [indexOfCard, setIndexOfCard] = useState<number>(0)
   const [completedCount, leftCount, totalCount]: [number, number, number] = useMemo(() => {
      const completed = Object.values(functionalityStatus).filter((el) => el).length
      const total = Object.values(functionalityStatus).length
      return [completed, total - completed, total]
   }, [functionalityStatus])
   const bp = useBreakpoints()

   const cardCount = useMemo(() => {
      if (bp.isXl) {
         return 4
      }
      if (bp.isLg) {
         return 3
      }
      if (bp.isMd) {
         return 1
      }
      if (bp.isXs || bp.isSm) {
         return 8
      }
      return 4
   }, [bp])

   const loadCards = useCallback(() => {
      Api.company.completions().then(({ data }) => setFunctionalityStatus(data))
   }, [])

   useEffect(() => {
      loadCards()
   }, [loadCards])

   useEffect(() => {
      setIndexOfCard(0)
   }, [cardCount])

   const canSwitch = (direction: TDirection): boolean =>
      (direction === "b" && indexOfCard > 0) || (direction === "f" && indexOfCard + cardCount < leftCount)

   const changeCart = (direction: TDirection) => () => {
      if (canSwitch(direction)) {
         setIndexOfCard(direction === "f" ? indexOfCard + 1 : indexOfCard - 1)
      }
   }

   const deleteCart = (k: keyof ICompletions) => {
      Api.company.changeCompletions({ [k]: true }).then(() => {
         loadCards()
      })
   }
   if (!Boolean(leftCount)) {
      return null
   }

   return (
      <>
         {completedCount < 7 && (
            <WidgetContainer>
               <Flex justifyContent="space-between">
                  <Msg fontWeight={900} fontSize={16} color="#151F2B">
                     ADD FUNCTIONALITY
                  </Msg>
                  <DropD trigger={["click"]} overlay={() => <ProgressBar functionalityStatus={functionalityStatus} />}>
                     <img src={MoreImg} alt="More" />
                  </DropD>
               </Flex>
               <WidgetDataContainer>
                  <Flex mt="13px">
                     <Progress
                        type="circle"
                        percent={(completedCount * 100) / (8 - 1)} //exlude one
                        width={87}
                        className="progress"
                        format={
                           () => (
                              <Msg fontWeight={900} fontSize={15} color="#151F2B" mb="13px">
                                 {`${completedCount} of ${totalCount - 1}`}
                              </Msg>
                           ) //exlude one
                        }
                     />
                  </Flex>
                  <DetailsFlex>
                     <Msg fontWeight={400} fontSize={13} color="#494949">
                        You're almost done setting up your company
                     </Msg>
                     <DropD
                        trigger={["click"]}
                        getPopupContainer={!bp.isXs ? (trigger: any) => trigger.parentElement : undefined}
                        overlay={() => <ProgressBar functionalityStatus={functionalityStatus} />}
                     >
                        <EmptyButton>Details</EmptyButton>
                     </DropD>
                  </DetailsFlex>
                  <FuncContainer>
                     {Object.entries(functionalityStatus)
                        .filter((el) => !el[1])
                        .slice(indexOfCard, cardCount + indexOfCard)
                        .map((el, idx) => (
                           <SingleCard
                              el={el as [keyof ICompletions, boolean]}
                              cardData={data[el[0] as keyof ICompletions]}
                              deleteCard={deleteCart}
                              key={idx}
                           />
                        ))}
                     {!bp.isXs && !bp.isSm ? (
                        <ButtonsContainer>
                           <NextBtn disabled={!canSwitch("f")} onClick={changeCart("f")}>
                              <img src={NextImg} alt="Next" />
                           </NextBtn>
                           <NextBtn disabled={!canSwitch("b")} onClick={changeCart("b")}>
                              <img src={PrevImg} alt="Prev" />
                           </NextBtn>
                        </ButtonsContainer>
                     ) : null}
                  </FuncContainer>
               </WidgetDataContainer>
            </WidgetContainer>
         )}
      </>
   )
}

export default AddFunctionality
