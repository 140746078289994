import moment from "moment"
import React from "react"
import { NavLink } from "react-router-dom"
import { BoldSpan } from "../../../layouts/WorkOrderFlow/styled"
import Flex from "../../../noui/Flex"
import routes from "../../../routes/routes"
import { Msg } from "../../../ui/Text"
import { RecurringJobIcon, WorkOrderIcon } from "../../../assets/icons"
import { ServiceItem, VesselPhoto } from "./styled"

type TJobRowProps = {
   j: any
}
const JobRow = ({ j }: TJobRowProps) => {
   console.log("j", j)
   return (
      <ServiceItem key={j.id} alignItems="center">
         {j.images.length ? <VesselPhoto src={j.images[0].image} /> : ""}
         <Flex flexDirection="column" ml="16px" style={{ width: j.images.length ? "calc(100% - 196px)" : "100%" }}>
            <Flex alignItems="center">
               {j.is_recurring ? <RecurringJobIcon /> : <WorkOrderIcon />}

               <NavLink
                  style={{ fontWeight: "bold", marginLeft: " 7px" }}
                  to={routes.workOrder.UpdateWorkOrder.replace(":id", `${j.work_order}`) + `?job=${j.id}`}
               >
                  {j.title}
               </NavLink>
            </Flex>
            <Msg fontSize={13} color="#828282" mt="11px">
               {j.materials.length ? (
                  <>
                     <BoldSpan>Materials: </BoldSpan>
                     <span>
                        {j.materials
                           .map(
                              (material: any) =>
                                 `${material.name}${material.description ? ` (${material.description})` : ""}. `
                           )
                           .join(" ")}
                     </span>
                  </>
               ) : (
                  ""
               )}
            </Msg>
            <Msg fontSize={13} color="#828282" mt="11px">
               {j.services.length ? (
                  <>
                     <BoldSpan>Services: </BoldSpan>
                     <span>
                        {j.services
                           .map(
                              (service: any) =>
                                 `${service.name}${service.description ? ` (${service.description})` : ""}. `
                           )
                           .join(" ")}
                     </span>
                  </>
               ) : (
                  ""
               )}
            </Msg>
            <Msg fontSize={13} color="#828282" mt="11px">
               {moment(j.created).format("M/D/YYYY")}
            </Msg>
         </Flex>
      </ServiceItem>
   )
}

export default JobRow
