// My business
import { IConstant, IUnsafeConstant } from "./api/types"
import { TOption } from "./types"

export const servicesUnitTypes: TOption[] = [
   { value: "PH", label: "Per Hour" },
   { value: "PD", label: "Per Day" },
   { value: "PJ", label: "Per Job" },
   { value: "PF", label: "Per Foot" },
]

export const materialsUnitTypes: TOption[] = [
   { value: "E", label: "Each" },
   { value: "F", label: "Foot" },
]

export const vesselTypes: TOption[] = [
   { value: "SB", label: "Sailboat" },
   { value: "PB", label: "Powerboat" },
   { value: "PW", label: "Personal Watercraft" },
   { value: "OT", label: "Commercial" },
]

export const fuelType: TOption[] = [
   { value: "G", label: "Gas" },
   { value: "D", label: "Diesel" },
   { value: "O", label: "Other" },
]
export const reportType: TOption[] = [
   { value: "daily", label: "Daily worked hours" },
   { value: "week", label: "Week worked hours " },
   { value: "month", label: "Month worked hours " },
]
// My customers
export const phoneEnums: IConstant = {
   M: "Mobile",
   F: "Fax",
   T: "Tel",
   H: "Home",
   W: "Work",
   S: "Satellite",
}

export const phonesToDisplay: IConstant = {
   ...phoneEnums,
   P: "Primary",
}

export const userTypes: IConstant = {
   1: "Super user",
   2: "Owner",
   3: "Admin",
   4: "Technician",
}

export const TimeZones: string[] = [
   // 'Europe/Andorra',
   // 'Asia/Dubai',
   // 'Asia/Kabul',
   // 'Europe/Tirane',
   // 'Asia/Yerevan',
   // 'Antarctica/Casey',
   // 'Antarctica/Davis',
   // 'Antarctica/DumontDUrville',
   // 'Antarctica/Mawson',
   // 'Antarctica/Palmer',
   // 'Antarctica/Rothera',
   // 'Antarctica/Syowa',
   // 'Antarctica/Troll',
   // 'Antarctica/Vostok',
   // "America/Argentina/Buenos_Aires",
   // "America/Argentina/Cordoba",
   // "America/Argentina/Salta",
   // "America/Argentina/Jujuy",
   // "America/Argentina/Tucuman",
   // "America/Argentina/Catamarca",
   // "America/Argentina/La_Rioja",
   // "America/Argentina/San_Juan",
   // "America/Argentina/Mendoza",
   // "America/Argentina/San_Luis",
   // "America/Argentina/Rio_Gallegos",
   // "America/Argentina/Ushuaia",
   // 'Pacific/Pago_Pago',
   // 'Europe/Vienna',
   // 'Australia/Lord_Howe',
   // 'Antarctica/Macquarie',
   // 'Australia/Hobart',
   // 'Australia/Currie',
   // 'Australia/Melbourne',
   // 'Australia/Sydney',
   // 'Australia/Broken_Hill',
   // 'Australia/Brisbane',
   // 'Australia/Lindeman',
   // 'Australia/Adelaide',
   // 'Australia/Darwin',
   // 'Australia/Perth',
   // 'Australia/Eucla',
   // 'Asia/Baku',
   // "America/Barbados",
   // 'Asia/Dhaka',
   // 'Europe/Brussels',
   // 'Europe/Sofia',
   // 'Atlantic/Bermuda',
   // 'Asia/Brunei',
   // "America/La_Paz",
   // "America/Noronha",
   // "America/Belem",
   // "America/Fortaleza",
   // "America/Recife",
   // "America/Araguaina",
   // "America/Maceio",
   // "America/Bahia",
   // "America/Sao_Paulo",
   // "America/Campo_Grande",
   // "America/Cuiaba",
   // "America/Santarem",
   // "America/Porto_Velho",
   // "America/Boa_Vista",
   // "America/Manaus",
   // "America/Eirunepe",
   // "America/Rio_Branco",
   // "America/Nassau",
   // 'Asia/Thimphu',
   // 'Europe/Minsk',
   // "America/Belize",
   // "America/St_Johns",
   // "America/Halifax",
   // "America/Glace_Bay",
   // "America/Moncton",
   // "America/Goose_Bay",
   // "America/Blanc-Sablon",
   // "America/Toronto",
   // "America/Nipigon",
   // "America/Thunder_Bay",
   // "America/Iqaluit",
   // "America/Pangnirtung",
   // "America/Atikokan",
   // "America/Winnipeg",
   // "America/Rainy_River",
   // "America/Resolute",
   // "America/Rankin_Inlet",
   // "America/Regina",
   // "America/Swift_Current",
   // "America/Edmonton",
   // "America/Cambridge_Bay",
   // "America/Yellowknife",
   // "America/Inuvik",
   // "America/Creston",
   // "America/Dawson_Creek",
   // "America/Fort_Nelson",
   // "America/Vancouver",
   // "America/Whitehorse",
   // "America/Dawson",
   // 'Indian/Cocos',
   // 'Europe/Zurich',
   // 'Africa/Abidjan',
   // 'Pacific/Rarotonga',
   // "America/Santiago",
   // "America/Punta_Arenas",
   // 'Pacific/Easter',
   // 'Asia/Shanghai',
   // 'Asia/Urumqi',
   // "America/Bogota",
   // "America/Costa_Rica",
   // "America/Havana",
   // 'Atlantic/Cape_Verde',
   // "America/Curacao",
   // 'Indian/Christmas',
   // 'Asia/Nicosia',
   // 'Asia/Famagusta',
   // 'Europe/Prague',
   // 'Europe/Berlin',
   // 'Europe/Copenhagen',
   // "America/Santo_Domingo",
   // 'Africa/Algiers',
   // "America/Guayaquil",
   // 'Pacific/Galapagos',
   // 'Europe/Tallinn',
   // 'Africa/Cairo',
   // 'Africa/El_Aaiun',
   // 'Europe/Madrid',
   // 'Africa/Ceuta',
   // 'Atlantic/Canary',
   // 'Europe/Helsinki',
   // 'Pacific/Fiji',
   // 'Atlantic/Stanley',
   // 'Pacific/Chuuk',
   // 'Pacific/Pohnpei',
   // 'Pacific/Kosrae',
   // 'Atlantic/Faroe',
   // 'Europe/Paris',
   // 'Europe/London',
   // 'Asia/Tbilisi',
   // "America/Cayenne",
   // 'Africa/Accra',
   // 'Europe/Gibraltar',
   // "America/Godthab",
   // "America/Danmarkshavn",
   // "America/Scoresbysund",
   // "America/Thule",
   // 'Europe/Athens',
   // 'Atlantic/South_Georgia',
   // "America/Guatemala",
   // 'Pacific/Guam',
   // 'Africa/Bissau',
   // "America/Guyana",
   // 'Asia/Hong_Kong',
   // "America/Tegucigalpa",
   // "America/Port-au-Prince",
   // 'Europe/Budapest',
   // 'Asia/Jakarta',
   // 'Asia/Pontianak',
   // 'Asia/Makassar',
   // 'Asia/Jayapura',
   // 'Europe/Dublin',
   // 'Asia/Jerusalem',
   // 'Asia/Kolkata',
   // 'Indian/Chagos',
   // 'Asia/Baghdad',
   // 'Asia/Tehran',
   // 'Atlantic/Reykjavik',
   // 'Europe/Rome',
   // "America/Jamaica",
   // 'Asia/Amman',
   // 'Asia/Tokyo',
   // 'Africa/Nairobi',
   // 'Asia/Bishkek',
   // 'Pacific/Tarawa',
   // 'Pacific/Enderbury',
   // 'Pacific/Kiritimati',
   // 'Asia/Pyongyang',
   // 'Asia/Seoul',
   // 'Asia/Almaty',
   // 'Asia/Qyzylorda',
   // 'Asia/Qostanay',
   // 'Asia/Aqtobe',
   // 'Asia/Aqtau',
   // 'Asia/Atyrau',
   // 'Asia/Oral',
   // 'Asia/Beirut',
   // 'Asia/Colombo',
   // 'Africa/Monrovia',
   // 'Europe/Vilnius',
   // 'Europe/Luxembourg',
   // 'Europe/Riga',
   // 'Africa/Tripoli',
   // 'Africa/Casablanca',
   // 'Europe/Monaco',
   // 'Europe/Chisinau',
   // 'Pacific/Majuro',
   // 'Pacific/Kwajalein',
   // 'Asia/Yangon',
   // 'Asia/Ulaanbaatar',
   // 'Asia/Hovd',
   // 'Asia/Choibalsan',
   // 'Asia/Macau',
   // "America/Martinique",
   // 'Europe/Malta',
   // 'Indian/Mauritius',
   // 'Indian/Maldives',
   // "America/Mexico_City",
   // "America/Cancun",
   // "America/Merida",
   // "America/Monterrey",
   // "America/Matamoros",
   // "America/Mazatlan",
   // "America/Chihuahua",
   // "America/Ojinaga",
   // "America/Hermosillo",
   // "America/Tijuana",
   // "America/Bahia_Banderas",
   // 'Asia/Kuala_Lumpur',
   // 'Asia/Kuching',
   // 'Africa/Maputo',
   // 'Africa/Windhoek',
   // 'Pacific/Noumea',
   // 'Pacific/Norfolk',
   // 'Africa/Lagos',
   // "America/Managua",
   // 'Europe/Amsterdam',
   // 'Europe/Oslo',
   // 'Asia/Kathmandu',
   // 'Pacific/Nauru',
   // 'Pacific/Niue',
   // 'Pacific/Auckland',
   // 'Pacific/Chatham',
   // "America/Panama",
   // "America/Lima",
   // 'Pacific/Tahiti',
   // 'Pacific/Marquesas',
   // 'Pacific/Gambier',
   // 'Pacific/Port_Moresby',
   // 'Pacific/Bougainville',
   // 'Asia/Manila',
   // 'Asia/Karachi',
   // 'Europe/Warsaw',
   // "America/Miquelon",
   // 'Pacific/Pitcairn',
   // "America/Puerto_Rico",
   // 'Asia/Gaza',
   // 'Asia/Hebron',
   // 'Europe/Lisbon',
   // 'Atlantic/Madeira',
   // 'Atlantic/Azores',
   // 'Pacific/Palau',
   // "America/Asuncion",
   // 'Asia/Qatar',
   // 'Indian/Reunion',
   // 'Europe/Bucharest',
   // 'Europe/Belgrade',
   // 'Europe/Kaliningrad',
   // 'Europe/Moscow',
   // 'Europe/Simferopol',
   // 'Europe/Kirov',
   // 'Europe/Astrakhan',
   // 'Europe/Volgograd',
   // 'Europe/Saratov',
   // 'Europe/Ulyanovsk',
   // 'Europe/Samara',
   // 'Asia/Yekaterinburg',
   // 'Asia/Omsk',
   // 'Asia/Novosibirsk',
   // 'Asia/Barnaul',
   // 'Asia/Tomsk',
   // 'Asia/Novokuznetsk',
   // 'Asia/Krasnoyarsk',
   // 'Asia/Irkutsk',
   // 'Asia/Chita',
   // 'Asia/Yakutsk',
   // 'Asia/Khandyga',
   // 'Asia/Vladivostok',
   // 'Asia/Ust-Nera',
   // 'Asia/Magadan',
   // 'Asia/Sakhalin',
   // 'Asia/Srednekolymsk',
   // 'Asia/Kamchatka',
   // 'Asia/Anadyr',
   // 'Asia/Riyadh',
   // 'Pacific/Guadalcanal',
   // 'Indian/Mahe',
   // 'Africa/Khartoum',
   // 'Europe/Stockholm',
   // 'Asia/Singapore',
   // "America/Paramaribo",
   // 'Africa/Juba',
   // 'Africa/Sao_Tome',
   // "America/El_Salvador",
   // 'Asia/Damascus',
   // "America/Grand_Turk",
   // 'Africa/Ndjamena',
   // 'Indian/Kerguelen',
   // 'Asia/Bangkok',
   // 'Asia/Dushanbe',
   // 'Pacific/Fakaofo',
   // 'Asia/Dili',
   // 'Asia/Ashgabat',
   // 'Africa/Tunis',
   // 'Pacific/Tongatapu',
   // 'Europe/Istanbul',
   // "America/Port_of_Spain",
   // 'Pacific/Funafuti',
   // 'Asia/Taipei',
   // 'Europe/Kiev',
   // 'Europe/Uzhgorod',
   // 'Europe/Zaporozhye',
   // 'Pacific/Wake',
   "America/New_York",
   "America/Detroit",
   "America/Kentucky/Louisville",
   "America/Kentucky/Monticello",
   "America/Indiana/Indianapolis",
   "America/Indiana/Vincennes",
   "America/Indiana/Winamac",
   "America/Indiana/Marengo",
   "America/Indiana/Petersburg",
   "America/Indiana/Vevay",
   "America/Chicago",
   "America/Indiana/Tell_City",
   "America/Indiana/Knox",
   "America/Menominee",
   "America/North_Dakota/Center",
   "America/North_Dakota/New_Salem",
   "America/North_Dakota/Beulah",
   "America/Denver",
   "America/Boise",
   "America/Phoenix",
   "America/Los_Angeles",
   "America/Anchorage",
   "America/Juneau",
   "America/Sitka",
   "America/Metlakatla",
   "America/Yakutat",
   "America/Nome",
   "America/Adak",
   // 'Pacific/Honolulu',
   // "America/Montevideo",
   // 'Asia/Samarkand',
   // 'Asia/Tashkent',
   // "America/Caracas",
   // 'Asia/Ho_Chi_Minh',
   // 'Pacific/Efate',
   // 'Pacific/Wallis',
   // 'Pacific/Apia',
   // 'Africa/Johannesburg'
]

export const states: string[] = [
   "AL",
   "AK",
   "AZ",
   "AR",
   "CA",
   "CO",
   "CT",
   "DC",
   "DE",
   "FL",
   "GA",
   "HI",
   "ID",
   "IL",
   "IN",
   "IA",
   "KS",
   "KY",
   "LA",
   "ME",
   "MD",
   "MA",
   "MI",
   "MN",
   "MS",
   "MO",
   "MT",
   "NE",
   "NV",
   "NH",
   "NJ",
   "NM",
   "NY",
   "NC",
   "ND",
   "OH",
   "OK",
   "OR",
   "PA",
   "RI",
   "SC",
   "SD",
   "TN",
   "TX",
   "UT",
   "VT",
   "VA",
   "WA",
   "WV",
   "WI",
   "WY",
]

// Tasks
export const filters: TOption[] = [
   { value: "all", label: "All Tasks", title: "All Tasks" },
   { value: "no_date", label: "No due date", title: "No due date" },
   { value: "today", label: "Today", title: "Today" },
   { value: "tomorrow", label: "Tomorrow", title: "Tomorrow" },
   { value: "upcoming", label: "Upcoming", title: "Upcoming" },
   { value: "completed", label: "Completed", title: "Completed" },
]

export const EstimateStatuses: IConstant = {
   Estimate: "e",
   Approved: "a",
   Scheduled: "s",
   InProgress: "p",
   Done: "d",
   Invoice: "i",
   Completed: "c",
}

export const JobProgress: IUnsafeConstant = {
   Overall: null,
   Estimate: "E",
   Schedule: "T",
   Start: "S",
   Work: "W",
   Complete: "C",
   Finished: "F",
}

export const PALETTE = {
   Primary: {
      Base: "#0F497B",
      Medium: "#257ABF",
      Light: "#62AEE0",
   },
   Secondary: {
      Base: "#3E6680",
      Medium: "#0496FF",
      Light: "#7CFFC4",
   },
   Success: "#6FD89C",
   Error: "#ff4e50",
   Text: "#202020",
   TextDisabled: "#DADADA",
   PrimaryText: "#109CF1",
   Background: "#EDEDED",
}

export const COLUMNS_STORAGE_KEY = "ClmnsSlctr"

export const DefaultPageSize: number = 10
export const DefaultTimeFormat: string = "h:mm a"
export const DefaultDateFormat = "MM/DD/YY"
export const DefaultDateTimeFormat: string = `${DefaultDateFormat} ${DefaultTimeFormat}`
export const DefaultDateFormatWithShortWeekDay = `ddd, ${DefaultDateFormat}`
export const Columns = {
   JobStatus: "jobStatus",
   TimeClock: "timeClock",
   TimeClockTab: "timeClockTab",
   Payments: "payments",
   Invoices: "invoices",
   Users: "users",
   WorkOrders: "workOrders",
}
export const DefaultPhoneNumber = "+1 123-456-7890";

export const HELP_PAGE_URL = "https://help.dockworks.co/en/pro"
