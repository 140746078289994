import React, { useEffect } from "react"
import { Form, Modal } from "antd"
import { getErrors } from "../../utils/utils"
import { CreateTemplateBlock } from "./CreateTemplateBlock"
import { TCallback } from "../../types"
import { ICreateMessageTemplate } from "../../api/types"
import { Api } from "../../api/api"
import { useLoading } from "../../utils/hooks"
import { StyledModalWrapper } from "../../modals/Messages/MessagesModal"

type TProps = {
   visible: boolean
   onClose: TCallback
   onSuccess: TCallback
}

export const AddNewTemplateModal: React.FC<TProps> = ({ visible, onClose, onSuccess }) => {
   const [saving, onSave] = useLoading()
   const [form] = Form.useForm<ICreateMessageTemplate>()

   useEffect(() => {
      if (visible) {
         form.resetFields()
      }
   }, [visible, form])

   const handleCreateTemplate = async () => {
      const fields = await form.validateFields()
      try {
         await onSave(Api.templates.create(fields))
         onSuccess()
         form.resetFields()
         onClose()
      } catch (e) {
         form.setFields(getErrors(e))
      }
   }

   return (
      <StyledModalWrapper
         visible={visible}
         onCancel={onClose}
         okText="Save"
         cancelText="Close"
         onOk={handleCreateTemplate}
         closable={false}
         confirmLoading={saving}
         className="modal-window"
         width={480}
      >
         <Form onFinish={handleCreateTemplate} form={form}>
            <CreateTemplateBlock form={form} />
         </Form>
      </StyledModalWrapper>
   )
}
