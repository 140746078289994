import React from "react"
import styled from "styled-components"
import Flex from "../../noui/Flex"

export const Wrapper = styled.div`
   h3 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
   }
   .errorMessage {
      color: #ff4d4f;
      font-size: 13px;
   }
`

export const Icon = styled.div`
   position: absolute;
   top: 50%;
   margin-top: -8px;
   right: 10px;
   width: 14px;
   height: 16px;
   background-size: 14px 16px;
   z-index: 0;
`
export const CalendarBtns = styled(Flex)`
   button {
      border: none;
      outline: none;
      background: none;
      height: 16px;
      cursor: pointer;
      padding: 0 7px;
      svg {
         width: 15px;
         height: 15px;
      }
      &:hover {
         svg {
            path {
               fill: #1890ff;
            }
         }
      }
   }
`
export const CalendarWrapper = styled(Flex)`
   label {
      position: relative;
      display: inline-block;
      &:before {
         content: "";
         height: 25px;
         position: absolute;
         right: 7px;
         top: 3px;
         width: 22px;

         //background: -webkit-linear-gradient(#fff, #f0f0f0);
         //background: -moz-linear-gradient(#fff, #f0f0f0);
         //background: linear-gradient(#f5f5f5, #e0e0e0);
         background: #fff; //for Firefox in Android

         border-top-right-radius: 3px;
         border-bottom-right-radius: 3px;
         pointer-events: none;
         display: block;
      }
      &:after {
         content: " ";
         position: absolute;
         right: 15px;
         top: 46%;
         margin-top: -3px;
         z-index: 2;
         pointer-events: none;
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 6.9px 4px 0 4px;
         border-color: #aaa transparent transparent transparent;
         pointer-events: none;
      }
      select {
         -webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
         padding: 0 30px 0 10px;

         border: 1px solid #e0e0e0;
         border-radius: 3px;
         line-height: 30px;
         height: 30px;
         //box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
         background: #fff;

         //min-width: 200px;
         margin: 0 5px 5px 0;
         outline: none;
      }
   }
   //fix for ie 10 later
   select::-ms-expand {
      display: none;
   }
`
