import styled from "styled-components";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import { Loading } from "./Loading";

const Container = styled(Flex)`
    width: 100%;
    max-width: 900px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 32px 50px 48px;
    margin: 48px auto 64px;
`

const DefaultMsg = styled(Msg)`
    font-size: 18px;
    color: #202020;
    text-align: center;
    margin-top: 24px;
`

const TitleMsg = styled(Msg)`
    font-size: 15px;
    font-weight: 900;
    color: #202020;
    text-align: center;
`

export const TextMessagingVerifyWaiting = () => {
    return (
        <Container>
            <Loading/>
            <TitleMsg>YOUR MESSAGING ACCOUNT IS BEING CONFIGURED</TitleMsg>
            <DefaultMsg>You have successfully registered your company for text messaging through DockWorks.</DefaultMsg>
            <DefaultMsg>We are generating your account and will notify you via email upon completion (typically 2-4 hours).</DefaultMsg>
            <DefaultMsg>Once your account is configured, you can select your business's personal messaging number and begin sending SMS messages to your customers.</DefaultMsg>
        </Container>
    );
}