import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Empty, Pagination, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import moment from "moment"
import { Api } from "../../api/api"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { PrimaryButton } from "../../ui/Button"
import { usePagination, useResponsive, useLoading } from "../../utils/hooks"
import { EPaymentType, IPaymentRecord } from "../../api/types"
import { filterColumns, getOrdering, downloadFile } from "../../utils/utils"
import Box from "../../noui/Box"
import { AddBtn } from "../../ui/TableUtils"
import { TableCard } from "../../components/TableCard"

import AddCustomerImg from "../../assets/images/addCustomer.svg"
import AddCustomerBlueImg from "../../assets/images/addCustomerBlue.svg"
import { Columns, DefaultDateFormat } from "../../constants"
import { ColumnsSelector } from "../../modals/ColumnsSelector/ColumnsSelector"
import { ColumnsButton } from "../../components/ColumnsButton"
import { useSelector } from "react-redux"
import { getColumns } from "../../config/reducers/columns/selectors"
import { PaymentsFiltersModal, TPaymentsFilters } from "./PaymentsFiltersModal"
import { ButtonsWrapper } from "./Invoices"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"

import styled from "styled-components"

export const ExportBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   width: 94px;
   background: #f0f1f6;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   font-weight: 600;
   font-size: 14px;
   color: #000000;
   & button {
      width: 94px;
   }
`

type TFilteredData = {
   id: number
   amount: number
}

interface IPaymentRecordWithRemaining extends IPaymentRecord {
   remaining?: number
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1).toLowerCase()

const getPaymentType = (type: string) => {
   switch (type) {
      case "card":
         return "Credit Card"
      case "us_bank_account":
         return "ACH"
      default:
         return type
   }
}

const formatPaymentType = (payment: IPaymentRecordWithRemaining) => {
   if (!payment.payment_intent) {
      if (payment.payment_type === "PE") {
         return "PayEngine"
      }
      return capitalize(payment.payment_type)
   }
   if (payment.payment_intent.payment_details.type === "card") {
      const { brand, last4 } = payment.payment_intent.payment_details.card
      const brandTittle = brand[0].toUpperCase() + brand.slice(1)
      return `${brandTittle} (${last4})`
   }
   return getPaymentType(payment.payment_intent.payment_details.type)
}

const columns: ColumnsType<IPaymentRecordWithRemaining> = [
   {
      title: "Work Order #",
      dataIndex: "order_number",
      key: "order_number",
      render: (_, record) => `#${record.work_order.order_number}`,
   },
   {
      title: "Customer",
      dataIndex: "customer_data",
      key: "customer_data",
      ellipsis: true,
      render: (_, record) => record.work_order.customer.first_name + " " + record.work_order.customer.last_name,
   },
   {
      title: "Vessel",
      dataIndex: "vessel_data",
      key: "vessel_data",
      ellipsis: true,
      render: (_, record) => record.work_order?.vessel?.name,
   },
   {
      title: "Work Order Name",
      dataIndex: "job",
      key: "job",
      ellipsis: true,
      render: (_, record) => record.work_order.title,
   },
   {
      title: "Payment Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
      render: (_, record) => (record.date ? moment(record.date).format(DefaultDateFormat) : "No data"),
   },
   {
      title: "Payment Method",
      dataIndex: "payment_type",
      key: "payment_type",
      render: (_, record) => formatPaymentType(record),
   },
   {
      title: "Project Total",
      dataIndex: "job",
      key: "total",
      render: (_, record) => `$${(record.work_order.total ?? 0).toFixed(2)}`,
   },
   {
      title: "Payment Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: (_, record) => `$${(+record.amount || 0).toFixed(2)}`,
   },
   {
      title: "Total Remaining",
      dataIndex: "deposit",
      key: "deposit",
      render: (_, record) => `$${(record.work_order.total - record.work_order.deposited).toFixed(2)}`,
   },
]

const calculateTotalInvoiceAmount = (data: IPaymentRecordWithRemaining[]) => {
   const filteredInvoices: TFilteredData[] = []
   data.forEach((d) => {
      if (!filteredInvoices.find((i) => i.id === d?.work_order?.id)) {
         filteredInvoices.push({
            id: d?.work_order?.id,
            amount: d?.work_order.total ?? 0,
         })
      }
   })
   return filteredInvoices.reduce((acc, i) => acc + i.amount, 0)
}

const calculatePaymentAmount = (data: IPaymentRecordWithRemaining[]) => data.reduce((acc, d) => d.amount + acc, 0)

const calculatePaymentsInData = (data: IPaymentRecordWithRemaining[]) => {
   const invoicePayments: TFilteredData[] = []
   data.sort((x, y) => x.id - y.id)
   data.map((d) => {
      const currentInvoice = invoicePayments.find((i) => i.id === d?.invoice?.id)
      d.remaining = currentInvoice?.amount ?? 0
      if (currentInvoice) {
         currentInvoice.amount += d.amount
         return d
      }
      if (d?.invoice?.id) {
         invoicePayments.push({
            id: d.invoice.id,
            amount: d.amount,
         })
      }
      return d
   })
   return data
}

const Payments: React.FC = () => {
   const [mouseEnter, setMouseEnter] = useState<boolean>(false)
   const { data, loading, onPromise, requestPagination, pagination, onChange, sorter } = usePagination<IPaymentRecord>()
   const [visible, setVisible] = useState<boolean>(false)
   const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
   const [saving, onSave] = useLoading()
   const selectedColumns = useSelector(getColumns(Columns.Payments))
   const { width } = useResponsive()
   const [filters, setFilters] = useState<TPaymentsFilters>({})
   const isXs = useBreakpoint(down("xs"))

   useEffect(() => {
      onPromise(
         Api.paymentRecords.getAll({
            ...requestPagination,
            ...getOrdering(sorter),
            ...filters,
            is_error: false,
         })
      )
   }, [requestPagination, sorter, onPromise, filters])

   const handleExport = async () => {
      let filteredData = [...selectedColumns]
      filteredData = filteredData.map((item) => {
         if (item === "customer_data") {
            return "customer_name"
         }
         if (item === "vessel_data") {
            return "vessel_name"
         }
         if (item === "job") {
            return "order_name"
         }
         if (item === "deposit") {
            return "total_remain"
         }
         return item
      })
      const fields = {
         ...filters,
         ...sorter,
         fields: filteredData,
         is_error: false,
      }
      const { data } = await onSave(Api.paymentRecords.download(fields))
      downloadFile(data, `Payments ${moment().format("LLL")}.xlsx`)
   }

   return (
      <>
         <Helmet>
            <title>Payments - DockWorks Pro</title>
         </Helmet>
         <ButtonsWrapper>
            <Flex justifyContent={"flex-start"} style={{ flexGrow: isXs ? undefined : 1, margin: "5px" }}>
               <AddBtn
                  onMouseEnter={() => setMouseEnter(true)}
                  onMouseLeave={() => setMouseEnter(false)}
                  style={{ marginLeft: "0px" }}
                  onClick={() => setFiltersVisible(true)}
               >
                  <Flex justifyContent="space-between">
                     <Msg fontWeight={600} fontSize={13} mr={20} color="#ffffff">
                        Add Filter
                     </Msg>
                     <img src={mouseEnter ? AddCustomerBlueImg : AddCustomerImg} alt="icon" />
                  </Flex>
               </AddBtn>
            </Flex>
            <Flex justifyContent="space-between" margin="5px" flexWrap="wrap">
               <ExportBtn loading={saving} onClick={handleExport} mr="20px">
                  Export
               </ExportBtn>

               <ColumnsButton onClick={() => setVisible(true)} />
            </Flex>
         </ButtonsWrapper>
         <Box px={3}>
            {width > 1340 ? (
               <Table
                  className="contained"
                  locale={{ emptyText: "No payments were found" }}
                  dataSource={calculatePaymentsInData(data)}
                  loading={loading}
                  columns={filterColumns(columns, selectedColumns)}
                  pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
                  onChange={onChange}
                  summary={() => (
                     <>
                        <Table.Summary.Row style={{ width: "100%" }}>
                           {filterColumns(columns, selectedColumns).map((c, i) => {
                              switch (c.title) {
                                 case "Work Order #":
                                    return (
                                       <Table.Summary.Cell key={i} index={i}>
                                          <Msg fontWeight={700}>Total</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 case "Project Total":
                                    return (
                                       <Table.Summary.Cell key={i} index={i}>
                                          <Msg fontWeight={700}>{`$${calculateTotalInvoiceAmount(data).toFixed(
                                             2
                                          )}`}</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 case "Payment Amount":
                                    return (
                                       <Table.Summary.Cell key={i} index={i}>
                                          <Msg fontWeight={700}>{`$${calculatePaymentAmount(data).toFixed(2)}`}</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 case "Total Remaining":
                                    return (
                                       <Table.Summary.Cell key={i} index={i}>
                                          <Msg fontWeight={700}>{`$${(
                                             calculateTotalInvoiceAmount(data) - calculatePaymentAmount(data)
                                          ).toFixed(2)}`}</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 default:
                                    return <Table.Summary.Cell key={i} index={i} />
                              }
                           })}
                        </Table.Summary.Row>
                     </>
                  )}
               />
            ) : (
               <Flex flexWrap="wrap">
                  {!!data.length ? (
                     data.map((d) => (
                        <TableCard key={d.id}>
                           {filterColumns(columns, selectedColumns).map((c, i) => (
                              <Flex key={i} justifyContent="space-between">
                                 <Msg fontWeight={700}>{c.title}</Msg>
                                 <Msg style={{ textAlign: "end" }}>{c.render && c.render(d, d, i)}</Msg>
                              </Flex>
                           ))}
                        </TableCard>
                     ))
                  ) : (
                     <Empty style={{ width: "100%" }} />
                  )}
                  {!!pagination?.total && pagination.total > 10 && (
                     <Pagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={(p) => {
                           onChange({ ...pagination, current: p }, {}, {})
                        }}
                        style={{
                           textAlign: "center",
                           width: "100%",
                           marginTop: "20px",
                        }}
                        size="small"
                     />
                  )}
               </Flex>
            )}
         </Box>
         <PaymentsFiltersModal
            visible={filtersVisible}
            onClose={() => setFiltersVisible(false)}
            filters={filters}
            setFilters={setFilters}
         />
         <ColumnsSelector
            name={Columns.Payments}
            columns={columns}
            visible={visible}
            onClose={() => setVisible(false)}
         />
      </>
   )
}

export default Payments
