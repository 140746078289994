import { Form, FormInstance, Checkbox, CheckboxOptionType } from "antd"
import { useEffect, useState } from "react"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { Api } from "../../api/api"
import { IServiceCategories } from "../../api/types"
import { useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import Flex from "../../noui/Flex"
import { CompanyInfoContainer, ContainerTitle, SaveBtn, SaveBtnWrapper } from "./styled"
import styled from "styled-components"

const OptionsContainer = styled(Flex)`
   max-width: 700px;
   .ant-checkbox-wrapper {
      width: calc(50% - 8px);
   }
   ${down("md")} {
      max-width: 100%;
   }
   ${down("xs")} {
      .ant-checkbox-wrapper {
         width: 100%;
      }
   }
`

type TProps = {
   form: FormInstance<any>
   onFinish: () => void
   saving: boolean
}

const ServiceTypes: React.FC<TProps> = ({ form, onFinish, saving }) => {
   const [fields, setFields] = useState<CheckboxOptionType[]>([])
   const isMd = useBreakpoint(down("md"))
   const profile = useAppSelector(getCurrentUser)

   const loadData = async () => {
      const { data } = await Api.config.getServiceCategories()
      setFields(formatData(data))
   }

   const formatData = (data: IServiceCategories[]) => data.map((d) => ({ label: d.name, value: d.id }))

   useEffect(() => {
      loadData()
   }, [])

   return (
      <CompanyInfoContainer flexDirection="column" p={16}>
         <Form layout="vertical" onFinish={onFinish} form={form}>
            <ContainerTitle>Types of Service</ContainerTitle>
            <OptionsContainer>
               <Form.Item name="service_categories" initialValue={profile?.company?.service_categories ?? []}>
                  <Checkbox.Group options={fields} />
               </Form.Item>
            </OptionsContainer>
            <SaveBtnWrapper justifyContent="flex-end" mt="15px">
               <Form.Item shouldUpdate>
                  {() => (
                     <SaveBtn loading={saving} disabled={!form.isFieldsTouched()} htmlType="submit">
                        Save
                     </SaveBtn>
                  )}
               </Form.Item>
            </SaveBtnWrapper>
         </Form>
      </CompanyInfoContainer>
   )
}

export default ServiceTypes
