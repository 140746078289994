import React from 'react';
import {ServicesBlock} from "./ServicesBlock";
import {MaterialsBlock} from "./MaterialsBlock";
import {Divider, FormInstance} from "antd";
import {ICreateJob} from "../../../api/types";

type TProps = {
    form: FormInstance<ICreateJob>
}
export const ServicesTab: React.FC<TProps> = ({form}) => {
    return <div>
        <ServicesBlock form={form} />
        <Divider />
        <MaterialsBlock form={form} />
    </div>
};