import React, { useState } from "react";
import { VesselFilters } from "./VesselFilters";
import { TStatusView } from "./types";
import { KanbanBoard } from "./KanbanBoard/KanbanBoard";
import { StatusListView } from "./StatusListView";

type TProps = {
  type: TStatusView;
};

export const StatusTab: React.FC<TProps> = ({ type }) => {
  const [view, setView] = useState<TStatusView>(type);

  const [sorter, setSorter] = useState<any | null>(null);

  return (
    <div>
      <VesselFilters onChange={setView} view={view} sorter={sorter} />
      {view === "table" ? (
        <StatusListView getSorter={(value) => setSorter(value)} />
      ) : (
        <KanbanBoard />
      )}
    </div>
  );
};
