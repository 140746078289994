import React, {useState} from 'react';
import {Button, FormInstance, Input} from "antd";
import styled from "styled-components";
import {UploadImage} from "../../../components/Icons";
import {TArgCallback, TCallback, TInputChangeHandler} from "../../../types";
import {TUnassignedInspection} from "../types";
import {ICreateJob} from "../../../api/types";

const { TextArea } = Input;

const InspectionWrapper = styled.div`
  padding: 14px 14px 14px 46px;
  position: relative;
`;
const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Label = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: #484848;
`
const NumberLabel = styled.span`
  position: absolute;
  left: 12px;
  top: 12px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const IconBtn = styled(Button)`
  display: inline-flex;
  margin-left: 12px;
  align-items: center;
  justify-content: center;
`;
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

type TInspectionProps = {
  inspection: TUnassignedInspection;
  onChange: TArgCallback<TUnassignedInspection>;
  number: number;
  addInspection: boolean;
  onAdd: TCallback;
}
const Inspection: React.FC<TInspectionProps> =
  ({inspection, onChange, number, addInspection, onAdd}) => {

  const handleChange: TInputChangeHandler = ({target: {name, value}}) => {
    onChange({...inspection, [name]: value});
  }

  return <Container style={{marginBottom: !addInspection ? 18 : 0}}>
    <InspectionWrapper>
      <NumberLabel>#{number}</NumberLabel>
      <InputWrapper>
        <Label>Inspection name</Label>
        <Input name="name" style={{maxWidth: 320}} value={inspection.name} onChange={handleChange} />
      </InputWrapper>
      <InputWrapper>
        <Label>Notes</Label>
        <ImgWrapper>
          <Input name="notes" style={{maxWidth: 450}} value={inspection.notes} onChange={handleChange} />
          <IconBtn icon={<UploadImage />} />
        </ImgWrapper>
      </InputWrapper>
      {addInspection
        ? <ButtonContainer>
          <Button type="primary" onClick={onAdd}>Add new Inspection</Button>
        </ButtonContainer> : null}
    </InspectionWrapper>
  </Container>
}

const BlankWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  > p {
    margin-bottom: 18px;
  }
`

const Title = styled.h2`
  font-weight: 900;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  color: #151F2B;
`;
const Container = styled.div`
  border-radius: 4px;
  border: 1px solid #cecece;
`

type TBlankProps = {
  onClick: TCallback;
}
const BlankInspection: React.FC<TBlankProps> = ({onClick}) => {
  return <Container>
    <BlankWrapper>
      <p>There are no inspections yet for this order. Add a new inspection to get started.</p>
      <Button onClick={onClick} type="primary">New Inspection</Button>
    </BlankWrapper>
  </Container>
}

const blankInspection: TUnassignedInspection = {name: "", notes: ""};

type TProps = {
  after?: boolean;
  form: FormInstance<ICreateJob>
}


export const BeforePhotosTab: React.FC<TProps> = ({after=false}) => {
  const [inspections, setInspections] = useState<TUnassignedInspection[]>([]);
  const handleChange = (idx: number) => (data: TUnassignedInspection) => {
    inspections[idx] = {...data};
    setInspections([...inspections]);
  }
  const addNewInspection = () => {
    setInspections([...inspections, {...blankInspection}]);
  }
  return (
    <div>
      <Title>Customer Comments</Title>
      <TextArea style={{marginBottom: 24}} placeholder={"Type Here"} rows={4} />
      <Title>{inspections.length ? "Inspection" : "Vessel Inspection"}</Title>
        {inspections.length ? inspections.map((i, idx) =>
          <Inspection
            key={idx} number={idx+1}
            onChange={handleChange(idx)}
            addInspection={idx+1 === inspections.length}
            onAdd={addNewInspection}
            inspection={i}
          />
        ) : <BlankInspection onClick={addNewInspection} />}
    </div>
  );
};