import React from 'react';
import {TModalProps} from "../../../types";
import {Modal} from "antd";
import {ISchedule} from "../../../api/types";
import {Calendar} from "../../calendar/Calendar";
import styled from "styled-components";


const Container = styled.div`
  height: 600px;
`

export const CalendarModal: React.FC<TModalProps<ISchedule>> = ({visible, onClose}) => {
  return <Modal width={"80vw"} title={"Schedule a time"} visible={visible} footer={null} onCancel={onClose}>
    <Container>
      <Calendar />
    </Container>
  </Modal>
};