import React from 'react'
import PropTypes from 'prop-types'
import Week from 'react-big-calendar/lib/Week'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import { useAppSelector } from '../../../config/hooks';
import { getCallendarSettings } from '../../../config/reducers/user/selectors';

const workWeekRange = (date, options) => Week.range(date, options);

function CustomWeek ({date, ...props}) {
  const settings = useAppSelector(getCallendarSettings);
  const weekFilter = (date, options) => {
    return Week.range(date, options).filter(
      d => { 
       const start = settings.daysToExclude.includes('saturday') ? 6 : 7;
       const end = settings.daysToExclude.includes('sunday') ? 0 : -1;
       return [start, end].indexOf(d.getDay()) === -1
      }
    )
  }
  let range = weekFilter(date, props);      
  return <TimeGrid {...props} range={range} eventOffset={15} />  
}

CustomWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
}

CustomWeek.defaultProps = TimeGrid.defaultProps

CustomWeek.range = workWeekRange

CustomWeek.navigate = Week.navigate

CustomWeek.title = (date, { localizer }) => {
  let [start, ...rest] = workWeekRange(date, { localizer })
  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat')
}

export default CustomWeek