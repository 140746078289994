import { createReducer } from "@reduxjs/toolkit"
import { setPdfCheckboxes } from "./actions"
import { Key } from "react"

type TState = Record<string, Key[]>
const storageItem =
   localStorage.getItem("pdfCheckboxes") ||
   JSON.stringify({ woPricing: true, woPhotos: false, vesselPhoto: true, beforeAfterPhotos: true, checkAll: false })
const initialState: TState = JSON.parse(storageItem)

export const pdfCheckboxesReducer = createReducer(initialState, (builder) =>
   builder.addCase(setPdfCheckboxes, (state, { payload }) => {
      console.log("payload", payload)
      const nState = { ...payload }
      localStorage.setItem("pdfCheckboxes", JSON.stringify(nState))
      return nState
   })
)
