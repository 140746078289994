import React, { useState } from "react"
import styled from "styled-components"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { PrimaryButton } from "../../ui/Button"
import ArrowImage from "../../assets/images/onboard/Arrow.svg"
import { notification } from "antd"
import Slider from "react-slick"
import SingleCard from "./SingleCard"
import StepOneImg from "../../assets/images/onboard/Step1.jpg"
import StepTwoImg from "../../assets/images/onboard/Step2.jpg"
import StepThreeImg from "../../assets/images/onboard/Step3.jpg"
import StepFourImg from "../../assets/images/onboard/Step4.jpg"
import StepOneImgTech from "../../assets/images/onboard/StepTechnician1.jpg"
import StepTwoImgTech from "../../assets/images/onboard/StepTechnician2.jpg"
import StepThreeImgTech from "../../assets/images/onboard/StepTechnician3.jpg"
import StepFourImgTech from "../../assets/images/onboard/StepTechnician4.jpg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const JobsContainer = styled(Flex)`
   position: relative;
   min-height: 635px;
   max-height: 635px;
   max-width: 1064px;
   width: 100%;
   height: 100%;
   margin-bottom: 36px;
   justify-content: center;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   background-color: #ffffff;
   padding: 40px;
   @media (max-width: 800px) {
      padding: 20px;
      min-height: 0;
   }
   @media (max-width: 700px) {
      max-height: 100%;
      min-height: 0;
      padding: 50px 20px 25px 20px;
   }
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }

   & .slick-slider {
      max-width: 100%;
      & .slick-dots {
         height: 20px;
         width: 20%;
         margin: 0;
         bottom: 12px;
         left: 160px;
         @media (max-width: 900px) {
            left: 100px;
         }
         @media (max-width: 700px) {
            width: 100%;
            position: inherit;
            left: 0;
            bottom: 0;
            margin-top: 10px;
         }
         li {
            background: #c4c4c4;
            height: 8px !important;
            width: 8px !important;
            border-radius: 50% !important;
            margin-left: 16px !important;
            &.slick-active {
               background: #109cf1;
            }
            button {
               &:before {
                  content: none;
               }
            }
         }
      }
   }
`

const NextButton = styled(PrimaryButton)`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 50%;
   right: -18px;
   width: 36px;
   height: 36px;
   border-radius: 50%;
   text-align: center;
   @media (max-width: 700px) {
      top: 15px;
      right: 15px;
   }
   &:hover {
      background: #109cf1;
   }
   &:focus {
      background: #109cf1;
   }
   &.ant-btn:hover {
      background: #109cf1;
   }
   &.ant-btn:focus {
      background: #109cf1;
   }
`

const PrevButton = styled(PrimaryButton)`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 50%;
   left: -18px;
   width: 36px;
   height: 36px;
   border-radius: 50%;
   text-align: center;
   transform: rotate(180deg);
   @media (max-width: 700px) {
      display: none;
   }
   &:hover {
      background: #109cf1;
   }
   &:focus {
      background: #109cf1;
   }
   &.ant-btn:hover {
      background: #109cf1;
   }
   &.ant-btn:focus {
      background: #109cf1;
   }
`

const CarouselContainer = ({ carouselIndex, setCarouselIndex, nextSlide, setNextSlide, onClose, technician }) => {
   const isXS = useBreakpoint(down("xs"))
   const slidesData = [
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               LETS`S SHOW YOU HOW TO CREATE YOUR FIRST ESTIMATE (JOB).
            </Msg>
         ),
         description: (
            <>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Job has 5 statuses - Estimate, To Be Scheduled, Scheduled, In Progress and Completed.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Create Estimate- add services, materials, assign technician(s), set deposit amount, and send Estimate
                  for Customer approval.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  After Customer approval, the job will be ready to be started and status will change.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Click Next to learn more details.
               </Msg>
            </>
         ),
         image: <img src={StepOneImg}></img>,
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               ADD CUSTOMER AND VESSEL TO THIS JOB
            </Msg>
         ),
         description: (
            <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
               Now, you need to add an existing customer and existing vessel. If no existing customers or vessels exist
               please use the Add new Vessel (or Add new Customer) button under the input field.
            </Msg>
         ),
         image: <img src={StepTwoImg}></img>,
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               ADD SERVICE AND MATERIAL ITEMS{" "}
            </Msg>
         ),
         description: (
            <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
               Now that you have a vessel and customer added to the Estimate. Please move below and add service items
               and associated materials items (if needed) for the job. Multiple jobs can be added within a Work Order
               Estimate. Additional service items and materials items can be added to each respective job within an
               estimate.
            </Msg>
         ),
         image: <img src={StepThreeImg}></img>,
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               DEPOSITS
            </Msg>
         ),
         description: (
            <>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  You can request customers to pay a required deposit before a Job begins.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  There may be some jobs which do not require a deposit. In this case you can input the required deposit
                  amount as $0.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Connect your banking and payments information to collect online deposits and invoice payments.
                  Payments can also be recorded manually in person.
               </Msg>
            </>
         ),
         image: <img src={StepFourImg}></img>,
      },
   ]

   const slidesDataTechnician = [
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               TECHNICIAN DASHBOARD
            </Msg>
         ),
         description: (
            <>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  The Technicians dashboard contains four modules. Next Job, Upcoming Jobs, My Status, and Open
                  Estimates.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  NEXT JOB - This module displays a high level overview of the next job in your que.
                  <br />
                  UPCOMING JOBS - Module contains the date, time, vessel name, and job description.
                  <br />
                  MY STATUS - Provides insight into your weekly, monthly, and yearly completed job numbers. The number
                  of your scheduled jobs is also displayed.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  OPEN ESTIMATES - Module contains a list of the jobs and job details that have not been scheduled or
                  are not scheduled to you.
               </Msg>
            </>
         ),
         image: <img src={StepOneImgTech}></img>,
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               NEXT JOB
            </Msg>
         ),
         description: (
            <>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Next Job module contains all your pertinent information for the next job (current job) in your que.
                  This is The work order and job number, scheduled date and time, vessel name, vessel location, and
                  location weather are all displayed within this module.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Clock in and out of this job using the Clock in button on the right side of this module.
               </Msg>
            </>
         ),
         image: <img src={StepTwoImgTech}></img>,
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               CHANGING JOBS
            </Msg>
         ),
         description: (
            <>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  There will be times when the job displayed in the Next Job module is not the job you want to take
                  action on. Viewing details, clocking in, or adding time to another job assigned to you is easy.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Change the job displayed in the Next Job module by clicking on the blue “Change” button located in the
                  row next to the Work order, job number, and job name.{" "}
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Select the appropriate job to interact with by clicking the button next to the correct job.{" "}
               </Msg>
            </>
         ),
         image: <img src={StepThreeImgTech}></img>,
      },
      {
         title: (
            <Msg fontWeight="900" fontSize="18px" mb="20px" textAlign={isXS ? "center" : "left"}>
               ADD TIME MANUALLY
            </Msg>
         ),
         description: (
            <>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Forgot to clock in? How about forgetting to clock out? Time can be manually added for any job which
                  has been assigned to you.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Click the “Add time manually” under the Clock in button to open the ADD TIME MANUALLY module.
               </Msg>
               <Msg fontWeight="400" fontSize="15px" mt="12px" textAlign={isXS ? "center" : "left"}>
                  Input the technician name, job, time in, time out, and the duration.
               </Msg>
            </>
         ),
         image: <img src={StepFourImgTech}></img>,
      },
   ]

   const [current, setCurrent] = useState(0)
   let slider = React.useRef()

   let next = () => {
      try {
         slider.current.slickNext()
         if (current === 3) {
            setCarouselIndex(5)
         }
         return carouselIndex >= 4 ? setNextSlide(!nextSlide) : ""
      } catch (e) {
         notification.error({
            description: "Something went wrong",
         })
      }
   }

   let prew = () => {
      try {
         slider.current.slickPrev()

         return carouselIndex < 2 ? setNextSlide(!nextSlide) : ""
      } catch (e) {
         notification.error({
            description: "Something went wrong",
         })
      }
   }

   const afterChange = (current) => {
      setCarouselIndex(current + 1)
      setCurrent(current)
   }

   const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      adaptiveHeight: true,
      beforeChange: (current, next) => afterChange(next),
   }

   const slides = technician ? slidesDataTechnician : slidesData
   return (
      <JobsContainer alignItems="center" flexDirection="column">
         <PrevButton onClick={prew}>
            <img src={ArrowImage}></img>
         </PrevButton>
         <Slider ref={slider} {...settings}>
            {slides.map((el) => {
               return <SingleCard key={el.title} next={next} slideData={el} onClose={onClose} />
            })}
         </Slider>
         <NextButton onClick={next}>
            <img src={ArrowImage}></img>
         </NextButton>
      </JobsContainer>
   )
}

export default CarouselContainer
