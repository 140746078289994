import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { NavLink, useLocation } from "react-router-dom"
import { CloseOutlined } from "@ant-design/icons"
import { Button, Form, Modal, Popconfirm } from "antd"

import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import Cash from "../../assets/images/cash.png"
import CreditCard from "../../assets/images/credit-card.png"
import Other from "../../assets/images/other.png"
import Receipt from "../../assets/images/check.png"
import Deposit from "../../assets/images/deposit.png"
import { UnionCardIcon, DirectDepositIcon, CashIcon, CheckPaymentIcon, GiftIcon } from "../../assets/icons"

import PaymentOption from "./PaymentOption"
import { TModalProps, TWithMomentDates } from "../../types"
import { ICreatePaymentRecord, EPaymentType, IWorkOrder } from "../../api/types"
import { Api } from "../../api/api"
import { getErrors } from "../../utils/utils"
import { useLoading } from "../../utils/hooks"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import NumberFormat from "react-number-format"
import { useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { track } from "../../utils/analytics"
import { EmptyButton } from "../../ui/Button"

type TMethod = {
   value: string
   name: string
   icon: any
}

const methods: TMethod[] = [
   { value: "card", name: "Card", icon: <UnionCardIcon /> },
   // {value: 'deposit', name: "Direct Deposit (ACH)", icon: <DirectDepositIcon/>},
   { value: "cash", name: "Cash", icon: <CashIcon /> },
   { value: "check", name: "Check", icon: <CheckPaymentIcon /> },
   { value: "other", name: "Other", icon: <GiftIcon /> },
]

export const DepositInputWrapper = styled.div`
   width: 100%;
   color: #42c57a;
   font-weight: 800;
   height: 66px;
   border: 1px solid #ededed !important;
   position: relative;
   padding-left: 30px;
   border-radius: 4px;
   &:focus,
   &:hover,
   &:focus-visible {
      border-color: #ededed !important;
      border-right-width: 1px !important;
   }
   &:before {
      content: "$";
      position: absolute;
      top: 12px;
      left: 10px;
      width: 20px;
      font-size: 24px;
      color: rgba(66, 197, 122, 1);
      font-weight: 800;
   }
`
export const DepositInput = styled(NumberFormat)`
   border: none;
   width: 100%;
   height: 60px;
   font-size: 24px;
   color: rgba(66, 197, 122, 1);
   font-weight: 800;
   &:focus,
   &:hover,
   &:focus-visible {
      border: none;
      outline: none;
   }
   & input {
      font-size: 28px;
      color: rgba(66, 197, 122, 1);
      font-weight: 800;
   }
`

const Method = styled(Flex)`
   width: 100%;
   gap: 14px;
   height: 100%;
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   cursor: pointer;
`

const ModalFooter = styled(Flex)`
   position: relative;
`

const OptionFooter = styled(Flex)`
   padding: 6px 0;
`

const SuccessButton = styled(Button)`
   background: #42c57a;
   transition: opacity 0.2s;
   border: none;
   color: #fff;
   padding: 7px 24px;
   &:hover {
      background: #42c57a;
      opacity: 0.8;
      color: #fff;
   }
   &:focus {
      background: #42c57a;
      color: #fff;
   }
`
const OptionButton = styled(EmptyButton)`
   position: absolute;
   top: 0;
   right: 0;
   width: 12px;
   height: 12px;
`

type TCreatePaymentRecord = TWithMomentDates<ICreatePaymentRecord, "date">
type TPaymentWithWO = {
   workOrder: IWorkOrder | null | undefined
   loadData: any
}
const PaymentsModal: React.FC<TModalProps & TPaymentWithWO> = ({ visible, onClose, workOrder, loadData }) => {
   const [mode, setMode] = useState<string>("")
   const [showGetStarted, setShowGetStarted] = useState<boolean>(true)
   const [form] = Form.useForm<TCreatePaymentRecord>()
   const [saving, onSave] = useLoading()
   const profile = useAppSelector(getCurrentUser)
   const isMd = useBreakpoint(down("md"))
   const backToMain = () => {
      form.resetFields()
      setMode("")
   }

   useEffect(() => {
      if (profile?.company?.payments_status === "active" && profile?.company?.payments_active) {
         setShowGetStarted(false)
      }
   }, [profile])

   const clearModal = () => {
      backToMain()
      onClose()
   }

   const handleSubmit = async (d: TCreatePaymentRecord) => {
      if (workOrder?.id) {
         try {
            const data: ICreatePaymentRecord = {
               ...d,
               work_order: workOrder.id,
               date: d.date?.toISOString() ?? "",
            }
            track("Payment Recorded", {
               workorderId: data.work_order,
               customerId: workOrder.customer.id,
               vesselId: workOrder?.vessel.id,
               vesselCity: workOrder?.current_address?.city,
               vesselState: workOrder?.current_address?.state,
               workorderTotalUSD: workOrder.total,
               paymentRecordedUSD: data.amount,
               paymentType: data.payment_type,
               paymentRecordedBy: profile?.id,
            })
            await onSave(Api.paymentRecords.create(data))
            clearModal()
            loadData()
         } catch (e) {
            form.setFields(getErrors(e))
         }
      }
   }

   const handleClickSubmit = async () => {
      const data = await form.validateFields()
      await handleSubmit({ ...data, payment_type: mode.toUpperCase() })
   }

   const CustomFooter = (
      <OptionFooter justifyContent="flex-end" alignItems="center">
         <Form.Item shouldUpdate style={{ marginBottom: "0px" }}>
            {() =>
               (workOrder?.total ?? 0) < (workOrder?.deposited ?? 0) + (Number(form.getFieldValue("amount")) || 0) ? (
                  <Popconfirm
                     title="Are you sure want to deposite more than you should?"
                     onConfirm={async () => {
                        handleClickSubmit()
                     }}
                  >
                     <SuccessButton loading={saving}>
                        Record ${(Number(form.getFieldValue("amount")) || 0).toFixed(2)}
                     </SuccessButton>
                  </Popconfirm>
               ) : (
                  <SuccessButton loading={saving} onClick={handleClickSubmit}>
                     Record ${(Number(form.getFieldValue("amount")) || 0).toFixed(2)}
                  </SuccessButton>
               )
            }
         </Form.Item>
      </OptionFooter>
   )

   const DepositFooter = (
      <OptionFooter justifyContent="flex-end" alignItems="center">
         <Button>Cancel</Button>
         <Button type="primary">Use this info</Button>
      </OptionFooter>
   )

   return (
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
         <Modal
            visible={visible}
            onCancel={clearModal}
            destroyOnClose
            footer={
               mode === "deposit"
                  ? DepositFooter
                  : mode
                  ? CustomFooter
                  : showGetStarted && (
                       <ModalFooter flexDirection="column" pt="15px" pb="22px" px="50px" alignItems="center">
                          <OptionButton onClick={() => setShowGetStarted(false)}>
                             <CloseOutlined
                                style={{
                                   fontSize: "12px",
                                }}
                             />
                          </OptionButton>

                          <Msg fontSize={13} color="#828282" lineHeight="22px" style={{ textAlign: "center" }}>
                             Accept payments online or in-person with DockWorks Payments
                          </Msg>
                          <NavLink to="/settings/payments">
                             <Msg
                                fontWeight={700}
                                fontSize={16}
                                color="#109CF1"
                                style={{ marginTop: "12px", textDecoration: "underline" }}
                             >
                                Get started
                             </Msg>
                          </NavLink>
                       </ModalFooter>
                    )
            }
            width={isMd ? "100%" : mode ? 384 : 352}
            bodyStyle={{
               padding: "16px",
               borderRadius: "4px",
               width: isMd ? "100%" : mode ? "384px" : "352px",
            }}
         >
            {mode ? (
               <PaymentOption
                  form={form}
                  onSubmit={handleSubmit}
                  onClose={backToMain}
                  option={mode}
                  workOrder={workOrder}
               />
            ) : (
               <Flex flexDirection="column">
                  <Flex flexDirection="column">
                     <Msg fontWeight={900} fontSize={15} style={{ textTransform: "uppercase" }}>
                        Record payment
                     </Msg>
                     <Flex alignItems="center" flexDirection="column" mt="20px" mb="14px">
                        <DepositInputWrapper>
                           <DepositInput
                              thousandSeparator={true}
                              onValueChange={(values: any) => {
                                 const { formattedValue, value } = values
                                 // formattedValue = $2,223
                                 // value ie, 2223
                                 form.setFieldsValue({ amount: Number(value) })
                              }}
                           />
                        </DepositInputWrapper>
                     </Flex>
                     <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-around">
                        {methods.map((m) => (
                           <Method
                              alignItems="center"
                              mb="14px"
                              py="15px"
                              pl="12px"
                              key={m.value}
                              onClick={() => setMode(m.value)}
                           >
                              {m.icon}
                              <Msg fontWeight={"bold"} color={"#494949"} fontSize={"15px"}>
                                 {m.name}
                              </Msg>
                           </Method>
                        ))}
                     </Flex>
                  </Flex>
               </Flex>
            )}
         </Modal>
      </Form>
   )
}

export default PaymentsModal
