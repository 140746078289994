import React from "react"

import { Steps } from "antd"
import Icon from "@ant-design/icons"
import { CaretRightOutlined } from "@ant-design/icons"

import classes from "../../jobs/styles.module.scss"

import CheckMark from "../../../assets/images/CheckMark.svg"
import Date from "../../../assets/images/Date.svg"
import Clock from "../../../assets/images/Clock.svg"
import Timetable from "../../../assets/images/Timetable.svg"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import styled from "styled-components"

const { Step } = Steps

type TStep = {
   title: string
   name?: string
   icon: JSX.Element
}

const StyledSteps = styled(Steps)`
   .ant-steps-item {
      overflow: visible;
      padding: 0px !important;
      .ant-steps-item-container {
         .ant-steps-item-content {
            .ant-steps-item-title {
               &:after {
                  width: 12vw;
                  background-color: #ACE0FF;
                  left: 75%;
               }
            }
         }
      }
      &:nth-child(n+3) .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title:after {
         left: 85%;
      }
   }
   @media (max-width: 1600px) {
      .ant-steps-item .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title:after {
         width: 9.7vw;
      }
   }
   @media (max-width: 1199px) {
      .ant-steps-item .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title:after {
         width: 13vw;
      }
   }
   //  ${down("lg")} {
   //     grid-gap: 0px;
   //     .ant-steps-item {
   //        margin-right: 65px;
   //        &:last-child {
   //           margin-right: 0px;
   //        }
   //        .ant-steps-item-container {
   //           .ant-steps-item-content {
   //              .ant-steps-item-title {
   //                 &:after {
   //                    width: 100px;
   //                    left: 80%;
   //                 }
   //              }
   //           }
   //        }
   //     }
   //  }
   ${down("md")} {
      grid-gap: 0px;
      .ant-steps-item {
         margin-right: 0px;
         display: flex;
         justify-content: center;
         width: 64px;
         &:last-child {
            margin-right: 0px;
         }
         .ant-steps-item-container {
            .ant-steps-item-content {
               .ant-steps-item-title {
                  > div {
                     width: 64px;
                     > span {
                        z-index: 9;
                     }
                     > h4 {
                        font-size: 10px !important;
                     }
                  }
                  &:after {
                     width: 53px;
                     left: 75% !important;
                  }
               }
            }
         }
      }
   }

   ${down("xs")} {
      .ant-steps-item {
         margin-right: 0px;
         display: flex;
         justify-content: center;
         width: 64px;
         .ant-steps-item-container {
            .ant-steps-item-content {
               .ant-steps-item-title {
                  > div {
                     width: 64px;
                     > span {
                        z-index: 9;
                     }
                     > h4 {
                        font-size: 10px !important;
                     }
                  }
                  &:after {
                     width: 53px;
                     left: 75% !important;
                  }
               }
            }
         }
      }
   }

   @media (max-width: 320px) {
      .ant-steps-item {
         width: 48px;
         .ant-steps-item-container {
            .ant-steps-item-content {
               .ant-steps-item-title {
                  > div {
                     width: 48px;
                  }
               }
            }
         }
      }
   }
`

const steps: TStep[] = [
   {
      title: "Estimate",
      icon: <Icon component={() => <img src={Timetable} alt="" />} />,
   },
   {
      title: "Schedule",
      name: "Schedule Job",
      icon: <Icon component={() => <img src={Date} alt="" />} />,
   },
   {
      title: "Start",
      name: "Start the Job",
      icon: <CaretRightOutlined style={{ fontSize: 22 }} />,
   },
   {
      title: "Work",
      name: "Finish the Job",
      icon: <Icon component={() => <img src={Clock} alt="" />} />,
   },
   {
      title: "Complete",
      icon: <Icon component={() => <img src={CheckMark} alt="" />} />,
   },
]

type TStepProps = {
   step: TStep
   undo: boolean
   content: JSX.Element | null
   isError?: boolean
}

const StepTitle: React.FC<TStepProps> = ({ step, undo, isError, content }) => {
   return (
      <div className={classes.stepContent}>
         {/* {undo ? <span className={classes.undo}>Undo</span> : null} */}
         {content ? <span className={classes.iconDates}>{content}</span> : step.icon}
         <h4>{isError ? "Declined" : step.title}</h4>
      </div>
   )
}

type TProps = {
   activeStep: number;
   isError?: boolean;
}

export const ProgressSteps: React.FC<TProps> = ({ activeStep, isError }) => {
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))

   return (
      <StyledSteps
         current={1}
         responsive={false}
         style={{
            paddingRight: isMd ? "5px" : "30px",
            paddingLeft: isMd ? "0px" : "10px",
            width: "100%",
            margin: "0 auto",
            overflow: isXs ? "hidden" : "visible",
         }}
      >
         {steps.map((step, idx) => {
            const cl = [classes.step]
            const isFuture = activeStep < idx + 1
            const isActive = activeStep === idx + 1
            const isPrev = activeStep - 1 === idx + 1
            if (isFuture) {
               cl.push(classes.future)
            } else if (isActive) {
               cl.push(classes.active)
            }
            return (
              <Step
                key={idx}
                className={cl.join(" ")}
                icon
                status={
                   (isActive && isError)
                     ? "error"
                     : (isActive || isPrev)
                       ? "wait" : "process"
                }
                title={<StepTitle step={step} isError={isError && isActive} undo={isPrev} content={null}/>}
              />
            )
         })}
      </StyledSteps>
   )
}
