import React, { useState, useEffect, useCallback } from "react"
import { NavLink, useHistory } from "react-router-dom"
import Vessels from "./Vessels"
import { EllipsisOutlined, InfoCircleOutlined, RightOutlined } from "@ant-design/icons"
import { DeleteIcon, EditIcon } from "../../assets/icons"
import { Msg } from "../../ui/Text"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { Popconfirm, Table, Dropdown, Menu, Form, Input, Select, Collapse, Spin, Button, Pagination } from "antd"
import { ExportBtn, AddBtn } from "../../ui/TableUtils"
import { EmptyButton } from "../../ui/Button"
import { SearchWithBorder } from "../../noui/Search"
import AddCustomerImg from "../../assets/images/addCustomer.svg"
import AddCustomerBlueImg from "../../assets/images/addCustomerBlue.svg"
import { VesselsModal } from "./VesselsModal"
import { Api } from "../../api/api"
import { IVessel } from "../../api/types"
import { useLoading, usePagination } from "../../utils/hooks"
import { ColumnsType } from "antd/es/table"
import Box from "../../noui/Box"
import { Key } from "antd/es/table/interface"
import routes from "../../routes/routes"
import { downloadFile } from "../../utils/utils"
import moment from "moment"
import { useAppSelector } from "../../config/hooks"
import { getConstants } from "../../config/reducers/user/selectors"
import { useDebounce } from "use-debounce/lib"
import { SelectValue } from "antd/lib/select"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { TableCard } from "../../components/TableCard"
import { TableWrapper } from "../customers/pages/styled"
const { Option } = Select
const { Panel } = Collapse

const SearchField = styled(SearchWithBorder)`
   border-radius: 4px;
   margin-right: 16px;
`

const CustomersHeader = styled(Flex)`
   padding: 0px 18px;
`

const OptionButton = styled(EmptyButton)`
   margin-right: 25px;
   font-size: 18px;
   color: rgba(0, 0, 0, 0.54);
   &.ant-btn {
      background: none !important;
   }
   svg {
      max-width: 15px;
   }
`

export const StyledMenu = styled(Menu)`
   padding: 10px 0 10px 14px;
   font-size: 14px;
   border-radius: 4px;
`

const FormInputs = styled.div`
   .ant-form-item {
      margin-right: 20px;
      &:last-child {
         margin-right: 0px;
      }
      .ant-input {
         width: 92px;
         &:last-child {
            width: 140px;
         }
      }
   }
`

const MobileFilters = styled(Collapse)`
   .ant-collapse-content-box {
      padding: 0px 16px 24px !important;
   }
   .ant-row.ant-form-item {
      margin-bottom: 0px;
   }
`

export const MobileAdd = styled(Button)`
   margin: 25px 15px 0;
   height: 36px;
   font-size: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   > img {
      margin-left: 10px;
   }
`

const DeleteBtn = styled(EmptyButton)`
   width: 34px;
   height: 34px;
   margin-right: 25px;
   font-size: 18px;
   background: #f0f1f6 !important;
   border: 1px solid #e6e6e6;
   &:disabled {
      opacity: 0.5;
   }
   svg {
      width: 15px;
      height: 15px;
   }
`

const noOfEnginesList = {
   None: "0",
   "1": "1",
   "2": "2",
   "3": "3",
   "4": "4",
   "View All": "",
}

const vesselSizeList = {
   "25-30 ft": ["25", "30"],
   "30-40 ft": ["30", "40"],
   "40-50 ft": ["40", "50"],
   "50+ ft": ["51", ""],
   "View All": ["", ""],
}

const vesselAgeList = {
   "Less than 5 years": ["", "5"],
   "5-10 years": ["5", "10"],
   "More than 10 years": ["10", ""],
   "View All": ["", ""],
}

const VesselsList = () => {
   const [mouseEnter, setMouseEnter] = useState<boolean>(false)
   const [visible, setVisible] = useState<boolean>(false)
   const [editedItem, setEditedItem] = useState<IVessel | null>(null)
   const { data, requestPagination, pagination, onChange, loading, onPromise } = usePagination<IVessel>()
   const [selected, setSelected] = useState<Key[]>([])
   const [dropdown, setDropdown] = useState<null | number>(null)
   const [popupVisible, setPopupVisible] = useState<boolean>(false)
   const [saving, onSave] = useLoading()
   const [search, setSearch] = useState<string>("")

   const [dbSearch] = useDebounce(search, 1000)
   const [enginesNum, setEnginesNum] = useState<string>("")
   const [dbEnginesNum] = useDebounce(enginesNum, 300)
   const [vesselTypes, setVesselTypes] = useState<SelectValue>()

   const [vesselSize, setVesselSize] = useState<string[]>(["", ""])
   const [vesselAge, setVesselAge] = useState<string[]>(["", ""])

   const history = useHistory()
   const [form] = Form.useForm()
   const isLg = useBreakpoint(down("lg"))

   const loadCards = useCallback(async () => {
      await onPromise(
         Api.vessels.getAll({
            ...requestPagination,
            search: dbSearch,
            number_of_engines: dbEnginesNum !== undefined ? dbEnginesNum : "",
            vessel_type: vesselTypes,
            vessel_size_min: vesselSize[0] ?? "",
            vessel_size_max: vesselSize[1] ?? "",
            year_min: vesselAge[0] ?? "",
            year_max: vesselAge[1] ?? "",
         })
      )
   }, [dbSearch, vesselTypes, vesselSize, vesselAge, dbEnginesNum, requestPagination, onPromise])

   useEffect(() => {
      window.addEventListener("scroll", () => setPopupVisible(false))
      loadCards().finally()
      return () => window.removeEventListener("scroll", () => setPopupVisible(false))
   }, [loadCards])

   const selectOptions = useAppSelector(getConstants)?.vessel_types

   const columns: ColumnsType<IVessel> = [
      {
         title: "Vessel Name",
         dataIndex: "name",
         sorter: true,
         ellipsis: true,
         className: "vesselName",
         render: (_, record) => <NavLink to={`/vessels/vessel/${record.id}`}>{record.name}</NavLink>,
      },
      {
         title: "Vessel Owner",
         dataIndex: "customer",
         sorter: true,
         ellipsis: true,
         className: "vesselOwner",
         render: (_, record) => record.customer?.full_name,
      },
      {
         title: "Vessel Location",
         dataIndex: "hailing_port",
         sorter: true,
         render: (_, record) => (record?.address ? record?.address?.address1 + ", " + record?.address?.city : ""),
      },
      {
         title: "Year",
         dataIndex: "year",
         sorter: true,
         render: (_, record) => record.year,
      },
      {
         title: "Manufacturer",
         dataIndex: "manufacturer",
         sorter: true,
         render: (_, record) => record.manufacturer,
      },
      {
         title: "Model",
         dataIndex: "model",
         sorter: true,
         render: (_, record) => record.model,
      },
      {
         title: "Hull ID",
         dataIndex: "hin",
         sorter: true,
         render: (_, record) => record.hin,
      },
      {
         title: "Actions",
         dataIndex: "actions",
         fixed: "right",
         render: (_, record) => (
            <Flex justifyContent="center" style={{ width: "100%" }}>
               <Dropdown
                  trigger={["click"]}
                  onVisibleChange={() => {
                     if (dropdown) {
                        setDropdown(null)
                     }
                  }}
                  visible={dropdown === record.id}
                  placement="bottomRight"
                  arrow
                  overlay={() => (
                     <StyledMenu>
                        <Flex flexDirection="column" alignItems="flex-start" style={{ gap: "16px" }}>
                           <OptionButton
                              onClick={() => history.push(routes.vessels.Page.replace(":id", String(record.id)))}
                           >
                              <Flex alignItems="center">
                                 <InfoCircleOutlined />
                                 <Msg marginLeft="9px">More Info</Msg>
                              </Flex>
                           </OptionButton>
                           <OptionButton onClick={() => openUpdateVessel(record)}>
                              <Flex alignItems="center">
                                 <EditIcon />
                                 <Msg marginLeft="9px">Edit</Msg>
                              </Flex>
                           </OptionButton>

                           <OptionButton onClick={() => deleteItems(record.id)}>
                              <Flex alignItems="center">
                                 <DeleteIcon />
                                 <Msg marginLeft="9px">Delete</Msg>
                              </Flex>
                           </OptionButton>
                        </Flex>
                     </StyledMenu>
                  )}
               >
                  <EmptyButton onClick={() => setDropdown(record.id)}>
                     <EllipsisOutlined style={{ fontSize: "18px", marginTop: "5px" }} />
                  </EmptyButton>
               </Dropdown>
            </Flex>
         ),
      },
   ]

   const rowSelection = {
      onChange: (selectedRows: Key[]) => setSelected(selectedRows),
      checkStrictly: true,
      selectedRowKeys: selected,
   }

   const deleteItems = async (id?: number) => {
      if (id) {
         await Api.vessels.delete(id)
      } else {
         const requests = [...selected].map((s) => Api.vessels.delete(Number(s)))
         await Promise.all(requests)
      }
      await loadCards()
      setSelected([])
      setDropdown(null)
   }

   const openUpdateVessel = (vessel: IVessel) => {
      setEditedItem(vessel)
      setSelected([])
      setDropdown(null)
      setVisible(true)
   }

   const handleOpen = () => {
      setEditedItem(null)
      setVisible(true)
   }

   const handleExport = async () => {
      const { data } = await onSave(Api.vessels.getAll({ format: "xlsx" }))
      downloadFile(data, `VesselsList ${moment().format("LLL")}.xlsx`)
   }

   const createOptions = () => {
      if (selectOptions) {
         const options = Object.entries(selectOptions)
         return options.map((option) => <Option value={option[0]}>{option[1]}</Option>)
      }
      return null
   }

   const noEnginesOptions = () =>
      Object.entries(noOfEnginesList).map((item) => <Option value={item[1]}>{item[0]}</Option>)
   const vesselSizeOptions = () =>
      Object.entries(vesselSizeList).map((item) => <Option value={item[1].join(" ")}>{item[0]}</Option>)
   const vesselAgeOptions = () =>
      Object.entries(vesselAgeList).map((item) => <Option value={item[1].join(" ")}>{item[0]}</Option>)

   const vesselFilters = () => {
      return (
         <Form layout="vertical">
            <Flex flexDirection={isLg ? "column" : "row"} padding={isLg ? 0 : 18} paddingBottom={0}>
               <Form.Item name="vesselSize" label="Vessel Size">
                  <Select defaultValue=" " onChange={(e) => setVesselSize(e.split(" "))}>
                     {vesselSizeOptions()}
                  </Select>
               </Form.Item>
               <Form.Item name="vesselAge" label="Vessel Age">
                  <Select
                     defaultValue=" "
                     onChange={(e) => {
                        const yearDiffs = e.split(" ")
                        const start = yearDiffs[1]
                           ? moment()
                                .startOf("year")
                                .subtract(+yearDiffs[1], "years")
                                .year()
                           : ""
                        const end = yearDiffs[0]
                           ? moment()
                                .startOf("year")
                                .subtract(+yearDiffs[0], "years")
                                .year()
                           : ""
                        setVesselAge([String(start), String(end)])
                     }}
                  >
                     {vesselAgeOptions()}
                  </Select>
               </Form.Item>
               <Form.Item name="enginesNum" label="No. of Engines">
                  <Select defaultValue="" onChange={setEnginesNum}>
                     {noEnginesOptions()}
                  </Select>
               </Form.Item>
               <Form.Item name="vesselTypes" label="Vessel Type(s) ">
                  <Select mode="multiple" placeholder="Select" onChange={setVesselTypes}>
                     {createOptions()}
                  </Select>
               </Form.Item>
            </Flex>
         </Form>
      )
   }

   return (
      <>
         <CustomersHeader justifyContent="space-between">
            <Flex width={isLg ? "100%" : "50%"} justifyContent="space-between">
               <SearchField placeholder="Search here" onChange={(e) => setSearch(e.target.value)} />
            </Flex>
            <Flex justifyContent="space-between">
               {!isLg && (
                  <>
                     <Popconfirm
                        title="Do you want to unassign those vessels?"
                        visible={popupVisible}
                        onConfirm={() => {
                           deleteItems()
                           setPopupVisible(false)
                        }}
                        onCancel={() => setPopupVisible(false)}
                        onVisibleChange={() => setPopupVisible(!popupVisible)}
                     >
                        <DeleteBtn
                           disabled={selected.length === 0}
                           onClick={() => {
                              setPopupVisible(true)
                           }}
                        >
                           <DeleteIcon />
                        </DeleteBtn>
                     </Popconfirm>
                     <ExportBtn loading={saving} onClick={handleExport}>
                        Export
                     </ExportBtn>
                     <AddBtn
                        width="186px"
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                        onClick={handleOpen}
                     >
                        <Flex justifyContent="space-between">
                           <Msg fontWeight={600} fontSize={13} color="#ffffff">
                              Add New Vessel
                           </Msg>
                           <img src={mouseEnter ? AddCustomerBlueImg : AddCustomerImg} alt="icon" />
                        </Flex>
                     </AddBtn>
                  </>
               )}
               <VesselsModal
                  onClose={() => setVisible(false)}
                  onSuccess={loadCards}
                  payload={editedItem || undefined}
                  visible={visible}
               />
            </Flex>
         </CustomersHeader>
         {isLg ? (
            <MobileFilters ghost>
               <Panel header="Show filters" key="1">
                  {vesselFilters()}
               </Panel>
            </MobileFilters>
         ) : (
            <FormInputs>{vesselFilters()}</FormInputs>
         )}

         <Box mx={3}>
            {!isLg ? (
               <TableWrapper>
                  <Table
                     loading={loading}
                     rowKey={(record) => record.id}
                     columns={columns}
                     dataSource={data}
                     rowSelection={rowSelection}
                     pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
                     onChange={onChange}
                     className="contained vessel-table"
                  />
               </TableWrapper>
            ) : (
               <Spin spinning={loading}>
                  <Flex flexWrap="wrap">
                     {data.map((d) => (
                        <TableCard key={d.id}>
                           <Flex flexDirection="row" alignItems="center">
                              <Flex flexDirection="column" flex="1">
                                 {columns.map((c, i) => (
                                    <Flex key={i} justifyContent="space-between">
                                       <Msg fontWeight={700}>{c.title}</Msg>
                                       <Msg style={{ textAlign: "end" }}>{c.render && c.render(d, d, i)}</Msg>
                                    </Flex>
                                 ))}
                              </Flex>
                              <Button
                                 style={{ border: "none" }}
                                 onClick={() => {
                                    history.push(routes.vessels.Page.replace(":id", String(d.id)))
                                 }}
                              >
                                 <RightOutlined />
                              </Button>
                           </Flex>
                        </TableCard>
                     ))}
                     {!!pagination?.total && pagination.total > 10 && (
                        <Pagination
                           current={pagination.current}
                           pageSize={pagination.pageSize}
                           total={pagination.total}
                           onChange={(p) => {
                              onChange({ ...pagination, current: p }, {}, {})
                           }}
                           style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
                           size="small"
                        />
                     )}
                  </Flex>
               </Spin>
            )}
         </Box>
         {isLg && (
            <MobileAdd type="primary" onClick={() => setVisible(true)}>
               Add new Vessel
               <img src={mouseEnter ? AddCustomerBlueImg : AddCustomerImg} alt="icon" />
            </MobileAdd>
         )}
      </>
   )
}

export default VesselsList
