import { DatePicker, Form, InputNumber, Modal, Select, Spin } from "antd"
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import { useState } from "react"
import styled from "styled-components";
import Flex from "../../noui/Flex";
import { down } from 'styled-breakpoints'

type TProps = {
    visible: boolean;
    onClose: () => void;
    filters: TInvoicesFilters;
    setFilters: (filters: TInvoicesFilters) => void;
}

type TOption = {
    [key: string]: Array<string|number>
}

type TShortOption = {
    [key: string]: string
}

export type TInvoicesFilters = {
    total_min?: number,
    total_max?: number,
    created_after?: string,
    created_before?: string,
    completed_time_after?: string,
    completed_time_before?: string,
}

enum EFilterType {
    Estimated,
    Created,
    Completed,
}

const StyledForm = styled(Form)`
    .ant-row {
        margin-bottom: 0;
    }
    margin-bottom: 12px;
`;

const StyledSelect = styled(Select)`
    width: 150px;
    input {
        min-width: 100px;
    }
`;

const FilterRow = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
    >div {
        width: 48%;
    }
    .ant-input-number {
        width: 100%;
    }
    ${down('xs')} {
        .ant-form-item {
            flex-direction: row !important;
        }
    }
`;

export const InvoicesFiltersModal: React.FC<TProps> = ({visible, onClose, filters, setFilters}) => {
    const [form] = Form.useForm();
    const [showDateInvoicedRange, setShowDateInvoicedRange] = useState(false);

    const dateFormat = "YYYY-MM-DD";

    const optionsForCreated: TOption = {
        "30 days or less": [moment().subtract(30, "days").startOf("day").format(dateFormat), moment().format(dateFormat)],
        "30 days to 6 months": [moment().subtract(6, "month").startOf("day").format(dateFormat), moment().subtract(30, "days").startOf("day").format(dateFormat)],
        "More than 6 months": ["", moment().subtract(6, "month").startOf("day").format(dateFormat)],
        "Select a Range": ["", ""]
    };

    const optionsForPaid: TOption = {
        "7 Days": [moment().subtract(7, "days").startOf("day").format(dateFormat), moment().format(dateFormat)],
        "30 days": [moment().subtract(30, "days").startOf("day").format(dateFormat), moment().format(dateFormat)],
        "6 months": [moment().subtract(6, "month").startOf("day").format(dateFormat), moment().format(dateFormat)],
    };

    const optionsForEstimated: TOption = {
        "Under $500": [0, 500],
        "$500-$2,500": [500, 2500],
        "More than $2,500": [2500, ""],
    };

    const handleFieldChange = (selected: SelectValue, filtersData: TOption|TShortOption, filterType: EFilterType, firstFieldName: string, secondFieldName?: string) => {
        switch(filterType) {
            case EFilterType.Created:
                setShowDateInvoicedRange(checkIsRangeString(selected as string))
            break;
        }
        const data = filtersData[selected as string];
        if (Array.isArray(data)) {
            form.setFieldsValue({
                [firstFieldName]: data[0],
                ...(secondFieldName && {[secondFieldName]: data[1]}),
            });
        }
        else {
            form.setFieldsValue({
                [firstFieldName]: data,
            });
        }
    }
    
    const handleSubmit = () => {
        const newFilters = form.getFieldsValue(["total_min", "total_max", "created_after", "created_before", "completed_time_after", "completed_time_before"]);
        newFilters.created_after = normalizeMomentFormat(newFilters.created_after)
        newFilters.created_before = normalizeMomentFormat(newFilters.created_before)
        setFilters(newFilters)
        onClose()
    }

    const normalizeMomentFormat = (data: Moment|string) => {
        if (moment.isMoment(data)) {
            return data.format(dateFormat)
        }
        return data;
    }

    const checkIsRangeString = (option: string) => option === "Select a Range"

    return <Modal title="Invoices Filters" visible={visible} onCancel={onClose} width="350px" onOk={handleSubmit}>
            <StyledForm form={form} layout="vertical">
                <Form.Item label="Estimate">
                    <StyledSelect 
                        placeholder="Choose estimated amount"
                        onChange={(selected) => handleFieldChange(selected, optionsForEstimated, EFilterType.Estimated, "total_min", "total_max")}
                    >
                        {Object.entries(optionsForEstimated).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                <Form.Item label="Date Invoiced">
                    <StyledSelect 
                        placeholder="Choose date of invoice"
                        onChange={(selected) => handleFieldChange(selected, optionsForCreated, EFilterType.Created, "created_after", "created_before")}
                    >
                        {Object.entries(optionsForCreated).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                {showDateInvoicedRange &&
                <FilterRow>
                    <Form.Item label="After" name="created_after">
                        <DatePicker format={dateFormat}/>
                    </Form.Item>
                    <Form.Item label="Before" name="created_before">
                        <DatePicker format={dateFormat} />
                    </Form.Item>
                </FilterRow>
                }
                <Form.Item label="Paid">
                    <StyledSelect 
                        placeholder="Choose date of payment"
                        onChange={(selected) => handleFieldChange(selected, optionsForPaid, EFilterType.Completed, "completed_time_after", "completed_time_before")}
                    >
                        {Object.entries(optionsForPaid).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
            </StyledForm>            
    </Modal>
}