import React, { useState, useEffect } from "react"
import { Tabs } from "antd"
import { Msg } from "../../ui/Text"
import { Container } from "../../components/Container"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import routes from "../../routes/routes"
import { Route, useLocation } from "react-router-dom"
import { down } from "styled-breakpoints"
import Jobs from "./Jobs"
import Invoices from "./Invoices"
import Payments from "./Payments"
import { CreditCardPayments } from "./CreditCardPayments"
import RateCard from "./RateCard"
import Materials from "./Materials"

const TabsWrapper = styled.div`
   .ant-radio-group {
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      > label {
         border-top: none;
         border-bottom: none;
         &:first-child {
            border-left: none;
         }
         &:last-child {
            border-right: none;
         }
      }
   }
   .ant-tabs-nav {
      padding: 0 24px;
   }
   ${down("xs")} {
      .ant-tabs-nav {
         padding: 0 8px;
      }
   }
`
const tabs = [
   { label: "Projects", route: routes.business.Jobs, component: <Jobs /> },
   { label: "Invoices", route: routes.business.Invoices, component: <Invoices /> },
   { label: "Payments", route: routes.business.Payments, component: <Payments /> },
   { label: "Credit Card Payments", route: routes.business.CCPayments, component: <CreditCardPayments /> },
   { label: "Rate Card", route: routes.business.RateCard, component: <RateCard /> },
   { label: "Materials", route: routes.business.Materials, component: <Materials /> },
]

const Business: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
   const location = useLocation()
   const user = useAppSelector(getCurrentUser)
   const [choosedTab, setChoosedTab] = useState(tabs[0].route)
   const isXs = useBreakpoint(down("xs"))

   useEffect(() => {
      setChoosedTab(location.pathname)
   }, [location.pathname])

   const isPaymentsReady = !!(user?.company && user.company.merchant_id && user.company.mid_hash)

   const filterTabs = !isPaymentsReady ? tabs.filter((item) => item.label !== "Credit Card Payments") : tabs

   return (
      <Flex p={isXs ? "14px" : "34px"} pt="15px" flexDirection="column">
         <Msg fontWeight={800} fontSize={24} mb="18px" mt={isXs ? "0" : "76px"}>
            Management
         </Msg>
         <Route
            path="/business/:tab?"
            render={({ match, history }) => {
               return (
                  <Container>
                     <TabsWrapper>
                        <Tabs
                           activeKey={choosedTab}
                           onChange={(key) => {
                              setChoosedTab(key)
                              history.push(key)
                           }}
                        >
                           {filterTabs.map((t) => (
                              <Tabs.TabPane key={t.route} tab={t.label}>
                                 {t.component}
                              </Tabs.TabPane>
                           ))}
                        </Tabs>
                     </TabsWrapper>
                  </Container>
               )
            }}
         />
      </Flex>
   )
}

export default Business
