import { Modal } from "antd"
import styled from "styled-components"
import Flex from "../noui/Flex";
import { Msg } from "../ui/Text";
import WarningModalIcon from '../assets/images/WarningModalIcon.svg'

export const WarningModal = styled(Modal)`
    .ant-modal-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #FB4D4F;
        &:before {
            display: block;
            content: ' ';
            background-image: url(${WarningModalIcon});
            background-size: 16px 16px;
            height: 16px;
            width: 16px;
            margin-right: 8px;
        }
    }
`;

const TextContainer = styled(Flex)`
    flex-direction: column;
`;

type TProps = {
    visible: boolean
    last_4: string
    days_left: number
    onOk: ()=>void
    onCancel: ()=>void
}

const PaymentWarningModal: React.FC<TProps> = ({visible, last_4, days_left, onOk, onCancel}) => {
    return <WarningModal
        title="Warning"
        visible={visible}
        width={384}
        okText="Update"
        onOk={onOk}
        onCancel={onCancel}
    >   
        <TextContainer>
            <Msg>We didn't get payment from your card</Msg>
            <Msg>**** **** **** {last_4}</Msg>
            <Msg>In {days_left} days access to the functions will be limited. Please update your card.</Msg>
        </TextContainer>          
    </WarningModal>
}

export default PaymentWarningModal;