import { RootState } from "../../store"
import { createSelector } from "@reduxjs/toolkit"
import { getTimeSlotCategory } from "../../../utils/utils"
import { TExtendedNotification } from "../../../utils/types"

export const getAllNotifications = (state: RootState) => state.notifications.notifications
export const getExtendedNotifications = createSelector(
   getAllNotifications,
   (notifications) =>
      notifications.map((n) => ({ ...n, category: getTimeSlotCategory(n.created) })) as TExtendedNotification[]
)

export const getUnreadNotifications = createSelector(getAllNotifications, (notifications) =>
   notifications.filter((n) => !n.read).slice(0, 10)
)

export const getNotificationsStatus = (state: RootState) => state.notifications.status
export const getNewNotificationsExists = createSelector(getUnreadNotifications, (notifications) =>
   Boolean(notifications.length)
)
