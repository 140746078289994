export const identify = async (id: number, name: string, email: string, created_at: number, plan?: string) => {
    //@ts-ignore
    await analytics.identify(id, {name, email});
    //@ts-ignore
    _cio.identify({id, name, email, created_at, ...(plan && {plan})})
} 

export const group = async (id?: number, name?: string, website?: string, industry?: string[], createdAt?: string, users?: number, plan?: string) => {
    //@ts-ignore
    analytics.group(id, {name, website, industry, createdAt, users, plan});
}

type tData = {
    [key: string]: string|number|undefined
}

export const track = async (eventName: string, data: tData) => {
    //@ts-ignore
    analytics.track(eventName, data);

}