import { TArgCallback } from "../../types"
import { Spin } from "antd"
import styled from "styled-components"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import NoDataIcon from "../../assets/images/Icon_No_data.svg"

export const GenerateReportBlock = styled(Flex)`
   width: calc(100% - 40px);
   height: 100%;
   margin: 20px;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
   border-radius: 4px;
   padding: 20px;
   min-height: 500px;
   @media (max-width: 600px) {
      min-height: 250px;
   }
`
type TProps = {
   type?: string
   loading: boolean
   data: any
}
const NoDataBlock: React.FC<TProps> = ({ type, loading, data }) => {
   return (
      <GenerateReportBlock flexDirection="column" alignItems="center" justifyContent="center">
         {data.length === 0 && loading ? (
            <Spin spinning={loading} />
         ) : (
            <>
               <img src={NoDataIcon} alt="no data" />
               <Msg
                  fontSize="13px"
                  color="#202020"
                  fontWeight="bold"
                  textAlign="center"
                  margin="15px 0"
                  style={{ maxWidth: "200px" }}
               >
                  No data, please select group to watch {type === "rateCard" ? "services" : "materials"}!
               </Msg>
            </>
         )}
      </GenerateReportBlock>
   )
}
export default NoDataBlock
