import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Api } from "../../../api/api";
import { Button, Form, Switch, Select, Input, Spin, Tabs } from "antd";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import Flex from "../../../noui/Flex";
import {
  getConstant,
  getCurrentUser,
} from "../../../config/reducers/user/selectors";
import ModalPrintCheckboxes from "../../jobs/pdfContent/ModalPrintCheckboxes";
import { TArgCallback, TOption } from "../../../types";
import { getReplacedTemplateMessage, mapOptions } from "../../../utils/utils";
import { MessagesTemplateModal } from "../../../containers/messages/MessagesTemplateModal";
import {
  ESendType,
  ICustomer,
  IMessageTemplate,
  ITemplateSettings,
} from "../../../api/types";
import { useLoading } from "../../../utils/hooks";
import { TextEditor } from "../../../components/TextEditor/TextEditor";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  CheckboxTab,
  StyledCBTabs,
  TextMessagingWarning,
} from "../../../modals/Messages/CheckboxTab";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${down("xs")} {
    background: #fff;
  }
  &.shareModal {
    background: none;
  }
`;
const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${down("xs")} {
    padding: 0;
  }
  & .template-item label {
    width: 100%;
  }
`;
const Card = styled.div`
  padding: 12px;
  width: 266px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: stretch;
  p {
    padding: 0;
    margin: 0;
    font-size: 13px !important;
  }
  h4 {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 14px;
  }
  a {
    align-self: end;
  }
  > .ant-form-item {
    margin-bottom: 0;
  }
  .card_content {
    position: relative;
  }
  ${down("xs")} {
    width: 100%;
    .card_content {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
const ManageButton = styled(Button)`
  border: none;
  color: #0496ff;
  padding: 0;
  border-radius: 0;
  height: auto;
  font-weight: 700;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  right: 0;
  > span {
    text-decoration: underline;
  }
`;
const SelectLabel = styled.div`
  position: relative;
  width: 100%;
  h4 {
    font-weight: 700;
    font-size: 13px;
  }
`;
const LinkButton = styled(Button)`
  border: none;
  background: none;
  color: #0496ff;
  padding: 0;
  border-radius: 0;
  height: auto;
  font-weight: 700;
  font-size: 13px;
  position: absolute;
  top: 0;
  right: 10px;
  > span {
    text-decoration: underline;
  }
`;
type TCardItem = {
  title: string;
  description: string;
  name: string;
};

const cards: TCardItem[] = [
   {
      title: "Attach Receipt",
      description: "Send a copy of the invoice as an attachment",
      name: "attach_receipt",
   },
   {
      title: "Display Messages",
      description: "Allow customers to view messages on estimates (invoices)",
      name: "display_messages",
   },
   {
      title: "Display Activity Feed",
      description: "Allow customers to see the activity feed on items",
      name: "display_activity_feed",
   },
]

type TProps = {
  checkboxes: any;
  handleCheck: TArgCallback<any>;
  job?: any;
  workOrder?: any;
  customer?: ICustomer;
  form: any;
  sendType?: ESendType[];
  setSendType: (type: ESendType[]) => void;
};

export const ConfigNotifySettings: React.FC<TProps> = ({
  checkboxes,
  handleCheck,
  job,
  workOrder,
  customer,
  form,
  sendType,
  setSendType,
}) => {
  const [templates, setTemplates] = useState<{
    data: IMessageTemplate[];
    mapped: TOption[];
  }>({ data: [], mapped: [] });
  const [messagesTemplateVisible, setMessagesTemplateVisible] =
    useState<boolean>(false);
  const [checkboxesPdfModal, setCheckboxesPdfModal] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<any>();
  const [messageContentHtml, setMessageContentHtml] = useState<any>();
  const [currentTab, setCurrentTab] = useState<string>(ESendType.E);
  const tags = useSelector(getConstant("template_keys"));
  const user = useSelector(getCurrentUser);

  const showPDFSelectModal = () => {
    setCheckboxesPdfModal(true);
  };
  const [loading, onCall] = useLoading();

  const loadTemplates = useCallback(() => {
    if (!templates.data.length) {
      onCall(
        Api.templates.getAll({ ordering: "title" }).then(({ data: dt }) => {
          const data = dt as IMessageTemplate[];
          setTemplates({ data, mapped: mapOptions(data, "title") });
        })
      ).finally();
    }
  }, [templates, onCall]);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  const handleChangeTemplate = async (templateId: string) => {
    const template = templates.data.find((t) => t.id === Number(templateId));
    if (template && user && customer && job && workOrder) {
      const message = getReplacedTemplateMessage(
        template.content,
        tags,
        user,
        customer,
        job,
        workOrder
      );
      const subject = getReplacedTemplateMessage(
        template.subject,
        tags,
        user,
        customer,
        job,
        workOrder
      );
      const sms_content = getReplacedTemplateMessage(
        template.sms_content,
        tags,
        user,
        customer,
        job,
        workOrder
      );
      setMessageContent(message);

      form.setFieldsValue({ content: message, subject, sms_content });
    }
  };

  const updateMessage = (value: string) => {
    setMessageContentHtml(value);
  };

  const handleCloseManageTemplate = () => {
    loadTemplates();
    setMessagesTemplateVisible(false);
  };

  const setTab = (key: string) => {
    setCurrentTab(key);
  };

  const handleTabCheck = (e: CheckboxChangeEvent, key: ESendType) => {
    if (sendType) {
      if (e.target.checked) {
        setSendType([...sendType, key]);
        return;
      }
      setSendType(sendType.filter((i) => i !== key));
    }
  };

  const renderContent = () => {
    return (
      <>
        <Form.Item
          name="template"
          rules={[{ required: true, message: "Please select a template" }]}
          className={"template-item"}
          style={{ marginTop: "25px" }}
          label={
            <SelectLabel>
              <h4>Template</h4>
              <LinkButton onClick={() => setMessagesTemplateVisible(true)}>
                Manage
              </LinkButton>
            </SelectLabel>
          }
        >
          <Select
            onChange={handleChangeTemplate}
            options={templates.mapped}
            getPopupContainer={(trigger: any) => trigger.parentElement}
            notFoundContent={loading ? <Spin size="small" /> : null}
          />
        </Form.Item>
        <div style={{ display: "none" }}>
          <Form.Item name="subject" label="Subject">
            <Input placeholder="Type here" />
          </Form.Item>
          <Form.Item name="content" label="Message">
            <Input.TextArea rows={6} />
          </Form.Item>
        </div>
        {currentTab === ESendType.E ? (
          <>
            <SelectLabel>
              <h4 style={{ marginBottom: "0px" }}>Message</h4>
            </SelectLabel>
            <TextEditor
              initialValue={messageContent}
              onChange={updateMessage}
            />
          </>
        ) : (
          <Form.Item name="sms_content" label="Message text">
            <Input.TextArea
              rows={5}
              placeholder="Type here"
              showCount={{
                formatter: ({ count, maxLength }) =>
                  `${count}/${maxLength} characters`,
              }}
              maxLength={120}
            />
          </Form.Item>
        )}
      </>
    );
  };

  useEffect(() => {
    form.setFieldsValue({ content: messageContentHtml });
  }, [messageContentHtml]);

  return (
    <>
      <Container>
        <Content>
          {cards.map((item) => (
            <Card key={item.name}>
              <Flex flexDirection="row" className="card_content">
                <div className="content">
                  <h4>{item.title}</h4>
                  {item.name === "attach_receipt" ? (
                    <div>
                      <p>{item.description}</p>
                      <ManageButton onClick={showPDFSelectModal}>
                        Manage
                      </ManageButton>
                    </div>
                  ) : (
                    <p>{item.description}</p>
                  )}
                </div>
                <Form.Item name={item.name}>
                  <Switch
                    checked={checkboxes[item.name]}
                    onChange={(checked) =>
                      handleCheck({ name: item.name, value: checked })
                    }
                  />
                </Form.Item>
              </Flex>
            </Card>
          ))}
          <StyledCBTabs
            onChange={setTab}
            isShort
            type="card"
            activeKey={currentTab}
            tabBarExtraContent={
              !user?.company?.messaging_status && (
                <TextMessagingWarning onClose={() => {}} />
              )
            }
          >
            <Tabs.TabPane
              tab={
                <CheckboxTab
                  checked={!!sendType?.includes(ESendType.E)}
                  onChange={(e) => handleTabCheck(e, ESendType.E)}
                  title="Email"
                />
              }
              key={ESendType.E}
            >
              {renderContent()}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <CheckboxTab
                  checked={!!sendType?.includes(ESendType.S)}
                  disabled={!user?.company?.messaging_status}
                  onChange={(e) => handleTabCheck(e, ESendType.S)}
                  title="SMS"
                />
              }
              disabled={!user?.company?.messaging_status}
              key={ESendType.S}
            >
              {renderContent()}
            </Tabs.TabPane>
          </StyledCBTabs>
        </Content>
      </Container>
      <ModalPrintCheckboxes
        setPrintModal={setCheckboxesPdfModal}
        showPrintModal={checkboxesPdfModal}
      />
      <MessagesTemplateModal
        visible={messagesTemplateVisible}
        onClose={handleCloseManageTemplate}
      />
    </>
  );
};
