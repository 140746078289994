import styled from "styled-components";
import {CustomInput} from "../../ui/Input";
import {Msg} from "../../ui/Text";
import Flex from "../../noui/Flex";
import {DatePicker} from "antd";
import { down } from "styled-breakpoints";

export const AmountInput = styled(CustomInput)`
  .ant-input::placeholder {
    color: #828282 !important;
  }
`;

export const StyledLabel = styled(Msg)`
  font-weight: 700;
  color: #484848;
  display: block;
  font-size: 13px;
`;

export const OptionContainer = styled(Flex)`
  position: relative;
  min-height: 300px;
  ${down('md')} {
    flex-direction: column;
  }
`;

export const OptionBody = styled(Flex)`
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
`;

export const OptionTitle = styled(Msg)`
  text-transform: uppercase;
`;

export const OptionInfo = styled(Flex)`
  background-color: #f4f8fb;
  flex-grow: 1;
  position: absolute;
  top: -16px;
  left: calc(100% + 18px);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 264px;
  height: calc(100% + 96px);
  ${down('md')} {
    position: initial;
    width: 100%;
  }
`;

export const InfoSection = styled(Flex)`
  border-bottom: 1px solid #e6e6e6;
`;

export const InfoItem = styled(Msg)`
  font-size: 13px;
  line-height: 22px;
  color: #828282;
`;

export const InfoAmount = styled(InfoItem)``;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  border-radius: 4px;
  .ant-picker-suffix {
    display: none;
  }
`;

export const StyledType = styled(Flex)<{selected?: boolean}>`
  border-radius: 4px;
  border: ${props => props.selected ? '1px solid #109cf1' : '1px solid #e6e6e6'};
  background-color: #fbfdff;
  flex-grow: 1;
  height: 32px;
  cursor: pointer;
`;