import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Api } from "../api/api";
import { Spin } from "antd";
import styled from "styled-components";
import MainWidgetContainer from "./widgets/upcomingJobs/MainWidgetContainer";
import OpenEstimateWidget from "../containers/widgets/OpenEstimateWidget";
import AddFunctionality from "../containers/widgets/addFunctionality/AddFunctionality";
import MainContainerStats from "./widgets/todayStats/MainContainerStats";
import OpenInvoices from "../containers/widgets/OpenInvoices";
import PaidInvoicesWidget from "./widgets/PaidInvoicesWidget";
import MyStats from "./widgets/technicians/mystats/MyStats";
import Welcome from "./widgets/technicians/nextJob/Welcome";
import TodayDate from "./widgets/technicians/nextJob/TodayDate";
import { EUserRole, IUserProfile } from "../api/types";
import { getCurrentUser } from "../config/reducers/user/selectors";
import { setCurrentUser } from "../config/reducers/user/actions";
import { useAppDispatch } from "../config/hooks";
import EntryScreen from "./onboardflow/EntryScreen";
import { down } from "styled-breakpoints";
import { useAppSelector } from "../config/hooks";
import { formatWeatherRequest, formatWeatherRequestDashboard } from "../api";
import { authService } from "../utils/authService";
import { useHistory } from "react-router-dom";
import routes from "../routes/routes";

const Spinner = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
`;

const Container = styled.div`
  display: grid;
  padding: 32px;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin: 65px auto 0;
  // max-width: 1240px;
  ${down("xs")} {
    margin-top: 0;
    padding: 16px;
    gap: 0px;
    > div ~ div {
      margin-top: 24px;
    }
  }
`;
const ContainerTechnicianInner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  ${down("xs")} {
  }
`;
const ContainerTechnician = styled.div`
  margin: 65px auto 0;
  padding: 32px;
  max-width: 1360px;
  ${down("xs")} {
    margin-top: 0;
    padding: 16px;
  }
`;

const Dashboard: React.FC = () => {
  const userData = useAppSelector(getCurrentUser);
  const [weather, setWeather] = useState<any | null>(null);
  const [weatherDashboard, setWeatherDashboard] = useState<any | null>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!(authService.getRefreshToken() || authService.getLocalToken())) {
      history.push(routes.auth.Login);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      if (userData.dashboard_address) {
        axios
          .get(formatWeatherRequestDashboard(userData))
          .then((w) => setWeatherDashboard(w.data));
      } else {
        axios
          .get(formatWeatherRequest(userData))
          .then((w) => setWeather(w.data));
      }
    }
  }, [userData]);

  const handleChangeWeatherLocation = async (dataNew: any) => {
    if (userData) {
      const { data } = await Api.user.dashboardAddress({
        userId: userData.id,
        address: dataNew,
      });
      dispatch(setCurrentUser(data as IUserProfile));
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - DockWorks Pro</title>
      </Helmet>

      {userData ? (
        userData?.role_id === EUserRole.Technician ? (
          <ContainerTechnician>
            <TodayDate />
            <ContainerTechnicianInner>
              <Welcome />
              <MyStats />
              <MainWidgetContainer
                isUpcoming
                weather={weatherDashboard ? weatherDashboard : weather}
              />
              <MainWidgetContainer
                weather={weatherDashboard ? weatherDashboard : weather}
              />
              <OpenEstimateWidget />
              <EntryScreen />
            </ContainerTechnicianInner>
          </ContainerTechnician>
        ) : (
          <Container>
            <AddFunctionality />
            <MainContainerStats
              changeWeatherLocation={handleChangeWeatherLocation}
              weather={weatherDashboard ? weatherDashboard : weather}
            />
            <Welcome />
            <MyStats />
            <MainWidgetContainer
              isUpcoming
              weather={weatherDashboard ? weatherDashboard : weather}
            />
            <MainWidgetContainer
              weather={weatherDashboard ? weatherDashboard : weather}
            />
            <OpenEstimateWidget />
            <OpenInvoices />
            <PaidInvoicesWidget />
            <EntryScreen />
          </Container>
        )
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Dashboard;
