import React, { useState, useEffect, useCallback } from 'react';
import { Spin } from "antd";
import Flex from "../../noui/Flex";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { SearchWithBorder } from "../../noui/Search";
import Box from '../../noui/Box';
import styled from "styled-components";
import { Api } from "../../api/api";
import { notification } from 'antd';
import { IHelpArticles } from '../../api/types';
import { LeftOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import routes from "../../routes/routes";
import { Msg } from '../../ui/Text';
import { useParams } from 'react-router-dom';


const SearchContainer = styled(Box)`
    padding: 20px 16px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
`;

const LeftArrow = styled(LeftOutlined)`
  font-size: 20px;
  margin-right: 6px;
`
const BackButton = styled(NavLink)`
  color: #0099FF;
  font-size: 18px;
  font-weight: 800;
`
const HTMLcontainer = styled(Box)`
img {
    width: 100%
}
`;

type TProps = {
    sectionNumber: number
}

export const SingleArticle: React.FC<TProps> = ({ sectionNumber }) => {

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [article, setArticle] = useState<IHelpArticles | null>(null);
    const { id: articleId } = useParams<{ id?: string }>();

    useEffect(() => {
        setLoading(true);
        try {
            Api.help.getArticle(Number(articleId)).then(data => {
                setArticle({ ...data.data as IHelpArticles })
            })

        } catch (e) {
            notification.error({
                message: 'Something went wrong'
            });
        } finally {
            setLoading(false);
        }

    }, []);


    return <Flex p='34px' pt='15px' flexDirection='column'>
        <Title>Help</Title>
        <Container>
            <SearchContainer>
                <SearchWithBorder
                    placeholder="Search here"
                    onChange={({ target: { value } }) => setSearch(value)}
                />
            </SearchContainer>
            {article ? <Flex p='15px 24px' flexDirection='column'>
                <BackButton to={routes.help.Categories.replace(':id', article ? article.category.toString() : '')}>
                    <Flex alignItems="center">
                        <LeftArrow />
                        Back
                    </Flex>

                </BackButton>
                <Flex flexDirection='column' p='0px 50px'>
                    {/* <Flex>
                        <img src={categoriesImages[id].image}></img>
                    </Flex> */}
                    <Msg fontWeight='800' fontSize='28px'>{article?.title}</Msg>
                    <Msg fontWeight='400' fontSize='22px' color='#828282' mb='30px'>{article?.subtitle}</Msg>
                    <HTMLcontainer width='100%'
                        dangerouslySetInnerHTML={{
                            __html: article ? article.content : ''
                        }}>
                    </HTMLcontainer>
                </Flex>
            </Flex> : <Flex width='100%' p='40px' justifyContent='center'>
                <Spin />
            </Flex>}
        </Container>
    </Flex>
};