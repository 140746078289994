import { AutoComplete, Form, FormInstance, Input, Select } from "antd"
import { useEffect, useState } from "react"
import { GoogleMapsAPIKey } from "../../api"
import { IAddress } from "../../api/types"
import { setDefaultFields } from "../../utils/utils"
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import styled from "styled-components"
import { Msg } from "../../ui/Text"
import { SearchOutlined } from "@ant-design/icons"
import Flex from "../../noui/Flex"
import {states} from "../../constants"
import { down } from "styled-breakpoints"

const Label = styled(Msg)<{isFullSize?: boolean}>`
    font-weight: 700;
    font-size: ${props => props.isFullSize ? "15px" : "13px"};
    color: #494949;
`

const HeaderLabel = styled(Msg)<{isFullSize?: boolean}>`
    font-size: ${props => props.isFullSize ? "15px" : "13px"};
    font-weight: 700;
    color: #494949;
    text-transform: uppercase;
    margin-bottom: 8px;
`

const Wrapper = styled(Flex)<{isLongView?: boolean, isVertical?: boolean}>`
    flex-direction: column;
    padding: 8px 0;
    ${props => props.isLongView && `
        .ant-input, .ant-select {
            width: 360px !important;
        }
        .ant-input {
            height: 40px;
        }
        .ant-select-selection-search .ant-input {
            height: 32px;
        }
        label {
            font-size: 15px !important;
        }
    `}    
    ${down('xs')} {
        .ant-input, .ant-select {
            width: 100% !important;
        }
        ${props => props.isVertical && `
            .ant-row.ant-form-item {
                flex-direction: column;
            }
        `}
    }
`;

const FormItemRow = styled(Flex)<{ isTriple?: boolean, isSingleChild?: boolean }>`
  gap: ${props => props.isTriple ? "8px" : "16px"};
  margin: 8px 0 0;
  width: ${props => props.isTriple ? "360px" : "100%"};

  .ant-form-item.ant-row {
    margin-bottom: 0;
  }

  ${down("xs")} {
    gap: 0;
    width: 100%;    
    .ant-row {
      flex-direction: row;

      &:first-child {
        margin-right: ${props => props.isSingleChild ? '0' : "8px"};
      }
      ${props => props.isTriple && `
        margin-left: 8px;
        &:first-child {
            margin-right: 0;
            margin-left: 0;
        }
      `}
    }
  }

  .ant-form-item-label label {
    font-weight: 700;
    font-size: 13px;
    color: #494949;
  }
`

const CustomFormItem = styled(Form.Item)<{isSmall?: boolean}>`
    width: 50%;
    ${props => props.isSmall && `
        width: 33%;
        .ant-input, .ant-select {
            width: 100% !important;
        }
        .ant-select, .ant-select-selector, .ant-select-selection-search-input, .ant-select-selection-item, .ant-select-selection-placeholder {
            height: 40px !important;
            line-height: 40px !important;
        }
    `}

    .ant-input-number {
        width: 100%;
    }

`

type TAddressWithAutoCompleteProps = {
    form: FormInstance<any>
    address?: IAddress|null
    isLongView?: boolean
    visible?: boolean
    label: string
    value: string
    setValue: (value: string) => void
    isVertical?: boolean
}

const AddressWithAutoComplete: React.FC<TAddressWithAutoCompleteProps> = ({form, address, isLongView, label, value, setValue, isVertical}) => {
    const {
        placePredictions,
        placesService,
        getPlacePredictions,
    } = useGoogle({
        apiKey: GoogleMapsAPIKey,
    });
    const [selectedValue, setSelectedValue] = useState<any>(null)

    const selectPlace = async (_value: string) => {
        const place = placePredictions.find(p => p.description === _value)
        if (place) {
            placesService?.getDetails(
                {placeId: place.place_id},
                (details: any) => setSelectedValue(details)
            )
        }
    }

    const findComponentValue = (name: string, isLong: boolean) => {
        const _component = selectedValue?.address_components?.find((c: any) => c?.types?.includes(name));
        return isLong ? _component?.long_name : _component?.short_name;
    }

    const formatAddress = () => {
        const route = findComponentValue('route', true);
        const street_number = findComponentValue('street_number', true);
        if (route && street_number) {
            return `${street_number} ${route}`
        }
        return selectedValue?.vicinity ?? '';
    }

    const formatData = () => {
        const zip_code = findComponentValue('postal_code', true);
        const state = findComponentValue('administrative_area_level_1', false);
        const city = findComponentValue('locality', true) ?? findComponentValue('administrative_area_level_1', true);
        const address1 = formatAddress()
        const address2 = findComponentValue('subpremise', true)
        const full_address = value;
        const _data: any = {
            zip_code,
            state,
            city,
            address1,
            address2,
            full_address
        };
        form.setFieldsValue(_data);
    }

    const handleAutocompleteValueChange = (_value: string) => {
        setValue(_value);
    }

    const formatPredictions = () => {
        return placePredictions.map(i => ({label: i.description, value: i.description}))
    }

    useEffect(() => {
        setDefaultFields(form, address ?? {})
        setValue(address?.full_address ?? "")
    }, [form, address])

    useEffect(() => {
        getPlacePredictions({input: value});
    }, [value])

    useEffect(() => {
        if (selectedValue) {
            formatData()
        }
    }, [selectedValue])

    const renderAddressCityPart = () => {
        if (!isLongView) {
            return (
                <>
                    <FormItemRow isSingleChild>
                        <Form.Item
                            label="City"
                            name="city"
                            style={{width: "100%"}}
                        >
                            <Input placeholder="City"/>
                        </Form.Item>
                    </FormItemRow>
                    <FormItemRow>
                        <CustomFormItem label="State" name="state">
                            <Select
                                placeholder="Select state"
                                showSearch
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                            >
                                {states.map((s) => (
                                    <Select.Option value={s} key={s}>
                                        {s}
                                    </Select.Option>
                                ))}
                            </Select>
                        </CustomFormItem>
                        <CustomFormItem
                            label="Zip Code"
                            name="zip_code"
                        >
                            <Input placeholder="Enter zip code"/>
                        </CustomFormItem>
                    </FormItemRow>
                </>
            )
        }
        return (
            <>
                <FormItemRow isTriple>
                    <CustomFormItem
                        label="City"
                        name="city"
                        isSmall
                    >
                        <Input placeholder="City"/>
                    </CustomFormItem>
                    <CustomFormItem 
                        label="State" 
                        name="state" 
                        isSmall
                    >
                        <Select
                            placeholder="Select state"
                            showSearch
                            getPopupContainer={(trigger: any) => trigger.parentElement}
                        >
                            {states.map((s) => (
                                <Select.Option value={s} key={s}>
                                    {s}
                                </Select.Option>
                            ))}
                        </Select>
                    </CustomFormItem>
                    <CustomFormItem
                        label="Zip Code"
                        name="zip_code"
                        isSmall
                    >
                        <Input placeholder="Enter zip code"/>
                    </CustomFormItem>
                </FormItemRow>
            </>
        )
    }

    return (
        <Form form={form} layout="vertical">
            <Wrapper isLongView={isLongView} isVertical={isVertical}>
                <HeaderLabel isFullSize={isLongView}>{label}</HeaderLabel>
                <Label isFullSize={isLongView}>Search Address</Label>
                <AutoComplete
                    options={formatPredictions()}
                    value={value}
                    onChange={handleAutocompleteValueChange}
                    style={{width: "100%"}}
                    onSelect={selectPlace}
                >
                    <Input prefix={<SearchOutlined/>} placeholder="Search"/>
                </AutoComplete>
                <FormItemRow isSingleChild>
                    <Form.Item
                        label="Address 1"
                        name="address1"
                        style={{width: "100%"}}
                    >
                        <Input placeholder="Address 1"/>
                    </Form.Item>
                </FormItemRow>
                <FormItemRow isSingleChild>
                    <Form.Item
                        label="Address 2"
                        name="address2"
                        style={{width: "100%"}}
                    >
                        <Input placeholder="Address 2"/>
                    </Form.Item>
                </FormItemRow>
                {renderAddressCityPart()}
            </Wrapper>            
        </Form>
    );
}

export default AddressWithAutoComplete;