import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Select,
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  Radio,
  Checkbox,
} from "antd";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { down } from "styled-breakpoints";
import { HeaderButton, EmptyButton } from "../../ui/Button";
import { Msg } from "../../ui/Text";
import { ModalWindow } from "../../ui/ModalWindow";
import Flex from "../../noui/Flex";
import PlusInCircule from "../../assets/images/PlusInCircule.svg";
import Tick from "../../assets/images/headerIcons/Tick.svg";
import Trash from "../../assets/images/Delete.svg";
import Edit from "../../assets/images/Edit.svg";
import { filters } from "../../constants";
import { useResponsive } from "../../utils/hooks";
import CreateTask from "./CreateTask";
import EditTask from "./EditTask";
import { ITask } from "../../api/types";
import { TArgCallback, TCallback } from "../../types";
import { Api } from "../../api/api";
import { useLoading } from "../../utils/hooks";
import { Content } from "../../components/Content";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import routes from "../../routes/routes";

const { Option } = Select;

const StyledModal = styled(ModalWindow)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: fit-content;
  box-shadow: none;
  position: relative;
`;

const AddTaskButton = styled(EmptyButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  & img {
    margin-right: 8px;
  }
  box-shadow: none;
`;

const DropD = styled(Dropdown)`
  position: relative;
  width: 45px;
  height: 45px;
  background-color: red;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
`;

const HeaderBtn = styled(HeaderButton)`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterMenu = styled(Select)`
  width: fit-content;
  // margin-bottom: 16px;
  .ant-select-selector {
    border: none !important;
    padding-left: 0 !important;
  }
  .ant-select-selection-item {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 15px;
  }
  .ant-select-arrow {
    color: #109cf1;
    margin-top: -8px;
  }
`;

const StyledHeader = styled(Msg)`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 15px;
  margin-top: 16px;
`;

const StyledTask = styled(Flex)`
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
`;

const StyledIcon = styled.img`
  cursor: pointer;
`;

const StyledMsg = styled(Msg)`
  max-width: 200px;
  word-wrap: break-word;
`;

const StyledMenu = styled(Menu)`
  max-height: 90vh;
  // overflow: auto;
  position: relative;
  ::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 3px;
  }
  border-radius: 6px;
`;

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    &::after {
      width: 12px;
      height: 12px;
    }
  }
`;
const StyledFlex = styled(Flex)`
  //padding: 16px 0;
  overflow-y: auto;
  max-height: 560px;
  min-height: 300px;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fbfbff;
    border-radius: 8px;
    border: 1px solid #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background: #0496ff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #40a9ff;
  }
`;

const Wrapper = styled.div`
  ${down("xs")} {
    .ant-picker-datetime-panel {
      flex-direction: column !important;
    }
  }
`;

type TSection = {
  header: string;
  tasks: ITask[];
};
type TTicketProps = {
  task: ITask;
  toggle: TArgCallback<ITask>;
  setMode: TArgCallback<string>;
  setTask: TArgCallback<ITask>;
  deleteTask: TArgCallback<number>;
  completeTask: TArgCallback<ITask>;
  clearModal: TCallback;
};
const TaskTicket: React.FC<TTicketProps> = ({
  task,
  toggle,
  setMode,
  setTask,
  deleteTask,
  completeTask,
  clearModal,
}) => {
  const {
    id,
    title,
    job,
    due_date,
    description,
    employee_data,
    customer_data,
    job_data,
    completed,
    work_order,
    work_order_data,
  } = task;
  const isXs = useBreakpoint(down("xs"));

  return (
    <StyledTask mt="12px" px="20px" py="16px">
      <Flex justifyContent="space-between" alignItems="center">
        <Popconfirm
          placement="right"
          getPopupContainer={() =>
            document.getElementById("task_list") as HTMLElement
          }
          // disabled={completed}
          title={
            !completed
              ? "Do you want to complete this task?"
              : "Do you want to uncomplete this task?"
          }
          onConfirm={() => completeTask({ ...task, completed: !completed })}
        >
          <Checkbox checked={completed} />
        </Popconfirm>
        <Flex ml="34px" flexDirection="column">
          <StyledMsg fontWeight={700}>{title}</StyledMsg>
          <StyledMsg>
            {description
              ? `${description.slice(0, 20)}${
                  description.length >= 20 ? "..." : ""
                }`
              : ""}
          </StyledMsg>
          <NavLink
            to={
              work_order
                ? routes.workOrder.UpdateWorkOrder.replace(
                    ":id",
                    String(work_order)
                  ) + `?job=${job}`
                : ""
            }
            onClick={clearModal}
          >
            <StyledMsg fontWeight={700} color="#109CF1">
              {job_data && `Estimate ${job_data?.title}`}
            </StyledMsg>
          </NavLink>
          <StyledMsg>
            {due_date && moment(due_date).format("MMMM Do YYYY")}
          </StyledMsg>
          <StyledMsg>{employee_data?.full_name}</StyledMsg>
          <StyledMsg fontWeight={700}>
            {customer_data &&
              `${customer_data.first_name} ${customer_data.last_name}`}
          </StyledMsg>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        style={{ flexGrow: 1 }}
      >
        {completed ? (
          <StyledIcon
            src={Trash}
            onClick={(e) => {
              // stopPropagation used to prevent selecting of deleted task
              e.stopPropagation();
              deleteTask(id);
            }}
            alt="icon"
          />
        ) : (
          <StyledIcon
            src={Edit}
            onClick={() => {
              setTask(task);
              setMode("Edit");
            }}
            alt="icon"
          />
        )}
      </Flex>
    </StyledTask>
  );
};

type TListProps = {
  sections: TSection[];
  setMode: TArgCallback<string>;
  deleteTask: TArgCallback<any>;
  completeTask: TArgCallback<any>;
  clearModal: TCallback;
  setEditTask: TArgCallback<ITask>;
  loading: boolean;
};
const TasksList: React.FC<TListProps> = ({
  sections,
  loading,
  setMode,
  deleteTask,
  setEditTask,
  completeTask,
  clearModal,
}) => {
  const [filter, setFilter] = useState(filters[0]);
  const [selected, setSelected] = useState<ITask[]>([]);
  const isXs = useBreakpoint(down("xs"));
  const { width } = useResponsive();
  const toggleTask = (task: ITask) => {
    const newTasks = selected.some((t) => t.id === task.id)
      ? selected.filter((t) => t.id !== task.id)
      : [...selected, task];
    setSelected(newTasks);
  };
  const displayedData =
    filter.value === "all"
      ? sections
      : sections.filter((s) => s.header === filter.title);
  return (
    <StyledModal width={isXs ? `${width - 25}px` : "400px"}>
      <Flex flexDirection="column" position="relative">
        <StyledFlex padding="16px" flexDirection="column" position="relative">
          <FilterMenu
            bordered={false}
            defaultValue={filters[0].title}
            onChange={(option) => setFilter(JSON.parse(option as string))}
            getPopupContainer={(trigger: any) => trigger.parentElement}
          >
            {filters.map((item) => (
              <Option key={item.value} value={JSON.stringify(item)}>
                {item.title}
              </Option>
            ))}
          </FilterMenu>
          <Content loading={loading}>
            {displayedData.some((s) => s.tasks.length) ? (
              displayedData.map((s) => (
                <Flex flexDirection="column" key={s.header}>
                  {filter.value === "all" && s.tasks.length ? (
                    <StyledHeader>{s.header}</StyledHeader>
                  ) : (
                    ""
                  )}
                  {s.tasks.map((t) => (
                    <TaskTicket
                      key={t.id}
                      task={t}
                      toggle={toggleTask}
                      setMode={setMode}
                      deleteTask={deleteTask}
                      completeTask={completeTask}
                      clearModal={clearModal}
                      setTask={setEditTask}
                    />
                  ))}
                </Flex>
              ))
            ) : (
              <Msg mt="16px">No tasks yet</Msg>
            )}
          </Content>
        </StyledFlex>
        <Button
          type="primary"
          style={{ margin: "12px", fontWeight: "bold" }}
          onClick={() => setMode("Create")}
        >
          Create New Task
        </Button>
      </Flex>
    </StyledModal>
  );
};

export const TasksModal: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [loading, onLoad] = useLoading();
  const [sections, setSections] = useState<TSection[]>([]);
  const [mode, setMode] = useState<string>("");
  const [editTask, setEditTask] = useState<ITask>({} as ITask);
  const loadCards = useCallback(async () => {
    const { data: d } = await onLoad(Api.tasks.getAll({}));
    const data = d as ITask[];

    const sections: TSection[] = filters
      .filter((f) => f.value !== "all")
      .map((filter) => {
        const section: TSection = { header: filter.title ?? "", tasks: [] };
        // Need to separate completed tasks from others
        let unfinishedTasks = data.filter((t) => !t.completed);
        switch (filter.value) {
          case "no_date":
            section.tasks = unfinishedTasks.filter((t) => !t.due_date);
            break;
          case "today":
            section.tasks = unfinishedTasks.filter(
              (t) => moment(t.due_date).date() === moment().date()
            );
            break;
          case "completed":
            section.tasks = data.filter((t) => t.completed);
            break;
          case "tomorrow":
            section.tasks = unfinishedTasks.filter(
              (t) => moment(t.due_date).date() === moment().add(1, "day").date()
            );
            break;
          default:
            section.tasks = unfinishedTasks.filter((t) =>
              moment(t.due_date).isAfter(
                moment()
                  .add(1, "day")
                  .set({ hours: 23, minutes: 59, seconds: 59 })
              )
            );
        }
        return section;
      });
    setSections(sections);
  }, [onLoad]);

  const deleteTask = async (id: number) => {
    await Api.tasks.remove(id);
    setMode("");
    loadCards().finally();
  };

  useEffect(() => {
    loadCards().finally();
  }, [loadCards, mode]);

  const clearModal = () => {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
      setMode("");
    }
  };

  const completeTask = async (task: ITask) => {
    await Api.tasks.patch({ ...task });
    loadCards().finally();
  };

  const ModalContent = (
    <StyledMenu id="task_list">
      {mode === "Create" ? (
        <CreateTask setMode={setMode} />
      ) : mode === "Edit" ? (
        <EditTask task={editTask} setMode={setMode} deleteTask={deleteTask} />
      ) : (
        <TasksList
          loading={loading}
          deleteTask={deleteTask}
          completeTask={completeTask}
          sections={sections}
          setMode={setMode}
          clearModal={clearModal}
          setEditTask={setEditTask}
        />
      )}
    </StyledMenu>
  );

  return (
    <Wrapper id="task_modal">
      <DropD
        getPopupContainer={(trigger: any) => trigger.parentElement}
        overlayStyle={{ boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.15)" }}
        trigger={["click"]}
        overlay={ModalContent}
        visible={visible}
        onVisibleChange={clearModal}
        arrow
      >
        <HeaderBtn onClick={() => setVisible(!visible)}>
          <img src={Tick} alt="Tick" />
        </HeaderBtn>
      </DropD>
    </Wrapper>
  );
};
