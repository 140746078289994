import styled from 'styled-components';
import {
  // backgroundColor,
  space, SpaceProps,
  height, HeightProps,
  width, WidthProps,
  minWidth, MinWidthProps,
  minHeight, MinHeightProps,
  maxWidth, MaxWidthProps,
  maxHeight, MaxHeightProps,
  textAlign, TextAlignProps,
  borders, BordersProps,
  fontSize, FontSizeProps,
  fontWeight, FontWeightProps,
  color, ColorProps,
  position, PositionProps,
  display, DisplayProps,
  top, TopProps,
  bottom, BottomProps,
  left, LeftProps,
  right, RightProps,
  lineHeight, LineHeightProps,
  borderRight, BorderRightProps
} from 'styled-system';

type TCustomProps = {
  inline?: boolean;
  textCenter?: boolean;
}
export type TBoxProps =
  & TCustomProps
  & SpaceProps
  & HeightProps
  & WidthProps
  & MinWidthProps
  & MinHeightProps
  & MaxWidthProps
  & MaxHeightProps
  & TextAlignProps
  & BordersProps
  & FontSizeProps
  & FontWeightProps
  & ColorProps
  & PositionProps
  & DisplayProps
  & TopProps
  & BottomProps
  & LeftProps
  & RightProps
  & LineHeightProps
  & BorderRightProps
// Add styled-system functions to your component

const Box = styled.div.attrs<TBoxProps>(props => ({
  display: props.inline ? 'inline-block' : 'block',
  textAlign: props.textCenter ? 'center' : 'left'
}))<TBoxProps>`
  ${display}
  ${space}
  ${width}
  ${minWidth}
  ${minHeight}
  ${maxWidth}
  ${maxHeight}
  ${textAlign}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${position}
  ${top}
  ${bottom}
  ${left}
  ${right}
  ${color}
  ${lineHeight}
  ${height}
  ${borderRight}
`;

export default Box;
