import { CheckOutlined } from "@ant-design/icons"
import { AlertBox } from "./styled"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { useResponsive } from "../../utils/hooks"

type TApprovedAlertProps = {
   activeStep: number
   isApproved?: boolean | undefined
   isFinalized?: boolean | undefined
}

type TAlertInfo = {
   [key: number]: { title: string; text: string }
}

const AlertInfo: TAlertInfo = {
   2: {
      title: "Estimate finalized and ready for customer review.",
      text: "Once the workorder is approved by the customer, you will be notified to schedule this job.",
   },
   3: {
      title: "The job estimate has been approved by the customer. ",
      text: "Use the scheduler tool below to book an appointment and assign the technician(s).",
   },
   4: {
      title: "The job is ready to start following an inspection.",
      text: "Submit before photos and notes to officially kick off the job.",
   },
   5: {
      title: "The job is in progress. Please log all hours worked below. ",
      text: "When work has concluded, click “Complete Job” below to submit final photos and notes.",
   },
   6: {
      title: "The job is in progress. Please log all hours worked below. ",
      text: "When work has concluded, click “Complete Job” below to submit final photos and notes.",
   },
   7: {
      title: "The job has been marked complete.",
      text: "Please submit after photos and notes below, then click “Send Invoice.”",
   },
}

export const ApprovedAlert = ({ activeStep, isApproved, isFinalized }: TApprovedAlertProps) => {
   const { width } = useResponsive()
   let step = isApproved ? activeStep + 1 : activeStep
   if (activeStep === 1 && isFinalized) {
      step = 2;
   }
   if (step === 1) return null
   return (
      <AlertBox alignItems="center" margin={`30px ${width > 576 ? 18 : 7}px 36px ${width > 576 ? 0 : 7}px`}>
         <CheckOutlined style={{ color: step === 2 || step === 6 ? "#42C57A" : "#109CF1" }} />
         <Flex flexDirection="column">
            <Msg color={step === 2 || step === 6 ? "#42C57A" : "#109CF1"} fontWeight={900} fontSize={14}>
               {AlertInfo[step].title}
            </Msg>
            <Msg color="#494949" fontSize={14}>
               {AlertInfo[step].text}
            </Msg>
         </Flex>
      </AlertBox>
   )
}
