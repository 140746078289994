
import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Spin } from "antd";
import Flex from "../../noui/Flex";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { SearchWithBorder } from "../../noui/Search";
import Box from '../../noui/Box';
import styled from "styled-components";
import { THelpCategories } from './types';
import Book from '../../assets/images/help/Book.svg';
import Settings from '../../assets/images/help/Settings.svg';
import Billing from '../../assets/images/help/Billing.svg';
import Calendar from '../../assets/images/help/Calendar.svg';
import { Categorie } from './Categories';
import { Api } from "../../api/api";
import { notification } from 'antd';
import { IHelpCategories } from '../../api/types';



const SearchContainer = styled(Box)`
    padding: 20px 16px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
`;



export const HelpPage: React.FC = () => {

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<IHelpCategories[]>([]);

    const categoriesImages = [
        {
            image: Billing,
        },
        {
            image: Settings,
        },
        {
            image: Calendar,
        },
        {
            image: Book,
        },
    ];

    useEffect(() => {
        setLoading(true);
        try {
            Api.help.getCategories().then(data => {
                setCategories([...data.data])
            })

        } catch (e) {
            notification.error({
                message: 'Something went wrong'
            });
        } finally {
            setLoading(false);
        }

    }, []);


    return <Flex p='34px' pt='15px' flexDirection='column'>
        <Title>Support</Title>
        <Container>
            <SearchContainer>
                <SearchWithBorder
                    placeholder="Search here"
                    // onChange={({ target: { value } }) => setSearch(value)}
                    onChange={e => setSearch(e.target.value)}
                />
            </SearchContainer>
            {loading ? <Spin /> : <Flex p='22px 24px' flexWrap='wrap' width='100%' justifyContent='space-between'>
                {categories.map((el, idx) =>
                    <Categorie categories={el} categoriesImages={categoriesImages} id={idx} />
                )}
            </Flex>
            }
        </Container>
    </Flex>
};