import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import Flex from "../../noui/Flex"
import AssignedJobs from "./AssignedJobs"
import Dispatch from "./Dispatch"
import Updates from "./Updates"
import JobsTable from "./JobsTable"
import { IJob, ITimeClock, IUser } from "../../api/types"
import { Api, TSchedule } from "../../api/api"
import { useLoading, useResponsive } from "../../utils/hooks"
import { Spin } from "antd"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"

const DispatchLayout = () => {
   const [users, setUsers] = useState<IUser[]>([])
   const [schedules, setSchedules] = useState<TSchedule[]>([])
   const [conflicts, setConflicts] = useState<TSchedule[]>([])
   const [timeclocks, setTimeclocks] = useState<ITimeClock[]>([])
   const [unscheduledJobs, setUnscheduledJobs] = useState<IJob[]>([])
   const [loading, onAction] = useLoading()
   const isXs = useBreakpoint(down("xs"))
   const isLg = useBreakpoint(down("lg"))
   const { width } = useResponsive()

   useEffect(() => {
      onAction(
         Api.user.getAll({}).then((res) => {
            const data = res.data as IUser[]
            setUsers(data)
         })
      ).finally()
      onAction(
         Api.schedules.getAll({}).then((res) => {
            const data = res.data as TSchedule[]
            setSchedules(data)
         })
      ).finally()
      onAction(
         Api.schedules.getConflicts({}).then((res) => {
            const data = res.data as TSchedule[]

            setConflicts(data)
         })
      ).finally()
      onAction(
         Api.timeClocks.getAll({}).then((res) => {
            const data = res.data as ITimeClock[]
            setTimeclocks(data)
         })
      ).finally()
      onAction(
         Api.jobs.getAll({ unscheduled: true }).then((res) => {
            const data = res.data as IJob[]
            setUnscheduledJobs(data)
         })
      ).finally()
   }, [onAction])

   return (
      <>
         <Helmet>
            <title>Dispatch Board - DockWorks Pro</title>
         </Helmet>

         <Dispatch>
            <Spin spinning={loading}>
               <Flex flexDirection="column" padding={isXs ? "0 11px" : "20px 24px 30px 29px"}>
                  {!!users.length && (
                     <>
                        <Flex flexDirection={width <= 1400 ? "column" : "row"}>
                           <AssignedJobs users={users} />
                           <Updates users={users} />
                        </Flex>
                        <JobsTable
                           users={users}
                           schedules={schedules}
                           timeclocks={timeclocks}
                           unscheduled={unscheduledJobs}
                           conflicts={conflicts}
                        />
                     </>
                  )}
               </Flex>
            </Spin>
         </Dispatch>
      </>
   )
}

export default DispatchLayout
