import styled, { StyledComponent } from "styled-components";
import { ERequestPriority, TRequest } from "../../../api/api";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { CloseWarning } from "../../../assets/icons";
import { Button } from "antd";
import React from "react";

type TWarningBlockProps = {
  priority: ERequestPriority;
};

const WarningBlock = styled(Flex)<TWarningBlockProps>`
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.priority !== ERequestPriority.Decline ? "#FBCA4D" : "#FB4D4F"};
  border-radius: 4px;
  padding: 4px 12px;
  width: 100%;
  margin-bottom: 12px;
` as StyledComponent<typeof Flex, TWarningBlockProps>;

const StyledButton = styled(Button)`
  border: none;
  background: none;
  height: 24px;
  width: 24px;
  padding: 0;
`;

type TWarningProps = {
  warning: TRequest;
  onClose: (index: number) => void;
  technicianRole: boolean;
};

export const Warning: React.FC<TWarningProps> = ({
  warning,
  onClose,
  technicianRole,
}) => {
  const formatTitle = () => {
    switch (warning.priority) {
      case ERequestPriority.Normal:
        return "Edit Service Plan:";
      case ERequestPriority.Decline:
        return "Decline Estimate:";
      default:
        return "Request Changes:";
    }
  };

  const handleClose = () => onClose(warning.id);

  return (
    <WarningBlock priority={warning.priority}>
      <Flex alignItems="center">
        <Msg
          fontSize={13}
          fontWeight={700}
          color="#202020"
          marginRight="8px"
          style={{
            minWidth: "115px",
          }}
        >
          {formatTitle()}
        </Msg>
        <Msg
          fontSize={13}
          fontWeight={600}
          color="#494949"
          style={{
            wordBreak: "break-word",
          }}
        >
          {warning.message}
        </Msg>
      </Flex>
      {!technicianRole && (
        <StyledButton onClick={handleClose}>
          <CloseWarning />
        </StyledButton>
      )}
    </WarningBlock>
  );
};
