import { useState } from "react"

import styled from "styled-components"
import { Switch, Menu, Modal, Popover } from "antd"
import { DeleteFilled, CopyOutlined } from "@ant-design/icons"
import { EditIcon } from "../../assets/icons"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import { TArgCallback } from "../../types"
import DotsIcons from "../../assets/images/dots_more.svg"
import ModalFormEditAdd from "./ModalFormEditAdd"
import { servicesUnitTypes } from "../../constants"

const TdInner = styled.div`
   position: relative;
   p {
      position: absolute;
      top: 100%;
      left: 0;
      margin: 0;
      color: #ff4d4f;
      min-width: 80px;
   }
   a {
      width: 100%;
      text-align: center;
   }
   .ant-popover-inner-content {
      padding: 0 !important;
   }
`
const StyledMenu = styled(Menu)`
   padding: 10px;
   font-size: 13px;
   border-radius: 4px;
   color: #494949;
   svg,
   img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
   }
   li {
      padding: 3px 10px !important;
      height: auto !important;
      line-height: 1.2 !important;

      &:hover {
         background: none;
      }
   }
`

const OptionButton = styled(EmptyButton)`
   &.ant-btn {
      background: none !important;
   }
   svg {
      color: #8b8b8b;
   }
   &:hover {
      svg {
         color: #0496ff;
         path {
            fill: #0496ff !important;
            fill-opacity: 1;
         }
      }
   }
`

const StyledModal = styled(Modal)`
   max-width: 350px;
   & .ant-modal-body {
      padding: 16px 60px 16px 16px;
   }

   h3 {
      font-size: 15px;
      font-weight: bold;
   }
`

type TProps = {
   item: any
   // deleteGroup: TCallback
   handleEditMaterial: TArgCallback<any>
   deleteMaterial: TArgCallback<any>
   duplicateMaterial: TArgCallback<any>
   handleCreateGroupe: TArgCallback<any>
   group: any
   groups: any
   columns: any
   type?: string
}

const TableRow: React.FC<TProps> = ({
   item,
   handleEditMaterial,
   deleteMaterial,
   duplicateMaterial,
   handleCreateGroupe,
   group,
   groups,
   columns,
   type,
}) => {
   const [showEditModal, setShowEditModal] = useState(false)

   const [showDeleteModal, setShowDeleteModal] = useState(false)

   const handleSwitchChange = (e: any) => {
      handleEditMaterial({
         ...item,
         active: e,
         group,
      })
   }

   const handleDelete = () => {
      setShowDeleteModal(false)
      deleteMaterial(item)
   }

   const clearModal = () => {
      setShowEditModal(false)
   }

   const findUnit = (value: string) => {
      if (value) {
         const item = servicesUnitTypes.find((item) => item.value === value)
         return item?.label
      }
      return null
   }

   const menu = (
      <StyledMenu>
         <Menu.Item key="0">
            <OptionButton onClick={() => duplicateMaterial(item)}>
               <Flex alignItems="center">
                  <CopyOutlined />
                  Duplicate
               </Flex>
            </OptionButton>
         </Menu.Item>
         <Menu.Item key="1">
            <OptionButton onClick={() => setShowEditModal(true)}>
               <Flex alignItems="center">
                  <EditIcon />
                  Edit
               </Flex>
            </OptionButton>
         </Menu.Item>
         <Menu.Item key="2">
            <OptionButton onClick={() => setShowDeleteModal(true)}>
               <Flex alignItems="center">
                  <DeleteFilled />
                  Delete
               </Flex>
            </OptionButton>
         </Menu.Item>
      </StyledMenu>
   )

   return (
      <>
         {columns &&
            columns.length > 0 &&
            columns.map((col: any) => {
               if (col.name === "cost" || col.name === "price" || col.name === "unit_cost") {
                  return (
                     <td>
                        <TdInner>${item[col.name].toFixed(2)}</TdInner>
                     </td>
                  )
               } else if (col.name === "unit") {
                  return (
                     <td>
                        <TdInner>{findUnit(item[col.name])}</TdInner>
                     </td>
                  )
               }
               return (
                  <td>
                     <TdInner style={{ minWidth: col.name === "description" ? "190px" : "100px" }}>
                        {item[col.name]}
                     </TdInner>
                  </td>
               )
            })}

         <td>
            <Switch checked={item.active} onChange={handleSwitchChange} />
         </td>
         <td>
            <TdInner>
               <Popover
                  placement="bottomRight"
                  trigger="click"
                  content={menu}
                  getPopupContainer={(trigger: any) => trigger.parentElement}
               >
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} style={{ display: "block" }}>
                     <img src={DotsIcons} alt="icon" />
                  </a>
               </Popover>
            </TdInner>{" "}
            <StyledModal
               destroyOnClose
               centered
               visible={showDeleteModal}
               onOk={handleDelete}
               onCancel={() => setShowDeleteModal(false)}
               okText="Ok"
            >
               <h3>{`Are you sure want to delete ${item.name}?`}</h3>
            </StyledModal>
            <ModalFormEditAdd
               visible={showEditModal}
               handleSubmit={handleEditMaterial}
               clearModal={clearModal}
               activeGroup={group}
               groups={groups}
               handleCreateGroupe={handleCreateGroupe}
               values={item}
               edit={true}
               type={type}
            />
         </td>
      </>
   )
}
export default TableRow
