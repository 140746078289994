import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import moment from "moment"
import { Modal, Radio } from "antd"
import { IMyJob } from "../api/types"
import { TCallback, TArgCallback } from "../types"
import { Msg } from "../ui/Text"

type TProps = {
   visible: boolean
   handleClose: TCallback
   jobs?: IMyJob[]
   activeJob?: IMyJob
   changeJob: TArgCallback<number>
}
type TJobsTiming = {
   older: IMyJob[]
   current: IMyJob[]
   next: IMyJob[]
}

export const MobileWrapper = styled.div`
   ${down("lg")} {
      .ant-picker-datetime-panel {
         flex-direction: column !important;
      }
   }
`

export const ChangeJobTechnician: React.FC<TProps> = ({ visible, handleClose, jobs, activeJob, changeJob }) => {
   const [value, setValue] = useState<any>(null)

   useEffect(() => {
      setValue(activeJob?.job?.id)
   }, [activeJob])

   const dateNow = new Date().toISOString()

   const onChange = (e: any) => {
      setValue(e.target.value)
   }

   const clearModal = () => {
      setValue(activeJob?.job?.id)
      handleClose()
   }

   const handleSubmit = async () => {
      changeJob(value)
      handleClose()
   }

   const renderList = () => {
      let older: IMyJob[] = []
      let current: IMyJob[] = []
      let next: IMyJob[] = []

      jobs?.forEach((element) => {
         if (element.start < dateNow && element.end < dateNow) {
            older.push(element)
         } else if (element.start === dateNow || (element.end > dateNow && element.start < dateNow)) {
            current.push(element)
         } else {
            next.push(element)
         }
      })

      return {
         older,
         current,
         next,
      }
   }

   const renderTitles = (title: string) => {
      if (title === "older") {
         return (
            <Msg fontWeight="bold" fontSize={13} color="#42C77B" textAlign="center" display="block" margin="10px 0">
               PAST JOBS
            </Msg>
         )
      } else if (title === "current") {
         return (
            <Msg fontWeight="bold" fontSize={13} color="#257ABF" textAlign="center" display="block" margin="10px 0">
               CURRENT(NEXT) JOB
            </Msg>
         )
      } else {
         return (
            <Msg fontWeight="bold" fontSize={13} color="#62AEE0" textAlign="center" display="block" margin="10px 0">
               FUTURE JOBS
            </Msg>
         )
      }
   }

   const obj = renderList() as TJobsTiming

   return (
      <Modal visible={visible} onCancel={clearModal} title="Change job" okText="Save" onOk={handleSubmit} width={352}>
         <MobileWrapper>
            {Object.keys(obj).length > 0 &&
               Object.keys(obj).map((item) => {
                  if (obj[item as keyof TJobsTiming].length > 0) {
                     return (
                        <div key={item}>
                           {renderTitles(item)}
                           <Radio.Group onChange={onChange} value={value}>
                              {obj[item as keyof typeof obj].map((el) => {
                                 return (
                                    <Radio
                                       value={el.job.id}
                                       key={el.job.id}
                                       style={{ fontWeight: item === "current" ? "bold" : "normal" }}
                                    >
                                       {`${moment(el.start).format("L")} ${el.job.order_number} ${
                                          el.job.services.length > 0 ? el.job.services[0].name : "none"
                                       }`}
                                    </Radio>
                                 )
                              })}
                           </Radio.Group>
                        </div>
                     )
                  }
               })}
         </MobileWrapper>
      </Modal>
   )
}
