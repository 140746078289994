import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Flex from "../../noui/Flex";
import Hr from "../../ui/Hr";
import {
  SubscriptionCardDefaultText,
  SubscriptionCardPriceText,
  SubscriptionCardTitle,
} from "../settings/SubscriptionCard";
import { ChangePlanButton, SCCollapseWithButton } from "./styled";
import { ReactComponent as CheckDefault } from "../../assets/images/CheckDefault.svg";

import { useSelector } from "react-redux";
import { getPaymentData } from "../../config/reducers/payment/paymentSlice";
import { Msg } from "../../ui/Text";
import React from "react";

type TProps = {
  title: string;
  monthly_price: number;
  annually_price: number;
  description: string;
  options: string;
  isOpen: boolean;
  setActive: () => void;
  setOpen: () => void;
  onboarding_fee: number;
};

const SubscriptionCollapse: React.FC<TProps> = ({
  title,
  monthly_price,
  onboarding_fee,
  annually_price,
  description,
  options,
  setActive,
  isOpen,
  setOpen,
}) => {
  const { payment_period } = useSelector(getPaymentData);

  return (
    <SCCollapseWithButton>
      <Flex justifyContent="space-between" width="100%" alignItems="baseline">
        <Flex flexDirection="column">
          <SubscriptionCardTitle isActive={false}>
            {title}
          </SubscriptionCardTitle>
          <Flex alignItems="baseline">
            <SubscriptionCardPriceText
              isActive={false}
              fontSize="24px"
              fontWeight="800"
            >
              $
              {payment_period === "M"
                ? monthly_price
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : (annually_price / 12)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </SubscriptionCardPriceText>

            <SubscriptionCardPriceText
              isActive={false}
              fontSize="15px"
              fontWeight="400"
            ></SubscriptionCardPriceText>
          </Flex>
        </Flex>
        <Flex onClick={setOpen}>
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </Flex>
      </Flex>
      <SubscriptionCardDefaultText
        isActive={false}
        fontSize="11px"
        fontWeight="400"
      >
        {description}
      </SubscriptionCardDefaultText>

      {payment_period === "M" && (
        <Msg color="#FB4D4F">
          Onboarding fee $
          {onboarding_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Msg>
      )}

      {isOpen && (
        <Flex flexDirection="column">
          <Hr />
          <Flex flexDirection="column" marginTop="10px">
            {!!options &&
              options.split("\r\n").map((option, i) => (
                <Flex marginTop="16px" key={i} width="100%">
                  <Flex marginRight="12px">
                    <CheckDefault />
                  </Flex>
                  <SubscriptionCardDefaultText isActive={false} fontSize="15px">
                    {option}
                  </SubscriptionCardDefaultText>
                </Flex>
              ))}
          </Flex>
          <ChangePlanButton onClick={setActive}>
            {"Get Started"}
          </ChangePlanButton>
        </Flex>
      )}
    </SCCollapseWithButton>
  );
};

export default SubscriptionCollapse;
