import styled from "styled-components";
import { TPaymentStripe } from "../../../api/api";
import { TCallback } from "../../../types";
import { IExternalEstimate, IExternalRecurring } from "../../../api/types";
import React from "react";
import { TCheckoutForm } from "./CheckoutForm";
import { CardIcon } from "../../../assets/icons/siteIcons";
import { CheckOutlined } from "@ant-design/icons";
interface Props {
  onSelected: boolean;
}
export type TSaveCardItem = {
  onSelected: boolean;
  isCardComponent?: boolean;
  itemCardTittle: string;
};
export type PCardItemTittle = {
  isCardComponent?: boolean;
};
const CardItem = styled.div<Props>`
  border: 1px solid ${(props) => (props.onSelected ? "#096DD9" : "#EDEDED")};
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 14px;
  outline: 0;
  width: 100%;
  cursor: pointer;
`;
const CardIconItem = styled.div`
  margin-top: 2.5px;
`;
const CardItemTittle = styled.div<PCardItemTittle>`
  width: 100%;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 700;
  color: ${(props) => (props.isCardComponent ? "#494949" : "#0496FF")};
`;
const SaveCardItem: React.FC<TSaveCardItem> = ({
  onSelected,
  itemCardTittle,
  isCardComponent,
}) => {
  return (
    <CardItem onSelected={onSelected}>
      <CardIconItem>
        <CardIcon color={onSelected ? "#0496FF" : "#DADADA"} />
      </CardIconItem>
      <CardItemTittle isCardComponent={isCardComponent}>
        {itemCardTittle}
      </CardItemTittle>
      {onSelected && (
        <CheckOutlined
          style={{ fontSize: "16px", color: "#0496FF", marginTop: "2.5px" }}
        />
      )}
    </CardItem>
  );
};

export default SaveCardItem;
