import React, { useState, useEffect, useCallback } from "react"
import { Modal, Form, Input, Select, DatePicker, Button, Dropdown, Menu, notification, Radio } from "antd"
import { PlusCircleFilled, MinusCircleOutlined, DownOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { Api } from "../api/api"
import { IVessel, ICustomer, IUser, EUserRole } from "../api/types"
import { useLoading } from "../utils/hooks"
import { PALETTE, DefaultDateTimeFormat } from "../constants"

import { Msg } from "../ui/Text"
import Flex from "../noui/Flex"
import { TCallback, TArgCallback } from "../types"
import moment from "moment"
import { down } from "styled-breakpoints"
import { MessagesModal } from "./Messages/MessagesModal"

type TProps = {
   visible: boolean
   onClose: TCallback
}

const StyledModal = styled(Modal)`
   .ant-modal-close {
      right: 0;
   }
   .ant-modal-body {
      padding: 0;
   }
   ${down("xs")} {
      .ant-modal-close {
         right: 0;
      }
   }
`

const ModalWrapper = styled(Flex)`
   align-items: stretch;
   ${down("lg")} {
      .ant-picker-datetime-panel {
         flex-direction: column !important;
      }
   }
`

const FormContainer = styled.div`
   width: 100%;
   ${down("xs")} {
      width: 100%;
   }
`

const FormRow = styled(Flex)`
   gap: 16px;
   align-items: center;
   & > * {
      width: calc(50% - 4px);
   }
   ${down("xs")} {
      gap: 0px;
      & > *:first-child {
         margin-right: 16px;
      }
   }
`

const AddButton = styled(Button)`
   font-size: 13px;
   font-weight: 700;
   padding: 0;
   display: inline-flex;
   align-items: center;
   margin: 8px 0;
   flex-direction: row;
`

const Footer = styled.div`
   border-top: 1px solid #e6e6e6;
   text-align: right;
   padding: 20px;
   ${down("xs")} {
      padding: 14px;
   }
`
const Header = styled.div`
   padding: 20px;
   ${down("xs")} {
      padding: 14px;
   }
`

const MenuItem = styled.div`
   width: 73px;
   padding: 8px 10px;
   &:hover {
      cursor: pointer;
   }
`

const PriorityOption = styled.div<{ background: string | undefined }>`
   border-radius: 4px;
   background-color: ${(props) => props.background};
   width: 30px;
   height: 20px;
`

const StyledDropdown = styled(Dropdown)`
   width: 53px;
   &:hover {
      cursor: pointer;
   }
`

export const SwitchButton = styled(Radio)`
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   display: inline-flex;
   padding: 0 14px;
   background: #fff;
   justify-content: space-between;
   border: none;
   align-items: center;
   height: 40px;
   width: calc(50% - 8px);
   border-radius: 4px;
   .ant-radio {
      margin-bottom: 5px;
   }
   &:after {
      content: none;
   }
   .ant-radio-inner,
   .ant-radio-inner:after {
      border-radius: 0 !important;
   }
   .ant-radio-inner:after {
      border: none !important;
   }
`

type TRProps = {
   technicians: IUser[]
   field: any
   onRemove: TArgCallback<number | number[]>
   showRemove: boolean
}

const TechnicianRow: React.FC<TRProps> = ({ technicians, field, onRemove, showRemove }) => {
   const [rate, setRate] = useState(0)
   const [hours, setHours] = useState(0)
   return (
      <Flex alignItems="center" style={{ gap: "8px" }}>
         <Flex flexDirection="column">
            <FormRow>
               <Form.Item
                  name={[field.name, "technician"]}
                  fieldKey={[field.fieldKey, "technician"]}
                  label="Technician"
               >
                  <Select placeholder="Type here">
                     {technicians.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                           {t.full_name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <Form.Item name={[field.name, "rate"]} fieldKey={[field.fieldKey, "rate"]} label="Rate">
                  <Input onChange={(e) => setRate(+e.target.value)} min={0} type="number" placeholder="Rate" />
               </Form.Item>
            </FormRow>
            <FormRow>
               <Form.Item name={[field.name, "est_hours"]} fieldKey={[field.fieldKey, "est_hours"]} label="Est. Hours">
                  <Input onChange={(e) => setHours(+e.target.value)} min={0} type="number" placeholder="Hours" />
               </Form.Item>
               <Flex flexDirection="column" marginBottom="16px">
                  <label style={{ fontWeight: "bold", paddingTop: "8px" }}>Est. Total</label>
                  <Input readOnly value={rate * hours || ""} placeholder="Total" />
               </Flex>
            </FormRow>
         </Flex>
         {showRemove ? (
            <Form.Item>
               <Form.Item {...field}>
                  <MinusCircleOutlined style={{ position: "relative", top: 21 }} onClick={() => onRemove(field.name)} />
               </Form.Item>
            </Form.Item>
         ) : null}
      </Flex>
   )
}

const priorities = [
   { value: "N", background: "#109CF1" },
   { value: "U", background: "#FF0000" },
]

export const AppointmentModal: React.FC<TProps> = ({ visible, onClose }) => {
   const [form] = Form.useForm()
   const [vessels, setVessels] = useState<IVessel[]>([])
   const [customers, setCustomers] = useState<ICustomer[]>([])
   const [technicians, setTechnicians] = useState<IUser[]>([])
   const [loading, onCall] = useLoading()
   const [priority, setPriority] = useState<string>("N")
   const [optionsVisible, setOptionsVisible] = useState<boolean>(false)
   const [messagesVisible, setMessagesVisible] = useState<boolean>(false)
   const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null)
   const [dataToSend, setDataToSend] = useState<any>(null)

   useEffect(() => {
      if (visible) {
         form.resetFields()
         setPriority("N")
      }
   }, [visible, form])

   const loadCards = useCallback(async () => {
      if (visible) {
         const { data: customers } = await onCall(Api.customers.getAll({}))
         const { data: vessels } = await onCall(Api.vessels.getAll({}))
         const { data: technicians } = await onCall(Api.user.getAll({}))
         setCustomers(customers)
         setVessels(vessels)
         setTechnicians(technicians)
      }
   }, [visible, onCall])

   useEffect(() => {
      loadCards()
   }, [loadCards])

   const showNotification = (description: string) => {
      notification.error({
         message: "Error",
         description: description,
      })
   }

   const formatDate = (date: string, time: string) => {
      const start = new Date(date)
      const end = new Date(time)
      const res = moment(start).set({
         hour: end.getHours(),
         minute: end.getMinutes(),
         second: end.getSeconds(),
      })
      return res.toISOString()
   }

   const createAppointment = async () => {
      const data = await form.validateFields()
      if (!data.vessel) {
         showNotification("Choose vessel")
         return
      }
      if (!data.customer) {
         showNotification("Choose customer")
         return
      }
      if (!data.purpose_of_visit) {
         showNotification("Choose purpose of visit")
         return
      }
      setDataToSend({
         ...data,
         priority,
         ...(data.start &&
            data.end && {
               schedule: {
                  start: data.start,
                  end: data.end,
               },
            }),
         ...(data.technicians &&
            data.technicians.length && {
               technicians: data.technicians.map((t: any) => {
                  if (t.rate && t.est_hours && t.technician) {
                     return {
                        technician: t.technician,
                        rate: +t.rate,
                        est_hours: +t.est_hours,
                        est_total: +t.rate * +t.est_hours,
                     }
                  }
                  return null
               }),
            }),
      })
      setSelectedCustomer(data.customer)
      setMessagesVisible(true)
   }

   const changePriority = (value: string) => {
      setPriority(value)
      setOptionsVisible(false)
   }

   return (
      <StyledModal footer={null} width={350} visible={visible} onCancel={onClose}>
         <Form form={form} layout="vertical">
            <ModalWrapper>
               <FormContainer>
                  <Header>
                     <Msg fontSize={15} fontWeight={900}>
                        NEW APPOINTMENT
                     </Msg>
                     <Form.Item
                        name="vessel"
                        label="Vessel Name"
                        required
                        rules={[
                           {
                              required: true,
                              message: "Please select Vessel Name",
                           },
                        ]}
                     >
                        <Select placeholder="Select a vessel" getPopupContainer={(trigger) => trigger.parentElement}>
                           {vessels.map((v) => (
                              <Select.Option value={v.id} key={v.id}>
                                 {v.name}
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="customer"
                        label="Customer Name"
                        rules={[
                           {
                              required: true,
                              message: "Please select Customer Name",
                           },
                        ]}
                     >
                        <Select placeholder="Select a customer" getPopupContainer={(trigger) => trigger.parentElement}>
                           {customers.map((c) => (
                              <Select.Option value={c.id} key={c.id}>
                                 {c.full_name}
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="purpose_of_visit"
                        label="Purpose of Visit"
                        rules={[
                           {
                              required: true,
                              message: "Please input Purpose of Visit",
                           },
                        ]}
                     >
                        <Input
                           placeholder="Type here"
                           addonAfter={
                              <Form.Item name="priority" style={{ marginBottom: 0, height: "30px" }}>
                                 <StyledDropdown
                                    visible={optionsVisible}
                                    onVisibleChange={() => setOptionsVisible(!optionsVisible)}
                                    trigger={["click"]}
                                    overlay={() => (
                                       <Menu style={{ marginLeft: "-11px", marginTop: "2px" }}>
                                          {priorities.map((p) => (
                                             <MenuItem onClick={() => changePriority(p.value)}>
                                                <PriorityOption background={p.background}></PriorityOption>
                                             </MenuItem>
                                          ))}
                                       </Menu>
                                    )}
                                 >
                                    <Flex justifyContent="space-between" alignItems="center">
                                       <PriorityOption
                                          background={priorities.find((p) => p.value === priority)?.background}
                                       ></PriorityOption>
                                       <DownOutlined />
                                    </Flex>
                                 </StyledDropdown>
                              </Form.Item>
                           }
                        />
                     </Form.Item>
                     <Form.List name="technicians">
                        {(fields, { add, remove }) => (
                           <>
                              {fields.map((field, idx) => (
                                 <TechnicianRow
                                    technicians={technicians}
                                    showRemove={fields.length > 1}
                                    field={field}
                                    key={field.key}
                                    onRemove={remove}
                                 />
                              ))}
                              <Form.Item>
                                 <AddButton type="link" onClick={() => add()}>
                                    <PlusCircleFilled
                                       style={{
                                          color: PALETTE.Secondary.Medium,
                                          fontSize: 17,
                                       }}
                                    />{" "}
                                    Add Technician
                                 </AddButton>
                              </Form.Item>
                           </>
                        )}
                     </Form.List>
                     <Form.Item name="start" label="Start date & time">
                        <DatePicker
                           format={DefaultDateTimeFormat}
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           showTime={{ format: "HH:mm", use12Hours: true }}
                           style={{ width: "100%" }}
                           disabledDate={(current) => current && current < moment().add(-1, "days")}
                        />
                     </Form.Item>
                     <Form.Item name="end" label="End date & time">
                        <DatePicker
                           format={DefaultDateTimeFormat}
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           showTime={{ format: "HH:mm", use12Hours: true }}
                           style={{ width: "100%" }}
                           disabledDate={(current) => current && current < moment().add(-1, "days")}
                        />
                     </Form.Item>
                     <Form.Item name="notes" label="Notes">
                        <Input.TextArea placeholder="This is where message to the client can be left." />
                     </Form.Item>
                  </Header>
                  <Footer>
                     <Button style={{ background: "transparent", width: "calc(50% - 4px)" }} onClick={onClose}>
                        Cancel
                     </Button>
                     <Button
                        style={{ marginLeft: 8, width: "calc(50% - 4px)" }}
                        type="primary"
                        loading={loading}
                        onClick={createAppointment}
                     >
                        Save
                     </Button>
                  </Footer>
               </FormContainer>
            </ModalWrapper>
         </Form>
         <MessagesModal
            visible={messagesVisible}
            onClose={() => {
               setMessagesVisible(false)
            }}
            customer={selectedCustomer ?? 0}
            onSubmit={async () => {
               await Api.appointments.create(dataToSend)
               onClose()
            }}
         />
      </StyledModal>
   )
}
