import styled from 'styled-components';
import Box from '../../../noui/Box';
import React, { useEffect, useState } from 'react';
import { useLoading } from '../../../utils/hooks';
import { Api } from '../../../api/api';
import GoogleMapReact from 'google-map-react';
import { Marker } from '../../customers/pages/styled';
import { Spin } from 'antd';
import moment from 'moment';
import { GoogleMapsAPIKey } from '../../../api';

const Wrapper = styled.div`
  margin: 10px 20px;
`;

const MapWrap = styled(Box)`
  width: 100%;
  height: 360px;
  border-radius: 4px;
  div {
    border-radius: 4px;
  }
`;


const MapView = () => {
  const [mapsOptions, setMapsOptions] = useState(
    {
      center: {
        lat: 40,
        lng: -73
      },
      zoom: 1,
      mapTypeControl: true,
    }
  );

  const [schedules, setSchedules] = useState([]);
  const [loading, onAction] = useLoading();
  const [vesselData, setVesselData] = useState([]);   

  useEffect(()=>{
    onAction(
      Api.schedules.getAll({time_after: moment().startOf('day').toISOString(), time_before: moment().endOf("month").toISOString()}).then((res) => {
          const data = res.data;
          setSchedules(data);
      })
  ).finally();
  }, [onAction]);

  useEffect(() => {
    if(schedules.length) {
      const newVesselData = [];
      schedules.filter(schedule => (schedule.address?.lat &&  schedule.address?.lng)).forEach(s => {
          if(!newVesselData.some(sch => sch.lat === s.address?.lat && sch.lng === s.address?.lng))
          {
              newVesselData.push({
                    lat: s.address?.lat ?? 0,
                    lng: s.address?.lng ?? 0
                })  
            }
      });
      setVesselData(newVesselData);
    }
  },[schedules]);

  return <Wrapper className='little-cart'>
    <Spin spinning={loading}>
    <MapWrap>      
      <GoogleMapReact
        bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
        defaultCenter={mapsOptions.center}
        defaultZoom={mapsOptions.zoom}
        mapTypeId='satellite'
        options={mapsOptions}
      >
       {vesselData.length && vesselData.map((item, index) => <Marker lat={item.lat} lng={item.lng} />)}
      </GoogleMapReact>      
    </MapWrap>
    </Spin>
  </Wrapper>
}

export default MapView;