import { Modal } from "antd"
import { useHistory } from "react-router-dom";
import styled from "styled-components"
import routes from "../../routes/routes";
import { TCallback } from "../../types"
import { Msg } from "../../ui/Text";

const StyledModal = styled(Modal)`
    .ant-modal-title {
        color: #FB4D4F;
    }
`;

type TRPMWarningModalProps = {
    visible: boolean
    onClose: TCallback
}

export const RPMWarningModal: React.FC<TRPMWarningModalProps> = ({visible, onClose}) => {
    const history = useHistory();

    const onOk = () => {
        history.push(routes.settings.Payments)
        onClose();
    }

    return (
        <StyledModal 
            visible={visible}
            okText="Setup Payments"
            title="GET STARTED WITH PAYMENTS"
            onCancel={onClose}
            onOk={onOk}
            width={384}
        >
            <Msg mb="16px">
                The account owner needs to activate online billing before you can request payment from customers.
            </Msg>
        </StyledModal>
    );
}
