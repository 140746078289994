import { Progress } from "antd"
import React from "react"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { IStats } from "../../../api/types"
import { formatToUSD } from "../../../utils/utils"

type TProps = {
   statsData: IStats | null
}
const OpenInvoices: React.FC<TProps> = ({ statsData }) => {
   const { invoices_due, invoices_paid } = statsData || { invoices_due: 0, invoices_paid: 0 }
   const total = invoices_due + invoices_paid

   return (
      <Flex className="little-cart" width="195px" p="10.17px" flexDirection="column">
         <Msg fontWeight={400} fontSize={13} color="#494949">
            Open Invoices
         </Msg>
         <Flex flexDirection="column" m="auto">
            <Msg fontWeight={400} fontSize={13} color="#494949">
               Total
            </Msg>
            <Msg fontWeight={800} fontSize={25} color="#494949">
               {formatToUSD(total)}
            </Msg>
         </Flex>
         <Flex flexDirection="column" width="100%">
            <Flex justifyContent="space-between">
               <Msg fontWeight={900} fontSize={13} color="#FF4E50">
                  Due
               </Msg>
               <Msg fontWeight={900} fontSize={13} color="#151F2B">
                  {formatToUSD(invoices_due)}
               </Msg>
            </Flex>
            <Progress
               percent={total ? (invoices_due / total) * 100 : 0}
               strokeColor="#FF4E50"
               className="progress"
               size="small"
               format={() => ""}
            />
            <Flex justifyContent="space-between">
               <Msg fontWeight={900} fontSize={13} color="#44CD7F">
                  Paid
               </Msg>
               <Msg fontWeight={900} fontSize={13} color="#151F2B">
                  {formatToUSD(invoices_paid)}
               </Msg>
            </Flex>
            <Progress
               percent={total ? (invoices_paid / total) * 100 : 0}
               className="progress"
               strokeColor="#44CD7F"
               size="small"
               format={() => ""}
            />
         </Flex>
      </Flex>
   )
}

export default OpenInvoices
