import React, {useCallback, useEffect, useRef, useState} from 'react';
import {TModalProps} from "../../types";
import {Divider, Modal} from "antd";
import {useDebounce} from "use-debounce/esm";
import {Api} from "../../api/api";
import styled from "styled-components";
import {ICompany, ICompanyAccessLog, IPaginatedResponse} from "../../api/types";
import {useAppDispatch, useAppSelector} from "../../config/hooks";
import {getCurrentUser} from "../../config/reducers/user/selectors";
import {setCurrentUser} from "../../config/reducers/user/actions";
import Routes from "../../routes/routes";
import {useHistory} from "react-router-dom";
import {AccessLog} from "./AccessLog";
import {SearchWithBorder} from "../../noui/Search";
import {useClickOutside} from "../../utils/hooks";
import LOGO from "../../assets/images/gear_logo.png";


const ImgWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
`;


const Overlay = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid #EDEDED;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 35px;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 13px;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    background-color: #FFFFFF;
    transition: background-color .2s linear;
    :hover {
      background-color: #EDEDED;
    }
  }
`;

const Container = styled.div`
  padding-bottom: 20px;
  h4 {
    margin: -8px 0 8px;
    font-size: 13px;
    font-weight: 700;
  }
`;


export const SwitchAccountModal: React.FC<TModalProps> = ({onClose, visible}) => {
  const [search, setSearch] = useState<string>("");
  const [searchResultsVisible, setSearchResultsVisible] = useState<boolean>(false);
  const [dbSearch] = useDebounce(search, 1000);
  const [searchResults, setSearchResults] = useState<ICompany[]>([]);
  const [logs, setLogs] = useState<ICompanyAccessLog[]>([]);
  const user = useAppSelector(getCurrentUser);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const impersonateAccount = useCallback((id: number) => {
    setSearchResultsVisible(false);
    Api.company.impersonate(id).then(({data: user}) => {
      dispatch(setCurrentUser(user));
      history.replace(Routes.main.Dashboard);
      onClose();
      window.location.reload();
    })
  }, [dispatch, history, onClose]);

  useEffect(() => {
    if (dbSearch) {
      Api.company
        .getAll({
          page: 1,
          pageSize: 5,
          search: dbSearch
        })
        .then(({data: d}) => {
          const {results} = d as IPaginatedResponse<ICompany[]>;
          setSearchResults(results);
        }).catch(() => setSearchResults([]));
    } else {
      setSearchResults([]);
    }
  }, [dbSearch]);

  useEffect(() => {
    if (user && visible) {
      setSearch("");
      Api.actionLogs.getAll({page: 1, pageSize: 8, user: user.id}).then(({data}) => {
        const {results} = data as IPaginatedResponse<ICompanyAccessLog[]>;
        setLogs(results);
      });
    }
  }, [user, visible]);

  const ref = useRef(null);
  const handleClickOutsideCallback = useCallback(() => {
    setSearchResultsVisible(false);
  }, []);
  useClickOutside(ref, handleClickOutsideCallback);

  return <Modal
    visible={visible}
    title={"Switch account"}
    onCancel={onClose}
    width={350}
    footer={null}>
    <Container>
      <div style={{position: "relative"}} ref={ref}>
        <SearchWithBorder
            placeholder={"Search account"}
            onFocus={() => setSearchResultsVisible(true)}
            value={search}
            onChange={({target: {value}}) => setSearch(value)}
          />
        {Boolean(searchResults.length && searchResultsVisible) ? <Overlay>
            {searchResults.map(el => <div key={el.id} onClick={() => impersonateAccount(el.id)}>
              <ImgWrapper><img src={el.logo ?? LOGO} alt={`${el.name} logo`}/></ImgWrapper>
              <span>{el.name}</span>
            </div>)}
        </Overlay> : null}
      </div>
      <Divider style={{width: "calc(100% + 48px)", marginLeft: -24}}/>
      <h4>Recently Accessed Accounts</h4>
      {logs.length ? <AccessLog logs={logs} onClick={impersonateAccount} /> : null}
    </Container>
  </Modal>
};