import React, { useState } from "react";
import { Checkbox, Dropdown, Menu, Tabs } from "antd";
import Flex from "../../noui/Flex";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "styled-components";
import { TMFieldInfo } from "../../assets/icons";
import { Msg } from "../../ui/Text";
import { Link } from "react-router-dom";
import routes from "../../routes/routes";

type StyledCBTabsProps = {
   isShort?: boolean
}

export const StyledCBTabs = styled(Tabs)<StyledCBTabsProps>`
   .ant-tabs-tab {
      width: ${props => props.isShort ? "auto" : "110px"};
      border-radius: 4px 4px 0px 0px !important;
      background-color: #EDEDED !important;
      &.ant-tabs-tab-active {
         background-color: #FFFFFF !important;
      }
      justify-content: center;
   }
   .ant-tabs-nav-wrap {
      flex: none !important; 
   }
`;

type TTextMessagingWarningProps = {
   onClose: () => void
}

const isProd = process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "demo"

export const TextMessagingWarning: React.FC<TTextMessagingWarningProps> = ({ onClose }) => {
   const [visible, setVisible] = useState(false);

   const toggleVisible = () => {
      setVisible(prev => !prev)
   }

   const handleClose = () => {
      setVisible(false);
      onClose()
   }


   return (
   <Flex>
      <Dropdown
         visible={visible}
         trigger={["click"]}
         onVisibleChange={(visible) => {
            setVisible(visible)
         }}
         overlay={
            <Menu>
               <Flex flexDirection="column" padding="8px" width="240px">
                  <Msg>
                     Connect with your customers on-the-go. Get approvals and payments faster with SMS.
                  </Msg>
                  <Msg marginTop="20px">
                     Select your dedicated number and get started with text messaging today.
                  </Msg>
                  <Link 
                     to={routes.settings.TextMessaging}
                     style={{fontWeight: 700, fontSize: "15px", marginTop: "20px"}}
                     onClick={handleClose}
                  >
                     {`Activate text messaging >`}
                  </Link>
               </Flex>
            </Menu>
         }
      >
         <Flex marginLeft="8px" onClick={toggleVisible}>
            <TMFieldInfo/>
         </Flex>
      </Dropdown>
   </Flex>
   );
}

export type TCheckboxTabProps = {
   checked: boolean
   onChange: (e: CheckboxChangeEvent) => void
   disabled?: boolean
   title: string
}

export const CheckboxTab: React.FC<TCheckboxTabProps> = ({ checked, onChange, title, disabled }) => {
   const handleChange = (e: CheckboxChangeEvent) => {
      onChange(e);
   };

   const stopPropogation = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
   };

   return (
      <Flex alignItems="center">
         <Checkbox
            checked={checked}
            onChange={handleChange}
            onClick={stopPropogation}
            disabled={!!disabled}
            style={{ marginRight: "8px" }} />
         {title}
      </Flex>
   );
};
