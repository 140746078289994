import { AxiosPromise } from "axios";
import moment from "moment";
import { EScheduleCategory, TAddress, TRecurringConfig, TRequest } from "./api";

export enum EState {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export enum EEpaIncludes {
  PARTS = "PARTS",
  LABOR = "LABOR",
}
export enum ETaxIncludes {
  PARTS = "PARTS",
  LABOR = "LABOR",
  EPA = "EPA",
  SHOP_SUPPLIES = "SHOP_SUPPLIES",
  SUBCONTRACT = "SUBCONTRACT",
}
export enum ECompanySize {
  s = "s",
  m = "m",
  l = "l",
  x = "x",
}
export enum EVesselType {
  SB = "SB",
  PB = "PB",
  PW = "PW",
  OT = "OT",
}
export enum EPhoneType {
  M = "M",
  F = "F",
  T = "T",
  H = "H",
  W = "W",
  P = "P",
  S = "S",
}
export enum ESendType {
  S = "S",
  E = "E",
}
export enum EJobStatus {
  Estimate = "e",
  Progress = "p",
  Completed = "c",
  Finalized = "f",
}
export enum EUnitType {
  PH = "PH",
  PJ = "PJ",
  PF = "PF",
}
export enum EAuthor {
  C = "C",
  T = "T",
}
export enum ENotificationCategory {
  MRFC = "MRFC",
  MSTC = "MSTC",
  SAU = "SAU",
  LIMC = "LIMC",
  SABC = "SABC",
  ACOC = "ACOC",
}
export enum EPaymentType {
  CASH = "CASH",
  CARD = "CARD",
  CHECK = "CHECK",
  OTHER = "OTHER",
  PE = "PE",
}
export enum ECardType {
  MC = "MC",
  VI = "VI",
  AM = "AM",
  OT = "OT",
}
export enum EJobPriority {
  Low = "L",
  Medium = "M",
  High = "H",
  Urgent = "U",
}
export enum EWorkOrderStatus {
  Declined = "D",
  Unscheduled = "U",
  Scheduled = "S",
  InProgress = "P",
  Completed = "C",
}
export enum EUserRole {
  SuperUser = 1,

  Technician = 4,
  Owner = 2,
  Admin = 3,
}
export enum EJobProgress {
  Declined = "D",
  Estimate = "E",
  Schedule = "T",
  Start = "S",
  Work = "W",
  UnFinished = "C",
  Complete = "F",
}

export enum ERecurringStatus {
  NotSet = "N",
  Draft = "D",
  Approved = "A",
  Canceled = "C",
  Overdue = "O",
}
export enum ENotificationStatus {
  Medium = "M",
  High = "H",
  Extreme = "E",
}
export enum ETemplateActions {
  ApproveAppointment = "AA",
  EditEstimate = "EE",
  SendEstimate = "SE",
  SendInvoice = "SI",
  ServicePlan = "SP",
}
export interface IAddress {
  address1: string;
  address2: string;
  city: string;
  state: EState;
  zip_code: string;
  lat: number | null;
  lng: number | null;
  full_address?: string;
}

export interface IShortAddress {
  full_address?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  lat?: number | null;
  lng?: number | null;
}

export interface IInvoiceSettings {
  epa?: number;
  tax?: number;
  epa_includes?: EEpaIncludes[];
  tax_includes?: ETaxIncludes[];
  estimate_footer: string;
  invoice_footer: string;
  show_timestamp: boolean;
  payment_status_wo: string;
  payment_status_sp: string;
}

export interface IWSMessage<T = any> {
  message: T;

  code?: string;
}
export interface IStripe {
  details_submitted: boolean;
  charges_enabled: boolean;
  stripe_id: string;
  active: boolean;
}

export interface IStripeSubscription {
  next_payment: string;
  brand: string;
  last_4: string;
  status: string;
  trial_end: string;
}
export interface IDefaultDeposit {
  amount: number | null;
  percentage: number | null;
}

export interface ICompany {
  id: number;
  name: string;
  logo: string | null;
  default_deposit: IDefaultDeposit;
  address: IAddress | null;
  invoice_settings: IInvoiceSettings | null;
  website: string;
  company_size: ECompanySize;
  timezone: string;
  vessel_types: EVesselType[];
  phone: string;
  email: string;
  payments_active?: boolean;
  payments_status?: string;
  merchant_id?: string;
  mid_hash?: string;
  plan?: ISubscriptionPlan;
  subscription_status: ISubscriptionStatus | null;
  last_4: string;
  service_categories: number[];
  three_d_secure: boolean;
  created: string;
  custom_email_footer: string;
  custom_terms_conditions: string;
  reply_to: string;
  stripe_subscription: IStripeSubscription;
  stripe: IStripe;
  details_submitted: boolean;
  charges_enabled: boolean;
  stripe_id: string;

  active: boolean;
}

export interface ICompanyAccessLog {
  created: string;
  user: number;
  company: ICompany;
}

export interface ICompany {
  id: number;
  name: string;
  logo: string | null;
  address: IAddress | null;
  invoice_settings: IInvoiceSettings | null;
  website: string;
  company_size: ECompanySize;
  timezone: string;
  vessel_types: EVesselType[];
  phone: string;
  email: string;
  payments_active?: boolean;
  payments_status?: string;
  merchant_id?: string;
  mid_hash?: string;
  plan?: ISubscriptionPlan;
  subscription_status: ISubscriptionStatus | null;
  last_4: string;
  service_categories: number[];
  three_d_secure: boolean;
  created: string;
  custom_email_footer: string;
  custom_terms_conditions: string;
  reply_to: string;
  stripe: IStripe;
  first_name: string;
  last_name: string;
  messaging_status: boolean;
}
export interface IExternalInfo {
  uuid: string;
  name: string;
  website: string;
  email: string;
  phone: string;
  logo: string | null;
  custom_terms_conditions: string;
  address: IAddress | null;
}
export interface ITemplateSettings {
  template: number;
  action: ETemplateActions;
}
export interface ISetPlan {
  plan: number;
  cardId: string;
  plan_period: string;

  promotion_code?: string;
}
export interface IUpdatePlan {
  plan: number;
  cardId: string;
  plan_period: string;
  promotion_code?: string;
}

export interface IUpdateCard {
  cardId: string;
  client_secret?: string;
}
export interface IUpdateServiceCategories {
  id: number;
  service_categories: number[];
}
export interface ICreateCompanyForm extends IAddress {
  name: string;
  website?: string;
  logo?: string;
  services: string[];
  company_size: ECompanySize;
  vessel_types: EVesselType[];
  public_phone: string;
  public_email: string;
}
export interface ICreateCompany {
  name: string;
  website?: string;
  logo?: string;
  services: string[];
  company_size: ECompanySize;
  vessel_types: EVesselType[];
  address?: IShortAddress;
}
export interface ICompletions {
  logo: boolean;
  info: boolean;
  invites: boolean;
  job: boolean;
  // quick_books: boolean;
  bank: boolean;
  prices: boolean;
  customers: boolean;
}
export interface IPhone {
  id: number;
  phone: string;
  phone_type: EPhoneType;
}
export interface IUpdatePhone extends Omit<IPhone, "id"> {
  id?: number | string;
}

type TCustomerVessel = {
  hin: string;
  id: number;
  name: string;
  year: number;
};
export interface ICard {
  brand: string;
  last4: string;
  wallet?: any;
  country: string;
  funding: string;
  exp_year: number;
  exp_month: number;
  fingerprint: string;
  generated_from?: any;
}
export interface IDetails {
  id: string;
  card: ICard;
}
export interface ICardDetails {
  updated: Date;
  details: IDetails;
}

type TDetailsAddress = {
  city: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  postal_code: string | null;
  state: string | null;
};

type TDetailsBillingAddress = {
  address: TDetailsAddress;
  email: string | null;
  name: string | null;
  phone: string | null;
};

type TStripeCardChecks = {
  address_line1_check: string | null;
  address_postal_code_check: string | null;
  cvc_check: string | null;
};

type TStripeCard = {
  brand: string;
  checks: TStripeCardChecks;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: any;
  last4: string;
  networks: { available: string[]; preferred: any };
  three_d_secure_usage: { supported: boolean };
  wallet: any;
};

type TDetails = {
  billing_details: TDetailsBillingAddress;
  card: TStripeCard;
  created: number;
  customer: string;
  id: string;
  livemode: boolean;
  metadata: any;
  object: string;
  type: string;
};

type TCardDetails = {
  updated: string;
  details: TDetails;
};

export interface ICustomerShort {
  id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  company_name: string;
}

export interface ICustomer {
  id: number;
  created: string;
  updated: string;
  email: string;
  company_name: string;
  job_title: string;
  phones: IPhone[];
  vessels: TCustomerVessel[];
  annual_invoices: number | null;
  open_invoices: number | null;
  last_appointment: string | null;
  next_appointment: string | null;
  first_name: string;
  last_name: string;
  full_name: string;
  address: IAddress | null;
  note: string;
  annual_spend: number | null;
  card_details: TCardDetails | null;
}

export interface ISendMessage {
  notification_type: ESendType[];
  template?: number;
  customer: number;
  job?: number;
  subject: string;
  content: string;
  sms_content: string;
}
export interface ICreateCustomer extends IAddress {
  email: string;
  phones: IUpdatePhone[];
  first_name: string;
  last_name: string;
  note?: string;
}
export interface IImage {
  id: number;
  image: string;
}
export interface ICreateImage {
  image: string;
}
export enum EInspectionCategory {
  After = "A",
  Before = "B",
}
export interface IInspection {
  id: number;
  created: string;
  name: string;
  job: number;
  notes: string;
  images: IImage[];
  category: EInspectionCategory;
}
export interface ICreateInspection {
  name: string;
  job: number;
  notes?: string;
}
export interface ICreateImages {
  images: ICreateImage[];
}
export interface IInvoice {
  id: number;
  created: string;
  completed: boolean;
  completed_time: string | null;
  amount: number;
  job: IJobParameter;
  pdf_file: string;
  deposit: number;
  work_order: IWorkOrder;
  total?: number;
}
export interface IConfirmation {
  uuid: string;
  collect_payment: boolean;
  display_messages: boolean;
  sender: number;
  created: string;
  send_type: ESendType;
  display_activity_feed: boolean;
  display_authorization_history: boolean;
  attach_receipt: boolean;
  template: number | string;
  content?: string;
  subject?: string;
  sms_content?: string;
  job?: number;
}
export interface ISendConfig {
  created: string;
  sender: number;
  uuid: string;
  work_order: number;
  collect_payment: boolean;
  display_messages: boolean;
  display_activity_feed: boolean;
  display_authorization_history: boolean;
}
export interface ISchedule {
  id?: number;
  created: string;
  start: string | moment.Moment;
  end: string | moment.Moment;
  arrival_notes?: string;
}
export interface IActivity {
  created: string;
  message: string;
  initiator: number;
}
export interface IExtendedActivity extends IActivity {
  job: number;
}
export interface IJobService {
  id: number;
  name: string;
  qty: number;
  unit_type: EUnitType;
  unit_price: number;
  description: string;
}
export interface ICreateJobService extends Omit<IJobService, "id"> {}
export interface IJobMaterial {
  id: number;
  name: string;
  qty: number;
  unit_price: number;
  description: string;
  sku?: string;
}
export interface ICreateJobMaterial extends Omit<IJobMaterial, "id"> {}
export interface IEngine {
  id: number;
  model: string;
  serial_number: string;
}
export interface IGenerator extends IEngine {}
export interface IVessel {
  id: number;
  created: string;
  updated: string;
  name: string;
  hin: string;
  manufacturer: string;
  model: string;
  engines: IEngine[];
  generators: IGenerator[];
  length: number;
  beam?: number;
  draft?: number;
  year: number;
  number_of_generators: number;
  number_of_engines: number;
  uscg_doc: string;
  state_of_registration: string;
  registration_number: string;
  hailing_port: string;
  customer?: ICustomer | null;
  vessel_type: string;
  image: string | null;
  address: IAddress | null;
  notes: string;
  location_name: string;
  slip_number: string;
}
export enum EAppointmentCategory {
  Work = "W",
  Inspection = "I",
}
export interface IWOAppointment {
  created: string;
  created_by: number;
  category: EScheduleCategory;
  start: string;
  end: string;
  id: number;
  uuid: string;
  approved_at: string | null;
  approved_by: number | null;
  job: number;
  status: string;
}

export interface ICreateWOAppointment {
  category: EAppointmentCategory;
  start: string;
  end: string;
}

export interface ICreateVessel
  extends Omit<IVessel, "id" | "created" | "updated" | "customer"> {}

export interface IEstimate {
  pdf_file: string | null;
}

export interface IWOInvoice {
  id: number;
  uuid: string;
  total: number;
  pdf_file: string | null;
  paid: boolean;
  partially_paid: boolean;
}

export interface IJobTechData {
  id: number;
  technician: number | string;
  rate?: number;
  estimated_hours: string | null;
  technician_name: string;
}

export interface IUpcomingJob {
  date: string;
  jobs_count: number;
}

export interface IJob {
  address: IAddress;
  id: number;
  created: string;
  updated: string;
  status: EJobStatus;
  work_order?: number;
  invoices: IInvoice[];
  customer_data: ICustomer;
  vessel_data: IVessel;
  title: string;
  created_by: ICustomer;
  technicians: number[];
  schedules: IWOAppointment[];
  job_number: number;
  order_number: string;
  send_config: ISendConfig | null;
  activities: IActivity[];
  tax: number;
  subtotal: number;
  estimate_pdf?: string;
  estimated_duration: string;
  total: number;
  started: string;
  finished: string;
  priority: EJobPriority;
  services: IJobService[];
  materials: IJobMaterial[];
  is_archived: boolean;
  is_approved: boolean;
  technicians_data: IJobTechData[];
  customer: ICustomer;
  vessel: IVessel;
  progress: EJobProgress;
  total_labor: number;
  total_materials: number;
  epa: number;
  sent?: string;
  completed_at?: string;
}

export interface IEvent {
  id: number;
  start: Date;
  end: Date;
  tittle: string;
}

export interface IEmployee {
  id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  avatar?: string | null;
  phone?: string;
  email?: string;
}

export interface IUuid {
  uuid: string;
}

export interface IJobParameter {
  id: number;
  created: string;
  updated: string;
  status: EJobStatus;
  estimated: number | null;
  title: string;
  created_by: ICustomer;
  technicians: number[];
  schedule: ISchedule | null;
  order_number: number;
  job_number: number;
  customer_data: ICustomer;
  vessel_data: IVessel;
  tax: number;
  subtotal: number;
  estimate_pdf?: string;
  total: number;
  started: string;
  finished: string;
  required_deposit: number;
  is_archived: boolean;
  payed: boolean;
  remained: number;
  deposited: number;
}
export interface IMyShortJob
  extends Omit<
    IJob,
    "customer" | "activities" | "vessel" | "materials" | "tax" | "total" | "epa"
  > {
  customer: ICustomer;
  vessel: IVessel;
  time_clocks: ITimeClock[];
}

export interface IMyJob {
  start: string;
  end: string;
  job: IMyShortJob;
  address: IAddress | null;
}

export interface IEmployeeStats {
  scheduled: number;
  to_be_scheduled: number;
  completed_week: number;
  completed_month: number;
  completed_year: number;
}
export interface IJobsTechnician {
  status: string;
  created_by: string;
  technicians: string;
  is_approved: string;
  work_order: string;
  scheduled: string;
  vessel: number;
  customer: number;
  unscheduled: string;
}
export interface ICreateJob {
  title?: string;
  work_order: number;
}
export interface IJobTechnician {
  id?: number;
  technician: number | string;
  rate: number | null;

  technician_name: string;

  estimated_hours: string | null | moment.Moment;
}

export interface IUpdateJob {
  title: string;
  work_order: number;
  services: ICreateJobService[];
  materials: ICreateJobMaterial[];
  technicians_data: IJobTechnician[];
  schedules: ISchedule[];
  priority?: EJobPriority | string;
  estimated_duration?: string | number | moment.Moment | null;

  labor_description?: string;
  materials_description?: string;
  temp_service?: ICreateJobService;
  temp_material?: ICreateJobService;
}
export interface IUpdate {
  id: number;
}
export interface IUser {
  id: number;
  email: string;
  date_joined: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
  phone: string;
  is_staff: boolean;
  role: string;
  role_id: number;
  title: string;
  affiliations: string;
  is_active: boolean;
  company: number | null;
  onboarding_completed: boolean;
  dashboard_address: IAddress | null;
  rate?: number;
}
export interface IUserProfile extends Omit<IUser, "company"> {
  company: ICompany | null;
  full_name: string;
}
export interface IUpdateUser
  extends Omit<
    IUser,
    | "date_joined"
    | "is_staff"
    | "role"
    | "role_id"
    | "company"
    | "dashboard_address"
  > {}
export interface IRegisterUser {
  email: string;
  first_name: string;
  last_name: string;
  phone?: string;
  password: string;
}
export interface IInviteUser extends Omit<IRegisterUser, "password"> {
  role: number;
  redirect_url?: string;
}
export interface IChangePassword {
  old_password: string;
  new_password: string;
}
export interface IResetPassword {
  email: string;
  redirect_url?: string;
}
export interface ISetPassword {
  password: string;
  token: string;
  uid: string;
  new_user?: boolean;
}
export interface IBlankRequest {}
export interface ICreateMessage {
  uuid: string;
  i?: boolean;
  job?: string;
  message: string;
}
export interface IAdminCreateMessage {
  job?: number;
  work_order?: number;
  message: string;
}
export interface IMessage {
  id: number;
  job: number;
  created: string;
  customer: number;
  vessel: number;
  technician: number;
  author: EAuthor;
  message: string;
  full_name: string;
  avatar: string;
}
export interface ICreateEstimate extends Omit<IConfirmation, "uuid"> {
  id: number;
}
export interface IReadEstimate {
  uuid: string;
  i?: boolean;
}
export interface ICreateSchedule extends Omit<ISchedule, "created"> {
  id: number;
}
export interface IMaterial {
  id: number;
  name: string;
  price: number;
  description: string;
  sku: string | "";
  active?: boolean;
}
export interface IMaterialGroup {
  id: number;
  name: string;
  order?: number;
}
export interface IMaterialGroupCreate {
  name: string;
}
export interface IMaterialGroupActive {
  group: number;
}
export interface IMaterialInGroup {
  id: number;
  name: string;
  description: string;
  unit_cost: number;
  price: number;
  group: number;
  active: boolean;
  sku?: string;
}
export interface ICreateMaterial extends Omit<IMaterial, "id"> {}
export interface IMessageTemplate {
  id: number;
  title: string;
  subject: string;
  content: string;
  sms_content: string;
}

export interface IHelpCategories {
  id: number;
  created: string;
  updated: string;
  articles: number;
  name: string;
  description: string;
}

export interface IHelpArticles {
  id: number;
  created: string;
  updated: string;
  section_name: string;
  category: number;
  category_name: string;
  section: number;
  title: string;
  subtitle: string;
  content: string;
}

export interface IStats {
  jobs_upcoming: number;
  jobs_tbs: number;
  jobs_rejected: number;
  jobs_approved: number;
  invoices_overdue: number;
  invoices_due: number;
  invoices_paid: number;
  invoices_paid_previously: number;
  revenue_labor: number;
  revenue_parts: number;
  revenue_recurring: number;
  revenue_other: number;
  top_customers: ICustomerStats[];
}

export interface IStatsManagement {
  label: string;
  key: EJobProgress | null;
  total: number;
  jobs: number;
}

export interface ICustomerStats {
  id: number;
  created: string;
  updated: string;
  email: string;
  full_name: string;
  company_name: string;
  job_title: string;
  phones: IPhone[];
  annual_invoices: number;
  open_invoices: number;
  last_appointment: string;
  next_appointment: string;
  first_name: string;
  last_name: string;
  address: IAddress | null;
  note: string;
  jobs: number;
  paid: number;
}
export interface ICreateMessageTemplate extends Omit<IMessageTemplate, "id"> {}
export interface INotificationSettings {
  category: ENotificationCategory;
  category_display: string;
  sms: boolean;
  email: boolean;
  in_app: boolean;
}
export interface ICreateNotificationSettings
  extends Omit<INotificationSettings, "category_display"> {}
export interface ICardInfo {
  last_4: string;
  card_type: ECardType;
  confirmation_number: string;
}
export interface ITag {
  tag: string;
  content_type: string;
  object_id: number;
}
export interface INotification {
  id: number;
  created: string;
  user: number | null;
  content: string;
  tags: ITag[];
  category: string;
  read: boolean;
  activity_name: string;
  priority: string;
}
export interface IPaymentDetails {
  type: string;
  card?: any;
  us_bank_account?: any;
}
export interface IPaymentIntent {
  created: string;
  intent_id?: string;
  payment_details: IPaymentDetails;
}
export interface IPaymentRecord {
  id: number;
  created: string;
  updated: string;
  work_order: IWorkOrder;
  customer: number;
  card_info: ICardInfo | null;
  invoice: IInvoice;
  payment_type: EPaymentType;
  check_number: string;
  date: string;
  amount: number;
  note: string;
  payment_intent?: IPaymentIntent;
}
export interface ICreatePaymentRecord {
  work_order: number;
  card_info?: ICardInfo;
  payment_type: string;
  check_number?: string;
  date: string;
  amount: number;
  note?: string;
}
export interface IServiceGroup {
  id: number;
  name: string;
}
export interface ICreateServiceGroup extends Omit<IServiceGroup, "id"> {}
export interface IService {
  id: number;
  name: string;
  description: string;
  group: number;
  unit: EUnitType;
  price: number;
  active: boolean;
}
export interface ICreateService extends Omit<IService, "id"> {}
export interface ITask {
  id: number;
  created: string;
  due_date: string;
  job: number | null;
  job_data: IJob | null;
  title: string;
  description: string;
  employee: number | null;
  employee_data: IUser | null;
  customer: number | null;
  customer_data: ICustomer | null;
  work_order?: number;
  completed?: boolean;
  work_order_data: any;
}
export interface ICreateTask
  extends Omit<
    Partial<ITask>,
    "id" | "created" | "job_data" | "employee_data" | "customer_data"
  > {
  title: string;
  work_order?: number;
}
export interface IConstant {
  [k: string]: string;
}
export interface IUnsafeConstant {
  [k: string]: string | null;
}
export interface IConstants {
  roles: IConstant;
  template_keys: IConstant;
  states: IConstant;
  company_sizes: IConstant;
  vessel_types: IConstant;
  phone_types: IConstant;
  job_statuses: IConstant;
  notification_categories: IConstant;
  inspection_types: IConstant;
  payment_types: IConstant;
  card_types: IConstant;
  epa_types: IConstant;
  tax_types: IConstant;
  send_types: IConstant;
  progress_steps: IConstant;
  countries: IConstant;
  entity_types: IConstant;
  stock_exchanges: IConstant;
  verticals: IConstant;
  alt_business_id_types: IConstant;
  work_order_filter_statuses: IConstant;
}
export interface ISubscriptionPlan {
  id: number;
  title: string;
  description: string;
  options: string;
  monthly_price: number;
  annually_price: number;
  number_of_users: number;
  upgrade_price_annual: number;
  upgrade_price_month: number;
  trial_period: number;
  onboarding_fee: number;
}
export interface IServiceCategories {
  id: number;
  name: string;
}
export interface ISubscriptionStatus {
  next_payment: string;
  status: string;
  trial_to: string | null;
}
export interface ILogin {
  email: string;
  password: string;
}
export interface IToken {
  access: string;
  refresh: string;
}
export interface IRefreshToken extends Omit<IToken, "access"> {}

export interface IListRequestGeneric {
  page?: number;
  pageSize?: number;
}

export interface IListRequest
  extends IListRequestGeneric,
    ISearch,
    IOrdering,
    IActive {}

export interface IPaginatedResponse<R> {
  results: R;
  count: number;
}
export type TApi<R, S = undefined> = S extends IListRequest
  ? (params: S) => AxiosPromise<IPaginatedResponse<R> | R>
  : S extends undefined
  ? () => AxiosPromise<R>
  : (data: S) => AxiosPromise<R>;

export interface ISuccess {
  detail: "Success";
}

export interface IOrdering {
  ordering?: string;
}
export interface ISearch {
  search?: string;
}
export interface IActive {
  active?: boolean;
}

export interface ITimeClock {
  id: number;
  job: IJob;
  user: IUser;
  start: string;
  stop: string;
  duration: string;
  address: TAddress;
}

export interface IJobsFieldsDownload {
  status?: string;
  fields?: any;
}

export interface ITimeClockCreate {
  job: number | undefined;
  user: number;
  start: string;
  stop?: string;
}

export interface ITimeClockUpdate {
  id: number;
  start: string;
  stop: string;
  job: number;
  user: number;
}

export interface ITimeClockStart {
  job?: any;
  user?: any;
  start?: string;
  id?: number;
}

export interface IUserReport {
  user: number;
  date: string;
  duration: number;
}
export interface IReportsRequest {
  user?: any;
  start_after: string;
  start_before: string;
}

export interface IWorkOrder {
  id: number;
  created: string;
  updated: string;
  title: string;
  order_number: number;
  customer: ICustomer;
  vessel: IVessel;
  estimated_duration: string;
  estimated_total: number;
  processing_intent: number | null;
  estimates: IEstimate[];
  total: number;
  subtotal: number;
  tax: number;
  epa: number;
  deposited: number;
  notes: string | undefined;
  required_deposit_amount: number;
  deposit_paid_at: null | string;
  additional_payment_amount: null | number;
  additional_payment_paid_at: null | string;
  current_address?: IAddress;
  is_recurring: boolean;
  recurring_config?: TRecurringConfig;
  recurring_status: ERecurringStatus;
  services?: IJobService[];
  materials?: IJobMaterial[];
  uuid: string;
  status: EWorkOrderStatus;
  priority: EJobPriority;
  warnings: TRequest[];
  created_by: any;
  invoices: IWOInvoice[];
  is_declined: boolean;
}

export interface IWorkOrderFilter {
  status?: string;
  is_recurring?: string;
  total_min?: string;
  total_max?: string;
}

export interface IWorkOrderWithJobs extends IWorkOrder {
  jobs: number[];
}

export interface ICreateWorkOrder {
  title?: string;
  customer: number;
  vessel: number;
  notes?: string;
}
export interface ICreateJobWO {
  title?: string;
  customer: number;
  vessel?: number;
  notes?: string;
  estimated_duration?: any;
  services?: IJobService[];
  materials?: IJobMaterial[];
  priority?: EJobPriority;
}
export interface ICreateJobWO {
  title?: string;
  customer: number;
  vessel?: number;
  notes?: string;
  estimated_duration?: any;
  services?: IJobService[];
  materials?: IJobMaterial[];
  priority?: EJobPriority;
}

export interface ISaveWorkOrderNote {
  id: number;
  note: string;
}

export interface ICreateWorkOrderJob {
  title: string;
  work_order: number;
}

export interface IWorkOrderUpdate extends ISendUpdate {
  created: string;
  updated: string;
  work_order: number;
  sender: number;
  uuid: number;
}

export interface ISendUpdate {
  template: number;
  collect_payment?: boolean;
  display_messages?: boolean;
  display_activity_feed?: boolean;
  display_authorization_history?: boolean;
  additional_payment?: number;
  pdf_file?: any;
  formData?: any;
}

export interface IVesselGallery {
  id: number;
  photo: string;
  vessel: number;
}

export interface ICreateVesselGallery {
  vessel: number;
}

export interface IUpdateVesselGallery {
  photos: string[];
  vessel: number;
}

export interface IRemovePhoto {
  photos: number[];
}

enum EAppointmentPriority {
  N = "Normal",
  U = "Urgent",
}

interface IAppointmentSchedule {
  created?: string;
  category?: EAppointmentCategory;
  start: string;
  end: string;
}

interface IAppointmentTechnician {
  technician: number;
  rate: number;
  est_hours: number | undefined;
  est_total: number | undefined;
}

export interface IAppointment {
  id: number;
  created: string;
  created_by: number;
  vessel: IVessel;
  customer: ICustomer;
  purpose_of_visit: string;
  priority: EAppointmentPriority;
  purpose_notes: string;
  schedule: IAppointmentSchedule;
  confirmation: boolean;
  confirmed: boolean;
  technicians: IAppointmentTechnician[];
  notes: string;
}

export interface ICreateAppointment {
  vessel: number;
  customer: number;
  purpose_of_visit: string;
  priority: EAppointmentPriority;
  purpose_notes?: string;
  schedule: IAppointmentSchedule;
  confirmation?: boolean;
  technicians: IAppointmentTechnician[];
  notes?: string;
}

export type TSettingsData = {
  weekStart: string;
  daysToExclude: string[];
  dayStart: string;
  dayEnd: string;
};

export interface IViewConfig {
  collect_payment: boolean;
  display_messages: boolean;
  display_activity_feed: boolean;
  display_authorization_history: boolean;
  updated?: string;
}
export interface ISubscription {
  status: string;
  subscription_id: string;
}

export interface IShortJob {
  id: number;
  created: string;
  updated: string;
  status: EJobStatus;
  work_order: number;
  job_number: number;
  title: string;
  services: IJobService[];
  materials: IJobMaterial[];
  order_number: string;
  estimated_duration: string | null;
  labor_description: string;
  materials_description: string;
  total_labor: number;
  total_materials: number;
  tax: number;
  epa: number;
  total: number;
  is_approved: boolean;
}
export interface IExtendedWorkOrder extends Omit<IWorkOrderWithJobs, "jobs"> {
  created_by: any;
  processing_intent: number | null;
  send_config: any;
  subscription: ISubscription | null;
  jobs: IShortJob[];
  payments_info: any;
}

export interface IExternalEstimate {
  amount_paid: number;
  amount_to_pay: number;
  uuid: string;
  merchant_id?: string | null; // Depends on view_config.collect_payment
  view_config: IViewConfig | null;
  created: string;
  pdf_file: string | null;
  company: ICompany;
  payment_methods: IDetails[];
  work_order: IExtendedWorkOrder;
  service_writer: IEmployee;
  is_approved: boolean;
  activities: IExtendedActivity[]; // Depends on view_config.display_activity_feed -> if false []
  messages: IMessage[]; // Depends on view_config.display_messages -> if false []
}
export interface IExternalInvoice extends IExternalEstimate {}

export enum ECompanyPaymentsStatus {
  Active = "active",
  InReview = "in_review",
  Editing = "editing",
  Suspended = "suspended",
  Sumbitted = "submitted_to_pe",
  Declined = "declined",
}

export interface IExternalRecurring {
  activities: IExtendedActivity[];
  company: ICompany;
  is_approved: boolean;
  merchant_id?: string | null;
  messages: IMessage[];
  service_writer: IEmployee;
  payment_methods: IDetails[];
  uuid: string;
  view_config: null | IViewConfig;
  work_order: IExtendedWorkOrder;
  recurring_payment_amount: number;
}

export interface ITechnian {
  avatar: string | null;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  phone: string;
  rate: number | null;
}

export interface IJobScheduleTechData {
  id: number;
  technician: ITechnian;
  rate?: number;
  estimated_hours: string | null;
}

export interface IScheduleJob extends Omit<IJob, "technicians_data"> {
  technicians_data: IJobScheduleTechData[];
  inspections?: IInspection[];
}

export interface IExtendedScheduleWorkOrder
  extends Omit<IWorkOrderWithJobs, "jobs"> {
  created_by: any;
  send_config: any;
  jobs: IScheduleJob[];
}

export interface IExternalSchedule {
  activities: IExtendedActivity[];
  company: ICompany;
  discount: number;
  is_approved: boolean;
  merchant_id?: string | null;
  messages: IMessage[];
  recurring_payment_amount: number;
  schedule: IWOAppointment;
  service_writer: IEmployee;
  uuid: string;
  view_config: null | IViewConfig;
  work_order: IExtendedScheduleWorkOrder;
}

export interface IStatementOfWork extends IExtendedScheduleWorkOrder {
  company: ICompany;
  activities: IExtendedActivity[];
  send_config: null | IViewConfig;
  messages: IMessage[];
}

export interface ITelgorithmBrand {
  companyName: string;
  displayName: string;
  entityType: string;
  country: string;
  ein: string;
  einIssuingCountry: string;
  firstName?: string;
  lastName?: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  email: string;
  stockSymbol: string;
  stockExchange: string;
  website: string;
  vertical: string;
  referenceId?: string;
}

export interface IErrorStatus {
  code: number;
  description: string;
  field: string | null;
}

export interface IStatusCategory {
  description: string;
  displayName: string;
  fields: string[];
  id: string;
}

export interface ITelgorithmBrandStatus {
  // brand?: number
  // verified_status?: boolean
  // imported_status?: boolean
  // unverified_message?: string
  brandId: string;
  category: IStatusCategory[];
}

export interface ITelgorithmBrandResponse {
  altBusinessIdType: string;
  altBusinessId?: string;
  brandId: string;
  city: string;
  companyName: string;
  corporate_phone: string | null;
  corporate_phone_status?: string;
  country: string;
  createDate: string;
  cspId: string;
  displayName: string;
  ein: string;
  einIssuingCountry: string;
  email: string;
  entityType: string;
  identityStatus: string;
  mock: boolean;
  phone: string;
  postalCode: string;
  state: string;
  stockExchange: string;
  stockSymbol: string;
  street: string;
  vertical: string;
  website: string;
}

export interface ITelgorithmSearchPhone {
  area_code?: string;
  state?: string;
  city?: string;
  quantity?: number;
}

export interface ITelgorithmPhone {
  city: string;
  number: string;
  state: string;
  supportedServices: string;
}

export interface ITelgorithmPhoneRes {
  phones: ITelgorithmPhone[];
}

export interface ITelgorithmSetPhone {
  phone: string;
}

export type TImportEnum = Array<{
  field_name: string;
  choices: Array<{ value: string; label: string }> | null;
  required: boolean;
}>;

export type TImportUpload = {
  data: FormData;
  table: string;
  id: number;
};

export type TImportValidate = {
  data: Array<{ [key: string]: string }>;
  table: string;
  id: number;
};

export type TImportUploadRes = {
  db_headers: { [key: string]: string };
  headers: string[];
  data: Array<{ [key: string]: string }>;
};

export interface IPaymentsDownload {
  status?: string;
  fields?: any;
}
