import { useEffect, useRef } from "react"
import lottie from "lottie-web"
import AnimationImg from "../../assets/animation/On_boarding_animation.json"

export const Animation = () => {
   const animImg = useRef(null)

   useEffect(() => {
      lottie.loadAnimation({
         container: animImg.current,
         animationData: AnimationImg,
         renderer: "svg", // "canvas", "html"
         loop: false, // boolean
         autoplay: true, // boolean
      })
   }, [])

   return <div style={{ width: "265px" }} ref={animImg} />
}
