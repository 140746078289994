import { useState, useEffect } from "react"
import { ModalWindow } from "../ui/ModalWindow"
import Flex from "../noui/Flex"
import { NavLink } from "react-router-dom"
import routes from "../routes/routes"
import { TArgCallback, TCallback } from "../types"
import { NewPayment } from "./NewPayment"
import styled from "styled-components"
import { usePrevious } from "../utils/hooks"
import { AppointmentIcon, CustomerIcon, VesselIcon, WorkOrderIcon, MessageIcon } from "../assets/icons"

const BtnWrapper = styled.div`
   cursor: pointer;

   h4 {
      color: #494949;
      font-weight: normal;
      font-size: 15px;
      margin: 0;
      transition: all 0.2s ease-out;
   }
   &:hover {
      h4 {
         color: #0496ff;
      }
      svg {
         path {
            fill: #0496ff;
         }
      }
   }
`

const StyledBox = styled.div`
   margin: 0 auto 10px auto;
   text-align: center;
   width: 20px;
   height: 20px;
   transition: all 0.2s ease-out;

   svg {
      width: 22px;
      height: auto;
      vertical-align: middle;
      transition: all 0.2s ease-out;
   }
`

const CreateWorkOrder = styled(Flex)`
   h4 {
      color: #494949;
      font-weight: normal;
      font-size: 15px;
      margin: 0;
      transition: all 0.2s ease-out;
   }
   &:hover {
      h4 {
         color: #0496ff;
      }
      svg {
         path {
            fill: #0496ff;
         }
      }
   }
`

type TProps = {
   onCustomer: TCallback
   onVessel: TCallback
   onMessage: TCallback
   onAppointment: TCallback
   plusModal: boolean
   setPlusModal: TArgCallback<boolean>
}

export const PlusModal = ({ onCustomer, onVessel, plusModal, onMessage, onAppointment, setPlusModal }: TProps) => {
   const [payment, setPayment] = useState<boolean>(false)
   const prevPlusModal = usePrevious(plusModal)

   useEffect(() => {
      if (!prevPlusModal && plusModal) setPayment(false)
   }, [plusModal, prevPlusModal])

   const mainBody = () => (
      <Flex height="151px" flexDirection="column" justifyContent="center">
         <Flex borderBottom="1px solid #EBEFF2">
            <CreateWorkOrder>
               <NavLink to={{ pathname: routes.workOrder.CreateWorkOrder, redirectUrl: window.location.pathname }}>
                  <Flex
                     width="133px"
                     height="75px"
                     justifyContent="center"
                     alignItems="center"
                     borderRight="1px solid #EBEFF2"
                  >
                     <Flex flexDirection="column">
                        <StyledBox>
                           <WorkOrderIcon />
                        </StyledBox>
                        <h4>Job</h4>
                     </Flex>
                  </Flex>
               </NavLink>
            </CreateWorkOrder>

            <BtnWrapper onClick={onMessage}>
               <Flex width="133px" height="75px" justifyContent="center" alignItems="center">
                  <Flex flexDirection="column">
                     <StyledBox>
                        <MessageIcon />
                     </StyledBox>
                     <h4>Message</h4>
                  </Flex>
               </Flex>
            </BtnWrapper>

            {/* <BtnWrapper onClick={onAppointment}>
               <Flex
                  width="133px"
                  height="75px"
                  borderRight="1px solid #EBEFF2"
                  justifyContent="center"
                  alignItems="center"
               >
                  <Flex flexDirection="column">
                     <StyledBox>
                        <AppointmentIcon />
                     </StyledBox>
                     <h4>Appointment</h4>
                  </Flex>
               </Flex>
            </BtnWrapper> */}
         </Flex>
         <Flex>
            {/* <BtnWrapper onClick={onCustomer}>
               <Flex
                  width="133px"
                  height="75px"
                  borderRight="1px solid #EBEFF2"
                  justifyContent="center"
                  alignItems="center"
               >
                  <Flex flexDirection="column">
                     <StyledBox>
                        <CustomerIcon />
                     </StyledBox>
                     <h4>Customer</h4>
                  </Flex>
               </Flex>
            </BtnWrapper> */}

            <BtnWrapper onClick={onVessel}>
               <Flex
                  width="133px"
                  height="75px"
                  justifyContent="center"
                  alignItems="center"
                  borderRight="1px solid #EBEFF2"
               >
                  <Flex flexDirection="column">
                     <StyledBox>
                        <VesselIcon />
                     </StyledBox>
                     <h4>Vessel</h4>
                  </Flex>
               </Flex>
            </BtnWrapper>
            <BtnWrapper onClick={onCustomer}>
               <Flex width="133px" height="75px" justifyContent="center" alignItems="center">
                  <Flex flexDirection="column">
                     <StyledBox>
                        <CustomerIcon />
                     </StyledBox>
                     <h4>Customer</h4>
                  </Flex>
               </Flex>
            </BtnWrapper>
         </Flex>
         {/* <CreateWorkOrder>
            <NavLink to={{ pathname: routes.workOrder.CreateWorkOrder, redirectUrl: window.location.pathname }}>
               <Flex width="266px" height="75px" justifyContent="center" alignItems="center">
                  <Flex flexDirection="column">
                     <StyledBox>
                        <WorkOrderIcon />
                     </StyledBox>
                     <h4>Create New Work Order</h4>
                  </Flex>
               </Flex>
            </NavLink>
         </CreateWorkOrder> */}
      </Flex>
   )
   return (
      <ModalWindow onClick={() => setPlusModal(!plusModal)} width={payment ? "565px" : "267px"}>
         {payment ? <NewPayment /> : mainBody()}
      </ModalWindow>
   )
}
