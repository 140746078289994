import React, {useEffect, useState} from "react"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import {Checkbox} from "antd"
import { CompanyInfoContainer, SaveBtn, ContainerTitle, SaveBtnWrapper } from "./styled"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import {TextEditor} from "./TextEditor";
import {Api} from "../../api/api";
import {getErrors, showNonFieldsErrors} from "../../utils/utils";
import {setCurrentUser} from "../../config/reducers/user/actions";
import {useAppDispatch} from "../../config/hooks";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {IInvoiceSettings} from "../../api/types";

export const FootersForm = () => {
    const [estimateFooter, setEstimateFooter] = useState("")
    const [invoiceFooter, setInvoiceFooter] = useState("")
    const [statusServicePlan, setStatusServicePlan] = useState("")
    const [statusWorkOrder, setStatusWorkOrder] = useState("")
    const [showTimestamp, setShowTimestamp] = useState<boolean>(false)
    const [invoiceSettings, setInvoiceSettings] = useState<IInvoiceSettings | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const dispatch = useAppDispatch()
    const initialEstimateFooter = invoiceSettings?.estimate_footer || ""
    const initialInvoiceFooter = invoiceSettings?.invoice_footer || ""
    const initialPaymentStatusWO = invoiceSettings?.payment_status_wo || ""
    const initialPaymentStatusSP = invoiceSettings?.payment_status_sp || ""
   const isXS = useBreakpoint(down("xs"))
    const updateEstimateFooter = (value: string) => {
        setEstimateFooter(value)
        if (!isDirty) {
            setIsDirty(true)
        }
    }
    const updateInvoiceFooter = (value: string) => {
        setInvoiceFooter(value)
        if (!isDirty) {
            setIsDirty(true)
        }
    }
    const updateShowTimeStamp = (e: CheckboxChangeEvent) => {
        setShowTimestamp(e.target.checked)
        if (!isDirty) {
            setIsDirty(true)
        }
    }
    const updateStatusServicePlan = (value: string) => {
        setStatusServicePlan(value)
        if (!isDirty) {
            setIsDirty(true)
        }
    }
    const updateStatusWorkOrder = (value: string) => {
        setStatusWorkOrder(value)
        if (!isDirty) {
            setIsDirty(true)
        }
    }

    useEffect(()=>{
        Api.company.profile().then(({ data: {invoice_settings } }) => {
            if(invoice_settings) {
                setInvoiceSettings(invoice_settings)
            }
        })
    },[])
    const handleSave = async () => {
        const data = {estimate_footer: estimateFooter, invoice_footer: invoiceFooter, payment_status_sp: statusServicePlan, payment_status_wo: statusWorkOrder, show_timestamp: showTimestamp}
        try {
            setIsLoading(true)
            await Api.company.setInvoiceSettings(data)
            const { data: user } = await Api.user.profile()
            dispatch(setCurrentUser(user))
            setIsDirty(false)
        } catch (err) {
            showNonFieldsErrors(getErrors(err))
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <CompanyInfoContainer p={16}>
            <Flex mb={isXS ? "0px" : "22px"} flexDirection="column" width="100%">
               <ContainerTitle>Custom-Branded Email</ContainerTitle>
               <Msg fontWeight={400} fontSize={13} color="#000000" mb="16px">
                  Footers appear on digital and PDF estimate and invoices.
               </Msg>
               <Msg fontWeight={700} fontSize={15}>
                  Estimate Footer
               </Msg>
               <TextEditor initialValue={initialEstimateFooter} onChange={updateEstimateFooter} />
               <Msg fontWeight={700} fontSize={15}>
                 Invoice Footer
               </Msg>
               <TextEditor initialValue={initialInvoiceFooter} onChange={updateInvoiceFooter} />
            <Msg fontWeight={700} fontSize={15}>
                    Payment Status for Work Order
                  </Msg>
                  <TextEditor initialValue={initialPaymentStatusWO} onChange={updateStatusWorkOrder} />
               <Msg fontWeight={700} fontSize={15}>
                  Payment Status for Service Plan
               </Msg>
               <TextEditor initialValue={initialPaymentStatusSP} onChange={updateStatusServicePlan} />
                <Checkbox checked={showTimestamp} onChange={updateShowTimeStamp}>Show timestamp with created and completed dates</Checkbox>
               <SaveBtnWrapper>
                  <SaveBtn disabled={!isDirty} loading={isLoading} onClick={handleSave} style={{ marginLeft: "auto" }}>
                     Save
                  </SaveBtn>
               </SaveBtnWrapper>
      </Flex>
        </CompanyInfoContainer>
   )
}
