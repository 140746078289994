import React, { useState, useEffect } from "react"
import { Api, ERequestPriority } from "../../api/api"
import { formatToUSD, getAddress, renderAddress } from "../../utils/utils"
import { servicesUnitTypes, vesselTypes } from "../../constants"
import moment from "moment"
import { Button } from "antd"
import DEFAULT_LOGO from "../../assets/images/gear_logo.png"
import { EInspectionCategory, EJobStatus, IInspection, IScheduleJob, IStatementOfWork } from "../../api/types"
import { TCallback } from "../../types"
import Flex from "../../noui/Flex"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import RequestChangesModal from "./RequestChangesModal"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import {
   ApproveRow,
   DetailsContainer,
   Header,
   Info,
   HeaderInfo,
   WOInfo,
   PaymentBlock,
   WOTitle,
   DetailsDiv,
   Customer,
   CustomerInfo,
   JobTitle,
   JobInfo,
   PaymentStatus,
   VesselHeader,
   VesselBottom,
   VesselImg,
   TableDiv,
   TableItem,
   Services,
   Materials,
   Summary,
   DropDownButton,
   JobDropDown,
   MobileItem,
} from "./styled"
import parse from "html-react-parser";

type TProps = {
   work_order: IStatementOfWork
   saving: boolean
   onApprove: TCallback
   isInvoice: boolean
   messageRef: any
   isMobileActions?: boolean
   openMessages?: () => void
}
type TPropsJobs = {
   job: any
}

const JobDetail: React.FC<TPropsJobs> = ({ job }) => {
   const [showDropdownContent, setShowDropdownContent] = useState<boolean>(false)
   const isXs = useBreakpoint(down("xs"))
   const handleActivityDropdownChange = () => {
      setShowDropdownContent((prev) => !prev)
   }
   const renderInspecions = (job: IScheduleJob) => {
      if (!job.inspections?.length) return null
      return job.inspections.map((inspection) => (
         <Flex flexDirection="column" marginTop="10px">
            <Flex>
               <span style={{ fontSize: "15px", fontWeight: 700, color: "#FB4D4F", marginRight: "8px" }}>
                  {getFullCategoryName(inspection.category)}:
               </span>
               <span style={{ fontSize: "15px", fontWeight: 700 }}>{inspection.name}</span>
            </Flex>
            <span style={{ fontSize: "13px" }}>{inspection.notes}</span>
            <Flex marginTop="8px">
               {inspection.images.map((image) => (
                  <img style={{ width: "120px", height: "100px", marginRight: "16px" }} src={image.image} />
               ))}
            </Flex>
         </Flex>
      ))
   }

   const getFullCategoryName = (category: EInspectionCategory) =>
      category === EInspectionCategory.After ? "After" : "Before"

   return (
      <>
         {isXs && (
            <JobDropDown
               flexDirection="row"
               justifyContent="space-between"
               alignItems="center"
               style={{ borderBottom: showDropdownContent ? "none" : "1px solid #dedede" }}
            >
               <JobTitle justifyContent="space-betwee" marginTop="16px">
                  <h3 style={{ color: job.status !== EJobStatus.Completed ? "#FB4D4F" : "#494949" }}>{job.title}</h3>
               </JobTitle>
               <DropDownButton
                  onClick={handleActivityDropdownChange}
                  icon={showDropdownContent ? <UpOutlined /> : <DownOutlined />}
               />
            </JobDropDown>
         )}
         {!isXs && (
            <JobTitle justifyContent="space-betwee" marginTop="16px">
               <h3 style={{ color: job.status !== EJobStatus.Completed ? "#FB4D4F" : "#494949" }}>{job.title}</h3>
            </JobTitle>
         )}
         {(showDropdownContent && isXs) || !isXs ? (
            <Flex flexDirection="column" paddingLeft="10px">
               <Customer style={{ width: "300px" }}>
                  <CustomerInfo style={{ marginTop: "8px" }}>
                     <div>Job {job.status === EJobStatus.Completed ? "Completed" : "Scheduler"}:</div>
                     <div style={{ fontWeight: "bold" }}>
                        {moment(job.schedules[0].start).format("M/DD/YYYY [at] hh:mma")}
                     </div>
                  </CustomerInfo>
                  <CustomerInfo style={{ marginTop: "8px" }}>
                     <div style={{ textDecoration: "underline" }}>TECHNICIAN(S)</div>
                     <div>{job.technicians_data[0].technician.full_name}</div>
                  </CustomerInfo>
               </Customer>
               {renderInspecions(job)}
            </Flex>
         ) : null}
      </>
   )
}

export const StatementOfWorkDetails: React.FC<TProps> = ({
   work_order,
   saving,
   onApprove,
   isInvoice,
   messageRef,
   isMobileActions,
   openMessages,
}) => {
   const { company } = work_order
   const [modalRequestChanges, setRequestChanges] = useState<boolean>(false)
   const [showDropdownContent, setShowDropdownContent] = useState<boolean>(false)
   const invoiceFooter = company?.invoice_settings?.invoice_footer as string
   const estimateFooter = company?.invoice_settings?.estimate_footer as string
   const paymentStatusWO = company?.invoice_settings?.payment_status_wo as string
   const paymentStatusSP = company?.invoice_settings?.payment_status_sp as string
   const isXs = useBreakpoint(down("xs"))

   const formatPrice = (price: number | undefined) =>
      price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

   const sendRequestChanges = async (message: string, priority: ERequestPriority) => {
      await Api.workOrders.requestChange({ message, priority, uuid: work_order.uuid })
   }

   const formatTimeDate = (date?: string) => {
      return moment(date).format("M/DD/YYYY") + " at " + work_order.recurring_config?.start_time
   }

   const formatPeriod = (period?: string) => {
      switch (period) {
         case "W":
            return "week"
         case "M":
            return "month"
         default:
            return ""
      }
   }

   const formatBillingPeriod = (period?: string) => {
      switch (period) {
         case "M":
            return "Monthly"
         case "A":
            return "Annually"
         default:
            return ""
      }
   }

   const getTotalServicePrice = () =>
      work_order.services?.reduce((total, current) => current.unit_price + total, 0) ?? 0

   const getTotalMaterialsPrice = () =>
      work_order.materials?.reduce((total, current) => current.unit_price + total, 0) ?? 0

   const getJobPrice = () => getTotalMaterialsPrice() + getTotalServicePrice()

   const getDiscountedPrice = () => getJobPrice() * ((100 - (work_order.recurring_config?.discount ?? 0)) / 100)

   const getJobsCount = () => work_order.jobs.length

   const handleActivityDropdownChange = () => {
      setShowDropdownContent((prev) => !prev)
   }

   const MainBlock = () => (
      <>
         {!isXs && (
            <WOInfo alignItems="center" justifyContent="space-between">
               <h3>SERVICE PLAN ESTIMATE</h3>

               <p>Sent: {moment(work_order?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p>
            </WOInfo>
         )}
         <WOTitle alignItems="center" justifyContent="space-between">
            <h3>{work_order?.title}</h3>
            <p>
               <span>Work order:</span> #{work_order?.order_number}
            </p>
         </WOTitle>
         <Info justifyContent="normal">
            <div className="infoBlock">
               <Customer>
                  <CustomerInfo>
                     <div>Service Writer: </div>
                     <div className="title">{work_order?.created_by?.full_name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Created on: </div>
                     <div className="title">
                        {work_order?.created ? moment(work_order?.created).format("ddd, M/D/YYYY h:mm A") : "No data"}
                     </div>
                  </CustomerInfo>
               </Customer>
               <DetailsDiv>
                  <h4>Project Details</h4>
                  <div>{work_order?.notes || "No notes provided"}</div>
               </DetailsDiv>
               <DetailsDiv>
                  <h4>SCOPE OF WORK</h4>
                  <div>
                     {work_order?.jobs && work_order?.jobs.length > 0 && (
                        <ul>
                           {work_order?.jobs
                              .filter((job) => job.status === EJobStatus.Completed)
                              .map((job: any) => {
                                 return (
                                    <li key={job?.id}>
                                       {job.title} <span>({job.order_number})</span>
                                    </li>
                                 )
                              })}
                        </ul>
                     )}
                  </div>
                  <h5>(Full scope of labor and materials listed below)</h5>
               </DetailsDiv>
               <DetailsDiv>
                  <h4>SCHEDULE</h4>
                  <Customer>
                     <CustomerInfo>
                        <div>Start Date: </div>
                        <div className="title">
                           {formatTimeDate(work_order.recurring_config?.first_appointment_date)}
                        </div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div>End Date: </div>
                        <div className="title">
                           {formatTimeDate(work_order.recurring_config?.final_appointment_date)}
                        </div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div>Job Frequency:</div>
                        <div className="title">
                           {work_order.recurring_config?.times_per_period}/
                           {formatPeriod(work_order.recurring_config?.schedule_period)}
                        </div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div># of Jobs (Total):</div>
                        <div className="title">{getJobsCount()}</div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div>Billing Frequency: </div>
                        <div className="title">{formatBillingPeriod(work_order.recurring_config?.billing)}</div>
                     </CustomerInfo>
                  </Customer>
               </DetailsDiv>
            </div>
            <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
               <VesselHeader>
                  <CustomerInfo>
                     <div className="title">Vessel:</div>
                     <div style={{ fontSize: "15px", fontWeight: "bold" }}>{work_order?.vessel?.name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div className="title">Hull ID:</div>
                     <div>{work_order?.vessel?.hin}</div>
                  </CustomerInfo>
               </VesselHeader>
               <VesselBottom>
                  <CustomerInfo>
                     <div>Vessel Type:</div>
                     <div className="title">
                        {vesselTypes.find((v) => v.value === work_order?.vessel?.vessel_type)?.label || "No data"}
                     </div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Location name:</div>
                     <div className="title">{work_order?.vessel?.location_name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Address:</div>
                     <div className="title">{getAddress(work_order?.vessel?.address) || "-"}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Slip #:</div>
                     <div className="title">{work_order?.vessel?.slip_number}</div>
                  </CustomerInfo>
                  {work_order?.vessel?.image && (
                     <VesselImg>{<img src={work_order?.vessel?.image} alt="vessel" />}</VesselImg>
                  )}
               </VesselBottom>
            </div>
         </Info>
         <PaymentStatus>
            <h3>PAYMENT STATUS</h3>
            <PaymentBlock>
               <div className="paymentBlock">
                  {isInvoice ? (
                     <p>{parse(paymentStatusSP)}</p>
                  ) : (
                     <p>{parse(paymentStatusWO)}</p>
                  )}
               </div>
               <div className="paymentBlock">
                  <Customer>
                     <CustomerInfo>
                        <div>Standard Rate:</div>
                        <div className="right">{formatToUSD(getJobPrice())}/job</div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div style={{ fontWeight: "bold", color: "#42C77B" }}>Discounted:</div>
                        <div className="right" style={{ fontWeight: "bold", color: "#42C77B" }}>
                           {work_order?.recurring_config?.discount ?? 0}%
                        </div>
                     </CustomerInfo>
                     <div className="divider" />
                     <CustomerInfo>
                        <div style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                           Discounted {work_order.recurring_config?.billing === "M" ? "Monthly" : "Annually"} Rate (
                           {work_order.recurring_config?.discount}%):
                        </div>
                        <div className="right" style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                           {formatToUSD(work_order.recurring_config?.recurring_payment_amount ?? 0)}
                        </div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                           {work_order.recurring_config?.billing === "M" ? "Monthly" : "Annually"} Savings
                        </div>
                        <div className="right" style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                           {formatToUSD(
                              ((work_order.recurring_config?.recurring_payment_amount ?? 0) /
                                 (100 - (work_order.recurring_config?.discount ?? 0))) *
                                 100 -
                              (work_order.recurring_config?.recurring_payment_amount ?? 0)
                           )}
                        </div>
                     </CustomerInfo>
                  </Customer>
               </div>
            </PaymentBlock>
         </PaymentStatus>
         {isXs && (
            <JobDropDown
               flexDirection="row"
               justifyContent="space-between"
               alignItems="center"
               style={{ borderBottom: "none" }}
            >
               <JobTitle flexDirection="column">
                  <h3>{work_order?.recurring_config?.title}</h3>
                  <p>(Job {work_order?.order_number})</p>
               </JobTitle>
               <DropDownButton
                  onClick={handleActivityDropdownChange}
                  icon={showDropdownContent ? <UpOutlined /> : <DownOutlined />}
               />
            </JobDropDown>
         )}

         {(showDropdownContent && isXs) || !isXs ? JobBlock() : null}
         {!isXs && <div className="divider" />}

         {!!work_order.jobs.length &&
            work_order.jobs
               .sort((a, b) => a.id - b.id)
               .filter((job) => job.status === EJobStatus.Completed)
               .map((job) => <JobDetail key={job.id} job={job} />)}
      </>
   )

   const JobBlock = () => {
      return (
         <div>
            <div className="noBreak">
               {!isXs && (
                  <JobTitle justifyContent="space-between">
                     <h3>{work_order.recurring_config?.title}</h3>
                     <p>(Job {work_order.order_number})</p>
                  </JobTitle>
               )}

               <JobInfo alignItems="center" justifyContent="space-between">
                  <div>
                     First Appointment:{" "}
                     <span>{formatTimeDate(work_order.recurring_config?.first_appointment_date)}</span>
                  </div>
                  <div>
                     Estimated Duration: <span>{work_order?.estimated_duration ?? "TBD"}</span>
                  </div>
               </JobInfo>
            </div>
            <TableDiv>
               {work_order?.services && work_order?.services.length > 0 && (
                  <Services className="noBreak">
                     {!isXs && (
                        <div className={`grid header`}>
                           <div className="name">LABOR</div>
                           <div className="desc">Description</div>
                           <div className="qty">Qty</div>
                           <div className="rate">Rate</div>

                           <div className="type">Type</div>
                           <div className="total">Total</div>
                        </div>
                     )}

                     {work_order?.services.map((item: any) => {
                        const type = servicesUnitTypes.find((s) => s.value === item.unit_type)
                        return !isXs ? (
                           <TableItem key={item.id}>
                              <div className={`grid `}>
                                 <div className="name">{item.name}</div>
                                 <div className="desc">{item.description}</div>
                                 <div className="qty">{item.qty}</div>

                                 <div className="rate">{formatToUSD(item.unit_price)}</div>

                                 <div className="type">{type && type.label}</div>

                                 <div className="total" style={{ fontWeight: "bold" }}>
                                    {formatToUSD(item.unit_price * item.qty)}
                                 </div>
                              </div>
                           </TableItem>
                        ) : (
                           <MobileItem key={item.id}>
                              <div className="main">
                                 <div>
                                    <div>
                                       <span style={{ fontWeight: 900 }}>LABOR</span>
                                    </div>
                                    <div>{item.name}</div>
                                 </div>
                                 <div>
                                    <div>Description</div>
                                    <div>{item.description}</div>
                                 </div>
                                 <div>
                                    <div>Qty</div>
                                    <div>{item.qty}</div>
                                 </div>
                                 <div>
                                    <div>Rate</div>
                                    <div>{formatToUSD(item.unit_price)} per job</div>
                                 </div>
                                 <div>
                                    <div>Total</div>
                                    <div>{formatToUSD(item.unit_price * item.qty)}</div>
                                 </div>
                              </div>
                           </MobileItem>
                        )
                     })}
                  </Services>
               )}
            </TableDiv>
            <TableDiv>
               {work_order?.materials && work_order.materials.length > 0 && (
                  <Materials className="noBreak">
                     {!isXs && (
                        <div className={`grid header `}>
                           <div className="name">MATERIALS</div>

                           <div className="desc">Description</div>
                           <div className="qty">Qty</div>
                           <div className="price">Unit price</div>
                           <div className="total">Total</div>
                        </div>
                     )}

                     {work_order.materials.map((item: any) => {
                        return !isXs ? (
                           <TableItem key={item.id}>
                              <div className={`grid`}>
                                 <div className="name">{item.name}</div>
                                 <div>{item.description}</div>
                                 <div className="qty">{item.qty}</div>

                                 <div className="price">{formatToUSD(item.unit_price)}</div>
                                 <div className="total" style={{ fontWeight: "bold" }}>
                                    {formatToUSD(item.unit_price * item.qty)}
                                 </div>
                              </div>
                           </TableItem>
                        ) : (
                           <MobileItem key={item.id}>
                              <div className="main">
                                 <div>
                                    <div>MATERIALS</div>
                                    <div>{item.name}</div>
                                 </div>
                                 <div>
                                    <div>Description</div>
                                    <div>{item.description}</div>
                                 </div>
                                 <div>
                                    <div>Qty</div>
                                    <div>{item.qty}</div>
                                 </div>
                                 <div>
                                    <div>Rate</div>
                                    <div>{formatToUSD(item.unit_price)} per qty</div>
                                 </div>
                                 <div>
                                    <div>Total</div>
                                    <div>{formatToUSD(item.unit_price * item.qty)}</div>
                                 </div>
                              </div>
                           </MobileItem>
                        )
                     })}
                  </Materials>
               )}
            </TableDiv>
            <div className="noBreak">
               <Summary>
                  <div className="disclaimers">
                     <h3>DISCLAIMERS</h3>
                     {isInvoice ? (
                        <p>{parse(invoiceFooter)}</p>
                     ) : (
                        <p>{parse(estimateFooter)}</p>
                     )}
                  </div>
                  <div className="summary">
                     <Flex justifyContent="space-between">
                        <div>Labor</div>
                        <div>{formatToUSD(getTotalServicePrice())}</div>
                     </Flex>
                     <Flex justifyContent="space-between">
                        <div>Materials</div>
                        <div>{formatToUSD(getTotalMaterialsPrice())}</div>
                     </Flex>
                     <Flex justifyContent="space-between">
                        <div>Subtotal</div>
                        <div>{formatToUSD(work_order.subtotal)}</div>
                     </Flex>
                     <Flex justifyContent="space-between">
                        <div>Taxes</div>
                        <div>{formatToUSD(work_order.tax ?? 0)}</div>
                     </Flex>
                     <Flex justifyContent="space-between">
                        <div>EPA fees</div>
                        <div>{formatToUSD(work_order.epa ?? 0)}</div>
                     </Flex>
                     <Flex className="total" justifyContent="space-between">
                        <div>Job total</div>
                        <div>{formatToUSD(getJobPrice())}</div>
                     </Flex>
                     <Flex justifyContent="space-between">
                        <div style={{ color: "#42C77B", fontWeight: "bold" }}>Discounted Total</div>
                        <div style={{ color: "#42C77B", fontWeight: "bold" }}>{formatToUSD(getDiscountedPrice())}</div>
                     </Flex>
                  </div>
               </Summary>
            </div>
         </div>
      )
   }

   const ActionBlock = () => (
      <ApproveRow>
         <p style={{ fontSize: 12, color: "#494949", maxWidth: isXs ? "100%" : "330px" }}>
            If you have comments or questions prior to approval, please submit them in the Messages section on the right
            and a representative will respond as soon as possible.
         </p>
         <div style={{ flexGrow: 1 }} />
         {!isInvoice ? (
            <>
               <RequestChangesModal
                  visible={modalRequestChanges}
                  close={() => setRequestChanges(false)}
                  sendRequestChanges={sendRequestChanges}
                  isStatementOfWork
               />
               <Button onClick={() => setRequestChanges(true)}>Edit Service Plan</Button>
            </>
         ) : null}
      </ApproveRow>
   )

   return isXs ? (
      isMobileActions ? (
         <ActionBlock />
      ) : (
         <MainBlock />
      )
   ) : (
      <DetailsContainer>
         <Header alignItems="center" className="topHeader">
            <Flex justifyContent="center" marginRight="60px">
               <div style={{ width: "150px", height: "90px" }}>
                  <img src={company.logo || DEFAULT_LOGO} alt="Logo" />
               </div>
            </Flex>
            <HeaderInfo>
               <Flex>
                  <Flex flexDirection="column" marginRight="120px">
                     <h3>COMPANY</h3>
                     <h4>{company.name}</h4>
                     <p>{getAddress(company.address) || "-"}</p>
                     <p> {company.phone || "-"}</p>
                     <p style={{ color: "#0496FF" }}>{company.email || "-"}</p>
                  </Flex>

                  <Flex alignItems="flex-start" flexDirection="column" marginLeft="55px">
                     <h3>BILLING CONTACT</h3>
                     <h4>{work_order?.customer?.full_name}</h4>
                     <p>{work_order?.customer?.address && renderAddress(work_order?.customer?.address)}</p>
                     <p>
                        {work_order?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")
                           ?.phone || "No data"}
                     </p>
                     <p style={{ color: "#0496FF" }}>{work_order?.customer?.email}</p>
                  </Flex>
               </Flex>
            </HeaderInfo>
         </Header>

         <MainBlock />

         <ActionBlock />
      </DetailsContainer>
   )
}
