import React, { useEffect, useState, createRef, useContext, useMemo, useCallback } from "react"
import { useReactToPrint } from "react-to-print"
import { Button, Form, Input, Tooltip, Modal, Checkbox, Spin, notification } from "antd"
import { Helmet } from "react-helmet-async"

import Flex from "../../noui/Flex"
import { Title } from "../../components/Title"
import { Container } from "../../components/Container"
import { AddCustomer } from "./components/AddCustomer"
import { AddVessel } from "./components/AddVessel"
import { AddJob } from "./components/AddJob"
import {
   WorkOrderName,
   ResponsiveFlex,
   TitleWorkOrder,
   WarningWorkOrder,
   StyledCloseBtn,
   WarningOnCreate,
} from "./styled"

import { WorkOrderInfo } from "./WorkOrderInfo"
import { useLoading, useQuery, useResponsive, useWebSocket } from "../../utils/hooks"
import { Api, TRecurringPricing, TRequest } from "../../api/api"
import { ERecurringStatus, IJob, IWorkOrderWithJobs, EJobProgress, IWSMessage, IUpdateJob } from "../../api/types"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { formatPriority, getErrors, showErrors } from "../../utils/utils"
import routes from "../../routes/routes"
import { CustomData } from "../types"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { Msg } from "../../ui/Text"
import moment from "moment"
import { EditIcon, CloseWarning } from "../../assets/icons"
import { EUserRole } from "../../api/types"
import { useSelector, useDispatch } from "react-redux"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { setMenuItem } from "../../config/reducers/menuClickReducer/actions"
import { getMenuItem } from "../../config/reducers/menuClickReducer/selectors"
import { track } from "../../utils/analytics"
import PrintWorkOrder from "./PrintDocuments/PrintWorkOrder"
import RecurringWorkOrder from "./RecurringWorkOrder"
import { RecurringIcon } from "../../assets/icons/siteIcons"
import RecurringModal from "./RecurringModal"
import styled from "styled-components"
import RecurringErrorModal from "./RecurringErrorModal"
import RecurringJobConfig from "./RecurringJobConfig"
import ModalPrintCheckboxes from "./PrintDocuments/ModalPrintCheckboxes"
import ModalRecurringCheckboxes from "./PrintDocuments/ModalRecurringCheckboxes"
import SaveChangesModal from "./components/SaveChangesModal"
import { Warning } from "./components/Warning"
import { WOContextStore } from "./WorkOrderContext"
import { JobForm } from "./JobForm"

type TJobWrapperProps = {
   isRecurring: boolean
}
type TRespFlexProps = { gap?: number; wrap?: boolean }

const JobWrapper = styled(Flex)<TJobWrapperProps>`
   flex-direction: column;
   box-shadow: ${(props) => (props.isRecurring ? "0px 2px 10px rgba(0, 0, 0, 0.15)" : "none")};
   border-radius: ${(props) => (props.isRecurring ? "0px 0px 4px 4px" : "0")};
   margin-top: ${(props) => (props.isRecurring ? "24px" : "0px")};
   ${down("lg")} {
      box-shadow: none;
      border-radius: 0;
      margin-top: 0px;
   }
`
const ResponsiveFlexWO = styled(ResponsiveFlex)<TRespFlexProps>`
   align-items: normal;
   height: 100%;
`

type TForm = {
   title?: string
   notes?: string
}
type PrintBlocks = {
   woPricing: boolean
   woPhotos: boolean
   vesselPhoto: boolean
   beforeAfterPhotos: boolean
   checkAll: boolean
}
type TJobsUpdate = {
   jobs: TJobUpdate[] | []
}
type TJobUpdate = {
   id: number
   tab: string
   update: boolean
}
type Period = {
   from: any
   to: any
}

const WorkOrderFlow: React.FC = () => {
   const [form] = Form.useForm<TForm>()
   const [formJob] = Form.useForm<IUpdateJob>()
   const [formSP] = Form.useForm()
   const [workOrder, setWorkOrder] = useState<IWorkOrderWithJobs | null>(null)
   const [jobs, setJobs] = useState<IJob[]>([])
   const [saving, onSave] = useLoading()
   const { id } = useParams<{ id?: string }>()
   const [customerId, setCustomerId] = useState<number | null>(null)
   const [customData, setCustomData] = useState<CustomData>({
      customer: null,
      vessel: null,
   })
   const { width } = useResponsive()
   let query = useQuery()
   const location = useLocation()
   const history = useHistory()
   const isXs = useBreakpoint(down("xs"))
   const isLg = useBreakpoint(down("lg"))
   const [isEditing, setIsEditing] = useState<boolean>(false)
   const userData = useSelector(getCurrentUser)

   const componentPrintRef = createRef<HTMLDivElement>()
   const [recurringModal, setRecurringModal] = useState(false)
   const [recurringWOForm] = Form.useForm()
   const [title, setTitle] = useState(workOrder?.recurring_config?.title || "No data")
   const [printBlocks, setPrintBlocks] = useState<PrintBlocks>({
      woPricing: true,
      woPhotos: false,
      vesselPhoto: true,
      beforeAfterPhotos: true,
      checkAll: false,
   })
   const [showPrintModal, setPrintModal] = useState(false)
   const [showPrintModalRecurring, setPrintModalRecurring] = useState(false)
   const [isPrintWorkOrderHidden, setIsPrintWorkOrderHidden] = useState<boolean>(false)
   const [showMore, setShowMore] = useState(true)
   const [showSaveChangesModal, setShowSaveChangesModal] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const [recurringPrintPeriod, setRecurringPrintPeriod] = useState<Period>({
      from: null,
      to: null,
   })
   const [warnings, setWarnings] = useState<TRequest[]>([])
   const [warningForm, setWarningForm] = useState<string | null>()
   const [currentSPJob, setCurrentSPJob] = useState<number | null>(null)
   const [jobFormData, setJobFormData] = useState<any>(null)
   const [orderNumber, setOrderNumber] = useState<any>(null)
   const [activeTab, setActiveTab] = useState<string>("1")
   const [pricing, setPricing] = useState<TRecurringPricing>({
      payment: 0,
      repeats: 0,
      total: 0,
   })
   const dispatch = useDispatch()
   const menuClick = useSelector(getMenuItem)

   const jobRecurringPrice = useMemo(() => {
      if (
         !workOrder ||
         !workOrder.is_recurring ||
         workOrder.recurring_status !== ERecurringStatus.Approved ||
         !pricing.repeats
      )
         return null
      return pricing.total / pricing.repeats
   }, [workOrder, pricing])

   const handleWarning = (data: IWSMessage<TRequest>) => {
      if (data?.code === "warning") {
         setWarnings([...warnings, data.message])
      }
   }

   useWebSocket(String(workOrder?.id ?? 0), "W", handleWarning)

   const loadWorkOrderData = async () => {
      setIsLoading(true)
      form.resetFields()
      if (id) {
         try {
            const { data } = await Api.workOrders.get(Number(id))
            setWorkOrder(data as IWorkOrderWithJobs)
            setWarnings(data.warnings.filter((w) => !w.resolve && !w.is_read))
            setCustomData({ customer: data.customer.id, vessel: data?.vessel?.id })
            form.setFieldsValue({ title: data.title, notes: data.notes })
            const { data: jobs } = await Api.jobs.getAll({
               work_order: Number(id),
            })
            setJobs(jobs as IJob[])
         } catch (err) {
            console.log(err)
         }
      }
      setIsLoading(false)
   }

   const calculateDurationEstimate = (d: string | null): moment.Moment | null => {
      if (!d) {
         return null
      }
      // TODO: Calc days?
      const parts = d.split(" ")
      const time = parts[parts.length - 1].split(":")
      return moment().hour(Number(time[0])).minute(Number(time[1])).second(0)
   }

   const loadPricing = async () => {
      if (workOrder?.recurring_config) {
         const { data: _data } = await Api.workOrders.getRecurringCalculations({
            ...workOrder.recurring_config,
            id: workOrder.id,
         })
         setPricing(_data)
      }
   }

   const getOrderNumber = async () => {
      if (isCreatePage()) {
         const { data } = await Api.workOrders.getOrderNumber()
         setOrderNumber(data.order_number)
      }
   }

   useEffect(() => {
      loadPricing()
   }, [workOrder])

   useEffect(() => {
      getOrderNumber()
   }, [])

   useEffect(() => {
      if (isCreatePage()) {
         setTitle(orderNumber)
      } else {
         setTitle(workOrder?.title ?? "")
      }
   }, [orderNumber, workOrder])

   const isCreatePage = () => location.pathname === routes.workOrder.CreateWorkOrder

   useEffect(() => {
      if (location.state && location.state.customer) {
         setCustomerId(location.state.customer.id)
      }
   }, [location.state])

   useEffect(() => {
      loadWorkOrderData()
   }, [id, form])

   const updateData = (key: string, value: any) => {
      setCustomData((prev) => ({
         ...prev,
         [key]: value,
      }))
   }
   const calculateDuration = (d: string | number | moment.Moment | null | undefined): moment.Moment | null | number => {
      if (!d) {
         return null
      }
      // TODO: Calc days?
      const time = moment(d).format("hh:mm:ss")
      const seconds = moment.duration(time).asSeconds()

      return seconds
   }

   const handleSaveWorkOrder = async (type: any) => {
      try {
         await form.validateFields()
         await formJob.validateFields()

         const formData = await form.getFieldsValue()
         let formDataJob = await formJob.getFieldsValue()
         // console.log("formData", formData)
         // console.log("formDataJob", formDataJob)
         if (!formDataJob.services && formDataJob?.temp_service) {
            const { temp_service, ..._jobData } = formDataJob
            const _services = !!temp_service.name ? [{ ...temp_service }] : []
            formDataJob = { ..._jobData, services: _services }
         }

         if (!formDataJob.materials && formDataJob?.temp_material) {
            const { temp_material, ..._jobData } = formDataJob
            const _materials = !!temp_material.name ? [{ ...temp_material }] : []
            formDataJob = { ..._jobData, materials: _materials }
         }

         if (!formDataJob.materials && !formDataJob.services) {
            setWarningForm("Please select a customer & add at least one item under Services or Materials")
         } else {
            setWarningForm("")
            const duration = calculateDuration(formDataJob.estimated_duration)
            let dataSend = {
               ...formData,
               ...formDataJob,
               customer: customData.customer,
               wo_title: title,
            }

            if (duration && typeof duration === "number") {
               dataSend = {
                  ...dataSend,
                  estimated_duration: duration,
               }
            }

            if (customData.vessel) {
               dataSend = {
                  ...dataSend,
                  ...customData,
               }
            }

            const { data } = await onSave(Api.workOrders.createJob(dataSend))
            await history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(data.work_order.id)), { type })
         }
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors)
      }
   }

   const proceedWithoutApproval = async () => {
      if (workOrder) {
         try {
            await Api.jobs.proceedWithoutApprove(workOrder.id)
            notification.success({ message: "The estimate has been approved" })
            setActiveTab("2")
         } catch (e) {
            console.log("eroro", e)
            // notification.error({
            //    message: "Job is already approved",
            // })
         }
      }
   }

   const handleAddJob = async () => {
      let work_order = workOrder?.id ?? 0
      if (!workOrder?.id) {
         try {
            const { data } = await onSave(
               Api.workOrders.create({
                  customer: customData.customer ?? 0,
                  vessel: customData.vessel ?? 0,
               })
            )
            track("Work Order Created", {
               workorderName: data.title,
               workorderId: data.id,
               workorderCreatedBy: userData?.id,
               customerId: data.customer.id,
               vesselName: data.vessel.name,
               vesselLengthFeet: data.vessel.length,
            })
            await setWorkOrder(data as IWorkOrderWithJobs)
            work_order = (await data.id) as number
         } catch (e) {
            showErrors(getErrors(e))
         }
      }
      const { data: jobData } = await onSave(Api.jobs.create({ work_order }))
      track("Job Added to Work Order", {
         workorderName: workOrder?.title,
         workorderId: work_order,
         jobCreatedBy: userData?.id,
         customerId: jobData.customer_data.id,
         vesselId: jobData?.vessel_data?.id,
         jobName: jobData.title,
         jobId: jobData.id,
         priority: formatPriority(jobData.priority),
      })
      await setJobs([...jobs, jobData])
      if (isCreatePage() && work_order) {
         await history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(work_order)))
      }
   }

   const showAddJob = Object.values(customData).every((k) => k)

   const changeTitle = async (e: any) => {
      const newTitle = e.target.value
      if (newTitle && workOrder?.id && newTitle !== workOrder?.title) {
         const res = await Api.workOrders.changeTitle({
            id: workOrder.id,
            title: newTitle,
         })
         const wo = (await res.data) as IWorkOrderWithJobs
         setWorkOrder(wo)
      }
      setIsEditing(false)
   }

   const handlePrint = useReactToPrint({
      content: () => componentPrintRef.current,
      onAfterPrint: () => {
         setRecurringPrintPeriod({ from: null, to: null })
         setPrintBlocks({
            woPricing: true,
            woPhotos: false,
            vesselPhoto: true,
            beforeAfterPhotos: true,
            checkAll: false,
         })
      },
   })

   const technicianRole = userData && userData?.role_id !== EUserRole.Technician ? false : true

   const openRecurringModal = () => {
      setRecurringModal(true)
   }

   const closeRecurringModal = () => {
      setRecurringModal(false)
   }

   const updateConfig = async () => {
      const fields = await recurringWOForm.validateFields()
      if (!fields?.errorFields?.length) {
         await recurringWOForm.submit()
         return true
      }
      return false
   }

   const checkLoading = () => !workOrder && !isCreatePage() && isLoading

   const isInvoice = () => jobs.every((j: any) => j.progress === EJobProgress.Complete)

   const handleShowPrintModal = () => {
      if (workOrder?.is_recurring && workOrder.recurring_status === ERecurringStatus.Approved) {
         setPrintModalRecurring(true)
      } else {
         setPrintModal(true)
      }
   }

   const getPageTitle = () => {
      if (isCreatePage()) {
         return "New Job"
      } else if (workOrder?.is_recurring) {
         return "Service Plan"
      }

      return workOrder?.title
   }

   const onWarningClose = async (id: number) => {
      const filteredWarnings = warnings.filter((w) => w.id !== id)
      await Api.warnings.read(id)
      setWarnings(filteredWarnings)
   }

   const isProd = () => process.env.REACT_APP_ENV === "demo" || process.env.REACT_APP_ENV === "prod"

   const updateJobById = async (id: number) => {
      if (!id) return
      const { data: _job } = await Api.jobs.get(id)
      setJobs(jobs.map((j) => (j.id === id ? _job : j)))
   }

   useEffect(() => {
      if (workOrder && jobs && workOrder.is_recurring && jobs.length) {
         let _currentJob: number | null = null
         jobs.forEach((job, index) => {
            if ((!_currentJob && !job.completed_at) || (!_currentJob && index === jobs.length - 1)) {
               _currentJob = job.id
            }
         })
         setCurrentSPJob(_currentJob)
      }
   }, [workOrder, jobs])

   const renderRecurringControl = () =>
      !!workOrder &&
      (!workOrder?.is_recurring ||
         workOrder.recurring_status === ERecurringStatus.Approved ||
         workOrder.recurring_status === ERecurringStatus.Draft) &&
      !technicianRole && (
         <Flex>
            <RecurringIcon />
            <Msg
               style={{
                  marginLeft: "6px",
                  color: "#0496FF",
                  textDecoration: "underline",
                  fontWeight: 700,
                  cursor: "pointer",
               }}
               onClick={openRecurringModal}
            >
               {workOrder?.is_recurring
                  ? workOrder.recurring_status === ERecurringStatus.Approved
                     ? "Stop Service Plan"
                     : "Delete Service Plan"
                  : "Create Service Plan"}
            </Msg>
         </Flex>
      )
   const closeShowSaveChangesModal = () => {
      dispatch(setMenuItem({ menuRoute: "work-order" }))
      setShowSaveChangesModal(false)
   }

   useEffect(() => {
      const handleBeforeUnload = (event: any) => {
         if (menuClick.showModal) {
            event.preventDefault()
            setShowSaveChangesModal(true)

            event.returnValue = "You have unfinished changes!"
         }
      }

      window.addEventListener("beforeunload", handleBeforeUnload)

      return () => window.removeEventListener("beforeunload", handleBeforeUnload)
   }, [menuClick])

   return (
      <WOContextStore>
         <RecurringModal
            visible={recurringModal}
            onClose={closeRecurringModal}
            workOrder={workOrder}
            jobs={jobs}
            loadData={loadWorkOrderData}
         />
         <Flex p={isXs ? "13px" : "34px"} pt="15px" flexDirection="column">
            <TitleWorkOrder
               flexDirection={isXs ? "column" : "row"}
               alignItems={isXs ? "start" : "center"}
               justifyContent="space-between"
               mb="18px"
               mt={width <= 576 ? "0px" : "76px"}
            >
               <Msg fontWeight={800} fontSize={24} marginRight="16px">
                  {getPageTitle()}
               </Msg>
               <Msg color="#202020" fontWeight="bold" fontSize="18px">
                  Job number: {isCreatePage() ? orderNumber ?? "" : `#${workOrder?.order_number ?? ""}`}
               </Msg>

               <WarningWorkOrder flex={1} width="100%" maxWidth="800px" flexDirection="column">
                  {warnings.map((warning) => (
                     <Warning technicianRole={technicianRole} warning={warning} onClose={onWarningClose} />
                  ))}
               </WarningWorkOrder>
               {warningForm && (
                  <WarningOnCreate>
                     <StyledCloseBtn onClick={() => setWarningForm("")}>
                        <CloseWarning />
                     </StyledCloseBtn>
                     {warningForm}
                  </WarningOnCreate>
               )}
            </TitleWorkOrder>
            {checkLoading() ? (
               <Container>
                  <Spin spinning={isLoading}>
                     <Flex height="200px" />
                  </Spin>
               </Container>
            ) : (
               <Container>
                  <Flex flexDirection="column" padding={`24px ${width > 576 ? 28 : width > 375 ? 18 : 8}px`}>
                     <ResponsiveFlexWO gap={isLg ? 0 : 24} mt={isXs ? "0px" : "24px"}>
                        <AddCustomer
                           form={form}
                           selectedCustomer={workOrder?.customer ?? null}
                           updateData={updateData}
                           customerId={[customerId, customData.customer]}
                           setCustomerId={setCustomerId}
                           showSaveModal={setShowSaveChangesModal}
                        />
                        <AddVessel
                           form={form}
                           selectedVessel={workOrder?.vessel ?? null}
                           updateData={updateData}
                           setCustomerId={setCustomerId}
                           workOrder={workOrder}
                           customData={customData}
                           showSaveModal={setShowSaveChangesModal}
                        />

                        <AddJob
                           onAddJob={handleAddJob}
                           saving={saving}
                           form={form}
                           formSP={formSP}
                           recurringWOForm={recurringWOForm}
                           workOrder={workOrder}
                           loadData={loadWorkOrderData}
                           jobs={jobs}
                           technicianRole={technicianRole}
                           handlePrint={handleShowPrintModal}
                           // isRecurring={isRecurring}
                           openRecurringModal={openRecurringModal}
                           setWorkOrder={setWorkOrder}
                           handleSaveWorkOrder={handleSaveWorkOrder}
                           isCreatePage={isCreatePage()}
                           orderNumber={orderNumber}
                           setTitle={setTitle}
                           title={title}
                           proceedWithoutApproval={proceedWithoutApproval}
                           showSaveModal={setShowSaveChangesModal}
                           saveChangesModal={showSaveChangesModal}
                        />
                     </ResponsiveFlexWO>
                     <JobWrapper isRecurring={!!workOrder?.is_recurring}>
                        {workOrder?.is_recurring && (
                           <>
                              <RecurringWorkOrder
                                 pricing={pricing}
                                 workOrder={workOrder}
                                 form={recurringWOForm}
                                 showMore={showMore}
                                 setShowMore={setShowMore}
                                 openRecurringModal={openRecurringModal}
                              />
                              {workOrder.recurring_status !== ERecurringStatus.Approved && !jobs.length && (
                                 <RecurringJobConfig
                                    workOrder={workOrder}
                                    loadData={loadWorkOrderData}
                                    form={formSP}
                                    recurringForm={recurringWOForm}
                                    showMoreJC={showMore}
                                    setShowMoreJC={setShowMore}
                                 />
                              )}
                           </>
                        )}
                        {jobs &&
                           jobs.length > 0 &&
                           jobs.map((job, index) => {
                              return (
                                 <WorkOrderInfo
                                    updateInfo={() => {
                                       isCreatePage() && workOrder !== null
                                          ? history.push(
                                               routes.workOrder.UpdateWorkOrder.replace(":id", String(workOrder.id))
                                            )
                                          : loadWorkOrderData()
                                    }}
                                    job={job}
                                    key={job.id}
                                    estimateSent={Boolean(workOrder?.estimates.length)}
                                    technicianRole={technicianRole}
                                    isDespositPaid={
                                       (workOrder?.deposited ?? 0) >= (workOrder?.required_deposit_amount ?? 0)
                                    }
                                    workOrder={workOrder}
                                    isInvoice={isInvoice()}
                                    isCurrentSPJob={job.id === currentSPJob}
                                    lastWOJob={!!(!workOrder?.is_recurring && index === jobs.length - 1)}
                                    updateJobById={updateJobById}
                                    onAddJob={handleAddJob}
                                    openRecurringModal={openRecurringModal}
                                    activeTabSecond={activeTab}
                                    jobRecurringPrice={jobRecurringPrice}
                                    showSaveModal={setShowSaveChangesModal}
                                 />
                              )
                           })}

                        {isCreatePage() && (
                           <JobForm
                              form={formJob}
                              orderNumber={orderNumber}
                              handleSaveWorkOrder={handleSaveWorkOrder}
                           />
                        )}
                     </JobWrapper>
                  </Flex>
               </Container>
            )}
         </Flex>
         <ModalPrintCheckboxes
            handlePrint={handlePrint}
            setPrintModal={setPrintModal}
            showPrintModal={showPrintModal}
            checkboxes={printBlocks}
            setPrintBlocks={setPrintBlocks}
            technicianRole={technicianRole}
         />
         <ModalRecurringCheckboxes
            handlePrint={handlePrint}
            setPrintModalRecurring={setPrintModalRecurring}
            showPrintModalRecurring={showPrintModalRecurring}
            checkboxes={printBlocks}
            setPrintBlocks={setPrintBlocks}
            technicianRole={technicianRole}
            setRecurringPrintPeriod={setRecurringPrintPeriod}
            recurringPrintPeriod={recurringPrintPeriod}
            jobs={jobs}
         />
         <SaveChangesModal visible={showSaveChangesModal} onClose={closeShowSaveChangesModal} />

         {(showPrintModal || showPrintModalRecurring) && (
            <PrintWorkOrder
               ref={componentPrintRef}
               workOrder={workOrder}
               jobs={jobs}
               userData={userData}
               technicianRole={technicianRole}
               woPricing={printBlocks.woPricing}
               woPhotos={printBlocks.woPhotos}
               vesselPhoto={printBlocks.vesselPhoto}
               beforeAfterPhotos={printBlocks.beforeAfterPhotos}
               isInvoice={isInvoice()}
               isRecurring={!!workOrder?.is_recurring}
               recurringPrintPeriod={recurringPrintPeriod}
            />
         )}
      </WOContextStore>
   )
}

export default WorkOrderFlow
