import React, { useState, useEffect } from "react"
import {
   CheckboxName,
   CloseLogoButton,
   CompanyInfoContainer,
   CompanyInfoWrapper,
   ImageWrapperFlex,
   MainInfoContainer,
   MainUpload,
   SaveBtn,
   UploadtContainer,
   UploadtContainerAfter,
   UploadtContainerAfterMsg,
   UploadtContent,
   UploadWrapper,
   ImageContainer,
   ResponsiveWrapper,
   ContainerTitle,
   SaveBtnWrapper,
} from "./styled"
import {Form, Input, Select, Spin, AutoComplete, InputNumber} from "antd"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import {DefaultPhoneNumber, states, TimeZones} from "../../constants"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { GoogleMapsAPIKey } from "../../api"
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { SearchOutlined } from "@ant-design/icons"
import styled from "styled-components";
import {phoneFormatter} from "../../utils/utils";

const PhoneInput = styled(InputNumber)`
   width: 100%;
   .ant-input-number-handler-wrap {
      display: none;
   }
`;

export const MainInfoForm = ({ form, showModal, onRemoveLogo, logo, saving, uploadImage, isImageUploading }) => {
   const isXS = useBreakpoint(down("xs"))
   const isMD = useBreakpoint(down("md"))
   const [value, setValue] = useState("");
   const {
         placePredictions,
         placesService,
         getPlacePredictions,
   } = useGoogle({
         apiKey: GoogleMapsAPIKey,
   });
   const [selectedValue, setSelectedValue] = useState(null)

   const selectPlace = async (_value) => {
         const place = placePredictions.find(p => p.description === _value)
         if (place) {
            placesService?.getDetails(
               {placeId: place.place_id},
               (details) => setSelectedValue(details)
            )
         }
   }

   const findComponentValue = (name, isLong) => {
         const _component = selectedValue?.address_components?.find((c) => c?.types?.includes(name));
         return isLong ? _component?.long_name : _component?.short_name;
   }

   const formatAddress = () => {
         const route = findComponentValue('route', true);
         const street_number = findComponentValue('street_number', true);
         if (route && street_number) {
            return `${street_number} ${route}`
         }
         return selectedValue?.vicinity ?? '';
   }

   const formatData = () => {
         const lat = selectedValue?.geometry?.location.lat()
         const lng = selectedValue?.geometry?.location.lng()
         const zip_code = findComponentValue('postal_code', true);
         const state = findComponentValue('administrative_area_level_1', false);
         const city = findComponentValue('locality', true) ?? findComponentValue('administrative_area_level_1', true);
         const address1 = formatAddress()
         const address2 = findComponentValue('subpremise', true)
         const full_address = value;
         const _data = {
            lat,
            lng,
            zip_code,
            state,
            city,
            address1,
            address2,
            full_address
         };
         form.setFieldsValue(_data);
   }

   const handleAutocompleteValueChange = (_value) => {
         setValue(_value);
   }

   const formatPredictions = () => {
         return placePredictions.map(i => ({label: i.description, value: i.description}))
   }

   useEffect(() => {
         getPlacePredictions({input: value});
   }, [value])

   useEffect(() => {
         if (selectedValue) {
            formatData()
         }
   }, [selectedValue])

   return (
      <>
         <CompanyInfoContainer flexDirection="column" p={16}>
            <Form
               layout={isXS ? "horizontal" : "vertical"}
               form={form}
               initialValues={{
                  ["state"]: states[0],
               }}
            >
               <Form.Item hidden name="id">
                  <Input type="hidden" />
               </Form.Item>
               <ContainerTitle>Company Info</ContainerTitle>
               <CompanyInfoWrapper pb="32px" mt="16px">
                  <MainInfoContainer flexDirection="column">
                     <Form.Item label="Company name" name="name">
                        <Input placeholder="Type here" />
                     </Form.Item>
                     <Form.Item name="print_name">
                        <CheckboxName>
                           <Msg fontWeight={400} fontSize={13} color="#484848">
                              Show name on printed documents.
                           </Msg>
                        </CheckboxName>
                     </Form.Item>
                     <Form.Item name="website" label="Website">
                        <Input placeholder="Type here" />
                     </Form.Item>
                     <Form.Item name="email" label="E-mail">
                        <Input type="email" placeholder="Type here" />
                     </Form.Item>
                     <Form.Item name="phone" label="Phone">
                        <Input
                           onBlur={() => {
                              form.setFieldsValue(
                                {'phone': phoneFormatter(form.getFieldsValue(['phone']).phone)}
                              );
                           }}
                           placeholder={DefaultPhoneNumber} />
                     </Form.Item>
                     <Form.Item name="timezone" label="Time Zone">
                        <Select showSearch getPopupContainer={(trigger) => trigger.parentElement}>
                           {TimeZones.map((el) => (
                              <Select.Option key={el} value={el}>
                                 {el}
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </MainInfoContainer>
                  <UploadtContainerAfter flexDirection="column">
                     <UploadtContainer>
                        <Form.Item name="logo" label={<label style={{ color: "white" }}>Logo</label>}>
                           <Spin spinning={isImageUploading}>
                              {logo ? (
                                 <ImageWrapperFlex>
                                    <ImageContainer>
                                       <img src={logo} alt="company logo" />
                                       <CloseLogoButton className="cancelButton" onClick={onRemoveLogo}>
                                          X
                                       </CloseLogoButton>
                                    </ImageContainer>
                                 </ImageWrapperFlex>
                              ) : (
                                 <MainUpload type="file" name="logo" customRequest={uploadImage}>
                                    <UploadWrapper>
                                       <UploadtContent />
                                       <Msg fontWeight={400} fontSize={13} color="#828282" mt="24px" center>
                                          Shop Logo{" "}
                                       </Msg>
                                       <Msg fontWeight={400} fontSize={13} color="#828282" center>
                                          Drag image here or click to add
                                       </Msg>
                                       {!isMD && (
                                          <Msg
                                             fontWeight={400}
                                             fontSize={13}
                                             color="#828282"
                                             center
                                             style={{ position: "absolute", bottom: "10px" }}
                                          >
                                             The recommended size of the company logo is 1024x1024
                                          </Msg>
                                       )}
                                    </UploadWrapper>
                                 </MainUpload>
                              )}
                           </Spin>
                        </Form.Item>
                     </UploadtContainer>
                     <UploadtContainerAfterMsg
                        fontWeight={400}
                        fontSize={13}
                        style={{ textAlign: "center" }}
                        color="#484848"
                        center
                        m="auto"
                     >
                        Your logo will appear on quotes, invoices, work orders, and work request forms.
                     </UploadtContainerAfterMsg>
                  </UploadtContainerAfter>
               </CompanyInfoWrapper>
               <Flex mt="33px" mb="22px" flexDirection="column">
                  <Msg fontWeight={700} fontSize={15} color="#494949" mb="16px">
                     Company Address
                  </Msg>
                  <Form.Item name="full_address" label="Search Address">
                     <AutoComplete
                        options={formatPredictions()}
                        value={value}
                        onChange={handleAutocompleteValueChange}
                        style={{width: isMD ? "100%" : "calc(50% - 15px)"}}
                        onSelect={selectPlace}
                     >
                        <Input prefix={<SearchOutlined/>} placeholder="Search"/>
                     </AutoComplete>
                  </Form.Item>                  
                  <ResponsiveWrapper justifyContent="space-between">
                     <Form.Item
                        name="address1"
                        label="Address 1"
                        style={{ flexGrow: 1 }}
                     >
                        <Input placeholder="Type here" />
                     </Form.Item>
                     <Form.Item name="address2" label="Address 2" style={{ flexGrow: 1 }}>
                        <Input placeholder="Type here" />
                     </Form.Item>
                  </ResponsiveWrapper>
                  <ResponsiveWrapper justifyContent="space-between" mb="22px" position="relative">
                     <Form.Item
                        name="city"
                        label="City"
                        style={{ flex: 1 }}
                     >
                        <Input placeholder="Type here" />
                     </Form.Item>
                     <Flex style={{ flex: 1 }}>
                        <Flex flexDirection="column" minWidth="104px" marginRight="30px">
                           <Form.Item name="state" label="State">
                              <Select showSearch getPopupContainer={(trigger) => trigger.parentElement}>
                                 {states.map((el) => (
                                    <Select.Option key={el} value={el}>
                                       {el}
                                    </Select.Option>
                                 ))}
                              </Select>
                           </Form.Item>
                        </Flex>
                        <Flex flexDirection="column" style={{ flexGrow: 1 }}>
                           <Form.Item name="zip_code" label="Zip Code">
                              <Input placeholder="95123" />
                           </Form.Item>
                        </Flex>
                     </Flex>
                  </ResponsiveWrapper>
                  <SaveBtnWrapper justifyContent="flex-end">
                     <Form.Item shouldUpdate>
                        {() => (
                           <SaveBtn
                              loading={saving}
                              disabled={!form.isFieldsTouched()}
                              ml={16}
                              onClick={() => showModal(true)}
                           >
                              Save
                           </SaveBtn>
                        )}
                     </Form.Item>
                  </SaveBtnWrapper>
               </Flex>
            </Form>
         </CompanyInfoContainer>
      </>
   )
}
