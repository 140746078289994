import { useState, useEffect } from "react"
import styled from "styled-components"
import { Collapse, Modal, Pagination } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { EditIcon } from "../../assets/icons"
import { IMaterialGroup } from "../../api/types"
import Flex from "../../noui/Flex"
import { TArgCallback, TCallback } from "../../types"
import { Button, Input } from "antd"
import { SearchWithBorder } from "../../noui/Search"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import MaterialsTableMobile from "./MaterialsTableMobile"
import ModalFormEditAdd from "./ModalFormEditAdd"

const Block = styled.div`
   width: calc(100% - 40px);
   margin: 20px;
   overflow-x: hidden;
`
const StyledCollapse = styled(Collapse)`
   width: 100%;
   background: none;
   border: none;
   margin-bottom: 50px;

   .ant-collapse-header {
      font-size: 15px;
      font-weight: bold;
   }
   .ant-collapse-item {
      position: relative;
      background: #ffffff;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 20px;
      &.ant-collapse-item-active {
         box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      }
   }
`
const CreateGroupWrapper = styled.div`
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   padding: 15px 20px;
   position: sticky;
   bottom: 0;
   left: 0;
   width: 100%;
   overflow-x: hidden;
   @media screen and (max-width: 575px) {
      position: fixed;
   }
`
const CreateGroup = styled(Button)`
   width: 100%;
   margin: 0 auto;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   color: #0496ff;
   font-weight: bold;
   font-size: 15px;
   border: none;
   height: 48px;
   &:hover {
      border: 1px solid #0496ff;
   }
`
const CreateGroupInput = styled(Flex)`
   width: 100%;
   background: #ffffff;

   border-radius: 4px;
   padding: 5px;
   height: 48px;
   position: relative;
   &.error {
      border: 1px solid #fb4d4f;
   }
   input {
      width: 100%;
   }
   button {
      border: none;
      color: #0496ff;
      font-weight: bold;
      font-size: 13px;
   }
`
const InputError = styled.div`
   position: absolute;
   left: 3px;
   top: 105%;
   color: #fb4d4f;
   font-size: 11px;
   width: 100%;
`
const SearchField = styled(SearchWithBorder)`
   border-radius: 4px;
`
const SearchWrapper = styled.div`
   margin-bottom: 20px;
`
const AddButton = styled(PrimaryButton)`
   width: 147px;
   height: 34px;
   background: #0496ff;
   border: 1px solid #0496ff;
   border-radius: 6px;
   margin-bottom: 0;

   &.save {
      width: 54px !important;
      min-width: 54px;
      padding: 5px;
      color: #fff;
      &:hover,
      &:focus {
         color: #0496ff;
         border: 1px solid #0496ff;
      }
   }
`
const CustomButton = styled(EmptyButton)`
   font-size: 18px;
   margin-right: 25px;
   width: 30px;
   color: #c2cfe0 !important;

   &.ant-btn {
      background: none !important;
   }
   &.cancel {
      margin: 0 10px;
   }
`
const DeleteGroupButton = styled(EmptyButton)`
   background: #ffffff;
   border: 1px solid #ededed;
   box-sizing: border-box;
   border-radius: 6px;
   font-weight: 600;
   font-size: 14px;
   color: #fb4d4f;
   width: 100%;
   padding: 5px;
   height: 34px;
   margin: 0;
   &:hover,
   &:focus {
      border: 1px solid #fb4d4f;
      color: #fb4d4f;
   }
`
const ManageButton = styled(PrimaryButton)`
   width: 100%;
   height: 34px;
   background: #0496ff;
   border: 1px solid #0496ff;
   border-radius: 6px;
   margin: 20px 0;
`
const AddMaterial = styled(PrimaryButton)`
   width: 100%;
   height: 34px;
   background: #0496ff;
   border: 1px solid #0496ff;
   border-radius: 6px;
`

const StyledModal = styled(Modal)`
   max-width: 350px;
   & .ant-modal-body {
      padding: 16px;
   }

   h2 {
      font-size: 15px;
      font-weight: 800;
      text-transform: uppercase;
   }
   h3 {
      font-size: 15px;
      font-weight: bold;
   }
   & .manage_btns {
      display: inline-flex;
      gap: 15px;
      width: 100%;
   }
`
const EditField = styled(Flex)`
   position: relative;
   input {
      width: 100%;
      max-width: 260px;
   }
`
const EditTitleWrapper = styled(Flex)`
   position: absolute;
   left: 0;
   top: 0;
   padding: 12px 16px;
   width: calc(100% - 30px);
   background: #fff;

   h3 {
      font-weight: bold;
      font-size: 15px;
      color: #0496ff;
      margin: 0;
      line-height: 1.8;
   }
`
const ManageTitleWrapper = styled(Flex)`
   margin-top: 10px;
   h3 {
      font-weight: bold;
      font-size: 15px;
      margin: 0;
      line-height: 1.8;
   }
   button {
      margin: 5px;
      min-width: 65px !important;
      max-width: 65px !important;
   }
`
type TNewGroup = {
   name: string | ""
   error: string | ""
}

type TProps = {
   groups?: IMaterialGroup[]
   group: any
   handleCreateGroupe: TArgCallback<string>
   setActiveGroup: TArgCallback<any>
   setSearch: TArgCallback<any>
   data: any
   handleEditMaterial: TArgCallback<any>
   deleteMaterial: TArgCallback<any>
   duplicateMaterial: TArgCallback<any>
   searchMaterial: TArgCallback<any>
   editGroupName: TArgCallback<any>
   deleteGroup: TCallback
   handleChangePagination: (page: number, pageSize: number | undefined) => void
   pagination: any
   handleCreateMaterial: TArgCallback<any>
   type?: any
}

const GroupsMobile: React.FC<TProps> = ({
   group,
   groups,
   handleCreateGroupe,
   setActiveGroup,
   setSearch,
   data,
   handleEditMaterial,
   deleteMaterial,
   duplicateMaterial,
   deleteGroup,
   searchMaterial,
   editGroupName,
   handleChangePagination,
   pagination,
   handleCreateMaterial,
   type,
}) => {
   const [showDeleteModal, setShowDeleteModal] = useState(false)
   const [showManageModal, setShowManageModal] = useState(false)
   const [createGroup, setCreateGroup] = useState<boolean>(false)
   const [newGroup, setNewGroup] = useState<TNewGroup>({
      name: "",
      error: "",
   })
   const [editName, setEditName] = useState("")
   const [showAddModal, setShowAddModal] = useState(false)

   const [input, setInput] = useState({
      name: "",
      error: "",
   })
   const { Panel } = Collapse

   const handleChange = (e: any) => {
      setNewGroup({
         ...newGroup,
         name: e.target.value,
         error: "",
      })
   }

   const handleCreate = () => {
      if (newGroup.name.trim().length > 0 && newGroup.name.trim().length < 26) {
         handleCreateGroupe(newGroup.name)
         setCreateGroup(false)
         setNewGroup({
            name: "",
            error: "",
         })
      } else if (newGroup.name.trim().length === 0) {
         setNewGroup({
            ...newGroup,
            error: "Please, input name!",
         })
      } else if (newGroup.name.trim().length > 25) {
         setNewGroup({
            ...newGroup,
            error: "Ensure this field has no more than 25 characters!",
         })
      }
   }

   const handleChangeAccordion = (id: any) => {
      if (groups) {
         const findItem = groups.find((item) => item.id === Number(id))
         if (findItem) {
            setActiveGroup(findItem)
         }
      }
      setEditName("")
   }

   const handleDelete = () => {
      setShowDeleteModal(false)
      deleteGroup()
   }

   useEffect(() => {
      if (editName) {
         setInput({ ...input, name: editName })
      }
   }, [editName])

   const handleSaveName = () => {
      if (input.name.trim().length > 0 && input.name.trim().length < 26) {
         editGroupName(input.name)

         setEditName("")
      } else if (input.name.trim().length === 0) {
         setInput({
            ...input,
            error: "Please, input name!",
         })
      } else if (input.name.trim().length > 25) {
         setInput({
            ...input,
            error: "Ensure this field has no more than 25 characters!",
         })
      }
   }

   const clearModal = () => {
      setShowAddModal(false)
   }

   return (
      <Block>
         <SearchWrapper>
            <SearchField
               placeholder={`Search ${type === "rateCard" ? "service" : "material"} group`}
               onChange={(e) => setSearch(e.target.value)}
            />
         </SearchWrapper>
         {groups && groups.length > 0 ? (
            <StyledCollapse
               bordered={false}
               accordion
               className="site-collapse-custom-collapse"
               onChange={(activeId) => handleChangeAccordion(activeId)}
               expandIconPosition="right"
            >
               {groups.map((item: any) => {
                  return (
                     <Panel header={item.name} key={item.id} className="site-collapse-custom-panel">
                        <EditTitleWrapper>
                           <h3>{item.name}</h3>
                        </EditTitleWrapper>

                        <SearchField
                           placeholder={`Search ${type === "rateCard" ? "service" : "material"}`}
                           onChange={(e) => searchMaterial(e.target.value)}
                        />

                        <ManageButton onClick={() => setShowManageModal(true)}>
                           Manage {type === "rateCard" ? "Service" : "Material"} Group
                        </ManageButton>

                        <StyledModal
                           destroyOnClose
                           centered
                           visible={showManageModal}
                           onOk={() => {}}
                           onCancel={() => setShowManageModal(false)}
                           okText="Ok"
                           footer={false}
                        >
                           <h2>Manage {type === "rateCard" ? "Service" : "Material"} Group</h2>
                           <ManageTitleWrapper alignItems="center">
                              {!editName ? (
                                 <>
                                    <h3>{item.name}</h3>

                                    <CustomButton onClick={() => setEditName(item.name)}>
                                       <EditIcon />
                                    </CustomButton>
                                 </>
                              ) : (
                                 <EditField alignItems="center">
                                    <Input
                                       value={input.name}
                                       onChange={(e) => setInput({ ...input, name: e.target.value })}
                                    />
                                    <AddButton className="save" onClick={handleSaveName}>
                                       Save
                                    </AddButton>
                                    <DeleteGroupButton
                                       onClick={() => {
                                          setEditName("")
                                          setInput({ ...input, name: item.name })
                                       }}
                                    >
                                       Cancel
                                    </DeleteGroupButton>

                                    {input.error && <InputError>{input.error}</InputError>}
                                 </EditField>
                              )}
                           </ManageTitleWrapper>
                           <div className="manage_btns">
                              <DeleteGroupButton onClick={() => setShowDeleteModal(true)}>
                                 Delete group
                              </DeleteGroupButton>
                              <AddMaterial onClick={() => setShowAddModal(true)}>
                                 Add {type === "rateCard" ? "service" : "material"}
                              </AddMaterial>
                           </div>
                        </StyledModal>
                        <StyledModal
                           destroyOnClose
                           centered
                           visible={showDeleteModal}
                           onOk={handleDelete}
                           onCancel={() => setShowDeleteModal(false)}
                           okText="Delete"
                        >
                           <h3>
                              Are you sure want to delete <br /> {item.name}?
                           </h3>
                        </StyledModal>
                        <ModalFormEditAdd
                           visible={showAddModal}
                           handleSubmit={handleCreateMaterial}
                           clearModal={clearModal}
                           activeGroup={group}
                           groups={groups}
                           handleCreateGroupe={handleCreateGroupe}
                           type={type}
                        />

                        <MaterialsTableMobile
                           data={data}
                           handleEditMaterial={handleEditMaterial}
                           deleteMaterial={deleteMaterial}
                           duplicateMaterial={duplicateMaterial}
                           group={group}
                           groups={groups}
                           handleCreateGroupe={handleCreateGroupe}
                           type={type}
                        />
                        {!!pagination?.total && pagination.total > 10 && (
                           <Flex justifyContent="flex-end">
                              <Pagination
                                 current={pagination.current}
                                 pageSize={pagination.pageSize}
                                 total={pagination.total}
                                 onChange={(page, pageSize) => {
                                    handleChangePagination(page, pageSize)
                                 }}
                                 showSizeChanger
                                 style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
                              />
                           </Flex>
                        )}
                     </Panel>
                  )
               })}
            </StyledCollapse>
         ) : (
            <p>No data</p>
         )}

         {createGroup ? (
            <CreateGroupWrapper>
               <CreateGroupInput alignItems="center" className={newGroup.error ? "error" : ""}>
                  <Input value={newGroup.name} onChange={handleChange} placeholder="Enter group name" />
                  <CustomButton
                     onClick={() => {
                        setCreateGroup(false)
                        setNewGroup({ name: "", error: "" })
                     }}
                     className="cancel"
                  >
                     <CloseOutlined />
                  </CustomButton>
                  <AddButton className="save" onClick={handleCreate}>
                     Save
                  </AddButton>
                  <InputError>{newGroup.error}</InputError>
               </CreateGroupInput>
            </CreateGroupWrapper>
         ) : (
            <CreateGroupWrapper>
               <CreateGroup onClick={() => setCreateGroup(true)}>
                  + Create {type === "rateCard" ? "Service" : "Material"} Group
               </CreateGroup>
            </CreateGroupWrapper>
         )}
      </Block>
   )
}
export default GroupsMobile
