import styled from "styled-components"
import { Select } from "antd"
import { PrimaryButton } from "./Button"

export const TableSelect = styled(Select)`
height: 34px;
margin-left: 16px;
&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 6px;
  height: 34px;
  font-weight: 600;
  font-size: 14px;
  & .ant-select-selection-item {
    line-height: 32px;
}`

export const AddBtn = styled(PrimaryButton)`
   height: 34px;
   margin-left: 20px;
   border: 0.7px solid #1c99fc;
   border-radius: 6px;
   &:hover {
      background-color: #ffffff;
      & span {
         color: #40a9ff;
      }
   }
   &:hover {
      background-color: #ffffff;
      & span {
         color: #40a9ff;
      }
   }
   &.ant-btn:focus {
      background-color: #0496ff !important;
      color: #ffffff;
      & span {
         color: #ffffff;
      }
   }
`

export const ExportBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   width: 94px;
   background: #f0f1f6;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   font-weight: 600;
   font-size: 14px;
   color: #000000;
   & button {
      width: 94px;
   }
`
