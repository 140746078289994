import { Button } from "antd";
import { maxWidth } from "styled-system";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { HeaderLabel, TypeRowBlock } from "./styled";

type TImportType = {
    title: string
    description: string
    value: string
} 

const ImportTypes: TImportType[] = [
    {
        title: 'Customers',
        description: 'Upload your customer data',
        value: 'customers',
    },
    {
        title: 'Vessels',
        description: 'Upload information about your vessels',
        value: 'vessels',
    },
    {
        title: 'Users',
        description: 'Upload information about your technicians and employees here',
        value: 'users',
    },
    {
        title: 'Rate Cards',
        description: 'Upload your rate card information here',
        value: 'rate_cards',
    },
    {
        title: 'Materials',
        description: 'Upload information about your materials that you may use',
        value: 'materials',
    }
]

type TDataImportChooseTypeProps = {
    setImportType: (value: string) => void
}

const TypeRow: React.FC<TImportType & TDataImportChooseTypeProps> = ({title, description, value, setImportType}) => {
    const handleButtonClick = () => {
        setImportType(value)
    }

    return (
        <TypeRowBlock>
            <Flex>
            <Msg width="120px" fontWeight={700}>{title}</Msg>
            <Msg>{description}</Msg>
            </Flex>
            <Button onClick={handleButtonClick}>Import Data</Button>
        </TypeRowBlock>
    );
}

const DataImportChooseType: React.FC<TDataImportChooseTypeProps> = ({setImportType}) => {
    return (
        <Flex maxWidth="900px" flexDirection="column">
            <Flex maxWidth="650px" flexDirection="column" marginBottom="26px" padding="10px">
                <HeaderLabel>Data Import</HeaderLabel>
                <Msg>
                    In order to effectively build invoices, time sheets, vessel information, etc., 
                    you be able to upload your existing business data into the DockWorks app in a simple way.
                </Msg>
            </Flex>
            <Flex flexDirection="column">
                {ImportTypes.map(type => (
                    <TypeRow {...type} setImportType={setImportType} key={type.value} />
                ))}
            </Flex>
        </Flex>
    );
}

export default DataImportChooseType;