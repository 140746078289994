import React, { useEffect, useState } from "react"
import { AutoComplete, Form, FormInstance, Input, Select } from "antd"
import styled from "styled-components"
import { Api } from "../../../api/api"
import { mapOptions } from "../../../utils/utils"
import { servicesUnitTypes, materialsUnitTypes } from "../../../constants"
import { AddButton } from "../AddButton"
import { MinusCircleOutlined } from "@ant-design/icons"
import { ICreateJob, IPaginatedResponse, IService } from "../../../api/types"
import { TArgCallback, TOption } from "../../../types"
import { FormListFieldData } from "antd/es/form/FormList"

const Wrapper = styled.div`
   display: grid;
   grid-gap: 12px;
   grid-template-columns: 4fr 1fr 1.5fr 1fr 0.5fr;
   min-width: 0;
   > .ant-row {
      min-width: 0;
   }
`

type TSRProps = {
   form: FormInstance<ICreateJob>
   remove: TArgCallback<number | number[]>
   field: FormListFieldData
}
const ServiceRow: React.FC<TSRProps> = ({ field: { key, ...field }, remove, form }) => {
   const [options, setOptions] = useState<{ data: IService[]; mapped: TOption[] }>({ data: [], mapped: [] })

   useEffect(() => {
      Api.services
         .getAll({
            page: 1,
            pageSize: 5,
         })
         .then(({ data: d }) => {
            const { results } = d as IPaginatedResponse<IService[]>
             const filteredData = results.filter(item=>item.active)
            setOptions({ data: filteredData, mapped: mapOptions(filteredData, "name") })
         })
   }, [])

   const handleSelect = (id: string, val: any) => {
      const value = val as TOption
      const opt = options.data.find((el) => id === el.id.toString())
      if (opt) {
         const { price: unit_price, unit: unit_type } = opt
         const { name: key } = field
         const services = form.getFieldValue("services")
         services[key] = { ...services[key], unit_type, unit_price, qty: 1, name: value.label }
         // form.setFieldsValue({services});
      }
   }

   const handleSearch = (search: string) => {
      Api.services
         .getAll({
            page: 1,
            pageSize: 5,
            search,
         })
         .then(({ data: d }) => {
            const { results } = d as IPaginatedResponse<IService[]>
             const filteredData = results.filter(item=>item.active)
            setOptions({ data: filteredData, mapped: mapOptions(filteredData, "name") })
         })
   }

   return (
      <Wrapper>
         <Form.Item hidden {...field} name={[field.name, "id"]} fieldKey={[field.name, "id"]}>
            <Input type="hidden" />
         </Form.Item>
         <Form.Item
            {...field}
            name={[field.name, "name"]}
            fieldKey={[field.fieldKey, "name"]}
            rules={[{ required: true, message: "Please select a service item" }]}
         >
            <AutoComplete
               showSearch
               placeholder="Select service item"
               filterOption={false}
               options={options.mapped}
               onSelect={handleSelect}
               onSearch={handleSearch}
               notFoundContent={null}
            >
               <Input />
            </AutoComplete>
         </Form.Item>
         <Form.Item
            {...field}
            name={[field.name, "qty"]}
            fieldKey={[field.fieldKey, "qty"]}
            rules={[{ required: true, message: "Qty is required" }]}
         >
            <Input type="number" />
         </Form.Item>
         <Form.Item
            {...field}
            name={[field.name, "unit_type"]}
            fieldKey={[field.fieldKey, "unit_type"]}
            rules={[{ required: true, message: "Unit type is required" }]}
         >
            <Select options={servicesUnitTypes} />
         </Form.Item>
         <Form.Item
            {...field}
            name={[field.name, "unit_price"]}
            fieldKey={[field.fieldKey, "unit_price"]}
            rules={[{ required: true, message: "Unit price is required" }]}
         >
            <Input type="number" />
         </Form.Item>
         <Form.Item {...field}>
            <MinusCircleOutlined onClick={() => remove(field.name)} />
         </Form.Item>
      </Wrapper>
   )
}

type TProps = {
   form: FormInstance<ICreateJob>
}
export const ServicesBlock: React.FC<TProps> = ({ form }) => {
   return (
      <div>
         <Wrapper>
            <span>Services</span>
            <span>Qty</span>
            <span>Unit type</span>
            <span>Unit price</span>
            <span />
         </Wrapper>
         <Form.List name="services">
            {(fields, { add, remove }) => (
               <>
                  {fields.map((field) => (
                     <ServiceRow form={form} key={field.key} field={field} remove={remove} />
                  ))}
                  <Form.Item>
                     <AddButton onClick={() => add()}>Add Services item</AddButton>
                  </Form.Item>
               </>
            )}
         </Form.List>
      </div>
   )
}
