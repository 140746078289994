import React, { useState, useEffect, useMemo } from "react"
import { Helmet } from "react-helmet-async"
import {
   notification,
   Table,
   Select,
   Form,
   Tooltip,
   Button,
   Collapse,
   Pagination,
   Spin,
   Tabs,
   Popconfirm,
   Dropdown,
} from "antd"
import { EllipsisOutlined, PlusOutlined, RightOutlined, SettingFilled } from "@ant-design/icons"
import styled from "styled-components"
import moment, { unitOfTime } from "moment"
import { Api } from "../../api/api"
import { filterColumns, formatTime, formatTimeTimeClockSum, getInitials, downloadFile } from "../../utils/utils"
import { EJobProgress, EUserRole, IJob, ITimeClock, IUser } from "../../api/types"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import { AddTimeManually } from "../../modals/AddTimeManually"
import { TableSelect, ExportBtn } from "../../ui/TableUtils"
import { TimeInput } from "../../ui/TimeInput"
import { Content } from "../../components/Content"
import { ColumnsType } from "antd/lib/table"
import { usePagination, useLoading } from "../../utils/hooks"
import { getColumns } from "../../config/reducers/columns/selectors"
import Box from "../../noui/Box"
import { ColumnsSelector } from "../../modals/ColumnsSelector/ColumnsSelector"
import { Columns } from "../../constants"
import { useSelector } from "react-redux"
import { TCallback } from "../../types"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { TableCard } from "../../components/TableCard"
import { useHistory } from "react-router-dom"
import routes from "../../routes/routes"
import { track } from "../../utils/analytics"
import { DeleteIcon, EditIcon } from "../../assets/icons"
import { StyledMenu } from "../../containers/vessels/VesselsList"
import { OptionButton } from "../../containers/customers/CustomerList"
const { Panel } = Collapse
const { TabPane } = Tabs

const stats = [
   { label: "Today", value: "day" },
   { label: "This week", value: "week" },
   { label: "This month", value: "month" },
]

const TechnicianContainer = styled(Flex)`
   justify-content: space-between;
   align-items: stretch;
   max-width: 100%;
   overflow-x: auto;
   padding: 4px;
   gap: 26px;
   ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
   }
   ::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 3px;
   }
   ${down("lg")} {
      flex-wrap: wrap;
      gap: 0px;
      > div {
         margin: 13px 0;
      }
   }
   ${down("md")} {
      flex-wrap: wrap;
      gap: 0px;
      > div {
         margin: 13px auto;
      }
   }
`

const TechnicianItem = styled(Flex)`
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   flex-direction: column;
   align-items: center;
   min-width: 300px;
   max-width: 300px;
   justify-content: space-between;
   ${down("lg")} {
      width: 48%;
      min-width: 200px;
   }
   ${down("md")} {
      width: 100%;
   }
   button {
      display: block;
      margin: 15px auto 0 auto;
      height: 34px;
      border-radius: 6px;
   }
   & .ant-form-item {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      & .ant-form-item-control {
         width: 100%;
      }
      & label {
         font-weight: bold;
      }
   }
`

const InitialsWrapper = styled(Flex)`
   align-items: center;
   justify-content: center;
   width: 50px;
   height: 50px;
   border: 1px solid rgba(230, 230, 230, 1);
   border-radius: 50%;
`

const StatsWrapper = styled(Flex)`
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   padding: 10px 15px;
   justify-content: space-between;
   align-items: center;
   margin: 35px 0 0 0;
   ${down("lg")} {
      flex-direction: column;
   }
`

const TechnicianName = styled(Msg)`
   font-size: 18px;
   font-weight: 900;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   max-height: 24px;
   max-width: 100%;
`

const TabsWrapper = styled.div`
   .ant-tabs-nav::before {
      border-bottom: none;
   }
   .ant-tabs-tab {
      font-size: 15px;
   }
   .ant-tabs-tab-active {
      font-weight: 700;
   }
`

const ContentWrapper = styled(Flex)`
   justify-content: center;
   overflow-x: auto;
   width: 100%;
   > div {
      width: 100%;
   }
`
const MobileSelect = styled(TableSelect)`
   margin: 0 16px 0 0;
   width: 100%;
`

const MobileFilterWrapper = styled(Flex)`
   justify-content: start;
   align-items: center;
   width: 100%;
   margin-bottom: 16px;
   position: relative;
   .ant-form-item {
      margin: 0 16px 0 0;
      margin-top: 0;
   }
   ${down("xs")} {
      justify-content: space-between;
      .ant-form-item {
         margin: 0 0 16px;
      }
   }
`

const StyledButton = styled(Button)`
   svg {
      font-size: 16px;
      vertical-align: bottom;
      color: #757575;
   }
   &:hover {
      svg {
         color: #0496ff;
      }
   }
`
const TableWrapper = styled.div`
   & .startDateTime {
      min-width: 115px !important;
      max-width: 115px !important;
   }
`

type TProps = {
   technician: IUser
   jobs: IJob[]
   timeClocks: ITimeClock[]
   loadCards: TCallback
}

export const TechnicianCard: React.FC<TProps> = ({ technician, jobs, timeClocks, loadCards }) => {
   const [form] = Form.useForm()
   const [started, setStarted] = useState<boolean>(false)
   const [timeValue, setTimeValue] = useState<number>(0)
   const [clockedIn, setClockedIn] = useState<string>("")
   const [isHidden, setIsHidden] = useState<boolean>(false)
   const [startTime, setStartTime] = useState<string | undefined>()
   const [startId, setStartId] = useState<number | undefined>()
   const isLg = useBreakpoint(down("lg"))
   const [isLoading, setIsLoading] = useState<boolean>(false)

   useEffect(() => {
      let timer: any
      if (started) {
         timer = setTimeout(async () => {
            if (document.hidden && !isHidden) {
               setIsHidden(true)
            } else if (!document.hidden && isHidden && startTime && startId) {
               const active = await Api.timeClocks.get(startId)
               if (!active.data.stop) {
                  setTimeValue(moment.duration(moment().diff(moment(startTime))).asSeconds())
                  setIsHidden(false)
                  return
               }
               setStarted(false)
               setClockedIn("")
               setTimeValue(0)
               if (active.data.stop) {
                  await loadCards()
               }
               return
            }
            setTimeValue(timeValue + 1)
         }, 1000)
      } else {
         setTimeValue(0)
      }
      return () => {
         clearTimeout(timer)
      }
   }, [started, timeValue])

   useEffect(() => {
      const newActive = timeClocks.find((clock) => clock.stop === null)
      setStartTime(newActive?.start)
      setStartId(newActive?.id)
      if (newActive) {
         setStarted(true)
         setClockedIn(newActive.start)
         setTimeValue(moment.duration(moment().diff(moment(newActive.start))).asSeconds())
         form.setFieldsValue({
            job: `Estimate #${newActive.job.order_number}`,
         })
      } else {
         setStarted(false)
         setClockedIn("")
         setTimeValue(0)
      }
   }, [])

   const handleStart = async () => {
      try {
         setIsLoading(true)
         form.submit()
         const data = await form.getFieldValue("job")

         if (data) {
            const timeClock = await Api.timeClocks.create({
               job: data,
               user: technician.id,
               start: new Date().toISOString(),
            })
            const res = timeClock.data
            track("Clocked In", {
               workorderId: res.job.work_order,
               vesselId: res.job?.vessel.id,
               technician: res.user.id,
               jobId: res.job.id,
               clockInTimestamp: res.start,
            })
            setStarted(true)
            setClockedIn(new Date().toISOString())
            setStartTime(timeClock.data.start)
            setStartId(timeClock.data.id)
            await loadCards()
         } else {
            notification.error({
               message: "You must specify job first",
            })
         }
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      } finally {
         setIsLoading(false)
      }
   }

   const handleStop = async () => {
      try {
         setIsLoading(true)
         const activeTimeClock = timeClocks.find((t) => t.stop === null)
         const { data } = await Api.timeClocks.stop(activeTimeClock?.id ?? timeClocks[0].id)
         track("Clocked Out", {
            workorderId: data.job.work_order,
            vesselId: data.job?.vessel.id,
            technician: data.user.id,
            jobId: data.job.id,
            clockInTimestamp: data.start,
            timeLoggedHours: moment(data.stop).diff(moment(data.start), "hours"),
         })
         setStarted(false)
         setClockedIn("")
         await loadCards()
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      } finally {
         form.resetFields()
         setIsLoading(false)
      }
   }

   const getWorkTime = (format: unitOfTime.StartOf) =>
      timeClocks
         .filter((t) => moment(t.start).toDate() > moment().startOf(format).toDate())
         .reduce((acc, b) => acc + (b.stop ? moment(b.stop).unix() - moment(b.start).unix() : 0), 0)

   const filterJobs = () => {
      let jobsCopy = [...jobs]
      const arr: IJob[] = []
      jobsCopy.forEach((item) => {
         const findTechnician = item.technicians_data.find((person) => person.technician === technician.id)
         if (findTechnician) {
            arr.push(item)
         }
      })

      return arr
   }

   const techJobs = filterJobs()

   return (
      <TechnicianItem px="24px" pt="18px" pb="26px" mb="10px" style={{ order: started ? 0 : 1 }}>
         <InitialsWrapper>
            <Msg fontSize={18} fontWeight={900}>
               {getInitials(technician).replace(/\s/g, "")}
            </Msg>
         </InitialsWrapper>
         <Tooltip title={technician.full_name}>
            <Flex
               flexDirection="column"
               alignItems="center"
               style={{
                  fontSize: "15px",
                  fontWeight: 900,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  height: "50px",
               }}
            >
               <TechnicianName mt="8px">{technician.full_name}</TechnicianName>
            </Flex>
         </Tooltip>
         <Form layout="vertical" form={form} style={{ width: "100%" }} requiredMark={false}>
            <Form.Item
               name="job"
               label="Job list"
               rules={[
                  {
                     required: true,
                     message: "Please select Job",
                  },
               ]}
            >
               <Select
                  placeholder="Select the job"
                  getPopupContainer={(trigger: any) => trigger.parentElement}
                  disabled={started || isLoading}
               >
                  {techJobs.length > 0 &&
                     techJobs.map((j) => (
                        <Select.Option value={j.id} key={j.id}>{`Estimate ${j.order_number}`}</Select.Option>
                     ))}
               </Select>
            </Form.Item>

            <div style={{ minHeight: "125px" }}>
               <TimeInput
                  step="1"
                  active={started}
                  value={started ? formatTime(timeValue, true) : "--:--:--"}
                  //type="time"
                  bordered={false}
                  name="time"
               />
               {started && (
                  <Msg fontSize={15} textAlign="center" width="100%" display="block">{`Clocked in ${moment(
                     clockedIn
                  ).format("hh:mm a")}`}</Msg>
               )}
               <Button
                  type="primary"
                  danger={started}
                  onClick={started ? handleStop : handleStart}
                  style={{ width: isLg ? "100%" : "65%" }}
                  disabled={isLoading}
                  loading={isLoading}
               >
                  Clock {started ? "out" : "in"}
               </Button>
            </div>
            <StatsWrapper>
               {stats.map((s) => (
                  <Flex
                     flexDirection={isLg ? "row" : "column"}
                     alignItems={isLg ? "space-between" : "center"}
                     width={isLg ? "100%" : "auto"}
                     justifyContent={isLg ? "space-between" : "start"}
                  >
                     <Msg fontSize={13} color="rgba(130, 130, 130, 1)">
                        {s.label}
                     </Msg>
                     <Msg fontSize={13}>
                        {formatTimeTimeClockSum(getWorkTime(s.value as unitOfTime.StartOf), false)}
                     </Msg>
                  </Flex>
               ))}
            </StatsWrapper>
         </Form>
      </TechnicianItem>
   )
}

const TimeClockTab: React.FC = () => {
   const { data, loading, onPromise, requestPagination, pagination, onChange } = usePagination<ITimeClock>()
   const [allTimeClocks, setAllTimeClocks] = useState<ITimeClock[]>([])
   const [technicians, setTechnicians] = useState<IUser[]>([])
   const [jobs, setJobs] = useState<IJob[]>([])
   const [addManually, setAddManually] = useState<boolean>(false)
   const [pageLoading, setPageLoading] = useState<boolean>(false)
   const [selectColumns, setSelectColumns] = useState<boolean>(false)
   const [saving, onSave] = useLoading()
   const [form] = Form.useForm()
   const isLg = useBreakpoint(down("lg"))
   const history = useHistory()
   const filtered = useSelector(getColumns(Columns.TimeClockTab))
   const [selectedJob, setSelectedJob] = useState<number | undefined>()
   const [selectedTech, setSelectedTech] = useState<number | undefined>()
   const [selectedTimeClock, setSelectedTimeClock] = useState<ITimeClock | null>(null)
   const [dropdown, setDropdown] = useState<number | null>(null)

   useEffect(() => {
      initFetch().finally()
   }, [])

   useEffect(() => {
      loadCards()
   }, [onPromise])

   useEffect(() => {
      handleFilters()
   }, [requestPagination])

   const columns: ColumnsType<ITimeClock> = useMemo(
      () => [
         {
            title: "Order #",
            key: "job",
            dataIndex: "job",
            sorter: true,
            render: (_, record) => `${record.job.order_number}`,
         },
         {
            title: "Order Name",
            key: "title",
            dataIndex: "title",
            ellipsis: true,
            render: (_, record) => record.job.title,
         },
         {
            title: "Technician",
            key: "user",
            sorter: true,
            dataIndex: "user",
            ellipsis: true,
            render: (_, record) => record.user.full_name,
         },
         {
            title: "First Name",
            key: "first_name",
            dataIndex: "first_name",
            ellipsis: true,
            render: (_, record) => record.job.customer.first_name,
         },
         {
            title: "Last Name",
            key: "last_name",
            dataIndex: "last_name",
            ellipsis: true,
            render: (_, record) => record.job.customer.last_name,
         },
         {
            title: "Vessels",
            key: "vessels",
            dataIndex: "vessel",
            ellipsis: true,
            render: (_, record) => record.job?.vessel.name,
         },
         {
            title: "Start Date + Time",
            key: "start",
            sorter: true,
            dataIndex: "start",
            className: "startDateTime",
            render: (_, record) => moment(record.start).format("L, hh:mm a"),
         },
         {
            title: "End Date + Time",
            key: "stop",
            sorter: true,
            dataIndex: "stop",

            render: (_, record) => record.stop && moment(record.stop).format("L, hh:mm a"),
         },
         {
            title: "Actions",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            render: (_, record) => (
               <Flex justifyContent="center" position="relative">
                  <Dropdown
                     trigger={["hover"]}
                     // getPopupContainer={(trigger: any) => trigger.parentElement}
                     overlay={() => (
                        <StyledMenu>
                           <Flex flexDirection="column" alignItems="flex-start">
                              <OptionButton onClick={() => handleEdit(record)} marginBottom="16px">
                                 <Flex alignItems="center">
                                    <EditIcon />
                                    <Msg marginLeft="9px">Edit</Msg>
                                 </Flex>
                              </OptionButton>
                              <Popconfirm
                                 title={`Are you sure want to delete this record?`}
                                 onConfirm={() => handleDeleteTimeClock(record.id)}
                                 trigger={["click"]}
                              >
                                 <OptionButton>
                                    <Flex alignItems="center">
                                       <DeleteIcon />
                                       <Msg marginLeft="9px">Delete</Msg>
                                    </Flex>
                                 </OptionButton>
                              </Popconfirm>
                           </Flex>
                        </StyledMenu>
                     )}
                     placement="bottomRight"
                     arrow
                  >
                     <EmptyButton onClick={(e) => e.preventDefault}>
                        <EllipsisOutlined style={{ fontSize: "18px", marginTop: "5px" }} />
                     </EmptyButton>
                  </Dropdown>
               </Flex>
            ),
         },
      ],
      [isLg]
   )

   const updateTable = () => {
      onPromise(Api.timeClocks.getAll({ ...requestPagination }))
   }

   const loadTimeRecords = async () => {
      const timeClocks = await Api.timeClocks.getAll({})
      setAllTimeClocks(timeClocks.data as ITimeClock[])
   }

   const loadCards = async () => {
      updateTable()
      const technicians = await Api.user.getAll({})
      setTechnicians(technicians.data as IUser[])
      const jobs = await Api.jobs.getAll({})
      const newJobs = jobs.data as IJob[]
      console.log("newJobs", newJobs)
      setJobs(newJobs.filter((j) => j.progress !== EJobProgress.Complete))
      loadTimeRecords()
   }

   const initFetch = async () => {
      setPageLoading(true)
      try {
         await loadCards()
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      } finally {
         setPageLoading(false)
      }
   }

   const handleFilters = async () => {
      const { technician, job } = await form.validateFields()
      if (selectedTech !== technician || selectedJob !== job) {
         setSelectedJob(job)
         setSelectedTech(technician)
         await onChange({ ...pagination, current: 1 }, {}, {})
         return
      }
      const params = {
         ...(technician && { user: technician }),
         ...(job && { job }),
         ...requestPagination,
      }
      onPromise(Api.timeClocks.getAll(params))
      setSelectedJob(job)
      setSelectedTech(technician)
   }

   const handleExport = async () => {
      const { data } = await onSave(Api.timeClocks.download())
      downloadFile(data, `TimeClocksList ${moment().format("LLL")}.xlsx`)
   }

   const handleDeleteTimeClock = async (id: number) => {
      await onSave(Api.timeClocks.delete(id))
      loadCards()
   }

   const handleEdit = (timeClock: ITimeClock) => {
      setSelectedTimeClock(timeClock)
      setAddManually(true)
   }

   const handleAddTimeManually = () => {
      setSelectedTimeClock(null)
      setAddManually(true)
   }

   const handleCloseTimeManually = () => {
      setSelectedTimeClock(null)
      setAddManually(false)
   }

   return (
      <>
         <Helmet>
            <title>Time Clocks - DockWorks Pro</title>
         </Helmet>
         <div>
            {isLg ? (
               <TabsWrapper>
                  <Tabs defaultActiveKey="1" centered>
                     <TabPane tab="Time Clocks" key="1">
                        <Flex justifyContent="flex-end" mb="12px">
                           <PrimaryButton
                              width="100%"
                              height="34px"
                              mr="4px"
                              onClick={handleAddTimeManually}
                              style={{ borderRadius: "6px" }}
                           >
                              Add Time Manually <PlusOutlined />
                           </PrimaryButton>
                           <AddTimeManually
                              loadCards={loadCards}
                              displayJobs={true}
                              jobs={jobs}
                              visible={addManually}
                              handleClose={handleCloseTimeManually}
                              technicians={technicians}
                              selectedTimeClock={selectedTimeClock}
                           />
                        </Flex>
                        <ContentWrapper>
                           <Content loading={pageLoading} noItems={!technicians.length || !jobs.length} noItemsLabel="">
                              <TechnicianContainer>
                                 {technicians.map((t) => (
                                    <TechnicianCard
                                       technician={t}
                                       jobs={jobs}
                                       timeClocks={allTimeClocks.filter((d) => d.user.id === t.id)}
                                       loadCards={loadCards}
                                    />
                                 ))}
                              </TechnicianContainer>
                           </Content>
                        </ContentWrapper>
                     </TabPane>
                     <TabPane tab="Time Clocks Results" key="2">
                        <Spin spinning={loading}>
                           <Form form={form}>
                              <MobileFilterWrapper>
                                 <Form.Item
                                    name="technician"
                                    style={{ marginTop: "16px", width: "calc(50% - 33px)", maxWidth: "150px" }}
                                 >
                                    <MobileSelect
                                       placeholder={"All technicians"}
                                       onSelect={handleFilters}
                                       getPopupContainer={(trigger: any) => trigger.parentElement}
                                    >
                                       <TableSelect.Option value={0}>All technicians</TableSelect.Option>
                                       {technicians.map((t) => (
                                          <TableSelect.Option key={t.id} value={t.id}>
                                             {t.full_name}
                                          </TableSelect.Option>
                                       ))}
                                    </MobileSelect>
                                 </Form.Item>
                                 <Form.Item
                                    name="job"
                                    style={{ marginTop: "16px", width: "calc(50% - 33px)", maxWidth: "150px" }}
                                 >
                                    <MobileSelect
                                       placeholder={"All jobs"}
                                       onSelect={handleFilters}
                                       getPopupContainer={(trigger: any) => trigger.parentElement}
                                    >
                                       <TableSelect.Option value={0}>All jobs</TableSelect.Option>
                                       {jobs.map((j) => (
                                          <TableSelect.Option
                                             key={j.id}
                                             value={j.id}
                                          >{`${j.order_number}`}</TableSelect.Option>
                                       ))}
                                    </MobileSelect>
                                 </Form.Item>
                                 <Button
                                    style={{ height: "34px", padding: "0 10px", marginLeft: "16px" }}
                                    onClick={() => setSelectColumns(true)}
                                 >
                                    <SettingFilled />
                                 </Button>
                              </MobileFilterWrapper>
                           </Form>
                           <ColumnsSelector
                              name={Columns.TimeClockTab}
                              columns={columns}
                              visible={selectColumns}
                              onClose={() => setSelectColumns(false)}
                           />
                           {/* </Flex> */}
                           <Flex flexWrap="wrap">
                              {data && data.length > 0 ? (
                                 data.map((d) => (
                                    <TableCard key={d.id}>
                                       <Flex flexDirection="row" alignItems="center">
                                          <Flex flexDirection="column" flex="1">
                                             {filterColumns(columns, filtered).map((c, i) => (
                                                <Flex key={i} justifyContent="space-between">
                                                   <Msg fontWeight={700}>{c.title}</Msg>
                                                   <Msg style={{ textAlign: "end" }}>
                                                      {c.render && c.render(d, d, i)}
                                                   </Msg>
                                                </Flex>
                                             ))}
                                          </Flex>
                                          <Button
                                             style={{ border: "none" }}
                                             onClick={() => {
                                                history.push(
                                                   routes.workOrder.UpdateWorkOrder.replace(
                                                      ":id",
                                                      String(d.job.work_order)
                                                   ) + `?job=${d.job.id}&tab=4`
                                                )
                                             }}
                                          >
                                             <RightOutlined />
                                          </Button>
                                       </Flex>
                                    </TableCard>
                                 ))
                              ) : (
                                 <p>No timesheets results were found</p>
                              )}
                              {!!pagination?.total && pagination.total > 10 && (
                                 <Pagination
                                    current={pagination.current}
                                    pageSize={pagination.pageSize}
                                    total={pagination.total}
                                    onChange={(p) => {
                                       onChange({ ...pagination, current: p }, {}, {})
                                    }}
                                    style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
                                    size="small"
                                 />
                              )}
                           </Flex>
                        </Spin>
                     </TabPane>
                  </Tabs>
               </TabsWrapper>
            ) : (
               <>
                  <Flex justifyContent="flex-end" mb="12px">
                     <PrimaryButton
                        width="auto"
                        height="34px"
                        mr="4px"
                        onClick={handleAddTimeManually}
                        style={{ borderRadius: "6px" }}
                     >
                        Add Time Manually <PlusOutlined />
                     </PrimaryButton>
                     <AddTimeManually
                        loadCards={loadCards}
                        displayJobs={true}
                        jobs={jobs}
                        visible={addManually}
                        handleClose={handleCloseTimeManually}
                        technicians={technicians}
                        selectedTimeClock={selectedTimeClock}
                     />
                  </Flex>
                  <Flex justifyContent="center">
                     <ContentWrapper>
                        <Content loading={pageLoading} noItems={!technicians.length || !jobs.length} noItemsLabel="">
                           <TechnicianContainer>
                              {technicians.map((t) => (
                                 <TechnicianCard
                                    technician={t}
                                    jobs={jobs}
                                    timeClocks={allTimeClocks.filter((d) => d.user.id === t.id)}
                                    loadCards={loadCards}
                                 />
                              ))}
                           </TechnicianContainer>
                        </Content>
                     </ContentWrapper>
                  </Flex>
                  <Box px="4px" mt="20px">
                     <Flex justifyContent="space-between" alignItems="center">
                        <Flex alignItems="center" style={{ gap: "16px" }}>
                           <Form form={form}>
                              <Flex style={{ gap: "16px", position: "relative" }}>
                                 <Form.Item name="technician" style={{ marginTop: "16px", position: "relative" }}>
                                    <TableSelect
                                       placeholder={"All technicians"}
                                       onSelect={handleFilters}
                                       getPopupContainer={(trigger: any) => trigger.parentElement}
                                    >
                                       <TableSelect.Option value={0}>All</TableSelect.Option>
                                       {technicians.map((t) => (
                                          <TableSelect.Option key={t.id} value={t.id}>
                                             {t.full_name}
                                          </TableSelect.Option>
                                       ))}
                                    </TableSelect>
                                 </Form.Item>
                                 <Form.Item name="job" style={{ marginTop: "16px", position: "relative" }}>
                                    <TableSelect
                                       placeholder={"All jobs"}
                                       onSelect={handleFilters}
                                       getPopupContainer={(trigger: any) => trigger.parentElement}
                                    >
                                       <TableSelect.Option value={0}>All</TableSelect.Option>
                                       {
                                          jobs.map((j) => (
                                             <TableSelect.Option
                                                key={j.id}
                                                value={j.id}
                                             >{`${j.order_number}`}</TableSelect.Option>
                                          ))
                                          // data.map(item => <TableSelect.Option key={item.id} value={item.job.id}>{`${item.job.title}`}</TableSelect.Option>)
                                       }
                                    </TableSelect>
                                 </Form.Item>
                              </Flex>
                           </Form>

                           <StyledButton
                              style={{ height: "34px", marginLeft: "16px" }}
                              onClick={() => setSelectColumns(true)}
                           >
                              Columns
                              <SettingFilled style={{ fontSize: "16px", verticalAlign: "middle", color: "#757575" }} />
                           </StyledButton>
                        </Flex>
                        <Flex>
                           <ExportBtn loading={saving} onClick={handleExport} mr="20px">
                              Exports
                           </ExportBtn>
                           {/* <TableSelect value="Columns" /> */}
                        </Flex>
                     </Flex>
                     <ColumnsSelector
                        name={Columns.TimeClockTab}
                        columns={columns}
                        visible={selectColumns}
                        onClose={() => setSelectColumns(false)}
                     />
                     <TableWrapper>
                        <Table
                           columns={filterColumns(columns, filtered)}
                           className="contained timeclocks-table"
                           dataSource={data}
                           loading={loading}
                           pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
                           onChange={onChange}
                           locale={{ emptyText: "No timesheets results were found" }}
                        />
                     </TableWrapper>
                  </Box>
               </>
            )}
         </div>
      </>
   )
}

export default TimeClockTab
