import React, { useState, useEffect, useCallback } from 'react';
import { Spin } from "antd";
import Flex from "../../noui/Flex";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { SearchWithBorder } from "../../noui/Search";
import Box from '../../noui/Box';
import styled from "styled-components";
import { Api } from "../../api/api";
import { notification } from 'antd';
import { IHelpArticles } from '../../api/types';
import { LeftOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import routes from "../../routes/routes";
import { Msg } from '../../ui/Text';
import { Section } from './Section';


const SearchContainer = styled(Box)`
    padding: 20px 16px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
`;

const LeftArrow = styled(LeftOutlined)`
  font-size: 20px;
  margin-right: 6px;
`
const BackButton = styled(NavLink)`
  color: #0099FF;
  font-size: 18px;
  font-weight: 800;
`

export const Articles: React.FC = () => {

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [article, setArticle] = useState<IHelpArticles[]>([]);
    const [sectionNumber, setSectionNumber] = useState<Array<number>>([]);
    const [unique, setUnique] = useState<Array<number>>([]);
    const sipmArr = [3, 5, 6]

    const onlyUnique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    }

    const loadCards = useCallback(async () => {


        setLoading(true);
        try {
            const { data } = await Api.help.get({ category: 3 })
            setArticle(data as IHelpArticles[])
            setSectionNumber([...article.map(el => el.section)])
            setUnique([...sectionNumber.filter(onlyUnique)])


        } catch (e) {
            notification.error({
                message: 'Something went wrong'
            });
        } finally {
            setLoading(false);
        }
    }, [sectionNumber, unique, article])

    useEffect(() => {
        loadCards().finally();
    }, []);





    return <Flex p='34px' pt='15px' flexDirection='column'>
        <Title>Help</Title>
        <Container>
            <SearchContainer>
                <SearchWithBorder
                    placeholder="Search here"
                    onChange={({ target: { value } }) => setSearch(value)}
                />
            </SearchContainer>
            {!loading ? <Flex p='15px 24px' flexDirection='column'>
                <BackButton to={routes.help.Base}>
                    <Flex alignItems="center">
                        <LeftArrow />
                        Back
                    </Flex>

                </BackButton>
                <Flex flexDirection='column'>
                    {/* <Flex>
                        <img src={categoriesImages[id].image}></img>
                    </Flex> */}
                    <Msg fontWeight='800' fontSize='28px'>{article[0]?.category_name}</Msg>
                </Flex>
                {sipmArr.map(el => <Section sectionNumber={el} />)}
            </Flex>
                : <Flex width='100%' p='40px' justifyContent='center'>
                    <Spin />
                </Flex>}
        </Container>
    </Flex>
};