import { Button, Modal } from "antd"
import styled from "styled-components"
import Flex from "../../noui/Flex";
import { TCallback } from "../../types"
import Hr from "../../ui/Hr";
import { Msg } from "../../ui/Text";

const StyledModal = styled(Modal)`
    .ant-modal-title {
        color: #FB4D4F;
    }
`;

type TRPMNewInvoiceModalProps = {
    visible: boolean
    onCancel: TCallback
    onOk: TCallback
    onClose: TCallback
}

export const RPMNewInvoiceModal: React.FC<TRPMNewInvoiceModalProps> = ({visible, onCancel, onOk, onClose}) => {
    return (
        <StyledModal 
            footer={null}
            visible={visible}
            okText="Send new invoice"
            cancelText="Resend last"
            title="Warning"
            onCancel={onClose}
            onOk={onOk}
            width={384}
        >
            <Msg>
                Your last invoice isn't paid yet. Do you want to send new invoice and close last one?
            </Msg>
            <Hr margin="16px 0" />
            <Flex width="100%" justifyContent="end">
                <Button onClick={onCancel}>Resend last</Button>
                <Button style={{margin: "0 0 16px 16px"}} type="primary" onClick={onOk}>Send new invoice</Button>
            </Flex>
        </StyledModal>
    );
}
