import { Input } from "antd"
import styled from "styled-components"

const { TextArea } = Input

export const CustomInput = styled(Input)`
   border-radius: 4px;

   ::placeholder {
      color: #828282 !important;
   }
   & .ant-input-prefix {
      color: #828282;
      display: block;
      text-align: right;
   }
`

export const CustomTextarea = styled(TextArea)`
   border-radius: 4px;
   resize: none;
   &::placeholder {
      color: #828282;
   }
`
