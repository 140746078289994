import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import styled from "styled-components"
import { PrimaryTooltip } from "../../../ui/Tooltip"
import moment from "moment"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { formatAddress } from "../../../utils/utils"

const ToolTip = styled(PrimaryTooltip)`
   white-space: pre-wrap;
`

const CartWrapper = styled(Flex)`
   &.selected {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.35);
      border-radius: 6px;
   }
`

export const CustomerCart = ({ data, activated }) => {
   const isXs = useBreakpoint(down("xs"))

   const withCoordinates = (data) => data.address?.lng && data.address?.lat

   return (
      <Flex flexDirection="column" p="3px">
         <CartWrapper
            p="12px 16px"
            justifyContent="space-between"
            className={activated && activated === data?.id ? "selected" : "little-cart"}
            mb="12px"
            style={{ minHeight: "85px" }}
         >
            <Flex flexDirection="column" textAlign="left">
               <PrimaryTooltip title={data.job?.vessel.name} width={isXs ? "auto" : "100px"}>
                  <Msg fontSize="13px" fontWeight="700" color="#484848" width="20px" className="short-text">
                     {data.job?.vessel.name}
                  </Msg>
               </PrimaryTooltip>
               <PrimaryTooltip
                  title={moment(data.start).format("ddd, MMM D, YYYY h:mm a")}
                  width={isXs ? "120px" : "200px"}
               >
                  <Msg fontSize="13px" fontWeight="700" color="#484848" width="20px" className="short-text">
                     {moment(data.start).format("ddd, MMM D, YYYY h:mm a")}
                  </Msg>
               </PrimaryTooltip>
               <PrimaryTooltip
                  title={`${data.job.customer.first_name} ${data.job.customer.last_name}`}
                  width={isXs ? "auto" : "200px"}
               >
                  <Msg fontSize="13px" fontWeight="300" color="#484848" className="short-text">
                     {data.job.customer.first_name} {data.job.customer.last_name}
                  </Msg>
               </PrimaryTooltip>
            </Flex>
            <ToolTip
               title={withCoordinates(data) ? formatAddress(data.address) : "No data"}
               width="200px"
               height="100%"
            >
               <Msg
                  fontSize="15px"
                  fontWeight="300"
                  color={activated && activated === data?.id ? "#0099FF" : "#484848"}
                  textAlign="right"
                  width="50%"
               >
                  {withCoordinates(data) ? formatAddress(data.address) : "No data"}
               </Msg>
            </ToolTip>
         </CartWrapper>
      </Flex>
   )
}
