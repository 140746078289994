import React, { useEffect, useState } from "react";
import { Api } from "../../api/api";
import { IVessel } from "../../api/types";
import Flex from "../../noui/Flex";
import { useLoading } from "../../utils/hooks";
import styled from "styled-components";
import { SelectValue } from "antd/lib/select";
import { Select, Spin } from "antd/es";
import { useAppSelector } from "../../config/hooks";
import { getConstants } from "../../config/reducers/user/selectors";
import { Msg } from "../../ui/Text";
import { GoogleMapsAPIKey } from "../../api";
import GoogleMapReact, { ChangeEventValue } from "google-map-react";
import { MapMarker } from "../../modals/MapMarker";
import { Button, Input, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounce } from "use-debounce/lib";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import VesselMapPopover from "./VesselMapPopover";
import VesselMapData from "./VesselMapData";
const { Option } = Select;

const Wrapper = styled(Flex)`
    flex-direction: column;
    padding: 0 32px 32px;
`

const MapRow = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
    >div {
        width: calc(65.5% - 16px);
    }
    >div~div {
        width: calc(35.5% - 16px);
    }
    ${down('lg')} {
        flex-direction: column;
        >div, >div~div {
            width: 100%;
        }
        >div~div {
            margin-top: 8px;
        }
    }
`

const Header = styled(Msg)`
    font-weight: 900;
    font-size: 15px;
    text-transform: uppercase;
    color: #202020;
`

const FilterWrapper = styled(Flex)`
    .ant-select {
        min-width: 150px;
    }
    ${down('lg')} {
        .ant-select {
            width: 100%;
        }
    }
`

const MapWrapper = styled(Flex)`
    height: 800px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    ${down('lg')} {
        height: 350px;
    }
`

const VesselWrapper = styled(Flex)`
    max-height: 850px;
    overflow: auto;
    flex-direction: column;
    margin-top: 16px;
    padding: 4px;
    >div {
        margin-top: 13px;
    }
    >div:first-child {
        margin-top: 0;
    }
    &::-webkit-scrollbar {
       width: 8px;   
    }
    &::-webkit-scrollbar-track {
       background: #fbfbff;
       border-radius: 8px;
       border: 1px solid #ededed;      
    }
    &::-webkit-scrollbar-thumb {
       background: #0496ff;
       border-radius: 8px;   
    }
    &::-webkit-scrollbar-thumb:hover {
       background: #40a9ff;
    }
    ${down('lg')} {
        max-height: 350px;
        margin-top: 0;
    }
`

const ShowVesselButton = styled(Button)`
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #202020;
    margin: 8px 0;
`;




const VesselMap = () => {
    const [vessels, setVessels] = useState<IVessel[]>([])
    const [loading, loadVessels] = useLoading()
    const [vesselTypes, setVesselTypes] = useState<SelectValue>()
    const [search, setSearch] = useState("")
    const [mapCenter, setMapCenter] = useState({
        lat: 41,
        lng: -73,
    })
    const [mapsOptions] = useState({
        zoom: 12,
        mapTypeControl: true,
        zoomControl: true,
        mapTypeId: "satellite",
    })
    const selectOptions = useAppSelector(getConstants)?.vessel_types
    const [debouncedSearch] = useDebounce(search, 500);
    const isLg = useBreakpoint(down('lg'))
    const [activeVessel, setActiveVessel] = useState<number | null>(null)
    const [map, setMap] = useState<google.maps.Map|null>(null)
    const [maps, setMaps] = useState<any|null>(null)

    const handleApiLoaded = (map: any, maps: any) => {
        setMap(map)
        setMaps(maps)
    }

    const createOptions = () => {
        if (selectOptions) {
           const options = Object.entries(selectOptions)
           return options.map((option) => <Option value={option[0]}>{option[1]}</Option>)
        }
        return null
     }

    const loadData = async () => {
        const { data } = await loadVessels(Api.vessels.getAll(
            {
                vessel_type: vesselTypes,
                search: debouncedSearch
            }
        ))
        setVessels(data)
    }

    const validateVesselAddress = (v?: IVessel) => {
        return !!(v?.address && v.address.lat != null && v.address.lng != null)
    }

    const formatMarkers = () => {
        return vessels
            .filter(v => validateVesselAddress(v))
            .map(v => (
                <MapMarker width="25px" key={v.id} lat={v?.address?.lat} lng={v?.address?.lng} >
                    <div style={{ position: "relative" }}>
                        <Popover
                           content={<VesselMapPopover vessel={v} />}
                           visible={v.id === activeVessel}
                           getPopupContainer={(trigger: any) => trigger.parentElement}
                           arrowPointAtCenter
                        >
                           <div></div>
                        </Popover>
                    </div>
                </MapMarker>
            ))            
    }

    const handleSearch = (e?: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e?.target.value ?? "")
    }

    const handleMakeActive = (id: number) => {
        if (activeVessel === id) {
            setActiveVessel(null)
            return
        }
        setActiveVessel(id)
    }

    const focusOnVessel = (vessel?: IVessel) => {
        if (validateVesselAddress(vessel)) {
            setMapCenter({ lat: vessel?.address?.lat ?? 41, lng: vessel?.address?.lng ?? -73 })
        }
    }

    const handleChildClick = (id: number) => {
        handleMakeActive(+id)
    }

    const removeFocus = () => {
        if (map && maps) {
            const bounds = new maps.LatLngBounds() as google.maps.LatLngBounds;
            const coords = vessels
                .filter(v => validateVesselAddress(v))
                .map(v => new maps.LatLng(v.address?.lat ?? 0, v.address?.lng ?? 0) as google.maps.LatLng)
            coords.forEach(c => bounds.extend(c)) 
            map.fitBounds(bounds)
        }
    }

    const handleShowAllVessels = () => {
        setActiveVessel(null)
        setVesselTypes(undefined)
        setSearch("")
        removeFocus()
    }

    const compareCoords = (center: { lat: number, lng: number }) => {
        return (center.lat === mapCenter.lat && center.lng === mapCenter.lng)
    }

    const handleMapSettingsChange = (value: ChangeEventValue) => {
        if (!compareCoords(value.center)) {
            setMapCenter(value.center)
        }
    }

    useEffect(() => {
        loadData();
    }, [vesselTypes, debouncedSearch])

    useEffect(() => {
        if (activeVessel) {
            const vessel = vessels.find(v => v.id === activeVessel)
            focusOnVessel(vessel)
            return;
        }
        setActiveVessel(null)
    }, [activeVessel])

    useEffect(() => {
        setActiveVessel(null)
        removeFocus()
    }, [vessels])

    return (
        <Spin spinning={loading}>
        <Wrapper>
            <MapRow alignItems="center">
                <FilterWrapper>
                    <Select mode="multiple" placeholder="Select Vessel Type" value={vesselTypes} onChange={setVesselTypes}>
                        {createOptions()}
                    </Select>
                </FilterWrapper>
                <div>
                    {isLg ?
                    (
                        <>
                        <Input 
                            prefix={<SearchOutlined/>} 
                            value={search} 
                            onChange={handleSearch} 
                            placeholder="Search vessel" 
                        />
                        <ShowVesselButton onClick={handleShowAllVessels}>
                            Show all vessels
                        </ShowVesselButton>
                        </>
                    ):
                    (
                        <Header>
                            Vessel List
                        </Header>
                    )}
                </div>
            </MapRow>
            <MapRow marginTop="16px">
                <MapWrapper>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: GoogleMapsAPIKey}}
                        center={mapCenter}
                        zoom={mapsOptions.zoom}
                        options={mapsOptions}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        onChildClick={handleChildClick}
                        onChange={handleMapSettingsChange}
                    >
                        {formatMarkers()}
                    </GoogleMapReact>
                </MapWrapper>
                <Flex flexDirection="column">
                    {!isLg && (
                        <>
                        <Input 
                            prefix={<SearchOutlined/>} 
                            value={search} 
                            onChange={handleSearch} 
                            placeholder="Search vessel" 
                        />
                        <ShowVesselButton onClick={handleShowAllVessels}>
                            Show all vessels
                        </ShowVesselButton>
                        </>
                    )}
                    <VesselWrapper>
                        {vessels.map(v => (
                            <VesselMapData 
                                makeActive={handleMakeActive} 
                                isActive={v.id === activeVessel} 
                                vessel={v} 
                                key={v.id}
                            />
                        ))}
                    </VesselWrapper>
                </Flex>
            </MapRow>
        </Wrapper>
        </Spin>        
    );
}

export default VesselMap;