import { render } from "@testing-library/react";
import { Button, Form, Input, Modal, notification, Select, Steps, Table, TableColumnsType } from "antd";
import { useEffect, useMemo, useState } from "react";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { Api } from "../../api/api";
import { IConstant, ITelgorithmBrandResponse, ITelgorithmBrandStatus, ITelgorithmPhone } from "../../api/types";
import { TMAppointment, TMPlus, TMSearch } from "../../assets/icons";
import { useAppSelector } from "../../config/hooks";
import { getConstants, getCurrentUser } from "../../config/reducers/user/selectors";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import { TMappedError } from "../../utils/types";
import { getErrors, showNonFieldsErrors } from "../../utils/utils";
import { StyledPhoneNumberSmall } from "../customers/pages/styled";
import { FormItem, FormRow } from "./FormItem";

const OnBoardingContainer = styled(Flex)`
    margin: 48px 80px 36px 48px;
    min-height: 600px;
    ${down('lg')} {
        flex-direction: column;
    }
    ${down('xs')} {
        margin: 16px 12px;
    }
`

const StepsContainer = styled.div`
    width: 250px;
    margin-right: 50px;
    .ant-steps-item {
        padding-bottom: 36px;
    }
    .ant-steps-item-tail::after {
        width: 4px !important;
        background-color: #ACE0FF !important;
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
        left: 14px;
    }
    .ant-steps-item-icon {
        display: flex;
        justify-content: center;
        align-content: center;
        .ant-steps-icon {
            color: #FFFFFF !important;
            font-size: 20px !important;
            font-weight: 900;
            vertical-align: middle;
            height: 100%;
            line-height: 30px;
            span {
                vertical-align: middle;
                height: 100%;
                svg {
                    height: 100%;
                }
            }
        }
    }    
    .ant-steps-item-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;   
    }
    .ant-steps-item-finish {
        .ant-steps-item-title {
            color: #42C77B !important;
        }
        .ant-steps-item-icon {
            background-color: #42C77B !important;
        }
    }
    .ant-steps-item-active {
        .ant-steps-item-title {
            color: #0496FF !important;
        }
        .ant-steps-item-icon {
            background-color: #0496FF !important;
        }
    }
    .ant-steps-item-wait {
        .ant-steps-item-title {
            color: #ACE0FF !important;
        }
        .ant-steps-item-icon {
            background-color: #ACE0FF !important;
        }
    }
    ${down('lg')} {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        .ant-steps {
            gap: 0;
            grid-gap: 0;
        }
    }
`

const ContentContainer = styled(Flex)`
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 16px;
    width: 100%;
`

const ContentFormTitle = styled(Msg)`
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
    color: #202020;
`

const PhoneBlock = styled(Flex)`
    flex-direction: column;
    >span {
        color: #494949;
        font-size: 13px;
        font-weight: 700;
    }
    width: calc(33% - 11px);
    margin-left: 16px;
    &:first-child {
        margin-left: 0;
    }
`

const SearchButton = styled(Button)`
    width: 186px;
    margin: 24px 0 32px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 36px;
    &:disabled {
        background: #ACE0FF;
        border-color: #ACE0FF;
        color: #FFFFFF;
    }
`

const PlusButton = styled(Button)<{isSelected?: boolean}>`
    width: 88px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${props => props.isSelected ? "15px" : "6px"};
    &:disabled {
        background: #ACE0FF;
        border-color: #ACE0FF;
        color: #FFFFFF;
    }
`

const Service = styled(Msg)`
    font-size: 13px;
    font-weight: 700;
    color: #0496FF;
    background: #ACE0FF;
    border-radius: 6px;
`

const steps = [
    { title: "brand details" },
    { title: "contact details" },
    { title: "SELECT NUMBER" }
]

type TProps = {
    finishOnBoarding: () => void;
    isThirdStep?: boolean;
    brand?: ITelgorithmBrandResponse | null;
    brandStatus?: ITelgorithmBrandStatus | null;
}

const TextMessagingOnBoarding: React.FC<TProps> = ({finishOnBoarding, isThirdStep, brand, brandStatus}) => {
    const [step, setStep] = useState(0)
    const [formType, setFormType] = useState("PRIVATE_PROFIT")
    const [businessType, setBusinessType] = useState("NONE")
    const [brandForm] = Form.useForm()
    const userData = useAppSelector(getCurrentUser)
    const [phone, setPhone] = useState('');
    const [sendData, setSendData] = useState<any>({});
    const c = useAppSelector(getConstants)
    const [visible, setVisible] = useState(false)
    const [searchBy, setSearchBy] = useState("RG")
    const [areaCode, setAreaCode] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [phones, setPhones] = useState<ITelgorithmPhone[]>([])
    const [phonesLoading, setPhonesLoading] = useState(false)
    const [selectedPhone, setSelectedPhone] = useState<string | null>(null)
    const [selectPhoneVisible, setSelectPhoneVisible] = useState(false)
    const [onboardingFinished, setOnboardingFinished] = useState(false)
    const [finishVisible, setFinishVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const validateBrand = (brand: any) => {
        if (brand && brand?.length) {
            const brandErrors = brand.filter((i: any) => i?.code === 501)
            const firstStepError = brandErrors.some((e: any) => e.field !== 'email' && e.field !== 'phone')
            if (firstStepError) {
                setStep(0)
            }
            brandForm.setFields(brandErrors.map((e: any) => ({name: e.field, errors: [e.description]})))
            return;
        }
        finishOnBoarding()
    }
    
    const createBrand = async () => {
        try {
            setIsLoading(true)
            const isProd = process.env.REACT_APP_ENV === "prod"
            const action = brand ? Api.telgorithm.updateBrand : Api.telgorithm.createBrand;
            const brandData = await brandForm.getFieldsValue()
            const _sendData = {...sendData, displayName: sendData.displayName || sendData.companyName}
            const { data } = await action({..._sendData, ...brandData, mock: false})
            validateBrand(data)
        } catch (e) {
            const errors = getErrors(e)
            if (errors.some(e => e.name !== 'email' && e.name !== 'phone')) {
                setStep(0)
            }
            brandForm.setFields(errors)
        } finally {
            setIsLoading(false)
        }
    }

    const handleNextStep = async () => {
        if (step === 0) {
            if (await brandForm.validateFields()) {
                setSendData({...sendData, ...brandForm.getFieldsValue()})
                setStep(prev => prev + 1)
            }
            return
        }
        if (step === 1) {
            //TODO: Confirmation modal
            if (await brandForm.validateFields()) {
                setVisible(true)
            }
        }
        if (step === 2) {
            //TODO Brend Created Modal
            setFinishVisible(true)
        }
    }

    const handlePrevious = () => {
        if (!isThirdStep) {
            setStep(prev => prev - 1)
        }
    }
    
    const getBrandStatus = () => {
        if (formType === 'PUBLIC_PROFIT') {
            return 'first'
        } else if (formType === 'SOLE_PROPRIETOR') {
            return 'third'
        }
        return 'second'
    }

    const removeEmptyFields = (data: any) => (
        Object.keys(data).reduce((acc, key) => {
            if (data[key]) return {...acc, [key]: data[key]}
            return acc;
        }, {}))  

    const loadData = () => {
        if (!userData) return null;
        const { company } = userData;
        if (!company) return null;
        const { 
            website, 
            name: company_name,  
            address,
            phone,
            email,
            first_name,
            last_name,
        } = company;
        let data: any = { company_name, website }
        if (address) {
            data = {
                ...data, 
                companyName: company_name,
                displayName: company_name,
                street: address.address1, 
                city: address.city, 
                state: address.state, 
                postalCode: address.zip_code, 
                firstName: first_name,
                lastName: last_name,
                phone, 
                email,
            }
        }
        brandForm.setFieldsValue(removeEmptyFields(data))
        setPhone(phone)
    }

    const getOptions = (constant?: IConstant | null) => {
        if (!constant) return null;
        return Object.entries(constant).map(opt => (
            <Select.Option value={opt[0]}>{opt[1]}</Select.Option>
        ))
    }

    const handleOkModal = () => {
        setVisible(false)
        createBrand()
    }

    const handleCancelModal = () => {
        setVisible(false)
    }

    const initThirdStep = () => {
        setStep(2)
    }

    const isSearchButtonDisabled = () => {
        if (searchBy === "RG") {
            return (!(state && city) && !phonesLoading) || onboardingFinished
        }
        return (!areaCode && !phonesLoading && !!selectedPhone) || onboardingFinished
    }

    const formatSearchParams = () => searchBy === "RG" ? { state, city } : { area_code: areaCode }

    const searchPhones = async () => {
        try {
            setPhonesLoading(true)
            const { data } = await Api.telgorithm.searchPhone(formatSearchParams())
            if (typeof data === 'string') {
                notification.error({message: data})
                return;
            }
            if (!data?.phones?.length) {
                notification.error({message: 'No phone numbers found'})
            }
            setPhones(data.phones)
        } finally {
            setPhonesLoading(false)
        }
    }

    const setBrandData = () => {
        if (brand) {
            brandForm.setFieldsValue(brand)
        }
        if (brandStatus) {
            const errors = brandStatus.category.reduce((acc: TMappedError[], c) => {
                acc.push(...c.fields.map(f => ({name: f, errors: [c.description]})))
                return acc;
            }, [])
            brandForm.setFields(errors)
        }
    }

    useEffect(() => {
        if (isThirdStep) {
            initThirdStep()
            return;
        }
        if (brand && brandStatus) {
            setBrandData()
            return;
        }
        loadData()
    }, [userData, isThirdStep, brand, brandStatus])

    const renderBrandForm = () => (
        <Flex width="100%" flexDirection="column">
            <Msg>We need to confirm some basic company information to help you get set up</Msg>
            <Msg>with a dedicated business number to begin sending text messages.</Msg>
                <Form form={brandForm} layout="vertical">
                <FormRow>
                    <FormItem label="Legal Company Name*" tooltip="Enter company name">
                        <Form.Item 
                            name="companyName" 
                            rules={[
                                {
                                required: getBrandStatus() !== 'third',
                                message: "Please enter a company name",
                                },
                            ]}
                        >
                            <Input placeholder="Enter DBA or Brand Name" disabled={getBrandStatus() === 'third'}/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="DBA or Brand Name, if different from legal name" tooltip="Enter DBA or Brand Name">
                        <Form.Item 
                            name="displayName" 
                        >
                            <Input placeholder="Enter DBA or Brand Name, if different from legal name"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="What type of legal form is the organization?*">
                        <Form.Item 
                        name="entityType" 
                        initialValue="PRIVATE_PROFIT"
                        rules={[
                            {
                            required: true,
                            message: "Please select a legal entity type",
                            },
                        ]}
                        >
                            <Select 
                                placeholder="What type of legal form is the organization?" 
                                onChange={(val) => setFormType(val as string)}
                            >
                                {getOptions(c?.entity_types)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Country of Registration*" tooltip="Select country">
                        <Form.Item name="country" initialValue="US">
                            <Select 
                                placeholder="Enter country of registration*" 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getOptions(c?.countries)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Tax Number/DI/EIN*">
                        <Form.Item 
                            name="ein"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a Tax #, DI, or EIN",
                                },
                                // ({getFieldValue}) => ({
                                //     validator(_, value) {
                                //       if (!value || (value.match(/\d{2}-\d{7}/gm) && value.length <= 10)) {
                                //         return Promise.resolve();
                                //       }
                                //       return Promise.reject('Invalid EIN - EIN is a nine-digit number. The format is XX-XXXXXXX. The "-" symbol is also accepted.');
                                //     },
                                // }),
                            ]}
                        >
                            <Input placeholder="Enter Tax Number/DI/EIN" disabled={getBrandStatus() === 'third'}/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Tax Number/ID/EIN Issuing Country" tooltip="Enter Country">
                        <Form.Item name="einIssuingCountry" initialValue="US">
                            <Select 
                                placeholder="Enter Tax Number/ID/EIN issuing country" 
                                disabled={getBrandStatus() === 'third'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getOptions(c?.countries)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="DUNS or GIIN or LEI Number" tooltip="Select DUNS">
                        <Form.Item 
                        name="altBusinessIdType"
                        initialValue="NONE"
                        rules={[
                            {
                            required: true,
                            message: "Please select business id!",
                            },
                        ]}
                        >
                            <Select 
                                placeholder="Select DUNS or GIIN or LEI Number" 
                                disabled={getBrandStatus() === 'third'}
                                onChange={(val) => setBusinessType(val as string)}
                            >
                                {getOptions(c?.alt_business_id_types)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="DUNS or GIIN or LEI Number" tooltip="Enter DUNS">
                        <Form.Item name="altBusinessId">
                            <Input placeholder="Enter DUNS or GIIN or LEI Number" disabled={getBrandStatus() === 'third' || businessType === 'NONE'}/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Address/Street*">
                        <Form.Item 
                            name="street"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a street address",
                                },
                            ]}
                        >
                            <Input placeholder="Enter address/street"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="City*">
                        <Form.Item 
                            name="city"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a city",
                                },
                            ]}
                        >
                            <Input placeholder="Enter city"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="State/Region*">
                        <Form.Item 
                            name="state"
                            rules={[
                                {
                                required: true,
                                message: "Please select a state/region",
                                },
                            ]}
                        >
                            <Input placeholder="Enter state/region"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Postal Code/ZIP Code*">
                        <Form.Item 
                            name="postalCode"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a zip code",
                                },
                            ]}
                        >
                            <Input placeholder="Enter Postal Code/ZIP Code"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Country">
                        <Form.Item 
                            name="country"
                            rules={[
                                {
                                required: true,
                                message: "Please select a country",
                                },
                            ]}
                        >
                            <Select 
                                placeholder="Select country"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getOptions(c?.countries)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Website*">
                        <Form.Item 
                            name="website"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a company URL",
                                },
                            ]}
                        >
                            <Input placeholder="Enter website"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Stock Symbol">
                        <Form.Item 
                            name="stockSymbol"
                            rules={[
                                {
                                    required: getBrandStatus() === 'first',
                                    message: 'Please enter your stock symbol'
                                }
                            ]}
                        >
                            <Input 
                                placeholder="Enter stock symbol"
                                disabled={getBrandStatus() === 'second' || getBrandStatus() === 'third'}
                            />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Stock Exchange">
                        <Form.Item 
                            name="stockExchange"
                            rules={[
                                {
                                    required: getBrandStatus() === 'first',
                                    message: 'Please select the stock exchange'
                                }
                            ]}    
                        >
                            <Select 
                                placeholder="Select stock exchange"
                                disabled={getBrandStatus() === 'second' || getBrandStatus() === 'third'}
                            >
                                {getOptions(c?.stock_exchanges)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="Vertical Type*">
                        <Form.Item 
                            name="vertical"
                            rules={[
                                {
                                required: true,
                                message: "Please select your industry vertical",
                                },
                            ]}
                        >
                            <Select 
                                placeholder="Select vertical type"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getOptions(c?.verticals)}
                            </Select>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Reference ID">
                        <Form.Item name="referenceId">
                            <Input placeholder="Enter reference ID"/>
                        </Form.Item>
                    </FormItem>
                </FormRow>
                <FormRow>
                    <FormItem label="First Name">
                        <Form.Item 
                            name="firstName"
                            rules={[
                                {
                                required: getBrandStatus() === 'third',
                                message: "Please provide the business owner's first name",
                                },
                            ]}
                        >
                            <Input 
                                placeholder="Enter first name" 
                                disabled={getBrandStatus() === 'first' || getBrandStatus() === 'second'}
                            />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Last Name">
                        <Form.Item 
                            name="lastName"
                            rules={[
                                {
                                required: getBrandStatus() === 'third',
                                message: "Please provide the business owner's last name",
                                },
                            ]}
                        >
                            <Input 
                                placeholder="Enter last name" 
                                disabled={getBrandStatus() === 'first' || getBrandStatus() === 'second'}
                            />
                        </Form.Item>
                    </FormItem>
                </FormRow>
            </Form>
        </Flex>
    )

    const renderContactForm = () => (
        <Flex width="100%" flexDirection="column">
            <Msg>Please provide an email address and phone number as your official contact</Msg>
            <Msg>information for registration with phone carriers.</Msg>
            <Form form={brandForm} layout="vertical">
                <FormRow>
                    <FormItem label="Support Email Address*">
                        <Form.Item 
                            name="email"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a email address!",
                                },
                            ]}
                        >
                            <Input placeholder="Enter email"/>
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Phone" tooltipPaddingLeft="60px">
                        <Form.Item 
                            name="phone"
                            rules={[
                                {
                                required: true,
                                message: "Please enter a phone number!",
                                },
                            ]}
                        >
                            <StyledPhoneNumberSmall
                                international
                                defaultCountry="US"
                                name="phone"
                                value={phone}
                                onChange={(val) => setPhone(val)}
                                fullWidth
                            />
                        </Form.Item>
                    </FormItem>
                </FormRow>
            </Form>
        </Flex>
    )

    const selectPhone = (number: string) => {
        if (!onboardingFinished) {
            setSelectedPhone(number)
            setSelectPhoneVisible(true)
        }
    }

    const handleSelectPhoneModalOk = async () => {
        if (selectedPhone) {
            try {
                await Api.telgorithm.setPhone(selectedPhone)
                setOnboardingFinished(true)
                setSelectPhoneVisible(false)
            } catch (err) {
                const errors = getErrors(err)
                showNonFieldsErrors(errors)
            }
        }
    }

    const handleSelectPhoneModalCancel = () => {
        setSelectPhoneVisible(false)
    }

    const isPlusButtonDisabled = (number: string) => {
        if (!selectedPhone || !onboardingFinished) {
            return false;
        }
        return number !== selectedPhone;
    }

    const finishThirdStep = () => {
        setFinishVisible(false)
        finishOnBoarding()
    }

    const columns: TableColumnsType<ITelgorithmPhone> = [
        {
            title: "Number",
            dataIndex: "number",
            key: "number",
        },
        {
            title: "Location",
            dataIndex: "city",
            key: "city",
            render: (_, record) => `${record.state}, ${record.city}`
        },
        {
            title: "Services",
            dataIndex: "supportedServices",
            key: "supportedServices",
            render: (_, record) => {
                const service = record.supportedServices;
                if (service === 'SMSMMS') {
                    return <>
                        <Service marginRight="4px">SMS</Service>
                        <Service>MMS</Service>
                    </>
                }
                return <Service>{service}</Service>
            }
        },
        {
            title: "",
            render: (_, record) => (
                <PlusButton 
                    type="primary" 
                    onClick={() => selectPhone(record.number)}
                    disabled={isPlusButtonDisabled(record.number)}
                    isSelected={selectedPhone === record.number && onboardingFinished}
                >
                    {selectedPhone === record.number && onboardingFinished ? "Selected" : "Select"}
                    {!(selectedPhone === record.number && onboardingFinished) && <TMPlus />}
                </PlusButton>
            )
        }

    ]

    const renderPhoneForm = () => (
        <Flex width="100%" flexDirection="column">
            <Modal 
                visible={selectPhoneVisible}
                onOk={handleSelectPhoneModalOk}
                onCancel={handleSelectPhoneModalCancel}
                width={374}
                okText="Select"
            >
                <Flex width="314px" flexDirection="column" paddingTop="16px">
                    <Msg fontSize="18px" fontWeight={700} color="#202020">
                        {selectedPhone}
                    </Msg>
                    <Msg fontSize="15px" fontWeight={400} color="#202020">
                        Are you sure you want to select this number? 
                    </Msg>
                    <Msg fontSize="15px" fontWeight={400} color="#202020" margin="16px 0 12px">
                        Once you finalize your selection, you will not be able to change your business number.
                    </Msg>
                </Flex>
            </Modal>
            <Modal
                visible={finishVisible}
                onOk={finishThirdStep}
                onCancel={finishThirdStep}
                width={374}
                title="Congratulations!"
                okText="Close"
            >
                <Msg fontSize="15px" fontWeight={300}>
                    You have successfully registered your company's number and activated SMS messaging.
                </Msg>
            </Modal>
            <Flex flexDirection="column">
                <Msg color="#494949" fontSize="15px">
                    Time to create your business's dedicated messaging number.
                </Msg>
                <Msg color="#494949" fontSize="15px">
                    Select a region or state to find your local area code.
                </Msg>
            </Flex>
            <Flex marginTop="20px">
                <Flex margin="3px 8px 0 0">
                    <TMAppointment />
                </Flex>
                <Flex flexDirection="column" width="100%">
                    <Msg color="#494949" fontSize="15px" fontWeight={700}>
                        NOTE: Once you confirm the number, you will not be able to change
                    </Msg>
                    <Msg color="#494949" fontSize="15px" fontWeight={700}>
                        your business's number. Choose wisely.
                    </Msg>
                </Flex>
            </Flex>                      
                <Form>
            <Flex marginTop="24px" width="100%">
                <PhoneBlock>
                    <Msg>Search by:</Msg>
                    <Select value={searchBy} onSelect={(value) => setSearchBy(value)} disabled={onboardingFinished}>
                        <Select.Option value="RG">Region</Select.Option>
                        <Select.Option value="AC">Area Code</Select.Option>
                    </Select>
                </PhoneBlock>
                {searchBy === 'RG' ?
                <>
                    <PhoneBlock>
                        <Msg>State:</Msg>
                        <Select 
                            value={state} 
                            onSelect={(value) => setState(value)} 
                            placeholder="Select state" 
                            disabled={onboardingFinished}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {!!c?.states && Object.entries(c.states).map(state => (
                                <Select.Option value={state[0]}>{state[1]}</Select.Option>
                            ))}
                        </Select>
                    </PhoneBlock>
                    <PhoneBlock>
                        <Msg>City:</Msg>
                        <Input value={city} onChange={(e) => setCity(e.target.value)} placeholder="Enter city"  disabled={onboardingFinished}/>
                    </PhoneBlock>
                </> :
                <>
                    <PhoneBlock>
                        <Msg>Area Code:</Msg>
                        <Form.Item
                            name="area_code"
                            rules={[
                                {},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                      if (!value || (value.match(/\d{3}/gm) && value.length === 3)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject('Invalid Area Code - Area Code is a three-digit number.');
                                    },
                                }),
                            ]}
                        >
                        <Input 
                            value={areaCode} 
                            onChange={(e) => setAreaCode(e.target.value)} 
                            placeholder="Enter area code"  
                            disabled={onboardingFinished}
                        />
                        </Form.Item>                        
                    </PhoneBlock>
                </>}  
            </Flex>
                </Form>                  
            <SearchButton 
                type="primary" 
                disabled={isSearchButtonDisabled()} 
                loading={phonesLoading} 
                onClick={searchPhones}
            >
                Search Number
                <TMSearch />
            </SearchButton>
            {!!phones?.length && 
            <Table
                columns={columns}
                dataSource={phones}
                className="contained text-messaging-table"
                loading={phonesLoading}
            />}
        </Flex>
    )


    const renderContent = () => {
        switch (step) {
            case 0: 
                return renderBrandForm();
            case 1: 
                return renderContactForm();
            case 2:
                return renderPhoneForm()
            default:
                return renderBrandForm()
        }
    }

    return <OnBoardingContainer>
        <Modal
            title="PLEASE CONFIRM"
            okText="Confirm"
            onOk={handleOkModal}
            onCancel={handleCancelModal}
            visible={visible}
            width={374}
        >
            <Flex marginBottom="16px">
                <Msg fontSize="18px" color="#202020" width="300px">
                    You are about to activate text messaging for your account. 
                    Do you agree to the DockWorks messaging 
                    <br/> 
                    <a target="_blank" href="http://www.dockworks.co/messaging-terms-of-use" style={{textDecoration: "underline"}}>
                        terms of use
                    </a>?
                </Msg>
            </Flex>            
        </Modal>
        <StepsContainer>
            <Steps direction="vertical" current={step}>
                {steps.map((step, i) => <Steps.Step {...step} icon={i+1} />)}
            </Steps>
        </StepsContainer>
        <Flex flexDirection="column" alignItems="center" width="100%">
            <ContentContainer>
                {renderContent()}
            </ContentContainer>
            <Flex marginTop="36px">
                {!!step &&
                <Button 
                    onClick={handlePrevious} 
                    style={{marginRight: "16px"}}
                    disabled={step === 2}
                    loading={isLoading}
                >
                    Previous
                </Button>}
                <Button 
                    type="primary" 
                    onClick={handleNextStep}
                    disabled={step === 2 && !onboardingFinished || isLoading}
                    loading={isLoading}
                >
                    Next
                </Button>
            </Flex>
        </Flex>
    </OnBoardingContainer>;
}

export default TextMessagingOnBoarding;