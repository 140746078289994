import { Button } from 'antd';
import styled from 'styled-components';
import {
  fontSize, FontSizeProps,
  fontWeight, FontWeightProps,
  space, SpaceProps,
  width, WidthProps,
  height, HeightProps,
  color, ColorProps, textAlign
} from 'styled-system';

type THBProps =
  & FontSizeProps
  & FontWeightProps
  & SpaceProps
  & WidthProps;
export const HeaderButton = styled(Button)<THBProps>`
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 6px;
  width: inherit;
  height: 45px;

  ${space};
  ${width};
  ${fontSize};
  ${fontWeight};
`;
type TAllProps =
  & FontSizeProps
  & FontWeightProps
  & SpaceProps
  & WidthProps
  & HeightProps
  & ColorProps;
export const EmptyButton = styled(Button)<TAllProps>`
  background: none;
  border: none;
  padding: 0;
  box-sizing: border-box;
  border-radius: 6px;
  width: inherit;
  height: 100%;
  ${space};
  ${width};
  ${fontSize};
  ${fontWeight};
  ${height}
  ${color}
  ${textAlign}
`;

export const PrimaryButton = styled(Button)<TAllProps>`
  border: none;
  box-sizing: border-box;
  height: 45px;
  background: #109CF1;
  border-radius: 4px;
  color: #ffffff;
  border: 0.7px solid #1C99FC;
  &:disabled, &:disabled:hover, &:disabled:active, &:disabled:focus {
    background-color: #ACE0FF;
    color: white;
  }
  ${space};
  ${width};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${color};
`;