import {createReducer} from "@reduxjs/toolkit";
import {setJobFilter} from "./actions";
import {JOBS} from "./constants";

type TState = Record<string, Record<string, string>>
const initialState: TState = {};
export const filtersReducer = createReducer(initialState, builder => builder
  .addCase(setJobFilter, (state, {payload}) => {
    return {...state, [JOBS]: payload};
  })
);