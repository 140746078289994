import React from 'react';
import DarkLogo from '../../assets/images/DarkLogo.svg';
import Flex from '../../noui/Flex';
import Box from '../../noui/Box';
import styled from 'styled-components';
import {Msg} from '../../ui/Text';
import {Input, Form} from 'antd';
import {PrimaryButton} from '../../ui/Button';
import {NavLink, useHistory} from 'react-router-dom';
import SmallBoatImg from '../../assets/images/SmallBoatImg.png'
import {Api} from "../../api/api";
import routes from "../../routes/routes";
import {getErrors, showErrors} from "../../utils/utils";
import {IRegisterUser} from "../../api/types";
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { BoxWrapper, LoginFlowWrapper, StyledEnvelopeIcon } from '../login/Login';


const StyledInput = styled(Input)`
  height: 40px;
  width: 374px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  border-radius: 4px;
  ${down('md')} {
    width: 100%;
  }
`;

const LogoContainer = styled(Box)`
& img {
    width: 140px;
    height: 24px;
}
${down('md')} {
  margin: 0 auto;
}
`;

const BtnPr = styled(PrimaryButton)`
&:hover {
  border: 1px solid #109CF1;
}
`

type TUserEmail = Pick<IRegisterUser, "email">;
const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm<TUserEmail>();
  const isMd = useBreakpoint(down('md'));

  const handleSubmit = async (data: TUserEmail) => {
    try {
      await Api.user.resetPassword(data);
      history.replace(routes.auth.CheckEmail);
    } catch (e) {
      const errors = getErrors(e);
      form.setFields(errors.map(err => ({...err, name: ["email"]})))
    }
  }

  return <Flex backgroundColor={!isMd ? "none" : "#FBFBFF"}>
    <LoginFlowWrapper padding={isMd ? "0 12px" : "0"}>
      <LogoContainer p={35}>
      <NavLink to={routes.auth.Login}>
        <img src={DarkLogo} alt='logotype'/>
      </NavLink>
      </LogoContainer>
      <Flex flexDirection="column" justifyContent='flex-start' height="100%">
        <Box m="auto">
          <Flex flexDirection='column' justifyContent='space-between' maxWidth={isMd ? "400px" : "initial"} margin="auto">
            <Flex flexDirection='column'>
              <Box mb='30px' textCenter={!!isMd}>
                <Msg fontWeight={700} fontSize={22}>Forgot Password?</Msg>
              </Box>
              <Box width={isMd ? "100%" : "310px"} mb='40px'>
                <Msg fontWeight={400} fontSize={16} color="#898989">
                  Enter the email you registered with and we will send you a link to reset your password
                </Msg>
              </Box>
              <Box mb='8px'>
                <Msg fontWeight={700} fontSize={15} color="#484848">E-mail</Msg>
              </Box>
              <Form
                form={form}
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: 'The input is not valid E-mail!',
                    },
                  ]}>
                  <StyledInput
                    prefix={<StyledEnvelopeIcon />}
                    placeholder="user@gmail.com"
                  />
                </Form.Item>
                <Box mt='30px' mb="15px">
                  <BtnPr width={isMd ? "100%" : "130px"} height="40px" fontWeight={600} fontSize={14} htmlType="submit">
                    Send email
                  </BtnPr>
                </Box>
              </Form>
            </Flex>
          </Flex>
        </Box>
        <Box width={isMd ? "100%" : "374px"} textCenter={!!isMd} m="auto" mb="30px" mt="0">
          <Msg fontWeight={600} fontSize={16} color="#BDBDBD">
            New to Dockworks? <NavLink to={routes.auth.Register}>Register Now</NavLink></Msg>
        </Box>
      </Flex>
    </LoginFlowWrapper>
    {!isMd && 
    <BoxWrapper>
      <img src={SmallBoatImg} width="100%" height="100%"/>
    </BoxWrapper>}
  </Flex>
}

export default ForgotPassword; 