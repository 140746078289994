import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { Affix, Button, Form, FormInstance, Input, notification, Tabs, Tooltip } from "antd"
import moment from "moment"
import { ChangeEventHandler, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { Api } from "../../api/api"
import { ERecurringStatus, IWorkOrderWithJobs } from "../../api/types"
import { EditIcon } from "../../assets/icons"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { useQuery } from "../../utils/hooks"
import { formatToUSD, getErrors, showErrors, showNonFieldsErrors } from "../../utils/utils"
import { MessagesTab } from "../jobs/content/RecurringMessagesTab"
import { RecurringBillingWarningModal } from "../jobs/RecurringBillingWarningModal"
import { SendEstimateModal } from "../jobs/SendEstimateModal"
import { RecurringSideContent } from "./components/RecurringSideContent"
import { ProgressSteps } from "./components/Steps"
import { InfoContainer, OrderInfoHeader, OrderInfoWrapper, ResponsiveFlex, TabsWrapper } from "./styled"
import { LaborTab } from "./tabs/ServicesTab"
import { SaveButton, tabs } from "./WorkOrderInfo"

function getCoords(elem: any) {
   if (!elem) return;
   let box = elem.getBoundingClientRect()
   return box.top + window.pageYOffset
}

type TProps = {
   workOrder: IWorkOrderWithJobs | null
   loadData: () => void
   recurringForm: FormInstance<any>
   form: FormInstance<any>
   showMoreJC: boolean
   setShowMoreJC: (value: boolean) => void
}

const RecurringJobConfig: React.FC<TProps> = ({ workOrder, loadData, recurringForm, form, showMoreJC, setShowMoreJC }) => {
   const isXs = useBreakpoint(down("xs"))
   const isLg = useBreakpoint(down("lg"))
   const [isTitleEditing, setIsTitleEditing] = useState(false)
   const [showMore, setShowMore] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [isSaved, setIsSaved] = useState(!!workOrder?.recurring_config)
   const [showEstimateModal, setShowEstimateModal] = useState(false)
   const [showBillingModal, setShowBillingModal] = useState(false)
   const [title, setTitle] = useState(workOrder?.recurring_config?.title ?? `#${workOrder?.order_number ?? 0}`)
   const [activeTab, setActiveTab] = useState("1")
   const query = useQuery()
   const isDisabled = useMemo(() => workOrder?.recurring_status !== ERecurringStatus.Draft, [workOrder?.recurring_status])
   const [showControls, setShowControls] = useState(true)

   const handleScroll = () => {
      const coords =  getCoords(document.getElementById("recurring_job"));
      if (!coords) return;
      if (coords + 150 <= window.scrollY + window.innerHeight) {
         setShowControls(true)
      } else if (coords + 150 > window.scrollY + window.innerHeight) {
         setShowControls(false)
      }
   }

   useEffect(() => {
      if (isLg && !isXs) {
         const block = document.getElementById("main_block")
         block?.addEventListener("scroll", handleScroll)
         return () => block?.removeEventListener("scroll", handleScroll)
      } else {
         window.addEventListener("scroll", handleScroll)
         return () => window.removeEventListener("scroll", handleScroll)
      }
   }, [isXs, isLg])

   useLayoutEffect(() => {
      const isMessage = query.get("message")
      if (isMessage === "true") {
         setShowMore(true)
         setActiveTab("5")
         
      }
   }, [])

   useLayoutEffect(() => {
      const isMessage = query.get("message")
      if (isMessage === "true" && showMore && activeTab === "5") {
         const configBlock = document.getElementById("recurring_job")
         if (configBlock) {
            configBlock.scrollIntoView({behavior: "smooth"})
         }
      }
   }, [showMore, activeTab])

   const handleSave = async () => {
      try {
         if (isLg && !showMoreJC) {
            await setShowMoreJC(true)
         }
         setIsLoading(true)
         let data = form.getFieldsValue()
         if (data?.estimated_duration) {
            data = { ...data, estimated_duration: moment(data.estimated_duration).format("HH:mm:ss") }
         }
         recurringForm.submit()
         const fields = await recurringForm.validateFields()
         if (workOrder) {
            const { start_end_time, first_appointment_date, final_appointment_date, first_billing_date, ..._data } =
               fields
            const formatedData = {
               ..._data,
               start_time: start_end_time[0].format("HH:mm"),
               end_time: start_end_time[1].format("HH:mm"),
               first_appointment_date: first_appointment_date.format("YYYY-MM-DD"),
               final_appointment_date: final_appointment_date.format("YYYY-MM-DD"),
               first_billing_date: first_billing_date.format("YYYY-MM-DD"),
               id: workOrder?.id ?? 0,
               ...(title && { title: title }),
            }
            await Api.workOrders.setRecurringConfig(formatedData)
            await Api.workOrders.patch({ ...data, id: workOrder?.id })
            notification.success({ message: "Successfully saved" })
            setIsSaved(true)
            await loadData()
         }
      } catch (err: any) {
         if (err?.config?.url.includes("set_recurring_config")) {
            const configErrors = getErrors(err)
            recurringForm.setFields(configErrors)
            showNonFieldsErrors(configErrors)
            return
         }
         showErrors(getErrors(err))
      } finally {
         setIsLoading(false)
      }
   }

   const checkBillingDate = () => {
      const billingDate = workOrder?.recurring_config?.first_billing_date;
      const startDate = workOrder?.recurring_config?.first_appointment_date;
      if (!billingDate || !startDate) {
         return false;
      }
      return (moment(startDate).isBefore(billingDate))
   }

   const handleSendToClient = () => {
      if (checkBillingDate()) {
         setShowBillingModal(true)
         return
      }
      setShowEstimateModal(true)
   }

   const handleBillingModalOk = () => {
      setShowBillingModal(false)
      setShowEstimateModal(true)
   }

   const handleBillingModalCancel = () => {
      setShowBillingModal(false)
   }

   const handleTitleChange = (e: any) => {
      setTitle(e.target.value)
   }

   const calculateDuration = (d: string | null): moment.Moment | null => {
      if (!d) {
         return null
      }
      // TODO: Calc days?
      const parts = d.split(" ")
      const time = parts[parts.length - 1].split(":")
      return moment().hour(Number(time[0])).minute(Number(time[1])).second(0)
   }

   useEffect(() => {
      if (workOrder) {
         form.resetFields()
         const { services, materials, priority } = workOrder
         const estimated_duration = calculateDuration(workOrder.estimated_duration)

         form.setFieldsValue({ services, materials, estimated_duration, priority })
      }
   }, [workOrder])

   return (
      <Form form={form} layout="vertical" onFieldsChange={() => setIsSaved(false)} id="recurring_job">
         <SendEstimateModal
            isInvoice={false}
            visible={showEstimateModal}
            onClose={() => setShowEstimateModal(false)}
            workOrderNumber={workOrder?.id ?? 0}
            workOrder={workOrder}
            isRecurring={true}
         />
         {!!(workOrder && isSaved && workOrder.recurring_config) && 
         <RecurringBillingWarningModal
            visible={showBillingModal}
            id={workOrder.id}
            billingDate={workOrder.recurring_config.first_billing_date}
            onCancel={handleBillingModalCancel}
            onOk={handleBillingModalOk}
         />}
         <OrderInfoWrapper isRecurring mt={workOrder?.is_recurring && !isLg ? "0px" : "24px"}>
            <OrderInfoHeader>
               <Flex justifyContent="space-between" alignItems="center">
                  {!isLg ? (
                     <ResponsiveFlex
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ flexGrow: 1 }}
                        mr="36px"
                        width="90%"
                     >
                        <Flex alignItems="center" width="80%">
                           {isTitleEditing ? (
                              <Input style={{ fontSize: 20 }} value={title} onChange={handleTitleChange} onBlur={() => setIsTitleEditing(false)}/>
                           ) : (
                              <>
                              <Tooltip title={title}>
                                 <Flex maxWidth={"100%"} style={{ overflow: "hidden" }}>
                                    <Msg fontWeight={900} fontSize={24}>
                                       {title}
                                    </Msg>
                                 </Flex>
                              </Tooltip>
                              <Button
                                 type="text"
                                 onClick={() => setIsTitleEditing(true)}
                                 style={{ margin: "0 0 0 10px" }}
                              >
                                 <EditIcon verticalAlign="middle" />
                              </Button>
                              </>
                           )}
                        </Flex>
                        <Flex flexDirection="row" alignItems="center">
                           <Flex flexDirection={!isLg ? "column" : "row"} alignItems={!isLg ? "start" : "center"}>
                              <Msg
                                 color="#828282"
                                 marginLeft={!isLg ? "0px" : "10px"}
                              >{`Job #${workOrder?.order_number}`}</Msg>
                           </Flex>
                        </Flex>
                     </ResponsiveFlex>
                  ) : (
                     <Flex alignItems="center" width="80%">
                        {isTitleEditing ? (
                           <Input style={{ fontSize: 20 }} value={title} onChange={handleTitleChange} />
                        ) : (
                           <Tooltip title={title}>
                              <Flex maxWidth={"100%"} style={{ overflow: "hidden" }}>
                                 <Msg fontWeight={900} fontSize={20}>
                                    {title}
                                 </Msg>
                              </Flex>
                           </Tooltip>
                        )}
                        <Button
                           type="text"
                           onClick={() => {
                              setIsTitleEditing((prev) => !prev)
                           }}
                           style={{ margin: "0 0 0 10px" }}
                        >
                           <EditIcon verticalAlign="middle" />
                        </Button>
                     </Flex>
                  )}
                  <Button type="text" onClick={() => setShowMore(!showMore)}>
                     {!showMore ? <DownOutlined /> : <UpOutlined />}
                  </Button>
               </Flex>
               {isLg && (
                  <Flex flexDirection="row" alignItems="center" marginTop="16px">
                     <Msg color="#8B8B8B" fontSize={15}>
                        Job #{workOrder?.order_number}
                     </Msg>
                     <Msg color="#0F497B" fontSize={20} fontWeight={800} margin="0 8px 0 auto">
                        {formatToUSD(workOrder?.total)}
                     </Msg>
                  </Flex>
               )}
            </OrderInfoHeader>
            {showMore && (
               <Flex flexDirection="column">
                  <InfoContainer>
                     <TabsWrapper>
                        <ProgressSteps activeStep={1} isError={workOrder?.is_declined} />
                        <Tabs
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        activeKey={activeTab}>
                           {tabs.map((t, i) => {
                              const C = t.component
                              return (
                                 <Tabs.TabPane key={t.key} tab={t.title} disabled={i>0 && i<4}>
                                    {i === 0 && (
                                       <LaborTab form={form} technicianRole={isDisabled} isJobConfig isTitleEditing={isTitleEditing} workOrder={workOrder} />
                                    )}
                                    {i === 4 && (
                                       <MessagesTab form={form} workOrder={workOrder} />
                                    )}
                                 </Tabs.TabPane>
                              )
                           })}
                        </Tabs>
                     </TabsWrapper>
                     <RecurringSideContent form={form} workOrder={workOrder} />
                  </InfoContainer>
                  {isLg ? (
                     <Affix offsetBottom={0}>
                        {(showControls && !isDisabled) && (
                           <Flex
                              alignItems="center"
                              padding="10px 16px"
                              mt={isXs ? "24px" : "36px"}
                              mb={isXs ? "0px" : "34px"}
                              justifyContent="space-between"
                              style={{ gap: "16px", boxShadow: "0px 0px 6px rgba(50, 50, 50, 0.2)" }}
                              backgroundColor="#FFFFFF"
                           >
                              <Button type="primary" disabled={!isSaved} onClick={handleSendToClient}>
                                 Send to client
                              </Button>
                              <SaveButton loading={isLoading} onClick={handleSave}>
                                 {isSaved ? "Saved" : "Save"}
                              </SaveButton>
                           </Flex>
                        )}
                     </Affix>
                  ) : (
                     !isDisabled && (<Flex
                        alignItems="center"
                        pl="30px"
                        pr="0px"
                        mt="36px"
                        mb="34px"
                        justifyContent="start"
                        style={{ gap: "16px" }}
                        backgroundColor="#FFFFFF"
                     >
                        <Button type="primary" disabled={!isSaved} onClick={handleSendToClient}>
                           Send to client
                        </Button>
                        <SaveButton loading={isLoading} onClick={handleSave}>
                           {isSaved ? "Saved" : "Save"}
                        </SaveButton>
                     </Flex>)
                  )}
               </Flex>
            )}
         </OrderInfoWrapper>
      </Form>
   )
}

export default RecurringJobConfig
