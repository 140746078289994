import { Select } from 'antd';
import styled from 'styled-components';

export const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 4px !important;
    .ant-select-selection-search input {
        width: calc(100% - 8px) !important;
        border-right: 1px solid #d9d9d9 !important;
        opacity: 1 !important;
    }
  }
  &.ant-select {
    width: 100%;   
  }
  .ant-select-selection-placeholder {
    color: #828282;
  }
`