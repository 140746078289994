import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import {
  TTimelineProps,
  TTimelineHourBlockProps,
  TTimelineEventProps,
  TUserTime,
} from "../types";
import TimelineEmployee from "./TimelineEmployee";
import { useHistory } from "react-router";
import routes from "../../../routes/routes";
import { ScheduledIcon } from "../../../assets/images/dispatchTab/TabIcons";
import { down } from "styled-breakpoints";
import Flex from "../../../noui/Flex";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { Msg } from "../../../ui/Text";
import { TSchedule } from "../../../api/api";
import { start } from "repl";
import { IUser } from "../../../api/types";

const HeaderUpperRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubRow = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
`;

const HeaderSideBlock = styled.div`
  border-right: 1px solid #bdbdbd;
  width: 26%;
  height: 100%;
  ${down("lg")} {
    width: 100%;
  }
  ${down("xs")} {
    font-size: 12px;
    font-weight: bold;
  }
`;

const TimelineRow = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ececec;
  &:last-child {
    border-bottom: 1px solid #ececec;
  }
  ${down("lg")} {
    width: fit-content;
  }
`;

const TimelineRowSideBlock = styled.div`
  border-right: 1px solid #bdbdbd;
  width: 26%;
  height: 100%;
  ${down("lg")} {
    width: 100%;
  }
`;

const TimelineRowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 72%;
  height: 100%;
  margin-left: 1.2%;
  position: relative;
  overflow: hidden;
  ${down("lg")} {
    overflow: visible;
    margin-left: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
`;

const TimelineHourBlock = styled.div`
  border-right: ${(props) => (props?.noBorder ? "none" : "1px solid #ECECEC")};
  width: calc((100% / ${(props: TTimelineHourBlockProps) => props.hourRange}));
  font-size: calc(
    min(
      6vw / (${(props: TTimelineHourBlockProps) => props.hourRange} / 2),
      0.85vw
    )
  );
  height: 100%;
  &:first-child {
    border-left: ${(props) => (props?.noBorder ? "none" : "1px solid #ECECEC")};
  }
  ${down("lg")} {
    font-size: 10px;
    width: 12vw;
    min-width: 12vw;
  }
  ${down("md")} {
    width: 18vw;
    min-width: 18vw;
  }
`;

const TimelineEvent = styled.div`
  background: ${(props: TTimelineEventProps) =>
    props.isScheduled ? "#8B8B8B" : "#109cf1"};
  border-radius: 4px;
  margin-top: ${(props: TTimelineEventProps) =>
    props.isLower && !props.isScheduled ? "16px" : "0px"};
  margin-top: ${(props: TTimelineEventProps) =>
    props.isScheduled ? "-12px" : "8px"};
  border: 1px solid #ffffff;
  overflow: hidden;
  height: 28px;
  position: absolute;
  top: 10px;
  left: ${(props: TTimelineEventProps) => props.left};
  right: ${(props: TTimelineEventProps) => props.right};
  color: #ffffff;
  padding: 3px 8px;
  cursor: pointer;
  display: flex;
  svg {
    margin-right: 5px;
  }
`;

const MainTimelineWrapper = styled(Flex)`
  display: flex;
  width: 100%;
`;

const InfoTimeLineBlock = styled(Flex)`
  flex-direction: column;
  flex: 28;
`;

const ContentTimeLineBlock = styled(Flex)`
  flex-direction: column;
  flex: 72;
  overflow-y: auto;
`;

const Timeline: React.FC<TTimelineProps> = ({
  date,
  dayStart,
  dayEnd,
  events,
  isScheduler,
  employees,
  search,
  currentView,
}) => {
  const [allDates, setAllDates] = useState<string[] | number[]>([]);
  const [userTime, setUserTime] = useState<TUserTime>({});
  const [filteredEmployees, setFilteredEmployees] = useState<IUser[]>();
  const history = useHistory();
  const isXs = useBreakpoint(down("xs"));
  const isLg = useBreakpoint(down("lg"));
  const getStartDateTime = () => {
    const startDateTime = moment(date).toDate();
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);
    startDateTime.setMilliseconds(0);
    return startDateTime;
  };
  function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());
    date.setDate(date.getDate() + 1);
    const dates = [];
    while (date < endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const timeConflictChecking = () => {
    if (!isScheduler) {
      return false;
    }
    const startTimeScheduledEvent = events[0].start;
    const endTimeScheduledEvent = events[0].end;
    const eventsWithTimeConflict: any = events
      .filter(function (o1) {
        return search.some(function (o2) {
          return o1.technicians.includes(+o2);
        });
      })
      .filter((e) => {
        return (
          moment(e.end).isAfter(moment(startTimeScheduledEvent)) &&
          moment(e.start).isBefore(moment(endTimeScheduledEvent))
        );
      });

    return eventsWithTimeConflict.length > 1;
  };
  function getHoursInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());
    date.setHours(date.getHours() + 1);

    const dates = [];
    while (date < endDate) {
      dates.push(new Date(date));
      date.setHours(date.getHours() + 1);
    }
    return dates;
  }
  const getDifferenceDate = (startDate: Date, endDate: Date) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(
      (date2 as unknown as number) - (date1 as unknown as number)
    );

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHors = Math.ceil(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    return { days: diffDays, hours: diffHors, minutes: diffMinutes };
  };

  function getMonthsInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date < endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const formatHour = (hour: number) => {
    const startDate = getStartDateTime();
    startDate.setHours(hour);
    return moment(startDate).format("h A");
  };

  const getWorkTime = (event: TSchedule) => {
    const _start = moment(date).startOf("day");
    const _end = moment(date).endOf("day");
    const startTime = moment(event.start).isSameOrAfter(_start)
      ? new Date(event.start)
      : _start.toDate();
    const endTime = moment(event.end).isSameOrBefore(_end)
      ? new Date(event.end)
      : _end.toDate();
    return moment(endTime).diff(moment(startTime), "minutes");
  };

  useEffect(() => {
    const hoursArray = getHoursInRange(dayStart, dayEnd).map((date) =>
      moment(date).toString()
    );

    if (currentView === "day") {
      setAllDates(hoursArray.length > 34 ? [] : hoursArray);
    } else {
      switch (currentView) {
        case "week":
          setAllDates(
            getDatesInRange(dayStart, dayEnd).map((date) =>
              moment(date).toString()
            )
          );
          break;
        case "month":
          setAllDates(
            getDatesInRange(dayStart, dayEnd).map((date) =>
              moment(date).toString()
            )
          );
          break;
      }
    }
  }, [dayStart, dayEnd]);

  useEffect(() => {
    const newUserTime: TUserTime = {};
    events.forEach((event) => {
      event.technicians.forEach((t: any) => {
        newUserTime[t] = (newUserTime[t] ?? 0) + getWorkTime(event);
      });
    });
    setUserTime(newUserTime);
  }, [events]);
  useEffect(() => {
    if (isScheduler) {
      setFilteredEmployees(
        employees.filter(function (o1) {
          return search.some(function (o2) {
            return o1.id === +o2; // return the ones with equal id
          });
        })
      );
    } else {
      setFilteredEmployees(
        employees.filter((employee) =>
          employee.full_name.toLowerCase().includes(search[0].toLowerCase())
        )
      );
    }
  }, [search, employees]);

  function getTimelineDivision(date: any) {
    switch (currentView) {
      case "week":
        return moment(date).format("DD MM").replace(" ", ".");
      case "month":
        return date;
      default:
        return formatHour(date);
    }
  }

  function getTimelineArray() {
    switch (currentView) {
      case "day":
        return allDates.map((date) => moment(date).toDate().getHours());
      case "month":
        return allDates
          .map((date) => moment(date).format("MMM YYYY"))
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          });
      default:
        return allDates;
    }
  }
  return !isLg ? (
    <div>
      <HeaderUpperRow>
        <HeaderSideBlock>Employees</HeaderSideBlock>
        <TimelineRowContent>
          {getTimelineArray().map((date: string | number) => (
            <TimelineHourBlock
              key={date}
              noBorder
              hourRange={getTimelineArray().length}
            >
              {getTimelineDivision(date)}
            </TimelineHourBlock>
          ))}
        </TimelineRowContent>
      </HeaderUpperRow>
      <SubRow>
        <HeaderSideBlock></HeaderSideBlock>
        <TimelineRowContent>
          {getTimelineArray().map((date: string | number) => {
            return (
              <TimelineHourBlock
                key={date}
                hourRange={getTimelineArray().length}
              />
            );
          })}
        </TimelineRowContent>
      </SubRow>

      <div>
        {filteredEmployees &&
          filteredEmployees.map((employee) => (
            <TimelineRow key={employee.id}>
              <TimelineRowSideBlock>
                <TimelineEmployee
                  employee={employee}
                  time={userTime[employee.id]}
                  isScheduler={isScheduler}
                />
              </TimelineRowSideBlock>
              <TimelineRowContent>
                {!!allDates.length &&
                  getTimelineArray().map((date: number | string) => (
                    <TimelineHourBlock
                      key={date}
                      hourRange={getTimelineArray().length}
                    />
                  ))}
                {!!(events.length && allDates.length) &&
                  events
                    .filter((event) => event.technicians.includes(employee.id))
                    .map((event) => {
                      let left: string | number = 0;
                      let right: string | number = 0;

                      if (
                        !(
                          moment(date).hour(+allDates[0] - 1) >
                          moment(event.start)
                        ) &&
                        currentView === "day"
                      ) {
                        const minutesDifference = getDifferenceDate(
                          new Date(allDates[0]),
                          event.start
                        ).minutes;

                        const minutesWidth = 100 / allDates.length / 60;
                        left = `${+minutesDifference * minutesWidth}%`;
                      }
                      if (currentView === "week") {
                        const hourDifference = getDifferenceDate(
                          new Date(allDates[0]),
                          event.start
                        ).hours;

                        const hourWidth = 100 / allDates.length / 24;
                        left = `${hourDifference * hourWidth}%`;
                      }
                      if (currentView === "month") {
                        const daysDifference = getDifferenceDate(
                          new Date(allDates[0]),
                          event.start
                        ).days;

                        const hourWidth = 100 / allDates.length;
                        left = `${daysDifference * hourWidth}%`;
                      }

                      if (currentView === "day") {
                        const minutesDifference =
                          getDifferenceDate(
                            new Date(allDates[allDates.length - 1]),
                            event.end
                          ).minutes + 60;

                        const minutesWidth = 100 / allDates.length / 60;
                        right = `${minutesDifference * minutesWidth}%`;
                      }
                      if (currentView === "week") {
                        const hourDifference =
                          getDifferenceDate(
                            new Date(allDates[allDates.length - 1]),
                            event.end
                          ).hours + 24;

                        const hourWidth = 100 / allDates.length / 24;
                        right = `${hourDifference * hourWidth}%`;
                      }
                      if (currentView === "month") {
                        const daysDifference = getDifferenceDate(
                          new Date(allDates[allDates.length - 1]),
                          event.end
                        ).days;

                        const hourWidth = 100 / allDates.length;

                        right = `${daysDifference * hourWidth}%`;
                      }

                      return (
                        <TimelineEvent
                          isScheduled={event.isScheduled}
                          isLower={isScheduler}
                          key={event.id}
                          right={right}
                          left={left}
                          onClick={() => {
                            history.push(
                              routes.workOrder.UpdateWorkOrder.replace(
                                ":id",
                                String(event.job.work_order)
                              ) + `?job=${String(event.job.id)}`
                            );
                          }}
                          id="timelineJob"
                        >
                          <ScheduledIcon />
                          <Msg fontSize="13px" color="#fff" id="titleJob">
                            {`${
                              event.job?.vessel?.name ||
                              event.job?.vessel_data?.name
                            } - ${event?.job?.title} - ${
                              event?.job?.order_number
                            }`}
                          </Msg>
                        </TimelineEvent>
                      );
                    })}
              </TimelineRowContent>
            </TimelineRow>
          ))}
      </div>
      <SubRow>
        <HeaderSideBlock></HeaderSideBlock>
        <TimelineRowContent>
          {allDates &&
            getTimelineArray().map((date: number | string) => (
              <TimelineHourBlock
                key={date}
                hourRange={getTimelineArray().length}
              />
            ))}
        </TimelineRowContent>
      </SubRow>
      {isScheduler && timeConflictChecking() && (
        <Msg color="#FB4D4F">You have time conflict</Msg>
      )}
    </div>
  ) : (
    <>
      <MainTimelineWrapper>
        <InfoTimeLineBlock>
          <HeaderUpperRow>
            <HeaderSideBlock>Employees</HeaderSideBlock>
          </HeaderUpperRow>
          <SubRow>
            <HeaderSideBlock></HeaderSideBlock>
          </SubRow>
          {filteredEmployees &&
            filteredEmployees.map((employee) => (
              <TimelineRow key={employee.id} style={{ width: "100%" }}>
                <TimelineRowSideBlock>
                  <TimelineEmployee
                    employee={employee}
                    time={userTime[employee.id]}
                  />
                </TimelineRowSideBlock>
              </TimelineRow>
            ))}
        </InfoTimeLineBlock>
        <ContentTimeLineBlock>
          <HeaderUpperRow>
            <TimelineRowContent style={{ height: isXs ? "18px" : "25px" }}>
              {allDates &&
                getTimelineArray().map((date: number | string) => (
                  <TimelineHourBlock
                    key={date}
                    noBorder
                    hourRange={allDates.length}
                  >
                    {getTimelineDivision(date)}
                  </TimelineHourBlock>
                ))}
            </TimelineRowContent>
          </HeaderUpperRow>
          <SubRow>
            <TimelineRowContent>
              {allDates &&
                getTimelineArray().map((date: number | string) => (
                  <TimelineHourBlock key={date} hourRange={allDates.length} />
                ))}
            </TimelineRowContent>
          </SubRow>
          {filteredEmployees &&
            filteredEmployees.map((employee) => (
              <TimelineRow key={employee.id}>
                <TimelineRowContent>
                  {!!allDates.length &&
                    getTimelineArray().map((date: number | string) => (
                      <TimelineHourBlock
                        key={date}
                        hourRange={getTimelineArray().length}
                      />
                    ))}
                  {!!(events.length && allDates.length) &&
                    events
                      .filter((event) =>
                        event.technicians.includes(employee.id)
                      )
                      .map((event) => {
                        let left: string | number = 0;
                        let right: string | number = 0;

                        if (
                          !(
                            moment(date).hour(+allDates[0] - 1) >
                            moment(event.start)
                          ) &&
                          currentView === "day"
                        ) {
                          const minutesDifference = getDifferenceDate(
                            new Date(allDates[0]),
                            event.start
                          ).minutes;

                          const minutesWidth = 100 / allDates.length / 60;
                          left = `${+minutesDifference * minutesWidth}%`;
                        }
                        if (currentView === "week") {
                          const hourDifference = getDifferenceDate(
                            new Date(allDates[0]),
                            event.start
                          ).hours;

                          const hourWidth = 100 / allDates.length / 24;
                          left = `${hourDifference * hourWidth}%`;
                        }
                        if (currentView === "month") {
                          const daysDifference = getDifferenceDate(
                            new Date(allDates[0]),
                            event.start
                          ).days;

                          const hourWidth = 100 / allDates.length;
                          left = `${daysDifference * hourWidth}%`;
                        }

                        if (currentView === "day") {
                          const minutesDifference =
                            getDifferenceDate(
                              new Date(allDates[allDates.length - 1]),
                              event.end
                            ).minutes + 60;

                          const minutesWidth = 100 / allDates.length / 60;
                          right = `${minutesDifference * minutesWidth}%`;
                        }
                        if (currentView === "week") {
                          const hourDifference =
                            getDifferenceDate(
                              new Date(allDates[allDates.length - 1]),
                              event.end
                            ).hours + 24;

                          const hourWidth = 100 / allDates.length / 24;
                          right = `${hourDifference * hourWidth}%`;
                        }
                        if (currentView === "month") {
                          const daysDifference = getDifferenceDate(
                            new Date(allDates[allDates.length - 1]),
                            event.end
                          ).days;

                          const hourWidth = 100 / allDates.length;

                          right = `${daysDifference * hourWidth}%`;
                        }
                        return (
                          <TimelineEvent
                            key={event.id}
                            isScheduled={event.isScheduled}
                            right={right}
                            left={left}
                            onClick={() => {
                              history.push(
                                routes.workOrder.UpdateWorkOrder.replace(
                                  ":id",
                                  String(event.job.work_order)
                                ) + `?job=${String(event.job.id)}`
                              );
                            }}
                          >
                            <ScheduledIcon />
                            <Msg fontSize="13px" color="#fff">
                              {`${event?.job?.vessel?.name} - ${event?.job?.title} - ${event?.job?.order_number}`}
                            </Msg>
                          </TimelineEvent>
                        );
                      })}
                </TimelineRowContent>
              </TimelineRow>
            ))}
        </ContentTimeLineBlock>
      </MainTimelineWrapper>
      {isScheduler && timeConflictChecking() && (
        <Msg color="#FB4D4F">You have time conflict</Msg>
      )}
    </>
  );
};

export default Timeline;
