import { Spin } from "antd";
import { memo } from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { ICustomer } from "../../../api/types"
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { InfoItem, InfoTitle } from "./styled";

type TPaymentMethodProps = {
    customer: ICustomer | null;
    loading: boolean
}


const PaymentMethod: React.FC<TPaymentMethodProps> = ({customer, loading}) => {
    const isXs = useBreakpoint(down('xs'))

    const getCardBrand = (brand: string) => {
        switch (brand) {
            case 'amex':
                return 'American Express';
            case 'cartes_bancaires':
                return 'Cartes Bancaries';
            case 'diners_club':
                return 'Diners Club';
            case 'discover':
                return 'Discover';
            case 'jcb':
                return 'JCB'
            case 'mastercard':
                return 'MasterCard';
            case 'visa':
                return 'Visa';
            case 'unionpay':
                return 'UnionPay';
            default:
                return brand;
        }
    }

    const formatExpireDate = (exp_year: number, exp_month: number) => {
        if (!exp_year) {
            return 'Unknown expired date'
        }
        const last_two = exp_year.toString().slice(-2);
        return `${exp_month}/${last_two}`;
    }

    const renderContent = () => {
        if (loading) {
            return <Spin style={{width: "100%", paddingTop: "16px"}}/>
        }

        if (!customer?.card_details?.details?.card) {
            return ( 
                <Msg marginTop="8px" >
                    No payment method found
                </Msg>
            );
        }

        const { 
            card: { 
                brand, 
                last4, 
                exp_month, 
                exp_year 
            } 
        } = customer.card_details.details;

        return (
            <Flex marginTop="8px" flexDirection="column">
                <Msg color="#494949" fontWeight={700} fontSize="13px">
                    Payment method stored on file:
                </Msg>
                <InfoItem marginTop="4px" style={{padding: "12px"}}>
                    <Msg color="#494949" fontSize="15px" fontWeight={700}>
                        {getCardBrand(brand)}
                    </Msg>
                    <Msg color="#0496FF" fontSize="15px" fontWeight={700}>
                        Last 4 digits: {last4}
                    </Msg>
                    <Msg color="#0496FF" fontSize="15px" fontWeight={700}>
                        Exp: {formatExpireDate(exp_year, exp_month)}
                    </Msg>
                </InfoItem>
            </Flex>
        );
    }

    return (
        <InfoItem marginBottom={isXs ? "16px" : "0"}>
            <InfoTitle>Payment METHOD</InfoTitle>
            {renderContent()}
        </InfoItem>
    );
}

export default memo(PaymentMethod);