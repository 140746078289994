import { Avatar, Button } from "antd";
import styled from "styled-components";
import Flex from "../../../noui/Flex";
import { RightOutlined } from "@ant-design/icons";
import { IUser } from "../../../api/types";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";

const TimelineEmployeePanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    margin-right: 1%;
    border: none;
  }
`;

const EmployeeText = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

type TTimelineEmployeeProps = {
  employee: IUser;
  time: number | undefined;
  isScheduler?: boolean;
};

const TimelineEmployee = ({
  employee,
  time,
  isScheduler,
}: TTimelineEmployeeProps) => {
  const isLg = useBreakpoint(down("lg"));
  return (
    <TimelineEmployeePanel>
      <Flex
        flexDirection="row"
        marginLeft="10%"
        justifyContent="start"
        alignItems="center"
        width="90%"
      >
        <Avatar size={36} src={employee.avatar} style={{ minWidth: "36px" }}>
          {!employee.avatar &&
            employee.full_name
              .split(" ")
              .map((e) => e[0].toUpperCase())
              .join("")}
        </Avatar>
        {!isLg && (
          <Flex
            flexDirection="column"
            alignItems="start"
            marginLeft="8px"
            style={{ overflow: "hidden", whiteSpace: "nowrap" }}
          >
            <EmployeeText>{employee.full_name}</EmployeeText>
            {!isScheduler && (
              <EmployeeText>{`${Math.floor((time || 0) / 60)}h ${Math.floor(
                (time || 0) % 60
              )}m`}</EmployeeText>
            )}
          </Flex>
        )}
        {!isLg && (
          <Button
            icon={<RightOutlined />}
            style={{ minWidth: "24px", width: "24px", marginLeft: "auto" }}
          />
        )}
      </Flex>
    </TimelineEmployeePanel>
  );
};

export default TimelineEmployee;
