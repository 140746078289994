import React, { useEffect, useState } from "react"
import { Modal, Checkbox } from "antd"
import styled from "styled-components"
import { TArgCallback } from "../../../types"
import { useDispatch, useSelector } from "react-redux"
import { getPdfCheckboxes } from "../../../config/reducers/pdfCheckboxes/selectors"
import { setPdfCheckboxes } from "../../../config/reducers/pdfCheckboxes/actions"

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 20px 0 0 0;

      h3 {
         text-transform: uppercase;
         padding: 0 15px;
         font-weight: 900;
         font-size: 15px;
      }
      p {
         padding: 0 15px;
         font-size: 18px;
      }
   }
   .ant-modal-footer {
      padding: 15px 15px 15px 20px;
   }
`
const Group = styled.div`
   label {
      display: flex;
      padding: 15px 15px;
      border-top: 1px solid #ededed;

      span {
         font-size: 13px;
      }
      .ant-checkbox + span {
         padding-right: 8px;
         padding-left: 15px;
      }
   }
`

type PrintBlocks = {
   woPricing: boolean
   woPhotos: boolean
   vesselPhoto: boolean
   beforeAfterPhotos: boolean
   checkAll: boolean
}

type TProps = {
   setPrintModal: TArgCallback<any>
   showPrintModal: boolean
}

const ModalPrintCheckboxes: React.FC<TProps> = ({ setPrintModal, showPrintModal }) => {
   const dispatch = useDispatch()
   const dataCheckboxes = useSelector(getPdfCheckboxes)
   const [checkboxes, setCheckBoxes] = useState<any>({
      woPricing: true,
      woPhotos: false,
      vesselPhoto: true,
      beforeAfterPhotos: true,
      checkAll: false,
   })

   useEffect(() => {
      const checkboxesValue = localStorage.getItem("pdfCheckboxes")
      if (checkboxesValue && dataCheckboxes) {
         setCheckBoxes(dataCheckboxes)
      } else {
         dispatch(setPdfCheckboxes(checkboxes))
      }
   }, [])

   const onCheckAllChange = () => {
      setCheckBoxes({
         woPricing: !checkboxes.checkAll,
         woPhotos: !checkboxes.checkAll,
         vesselPhoto: !checkboxes.checkAll,
         beforeAfterPhotos: !checkboxes.beforeAfterPhotos,
         checkAll: !checkboxes.checkAll,
      })
   }

   const onCheckChange = (e: any) => {
      const { name, checked } = e.target
      setCheckBoxes((prev: any) => {
         return {
            ...prev,
            [name]: checked,
         }
      })
   }

   useEffect(() => {
      if (checkboxes.woPricing && checkboxes.woPhotos && checkboxes.vesselPhoto) {
         setCheckBoxes((prev: any) => {
            return {
               ...prev,
               beforeAfterPhotos: true,
               checkAll: true,
            }
         })
      } else {
         setCheckBoxes((prev: any) => {
            return {
               ...prev,
               beforeAfterPhotos: false,
               checkAll: false,
            }
         })
      }
   }, [checkboxes.woPricing, checkboxes.woPhotos, checkboxes.vesselPhoto])

   const handleSave = () => {
      dispatch(setPdfCheckboxes(checkboxes))
      setPrintModal(false)
   }

   return (
      <StyledModal
         destroyOnClose
         centered
         visible={showPrintModal}
         onOk={handleSave}
         onCancel={() => setPrintModal(false)}
         okText="Manage"
      >
         <h3>MANAGE ATTACHed Receipt</h3>
         <p>Select the information that you would like to include in your attachment</p>
         <Group>
            <Checkbox onChange={onCheckChange} checked={checkboxes.woPricing} name="woPricing">
               Work Order Pricing
            </Checkbox>
            <Checkbox onChange={onCheckChange} checked={checkboxes.woPhotos} name="woPhotos">
               Work Order Photos and Notes
            </Checkbox>
            <Checkbox onChange={onCheckChange} checked={checkboxes.vesselPhoto} name="vesselPhoto">
               Vessel Profile Photo
            </Checkbox>
            <Checkbox onChange={onCheckAllChange} checked={checkboxes.checkAll} name="checkAll">
               Select all
            </Checkbox>
         </Group>
      </StyledModal>
   )
}
export default ModalPrintCheckboxes
