import { forwardRef, useEffect, useMemo, useState } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { formatToUSD, renderAddress } from "../../../utils/utils"
import { servicesUnitTypes, vesselTypes } from "../../../constants"
import { IUser, EJobProgress, IJobService, IJobMaterial } from "../../../api/types"
import Flex from "../../../noui/Flex"
import { Api } from "../../../api/api"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import Photos from "./Photos"

import logo from "../../../assets/images/dockworks_logo.png"
import parse from "html-react-parser";

const Wrapper = styled.div`
   background: #fff;
   table {
      width: 100%;
   }
   @page {
      margin: 30px 30px 2cm 30px;
   }

   @media all {
      .pagebreak {
         display: none;
      }
   }

   @media print {
      .pagebreak {
         page-break-before: always;
      }
      .noBreak {
         page-break-inside: avoid;
      }
      .topHeader {
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         height: 155px;
         overflow: hidden;
      }
   }
`
const Header = styled(Flex)`
   padding: 20px 0;

   p {
      font-size: 12px;
      margin-bottom: 0;
      span {
         font-weight: bold;
      }
   }

   h3 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 5px;
   }
   h4 {
      font-weight: 900;
      font-size: 13px;
      margin-bottom: 3px;
   }
   div {
      // width: 100%;
   }
   img {
      max-width: 150px;
      max-height: 90px;
      width: 100%;
      height: auto;
      object-fit: contain;
   }
`
const Info = styled(Flex)`
   gap: 30px;
   .infoBlock {
      width: 100%;
   }
   h4 {
      font-weight: bold;
      font-size: 13px;
      text-align: center;
   }
`
const HeaderInfo = styled.div`
   margin-left: 25px;
`
const WOInfo = styled(Flex)`
   width: 100%;
   padding: 7px 15px;
   color: #fff;
   background: #0f497b;
   h3 {
      font-weight: bold;
      font-size: 13px;
      color: #fff;
      margin: 0;
   }
   p {
      font-weight: 600;
      font-size: 12px;
      margin: 0;
   }
`
const WOTitle = styled(Flex)`
   width: 100%;
   padding: 15px;
   h3 {
      font-weight: 900;
      font-size: 13px;
      color: #202020;
      margin: 0;
   }
   p {
      font-weight: 900;
      font-size: 12px;
      margin: 0;
      span {
         color: #8b8b8b;
         font-weight: normal;
      }
   }
`
const EmptyHeader = styled.div`
   width: 100%;
   height: 130px;
   margin-bottom: 20px;
`
const Details = styled.div`
   margin: 24px 0;
   font-size: 12px;
   h4 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      text-align: left;
      text-decoration: underline;
   }
   ul {
      margin: 0 0 15px 0;
      padding-left: 25px;
      li {
         font-weight: bold;
         span {
            font-weight: normal;
         }
      }
   }
   h5 {
      color: #8b8b8b;
      font-weight: normal;
      font-size: 12px;
   }
`
const Customer = styled.div`
   width: 100%;

   min-width: 300px;
`
const CustomerInfo = styled(Flex)`
   width: 100%;
   justify-content: space-between;
   font-size: 12px;
   > div:first-child {
      width: 130px;
   }
   .title {
      font-weight: bold;
   }
   &.leftAligned {
      justify-content: flex-start;
      > div:first-child {
         width: 130px;
      }
   }
`
const Notes = styled.div`
   border: 1px solid #ededed;
   font-size: 12px;
   margin: 20px 0;
   h4 {
      background: #ededed;
      padding: 10px;
      font-weight: bold;
      font-size: 11px;
      margin: 0;
   }
   div {
      min-height: 60px;
      padding: 10px;
      background: #fbfbff;
   }
`
const JobTitle = styled(Flex)`
   margin-top: 20px;
   padding: 10px;
   border-bottom: 1px solid #ededed;
   h3 {
      font-weight: 900;
      font-size: 15px;
      text-transform: uppercase;
      margin: 0;
   }
   p {
      color: #494949;
      font-size: 13px;
      margin: 0;
   }
`
const JobInfo = styled(Flex)`
   margin-bottom: 20px;
   padding: 10px;
   font-size: 13px;
   span {
      font-weight: bold;
   }
`
const JobSummary = styled.div`
   h4 {
      font-weight: bold;
      font-size: 13px;
   }

   p {
      font-size: 12px;
      margin-bottom: 5px;
      span {
         font-weight: bold;
      }
   }
`
const Payments = styled.div`
   padding: 10px;
   border: 1px solid #ededed;
   margin-top: 25px;
`
const PaymentStatus = styled.div`
   border: 1px solid #ededed;
   margin-top: 25px;
   h3 {
      background: #0f497b;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
      margin: 0;
      padding: 7px;
   }
   .paymentBlock {
      width: 50%;
      padding: 10px;
      p {
         font-size: 12px;
         margin-bottom: 10px;
         
      }
   }
   .divider {
      width: 100%;
      height: 1px;
      background: #ededed;
      margin: 5px 0;
   }
`
const VesselHeader = styled.div`
   padding: 10px;
   background: #ededed;
`
const VesselBottom = styled.div`
   padding: 10px;
`
const VesselImg = styled.div`
   width: 100%;
   height: 135px;
   overflow: hidden;
   border-radius: 4px;
   margin-top: 10px;
   img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
   }
`
const Total = styled.div`
   text-align: right;
   h3 {
      font-weight: bold;
      font-size: 15px;
   }

   p {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
      color: #494949;
   }
`
const Table = styled.div`
   margin: 10px 0;
   box-sizing: border-box;
`
const TableItem = styled.div`
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
`
const Services = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 3fr 1fr 1.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 1fr 1.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Materials = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 4fr 1fr 1.5fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 2.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .description {
      padding: 8px;
      font-size: 12px;
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Technician = styled.div`
   background: #fbfbff;
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
   padding-bottom: 4px;
   & .grid {
      display: grid;
      grid-template-columns: 3fr 2fr 1.5fr 1fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;

      > div {
         padding: 4px 8px;
      }
      &.header {
         text-decoration: underline;
         background: #fbfbff;
         font-weight: normal;
      }
   }
`
const Scheduler = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 5.5fr 1.75fr 1.75fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`

const Summary = styled(Flex)`
   justify-content: space-between;
   align-items: flex-start;
   .disclaimers {
      margin-right: 10px;
      h3 {
         text-decoration: underline;
      }
   }
   .summary {
      min-width: 220px;
      padding: 4px 10px;
      border: 1px solid #ededed;
      background: #fbfbff;
      div {
         padding: 3px 0;
      }
   }

   //float: right;
   font-size: 12px;

   & .total {
      font-weight: bold;
      border-top: 1px solid #ededed;
   }
`

type Props = {
   workOrder: any
   job: any
   technicianRole: boolean
   woPricing: boolean
   woPhotos: boolean
   vesselPhoto: boolean
   isInvoice: boolean
}
export type Ref = HTMLDivElement

const PrintJob = forwardRef<Ref, Props>(
   ({ workOrder, job, vesselPhoto, woPhotos, woPricing, technicianRole, isInvoice }, ref) => {
      const [options, setOptions] = useState<IUser[]>([])
      const [vesselImg, setVesselImg] = useState<any>(null)
      const userData = useSelector(getCurrentUser)
      const invoiceFooter = userData?.company?.invoice_settings?.invoice_footer as string
      const estimateFooter = userData?.company?.invoice_settings?.estimate_footer as string

      const isRecurring = useMemo(() => {
         return workOrder?.is_recurring ?? false;
      }, [workOrder])

      const services = useMemo(() => {
         if (!isRecurring) {
            return job?.services?.length ? job.services : [];
         }
         return workOrder?.services?.length ? workOrder.services : [];
      }, [workOrder, job, isRecurring])

      const materials = useMemo(() => {
         if (!isRecurring) {
            return job?.materials?.length ? job.materials : [];
         }
         return workOrder?.materials?.length ? workOrder.materials : [];
      }, [workOrder, job, isRecurring])

      const totalServices = useMemo(() => {
         return services.reduce((acc: number, s: IJobService) => acc + (s.qty * s.unit_price), 0)
      }, [services])

      const totalMaterials = useMemo(() => {
         return materials.reduce((acc: number, m: IJobMaterial) => acc + (m.qty * m.unit_price), 0)
      }, [services])

      useEffect(() => {
         Api.user.getAll({}).then(({ data: d }) => {
            const results = d as IUser[]
            setOptions(results)
         })
      }, [])

      useEffect(() => {
         if (workOrder && workOrder.vessel) {
            Api.vessels.get(workOrder?.vessel.id).then(({ data }) => {
               setVesselImg(data.image)
            })
         }
      }, [workOrder])

      const formatPrice = (price: number | undefined) =>
         price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

      return (
         <Wrapper ref={ref}>
            <Header alignItems="center" className="topHeader">
               <Flex justifyContent="center">
                  <div style={{ width: "150px", height: "90px" }}>
                     <img src={userData?.company?.logo ? userData.company.logo : logo} alt="logo" />
                  </div>
               </Flex>
               <HeaderInfo>
                  <Flex>
                     <Flex flexDirection="column">
                        <h3>COMPANY</h3>
                        <h4>{userData?.company?.name}</h4>
                        <p>
                           {userData?.company?.address ? renderAddress(userData?.company?.address) : "No data location"}
                        </p>

                        <p>{userData?.company?.phone}</p>
                        <p style={{ color: "#0496FF" }}>{userData?.company?.email}</p>
                     </Flex>

                     <Flex alignItems="flex-start" flexDirection="column" marginLeft="55px">
                        <h3>BILLING CONTACT</h3>
                        <h4>{workOrder?.customer?.full_name}</h4>
                        <p> {workOrder?.customer?.address && renderAddress(workOrder?.customer?.address)}</p>
                        <p>
                           {workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")
                              ?.phone || "No data"}
                        </p>
                        <p style={{ color: "#0496FF" }}>{workOrder?.customer?.email}</p>
                     </Flex>
                  </Flex>
               </HeaderInfo>
            </Header>
            <table>
               <thead>
                  <tr>
                     <th>
                        <EmptyHeader />
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        <div className="content">
                           <WOInfo alignItems="center" justifyContent="space-between">
                              <h3>JOB ESTIMATE</h3>
                              <p>Sent: {moment(workOrder?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p>
                           </WOInfo>
                           <WOTitle alignItems="center" justifyContent="space-between">
                              <h3>{workOrder?.title}</h3>
                              <p>
                                 <span>Work order:</span> #{workOrder?.order_number}
                              </p>
                           </WOTitle>
                           <Info justifyContent="normal">
                              <div className="infoBlock">
                                 <Customer>
                                    <CustomerInfo className="leftAligned">
                                       <div>Service Writer: </div>
                                       <div className="title">{workOrder?.created_by?.full_name}</div>
                                    </CustomerInfo>
                                    <CustomerInfo className="leftAligned">
                                       <div>Created on: </div>
                                       <div className="title">
                                          {workOrder?.created
                                             ? moment(workOrder.created).format("ddd, M/D/YYYY h:mm A")
                                             : "No data"}
                                       </div>
                                    </CustomerInfo>
                                 </Customer>
                                 <Details>
                                    <h4>Project Details</h4>
                                    <div>{workOrder?.notes || "No notes provided"}</div>
                                 </Details>
                                 <Details>
                                    <h4>SCOPE OF WORK</h4>
                                    <div>
                                       <ul>
                                          <li>
                                             {job.title} <span>({job.order_number})</span>
                                          </li>
                                       </ul>
                                    </div>
                                    <h5>(Full project summary listed below)</h5>
                                 </Details>
                                 <Customer>
                                    <CustomerInfo className="leftAligned">
                                       <div>Due Date: </div>
                                       <div className="title">
                                          {moment(workOrder?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo className="leftAligned">
                                       <div>Est. Duration: </div>
                                       <div className="title">{workOrder?.estimated_duration ?? "TBD"}</div>
                                    </CustomerInfo>
                                 </Customer>
                              </div>
                              <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
                                 <VesselHeader>
                                    <CustomerInfo>
                                       <div>Vessel:</div>
                                       <div className="title" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                          {workOrder?.vessel?.name}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Hull ID:</div>
                                       <div className="title">{workOrder?.vessel?.hin}</div>
                                    </CustomerInfo>
                                 </VesselHeader>
                                 <VesselBottom>
                                    <CustomerInfo>
                                       <div>Vessel Type:</div>
                                       <div className="title">
                                          {vesselTypes.find((v) => v.value === workOrder?.vessel?.vessel_type)?.label ||
                                             "No data"}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Location:</div>
                                       <div className="title">{workOrder?.vessel?.location_name}</div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Address:</div>
                                       <div className="title">
                                          {workOrder?.vessel?.address
                                             ? renderAddress(workOrder?.vessel?.address)
                                             : "No data location"}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Slip #:</div>
                                       <div className="title">{workOrder?.vessel?.slip_number}</div>
                                    </CustomerInfo>
                                    {vesselPhoto && vesselImg && (
                                       <VesselImg>{vesselImg && <img src={vesselImg} alt="vessel" />}</VesselImg>
                                    )}
                                 </VesselBottom>
                              </div>
                           </Info>

                           {job && (
                              <div key={job?.id}>
                                 <div className="noBreak">
                                    <JobTitle justifyContent="space-between">
                                       <h3>{job.title}</h3>
                                       <p>(Job {job.order_number})</p>
                                    </JobTitle>
                                    <JobInfo alignItems="center" justifyContent="space-between">
                                       <div>
                                          Start Date & Time:{" "}
                                          <span>{moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}</span>
                                       </div>
                                       <div>
                                          Estimated Duration: <span>{job?.estimated_duration ?? "TBD"}</span>
                                       </div>
                                    </JobInfo>
                                 </div>
                                 <div className="noBreak">
                                    <Table>
                                       {services.length > 0 && (
                                          <Services className="noBreak">
                                             <div
                                                className={`grid header ${technicianRole || !woPricing ? "tech" : ""}`}
                                             >
                                                <div className="name">LABOR</div>
                                                <div className="desc">Description</div>
                                                <div className="qty">Qty</div>
                                                {woPricing && !technicianRole && <div className="rate">Rate</div>}

                                                <div className="type">Type</div>
                                                {woPricing && !technicianRole && <div className="total">Total</div>}
                                             </div>

                                             {services.map((item: any) => {
                                                const type = servicesUnitTypes.find((s) => s.value === item.unit_type)
                                                return (
                                                   <TableItem key={item.id}>
                                                      <div
                                                         className={`grid ${
                                                            technicianRole || !woPricing ? "tech" : ""
                                                         }`}
                                                      >
                                                         <div className="name">{item.name}</div>
                                                         <div className="desc">{item.description}</div>
                                                         <div className="qty">{item.qty.toFixed(2)}</div>
                                                         {woPricing && !technicianRole && (
                                                            <div className="rate">{formatToUSD(item.unit_price)}</div>
                                                         )}

                                                         <div className="type">{type && type.label}</div>
                                                         {woPricing && !technicianRole && (
                                                            <div className="total" style={{ fontWeight: "bold" }}>
                                                               {formatToUSD(item.unit_price * item.qty)}
                                                            </div>
                                                         )}
                                                      </div>
                                                   </TableItem>
                                                )
                                             })}
                                             {job?.technicians_data && job.technicians_data.length > 0 && (
                                                <Technician>
                                                   <div className={`grid header`}>
                                                      <div />
                                                      <div>Technician(s)</div>
                                                      <div>Estimated Hours</div>
                                                      <div />
                                                      <div />
                                                      <div />
                                                   </div>

                                                   {job.technicians_data.map((item: any) => {
                                                      const technician = options.find(
                                                         (t) => Number(t.id) === item.technician
                                                      )
                                                      return (
                                                         <div key={item.id} className={`grid`}>
                                                            <div />
                                                            <div>{technician && technician.full_name}</div>
                                                            <div>{item.estimated_hours}</div>
                                                            <div />
                                                            <div />
                                                            <div />
                                                         </div>
                                                      )
                                                   })}
                                                </Technician>
                                             )}
                                          </Services>
                                       )}
                                    </Table>
                                 </div>
                                 <div className="noBreak">
                                    <Table>
                                       {materials.length > 0 && (
                                          <Materials className="noBreak">
                                             <div
                                                className={`grid header ${technicianRole || !woPricing ? "tech" : ""}`}
                                             >
                                                <div className="name">MATERIALS</div>

                                                <div className="desc">Description</div>
                                                <div className="qty">Qty</div>
                                                {woPricing && !technicianRole && (
                                                   <>
                                                      <div className="price">Unit price</div>
                                                      <div className="total">Total</div>
                                                   </>
                                                )}
                                             </div>

                                             {materials.map((item: any) => {
                                                return (
                                                   <TableItem key={item.id}>
                                                      <div
                                                         className={`grid ${
                                                            technicianRole || !woPricing ? "tech" : ""
                                                         }`}
                                                      >
                                                         <div className="name">{item.name}</div>

                                                         <div>{item.description}</div>
                                                         <div className="qty">{item.qty}</div>
                                                         {woPricing && !technicianRole && (
                                                            <>
                                                               <div className="price">{formatToUSD(item.unit_price)}</div>
                                                               <div className="total" style={{ fontWeight: "bold" }}>
                                                                  {formatToUSD(item.unit_price * item.qty)}
                                                               </div>
                                                            </>
                                                         )}
                                                      </div>
                                                   </TableItem>
                                                )
                                             })}
                                          </Materials>
                                       )}
                                    </Table>
                                 </div>
                                 {/* <Table>
                                    {job?.technicians_data && job.technicians_data.length > 0 && (
                                       <Technician className="noBreak">
                                          <div className={`grid header ${technicianRole ? "tech" : ""}`}>
                                             <div>TECHNICIAN(s)</div>
                                             {!technicianRole && woPricing && <div>Rate</div>}

                                             <div>Estimated Hours</div>
                                             {!technicianRole && woPricing && <div>Total</div>}
                                          </div>

                                          {job.technicians_data.map((item: any) => {
                                             const technician = options.find((t) => Number(t.id) === item.technician)
                                             return (
                                                <TableItem key={item.id}>
                                                   <div className={`grid  ${technicianRole ? "tech" : ""}`}>
                                                      <div>{technician && technician.full_name}</div>
                                                      {!technicianRole && woPricing && <div>{item.rate}</div>}

                                                      <div>{item.estimated_hours}</div>
                                                      {!technicianRole && woPricing && (
                                                         <div style={{ fontWeight: "bold" }}>
                                                            ${calculateRows(item)}
                                                         </div>
                                                      )}
                                                   </div>
                                                </TableItem>
                                             )
                                          })}
                                       </Technician>
                                    )}
                                    {job?.schedules && job.schedules.length > 0 && (
                                       <Scheduler className="noBreak">
                                          <div className="grid header">
                                             <div>SCHEDULER</div>
                                             <div>Start date & time</div>
                                             <div>End date & time</div>
                                          </div>

                                          {job.schedules.map((item: any) => {
                                             return (
                                                <TableItem key={item.id}>
                                                   <div className="grid">
                                                      <div></div>
                                                      <div>{moment(item.start).format("M/DD/YY LT")}</div>
                                                      <div>{moment(item.end).format("M/DD/YY LT")}</div>
                                                   </div>
                                                </TableItem>
                                             )
                                          })}
                                       </Scheduler>
                                    )}
                                 </Table> */}
                                 {woPricing && !technicianRole && (
                                    <div className="noBreak">
                                       <Summary>
                                          <div className="disclaimers">
                                             <h3>DISCLAIMERS</h3>
                                             {isInvoice ? (
                                                <p>{parse(invoiceFooter)}</p>
                                             ) : (
                                                <p>{parse(estimateFooter)}</p>
                                             )}
                                          </div>
                                          <div className="summary">
                                             <Flex justifyContent="space-between">
                                                <div>Labor</div>
                                                <div>{formatToUSD(totalServices)}</div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>Materials</div>
                                                <div>{formatToUSD(totalMaterials)}</div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>Subtotal</div>
                                                <div>
                                                   {formatToUSD(totalServices + totalMaterials)}
                                                </div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>Taxes</div>
                                                <div>{formatToUSD(isRecurring ? workOrder.tax : job.tax)}</div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>EPA fees</div>
                                                <div>{formatToUSD(isRecurring ? workOrder.epa : job.epa)}</div>
                                             </Flex>
                                             <Flex className="total" justifyContent="space-between">
                                                <div>Job total</div>
                                                <div>{formatToUSD(isRecurring ? workOrder.total : job?.total)}</div>
                                             </Flex>
                                          </div>
                                       </Summary>
                                    </div>
                                 )}
                                 {woPhotos && <Photos job={job} />}
                              </div>
                           )}
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </Wrapper>
      )
   }
)

export default PrintJob
