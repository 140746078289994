import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Dropdown, Modal, Pagination, Spin, Table } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getConstants } from "../../config/reducers/user/selectors";
import routes from "../../routes/routes";
import { ColumnsType } from "antd/lib/table";
import { IJob } from "../../api/types";
import { filterColumns, getFullName, getOrdering } from "../../utils/utils";
import {
  ClockCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { EditIcon, MWOMessage, MWOSchedule } from "../../assets/icons";
import { Api } from "../../api/api";
import { usePagination } from "../../utils/hooks";
import styled from "styled-components";
import { Columns, PALETTE } from "../../constants";
import { getColumns } from "../../config/reducers/columns/selectors";
import { getJobFilters } from "../../config/reducers/filters/selectors";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import Flex from "../../noui/Flex";
import { TableCard } from "../../components/TableCard";
import { Msg } from "../../ui/Text";
import { useTechnicianRole } from "../../utils/hooks";
import { TArgCallback } from "../../types";
import { EmptyButton } from "../../ui/Button";
import { StyledMenu } from "../../containers/settings/Users";
import { MenuItem } from "../../containers/business/Jobs";

const Title = styled.span`
  color: ${PALETTE.Secondary.Medium};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const ModalContainer = styled.div`
  padding: 10px;
  width: 60%;
`;
const StyledButton = styled(Button)`
  border: 1px solid transparent;
  background: none;
  svg {
    vertical-align: middle;
  }
  &:hover {
    background: #fff;
    border: 1px solid #0496ff;
  }
`;
type TProps = {
  getSorter: TArgCallback<any>;
};
export const StatusListView: React.FC<TProps> = ({ getSorter }) => {
  const {
    data,
    requestPagination,
    onPromise,
    pagination,
    sorter,
    loading,
    onChange,
  } = usePagination<IJob>();
  const history = useHistory();
  const configs = useSelector(getConstants);
  const filtered = useSelector(getColumns(Columns.JobStatus));
  const jobFilters = useSelector(getJobFilters);
  const isLg = useBreakpoint(down("lg"));
  const technicianRole = useTechnicianRole();
  const [clockInModalIsVisible, setClockInModalIsVisible] =
    useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<number | null>(null);
  const [selected, setSelected] = useState<IJob | null>(null);
  const handleEdit = useCallback(
    (id, workOrder) => () => {
      history.push(
        routes.workOrder.UpdateWorkOrder.replace(":id", workOrder) +
          `?job=${id}`
      );
    },
    [history]
  );

  // !!!REMEMBER!!! IF CHANGED, TO CHANGE COLUMNS in utils as well
  const columns: ColumnsType<IJob> = useMemo(
    () => [
      {
        title: "#",
        width: "120px",
        dataIndex: "order_number",
        key: "order_number",
        sorter: true,
        render: (_, record) => record.order_number,
      },
      {
        title: "Job Name",
        dataIndex: "title",
        key: "title",
        sorter: true,
        ellipsis: true,
        render: (_, record) => (
          <Title onClick={handleEdit(record.id, record.work_order)}>
            {record.title}
          </Title>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer_data",
        key: "customer",
        sorter: true,
        render: (_, record) => getFullName(record.customer_data),
      },
      {
        title: "Vessel",
        dataIndex: "vessel",
        key: "vessel",
        sorter: true,
        render: (_, record) => record?.vessel_data?.name,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: true,
        render: (_, record) => `$${record.total.toFixed(2)}`,
      },
      {
        title: "Status",
        dataIndex: "status",
        //sorter: true,
        key: "status",
        sorter: true,
        render: (_, record) => configs?.progress_steps[record.progress] ?? "",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        fixed: "right",
        render: (_, record) => {
          return (
            <Flex justifyContent="center" style={{ width: "100%" }}>
              <Dropdown
                overlay={() => (
                  <StyledMenu>
                    <Flex flexDirection="column" alignItems="flex-start">
                      <MenuItem
                        onClick={() => {
                          setClockInModalIsVisible(true);
                          setDropdown(null);
                        }}
                      >
                        <Flex alignItems="center">
                          <ClockCircleOutlined
                            style={{ marginLeft: "1.5px" }}
                          />
                          <span>
                            {record.progress === "W" ? "Clock out" : "Clock in"}
                          </span>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          history.push(
                            routes.workOrder.UpdateWorkOrder.replace(
                              ":id",
                              String(record.work_order) +
                                `?job=${record.id}&sentMessage=true`
                            )
                          );

                          setDropdown(null);
                        }}
                      >
                        <Flex alignItems="center">
                          <MWOMessage />
                          <span>Message</span>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          history.push(
                            routes.workOrder.UpdateWorkOrder.replace(
                              ":id",
                              String(record.work_order) +
                                `?job=${record.id}&tab=2`
                            )
                          );
                          setDropdown(null);
                        }}
                      >
                        <Flex alignItems="center">
                          <MWOSchedule />
                          <span>
                            {record.schedules.length > 0
                              ? "Reschedule"
                              : "Schedule"}
                          </span>
                        </Flex>
                      </MenuItem>
                    </Flex>
                  </StyledMenu>
                )}
                placement="bottomCenter"
                arrow
              >
                <EmptyButton
                  onMouseMove={() => {
                    setDropdown(record.id);
                    setSelected(record);
                  }}
                >
                  <EllipsisOutlined
                    style={{ fontSize: "18px", marginTop: "5px" }}
                  />
                </EmptyButton>
              </Dropdown>
            </Flex>
          );
        },
      },
    ],
    [handleEdit, configs]
  );

  useEffect(() => {
    onPromise(
      Api.jobs.getAll({
        ...requestPagination,
        ...getOrdering(sorter),
        ...jobFilters,
      })
    );
  }, [requestPagination, sorter, jobFilters, onPromise]);

  useEffect(() => {
    const sorterValue = getOrdering(sorter);
    getSorter(sorterValue);
  }, [sorter]);

  const techCols = technicianRole
    ? columns.filter((item) => item.title !== "Total")
    : columns;

  return (
    <>
      <Modal
        okText={"Yes"}
        onCancel={() => setClockInModalIsVisible(false)}
        width={350}
        onOk={() => {
          if (selected) {
            history.push(
              routes.workOrder.UpdateWorkOrder.replace(
                ":id",
                String(selected.work_order) + `?job=${selected.id}&tab=4`
              )
            );
          }
        }}
        visible={clockInModalIsVisible}
        centered
      >
        <ModalContainer>
          <Msg fontSize={"15px"} fontWeight={700}>
            Are you sure you want to{" "}
            {selected && selected.progress === "W" ? "clock out" : "clock in"}?
          </Msg>
        </ModalContainer>
      </Modal>
      <Helmet>
        <title>Projects - DockWorks Pro</title>
      </Helmet>
      {!isLg ? (
        <Table
          style={{ marginTop: -10 }}
          className="contained status-table"
          columns={filterColumns(techCols, filtered)}
          dataSource={data}
          loading={loading}
          pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
          onChange={onChange}
        />
      ) : (
        <Spin spinning={loading}>
          <Flex flexWrap="wrap">
            {data.map((d) => (
              <TableCard key={d.id}>
                <Flex flexDirection="row" alignItems="center">
                  <Flex flexDirection="column" flex="1">
                    {filterColumns(techCols, filtered)
                      .filter((c) => c.title !== "Actions")
                      .map((c, i) => (
                        <Flex key={i} justifyContent="space-between">
                          <Msg fontWeight={700}>
                            {c.title === "#" ? "Number" : c.title}
                          </Msg>
                          <Msg style={{ textAlign: "end" }}>
                            {c.render && c.render(d, d, i)}
                          </Msg>
                        </Flex>
                      ))}
                  </Flex>
                  <Button
                    style={{ border: "none" }}
                    onClick={() => {
                      history.push(
                        routes.workOrder.UpdateWorkOrder.replace(
                          ":id",
                          String(d.work_order)
                        ) + `?job=${d.id}`
                      );
                    }}
                  >
                    <RightOutlined />
                  </Button>
                </Flex>
              </TableCard>
            ))}
            {!!pagination?.total && pagination.total > 10 && (
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={(p) => {
                  onChange({ ...pagination, current: p }, {}, {});
                }}
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "20px",
                }}
              />
            )}
          </Flex>
        </Spin>
      )}
    </>
  );
};
