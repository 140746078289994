import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../../api/api";
import { INotification, IPaginatedResponse } from "../../../api/types";

export const getAllNotifications = createAsyncThunk<INotification[]>(
  "notifications/getAll",
  async () => {
    const { data } = await Api.notifications.getAll({ pageSize: 50 });
    return (data as IPaginatedResponse<INotification[]>).results;
  }
);

export const readNotification = createAsyncThunk<number, number>(
  "notifications/read",
  async (id) => {
    await Api.notifications.readNotification(id);
    return id;
  }
);

export const unreadNotification = createAsyncThunk<number, number>(
  "notifications/unread",
  async (id) => {
    await Api.notifications.unreadNotification(id);
    return id;
  }
);

export const removeNotification = createAsyncThunk<number, number>(
  "notifications/remove",
  async (id) => {
    await Api.notifications.removeNotification(id);
    return id;
  }
);

export const addNewNotification = createAction<INotification>(
  "notifications/addNewNotification"
);
