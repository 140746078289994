import React from "react";
import Flex from "../../../noui/Flex";
import styled from "styled-components";
import { PALETTE } from "../../../constants";
import {
  SubscriptionCardTitle,
  SubscriptionCardDefaultText,
  SubscriptionCardPriceText,
} from "../../settings/SubscriptionCard";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Hr from "../../../ui/Hr";
import { ReactComponent as CheckActive } from "../../../assets/images/CheckActive.svg";
import { ReactComponent as CheckDefault } from "../../../assets/images/CheckDefault.svg";
import TrialLabel from "./TrialLabel";

import { Msg } from "../../../ui/Text";
import { useSelector } from "react-redux";
import { getPaymentData } from "../../../config/reducers/payment/paymentSlice";

type TStyledProps = {
  isActive: boolean;
};

const SCCollapse = styled(Flex)`
  margin-top: 16px;
  background: ${(props: TStyledProps) =>
    props.isActive ? "#096DD9" : "#FFFFFF"};
  color: ${(props: TStyledProps) =>
    props.isActive ? "#FFFFFF" : PALETTE.Text};
  padding: 9px 14px 9px 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  flex-direction: column;
  &:last-child {
    margin-bottom: 20px;
  }
  position: relative;
`;

type TProps = {
  title: string;
  monthly_price: number;
  annually_price: number;
  description: string;
  options: string;
  isActive: boolean;
  isOpen: boolean;
  trial_period: number | null;
  setActive: () => void;
  setOpen: () => void;
  isRegister: () => boolean;
  onboarding_fee: number;
};

const SubscriptionCollapse: React.FC<TProps> = ({
  title,
  monthly_price,
  description,
  annually_price,
  onboarding_fee,
  options,
  isActive,
  setActive,
  isOpen,
  trial_period,
  setOpen,
  isRegister,
}) => {
  const { payment_period } = useSelector(getPaymentData);

  return (
    <SCCollapse isActive={isActive} onClick={setActive}>
      {isRegister() && <TrialLabel trial_period={trial_period} />}
      <Flex justifyContent="space-between" width="100%" alignItems="baseline">
        <Flex alignItems="baseline">
          <SubscriptionCardTitle isActive={isActive}>
            {title}
          </SubscriptionCardTitle>
          <Flex alignItems="baseline" marginLeft="24px">
            <SubscriptionCardPriceText
              isActive={isActive}
              fontSize="24px"
              fontWeight="800"
            >
              $
              {payment_period === "M"
                ? monthly_price
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : (annually_price / 12)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </SubscriptionCardPriceText>

            <SubscriptionCardPriceText
              isActive={isActive}
              fontSize="15px"
              fontWeight="400"
            ></SubscriptionCardPriceText>
          </Flex>
        </Flex>
        <Flex onClick={setOpen}>
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </Flex>
      </Flex>
      <SubscriptionCardDefaultText
        isActive={isActive}
        fontSize="11px"
        fontWeight="400"
        width={isRegister() ? "calc(100% - 110px)" : "100%"}
      >
        {description}
      </SubscriptionCardDefaultText>
      {payment_period === "M" && (
        <Msg color={isActive ? "White" : "#FB4D4F"}>
          Onboarding fee $
          {onboarding_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Msg>
      )}

      {isOpen && (
        <Flex flexDirection="column">
          <Hr />
          <Flex flexDirection="row" flexWrap="wrap" marginTop="10px">
            {!!options &&
              options.split("\r\n").map((option, i) => (
                <Flex marginTop="16px" key={i} width="50%">
                  <Flex marginRight="12px">
                    {isActive ? <CheckActive /> : <CheckDefault />}
                  </Flex>
                  <SubscriptionCardDefaultText
                    isActive={isActive}
                    fontSize="15px"
                  >
                    {option}
                  </SubscriptionCardDefaultText>
                </Flex>
              ))}
          </Flex>
        </Flex>
      )}
    </SCCollapse>
  );
};

export default SubscriptionCollapse;
