import React from 'react';
import {Progress, Steps} from "antd";
import classes from "../styles.module.scss";
import {PALETTE} from "../../../constants";
import {CaretRightOutlined, SendOutlined, WalletOutlined} from "@ant-design/icons";
import {StopIcon} from "../../../components/Icons";
import {TCallback} from "../../../types";

const { Step } = Steps;


type TStep = {
  title: string;
  name: string;
  icon: JSX.Element;
}
const steps = [
  {
    title: "Schedule",
    name: "Schedule Job",
    icon: <CaretRightOutlined className={classes.icon} style={{fontSize: 22}} />
  },
  {
    title: "Start",
    name: "Start the Job",
    icon: <CaretRightOutlined className={classes.icon} style={{fontSize: 22}} />,
  },
  {
    title: "Finish",
    name: "Finish the Job",
    icon: <StopIcon className={classes.icon} style={{fontSize: 22}} />,
  },
  {
    title: "Invoice",
    name: "Send Invoice",
    icon: <SendOutlined className={classes.icon} style={{fontSize: 18}} />,
  },
  {
    title: "Payed",
    name: "Complete the Job",
    icon: <WalletOutlined className={classes.icon} style={{fontSize: 18}} />,
  },
];


type TStepProps = {
  step: TStep;
  undo: boolean;
  content: JSX.Element|null;
}
const StepTitle: React.FC<TStepProps> = ({step, undo, content}) => {
  return <div className={classes.stepContent}>
    {undo ? <span className={classes.undo}>Undo</span> : null}
    {content
      ? <span className={classes.iconDates}>{content}</span>
      : step.icon}
    <h4>{step.title}</h4>
  </div>;
}

type TProps = {
  getContent: (n: number) => JSX.Element|null;
  processing: boolean;
  cancelling: boolean;
  activeStep: number;
  onAction: TCallback;
  onUndo: TCallback;
}
const ProgressSteps: React.FC<TProps> = ({activeStep, processing, cancelling, getContent, onAction, onUndo}) => {
  return <Steps current={1}>
    {steps.map((step, idx) => {
        const cl = [classes.step];
        const isFuture = activeStep < idx + 1;
        const isActive = activeStep === idx + 1;
        const isPrev = activeStep - 1 === idx + 1;
        if (isActive && activeStep === 5) {
          cl.push(classes.notPayed);
        }
        if (isFuture) {
          cl.push(classes.future);
        } else if (isActive) {
          cl.push(classes.active);
        }
        return <Step
          key={idx}
          className={cl.join(" ")}
          onClick={!processing
            ? isActive
              ? onAction
              : isPrev
                ? onUndo
                : undefined
            : undefined}
          icon
          status={(isActive && processing) || (isPrev && cancelling)
            ? "wait" : "process"}
          title={<StepTitle step={step} undo={isPrev} content={!(isPrev && cancelling) ? getContent(idx) : null} />}
        />;
      }
    )}
  </Steps>
}


export const ProgressBar: React.FC<TProps> = ({activeStep, processing, getContent, cancelling, onAction, onUndo}) => {
  const format = () => {
    return `${activeStep} of 6`;
  }

  const getNextStep = () => {
    return steps[activeStep - 1].name
  }

  return <div className={classes.progressWrapper}>
    <Progress
      className={classes.progress}
      type="circle"
      percent={(100/6) * activeStep}
      format={format}
      strokeColor={PALETTE.Success} />
    <div className={classes.progressLabels}>
      Next Step: <br/>
      <span style={{color: PALETTE.Success}}>{getNextStep()}</span>
    </div>
    <ProgressSteps
      processing={processing}
      cancelling={cancelling}
      onAction={onAction}
      getContent={getContent}
      activeStep={activeStep}
      onUndo={onUndo} />
  </div>
};