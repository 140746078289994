import React from "react";
import { FormInstance, Divider } from "antd";

import { IJob, IUpdateJob, IWorkOrderWithJobs } from "../../../api/types";

import { TechniciansBlock } from "../components/TechniciansBlock";
import { ScheduleBlock } from "../components/ScheduleBlock";

type TProps = {
  form: FormInstance<IUpdateJob>;
  job: IJob;
  technicianRole: boolean;
  setIsBooked: any;
  workOrder?: IWorkOrderWithJobs | null;
  technicians: string[];
  setIsFormFieldChanged?: (value: boolean) => void;
};

export const DateTimeTab: React.FC<TProps> = ({
  form,
  job,
  technicianRole,
  technicians,
  setIsBooked,
  workOrder,
  setIsFormFieldChanged,
}) => {
  return (
    <div>
      <TechniciansBlock
        job={job}
        form={form}
        technicianRole={technicianRole}
        setIsBooked={setIsBooked}
        setIsFormFieldChanged={setIsFormFieldChanged}
        workOrder={workOrder}
      />
      <Divider />
      <ScheduleBlock
        job={job}
        form={form}
        technicianRole={technicianRole}
        setIsFormFieldChanged={setIsFormFieldChanged}
        technicians={technicians}
        setIsBooked={setIsBooked}
      />
    </div>
  );
};
