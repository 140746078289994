import styled from "styled-components"
import Flex from "../../noui/Flex"
import { PrimaryButton } from "../../ui/Button"
import { Button, Checkbox, Input, Upload, Modal } from "antd"
import { Msg } from "../../ui/Text"
import UploadImg from "../../assets/images/UploadPhotoBig.svg"
import { down } from "styled-breakpoints"

export const CheckboxName = styled(Checkbox)`
   margin-top: 10px;
`

export const CompanyInfoContainer = styled(Flex)`
   width: 100%;
   margin: auto;
   margin-bottom: 36px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
   ${down("xs")} {
      margin-bottom: 16px;
   }
`

export const ContainerTitle = styled(Msg)`
   font-weight: 800;
   font-size: 15;
   color: #484848;
   margin-bottom: 16px;
   display: block;
   text-transform: uppercase;
`

export const UploadWrapper = styled(Button)`
   position: relative;
   display: flex;
   flex-wrap: wrap;
   height: 312px;
   flex-direction: column;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   width: 100%;
   // width: 192px;
   padding: 0;
   text-align: center;
   outline: 0 !important;
   align-items: center;
   justify-content: center;
   &.ant-btn > span {
      display: flex;
   }

   &.ant-row.ant-form-item {
      width: 100%;
   }

   & span {
      display: inline-block !important;
      // white-space: pre-wrap
      // width: 192px;
      // margin-top: 24px;
      text-align: center;
   }

   &:focus {
      outline: 0 !important;
   }
`

export const UploadImage = styled(Input)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 312px;
   //   max-width: 440px;
   //   width: 100%;
   padding: 0px 29px;
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   & input {
      font-weight: 400px;
      font-size: 15px;
      color: #9aa1aa;
      width: 192px;
   }
   & ::value {
      font-weight: 400px;

      font-size: 15px;
      color: #9aa1aa;
   }
   & :focus {
      outline: 0 !important;
   }
   & .ant-input-prefix {
      width: 26px;
      display: inline-block;
      margin-right: 19px;
   }
   &.ant-input-affix-wrapper > input.ant-input {
      width: 192px;
      height: 40px;
   }
`

export const ImageContainer = styled.div`
   width: 100%;
   height: 312px;
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   padding: 5px;
   position: relative;
   line-height: 312px;

   img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
   }
`

export const MainUpload = styled(Upload)`
   width: 100%;
   & button {
      width: 100%;
   }
   text-align: center;
   margin: auto;
   .ant-upload-list {
      display: none;
   }
`

export const UploadtContainer = styled(Flex)`
   width: 100%;
   margin: auto;
   display: block;
   .ant-upload.ant-upload-select.ant-upload-select-text {
      display: block !important;
      width: 100%;
   }
   & .ant-row.ant-form-item.ant-form-item-has-success {
      width: 100%;
      text-align: center;
   }
   text-align: center;
   & .ant-row.ant-form-item {
      width: 100%;
   }
`

export const UploadtContainerAfter = styled(Flex)`
   width: 100%;
   @media (min-width: 992px) {
      max-width: calc(50% - 8px);
   }
`

export const UploadtContainerAfterMsg = styled(Msg)`
   // max-width: 440px;
   width: 312px;
   margin-top: 12px;
   ${down("xs")} {
      font-size: 15px;
      width: 100%;
   }
`

export const AddAttachment = styled(Msg)`
   // max-width: 440px;
   width: 100px;
   border-bottom: 1px dashed #1c99fc;
`

export const StyledTextArea = styled(Input.TextArea)`
   width: 100%;
   height: 272px !important;
   min-height: 74px !important;
`

export const SmallTextArea = styled(Input.TextArea)`
   width: 100%;
   height: 130px !important;
   min-height: 74px !important;
`

export const ImageWrapperFlex = styled(Flex)`
   & div {
      width: 100%;
      text-align: center;
      position: relative;
   }
   & img {
      flex: 0 0 440px;
   }
   & button {
      position: absolute;
      right: 3px;
      color: red;
      &:hover {
         background-color: 0;
         background: 0;
      }
   }
`

export const CloseLogoButton = styled(PrimaryButton)`
   padding: 0;
   background: none;
   color: #ffffff;
   top: 3px;
   right: 7px !important;

   &.ant-btn.cancelButton {
      height: 20px;
      border: none;
   }
`

export const MainInfoContainer = styled(Flex)`
   flex-grow: 1;
   @media (min-width: 992px) {
      max-width: calc(50% - 8px);
   }
   position: relative;
`

export const CompanyInfoWrapper = styled(Flex)`
   border-bottom: 1px solid rgba(0, 0, 0, 0.15);
   flex-wrap: wrap;
   @media (min-width: 992px) {
      gap: 16px;
   }
`

const UploadPhoto = (props) => <img src={UploadImg} alt="Envelope" />
export const UploadtContent = styled(UploadPhoto)`
   display: inline-block;
   width: 26px;
`

export const CancelBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
`

export const SaveBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
   &:disabled,
   &:disabled:hover,
   &:disabled:focus,
   &:disabled:active {
      background: #ace0ff;
      border: 0.7px solid #ace0ff;
      color: #fff;
   }
   @media (max-width: 640px) {
      width: 100%;
      margin-left: 0 !important;
   }
`

export const ResponsiveWrapper = styled(Flex)`
   flex-direction: column;
   @media (min-width: 992px) {
      flex-direction: row;
      gap: 30px;
   }
`
export const SaveBtnWrapper = styled(Flex)`
   @media (max-width: 640px) {
      .ant-form-item {
         width: 100%;
      }
      flex-direction: column;
   }
`

export const PaymentsHeaderText = styled.p`
   font-size: 15px;
   font-weight: 400;
   text-align: center;
   padding: 0px;
   margin: 0px;
`

export const AfterOnBoardingBlockHeader = styled(Msg)`
   text-transform: uppercase;
   font-weight: 800;
   font-size: 18px;
   margin: 0 12px 0 8px;
`

export const PaymentsMainBlock = styled.div`
   display: flex;
   width: calc(100% - 230px);
   margin: 20px 115px 24px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   padding: 24px 88px;
   flex-direction: column;
   align-items: center;
`

export const PaymentsMainBlockHeaderText = styled.p`
   font-weight: 900;
   font-size: 18px;
   line-height: 25px;
   letter-spacing: 0.01em;
   text-transform: uppercase;
   color: #151f2b;
   margin: 0px;
`

export const PaymentsMainBlockPaymentTypes = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 100%;
   margin: 24px 0 36px;
   > img {
      margin-left: 8px;
      &:first-child {
         margin-left: 0px;
      }
   }
`

export const PaymentsMainBlockPaymentSpecs = styled.div`
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   display: flex;
   flex-direction: row;
   padding: 24px 0;
   margin: 16px 0 32px;
`

export const PaymentsMainBlockPaymentSpecsItem = styled.div`
   flex: 1;
   // height: 156px;
   border-left: 2px solid #e9f6ff;
   &:first-child {
      border-left: none;
   }
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
   padding: 20px 24px;
   text-align: center;
`

export const PaymentsMainBlockCheckBlock = styled.div`
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   margin-bottom: 36px;
   width: 100%;
   padding: 26px 28px;
   display: flex;
   flex-direction: column;
`

export const PaymentsMainBlockCheckBlockItem = styled.div`
   display: flex;
   flex-direcyion: row;
   justify-content: start;
   margin-top: 16px;
   &:first-child {
      margin-top: 0px;
   }
`

export const PaymentsMainBlockColumnHeader = styled(Msg)`
   font-weight: 900;
   font-size: 18px;
   line-height: 25px;
   letter-spacing: 0.01em;
   text-transform: uppercase;
   color: #151f2b;
   margin-left: 12px;
`

export const PaymentsMainBlockColumnItem = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 24px;
`

export const PaymentsMainItemDefaultText = styled.span`
   font-size: 15px;
   font-weight: 400;
`

export const PaymentsMainItemBoldText = styled.span`
   font-size: 15px;
   font-weight: 700;
`

export const PaymentsMainItemGrayText = styled.span`
   font-size: 15px;
   font-weight: 400;
   color: #828282;
`

export const GetStartedMobileBlock = styled.div`
   margin: 24px 12px 48px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   display: flex;
   flex-direction: column;
   padding: 24px 12px 48px;
`

export const AfterOnBoardingBlock = styled.div`
   border: 1px solid rgba(0, 0, 0, 0.15);
   box-sizing: border-box;
   border-radius: 4px;
   margin-top: 20px;
   width: 100%;
   max-width: 750px;
   padding: 24px;
`
export const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 0;
   }
`
export const ModalInner = styled.div`
   .modal_header {
      padding: 20px 25px 10px 25px;
   }

   .modal_footer {
      padding: 15px 25px 15px 25px;
      border-top: 1px solid #ddd;
   }
   h3 {
      font-weight: bold;
      max-width: 250px;
   }
   button {
      margin: 5px;
   }
`

export const onBoardingStyles = `

.merchant-wizard-next .content {
    z-index: 1 !important;
}

.merchant-wizard-next {
    font-family: Nunito Sans;
}

div.sidebar >ul >button {
    display: none;
}

div.sidebar >ul >div:not(:last-of-type) >li {
    padding-bottom: 52px;
    border-left: 4px solid #D3EDFF !important;
}

div.sidebar >ul >div >li {
    text-transform: uppercase;
    color: #109CF1;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
}

.merchant-wizard-next div.invalid-feedback {
   display: inline;
   margin-right: 12px;
}

.merchant-wizard-next div.sidebar >ul >div >li.list-group-item.active {
    color: #109CF1 !important;
    z-index: 1;
}

.merchant-wizard-next div.sidebar >ul >div >li.list-group-item.inactive.list-group-item-undefined:not(.disabled) {
    color: #23c770;
}

.merchant-wizard-next div.sidebar >ul >div >li.list-group-item.inactive.list-group-item-undefined  span {
    top: 0;
    left: -22px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    color: white;
    font-size: 24px;
    background-image: none;
    text-indent: 0px;
    font-weight: 900;
}

.merchant-wizard-next div.sidebar >ul >div >li.list-group-item.inactive.disabled {
    color: #B9E3FF;
}

.merchant-wizard-next div.sidebar >ul >div >li.list-group-item.inactive.disabled span {
    top: 0;
    left: -22px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-color: #FFFFFF;
    background-color: #B9E3FF;
    color: white;
    font-size: 24px;
    // background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M7 14.586L17.293 4.293a1 1 0 0 1 1.414 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L7 14.586z'%3E%3C/path%3E%3C/svg%3E")
}

.merchant-wizard-next div.sidebar >ul >div >li.list-group-item.active span {
    top: 0;
    left: -22px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-color: #FFFFFF;
    background-color: #109CF1;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M7 14.586L17.293 4.293a1 1 0 0 1 1.414 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L7 14.586z'%3E%3C/path%3E%3C/svg%3E")
}

div.content {
    padding: 24px !important;
}

h2.heading {
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #151F2B;
    margin-top: 0px;
}

h3.subheading {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #484848;
    background-color: #FFFFFF;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.merchant-wizard-next div.content .form-actions {
    text-align: center;
}

.merchant-wizard-next div.content .form-actions >button{
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
}

.merchant-wizard-next div.content .form-actions >button.pf-button-next {
    background-color: #109CF1 !important;
}

div.content-0 h3.subheading:nth-child(2) {
    margin-bottom: 0px;
}

div.content-0 div.form-group.row:nth-child(3) > label {
    display: none;
}

div.content-0 div.form-group.row:nth-child(3) > div {
    flex: 0 0 100%;
    max-width: 100%;
}

div.content-0 div.form-group.row:nth-child(5){
    align-items: start;
}

div.content-0 div.form-group.row:nth-child(5) >label{
    text-align: left;
    flex: 0 0 20%;
    max-width: 20%;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #484848;
}

div.content-0 div.form-group.row:nth-child(5) >div{
    text-align: left;
    flex: 0 0 80%;
    max-width: 80%;
}

div.content-0 div.form-group.row:nth-child(5) >div >div >div:last-child{
    display: none;
}

div.content-0 div.form-group.row:nth-child(5) >div >ul >li.active {
    background: #109CF1;
}

div.content-0 div.form-group.row:nth-child(5) >div >ul::-webkit-scrollbar{
    width: 10px;
    height: 5px;
}

div.content-0 div.form-group.row:nth-child(5) >div >ul::-webkit-scrollbar-thumb{
    background: #C4C4C4;
    border-radius: 3px;
}

div.content-1 div.form-group.row .col-sm-4.col-form-label {
   max-width: 100%;
   width: 100%;
   white-space: break-spaces;
}

div.content-1 div.container div.form-group.row, div.content-2 div.container div.form-group.row, div.content-3 div.container div.form-group.row {
    flex-direction: column;
    align-items: start;
}

div.content-1 div.container h3.subheading, div.content-2 div.container h3.subheading {
    text-transform: uppercase;
    color: #151F2B;
}

div.content-1 div.container div.form-group.row >label, div.content-2 div.container div.form-group.row >label, div.content-3 div.container div.form-group.row >label {
    text-align: left;
    white-space: nowrap;
    font-weight: 700;
    color: #484848;
}

div.content-1 div.container div.form-group.row >div, div.content-2 div.container div.form-group.row >div, div.content-3 div.container div.form-group.row >div{
    flex: 0 0 100%;
    max-width: 100%;
}

div.content-1 div.container div.form-group.row div.col-sm-8.offset-sm-4, div.content-2 div.container div.form-group.row div.col-sm-8.offset-sm-4, div.content-3 div.container div.form-group.row div.col-sm-8.offset-sm-4 {
    margin-left: 0px;
}

div.content-1 .col-sm-8 .pf-field-group .pf-field-years {
    margin-right: 16px;
}

div.content-1 .form-group.row .col-sm-8 .row.mt-2 {
    align-items: end;
}

div.content-2 div.container button.font-weight-bold {
    color: #1C99FC;
}

div.content-2 div.container .form-check-input[type="checkbox"].filled-in:checked+label:after {
    background-color: #1C99FC;
    border: 2px solid #1C99FC;
}

.merchant-wizard-next .content-2 input ~ button {
   padding-left: 0;
   margin-right: 16px;
}

.merchant-wizard-next .content-3 .owner-table td {
    border-bottom: 0px;
}

div.alert.alert-success, div.alert.alert-primary  {
    background: none;
    border: none;
    padding: 0px;
    color: #1C99FC;
    font-weight: 800;
}
`
