import moment from "moment"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getSvgByValue } from "../../../assets/images/weatherIcons"
import Flex from "../../../noui/Flex"
import { getHumidity } from "../../../utils/utils"
import { DayName, DayNumber, RainBlock, TemperatureText } from "./styled"
import { CustomHeaderProps, Weather } from "./types"
import { isDateCurrent, kebabCaseToNormalText } from "./utils"
import Humidity from "../../../assets/images/Humidity.svg"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"

const WeatherIconBlock = styled.div`
   width: 55px;
   height: 55px;
   display: flex;
   justify-content: center;
   align-items: center;
   svg {
      width: 100%;
      height: 100%;
   }
   margin-right: 10px;
`

const WeatherText = styled.div`
   font-size: 16px;
   font-weight: 700;
`

const MobileProbBlock = styled(Flex)`
   flex-direction: row;
   align-items: center;
   > svg,
   img {
      width: 18px;
      height: 18px;
      margin-right: 4px;
   }
   > span {
      color: #109cf1;
      font-size: 12px;
      font-weight: 700;
   }
`

const MobileWeatherIconBlock = styled.div`
   width: 30px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   svg {
      width: 100%;
      height: 100%;
   }
   margin-right: 10px;
`

export const DayCalendarHeader: React.FC<CustomHeaderProps> = ({ date, weather }) => {
   const [dayWeather, setDayWeather] = useState<Weather | null>(null)
   const isLg = useBreakpoint(down("xs"))

   useEffect(() => {
      const today = moment().startOf("day")
      const dayDiff = moment(date).diff(today, "days")

      if (dayDiff >= 0 && dayDiff <= 9 && Array.isArray(weather.daily)) {
         setDayWeather({
            icon: weather?.daily[dayDiff]?.icon ?? "sunny",
            temperature: (weather?.daily[dayDiff]?.air_temp_high + weather?.daily[dayDiff]?.air_temp_low) / 2 ?? 0,
            precip_type: weather?.daily[dayDiff]?.precip_icon ?? "chance-rain",
            probability: weather?.daily[dayDiff]?.precip_probability ?? 0,
            humidity: getHumidity(weather?.hourly, dayDiff) ?? 0,
         })
      } else {
         setDayWeather(null)
      }
   }, [date, weather])

   return (
      <Flex
         flexDirection="row"
         height={isLg ? "auto" : "100px"}
         justifyContent="space-between"
         marginBottom={isLg ? "0" : "18px"}
         alignItems="center"
      >
         <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ width: "70.58px", borderRight: isLg ? "1px solid #ddd" : "none", height: "100px" }}
         >
            <DayName isCurrent={isDateCurrent(date)}>{moment(date).format("ddd")}</DayName>
            <DayNumber isCurrent={isDateCurrent(date)}>{moment(date).format("DD")}</DayNumber>
         </Flex>
         {!!dayWeather &&
            (!isLg ? (
               <Flex flexDirection="row" justifyContent="center" alignItems="center">
                  <RainBlock>
                     {getSvgByValue(dayWeather.precip_type)}
                     <span>{`${dayWeather.probability} %`} </span>
                  </RainBlock>
                  <WeatherIconBlock>{getSvgByValue(dayWeather.icon)}</WeatherIconBlock>
                  <Flex flexDirection="column" alignItems="center" justifyContent="center">
                     <TemperatureText>{`${Math.round(dayWeather.temperature)} °F`} </TemperatureText>
                     <WeatherText>{kebabCaseToNormalText(dayWeather.icon)}</WeatherText>
                  </Flex>
                  <RainBlock>
                     <img src={Humidity} alt="humidity" />
                     <span>{`${dayWeather.humidity} %`}</span>
                  </RainBlock>
               </Flex>
            ) : (
               <Flex flexDirection="column" marginRight="16px">
                  <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
                     <MobileWeatherIconBlock>{getSvgByValue(dayWeather.icon)}</MobileWeatherIconBlock>
                     <Flex flexDirection="column" alignItems="center" lineHeight="22px">
                        <span style={{ fontSize: "24px", fontWeight: 700 }}>
                           {`${Math.round(dayWeather.temperature)} °F`}
                        </span>
                        <span style={{ fontSize: "12px", fontWeight: 700 }}>
                           {kebabCaseToNormalText(dayWeather.icon)}
                        </span>
                     </Flex>
                  </Flex>
                  <Flex flexDirection="row" marginTop="10px" justifyContent="space-between">
                     <MobileProbBlock marginRight="10px">
                        {getSvgByValue(dayWeather.precip_type)}
                        <span>{`${dayWeather.probability} %`}</span>
                     </MobileProbBlock>
                     <MobileProbBlock>
                        <img src={Humidity} alt="humidity" />
                        <span>{`${dayWeather.humidity} %`}</span>
                     </MobileProbBlock>
                  </Flex>
               </Flex>
            ))}
         {!isLg && <Flex width="120px" height="50px"></Flex>}
      </Flex>
   )
}
