import { createReducer } from "@reduxjs/toolkit";
import { setColumns } from "./actions";
import { Key } from "react";
import { COLUMNS_STORAGE_KEY } from "../../../constants";

type TState = Record<string, Key[]>;
const storageItem = localStorage.getItem(COLUMNS_STORAGE_KEY) || "{}";

const initialState: TState = JSON.parse(storageItem);

export const columnsReducer = createReducer(initialState, (builder) =>
  builder.addCase(setColumns, (state, { payload }) => {
    const nState = { ...state, [payload.name]: payload.columns };
    console.log(nState);
    localStorage.setItem(COLUMNS_STORAGE_KEY, JSON.stringify(nState));
    return nState;
  })
);
