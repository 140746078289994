import { ImportDocument } from "../../../assets/icons";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";

export const EmptyDraggerContent = () => {
    return (
        <>
            <ImportDocument />
            <Flex flexDirection="column" alignItems="start">
                <Msg color="#0496FF" fontSize="18px" fontWeight={800}>Click to choose a file or drag and drop here</Msg>
                <Msg color="#8B8B8B">CSV format only</Msg>
            </Flex>
        </>
    );
};
