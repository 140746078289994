import { useState, useEffect } from "react"
import axios from "axios"
import moment from "moment"
import { GoogleMapsAPIKey } from "../../../api"
import { Api } from "../../../api/api"
import { getErrors } from "../../../utils/utils"
import { Button, Modal, Form, Input, Select } from "antd"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { getSvgByValue } from "../../../assets/images/weatherIcons"
import Humidity from "../../../assets/images/Humidity.svg"
import { getHumidity } from "../../../utils/utils"
import { states } from "../../../constants"
import { useAppSelector } from "../../../config/hooks"
import { getCurrentUser } from "../../../config/reducers/user/selectors"

const StyledBtn = styled(Button)`
   border: none;
   outline: none;
   background: none;
   color: #0496ff;
   font-weight: bold;
   font-size: 13px;
   margin: 0;
   padding: 0;
   white-space: normal;
   text-align: left;
   height: auto;
   &:hover {
      > span {
         text-decoration: underline;
      }
   }
`
const StyledModal = styled(Modal)`
   & .ant-modal-body {
      padding: 0;
   }

   & .ant-modal-footer {
      display: none;
   }
   & .ant-form-item {
      margin-bottom: 0;
   }
`
const ContainerMainButtons = styled(Flex)`
   border-top: 1px solid #ededed;
   margin-top: 20px;
   padding: 20px;
`
const ContainerForm = styled.div`
   padding: 0 20px;
`
const ModalCancelBtn = styled(Button)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 1px solid #ededed;
   color: #202020;
   margin-right: 10px;
   &:hover {
      border: 1px solid #202020;
   }
`
const ModalCreateBtn = styled(Button)`
   width: 80px;
   height: 34px;
   background: #0496ff;
   color: #fff;
   border: 1px solid #0496ff;
`
const StyledFlex = styled(Flex)`
   width: 100%;
   .ant-row {
      width: 100%;
      &:last-child {
         max-width: 80px;
      }
   }
`

const WeatherAlert = ({ fullWeather, changeWeatherLocation }) => {
   const [showModal, setShowModal] = useState(false)
   const [form] = Form.useForm()
   const userData = useAppSelector(getCurrentUser)

   const renderAddress = () => {
      let str = ""
      if (userData) {
         if (userData.dashboard_address) {
            const { address1, address2, city, state } = userData.dashboard_address
            str += address1 && `${address1}, `
            str += address2 && `${address2}, `
            str += city && `${city}, `
            str += state && state
         } else if (userData.company && userData.company.address) {
            const { address1, address2, city, state } = userData.company.address
            str += address1 && `${address1}, `
            str += address2 && `${address2}, `
            str += city && `${city}, `
            str += state && state
         }
      }
      return str ? str : "No address"
   }

   const renderFormFields = () => {
      if (userData) {
         if (userData.dashboard_address) {
            const { address1, city, state } = userData.dashboard_address
            form.setFieldsValue({
               address1: address1,
               city,
               state,
            })
         } else if (userData.company && userData.company.address) {
            const { address1, city, state } = userData.company.address
            form.setFieldsValue({
               address1: address1,
               city,
               state,
            })
         }
      }
   }

   useEffect(() => {
      renderFormFields()
   }, [userData])

   const onClose = () => {
      renderFormFields()
      setShowModal(false)
   }

   const handleChangeLocation = async () => {
      const data = await form.validateFields()
      try {
         let lat = null
         let lng = null

         const res = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
               `${data.address1}${data.city}`
            )}&key=${GoogleMapsAPIKey}`
         )
         const resData = await res.data
         if (resData.status === "OK" && resData.results.length) {
            lat = resData.results[0]?.geometry?.location?.lat
            lng = resData.results[0]?.geometry?.location?.lng
         }

         data.lat = lat
         data.lng = lng

         changeWeatherLocation(data)
         setShowModal(false)
      } catch (e) {
         console.log("errors", e)
      }
   }

   return (
      <>
         <Flex className="little-cart" width="195px" p="10.17px" flexDirection="column">
            <Msg fontWeight={400} fontSize={13} color="#494949">
               Weather Alerts
            </Msg>
            {fullWeather && (
               <Flex flexDirection="column" justifyContent="space-between" style={{ flexGrow: 1 }}>
                  <Flex flexDirection="column" alignItems="start">
                     <Msg fontWeight={700} fontSize={14}>
                        {moment().format("ddd, MMMM D")}
                     </Msg>
                     <Flex alignItems="center" justifyContent="space-between" width="100%">
                        <Flex flexDirection="row">
                           {getSvgByValue(fullWeather?.forecast?.daily[0]?.icon)}
                           <Flex flexDirection="column" marginLeft="5px">
                              <Msg fontSize={11} fontWeight={600} color="#FB4D4F">
                                 {fullWeather?.forecast?.daily[0]?.conditions}
                              </Msg>
                              <Msg color="#FB4D4F" fontWeight={700}>
                                 {fullWeather?.forecast?.daily[0]?.precip_probability}%
                              </Msg>
                           </Flex>
                        </Flex>
                        <Flex flexDirection="column" alignItems="center">
                           <img src={Humidity} style={{ width: "24px", height: "24px" }} alt="Humidity" />
                           <Flex flexDirection="column" marginLeft="5px">
                              <Msg color="#FB4D4F" fontWeight={700}>
                                 {getHumidity(fullWeather?.forecast?.hourly, 0)}%
                              </Msg>
                           </Flex>
                        </Flex>
                     </Flex>
                  </Flex>
                  <Flex flexDirection="column" alignItems="start">
                     <Msg fontWeight={700} fontSize={14}>
                        {moment().add(1, "days").format("ddd, MMMM D")}
                     </Msg>
                     <Flex alignItems="center" justifyContent="space-between" width="100%">
                        <Flex flexDirection="row">
                           {getSvgByValue(fullWeather?.forecast?.daily[1]?.icon)}
                           <Flex flexDirection="column" marginLeft="5px">
                              <Msg fontSize={11} fontWeight={600} color="#FB4D4F">
                                 {fullWeather?.forecast?.daily[1]?.conditions}
                              </Msg>
                              <Msg color="#FB4D4F" fontWeight={700}>
                                 {fullWeather?.forecast?.daily[1]?.precip_probability}%
                              </Msg>
                           </Flex>
                        </Flex>
                        <Flex flexDirection="column" alignItems="center">
                           <img src={Humidity} style={{ width: "24px", height: "24px" }} alt="Humidity" />
                           <Flex flexDirection="column" marginLeft="5px">
                              <Msg color="#FB4D4F" fontWeight={700}>
                                 {getHumidity(fullWeather?.forecast?.hourly, 1)}%
                              </Msg>
                           </Flex>
                        </Flex>
                     </Flex>
                  </Flex>
                  <Msg fontWeight={300} fontSize={13}>
                     <StyledBtn onClick={() => setShowModal(true)}>{renderAddress()}</StyledBtn>
                  </Msg>
               </Flex>
            )}
         </Flex>
         <StyledModal
            title="LOCATION"
            visible={showModal}
            onCancel={onClose}
            width="380px"
            onOk={handleChangeLocation}
            forceRender
         >
            <Form form={form} layout="vertical" onFinish={handleChangeLocation}>
               <ContainerForm justifyContent="flex-end">
                  <StyledFlex style={{ display: "inline-flex", gap: "10px" }}>
                     <Form.Item
                        label="City"
                        name="city"
                        rules={[
                           {
                              required: true,
                              message: "Please input city",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item name="state" label="State">
                        <Select showSearch getPopupContainer={(trigger) => trigger.parentElement}>
                           {states.map((el) => (
                              <Select.Option key={el} value={el}>
                                 {el}
                              </Select.Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </StyledFlex>
                  <Form.Item label="Address" name="address1">
                     <Input />
                  </Form.Item>
               </ContainerForm>
               <ContainerMainButtons justifyContent="flex-end">
                  <ModalCancelBtn onClick={onClose}>
                     <Msg fontWeight={600} fontSize={14}>
                        Cancel
                     </Msg>
                  </ModalCancelBtn>
                  <ModalCreateBtn ml={16} htmlType="submit">
                     Apply
                  </ModalCreateBtn>
               </ContainerMainButtons>
            </Form>
         </StyledModal>
      </>
   )
}

export default WeatherAlert
