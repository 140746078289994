import React, { useEffect, useState } from "react"
import moment from "moment"
import styled from "styled-components"
import { Modal } from "antd"
import { Api } from "../../api/api"

import { IJob, ITimeClock, IWorkOrderWithJobs } from "../../api/types"
import { formatTimeTimeClockSum } from "../../utils/utils"
import { TCallback } from "../../types"

const StyledModal = styled(Modal)`
   & .ant-modal-header .ant-modal-title {
      color: #fb4d4f;
   }
   .ant-modal-body {
      max-height: 600px;
      overflow: auto;
      border-radius: 4px;

      &::-webkit-scrollbar {
         width: 8px;
      }

      &::-webkit-scrollbar-track {
         background: #fbfbff;
         border-radius: 8px;
         border: 1px solid #ededed;
      }

      &::-webkit-scrollbar-thumb {
         background: #0496ff;
         border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
         background: #40a9ff;
      }
   }
   p {
      font-weight: 300;
      font-size: 15px;
      margin: 12px 0;
   }
`
const User = styled.div`
   font-weight: 300;
   font-size: 15px;
   .timer {
      color: #42c77b;
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      margin: 12px 0;
      line-height: 1.2;
   }
`

export type TModalProps = {
   visible: boolean
   onClose: TCallback
   job?: IJob
   workOrder?: IWorkOrderWithJobs | null | undefined
   onSubmit: TCallback
}
export type TUserBlockProps = {
   data: any
}

export const UserBlock: React.FC<TUserBlockProps> = ({ data }) => {
   const [timerValue, setTimerValue] = useState(0)

   useEffect(() => {
      let timer: any
      timer = setTimeout(async () => {
         setTimerValue(moment.duration(moment().diff(moment(data.start))).asSeconds())
      }, 1000)

      return () => {
         clearTimeout(timer)
      }
   }, [timerValue])

   return (
      <User>
         {data.user.full_name} is still clocked into job {data.job.title}:
         <div className="timer">{formatTimeTimeClockSum(timerValue, true)}</div>
      </User>
   )
}

export const JobProgressModal: React.FC<TModalProps> = ({ visible, onClose, job, onSubmit, workOrder }) => {
   const [timeClocks, setTimeClocks] = useState<any[]>([])

   const loadCards = async () => {
      const res = await Api.timeClocks.getAll({ job: job?.id })
      const timeClocks = res.data as ITimeClock[]
      const activeTimeClocks = timeClocks.filter((clock) => clock.stop === null)
      setTimeClocks(activeTimeClocks)
   }

   useEffect(() => {
      loadCards()
   }, [])

   //console.log("wo", workOrder)

   return (
      <StyledModal
         visible={visible}
         title="JOB IN PROGRESS"
         onCancel={onClose}
         onOk={onSubmit}
         width="385px"
         okText="Yes"
         cancelText="No"
      >
         {timeClocks &&
            timeClocks.length > 0 &&
            timeClocks.map((item) => {
               return <UserBlock key={item.id} data={item} />
            })}
         <p>Do you want to clock all team members out so you can complete the job?</p>
      </StyledModal>
   )
}
