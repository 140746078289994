import { SelectValue } from "antd/lib/select"
import { customerInstance, instance, instancePE } from "./index"
import * as qs from "qs"
import {
  EUserRole,
  IAdminCreateMessage,
  ICompany,
  IConstants,
  ICreateCompany,
  ICreateCustomer,
  ICreateEstimate,
  ICreateJob,
  ICreateMessage,
  ICreateMessageTemplate,
  ICreatePaymentRecord,
  ICreateSchedule,
  ICreateTask,
  ICreateVessel,
  ICustomer,
  IEmployeeStats,
  IInvoiceSettings,
  IJob,
  IInvoice,
  IListRequest,
  IReportsRequest,
  ILogin,
  IMaterial,
  IMessage,
  IMessageTemplate,
  IPaymentRecord,
  IRefreshToken,
  IRegisterUser,
  IService,
  ISetPassword,
  ISuccess,
  ITask,
  IToken,
  IUpdate,
  IUpdateUser,
  IUser,
  IUserProfile,
  IVessel,
  TApi,
  IHelpCategories,
  IHelpArticles,
  EJobStatus,
  IStats,
  IChangePassword,
  INotificationSettings,
  ICreateNotificationSettings,
  INotification,
  ITimeClock,
  ITimeClockCreate,
  ITimeClockStart,
  IUserReport,
  ISendMessage,
  ICompletions,
  IWorkOrder,
  ICreateWorkOrder,
  ISendUpdate,
  IWorkOrderUpdate,
  IInspection,
  ICreateInspection,
  IUpdateJob,
  IAppointment,
  ICreateAppointment,
  IVesselGallery,
  ICreateVesselGallery,
  IUpdateVesselGallery,
  IRemovePhoto,
  ICreateWOAppointment,
  IWOAppointment,
  IExternalEstimate,
  IExternalInvoice,
  IUuid,
  IUpcomingJob,
  IMyJob,
  ISubscriptionPlan,
  ISetPlan,
  IUpdatePlan,
  IMaterialGroup,
  IMaterialGroupActive,
  IMaterialGroupCreate,
  IMaterialInGroup,
  IUpdateCard,
  ISaveWorkOrderNote,
  IServiceCategories,
  IUpdateServiceCategories,
  IWorkOrderFilter,
  ITimeClockUpdate,
  IExternalRecurring,
  IExternalInfo,
  IExternalSchedule,
  IStatementOfWork,
  ITemplateSettings,
  ITelgorithmBrand,
  ITelgorithmPhone,
  ITelgorithmSearchPhone,
  ITelgorithmSetPhone,
  ITelgorithmPhoneRes,
  ITelgorithmBrandResponse,
  ITelgorithmBrandStatus,
  IErrorStatus,
  IJobsFieldsDownload,
  TImportUploadRes,
  TImportUpload,
  TImportEnum,
  TImportValidate,
  IPaymentsDownload,
  ICompanyAccessLog,
  ICreateJobWO,
  ICustomerShort,
} from "./types";
import { SizeType } from "../layouts/CustomerLayout/PaymentModal";
import { StripeCardElement } from "@stripe/stripe-js";

export interface IActivity {
   created: string
   message: string
   work_order: number
   job: number
   initiator: number
}
export interface IPaymentPEResult {
   created: string
   intent: any
   amount: number
}
class activities {
   static get: TApi<IActivity[]> = () => instance.get("/activities")
}

interface IAppointmentFilter {
   scheduled_after?: string
   scheduled_before?: string
   unscheduled?: boolean
}

class appointments {
   static getAll: TApi<IAppointment[], IListRequest & IAppointmentFilter> = (params) =>
      instance.get("/appointments", { params })
   static create: TApi<IAppointment, ICreateAppointment> = (data) => instance.post("/appointments", data)
   static get: TApi<IAppointment, number> = (id) => instance.get(`/appointments/${id}`)
}

class company {
  static impersonate: TApi<IUserProfile, number> = (id) => instance.put(`/companies/${id}/impersonate`);
  static stopImpersonating: TApi<IUserProfile> = () => instance.put("/companies/stop_impersonating");
  static profile: TApi<ICompany> = () => instance.get("/companies/profile");
  static create: TApi<ICompany, ICreateCompany> = (data) =>
    instance.post("/companies", data);
  static getAll: TApi<ICompany[], IListRequest> = (params) =>
    instance.get("/companies", {params});
  static update: TApi<ICompany, ICreateCompany & IUpdate> = (data) =>
    instance.put(`/companies/${data.id}`, data);
  static patch: TApi<ICompany, Partial<ICompany> & IUpdate> = (data) =>
    instance.patch(`/companies/${data?.id ?? 0}`, data);
  static setInvoiceSettings: TApi<IInvoiceSettings, IInvoiceSettings> = (
    data
  ) => instance.put("/companies/invoice_settings", data);
  static completions: TApi<ICompletions> = () =>
    instance.get("/companies/completions");
  static changeCompletions: TApi<ICompletions, Partial<ICompletions>> = (
    data
  ) => instance.patch("/companies/completions", data);
  static turnOnPayments = () => instance.post("/companies/connect_stripe", {});
  static completeStripeOnboarding = () =>
    instance.put("/companies/complete_stripe_onboarding", {});
  static changePaymentStatus = (isActive: boolean) =>
    instance.patch("/companies/change_payments_status", {
      is_active: isActive,
    });
  static changeThreeDSecureStatus = (threeDSecure: boolean) =>
    instance.patch("/companies/change_payments_status", {
      three_d_secure: threeDSecure,
    });
  static setPlan: TApi<ISetPlan, ISetPlan> = (data) =>
    instance.post("/companies/set_plan", data);
  static updatePlan: TApi<ICompany, IUpdatePlan> = (data) =>
    instance.post("/companies/update_plan", data);
  static updateCard: TApi<IUpdateCard, IUpdateCard> = (data) =>
    instance.post("/companies/update_card", data);
  static updateServiceCategories: TApi<ICompany, IUpdateServiceCategories> = (
    data
  ) =>
    instance.patch(`/companies/${data.id}`, {
      service_categories: data.service_categories,
    });
  static getExternalInfo: TApi<IExternalInfo, string> = (uuid) =>
    instance.get(`/companies/${uuid}/external_info`);
  static getTemplateSettings: TApi<ITemplateSettings[]> = () =>
    instance.get(`/companies/template_settings`);
  static setTemplateSettings: TApi<ITemplateSettings[], ITemplateSettings[]> = (
    data
  ) => instance.post(`/companies/template_settings`, data);
  static importUpload: TApi<TImportUploadRes, TImportUpload> = (d) => 
    instance.post(`/companies/${d.id}/import/upload?table=${d.table}`, d.data)
  static importExecute: TApi<Array<{[key: string]: string}>, TImportValidate> = (d) => 
    instance.post(`/companies/${d.id}/import/execute?table=${d.table}`, d.data)
  static importPresetData: TApi<Array<{[key: string]: string | Array<any>}>, Omit<TImportValidate, "id">> = (d) => 
    instance.post(`/companies/import/preset_data?table=${d.table}`, d.data)
  static importEnums: TApi<TImportEnum, string> = (table) =>
    instance.get(`/companies/import/enums?table=${table}`)
  static downloadTemplate = (table: string) => 
    instance.get(`/companies/import/templates?table=${table}`, {responseType: "blob"})
  static validateCoupon = (coupon: string) =>
      instance.post(`/companies/validate_coupon`, {promotion_code: coupon})
}

class config {
   static getConstants: TApi<IConstants> = () => customerInstance.get("/config/constants")
   static getSubscriptionPlans: TApi<ISubscriptionPlan[]> = () =>
      instance.get("/config/subscription_plans?ordering=monthly_price")
   static getServiceCategories: TApi<IServiceCategories[]> = () => instance.get("/config/service_categories")
}

type TCustomerFilter = {
   last_appointment_after?: string
   last_appointment_before?: string
   next_appointment_after?: string
   next_appointment_before?: string
   payment_status?: string
   annual_spend_min?: string
   annual_spend_max?: string
}

type TAssignVessel = {
   id: number
   vessel: number
}

interface ISendMessageWithSendType extends ISendMessage {
   send_type: string[]
}

class customers {
   static getAll: TApi<ICustomer[], IListRequest & TCustomerFilter> = (params) => instance.get("/customers", { params })
   static getShort: TApi<ICustomerShort[], IListRequest & TCustomerFilter> = (params) => instance.get(
     "/customers/short", { params }
   )
   static get: TApi<ICustomer, number> = (customerId) => instance.get(`/customers/${customerId}`)
   static patch: TApi<ICustomer, Partial<ICreateCustomer> & IUpdate> = (data) =>
      instance.patch(`/customers/${data.id}`, data)
   static update: TApi<ICustomer, ICreateCustomer & IUpdate> = (data) => instance.put(`/customers/${data.id}`, data)
   static put: TApi<ICustomer, ICreateCustomer & IUpdate> = (data) => instance.patch(`/customers/${data.id}`, data)
   static remove: TApi<ISuccess, number> = (id) => instance.delete(`/customers/${id}`)
   static download = () =>
      instance.get("/customers", {
         params: { format: "xlsx" },
         responseType: "blob",
      })
   static sendMessage: TApi<ISuccess, ISendMessageWithSendType> = (data) =>
      instance.post(`/customers/${data.customer}/send_message`, data)
   static assignVessel = (data: TAssignVessel) =>
      instance.put(`/customers/${data.id}/assign_vessel`, {
         vessel: data.vessel,
      })
}

type IStatsFilter = {
   time_before?: string
   time_after?: string
}
class stats {
   static getAll: TApi<IStats, IListRequest & IStatsFilter> = (params) => instance.get("/stats", { params })
   static getManagementStats = () => instance.get("/stats/management_stats")
}

class help {
   static get: TApi<IHelpArticles[], IListRequest & IHelpFilter> = (params) => instance.get("/help", { params })
   static getCategories: TApi<IHelpCategories[]> = () => instance.get("/help/categories")
   static getArticle: TApi<IHelpArticles, number> = (helpId) => instance.get(`/help/${helpId}`)
}

type IHelpFilter = {
   id?: number
   created?: string
   updated?: string
   section_name?: string
   category?: number
   category_name?: string
   section?: number
   title?: string
   subtitle?: string
   content?: string
}
type TJobFilter = {
   vessel?: number
   unscheduled?: boolean
   work_order?: number
   status?: EJobStatus
   // scheduled_before?: string;
   // scheduled_after?: string;
   customer?: number
   is_approved?: boolean
   scheduled_before?: string
   scheduled_after?: string
}

type TInspectionFilter = {
   job?: number
   vessel?: number
   inspection_type?: string
   category?: string
   work_order?: number
}

type TUpcomingJobRequest = {
   date: string
}

class jobs {
   static getAll: TApi<IJob[], IListRequest & TJobFilter> = (params: IListRequest) => instance.get("/jobs", { params })
   static get: TApi<IJob, number> = (jobId) => instance.get(`/jobs/${jobId}`)
   static sendEstimate: TApi<IJob, ICreateEstimate> = (data) => instance.post(`/jobs/${data.id}/estimate`, data)
   static sendMessage: TApi<IMessage, ICreateMessage> = ({ uuid, i, job, ...data }) =>
      customerInstance.post(`/jobs/send-message/${uuid}`, data, {
         params: { i, job },
      })
   static proceed: TApi<IJob, number> = (id) => instance.patch(`/jobs/${id}/proceed_without_approval`)
   static create: TApi<IJob, ICreateJob> = (data) => instance.post("/jobs", data)
   static update: TApi<IJob, IUpdateJob & { id: number }> = (data) => instance.put(`/jobs/${data.id}`, data)
   static patch: TApi<IJob, Partial<IUpdateJob> & IUpdate> = (data) => instance.patch(`/jobs/${data.id}`, data)
   static start: TApi<IJob, number> = (jobId) => instance.put(`/jobs/${jobId}/start`, {})
   static stop: TApi<IJob, number> = (jobId) => instance.put(`/jobs/${jobId}/stop`, {})
   static finish: TApi<any, any> = (data) => instance.put(`/jobs/${data.get("jobId")}/complete`, data)
   static approve: TApi<IExternalEstimate, string> = (uid) => customerInstance.put(`/work-orders/approve/${uid}`, {})
   static estimateDetails: TApi<IExternalEstimate, IUuid> = ({ uuid }) =>
      customerInstance.get(`/work-orders/details/${uuid}`)
   static invoiceDetails: TApi<IExternalInvoice, IUuid> = ({ uuid }) =>
      customerInstance.get(`/work-orders/invoice-details/${uuid}`)
   static recurringDetails: TApi<IExternalRecurring, IUuid> = ({ uuid }) =>
      customerInstance.get(`/work-orders/recurring-details/${uuid}`)
   static getScheduleView: TApi<IExternalSchedule, IUuid> = ({ uuid }) =>
      customerInstance.get(`/work-orders/schedule-details/${uuid}`)
   static schedule: TApi<IJob, ICreateSchedule> = (data) => instance.put(`/jobs/${data.id}/schedule`, data)
   static proceedWithoutApprove: TApi<IJob, number> = (id) => instance.put(`/work-orders/${id}/approve`, {})
   static invoice: TApi<IJob, number> = (jobId) => instance.put(`/jobs/${jobId}/invoice`, {})
   static employeeStats: TApi<IEmployeeStats> = () => instance.get("/jobs/employee_stats")
   static delete: TApi<IJob, number> = (jobId) => instance.delete(`/jobs/${jobId}`)
   static appointment: TApi<IWOAppointment, ICreateWOAppointment & IUpdate> = (data) =>
      instance.post(`/jobs/${data.id}/create_schedule`, data)
   static upcomingJobs: TApi<IUpcomingJob[], TUpcomingJobRequest> = (params) =>
      instance.get("/jobs/upcoming_jobs", { params })
   static getJobs: TApi<IMyJob[]> = () => instance.get("/jobs/my_jobs")
   static download = (params?: IJobsFieldsDownload) => {
      console.log("params", params)
      return instance.get("/jobs", {
         params: { format: "xlsx", ...params },
         paramsSerializer: (params) => qs.stringify(params, { indices: false }),
         responseType: "blob",
      })
   }
}

type TTimeClockFilter = {
   job?: number
   user?: number
}

class timeClocks {
   static getAll: TApi<ITimeClock[], IListRequest & TTimeClockFilter> = (params: IListRequest) =>
      instance.get("/time-clocks", { params })
   static create: TApi<ITimeClock, ITimeClockCreate> = (data) => instance.post("/time-clocks", data)
   static get: TApi<ITimeClock, number> = (timeClockId) => instance.get(`/time-clocks/${timeClockId}`)
   static start: TApi<ITimeClockStart, ITimeClockStart> = (data) => instance.post("/time-clocks/start", data)
   static stop: TApi<ITimeClock, number> = (id) => instance.put(`/time-clocks/${id}/stop`)
   static download = () =>
      instance.get("/time-clocks", {
         params: { format: "xlsx" },
         responseType: "blob",
      })
   static getReports: TApi<IUserReport[], IReportsRequest> = (params: IReportsRequest) =>
      instance.get("/time-clocks/reporting", { params })

   static downloadReports = (params: IReportsRequest) =>
      instance.get("/time-clocks/reporting", {
         params: { ...params, format: "xlsx" },
         responseType: "blob",
      })
   static delete: TApi<ITimeClock, number> = (timeClockId) => instance.delete(`/time-clocks/${timeClockId}`)
   static update: TApi<ITimeClock, ITimeClockUpdate> = (data) => instance.put(`/time-clocks/${data.id}`, data)
}

type TInvoiceFilters = {
   completed_time_before?: string
   completed_time_after?: string
   completed?: boolean
}
class invoices {
   static getAll: TApi<IInvoice[], IListRequest & TInvoiceFilters> = (params: IListRequest) =>
      instance.get("/invoices", { params })
   static get: TApi<IInvoice, number> = (invoiceId) => instance.get(`/invoices/${invoiceId}`)
}

interface IInspectionImage {
   id: number
   image: string[]
}

interface IInspectionSetImage {
   id: number
   images: any[]
}

interface IInspectionRemoveImage {
   id: number
   imageId: number
}

class inspections {
   static getAll: TApi<IInspection[], IListRequest & TInspectionFilter> = (params) =>
      instance.get("/inspections", { params })
   static get: TApi<IInspection, number> = (inspectionId) => instance.get(`/inspections/${inspectionId}`)
   static create: TApi<IInspection, ICreateInspection> = (data) => instance.post("/inspections", data)
   static put: TApi<IInspection, IInspection> = (data) => instance.put(`/inspections/${data.id}`, data)
   static patch: TApi<IInspection, Partial<IInspection>> = (data) => instance.patch(`/inspections/${data.id}`, data)
   static delete: TApi<ISuccess, number> = (inspectionId) => instance.delete(`/inspections/${inspectionId}`)
   static addImage: TApi<IInspectionImage, IInspectionSetImage> = (data) =>
      instance.post(`/inspections/${data.id}/add_image`, { images: data.images })
   static removeImage: TApi<ISuccess, IInspectionRemoveImage> = (data) =>
      instance.delete(`/inspections/${data.id}/remove_image/${data.imageId}`)
}

class materials {
   static getAll: TApi<IMaterial[], IListRequest> = (params) => instance.get("/materials", { params })
   static getAllMaterials: TApi<IMaterial[], IListRequest & IMaterialGroupActive> = (params) =>
      instance.get("/materials", { params: { ...params, ordering: "id" } })
   static getAllGroups: TApi<IMaterialGroup[], IListRequest> = (params) => instance.get("/material-groups", { params })
   static createMaterialGroup: TApi<IMaterialGroup, IMaterialGroupCreate> = (data) =>
      instance.post("/material-groups", data)
   static deleteMaterialGroup: TApi<ISuccess, IMaterialGroup> = (data) => instance.delete(`/material-groups/${data.id}`)
   static editMaterialGroup: TApi<IMaterialGroup, IMaterialGroup> = (data) =>
      instance.put(`/material-groups/${data.id}`, { name: data.name })
   static createMaterial: TApi<IMaterialInGroup, IMaterialInGroup> = (data) => instance.post("/materials", data)
   static editMaterial: TApi<IMaterialInGroup, Partial<IMaterialInGroup>> = (data) =>
      instance.patch(`/materials/${data.id}`, data)
   static deleteMaterial: TApi<ISuccess, IMaterial> = (data) => instance.delete(`/materials/${data.id}`)
}

type TMessagesFilter = {
   job?: number
   work_order?: number
}

class messages {
   static getAll: TApi<IMessage[], IListRequest & TMessagesFilter> = (params) => instance.get("/messages", { params })
   static create: TApi<IMessage, IAdminCreateMessage> = (data) => instance.post("/messages", data)
}

type TNotificationsFilter = {
   read?: boolean
   created?: string
}

class notifications {
   static getAll: TApi<INotification[], IListRequest & TNotificationsFilter> = (params) =>
      instance.get("/notifications", { params })
   static get: TApi<INotification, number> = (id) => instance.get(`/notifications/${id}`)
   static readNotification: TApi<INotification, number> = (id) => instance.put(`/notifications/${id}/read`)
   static unreadNotification: TApi<INotification, number> = (id) => instance.put(`/notifications/${id}/unread`)
   static removeNotification: TApi<INotification, number> = (id) => instance.put(`/notifications/${id}/remove`)
}

class notificationSettings {
   static get: TApi<INotificationSettings[]> = () => instance.get("/notification-settings")
   static update: TApi<INotificationSettings, ICreateNotificationSettings> = (data) =>
      instance.put(`/notification-settings/${data.category}`, data)
}

type TPaymentRecordFilters = {
   customer?: number
   is_error?: boolean
   work_order?: number
}

export type TPaymentStripe = {
   amount?: number | SizeType
   invoice?: string
   estimate?: string
}

export type TCheckThreedsData = {
   merchant_id: string
   transaction_uid: string
}

class paymentRecords {
   static getAll: TApi<IPaymentRecord[], IListRequest & TPaymentRecordFilters> = (params) =>
      instance.get("payment-records", { params })
   static create: TApi<IPaymentRecord, ICreatePaymentRecord> = (data) => instance.post("payment-records", data)
   static payByStipe: TApi<IPaymentPEResult, TPaymentStripe> = (data) =>
      instance.post("payment-records/pay_by_stripe", data)
   static checkThreedsTransaction: TApi<any, TCheckThreedsData> = (data) =>
      instance.post("/payment-records/check_threeds_transaction", data)
   static download = (params?: IPaymentsDownload) => {
      return instance.get("/payment-records", {
         params: { format: "xlsx", ...params },
         paramsSerializer: (params) => qs.stringify(params, { indices: false }),
         responseType: "blob",
      })
   }
}

type ISchedulesRequest = {
   category?: string
   status?: string
   search?: string
   ordering?: string
   page?: number
   pageSize?: number
   time?: string
   time_after?: string
   time_before?: string
   is_conflict?: boolean
   is_alert?: boolean
}

export enum EScheduleStatuses {
   A = "A",
   H = "H",
   C = "C",
}

export enum EScheduleCategory {
   W = "W",
   I = "I",
   A = "A",
}

export type TAddress = {
   full_address?: string
   address1: string
   address2: string
   city: string
   lat: number | null
   lng: number | null
   state: string | null
   zip_code: string
}

export type TSchedule = IWOAppointment & {
   address: TAddress
   id: number
   job: IJob
   technicians: number[]
   status: EScheduleStatuses
   is_recurring?: boolean
   start?: string
   time_logged?: string;
   active_clock?: number|null;
   active_started?: string|null;
}

class schedules {
   static getAll: TApi<TSchedule[], ISchedulesRequest> = (params) => instance.get("/schedules", { params })
   static getConflicts: TApi<TSchedule[], ISchedulesRequest> = (params) =>
      instance.get("/schedules/conflicts", { params })
}

type TServicesFilter = {
   active?: boolean
}
class services {
   static getAll: TApi<IService[], IListRequest & TServicesFilter> = (params) => instance.get("/services", { params })
}

class rateCard {
   static getAll: TApi<IMaterial[], IListRequest> = (params) => instance.get("/services", { params })
   static getAllServices: TApi<IMaterial[], IListRequest & IMaterialGroupActive> = (params) =>
      instance.get("/services", { params: { ...params, ordering: "id" } })
   static getAllGroups: TApi<IMaterialGroup[], IListRequest> = (params) => instance.get("/service-groups", { params })
   static createMaterialGroup: TApi<IMaterialGroup, IMaterialGroupCreate> = (data) =>
      instance.post("/service-groups", data)
   static deleteMaterialGroup: TApi<ISuccess, IMaterialGroup> = (data) => instance.delete(`/service-groups/${data.id}`)
   static editMaterialGroup: TApi<IMaterialGroup, IMaterialGroup> = (data) =>
      instance.put(`/service-groups/${data.id}`, { name: data.name })
   static createMaterial: TApi<IMaterialInGroup, IMaterialInGroup> = (data) => instance.post("/services", data)
   static editMaterial: TApi<IMaterialInGroup, Partial<IMaterialInGroup>> = (data) =>
      instance.patch(`/services/${data.id}`, data)
   static deleteMaterial: TApi<ISuccess, IMaterial> = (data) => instance.delete(`/services/${data.id}`)
}

class tasks {
   static getAll: TApi<ITask[], IListRequest> = (params) => instance.get("/tasks", { params })
   static remove: TApi<ISuccess, number> = (id) => instance.delete(`/tasks/${id}`)
   static update: TApi<ITask, ICreateTask & IUpdate> = (data) => instance.put(`/tasks/${data.id}`, data)
   static patch: TApi<ITask, Partial<ICreateTask> & IUpdate> = (data) => instance.patch(`/tasks/${data.id}`, data)
   static create: TApi<ITask, ICreateTask> = (data) => instance.post("/tasks", data)
}

class telgorithm {
   static createBrand: TApi<ITelgorithmBrandResponse, ITelgorithmBrand> = (data) =>
      instance.post("/telgorithm/brand", data)
   static updateBrand: TApi<ITelgorithmBrandResponse, ITelgorithmBrand> = (data) =>
      instance.put("/telgorithm/brand", data)
   static getBrand: TApi<ITelgorithmBrandResponse> = () => instance.get("/telgorithm/brand")
   static deleteBrand = () => instance.delete("/telgorithm/brand")
   static searchPhone: TApi<ITelgorithmPhoneRes | string, ITelgorithmSearchPhone> = (params) =>
      instance.get("/telgorithm/phones", {
         params: { ...params, quantity: 100 },
      })
   static setPhone: TApi<ITelgorithmSetPhone, string> = (phone) => instance.post("/telgorithm/phones", { phone })
   static getBrandStatus: TApi<ITelgorithmBrandStatus | IErrorStatus[]> = () => instance.get("/telgorithm/brand/status")
}

class templates {
   static getAll: TApi<IMessageTemplate[], IListRequest> = (params) => instance.get("/message-templates", { params })
   static create: TApi<IMessageTemplate, ICreateMessageTemplate> = (data) => instance.post("/message-templates", data)
   static update: TApi<IMessageTemplate, ICreateMessageTemplate & IUpdate> = (data) =>
      instance.put(`/message-templates/${data.id}`, data)
   static patch: TApi<IMessageTemplate, Partial<ICreateMessageTemplate> & IUpdate> = (data) =>
      instance.patch(`/message-templates/${data.id}`, data)
   static remove: TApi<ISuccess, number> = (templateId) => instance.delete(`/message-templates/${templateId}`)
}

class token {
   static get: TApi<IToken, ILogin> = (data) => instance.post("/token/", data)
   static refresh: TApi<IToken, IRefreshToken> = (data) =>
      instance.post("/token/refresh/", data, {
         headers: { Authorization: undefined },
      })
}

type TUserFilter = {
   role?: EUserRole
}

class user {
   static getAll: TApi<IUser[], IListRequest & TUserFilter> = (params) => instance.get("/users", { params })
   static get: TApi<IUser, number> = (userId) => instance.get(`/users/${userId}`)
   static profile: TApi<IUserProfile> = () => instance.get("/users/profile")
   static resetPassword: TApi<ISuccess, Pick<IRegisterUser, "email">> = (data) =>
      instance.post("/users/reset_password", data)
   static changePassword: TApi<ISuccess, IChangePassword> = (data) => instance.post("/users/change_password", data)
   static setPassword: TApi<ISuccess, ISetPassword> = (data) => instance.post("/users/set_password", data)
   static patch: TApi<IUser, Partial<IUpdateUser>> = (data) => instance.patch("/users/profile", data)
   static register: TApi<IUser, IRegisterUser> = (data) => instance.post("/users", data)
   static completeOnboarding: TApi<IUserProfile> = () => instance.get("/users/complete_onboarding")
   static download = () =>
      instance.get("/users", {
         params: { format: "xlsx" },
         responseType: "blob",
      })
   static dashboardAddress: TApi<IUserProfile, any> = (data: any) =>
      instance.patch(`/users/${data.userId}`, {
         dashboard_address: data.address,
      })
}

type TVesselsFilter = {
   format?: "xlsx" | "xls"
   year?: string
   number_of_engines?: string
   vessel_type?: SelectValue
   vessel_size_min?: string
   vessel_size_max?: string
   year_min?: string
   year_max?: string
}

type TUpdateNotes = {
   notes: string
   id: number
}

type TUpdateLocation = {
   id: number
   lat: number
   lng: number
}

type TUpdateAvatar = {
   id: number
   avatar: string[]
}

type TRemoveAvatar = {
   id: number
   avatar: string[]
}

class vessels {
   static getAll: TApi<IVessel[], IListRequest & TVesselsFilter> = (params) =>
      instance.get("/vessels", {
         params,
         paramsSerializer: (params) => qs.stringify(params, { indices: false }),
         responseType: ["xlsx", "xlsx"].includes(params.format ?? "") ? "blob" : undefined,
      })
   static assign: TApi<IVessel, number> = (vesselId) => instance.put(`/vessels/${vesselId}/assign`, {})
   static unassign: TApi<IVessel, number> = (id) => instance.put(`/vessels/${id}/unassign`, {})
   static get: TApi<IVessel, number> = (vesselId) => instance.get(`/vessels/${vesselId}`)
   static create: TApi<IVessel, ICreateVessel> = (data) => instance.post("/vessels", data)
   static update: TApi<IVessel, ICreateVessel & IUpdate> = (data) => instance.put(`/vessels/${data.id}`, data)
   static patch: TApi<IVessel, Partial<ICreateVessel & IUpdate>> = (data) => instance.patch(`/vessels/${data.id}`, data)
   static updateNotes = (data: TUpdateNotes) => instance.patch(`/vessels/${data.id}`, { notes: data.notes })
   static updateLocation = (data: TUpdateLocation) =>
      instance.patch(`/vessels/${data.id}`, {
         address: { lat: data.lat, lng: data.lng },
      })
   static updateAvatar: TApi<IVessel, TUpdateAvatar> = (data) =>
      instance.patch(`/vessels/${data.id}`, { image: data.avatar })
   static removeAvatar: TApi<IVessel, TRemoveAvatar> = (data) => instance.patch(`/vessels/${data.id}`, { image: null })
   static delete: TApi<IVessel, number> = (id) => instance.delete(`/vessels/${id}`)
}

class vesselsGallery {
   static getAll: TApi<IVesselGallery[], IListRequest> = (params) => instance.get("/vessels-gallery", { params })
   static get: TApi<IVesselGallery, number> = (id) => instance.get(`/vessels-gallery/${id}`)
   static create: TApi<IVesselGallery, ICreateVesselGallery> = (data) => instance.post("/vessels-gallery", data)
   static addPhoto: TApi<IVesselGallery, IUpdateVesselGallery> = (data) =>
      instance.post("/vessels-gallery/bulk_create", data)
   static removePhoto: TApi<IVesselGallery, IRemovePhoto> = (data) =>
      instance.post("/vessels-gallery/bulk_remove", data)
}

type TChangeWOTitle = {
   id: number
   title: string
}

type TFormat = {
   format?: "xlsx" | "xls"
}

type TDeposit = {
   percentage: number | null
   amount: number | null
}
type TDefaultDeposit = {
   percentage: number | null
   amount: number | null
}
type TMakeRecurring = {
   id: number
   clone: boolean
   jobs?: number[]
}

export type TRecurringConfig = {
   id: number
   first_appointment_date: string
   final_appointment_date: string
   first_billing_date: string
   billing: string
   start_time: string
   end_time: string
   times_per_period: number
   uuid: string
   schedule_period: string
   title: string
   technician?: number
   day_of_week?: number[]
   discount?: number
   recurring_payment_amount?: number
   recurring_total_amount?: number
}

export type TRecurringPricing = {
   payment: number
   repeats: number
   total: number
}

export type TRecurringSchedules = {
   start: string
   end: string
   period: string
   dates: string[]
}

export type TApproveRecurring = {
   uuid: string
   cardId: string
}

type TCancelRecurring = {
   id: number
   message: string
}

export enum ERequestPriority {
   Normal = "N",
   Urgent = "U",
   Decline = "D"
}

export type TSendMessage = {
   uuid: string
   message: string
}

export type TRequestChange = TSendMessage & { priority?: ERequestPriority }
export type TRequest = Omit<
   Omit<TRequestChange, "priority"> & {
      created: string
      customer: number
      work_order: number
      priority: ERequestPriority;
      id: number
      resolve: string | null;
      is_read?: boolean;
   },
   "uuid"
>

class workOrders {
   static getAll: TApi<IWorkOrder[], IListRequest & IWorkOrderFilter & TFormat> = (params) =>
      instance.get("/work-orders", {
         params,
         paramsSerializer: (params) => qs.stringify(params, { indices: false }),
         responseType: ["xlsx", "xlsx"].includes(params.format ?? "") ? "blob" : undefined,
      })
   static get: TApi<IWorkOrder, number> = (id) => instance.get(`/work-orders/${id}`)
   static create: TApi<IWorkOrder, ICreateWorkOrder> = (data) => instance.post("/work-orders", data)
   static createJob: TApi<any, any> = (data) => instance.post("/jobs/with_wo", data)
   static update: TApi<IWorkOrder, ICreateWorkOrder & IUpdate> = (data) => instance.put(`/work-orders/${data.id}`, data)
   static patch: TApi<IWorkOrder, Partial<IWorkOrder & IUpdate>> = (data) =>
      instance.patch(`/work-orders/${data.id}`, data)
   //static sendUpdate: TApi<IWorkOrderUpdate, ISendUpdate & IUpdate> = (data) =>
   static sendUpdate: TApi<any, any> = (data: any) => instance.post(`/work-orders/${data.get("id")}/send_update`, data)
   static requestPayment: TApi<any, any> = (data: any) => instance.post(`/work-orders/${data.id}/send_update`, data)
   //static generateInvoice: TApi<IWorkOrderUpdate, ISendUpdate & IUpdate> = (data) =>
   static generateInvoice: TApi<any, any> = (data: any) =>
      instance.put(`/work-orders/${data.get("id")}/generate_invoice`, data)
   //static sendSubscription: TApi<IWorkOrderUpdate, Omit<ISendUpdate & IUpdate, "additional_payment">> = (data) =>
   static sendSubscription: TApi<any, any> = (data) =>
      instance.put(`/work-orders/${data.get("id")}/send_subscription`, data)
   static sendSchedule: TApi<any, any> = (data) => instance.put(`/work-orders/${data.get("id")}/send_schedule`, data)
   static delete: TApi<IWorkOrder, number> = (id) => instance.delete(`/work-orders/${id}`)
   static changeTitle: TApi<IWorkOrder, TChangeWOTitle> = (data) =>
      instance.patch(`/work-orders/${data.id}`, { title: data.title })
   static setDeposit: TApi<TDeposit, TDeposit & { id: number }> = (data) =>
      instance.patch(`/work-orders/${data.id}/required_deposit`, {
         ...(data.amount !== null && { amount: data.amount }),
         ...(data.percentage !== null && { percentage: data.percentage }),
      })
   static setDefaultDeposit: TApi<TDefaultDeposit, TDefaultDeposit> = (data) =>
      instance.patch(`/companies/set_default_deposit`, {
         ...(data.amount !== null && { amount: data.amount }),
         ...(data.percentage !== null && { percentage: data.percentage }),
      })
   static saveNotes: TApi<IWorkOrder, ISaveWorkOrderNote> = (data) =>
      instance.patch(`/work-orders/${data.id}`, { notes: data.note })
   static makeRecurring: TApi<IWorkOrder, TMakeRecurring> = (data) =>
      instance.post(`/work-orders/${data.id}/make_recurring`, data)
   static setRecurringConfig: TApi<TRecurringConfig, TRecurringConfig> = (data) =>
      instance.put(`/work-orders/${data.id}/set_recurring_config`, data)
   static getRecurringCalculations: TApi<TRecurringPricing, TRecurringConfig> = (data) =>
      instance.put(`/work-orders/${data.id}/get_recurring_calculations`, data)
   static getRelativeSchedules: TApi<TRecurringSchedules, number> = (id) =>
      instance.get(`/work-orders/${id}/get_relative_schedules`)
   static approveSubscriptions: TApi<any, TApproveRecurring> = (data) =>
      instance.put(`/work-orders/approve-subscription/${data.uuid}`, {
         cardId: data.cardId,
      })
   static updateCardForSubscriptions: TApi<any, TApproveRecurring> = (data) =>
      instance.patch(`/work-orders/${data.uuid}/update_card`, {
         cardId: data.cardId,
      })
   static approveSchedule: TApi<any, string> = (uuid) => instance.put(`/work-orders/approve-schedule/${uuid}`, {})
   static cancelRecurring: TApi<TCancelRecurring, TCancelRecurring> = (data) =>
      instance.post(`/work-orders/${data.id}/cancel_recurring_order`, {
         message: data.message,
      })
   static getStatementOfWork: TApi<IStatementOfWork, string> = (uuid) =>
      instance.get(`/work-orders/${uuid}/statement_of_work`)
   static requestChange: TApi<TRequest, TRequestChange> = (data) =>
      instance.post(`/work-orders/${data.uuid}/request_change`, {
         message: data.message,
         priority: data.priority,
      })
   static declineEstimate: TApi<IStatementOfWork, TRequestChange> = ({uuid, ...data}) =>
     instance.post(`/work-orders/${uuid}/decline`, data);
   static sendMessage: TApi<IMessage, TSendMessage> = (data) =>
      instance.post(`/work-orders/${data.uuid}/send_message`, {
         message: data.message,
      })
   static getSendSettings: TApi<any, number> = (id) => instance.get(`/work-orders/${id}/send_settings`)
   static postSendSettings: TApi<any, any> = (data: any) => instance.post(`/work-orders/${data.id}/send_settings`, data)
   static getTotalLimits: TApi<{ total_max: number; total_min: number }> = () =>
      instance.get(`/work-orders/get_total_limits`)
   static getOrderNumber: TApi<{ order_number: string }> = () => instance.get(`/work-orders/get_next_order_number`)
   static billingHistory: TApi<any, number> = (id) => instance.get(`/work-orders/${id}/billing_history`)
}

class warnings {
   static resolve: TApi<any, number> = (id) => instance.post(`/warnings/${id}/resolve`);
   static read: TApi<any, number> = (id) => instance.post(`/warnings/${id}/read`);
}

type TCCSale = {
   merchantId: string
   invoiceUID: string
   token: string
   amount: string
}

class paymentsPE {
   static CCSale: TApi<any, TCCSale> = (data) =>
      instancePE.post("/payment/sale", {
         merchant_id: data.merchantId,
         invoice_id: data.invoiceUID,
         data: {
            cardToken: data.token,
            transactionAmount: data.amount,
         },
      })
   static ACHSale: TApi<any, TCCSale> = (data) =>
      instancePE.post("/payment/ach", {
         merchant_id: data.merchantId,
         invoice_id: data.invoiceUID,
         data: {
            accountToken: data.token,
            transactionAmount: data.amount,
         },
      })
}

type TActionLogsFilter = {
  user?: number;
}
class actionLogs {
  static getAll: TApi<ICompanyAccessLog[], IListRequest & TActionLogsFilter> = (params) =>
    instance.get("/impersonate-actions", { params });
}

export const Api = {
   activities,
   actionLogs,
   appointments,
   company,
   config,
   customers,
   jobs,
   invoices,
   inspections,
   materials,
   messages,
   notifications,
   notificationSettings,
   paymentRecords,
   schedules,
   services,
   tasks,
   telgorithm,
   templates,
   token,
   user,
   vessels,
   vesselsGallery,
   help,
   stats,
   timeClocks,
   workOrders,
   paymentsPE,
   warnings,
   rateCard,
}
