import React from 'react';
import {DatePicker, Form, Modal, notification, Select} from "antd";
import {Api} from "../../api/api";
import {TModalProps} from "../../types";
import {IJob} from "../../api/types";
import {useLoading} from "../../utils/hooks";

export const ScheduleModal: React.FC<TModalProps<IJob>> = ({visible, onClose, payload, onSuccess}) => {
  const [form] = Form.useForm();
  const [loading, onCall] = useLoading();

  const scheduleWork = async () => {
    try {
      const data = await form.validateFields();
      await onCall(Api.jobs.appointment({
        id: payload?.id,
        ...data
      }));
      notification.success({
        message: 'Job scheduled successfully'
      })
      if (onSuccess && payload) {
        onSuccess(payload);
      }
      onClose();
    } catch (e) {
      notification.error({
        message: 'Something went wrong'
      })
    }
  }
  return (
    <Modal
      visible={visible}
      title="Schedule work"
      onCancel={onClose}
      onOk={scheduleWork}
      width="380px"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="category" label="Category" rules={[{
          required: true,
          message: 'Category is required'
        }]}>
          <Select placeholder="Select category">
            <Select.Option value="W">Work</Select.Option>
            <Select.Option value="I">Inspection</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="start" label="Start date" rules={[{
          required: true,
          message: 'Start date is required'
        }]}>
          <DatePicker style={{width: '100%'}} showTime={{format: 'HH:mm', use12Hours: true}}/>
        </Form.Item>
        <Form.Item name="end" label="End date" rules={[{
          required: true,
          message: 'End date is required'
        }]}>
          <DatePicker style={{width: '100%'}} showTime={{format: 'HH:mm', use12Hours: true}}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}