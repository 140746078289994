export const MWOMessage = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66665 2.40112H13.3333C14.0666 2.40112 14.6666 3.00112 14.6666 3.73446V11.7345C14.6666 12.4678 14.0666 13.0678 13.3333 13.0678H2.66665C1.93331 13.0678 1.33331 12.4678 1.33331 11.7345L1.33998 3.73446C1.33998 3.00112 1.93331 2.40112 2.66665 2.40112ZM7.99951 8.40006L13.3328 5.06672V3.73339L7.99951 7.06672L2.66617 3.73339V5.06672L7.99951 8.40006Z" fill="#8B8B8B"/>
    </svg>
)

export const MWOSchedule = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4733 9.03849V1.98446C14.4733 1.74712 14.281 1.55477 14.0436 1.55477H11.8665V1.09644C11.8665 0.8591 11.6742 0.666748 11.4368 0.666748C11.1995 0.666748 11.0072 0.8591 11.0072 1.09644V1.55477H9.28841V1.09644C9.28841 0.8591 9.09606 0.666748 8.85872 0.666748C8.62139 0.666748 8.42904 0.8591 8.42904 1.09644V1.55477H6.71029V1.09644C6.71029 0.8591 6.51793 0.666748 6.2806 0.666748C6.04326 0.666748 5.85091 0.8591 5.85091 1.09644V1.55477H4.13216V1.09644C4.13216 0.8591 3.93981 0.666748 3.70247 0.666748C3.46514 0.666748 3.27279 0.8591 3.27279 1.09644V1.55477H1.0957C0.858368 1.55477 0.666016 1.74712 0.666016 1.98446V13.185C0.666016 13.4223 0.858368 13.6147 1.0957 13.6147H8.25157C8.94623 14.6503 10.1275 15.3334 11.4655 15.3334C13.5978 15.3334 15.3327 13.5986 15.3327 11.4662C15.3327 10.5475 15.0104 9.70272 14.4733 9.03849ZM1.52539 12.7553V4.99227H13.6139V8.2523C12.9991 7.83996 12.2599 7.59904 11.4655 7.59904C10.1077 7.59904 8.91132 8.30254 8.22113 9.364C8.22113 9.364 7.63268 10.2001 7.62237 11.0365V11.8959C7.65538 12.1938 7.7224 12.4816 7.81953 12.7553H1.52539ZM11.4655 14.474C9.80695 14.474 8.45768 13.1248 8.45768 11.4662C8.45768 9.80768 9.80695 8.45841 11.4655 8.45841C13.124 8.45841 14.4733 9.80768 14.4733 11.4662C14.4733 13.1248 13.124 14.474 11.4655 14.474Z" fill="#8B8B8B"/>
        <path d="M13.1842 11.0365H11.8952V9.74748C11.8952 9.51014 11.7028 9.31779 11.4655 9.31779C11.2282 9.31779 11.0358 9.51014 11.0358 9.74748V11.4662C11.0358 11.7036 11.2282 11.8959 11.4655 11.8959H13.1842C13.4216 11.8959 13.6139 11.7036 13.6139 11.4662C13.6139 11.2289 13.4216 11.0365 13.1842 11.0365Z" fill="#8B8B8B"/>
    </svg>
)

export const MWOReschedule = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.474 1.98446V9.03849C15.0111 9.70272 15.3334 10.5475 15.3334 11.4662C15.3334 13.5986 13.5985 15.3334 11.4662 15.3334C10.1282 15.3334 8.9469 14.6503 8.25224 13.6147H1.09637C0.859039 13.6147 0.666687 13.4223 0.666687 13.185V1.98446C0.666687 1.74712 0.859039 1.55477 1.09637 1.55477H3.27346V1.09644C3.27346 0.8591 3.46581 0.666748 3.70315 0.666748C3.94048 0.666748 4.13283 0.8591 4.13283 1.09644V1.55477H5.85158V1.09644C5.85158 0.8591 6.04393 0.666748 6.28127 0.666748C6.51861 0.666748 6.71096 0.8591 6.71096 1.09644V1.55477H8.42971V1.09644C8.42971 0.8591 8.62206 0.666748 8.8594 0.666748C9.09673 0.666748 9.28908 0.8591 9.28908 1.09644V1.55477H11.0078V1.09644C11.0078 0.8591 11.2002 0.666748 11.4375 0.666748C11.6749 0.666748 11.8672 0.8591 11.8672 1.09644V1.55477H14.0443C14.2816 1.55477 14.474 1.74712 14.474 1.98446ZM1.52606 4.99227V12.7553H7.8202C7.72307 12.4816 7.65605 12.1938 7.62304 11.8959V11.0365C7.63335 10.2001 8.2218 9.364 8.2218 9.364C8.91199 8.30254 10.1084 7.59904 11.4662 7.59904C12.2605 7.59904 12.9997 7.83996 13.6146 8.2523V4.99227H1.52606ZM8.45835 11.4662C8.45835 13.1248 9.80762 14.474 11.4662 14.474C13.1247 14.474 14.474 13.1248 14.474 11.4662C14.474 9.80768 13.1247 8.45841 11.4662 8.45841C9.80762 8.45841 8.45835 9.80768 8.45835 11.4662ZM11.8959 11.0365H13.1849C13.4223 11.0365 13.6146 11.2289 13.6146 11.4662C13.6146 11.7036 13.4223 11.8959 13.1849 11.8959H11.9115V13.1511C11.9115 13.3885 11.7191 13.5808 11.4818 13.5808C11.2445 13.5808 11.0521 13.3885 11.0521 13.1511V11.8621H9.76304C9.52571 11.8621 9.33335 11.6697 9.33335 11.4324C9.33335 11.195 9.52571 11.0027 9.76304 11.0027H11.0365V9.74748C11.0365 9.51014 11.2288 9.31779 11.4662 9.31779C11.7035 9.31779 11.8959 9.51014 11.8959 9.74748V11.0365Z" fill="#8B8B8B"/>
    </svg>
)

export const MWOInvoice = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8 5.86675H11.7332C11.4385 5.86675 11.2 5.62819 11.2 5.33331H11.1995H11.2C11.2 5.03859 11.4385 4.80003 11.7332 4.80003V4.79955V4.80003H12.8C13.0948 4.80003 13.3332 5.03859 13.3332 5.33331H14.4C14.4 4.44995 13.6833 3.73331 12.8 3.73331V2.66675H11.7332V3.73331C10.8499 3.73331 10.1332 4.44963 10.1332 5.33331C10.1332 6.21667 10.8499 6.93331 11.7332 6.93331H12.8C13.0948 6.93331 13.3332 7.17187 13.3332 7.46675H13.3323C13.3323 7.76147 13.0937 8.00003 12.7988 8.00003H11.7332C11.4385 8.00003 11.2 7.76147 11.2 7.46675H10.1332C10.1332 8.35011 10.8499 9.06675 11.7332 9.06675V10.1333H12.8V9.06675C13.6833 9.06563 14.3995 8.35011 14.3995 7.46675H14.4C14.4 6.58339 13.6833 5.86675 12.8 5.86675Z" fill="#8B8B8B"/>
        <path d="M2.60004 1.6001C2.04775 1.6001 1.60004 2.04781 1.60004 2.6001V12.2668C1.60004 13.4449 2.55524 14.4001 3.73332 14.4001C4.9114 14.4001 5.86676 13.4449 5.86676 12.2668V10.1334H9.06675V2.6001C9.06675 2.04781 8.61904 1.6001 8.06675 1.6001H2.60004ZM2.66676 2.66682H5.86676V3.73338H2.66676V2.66682ZM4.80004 10.1334H2.66676V9.06682H4.80004V10.1334ZM6.93332 8.0001H2.66676V6.93338H6.93332V8.0001ZM8.00004 5.86682H2.66676V4.8001H8.00004V5.86682Z" fill="#8B8B8B"/>
        <path d="M6.93319 11.2V12.2667C6.93319 13.0864 6.62071 13.8332 6.11127 14.4H10.6666C11.8447 14.4 12.7999 13.4448 12.7999 12.2667V12.2C12.7999 11.6477 12.3522 11.2 11.7999 11.2H6.93319Z" fill="#8B8B8B"/>
    </svg>
)

export const MWODelete = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.94804 0.90685C4.52282 0.990171 4.19884 1.2787 4.0571 1.69994C4.02127 1.8064 4.02127 1.81874 4.01504 2.62109L4.01037 3.43578H2.81257C1.63814 3.43578 1.61322 3.43578 1.52755 3.46818C1.42319 3.50675 1.29235 3.62093 1.23628 3.72432C1.21292 3.76597 1.18644 3.84466 1.17865 3.89867C1.15684 4.02982 1.15684 4.54363 1.17865 4.60535C1.21136 4.69638 1.23784 4.70101 1.83596 4.70101H2.37489L2.38268 4.78125C2.38735 4.82599 2.42006 5.47404 2.45432 6.22085C2.48859 6.96761 2.58672 9.02907 2.67239 10.8034C2.8079 13.6533 2.82971 14.0422 2.85774 14.1471C2.95431 14.5143 3.24247 14.8183 3.61474 14.9432L3.73935 14.9849H7.99939H12.2594L12.3794 14.9448C12.5725 14.8784 12.6971 14.8013 12.8435 14.6547C13.0522 14.4464 13.1441 14.2474 13.1722 13.9434C13.1862 13.7875 13.3482 10.4424 13.5444 6.21312C13.5787 5.47096 13.6114 4.82599 13.6161 4.78125L13.6239 4.70101H14.1628C14.761 4.70101 14.7874 4.69638 14.8201 4.60535C14.8419 4.54363 14.8419 4.02982 14.8201 3.89867C14.8123 3.84466 14.7859 3.76597 14.7625 3.72432C14.7064 3.62093 14.5756 3.50675 14.4712 3.46818C14.3855 3.43578 14.3606 3.43578 13.1862 3.43578H11.9884L11.9837 2.62109C11.9775 1.81874 11.9775 1.8064 11.9417 1.69994C11.814 1.31882 11.557 1.06423 11.1691 0.934624L11.06 0.897589L8.03836 0.896047C6.36234 0.894505 4.98543 0.899132 4.94804 0.90685ZM10.7096 2.79544V3.43578H7.99939H5.28916V2.79544V2.15511H7.99939H10.7096V2.79544Z" fill="#8B8B8B"/>
    </svg>
)

export const MWOClose = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5971 5.20857C11.8196 4.98608 11.8196 4.62535 11.5971 4.40286C11.3747 4.18037 11.0139 4.18037 10.7914 4.40286L8 7.19429L5.20857 4.40286C4.98608 4.18037 4.62535 4.18037 4.40286 4.40286C4.18037 4.62535 4.18037 4.98608 4.40286 5.20857L7.19429 8L4.40286 10.7914C4.18037 11.0139 4.18037 11.3747 4.40286 11.5971C4.62535 11.8196 4.98608 11.8196 5.20857 11.5971L8 8.80571L10.7914 11.5971C11.0139 11.8196 11.3747 11.8196 11.5971 11.5971C11.8196 11.3747 11.8196 11.0139 11.5971 10.7914L8.80571 8L11.5971 5.20857Z" fill="#8B8B8B"/>
    </svg>
)