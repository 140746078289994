import React, {useEffect, useMemo, useRef} from 'react';
import moment from "moment";
import {Button, DatePicker, Radio} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import SettingsIcon from '../../../assets/images/SettingsIcon.svg';
import Flex from '../../../noui/Flex';
import {Wrapper, DateTitle, SettingsButton, CurrentDateButton, RadioContainer} from './styled';
import { useState } from 'react';
import CalendarSettings from '../../../modals/CalendarSettings';
import styled from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import CalendarImage from '../../../assets/images/CalendarImage.svg';

const StyledButtonGroup = styled(Button.Group)`
  .ant-btn {
    border: none;
  }
  .ant-btn.ant-btn-icon-only {
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

const StyledDatePicker:React.FunctionComponent<any> = styled(DatePicker)`
  width: 0px;
  height: 0px;
  padding: 0px;
  border: none;
  opacity: 0;
  span {
    display: none;
  }
`;


const MobileWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
  .ant-picker-month-panel {
    .ant-picker-content {
      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .ant-picker-date-panel {
    .ant-picker-content {
      .ant-picker-cell {
        height: 36px;
      }
      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: #109CF1;
        }
      }
      .ant-picker-cell-today {
        background-color: #109CF1;
        border-radius: 50%;
        color: #FFFFFF;
        .ant-picker-cell-inner::before {
          border: none;
        }
      }
    }
  }
`;
const MobileWeek = styled(Flex)`
  margin-top: 10px;
  height: 50px;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
`;

const DayNumber = styled.div<{isCurrent: boolean}>`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.isCurrent ? '#FFFFFF' : '#000000'};
  background-color: ${props => props.isCurrent ? '#109CF1' : 'none'};
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 25px;
  display: inline-block;
  text-align: center;
`;

const DayBlock = styled.div<{isTodayDayOfWeek: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.isTodayDayOfWeek ? "#DCDFE3" : "#FFFFFF"};
  border-left: ${props => props.isTodayDayOfWeek ? "1px solid rgba(0, 0, 0, 0.15)" : "none"};
  border-right: ${props => props.isTodayDayOfWeek ? "1px solid rgba(0, 0, 0, 0.15)" : "none"};
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
`;

const getWeekDays = (date: Date) => {
  const dateArr: Date[] = [];
  const weekStart = moment(date).startOf('week');
  for(let i = 0; i < 7; i++) {
    dateArr.push(weekStart.clone().add(i, 'days').toDate())
  }
  return dateArr;  
}

export const HeaderComponent: React.FC<any> = ({date, onNavigate, localizer, onView, view, views, getView}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const isLg = useBreakpoint(down('lg'));
  const [openPicker, setOpenPicker] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const datePickerRef = useRef<any>(null);

  const options = useMemo(() => {
    const v = views as string[];
    return v.map(value => ({label: localizer.messages[value as keyof typeof localizer['messages']], value}));
  }, [views, localizer]);

  const getDate = () => {
    const mDate = moment(date);
    switch (view) {
      case "day":
        return mDate.format("ddd, D MMM, YYYY")
      case "week":
        return "Week " + mDate.format("w, MMM, YYYY");
      case "month":
      default:
        return mDate.format("MMMM, YYYY");
    }
  }

  const handleDateChange = (e: any) => {
    onNavigate('DATE', e.toDate());
  }


  return (
    <Wrapper style={{flexDirection: isLg ? "column" : "row"}}>
      { isLg ?
      <MobileWrapper>
        <DateTitle width={ref.current?.offsetWidth}>
          {moment(date).format('dddd, MMM Do')}
        </DateTitle>
        <Button style={{padding: "0 5px"}} onClick={() => {
          datePickerRef?.current.focus();
        }}>
          <StyledDatePicker  
          onFocus={() => setOpenPicker(true)}
          onBlur={() => setOpenPicker(false)}
          ref={datePickerRef} 
          open={openPicker} 
          picker={view === "month" ? "month" : "date"}
          getPopupContainer={(trigger: any) => trigger.parentElement} 
          suffixIcon={null} 
          onChange={handleDateChange}
          value={moment(date)}
          />
          {<img src={CalendarImage} />}
        </Button>
      </MobileWrapper> :
      <>
      <DateTitle width={ref.current?.offsetWidth}>
        { moment().format('dddd, MMMM Do') }
      </DateTitle>
      <div>
      <StyledButtonGroup>
        <Button onClick={() => onNavigate("PREV")} icon={<LeftOutlined />} />
        <CurrentDateButton className="dateView">{getDate()}</CurrentDateButton>
        <Button onClick={() => onNavigate("NEXT")} icon={<RightOutlined />} />
      </StyledButtonGroup>
      </div>
      </>}
      <Flex ref={ref} width={isLg ? "100%" : "auto"}>
        <RadioContainer>
          <Radio.Group
            onChange={({target: {value}}) => { 
              onView(value);
            }}
            value={view}
            options={options}
            optionType={"button"}
            buttonStyle="solid"
            />
        </RadioContainer>
       {!isLg && <SettingsButton
          onClick={()=>{setVisible(true)}}
        >
          <img src={SettingsIcon}/>
        </SettingsButton>}
        <CalendarSettings visible={visible} handleClose={setVisible}/>
      </Flex>
      {(isLg && view === 'week') &&
        <MobileWeek>
          {getWeekDays(date).map(d => { 
          const isTodayDayOfWeek = moment(d).format('ddd') === moment(date).format('ddd');           
          return <DayBlock isTodayDayOfWeek={isTodayDayOfWeek} onClick={()=>{onNavigate("DATE", d)}}>
            <Flex style={{fontWeight: "bold"}}>{moment(d).format('ddd')} </Flex>
            <DayNumber isCurrent={moment(d).format('MMM, DD') === moment().format('MMM, DD')}>{moment(d).format('D')}</DayNumber>
          </DayBlock>})}
        </MobileWeek>
      }
    </Wrapper>
  );
};