import { Button, Modal, Spin } from "antd"
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from "react"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { Api } from "../../api/api"
import { ISubscriptionPlan, IUpdatePlan } from "../../api/types"
import { useAppDispatch, useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { ContentStyled } from "../profile/styled"
import { setCurrentUser } from "../../config/reducers/user/actions"
import ChangePlanModal from "../../modals/ChangePlanModal"
import { track } from "../../utils/analytics"
import SubscriptionCollapse from "./SubscriptionCollapse"
import { SubscriptionsContainer } from "./styled"
import CurrentSubscription from "./CurrentSubscriptions"
import PromoCodeField from "../../components/payments/PromoCodeField"
import styled from "styled-components"
import { getPaymentData } from "../../config/reducers/payment/paymentSlice"
import AnnualSelect from "../../components/payments/AnnualSelect"

const PromoContainer = styled.div`
   margin: 24px 0;
   width: 60%;
`

const MySubscription = () => {
   const isXs = useBreakpoint(down("xs"))
   const [isLoading, setIsLoading] = useState(false)
   const [plans, setPlans] = useState<ISubscriptionPlan[]>([])
   const [targetPlan, setTargetPlan] = useState<ISubscriptionPlan | null>(null)
   const [visible, setVisible] = useState(false)
   const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)
   const user = useAppSelector(getCurrentUser)
   const { promoCodeData, payment_period } = useAppSelector(getPaymentData)
   const [openStatus, setOpenStatus] = useState<boolean[]>([])
   const dispatch = useAppDispatch()
   const [isUpgrade, setIsUpgrade] = useState(false)
   const [isUpdating, setIsUpdating] = useState(false)

   useEffect(() => {
      loadData().then()
   }, [])

   useEffect(() => {
      setIsUpgrade((user?.company?.plan?.monthly_price ?? 0) < (targetPlan?.monthly_price ?? 0))
   }, [user, targetPlan])

   const loadData = async () => {
      try {
         setIsLoading(true)
         const res = await Api.config.getSubscriptionPlans()
         setPlans(res.data)
         setOpenStatus(res.data.map((_) => false))
      } catch (err) {
         console.log(err)
      } finally {
         setIsLoading(false)
      }
   }

   const handleSetActive = async (id: number) => {
      if (user?.company?.last_4 || (user?.company && user?.company.stripe_subscription?.last_4)) {
         setTargetPlan(plans.find((plan) => plan.id === id) ?? null)
         setVisible(true)
      }
   }

   const handleChangePlan = async (id: number) => {
      try {
         setIsUpdating(true)
         const updatePlanQuery: IUpdatePlan = {
            plan: id,
            cardId: "",
            plan_period: payment_period,
         }
         if (promoCodeData) {
            updatePlanQuery.promotion_code = promoCodeData.code
         }

         const res = await Api.company.updatePlan(updatePlanQuery)
         const data = await res.data
         if (isUpgrade) {
            await track("Subscription Upgraded", {
               companyId: data.id,
               upgradedById: user?.id,
               previousPlan: user?.company?.plan?.title,
               newPlan: targetPlan?.title,
               totalPaidUSD: (targetPlan?.monthly_price ?? 0) - (user?.company?.plan?.monthly_price ?? 0),
            })
         } else {
            await track("Subscription Downgraded", {
               companyId: data.id,
               downgradedById: user?.id,
               previousPlan: user?.company?.plan?.title,
               newPlan: targetPlan?.title,
               totalPaidUSD: 0,
            })
         }
         const newUser = await (await Api.user.profile()).data
         await dispatch(setCurrentUser(newUser))
         await loadData()
      } catch (err) {
         console.log(err)
      } finally {
         setVisible(false)
         setConfirmationModalVisible(false)
         setIsUpdating(false)
      }
   }

   const handleConfirm = () => handleChangePlan(targetPlan?.id ?? 0)

   return (
      <>
         <Helmet>
            <title>My Subscription - DockWorks Pro</title>
         </Helmet>

         <Flex p={isXs ? "14px" : "34px"} pt="15px" flexDirection="column">
            <Msg fontWeight={800} fontSize={24} mb="18px" mt={isXs ? "16px" : "76px"}>
               My subscription
            </Msg>
            <ContentStyled flexDirection="column" pb={!isXs ? "20px" : "0px"} pt={!isXs ? "30px" : "0px"}>
               <SubscriptionsContainer>
                  <Msg fontWeight={700} fontSize={15} color="#494949">
                     My Plan
                  </Msg>
                  <Spin spinning={isLoading}>
                     {!!(user && plans.length) && (
                        <>
                           <CurrentSubscription
                              planName={user.company?.plan?.title ?? ""}
                              nextBillingDate={
                                 user?.company?.stripe_subscription
                                    ? user.company?.stripe_subscription?.next_payment
                                    : user?.company?.subscription_status?.next_payment ?? ""
                              }
                              last4={user.company?.stripe_subscription?.last_4 ?? ""}
                              cardBrand={user.company?.stripe_subscription?.brand ?? ""}
                           />
                           <PromoContainer>
                              <PromoCodeField />
                           </PromoContainer>

                           <Msg fontWeight={700} fontSize={13} color="#494949">
                              Offers from Dockworks
                           </Msg>

                           <AnnualSelect fromStart />

                           <Flex alignItems="flex-start" flexWrap="wrap">
                              {plans
                                 .filter((s) => s.id !== user.company?.plan?.id)
                                 .map((s, i) => (
                                    <SubscriptionCollapse
                                       key={s.id}
                                       {...s}
                                       setActive={() => handleSetActive(s.id)}
                                       isOpen={openStatus[i]}
                                       setOpen={() => setOpenStatus(openStatus.map((s, j) => (j === i ? !s : s)))}
                                    />
                                 ))}
                           </Flex>
                        </>
                     )}
                  </Spin>
               </SubscriptionsContainer>
            </ContentStyled>
            <ChangePlanModal
               visible={visible}
               onOk={() => setConfirmationModalVisible(true)}
               onCancel={() => setVisible(false)}
               currentPlan={user?.company?.plan ?? null}
               targetPlan={targetPlan}
               isUpgrade={isUpgrade}
               paymentDate={user?.company?.stripe_subscription?.next_payment ?? ""}
               last_4={user?.company?.stripe_subscription?.last_4 ?? ""}
            />
            <Modal
               visible={confirmationModalVisible}
               title="Confirmation"
               okText="Finish & proceed"
               onCancel={() => setConfirmationModalVisible(false)}
               onOk={handleConfirm}
               width={384}
               centered
               footer={[
                  <Button key="submit" type="primary" onClick={handleConfirm} loading={isUpdating}>
                     Finish & proceed
                  </Button>,
               ]}
            >
               <Msg>You have successfully subscribed for {targetPlan?.title ?? ""} plan</Msg>
            </Modal>
         </Flex>
      </>
   )
}

export default MySubscription
