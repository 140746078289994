import { memo, useMemo } from "react";
import { IWorkOrder, IWorkOrderWithJobs } from "../../api/types";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import Flex from "../../noui/Flex";
import { Customer, CustomerInfo, DetailsContainer, DetailsDiv, Header, HeaderInfo, Info, JobInfo, JobTitle, Materials, PaymentStatus, Services, Summary, TableDiv, TableItem, Technician, VesselBottom, VesselHeader, VesselImg, WOInfo, WOTitle } from "../CustomerLayout/styled";
import DEFAULT_LOGO from "../../assets/images/gear_logo.png"
import { formatToUSD, getAddress, renderAddress } from "../../utils/utils";
import moment from "moment";
import { servicesUnitTypes, vesselTypes } from "../../constants";

type TRPMDetailsProps = {
    workOrder?: IWorkOrderWithJobs | IWorkOrder | null
    jobs?: any[]
    amount?: number
}

const RPMDetails: React.FC<TRPMDetailsProps> = ({workOrder, jobs, amount}) => {
    const user = useAppSelector(getCurrentUser)
    const formattedAmount = useMemo(() => {
        if (!amount) return 0;
        return +amount;
    }, [amount])

    const formatPrice = (price: number | undefined) =>
        price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)


    if (!workOrder || !jobs || !user) return null;
    
    return (
        <DetailsContainer>
         <Header alignItems="center" className="topHeader">
            <Flex justifyContent="center">
               <div style={{ width: "150px", height: "90px" }}>
                  <img src={user?.company?.logo || DEFAULT_LOGO} alt="Logo" />
               </div>
            </Flex>
            <HeaderInfo style={{marginLeft: "25px"}}>
               <Flex>
                  <Flex flexDirection="column">
                     <h3>COMPANY</h3>
                     <h4>{user?.company?.name}</h4>
                     <p>{getAddress(user?.company?.address) || "-"}</p>
                     <p> {user?.company?.phone || "-"}</p>
                     <p style={{ color: "#0496FF" }}>{user?.company?.email || "-"}</p>
                  </Flex>

                  <Flex alignItems="flex-start" flexDirection="column" marginLeft="80px">
                     <h3>BILLING CONTACT</h3>
                     <h4>{workOrder?.customer?.full_name}</h4>
                     <p>
                        {workOrder?.customer?.address
                           ? renderAddress(workOrder?.customer?.address)
                           : "No data"}
                     </p>
                     <p>
                        {workOrder?.customer?.phones.find(
                           (p: { phone_type: string }) => p.phone_type === "P"
                        )?.phone || "No data"}
                     </p>
                     <p style={{ color: "#0496FF" }}>{workOrder?.customer?.email}</p>
                  </Flex>
               </Flex>
            </HeaderInfo>
         </Header>

         <WOInfo alignItems="center" justifyContent="space-between">
              <h3>WORK ORDER INVOICE</h3>
  
              {/* <p>Sent: {moment(workOrder?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p> */}
           </WOInfo>
           <WOTitle alignItems="center" justifyContent="space-between">
              <h3>{workOrder?.title}</h3>
              <p>
                 <span>Work order:</span> #{workOrder?.order_number}
              </p>
           </WOTitle>
           <Info justifyContent="normal">
              <div className="infoBlock" style={{width: "50%"}}>
                 <Customer>
                    <CustomerInfo className="leftAligned">
                       <div>Service Writer: </div>
                       <div className="title">{workOrder?.created_by?.full_name}</div>
                    </CustomerInfo>
                    <CustomerInfo className="leftAligned">
                       <div>Created on: </div>
                       <div className="title">
                          {workOrder?.created
                             ? moment(workOrder?.created).format("ddd, M/D/YYYY h:mm A")
                             : "No data"}
                       </div>
                    </CustomerInfo>
                 </Customer>
                 <DetailsDiv>
                    <h4>Project Details</h4>
                    <div>{workOrder?.notes || "No notes provided"}</div>
                 </DetailsDiv>
                 <DetailsDiv>
                    <h4>SCOPE OF WORK</h4>
                    <div>
                       {jobs && jobs.length > 0 && (
                          <ul>
                             {jobs.map((job: any) => {
                                return (
                                   <li key={job?.id}>
                                      {job.title} <span>({job.order_number})</span>
                                   </li>
                                )
                             })}
                          </ul>
                       )}
                    </div>
                    <h5>(Full project summary listed below)</h5>
                 </DetailsDiv>
                 <Customer>
                    <CustomerInfo className="leftAligned">
                       <div>Due Date: </div>
                       <div className="title">
                          {moment(workOrder?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                       </div>
                    </CustomerInfo>
                    <CustomerInfo className="leftAligned">
                       <div>Est. Duration: </div>
                       <div className="title">{workOrder?.estimated_duration ?? "TBD"}</div>
                    </CustomerInfo>
                 </Customer>
              </div>
              <div className="infoBlock" style={{ border: "1px solid #ededed", width: "50%" }}>
                 <VesselHeader>
                    <CustomerInfo>
                       <div>Vessel:</div>
                       <div style={{ fontSize: "15px", fontWeight: "bold" }}>{workOrder?.vessel?.name}</div>
                    </CustomerInfo>
                    <CustomerInfo>
                       <div>Hull ID:</div>
                       <div className="title">{workOrder?.vessel?.hin}</div>
                    </CustomerInfo>
                 </VesselHeader>
                 <VesselBottom>
                    <CustomerInfo>
                       <div>Vessel Type:</div>
                       <div className="title">
                          {vesselTypes.find((v) => v.value === workOrder?.vessel?.vessel_type)?.label ||
                             "No data"}
                       </div>
                    </CustomerInfo>
                    <CustomerInfo>
                       <div>Location:</div>
                       <div className="title">{workOrder?.vessel?.location_name}</div>
                    </CustomerInfo>
                    <CustomerInfo>
                       <div>Address:</div>
                       <div className="title">{getAddress(workOrder?.vessel?.address) || "-"}</div>
                    </CustomerInfo>
                    <CustomerInfo>
                       <div>Slip #:</div>
                       <div className="title">{workOrder?.vessel?.slip_number}</div>
                    </CustomerInfo>
                    {workOrder?.vessel?.image && (
                       <VesselImg>{<img src={workOrder?.vessel?.image} alt="vessel" />}</VesselImg>
                    )}
                 </VesselBottom>
              </div>
           </Info>
           <PaymentStatus>
              <h3>PAYMENT STATUS</h3>
              <Flex>
                 <div className="paymentBlock">
                    <p>{user?.company?.invoice_settings?.payment_status_wo}</p>
                 </div>
                 <div className="paymentBlock">
                    <Customer>
                       <CustomerInfo>
                          <div>Work Order Total:</div>
                          <div>{formatToUSD(workOrder?.total)}</div>
                       </CustomerInfo>
                       <CustomerInfo>
                          <div style={{ fontWeight: "bold", color: "#42C77B" }}>Paid To-Date:</div>
                          <div style={{ fontWeight: "bold", color: "#42C77B" }}>
                             {formatToUSD(workOrder?.deposited)}
                          </div>
                       </CustomerInfo>
                       <div className="divider" />
                       <CustomerInfo>
                          <div style={{ color: "#FB4D4F" }}>Payment Due (Deposit):</div>
                          <div
                             style={{
                                color: "#FB4D4F",
                                fontWeight: "bold",
                                fontSize: "15px",
                             }}
                          >
                             
                             {formatToUSD(formattedAmount)}
                          </div>
                       </CustomerInfo>
                       <CustomerInfo>
                          <div style={{ color: "#8B8B8B" }}>Remaining after this payment: </div>
                          <div style={{ color: "#8B8B8B" }}>{formatToUSD((workOrder?.total ?? 0) - (amount ?? 0))}</div>
                       </CustomerInfo>
                    </Customer>
                 </div>
              </Flex>
           </PaymentStatus>
           {jobs &&
              jobs.length > 0 &&
              jobs.map((job: any) => {
                 return (
                    <div key={job?.id}>
                       <div className="noBreak">
                          <JobTitle justifyContent="space-between">
                             <h3>{job.title}</h3>
                             <p>(Job {job.order_number})</p>
                          </JobTitle>
                          <JobInfo alignItems="center" justifyContent="space-between">
                             <div>
                                Start Date & Time:{" "}
                                <span>{moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}</span>
                             </div>
                             <div>
                                Estimated Duration: <span>{job?.estimated_duration ?? "TBD"}</span>
                             </div>
                          </JobInfo>
                       </div>
                       <TableDiv>
                          {job?.services && job.services.length > 0 && (
                             <Services className="noBreak">
                                <div className={`grid header`}>
                                   <div className="name">LABOR</div>
                                   <div className="desc">Description</div>
                                   <div className="qty">Qty</div>
                                   <div className="rate">Rate</div>
  
                                   <div className="type">Type</div>
                                   <div className="total">Total</div>
                                </div>
  
                                {job.services.map((item: any) => {
                                   const type = servicesUnitTypes.find((s) => s.value === item.unit_type)
                                   return (
                                      <TableItem key={item.id}>
                                         <div className={`grid `}>
                                            <div className="name">{item.name}</div>
                                            <div className="desc">{item.description}</div>
                                            <div className="qty">{item.qty.toFixed(2)}</div>
  
                                            <div className="rate">{formatToUSD(item.unit_price)}</div>
  
                                            <div className="type">{type && type.label}</div>
  
                                            <div className="total" style={{ fontWeight: "bold" }}>
                                               {formatToUSD(item.unit_price * item.qty)}
                                            </div>
                                         </div>
                                      </TableItem>
                                   )
                                })}
                                {job?.technicians_data && job.technicians_data.length > 0 && (
                                   <Technician>
                                      <div className={`grid header`}>
                                         <div />
                                         <div>Technician(s)</div>
                                         <div>Estimated Hours</div>
                                         <div />
                                         <div />
                                         <div />
                                      </div>
  
                                      {job.technicians_data.map((item: any) => {
                                         return (
                                            <div key={item.id} className={`grid`}>
                                               <div />
                                               <div>{item?.technician?.full_name}</div>
                                               <div>{item.estimated_hours}</div>
                                               <div />
                                               <div />
                                               <div />
                                            </div>
                                         )
                                      })}
                                   </Technician>
                                )}
                             </Services>
                          )}
                       </TableDiv>
                       <TableDiv>
                          {job?.materials && job.materials.length > 0 && (
                             <Materials className="noBreak">
                                <div className={`grid header `}>
                                   <div className="name">MATERIALS</div>
                                   <div className="desc">Description</div>
                                   <div className="qty">Qty</div>
                                   <div className="price">Unit price</div>
                                   <div className="total">Total</div>
                                </div>
  
                                {job.materials.map((item: any) => {
                                   return (
                                      <TableItem key={item.id}>
                                         <div className={`grid`}>
                                            <div className="name">{item.name}</div>
  
                                            <div>{item.description}</div>
                                            <div className="qty">{item.qty.toFixed(2)}</div>
                                            <div className="price">{formatToUSD(item.unit_price)}</div>
                                            <div className="total" style={{ fontWeight: "bold" }}>
                                               {formatToUSD(item.unit_price * item.qty)}
                                            </div>
                                         </div>
                                      </TableItem>
                                   )
                                })}
                             </Materials>
                          )}
                       </TableDiv>
  
                       <div className="noBreak">
                          <Summary>
                             <div className="disclaimers">
                                <h3>DISCLAIMERS</h3>
                                {/* {isInvoice ? ( */}
                                   <p>{user?.company?.invoice_settings?.invoice_footer}</p>
                                {/* ) : (
                                   <p>{estimate?.company?.invoice_settings?.estimate_footer}</p>
                                )} */}
                             </div>
                             <div className="summary">
                                <Flex justifyContent="space-between">
                                   <div>Labor</div>
                                   <div>{formatToUSD(job.total_labor)}</div>
                                </Flex>
                                <Flex justifyContent="space-between">
                                   <div>Materials</div>
                                   <div>{formatToUSD(job.total_materials)}</div>
                                </Flex>
                                <Flex justifyContent="space-between">
                                   <div>Subtotal</div>
                                   <div>{formatToUSD(job !== null ? job.total_labor + job.total_materials : 0)}</div>
                                </Flex>
                                <Flex justifyContent="space-between">
                                   <div>Taxes</div>
                                   <div>{formatToUSD(job !== null ? job.tax : 0)}</div>
                                </Flex>
                                <Flex justifyContent="space-between">
                                   <div>EPA fees</div>
                                   <div>{formatToUSD(job !== null ? job.epa : 0)}</div>
                                </Flex>
                                <Flex className="total" justifyContent="space-between">
                                   <div>Job total</div>
                                   <div>
                                      {formatToUSD(
                                         job !== null ? job.total_labor + job.total_materials + job.tax + job.epa : 0
                                      )}
                                   </div>
                                </Flex>
                             </div>
                          </Summary>
                       </div>
  
                       {/* <Photos job={job} /> */}
                    </div>
                 )
              })}
      </DetailsContainer>
    );
}

// export { RPMDetails }

export default memo(RPMDetails)