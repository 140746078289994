import React, { useState, useEffect, useCallback, useRef } from "react"
import { useLocation, NavLink, useHistory } from "react-router-dom"
import { Dropdown, Menu, Calendar, notification, Modal, Image, Spin } from "antd"
import { CalendarOutlined, UserOutlined, DeleteFilled } from "@ant-design/icons"
import moment from "moment"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import LocationModal from "../../../modals/Location"
import { Msg } from "../../../ui/Text"
import { EmptyButton } from "../../../ui/Button"
import Flex from "../../../noui/Flex"
import { VesselsModal } from "../../vessels/VesselsModal"
import { Content } from "../../../components/Content"
import { SearchWithBorder } from "../../../noui/Search"
import Notes from "./Notes"
import { Api } from "../../../api/api"
import { GoogleMapsAPIKey, instance } from "../../../api"
import { renderAddress } from "../../../utils/utils"
import { validateLatitude, validateLongtitude } from "../../../utils/utils"
import {
   ContentStyled,
   StepBackButton,
   LeftArrow,
   InfoSection,
   InfoItem,
   InfoTitle,
   EditButton,
   WholeWidth,
   VesselInfo,
   VesselText,
   ServiceItem,
   CalendarBtn,
   VesselPhoto,
   GalleryVessel,
   DateSelector,
   MapWrap,
   Marker,
   UserAvatar,
   ImageItem,
   DeleteImage,
   AddAvatarText,
   VesselAvatar,
} from "./styled"
import routes from "../../../routes/routes"
import JobRow from "./JobRow"
import { vesselTypes } from "../../../constants"
import { BoldSpan } from "../../../layouts/WorkOrderFlow/styled"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import AddVesselAvatar from "../../../assets/images/AddVesselAvatar.svg"
import { DeleteImgBtn } from "../../../layouts/WorkOrderFlow/styled"
import LargeDelete from "../../../assets/images/ImageDeleteLarge.svg"
import { useLoading, useTechnicianRole } from "../../../utils/hooks"
import { RecurringJobIcon, WorkOrderIcon } from "../../../assets/icons"
import { Upload } from "antd"

const { Dragger } = Upload

const LocationVesselInfo = styled(Flex)`
   flex-direction: column;
   margin-top: 16px;
   width: 120px;
`

const VesselPage = () => {
   const location = useLocation()
   const imageUpload = useRef(null)
   const avatarUpload = useRef(null)
   const [loading, setLoading] = useState(false)
   const [vessel, setVessel] = useState({})
   const [edit, setEdit] = useState(false)
   const [services, setServices] = useState([])
   const [previousOwners, setPreviousOwners] = useState([])
   const [dropdownVisible, setDropdownVisible] = useState(false)
   const [modalVisible, setModalVisible] = useState(false)
   const [dates, setDates] = useState({
      startDate: moment().startOf("year"),
      endDate: moment(),
   })
   const [dateMode, setDateMode] = useState("start")
   const [images, setImages] = useState([])
   const [search, setSearch] = useState("")
   const [showMore, setShowMore] = useState(false)
   const [showOwners, setShowOwners] = useState(false)
   const [mapsOptions, setMapsOptions] = useState({
      ...(validateLatitude(vessel?.address?.lat) &&
         validateLongtitude(vessel?.address?.lng) && {
            center: {
               lat: vessel?.address.lat,
               lng: vessel?.address.lng,
            },
         }),
      zoom: 1,
      mapTypeControl: true,
   })
   const history = useHistory()
   const technicianRole = useTechnicianRole()
   const routeParams = location.pathname.split("/")
   const vesselId = routeParams[routeParams.length - 1]
   const [imageLoading, setImageLoading] = useState(false)
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))
   const isLg = useBreakpoint(down("lg"))
   const [viewAll, setViewAll] = useState(false)
   const [isLoadingAvatar, loadAvatar] = useLoading()

   const loadCards = useCallback(async () => {
      setLoading(true)
      try {
         const { data } = await Api.vessels.get(vesselId)
         const { data: jobsData } = await instance.get(`/jobs?vessel=${vesselId}`)
         const { data: images } = await Api.vesselsGallery.getAll({ vessel: vesselId })
         const jobs = await Promise.all(
            jobsData.map(async (j) => {
               const { data } = await instance.get(`inspections?job=${j.id}`)
               return {
                  ...j,
                  images: data.map((d) => d.images).length ? data.map((d) => d.images)[0] : [],
               }
            })
         )
         await setVessel(data)
         await setServices(jobs)
         await setVessel(data)
         await setImages(images)
         if (data.customers && data.customers.length > 1) {
            const previousOwners = []
            data.customers.forEach((customer, index, array) => {
               if (index !== array.length - 1) {
                  previousOwners.push({ ...customer, created: data.customers[index + 1].created })
               }
            })
            setPreviousOwners(previousOwners)
         }
      } catch (e) {
         history.push(routes.errorPage.Base)
         // notification.error({
         //   description: 'Something went wrong'
         // });
      } finally {
         setLoading(false)
      }
   }, [vesselId])

   useEffect(() => {
      if (vessel?.address?.lat && vessel?.address?.lng) {
         setMapsOptions({
            zoom: 1,
            mapTypeControl: true,
            ...(validateLatitude(vessel?.address?.lat) &&
               validateLongtitude(vessel?.address?.lng) && {
                  center: {
                     lat: vessel?.address.lat,
                     lng: vessel?.address.lng,
                  },
                  zoom: 10,
               }),
         })
      }
   }, [vessel])

   useEffect(() => {
      window.addEventListener("scroll", () => setDropdownVisible(false))
      loadCards()
      return () => window.removeEventListener("scroll", () => setDropdownVisible(false))
   }, [loadCards])

   const renderVesselInfo = (data) => ({
      "Vessel Name": data.name,
      "Vessel Type": vesselTypes.find((v) => v.value === data.vessel_type)?.label || "No data",
      Length: `${data.length} ft`,
      Beam: data.beam ? `${data.beam} ft` : "No data",
      Draft: data.draft ? `${data.draft} ft` : "No data",
      Model: data.model,
      "Number of engines": data.number_of_engines,
      "Registration number": data.registration_number,
      "Hailing port": data.hailing_port,
      "Year built": data.year,
      Manufacturer: data.manufacturer,
      "Engine model": data.engines?.length ? data.engines[0].model : "No engines",
      "Hull ID": data.hin,
      "USCG Number": data.uscg_doc,
   })

   const CalendarOverlay = () => (
      <Menu style={{ width: isXs ? "300px" : "382px" }}>
         <Flex flexDirection="column">
            <Flex py="16px" px="20px" flexDirection="column">
               <InfoTitle>Calendar</InfoTitle>
               <Flex justifyContent="space-between" alignItems="flex-end" mt="16px">
                  <Flex flexDirection="column" style={{ width: "45%" }}>
                     <Msg fontSize={13} fontWeight={700}>
                        From
                     </Msg>
                     <DateSelector
                        focus={dateMode === "start"}
                        onClick={() => {
                           setDateMode("start")
                        }}
                     >
                        <Msg color={dateMode === "start" ? "#40a9ff" : "#828282"} fontSize={13}>
                           {dates.startDate.format("L")}
                        </Msg>
                     </DateSelector>
                  </Flex>
                  <div
                     style={{
                        height: "1px",
                        width: "22px",
                        backgroundColor: "rgba(0, 0, 0, 0.15)",
                        marginBottom: "16px",
                     }}
                  ></div>
                  <Flex flexDirection="column" style={{ width: "45%" }}>
                     <Msg fontSize={13} fontWeight={700}>
                        To
                     </Msg>
                     <DateSelector
                        focus={dateMode === "end"}
                        onClick={() => {
                           setDateMode("end")
                        }}
                     >
                        <Msg color={dateMode === "end" ? "#40a9ff" : "#828282"} fontSize={13}>
                           {dates.endDate.format("L")}
                        </Msg>
                     </DateSelector>
                  </Flex>
               </Flex>
            </Flex>
            <Calendar
               mode="month"
               value={dateMode === "start" ? dates.startDate : dates.endDate}
               validRange={[
                  dateMode === "end" ? dates.startDate : moment().subtract(5, "year").startOf("year"),
                  moment(),
               ]}
               onChange={(date) => {
                  if (dateMode === "start") {
                     setDates({
                        ...dates,
                        startDate: date,
                     })
                  } else if (dateMode === "end") {
                     setDates({
                        ...dates,
                        endDate: date,
                     })
                  }
               }}
               fullscreen={false}
            />
         </Flex>
      </Menu>
   )

   const filterBySearch = (service) => service.title.toLowerCase().includes(search.toLowerCase())
   const filterByDate = (services) =>
      services.filter((s) => {
         const serviceDate = moment(s.created)
         return serviceDate.isSameOrAfter(dates.startDate) && serviceDate.isSameOrBefore(moment(dates.endDate))
      })
   const showServices = () => {
      const filteredByParameters = filterByDate(services).filter((s) => filterBySearch(s))
      return showMore ? filteredByParameters : filteredByParameters.slice(0, 5)
   }

   const editNotes = async (notes) => {
      try {
         await Api.vessels.updateNotes({ id: vessel?.id, notes: notes })
         await loadCards()
         notification.success({
            message: "Notes saved successfully",
         })
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => resolve(reader.result)
         reader.onerror = (error) => reject(error)
      })
   }

   const uploadImage = (e) => {
      ;[...e.target.files].forEach((file) => {
         getBase64(file).then((image) =>
            Api.vesselsGallery
               .addPhoto({
                  vessel: vessel?.id,
                  photos: [image],
               })
               .then(async (res) => {
                  try {
                     setImageLoading(true)
                     const { data } = await Api.vesselsGallery.getAll({ vessel: vesselId })
                     setImages(data)
                  } finally {
                     setImageLoading(false)
                  }
               })
         )
      })
   }

   const deleteImage = (id) => {
      Api.vesselsGallery
         .removePhoto({
            photos: [id],
         })
         .then(async () => {
            const { data } = await Api.vesselsGallery.getAll({ vessel: vesselId })
            setImages(data)
         })
   }

   const uploadAvatar = async (e) => {
      const image = await getBase64(e)
      const { data: _vessel } = await loadAvatar(Api.vessels.updateAvatar({ id: vessel?.id, avatar: image }))
      setVessel(_vessel)
      return false
   }

   const removeAvatar = () => {
      Api.vessels.removeAvatar({ id: vessel?.id }).then((res) => {
         setVessel(res.data)
      })
   }

   const toggleViewAll = () => setViewAll((prev) => !prev)

   const filterPhotos = (_, index) => {
      if (!viewAll) {
         if (isXs) {
            return index < 8
         }
         return index < 15
      }
      return true
   }

   const showViewButton = () => (isXs && images.length > 8) || images.length > 15

   return (
      <Flex p={isLg ? "14px" : "34px"} pt="15px" flexDirection="column">
         {!isXs && (
            <Msg fontWeight={800} fontSize={24} mb="18px" mt="76px">
               Vessels
            </Msg>
         )}
         <ContentStyled flexDirection="column" py="20px" px={isLg ? "10px" : "24px"}>
            {!technicianRole && (
               <Flex justifyContent="space-between" alignItems="center" mb="16px">
                  <StepBackButton onClick={() => history.go(-1)}>
                     <Flex alignItems="center">
                        <LeftArrow />
                        Back to {location?.state?.fromWO ? "Work Order" : "Vessels List"}
                     </Flex>
                  </StepBackButton>
               </Flex>
            )}
            <Flex justifyContent="space-between">
               <InfoSection width={isLg ? 100 : 40}>
                  <InfoItem pb="0px">
                     <WholeWidth justifyContent="space-between" mb="16px">
                        <InfoTitle>Vessel information</InfoTitle>
                        {!technicianRole && <EditButton onClick={() => setEdit(true)}>Edit</EditButton>}
                     </WholeWidth>
                     <Content loading={loading} noItems={!Object.values(vessel).length} noItemsLabel={"No vessel info"}>
                        <VesselsModal
                           visible={edit}
                           payload={vessel}
                           onClose={() => setEdit(false)}
                           onSuccess={loadCards}
                        />
                        <Spin spinning={isLoadingAvatar}>
                           {!vessel?.image ? (
                              <Dragger
                                 maxCount={1}
                                 multiple={false}
                                 accept="image/*"
                                 showUploadList={false}
                                 beforeUpload={uploadAvatar}
                                 disabled={isLoadingAvatar}
                              >
                                 <img src={AddVesselAvatar} alt="Add avatar" style={{ marginBottom: "24px" }} />
                                 <AddAvatarText>Shop Photo</AddAvatarText>
                                 <AddAvatarText>Drag image here or click to add</AddAvatarText>
                              </Dragger>
                           ) : (
                              <VesselAvatar>
                                 <img src={vessel?.image} alt="Avatar" />
                                 <DeleteImgBtn onClick={removeAvatar}>
                                    <img src={LargeDelete} alt="delete" />
                                 </DeleteImgBtn>
                              </VesselAvatar>
                           )}
                        </Spin>
                        <Flex width="100%" flexDirection="row" justifyContent="space-between" marginBottom="10px">
                           <Flex flexDirection="column">
                              {Object.entries(renderVesselInfo(vessel))
                                 .filter((e, i, a) => i >= 0 && i < a.length / 2)
                                 .map((e) => {
                                    return (
                                       <VesselInfo key={`${e[0]}-${e[1]}`}>
                                          <VesselText fontWeight={700}>{e[0]}</VesselText>
                                          <VesselText>{e[1] && e[1].toString() ? e[1] : "No data"}</VesselText>
                                       </VesselInfo>
                                    )
                                 })}
                           </Flex>
                           <Flex flexDirection="column">
                              {Object.entries(renderVesselInfo(vessel))
                                 .filter((e, i, a) => i >= a.length / 2)
                                 .map((e) => (
                                    <VesselInfo key={`${e[0]}-${e[1]}`}>
                                       <VesselText fontWeight={700}>{e[0]}</VesselText>
                                       <VesselText>{e[1] && e[1].toString() ? e[1] : "No data"}</VesselText>
                                    </VesselInfo>
                                 ))}
                           </Flex>
                        </Flex>
                        <Flex>
                           <Modal
                              visible={showOwners}
                              onCancel={() => setShowOwners(false)}
                              footer={null}
                              title="Previous owners list"
                           >
                              <Flex alignItems="center">
                                 <Msg fontSize={13} fontWeight={700} style={{ width: "50%" }}>
                                    Owner Name
                                 </Msg>
                                 <Flex justifyContent="space-between" style={{ width: "50%" }}>
                                    <Msg fontSize={13} fontWeight={700}>
                                       Sold
                                    </Msg>
                                    <Msg fontSize={13} fontWeight={700}>
                                       Location
                                    </Msg>
                                 </Flex>
                              </Flex>
                              <Flex flexDirection="column" py="16px">
                                 {previousOwners.map((c) => {
                                    const {
                                       customer: { avatar, address, first_name, last_name },
                                    } = c
                                    return (
                                       <Flex key={c.id} mb="16px" alignItems="center">
                                          <Flex style={{ width: "50%" }} alignItems="center">
                                             <UserAvatar src={avatar} alignItems="center" justifyContent="center">
                                                {!avatar && <UserOutlined />}
                                             </UserAvatar>
                                             <Msg fontSize={13} ml={16}>{`${first_name} ${last_name}`}</Msg>
                                          </Flex>
                                          <Flex
                                             justifyContent="space-between"
                                             alignItems="center"
                                             style={{ width: "50%" }}
                                          >
                                             <Msg fontSize={13} fontWeight={700}>
                                                {moment(c.created).format("L")}
                                             </Msg>
                                             <Msg
                                                fontSize={13}
                                                fontWeight={700}
                                                style={{ maxWidth: "50%", textAlign: "end" }}
                                             >
                                                {renderAddress(address)}
                                             </Msg>
                                          </Flex>
                                       </Flex>
                                    )
                                 })}
                              </Flex>
                           </Modal>
                           {previousOwners.length ? (
                              <EmptyButton
                                 width="100%"
                                 onClick={() => setShowOwners(!showOwners)}
                                 style={{ boxShadow: "none" }}
                              >
                                 <Msg fontWeight={600} fontSize={14} color="#109CF1">
                                    View previous owners
                                 </Msg>
                              </EmptyButton>
                           ) : (
                              <Msg fontSize={14} fontWeight={600} style={{ width: "100%", textAlign: "center" }}>
                                 No previous owners
                              </Msg>
                           )}
                        </Flex>
                     </Content>
                  </InfoItem>
                  <InfoItem my="24px">
                     <Flex justifyContent="space-between" alignItems="center">
                        <InfoTitle>Location</InfoTitle>
                        {!technicianRole && (
                           <EmptyButton onClick={() => setModalVisible(true)}>
                              <Msg
                                 fontWeight={600}
                                 fontSize={14}
                                 color="#109CF1"
                                 style={{ textDecoration: "underline" }}
                              >
                                 Set location
                              </Msg>
                           </EmptyButton>
                        )}

                        <LocationModal
                           vessel={vessel}
                           visible={modalVisible}
                           handleClose={() => setModalVisible(false)}
                           loadCards={loadCards}
                        />
                     </Flex>
                     <Content
                        loading={loading}
                        noItems={!vessel?.address || !Object.values(vessel?.address).length}
                        noItemsLabel={"No location provided"}
                     >
                        <MapWrap my="8px">
                           <GoogleMapReact
                              bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
                              defaultCenter={mapsOptions.center ?? { lat: 42, lng: -70 }}
                              defaultZoom={mapsOptions.zoom}
                              mapTypeId="satellite"
                              options={mapsOptions}
                           >
                              {validateLatitude(vessel?.address?.lat) && validateLongtitude(vessel?.address?.lng) && (
                                 <Marker lat={vessel?.address?.lat || 0} lng={vessel?.address?.lng || 0} />
                              )}
                           </GoogleMapReact>
                        </MapWrap>
                        {!isLg && (
                           <Flex flexDirection="column">
                              <LocationVesselInfo>
                                 <VesselText fontWeight={700}>Location address</VesselText>
                                 <VesselText>{vessel?.address && renderAddress(vessel?.address)}</VesselText>
                              </LocationVesselInfo>
                              <Flex justifyContent="space-between">
                                 <LocationVesselInfo>
                                    <VesselText fontWeight={700}>Latitude</VesselText>
                                    <VesselText>{vessel?.address?.lat || "No data"}</VesselText>
                                 </LocationVesselInfo>
                                 <LocationVesselInfo>
                                    <VesselText fontWeight={700}>Longitude</VesselText>
                                    <VesselText>{vessel?.address?.lng || "No data"}</VesselText>
                                 </LocationVesselInfo>
                              </Flex>
                              <Flex justifyContent="space-between">
                                 <LocationVesselInfo>
                                    <VesselText fontWeight={700}>Location name</VesselText>
                                    <VesselText>{vessel?.location_name || "No data"}</VesselText>
                                 </LocationVesselInfo>
                                 <LocationVesselInfo>
                                    <VesselText fontWeight={700}>Slip number</VesselText>
                                    <VesselText>{vessel?.slip_number || "No data"}</VesselText>
                                 </LocationVesselInfo>
                              </Flex>
                              {/* <LocationVesselInfo>
                    <VesselText fontWeight={700}>Notes</VesselText>
                    <VesselText>{vessel?.notes || 'No data'}</VesselText>
                  </LocationVesselInfo> */}
                           </Flex>
                        )}
                     </Content>
                  </InfoItem>
                  {!isLg && (
                     <Notes
                        notes={vessel?.notes ?? ""}
                        loading={loading}
                        noItems={!Object.values(vessel).length}
                        onSuccess={editNotes}
                     />
                  )}
                  {isLg && (
                     <InfoItem bottom={services.length}>
                        <WholeWidth flexDirection="column" width="100%">
                           <Flex
                              flexDirection="row"
                              alignItems="start"
                              justifyContent="space-between"
                              width="100%"
                              marginBottom="10px"
                           >
                              <InfoTitle>Service history</InfoTitle>
                              <Flex alignItems="center">
                                 <Dropdown
                                    trigger={["click"]}
                                    overlay={CalendarOverlay}
                                    visible={dropdownVisible}
                                    onVisibleChange={() => {
                                       setDropdownVisible(!dropdownVisible)
                                    }}
                                    arrow
                                    placement="bottomRight"
                                    style={{ boxShadow: "none" }}
                                 >
                                    <CalendarBtn onClick={() => setDropdownVisible(!dropdownVisible)}>
                                       <CalendarOutlined />
                                    </CalendarBtn>
                                 </Dropdown>
                              </Flex>
                           </Flex>
                           <SearchWithBorder
                              onChange={(e) => setSearch(e.target.value)}
                              disabled={!services.length}
                              placeholder="Search jobs, service types..."
                           />
                        </WholeWidth>
                        <Flex flexDirection="column" mt="16px">
                           <Content
                              loading={loading}
                              noItems={!services.length}
                              noItemsLabel={"No services are applied yet"}
                           >
                              {showServices().length ? (
                                 <>
                                    {showServices().map((j) => (
                                       <ServiceItem key={j.id} alignItems="center">
                                          <Flex
                                             flexDirection="column"
                                             ml="16px"
                                             style={{ width: j.images.length && !isXs ? "calc(100% - 196px)" : "100%" }}
                                          >
                                             <Flex alignItems="center">
                                                {j.is_recurring ? <RecurringJobIcon /> : <WorkOrderIcon />}

                                                <NavLink
                                                   style={{ fontWeight: "bold", marginLeft: "7px" }}
                                                   to={
                                                      routes.workOrder.UpdateWorkOrder.replace(
                                                         ":id",
                                                         `${j.work_order}`
                                                      ) + `?job=${j.id}`
                                                   }
                                                >
                                                   {j.title}
                                                </NavLink>
                                             </Flex>

                                             <Msg fontSize={13} color="#828282" mt="11px">
                                                {j.materials.length ? (
                                                   <>
                                                      <BoldSpan>Materials: </BoldSpan>
                                                      <span>
                                                         {j.materials.map((material) => (
                                                            <React.Fragment key={material.name}>
                                                               {`${material.name}  ${
                                                                  material.description ? material.description : ""
                                                               } `}
                                                            </React.Fragment>
                                                         ))}
                                                         .
                                                      </span>
                                                   </>
                                                ) : (
                                                   ""
                                                )}
                                             </Msg>
                                             <Msg fontSize={13} color="#828282" mt="11px">
                                                {j.services.length ? (
                                                   <>
                                                      <BoldSpan>Services: </BoldSpan>
                                                      <span>
                                                         {j.services.map((service) => (
                                                            <React.Fragment key={service.name}>
                                                               {`${service.name}  ${
                                                                  service.description ? service.description : ""
                                                               } `}
                                                            </React.Fragment>
                                                         ))}
                                                      </span>
                                                   </>
                                                ) : (
                                                   ""
                                                )}
                                             </Msg>
                                             <Msg fontSize={13} color="#828282" mt="11px">
                                                {moment(j.created).format("M/D/YYYY")}
                                             </Msg>
                                          </Flex>
                                          {j.images.length ? (
                                             <VesselPhoto src={j.images[0].image} alt="Vessel photo" />
                                          ) : (
                                             ""
                                          )}
                                       </ServiceItem>
                                    ))}
                                    <EmptyButton
                                       width="100%"
                                       height="45px"
                                       onClick={() => setShowMore(!showMore)}
                                       style={{ display: showServices().length < 3 ? "none" : "block" }}
                                    >
                                       <Msg fontWeight={600} fontSize={14} color="#109CF1">
                                          {showMore ? "View less" : "View all history"}
                                       </Msg>
                                    </EmptyButton>
                                 </>
                              ) : (
                                 <Msg mb="32px" center>
                                    No services found
                                 </Msg>
                              )}
                           </Content>
                        </Flex>
                     </InfoItem>
                  )}
                  <InfoItem mt="24px">
                     <Flex justifyContent="space-between">
                        <InfoTitle>Gallery</InfoTitle>
                        {!technicianRole && (
                           <EmptyButton onClick={() => imageUpload.current && imageUpload.current.click()}>
                              <Msg fontWeight={600} fontSize={14} color="#109CF1">
                                 Add photo
                              </Msg>
                              <input
                                 type="file"
                                 hidden
                                 ref={imageUpload}
                                 onChange={uploadImage}
                                 multiple
                                 accept="image/png, image/jpeg"
                              />
                           </EmptyButton>
                        )}
                     </Flex>
                     <Content
                        loading={loading || imageLoading}
                        noItems={!images.length}
                        noItemsLabel="No photos of vessel"
                     >
                        <Flex flexWrap="wrap" mt="8px">
                           <GalleryVessel>
                              {images.filter(filterPhotos).map((i) => (
                                 <ImageItem key={i.id}>
                                    <Image src={i.photo} />
                                    <DeleteImage onClick={() => deleteImage(i.id)}>
                                       <DeleteFilled />
                                    </DeleteImage>
                                 </ImageItem>
                              ))}
                           </GalleryVessel>

                           {showViewButton() && (
                              <Msg
                                 color="#109CF1"
                                 textAlign="center"
                                 fontSize={16}
                                 fontWeight={700}
                                 margin="20px 0"
                                 width="100%"
                                 onClick={toggleViewAll}
                              >
                                 {!viewAll ? "View all photos" : "Hide all photos"}
                              </Msg>
                           )}
                        </Flex>
                     </Content>
                  </InfoItem>
               </InfoSection>
               {!isLg && (
                  <InfoSection width={55}>
                     <InfoItem bottom={services.length}>
                        <WholeWidth justifyContent="space-between" alignItems="center">
                           <InfoTitle>Service history</InfoTitle>
                           <Flex>
                              <Flex style={{ minWidth: isLg ? "200px" : "292px" }} alignItems="center">
                                 <SearchWithBorder
                                    onChange={(e) => setSearch(e.target.value)}
                                    disabled={!services.length}
                                    placeholder="Search jobs, service types..."
                                 />
                                 <Dropdown
                                    trigger={["click"]}
                                    overlay={CalendarOverlay}
                                    visible={dropdownVisible}
                                    onVisibleChange={() => {
                                       setDropdownVisible(!dropdownVisible)
                                    }}
                                    arrow
                                    placement="bottomRight"
                                    style={{ boxShadow: "none" }}
                                 >
                                    <CalendarBtn onClick={() => setDropdownVisible(!dropdownVisible)}>
                                       <CalendarOutlined />
                                    </CalendarBtn>
                                 </Dropdown>
                              </Flex>
                           </Flex>
                        </WholeWidth>
                        <Flex flexDirection="column" mt="16px">
                           <Content
                              loading={loading}
                              noItems={!services.length}
                              noItemsLabel={"No services are applied yet"}
                           >
                              {showServices().length ? (
                                 <>
                                    {showServices().map((j) => (
                                       <JobRow j={j} key={j.id} />
                                    ))}
                                    <EmptyButton
                                       width="100%"
                                       height="45px"
                                       onClick={() => setShowMore(!showMore)}
                                       style={{ display: showServices().length < 3 ? "none" : "block" }}
                                    >
                                       <Msg fontWeight={600} fontSize={14} color="#109CF1">
                                          {showMore ? "View less" : "View all history"}
                                       </Msg>
                                    </EmptyButton>
                                 </>
                              ) : (
                                 <Msg mb="32px" center>
                                    No services found
                                 </Msg>
                              )}
                           </Content>
                        </Flex>
                     </InfoItem>
                  </InfoSection>
               )}
            </Flex>
         </ContentStyled>
      </Flex>
   )
}

export default VesselPage
