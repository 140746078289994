import React, { useEffect, useState } from "react"
import { IJob, IVessel } from "../../../api/types"
import { InfoItem, InfoTitle, MapWrap, Marker } from "./styled"
import GoogleMapReact from "google-map-react"
import { GoogleMapsAPIKey } from "../../../api"

type TVessel = IVessel & { jobs: IJob[] }

type TMobileMapProps = {
   vessels: TVessel[]
}

type TVesselData = {
   lat: number
   lng: number
}

const MobileMap = ({ vessels }: TMobileMapProps) => {
   const [mapsOptions, setMapsOptions] = useState({
      center: {
         lat: 41,
         lng: -73,
      },
      zoom: 1,
   })

   const [vesselData, setVesselData] = useState<TVesselData[]>([])

   useEffect(() => {
      const newVesselData: TVesselData[] = []
      vessels
         .filter((vessel) => vessel?.address?.lat && vessel?.address?.lng)
         .forEach((v) => {
            if (!newVesselData.some((vsl) => vsl.lat === v?.address?.lat && vsl.lng === v?.address?.lng)) {
               newVesselData.push({
                  lat: v?.address?.lat ?? 0,
                  lng: v?.address?.lng ?? 0,
               })
            }
         })
      setVesselData(newVesselData)
   }, [vessels])

   return (
      <InfoItem>
         <InfoTitle>Vessel location</InfoTitle>
         <MapWrap my="8px">
            <GoogleMapReact
               bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
               defaultCenter={mapsOptions.center}
               defaultZoom={mapsOptions.zoom}
            >
               {vesselData.length && vesselData.map((item, index) => <Marker lat={item.lat} lng={item.lng} />)}
            </GoogleMapReact>
         </MapWrap>
      </InfoItem>
   )
}

export default MobileMap
