import React from "react"
import { Redirect, Route } from "react-router-dom"
import { authService } from "../utils/authService"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../config/reducers/user/selectors"
import { TRoute } from "../layouts/types"
import routes from "../routes/routes"

const PrivateRoute: React.FC<TRoute> = (props) => {
   const user = useSelector(getCurrentUser)

   if (!authService.getLocalToken()) {
      return <Redirect to="/login" />
   }

   if (user) {
      const roleValid = props.roles?.includes(user.role_id) ? true : false

      return roleValid ? <Route {...props} /> : <Redirect to={routes.errorPage.Base} />
   }

   return null
}

export default PrivateRoute
