import { DownOutlined } from "@ant-design/icons";
import { Button, Select, Spin, Steps, Table, Upload, Form, Tooltip, Checkbox } from "antd";
import { notification } from "antd/es";
import { useForm } from "antd/es/form/Form";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import { RcFile } from "antd/lib/upload";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Api } from "../../../api/api";
import { TImportEnum, TImportUploadRes } from "../../../api/types";
import { GettingStarted, ImportCheck, ImportData, ImportDownload, ImportNotification, ImportRightArrow, MatchColumns, UploadFile } from "../../../assets/icons";
import { useAppSelector } from "../../../config/hooks";
import { getCurrentUser } from "../../../config/reducers/user/selectors";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { useLoading, useWebSocket } from "../../../utils/hooks";
import { convertFileSize, downloadFile, isArrayContainsDuplicates } from "../../../utils/utils";
import { EmptyDraggerContent } from "./EmptyDraggerContent";
import ErrorTooltip from "./ErrorTooltip";
import { FileDraggerContent } from "./FileDraggerContent";
import { RenderIfVisible } from "./RenderIfVisible";
import { Container, Content, DataSelect, DownloadButton, DraggerWrapper, FinalStepContainer, HeaderLabel, HeaderSelect, ImportSteps, StyledInput, StyledSelect, StyledTable, TableContainer } from "./styled";
import { getMockTableData } from "./utils";
const { Step } = Steps;
const { Dragger } = Upload;

const steps = [
    {
        title: "getting started",
        icon: (isActive?: boolean) => <GettingStarted isActive={isActive} />
    },
    {
        title: "upload file",
        icon: (isActive?: boolean) => <UploadFile isActive={isActive} />
    },
    {
        title: "match columns",
        icon: (isActive?: boolean) => <MatchColumns isActive={isActive} />
    },
    {
        title: "import data",
        icon: (isActive?: boolean) => <ImportData isActive={isActive} />
    },
]

type TDataImportMainProps = {
    importType: string
    handleCancel: () => void
}

const DataImportMain: React.FC<TDataImportMainProps> = ({importType, handleCancel}) => {
    const user = useAppSelector(getCurrentUser)
    const [step, setStep] = useState(0)
    const [file, setFile] = useState<RcFile|null>(null)
    const [isLoading, load] = useLoading()
    const [isRequesting, setIsRequesting] = useState(false)
    const [headers, setHeaders] = useState<string[]>([])
    const [DBHeaders, setDBHeaders] = useState<{[key: string]: string}|null>(null)
    const [fileData, setFileData] = useState<Array<{[key: string]: string}>>([])
    const [enums, setEnums] = useState<TImportEnum>([])
    const [headerRelation, setHeaderRelation] = useState<{[key: string]: string}>({})
    const [form] = useForm()
    const [validationRes, setValidationRes] = useState<Array<{[key: string]: string}>>([]);
    const [filterErrors, setFilterErrors] = useState(false)
    const [formatedPresetData, setFormatedPresetData] = useState<{[key: string]: Array<any>}>({});

    const rowsWithErrors = useMemo(() => {
        return validationRes.reduce((acc, current) => {
            if (!current || Object.keys(current).length === 0 || current?.non_field_errors?.length) {
                return acc;
            }
            return acc + 1
        }, 0)
    }, [validationRes])

    const isAllRequiredColumnsMatched = useMemo(() => {
        if (!headerRelation) return true;
        return enums.filter(e => e.required).every(e => Object.values(headerRelation).includes(e.field_name))
    }, [headerRelation, enums])

    const isNextDisabled = useMemo(() => {
        if (step === 0) return false;
        if (step === 1) return !file || isLoading;
        if (step === 2) return !isAllRequiredColumnsMatched || isLoading || isRequesting;
        return false;
    }, [step, file, isLoading, isAllRequiredColumnsMatched, isRequesting]) 

    const isPreviousDisabled = useMemo(() => {
        if (step === 3) return true;
        return isLoading || isRequesting;
    }, [step, isLoading, isRequesting])
    
    const catchSocketResponse = useCallback((m) => {
        setIsRequesting(false)
        if (!m.message) return;
        if (m?.message === "Success") {
            setStep(3)
            return;
        }
        try {
            const errors = JSON.parse(m.message);
            if (Array.isArray(errors)) {
                if (errors[0]?.non_field_errors?.length) {
                    notification.error({message:  errors[0].non_field_errors.join('\n')})
                }
                setValidationRes(errors)
            }
        } catch (err) {
        }
    }, [])
    
    const formatFieldNames = useCallback((name: string) => {
        return name.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
    }, [])

    const formatEnumColumns = useMemo(() => {
        return enums.map(e => (
            {
                title: formatFieldNames(e.field_name), 
                dataIndex: e.field_name, 
                key: e.field_name,
            }
        ))
    }, [enums, formatFieldNames])

    useWebSocket(String(user?.company?.id), "DI", catchSocketResponse)

    const handlePrevious = () => {
        if (step === 0) {
            handleCancel();
            return;
        }
        if (step === 1) {
            setFile(null)
        }
        if (step === 2) {
            form.resetFields()
            setDBHeaders(null)
            setHeaders([])
            setFileData([])
            setHeaderRelation({})
            setFilterErrors(false)
            setValidationRes([])
            setFormatedPresetData({})
        }
        setStep(step - 1);
    }

    const handleNext = async () => {
        if (step === 3) {
            handleCancel();
            return;
        }
        if (step === 1) {
            await handleUpload();
            return;
        }
        if (step === 2) {
            await handleValidate();
            return;
        }
        setStep(step + 1);
    }

    const loadEnums = async () => {
        const { data: _data } = await load(Api.company.importEnums(importType))
        setEnums(_data)
    }

    const handleUpdateFile = (_file: RcFile) => {
        if (_file.size >= 2621440) {
            notification.error({message: `CSV file size should be less than ${convertFileSize(2621440, 2)}`})
            return false;
        }
        setFile(_file)
        return false;
    }

    const handleUpload = async () => {
        try {

            const id = user?.company?.id;
            if (file && id) {
                const data = new FormData();
                data.append('document', file)
                const { data: _data } = await load(Api.company.importUpload({ data, id, table: importType}))
                if (_data) {
                    if (_data.detail) {
                        notification.error({message: _data.detail})
                        return;
                    }
                    const { data, headers, db_headers } = _data as TImportUploadRes;
                    setFileData(data.map((d, i) => ({...d, key: String(i)})))
                    setHeaders(headers)
                    setDBHeaders(db_headers)
                    //prepopulate relations
                    // setHeaderRelation(formatHeaderRelation(headers, db_headers))
                    setStep(2)
                }
            }
        } catch (e: unknown | AxiosError) {
            if (!axios.isAxiosError(e)) return;
            const err = e?.response?.data;
            if (!err?.length) return;
            notification.error({message: err.join('\n')})            
        }
    }

    const formatPresetData = async (presetData: Array<{[key: string]: string | Array<any>}>) => {
        const _formattedPresetData: {[key: string]: Array<any>} = {};
        if (!presetData || !presetData.length) return;
        const res = presetData.reduce((acc, current) => {
            const entries = Object.entries(current)
            entries.forEach((e) => {
                const [key, value] = e;
                if (!Array.isArray(value)) return;
                const prevValue = acc[key];
                if (Array.isArray(prevValue) && typeof prevValue !== 'string') {
                    acc[key] = [...prevValue, value]
                    return acc;
                }
                acc[key] = [value]
                return acc;
            })
            return acc;
        }, _formattedPresetData)  as {[key: string]: Array<any>}
        setFormatedPresetData(res)
        //update form value with single option selects 
        if (!res) return;
        const entries = Object.entries(res);
        if (!entries?.length) return;
        const _res: {[key: string]: number} = {};
        entries.forEach(e => {
            const [key, value] = e;
            value.forEach((v, i) => {
                if (v?.length === 1) {
                    const _key = Object.keys(headerRelation).find(k => headerRelation[k] === key) ?? key;
                    _res[`${i}-${_key}`] = v[0]?.id
                }
            })
        })
        await form.setFieldsValue(_res)
    }

    const formatFormData = async () => {
        const _formData = await form.getFieldsValue(true)
        const _data: Array<any> = [];
        Object.entries(_formData).map(f => {
            const [_fullName, _value] = f;
            const [_index, _name] = _fullName.split('-') 
            if (_data[+_index]) {
                _data[+_index] = {..._data[+_index], [_name]: _value}
                return;
            }
            _data[+_index] = {[_name]: _value} 
        })
        const res = _data.map(f => {
            return Object.entries(headerRelation).reduce((prev, h) => {
                const [_name, _value] = h;
                return {...prev, [_value]: f[_name]};
            }, {});
        })
        return res;
    }

    const handleValidate = async () => {
        if (isArrayContainsDuplicates(Object.values(headerRelation))) {
            notification.error({message: 'Error'})
            return;
        }
        const id = user?.company?.id;
        if (id) {
            let res = await formatFormData();
            setIsRequesting(true)
            //if preset data isn't loaded yet
            if (!Object.keys(formatedPresetData).length) {
                const { data:_presetData } = await load(Api.company.importPresetData({data: res, table: importType}))
                await formatPresetData(_presetData)
                res = await formatFormData();
            }
            await load(Api.company.importExecute({data: res, table: importType, id}))
        }
    }

    const handleDeleteFile = () => {
        setFile(null)
    }

    const handleDownloadTemplate = async () => {
        const { data: _data } = await Api.company.downloadTemplate(importType)
        downloadFile(_data, `${importType}_template.csv`)
    }

    const formatEnumWarning = (isUrgent?: boolean) => {
        return enums
            .filter(e => !!(e.choices && e.choices.length))
            .map((e, i) => (
                <Msg color={isUrgent ? "#FB4D4F" : "#494949"} key={i}>
                    In column "{formatFieldNames(e.field_name)}" you can only enter: {
                    e.choices?.map(e => 
                        e.value === e.label ? 
                        `"${e.value}"` : 
                        `"${e.value}" (${e.label})`
                    ).join(', ')}
                </Msg>
            ))
            
    }

    const formatHeaderRelation = (headers: string[], db_headers: {[key: string]: string}) => {
        const _relations = headers.reduce((acc, h, i) => {
            if (Object.keys(db_headers)[i]) {
                return {...acc, [h]: Object.keys(db_headers)[i]}
            }
            return acc;
        }, {})
        return _relations;
    }

    const updateRelation = (fieldName: string, value: SelectValue) => {
        if (value) {
            setHeaderRelation({...headerRelation, [fieldName]: String(value)})
            return;
        }
        const {[fieldName]:field, ...newRelation} = headerRelation;
        setHeaderRelation(newRelation)
    }

    const handleChangeErrorFilter = (e: CheckboxChangeEvent) => {
        setFilterErrors(e.target.checked)
    }

    const renderContent = () => {
        if (step === 0) {
            return (
                <Flex flexDirection="column" alignItems="center">
                    <Flex width="100%" flexDirection="column" marginBottom="16px">
                        <HeaderLabel>Get ready to import your data</HeaderLabel>
                        <Msg>The following columns are supported. Some may be required, the rest are optional.</Msg>
                        {formatEnumWarning(true)}
                    </Flex>
                    <Content>
                        <Spin spinning={isLoading}>
                            {!!enums.length && (
                                <TableContainer>
                                    <Table 
                                        dataSource={getMockTableData(importType) as Array<any>} 
                                        columns={formatEnumColumns} 
                                        pagination={false} 
                                    />
                                </TableContainer>
                            )}
                        </Spin>                    
                    </Content>
                    <DownloadButton onClick={handleDownloadTemplate}>
                        <ImportDownload />
                        Download csv. example
                    </DownloadButton>
                </Flex>
            );
        }
        if (step === 1) {
            return (
                <DraggerWrapper>
                    <Dragger 
                        maxCount={1} 
                        accept=".csv" 
                        beforeUpload={handleUpdateFile} 
                        multiple={false} 
                        showUploadList={false} 
                        fileList={file ? [file] : []}
                    >
                        <Flex alignItems="center" justifyContent="center">
                            {file ? <FileDraggerContent file={file} deleteFile={handleDeleteFile} /> : <EmptyDraggerContent />}
                        </Flex>
                    </Dragger>
                </DraggerWrapper>
                
            );
        }
        if (step === 2) {
            return (
                <Flex flexDirection="column" alignItems="center">
                    <Flex width="100%" flexDirection="column" marginBottom="16px">
                        {formatEnumWarning(false)}
                        {isAllRequiredColumnsMatched ? 
                        (
                            <Flex alignItems="center">
                                <ImportCheck isLarge={false} />
                                <Msg fontWeight={700} marginLeft="8px" color="#42C77B">
                                    All required columns have been matched
                                </Msg>
                            </Flex>
                        ) :
                        (
                            <Flex alignItems="center">
                                <ImportNotification/>
                                <Msg fontWeight={700} marginLeft="8px">
                                    Now match your columns by clicking in <DownOutlined/> each column header
                                </Msg>
                            </Flex>
                        )}
                        {rowsWithErrors !== 0 && (
                            <Flex alignItems="center"> 
                                <ImportNotification isError />
                                <Msg fontWeight={700} color="#FB4D4F" marginLeft="8px">
                                    There are {rowsWithErrors} rows which are not valid, please check these before you continue.
                                </Msg>
                            </Flex>
                        )}
                        {!!validationRes.length && (
                        <Checkbox onChange={handleChangeErrorFilter}>
                            Only show rows with errors
                        </Checkbox>
                        )}
                    </Flex>
                    { !!(fileData && headers && DBHeaders) && (
                        <Flex width="100%">
                            <StyledTable>
                                <thead>
                                    <tr>
                                        {headers.map(h => (
                                            <th key={h}>
                                                <HeaderSelect>
                                                    <Msg>{formatFieldNames(h)} </Msg>
                                                    <ImportRightArrow />
                                                    <StyledSelect 
                                                        defaultValue={headerRelation[h]} 
                                                        onChange={(v) => updateRelation(h, v)} 
                                                        allowClear 
                                                        placeholder="Choose column" 
                                                        optionLabelProp="label"
                                                        getPopupContainer={(trigger: any) => trigger.parentElement}
                                                    >
                                                        {!!DBHeaders && Object.keys(DBHeaders).map(k => {
                                                        const isRequired = !!enums.find(e => e.field_name === k)?.required;
                                                        const isDisabled = Object.values(headerRelation).includes(k)
                                                        return (
                                                            <Select.Option key={k} value={k} label={DBHeaders[k]} disabled={isDisabled}>
                                                                <Flex justifyContent="space-between">
                                                                    <Msg fontSize="13px" fontWeight={600} color={isDisabled ? "#B0B4B7" : "#484848"}>
                                                                        {DBHeaders[k]}
                                                                    </Msg>
                                                                    <Msg fontSize="9px" fontWeight={600} color={isRequired ? "#FB4D4F" : "#8B8B8B"}>
                                                                        {isRequired ? "REQUIRED" : "OPTIONAL" }
                                                                    </Msg>
                                                                </Flex>
                                                            </Select.Option>
                                                        )})}
                                                    </StyledSelect>
                                                </HeaderSelect>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                    <tbody>
                                        {fileData.map((r, i) => {
                                        if (validationRes && validationRes[i] && Object.keys(validationRes[i]).length === 0 && filterErrors) {
                                            return null;
                                        }
                                        
                                        return (
                                            <tr key={i}>
                                                {headers.map(h => {
                                                const errorMessage = !!(validationRes && validationRes.length && validationRes[i] && headerRelation && headerRelation[h] && validationRes[i][headerRelation[h]]) ? validationRes[i][headerRelation[h]] : '';
                                                const _fieldName = headerRelation[h]
                                                const _enum = enums.find(e => e.field_name === _fieldName && e.choices?.length)
                                                const isInPresset = !!formatedPresetData[_fieldName]?.length;
                                                if (isInPresset) {
                                                    return (
                                                        <td key={`${i}_${h}`}>
                                                            <RenderIfVisible defaultHeight={32} visibleOffset={2000}>
                                                                <Form form={form}>
                                                                    <Form.Item name={`${i}-${h}`}>
                                                                        <DataSelect 
                                                                            isError={!!errorMessage} 
                                                                            suffixIcon={!!errorMessage ? <ImportNotification isError /> : undefined}
                                                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                                                        >
                                                                            {formatedPresetData[_fieldName][i].map((c: any) => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                                                                        </DataSelect>
                                                                    </Form.Item>
                                                                </Form>
                                                            </RenderIfVisible>
                                                        </td>
                                                    )
                                                }
                                                if (_enum) {
                                                    return (
                                                    <td key={`${i}_${h}`}>
                                                        <RenderIfVisible defaultHeight={32} visibleOffset={2000}>
                                                            <Form form={form}>
                                                                <Form.Item name={`${i}-${h}`}>
                                                                    <DataSelect 
                                                                        isError={!!errorMessage} 
                                                                        suffixIcon={!!errorMessage ? <ImportNotification isError /> : undefined}
                                                                        getPopupContainer={(trigger: any) => trigger.parentElement}
                                                                    >
                                                                        {_enum?.choices?.map(c => <Select.Option key={c.value} value={c.value}>{c.label}</Select.Option>)}
                                                                    </DataSelect>
                                                                </Form.Item>
                                                            </Form>
                                                        </RenderIfVisible>
                                                    </td>
                                                    )
                                                }
                                                return (
                                                    <td key={`${i}_${h}`}>
                                                        <RenderIfVisible defaultHeight={32} visibleOffset={2000}>
                                                            <Form form={form}>
                                                                <Form.Item name={`${i}-${h}`}>
                                                                    <StyledInput 
                                                                        isError={!!errorMessage} 
                                                                        suffix={!!errorMessage ? <ErrorTooltip title={errorMessage} />  : null} 
                                                                    />
                                                                </Form.Item>
                                                            </Form>
                                                        </RenderIfVisible>
                                                    </td>
                                                )})}
                                            </tr>
                                        )})}
                                    </tbody>
                            </StyledTable>
                        </Flex>
                    )}
                </Flex>
            )
        }
        if (step === 3) {
            return ( 
                <FinalStepContainer>
                    <Flex>
                        <ImportCheck isLarge />
                    </Flex>
                    <Msg fontSize="18px" fontWeight={700} marginTop="12px">
                        {fileData.length} New rows were imported successfully
                    </Msg>                    
                </FinalStepContainer>
            );
        }
        return null;
    }

    useEffect(() => {
        loadEnums()
    }, [importType])

    useEffect(() => {
        const newFormData = fileData.reduce((prev, r, i) => {
            Object.entries(r).map(h => prev[`${i}-${h[0]}`] = h[1])
            return prev;
        }, {})
        form.setFieldsValue(newFormData)
    }, [fileData])


    return (
        <Flex flexDirection="column" alignItems="center">
            <Flex maxWidth="900px" margin="24px 0">
                <ImportSteps  current={step}>
                    {steps.map((s, i) => <Step title={s.title} icon={s.icon(i === step)} key={s.title} />)}
                </ImportSteps>
            </Flex>
            <Container>
                {renderContent()}
            </Container>
            <Flex marginTop="16px" width="100%" justifyContent="end">
                <Button
                    onClick={handlePrevious}
                    disabled={isPreviousDisabled}
                >
                    {!step ? "Cancel" : "Back"}
                </Button>
                <Button
                    type="primary"
                    style={{marginLeft: "16px"}}
                    onClick={handleNext}
                    disabled={isNextDisabled}
                    loading={isLoading || isRequesting}
                >
                    {step === 3 ? 'Close' : 'Next Step'}
                </Button>
            </Flex>
        </Flex>
    );
}

export default DataImportMain;