import React from 'react';
import DarkLogo from '../assets/images/DarkLogo.svg';
import Flex from '../noui/Flex';
import Box from '../noui/Box';
import styled from 'styled-components';
import {Msg} from '../ui/Text';
import {PrimaryButton} from '../ui/Button';
import SmallBoatImg from '../assets/images/SmallBoatImg.png';
import { NavLink } from 'react-router-dom';
import routes from '../routes/routes';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { BoxWrapper, LoginFlowWrapper } from '../containers/login/Login';

const LogoContainer = styled(Box)`
& img {
    width: 140px;
    height: 24px;
}
${down('md')} {
  margin: 0 auto;
}
`;

const BtnPr = styled(PrimaryButton)`
&:hover {
  border: 1px solid #109CF1;
}
`

const CheckEmailLayout: React.FC = () => {
  const isMd = useBreakpoint(down('md'));

  return <Flex backgroundColor={!isMd ? "none" : "#FBFBFF"}>
    <LoginFlowWrapper padding={isMd ? "0 12px" : "0"}>
      <LogoContainer p={35}>
        <NavLink to={routes.auth.Login}>
          <img src={DarkLogo} alt='logotype'/>
        </NavLink>
      </LogoContainer>
      <Flex flexDirection="column" justifyContent='flex-start' height="100%">
        <Box m="auto">
          <Flex flexDirection='column' justifyContent='space-between' maxWidth={isMd ? "400px" : "initial"} margin="auto">
            <Flex flexDirection='column' alignItems="center">
              <Box mb='30px'>
                <Msg fontWeight={700} fontSize={22}>Check your email</Msg>
              </Box>
              <Box width={isMd ? "100%" : "368px"} mb='40px' textCenter={!isMd}>
                <Msg fontWeight={400} fontSize={16} color="#898989">
                  We sent you a link to reset your password.
                  Did not get an email? Check also spam holder
                </Msg>
              </Box>
              <Box mb="15px" width={isMd ? "100%" : "auto"}>
                <BtnPr width={isMd ? "100%" : "130px"} height="40px" fontWeight={600} fontSize={14}
                       onClick={() => window.location.href = '/login'}>Close</BtnPr>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </LoginFlowWrapper>
    {!isMd && <BoxWrapper><img src={SmallBoatImg} width="100%" height="100%" alt="background" /></BoxWrapper>}
  </Flex>
}

export default CheckEmailLayout; 