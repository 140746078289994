import React from 'react';
import {Modal} from "antd";
import styled from "styled-components";

const Message = styled.p`
  font-size: 13px;
`;

export const EstimateApprovedModal = ({visible, onOk, onCancel}) => {
  return <Modal
    centered
    visible={visible}
    onOk={onOk}
    onCancel={onCancel}
    cancelText="Dismiss"
    okText="Finish"
    title="Estimate has been Approved"
    width={352}
  >
    <Message>Thanks! We`ve received your authorization and are one step closer to working on your vessel</Message>
  </Modal>
};