import styled from "styled-components"
import { down } from "styled-breakpoints"

export const Row = styled.div`
   display: flex;
   gap: 8px;
   justify-content: space-between;
   align-items: flex-start;
   padding: 10px 14px;
   background: #ffffff;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   > div:last-child {
      text-align: right;
   }
   ${down("lg")} {
      flex-direction: column;
      > div:last-child {
         text-align: left;
      }
   }
`
