import React, { useEffect, useRef, useState } from "react"
import DarkLogo from "../../../assets/images/DarkLogo.svg"
import UploadImg from "../../../assets/images/UploadImg.svg"
import BoatBtnWhite from "../../../assets/images/SailboatWhite.svg"
import ShipBtnBlack from "../../../assets/images/BoatBlack.svg"
import KaterBtnBlack from "../../../assets/images/KaterBlack.svg"
import BoatBtnBlack from "../../../assets/images/SailboatBlack.svg"
import ShipBtnWhite from "../../../assets/images/BoatWhite.svg"
import KaterBtnWhite from "../../../assets/images/KaterWhite.svg"
import ShipOtherBlack from "../../../assets/images/CommercialBlack.svg"
import ShipOtherWhite from "../../../assets/images/CommercialWhite.svg"
import TrashBin from "../../../assets/images/TrashBin.svg"
import Flex from "../../../noui/Flex"
import Box from "../../../noui/Box"
import styled from "styled-components"
import { Msg } from "../../../ui/Text"
import { PrimaryButton } from "../../../ui/Button"
import { Upload, Button, Checkbox, Radio, Form, Input, notification, CheckboxOptionType } from "antd"
import SmallBoatImg from "../../../assets/images/SmallBoatImg.png"
import {getErrors, updatePhoneOnBlur} from "../../../utils/utils"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import { ICreateCompanyForm, IServiceCategories } from "../../../api/types"
import { useLoading, useTechnicianRole } from "../../../utils/hooks"
import { Api } from "../../../api/api"
import { setCurrentUser } from "../../../config/reducers/user/actions"
import { NavLink } from "react-router-dom"
import routes from "../../../routes/routes"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { companyName, phoneNumberPattern } from "../../../utils/regex"
import { BoxWrapper, LoginFlowWrapper } from "../../login/Login"
import { authService } from "../../../utils/authService"
import AddressWithAutoComplete from "../../customers/AddressWithAutocomplete"
import {DefaultPhoneNumber} from "../../../constants";

const CheckboxGroup = Checkbox.Group

const UploadPhoto = () => <img src={UploadImg} alt="Envelope" />

// const kindOfServices = [
//   'General Maintenance',
//   'Boat Cleaning & Detail',
//   'Bottom Cleaning & Divers',
//   'Engine Repair & Maintenance',
//   'Electronics & Electrical',
//   'Paint and Fiberglass',
//   'Carpentry',
//   'Plumbing & HVAC',
//   'Rigging',
//   'Upholstery & Canvas'
// ];

const boats = [
   { value: "SB", label: "Sailboat", image: BoatBtnBlack, checkedImage: BoatBtnWhite },
   { value: "PB", label: "Powerboat", image: ShipBtnBlack, checkedImage: ShipBtnWhite },
   { value: "PW", label: "Personal Watercraft", image: KaterBtnBlack, checkedImage: KaterBtnWhite },
   { value: "OT", label: "Commercial", image: ShipOtherBlack, checkedImage: ShipOtherWhite },
]

const StyledInput = styled(Input)`
   height: 40px;
   width: 360px;
   border: 1px solid rgba(0, 0, 0, 0.15);
   outline: 0;
   border-radius: 4px;
   ${down("md")} {
      width: 100%;
   }
`

const UploadWrapper = styled(Button)`
   display: flex;
   height: 77px;
   width: 360px;
   padding: 0;
   outline: 0 !important;
   border: 0;
   align-items: center;
   justify-content: center;
   &.ant-btn > span {
      display: flex;
   }
   &:focus {
      outline: 0 !important;
   }
   ${down("md")} {
      width: 100%;
      .ant-upload.ant-upload-select {
         width: 100%;
      }
   }
`

const UploadImage = styled(Input)`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 77px;
   width: 374px;
   padding: 0px 29px;
   border: 0;
   outline: 0;
   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%238F8F90FF' stroke-width='2' stroke-dasharray='9%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
   border-radius: 2px;
   & input {
      font-weight: 400px;
      font-size: 15px;
      cursor: pointer;
      color: #9aa1aa;
   }
   & ::value {
      font-weight: 400px;
      font-size: 15px;
      color: #9aa1aa;
   }
   & :focus {
      outline: 0 !important;
   }
   & .ant-input-prefix {
      width: 26px;
      display: inline-block;
      margin-right: 19px;
   }
   &.ant-input-affix-wrapper > input.ant-input {
      width: 260px;
   }
   ${down("md")} {
      width: 100%;
      &.ant-input-affix-wrapper > input.ant-input {
         width: 100%;
      }
   }
`

const UploadtContent = styled(UploadPhoto)`
   display: inline-block;
   width: 26px;
`

export const LogoContainer = styled(Box)`
   & img {
      width: 140px;
      height: 24px;
   }
   ${down("md")} {
      margin: 0 auto;
   }
` as typeof Box

const StylizeWr = styled(Radio.Group)`
   display: flex;
   width: 360px;
   justify-content: space-between;
   ${down("md")} {
      width: 100%;
   }
`

const StylizeRadio = styled(Radio.Button)`
   background-color: #ffffff;
   color: #252733;
   border: 1px solid #dadada;
   box-sizing: border-box;
   border-radius: 2px;
   width: 73px;
   height: 40px;
   text-align: center;
   line-height: 40px;
   font-weight: 600px;
   font-size: 14px;
   &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #ffffff;
      background-color: #109cf1;
      &::before {
         background-color: none;
         dispaly: none;
      }
   }
   &.ant-radio-button-wrapper:first-child {
      // border-left: none;
      border-radius: 2px !important;
   }
   &.ant-radio-button-wrapper:last-child {
      border-radius: 2px !important;
   }
   &::before {
      background-color: none !important;
   }
   &.ant-radio-button-wrapper::before {
      background-color: rgba(255, 0, 0, 0);
   }
`

const StyledCheckboxGroup = styled(Checkbox.Group)`
   display: flex;
   width: 374px;
   flex-direction: row;
   align-items: center;
   gap: 16px;
   > label {
      width: 107px;
      height: 74px;
      color: #252733;
      border: 1px solid #dadada;
      border-radius: 2px;
      box-sizing: border-box;
      transition: all 0.2s;
      &.ant-checkbox-wrapper-checked {
         background: #109cf1;
         color: #fff;
      }
      &:hover {
         border-color: #109cf1;
      }
      > span:last-child {
         width: 100%;
         height: 100%;
         font-size: 10px;
         font-weight: 700;
         display: flex;
         flex-flow: column nowrap;
         text-align: center;
         justify-content: center;
         align-items: center;
         padding: 0;
      }
      img {
         height: 35px;
         max-width: 47px;
         margin-bottom: 8px;
      }
   }
   .ant-checkbox {
      display: none;
   }
   ${down("md")} {
      width: 100%;
      > label {
         width: calc(25% - 12px);
      }
   }
`

const CheckboxWrapper = styled(Flex)`
   & .ant-checkbox-group {
      display: flex;
      flex-direction: column;
   }
   & .ant-checkbox-wrapper.ant-checkbox-group-item {
      margin-bottom: 7px;
      & .ant-checkbox-inner {
         width: 20px;
         height: 20px;
      }
      & span {
         color: #252733;
         font-weight: 400;
         font-size: 15px;
      }
   }
` as typeof Flex

const ImageWrapperFlex = styled(Flex)`
   & div {
      width: 150px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      user-select: none;
   }
   & img {
      flex: 0 0 150px;
      cursor: default;
      width: 100%;
   }
   & button {
      position: absolute;
      right: 0px;
      bottom: 1px;
      color: red;
      width: 24px;
      visibility: visible;
      &:hover {
         background-color: #df3737;
      }
   }
   ${down("md")} {
      & div {
         width: 100%;
      }
      & img {
         width: 100%;
      }
   }
` as typeof Flex
const CloseLogoButton = styled(PrimaryButton)`
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 5px;
   top: 0;
   background-color: #df3737;
   // background: url(${TrashBin});
   // background-repeat: no-repeat;
   // background-position: center;
   // color: #ffffff;
   width: 24px;
   height: 24px;
   cursor: pointer;
   & span {
      width: 100%;
      height: 100%;
   }
   & img {
      width: 100%;
      height: 100%;
      cursor: pointer;
   }
`
const BtnPr = styled(PrimaryButton)`
   &:hover {
      border: 1px solid #109cf1;
   }
`
const LogOutBtn = styled(Button)`
   border: none;
   margin: 0 10px;
   padding: 0;
   color: #0496ff;
   font-weight: bold;
   font-size: 15px;
   text-decoration: underline;
   > span {
      text-decoration: underline;
   }
`
const MainWrapper = styled(Flex)`
   ${down("md")} {
      .ant-upload.ant-upload-select {
         width: 100%;
      }
   }
`

const StyledUpload = styled(Upload)`
   .ant-upload-list {
      display: none;
   }
`

export const RegistrationStepTwo = () => {
   const field = useRef<HTMLInputElement>(null)
   const [form] = Form.useForm<ICreateCompanyForm>()
   const user = useSelector(getCurrentUser)
   const dispatch = useDispatch()
   const [logo, setLogo] = useState<string | null>(null)
   const [saving, onSave] = useLoading()
   const isMd = useBreakpoint(down("md"))
   const [fields, setFields] = useState<CheckboxOptionType[]>([])
   const technicianRole = useTechnicianRole()
   const [value, setValue] = useState("")

   const handleLogout = () => {
      authService.logout()
      window.location.href = "/login"
   }

   const loadData = async () => {
      const { data } = await Api.config.getServiceCategories()
      const sortData = sortServices(data)

      setFields(formatData(sortData))
   }

   const sortServices = (data: IServiceCategories[]) => {
      return data.sort(function (a, b) {
         const nameA = a.name.toUpperCase()
         const nameB = b.name.toUpperCase()
         if (nameA < nameB) {
            return -1
         }
         if (nameA > nameB) {
            return 1
         }
         return 0
      })
   }

   const formatData = (data: IServiceCategories[]) => data.map((d) => ({ label: d.name, value: d.id }))

   useEffect(() => {
      loadData()
   }, [])

   useEffect(() => {
      if (user && user.id && user.company !== null && user.company.plan === null) {
         window.location.href = "/registration/step3"
      } else if ((user && user.id && user.company && user.company.plan) || technicianRole) {
         window.location.href = "/"
      }
   }, [user])

   const uploadImage = async (options: any) => {
      const { onSuccess, onError, file, onProgress } = options
      try {
         if (!file) {
            throw new Error()
         }
         if (["image/jpeg", "image/png"].indexOf(file.type) === -1) {
            notification.error({
               message: "You can upload only image file. Supported image formats: jpeg, png.",
            })
            throw new Error()
         } else if (file.size > 1048576) {
            notification.error({
               message: "File size must be less than 1MB.",
            })
            throw new Error()
         }
         const baseFile = await getBase64(file)
         if (typeof baseFile === "string") {
            setLogo(baseFile)
            onSuccess("Ok")
         }
      } catch (err) {
         onError(err)
      }
   }

   const clearValue = () => {
      if (field.current?.value) {
         field.current.value = ""
      }
   }

   const handleClearImage = () => {
      setLogo(null)
   }

   const getBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => {
            const img = new Image()
            //@ts-ignore
            img.src = reader.result
            img.onload = () => {
               if (img.width >= 1024) {
                  notification.error({ message: "Image width should be less than 1024px" })
                  reject()
                  return
               } else if (img.height >= 1024) {
                  notification.error({ message: "Image height should be less than 1024px" })
                  reject()
                  return
               }
               resolve(reader.result)
            }
         }
         reader.onerror = (error) => reject(error)
      })
   }

   const handleSubmit = async () => {
      await form.validateFields()
      const data = await form.getFieldsValue()
      if (logo) {
         data.logo = logo
      }
      try {
         const { address1, address2, city, state, zip_code, ...newData } = data
         await onSave(
            Api.company.create({
               ...newData,
               address: {
                  full_address: value,
                  address1: address1 ?? "",
                  address2: address2 ?? "",
                  city: city ?? "",
                  state: state ?? "",
                  zip_code: zip_code ?? "",
               },
            })
         )
         const { data: profile } = await Api.user.profile()
         dispatch(setCurrentUser(profile))
      } catch (e) {
         form.setFields(getErrors(e))
      }
   }

   return (
      <MainWrapper backgroundColor={!isMd ? "none" : "#FBFBFF"}>
         <LoginFlowWrapper padding={isMd ? "0 12px" : "0"}>
            <LogoContainer p={35}>
               <NavLink to={routes.auth.Login}>
                  <img src={DarkLogo} alt="logotype" />
               </NavLink>
            </LogoContainer>
            <Flex flexDirection="column" justifyContent="flex-start" height="100%">
               <Box m="auto">
                  <Flex
                     flexDirection="column"
                     justifyContent="space-between"
                     maxWidth={isMd ? "400px" : "initial"}
                     margin="auto auto 30px auto"
                  >
                     <Flex flexDirection="column">
                        <Box mb="24px" textCenter>
                           <Msg fontWeight={600} fontSize={15} color="#252733">
                              Step 2 of 3
                           </Msg>
                        </Box>
                        <Box mb="30px" textCenter>
                           <Msg fontWeight={700} fontSize={22}>
                              You`re almost done!
                           </Msg>
                        </Box>
                        <Form form={form} onFinish={handleSubmit}>
                           <Box mb="19px">
                              <Msg fontWeight={700} fontSize={18} color="#252733">
                                 1. Tell us about your company.
                              </Msg>
                           </Box>
                           <Box mb="8px">
                              <Msg fontWeight={700} fontSize={15} color="#484848">
                                 Company name
                              </Msg>
                           </Box>
                           <Form.Item
                              name="name"
                              rules={[
                                 {
                                    required: true,
                                    // type: "regexp",
                                    pattern: companyName,
                                    message: "Please input correct company name!",
                                 },
                              ]}
                           >
                              <StyledInput placeholder="Type here" />
                           </Form.Item>

                           <Box mb="8px" mt="15px">
                              <Msg fontWeight={700} fontSize={15} color="#484848">
                                 Website (optional)
                              </Msg>
                           </Box>
                           <Form.Item name="website">
                              <StyledInput placeholder="Type here" />
                           </Form.Item>
                           <Box mb="8px" mt="15px">
                              <Msg fontWeight={700} fontSize={15} color="#484848">
                                 Company logo (optional)
                              </Msg>
                           </Box>
                           <Form.Item name="logo">
                              {logo ? (
                                 <ImageWrapperFlex>
                                    <div>
                                       <img src={logo} alt="Logo" />
                                       <CloseLogoButton onClick={handleClearImage} className="cancelButton">
                                          <img src={TrashBin} alt="icon" />
                                       </CloseLogoButton>
                                    </div>
                                 </ImageWrapperFlex>
                              ) : (
                                 <>
                                    <StyledUpload name="logo" customRequest={uploadImage} ref={field}>
                                       <UploadWrapper>
                                          <UploadImage
                                             prefix={<UploadtContent />}
                                             value="Drag image here or click here to add"
                                          />
                                       </UploadWrapper>
                                       <Msg fontSize="13px" color="#484848">
                                          The recommended size of the company logo is 1024x1024
                                       </Msg>
                                    </StyledUpload>
                                 </>
                              )}
                           </Form.Item>
                           <AddressWithAutoComplete form={form} label="Business address" value={value} setValue={setValue} isLongView  />

                           <Box mb="8px" mt="15px">
                              <Msg fontWeight={700} fontSize={15} color="#484848">
                                 Public phone number
                              </Msg>
                           </Box>
                           <Form.Item
                              name="phone"
                           >
                              <StyledInput
                                placeholder={DefaultPhoneNumber}
                                onBlur={updatePhoneOnBlur(form, 'phone')} />
                           </Form.Item>

                           <Box mb="8px" mt="15px">
                              <Msg fontWeight={700} fontSize={15} color="#484848">
                                 Public email address
                              </Msg>
                           </Box>
                           <Form.Item
                              name="email"
                              rules={[
                                 {
                                    // required: true,
                                    message: "Please input public email address!",
                                 },
                              ]}
                           >
                              <StyledInput placeholder="Type here" type="email" />
                           </Form.Item>

                           <Box mb="19px" mt={isMd ? "24px" : "44px"}>
                              <Msg fontWeight={700} fontSize={18} color="#252733">
                                 2. How many people work in your company?
                              </Msg>
                           </Box>

                           <Form.Item
                              name="company_size"
                              rules={[
                                 {
                                    required: true,
                                    message: "Please choose number of people in your company!",
                                 },
                              ]}
                           >
                              <Flex width={isMd ? "100%" : "374px"} justifyContent="space-between">
                                 <StylizeWr>
                                    <StylizeRadio value="s">1-4</StylizeRadio>
                                    <StylizeRadio value="m">5-8</StylizeRadio>
                                    <StylizeRadio value="l">9-15</StylizeRadio>
                                    <StylizeRadio value="x">15+</StylizeRadio>
                                 </StylizeWr>
                              </Flex>
                           </Form.Item>
                           <Box mb="19px" mt={isMd ? "24px" : "44px"}>
                              <Msg fontWeight={700} fontSize={18} color="#252733">
                                 3. What kinds of services do you offer?
                              </Msg>
                           </Box>
                           <CheckboxWrapper flexDirection="column" width={isMd ? "100%" : "374px"}>
                              <Form.Item name={"service_categories"}>
                                 <CheckboxGroup options={fields} />
                              </Form.Item>
                           </CheckboxWrapper>
                           <Box mb="19px" mt={isMd ? "0px" : "44px"}>
                              <Msg fontWeight={700} fontSize={18} color="#252733">
                                 4. What types of vessel do you service?
                              </Msg>
                           </Box>
                           <Form.Item noStyle shouldUpdate>
                              {({ getFieldValue }) => (
                                 <Form.Item
                                    name="vessel_types"
                                    rules={[{ required: true, message: "Please select at least one." }]}
                                    shouldUpdate
                                 >
                                    <StyledCheckboxGroup>
                                       {boats.map((boat) => (
                                          <Checkbox value={boat.value}>
                                             <img
                                                src={
                                                   (getFieldValue("vessel_types") || []).includes(boat.value)
                                                      ? boat.checkedImage
                                                      : boat.image
                                                }
                                                alt={boat.value}
                                             />
                                             <span>{boat.label}</span>
                                          </Checkbox>
                                       ))}
                                    </StyledCheckboxGroup>
                                 </Form.Item>
                              )}
                           </Form.Item>
                           <Box mt="30px" mb="16px">
                              <BtnPr
                                 loading={saving}
                                 width={isMd ? "100%" : "100px"}
                                 height="40px"
                                 fontWeight={600}
                                 fontSize={14}
                                 onClick={() => handleSubmit()}
                              >
                                 Next step
                              </BtnPr>
                           </Box>
                           <Msg fontSize="15px" color="#8B8B8B" fontWeight="bold">
                              Do you want to return to step 1?
                              <LogOutBtn onClick={handleLogout}>Log out</LogOutBtn>
                           </Msg>
                        </Form>
                     </Flex>
                  </Flex>
               </Box>
            </Flex>
         </LoginFlowWrapper>
         {!isMd && (
            <BoxWrapper position="fixed" right="0px">
               <img src={SmallBoatImg} width="100%" height="100%" alt="background" />
            </BoxWrapper>
         )}
      </MainWrapper>
   )
}
