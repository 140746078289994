import React, { useEffect, useState } from "react";

import {
  AddButton,
  BlockTitle,
  BoldSpan,
  Wrapper,
  WrapperTechnician,
} from "../styled";

import { Form, FormInstance } from "antd";
import {
  IJob,
  IUpdateJob,
  IUser,
  IWorkOrderWithJobs,
} from "../../../api/types";
import { TechnicianRow } from "./TechnicianRow";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { TCallback } from "../../../types";
import { Api } from "../../../api/api";
import { FormListFieldData } from "antd/lib/form/FormList";

type TProps = {
  form: FormInstance<IUpdateJob>;
  job: IJob | null;
  technicianRole: boolean;
  setIsBooked: any;
  workOrder?: IWorkOrderWithJobs | null;
  setIsFormFieldChanged?: (value: boolean) => void;
};

export const TechniciansBlock: React.FC<TProps> = ({
  form,
  job,
  technicianRole,
  setIsBooked,
  workOrder,
  setIsFormFieldChanged,
}) => {
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));
  const [allUsers, setAllUsers] = useState<IUser[]>([]);

  const handleAdd = (add: TCallback) => {
    add();
    setIsBooked(job);
    if (setIsFormFieldChanged) {
      setIsFormFieldChanged(true);
    }
  };

  const loadData = async () => {
    const { data } = await Api.user.getAll({});
    setAllUsers(data as IUser[]);
  };

  const checkIsAllTechs = (fields: FormListFieldData[]) =>
    !!allUsers.length && fields.length >= allUsers.length;

  useEffect(() => {
    loadData().then();
  }, []);

  return (
    <div>
      <BlockTitle>Technicians</BlockTitle>
      {!(isXs || isMd) && (
        <WrapperTechnician
          style={
            technicianRole
              ? { gridTemplateColumns: "2fr 2fr 0.5fr 1fr 1fr 0.5fr" }
              : {}
          }
        >
          <BoldSpan>Technicians</BoldSpan>
          {!technicianRole && <BoldSpan>Rate</BoldSpan>}

          <BoldSpan style={{ minWidth: "85px" }}>Estimated hours</BoldSpan>
          {!technicianRole && <BoldSpan>Est. Total</BoldSpan>}

          <span />
        </WrapperTechnician>
      )}
      <Form.List name="technicians_data">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <TechnicianRow
                form={form}
                key={`${job ? job.id : ""}-${field.key}`}
                field={field}
                remove={remove}
                technicianRole={technicianRole}
                workOrder={workOrder}
                setIsFormFieldChanged={setIsFormFieldChanged}
              />
            ))}
            {!technicianRole && !checkIsAllTechs(fields) && (
              <Form.Item>
                <AddButton
                  id={`${job?.id}_tech_add`}
                  onClick={() => {
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                    handleAdd(add);
                  }}
                >
                  Add Technician
                </AddButton>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
};
