import { Tabs, Select, Table, Button, Empty } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import { Search } from "../../noui/Search";
import { ColumnsType } from "antd/es/table";
import { EScheduleStatuses, TAddress } from "../../api/api";
import moment from "moment";
import { TJobsTableData, TJobsTableProps, TScheduleOrTimeclock } from "./types";
import { EJobStatus, IAddress, IJob } from "../../api/types";
import { isSchedule } from "./utils";
import {
  CompletedIcon,
  FutureIcon,
  ScheduledIcon,
  UnassignedIcon,
  WorkingIcon,
  ConflictIcon,
} from "../../assets/images/dispatchTab/TabIcons";
import { NavLink, useHistory } from "react-router-dom";
import routes from "../../routes/routes";
import GoogleMapReact from "google-map-react";
import { Marker } from "../../containers/customers/pages/styled";
import { validateLatitude, validateLongtitude } from "../../utils/utils";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { TableCard } from "../../components/TableCard";
import { RightOutlined } from "@ant-design/icons";
import { width } from "styled-system";
import { PALETTE } from "../../constants";
import { GoogleMapsAPIKey } from "../../api";

const { TabPane } = Tabs;
const { Option } = Select;

const JobsTablePanel = styled.div`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px;
  margin-top: 24px;
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: ${PALETTE.Secondary.Medium};
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${PALETTE.Secondary.Medium};
    }
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #ffffff;
  }
  .ant-select-selector {
    font-weight: bold;
  }
  .ant-table-thead > tr > th {
    background-color: #ffffff;
    font-weight: bold;
  }
  ${down("lg")} {
    padding: 14px;
    .ant-tabs {
      width: 100%;
    }
  }
`;

const DesktopTabsWrapper = styled.div`
  .ant-tabs-tab {
    font-weight: 600;
    .anticon {
      margin-right: 5px;
    }
  }
  .ant-tabs {
    flex: 1;
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-tab {
      flex: 1;
      text-align: center;
      justify-content: center;
    }
  }
`;

const JobLocationPanel = styled.div`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px;
  margin-top: 24px;
  height: 600px;
  padding-bottom: 50px;
  ${down("lg")} {
    height: 400px;
  }
  ${down("xs")} {
    height: 300px;
    padding: 14px 14px 40px;
  }
`;

const SmallSearch = styled(Search)`
  width: 142px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-left: 16px;
  ${down("lg")} {
    margin-left: 0px;
    width: 100%;
  }
`;
const TabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
  }
`;
const CountConflicts = styled(Flex)`
  width: 22px;
  height: 22px;
  background: #fb4d4f;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  margin-left: 5px;
`;

const JobsTable = ({
  users,
  schedules,
  timeclocks,
  unscheduled,
  conflicts,
}: TJobsTableProps) => {
  const [choosedTab, setChoosedTab] = useState("unassigned");
  const [jobs, setJobs] = useState<TJobsTableData[]>([]);
  const [jobSearch, setJobSearch] = useState("");
  const [jobSelectValue, setJobSelectValue] = useState("all");
  const [mapsOptions, setMapsOptions] = useState({
    center: {
      lat: 41,
      lng: -73,
    },
    zoom: 1,
  });
  const history = useHistory();
  const isXs = useBreakpoint(down("xs"));
  const isLg = useBreakpoint(down("lg"));

  const filterByTab = () => {
    switch (choosedTab) {
      case "unassigned":
        return (item: TScheduleOrTimeclock) =>
          isSchedule(item) ? item.technicians.length === 0 : false;
      case "scheduled":
        return (item: TScheduleOrTimeclock) =>
          isSchedule(item) ? true : false;
      case "on my way":
        //TODO
        return (s: TScheduleOrTimeclock) => false;
      case "working":
        return (item: TScheduleOrTimeclock) =>
          isSchedule(item) ? false : !item.stop;
      case "completed":
        return (item: TScheduleOrTimeclock) =>
          isSchedule(item) ? false : item.stop;
      case "hold":
        return (item: TScheduleOrTimeclock) =>
          isSchedule(item) ? item.status === EScheduleStatuses.H : false;
      case "canceled":
        return (item: TScheduleOrTimeclock) =>
          isSchedule(item) ? item.status === EScheduleStatuses.C : false;
      case "future":
        //TODO
        return (s: TScheduleOrTimeclock) => false;
      default:
        return (s: TScheduleOrTimeclock) => false;
    }
  };

  const filterByJobStatus = () => {
    if (jobSelectValue !== "all") {
      if (jobSelectValue === "Approved") {
        return (s: TScheduleOrTimeclock) => s.job.is_approved;
      }
      return (s: TScheduleOrTimeclock) => s.job.status === jobSelectValue;
    }
    return (s: TScheduleOrTimeclock) => true;
  };

  const filterJobByStatus = () => {
    if (jobSelectValue !== "all") {
      if (jobSelectValue === "Approved") {
        return (j: IJob) => j.is_approved;
      }
      return (j: IJob) => j.status === jobSelectValue;
    }
    return (j: IJob) => true;
  };

  const getAssignee = (item: TScheduleOrTimeclock) => {
    if (isSchedule(item)) {
      return (
        users.find((user) => user.id === item.technicians[0])?.full_name ||
        "Unassigned"
      );
    }
    return (
      users.find((user) => user.id === item.job.technicians[0])?.full_name ||
      "Unassigned"
    );
  };

  const getAddress = (address: TAddress | null) =>
    `${address?.city ? address.city + ", " : ""}${address?.address1 ?? ""}` ||
    "No data";

  const setItems = (items: Array<TScheduleOrTimeclock>) => {
    const filteredItems: TJobsTableData[] = [];
    //console.log("unscheduled", unscheduled)
    //console.log("conflict", conflicts)
    if (choosedTab === "conflict") {
      conflicts
        .filter(filterByJobStatus())
        .filter((item) => item.job.title.includes(jobSearch))
        .forEach((item) => {
          filteredItems.push({
            job: item.job,
            assignee: getAssignee(item),
            created: moment(item.job.created).format("MM/DD/YY [at] h:mmA"),
            start: moment(new Date(item.start)).format("MM/DD/YY [at] h:mmA"),
            end: moment(new Date(item.end)).format("MM/DD/YY [at] h:mmA"),
            vessel: item.job?.vessel?.name,
            customer:
              item.job.customer.first_name + " " + item.job.customer.last_name,
            vessel_location: getAddress(item?.address) ?? "No data",
            lat: item?.address?.lat ?? null,
            lng: item?.address?.lng ?? null,
          });
        });
    } else if (choosedTab !== "unscheduled") {
      items
        .filter(filterByTab())
        .filter(filterByJobStatus())
        .filter((item) => item.job.title.includes(jobSearch))
        .forEach((item) => {
          filteredItems.push({
            job: item.job,
            assignee: getAssignee(item),
            created: moment(
              new Date(isSchedule(item) ? item.created : item.start)
            ).format("MM/DD/YY [at] h:mmA"),
            start: moment(new Date(item.start)).format("MM/DD/YY [at] h:mmA"),
            //end: moment(new Date(item.stop)).format("MM/DD/YY [at] h:mmA"),
            vessel: item.job?.vessel.name,
            customer:
              item.job.customer.first_name + " " + item.job.customer.last_name,
            vessel_location: getAddress(item?.address) ?? "No data",
            lat: item?.address?.lat ?? null,
            lng: item?.address?.lng ?? null,
          });
        });
    } else {
      unscheduled
        .filter(filterJobByStatus())
        .filter((item) => item.title.includes(jobSearch))
        .forEach((job) => {
          filteredItems.push({
            job: job,
            assignee: "Unassigned",
            created: moment(job.created).format("MM/DD/YY [at] h:mmA"),
            start: "Unscheduled",
            vessel: job.vessel_data.name,
            customer:
              job.customer_data.first_name + " " + job.customer_data.last_name,
            vessel_location: getAddress(job?.address),
            lat: job?.address?.lat ?? null,
            lng: job?.address?.lng ?? null,
          });
        });
    }

    return filteredItems;
  };

  useEffect(() => {
    if (schedules.length && timeclocks.length) {
      let newJobs: TJobsTableData[] = [];
      // choosedTab !== "unscheduled" ? [...setItems(schedules), ...setItems(timeclocks)] : [...setItems(schedules)]
      if (choosedTab === "unscheduled") {
        newJobs = [...setItems(schedules)];
      } else if (choosedTab === "conflict") {
        newJobs = [...setItems(conflicts)];
      } else {
        newJobs = [...setItems(schedules), ...setItems(timeclocks)];
      }

      setJobs(newJobs);
    }
  }, [schedules, timeclocks, choosedTab, jobSearch, jobSelectValue, conflicts]);

  useEffect(() => {
    const filteredJobs = jobs.filter(filterJobsByVesselAddress);
    if (filteredJobs.length) {
      setMapsOptions({
        center: {
          lat: filteredJobs[0].lat ?? 41,
          lng: filteredJobs[0].lng ?? -73,
        },
        zoom: 1,
      });
    }
  }, [jobs]);
  //console.log("jobs", jobs)

  const columns: ColumnsType<TJobsTableData> = [
    {
      title: "Job #",
      dataIndex: "job",
      key: "job",
      render: (_, item) => (
        <NavLink
          to={
            routes.workOrder.UpdateWorkOrder.replace(
              ":id",
              `${item.job.work_order}`
            ) + `?job=${item.job.id}`
          }
        >
          {item.job.title}
        </NavLink>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      render: (_, item) => item.assignee,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (_, item) => item.created,
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      render: (_, item) => item.start,
    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
      render: (_, item) => item.end,
    },
    {
      title: "Vessel",
      dataIndex: "vessel",
      key: "vessel",
      render: (_, item) => item?.vessel,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (_, item) => item.customer,
    },
    {
      title: "Vessel Location",
      dataIndex: "vessel_location",
      key: "vessel_location",
      render: (_, item) => item.vessel_location,
    },
  ];

  const filterColumns = () => {
    if (choosedTab === "unassigned") {
      return columns.filter((c) => c.title !== "Assignee" && c.title !== "End");
    } else if (choosedTab === "conflict") {
      return columns.filter((c) => c.title !== "Created");
    } else {
      return columns.filter((c) => c.title !== "End");
    }
  };

  const filterJobsByVesselAddress = (item: TJobsTableData) =>
    validateLatitude(item.lat) && validateLongtitude(item.lng);

  const handleChange = (key: string) => {
    setChoosedTab(key);
  };

  const handleMapChange = (options: GoogleMapReact.ChangeEventValue) => {
    setMapsOptions({ center: options.center, zoom: options.zoom });
  };

  const showTabs = () => (
    <TabsStyled defaultActiveKey="unassigned" onChange={handleChange}>
      <TabPane
        tab={
          <Flex alignItems="center">
            <UnassignedIcon />
            Unassigned Jobs
          </Flex>
        }
        key="unassigned"
      />
      <TabPane
        tab={
          <Flex alignItems="center">
            <ConflictIcon />
            Conflict{" "}
            {conflicts && conflicts.length > 0 && (
              <CountConflicts alignItems="center" justifyContent="center">
                {conflicts.length}
              </CountConflicts>
            )}
          </Flex>
        }
        key="conflict"
      />
      <TabPane
        tab={
          <Flex alignItems="center">
            <ScheduledIcon />
            Scheduled
          </Flex>
        }
        key="scheduled"
      />
      <TabPane
        tab={
          <Flex alignItems="center">
            <FutureIcon />
            Unscheduled
          </Flex>
        }
        key="unscheduled"
      />
      <TabPane
        tab={
          <Flex alignItems="center">
            <WorkingIcon />
            Working
          </Flex>
        }
        key="working"
      />
      <TabPane
        tab={
          <Flex alignItems="center">
            <CompletedIcon />
            Completed
          </Flex>
        }
        key="completed"
      />
    </TabsStyled>
  );

  return (
    <>
      <JobsTablePanel>
        <Msg fontWeight={900} fontSize={16} color="#151F2B">
          JOBS LIST - {choosedTab.toUpperCase()}
        </Msg>
        <Flex
          flexDirection={isLg ? "column-reverse" : "row"}
          justifyContent="space-between"
          alignItems={isLg ? "start" : "center"}
          flexWrap="wrap"
        >
          {isLg ? (
            showTabs()
          ) : (
            <DesktopTabsWrapper>{showTabs()}</DesktopTabsWrapper>
          )}
          <Flex
            marginTop={isLg ? "10px" : "0px"}
            style={{ padding: "10px 0" }}
            flexDirection={isLg ? "row-reverse" : "row"}
          >
            <Select
              defaultValue="all"
              onChange={(value) => setJobSelectValue(value)}
              style={{ marginLeft: 10, width: "116px", minWidth: "116px" }}
            >
              <Option key="all" value="all">
                All Jobs
              </Option>
              <Option key={EJobStatus.Estimate} value={EJobStatus.Estimate}>
                Estimate
              </Option>
              <Option key={"Approved"} value={"Approved"}>
                Approved
              </Option>
              <Option key={EJobStatus.Progress} value={EJobStatus.Progress}>
                In Progress
              </Option>
            </Select>
            <SmallSearch
              placeholder=""
              onChange={(e: React.ChangeEvent<any>) =>
                setJobSearch(e.target.value)
              }
            />
          </Flex>
        </Flex>
        {!isLg ? (
          <Table
            dataSource={jobs}
            columns={filterColumns()}
            pagination={false}
            scroll={{ y: 320 }}
          />
        ) : (
          <Flex
            flexWrap="wrap"
            style={{ maxHeight: "500px", overflow: "auto", padding: "5px" }}
          >
            {jobs.length ? (
              jobs.map((d) => (
                <TableCard>
                  <Flex flexDirection="row" alignItems="center">
                    <Flex flexDirection="column" flex="1">
                      {filterColumns().map((c, i) => (
                        <Flex key={i} justifyContent="space-between">
                          <Msg fontWeight={700}>{c.title}</Msg>
                          <Msg style={{ textAlign: "end" }}>
                            {c.render && c.render(d, d, i)}
                          </Msg>
                        </Flex>
                      ))}
                    </Flex>
                    <Button
                      style={{ border: "none" }}
                      onClick={() => {
                        history.push(
                          routes.workOrder.UpdateWorkOrder.replace(
                            ":id",
                            `${d.job.work_order}`
                          ) + `?job=${d.job.id}`
                        );
                      }}
                    >
                      <RightOutlined />
                    </Button>
                  </Flex>
                </TableCard>
              ))
            ) : (
              <Empty style={{ width: "100%" }} />
            )}
          </Flex>
        )}
      </JobsTablePanel>
      <JobLocationPanel>
        <Msg fontWeight={900} fontSize={16} color="#151F2B">
          JOB LOCATION
        </Msg>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
          defaultCenter={{ lat: 41, lng: -73 }}
          center={mapsOptions.center}
          defaultZoom={mapsOptions.zoom}
          onChange={handleMapChange}
        >
          {jobs.filter(filterJobsByVesselAddress).map((item) => (
            <Marker lat={item.lat} lng={item.lng} />
          ))}
        </GoogleMapReact>
      </JobLocationPanel>
    </>
  );
};

export default JobsTable;
