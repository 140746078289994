import React from 'react';
import DarkLogo from '../../../assets/images/DarkLogo.svg';
import Flex from "../../../noui/Flex";
import Box from '../../../noui/Box';
import styled from 'styled-components';
import {Msg} from '../../../ui/Text';
import {Form, Input} from 'antd';
import {PrimaryButton} from '../../../ui/Button';
import {NavLink, useHistory} from 'react-router-dom';
import SmallBoatImg from '../../../assets/images/SmallBoatImg.png'
import routes from "../../../routes/routes";
import {getErrors} from "../../../utils/utils";
import {Api} from "../../../api/api";
import {IRegisterUser} from "../../../api/types";
import {authService} from "../../../utils/authService";
import {setCurrentUser} from "../../../config/reducers/user/actions";
import {useAppDispatch} from "../../../config/hooks";
import {useLoading} from "../../../utils/hooks";
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { BoxWrapper, LoginFlowWrapper, StyledEnvelopeIcon, StyledLockIcon } from '../../login/Login';


const StyledInput = styled(Input)`
  height: 40px;
  width: 374px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  border-radius: 4px;
  ${down('md')} {
    width: 100%;
  }
`;

const StyledPasswordInput = styled(Input.Password)`
  height: 40px;
  width: 374px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  border-radius: 4px;
  ${down('md')} {
    width: 100%;
  }
`;

const LogoContainer = styled(Box)`
& img {
    width: 140px;
    height: 24px;
}
${down('md')} {
  margin: 0 auto;
}
`;

const BtnPr = styled(PrimaryButton)`
&:hover {
  border: 1px solid #109CF1;
}
`

export const RegistrationStepOne: React.FC = () => {
  const [form] = Form.useForm<IRegisterUser>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [saving, onSave] = useLoading();
  const isMd = useBreakpoint(down('md'));

  const handleSubmit = async (data: IRegisterUser) => {
    try {
      await onSave(Api.user.register(data));
      await authService.login({email: data.email, password: data.password});
      const {data: profile} = await Api.user.profile();
      dispatch(setCurrentUser(profile));
      history.replace(routes.registration.Step2);
    } catch (e) {
      form.setFields(getErrors(e));
    }
  }

  return <Flex backgroundColor={!isMd ? "none" : "#FBFBFF"}>
    <LoginFlowWrapper padding={isMd ? "0 12px" : "0"}>
      <LogoContainer p={35}>
        <NavLink to={routes.auth.Login}>
          <img src={DarkLogo} alt='logotype'/>
        </NavLink>
      </LogoContainer>
      <Flex flexDirection="column" justifyContent='flex-start' height="100%">
        <Box m={isMd ? "auto 0" : "auto"}>
          <Flex flexDirection='column' justifyContent='space-between' maxWidth={isMd ? "400px" : "initial"} margin="auto">
            <Flex flexDirection='column'>
              <Box mb='30px' textCenter={!!isMd}>
                <Msg fontWeight={700} fontSize={22}>Get Started</Msg>
              </Box>
              <Form
                onFinish={handleSubmit}
                form={form}
              >
                <Box mb='8px'>
                  <Msg fontWeight={700} fontSize={15} color="#484848">First name</Msg>
                </Box>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your first name!',
                    },
                  ]}>
                  <StyledInput placeholder="Type here" />
                </Form.Item>

                <Box mb='8px' mt='15px'>
                  <Msg fontWeight={700} fontSize={15} color="#484848">Last name</Msg>
                </Box>
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your last name!',
                    },
                  ]}>
                  <StyledInput placeholder="Type here" />
                </Form.Item>
                <Box mb='8px' mt='15px'>
                  <Msg fontWeight={700} fontSize={15} color="#484848">E-mail</Msg>
                </Box>
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: 'The input is not valid e-mail!',
                    },
                  ]}
                >
                  <StyledInput prefix={<StyledEnvelopeIcon />} placeholder="user@gmail.com" />
                </Form.Item>

                <Box mb='8px' mt='15px'>
                  <Msg fontWeight={700} fontSize={15} color="#484848">Create Password</Msg>
                </Box>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <StyledPasswordInput prefix={<StyledLockIcon />} aria-autocomplete="inline" autoComplete="new-password" placeholder="Password" />
                </Form.Item>
                <Box mt='30px' mb="15px">
                  <Form.Item>
                    <BtnPr
                      width={isMd ? "100%" : "100px"}
                      loading={saving}
                      height="40px"
                      fontWeight={600}
                      fontSize={14}
                      htmlType="submit">Register</BtnPr>
                  </Form.Item>
                </Box>
              </Form>
            </Flex>

          </Flex>
        </Box>
        <Box width={isMd ? "100%" : "374px"} m="auto" mb="30px" mt="0" textCenter={!!isMd}>
          <Msg fontWeight={600} fontSize={16} color="#BDBDBD">
            Are you already registered?
            &nbsp;
            <NavLink to="/login" style={{textDecoration: "underline"}}>Sign In</NavLink>
          </Msg>
        </Box>
      </Flex>
    </LoginFlowWrapper>
    {!isMd && <BoxWrapper><img src={SmallBoatImg} width="100%" height="100%" alt="background"/></BoxWrapper>}
  </Flex>
}