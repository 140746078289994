import React, { forwardRef, useMemo } from "react"
import { Button, Divider, Input, Select } from "antd"
import { SendOutlined, DownloadOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { Title } from "../../layouts/CustomerLayout/styled"
import {
   EAuthor,
   IExternalEstimate,
   IExternalRecurring,
   IExternalSchedule,
   IJob,
   IMessage,
   IScheduleJob,
   IShortJob,
   IStatementOfWork,
} from "../../api/types"
import moment from "moment"
import { TArgCallback, TCallback, TInputChangeHandler } from "../../types"
import { mapOptions } from "../../utils/utils"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"

const CommentsWrapper = styled.div`
   display: flex;
   flex-flow: column nowrap;
   position: absolute;
   top: 50px;
   left: 16px;
   width: 100%;
   align-items: center;
   //justify-content: end;
   // height: 400px;
   max-height: calc(100% - 50px);
   height: calc(100% - 50px);
   overflow-y: scroll;

   > :first-child {
      margin-top: auto !important;
      /* use !important to prevent breakage from child margin settings */
   }

   & > div + div {
      margin-top: 8px;
   }
   ${down("xs")} {
      max-height: 250px;
      min-height: 250px;
   }
`

const CommentContainer = styled.div`
   border: 1px solid #e6e6e6;
   box-sizing: border-box;
   border-radius: 4px;
   padding: 8px;
   width: 90%;
   h4 {
      font-weight: bold;
      margin: 0;
      font-size: 14px;
      line-height: 14px;
   }
   small {
      font-size: 11px;
      font-style: italic;
      margin-bottom: 12px;
      display: inline-block;
   }
   p {
      margin: 0;
      font-size: 15px;
   }
   &.T {
      background: #fbfdff;
      text-align: right;
      align-self: flex-end;
   }
   &.C {
      background: #109cf1;
      color: #fff;
      align-self: flex-start;
      text-align: left;
      h4 {
         color: #fff;
      }
   }
`

const NewMessageContainer = styled.div`
   margin-top: 12px;
   display: flex;
   input {
      margin-right: 8px;
   }
`

const SelectJobWrapper = styled.div`
   margin-top: 12px;
   display: flex;
   align-items: center;
   .ant-select {
      flex-grow: 1;
   }
`
const NewMessageWrapper = styled.div`
   background: white;
   z-index: 25;
   width: 100%;
   padding: 0px 15px 15px 20px;
`
const PaddedContainer = styled.div`
   height: 100%;
   flex-direction: column;
   justify-content: space-between;
   border: 1px seagreen solid;
`
const SubTittle = styled.p`
   width: 68%;
`

const PdfButton = styled(Button)`
   height: 100%;
   width: 100%;
   &.ant-btn {
      padding: 10px !important;
   }
`

type TMProps = {
   message: IMessage
   reverse: boolean
}

const Message: React.FC<TMProps> = ({ message, reverse = false }) => {
   const formatMessage = (msg: string) => msg.split("\n").map((m) => <p style={{ wordBreak: "break-all" }}>{m}</p>)

   return (
      <CommentContainer className={!reverse ? message.author : message.author === EAuthor.T ? EAuthor.C : EAuthor.T}>
         <h4>{message.full_name}</h4>
         <small>{moment(message.created).format("LLL")}</small>
         {formatMessage(message.message)}
      </CommentContainer>
   )
}

type TProps = {
   message?: string
   messages: IMessage[]
   onChangeMessage?: TInputChangeHandler
   onSend?: TCallback
   short?: boolean
   jobs?: (IJob | IShortJob | IScheduleJob)[]
   selectedJob: string | null
   onSelectJob?: TArgCallback<string>
   estimate?: IExternalRecurring | IExternalSchedule | IStatementOfWork
   handleToggleSideBarComponent?: (info: string) => void
   isHide?: boolean
}
export const Messages = forwardRef<Input, TProps>(
   (
      {
         messages,
         message,
         isHide,
         onChangeMessage,
         handleToggleSideBarComponent,
         onSend,
         estimate,
         short = false,
         jobs,
         selectedJob,
         onSelectJob,
      },
      ref
   ) => {
      const messagesList = useMemo(() => {
         return short ? messages : messages.slice().reverse()
      }, [messages, short])
      const jobOptions = useMemo(() => {
         if (jobs && jobs.length) {
            return mapOptions(jobs, "order_number")
         }
         return []
      }, [jobs])
      const isXs = useBreakpoint(down("xs"))
      return (
         <PaddedContainer>
            <Flex justifyContent={"space-between"} padding={"16px"}>
               {!short && !isXs ? <Title>Messages</Title> : null}
               {handleToggleSideBarComponent && (
                  <Msg
                     color={"#0496FF"}
                     style={{ cursor: "pointer", textDecoration: "underline" }}
                     onClick={() => {
                        handleToggleSideBarComponent("messages")
                     }}
                  >
                     {isHide ? "Hide" : "Show"}
                  </Msg>
               )}
            </Flex>
            {isHide && (
               <Flex flexDirection="column">
                  {messages.length === 0 && isHide && (
                     <SubTittle>
                        <Msg color="#8B8B8B">
                           Type a message below to talk directly with a {estimate?.company.name} representative.
                        </Msg>
                     </SubTittle>
                  )}
                  <CommentsWrapper>
                     {messages.length > 0 &&
                        messages.map((message) => <Message key={message.id} message={message} reverse={short} />)}
                  </CommentsWrapper>
                  {onSend ? (
                     <>
                        <NewMessageWrapper>
                           <NewMessageContainer>
                              <Input ref={ref} value={message} onPressEnter={onSend} onChange={onChangeMessage} />
                              <Button type="primary" onClick={onSend} disabled={!message} icon={<SendOutlined />} />
                           </NewMessageContainer>
                        </NewMessageWrapper>
                     </>
                  ) : null}
               </Flex>
            )}
         </PaddedContainer>
      )
   }
)
