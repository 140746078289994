import { Button, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Msg } from '../../ui/Text';
import { TUpdateDay, TUpdateDayItem, TUpdateProps } from './types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Flex from '../../noui/Flex';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { PALETTE } from '../../constants';
import { Api, IActivity } from '../../api/api';
import { textInBrackets } from '../../utils/regex';

const UpdatesContainer = styled.div`
    flex: 1;
    padding: 28px 4px 4px 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    max-height: 700px;
    margin-left: 24px;
    @media (max-width: 1400px) {
        margin-left: 0px;
        margin-top: 24px;
        padding: 14px;
    }
`;

const UpdatesWrapper = styled.div`
    margin-top: 10px;
    overflow-y: auto;
    height: 100%;
    max-height: 600px;
    width: 250px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: ${PALETTE.Secondary.Medium};
    }
    @media (max-width: 1400px) {
        width: 100%;
    }
`;

const UpdateDayContainer = styled.div`
    margin-top: 24px;
`;

const UpdateDayTitle = styled.p`
    text-align: center;
    color: #BDBDBD;
    font-size: 12px;
    @media (max-width: 1400px) {
        font-weight: bold;
    }
`;

const UpdateDayItemContainer = styled.div`
    border-bottom: 1px solid #ECECEC;
    margin-top: 16px;
    padding-bottom: 16px;
    font-size: 12px;
    color: #151F2B;  
    text-align: left;
`;

const UpdateDayItemEmployee = styled.p`
    font-weight: 700;
    margin: 4px 0;
`;

const UpdateDayItemJob = styled.p`
    font-weight: 400;
    margin: 0;
`;

const UpdateDayItem = ({employee, message}: TUpdateDayItem) => {
    const formatActivityMessage = (message: string) => {
        const date = message.match(textInBrackets)
        if (!date) {
           return message
        }
        const formatedDate = moment(date[0].slice(1, date[0].length - 1)).format("MM/DD/YY [at] hh:mm A")
        return message.replace(date[0], formatedDate)
    }
    
    return (
        <UpdateDayItemContainer>
            <UpdateDayItemEmployee>{employee}</UpdateDayItemEmployee>
            <UpdateDayItemJob>{formatActivityMessage(message)}</UpdateDayItemJob>
        </UpdateDayItemContainer>
    );
}

const UpdateDay = ({date, items, users}: TUpdateDay) => {
    const getInitiatorFullName = (initiator: number) => 
        users.find(user => user.id === initiator)?.full_name ?? "";

    return (
        <UpdateDayContainer>
            <UpdateDayTitle>{date}</UpdateDayTitle>
            {items && items.map(item => 
            <UpdateDayItem key={item.created} employee={getInitiatorFullName(item.initiator)} message={item.message}/>)
            }
        </UpdateDayContainer>
    );
}

type TDateActivity = {
    [key: string]: IActivity[]
}

const Updates = ({users}: TUpdateProps) => {
    const [isShowing, setIsShowing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isLg = useBreakpoint(down('lg'));
    const [activities, setActivities] = useState<TDateActivity>({})

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async() => {
        try {
            setIsLoading(true)
            const { data } = await Api.activities.get();
            setActivities(formatActivities(data))
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    } 

    const formatActivities = (data: IActivity[]) => {
        const formatedData: TDateActivity = {};
        return data.reduce((acc, activity) => {
            const act_date = moment(activity.created).format("MM/DD/YYYY")
            if (acc[act_date]) {
                acc[act_date].push(activity)
            }
            else {
                acc[act_date] = [activity]
            }
            return acc;
        }, formatedData)        
    }

    return (
        <UpdatesContainer>
            <Spin spinning={isLoading}>
            <Flex flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
                <Msg fontWeight={900} fontSize={16} color="#151F2B">UPDATES</Msg>
                {isLg && <Button 
                icon={isShowing ? <UpOutlined/> : <DownOutlined/>}
                style={{border: "none"}}
                onClick={()=>{setIsShowing(prev => !prev)}}
                ></Button>}
            </Flex>
            {(!isLg || isLg && isShowing) && 
            <UpdatesWrapper>
                {Object.keys(activities).map(date => 
                <UpdateDay key={date} date={date} items={activities[date]} users={users} />)}
            </UpdatesWrapper>}
            </Spin>
        </UpdatesContainer>
    );
}

export default Updates;