import React, { useState, useEffect } from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { TArgCallback } from "../../types"
import { ArrowBack, ArrowNext } from "../../assets/icons"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { url } from "inspector"
import CalendarIcon from "../../assets/images/calendar-icon.svg"
import { Wrapper, Icon, CalendarBtns, CalendarWrapper } from "./styled"

export const Calendar = styled(Flex)<{ disableTime: boolean }>`
   position: relative;

   .react-datepicker__input-container {
      input {
         outline: none;
         border: 1px solid #ccc;
         border-radius: 3px;
         padding: 4px 5px;
         width: 100%;
         &:focus {
            border-color: #40a9ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
         }
         &:disabled {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            cursor: not-allowed;
         }
      }
   }

   .react-datepicker {
      border: none;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
   }
   .react-datepicker__header {
      background: none;
      padding: 8px;
      border-bottom: 1px solid #f0f0f0;
   }
   .react-datepicker__month {
      margin: 8px;
   }
   .react-datepicker__day-name {
      font-weight: bold;
   }
   .react-datepicker__day--selected,
   .react-datepicker__day--keyboard-selected {
      background-color: #1890ff;
   }
   select {
      margin: 0 6px 0 0 !important;
   }
   .react-datepicker__input {
      &-time-container {
         padding: 9px 15px;
         margin: 0;
         background: #f5f5f5;
         height: 100%;
         border-radius: 0 0 0.3rem 0.3rem;
      }
   }
   .react-datepicker-time__input {
      .react-datepicker-time__input {
         border: 1px solid #ccc;
         padding: 2px 5px;
         border-radius: 5px;
         color: #3c3939;
         font-size: 16px;
         outline: none;
         &:focus {
            border: 1px solid #1890ff;
         }
      }
   }
   input[type="time"]::-webkit-calendar-picker-indicator {
      display: block;
   }
   .react-datepicker__day--outside-month {
      color: #ccc !important;
      pointer-events: none;
   }
   .react-datepicker__today-button {
      position: ${(props) => (props.disableTime ? "relative" : "absolute")};
      bottom: ${(props) => (props.disableTime ? "0" : "14px")};
      right: ${(props) => (props.disableTime ? "0" : "18px")};
      background: none;
      border-top: ${(props) => (props.disableTime ? "1px solid #f0f0f0" : "none")};
      padding: ${(props) => (props.disableTime ? "10px 0" : "0")};
      color: #1890ff;
   }
`

type TProps = {
   date: any
   setDate: TArgCallback<any>
   label?: string
   excludePastDays?: boolean
   disableTime?: boolean
   disable?: boolean
}

export const CalendarPicker: React.FC<TProps> = ({ date, setDate, label, excludePastDays, disableTime, disable }) => {
   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ]

   const generateYears = () => {
      const years = []
      const dateStart = moment().subtract(3, "y")
      const dateEnd = moment().add(3, "y")
      while (dateEnd.diff(dateStart, "years") >= 0) {
         years.push(dateStart.format("YYYY"))
         dateStart.add(1, "year")
      }
      return years
   }

   return (
      <Wrapper>
         {label && <h3>{label}</h3>}
         <Calendar disableTime={Boolean(disableTime)}>
            <DatePicker
               renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
               }) => (
                  <CalendarWrapper justifyContent="center" alignItems="center">
                     <label>
                        <select
                           value={months[date.getMonth()]}
                           onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                        >
                           {months.map((option) => (
                              <option key={option} value={option}>
                                 {option}
                              </option>
                           ))}
                        </select>
                     </label>

                     <label>
                        <select value={date.getFullYear()} onChange={({ target }) => changeYear(Number(target.value))}>
                           {generateYears().map((option) => (
                              <option key={option} value={option}>
                                 {option}
                              </option>
                           ))}
                        </select>
                     </label>

                     <CalendarBtns justifyContent="center">
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                           <ArrowBack />
                        </button>
                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                           <ArrowNext />
                        </button>
                     </CalendarBtns>
                  </CalendarWrapper>
               )}
               selected={date}
               onChange={(date) => setDate(date)}
               timeInputLabel="Time:"
               dateFormat={disableTime ? "MM/dd/yyyy" : "MM/dd/yyyy h:mm aa"}
               showTimeInput={!disableTime}
               showPopperArrow={false}
               shouldCloseOnSelect={false}
               placeholderText="Select date"
               minDate={excludePastDays ? new Date() : null}
               todayButton="Today"
               disabled={Boolean(disable)}
            />
            {!date && <Icon style={{ backgroundImage: `url(${CalendarIcon})` }} />}
         </Calendar>
      </Wrapper>
   )
}
