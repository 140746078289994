import styled from "styled-components";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import textMessagingMobile from "../../assets/images/textMessagingMobile.svg";


const Container = styled(Flex)`
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
`

const TextWrapper = styled(Flex)`
    padding: 24px 32px 0;
    flex-direction: column;
    align-items: center;
    > span {
        text-align: center;
    }
`

const ImageWrapper = styled(Flex)`
    margin: 36px 12px;
`

export const TextMessagingMobile = () => {
    return (
        <Container>
            <TextWrapper>
                <Msg fontSize="15px" color="#202020">
                    For the best possible experience, please visit
                </Msg>
                <Msg color="#0F497B" fontSize="18px" fontWeight={700} marginTop="16px">
                    DockWorks on your laptopor desktop computer to setup DockWorks Messaging.
                </Msg>
            </TextWrapper>
            <ImageWrapper>
                <img src={textMessagingMobile} />
            </ImageWrapper>
        </Container>
    );
}