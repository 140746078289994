import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  createRef,
} from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation, useParams } from "react-router-dom";
import { Api } from "../../api/api";
import styled from "styled-components";
import { Button, Divider, Dropdown, Spin, Collapse } from "antd";
import { getErrors, showErrors } from "../../utils/utils";
import { sortByDate } from "../../utils/utils";
import { PaddedContainer, Title } from "./styled";
import { Messages } from "../../components/Messages/Messages";
import { Details } from "./Details";
import { Activities } from "../jobs/content/Activities";
import { EstimateApprovedModal } from "./EstimateApprovedModal";
import { TInputChangeHandler, TWithId } from "../../types";
import {
  IActivity,
  IExtendedActivity,
  IExternalEstimate,
  IMessage,
  IShortJob,
  IWSMessage,
} from "../../api/types";
import { useWebSocket } from "../../utils/hooks";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import DEFAULT_LOGO from "../../assets/images/gear_logo.png";
import moment from "moment";
import { track } from "../../utils/analytics";
import PrintWorkOrder from "./PrintWorkOrder";
import { background } from "styled-system";
import { SideBarActivities } from "../jobs/content/SideBarActivities";
import { SideBarMessages } from "../../components/Messages/SideBarMessages";

interface IContainerProps {
  showSideBlock: boolean;
}
interface ISideBarContainerProps {
  isFlex: boolean;
  isHide: boolean;
}

const Container = styled.div<IContainerProps>`
  background: #ebf1f6;
  padding: 72px 24px 24px;
  min-width: 0;
  min-height: 0;
  display: grid;
  grid-template-columns: ${(props) =>
    props.showSideBlock ? "3fr 1fr" : "1fr"};
  grid-gap: 24px;
  font-size: 14px;
  .ant-divider {
    margin: 0;
  }
  ${down("sm")} {
    grid-template-columns: 1fr;
  }
`;

const Plate = styled.div`
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  &:nth-child(2) {
    margin-top: 16px;
  }
`;
const ActivitiesContainer = styled(Plate)<ISideBarContainerProps>`
  flex: ${(props) => (props.isFlex ? "1 1 40%" : "none")};
  height: ${(props) => (props.isHide ? "54px" : "100%")};
  overflow: hidden;
  position: relative;
  ${down("sm")} {
    height: ${(props) => (props.isHide ? "54px" : "50vh")};
  } ;
`;
const MessagesContainer = styled(Plate)<ISideBarContainerProps>`
  flex: ${(props) => (props.isFlex ? "1 1 60%" : "none")};
  height: ${(props) => (props.isHide ? "54px" : "100%")};
  position: relative;
  ${down("sm")} {
    height: ${(props) => (props.isHide ? "54px" : "50vh")};
  } ;
`;

const StyledPlate = styled.div`
  display: flex;
  flex-flow: column;
  ${down("sm")} {
    display: block;
  } ;
`;

const StyledSpin = styled(Spin)`
  grid-column-start: 1;
  grid-column-end: 3;
`;

const MobileContainer = styled.div`
  background: #ebf1f6;
  padding: 40px 12px 12px;
  min-height: 100vh;
`;

const MobileContent = styled(Flex)`
  flex-direction: column;
  padding: 24px 16px 16px;
  border-radius: 8px;
  background-color: #ffffff;
`;

const MobileLogo = styled.div`
  width: 64px;
  height: 48px;
  > img {
    width: 100%;
  }
  margin-right: 16px;
`;

const MobileHeaderText = styled(Msg)`
  text-transform: uppercase;
  font-weight: 900;
`;

const MobileDropDownBlock = styled(Flex)`
  flex-direction: column;
  a {
    color: #202020;
  }
`;

const MobileBlock = styled(Flex)`
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  margin-top: 16px;
`;

const DropDownButton = styled(Button)`
  border: none;
  width: 16px;
  padding: 0px;
`;
const StyledCollapse = styled(Collapse)`
  & .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 0 !important;
  }
  & .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-collapse-item {
    .ant-collapse-arrow {
      position: inherit !important;
      top: inherit !important;
      right: inherit !important;
      margin-left: 10px !important;
      transform: rotate(90deg) !important;
    }
    &.ant-collapse-item-active {
      .ant-collapse-arrow {
        transform: rotate(180deg) !important;
      }
    }
  }
`;
type TDropdownContent = {
  showServices: boolean;
  showActivity: boolean;
  showMessages: boolean;
};

export const CustomerEstimateLayout: React.FC = () => {
  const { id } = useParams<TWithId>();
  const [estimate, setEstimate] = useState<IExternalEstimate | null>(null);
  const [approveVisible, setApproveVisible] = useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [jobs, setJobs] = useState<IShortJob[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [activeInfo, setActiveInfo] = useState<boolean>(false);
  const [isActivitiesHide, setIsActivitiesHide] = useState<boolean>(true);
  const [isMessagesHide, setIsMessagesHide] = useState<boolean>(true);
  const messageRef = useRef<any>(null);
  const { pathname } = useLocation();
  const isXs = useBreakpoint(down("xs"));
  const componentPrintRef = createRef<HTMLDivElement>();
  const [showDropdownContent, setShowDropdownContent] =
    useState<TDropdownContent>({
      showServices: false,
      showActivity: false,
      showMessages: false,
    });
  const isInvoice = useMemo(() => {
    return pathname.search("invoice") !== -1;
  }, [pathname]);

  const viewMessage = useCallback((m: IWSMessage) => {
    if (m.code === "paymentrecord") {
      fetchData();
    } else {
      setMessages((messages) => [m.message as IMessage, ...messages]);
    }
  }, []);

  useWebSocket(String(estimate?.work_order.uuid), "E", viewMessage);

  useEffect(() => {
    fetchData();
  }, [id, isInvoice]);

  const fetchData = () => {
    if (id) {
      setLoading(true);
      const route = isInvoice
        ? Api.jobs.invoiceDetails
        : Api.jobs.estimateDetails;
      route({ uuid: id })
        .then(({ data }) => {
          setEstimate(data);
          const colActivities: IExtendedActivity[] = [...data.activities];
          setActivities([...colActivities.sort(sortByDate("created", true))]);
          setMessages(data.messages);
          const { jobs } = data.work_order;
          setJobs([...jobs]);
          if (jobs.length) {
            setSelectedJobId(String(jobs[jobs.length - 1].id));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   messageRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [messages]);
  const handleToggleSideBarComponent = (info: string) => {
    info === "messages"
      ? setIsMessagesHide(!isMessagesHide)
      : setIsActivitiesHide(!isActivitiesHide);
  };

  const handleChangeMessage: TInputChangeHandler = ({ target: { value } }) => {
    setMessage(value);
  };
  const handleSend = () => {
    if (id) {
      Api.jobs
        .sendMessage({
          uuid: id,
          message,
          i: isInvoice,
          job: selectedJobId ?? "",
        })
        .then(({ data }) => {
          setMessage("");
          setMessages([data, ...messages]);
          track("Customer Sent a Message", {
            messageSentToCompany: estimate?.company.id,
            messageSentToUser: data.technician,
            messageSentBy: data.customer,
            messageTimestamp: moment().toISOString(),
          });
        })
        .catch((e) => {
          getErrors(e);
        });
    }
  };

  const handleApprove = () => {
    if (id) {
      setSaving(true);
      Api.jobs
        .approve(id)
        .then(({ data }) => {
          track("Work Order Estimate Approved", {
            workorderName: data.work_order.title,
            workorderId: data.work_order.id,
            customerId: data.work_order.customer.id,
            workorderApprovalTimestamp: moment().toISOString(),
            vesselId: data.work_order?.vessel.id,
            vesselCity: data.work_order?.current_address?.city,
            vesselState: data.work_order?.current_address?.state,
            estimatedDurationMins: data.work_order.estimated_duration,
            workorderTotal: data.work_order.total,
          });
          // setApproveVisible(true);
          setEstimate((e) => (e ? { ...e } : e));
        })
        .catch((e) => {
          showErrors(getErrors(e));
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const showSideBlock = () =>
    !!(
      estimate?.view_config?.display_activity_feed ||
      estimate?.view_config?.display_messages
    );

  const calculateItemsCount = () =>
    estimate?.work_order.jobs.reduce(
      (acc, j) => acc + j.services.length + j.materials.length,
      0
    );

  const handleServicesDropdownChange = () =>
    setShowDropdownContent({
      ...showDropdownContent,
      showServices: !showDropdownContent.showServices,
    });
  const handleActivityDropdownChange = () =>
    setShowDropdownContent({
      ...showDropdownContent,
      showActivity: !showDropdownContent.showActivity,
    });
  const handleMessagesDropdownChange = () =>
    setShowDropdownContent({
      ...showDropdownContent,
      showMessages: !showDropdownContent.showMessages,
    });

  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const mobileDropdown = (
    <MobileDropDownBlock>
      <MobileHeaderText fontSize="15px" marginBottom="10px">
        {estimate?.company.name}
      </MobileHeaderText>
      {estimate?.company.address && (
        <Msg fontSize="13px" fontWeight="700" lineHeight="20px">
          {Object.values({ ...estimate.company.address, lat: "", lng: "" })
            .filter((v) => v !== "")
            .join(", ")}
        </Msg>
      )}
      <Msg fontSize="13px" fontWeight="700" lineHeight="20px">
        {estimate?.company.email}
      </Msg>
      {estimate?.company.phone && (
        <a href={`tel:${estimate.company.phone}`}>{estimate.company.phone}</a>
      )}
    </MobileDropDownBlock>
  );

  return (
    <>
      {isXs ? (
        estimate?.company ? (
          <MobileContainer>
            <MobileContent>
              <Flex marginBottom="40px">
                <MobileLogo>
                  <img
                    src={estimate?.company?.logo ?? DEFAULT_LOGO}
                    alt="Logo"
                  />
                </MobileLogo>
                <Flex flexDirection="column">
                  <StyledCollapse
                    ghost
                    expandIconPosition="right"
                    onChange={() => setActiveInfo((prev) => !prev)}
                  >
                    <Collapse.Panel header={estimate.company.name} key="1">
                      <MobileDropDownBlock>
                        <Msg
                          fontSize="15px"
                          fontWeight="700"
                          color="#0496FF"
                          style={{ textDecoration: "underline" }}
                        >
                          {estimate.company.email}
                        </Msg>
                        {estimate?.company.address && (
                          <Msg fontSize="15px" lineHeight="20px">
                            {Object.values({
                              ...estimate.company.address,
                              lat: "",
                              lng: "",
                            })
                              .filter((v) => v !== "")
                              .join(", ")}
                          </Msg>
                        )}

                        {estimate?.company.phone && (
                          <a href={`tel:${estimate.company.phone}`}>
                            {estimate.company.phone}
                          </a>
                        )}
                      </MobileDropDownBlock>
                    </Collapse.Panel>
                  </StyledCollapse>
                  {!activeInfo && (
                    <Msg
                      fontSize="15px"
                      fontWeight="700"
                      color="#0496FF"
                      style={{ textDecoration: "underline" }}
                    >
                      {estimate.company.email}
                    </Msg>
                  )}
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                <MobileHeaderText fontSize="18px" marginBottom="10px">
                  #{estimate.work_order.order_number} (
                  {estimate.work_order.title})
                </MobileHeaderText>
                <Msg fontSize="15px">
                  <b>Send: </b>
                  {moment(estimate.created).format("LLLL")}
                </Msg>
                <Msg fontSize="15px">
                  <b>Service writer: </b>
                  {estimate.service_writer?.full_name}
                </Msg>
              </Flex>
              <Flex flexDirection="column">
                <MobileBlock>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <MobileHeaderText fontSize="18px">
                      Services
                    </MobileHeaderText>
                    <DropDownButton
                      onClick={handleServicesDropdownChange}
                      icon={
                        showDropdownContent.showServices ? (
                          <UpOutlined />
                        ) : (
                          <DownOutlined />
                        )
                      }
                    />
                  </Flex>
                  {!showDropdownContent.showServices ? (
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Msg fontSize="15px">
                        Grand total ({calculateItemsCount()} items)
                      </Msg>
                      <Msg fontSize="15px">
                        ${estimate.work_order.total.toFixed(2)}
                      </Msg>
                    </Flex>
                  ) : (
                    <Details
                      isInvoice={isInvoice}
                      estimate={estimate}
                      saving={saving}
                      onApprove={handleApprove}
                      messageRef={messageRef}
                      handlePrint={handlePrint}
                    />
                  )}
                </MobileBlock>
                {estimate?.view_config?.display_activity_feed && (
                  <MobileBlock>
                    <Flex flexDirection="row" justifyContent="space-between">
                      <MobileHeaderText fontSize="18px">
                        Activity
                      </MobileHeaderText>
                      <DropDownButton
                        onClick={handleActivityDropdownChange}
                        icon={
                          showDropdownContent.showActivity ? (
                            <UpOutlined />
                          ) : (
                            <DownOutlined />
                          )
                        }
                      />
                    </Flex>
                    {showDropdownContent.showActivity && (
                      <Activities activities={activities} />
                    )}
                  </MobileBlock>
                )}
                {estimate?.view_config?.display_messages && (
                  <MobileBlock>
                    <Flex flexDirection="row" justifyContent="space-between">
                      <MobileHeaderText fontSize="18px">
                        Messages{" "}
                      </MobileHeaderText>
                      <DropDownButton
                        onClick={handleMessagesDropdownChange}
                        icon={
                          showDropdownContent.showMessages ? (
                            <UpOutlined />
                          ) : (
                            <DownOutlined />
                          )
                        }
                      />
                    </Flex>
                    {showDropdownContent.showMessages && (
                      <Messages
                        estimate={estimate}
                        ref={messageRef}
                        jobs={jobs}
                        onSelectJob={(jobId) => setSelectedJobId(jobId)}
                        selectedJob={selectedJobId}
                        messages={messages}
                        message={message}
                        onSend={handleSend}
                        onChangeMessage={handleChangeMessage}
                        handlePrint={handlePrint}
                      />
                    )}
                  </MobileBlock>
                )}
                <Details
                  isInvoice={isInvoice}
                  estimate={estimate}
                  saving={saving}
                  onApprove={handleApprove}
                  messageRef={messageRef}
                  isMobileActions
                  openMessages={() =>
                    setShowDropdownContent({
                      ...showDropdownContent,
                      showMessages: true,
                    })
                  }
                  handlePrint={handlePrint}
                />
              </Flex>
            </MobileContent>
          </MobileContainer>
        ) : null
      ) : (
        <Container showSideBlock={showSideBlock()}>
          {loading ? (
            <StyledSpin />
          ) : !estimate ? (
            <p>No estimate</p>
          ) : (
            <>
              <Plate>
                <Details
                  isInvoice={isInvoice}
                  estimate={estimate}
                  saving={saving}
                  onApprove={handleApprove}
                  messageRef={messageRef}
                  handlePrint={handlePrint}
                />
              </Plate>
              {showSideBlock() && (
                <StyledPlate>
                  {estimate?.view_config?.display_activity_feed && (
                    <ActivitiesContainer
                      isHide={isActivitiesHide}
                      isFlex={
                        estimate?.view_config?.display_messages &&
                        !isActivitiesHide
                      }
                    >
                      <Flex justifyContent={"space-between"}>
                        <PaddedContainer>
                          <Title>JOB Activity</Title>
                          {!isActivitiesHide && (
                            <Activities activities={activities} />
                          )}
                        </PaddedContainer>
                        <PaddedContainer>
                          <Msg
                            color={"#0496FF"}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              handleToggleSideBarComponent("Activities");
                            }}
                          >
                            Hide
                          </Msg>
                        </PaddedContainer>
                      </Flex>
                    </ActivitiesContainer>
                  )}
                  {estimate?.view_config?.display_messages && (
                    <MessagesContainer
                      isHide={isMessagesHide}
                      isFlex={
                        estimate?.view_config?.display_activity_feed &&
                        !isMessagesHide
                      }
                    >
                      <Messages
                        estimate={estimate}
                        ref={messageRef}
                        jobs={jobs}
                        onSelectJob={(jobId) => setSelectedJobId(jobId)}
                        selectedJob={selectedJobId}
                        messages={messages}
                        message={message}
                        handleToggleSideBarComponent={
                          handleToggleSideBarComponent
                        }
                        isHide={!isMessagesHide}
                        onSend={handleSend}
                        onChangeMessage={handleChangeMessage}
                      />
                    </MessagesContainer>
                  )}
                </StyledPlate>
              )}
              <EstimateApprovedModal
                visible={approveVisible}
                onCancel={() => setApproveVisible(false)}
                onOk={() => setApproveVisible(false)}
              />
            </>
          )}
        </Container>
      )}
      <div style={{ display: "none" }}>
        <PrintWorkOrder
          ref={componentPrintRef}
          workOrder={estimate?.work_order}
          jobs={estimate?.work_order?.jobs}
          userData={estimate}
          technicianRole={false}
          woPricing={true}
          woPhotos={true}
          vesselPhoto={true}
          isInvoice={isInvoice}
        />
      </div>
    </>
  );
};
