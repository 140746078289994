import { ChangeEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DefaultPageSize } from "../constants"
import { IListRequest, IPaginatedResponse, IWSMessage } from "../api/types"
import { SorterResult } from "antd/lib/table/interface"
import { TablePaginationConfig } from "antd/es"
import { AxiosPromise } from "axios"
import { getErrors, getOrdering, showErrors } from "./utils"
import { setTimeout } from "timers"
import { useLocation } from "react-router-dom"
import { TArgCallback } from "../types"
import { WSUrl } from "../api"
import { authService } from "./authService"
import { getCurrentUser } from "../config/reducers/user/selectors"
import { useSelector } from "react-redux"
import { EUserRole } from "../api/types"
type onChangeType = (e: ChangeEvent<HTMLInputElement>) => void
export const usePagination = <T = any>() => {
   const [pagination, setPagination] = useState<TablePaginationConfig>({
      current: 1,
      pageSize: DefaultPageSize,
   })
   const [filters, setFilters] = useState({})
   const [sorter, setSorter] = useState<SorterResult<T> | SorterResult<T>[]>({})
   const [loading, setLoading] = useState<boolean>(false)
   const [data, setData] = useState<T[]>([])
   const { current, pageSize } = pagination

   const requestPagination: IListRequest = useMemo(() => {
      return {
         page: current ?? 1,
         pageSize: pageSize ?? DefaultPageSize,
         ...getOrdering(sorter),
      }
   }, [current, pageSize, sorter])

   const onChange = useCallback(
      (pagination: TablePaginationConfig, filters, sorter: SorterResult<T> | SorterResult<T>[]) => {
         setPagination(pagination)
         setFilters(filters)
         setSorter(sorter)
      },
      []
   )

   const onPromise = useCallback((promise: AxiosPromise<IPaginatedResponse<T[]> | T[]>) => {
      setLoading(true)
      promise
         .then(({ data }) => {
            const { results, count } = data as IPaginatedResponse<T[]>
            setData(results)
            setPagination((p) => ({ ...p, total: count }))
         })
         .catch((e) => {
            showErrors(getErrors(e))
         })
         .finally(() => {
            setLoading(false)
         })
   }, [])

   const onLoading = useCallback((l: boolean) => {
      setLoading(l)
   }, [])

   return {
      pagination,
      requestPagination,
      filters,
      sorter,
      loading,
      data,
      onChange,
      onPromise,
      onLoading,
   }
}

export const useLoading = (): [boolean, (cb: Promise<any>) => Promise<any>] => {
   const [loading, setLoading] = useState<boolean>(false)

   const onCall = useCallback(<T>(cb: Promise<T>): Promise<T> => {
      setLoading(true)
      return cb.finally(() => {
         setLoading(false)
      })
   }, [])
   return [loading, onCall]
}

export const usePrevious = (value: any) => {
   const ref = useRef(value)
   useEffect(() => {
      ref.current = value
   }, [value])
   return ref.current
}

type TTimeout = ReturnType<typeof setTimeout>
export const useDebounce = (orgSearch: string, debounce = 1000) => {
   const [dbSearch, setSearch] = useState<string>(orgSearch)
   const to_ = useRef<TTimeout>()

   useEffect(() => {
      to_.current = setTimeout(() => {
         setSearch(orgSearch)
      }, debounce)
      return () => {
         if (to_.current) {
            clearTimeout(to_.current)
         }
      }
   }, [orgSearch, debounce])

   return { dbSearch }
}

type ResponsiveType = {
   width: number
   height: number
}

export const useResponsive = (): ResponsiveType => {
   const [windowSize, setWindowSize] = useState<ResponsiveType>({
      width: window.innerWidth,
      height: window.innerHeight,
   })

   useEffect(() => {
      window.addEventListener("resize", () => {
         setWindowSize({ width: window.innerWidth, height: window.innerHeight })
      })
      return () => {
         window.removeEventListener("resize", () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight })
         })
      }
   }, [])

   return windowSize
}

export const useQuery = () => {
   return new URLSearchParams(useLocation().search)
}

type TSocketType = "E" | "I" | "J" | "W" | "U" | "DI"
export const useWebSocket = <T = any>(id: string, t: TSocketType, onMessage: TArgCallback<IWSMessage<T>>) => {
   const ws = useRef<WebSocket | null>(null)
   const user = useSelector(getCurrentUser)
   useEffect(() => {
      switch (t) {
         case "U":
            if (user) {
               ws.current = new WebSocket(`${WSUrl}/users/${user.id}/?token=${authService.getLocalToken()}`)
            }
            break
         case "J":
            ws.current = new WebSocket(`${WSUrl}/jobs/${id}/?token=${authService.getLocalToken()}`)
            break
         case "W":
            if (id !== "0") {
               ws.current = new WebSocket(`${WSUrl}/wo/${id}/?token=${authService.getLocalToken()}`)
            }
            break
         case "DI":
            if (id !== "undefined") {
               ws.current = new WebSocket(`${WSUrl}/import/${id}/?token=${authService.getLocalToken()}`)
            }
            break
         case "E":
         case "I":
         default: {
            if (id !== "undefined") {
               ws.current = new WebSocket(`${WSUrl}/woe/${id.replaceAll("-", "")}/`)
            }
            break
         }
      }
      if (ws.current) {
         ws.current.onmessage = (ev) => {
            const data = JSON.parse(ev.data) as IWSMessage<T>
            onMessage(data)
         }
      }
      return () => {
         ws.current?.close()
      }
   }, [id, t, onMessage, user])
   return ws
}

export const useTechnicianRole = () => {
   const userData = useSelector(getCurrentUser)

   if (userData) {
      const technicianRole = userData?.role_id !== EUserRole.Technician ? false : true
      return technicianRole
   }
   return null
}

export const useSubscriptionStatus = () => {
   const profile = useSelector(getCurrentUser)
   const status = profile?.company?.stripe_subscription
   return status ?? null
}

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: TArgCallback<Event>) => {
   useEffect(() => {
      const handleClickOutside = (event: Event) => {
         if (ref.current && !ref.current.contains(event.target as Node)) {
            callback(event)
         }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
         document.removeEventListener("mousedown", handleClickOutside)
      }
   }, [ref, callback])
}
