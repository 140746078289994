import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import Settings from "./Settings"
import { Form, notification, Modal, Button } from "antd"
import Flex from "../../noui/Flex"
import { Api } from "../../api/api"
import { getErrors, setDefaultFields } from "../../utils/utils"
import { InvoiceForm } from "./InvoiceForm"
import { MainInfoForm } from "./MainInfoForm"
import { FootersForm } from "./FootersForm.tsx"
import { VesselTypeForm } from "./VesselTypeForm"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import axios from "axios"
import { useAppDispatch } from "../../config/hooks"
import { setCurrentUser } from "../../config/reducers/user/actions"
import ServiceTypes from "./ServiceTypes"
import { GoogleMapsAPIKey } from "../../api"
import { CustomFooters } from "./CustomFooters"
import { ReplyForm } from "./ReplyForm"
import { EmailTemplatesForm } from "./EmailTempatesForm"
import { ModalInner, StyledModal } from "./styled"

const GeneralSettings = () => {
   const [saving, setSaving] = useState(false)
   const [isImageUploading, setIsImageUploading] = useState(false)
   const [showModal, setShowModal] = useState(false)
   const [form] = Form.useForm()
   const [invoiceForm] = Form.useForm()
   const [contentForm] = Form.useForm()
   const [vesselForm] = Form.useForm()
   const [serviceForm] = Form.useForm()
   const [logo, setLogo] = useState(null)
   const profile = useSelector(getCurrentUser)
   const dispatch = useAppDispatch()

   const loadData = () => {
      Api.company.profile().then(({ data: { logo, invoice_settings, address, ...data } }) => {
         setDefaultFields(form, {...data, ...address})
         setDefaultFields(vesselForm, data)
         setLogo(logo)
         if (invoice_settings) {
            const { invoice_footer, estimate_footer, payment_status_wo, payment_status_sp, show_timestamp, ...rest } =
               invoice_settings
            setDefaultFields(invoiceForm, rest)
            setDefaultFields(contentForm, {
               invoice_footer,
               estimate_footer,
               payment_status_wo,
               payment_status_sp,
               show_timestamp,
            })
         } else {
            invoiceForm.resetFields()
            contentForm.resetFields()
         }
      })
   }

   useEffect(() => {
      loadData()
   }, [form, invoiceForm, vesselForm, contentForm])

   const uploadImage = async (options) => {
      const { onSuccess, onError, file, onProgress } = options
      try {
         setIsImageUploading(true)
         if (!file) {
            throw new Error()
         }
         if (["image/jpeg", "image/png"].indexOf(file.type) === -1) {
            notification.error({
               message: "You can upload only image file. Supported image formats: jpeg, png.",
            })
            throw new Error()
         } else if (file.size > 1048576) {
            notification.error({
               message: "File size must be less than 1MB.",
            })
            throw new Error()
         }
         const baseFile = await getBase64(file)
         await Api.company.patch({ id: profile.company.id, logo: baseFile })
         setLogo(baseFile)
         onSuccess("Ok")
      } catch (err) {
         onError(err)
      } finally {
         setIsImageUploading(false)
      }
   }

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => {
            const img = new Image()
            img.src = reader.result
            img.onload = () => {
               if (img.width > 2000) {
                  notification.error({ message: "Image width should be less than 2000px" })
                  reject()
                  return
               } else if (img.height > 2000) {
                  notification.error({ message: "Image height should be less than 2000px" })
                  reject()
                  return
               }
               resolve(reader.result)
            }
         }
         reader.onerror = (error) => reject(error)
      })
   }

   const handleRemoveLogo = () => {
      Api.company.patch({ id: form.getFieldValue("id"), logo: null }).then(() => {
         setLogo(null)
         form.setFields([{ name: "logo", touched: false, value: null }])
      })
   }

   const handleSubmitMain = async (updateFooter) => {
      const data = await form.validateFields()
      if (data.logo?.file) {
         data.logo = logo
      } else {
         delete data.logo
      }
      setSaving(true)
      try {
         const _data = await form.getFieldsValue(true)
         const res = {...data, address: {
            lat: _data.lat,
            lng: _data.lng,
            city: _data.city,
            state: _data.state,
            address1: _data.address1,
            address2: _data.address2,
            full_address: _data.full_address,
            zip_code: _data.zip_code
         }}
         const { data: resp } = await Api.company.patch({ ...res, update_footer: updateFooter ? true : false })
         setDefaultFields(form, resp)
         await Api.user
            .profile()
            .then(({ data }) => {
               dispatch(setCurrentUser(data))
            })
            .catch(() => {})
         form.setFields(form.getFieldsError().map((field) => ({ ...field, errors: [] })))
         if (!!updateFooter) {
            await loadData()
         }
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors.map(e => !e.name.includes('address') ? e : {...e, name: e.name.filter(n => n !== 'address')}))
      } finally {
         setSaving(false)
      }
   }

   const handleSubmitServiceCategories = async () => {
      setSaving(true)
      try {
         const { service_categories } = await serviceForm.validateFields()
         const data = { id: profile.company.id, service_categories }
         const { data: resp } = await Api.company.updateServiceCategories(data)
         const { data: user } = await Api.user.profile()
         dispatch(setCurrentUser(user))
         setDefaultFields(form, resp)
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors)
      } finally {
         setSaving(false)
      }
   }

   const handleSubmitVesselTypes = async () => {
      const data = await vesselForm.validateFields()
      setSaving(true)
      try {
         const { data: resp } = await Api.company.patch({ id: profile.company.id, ...data })
         setDefaultFields(form, resp)
      } catch (e) {
         const errors = getErrors(e)
         form.setFields(errors)
      } finally {
         setSaving(false)
      }
   }

   const clearErrors = () => {
      const fields = invoiceForm.getFieldsError()
      const fieldNames = []
      fields.filter((f) => f.errors.length).forEach((f) => fieldNames.push(f.name[0]))
      invoiceForm.setFields(fieldNames.map((f) => ({ name: f, errors: [] })))
   }

   const handleSubmitInvoice = async () => {
      const { tax, epa, epa_includes, epa_amount, epa_amount_includes, tax_includes } =
         await invoiceForm.validateFields()
      const data = { tax, epa, epa_includes, epa_amount, epa_amount_includes, tax_includes }
      setSaving(true)
      clearErrors()
      try {
         const {
            data: { tax, epa, epa_includes, epa_amount, epa_amount_includes, tax_includes },
         } = await Api.company.setInvoiceSettings(data)
         setDefaultFields(invoiceForm, { tax, epa, epa_includes, epa_amount, epa_amount_includes, tax_includes })
      } catch (e) {
         invoiceForm.setFields(getErrors(e))
      } finally {
         setSaving(false)
      }
   }

   const handleSubmitFooters = async () => {
      const data = await contentForm.validateFields()
      console.log(data)
      setSaving(true)
      try {
         const {
            data: { invoice_footer, estimate_footer, payment_status_wo, payment_status_sp },
         } = await Api.company.setInvoiceSettings(data)
         setDefaultFields(invoiceForm, { invoice_footer, estimate_footer })
      } catch (e) {
         contentForm.setFields(getErrors(e))
      } finally {
         setSaving(false)
      }
   }

   const handleUpdateEmails = async () => {
      await handleSubmitMain(true)
      setShowModal(false)
   }
   const handleSaveMain = () => {
      handleSubmitMain()
      setShowModal(false)
   }

   return (
      <>
         <Helmet>
            <title>General Settings - DockWorks Pro</title>
         </Helmet>

         <Settings>
            <MainInfoForm
               form={form}
               onRemoveLogo={handleRemoveLogo}
               logo={logo}
               saving={saving}
               isImageUploading={isImageUploading}
               uploadImage={uploadImage}
               showModal={setShowModal}
            />
            <ServiceTypes form={serviceForm} onFinish={handleSubmitServiceCategories} saving={saving} />
            <VesselTypeForm form={vesselForm} onFinish={handleSubmitVesselTypes} saving={saving} />
            <InvoiceForm form={invoiceForm} onFinish={handleSubmitInvoice} saving={saving} />
            <FootersForm form={contentForm} onFinish={handleSubmitFooters} saving={saving} />
            <CustomFooters profile={profile} />
            <ReplyForm />
            <EmailTemplatesForm />
            <StyledModal visible={showModal} width={400} footer={null} closable={false}>
               <ModalInner>
                  <div className="modal_header">
                     <h3>Do you want to update the Company address for Customer Branded Emails?</h3>
                  </div>
                  <div className="modal_footer">
                     <Flex alignItems="center" justifyContent="flex-end">
                        <Button onClick={handleSaveMain}>No</Button>
                        <Button type="primary" onClick={handleUpdateEmails}>
                           Yes
                        </Button>
                     </Flex>
                  </div>
               </ModalInner>
            </StyledModal>
         </Settings>
      </>
   )
}

export default GeneralSettings
