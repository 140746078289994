import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import routes from "../../routes/routes"
import { Table } from "./styled"

type TProps = {
   data?: any
   calendar?: any
   dayTotal?: any
}

const ListWeekMonth: React.FC<TProps> = ({ data, calendar, dayTotal }) => {
   const history = useHistory()
   const memoizedData = useMemo(() => data, [data])
   const memoizedCalendar = useMemo(() => calendar, [calendar])
   return (
      <Table>
         <tbody>
            <tr>
               <th className="job">Job#</th>
               <th className="job">Job Name</th>
               {memoizedCalendar &&
                  memoizedCalendar.length > 0 &&
                  memoizedCalendar.map((item: { subtitle: number; title: string; date: any }) => {
                     return (
                        <th key={item.date}>
                           <span className="weekDay">{item.title}</span>
                           <span className="day">{item.subtitle}</span>
                        </th>
                     )
                  })}
               <th className="fixed">Total</th>
            </tr>
            {memoizedData &&
               memoizedData.length > 0 &&
               memoizedData.map((job: { job: any; dates: any[]; total: any }) => {
                  return (
                     <tr key={job.job.id}>
                        <td
                           onClick={() =>
                              history.push(
                                 routes.workOrder.UpdateWorkOrder.replace(":id", `${job.job.work_order}`) +
                                    `?job=${job.job.id}`
                              )
                           }
                           className="job-link"
                        >
                           {job.job.order_number}
                        </td>
                        <td
                           onClick={() =>
                              history.push(
                                 routes.workOrder.UpdateWorkOrder.replace(":id", `${job.job.work_order}`) +
                                    `?job=${job.job.id}`
                              )
                           }
                           className="job-link"
                        >
                           {job.job.title}
                        </td>
                        {memoizedCalendar &&
                           memoizedCalendar.length > 0 &&
                           memoizedCalendar.map((item: { date: any; subtitle: any }) => {
                              const findJob = job.dates.find((i) => i.date === item.subtitle)
                              if (findJob) {
                                 return (
                                    <td key={item.date} style={{ textAlign: "center" }}>
                                       {findJob.duration && `${findJob.duration}h`}
                                    </td>
                                 )
                              }
                              return <td key={item.date}></td>
                           })}
                        <td className="fixed">{job.total}h</td>
                     </tr>
                  )
               })}
            <tr>
               <td className="job">Total</td>
               <td></td>
               {dayTotal && (
                  <>
                     {dayTotal.total.map((item: any) => {
                        return (
                           <td key={`${item.date}`} style={{ textAlign: "center" }}>
                              {item.duration && `${item.duration}h`}
                           </td>
                        )
                     })}

                     <td className="fixed total">{dayTotal.totalDuration}h</td>
                  </>
               )}
            </tr>
         </tbody>
      </Table>
   )
}
export default ListWeekMonth
