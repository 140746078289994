import {ITag, IUserProfile} from "../../api/types";
import routes from "../../routes/routes";

export const getNotificationTagUrl = (tag: ITag, tags: ITag[], user: IUserProfile | null) => {
    switch (tag.content_type) {
      case "message": {
        const wo_tag = tags.find(t => t.content_type === 'work order');
        const job_tag = tags.find(t => t.content_type === 'job');
        if (wo_tag && job_tag) {
          return routes.workOrder.UpdateWorkOrder.replace(":id", String(wo_tag.object_id)) + `?job=${job_tag.object_id}&tab=5`
        }
        if (wo_tag) {
          return routes.workOrder.UpdateWorkOrder.replace(":id", String(wo_tag.object_id) + "?message=true")
        }
        return "#"
      }
      case "job":
        const wo_tag = tags.find(t => t.content_type === 'work order');
        if (wo_tag) {
          return routes.workOrder.UpdateWorkOrder.replace(
            ":id", String(wo_tag.object_id)) + `?job=${tag.object_id}`;
        }
        return "#";
      case "work order":
        return routes.workOrder.UpdateWorkOrder.replace(":id", String(tag.object_id))
      case "user": {
        if (user?.role_id === 4) return "#";
        return routes.settings.Users;
      }
      case "customer": {
        if (user?.role_id === 4) return "#";
        return routes.customers.CustomerPage.replace(":id", String(tag.object_id));
      }
      case "brand": {
        return routes.settings.TextMessaging;
      }
      default:
        return "#";
    }
  }