import React from "react"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { ReactComponent as SmallArrowUp } from "../../../assets/images/SmallArrowUp.svg"
import { ReactComponent as SmallArrowDown } from "../../../assets/images/SmallArrowDown.svg"
import { IStats } from "../../../api/types"
import { formatToUSD } from "../../../utils/utils"

const ArrowImage = styled.div<{ isBetter: boolean }>`
   width: 10px;
   display: flex;
   align-items: center;
   height: 5px;
   color: ${({ isBetter }) => (isBetter ? "#42C57A" : "#FB4D4F")};
`
type TProps = {
   statsData: IStats | null
}
const PaidInvoices: React.FC<TProps> = ({ statsData }) => {
   const { invoices_paid, invoices_paid_previously } = statsData || { invoices_paid: 0, invoices_paid_previously: 0 }
   const isBetter = invoices_paid >= invoices_paid_previously
   const percentage = invoices_paid_previously
      ? ((invoices_paid - invoices_paid_previously) / invoices_paid_previously) * 100
      : null

   return (
      <Flex className="little-cart" width="195px" p="10.17px" flexDirection="column">
         <Msg fontWeight={400} fontSize={13} color="#494949" mb="30px">
            Paid invoices
         </Msg>
         <Flex justifyContent="center" alignItems="center" mb="8px">
            <Msg fontWeight={400} fontSize={13} color="#494949" mr="5px">
               {isBetter ? "+" : ""}
               {percentage?.toFixed(0) ?? "*"}%
            </Msg>
            <ArrowImage isBetter={isBetter}>{isBetter ? <SmallArrowUp /> : <SmallArrowDown />}</ArrowImage>
         </Flex>
         <Flex flexDirection="column" width="100%" alignItems="center">
            <Msg fontWeight={800} fontSize={28} color="#151F2B" mb="10px">
               {formatToUSD(invoices_paid)}
            </Msg>
            <Msg fontWeight={400} fontSize={13} color={isBetter ? "#42C57A" : "#FB4D4F"}>
               {isBetter ? "better" : "worth"} than previous
            </Msg>
         </Flex>
      </Flex>
   )
}

export default PaidInvoices
