import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Api, ERequestPriority } from "../../api/api"
import { formatToUSD, getAddress, renderAddress } from "../../utils/utils"
import { servicesUnitTypes, vesselTypes } from "../../constants"
import moment from "moment"
import { Button, Modal, notification } from "antd"
import { PrimaryButton, EmptyButton, HeaderButton } from "../../ui/Button"
import DEFAULT_LOGO from "../../assets/images/gear_logo.png"
import { IExternalEstimate, IJobMaterial, IJobService } from "../../api/types"
import { TCallback } from "../../types"
import PaymentModal from "./PaymentModal"
import Photos from "./Photos"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { DownloadIcon } from "../../assets/icons"
import RequestChangesModal from "./RequestChangesModal"
import { DownOutlined, UpOutlined } from "@ant-design/icons"

import {
   ApproveRow,
   DetailsContainer,
   ActionButton,
   Header,
   Info,
   HeaderInfo,
   WOInfo,
   PaymentBlock,
   WOTitle,
   DetailsDiv,
   Customer,
   CustomerInfo,
   JobTitle,
   JobInfo,
   PaymentStatus,
   VesselHeader,
   VesselBottom,
   VesselImg,
   TableDiv,
   TableItem,
   Services,
   Materials,
   Summary,
   DropDownButton,
   MobileItem,
   Technician,
   TransactionInfo,
} from "./styled"
import parse from "html-react-parser";

type TProps = {
   estimate: IExternalEstimate
   saving: boolean
   onApprove: TCallback
   isInvoice: boolean
   messageRef: any
   isMobileActions?: boolean
   openMessages?: () => void
   handlePrint?: TCallback
}
export const Details: React.FC<TProps> = ({
   estimate,
   saving,
   onApprove,
   isInvoice,
   messageRef,
   isMobileActions,
   openMessages,
   handlePrint,
}) => {
   const { company } = estimate
   const [visible, setVisible] = useState<boolean>(false)
   const [modalRequestChanges, setRequestChanges] = useState<boolean>(false)
   const [modalDeclineEstimate, setDeclineEstimate] = useState<boolean>(false)
   const [payed, setPayed] = useState<boolean>(false)
   const [isPending, setIsPending] = useState<boolean>(false)
   const [blockedBy3DS, setBlockedBy3DS] = useState(false)
   const [approveVisible, setApproveVisible] = useState<boolean>(false)
   const [isApproved, setIsApproved] = useState<boolean>(false)
   const [openedJobs, setOpenedJobs] = useState<number[]>([])
   const isXs = useBreakpoint(down("xs"))
   const invoiceFooter = estimate?.company?.invoice_settings?.invoice_footer as string
   const estimateFooter = estimate?.company?.invoice_settings?.estimate_footer as string
   const paymentStatusWO = estimate?.company?.invoice_settings?.payment_status_wo as string
   const paymentStatusSP = estimate?.company?.invoice_settings?.payment_status_sp as string
   console.log(paymentStatusWO)
   const getPayAmount = () => {
      if (isInvoice) {
         return +(estimate.amount_to_pay).toFixed(2)
      } else {
         if (estimate.work_order.deposited < estimate.work_order.required_deposit_amount) {
            return +(estimate.work_order.required_deposit_amount - estimate.work_order.deposited).toFixed(2)
         } else if (estimate.work_order.additional_payment_amount) {
            return +estimate.work_order.additional_payment_amount.toFixed(2)
         }
         return +(0).toFixed(2)
      }
   }

   const checkPayAbility = () => estimate?.view_config?.collect_payment && estimate?.company?.stripe?.charges_enabled

   const checkNotPayed = () =>
      Number(estimate.work_order.total.toFixed(2)) > Number(estimate.work_order.deposited.toFixed(2))

   const formatPrice = (price: number | undefined) =>
      price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

   const sendRequestChanges = async (message: string, priority: ERequestPriority) => {
      await Api.workOrders.requestChange({
         message,
         priority,
         uuid: estimate.work_order.uuid,
      });
   }

   const handleDeclineEstimate = async (message: string, priority: ERequestPriority) => {
      await Api.workOrders.declineEstimate({
         message,
         uuid: estimate.work_order.uuid
      });
   }

   const checkDepositUnpaid = () =>
      !isInvoice && !!estimate.work_order.required_deposit_amount && !estimate.work_order.deposit_paid_at

   const countRemaining = () => {
      if (isInvoice) {
         return estimate.work_order.total - estimate.work_order.deposited - estimate.amount_to_pay
      }
      if (checkDepositUnpaid()) {
         return estimate.work_order.total - estimate.work_order.required_deposit_amount
      }
      return (
         estimate.work_order.total -
         estimate.work_order.deposited -
         (estimate.work_order.additional_payment_amount ?? 0)
      )
   }

   const isJobOpened = (id: number) => openedJobs.includes(id)
   const toggleJobOpen = (id: number) => {
      if (isJobOpened(id)) {
         setOpenedJobs(openedJobs.filter((j) => j !== id))
         return
      }
      setOpenedJobs([...openedJobs, id])
   }

   const MobileMainBlock = () => (
      <>
         <WOTitle flexDirection="column">
            <h3>{estimate?.work_order?.title}</h3>
            <p>
               <span>Work order:</span> #{estimate?.work_order?.order_number}
            </p>
         </WOTitle>
         <Info justifyContent="normal">
            <div className="infoBlock">
               <Customer>
                  <CustomerInfo className="leftAligned">
                     <div>Service Writer: </div>
                     <div className="title">{estimate?.work_order?.created_by?.full_name}</div>
                  </CustomerInfo>
                  <CustomerInfo className="leftAligned">
                     <div>Created on: </div>
                     <div className="title">
                        {estimate?.work_order?.created
                           ? moment(estimate?.work_order?.created).format("ddd, M/D/YYYY h:mm A")
                           : "No data"}
                     </div>
                  </CustomerInfo>
               </Customer>
               <DetailsDiv>
                  <h4>Project Details</h4>
                  <div>{estimate?.work_order?.notes || "No notes provided"}</div>
               </DetailsDiv>
               <DetailsDiv>
                  <h4>SCOPE OF WORK</h4>
                  <div>
                     {estimate?.work_order?.jobs && estimate?.work_order?.jobs.length > 0 && (
                        <ul>
                           {estimate?.work_order?.jobs.map((job: any) => {
                              return (
                                 <li key={job?.id}>
                                    {job.title} <span>({job.order_number})</span>
                                 </li>
                              )
                           })}
                        </ul>
                     )}
                  </div>
                  <h5>(Full project summary listed below)</h5>
               </DetailsDiv>
               <Customer>
                  <CustomerInfo className="leftAligned">
                     <div>Due Date: </div>
                     <div className="title">
                        {moment(estimate?.work_order?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                     </div>
                  </CustomerInfo>
                  <CustomerInfo className="leftAligned">
                     <div>Est. Duration: </div>
                     <div className="title">{estimate?.work_order?.estimated_duration ?? "TBD"}</div>
                  </CustomerInfo>
               </Customer>
            </div>
            <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
               <VesselHeader>
                  <CustomerInfo>
                     <div>Vessel:</div>
                     <div style={{ fontSize: "15px", fontWeight: "bold" }}>{estimate?.work_order?.vessel?.name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Hull ID:</div>
                     <div className="title">{estimate?.work_order?.vessel?.hin}</div>
                  </CustomerInfo>
               </VesselHeader>
               <VesselBottom>
                  <CustomerInfo>
                     <div>Vessel Type:</div>
                     <div className="title">
                        {vesselTypes.find((v) => v.value === estimate?.work_order?.vessel?.vessel_type)?.label ||
                           "No data"}
                     </div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Location:</div>
                     <div className="title">{estimate?.work_order?.vessel?.location_name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Address:</div>
                     <div className="title">{getAddress(estimate?.work_order?.vessel?.address) || "-"}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Slip #:</div>
                     <div className="title">{estimate?.work_order?.vessel?.slip_number}</div>
                  </CustomerInfo>
                  {estimate?.work_order?.vessel?.image && (
                     <VesselImg>{<img src={estimate?.work_order?.vessel?.image} alt="vessel" />}</VesselImg>
                  )}
               </VesselBottom>
            </div>
         </Info>
         <PaymentStatus>
            <h3>PAYMENT STATUS</h3>
            <PaymentBlock>
               <div className="paymentBlock">
                  {checkIsReceipt() ? (
                     <TransactionInfo>
                        <h4>THANK YOU FOR YOUR PAYMENT!</h4>
                        <p style={{ color: "#42C77B", fontWeight: "bold" }}>
                           Amount Paid: ${estimate?.work_order?.payments_info.amount}
                        </p>
                        <p>Date: {moment(estimate?.work_order?.payments_info.date).format("l LT")}</p>
                        <p>
                           Transaction ID: #
                           {estimate?.work_order?.payments_info?.payment_intent?.intent_id
                              ? estimate?.work_order?.payments_info?.payment_intent?.intent_id.slice(3)
                              : "-"}
                        </p>
                        <p>
                           Payment{" "}
                           {estimate?.work_order?.payments_info?.payment_intent?.payment_details?.type === "card"
                              ? "Card"
                              : "Bank Account"}
                           : **** **** ****{" "}
                           {estimate?.work_order?.payments_info?.payment_intent?.payment_details?.type === "card"
                              ? estimate?.work_order?.payments_info?.payment_intent?.payment_details?.card.last4
                              : estimate?.work_order?.payments_info?.payment_intent?.payment_details?.us_bank_account
                                   .last4}
                        </p>
                     </TransactionInfo>
                  ) : estimate?.work_order?.is_recurring ? (
                     <p>{estimate?.company?.invoice_settings?.payment_status_sp}</p>
                  ) : (
                     <p>{estimate?.company?.invoice_settings?.payment_status_wo}</p>
                  )}
               </div>
               <div className="paymentBlock">
                  <Customer>
                     <CustomerInfo>
                        <div>Work Order Total:</div>
                        <div className="right">{formatToUSD(estimate?.work_order?.total)}</div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div style={{ fontWeight: "bold", color: "#42C77B" }}>Paid To-Date:</div>
                        <div className="right" style={{ fontWeight: "bold", color: "#42C77B" }}>
                           {formatToUSD(estimate?.work_order?.deposited)}
                        </div>
                     </CustomerInfo>
                     <div className="divider" />
                     <CustomerInfo>
                        <div style={{ color: "#FB4D4F" }}>Payment Due (Deposit):</div>
                        <div
                           className="right"
                           style={{
                              color: "#FB4D4F",
                              fontWeight: "bold",
                              fontSize: "15px",
                           }}
                        >
                           {formatToUSD(checkDepositUnpaid()
                              ? estimate.work_order.required_deposit_amount
                              : estimate.work_order.additional_payment_amount ?? 0
                           )}
                        </div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div style={{ color: "#8B8B8B" }}>Remaining after this payment: </div>
                        <div className="right" style={{ color: "#8B8B8B" }}>
                           {estimate?.work_order && formatToUSD(countRemaining())}
                        </div>
                     </CustomerInfo>
                  </Customer>
               </div>
            </PaymentBlock>
         </PaymentStatus>
         {estimate?.work_order.jobs &&
            estimate?.work_order.jobs.length > 0 &&
            estimate?.work_order.jobs.map((job: any) => {
               return (
                  <div key={job?.id}>
                     <div className="noBreak">
                        <JobTitle justifyContent="space-between">
                           <Flex flexDirection="column">
                              <h3>{job.title}</h3>
                              <p>(Job {job.order_number})</p>
                           </Flex>
                           <DropDownButton
                              onClick={() => toggleJobOpen(job.id)}
                              icon={isJobOpened(job.id) ? <UpOutlined /> : <DownOutlined />}
                           />
                        </JobTitle>
                        {isJobOpened(job.id) && (
                           <JobInfo alignItems="center" justifyContent="space-between">
                              <div>
                                 Start Date & Time:{" "}
                                 <span>{moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}</span>
                              </div>
                              <div>
                                 Estimated Duration: <span>{job?.estimated_duration ?? "TBD"}</span>
                              </div>
                           </JobInfo>
                        )}
                     </div>
                     {isJobOpened(job.id) && (
                        <>
                           <TableDiv>
                              {!!job.services &&
                                 !!job.services.length &&
                                 job.services.map((service: IJobService) => (
                                    <MobileItem key={service.id}>
                                       <div className="main">
                                          <div>
                                             <div>LABOR</div>
                                             <div>{service.name}</div>
                                          </div>
                                          <div>
                                             <div>Description</div>
                                             <div>{service.description}</div>
                                          </div>
                                          <div>
                                             <div>Qty</div>
                                             <div>{service.qty}</div>
                                          </div>
                                          <div>
                                             <div>Rate</div>
                                             <div>{formatToUSD(service.unit_price)} per job</div>
                                          </div>
                                          <div>
                                             <div>Total</div>
                                             <div>{formatToUSD(service.unit_price * service.qty)}</div>
                                          </div>
                                       </div>

                                       {job.technicians_data && job.technicians_data.length > 0 && (
                                          <div className="tech">
                                             <div>
                                                <div>Technician(s)</div>
                                                <div>Estimate Hours</div>
                                             </div>
                                             {job.technicians_data.map((tech: any) => (
                                                <div key={tech?.technician?.id}>
                                                   <div>{tech?.technician?.full_name}</div>
                                                   <div>{tech?.estimated_hours}</div>
                                                </div>
                                             ))}
                                          </div>
                                       )}
                                    </MobileItem>
                                 ))}
                           </TableDiv>
                           <TableDiv>
                              {!!job.materials &&
                                 !!job.materials.length &&
                                 job.materials.map((material: IJobMaterial) => (
                                    <MobileItem key={material.id}>
                                       <div className="main">
                                          <div>
                                             <div>MATERIALS</div>
                                             <div>{material.name}</div>
                                          </div>

                                          <div>
                                             <div>Description</div>
                                             <div>{material.description}</div>
                                          </div>
                                          <div>
                                             <div>Qty</div>
                                             <div>{material.qty}</div>
                                          </div>
                                          <div>
                                             <div>Rate</div>
                                             <div>{formatToUSD(material.unit_price)} per qty</div>
                                          </div>
                                          <div>
                                             <div>Total</div>
                                             <div>{formatToUSD(material.unit_price * material.qty)}</div>
                                          </div>
                                       </div>
                                    </MobileItem>
                                 ))}
                           </TableDiv>
                           <div className="noBreak">
                              <Summary>
                                 <div className="disclaimers">
                                    <h3>DISCLAIMERS</h3>
                                    {isInvoice ? (
                                       <p>{parse(invoiceFooter)}</p>
                                    ) : (
                                       <p>{parse(estimateFooter)}</p>
                                    )}
                                 </div>
                                 <div className="summary">
                                    <Flex justifyContent="space-between">
                                       <div>Labor</div>
                                       <div>{formatToUSD(job.total_labor)}</div>
                                    </Flex>
                                    <Flex justifyContent="space-between">
                                       <div>Materials</div>
                                       <div>{formatToUSD(job.total_materials)}</div>
                                    </Flex>
                                    <Flex justifyContent="space-between">
                                       <div>Subtotal</div>
                                       <div>
                                          {formatToUSD(job !== null ? job.total_labor + job.total_materials : 0)}
                                       </div>
                                    </Flex>
                                    <Flex justifyContent="space-between">
                                       <div>Taxes</div>
                                       <div>{formatToUSD(job !== null ? job.tax : 0)}</div>
                                    </Flex>
                                    <Flex justifyContent="space-between">
                                       <div>EPA fees</div>
                                       <div>{formatToUSD(job !== null ? job.epa : 0)}</div>
                                    </Flex>
                                    <Flex className="total" justifyContent="space-between">
                                       <div>Job total</div>
                                       <div>
                                          {formatToUSD(
                                             job !== null
                                                ? job.total_labor + job.total_materials + job.tax + job.epa
                                                : 0
                                          )}
                                       </div>
                                    </Flex>
                                 </div>
                              </Summary>
                           </div>
                           <Photos job={job} />
                        </>
                     )}
                  </div>
               )
            })}
         {/* <MessageRow><TextBlock>{getFooter()}</TextBlock></MessageRow> */}
      </>
   )
   //  console.log("estimate", estimate?.work_order)
   //  console.log("!checkNotPayed()", !checkNotPayed())
   //  console.log("payed", payed)
   //  console.log("111", Number(estimate.work_order.total.toFixed(2)))
   //  console.log("222", Number(estimate.work_order.deposited.toFixed(2)))

   const checkIsReceipt = () => {
      if (isInvoice) {
         if (estimate?.work_order?.payments_info && (!checkNotPayed() || payed)) {
            return true
         }
      } else {
         if (
            estimate?.work_order?.payments_info &&
            estimate?.work_order?.required_deposit_amount === estimate?.work_order?.deposited
         ) {
            return true
         }
      }
      return false
   }

   const MainBlock = () => (
      <>
         <WOInfo alignItems="center" justifyContent="space-between">
            <h3>WORK ORDER {checkIsReceipt() ? "PAYMENT RECEIPT" : isInvoice ? "INVOICE" : "ESTIMATE"}</h3>

            <p>Sent: {moment(estimate?.work_order?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p>
         </WOInfo>
         <WOTitle alignItems="center" justifyContent="space-between">
            <h3>{estimate?.work_order?.title}</h3>
            <p>
               <span>Work order:</span> #{estimate?.work_order?.order_number}
            </p>
         </WOTitle>
         <Info justifyContent="normal">
            <div className="infoBlock">
               <Customer>
                  <CustomerInfo className="leftAligned">
                     <div>Service Writer: </div>
                     <div className="title">{estimate?.work_order?.created_by?.full_name}</div>
                  </CustomerInfo>
                  <CustomerInfo className="leftAligned">
                     <div>Created on: </div>
                     <div className="title">
                        {estimate?.work_order?.created
                           ? moment(estimate?.work_order?.created).format("ddd, M/D/YYYY h:mm A")
                           : "No data"}
                     </div>
                  </CustomerInfo>
               </Customer>
               <DetailsDiv>
                  <h4>Project Details</h4>
                  <div>{estimate?.work_order?.notes || "No notes provided"}</div>
               </DetailsDiv>
               <DetailsDiv>
                  <h4>SCOPE OF WORK</h4>
                  <div>
                     {estimate?.work_order?.jobs && estimate?.work_order?.jobs.length > 0 && (
                        <ul>
                           {estimate?.work_order?.jobs.map((job: any) => {
                              return (
                                 <li key={job?.id}>
                                    {job.title} <span>({job.order_number})</span>
                                 </li>
                              )
                           })}
                        </ul>
                     )}
                  </div>
                  <h5>(Full project summary listed below)</h5>
               </DetailsDiv>
               <Customer>
                  <CustomerInfo className="leftAligned">
                     <div>Due Date: </div>
                     <div className="title">
                        {moment(estimate?.work_order?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                     </div>
                  </CustomerInfo>
                  <CustomerInfo className="leftAligned">
                     <div>Est. Duration: </div>
                     <div className="title">{estimate?.work_order?.estimated_duration ?? "TBD"}</div>
                  </CustomerInfo>
               </Customer>
            </div>
            <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
               <VesselHeader>
                  <CustomerInfo>
                     <div>Vessel:</div>
                     <div style={{ fontSize: "15px", fontWeight: "bold" }}>{estimate?.work_order?.vessel?.name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Hull ID:</div>
                     <div className="title">{estimate?.work_order?.vessel?.hin}</div>
                  </CustomerInfo>
               </VesselHeader>
               <VesselBottom>
                  <CustomerInfo>
                     <div>Vessel Type:</div>
                     <div className="title">
                        {vesselTypes.find((v) => v.value === estimate?.work_order?.vessel?.vessel_type)?.label ||
                           "No data"}
                     </div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Location:</div>
                     <div className="title">{estimate?.work_order?.vessel?.location_name}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Address:</div>
                     <div className="title">{getAddress(estimate?.work_order?.vessel?.address) || "-"}</div>
                  </CustomerInfo>
                  <CustomerInfo>
                     <div>Slip #:</div>
                     <div className="title">{estimate?.work_order?.vessel?.slip_number}</div>
                  </CustomerInfo>
                  {estimate?.work_order?.vessel?.image && (
                     <VesselImg>{<img src={estimate?.work_order?.vessel?.image} alt="vessel" />}</VesselImg>
                  )}
               </VesselBottom>
            </div>
         </Info>
         <PaymentStatus>
            <h3>PAYMENT STATUS</h3>
            <Flex>
               <div className="paymentBlock">
                  {checkIsReceipt() ? (
                     <TransactionInfo>
                        <h4>THANK YOU FOR YOUR PAYMENT!</h4>
                        <p style={{ color: "#42C77B", fontWeight: "bold" }}>
                           Amount Paid: ${estimate?.work_order?.payments_info.amount}
                        </p>
                        <p>Date: {moment(estimate?.work_order?.payments_info.date).format("l LT")}</p>
                        <p>
                           Transaction ID: #
                           {estimate?.work_order?.payments_info?.payment_intent?.intent_id
                              ? estimate?.work_order?.payments_info?.payment_intent?.intent_id.slice(3)
                              : "-"}
                        </p>
                        <p>
                           Payment{" "}
                           {estimate?.work_order?.payments_info?.payment_intent?.payment_details?.type === "card"
                              ? "Card"
                              : "Bank Account"}
                           : **** **** ****{" "}
                           {estimate?.work_order?.payments_info?.payment_intent?.payment_details?.type === "card"
                              ? estimate?.work_order?.payments_info?.payment_intent?.payment_details?.card.last4
                              : estimate?.work_order?.payments_info?.payment_intent?.payment_details?.us_bank_account
                                   .last4}
                        </p>
                     </TransactionInfo>
                  ) : estimate?.work_order?.is_recurring ? (
                     <p>{parse(paymentStatusSP)}</p>
                  ) : (
                     <p>{parse(paymentStatusWO)}</p>
                  )}
               </div>
               <div className="paymentBlock">
                  <Customer>
                     <CustomerInfo>
                        <div>Work Order Total:</div>
                        <div>{formatToUSD(estimate?.work_order?.total)}</div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div style={{ fontWeight: "bold", color: "#42C77B" }}>Paid To-Date:</div>
                        <div style={{ fontWeight: "bold", color: "#42C77B" }}>
                           {formatToUSD(estimate?.work_order?.deposited)}
                        </div>
                     </CustomerInfo>
                     <div className="divider" />
                     <CustomerInfo>
                        <div style={{ color: "#FB4D4F" }}>Payment Due (Deposit):</div>
                        <div
                           style={{
                              color: "#FB4D4F",
                              fontWeight: "bold",
                              fontSize: "15px",
                           }}
                        >
                           {formatToUSD(isInvoice ? estimate.amount_to_pay : checkDepositUnpaid()
                              ? estimate.work_order.required_deposit_amount
                              : estimate.work_order.additional_payment_amount ?? 0
                           )}
                        </div>
                     </CustomerInfo>
                     <CustomerInfo>
                        <div style={{ color: "#8B8B8B" }}>Remaining after this payment: </div>
                        <div style={{ color: "#8B8B8B" }}>{estimate?.work_order && formatToUSD(countRemaining())}</div>
                     </CustomerInfo>
                  </Customer>
               </div>
            </Flex>
         </PaymentStatus>
         {estimate?.work_order.jobs &&
            estimate?.work_order.jobs.length > 0 &&
            estimate?.work_order.jobs.map((job: any) => {
               return (
                  <div key={job?.id}>
                     <div className="noBreak">
                        <JobTitle justifyContent="space-between">
                           <h3>{job.title}</h3>
                           <p>(Job {job.order_number})</p>
                        </JobTitle>
                        <JobInfo alignItems="center" justifyContent="space-between">
                           <div>
                              Start Date & Time:{" "}
                              <span>{moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}</span>
                           </div>
                           <div>
                              Estimated Duration: <span>{job?.estimated_duration ?? "TBD"}</span>
                           </div>
                        </JobInfo>
                     </div>
                     <TableDiv>
                        {job?.services && job.services.length > 0 && (
                           <Services className="noBreak">
                              <div className={`grid header`}>
                                 <div className="name">LABOR</div>
                                 <div className="desc">Description</div>
                                 <div className="qty">Qty</div>
                                 <div className="rate">Rate</div>

                                 <div className="type">Type</div>
                                 <div className="total">Total</div>
                              </div>

                              {job.services.map((item: any) => {
                                 const type = servicesUnitTypes.find((s) => s.value === item.unit_type)
                                 return (
                                    <TableItem key={item.id}>
                                       <div className={`grid `}>
                                          <div className="name">{item.name}</div>
                                          <div className="desc">{item.description}</div>
                                          <div className="qty">{item.qty.toFixed(2)}</div>

                                          <div className="rate">{formatToUSD(item.unit_price)}</div>

                                          <div className="type">{type && type.label}</div>

                                          <div className="total" style={{ fontWeight: "bold" }}>
                                             {formatToUSD(item.unit_price * item.qty)}
                                          </div>
                                       </div>
                                    </TableItem>
                                 )
                              })}
                              {job?.technicians_data && job.technicians_data.length > 0 && (
                                 <Technician>
                                    <div className={`grid header`}>
                                       <div />
                                       <div>Technician(s)</div>
                                       <div>Estimated Hours</div>
                                       <div />
                                       <div />
                                       <div />
                                    </div>

                                    {job.technicians_data.map((item: any) => {
                                       return (
                                          <div key={item.id} className={`grid`}>
                                             <div />
                                             <div>{item?.technician?.full_name}</div>
                                             <div>{item.estimated_hours}</div>
                                             <div />
                                             <div />
                                             <div />
                                          </div>
                                       )
                                    })}
                                 </Technician>
                              )}
                           </Services>
                        )}
                     </TableDiv>
                     <TableDiv>
                        {job?.materials && job.materials.length > 0 && (
                           <Materials className="noBreak">
                              <div className={`grid header `}>
                                 <div className="name">MATERIALS</div>
                                 <div className="desc">Description</div>
                                 <div className="qty">Qty</div>
                                 <div className="price">Unit price</div>
                                 <div className="total">Total</div>
                              </div>

                              {job.materials.map((item: any) => {
                                 return (
                                    <TableItem key={item.id}>
                                       <div className={`grid`}>
                                          <div className="name">{item.name}</div>

                                          <div>{item.description}</div>
                                          <div className="qty">{item.qty.toFixed(2)}</div>
                                          <div className="price">{formatToUSD(item.unit_price)}</div>
                                          <div className="total" style={{ fontWeight: "bold" }}>
                                             {formatToUSD(item.unit_price * item.qty)}
                                          </div>
                                       </div>
                                    </TableItem>
                                 )
                              })}
                           </Materials>
                        )}
                     </TableDiv>

                     <div className="noBreak">
                        <Summary>
                           <div className="disclaimers">
                              <h3>DISCLAIMERS</h3>
                              {isInvoice ? (
                                 <p>{parse(invoiceFooter)}</p>
                              ) : (
                                 <p>{parse(estimateFooter)}</p>
                              )}
                           </div>
                           <div className="summary">
                              <Flex justifyContent="space-between">
                                 <div>Labor</div>
                                 <div>{formatToUSD(job.total_labor)}</div>
                              </Flex>
                              <Flex justifyContent="space-between">
                                 <div>Materials</div>
                                 <div>{formatToUSD(job.total_materials)}</div>
                              </Flex>
                              <Flex justifyContent="space-between">
                                 <div>Subtotal</div>
                                 <div>{formatToUSD(job !== null ? job.total_labor + job.total_materials : 0)}</div>
                              </Flex>
                              <Flex justifyContent="space-between">
                                 <div>Taxes</div>
                                 <div>{formatToUSD(job !== null ? job.tax : 0)}</div>
                              </Flex>
                              <Flex justifyContent="space-between">
                                 <div>EPA fees</div>
                                 <div>{formatToUSD(job !== null ? job.epa : 0)}</div>
                              </Flex>
                              <Flex className="total" justifyContent="space-between">
                                 <div>Job total</div>
                                 <div>
                                    {formatToUSD(
                                       job !== null ? (job.total_labor + job.total_materials + job.tax + job.epa) : 0
                                    )}
                                 </div>
                              </Flex>
                           </div>
                        </Summary>
                     </div>

                     <Photos job={job} />
                  </div>
               )
            })}
      </>
   )
   const ActionBlock = () => (
      <ApproveRow>
         <p style={{ fontSize: 12, color: "#494949", maxWidth: "330px" }}>
            If you have comments or questions prior to approval, please submit them in the Messages section on the right
            and a representative will respond as soon as possible.
         </p>
         <div style={{ flexGrow: 1 }} />
         {!isInvoice ? (
            <>
               <Modal
                  title="Estimate has been Approved"
                  footer={[
                     <ActionButton
                        key="dismiss"
                        onClick={() => {
                           setApproveVisible(false)
                        }}
                     >
                        Dismiss
                     </ActionButton>,
                     estimate?.view_config?.display_messages ? (
                        <ActionButton
                           key="message"
                           onClick={async () => {
                              await setApproveVisible(false)
                              openMessages && openMessages()
                              await setTimeout(() => {
                                 messageRef?.current?.focus()
                              }, 300)
                           }}
                        >
                           Send Message
                        </ActionButton>
                     ) : null,
                     <ActionButton
                        key="finish"
                        type="primary"
                        onClick={() => {
                           if (
                              checkPayAbility() &&
                              estimate.work_order.required_deposit_amount > estimate.work_order.deposited
                           ) {
                              setVisible(true)
                              setApproveVisible(false)
                              return
                           }

                           onApprove()
                           setApproveVisible(false)
                           setIsApproved(true)
                        }}
                     >
                        {checkPayAbility() &&
                        estimate.work_order.required_deposit_amount > estimate.work_order.deposited
                           ? "Next step"
                           : "Finish"}
                     </ActionButton>,
                  ]}
                  width="350px"
                  visible={approveVisible}
                  onCancel={() => setApproveVisible(false)}
               >
                  <Flex padding="48px 0">
                     <Msg fontSize="13px" color="#494949">
                        Thanks! We`ve received your authorization and are one step closer to working on your vessel
                     </Msg>
                  </Flex>
               </Modal>
               <PaymentModal
                  visible={visible}
                  estimate={estimate}
                  onCancel={() => setVisible(false)}
                  onSubmit={() => setPayed(true)}
                  onPending={(value: boolean) => setIsPending(value)}
                  amount={getPayAmount()}
                  invoiceUID={estimate.uuid}
                  totalAmount={estimate.work_order.total}
                  isInvoice={isInvoice}
                  invoiceName={"#" + estimate.work_order.order_number}
                  onApprove={onApprove}
                  setBlockedBy3DS={setBlockedBy3DS}
               />
               {!estimate.is_approved && !isApproved && !payed && (
                  <Flex width={isXs ? "100%" : "auto"}>
                     <Flex style={{gap: "12px"}} width={isXs ? "100%" : "inherit" }>
                        <RequestChangesModal
                           visible={modalDeclineEstimate}
                           isDecline
                           close={() => setDeclineEstimate(false)}
                           sendRequestChanges={handleDeclineEstimate}
                        />
                        <RequestChangesModal
                           visible={modalRequestChanges}
                           close={() => setRequestChanges(false)}
                           sendRequestChanges={sendRequestChanges}
                        />
                        <HeaderButton onClick={() => setRequestChanges(true)}>
                           Request Changes
                        </HeaderButton>
                        <HeaderButton onClick={() => setDeclineEstimate(true)}>
                           Decline Estimate
                        </HeaderButton>
                     </Flex>
                     {isXs ? (
                        <Button
                           style={{
                              flexShrink: 0,
                              border: "none",
                              padding: 0,
                              width: "38px",
                              height: "38px",
                              marginLeft: "10px",
                           }}
                           onClick={handlePrint}
                           icon={<DownloadIcon />}
                        />
                     ) : null}
                  </Flex>
               )}
               <Flex width={isXs ? "100%" : "auto"}>
                  {checkPayAbility() && estimate.work_order.required_deposit_amount > estimate.work_order.deposited ? (
                     <>
                        <PrimaryButton
                           type="primary"
                           loading={saving}
                           onClick={() => {
                              setApproveVisible(true)
                           }}
                           disabled={
                              saving || payed || blockedBy3DS || !!estimate.work_order.processing_intent || isPending
                           }
                        >
                           {payed
                              ? "Approved"
                              : estimate.work_order.processing_intent || isPending
                              ? `Processing Payment ${
                                   !!estimate.work_order.processing_intent
                                      ? "$" + estimate.work_order.processing_intent.toFixed(2)
                                      : ""
                                }`
                              : estimate.is_approved
                              ? "Pay Estimate"
                              : `Approve & Pay  $${
                                   checkDepositUnpaid()
                                      ? (estimate.work_order.required_deposit_amount ?? 0).toFixed(2)
                                      : (estimate?.work_order?.additional_payment_amount ?? 0).toFixed(2)
                                }`}
                        </PrimaryButton>
                     </>
                  ) : estimate.work_order.additional_payment_amount && !isInvoice ? (
                     <PrimaryButton
                        type="primary"
                        loading={saving}
                        onClick={() => setVisible(true)}
                        disabled={saving || payed || !!estimate.work_order.processing_intent || isPending}
                     >
                        {payed
                           ? "Paid"
                           : estimate.work_order.processing_intent || isPending
                           ? `Processing Payment ${
                                !!estimate.work_order.processing_intent
                                   ? "$" + estimate.work_order.processing_intent.toFixed(2)
                                   : ""
                             }`
                           : `Pay $${(estimate?.work_order?.additional_payment_amount ?? 0).toFixed(2)}`}
                     </PrimaryButton>
                  ) : (
                     <PrimaryButton
                        type="primary"
                        loading={saving}
                        onClick={() => setApproveVisible(true)}
                        disabled={saving || estimate.is_approved || isApproved}
                     >
                        {estimate.is_approved || isApproved ? "Approved" : "Approve"}
                     </PrimaryButton>
                  )}
                  {isXs && (estimate.is_approved || isApproved || payed) && (
                     <Button
                        style={{
                           flexShrink: 0,
                           border: "none",
                           padding: 0,
                           width: "38px",
                           height: "38px",
                           marginLeft: "10px",
                        }}
                        onClick={handlePrint}
                        icon={<DownloadIcon />}
                     />
                  )}
               </Flex>
            </>
         ) : null}

         {checkPayAbility() && isInvoice && (
            <>
               <PrimaryButton
                  type="primary"
                  disabled={
                     !checkNotPayed() || payed || blockedBy3DS || !!estimate.work_order.processing_intent || isPending || !estimate.amount_to_pay
                  }
                  onClick={() => {
                     setVisible(true)
                  }}
               >
                  {!checkNotPayed() || payed || !estimate.amount_to_pay
                     ? "Paid"
                     : estimate.work_order.processing_intent || isPending
                     ? `Processing Payment ${
                          !!estimate.work_order.processing_intent
                             ? formatToUSD(estimate.work_order.processing_intent)
                             : ""
                       }`
                     : isInvoice
                     ? `Pay Invoice  ${formatToUSD(getPayAmount() || 0)}`
                     : "Pay Estimate"}
               </PrimaryButton>
               <PaymentModal
                  visible={visible}
                  estimate={estimate}
                  onCancel={() => setVisible(false)}
                  onSubmit={() => setPayed(true)}
                  onPending={() => setIsPending(true)}
                  amount={getPayAmount()}
                  invoiceUID={estimate.uuid}
                  isInvoice={isInvoice}
                  invoiceName={"#" + estimate.work_order.order_number}
                  onApprove={onApprove}
                  setBlockedBy3DS={setBlockedBy3DS}
                  totalAmount={estimate.work_order.total}
               />
            </>
         )}
         {!isXs ? (
            <Button
               style={{
                  flexShrink: 0,
                  border: "none",
                  padding: 0,
                  width: "38px",
                  height: "38px",
               }}
               onClick={handlePrint}
               icon={<DownloadIcon />}
            />
         ) : null}
      </ApproveRow>
   )

   return isXs ? (
      isMobileActions ? (
         <ActionBlock />
      ) : (
         <MobileMainBlock />
      )
   ) : (
      <DetailsContainer>
         <Header alignItems="center" className="topHeader">
            <Flex justifyContent="center">
               <div style={{ width: "150px", height: "90px" }}>
                  <img src={company.logo || DEFAULT_LOGO} alt="Logo" />
               </div>
            </Flex>
            <HeaderInfo>
               <Flex>
                  <Flex flexDirection="column">
                     <h3>COMPANY</h3>
                     <h4>{company.name}</h4>
                     <p>{getAddress(company.address) || "-"}</p>
                     <p> {company.phone || "-"}</p>
                     <p style={{ color: "#0496FF" }}>{company.email || "-"}</p>
                  </Flex>

                  <Flex alignItems="flex-start" flexDirection="column" marginLeft="100px">
                     <h3>BILLING CONTACT</h3>
                     <h4>{estimate?.work_order?.customer?.full_name}</h4>
                     <p>
                        {estimate?.work_order?.customer?.address &&
                           renderAddress(estimate?.work_order?.customer?.address)}
                     </p>
                     <p>
                        {estimate?.work_order?.customer?.phones.find(
                           (p: { phone_type: string }) => p.phone_type === "P"
                        )?.phone || "No data"}
                     </p>
                     <p style={{ color: "#0496FF" }}>{estimate?.work_order?.customer?.email}</p>
                  </Flex>
               </Flex>
            </HeaderInfo>
         </Header>

         <MainBlock />

         <ActionBlock />
      </DetailsContainer>
   )
}
