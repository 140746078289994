import React, { useEffect } from "react"
import { Modal, Checkbox } from "antd"
import styled from "styled-components"
import { TArgCallback, TCallback } from "../../../types"

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 20px 0 0 0;

      h3 {
         text-transform: uppercase;
         padding: 0 15px;
         font-weight: 900;
         font-size: 15px;
      }
      p {
         padding: 0 15px;
         font-size: 18px;
      }
   }
   .ant-modal-footer {
      padding: 15px 15px 15px 20px;
   }
`
const Group = styled.div`
   label {
      display: flex;
      padding: 15px 15px;
      border-top: 1px solid #ededed;

      span {
         font-size: 13px;
      }
      .ant-checkbox + span {
         padding-right: 8px;
         padding-left: 15px;
      }
   }
`

type TProps = {
   handlePrint: TCallback
   setPrintModal: TArgCallback<any>
   setPrintBlocks: TArgCallback<any>
   showPrintModal: boolean
   checkboxes: any
   technicianRole: boolean
   job?: boolean
}

const ModalPrintCheckboxes: React.FC<TProps> = ({
   handlePrint,
   setPrintModal,
   showPrintModal,
   checkboxes,
   setPrintBlocks,
   technicianRole,
   job,
}) => {
   const onCheckAllChange = () => {
      setPrintBlocks({
         woPricing: !checkboxes.checkAll,
         woPhotos: !checkboxes.checkAll,
         vesselPhoto: !checkboxes.checkAll,
         beforeAfterPhotos: !checkboxes.beforeAfterPhotos,
         checkAll: !checkboxes.checkAll,
      })
   }

   const onCheckChange = (e: any) => {
      const { name, checked } = e.target
      setPrintBlocks((prev: any) => {
         return {
            ...prev,
            [name]: checked,
         }
      })
   }

   useEffect(() => {
      if (checkboxes.woPricing && checkboxes.woPhotos && checkboxes.vesselPhoto) {
         setPrintBlocks((prev: any) => {
            return {
               ...prev,
               beforeAfterPhotos: true,
               checkAll: true,
            }
         })
      } else {
         setPrintBlocks((prev: any) => {
            return {
               ...prev,
               beforeAfterPhotos: false,
               checkAll: false,
            }
         })
      }
   }, [checkboxes.woPricing, checkboxes.woPhotos, checkboxes.vesselPhoto])

   return (
      <StyledModal
         destroyOnClose
         centered
         visible={showPrintModal}
         onOk={() => {
            handlePrint()
            setPrintModal(false)
         }}
         onCancel={() => setPrintModal(false)}
         okText="Print"
      >
         <h3> Print {job ? "job" : "work Order"} </h3>
         <p>Select the information that you would like to include in your printable {job ? "job" : "work Order"}</p>
         <Group>
            {!technicianRole && (
               <Checkbox onChange={onCheckChange} checked={checkboxes.woPricing} name="woPricing">
                  {job ? "Job" : "Work Order"} Pricing
               </Checkbox>
            )}

            <Checkbox onChange={onCheckChange} checked={checkboxes.woPhotos} name="woPhotos">
               {job ? "Job" : "Work Order"} Photos and Notes
            </Checkbox>
            <Checkbox onChange={onCheckChange} checked={checkboxes.vesselPhoto} name="vesselPhoto">
               Vessel Profile Photo
            </Checkbox>
            <Checkbox onChange={onCheckAllChange} checked={checkboxes.checkAll} name="checkAll">
               Select all
            </Checkbox>
         </Group>
      </StyledModal>
   )
}
export default ModalPrintCheckboxes
