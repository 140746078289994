import React, { useEffect, useMemo, useState } from "react";
import { TCallback } from "../../types";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  Dropdown,
  Empty,
  Menu,
  notification,
  Popconfirm,
  Skeleton,
  Tooltip,
} from "antd";
import { INotification, ENotificationStatus } from "../../api/types";
import { ReactComponent as Remove } from "../../assets/images/Remove.svg";
import { ReactComponent as Check } from "../../assets/images/CheckCircle.svg";
import { getAgoTime, getNotificationsColor } from "../../utils/utils";
import { PALETTE } from "../../constants";
import { TExtendedNotification, TTimeSlotCategory } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtendedNotifications,
  getNotificationsStatus,
} from "../../config/reducers/notifications/selectors";
import {
  readNotification,
  removeNotification,
  unreadNotification,
} from "../../config/reducers/notifications/actions";
import reactStringReplace from "react-string-replace";
import { StyledFlex, StyledLink } from "./NotificationsShortView";
import { getNotificationTagUrl } from "./utils";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import Flex from "../../noui/Flex";
import { EmptyButton } from "../../ui/Button";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import { DeleteIcon, CheckCircleIcon } from "../../assets/icons";

interface TStyleProps {
  notificationColor: string;
}
const Wrapper = styled.div`
  width: 800px;
  h4 {
    font-weight: 900;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
  ${down("md")} {
    width: 100%;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const Container = styled.div`
  padding: 16px 0;
  overflow-y: auto;
  max-height: 560px;
  &::-webkit-scrollbar {
    width: 8px;
  }

  @media (max-height: 700px) {
    max-height: 500px;
  }
  @media (max-height: 650px) {
    max-height: 400px;
  }
  @media (max-height: 450px) {
    max-height: 300px;
  }

  &::-webkit-scrollbar-track {
    background: #fbfbff;
    border-radius: 8px;
    border: 1px solid #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background: #0496ff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #40a9ff;
  }
  h5 {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    margin: 0;
    padding: 22px 16px 16px;
  }
`;

const DaysAgo = styled.div`
  margin-left: auto;
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
  opacity: 0.6;
  ${down("xs")} {
    margin-left: 0;
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  min-height: 70px;
  align-items: center;
  width: 100%;
  gap: 12px;
  border-top: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: 1px solid #d9d9d9;
  }
  ${down("md")} {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 16px 12px;
    // justify-content: space-between;
    align-items: start;
    flex-direction: column;
    width: 100%;
  }
`;
const IconWrapper = styled.div`
  color: #595959;
  cursor: pointer;
  transition: opacity 0.2s;
  line-height: 0;
  &:hover {
    opacity: 0.8;
  }
  &.active {
    color: ${PALETTE.PrimaryText};
  }
  &.disabled {
    cursor: auto;
  }
`;

const StyledMenu = styled(Menu)`
  padding: 15px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;

const OptionButton = styled(EmptyButton)`
  margin-right: 25px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
  &.ant-btn {
    background: none !important;
  }
`;

export const PrefButton = styled(Button)`
  margin-right: -12px;
  font-weight: bold;
  color: #0496ff;

  font-weight: 700;
  font-size: 13px;
  > span {
    text-decoration: underline;
  }
`;

export const StyleMessage = styled(Flex)<TStyleProps>`
  width: 80%;
  word-wrap: break-word;

  a {
    font-weight: bold;
  }

  color: ${(props) => props.notificationColor} !important;
  span,
  a {
    color: ${(props) => props.notificationColor} !important;
  }
`;

type TNotificationProps = {
  notification: INotification;
  onRead: TCallback;
  onRemove: TCallback;
  onClose: TCallback;
  allSelected: number[];
  setAllSelected: any;
};
const Notification: React.FC<TNotificationProps> = ({
  notification,
  onRead,
  onRemove,
  onClose,
  allSelected,
  setAllSelected,
}) => {
  const user = useAppSelector(getCurrentUser);
  const getMessage = () => {
    let c: React.ReactNodeArray = [notification.content];
    return notification.tags
      .slice()
      .sort((a, b) => a.content_type.localeCompare(b.content_type))
      .reduce((acc, tag) => {
        return reactStringReplace(acc, tag.tag, () => (
          <StyledLink
            onClick={onClose}
            to={getNotificationTagUrl(tag, notification.tags, user)}
          >
            {tag.tag}
          </StyledLink>
        ));
      }, c);
  };

  const isMd = useBreakpoint(down("md"));

  const handleCheck = (e: CheckboxChangeEvent) => {
    e.target.checked
      ? setAllSelected([...allSelected, notification.id])
      : setAllSelected(allSelected.filter((n) => n !== notification.id));
  };

  const renderColorBtn = () => {
    let color = "";

    if (notification.priority !== ENotificationStatus.Medium) {
      color = getNotificationsColor(notification.priority);
    } else {
      color = !notification.read ? "#0496FF" : "#8B8B8B";
    }
    return color;
  };

  return isMd ? (
    <NotificationWrapper>
      <StyledFlex justifyContent="space-between" width="100%">
        <Flex>
          <Checkbox
            checked={allSelected.includes(notification.id)}
            style={{ marginRight: "16px" }}
            onChange={handleCheck}
          />
          <div>{getMessage()}</div>
        </Flex>
        <IconWrapper onClick={onRemove}>
          <Remove />
        </IconWrapper>
      </StyledFlex>
      <Flex alignItems="center">
        <IconWrapper
          onClick={onRead}
          style={{ marginRight: "8px" }}
          className={!notification.read ? "active" : "disabled"}
        >
          <CheckCircleIcon color={renderColorBtn()} />
        </IconWrapper>
        <DaysAgo>{getAgoTime(notification.created)}</DaysAgo>
      </Flex>
    </NotificationWrapper>
  ) : (
    <NotificationWrapper>
      <StyleMessage
        notificationColor={getNotificationsColor(notification.priority)}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mr="15px"
        >
          <Checkbox
            checked={allSelected.includes(notification.id)}
            style={{ marginRight: "10px", marginBottom: "0px" }}
            onChange={handleCheck}
          />
          <Tooltip
            title={`Mark as  ${!notification.read ? "read" : "unread"}`}
            placement="right"
            overlayInnerStyle={{
              minWidth: "30px",
              minHeight: " 22px",
              padding: "2px 4px",
            }}
            overlayStyle={{ fontSize: "12px" }}
          >
            <IconWrapper
              onClick={onRead}
              className={!notification.read ? "active" : "disabled"}
            >
              <CheckCircleIcon
                color={renderColorBtn()}
                width="20px"
                height="20px"
              />
            </IconWrapper>
          </Tooltip>
        </Flex>

        <div style={{ width: "550px" }}>{getMessage()}</div>
      </StyleMessage>
      <DaysAgo>{getAgoTime(notification.created)}</DaysAgo>
      <IconWrapper onClick={onRemove}>
        <DeleteIcon width="20px" height="20px" />
      </IconWrapper>
    </NotificationWrapper>
  );
};

type TGroupedNotification = {
  notifications: TExtendedNotification[];
  category: TTimeSlotCategory;
};
type TProps = {
  onClose: TCallback;
  onPreferences: TCallback;
};
export const NotificationsFull: React.FC<TProps> = ({
  onPreferences,
  onClose,
}) => {
  const dispatch = useDispatch();
  const notificationsStatus = useSelector(getNotificationsStatus);
  const notifications = useSelector(getExtendedNotifications);
  const isMd = useBreakpoint(down("md"));
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>(
    []
  );

  const groupedNotifications: TGroupedNotification[] = useMemo(() => {
    return notifications.reduce((acc, n) => {
      const item = acc.find((el) => el.category === n.category);
      if (item) {
        item.notifications.push(n);
      } else {
        acc.push({ category: n.category, notifications: [n] });
      }
      return acc;
    }, [] as TGroupedNotification[]);
  }, [notifications]);

  const handleRead = (notification: INotification) => async () => {
    await dispatch(readNotification(notification.id));
  };
  const handleToggle = (notification: INotification) => async () => {
    if (notification.read) {
      await dispatch(unreadNotification(notification.id));
      return;
    }
    await dispatch(readNotification(notification.id));
  };
  const handleRemove = (notification: INotification) => async () => {
    await dispatch(removeNotification(notification.id));
  };

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    e.target.checked
      ? setSelectedNotifications(notifications.map((n) => n.id))
      : setSelectedNotifications([]);
  };

  const handleDeleteChecked = async () => {
    const filteredNotifications = notifications.filter((n) =>
      selectedNotifications.includes(n.id)
    );
    for (const n of filteredNotifications) {
      await handleRemove(n)();
    }
    await setSelectedNotifications([]);
  };

  const handleReadChecked = async () => {
    const filteredNotifications = notifications.filter((n) =>
      selectedNotifications.includes(n.id)
    );
    for (const n of filteredNotifications) {
      await handleRead(n)();
    }
  };

  return (
    <Wrapper>
      <TitleContainer>
        <h4>Notifications</h4>
        <PrefButton type="link" onClick={onPreferences}>
          Preferences
        </PrefButton>
      </TitleContainer>
      <Container>
        <Skeleton
          active={notificationsStatus === "loading"}
          round
          loading={notificationsStatus === "loading"}
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            padding={isMd ? "0 12px 0 24px" : "0 16px"}
          >
            <Checkbox onChange={handleCheckAll} />
            <Flex justifyContent="flex-end" alignItems="center" width="100%">
              <Button
                onClick={handleDeleteChecked}
                style={{ marginLeft: "27px" }}
              >
                Delete
              </Button>
              <Button
                onClick={handleReadChecked}
                style={{ marginLeft: "12px" }}
              >
                Mark as read
              </Button>
            </Flex>
          </Flex>
          {groupedNotifications.length ? (
            groupedNotifications.map((group) => {
              return (
                <div
                  key={group.category}
                  style={{ padding: isMd ? "12px" : 0 }}
                >
                  <h5 style={{ padding: "10px 16px" }}>{group.category}</h5>
                  {group.notifications.map((n) => (
                    <Notification
                      onClose={onClose}
                      notification={n}
                      key={n.id}
                      onRead={handleToggle(n)}
                      onRemove={handleRemove(n)}
                      allSelected={selectedNotifications}
                      setAllSelected={setSelectedNotifications}
                    />
                  ))}
                </div>
              );
            })
          ) : (
            <Empty description={"No notifications"} />
          )}
        </Skeleton>
      </Container>
    </Wrapper>
  );
};
