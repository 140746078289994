import styled from 'styled-components';
import {
  space, SpaceProps,
  background, BackgroundProps
} from 'styled-system';

export default styled('span')<SpaceProps&BackgroundProps>`
  display: inline-block;
  width: 100%;
  height: 1px;
  max-height: 2px;
  line-height: 2px;
  position: relative;
  background-color: #C2CFE0;
  vertical-align: top;
  ${space};
  ${background};
`;