import React, { useState, useEffect } from 'react';
import Flex from "../../noui/Flex";
import styled from "styled-components";
import { Api } from "../../api/api";
import { notification } from 'antd';
import { IHelpArticles } from '../../api/types';
import { NavLink } from 'react-router-dom';
import routes from "../../routes/routes";
import { Msg } from '../../ui/Text';
import { useParams } from 'react-router-dom';


const TitleOfArticles = styled(Flex)`
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
border-radius: 6px;
`;

const LinkToArticle = styled(NavLink)`
    text-decoration: underline
`;

type TProps = {
    sectionNumber: number
}

export const Section: React.FC<TProps> = ({ sectionNumber }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [article, setArticle] = useState<IHelpArticles[]>([]);
    const { id: articleId } = useParams<{ id?: string }>();

    useEffect(() => {
        setLoading(true);
        try {
            Api.help.get({ section: sectionNumber }).then(data => {
                setArticle(data.data as IHelpArticles[])
            })

        } catch (e) {
            notification.error({
                message: 'Something went wrong'
            });
        } finally {
            setLoading(false);
        }

    }, []);


    return <Flex flexDirection='column'>
        <Msg fontWeight='800' fontSize='15px' color='#484848' mb='16px' mt='30px'>{article[0]?.section_name}</Msg>
        {article.map(el => <TitleOfArticles flexDirection='column'>
            <Flex className='with-underline' flexDirection='column'>
                <Msg fontWeight='800' fontSize='15px' color='#109CF1' p='20px 56px 8px'><LinkToArticle to={routes.help.Articles.replace(":id", el.id.toString())}>{el.title}</LinkToArticle></Msg>
                <Msg fontWeight='400' fontSize='15px' color='#000000' p='0px 56px 20px'>{el.subtitle}</Msg>
            </Flex>
        </TitleOfArticles>)

        }
    </Flex>
};