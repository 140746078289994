import React from 'react';
import classes from "../styles.module.scss";
import {ProgressBar} from "./ProgressBar";
import moment from "moment";
import {IJob} from "../../../api/types";
import {TCallback} from "../../../types";

type TProps = {
  job?: IJob;
  processing: boolean;
  cancelling: boolean;
  activeStep: number;
  onAction: TCallback;
  onUndo: TCallback;
}
export const JobStatus: React.FC<TProps> = ({job, processing, cancelling, activeStep, onAction, onUndo}) => {
  if (activeStep < 1) return null;

  const getDate = (date?: string): JSX.Element|null => {
    return date ? <>
      {moment(date).format("MM/DD/YYYY")} <br/>
      <span>{moment(date).format("h:mm a")}</span>
    </> : null;
  }
  const handleGetContent = (idx: number): JSX.Element|null => {
    switch (idx) {
      case 0:
        return getDate(job?.schedules[0]?.start);
      case 1:
        return getDate(job?.started);
      case 2:
        return getDate(job?.finished);
      case 3:
        return getDate(job?.invoices[0]?.created);
      default:
        return null;
    }
  }

  return (
    <div className={classes.status}>
      <ProgressBar
        getContent={handleGetContent}
        processing={processing}
        cancelling={cancelling}
        activeStep={activeStep}
        onAction={onAction}
        onUndo={onUndo} />
    </div>
  );
};