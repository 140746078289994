import React from "react"
import styled, { StyledComponent, css } from "styled-components"
import { down } from "styled-breakpoints"
import { Input, Button, Divider, Upload, Form, Menu } from "antd"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { PlusCircleFilled } from "@ant-design/icons"
import Flex, { TFlexProps } from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { EmptyButton } from "../../ui/Button"
import PlusInCircle from "../../assets/images/PlusInCircule.svg"
import { TCallback } from "../../types"
import UploadImage from "../../assets/images/UploadPhotoBig.svg"
import UploadPhoneImage from "../../assets/images/UploadPhone.svg"
import { NewCustomerIcon } from "../../assets/icons"

// export const PageContainer = styled(Flex)`
//   padding: 24px 28px;
//   flex-direction: column;
// ` as typeof Flex;

export const WorkOrderName = styled(Input)`
   font-size: 24px;
   font-weight: 900;
   width: 66%;
   text-transform: uppercase;
   ${down("md")} {
      width: 100%;
   }
`
export const FormPhotos = styled(Form)`
   .ant-form-item-label {
      padding: 0 0 5px 0 !important;
   }
`
type TRespFlexProps = { gap?: number; wrap?: boolean }
export const ResponsiveFlex = styled(Flex)<TRespFlexProps>`
   flex-direction: row;
   align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
   flex-wrap: ${(props) => props.wrap && "wrap"};
   gap: ${(props) => (props.gap ? `${props.gap}px` : "0px")};
   ${down("lg")} {
      flex-direction: column;
      align-items: flex-start;
      & > * {
         width: 100%;
         .ant-input-number {
            width: 100%;
         }
      }
   }
` as StyledComponent<"div", any, TRespFlexProps & TFlexProps>

// Card styled
export const SelectCard = styled(Flex)<{ stretch?: boolean; isRecurring?: boolean }>`
   border-radius: 4px;
   box-shadow: ${(props) => (props.isRecurring ? "none" : "0px 2px 10px 0px rgba(0, 0, 0, 0.15)")};
   padding: 16px 24px 24px 24px;
   width: calc(33% - 8px);
   align-self: ${(props) => (props.stretch ? "stretch" : "flex-start")};
   flex-direction: column;

   ${down("lg")} {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
      width: 100%;
   }
   ${down("xs")} {
      padding: 16px 12px;
   }
` as StyledComponent<"div", any, { stretch?: boolean; isRecurring?: boolean } & TFlexProps>

export const SelectCardTitle = styled(Msg)`
   font-size: 15px;
   font-weight: 900;
   text-transform: uppercase;
   margin-bottom: 12px;
   ${down("lg")} {
      margin-bottom: 10px;
   }
`

// Buttons

export const AddButton: React.FC<{ onClick: TCallback; id?: string }> = ({ children, onClick, id }) => {
   const isXs = useBreakpoint(down('xs'))
   const buttonStyles = !isXs ? {} : {
      border: '1px solid #EDEDED',
      borderRadius: '4px',
      width: '100%',
   }

   return (
   <EmptyButton 
      id={id} 
      onClick={onClick} 
      style={buttonStyles}
   >
      <Flex alignItems="center" justifyContent={isXs ? "center" : "start"} padding={isXs ? "8px 0 " : "0"}>
         <img src={PlusInCircle} alt="Plus" style={{ height: "24px", width: "24px" }} />
         <Msg color="#109CF1" fontSize={14} fontWeight={700} ml="12px">
            {children}
         </Msg>
      </Flex>
   </EmptyButton>
)}

export const AutocompleteAddButton: React.FC<{ onClick: TCallback; text: string }> = ({ onClick, text }) => (
   <Flex padding="12px 0" style={{ cursor: "pointer" }} onClick={onClick} alignItems="center">
      <NewCustomerIcon />
      <Msg fontWeight={700} color="#0496FF" marginLeft="12px" style={{ textDecoration: "underline" }}>
         {text}
      </Msg>
   </Flex>
)

export const StyledButton = styled(Button)`
   height: 40px;
`

export const SuccessButton = styled(StyledButton)`
   background-color: #42c57a;
   border-color: #42c57a;
   flex-grow: 1;

   @media (max-width: 680px) {
      width: 100%;
   }
   @media (max-width: 1319px) and (min-width: 1200px) {
      width: 100%;
   }

   &:hover,
   &:focus {
      background-color: #42c57a;
      border-color: #42c57a;
      opacity: 0.8;
   }
`

export const InfoRow = styled(Flex)`
   align-items: start;
   overflow: hidden;
   margin: 4px 0;
   > svg {
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 14px;
   }
`

// Work order info
export const OrderInfoWrapper = styled(SelectCard)`
   ${down("xs")} {
      box-shadow: none;
   }
   padding: 0;
   width: 100%;
` as typeof SelectCard

export const InfoContainer = styled(Flex)`
   padding: 24px 20px 0px 20px;
   flex-direction: row;
   ${down("lg")} {
      flex-direction: column;
      padding: 24px 0px 0px 0px;
   }
   ${down("xs")} {
      padding: 0;
   }
` as typeof Flex

export const OrderInfoHeader = styled(Flex)`
   border-bottom: 1px solid #e6e6e6;
   padding: 30px 24px;
   flex-direction: column;
   ${down("md")} {
      padding: 12px;
   }
   ${down("xs")} {
      box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.15);
   }
   & div > input {
      outline: none;
      font-weight: 900;
      font-size: 24px;
      ::placeholder {
         text-transform: uppercase;
      }
   }
` as typeof Flex

export const TabsWrapper = styled.div`
   width: calc(100% - 300px);
   @media (max-width: 1275px) {
      width: calc(100% - 250px);
   }
   .ant-tabs-nav {
      ${down("xs")} {
         padding: 0 12px;
      }
      ${down("lg")} {
         padding: 0 12px;
      }
      padding: 0px 18px 0px 0px;
   }
   ${down("xs")} {
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
   }
   ${down("lg")} {
      width: 100%;
      .ant-tabs-tab {
         font-size: 15px;
      }
      .ant-tabs-tab-active {
         font-weight: 600;
      }
   }
`

export const BlockTitle = styled(Msg)`
   color: #0f497b;
   font-weight: 900;
   font-size: 16px;
   text-transform: uppercase;
` as typeof Msg

export const Wrapper = styled.div`
   display: grid;
   grid-gap: 12px;
   margin-top: 24px;
   grid-template-columns: 3.8fr 0.5fr 0.7fr 1fr 1fr 0.3fr;
   //grid-template-columns: 2fr 1fr 1.5fr 1fr 1fr 0.5fr;
   min-width: 0;
   > .ant-row {
      min-width: 0;
   }
   position: relative;
   ${down("md")} {
      grid-template-areas:
         "main main"
         "add add"
         "add add";
   }
   & .ant-form-item {
      margin-bottom: 5px;
   }
`
export const WrapperServicesTechnician = styled.div`
   display: grid;
   grid-gap: 12px;
   margin-top: 24px;
   grid-template-columns: 3.8fr 0.5fr 0.7fr 1fr 1fr 0.3fr;
   //grid-template-columns: 2fr 1fr 1.5fr 1fr 1fr 0.5fr;
   min-width: 0;
   > .ant-row {
      min-width: 0;
   }
   ${down("md")} {
      grid-template-columns: 2fr 0.5fr 1fr;
      & .ant-row.ant-form-item {
         display: block;
      }
   }
`
export const WrapperTechnician = styled.div`
   display: grid;
   grid-gap: 12px;
   margin-top: 24px;
   grid-template-columns: 3.8fr 0.5fr 1fr 1fr 0.5fr;
   //grid-template-columns: 2fr 1fr 1.5fr 1fr 1fr 0.5fr;
   min-width: 0;
   > .ant-row {
      min-width: 0;
   }
   @media (max-width: 1350px) {
      grid-template-columns: 3.3fr 0.8fr 1.4fr 1fr 0.5fr;
   }
   ${down("md")} {
      grid-template-columns: 2fr 0.5fr 1fr;
      & .ant-row.ant-form-item {
         display: block;
      }
   }
`
export const InputStyled = styled(Input)`
   &:disabled {
      border: none;
      background: none;
      color: #000;
      padding: 0;
   }
`
export const MaterialWrapper = styled.div`
   display: grid;
   grid-gap: 12px;
   margin-top: 24px;
   grid-template-columns: 3fr 1fr 0.5fr 1fr 1fr 0.5fr;
   min-width: 0;
   > .ant-row {
      min-width: 0;
   }
   ${down("md")} {
      grid-template-areas:
         "main main"
         "add add"
         "add add";
   }
   & .ant-form-item {
      margin-bottom: 5px;
   }
`

// Side content

export const SideContentWrapper = styled.div`
   border-left: 1px solid #e6e6e6;
   width: 300px;
   @media (max-width: 1275px) {
      width: 250px;
   }
   ${down("xs")} {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
      margin-top: 16px;
      padding-right: 0;
   }
   ${down("lg")} {
      width: 100%;
      padding-right: 12px;
      min-width: 100%;
      max-width: 100%;
   }
`

export const SideContentBlock = styled(Flex)`
   flex-direction: column;
   padding: 0 0 0 16px;
   gap: 10px;
   ${down('xs')} {
      padding: 12px;
   }
`

export const SideContentLabel = styled(Msg)`
   color: #828282;
   font-size: 16px;
`

export const UnderlineInfo = styled(Msg)`
   color: #0496ff;
   font-weight: bold;
   font-size: 15px;
   text-decoration: underline;
`

export const CustomDivider = styled(Divider)`
   margin: 8px 0;
`

// Time clocks
const ButtonWrapper = styled(Flex)`
   align-self: stretch;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   color: rgba(189, 189, 189, 1);
   border-radius: 4px;
   min-height: 224px;
   height: 100%;
   min-height: 224px;
`
export const TechnicianWrapper = styled(Flex)`
   align-items: stretch;
   max-width: 100%;
   overflow-x: auto;
   padding: 4px;
   gap: 26px;
   ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
   }
   ::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 3px;
   }
   ${down("md")} {
      flex-direction: column;
      align-items: center;
      & > * {
         width: 100%;
         min-height: 224px;
      }
   }
`

export const TechnicianItem = styled(Flex)`
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   flex-direction: column;
   align-items: center;
   min-width: 250px;
   max-width: 270px;
   justify-content: space-between;
`

export const StatsWrapper = styled(Flex)`
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   padding: 10px 15px;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   margin-top: 15px;
`
export const BottomTechnician = styled.div`
   margin-top: 20px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   width: 100%;

   button {
      &:disabled {
         opacity: 0.6;
      }
   }
`

export const InitialsWrapper = styled(Flex)`
   align-items: center;
   justify-content: center;
   width: 50px;
   height: 50px;
   border: 1px solid rgba(230, 230, 230, 1);
   border-radius: 50%;
`

export const TechnicianName = styled(Msg)`
   font-size: 15px;
   font-weight: 900;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   max-height: 24px;
   max-width: 100%;
`

const PlusButton = styled(Flex)`
   min-width: 250px;
   align-self: stretch;
   min-height: 224px;
`

export const AddTechnician: React.FC<{ onClick: TCallback }> = ({ onClick }) => {
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))
   return (
      <PlusButton style={{ alignSelf: "stretch" }}>
         <EmptyButton onClick={onClick} style={{ width: "100%", minHeight: isMd || isXs ? "250px" : "414px" }}>
            <ButtonWrapper flexDirection="column" alignItems="center" justifyContent="center">
               <PlusCircleFilled style={{ fontSize: "48px" }} />
               <Msg fontSize={13} color="rgba(189, 189, 189, 1)" mt="16px">
                  Add another tech to the job
               </Msg>
            </ButtonWrapper>
         </EmptyButton>
      </PlusButton>
   )
}

export const Total = styled(Flex)`
   border: 1px solid rgba(230, 230, 230, 1);
   border-radius: 4px;
   padding: 5px 10px;
   font-weight: 700;
   height: 32px;
   overflow-x: auto;
   &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }
   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }
   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
` as typeof Flex

export const ResponsiveTextarea = styled(Input.TextArea)`
   resize: none;
`

export const RemoveButton = styled(Button)`
   text-align: center;
   color: #e53e4a;
   font-weight: 700;
   width: 100%;
   border: 1px solid #EDEDED;
   border-radius: 4px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 19px 15px;
   >svg {
      margin-right: 8px;
   }
`

// Photos & Notes
export const UploadImgWrapper = styled(Flex)`
   height: 110px;
   width: calc(20% - 16px);
`

export const MainUpload = styled(Upload)`
   width: 132px;
   height: 100%;
   display: flex;
   align-items: center;
   cursor: pointer;
   & > img {
      margin-top: -6px;
      border-radius: 4px;
   }
   & button {
      // max-width: 440px;
      width: 100%;
      border-radius: 4px;
   }
   height: 110px;
   border-radius: 4px;
`

export const MainUploadDiv = styled.div`
   width: 132px;
   height: 100%;
   display: flex;
   align-items: center;
   cursor: pointer;
   & > img {
      margin-top: -6px;
      border-radius: 4px;
   }
   & button {
      // max-width: 440px;
      width: 100%;
      border-radius: 4px;
   }
   height: 110px;
   border-radius: 4px;
   margin: 0 8px 8px 0;
   border-radius: 4px;
   overflow: hidden;
`

export const InspectionImage = styled(Flex)<{ src: string }>`
   background-image: ${(props) => `url(${props.src})`};
   background-size: cover;
   background-repeat: no-repeat;
   border-radius: 4px;
   position: relative;
   flex-basis: calc(25% - 8px);
   height: 110px;
   margin: 0 8px 8px 0;
   overflow: hidden;
   ${down("xs")} {
      flex-basis: initial;
      width: 100%;
      height: 300px !important;
   }
   ${down("md")} {
      flex-basis: initial;
      width: 100%;
      height: 300px !important;
   }
`

export const DeleteImgBtn = styled(Button)`
   position: absolute;
   bottom: 0;
   right: 0;
   background: #df3737;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-bottom-left-radius: 0px;
   border-top-right-radius: 0px;
   border: none;
   &:hover,
   &:focus {
      opacity: 0.8;
      border: none;
      background: #df3737;
   }
   ${down("xs")} {
      width: 44px;
      height: 44px;
   }
   ${down("md")} {
      width: 44px;
      height: 44px;
   }
`
export const DeleteBtn = styled(EmptyButton)`
   position: absolute;
   bottom: 0;
   right: 0;
   background: #df3737;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 4px 4px 0 0;
   border: none;
   &:hover,
   &:focus {
      opacity: 0.8;
      border: none;
      background: #df3737;
   }
   ${down("xs")} {
      width: 44px;
      height: 44px;
   }
   ${down("md")} {
      width: 44px;
      height: 44px;
   }
`

export const ImageFlex = styled(Flex)`
  flex-direction: row;
  align-items: "center"};
  flex-wrap: wrap;
  ${down("xs")} {
    >div:not(:first-child) {
      width: 100%;
    }
    flex-direction: column;
  }
  ${down("md")} {
    >div:not(:first-child) {
      width: 100%;
    }
    flex-direction: column;
  }
`

export const Buttons = styled(Flex)`
   display: inline-flex;
   gap: 10px;
   button {
      width: calc(50% - 5px);
      max-width: 120px;
   }
   ${down("xs")} {
      button {
         width: 100%;
         max-width: 100%;
      }
   }
`

export const PhoneUpload = styled.div`
   background-image: url(${UploadPhoneImage});
   width: 44px;
   height: 44px;
   margin-bottom: 10px;
`

export const UploadBtn = styled(Flex)`
   border: 1px dashed #828282;
   background-image: url(${UploadImage});
   height: 100%;
   width: 100%;
   background-repeat: no-repeat;
   background-position: 60% 40%;
   background-size: 60%;
   // ${down("xs")} {
   //   background-image: url(${UploadPhoneImage});
   //   width: 34px;
   //   height: 34px;
   //   background-size: 100%;
   // }
`

export const AssignButton = styled(Button)`
   color: #109cf1;
   text-decoration: underline;
   font-size: 16px;
   & > img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      margin-top: -2px;
   }
`

export const AlertBox = styled(Flex)`
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   padding: 16px 20px;
   // margin: 30px 18px 36px 0;
   & > span[role="img"] {
      font-size: 28px;
      color: #42c57a;
      margin-right: 12px;
   }
`

export const BoldSpan = styled.span`
   font-weight: bold;
`

export const NotificationComplete = styled.div`
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   padding: 20px;
   max-width: 520px;
   margin-bottom: 25px;
   h3 {
      font-weight: 900;
      font-size: 15px;
      color: #42c77b;
      text-transform: uppercase;
   }
   p {
      font-weight: 300;
      font-size: 18px;
      margin: 0;
   }
`
/**
 *
 */
export const Root = styled.div`
   position: relative;
   width: 100%;
`
export const baseButtonMixin = css`
   background: none;
   border: none;
   padding: 0;
`
export const ValueWrapper = styled.input`
   width: 100%;
   padding-left: 8px;
   padding-right: 32px;
   height: 32px;
   box-sizing: border-box;
   border-radius: 2px;
   border: 1px solid #d9d9d9;
   line-height: 32px;
`
export const AutoCompleteIcon = styled.span`
   position: absolute;
   top: 0;
   right: 0;
   height: 32px;
   width: 32px;
   transition: all 150ms linear;
   transform: ${(props: any) => (props.isOpen ? "rotate(0.5turn)" : "none")};
   transform-origin: center;
   display: flex;

   svg {
      margin: auto;
   }

   ${ValueWrapper}:focus + & {
      color: ${(props: any) => props.color || "109cf1"};
      fill: ${(props: any) => props.fill || "109cf1"};
   }
`
export const AutoCompleteContainer = styled.ul`
   background: #fff;
   padding: 8px 0;
   list-style-type: none;
   min-width: 100%;
   position: absolute;
   top: 100%;
   left: 0;
   border: 1px solid #b6c1ce;
   border-radius: 2px;
   margin: 0;
   box-sizing: border-box;
   max-height: 280px;
   overflow-y: auto;
   z-index: 10;
`
export const AutoCompleteItem = styled.li`
   padding: 0 24px;
   width: 100%;
   box-sizing: border-box;
   &:hover {
      background-color: #ebf4ff;
   }
`
export const AutoCompleteItemButton = styled.button`
   ${baseButtonMixin} width: 100%;
   line-height: 32px;
   text-align: left;
   &:active {
      outline: none;
      color: #0076f5;
   }
`
export const InputSearch = styled(ValueWrapper)`
   transition: border-color 150ms linear;

   &:focus {
      border-color: #109cf1;
      outline: none;

      + ${AutoCompleteIcon} {
         color: ${(props: any) => props.color || "109cf1"};
         fill: ${(props: any) => props.fill || "109cf1"};
      }
   }
`

export const StyledMenu = styled(Menu)`
   padding: 15px 16px;
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`
export const OptionButton = styled(EmptyButton)`
   margin-right: 25px;
   font-size: 18px;
   color: rgba(0, 0, 0, 0.54);
   &.ant-btn {
      background: none !important;
   }
   svg {
      max-width: 15px;
   }
`
interface ISideBarContainerProps {
   isFlex: boolean
   isHide: boolean
}
const Plate = styled.div`
   background: #fff;
   border: 1px solid #ededed;
   border-radius: 4px;
`
export const ActivitiesContainer = styled(Plate)<ISideBarContainerProps>`
   flex: ${(props) => (props.isFlex ? "1 1 40%" : "none")};
   height: ${(props) => (props.isHide ? "55px" : "100%")};
   ${down('xs')} {
      padding: 16px 12px;
   }
`
export const TotalContainer = styled(Plate)<ISideBarContainerProps>`
   flex: ${(props) => (props.isFlex ? "1 1 40%" : "none")};
   height: ${(props) => (props.isHide ? "55px" : "100%")};
   ${down('xs')} {
      padding: 16px 12px;
   }
`
export const MessagesContainer = styled(Plate)<ISideBarContainerProps>`
   flex: ${(props) => (props.isFlex ? "1 1 60%" : "none")};
   height: ${(props) => (props.isHide ? "55px" : "100%")};
   ${down('xs')} {
      padding: 16px 12px;
   }
`

export const PaddedContainer = styled.div`
   padding: 16px;
   ${down("xs")} {
      padding: 0px;
   }
`
export const AddJobButton = styled(Button)`
   float: right;
   color: #0496ff;
   font-size: 15px;
   font-weight: bold;
   text-decoration: underline;
   background: #FFFFFF;
   border: 1px solid #EDEDED;
   border-radius: 4px;
   width: 100%;
   >span {
      text-decoration-line: underline;
   }
`
export const TitleWorkOrder = styled(Flex)`
   position: relative;
`
export const WarningWorkOrder = styled(Flex)`
   position: absolute;
   top: 0;
   right: 0;
`
export const StyledCloseBtn = styled(Button)`
   position: absolute;
   right: 5px;
   top: 50%;
   margin-top: -12px;
   border: none;
   background: none;
   height: 24px;
   width: 24px;
   padding: 0;
`
export const WarningOnCreate = styled.div`
   position: absolute;
   top: 0;
   right: 0;
   padding: 10px 40px 10px 10px;
   background: #ef9899;
   border-radius: 4px;
   font-size: 13px;
   font-weight: bold;
`
