import React, {
  useEffect,
  useState,
  createRef,
  useLayoutEffect,
  useContext,
  useCallback,
} from "react";
import { useReactToPrint } from "react-to-print";
import { jsPDF } from "jspdf";
import styled from "styled-components";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Popconfirm,
  Tabs,
  Tooltip,
  Affix,
  Modal,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { EditIcon } from "../../assets/icons";
import { Api } from "../../api/api";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import PrintIcon from "../../assets/images/print_icon.svg";
import { LaborTab } from "./tabs/ServicesTab";
import { TimeClock } from "./tabs/TimeClock";
import { DateTimeTab } from "./tabs/DateTime";
import { PhotosTab } from "./tabs/Photos";

import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import {
  AssignButton,
  InfoContainer,
  OrderInfoHeader,
  OrderInfoWrapper,
  ResponsiveFlex,
  TabsWrapper,
} from "./styled";
import { TechnicianModal } from "../jobs/content/TechnicianModal";
import { SendEstimateModal } from "../jobs/SendEstimateModal";
import {
  EJobPriority,
  EJobProgress,
  IJob,
  IJobTechData,
  IJobTechnician,
  IUpdateJob,
  IUser,
  IWorkOrderWithJobs,
  ITimeClock,
} from "../../api/types";
import { TArgCallback } from "../../types";
import { SideContent } from "./components/SideContent";
import { ProgressSteps } from "./components/Steps";
import { JobProgress } from "../../constants";
import { useLoading, useQuery, useResponsive } from "../../utils/hooks";
import moment from "moment";
import Technician from "../../assets/images/Technician.svg";
import Schedule from "../../assets/images/Schedule.svg";
import { ApprovedAlert } from "./Alerts";
import { ScheduleModal } from "./ScheduleModal";
import { EmptyButton } from "../../ui/Button";
import { MessagesTab } from "../jobs/content/MessagesTab";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import {
  formatPriority,
  getErrors,
  renderAddress,
  renderFormDataSend,
} from "../../utils/utils";
import { StyledButton } from "../../containers/settings/SubscriptionCard";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import { track } from "../../utils/analytics";
import PrintEstimate from "./PrintDocuments/PrintJob";
import ModalPrintCheckboxes from "./PrintDocuments/ModalPrintCheckboxes";
import { SendScheduleModal } from "../jobs/SendScheduleModal";
import { CompleteModal } from "./CompleteModal/CompleteModal";
import { JobProgressModal } from "./JobProgressModal";
import PdfJob from "../jobs/pdfContent/PdfJob";
import logo from "../../assets/images/dockworks_logo.png";
import { WOContext } from "./WorkOrderContext";
import SaveWarningModal from "./SaveWarningModal"

function getCoords(elem: any) {
  let box = elem.getBoundingClientRect();
  return box.top + window.pageYOffset;
}

export const tabs = [
  {
    key: "1",
    title: "Service & Materials",
    component: LaborTab,
    disabled: false,
  },
  {
    key: "2",
    title: "Technicians & Scheduler",
    component: DateTimeTab,
    disabled: false,
  },
  { key: "3", title: "Photos & Notes", component: PhotosTab, disabled: false },
  { key: "4", title: "Time Clocks", component: TimeClock, disabled: false },
];

type TProps = {
  form: any;
  orderNumber: string;
  handleSaveWorkOrder: TArgCallback<any>;
};

const StyledMenu = styled(Menu)`
  li {
    white-space: normal;
  }
`;

const OptionButton = styled(EmptyButton)`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  height: 34px;
  margin-right: 12px;
  padding: 0 6px;
`;
const StyledInput = styled(Input)`
  &:disabled {
    background: #fff;
    color: #202020;
    border: none;
    font-size: 20px;
  }
`;
const SaveButton = styled(StyledButton)`
  margin: 0;
  width: 100px;
`;

export const JobForm: React.FC<TProps> = ({ form, orderNumber, handleSaveWorkOrder }) => {
  const [showMore, setShowMore] = useState<boolean>(true);
  // const [form] = Form.useForm<IUpdateJob>()
  const [estimate, setEstimate] = useState<IJob | null>(null);
  const [saving, onSave] = useLoading();
  const [saved, setSaved] = useState<boolean>(false);
  const [technician, setTechnician] = useState<IUser | null>(null);
  const [schedule, setSchedule] = useState<string>("");
  const [showTechModule, setShowTechModule] = useState<boolean>(false);
  const [timeModalVisible, setShowTimeModal] = useState<boolean>(false);
  const [showEstimateModal, setShowEstimateModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [estimateDuration, setEstimateDuration] = useState<string | null>(null);
  const [priority, setPriority] = useState<string | null>(null);
  const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>("");
  const [warningVisible, setWarningVisible] = useState(false);

  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));
  const isLg = useBreakpoint(down("lg"));
  let query = useQuery();

  const [isFinalized, setIsFinalized] = useState(false);
  const profile = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (orderNumber) {
      setJobTitle(`${orderNumber}-001`);
    }
  }, [orderNumber]);

  const handleScroll = () => {
    // if (getCoords(document.getElementById(String(job.id))) + 150 <= window.scrollY + window.innerHeight) {
    //    setShowControls(true)
    // } else if (getCoords(document.getElementById(String(job.id))) + 150 > window.scrollY + window.innerHeight) {
    //    setShowControls(false)
    // }
  };

  useEffect(() => {
    if (isLg && !isXs) {
      const block = document.getElementById("main_block");
      block?.addEventListener("scroll", handleScroll);
      return () => block?.removeEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isXs, isLg]);

  const calculateDuration = (d: string | null): moment.Moment | null => {
    if (!d) {
      return null;
    }
    // TODO: Calc days?
    const parts = d.split(" ");
    const time = parts[parts.length - 1].split(":");
    return moment().hour(Number(time[0])).minute(Number(time[1])).second(0);
  };

  const getActiveStep = (): number => {
    return 1;
  };

  const updateComponent = async () => {
    const { data } = await Api.jobs.get(estimate?.id || 0);
    setEstimate(data);
  };

  const formatDuration = (data: moment.Moment) =>
    `${data.hours() >= 10 ? data.hours() : `0${data.hours()}`}:${
      data.minutes() >= 10 ? data.minutes() : `0${data.minutes()}`
    }:00`;

  const updateSidebarData = (data: IUpdateJob) => {
    if (data.estimated_duration) {
      setEstimateDuration(formatDuration(moment(data.estimated_duration)));
    }
    if (data.priority) {
      setPriority(data.priority);
    }
  };

  const assignTechnician = (value: IUser) => {
    setTechnician(value);
    setShowTechModule(false);
    notification.success({
      message: "Technician assigned",
    });
  };

  const updateJobTitle = async (e: any) => {
    const newTitle = e.target.value;

    setJobTitle(newTitle);
    setIsTitleEditing(false);
  };
  //console.log("form", form.getFieldsValue())

   const handleTabChange = useCallback((key: string) => {
      setActiveTab(key)
      if (key === "1") return;
      setWarningVisible(true)
   }, [setActiveTab, setWarningVisible])

   const warningModalOk = useCallback(() => {
      setWarningVisible(false)
      handleSaveWorkOrder(`tab_${activeTab}`)
   }, [setWarningVisible, handleSaveWorkOrder, activeTab])

   const warningModalCancel = useCallback(() => {
      setWarningVisible(false)
   }, [setWarningVisible])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={() => form.getFieldsValue()}
        requiredMark={false}
      >
        <OrderInfoWrapper isRecurring={false} mt={"24px"}>
          <OrderInfoHeader style={{ flexDirection: "row" }}>
            <Flex alignItems="center" width="100%">
              <Form.Item name="title" style={{ marginBottom: 0 }}>
                {isTitleEditing ? (
                  <Input
                    style={{ fontSize: 20 }}
                    defaultValue={jobTitle}
                    onPressEnter={updateJobTitle}
                    onBlur={updateJobTitle}
                  />
                ) : (
                  <Tooltip title={jobTitle}>
                    <Flex maxWidth={"100%"} style={{ overflow: "hidden" }}>
                      <Msg fontWeight={900} fontSize={20}>
                        {jobTitle}
                      </Msg>
                    </Flex>
                  </Tooltip>
                )}
              </Form.Item>

              <Button
                type="text"
                onClick={() => {
                  setIsTitleEditing((prev) => !prev);
                }}
                style={{ margin: "0 0 0 10px" }}
              >
                <EditIcon verticalAlign="middle" />
              </Button>
            </Flex>
            <Flex justifyContent="flex-end" alignItems="center" width="100%">
              <Flex flexDirection="column" alignItems="center">
                <Msg
                  color="#0F497B"
                  fontSize={20}
                  fontWeight={800}
                  margin="0 8px 0 auto"
                >
                  $0.00
                </Msg>
                <Msg color="#8B8B8B" fontSize={15}>
                  Subjob {orderNumber}-001
                </Msg>
              </Flex>
              <Button type="text" onClick={() => setShowMore(!showMore)}>
                {!showMore ? <DownOutlined /> : <UpOutlined />}
              </Button>
            </Flex>
          </OrderInfoHeader>
          {showMore && (
            <Flex flexDirection="column">
              <InfoContainer>
                <TabsWrapper>
                  <ProgressSteps activeStep={getActiveStep()} />
                  <ApprovedAlert
                    activeStep={getActiveStep()}
                    isApproved={estimate?.is_approved}
                    isFinalized={isFinalized}
                  />
                  <Tabs
                    id={`new_job_tabs`}
                    onChange={handleTabChange}
                    activeKey={"1"}
                  >
                    {tabs.map((t) => {
                      const C = t.component;

                      return (
                        <Tabs.TabPane
                          key={t.key}
                          tab={t.title}
                          disabled={t.disabled}
                        >
                          <C
                            form={form}
                            job={null}
                            updateInfo={() => {}}
                            technicianRole={false}
                            setIsBooked={false}
                            workOrder={null}
                            updateJobById={() => {}}
                            setIsFormFieldChanged={undefined}
                          />
                        </Tabs.TabPane>
                      );
                    })}
                  </Tabs>
                </TabsWrapper>
                <SideContent
                  form={form}
                  job={null}
                  priority={priority}
                  estimateDuration={0}
                  technicianRole={false}
                  isRecurring={false}
                />
              </InfoContainer>
            </Flex>
          )}
        </OrderInfoWrapper>
      </Form>
      <SaveWarningModal
         visible={warningVisible}
         onOk={warningModalOk}
         onCancel={warningModalCancel}
      />
    </>
  );
};
