import styled from "styled-components"
import Flex from "../../noui/Flex"
import { EmptyButton, PrimaryButton } from "../../ui/Button"

const ImageContainer = styled(Flex)`
   margin: auto;
   width: 60%;
   justify-content: center;
   align-items: center;
   img {
      max-width: 95%;
   }
   @media (max-width: 800px) {
      width: 50%;
   }
   @media (max-width: 700px) {
      width: 100%;
      justify-content: center;
   }
`
const SlideContainer = styled(Flex)`
   min-height: 510px;
   @media (max-width: 700px) {
      flex-direction: column;
      position: relative;
   }
`
const SlideContainerBtns = styled(Flex)`
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   @media (max-width: 700px) {
      display: none;
   }
`
const SlideContainerBtnsMobile = styled(Flex)`
   width: 100%;
   width: 100%;
   max-width: 250px;
   margin-top: 15px;
   margin-bottom: 15px;
   @media (min-width: 701px) {
      display: none;
   }
`
const SlideContainerText = styled(Flex)`
   position: relative;
   min-height: 500px;
   width: 40%;
   @media (max-width: 800px) {
      width: 50%;
   }
   @media (max-width: 700px) {
      width: 100%;
      min-height: 100%;
      margin-bottom: 20px;
   }
`
const TextInner = styled(Flex)`
   height: 100%;
   min-height: 450px;
   @media (max-width: 700px) {
      min-height: 100%;
   }
`

const NextButton = styled(PrimaryButton)`
   width: 165px;
   height: 40px;
   background: #0496ff;
   margin-top: 0;
   &:hover {
      border: 0.7px solid #0496ff;
      color: #0496ff;
   }
   @media (max-width: 900px) {
      width: 130px;
   }
   @media (max-width: 500px) {
      width: 165px;
   }
`

const SkipButton = styled(EmptyButton)`
   color: #404040;
   width: fit-content;
   height: 40px;
   font-weight: 700;
   font-size: 15px;
   margin-top: 0;
   &:hover {
      color: #109cf1;
   }
`

const SingleCard = ({ next, slideData, onClose }) => {
   return (
      <SlideContainer width="100%" justifyContent="space-between" alignItems="center">
         <SlideContainerText flexDirection="column" p="0px 20px" justifyContent="space-between">
            <TextInner justifyContent="center" alignItems="center">
               <Flex flexDirection="column">
                  {slideData.title}
                  {slideData.description}
               </Flex>
            </TextInner>
            <SlideContainerBtns width="100%" justifyContent="space-between" alignItems="flex-end" mt="30px">
               <NextButton onClick={next}>Next</NextButton>
               <SkipButton onClick={onClose}>Skip</SkipButton>
            </SlideContainerBtns>
         </SlideContainerText>
         <ImageContainer>{slideData.image}</ImageContainer>
         <SlideContainerBtnsMobile width="100%" justifyContent="space-between" alignItems="flex-end" mt="30px">
            <SkipButton onClick={onClose}>Skip</SkipButton>
            <NextButton onClick={next}>Next</NextButton>
         </SlideContainerBtnsMobile>
      </SlideContainer>
   )
}

export default SingleCard
