import { Progress } from "antd"
import React from "react"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { IStats } from "../../../api/types"
import { formatToUSD } from "../../../utils/utils"

type TOption = {
   label: string
   key: keyof Omit<IStats, "top_customers">
}
const options: TOption[] = [
   { label: "Labor", key: "revenue_labor" },
   { label: "Parts", key: "revenue_parts" },
   { label: "Recurring services", key: "revenue_recurring" },
   { label: "Other services", key: "revenue_other" },
]

type TProps = {
   statsData: IStats | null
}
const Revenue: React.FC<TProps> = ({ statsData }) => {
   const total: number = statsData
      ? Object.entries(statsData).reduce((acc, [k, v]) => {
           if (k.startsWith("revenue_")) {
              return acc + (v as number)
           }
           return acc
        }, 0)
      : 0
   return (
      <Flex className="little-cart" width="195px" p="10.17px" flexDirection="column">
         <Msg fontWeight={400} fontSize={13} color="#494949" mb="15px">
            Revenue
         </Msg>
         <Flex flexDirection="column" width="100%">
            {options.map((o) => {
               const revenue = statsData ? statsData[o.key] : 0
               return (
                  <React.Fragment key={o.label}>
                     <Flex justifyContent="space-between">
                        <Msg fontWeight={400} fontSize={12} color="#151F2B">
                           {o.label}
                        </Msg>
                        <Msg fontWeight={900} fontSize={11} color="#151F2B">{formatToUSD(revenue)}</Msg>
                     </Flex>
                     <Progress
                        percent={total ? (revenue / total) * 100 : 0}
                        strokeColor="#44CD7F"
                        className="progress"
                        size="small"
                        format={() => ""}
                     />
                  </React.Fragment>
               )
            })}
         </Flex>
      </Flex>
   )
}

export default Revenue
