import { Modal } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Api } from "../../api/api";
import { DefaultDateTimeFormat } from "../../constants";
import Flex from "../../noui/Flex";
import { TCallback } from "../../types";
import { capitalize, formatToUSD } from "../../utils/utils";

const Wrapper = styled(Flex)`
    .ant-table-content {
        &::-webkit-scrollbar {
           width: 4px;
           height: 4px;
        }
    
        &::-webkit-scrollbar-track {
           background: #fbfbff;
           border-radius: 8px;
           border: 1px solid #ededed;
        }
    
        &::-webkit-scrollbar-thumb {
           background: #0496ff;
           border-radius: 8px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
           background: #40a9ff;
        }
        thead > tr:first-child th:last-child, tr td:last-child {
            min-width: 275px !important;
        }
    }
`

type TBillingColumns = {
    date: Date,
    id: string
    amount: number
    status: string
    currency: string
}

const columns: ColumnsType<TBillingColumns> = [
    {
       title: "Date",
       dataIndex: "date",
       key: "date",
       render: (_, record) => moment(record.date).format(DefaultDateTimeFormat),
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (_, record) => formatToUSD(record.amount / 100) + " " + record.currency?.toLocaleUpperCase(),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, record) => capitalize(record.status),
    },
    {
       title: "Transaction ID",
       dataIndex: "id",
       key: "id",
       render: (_, record) => record.id,
    }, 
]

type TBillingHistoryProps = {
    visible: boolean;
    workOrderId?: number | null
    onClose: TCallback
}

const BillingHistory: React.FC<TBillingHistoryProps> = ({visible, workOrderId, onClose}) => {
    const [data, setData] = useState<any|null>(null)
    const formattedData = useMemo(() => {
        if (!data || !data?.data?.length) return [];
        return data.data.map((d: any) => ({date: new Date(d.created * 1000), id: d.id, amount: d.amount_due, status: d.status, currency: d.currency}))
    }, [data])    

    const loadData = async () => {
        if (!workOrderId) return;
        const {data: _data} = await Api.workOrders.billingHistory(workOrderId)
        console.log(_data)
        setData(_data)
    }

    useEffect(() => {
        loadData();
    }, [workOrderId])

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={onClose}
            onOk={onClose}
            title="Billing History"
            width={800}
        >
            <Wrapper>
                <Table
                    className="contained"
                    locale={{ emptyText: "No payments were found" }}
                    dataSource={formattedData}
                    columns={columns}
                    scroll={{ x: 750 }}
                />
            </Wrapper>
        </Modal>
    );
}

export default BillingHistory;