import React from "react"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { ICustomerStats, IStats } from "../../../api/types"
import { formatToUSD } from "../../../utils/utils"

type TProps = {
   statsData: IStats | null
}
const TopCustomers: React.FC<TProps> = ({ statsData }) => {
   const { top_customers } = statsData || { top_customers: [] as ICustomerStats[] }

   return (
      <Flex className="little-cart" width="195px" p="10.17px" flexDirection="column">
         {" "}
         <Msg fontWeight={400} fontSize={13} color="#494949" mb="15px">
            Top Customers
         </Msg>
         <Flex flexDirection="column" width="100%">
            {top_customers.map((customer) => {
               return (
                  <React.Fragment key={customer.id}>
                     <Msg fontWeight={400} fontSize={12} color="#151F2B">
                        {customer.full_name}
                     </Msg>
                     <Flex justifyContent="space-between" mb="15px">
                        <Msg fontWeight={400} fontSize={12} color="#42C57A">
                           {customer.jobs} jobs
                        </Msg>
                        <Msg fontWeight={900} fontSize={13} color="#42C57A">
                           {formatToUSD(customer.paid)}
                        </Msg>
                     </Flex>
                  </React.Fragment>
               )
            })}
         </Flex>
      </Flex>
   )
}

export default TopCustomers
