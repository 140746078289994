import React, { forwardRef, useEffect, useState, useCallback } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { renderAddress, mapOptions, formatToUSD } from "../../../utils/utils"
import { servicesUnitTypes, vesselTypes } from "../../../constants"
import { IUser, EJobPriority, ERecurringStatus, EJobProgress, IPaymentRecord } from "../../../api/types"
import { TOption } from "../../../types"
import Flex from "../../../noui/Flex"
import { Api } from "../../../api/api"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import Photos from "./Photos"
import logo from "../../../assets/images/dockworks_logo.png"
import parse from "html-react-parser";

const Wrapper = styled.div`
   background: #fff;
   table {
      width: 100%;
   }
   @page {
      margin: 30px 30px 50px 30px;
   }

   @media all {
      .pagebreak {
         display: none;
      }
   }

   @media print {
      .pagebreak {
         page-break-before: always;
      }
      .noBreak {
         page-break-inside: avoid;
      }
      .topHeader {
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         height: 155px;
         overflow: hidden;
      }
   }
`
const Header = styled(Flex)`
   padding: 20px 0;

   p {
      font-size: 12px;
      margin-bottom: 0px;
      span {
         font-weight: bold;
      }
   }

   h3 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 5x;
   }
   h4 {
      font-weight: 900;
      font-size: 13px;
      margin-bottom: 3px;
   }
   div {
      // width: 100%;
   }
   img {
      max-width: 150px;
      max-height: 90px;
      width: 100%;
      height: auto;
      object-fit: contain;
   }
`
const Info = styled(Flex)`
   gap: 30px;
   .infoBlock {
      width: 100%;
   }
   h4 {
      font-weight: bold;
      font-size: 13px;
      text-align: center;
   }
`
const HeaderInfo = styled.div`
   margin-left: 25px;
`
const WOInfo = styled(Flex)`
   width: 100%;
   padding: 7px 15px;
   color: #fff;
   background: #0f497b;
   h3 {
      font-weight: bold;
      font-size: 13px;
      color: #fff;
      margin: 0;
   }
   p {
      font-weight: 600;
      font-size: 12px;
      margin: 0;
   }
`
const WOTitle = styled(Flex)`
   width: 100%;
   padding: 15px;
   h3 {
      font-weight: 900;
      font-size: 13px;
      color: #202020;
      margin: 0;
   }
   p {
      font-weight: 900;
      font-size: 12px;
      margin: 0;
      span {
         color: #8b8b8b;
         font-weight: normal;
      }
   }
`
const EmptyHeader = styled.div`
   width: 100%;
   height: 130px;
   margin-bottom: 20px;
`
const Details = styled.div`
   margin: 24px 0;
   font-size: 12px;
   h4 {
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      text-align: left;
      text-decoration: underline;
   }
   ul {
      margin: 0 0 15px 0;
      padding-left: 25px;
      &.noListType {
         list-style-type: none;
         padding-left: 0;
         li {
            span {
               margin-right: 10px;
            }
         }
      }
      li {
         font-weight: bold;
         span {
            font-weight: normal;
         }
      }
   }
   h5 {
      color: #8b8b8b;
      font-weight: normal;
      font-size: 12px;
   }
`
const Customer = styled.div`
   width: 100%;

   min-width: 300px;
`
const CustomerInfo = styled(Flex)`
   width: 100%;
   justify-content: space-between;
   font-size: 12px;
   > div:first-child {
      width: 200px;
   }
   .title {
      font-weight: bold;
   }
   &.leftAligned {
      justify-content: flex-start;
      > div:first-child {
         width: 130px;
      }
   }
`
const Notes = styled.div`
   border: 1px solid #ededed;
   font-size: 12px;
   margin: 20px 0;
   h4 {
      background: #ededed;
      padding: 10px;
      font-weight: bold;
      font-size: 11px;
      margin: 0;
   }
   div {
      min-height: 60px;
      padding: 10px;
      background: #fbfbff;
   }
`
const JobTitle = styled(Flex)`
   margin-top: 20px;
   padding: 10px;
   border-bottom: 1px solid #ededed;
   h3 {
      font-weight: 900;
      font-size: 15px;
      text-transform: uppercase;
      margin: 0;
   }
   p {
      color: #494949;
      font-size: 13px;
      margin: 0;
   }
`
const JobInfo = styled(Flex)`
   margin-bottom: 20px;
   padding: 10px;
   font-size: 13px;
   span {
      font-weight: bold;
   }
   p {
      margin: 0;
   }
`
const JobSummary = styled.div`
   h4 {
      font-weight: bold;
      font-size: 13px;
   }

   p {
      font-size: 12px;
      margin-bottom: 5px;
      span {
         font-weight: bold;
      }
   }
`
const Payments = styled.div`
   padding: 10px;
   border: 1px solid #ededed;
   margin-top: 25px;
`
const PaymentStatus = styled.div`
   border: 1px solid #ededed;
   margin-top: 25px;
   h3 {
      background: #0f497b;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
      margin: 0;
      padding: 7px;
   }
   .paymentBlock {
      width: 50%;
      padding: 10px;
      p {
         font-size: 12px;
         margin-bottom: 10px;
       
      }
   }
   .divider {
      width: 100%;
      height: 1px;
      background: #ededed;
      margin: 5px 0;
   }
`
const VesselHeader = styled.div`
   padding: 10px;
   background: #ededed;
`
const VesselBottom = styled.div`
   padding: 10px;
`
const VesselImg = styled.div`
   width: 100%;
   height: 135px;
   overflow: hidden;
   border-radius: 4px;
   margin-top: 10px;
   img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
   }
`
const Total = styled.div`
   text-align: right;
   h3 {
      font-weight: bold;
      font-size: 15px;
   }

   p {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
      color: #494949;
   }
`
const Table = styled.div`
   margin: 10px 0;
   box-sizing: border-box;
`
const TableItem = styled.div`
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
`
const Services = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 3fr 1fr 1.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 1fr 1.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Materials = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 4fr 1fr 1.5fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 2.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .description {
      padding: 8px;
      font-size: 12px;
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Technician = styled.div`
   background: #fbfbff;
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
   padding-bottom: 4px;
   & .grid {
      display: grid;
      grid-template-columns: 3fr 2fr 1.5fr 1fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      &.tech {
         grid-template-columns: 3fr 2fr 1.5fr 1fr;
      }

      > div {
         padding: 4px 8px;
      }
      &.header {
         text-decoration: underline;
         background: #fbfbff;
         font-weight: normal;
      }
   }
`
const Scheduler = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 5.5fr 1.75fr 1.75fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Summary = styled(Flex)`
   justify-content: space-between;
   align-items: flex-start;
   .disclaimers {
      margin-right: 10px;
      h3 {
         text-decoration: underline;
      }
   }
   .summary {
      min-width: 220px;
      padding: 4px 10px;
      border: 1px solid #ededed;
      background: #fbfbff;
      div {
         padding: 3px 0;
      }
   }

   //float: right;
   font-size: 12px;

   & .total {
      font-weight: bold;
   }
   & .discount {
      color: #42c77b;
      font-weight: 700;
   }
`
const JobTechnicians = styled.div`
   padding: 0 10px;
   margin-bottom: 30px;
   h3 {
      font-weight: 700;
      font-size: 12px;
      text-decoration: underline;
   }
   p {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 3px;
   }
`

type Props = {
   workOrder: any
   jobs: any
   userData: any
   technicianRole: boolean
   woPricing: boolean
   woPhotos: boolean
   vesselPhoto: boolean
   beforeAfterPhotos: boolean
   isInvoice?: boolean
   isRecurring: boolean
   recurringPrintPeriod?: any
}

const scheduleOptions: TOption[] = [
   { label: "week", value: "W" },
   { label: "month", value: "M" },
   { label: "year", value: "Y" },
]

const billOptions: TOption[] = [
   { label: "Monthly", value: "M" },
   { label: "Annual", value: "A" },
]

type TDoWOption = {
   label: string
   value: number
}

const dowOptions: TDoWOption[] = [
   { label: "Mon", value: 0 },
   { label: "Tue", value: 1 },
   { label: "Wed", value: 2 },
   { label: "Thu", value: 3 },
   { label: "Fri", value: 4 },
   { label: "Sat", value: 5 },
   { label: "Sun", value: 6 },
]

export type Ref = HTMLDivElement

const PrintWorkOrder = forwardRef<Ref, Props>(
   (
      {
         workOrder,
         jobs,
         userData,
         technicianRole,
         woPricing,
         woPhotos,
         vesselPhoto,
         beforeAfterPhotos,
         isInvoice,
         isRecurring,
         recurringPrintPeriod,
      },
      ref
   ) => {
      const [options, setOptions] = useState<IUser[]>([])
      const [vesselImg, setVesselImg] = useState<any>(null)
      const [recurringCalculations, setRecurringCalculations] = useState<any>(null)
      const [payments, setPayments] = useState<IPaymentRecord[]>([])
      const invoiceFooter = userData?.company?.invoice_settings?.invoice_footer as string
      const estimateFooter = userData?.company?.invoice_settings?.estimate_footer as string
      const paymentStatusWO = userData?.company?.invoice_settings?.payment_status_wo as string
      const paymentStatusSP = userData?.company?.invoice_settings?.payment_status_sp as string
      const user = useSelector(getCurrentUser)

      useEffect(() => {
         if (!options) {
            Api.user.getAll({}).then(({ data: d }) => {
               const results = d as IUser[]

               setOptions(results)
            })
         }
      }, [])

      useEffect(() => {
         if (isRecurring) {
            Api.workOrders
               .getRecurringCalculations({
                  ...workOrder.recurring_config,
                  id: workOrder.id,
               })
               .then(({ data }) => {
                  setRecurringCalculations(data)
               })
         }
      }, [isRecurring])

      useEffect(() => {
         if (workOrder && workOrder.vessel) {
            Api.vessels.get(workOrder?.vessel.id).then(({ data }) => {
               setVesselImg(data.image)
            })
         }
      }, [workOrder])

      const getFullPriority = (priority: string | null | EJobPriority | undefined) => {
         switch (priority) {
            case EJobPriority.Low:
               return "Low"
            case EJobPriority.Medium:
               return "Medium"
            case EJobPriority.High:
               return "High"
            case EJobPriority.Urgent:
               return "Urgent"
            default:
               return null
         }
      }

      const calculateRows = (data: any): number => {
         const minutesToHours = moment.duration(data.estimated_hours).asHours()
         const num = (data.rate ?? 0) * minutesToHours
         return +num.toFixed(3)
      }

      const formatPrice = (price: number | undefined) =>
         price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

      const renderPeriod = (value: string) => {
         let period = scheduleOptions.find((item) => item.value === value)
         return period ? period.label : ""
      }

      const calculateTotal = (data: any) => {
         let sum: any = null
         data.forEach((i: any) => {
            sum += i.unit_price * i.qty
         })
         return sum
      }

      const showDays = (data: any) => {
         let str: any[] = []
         data.forEach((i: any) => {
            const findDay = dowOptions.find((item) => item.value === i)
            if (findDay) {
               str.push(findDay.label)
            }
         })
         return str.join(", ")
      }

      const renderRecurringJobStatus = (progress: any, schedules: any, completed: any) => {
         const start = schedules?.[0]?.start

         if (progress && start) {
            const start_date = moment(start)
            const today_date = moment()
            if (progress === EJobProgress.Complete) {
               if (completed) {
                  return <p>Completed at {moment(completed).format("MM/DD/yy")}</p>
               }
            } else if (progress === EJobProgress.Work || progress === EJobProgress.UnFinished) {
               return <p>In progress</p>
            } else if (start_date.isSameOrBefore(today_date, "days") && progress === EJobProgress.Start) {
               return <p>Need to complete</p>
            } else if (start_date.isAfter(today_date, "days")) {
               return <p>Upcoming at {start_date.format("MM/DD/yy")}</p>
            }
         }
         return null
      }

      const renderJobsRecurring = () => {
         let jobsArray: any[] = []
         if (isRecurring && recurringPrintPeriod && recurringPrintPeriod.from && recurringPrintPeriod.to) {
            if (jobs && jobs.length > 0) {
               jobs.forEach((job: { schedules: { start: moment.MomentInput }[] }) => {
                  const start = moment(job.schedules[0].start).format("L")
                  const from = moment(recurringPrintPeriod.from).format("L")
                  const to = moment(recurringPrintPeriod.to).format("L")

                  if ((start > from || start === from) && (start < to || start === to)) {
                     jobsArray.push(job)
                  }
               })
            }

            return jobsArray
         }
         return jobs
      }

      const loadCards = useCallback(async () => {
         if (workOrder && workOrder.id && !payments) {
            try {
               const { data } = await Api.paymentRecords.getAll({ work_order: workOrder.id })
               setPayments(data as IPaymentRecord[])
            } catch (e) {
               console.log("error")
            }
         }
      }, [workOrder])

      const getDiscountedTotal = () => {
         if (!isRecurring) return 0;
         const _total = workOrder?.total ?? 1;
         const discount = workOrder?.recurring_config?.discount ?? 0;
         return _total / 100 * discount;
      }

      useEffect(() => {
         loadCards()
      }, [loadCards, workOrder])

      const formatPeriod = (period?: string) => {
         switch (period) {
            case "W":
               return "week"
            case "M":
               return "month"
            default:
               return ""
         }
      }

      const technicianName = options.find((t) => Number(t.id) === workOrder?.recurring_config?.technician)

      const checkDepositUnpaid = () => !isInvoice && !!workOrder.required_deposit_amount && !workOrder.deposit_paid_at

      //console.log("wo", workOrder)
      //console.log("payments", payments)

      return (
         <Wrapper ref={ref}>
            <Header alignItems="center" className="topHeader">
               <Flex justifyContent="center">
                  <div style={{ width: "150px", height: "90px" }}>
                     <img src={user?.company?.logo ? user.company.logo : logo} alt="logo" />
                  </div>
               </Flex>
               <HeaderInfo>
                  <Flex>
                     <Flex flexDirection="column">
                        <h3>COMPANY</h3>
                        <h4>{userData?.company?.name}</h4>
                        <p>
                           {userData?.company?.address ? renderAddress(userData?.company?.address) : "No data location"}
                        </p>
                        <p>{userData?.company?.phone}</p>
                        <p style={{ color: "#0496FF" }}>{userData?.company?.email}</p>
                     </Flex>

                     <Flex alignItems="flex-start" flexDirection="column" marginLeft="55px">
                        <h3>BILLING CONTACT</h3>
                        <h4>{workOrder?.customer?.full_name}</h4>
                        <p> {workOrder?.customer?.address && renderAddress(workOrder?.customer?.address)}</p>
                        <p>
                           {workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")
                              ?.phone || "No data"}
                        </p>
                        <p style={{ color: "#0496FF" }}>{workOrder?.customer?.email}</p>
                     </Flex>
                  </Flex>
               </HeaderInfo>
            </Header>
            <table>
               <thead>
                  <tr>
                     <th>
                        <EmptyHeader />
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        <div className="content">
                           <WOInfo alignItems="center" justifyContent="space-between">
                              <h3>{isRecurring ? "SERVICE PLAN ESTIMATE" : "WORK ORDER"}</h3>
                              <p>Sent: {moment(workOrder?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p>
                           </WOInfo>
                           <WOTitle alignItems="center" justifyContent="space-between">
                              <h3>{workOrder?.title}</h3>
                              <p>
                                 <span>Work order:</span> #{workOrder?.order_number}
                              </p>
                           </WOTitle>

                           <Info justifyContent="normal">
                              <div className="infoBlock">
                                 <Customer>
                                    <CustomerInfo className="leftAligned">
                                       <div>Service Writer: </div>
                                       <div className="title">{workOrder?.created_by?.full_name}</div>
                                    </CustomerInfo>
                                    <CustomerInfo className="leftAligned">
                                       <div>Created on: </div>
                                       <div className="title">
                                          {workOrder?.created
                                             ? moment(workOrder.created).format("ddd, M/D/YYYY h:mm A")
                                             : "No data"}
                                       </div>
                                    </CustomerInfo>
                                 </Customer>
                                 <Details>
                                    <h4>Project Details</h4>
                                    <div>{workOrder?.notes || "No notes provided"}</div>
                                 </Details>
                                 <Details>
                                    <h4>SCOPE OF WORK</h4>
                                    <div>
                                       {!isRecurring ? (
                                          jobs &&
                                          jobs.length > 0 && (
                                             <ul>
                                                {jobs.map((job: any) => {
                                                   return (
                                                      <li key={job?.id}>
                                                         {job.title} <span>({job.order_number})</span>
                                                      </li>
                                                   )
                                                })}
                                             </ul>
                                          )
                                       ) : (
                                          <p>{workOrder?.recurring_config?.title}</p>
                                       )}
                                    </div>
                                    <h5>(Full project summary listed below)</h5>
                                 </Details>

                                 {!isRecurring ? (
                                    <Customer>
                                       <CustomerInfo className="leftAligned">
                                          <div>Due Date: </div>
                                          <div className="title">
                                             {moment(workOrder?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                                          </div>
                                       </CustomerInfo>
                                       <CustomerInfo className="leftAligned">
                                          <div>Est. Duration: </div>
                                          <div className="title">{workOrder?.estimated_duration ?? "TBD"}</div>
                                       </CustomerInfo>
                                    </Customer>
                                 ) : workOrder.recurring_status === ERecurringStatus.Approved ? (
                                    <>
                                       <Details>
                                          <h4>BILLING CYCLE</h4>
                                          <div>
                                             <ul className="noListType">
                                                {payments && payments.length > 0 ? (
                                                   payments.map((item, idx) => {
                                                      return (
                                                         <li key={item.id}>
                                                            <span>
                                                               {item.work_order.order_number}-
                                                               {(idx + 1).toString().padStart(3, "0")}:
                                                            </span>{" "}
                                                            {moment(item.date).format("M/D/YYYY h:mm A")}
                                                         </li>
                                                      )
                                                   })
                                                ) : (
                                                   <p>No data</p>
                                                )}
                                             </ul>
                                          </div>
                                       </Details>
                                    </>
                                 ) : (
                                    <>
                                       <Details>
                                          <h4>SCHEDULE</h4>
                                       </Details>

                                       <Customer>
                                          <CustomerInfo className="leftAligned">
                                             <div>Start Date: </div>
                                             <div className="title">
                                                {workOrder?.recurring_config?.first_appointment_date &&
                                                workOrder?.recurring_config?.start_time
                                                   ? `${moment(
                                                        `${workOrder?.recurring_config?.first_appointment_date} ${workOrder?.recurring_config?.start_time}`
                                                     ).format("M/D/YYYY h:mm A")}`
                                                   : "No data"}
                                             </div>
                                          </CustomerInfo>
                                          <CustomerInfo className="leftAligned">
                                             <div>End Date: </div>
                                             <div className="title">
                                                {workOrder?.recurring_config?.times_per_period &&
                                                workOrder?.recurring_config?.end_time
                                                   ? `${moment(
                                                        `${workOrder?.recurring_config?.final_appointment_date} ${workOrder?.recurring_config?.end_time}`
                                                     ).format("M/D/YYYY h:mm A")}`
                                                   : "No data"}
                                             </div>
                                          </CustomerInfo>
                                          <CustomerInfo className="leftAligned">
                                             <div>Job Frequency: </div>
                                             <div className="title">
                                                {workOrder?.recurring_config?.times_per_period &&
                                                workOrder?.recurring_config?.schedule_period
                                                   ? `${workOrder?.recurring_config?.times_per_period}/${renderPeriod(
                                                        workOrder.recurring_config.schedule_period
                                                     )}`
                                                   : "No data"}
                                             </div>
                                          </CustomerInfo>
                                          <CustomerInfo className="leftAligned">
                                             <div># of Jobs (total): </div>
                                             <div className="title">
                                                {recurringCalculations ? recurringCalculations?.repeats : "No data"}
                                             </div>
                                          </CustomerInfo>
                                          <CustomerInfo className="leftAligned">
                                             <div>Billing Frequency: </div>
                                             <div className="title">
                                                {workOrder?.recurring_config?.billing
                                                   ? billOptions.find(
                                                        (item) => item.value === workOrder.recurring_config.billing
                                                     )?.label
                                                   : "No data"}
                                             </div>
                                          </CustomerInfo>
                                       </Customer>
                                    </>
                                 )}
                              </div>
                              <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
                                 <VesselHeader>
                                    <CustomerInfo>
                                       <div>Vessel:</div>
                                       <div className="title" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                          {workOrder?.vessel?.name}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Hull ID:</div>
                                       <div className="title"> {workOrder?.vessel?.hin}</div>
                                    </CustomerInfo>
                                 </VesselHeader>
                                 <VesselBottom>
                                    <CustomerInfo>
                                       <div>Vessel Type:</div>
                                       <div className="title">
                                          {vesselTypes.find((v) => v.value === workOrder?.vessel?.vessel_type)?.label ||
                                             "No data"}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Location:</div>
                                       <div className="title">{workOrder?.vessel?.location_name}</div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Address:</div>
                                       <div className="title">
                                          {workOrder?.vessel?.address
                                             ? renderAddress(workOrder?.vessel?.address)
                                             : "No data location"}
                                       </div>
                                    </CustomerInfo>
                                    <CustomerInfo>
                                       <div>Slip #:</div>
                                       <div className="title">{workOrder?.vessel?.slip_number}</div>
                                    </CustomerInfo>
                                    {vesselPhoto && vesselImg && (
                                       <VesselImg>{vesselImg && <img src={vesselImg} alt="vessel" />}</VesselImg>
                                    )}
                                 </VesselBottom>
                              </div>
                           </Info>
                           {woPricing && !technicianRole && (
                              <PaymentStatus>
                                 <h3>{isRecurring ? "PLAN RATES" : "PAYMENT STATUS"}</h3>
                                 <Flex>
                                    <div className="paymentBlock">
                                       {isRecurring ? (
                                          <p>{parse(paymentStatusSP)}</p>
                                       ) : (
                                          <p>{parse(paymentStatusWO)}</p>
                                       )}
                                    </div>
                                    <div className="paymentBlock">
                                       {!isRecurring ? (
                                          <Customer>
                                             <CustomerInfo>
                                                <div>Work Order Total:</div>
                                                <div>{formatToUSD(workOrder?.total)}</div>
                                             </CustomerInfo>
                                             <CustomerInfo>
                                                <div style={{ fontWeight: "bold", color: "#42C77B" }}>
                                                   Paid To-Date:
                                                </div>
                                                <div style={{ fontWeight: "bold", color: "#42C77B" }}>
                                                   {formatToUSD(workOrder?.deposited)}
                                                </div>
                                             </CustomerInfo>
                                             <div className="divider" />
                                             <CustomerInfo>
                                                <div style={{ color: "#FB4D4F" }}>Payment Due (Deposit):</div>
                                                <div
                                                   style={{
                                                      color: "#FB4D4F",
                                                      fontWeight: "bold",
                                                      fontSize: "15px",
                                                   }}
                                                >
                                                   {formatToUSD(checkDepositUnpaid()
                                                      ? workOrder?.required_deposit_amount
                                                      : workOrder?.additional_payment_amount ?? 0
                                                   )}
                                                </div>
                                             </CustomerInfo>
                                             <CustomerInfo>
                                                <div style={{ color: "#8B8B8B" }}>Remaining after this payment: </div>
                                                <div style={{ color: "#8B8B8B" }}>
                                                   {(workOrder && formatToUSD(workOrder.total - workOrder.deposited))}
                                                </div>
                                             </CustomerInfo>
                                          </Customer>
                                       ) : (
                                          <Customer>
                                             <CustomerInfo>
                                                <div>Standard Rate:</div>
                                                <div>{formatToUSD(workOrder?.total)}/job</div>
                                             </CustomerInfo>
                                             <CustomerInfo>
                                                <div style={{ fontWeight: "bold", color: "#42C77B" }}>Discounted:</div>
                                                <div style={{ fontWeight: "bold", color: "#42C77B" }}>
                                                   {workOrder?.recurring_config?.discount ?? 0}%
                                                </div>
                                             </CustomerInfo>
                                             <CustomerInfo>
                                                <div style={{ fontWeight: "bold", color: "#FB4D4F" }}>
                                                   Appointments per Period:
                                                </div>
                                                <div className="right" style={{ fontWeight: "bold", color: "#FB4D4F" }}>
                                                   {workOrder.recurring_config?.times_per_period}/
                                                   {formatPeriod(workOrder.recurring_config?.schedule_period)}
                                                </div>
                                             </CustomerInfo>
                                             <div className="divider" />
                                             <CustomerInfo>
                                                <div style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                                                   Discounted{" "}
                                                   {workOrder.recurring_config?.billing === "M"
                                                      ? "Monthly"
                                                      : "Annually"}{" "}
                                                   Rate ({workOrder.recurring_config?.discount}%):
                                                </div>
                                                <div style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                                                   {formatToUSD(recurringCalculations?.payment ?? 0)}
                                                </div>
                                             </CustomerInfo>
                                             <CustomerInfo>
                                                <div style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                                                   {workOrder.recurring_config?.billing === "M"
                                                      ? "Monthly"
                                                      : "Annually"}{" "}
                                                   Savings
                                                </div>
                                                <div style={{ color: "#202020", fontWeight: 700, fontSize: "13px" }}>
                                                   {formatToUSD(
                                                      ((recurringCalculations?.payment ?? 0) /
                                                         (100 - (workOrder.recurring_config?.discount ?? 0))) *
                                                         100 -
                                                      (recurringCalculations?.payment ?? 0)
                                                   )}
                                                </div>
                                             </CustomerInfo>
                                          </Customer>
                                       )}
                                    </div>
                                 </Flex>
                              </PaymentStatus>
                           )}

                           {!isRecurring &&
                              jobs &&
                              jobs.length > 0 &&
                              jobs.map((job: any) => {
                                 return (
                                    <div key={job?.id}>
                                       <div className="noBreak">
                                          <JobTitle justifyContent="space-between">
                                             <h3>{job.title}</h3>
                                             <p>(Job {job.order_number})</p>
                                          </JobTitle>
                                          <JobInfo alignItems="center" justifyContent="space-between">
                                             <div>
                                                Start Date & Time:{" "}
                                                <span>
                                                   {moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}
                                                </span>
                                             </div>
                                             <div>
                                                Estimated Duration: <span>{job?.estimated_duration ?? "TBD"}</span>
                                             </div>
                                          </JobInfo>
                                       </div>
                                       <Table>
                                          {job?.services && job.services.length > 0 && (
                                             <Services className="noBreak">
                                                <div
                                                   className={`grid header ${
                                                      technicianRole || !woPricing ? "tech" : ""
                                                   }`}
                                                >
                                                   <div className="name">LABOR</div>
                                                   <div className="desc">Description</div>
                                                   <div className="qty">Qty</div>
                                                   {woPricing && !technicianRole && <div className="rate">Rate</div>}

                                                   <div className="type">Type</div>
                                                   {woPricing && !technicianRole && <div className="total">Total</div>}
                                                </div>

                                                {job.services.map((item: any) => {
                                                   const type = servicesUnitTypes.find(
                                                      (s) => s.value === item.unit_type
                                                   )
                                                   return (
                                                      <TableItem key={item.id}>
                                                         <div
                                                            className={`grid ${
                                                               technicianRole || !woPricing ? "tech" : ""
                                                            }`}
                                                         >
                                                            <div className="name">{item.name}</div>
                                                            <div className="desc">{item.description}</div>
                                                            <div className="qty">{item.qty.toFixed(2)}</div>
                                                            {woPricing && !technicianRole && (
                                                               <div className="rate">{formatToUSD(item.unit_price)}</div>
                                                            )}

                                                            <div className="type">{type && type.label}</div>
                                                            {woPricing && !technicianRole && (
                                                               <div className="total" style={{ fontWeight: "bold" }}>
                                                                  {formatToUSD(item.unit_price * item.qty)}
                                                               </div>
                                                            )}
                                                         </div>
                                                      </TableItem>
                                                   )
                                                })}

                                                {job?.technicians_data && job.technicians_data.length > 0 && (
                                                   <Technician>
                                                      <div
                                                         className={`grid header  ${
                                                            technicianRole || !woPricing ? "tech" : ""
                                                         }`}
                                                      >
                                                         <div />
                                                         <div>Technician(s)</div>
                                                         <div>Estimated Hours</div>
                                                         {woPricing && !technicianRole && <div />}
                                                         <div />
                                                         {woPricing && !technicianRole && <div />}
                                                      </div>

                                                      {job.technicians_data.map((item: any) => {
                                                         const technician = options.find(
                                                            (t) => Number(t.id) === item.technician
                                                         )
                                                         return (
                                                            <div
                                                               key={item.id}
                                                               className={`grid  ${
                                                                  technicianRole || !woPricing ? "tech" : ""
                                                               }`}
                                                            >
                                                               <div />
                                                               <div>{technician && technician.full_name}</div>
                                                               <div>{item.estimated_hours}</div>
                                                               {woPricing && !technicianRole && <div />}
                                                               <div />
                                                               {woPricing && !technicianRole && <div />}
                                                            </div>
                                                         )
                                                      })}
                                                   </Technician>
                                                )}
                                             </Services>
                                          )}
                                       </Table>
                                       <Table>
                                          {job?.materials && job.materials.length > 0 && (
                                             <Materials className="noBreak">
                                                <div
                                                   className={`grid header ${
                                                      technicianRole || !woPricing ? "tech" : ""
                                                   }`}
                                                >
                                                   <div className="name">MATERIALS</div>

                                                   <div className="desc">Description</div>
                                                   <div className="qty">Qty</div>
                                                   {woPricing && !technicianRole && (
                                                      <>
                                                         <div className="price">Unit price</div>
                                                         <div className="total">Total</div>
                                                      </>
                                                   )}
                                                </div>

                                                {job.materials.map((item: any) => {
                                                   return (
                                                      <TableItem key={item.id}>
                                                         <div
                                                            className={`grid ${
                                                               technicianRole || !woPricing ? "tech" : ""
                                                            }`}
                                                         >
                                                            <div className="name">{item.name}</div>

                                                            <div>{item.description}</div>
                                                            <div className="qty">{item.qty}</div>
                                                            {woPricing && !technicianRole && (
                                                               <>
                                                                  <div className="price">{formatToUSD(item.unit_price)}</div>
                                                                  <div className="total" style={{ fontWeight: "bold" }}>
                                                                     {formatToUSD(item.unit_price * item.qty)}
                                                                  </div>
                                                               </>
                                                            )}
                                                         </div>
                                                      </TableItem>
                                                   )
                                                })}
                                             </Materials>
                                          )}
                                       </Table>

                                       {/* <Table className="noBreak">
                                          {job?.technicians_data && job.technicians_data.length > 0 && (
                                             <Technician className="noBreak">
                                                <div className={`grid header ${technicianRole ? "tech" : ""}`}>
                                                   <div>TECHNICIAN(s)</div>
                                                   {!technicianRole && woPricing && <div>Rate</div>}

                                                   <div>Estimated Hours</div>
                                                   {!technicianRole && woPricing && <div>Total</div>}
                                                </div>

                                                {job.technicians_data.map((item: any) => {
                                                   const technician = options.find(
                                                      (t) => Number(t.id) === item.technician
                                                   )
                                                   return (
                                                      <TableItem key={item.id}>
                                                         <div className={`grid  ${technicianRole ? "tech" : ""}`}>
                                                            <div>{technician && technician.full_name}</div>
                                                            {!technicianRole && woPricing && <div>{item.rate}</div>}

                                                            <div>{item.estimated_hours}</div>
                                                            {!technicianRole && woPricing && (
                                                               <div style={{ fontWeight: "bold" }}>
                                                                  ${calculateRows(item)}
                                                               </div>
                                                            )}
                                                         </div>
                                                      </TableItem>
                                                   )
                                                })}
                                             </Technician>
                                          )}
                                       </Table>
                                       <Table>
                                          {job?.schedules && job.schedules.length > 0 && (
                                             <Scheduler className="noBreak">
                                                <div className="grid header">
                                                   <div>SCHEDULER</div>
                                                   <div>Start date & time</div>
                                                   <div>End date & time</div>
                                                </div>

                                                {job.schedules.map((item: any) => {
                                                   return (
                                                      <TableItem key={item.id}>
                                                         <div className="grid">
                                                            <div></div>
                                                            <div>{moment(item.start).format("M/DD/YY LT")}</div>
                                                            <div>{moment(item.end).format("M/DD/YY LT")}</div>
                                                         </div>
                                                      </TableItem>
                                                   )
                                                })}
                                             </Scheduler>
                                          )}
                                       </Table> */}

                                       {woPricing && !technicianRole && (
                                          <div className="noBreak">
                                             <Summary>
                                                <div className="disclaimers">
                                                   <h3>DISCLAIMERS</h3>
                                                   {isInvoice ? (
                                                      <p>{parse(invoiceFooter)}</p>
                                                   ) : (
                                                      <p>{parse(estimateFooter)}</p>
                                                   )}
                                                </div>
                                                <div className="summary">
                                                   <Flex justifyContent="space-between">
                                                      <div>Labor</div>
                                                      <div>{formatToUSD(job.total_labor)}</div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>Materials</div>
                                                      <div>{formatToUSD(job.total_materials)}</div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>Subtotal</div>
                                                      <div>
                                                         {formatToUSD(
                                                            job !== null ? job.total_labor + job.total_materials : 0
                                                         )}
                                                      </div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>Taxes</div>
                                                      <div>{formatToUSD(job !== null ? job.tax : 0)}</div>
                                                   </Flex>
                                                   <Flex justifyContent="space-between">
                                                      <div>EPA fees</div>
                                                      <div>{formatToUSD(job !== null ? job.epa : 0)}</div>
                                                   </Flex>
                                                   <Flex className="total" justifyContent="space-between">
                                                      <div>Job total</div>
                                                      <div>{formatToUSD(job?.total)}</div>
                                                   </Flex>
                                                </div>
                                             </Summary>
                                          </div>
                                       )}
                                       {woPhotos && <Photos job={job} />}
                                    </div>
                                 )
                              })}

                           {isRecurring && (
                              <div>
                                 <div className="noBreak">
                                    <JobTitle justifyContent="space-between">
                                       <h3>{workOrder?.recurring_config?.title}</h3>
                                    </JobTitle>
                                    <JobInfo alignItems="center" justifyContent="space-between">
                                       <div>
                                          Schedule Window:{" "}
                                          <span>
                                             {workOrder?.recurring_config?.times_per_period &&
                                             workOrder?.recurring_config?.schedule_period
                                                ? `${
                                                     workOrder?.recurring_config?.times_per_period
                                                  } times/${renderPeriod(workOrder.recurring_config.schedule_period)}`
                                                : ""}
                                             {workOrder?.recurring_config?.day_of_week &&
                                                ` (${showDays(workOrder?.recurring_config?.day_of_week)})`}
                                          </span>
                                       </div>
                                       <div>
                                          Estimated Duration: <span>{workOrder?.estimated_duration ?? "TBD"}</span>
                                       </div>
                                    </JobInfo>
                                 </div>
                                 <Table>
                                    {workOrder?.services && workOrder.services.length > 0 && (
                                       <Services className="noBreak">
                                          <div className={`grid header ${technicianRole || !woPricing ? "tech" : ""}`}>
                                             <div className="name">LABOR</div>
                                             <div className="desc">Description</div>
                                             <div className="qty">Qty</div>
                                             {woPricing && !technicianRole && <div className="rate">Rate</div>}

                                             <div className="type">Type</div>
                                             {woPricing && !technicianRole && <div className="total">Total</div>}
                                          </div>

                                          {workOrder.services.map((item: any) => {
                                             const type = servicesUnitTypes.find((s) => s.value === item.unit_type)
                                             return (
                                                <TableItem key={item.id}>
                                                   <div
                                                      className={`grid ${technicianRole || !woPricing ? "tech" : ""}`}
                                                   >
                                                      <div className="name">{item.name}</div>
                                                      <div className="desc">{item.description}</div>
                                                      <div className="qty">{item.qty.toFixed(2)}</div>
                                                      {woPricing && !technicianRole && (
                                                         <div className="rate">{formatToUSD(item.unit_price)}</div>
                                                      )}

                                                      <div className="type">{type && type.label}</div>
                                                      {woPricing && !technicianRole && (
                                                         <div className="total" style={{ fontWeight: "bold" }}>
                                                            {formatToUSD(item.unit_price * item.qty)}
                                                         </div>
                                                      )}
                                                   </div>
                                                </TableItem>
                                             )
                                          })}
                                          {workOrder?.recurring_config?.technician && (
                                             <Technician>
                                                <div
                                                   className={`grid header  ${
                                                      technicianRole || !woPricing ? "tech" : ""
                                                   }`}
                                                >
                                                   <div />
                                                   <div>Technician(s)</div>
                                                   <div>Estimated Hours</div>
                                                   {woPricing && !technicianRole && <div />}
                                                   <div />
                                                   {woPricing && !technicianRole && <div />}
                                                </div>
                                                <div className={`grid  ${technicianRole || !woPricing ? "tech" : ""}`}>
                                                   <div />
                                                   <div>{technicianName && technicianName.full_name}</div>
                                                   <div>{workOrder?.estimated_duration ?? "TBD"}</div>
                                                   {woPricing && !technicianRole && <div />}
                                                   <div />
                                                   {woPricing && !technicianRole && <div />}
                                                </div>
                                             </Technician>
                                          )}
                                       </Services>
                                    )}
                                 </Table>
                                 <Table>
                                    {workOrder?.materials && workOrder.materials.length > 0 && (
                                       <Materials className="noBreak">
                                          <div className={`grid header ${technicianRole || !woPricing ? "tech" : ""}`}>
                                             <div className="name">MATERIALS</div>
                                             <div className="desc">Description</div>
                                             <div className="qty">Qty</div>
                                             {woPricing && !technicianRole && (
                                                <>
                                                   <div className="price">Unit price</div>
                                                   <div className="total">Total</div>
                                                </>
                                             )}
                                          </div>

                                          {workOrder.materials.map((item: any) => {
                                             return (
                                                <TableItem key={item.id}>
                                                   <div
                                                      className={`grid ${technicianRole || !woPricing ? "tech" : ""}`}
                                                   >
                                                      <div className="name">{item.name}</div>
                                                      <div>{item.description}</div>
                                                      <div className="qty">{item.qty}</div>
                                                      {woPricing && !technicianRole && (
                                                         <>
                                                            <div className="price">{formatToUSD(item.unit_price)}</div>
                                                            <div className="total" style={{ fontWeight: "bold" }}>
                                                               {formatToUSD(item.unit_price * item.qty)}
                                                            </div>
                                                         </>
                                                      )}
                                                   </div>
                                                </TableItem>
                                             )
                                          })}
                                       </Materials>
                                    )}
                                 </Table>
                                 {woPricing && !technicianRole && (
                                    <div className="noBreak">
                                       <Summary>
                                          <div className="disclaimers">
                                             <h3>DISCLAIMERS</h3>
                                             {isInvoice ? (
                                                <p>{parse(invoiceFooter)}</p>
                                             ) : (
                                                <p>{parse(estimateFooter)}</p>
                                             )}
                                          </div>
                                          <div className="summary">
                                             <Flex justifyContent="space-between">
                                                <div>Labor</div>
                                                <div>{formatToUSD(calculateTotal(workOrder.services))}</div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>Materials</div>
                                                <div>{formatToUSD(calculateTotal(workOrder.materials))}</div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>Subtotal</div>
                                                <div>
                                                   {formatToUSD(
                                                      workOrder.materials && workOrder.services !== null
                                                         ? calculateTotal(workOrder.materials) +
                                                              calculateTotal(workOrder.services)
                                                         : 0
                                                   )}
                                                </div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>Taxes</div>
                                                <div>{formatToUSD(workOrder !== null ? workOrder.tax : 0)}</div>
                                             </Flex>
                                             <Flex justifyContent="space-between">
                                                <div>EPA fees</div>
                                                <div>{formatToUSD(workOrder !== null ? workOrder.epa : 0)}</div>
                                             </Flex>
                                             <Flex className="total" justifyContent="space-between">
                                                <div>Job total</div>
                                                <div>{formatToUSD(workOrder?.total)}</div>
                                             </Flex>
                                             {isRecurring && (
                                                <Flex className="discount" justifyContent="space-between">
                                                   <div>Discounted Total</div>
                                                   <div> {formatToUSD(getDiscountedTotal())}</div>
                                                </Flex>
                                             )}
                                          </div>
                                       </Summary>
                                    </div>
                                 )}
                              </div>
                           )}
                           {isRecurring &&
                              workOrder.recurring_status === ERecurringStatus.Approved &&
                              renderJobsRecurring() &&
                              renderJobsRecurring().length > 0 &&
                              renderJobsRecurring().map((job: any) => {
                                 return (
                                    <React.Fragment key={job?.id}>
                                       <div className="noBreak">
                                          <JobTitle justifyContent="space-between">
                                             <h3>{job.title}</h3>
                                             <p>(Job {job.order_number})</p>
                                          </JobTitle>
                                          <JobInfo alignItems="center" justifyContent="space-between">
                                             <div>
                                                {renderRecurringJobStatus(
                                                   job?.progress,
                                                   job?.schedules,
                                                   job?.completed_at
                                                )}
                                             </div>
                                          </JobInfo>
                                       </div>
                                       <div className="noBreak">
                                          <JobTechnicians>
                                             <h3>Technician(s)</h3>
                                             {job.technicians_data.map((item: any) => {
                                                const technician = options.find((t) => Number(t.id) === item.technician)
                                                return <p key={item.id}>{technician && technician.full_name}</p>
                                             })}
                                          </JobTechnicians>
                                       </div>
                                       {beforeAfterPhotos && <Photos job={job} />}
                                    </React.Fragment>
                                 )
                              })}
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </Wrapper>
      )
   }
)

export default PrintWorkOrder
