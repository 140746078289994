import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { getSvgByValue } from "../../../assets/images/weatherIcons";
import Flex from "../../../noui/Flex";
import { MonthWeatherBlock } from "./styled";
import { Weather } from "./types";
import { kebabCaseToNormalText } from "./utils";

export const MonthDateHeader: React.FC<any> = ({ label, drilldownView, onDrillDown, weather, date }) => {
    const [dayWeather, setDayWeather] = useState<Weather|null>(null);
    const isLg = useBreakpoint(down('lg'));

    useEffect(()=>{
      const today = moment().startOf('day');
      const dayDiff = moment(date).diff(today, 'days');
        if (dayDiff >= 0 && dayDiff<= 9 && Array.isArray(weather.daily)) {
          setDayWeather(
            {
              icon: weather?.daily[dayDiff]?.icon ?? 'sunny',
              temperature: (weather?.daily[dayDiff]?.air_temp_high + weather?.daily[dayDiff]?.air_temp_low)/2 ?? 0,
              precip_type: weather?.daily[dayDiff]?.precip_icon ?? 'chance-rain',
              probability: weather?.daily[dayDiff]?.precip_probability ?? 0,
            }
          );
        }
        else {
          setDayWeather(null);
        }
    },[date, weather]);
    

    return (
        <Flex flexDirection="row" justifyContent={isLg ? "center" : "space-between"}>
            {!isLg && <div>
                {dayWeather &&
                <Tooltip placement="topLeft" title={kebabCaseToNormalText(dayWeather.icon)}> 
                <MonthWeatherBlock>
                    {getSvgByValue(dayWeather.icon)}
                    <span>{`${Math.round(dayWeather.temperature)}°F`}</span>
                </MonthWeatherBlock>
                </Tooltip>
                }
            </div>}
            <a href="#" onClick={onDrillDown}>
                {label}
            </a>            
        </Flex>
    )
}