import React, {useMemo} from "react"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import routes from "../../routes/routes"
import {useAppSelector} from "../../config/hooks";
import {getCurrentUser} from "../../config/reducers/user/selectors";

const MenuWrapper = styled(Flex)`
   width: 100%;
   height: 40px;
   border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
   overflow-y: hidden;
   overflow-x: auto;
   @media (min-width: 992px) {
      padding-left: 52px;
   }
   & a {
      height: 40px;
      line-height: 40px;
      display: inline-block;
      white-space: nowrap;
      & :focus {
         color: #109cf1;
         border-bottom: 4px solid #109cf1;
         border-radius: 1px;
         height: 40px;
         line-height: 40px;
         display: inline-block;
      }
   }
`

const Nlink = styled(NavLink)`
   &.Selected {
      color: #109cf1;
      border-bottom: 4px solid #109cf1;
      border-radius: 1px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
   }
`

const isPage = (pattern: string) => document.location.href.match(pattern)

const isProd = process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "demo"

const SettingsMenu: React.FC = () => {
   const user = useAppSelector(getCurrentUser);
   const pages = useMemo(() => {
      const items = [
         {title: "General Settings", path: routes.settings.General},
         {title: "Users", path: routes.settings.Users},
         {title: "Data Import", path: routes.settings.DataImport},
         {title: "Subscription", path: routes.settings.Subscription},
         {title: "Payments", path: routes.settings.Payments},
         {title: "Text Messaging", path: routes.settings.TextMessaging},
      ];
      if (user?.is_staff) {
         items.splice(4, 1) // Remove Payments
      }
      return items;
   }, [user]);

   return (
      <MenuWrapper alignItems="center" mb="22px">
         <Flex justifyContent="space-between">
            {pages.map((page, i) => (
               <Nlink
                  key={page.title}
                  style={{ marginRight: pages.length - 1 > i ? "52px" : "0px" }}
                  to={page.path}
                  className={isPage(page.path) ? "Selected" : ""}
               >
                  <Msg fontWeight={600} fontSize={15} color={isPage(page.path) ? "#109CF1" : "#494949"}>
                     {page.title}
                  </Msg>
               </Nlink>
            ))}
         </Flex>
      </MenuWrapper>
   )
}

export default SettingsMenu
