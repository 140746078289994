import { Form, Select } from "antd"
import { useEffect, useMemo, useState } from "react"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { Api } from "../../api/api"
import { ETemplateActions, IMessageTemplate, ITemplateSettings } from "../../api/types"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { MessagesTemplateModal } from "../messages/MessagesTemplateModal"
import { CompanyInfoContainer, ContainerTitle, SaveBtn, SaveBtnWrapper } from "./styled"

type TTemplateAction = Partial<Record<ETemplateActions, number>>

export const EmailTemplatesForm = () => {
    const isMD = useBreakpoint(down('md'))
    const isXS = useBreakpoint(down('xs'))
    const [form] = Form.useForm<TTemplateAction>()
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [templates, setTemplates] = useState<IMessageTemplate[]>([])
    const [templateSettings, setTemplateSettings] = useState<ITemplateSettings[]>([])

    const handleSubmit = async () => {
        const formFields = form.getFieldsValue()
        const settingsData = Object.entries(formFields).filter(s => !!(s[1]))
            .map(s => ({action: s[0], template: s[1]})) as ITemplateSettings[]
        await Api.company.setTemplateSettings(settingsData)
    }

    const handleCloseTemplateModal = async () => {
        loadData()
        setVisible(false)
    }

    const loadData = async () => {
        try {
            setIsLoading(true)
            const { data: _templates } = await Api.templates.getAll({})
            setTemplates(_templates as IMessageTemplate[])
            const { data: settings } = await Api.company.getTemplateSettings() 
            setTemplateSettings(settings)
        } finally {
            setIsLoading(false)
        }
    }

    const setFormFields = () => {
        const formattedData: TTemplateAction = templateSettings.reduce((acc: TTemplateAction, s) => {
            acc[s.action] = s.template
            return acc;
        }, {})
        form.setFieldsValue(formattedData)
    }

    const getOptions = useMemo(() => templates.map(template => (
        <Select.Option value={template.id}>
            {template.title}
        </Select.Option>
    )), [templates])

    const ManageButton = () => (
        <Msg 
            fontSize="13px" 
            fontWeight={700} 
            color="#0496FF" 
            marginBottom={isXS ? "8px" : "0"}
            style={{textDecoration: "underline", cursor: "pointer"}}
            onClick={() => setVisible(true)}
        >
            Manage
        </Msg>
    )

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (templateSettings.length) {
            setFormFields()
        }
    }, [templateSettings])

    return <CompanyInfoContainer p={16}>
    <MessagesTemplateModal visible={visible} onClose={handleCloseTemplateModal} backLabel="Back to General Settings" />
    <Flex mb={isXS ? "0px" : "22px"} flexDirection="column" width="100%">
        <ContainerTitle>Company settings for email templates</ContainerTitle>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Flex flexDirection={isMD ? "column" : "row"} maxWidth="900px" justifyContent="space-between" alignItems="start" marginBottom="16px">
                <Flex flexDirection="column" width={isMD ? "100%" : "calc(50% - 16px)"}>
                    <Msg fontSize="18px" fontWeight={700} color="#494949">
                        Work Order
                    </Msg>
                    <Flex flexDirection="column" width="100%">
                        <Flex justifyContent="space-between" margin="8px 0">
                            <Msg fontSize="13px" fontWeight={700}>Send Estimate</Msg>
                        </Flex>
                        <Form.Item name={ETemplateActions.SendEstimate}>
                            <Select 
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                placeholder="Choose template"
                            >
                                {getOptions}
                            </Select>
                        </Form.Item>
                        <Flex justifyContent="space-between" margin="8px 0">
                            <Msg fontSize="13px" fontWeight={700}>Send Invoice</Msg>
                        </Flex>
                        <Form.Item name={ETemplateActions.SendInvoice}>
                            <Select 
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                placeholder="Choose template"
                            >
                                {getOptions}
                            </Select>
                        </Form.Item>
                        <Flex justifyContent="space-between" margin="8px 0">
                            <Msg fontSize="13px" fontWeight={700}>Edit Estimate</Msg>
                        </Flex>
                        <Form.Item name={ETemplateActions.EditEstimate}>
                            <Select 
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                placeholder="Choose template"
                            >
                                {getOptions}
                            </Select>
                        </Form.Item>
                    </Flex>
                </Flex>
                <Flex flexDirection="column" width={isMD ? "100%" : "calc(50% - 16px)"} marginTop={isMD ? "16px" : "0"}>
                    <Msg fontSize="18px" fontWeight={700} color="#494949">
                        Service Plan
                    </Msg>
                    <Flex flexDirection="column" width="100%">
                        <Flex justifyContent="space-between" margin="8px 0">
                            <Msg fontSize="13px" fontWeight={700}>Send to Client</Msg>
                        </Flex>
                        <Form.Item name={ETemplateActions.ServicePlan}>
                            <Select 
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                placeholder="Choose template"
                            >
                                {getOptions}
                            </Select>
                        </Form.Item>
                        <Flex justifyContent="space-between" margin="8px 0">
                            <Msg fontSize="13px" fontWeight={700}>Request Confirmation</Msg>
                        </Flex>
                        <Form.Item name={ETemplateActions.ApproveAppointment}>
                            <Select 
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                placeholder="Choose template"
                            >
                                {getOptions}
                            </Select>
                        </Form.Item>
                    </Flex>
                </Flex>
            </Flex>
            <SaveBtnWrapper justifyContent="flex-end" alignItems="center">
                <ManageButton/>
                <Form.Item shouldUpdate>
                    {() => (
                        <SaveBtn loading={isLoading} disabled={!form.isFieldsTouched()} ml={16} htmlType="submit">
                        Save
                        </SaveBtn>
                    )}
                </Form.Item>
            </SaveBtnWrapper>
        </Form>
    </Flex>
</CompanyInfoContainer>
}