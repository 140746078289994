import React, { useCallback, useEffect, useState, useMemo } from "react"
import { Checkbox, Collapse, Form, Modal, notification, Radio, Select, Spin, Tabs } from "antd"
import { Api } from "../../api/api"
import styled from "styled-components"
import {
   getReplacedTemplateMessage,
   mapOptions,
   formatToUSD,
} from "../../utils/utils"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import {
   ESendType,
   ETemplateActions,
   ICreateEstimate,
   IMessageTemplate,
   ITemplateSettings,
   IWorkOrder,
} from "../../api/types"
import { useLoading } from "../../utils/hooks"
import { useSelector } from "react-redux"
import { getConstant, getCurrentUser } from "../../config/reducers/user/selectors"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { DepositInput, DepositInputWrapper } from "../../modals/Payments/PaymentsModal"
import { IWorkOrderWithJobs } from "../../api/types"
import { TOption } from "../../types"
import { CardSwitch } from "./CardSwitch"
import { RadioChangeEvent } from "antd/es"
import { Msg } from "../../ui/Text"
import RPMTemplateModal from "./RPMTemplateModal"
import RPMDetails from "./RPMDetails"
import { RPMNewInvoiceModal } from "./RPMNewInvoiceModal"

const { Panel } = Collapse;


const Title = styled.h2`
   font-weight: 900;
   font-size: 15px;
   margin-bottom: 10px;
   line-height: 20px;
   text-transform: uppercase;
`

const ModalWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: stretch;
   ${down("xs")} {
      flex-direction: column;
   }
`
const StyledForm = styled(Form)`
   .template-item label {
      width: 100%;
   }
`
const FormContainer = styled.div`
   padding: 16px;
   width: calc(50% + 20px);
   ${down("xs")} {
      width: 100%;
      .ant-checkbox-group {
         width: 100%;
         display: flex;
         justify-content: space-between;
      }
   }
`

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 0;
   }
`

const LinkButton = styled(EmptyButton)`
   > span {
      text-decoration: underline;
      color: #0496FF !important;
   }
`

const Container = styled.div`
   background: #f4f8fb;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   ${down("xs")} {
      background: #fff;
   }
   &.shareModal {
      background: none;
   }
`
const Content = styled.div`
   flex-grow: 1;
   padding: 50px 20px 20px;
   display: flex;
   flex-direction: column;
   gap: 8px;
   ${down("xs")} {
      padding: 0;
   }
   background-color: #EDEDED;
   .ant-collapse-header {
      color: #0496FF !important;
      text-decoration: underline;
   }
`

const RadioWrapper = styled(Flex)`
   flex-direction: column;
   margin-top: 24px;
   .ant-radio-wrapper {
      margin-top: 16px;
      &:first-child {
         margin-top: 0;
      }
      span {
         font-weight: 700;
         font-size: 13px;
         color: #202020;
      }
   }
`

const LinkButtonWrapper = styled.div`
   display: inline-flex;
   >button {
      margin-left: 16px;
   }
`
const FooterBlock = styled(Flex)`
   flex-direction: column;
   padding: 16px;
   width: 100%;
`

const MainBlockContent = styled(Flex)`
   width: 100%;
   height: 330px;
   box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.15);
   overflow: auto;
   &::-webkit-scrollbar {
      width: 4px;   
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;      
   }
   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;   
   }
   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
`

type TCardItem = {
    title: string
    description: string
    name: keyof ICreateEstimate
}
 
const cards: TCardItem[] = [
    {
       title: "Accept Payment",
       description: "Allow customers to pay credit card on the order page",
       name: "collect_payment",
    },
    {
       title: "Attach Receipt",
       description: "Send a copy of the invoice as an attachment",
       name: "attach_receipt",
    },
    {
       title: "Display Messages",
       description: "Allow customers to view messages on estimates (invoices)",
       name: "display_messages",
    },
    {
       title: "Display Activity Feed",
       description: "Allow customers to see the activity feed on items",
       name: "display_activity_feed",
    },
    {
       title: "Display Authorization History",
       description: "Allow customers to see authorizations made to this order",
       name: "display_authorization_history",
    },
]

type TProps = {
   visible: boolean
   onClose: (update?: boolean) => void
   workOrder?: IWorkOrderWithJobs | IWorkOrder | null
   jobs?: any[]
}

enum EPaymentRadioType {
   Remaining="PT",
   Deposit="D",
   Custom="C"
}

enum EPaymentAmountType {
   Full="full",
   Half="half",
   Quarter="quarter",
   Material="material"
}

export const RequestPaymentModal: React.FC<TProps> = ({
   visible,
   onClose,
   workOrder,
   jobs,
}) => {
   const [templates, setTemplates] = useState<{
      data: IMessageTemplate[]
      mapped: TOption[]
   }>({ data: [], mapped: [] })
   const [form] = Form.useForm<ICreateEstimate>()
   const [loading, onCall] = useLoading()
   const tags = useSelector(getConstant("template_keys"))
   const user = useSelector(getCurrentUser)
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("xs"))
   const [amount, setAmount] = useState<number | undefined>()
   const [templateSettings, setTemplateSettings] = useState<ITemplateSettings[]>([])
   const [sendType, setSendType] = useState([ESendType.E])
   const [paymentType, setPaymentType] = useState<EPaymentRadioType>(EPaymentRadioType.Remaining)
   const [templateModalVisible, setTemlateModalVisible] = useState(false)
   const [newInvoiceModalVisible, setNewInvoiceModalVisible] = useState(false)
   //SELECTED TEMPLATE DATA
   const [templateData, setTemplateData] = useState<any>(null)

   const [cardsData, setCardsData] = useState<any>({
      attach_receipt: false,
      collect_payment: true,
      display_activity_feed: true,
      display_authorization_history: true,
      display_messages: true,
   })
   const maxValue = useMemo(() => {
      if (!workOrder) return 0;
      const _val = workOrder.total - workOrder.deposited;
      if (_val <= 0) return 0;
      return _val; 
   }, [workOrder]);

   const materialsTotal = useMemo(() => {
      if (!jobs || !jobs.length) return 0;
      return jobs.reduce((acc, j) => acc + j.total_materials, 0);
   }, [jobs])
   
   const filteredCards = useMemo(() => {
      return cards.filter(c => c.name === "display_messages" || c.name === "display_activity_feed")
   }, [cards])

   const isUnpayedInvoice = useMemo(() => {
      if (!workOrder || !workOrder?.invoices?.length) return false;
      return !workOrder.invoices.every(i => i.paid)
   }, [workOrder])

   const loadCards = useCallback(() => {
      const workOrderNumber = workOrder?.id;
      if (workOrderNumber) {
         Api.workOrders.getSendSettings(workOrderNumber).then(({ data }) => {
            setCardsData({
               attach_receipt: data.attach_receipt,
               display_activity_feed: data.display_activity_feed,
               display_authorization_history: data.display_authorization_history,
               display_messages: data.display_messages,
               collect_payment: data.collect_payment,
            })
         })
      }
   }, [])

   useEffect(() => {
      loadCards()
   }, [loadCards])

   useEffect(() => {
      if (visible) {
         form.resetFields()
      }
   }, [visible, form])

   useEffect(() => {
      setPaymentType(EPaymentRadioType.Remaining)
      setAmount(maxValue)
   }, [maxValue])

   const handleCheck = useCallback(
      (data: any) => { setCardsData({...cardsData, [data.name]: data.value})},
      [setCardsData, cardsData]
   )

   const validateAmount = useCallback(
      ({ floatValue }: {floatValue: number}) => floatValue <= maxValue, 
      [maxValue]
   )

   const changeAmount = useCallback((type: EPaymentAmountType) => {
      if (type === EPaymentAmountType.Full) {
         setAmount(maxValue)
         return;
      } else if (type === EPaymentAmountType.Half) {
         setAmount(maxValue / 2)
         return;
      } else if (type === EPaymentAmountType.Quarter) {
         setAmount(maxValue / 4)
         return;
      }
      setAmount(materialsTotal);
   }, [setAmount, materialsTotal])

   const changePaymentType = useCallback(
      (e: RadioChangeEvent) => {
         const _value = e.target.value
         if (_value === EPaymentRadioType.Remaining) {
            changeAmount(EPaymentAmountType.Full)
         } else if (_value ===  EPaymentRadioType.Deposit) {
            changeAmount(EPaymentAmountType.Half)
         }
         changeAmount(EPaymentAmountType.Full)
         setPaymentType(_value)
      }, 
      [setPaymentType, changeAmount]
   )

   const handleAmountInputChange = useCallback((values: any) => {
      const { formattedValue, value } = values
      setAmount(value)
   }, [setAmount, maxValue, setPaymentType, materialsTotal])

   const handleTemplateModalOpen = useCallback(() => {
      setTemlateModalVisible(true)
   }, [setTemlateModalVisible])

   const handleTemplateModalOk = useCallback((data: any) => {
      setTemplateData(data); 
      setSendType(data.send_type); 
      setTemlateModalVisible(false);
   }, [setTemplateData, setSendType, setTemlateModalVisible])

   const handleTemplateModalClose = useCallback(() => {
      setTemlateModalVisible(false)
   }, [setTemlateModalVisible])

   const handleLinkButtonClick = (type: EPaymentAmountType) => {
      setPaymentType(EPaymentRadioType.Deposit)
      changeAmount(type)
   }

   const sendData = async (send_new?: boolean) => {
      if (!amount) return;
      const {template, sendType: st,  ..._tData} = templateData;
      let _data = {
         ..._tData,
         ...cardsData,
         additional_payment: +amount,
         template: +template,
         additional_payment_purpose: paymentType,
         send_type: sendType,
         id: workOrder?.id,
      }
      if (typeof send_new === "boolean") {
         _data = {..._data, send_new}
         setNewInvoiceModalVisible(false)
      }
      await Api.workOrders.requestPayment(_data)
      onClose(true)
   }

   const handleNewInvoiceModalCancel = useCallback(() => {
      sendData(false)
   }, [sendData])

   const handleNewInvoiceModalOk = useCallback(() => {
      sendData(true)
   }, [sendData])

   const handleNewInvoiceModalClose = useCallback(() => {
      setNewInvoiceModalVisible(false)
   }, [setNewInvoiceModalVisible])

   const getDefaultTemplate = async () => {
      const actionType = ETemplateActions.SendInvoice
      const templateID = templateSettings.find((t) => t.action === actionType)?.template
      const template = templates.data.find((t) => t.id === templateID)
      if (templateID && template && user) {
         const content = getReplacedTemplateMessage(
            template.content,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const subject = getReplacedTemplateMessage(
            template.subject,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const sms_content = getReplacedTemplateMessage(
            template.sms_content,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const _data = {
            content,
            subject,
            sendType,
            sms_content,
            template: String(templateID),
            id: workOrder?.id || 0,
         }
         setTemplateData(_data)
      }
   }

   const loadTemplates = useCallback(() => {
      if (!templates.data.length) {
         onCall(
            Api.templates.getAll({ ordering: "title" }).then(({ data: dt }) => {
               const data = dt as IMessageTemplate[]
               setTemplates({ data, mapped: mapOptions(data, "title") })
            })
         ).finally()
      }
   }, [templates, onCall])

   useEffect(() => {
      loadTemplates()
   }, [loadTemplates])

   const loadTemplateSettings = async () => {
      const { data } = await Api.company.getTemplateSettings()
      setTemplateSettings(data)
   }

   useEffect(() => {
      loadTemplateSettings()
   }, [])

   useEffect(() => {
      getDefaultTemplate()
   }, [templateSettings, templates])

   const sendEstimate = async (sendSettings: any) => {
      if (!sendType.length) {
         notification.error({message: "Please choose message type"})
         return;
      }
      if (!templateData) {
         notification.error({message: "Please select customer message"})
         return;
      }
      if (!amount || +amount <= 0) {
         notification.error({message: "Invalid payment amount"})
         return;
      }

      if (isUnpayedInvoice) {
         setNewInvoiceModalVisible(true)
         return;
      }

      sendData()
   }

   return (
         <StyledModal
            width={isXs ? "100%" : 660}
            visible={visible}
            onCancel={() => onClose(false)}
            onOk={sendEstimate}
            destroyOnClose
            okText="Send"
            // afterClose={() => setAmount(undefined)}
         >
            <StyledForm
               labelCol={{ style: { fontSize: 13, fontWeight: "bold" } }}
               requiredMark={false}
               layout="vertical"
               form={form}
               onFinish={sendEstimate}
            >
               <Flex flexDirection="column">
               <ModalWrapper>
                  <FormContainer>
                     <Spin spinning={loading}>
                        <Title>Request payment</Title>
                        <Radio.Group value={paymentType} onChange={changePaymentType}>
                           <RadioWrapper>
                              <Radio value={EPaymentRadioType.Remaining}>
                                 Remaining Project Total
                              </Radio>
                              <Radio value={EPaymentRadioType.Deposit}>
                                 Deposit
                                 <LinkButtonWrapper>
                                    <LinkButton onClick={() => handleLinkButtonClick(EPaymentAmountType.Quarter)}>
                                       25%
                                    </LinkButton>
                                    <LinkButton onClick={() => handleLinkButtonClick(EPaymentAmountType.Half)}>
                                       50%
                                    </LinkButton>
                                    <LinkButton onClick={() => handleLinkButtonClick(EPaymentAmountType.Material)}>
                                       Materials Only
                                    </LinkButton>
                                 </LinkButtonWrapper>
                              </Radio>
                              <Radio value={EPaymentRadioType.Custom}>
                                 Custom Amount
                              </Radio>
                           </RadioWrapper>
                        </Radio.Group>
                        <Flex alignItems="center" flexDirection="column" mt="24px" mb="14px">
                              <Form.Item
                                 name="additional_payment"
                                 rules={[{ required: true, message: "Please input amount" }]}
                              >
                                 <DepositInputWrapper>
                                    <DepositInput
                                       thousandSeparator={true}
                                       decimalScale={2}
                                       allowNegative={false}
                                       value={amount}
                                       fixedDecimalScale
                                       onValueChange={handleAmountInputChange}
                                       onChange={() => setPaymentType(EPaymentRadioType.Custom)}
                                       isAllowed={validateAmount}
                                       suffix={` / ${formatToUSD(maxValue)}`}
                                    />
                                 </DepositInputWrapper>
                              </Form.Item>
                        </Flex>
                     </Spin>
                  </FormContainer>
                  <Container>
                  <Content>
                     {isXs ? (
                        <Collapse ghost>
                           <Panel key="panel_1" header="Show options">
                              {cardsData &&
                                 filteredCards.map((card) => (
                                       <div style={{ marginBottom: isXs ? "10px" : "0px" }}>
                                          <CardSwitch
                                             key={card.name}
                                             item={card}
                                             checked={cardsData[card.name]}
                                             handleCheck={handleCheck}
                                          />
                                       </div>
                                    ))}
                           </Panel>
                        </Collapse>
                     ) : (
                        cardsData &&
                        filteredCards.map((card) => (
                              <CardSwitch
                                 key={card.name}
                                 item={card}
                                 checked={cardsData[card.name]}
                                 handleCheck={handleCheck}
                              />
                           ))
                     )}
                  </Content>
                  </Container>
               </ModalWrapper>
               {!isMd && ( 
               <MainBlockContent>
                  <RPMDetails workOrder={workOrder} jobs={jobs} amount={amount}/>                 
               </MainBlockContent>
               )}
               <FooterBlock>
                  <Msg>Send payment request to customer via: </Msg>
                  <Flex justifyContent="space-between">
                     <Checkbox.Group value={sendType} onChange={value => setSendType(value as ESendType[])}>
                        <Checkbox value={ESendType.E}>Email</Checkbox>
                        <Checkbox value={ESendType.S} disabled={!user?.company?.messaging_status}>SMS</Checkbox>
                     </Checkbox.Group>
                     <LinkButton onClick={handleTemplateModalOpen}>Edit Customer Message</LinkButton>
                  </Flex>
               </FooterBlock>
               </Flex>
            </StyledForm>
            <RPMTemplateModal 
               visible={templateModalVisible} 
               templateData={templateData} 
               onOk={handleTemplateModalOk} 
               onClose={handleTemplateModalClose} 
               workOrder={workOrder} 
               jobs={jobs} 
            />
            <RPMNewInvoiceModal 
               visible={newInvoiceModalVisible}
               onOk={handleNewInvoiceModalOk}
               onCancel={handleNewInvoiceModalCancel}
               onClose={handleNewInvoiceModalClose}
            />
         </StyledModal>        
   )
}
