import { useBreakpoint } from "styled-breakpoints/react-styled"
import { Helmet } from "react-helmet-async"
import { PALETTE } from "../../constants"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import Settings from "./Settings"
import SubscriptionCard, { SpinWrapper } from "./SubscriptionCard"
import { down } from "styled-breakpoints"
import { useEffect, useState } from "react"
import { ISubscriptionPlan, IUpdatePlan } from "../../api/types"
import { Api } from "../../api/api"
import { useAppDispatch, useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import { Button, Modal, Spin } from "antd"
import { setCurrentUser } from "../../config/reducers/user/actions"
import ChangePlanModal from "../../modals/ChangePlanModal"
import ChangePlanWarning from "../../modals/ChangePlanWarning"
import { useHistory } from "react-router-dom"
import routes from "../../routes/routes"
import { track } from "../../utils/analytics"
import AnnualSelect from "../../components/payments/AnnualSelect"
import { useSelector } from "react-redux"
import { getPaymentData } from "../../config/reducers/payment/paymentSlice"
import styled from "styled-components"
const SubscriptionCardContainer = styled(Flex)`
   margin: 16px 0 36px;
   align-items: start;
   flex-direction: row;
   justify-content: center;
   width: 100%;
   ${down("lg")} {
      align-items: center;
      flex-direction: column;
   }
`

const SubscriptionLayout = () => {
   const isLg = useBreakpoint(down("lg"))
   const isXs = useBreakpoint(down("xs"))
   const [subscriptions, setSubscriptions] = useState<ISubscriptionPlan[]>([])
   const [targetPlan, setTargetPlan] = useState<ISubscriptionPlan | null>(null)
   const [visible, setVisible] = useState(false)
   const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)
   const [warningVisible, setWarningVisible] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const profile = useAppSelector(getCurrentUser)
   const { payment_period, promoCodeData } = useSelector(getPaymentData)
   const dispatch = useAppDispatch()
   const history = useHistory()
   const [isUpgrade, setIsUpgrade] = useState(false)
   const [isUpdating, setIsUpdating] = useState(false)

   useEffect(() => {
      loadData().then()
   }, [])

   useEffect(() => {
      setIsUpgrade((profile?.company?.plan?.monthly_price ?? 0) < (targetPlan?.monthly_price ?? 0))
   }, [profile, targetPlan])

   const loadData = async () => {
      try {
         setIsLoading(true)
         const request = await Api.config.getSubscriptionPlans()
         const plans = request.data
         setSubscriptions(plans)
      } finally {
         setIsLoading(false)
      }
   }

   const handleSetActive = async (id: number) => {
      if (profile?.company?.last_4) {
         setTargetPlan(subscriptions.find((plan) => plan.id === id) ?? null)
         setVisible(true)
      } else {
         setWarningVisible(true)
      }
   }

   const handleChangePlan = async (id: number) => {
      try {
         setIsUpdating(true)
         const updatePlanQuery: IUpdatePlan = {
            plan: id,
            cardId: "",
            plan_period: payment_period,
         }
         if (promoCodeData) {
            updatePlanQuery.promotion_code = promoCodeData.code
         }

         const res = await Api.company.updatePlan(updatePlanQuery)
         const data = await res.data
         if (isUpgrade) {
            await track("Subscription Upgraded", {
               companyId: data.id,
               upgradedById: profile?.id,
               previousPlan: profile?.company?.plan?.title,
               newPlan: targetPlan?.title,
               totalPaidUSD: (targetPlan?.monthly_price ?? 0) - (profile?.company?.plan?.monthly_price ?? 0),
            })
         } else {
            await track("Subscription Downgraded", {
               companyId: data.id,
               downgradedById: profile?.id,
               previousPlan: profile?.company?.plan?.title,
               newPlan: targetPlan?.title,
               totalPaidUSD: 0,
            })
         }
         const user = await (await Api.user.profile()).data
         await dispatch(setCurrentUser(user))
         await loadData()
      } catch (err) {
         console.log(err)
      } finally {
         setVisible(false)
         setIsUpdating(false)
         setConfirmationModalVisible(false)
      }
   }

   const handleWarningRedirect = () => {
      setWarningVisible(false)
      history.push(routes.profile.MySubscription)
   }

   const handleConfirm = () => handleChangePlan(targetPlan?.id ?? 0)

   return (
      <>
         <Helmet>
            <title>Subscription - DockWorks Pro</title>
         </Helmet>

         <Settings>
            <Flex flexDirection="column" paddingX={isXs ? 0 : 20} alignItems={isLg ? "center" : "start"} width="100%">
               <Msg fontSize="18px" color={PALETTE.Text} fontWeight={900} style={{ textTransform: "uppercase" }}>
                  Subscription
               </Msg>
               <Msg fontSize="15px" color={PALETTE.Text}>
                  Ready to subscribe? Let’s get crusin!
               </Msg>
               <SpinWrapper flexDirection="column" alignItems="center">
                  <Spin spinning={isLoading}>
                     <AnnualSelect />
                     <SubscriptionCardContainer>
                        {subscriptions.map((item) => (
                           <SubscriptionCard
                              {...item}
                              isActive={item.id === profile?.company?.plan?.id}
                              setActive={() => handleSetActive(item.id)}
                           />
                        ))}
                     </SubscriptionCardContainer>
                  </Spin>
               </SpinWrapper>
            </Flex>
            <ChangePlanModal
               visible={visible}
               onOk={() => setConfirmationModalVisible(true)}
               onCancel={() => setVisible(false)}
               currentPlan={profile?.company?.plan ?? null}
               targetPlan={targetPlan}
               isUpgrade={isUpgrade}
               paymentDate={profile?.company?.subscription_status?.next_payment ?? ""}
               last_4={profile?.company?.last_4 ?? ""}
            />
            <Modal
               visible={confirmationModalVisible}
               title="Confirmation"
               okText="Finish & proceed"
               onCancel={() => setConfirmationModalVisible(false)}
               onOk={handleConfirm}
               width={384}
               centered
               footer={[
                  <Button key="submit" type="primary" onClick={handleConfirm} loading={isUpdating}>
                     Finish & proceed
                  </Button>,
               ]}
            >
               <Msg>You have successfully subscribed for {targetPlan?.title ?? ""} plan</Msg>
            </Modal>
            <ChangePlanWarning
               visible={warningVisible}
               onOk={handleWarningRedirect}
               onCancel={() => setWarningVisible(false)}
            />
         </Settings>
      </>
   )
}

export default SubscriptionLayout
