import React from "react"
import styled from "styled-components"
import { up, between, only } from "styled-breakpoints"

import Flex from "../noui/Flex"

const CardContainer = styled(Flex)`
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   flex-grow: 1;
   margin: 5px;
   > div > span {
      width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }
   ${between("xs", "md")} {
      width: 100%;
      min-width: 100%;
   }
   ${only("lg")} {
      width: calc(50% - 10px);
   }
   ${up("xl")} {
      width: calc(33% - 10px);
   }
`

export const TableCard: React.FC = ({ children }) => {
   return (
      <CardContainer flexDirection="column" p="12px">
         {children}
      </CardContainer>
   )
}
