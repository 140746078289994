import { createReducer } from "@reduxjs/toolkit"
import { IConstants, IUserProfile, TSettingsData } from "../../../api/types"
import { setCallendarSettings, setConstants, setCurrentUser, setSidebarCollapse } from "./actions"

type TState = {
   currentUser: IUserProfile | null
   constants: IConstants | null
   callendarSettings: TSettingsData
   sidebarCollapse: boolean
}

const initialState: TState = {
   currentUser: null,
   constants: null,
   callendarSettings: {
      weekStart: "monday",
      daysToExclude: [],
      dayStart: "2021-01-01T07:00:00",
      dayEnd: "2021-01-01T19:00:00",
   },
   sidebarCollapse: false,
}

export const userReducer = createReducer(initialState, (builder) =>
   builder
      .addCase(setCurrentUser, (state, { payload }) => {
         return { ...state, currentUser: payload }
      })
      .addCase(setConstants, (state, { payload }) => {
         return { ...state, constants: payload }
      })
      .addCase(setCallendarSettings, (state, { payload }) => {
         return { ...state, callendarSettings: payload }
      })
      .addCase(setSidebarCollapse, (state, { payload }) => {
         return { ...state, sidebarCollapse: payload }
      })
)
