import { TSchedule } from "../../api/api";
import { TSettingStorage } from "./types";

export function isSchedule(obj: any): obj is TSchedule {
  return obj.end !== undefined;
}

export function sortDates(first: string, second: string) {
  return new Date(second).getTime() - new Date(first).getTime();
}

export const getTime = (isStart: boolean) => {
  const timelineSettings = localStorage.getItem("timelineSettings");
  if (timelineSettings) {
    const data = JSON.parse(timelineSettings) as TSettingStorage;
    return isStart ? data.start : data.end;
  }
  return isStart ? 8 : 17;
};
