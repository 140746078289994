import { Tabs } from "antd";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes/routes";
import VesselMap from "./VesselMap";
import Vessels from "./Vessels";
import VesselsList from "./VesselsList";
const { TabPane } = Tabs;

const VesselTabs = styled(Tabs)`
    .ant-tabs-nav-wrap {
        padding: 0 48px;
    }
    .ant-tabs-tab {
        padding: 0 0 12px;
    }
    .ant-tabs-tab-btn {
        font-size: 15px;
        font-weight: 600;
    }
    .ant-tabs-ink-bar {
        height: 4px;
    }
`;

const VesselContainer = () => {
    const location = useLocation();
    const history = useHistory();
    const isList = useMemo(() => location.pathname.includes('/list'), [location])

    const handleTabChange = (key: string) => {
        if (key === 'list') {
            history.push(routes.vessels.List)
            return;
        }
        history.push(routes.vessels.Map)
    }

    return (
        <Vessels>
            <VesselTabs defaultActiveKey={isList ? "list" : "map"} onChange={handleTabChange}>
                <TabPane tab="Vessel List" key="list">
                    <VesselsList/>
                </TabPane>
                <TabPane tab="Map" key="map">
                    <VesselMap/>
                </TabPane>
            </VesselTabs>
        </Vessels>
    );
}

export default VesselContainer;