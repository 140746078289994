import React, { useEffect, useState, useCallback, useRef } from "react";
import { FormInstance, Form, Button, Divider } from "antd";
import moment from "moment";
import { Api } from "../../../api/api";
import { IUser } from "../../../api/types";
import { TOption, TInputChangeHandler } from "../../../types";
import { TCallback } from "../../../types";
import { formatToUSD, mapOptions } from "../../../utils/utils";
import { useLocation } from "react-router-dom";
import routes from "../../../routes/routes";
import {
  SideContentWrapper,
  SideContentBlock,
  SelectCardTitle,
  SideContentLabel,
  UnderlineInfo,
  CustomDivider,
  BlockTitle,
  ActivitiesContainer,
  PaddedContainer,
  TotalContainer,
  MessagesContainer,
  AddJobButton,
} from "../styled";
import { Msg } from "../../../ui/Text";
import Flex from "../../../noui/Flex";
import { Activities } from "../../jobs/content/Activities";
import { Messages } from "../../../components/Messages/Messages";
import { useWebSocket } from "../../../utils/hooks";
import { getCurrentUser } from "../../../config/reducers/user/selectors";
import { track } from "../../../utils/analytics";
import { useAppSelector } from "../../../config/hooks";
import {
  EJobPriority,
  ICreateJob,
  IJob,
  IJobMaterial,
  IJobService,
  IMessage,
  IWorkOrderWithJobs,
  IWSMessage,
} from "../../../api/types";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import NotificationClose from "../../../assets/images/NotificationClose.svg";
import WorkorderPanelOpen from "../../../assets/images/WorkorderPanelOpen.svg";
import { EmptyButton } from "../../../ui/Button";

type TProps = {
  form: FormInstance;
  job: IJob | null;
  priority: string | null;
  estimateDuration: string | null | any;
  technicianRole: boolean;
  isRecurring: boolean;
  workOrder?: IWorkOrderWithJobs | null;
  handleAddAnotherJob?: TCallback;
};

type TCalculations = {
  services: number;
  materials: number;
  deposit: number;
  discount: number;
  grand: number;
};

export const calculateRows = <Item extends IJobService | IJobMaterial>(
  items: Item[]
): number => {
  return items
    .map((s) => (s?.qty || 0) * (s?.unit_price || 0))
    .reduce((acc, i) => acc + i, 0);
};

type label = [string, keyof TCalculations];

const mainLabels: label[] = [
  ["Total Services", "services"],
  ["Total Materials", "materials"],
];

export const SideContent: React.FC<TProps> = ({
  form,
  job,
  estimateDuration,
  priority,
  technicianRole,
  isRecurring,
  workOrder,
  handleAddAnotherJob,
}) => {
  const isXs = useBreakpoint(down("xs"));
  const isLg = useBreakpoint(down("lg"));
  const [showSummary, setShowSummary] = useState(false);
  const [showTotals, setShowTotals] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [options, setOptions] = useState<TOption[]>([]);
  const [isActivitiesHide, setIsActivitiesHide] = useState<boolean>(true);
  const [isTotalHide, setIsTotalHide] = useState<boolean>(false);
  const [isMessagesHide, setIsMessagesHide] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const profile = useAppSelector(getCurrentUser);
  const messageRef = useRef<any>(null);
  const location = useLocation();

  const isCreatePage = () =>
    location.pathname === routes.workOrder.CreateWorkOrder;

  const calculatePrices = (form: FormInstance<ICreateJob>): TCalculations => {
    const sVals = form.getFieldValue("services") || [];
    const services = calculateRows(sVals);
    const mVals = form.getFieldValue("materials") || [];
    const materials = calculateRows(mVals);
    const deposit = Number(form.getFieldValue("required_deposit") || 0);
    const discount: number = 0;
    const grand: number = services + materials - discount;

    return { services, materials, deposit, discount, grand };
  };

  const formatPrice = (price: number | undefined) =>
    price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2);

  useEffect(() => {
    loadData();
    // form.resetFields()
  }, [form]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const sentMessage = params.get("sentMessage");
    if (sentMessage && messageRef) {
      messageRef.current.focus();
    }
  }, []);
  const loadData = async () => {
    try {
      setLoading(true);
      if (isRecurring) {
        const { data } = await Api.messages.getAll({
          work_order: workOrder?.id,
        });
        setMessages(data as IMessage[]);
      } else {
        if (job) {
          const { data } = await Api.messages.getAll({ job: job.id });
          setMessages(data as IMessage[]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMessage = useCallback((data: IWSMessage<IMessage>) => {
    setMessages((messages) => [data.message, ...messages]);
  }, []);

  const handleChangeMessage: TInputChangeHandler = ({ target: { value } }) => {
    setMessage(value);
  };

  useWebSocket(
    isRecurring ? String(workOrder?.id) : String(job?.id),
    isRecurring ? "W" : "J",
    handleMessage
  );

  const handleSave = async () => {
    setSaving(true);
    try {
      const { data } = await Api.messages.create({
        message,
        ...(isRecurring ? { work_order: workOrder?.id } : { job: job?.id }),
      });
      track("Message Sent to Customer", {
        messageSentByCompany: profile?.company?.id,
        messageSentByUser: profile?.id,
        messageSentTo: data.customer,
        messageTimestamp: moment().toISOString(),
      });
      setMessages([data, ...messages]);
      // form.resetFields()
      setMessage("");
    } catch (e) {
    } finally {
      setSaving(false);
    }
  };

  return (
    <SideContentWrapper>
      <SideContentBlock>
        {!technicianRole && (
          <TotalContainer isHide={isTotalHide} isFlex={!isTotalHide}>
            <Flex justifyContent={"space-between"} style={{ width: "100%" }}>
              <PaddedContainer style={{ width: "100%" }}>
                <Flex justifyContent="space-between">
                  <SelectCardTitle>job totals</SelectCardTitle>
                  <Msg
                    color={"#0496FF"}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                    onClick={() => setIsTotalHide((prev) => !prev)}
                  >
                    {isTotalHide ? "Show" : "Hide"}
                  </Msg>
                </Flex>

                {!isTotalHide && (
                  <>
                    <Form.Item shouldUpdate style={{ margin: 0 }}>
                      {() => {
                        const prices = calculatePrices(form);
                        return (
                          <>
                            {!technicianRole &&
                              mainLabels.map(([label, key]) => {
                                return (
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    key={key}
                                  >
                                    <SideContentLabel>{label}</SideContentLabel>
                                    <SideContentLabel>
                                      {formatToUSD(
                                        parseFloat(String(prices[key]))
                                      )}
                                    </SideContentLabel>
                                  </Flex>
                                );
                              })}
                            {!technicianRole && (
                              <>
                                <CustomDivider />
                                <Flex
                                  flexDirection="column"
                                  alignItems="flex-end"
                                  style={{
                                    width: "fit-content",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    minWidth="150px"
                                  >
                                    <SideContentLabel marginRight="8px">
                                      Subtotal
                                    </SideContentLabel>
                                    <SideContentLabel>
                                      {formatToUSD(
                                        job
                                          ? job.total_labor +
                                              job.total_materials
                                          : 0
                                      )}
                                    </SideContentLabel>
                                  </Flex>
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    minWidth="150px"
                                  >
                                    <UnderlineInfo marginRight="8px">
                                      Taxes
                                    </UnderlineInfo>
                                    <SideContentLabel>
                                      {formatToUSD(job?.tax)}
                                    </SideContentLabel>
                                  </Flex>
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    minWidth="150px"
                                  >
                                    <UnderlineInfo marginRight="8px">
                                      EPA Fees
                                    </UnderlineInfo>
                                    <SideContentLabel>
                                      {formatToUSD(job?.epa)}
                                    </SideContentLabel>
                                  </Flex>
                                </Flex>
                                <CustomDivider />
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Msg
                                    fontSize={16}
                                    fontWeight={"bold"}
                                    color="#FB4D4F"
                                  >
                                    Remaining Balance
                                  </Msg>
                                  <Msg
                                    fontSize={16}
                                    fontWeight={"bold"}
                                    color="#FB4D4F"
                                  >
                                    {formatToUSD(job?.total || prices.grand)}
                                  </Msg>
                                </Flex>
                              </>
                            )}
                          </>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
              </PaddedContainer>
            </Flex>
          </TotalContainer>
        )}

        <MessagesContainer isHide={isMessagesHide} isFlex={!isMessagesHide}>
          <Messages
            selectedJob={null}
            ref={messageRef}
            messages={messages}
            message={message}
            handleToggleSideBarComponent={() =>
              setIsMessagesHide(!isMessagesHide)
            }
            isHide={!isMessagesHide}
            onSend={handleSave}
            onChangeMessage={handleChangeMessage}
            maxHeight={"300px"}
            woWidget={true}
          />
        </MessagesContainer>

        {job?.activities && (
          <ActivitiesContainer
            isHide={isActivitiesHide}
            isFlex={!isActivitiesHide}
          >
            <Flex justifyContent={"space-between"}>
              <PaddedContainer style={{ width: "100%" }}>
                <Flex
                  justifyContent={"space-between"}
                  style={{ width: "100%" }}
                >
                  <SelectCardTitle>Job Activity</SelectCardTitle>
                  <Msg
                    color={"#0496FF"}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                    onClick={() => setIsActivitiesHide((prev) => !prev)}
                  >
                    {isActivitiesHide ? "Show" : "Hide"}
                  </Msg>
                </Flex>

                {!isActivitiesHide && job && (
                  <Activities activities={job.activities} />
                )}
              </PaddedContainer>
            </Flex>
          </ActivitiesContainer>
        )}
        {!technicianRole && <Divider />}
        {!isCreatePage() && !workOrder?.is_recurring && (
          <AddJobButton onClick={handleAddAnotherJob}>
            + add another job
          </AddJobButton>
        )}
      </SideContentBlock>
    </SideContentWrapper>
  );
};
