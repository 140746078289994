import React, { useState } from "react"
import styled from "styled-components"
import { Input, Modal } from "antd"
import { TArgCallback } from "../../types"
import { ERequestPriority } from "../../api/api"

export const StyledModal = styled(Modal)`
   max-width: 355px;
   .ant-modal-body {
      margin-bottom: 18px;
   }
`

type TProps = {
   visible: boolean;
   isDecline?: boolean;
   close: any
   sendRequestChanges: (message: string, priority: ERequestPriority) => void
   isStatementOfWork?: boolean
}

const RequestChangesModal: React.FC<TProps> = ({ visible, isDecline, close, sendRequestChanges, isStatementOfWork }) => {
   const [value, setValue] = useState<string>("")

   const handleSubmit = () => {
      const priority = isStatementOfWork ? ERequestPriority.Normal : ERequestPriority.Urgent
      sendRequestChanges(value, priority)
      close()
   }
   return (
      <StyledModal
         title={isDecline ? "Decline Estimate" : isStatementOfWork ? "Edit SERVICE PLAN"  : "Request Changes"}
         visible={visible}
         onCancel={close}
         centered
         okText={isDecline ? "Decline" : isStatementOfWork ? "Send" : "Request Changes"}
         onOk={handleSubmit}
      >
         <Input.TextArea
            rows={4}
            placeholder="Reason, please describe why?"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            showCount 
            maxLength={256}
         />
      </StyledModal>
   )
}
export default RequestChangesModal
