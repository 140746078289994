import { useEffect, useRef } from "react"
import lottie from "lottie-web"
import LoadingImg from "../../assets/animation/loading.json"

export const Loading = () => {
   const animImg = useRef(null)

   useEffect(() => {
        lottie.loadAnimation({
            container: animImg.current,
            animationData: LoadingImg,
            renderer: "svg", // "canvas", "html"
            loop: true, // boolean
            autoplay: true, // boolean
        })
   }, [])

   return <div style={{ width: "175px", height: "175px" }} ref={animImg} />
}
