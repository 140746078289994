const PossiblySleetNight = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M23.8485 0.339506C22.7232 0.419093 21.6 0.824412 20.6302 1.57265C18.0731 3.54574 17.5517 7.1563 19.466 9.63727C21.3801 12.1181 25.005 12.5297 27.5622 10.5566C28.28 10.0027 28.8375 9.31973 29.2256 8.56894C28.8885 12.5997 25.6152 15.7627 21.6271 15.7627C17.4146 15.7627 14 12.2341 14 7.88136C14 3.52865 17.4146 0 21.6271 0C22.3998 0 23.1456 0.118733 23.8485 0.339506Z"
         fill="#FDD020"
      />
      <path
         d="M24.7906 27.2447H25.308C27.4773 27.2449 29.458 26.012 30.4155 24.0654C31.373 22.119 31.1409 19.7975 29.817 18.0792C28.4929 16.3607 26.3073 15.5445 24.181 15.9742C23.9013 14.5783 22.9971 13.3876 21.7276 12.7431C20.4583 12.0986 18.9634 12.0716 17.6714 12.6696C16.5021 9.44889 12.9434 7.78598 9.72264 8.95522C6.50193 10.1247 4.83902 13.6835 6.00826 16.9042C3.18698 16.9931 0.958398 19.3283 1.00059 22.1506C1.04303 24.9732 3.34084 27.2401 6.16364 27.2447"
         fill="#A3B3BA"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12.5725 19.2246C12.4404 19.233 12.3146 19.2839 12.214 19.3699C12.1134 19.4559 12.0434 19.5722 12.0146 19.7014C11.9858 19.8306 11.9998 19.9657 12.0543 20.0863C12.1088 20.2069 12.2011 20.3065 12.3171 20.3702L16.5797 22.8046C16.6494 22.8454 16.7266 22.872 16.8068 22.8828C16.8869 22.8936 16.9683 22.8884 17.0464 22.8675C17.1245 22.8467 17.1977 22.8104 17.2618 22.761C17.3258 22.7117 17.3794 22.6502 17.4195 22.5799C17.4595 22.5097 17.4853 22.4322 17.4952 22.352C17.5052 22.2718 17.4991 22.1904 17.4774 22.1126C17.4557 22.0347 17.4187 21.9618 17.3687 21.8983C17.3186 21.8348 17.2565 21.7819 17.1859 21.7426L12.9233 19.308C12.8174 19.2457 12.6951 19.2167 12.5725 19.2249V19.2246Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.742 18.0001C14.6617 18.0013 14.5825 18.0185 14.5089 18.0503C14.4352 18.0822 14.3686 18.1282 14.3127 18.1858C14.2569 18.2434 14.2129 18.3115 14.1834 18.3861C14.1538 18.4608 14.1393 18.5404 14.1405 18.6207V23.4921C14.1394 23.573 14.1544 23.6534 14.1846 23.7285C14.2148 23.8036 14.2596 23.872 14.3165 23.9296C14.3733 23.9872 14.441 24.0331 14.5157 24.0643C14.5904 24.0955 14.6705 24.1116 14.7515 24.1116C14.8324 24.1116 14.9126 24.0955 14.9873 24.0643C15.0619 24.0331 15.1297 23.9872 15.1865 23.9296C15.2434 23.872 15.2882 23.8036 15.3184 23.7285C15.3486 23.6534 15.3636 23.573 15.3625 23.4921V18.6207C15.3638 18.5388 15.3486 18.4575 15.3179 18.3817C15.2871 18.3058 15.2415 18.237 15.1836 18.1791C15.1257 18.1212 15.0568 18.0756 14.9809 18.0448C14.9051 18.0141 14.8238 17.9988 14.742 18.0001Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16.8518 19.2246C16.7558 19.2305 16.6626 19.259 16.5797 19.3077L12.3171 21.7421C12.2465 21.7814 12.1843 21.8344 12.1343 21.8979C12.0842 21.9614 12.0473 22.0342 12.0256 22.1121C12.0038 22.19 11.9978 22.2714 12.0077 22.3517C12.0177 22.4319 12.0434 22.5094 12.0835 22.5796C12.1236 22.6498 12.1772 22.7113 12.2412 22.7607C12.3052 22.8101 12.3784 22.8462 12.4565 22.8671C12.5346 22.888 12.6161 22.8932 12.6962 22.8824C12.7763 22.8716 12.8535 22.8451 12.9233 22.8043L17.1859 20.3698C17.3079 20.3029 17.4034 20.1963 17.4566 20.0677C17.5099 19.9392 17.5177 19.7964 17.4787 19.6629C17.4398 19.5293 17.3564 19.413 17.2424 19.3332C17.1284 19.2534 16.9906 19.215 16.8518 19.2241V19.2246Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.46846 27.0021C7.36038 27.0089 7.25744 27.0506 7.17512 27.121C7.0928 27.1914 7.03555 27.2865 7.01198 27.3922C6.98842 27.4979 6.99981 27.6085 7.04444 27.7072C7.08907 27.8059 7.16453 27.8874 7.25948 27.9395L10.7475 29.9315C10.8046 29.9649 10.8677 29.9867 10.9333 29.9955C10.9989 30.0043 11.0655 30.0002 11.1294 29.9831C11.1934 29.966 11.2532 29.9363 11.3056 29.8959C11.358 29.8555 11.4019 29.8052 11.4347 29.7477C11.4675 29.6902 11.4886 29.6268 11.4967 29.5612C11.5048 29.4955 11.4999 29.429 11.4821 29.3653C11.4643 29.3015 11.4341 29.2419 11.3931 29.19C11.3522 29.138 11.3013 29.0947 11.2435 29.0625L7.75553 27.0703C7.66886 27.0193 7.56881 26.9956 7.46846 27.0023V27.0021Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.24371 26.0001C9.17806 26.0011 9.11324 26.0151 9.05298 26.0412C8.99271 26.0673 8.93817 26.1049 8.89246 26.152C8.84676 26.1992 8.81079 26.2549 8.78662 26.316C8.76244 26.377 8.75053 26.4422 8.75156 26.5079V30.4941C8.75065 30.5603 8.76291 30.6261 8.78762 30.6876C8.81234 30.749 8.84902 30.805 8.89554 30.8521C8.94205 30.8993 8.99747 30.9368 9.05858 30.9623C9.11969 30.9879 9.18527 31.001 9.25151 31.001C9.31775 31.001 9.38333 30.9879 9.44444 30.9623C9.50555 30.9368 9.56097 30.8993 9.60749 30.8521C9.654 30.805 9.69069 30.749 9.7154 30.6876C9.74012 30.6261 9.75238 30.5603 9.75147 30.4941V26.5079C9.75252 26.4409 9.7401 26.3744 9.71496 26.3123C9.68981 26.2502 9.65245 26.1939 9.60509 26.1465C9.55773 26.0992 9.50134 26.0618 9.43926 26.0367C9.37718 26.0115 9.31068 25.999 9.24371 26.0001Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M10.9702 27.0021C10.8916 27.0069 10.8153 27.0302 10.7475 27.0701L7.25948 29.0621C7.20168 29.0943 7.15084 29.1376 7.10989 29.1896C7.06895 29.2415 7.03871 29.3011 7.02093 29.3649C7.00316 29.4286 6.9982 29.4953 7.00633 29.5609C7.01447 29.6266 7.03555 29.69 7.06834 29.7474C7.10114 29.8049 7.145 29.8552 7.19739 29.8956C7.24979 29.936 7.30967 29.9656 7.37358 29.9827C7.43749 29.9998 7.50415 30.0041 7.56971 29.9952C7.63528 29.9864 7.69844 29.9647 7.75554 29.9313L11.2435 27.9392C11.3434 27.8845 11.4215 27.7972 11.4651 27.692C11.5087 27.5868 11.5151 27.47 11.4832 27.3607C11.4513 27.2514 11.3831 27.1562 11.2898 27.0909C11.1965 27.0257 11.0838 26.9942 10.9702 27.0017V27.0021Z"
         fill="#60A2D7"
      />
      <path
         d="M22.4878 28.3476C22.4878 29.4906 21.5611 30.4173 20.4181 30.4173C19.2749 30.4173 18.3484 29.4906 18.3484 28.3476C18.3484 26.7953 20.4181 23.1734 20.4181 23.1734C20.4181 23.1734 22.4878 26.7953 22.4878 28.3476Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblySnowNight = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 23.6636H25.308C27.4773 23.6638 29.458 22.4309 30.4155 20.4843C31.373 18.5379 31.1409 16.2164 29.817 14.4981C28.4929 12.7796 26.3073 11.9633 24.181 12.3931C23.9013 10.9972 22.9971 9.80651 21.7276 9.16201C20.4583 8.51751 18.9634 8.49048 17.6714 9.08849C16.5021 5.86778 12.9434 4.20487 9.72264 5.37411C6.50193 6.5436 4.83902 10.1024 6.00826 13.3231C3.18698 13.412 0.958398 15.7472 1.00059 18.5695C1.04303 21.392 3.34084 23.659 6.16364 23.6636"
         fill="#A3B3BA"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12.5725 15.6435C12.4404 15.6519 12.3146 15.7028 12.214 15.7888C12.1134 15.8748 12.0434 15.9911 12.0146 16.1203C11.9858 16.2495 11.9998 16.3846 12.0543 16.5052C12.1088 16.6258 12.2011 16.7254 12.3171 16.789L16.5797 19.2235C16.6494 19.2643 16.7266 19.2909 16.8068 19.3016C16.8869 19.3124 16.9683 19.3073 17.0464 19.2864C17.1245 19.2655 17.1977 19.2293 17.2618 19.1799C17.3258 19.1306 17.3794 19.069 17.4195 18.9988C17.4595 18.9286 17.4853 18.8511 17.4952 18.7709C17.5052 18.6906 17.4991 18.6093 17.4774 18.5314C17.4557 18.4536 17.4187 18.3807 17.3687 18.3172C17.3186 18.2537 17.2565 18.2008 17.1859 18.1615L12.9233 15.7269C12.8174 15.6645 12.6951 15.6356 12.5725 15.6438V15.6435Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.742 14.419C14.6617 14.4202 14.5825 14.4374 14.5089 14.4692C14.4352 14.5011 14.3686 14.5471 14.3127 14.6047C14.2569 14.6623 14.2129 14.7304 14.1834 14.805C14.1538 14.8796 14.1393 14.9593 14.1405 15.0395V19.9109C14.1394 19.9919 14.1544 20.0723 14.1846 20.1474C14.2148 20.2225 14.2596 20.2908 14.3165 20.3485C14.3733 20.4061 14.441 20.452 14.5157 20.4832C14.5904 20.5144 14.6705 20.5305 14.7515 20.5305C14.8324 20.5305 14.9126 20.5144 14.9873 20.4832C15.0619 20.452 15.1297 20.4061 15.1865 20.3485C15.2434 20.2908 15.2882 20.2225 15.3184 20.1474C15.3486 20.0723 15.3636 19.9919 15.3625 19.9109V15.0395C15.3638 14.9577 15.3486 14.8764 15.3179 14.8006C15.2871 14.7247 15.2415 14.6559 15.1836 14.598C15.1257 14.5401 15.0568 14.4944 14.9809 14.4637C14.9051 14.433 14.8238 14.4177 14.742 14.419Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16.8518 15.6435C16.7558 15.6494 16.6626 15.6778 16.5797 15.7266L12.3171 18.161C12.2465 18.2003 12.1843 18.2532 12.1343 18.3167C12.0842 18.3802 12.0473 18.4531 12.0256 18.531C12.0038 18.6088 11.9978 18.6903 12.0077 18.7705C12.0177 18.8508 12.0434 18.9283 12.0835 18.9985C12.1236 19.0687 12.1772 19.1302 12.2412 19.1796C12.3052 19.229 12.3784 19.2651 12.4565 19.286C12.5346 19.3068 12.6161 19.3121 12.6962 19.3013C12.7763 19.2905 12.8535 19.264 12.9233 19.2231L17.1859 16.7887C17.3079 16.7218 17.4034 16.6152 17.4566 16.4866C17.5099 16.3581 17.5177 16.2153 17.4787 16.0818C17.4398 15.9482 17.3564 15.8318 17.2424 15.7521C17.1284 15.6723 16.9906 15.6338 16.8518 15.643V15.6435Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M18.7598 21.0443C18.5845 21.0554 18.4176 21.123 18.284 21.2371C18.1505 21.3513 18.0577 21.5056 18.0194 21.6771C17.9812 21.8485 17.9997 22.0279 18.0721 22.1879C18.1445 22.348 18.2669 22.4802 18.4209 22.5647L24.0783 25.7958C24.171 25.85 24.2734 25.8852 24.3798 25.8995C24.4861 25.9139 24.5942 25.9071 24.6979 25.8793C24.8015 25.8516 24.8987 25.8035 24.9837 25.738C25.0686 25.6725 25.1398 25.5908 25.193 25.4976C25.2462 25.4044 25.2803 25.3016 25.2935 25.1951C25.3067 25.0886 25.2987 24.9806 25.2699 24.8773C25.241 24.7739 25.192 24.6772 25.1256 24.593C25.0592 24.5087 24.9767 24.4385 24.8829 24.3863L19.2255 21.155C19.0849 21.0722 18.9226 21.0338 18.7598 21.0447V21.0443Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M21.6393 19.419C21.5328 19.4206 21.4276 19.4434 21.3299 19.4857C21.2321 19.528 21.1437 19.589 21.0695 19.6655C20.9954 19.742 20.9371 19.8324 20.8978 19.9314C20.8586 20.0304 20.8393 20.1361 20.841 20.2426V26.7082C20.8395 26.8156 20.8594 26.9223 20.8995 27.022C20.9396 27.1217 20.9991 27.2124 21.0745 27.2889C21.15 27.3654 21.2399 27.4263 21.339 27.4677C21.4381 27.5092 21.5445 27.5305 21.6519 27.5305C21.7593 27.5305 21.8657 27.5092 21.9648 27.4677C22.0639 27.4263 22.1538 27.3654 22.2293 27.2889C22.3047 27.2124 22.3642 27.1217 22.4043 27.022C22.4444 26.9223 22.4643 26.8156 22.4628 26.7082V20.2426C22.4645 20.134 22.4444 20.0262 22.4036 19.9255C22.3628 19.8248 22.3022 19.7334 22.2254 19.6566C22.1486 19.5798 22.0571 19.5192 21.9564 19.4784C21.8557 19.4376 21.7479 19.4173 21.6393 19.419Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M24.4395 21.0442C24.3121 21.052 24.1884 21.0898 24.0783 21.1545L18.4209 24.3856C18.3271 24.4378 18.2446 24.508 18.1782 24.5923C18.1118 24.6766 18.0628 24.7733 18.0339 24.8766C18.0051 24.98 17.9971 25.0881 18.0103 25.1946C18.0235 25.3011 18.0576 25.404 18.1108 25.4971C18.164 25.5903 18.2352 25.672 18.3201 25.7375C18.4051 25.803 18.5023 25.851 18.6059 25.8787C18.7096 25.9064 18.8177 25.9134 18.924 25.8991C19.0304 25.8847 19.1328 25.8495 19.2255 25.7953L24.8829 22.5642C25.0449 22.4755 25.1716 22.3339 25.2423 22.1633C25.313 21.9927 25.3233 21.8032 25.2716 21.6259C25.2199 21.4486 25.1093 21.2942 24.958 21.1884C24.8067 21.0825 24.6238 21.0314 24.4395 21.0436V21.0442Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.46846 23.421C7.36038 23.4278 7.25744 23.4695 7.17512 23.5399C7.0928 23.6102 7.03555 23.7054 7.01198 23.8111C6.98842 23.9168 6.99981 24.0274 7.04444 24.1261C7.08907 24.2247 7.16453 24.3063 7.25948 24.3584L10.7475 26.3504C10.8046 26.3838 10.8677 26.4056 10.9333 26.4144C10.9989 26.4232 11.0655 26.419 11.1294 26.4019C11.1934 26.3848 11.2532 26.3552 11.3056 26.3148C11.358 26.2744 11.4019 26.224 11.4347 26.1666C11.4675 26.1091 11.4886 26.0457 11.4967 25.9801C11.5048 25.9144 11.4999 25.8479 11.4821 25.7841C11.4643 25.7204 11.4341 25.6608 11.3931 25.6088C11.3522 25.5569 11.3013 25.5136 11.2435 25.4814L7.75553 23.4892C7.66886 23.4382 7.56881 23.4145 7.46846 23.4212V23.421Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.24371 22.4189C9.17806 22.42 9.11324 22.434 9.05298 22.4601C8.99271 22.4862 8.93817 22.5238 8.89246 22.5709C8.84676 22.6181 8.81079 22.6738 8.78662 22.7349C8.76244 22.7959 8.75053 22.8611 8.75156 22.9268V26.913C8.75065 26.9792 8.76291 27.045 8.78762 27.1064C8.81234 27.1679 8.84902 27.2238 8.89554 27.271C8.94205 27.3182 8.99747 27.3557 9.05858 27.3812C9.11969 27.4068 9.18527 27.4199 9.25151 27.4199C9.31775 27.4199 9.38333 27.4068 9.44444 27.3812C9.50555 27.3557 9.56097 27.3182 9.60749 27.271C9.654 27.2238 9.69069 27.1679 9.7154 27.1064C9.74012 27.045 9.75238 26.9792 9.75147 26.913V22.9268C9.75252 22.8598 9.7401 22.7933 9.71496 22.7312C9.68981 22.6691 9.65245 22.6128 9.60509 22.5654C9.55773 22.5181 9.50134 22.4807 9.43926 22.4556C9.37718 22.4304 9.31068 22.4179 9.24371 22.4189Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M10.9702 23.421C10.8916 23.4258 10.8153 23.4491 10.7475 23.4889L7.25948 25.481C7.20168 25.5132 7.15084 25.5565 7.10989 25.6085C7.06895 25.6604 7.03871 25.72 7.02093 25.7837C7.00316 25.8475 6.9982 25.9141 7.00633 25.9798C7.01447 26.0454 7.03555 26.1089 7.06834 26.1663C7.10114 26.2238 7.145 26.2741 7.19739 26.3145C7.24979 26.3549 7.30967 26.3844 7.37358 26.4015C7.43749 26.4186 7.50415 26.423 7.56971 26.4141C7.63528 26.4053 7.69844 26.3836 7.75554 26.3502L11.2435 24.3581C11.3434 24.3034 11.4215 24.2161 11.4651 24.1109C11.5087 24.0057 11.5151 23.8889 11.4832 23.7796C11.4513 23.6703 11.3831 23.5751 11.2898 23.5098C11.1965 23.4446 11.0838 23.4131 10.9702 23.4206V23.421Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblySnowDay = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 23.6636H25.308C27.4773 23.6638 29.458 22.4309 30.4155 20.4843C31.373 18.5379 31.1409 16.2164 29.817 14.4981C28.4929 12.7796 26.3073 11.9633 24.181 12.3931C23.9013 10.9972 22.9971 9.80651 21.7276 9.16201C20.4583 8.51751 18.9634 8.49048 17.6714 9.08849C16.5021 5.86778 12.9434 4.20487 9.72264 5.37411C6.50193 6.5436 4.83902 10.1024 6.00826 13.3231C3.18698 13.412 0.958398 15.7472 1.00059 18.5695C1.04303 21.392 3.34084 23.659 6.16364 23.6636"
         fill="#D0DAD9"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12.5725 15.6435C12.4404 15.6519 12.3146 15.7028 12.214 15.7888C12.1134 15.8748 12.0434 15.9911 12.0146 16.1203C11.9858 16.2495 11.9998 16.3846 12.0543 16.5052C12.1088 16.6258 12.2011 16.7254 12.3171 16.789L16.5797 19.2235C16.6494 19.2643 16.7266 19.2909 16.8068 19.3016C16.8869 19.3124 16.9683 19.3073 17.0464 19.2864C17.1245 19.2655 17.1977 19.2293 17.2618 19.1799C17.3258 19.1306 17.3794 19.069 17.4195 18.9988C17.4595 18.9286 17.4853 18.8511 17.4952 18.7709C17.5052 18.6906 17.4991 18.6093 17.4774 18.5314C17.4557 18.4536 17.4187 18.3807 17.3687 18.3172C17.3186 18.2537 17.2565 18.2008 17.1859 18.1615L12.9233 15.7269C12.8174 15.6645 12.6951 15.6356 12.5725 15.6438V15.6435Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.742 14.419C14.6617 14.4202 14.5825 14.4374 14.5089 14.4692C14.4352 14.5011 14.3686 14.5471 14.3127 14.6047C14.2569 14.6623 14.2129 14.7304 14.1834 14.805C14.1538 14.8796 14.1393 14.9593 14.1405 15.0395V19.9109C14.1394 19.9919 14.1544 20.0723 14.1846 20.1474C14.2148 20.2225 14.2596 20.2908 14.3165 20.3485C14.3733 20.4061 14.441 20.452 14.5157 20.4832C14.5904 20.5144 14.6705 20.5305 14.7515 20.5305C14.8324 20.5305 14.9126 20.5144 14.9873 20.4832C15.0619 20.452 15.1297 20.4061 15.1865 20.3485C15.2434 20.2908 15.2882 20.2225 15.3184 20.1474C15.3486 20.0723 15.3636 19.9919 15.3625 19.9109V15.0395C15.3638 14.9577 15.3486 14.8764 15.3179 14.8006C15.2871 14.7247 15.2415 14.6559 15.1836 14.598C15.1257 14.5401 15.0568 14.4944 14.9809 14.4637C14.9051 14.433 14.8238 14.4177 14.742 14.419Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16.8518 15.6435C16.7558 15.6494 16.6626 15.6778 16.5797 15.7266L12.3171 18.161C12.2465 18.2003 12.1843 18.2532 12.1343 18.3167C12.0842 18.3802 12.0473 18.4531 12.0256 18.531C12.0038 18.6088 11.9978 18.6903 12.0077 18.7705C12.0177 18.8508 12.0434 18.9283 12.0835 18.9985C12.1236 19.0687 12.1772 19.1302 12.2412 19.1796C12.3052 19.229 12.3784 19.2651 12.4565 19.286C12.5346 19.3068 12.6161 19.3121 12.6962 19.3013C12.7763 19.2905 12.8535 19.264 12.9233 19.2231L17.1859 16.7887C17.3079 16.7218 17.4034 16.6152 17.4566 16.4866C17.5099 16.3581 17.5177 16.2153 17.4787 16.0818C17.4398 15.9482 17.3564 15.8318 17.2424 15.7521C17.1284 15.6723 16.9906 15.6338 16.8518 15.643V15.6435Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M18.7598 21.0443C18.5845 21.0554 18.4176 21.123 18.284 21.2371C18.1505 21.3513 18.0577 21.5056 18.0194 21.6771C17.9812 21.8485 17.9997 22.0279 18.0721 22.1879C18.1445 22.348 18.2669 22.4802 18.4209 22.5647L24.0783 25.7958C24.171 25.85 24.2734 25.8852 24.3798 25.8995C24.4861 25.9139 24.5942 25.9071 24.6979 25.8793C24.8015 25.8516 24.8987 25.8035 24.9837 25.738C25.0686 25.6725 25.1398 25.5908 25.193 25.4976C25.2462 25.4044 25.2803 25.3016 25.2935 25.1951C25.3067 25.0886 25.2987 24.9806 25.2699 24.8773C25.241 24.7739 25.192 24.6772 25.1256 24.593C25.0592 24.5087 24.9767 24.4385 24.8829 24.3863L19.2255 21.155C19.0849 21.0722 18.9226 21.0338 18.7598 21.0447V21.0443Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M21.6393 19.419C21.5328 19.4206 21.4276 19.4434 21.3299 19.4857C21.2321 19.528 21.1437 19.589 21.0695 19.6655C20.9954 19.742 20.9371 19.8324 20.8978 19.9314C20.8586 20.0304 20.8393 20.1361 20.841 20.2426V26.7082C20.8395 26.8156 20.8594 26.9223 20.8995 27.022C20.9396 27.1217 20.9991 27.2124 21.0745 27.2889C21.15 27.3654 21.2399 27.4263 21.339 27.4677C21.4381 27.5092 21.5445 27.5305 21.6519 27.5305C21.7593 27.5305 21.8657 27.5092 21.9648 27.4677C22.0639 27.4263 22.1538 27.3654 22.2293 27.2889C22.3047 27.2124 22.3642 27.1217 22.4043 27.022C22.4444 26.9223 22.4643 26.8156 22.4628 26.7082V20.2426C22.4645 20.134 22.4444 20.0262 22.4036 19.9255C22.3628 19.8248 22.3022 19.7334 22.2254 19.6566C22.1486 19.5798 22.0571 19.5192 21.9564 19.4784C21.8557 19.4376 21.7479 19.4173 21.6393 19.419Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M24.4395 21.0442C24.3121 21.052 24.1884 21.0898 24.0783 21.1545L18.4209 24.3856C18.3271 24.4378 18.2446 24.508 18.1782 24.5923C18.1118 24.6766 18.0628 24.7733 18.0339 24.8766C18.0051 24.98 17.9971 25.0881 18.0103 25.1946C18.0235 25.3011 18.0576 25.404 18.1108 25.4971C18.164 25.5903 18.2352 25.672 18.3201 25.7375C18.4051 25.803 18.5023 25.851 18.6059 25.8787C18.7096 25.9064 18.8177 25.9134 18.924 25.8991C19.0304 25.8847 19.1328 25.8495 19.2255 25.7953L24.8829 22.5642C25.0449 22.4755 25.1716 22.3339 25.2423 22.1633C25.313 21.9927 25.3233 21.8032 25.2716 21.6259C25.2199 21.4486 25.1093 21.2942 24.958 21.1884C24.8067 21.0825 24.6238 21.0314 24.4395 21.0436V21.0442Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.46846 23.421C7.36038 23.4278 7.25744 23.4695 7.17512 23.5399C7.0928 23.6102 7.03555 23.7054 7.01198 23.8111C6.98842 23.9168 6.99981 24.0274 7.04444 24.1261C7.08907 24.2247 7.16453 24.3063 7.25948 24.3584L10.7475 26.3504C10.8046 26.3838 10.8677 26.4056 10.9333 26.4144C10.9989 26.4232 11.0655 26.419 11.1294 26.4019C11.1934 26.3848 11.2532 26.3552 11.3056 26.3148C11.358 26.2744 11.4019 26.224 11.4347 26.1666C11.4675 26.1091 11.4886 26.0457 11.4967 25.9801C11.5048 25.9144 11.4999 25.8479 11.4821 25.7841C11.4643 25.7204 11.4341 25.6608 11.3931 25.6088C11.3522 25.5569 11.3013 25.5136 11.2435 25.4814L7.75553 23.4892C7.66886 23.4382 7.56881 23.4145 7.46846 23.4212V23.421Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.24371 22.4189C9.17806 22.42 9.11324 22.434 9.05298 22.4601C8.99271 22.4862 8.93817 22.5238 8.89246 22.5709C8.84676 22.6181 8.81079 22.6738 8.78662 22.7349C8.76244 22.7959 8.75053 22.8611 8.75156 22.9268V26.913C8.75065 26.9792 8.76291 27.045 8.78762 27.1064C8.81234 27.1679 8.84902 27.2238 8.89554 27.271C8.94205 27.3182 8.99747 27.3557 9.05858 27.3812C9.11969 27.4068 9.18527 27.4199 9.25151 27.4199C9.31775 27.4199 9.38333 27.4068 9.44444 27.3812C9.50555 27.3557 9.56097 27.3182 9.60749 27.271C9.654 27.2238 9.69069 27.1679 9.7154 27.1064C9.74012 27.045 9.75238 26.9792 9.75147 26.913V22.9268C9.75252 22.8598 9.7401 22.7933 9.71496 22.7312C9.68981 22.6691 9.65245 22.6128 9.60509 22.5654C9.55773 22.5181 9.50134 22.4807 9.43926 22.4556C9.37718 22.4304 9.31068 22.4179 9.24371 22.4189Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M10.9702 23.421C10.8916 23.4258 10.8153 23.4491 10.7475 23.4889L7.25948 25.481C7.20168 25.5132 7.15084 25.5565 7.10989 25.6085C7.06895 25.6604 7.03871 25.72 7.02093 25.7837C7.00316 25.8475 6.9982 25.9141 7.00633 25.9798C7.01447 26.0454 7.03555 26.1089 7.06834 26.1663C7.10114 26.2238 7.145 26.2741 7.19739 26.3145C7.24979 26.3549 7.30967 26.3844 7.37358 26.4015C7.43749 26.4186 7.50415 26.423 7.56971 26.4141C7.63528 26.4053 7.69844 26.3836 7.75554 26.3502L11.2435 24.3581C11.3434 24.3034 11.4215 24.2161 11.4651 24.1109C11.5087 24.0057 11.5151 23.8889 11.4832 23.7796C11.4513 23.6703 11.3831 23.5751 11.2898 23.5098C11.1965 23.4446 11.0838 23.4131 10.9702 23.4206V23.421Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblySleetDay = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 23.6636H25.308C27.4773 23.6638 29.458 22.4309 30.4155 20.4843C31.373 18.5379 31.1409 16.2164 29.817 14.4981C28.4929 12.7796 26.3073 11.9633 24.181 12.3931C23.9013 10.9972 22.9971 9.80651 21.7276 9.16201C20.4583 8.51751 18.9634 8.49048 17.6714 9.08849C16.5021 5.86778 12.9434 4.20487 9.72264 5.37411C6.50193 6.5436 4.83902 10.1024 6.00826 13.3231C3.18698 13.412 0.958398 15.7472 1.00059 18.5695C1.04303 21.392 3.34084 23.659 6.16364 23.6636"
         fill="#D0DAD9"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12.5725 15.6435C12.4404 15.6519 12.3146 15.7028 12.214 15.7888C12.1134 15.8748 12.0434 15.9911 12.0146 16.1203C11.9858 16.2495 11.9998 16.3846 12.0543 16.5052C12.1088 16.6258 12.2011 16.7254 12.3171 16.7891L16.5797 19.2235C16.6494 19.2643 16.7266 19.2909 16.8068 19.3017C16.8869 19.3125 16.9683 19.3073 17.0464 19.2864C17.1245 19.2656 17.1977 19.2293 17.2618 19.1799C17.3258 19.1306 17.3794 19.069 17.4195 18.9988C17.4595 18.9286 17.4853 18.8511 17.4952 18.7709C17.5052 18.6907 17.4991 18.6093 17.4774 18.5315C17.4557 18.4536 17.4187 18.3807 17.3687 18.3172C17.3186 18.2537 17.2565 18.2008 17.1859 18.1615L12.9233 15.7269C12.8174 15.6646 12.6951 15.6356 12.5725 15.6438V15.6435Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.742 14.419C14.6617 14.4202 14.5825 14.4374 14.5089 14.4692C14.4352 14.5011 14.3686 14.5471 14.3127 14.6047C14.2569 14.6623 14.2129 14.7304 14.1834 14.805C14.1538 14.8796 14.1393 14.9593 14.1405 15.0395V19.9109C14.1394 19.9919 14.1544 20.0723 14.1846 20.1474C14.2148 20.2225 14.2596 20.2908 14.3165 20.3485C14.3733 20.4061 14.441 20.452 14.5157 20.4832C14.5904 20.5144 14.6705 20.5305 14.7515 20.5305C14.8324 20.5305 14.9126 20.5144 14.9873 20.4832C15.0619 20.452 15.1297 20.4061 15.1865 20.3485C15.2434 20.2908 15.2882 20.2225 15.3184 20.1474C15.3486 20.0723 15.3636 19.9919 15.3625 19.9109V15.0395C15.3638 14.9577 15.3486 14.8764 15.3179 14.8006C15.2871 14.7247 15.2415 14.6559 15.1836 14.598C15.1257 14.5401 15.0568 14.4944 14.9809 14.4637C14.9051 14.433 14.8238 14.4177 14.742 14.419Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16.8518 15.6435C16.7558 15.6494 16.6626 15.6778 16.5797 15.7266L12.3171 18.161C12.2465 18.2003 12.1843 18.2532 12.1343 18.3167C12.0842 18.3802 12.0473 18.4531 12.0256 18.531C12.0038 18.6088 11.9978 18.6903 12.0077 18.7705C12.0177 18.8508 12.0434 18.9283 12.0835 18.9985C12.1236 19.0687 12.1772 19.1302 12.2412 19.1796C12.3052 19.229 12.3784 19.2651 12.4565 19.286C12.5346 19.3068 12.6161 19.3121 12.6962 19.3013C12.7763 19.2905 12.8535 19.264 12.9233 19.2231L17.1859 16.7887C17.3079 16.7218 17.4034 16.6152 17.4566 16.4866C17.5099 16.3581 17.5177 16.2153 17.4787 16.0818C17.4398 15.9482 17.3564 15.8318 17.2424 15.7521C17.1284 15.6723 16.9906 15.6338 16.8518 15.643V15.6435Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.46846 23.421C7.36038 23.4278 7.25744 23.4695 7.17512 23.5399C7.0928 23.6102 7.03555 23.7054 7.01198 23.8111C6.98842 23.9168 6.99981 24.0274 7.04444 24.1261C7.08907 24.2247 7.16453 24.3063 7.25948 24.3584L10.7475 26.3504C10.8046 26.3838 10.8677 26.4056 10.9333 26.4144C10.9989 26.4232 11.0655 26.419 11.1294 26.4019C11.1934 26.3848 11.2532 26.3552 11.3056 26.3148C11.358 26.2744 11.4019 26.224 11.4347 26.1666C11.4675 26.1091 11.4886 26.0457 11.4967 25.9801C11.5048 25.9144 11.4999 25.8479 11.4821 25.7841C11.4643 25.7204 11.4341 25.6608 11.3931 25.6088C11.3522 25.5569 11.3013 25.5136 11.2435 25.4814L7.75553 23.4892C7.66886 23.4382 7.56881 23.4145 7.46846 23.4212V23.421Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.24371 22.4189C9.17806 22.42 9.11324 22.434 9.05298 22.4601C8.99271 22.4862 8.93817 22.5238 8.89246 22.5709C8.84676 22.6181 8.81079 22.6738 8.78662 22.7349C8.76244 22.7959 8.75053 22.8611 8.75156 22.9268V26.913C8.75065 26.9792 8.76291 27.045 8.78762 27.1064C8.81234 27.1679 8.84902 27.2238 8.89554 27.271C8.94205 27.3182 8.99747 27.3557 9.05858 27.3812C9.11969 27.4068 9.18527 27.4199 9.25151 27.4199C9.31775 27.4199 9.38333 27.4068 9.44444 27.3812C9.50555 27.3557 9.56097 27.3182 9.60749 27.271C9.654 27.2238 9.69069 27.1679 9.7154 27.1064C9.74012 27.045 9.75238 26.9792 9.75147 26.913V22.9268C9.75252 22.8598 9.7401 22.7933 9.71496 22.7312C9.68981 22.6691 9.65245 22.6128 9.60509 22.5654C9.55773 22.5181 9.50134 22.4807 9.43926 22.4556C9.37718 22.4304 9.31068 22.4179 9.24371 22.4189Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M10.9702 23.421C10.8916 23.4258 10.8153 23.4491 10.7475 23.4889L7.25948 25.481C7.20168 25.5132 7.15084 25.5565 7.10989 25.6085C7.06895 25.6604 7.03871 25.72 7.02093 25.7837C7.00316 25.8475 6.9982 25.9141 7.00633 25.9798C7.01447 26.0454 7.03555 26.1089 7.06834 26.1663C7.10114 26.2238 7.145 26.2741 7.19739 26.3145C7.24979 26.3549 7.30967 26.3844 7.37358 26.4015C7.43749 26.4186 7.50415 26.423 7.56971 26.4141C7.63528 26.4053 7.69844 26.3836 7.75554 26.3502L11.2435 24.3581C11.3434 24.3034 11.4215 24.2161 11.4651 24.1109C11.5087 24.0057 11.5151 23.8889 11.4832 23.7796C11.4513 23.6703 11.3831 23.5751 11.2898 23.5098C11.1965 23.4446 11.0838 23.4131 10.9702 23.4206V23.421Z"
         fill="#60A2D7"
      />
      <path
         d="M22.4878 24.7665C22.4878 25.9094 21.5611 26.8361 20.4181 26.8361C19.2749 26.8361 18.3484 25.9094 18.3484 24.7665C18.3484 23.2142 20.4181 19.5923 20.4181 19.5923C20.4181 19.5923 22.4878 23.2142 22.4878 24.7665Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblyRainyNight = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M24.8485 0.339506C23.7232 0.419093 22.6 0.824412 21.6302 1.57265C19.0731 3.54574 18.5517 7.1563 20.466 9.63727C22.3801 12.1181 26.005 12.5297 28.5622 10.5566C29.28 10.0027 29.8375 9.31973 30.2256 8.56894C29.8885 12.5997 26.6152 15.7627 22.6271 15.7627C18.4146 15.7627 15 12.2341 15 7.88136C15 3.52865 18.4146 0 22.6271 0C23.3998 0 24.1456 0.118733 24.8485 0.339506Z"
         fill="#FDD020"
      />
      <path
         d="M24.7906 27.2447H25.308C27.4773 27.2449 29.458 26.012 30.4155 24.0654C31.373 22.119 31.1409 19.7975 29.817 18.0792C28.4929 16.3607 26.3073 15.5445 24.181 15.9742C23.9013 14.5783 22.9971 13.3876 21.7276 12.7431C20.4583 12.0986 18.9634 12.0716 17.6714 12.6696C16.5021 9.44889 12.9434 7.78598 9.72264 8.95522C6.50193 10.1247 4.83902 13.6835 6.00826 16.9042C3.18698 16.9931 0.958398 19.3283 1.00059 22.1506C1.04303 24.9732 3.34084 27.2401 6.16364 27.2447"
         fill="#A3B3BA"
      />
      <path
         d="M22.4878 28.3476C22.4878 29.4906 21.5611 30.4173 20.4181 30.4173C19.2749 30.4173 18.3484 29.4906 18.3484 28.3476C18.3484 26.7953 20.4181 23.1734 20.4181 23.1734C20.4181 23.1734 22.4878 26.7953 22.4878 28.3476Z"
         fill="#60A2D7"
      />
      <path
         d="M17.3136 23.1742C17.3136 24.3171 16.3869 25.2438 15.2439 25.2438C14.1007 25.2438 13.1742 24.3171 13.1742 23.1742C13.1742 21.6219 15.2439 18 15.2439 18C15.2439 18 17.3136 21.6219 17.3136 23.1742Z"
         fill="#60A2D7"
      />
      <path
         d="M12.1393 29.3829C12.1393 30.5258 11.2126 31.4525 10.0697 31.4525C8.92645 31.4525 8 30.5258 8 29.3829C8 27.8306 10.0697 24.2087 10.0697 24.2087C10.0697 24.2087 12.1393 27.8306 12.1393 29.3829Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblyRainyDay = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 23.6636H25.308C27.4773 23.6638 29.458 22.4309 30.4155 20.4843C31.373 18.5379 31.1409 16.2164 29.817 14.4981C28.4929 12.7796 26.3073 11.9633 24.181 12.3931C23.9013 10.9972 22.9971 9.80651 21.7276 9.16201C20.4583 8.51751 18.9634 8.49048 17.6714 9.08849C16.5021 5.86778 12.9434 4.20487 9.72264 5.37411C6.50193 6.5436 4.83902 10.1024 6.00826 13.3231C3.18698 13.412 0.958398 15.7472 1.00059 18.5695C1.04303 21.392 3.34084 23.659 6.16364 23.6636"
         fill="#D0DAD9"
      />
      <path
         d="M22.4977 24.7665C22.4977 25.9094 21.571 26.8361 20.4281 26.8361C19.2848 26.8361 18.3584 25.9094 18.3584 24.7665C18.3584 23.2142 20.4281 19.5923 20.4281 19.5923C20.4281 19.5923 22.4977 23.2142 22.4977 24.7665Z"
         fill="#60A2D7"
      />
      <path
         d="M17.3235 19.5931C17.3235 20.736 16.3968 21.6627 15.2538 21.6627C14.1106 21.6627 13.1842 20.736 13.1842 19.5931C13.1842 18.0408 15.2538 14.4189 15.2538 14.4189C15.2538 14.4189 17.3235 18.0408 17.3235 19.5931Z"
         fill="#60A2D7"
      />
      <path
         d="M12.1493 25.8018C12.1493 26.9447 11.2226 27.8714 10.0796 27.8714C8.9364 27.8714 8.00995 26.9447 8.00995 25.8018C8.00995 24.2495 10.0796 20.6276 10.0796 20.6276C10.0796 20.6276 12.1493 24.2495 12.1493 25.8018Z"
         fill="#60A2D7"
      />
   </svg>
)

const Windy = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 19.6636H25.308C27.4773 19.6638 29.458 18.4309 30.4155 16.4843C31.373 14.5379 31.1409 12.2164 29.817 10.4981C28.4929 8.77963 26.3073 7.96334 24.181 8.39308C23.9013 6.99722 22.9971 5.80651 21.7276 5.16201C20.4583 4.51751 18.9634 4.49048 17.6714 5.08849C16.5021 1.86778 12.9434 0.204868 9.72264 1.37411C6.50193 2.5436 4.83902 6.10235 6.00826 9.32307C3.18698 9.412 0.958398 11.7472 1.00059 14.5695C1.04303 17.392 3.34084 19.659 6.16364 19.6636"
         fill="#D0DAD9"
      />
      <path
         d="M22.5 24H13.5C13.2239 24 13 24.2236 13 24.5C13 24.7764 13.2239 25 13.5 25H22.5C23.3271 25 24 24.3271 24 23.5C24 22.6729 23.3271 22 22.5 22C22.2239 22 22 22.2236 22 22.5C22 22.7764 22.2239 23 22.5 23C22.7756 23 23 23.2241 23 23.5C23 23.7759 22.7756 24 22.5 24Z"
         fill="#60A2D7"
      />
      <path
         d="M20.5 26H15.5C15.2239 26 15 26.2236 15 26.5C15 26.7764 15.2239 27 15.5 27H20.5C21.3271 27 22 27.6729 22 28.5C22 29.3271 21.3271 30 20.5 30C19.6729 30 19 29.3271 19 28.5C19 28.2236 18.7761 28 18.5 28C18.2239 28 18 28.2236 18 28.5C18 29.8784 19.1216 31 20.5 31C21.8784 31 23 29.8784 23 28.5C23 27.1216 21.8784 26 20.5 26Z"
         fill="#60A2D7"
      />
      <path
         d="M26.5 26H23.5C23.2239 26 23 26.2236 23 26.5C23 26.7764 23.2239 27 23.5 27H26.5C26.7761 27 27 26.7764 27 26.5C27 26.2236 26.7761 26 26.5 26Z"
         fill="#60A2D7"
      />
      <path
         d="M13 26.5C13 26.2236 12.7761 26 12.5 26H6.5C6.22388 26 6 26.2236 6 26.5C6 26.7764 6.22388 27 6.5 27H12.5C12.7761 27 13 26.7764 13 26.5Z"
         fill="#60A2D7"
      />
      <path
         d="M12 24.5C12 24.2236 11.7761 24 11.5 24H5.5C5.22388 24 5 24.2236 5 24.5C5 24.7764 5.22388 25 5.5 25H11.5C11.7761 25 12 24.7764 12 24.5Z"
         fill="#60A2D7"
      />
      <path
         d="M14.5 28H8.5C8.22388 28 8 28.2236 8 28.5C8 28.7764 8.22388 29 8.5 29H14.5C14.7761 29 15 28.7764 15 28.5C15 28.2236 14.7761 28 14.5 28Z"
         fill="#60A2D7"
      />
   </svg>
)

const Thunderstorm = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 3H7L3 14.5385H12L9.17578 28L21 10.6923H13L15 3Z" fill="#FFA754" />
      <path
         d="M29 24C29 26.209 27.209 28 25 28C22.7905 28 21 26.209 21 24C21 21 25 14 25 14C25 14 29 21 29 24Z"
         fill="#60A2D7"
      />
   </svg>
)

const Snow = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.92509 6.96141C9.71355 6.97479 9.51206 7.05639 9.35093 7.19413C9.18981 7.33187 9.07776 7.51813 9.03163 7.72503C8.98551 7.93193 9.0078 8.14833 9.09516 8.34147C9.18252 8.53461 9.33022 8.69417 9.51605 8.79615L16.3431 12.6952C16.4549 12.7606 16.5785 12.8031 16.7069 12.8204C16.8352 12.8377 16.9657 12.8295 17.0908 12.7961C17.2158 12.7626 17.3331 12.7045 17.4356 12.6255C17.5382 12.5464 17.624 12.4479 17.6882 12.3354C17.7524 12.2229 17.7936 12.0988 17.8096 11.9703C17.8255 11.8418 17.8158 11.7116 17.781 11.5868C17.7462 11.4621 17.687 11.3454 17.6069 11.2437C17.5267 11.142 17.4272 11.0573 17.3141 10.9943L10.487 7.09498C10.3173 6.9951 10.1215 6.94873 9.92509 6.96189V6.96141Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13.3998 5.00012C13.2713 5.00212 13.1444 5.0296 13.0265 5.08064C12.9085 5.13167 12.8018 5.20529 12.7123 5.29758C12.6229 5.38988 12.5525 5.49896 12.5051 5.61847C12.4578 5.73797 12.4345 5.86554 12.4365 5.99406V13.7963C12.4347 13.9259 12.4587 14.0547 12.5071 14.175C12.5555 14.2953 12.6273 14.4047 12.7183 14.4971C12.8094 14.5894 12.9178 14.6628 13.0375 14.7128C13.1571 14.7628 13.2854 14.7886 13.4151 14.7886C13.5447 14.7886 13.6731 14.7628 13.7927 14.7128C13.9123 14.6628 14.0208 14.5894 14.1118 14.4971C14.2029 14.4047 14.2747 14.2953 14.323 14.175C14.3714 14.0547 14.3954 13.9259 14.3936 13.7963V5.99406C14.3957 5.86298 14.3714 5.73281 14.3222 5.6113C14.273 5.48979 14.1998 5.37954 14.1071 5.28683C14.0144 5.19413 13.9041 5.12102 13.7825 5.0718C13.661 5.02257 13.5309 4.99807 13.3998 5.00012Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16.779 6.96135C16.6252 6.9708 16.4759 7.01639 16.3431 7.09443L9.51604 10.9935C9.4029 11.0565 9.30339 11.1412 9.22324 11.243C9.1431 11.3447 9.08392 11.4613 9.04912 11.5861C9.01433 11.7108 9.00462 11.8413 9.02055 11.9698C9.03647 12.0983 9.07772 12.2224 9.14191 12.3349C9.2061 12.4473 9.29196 12.5459 9.39451 12.6249C9.49706 12.704 9.61427 12.7618 9.73936 12.7953C9.86444 12.8287 9.99492 12.8372 10.1233 12.8199C10.2516 12.8026 10.3752 12.7601 10.487 12.6947L17.3141 8.79561C17.5095 8.68848 17.6625 8.51765 17.7477 8.31178C17.833 8.1059 17.8455 7.87726 17.7831 7.66332C17.7207 7.44939 17.5872 7.26304 17.4046 7.13529C17.222 7.00754 17.0013 6.94592 16.779 6.96063V6.96135Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M19.835 15.6115C19.5542 15.6293 19.2868 15.7376 19.0729 15.9204C18.8591 16.1032 18.7104 16.3504 18.6491 16.625C18.5879 16.8996 18.6175 17.1868 18.7335 17.4432C18.8494 17.6995 19.0454 17.9113 19.2921 18.0467L28.3533 23.2217C28.5017 23.3085 28.6657 23.3649 28.8361 23.3879C29.0064 23.4108 29.1796 23.3999 29.3456 23.3555C29.5116 23.3111 29.6672 23.234 29.8033 23.1291C29.9394 23.0242 30.0533 22.8934 30.1385 22.7441C30.2237 22.5949 30.2785 22.4301 30.2996 22.2596C30.3208 22.089 30.3079 21.9161 30.2617 21.7506C30.2155 21.585 30.137 21.4302 30.0306 21.2952C29.9242 21.1602 29.7921 21.0477 29.642 20.9641L20.5808 15.7888C20.3556 15.6562 20.0957 15.5947 19.835 15.6121V15.6115Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M24.4468 13.0083C24.2762 13.011 24.1078 13.0475 23.9513 13.1152C23.7947 13.1829 23.653 13.2806 23.5343 13.4031C23.4155 13.5256 23.3221 13.6704 23.2593 13.829C23.1965 13.9876 23.1655 14.157 23.1682 14.3275V24.683C23.1659 24.8551 23.1977 25.026 23.2619 25.1856C23.3261 25.3453 23.4214 25.4906 23.5423 25.6131C23.6631 25.7356 23.8071 25.8331 23.9658 25.8995C24.1246 25.9659 24.2949 26 24.467 26C24.6391 26 24.8095 25.9659 24.9682 25.8995C25.127 25.8331 25.2709 25.7356 25.3918 25.6131C25.5126 25.4906 25.6079 25.3453 25.6721 25.1856C25.7363 25.026 25.7682 24.8551 25.7658 24.683V14.3275C25.7685 14.1536 25.7363 13.9808 25.671 13.8195C25.6056 13.6582 25.5086 13.5119 25.3856 13.3889C25.2625 13.2658 25.116 13.1688 24.9547 13.1035C24.7935 13.0381 24.6207 13.0056 24.4468 13.0083Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M28.9318 15.6114C28.7278 15.6239 28.5295 15.6844 28.3533 15.788L19.2921 20.963C19.1419 21.0466 19.0098 21.1591 18.9035 21.2941C18.7971 21.4291 18.7185 21.5839 18.6724 21.7495C18.6262 21.915 18.6133 22.0882 18.6344 22.2588C18.6556 22.4293 18.7103 22.5941 18.7955 22.7433C18.8807 22.8926 18.9947 23.0234 19.1308 23.1283C19.2669 23.2333 19.4225 23.31 19.5885 23.3544C19.7545 23.3988 19.9277 23.41 20.098 23.3871C20.2683 23.3641 20.4324 23.3077 20.5807 23.2209L29.642 18.0459C29.9013 17.9037 30.1044 17.677 30.2176 17.4037C30.3308 17.1305 30.3474 16.827 30.2646 16.5431C30.1817 16.2591 30.0045 16.0118 29.7622 15.8422C29.5198 15.6727 29.2269 15.5909 28.9318 15.6104V15.6114Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M1.7503 19.4181C1.57719 19.429 1.41232 19.4958 1.28047 19.6085C1.14863 19.7212 1.05694 19.8736 1.01919 20.0429C0.981447 20.2122 0.999688 20.3893 1.07117 20.5474C1.14266 20.7054 1.26352 20.836 1.41559 20.9194L7.0021 24.11C7.09356 24.1635 7.19472 24.1983 7.29973 24.2124C7.40474 24.2266 7.51151 24.2199 7.61387 24.1925C7.71623 24.1651 7.81214 24.1176 7.89605 24.0529C7.97997 23.9882 8.05022 23.9076 8.10275 23.8156C8.15527 23.7235 8.18903 23.622 8.20206 23.5168C8.21509 23.4117 8.20715 23.3051 8.17868 23.203C8.15021 23.1009 8.10178 23.0055 8.0362 22.9223C7.97061 22.839 7.88918 22.7697 7.79661 22.7181L2.21009 19.5274C2.07127 19.4457 1.91103 19.4077 1.7503 19.4185V19.4181Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M4.59361 17.8132C4.48845 17.8148 4.38464 17.8373 4.28812 17.8791C4.19159 17.9208 4.10423 17.9811 4.03104 18.0566C3.95784 18.1321 3.90023 18.2214 3.86151 18.3192C3.82279 18.417 3.80371 18.5213 3.80535 18.6265V25.011C3.8039 25.117 3.82353 25.2224 3.86312 25.3208C3.90271 25.4193 3.96146 25.5089 4.03596 25.5844C4.11046 25.6599 4.19923 25.72 4.2971 25.7609C4.39498 25.8019 4.50001 25.8229 4.6061 25.8229C4.71219 25.8229 4.81723 25.8019 4.9151 25.7609C5.01298 25.72 5.10175 25.6599 5.17625 25.5844C5.25075 25.5089 5.3095 25.4193 5.34909 25.3208C5.38867 25.2224 5.40831 25.117 5.40685 25.011V18.6265C5.40853 18.5192 5.38865 18.4127 5.34837 18.3133C5.3081 18.2139 5.24826 18.1237 5.17241 18.0478C5.09655 17.9719 5.00623 17.9121 4.9068 17.8718C4.80738 17.8316 4.70087 17.8115 4.59361 17.8132Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.35878 19.418C7.23298 19.4258 7.11078 19.4631 7.00212 19.5269L1.41561 22.7175C1.32303 22.769 1.2416 22.8384 1.17602 22.9216C1.11044 23.0048 1.06201 23.1003 1.03354 23.2024C1.00507 23.3044 0.997119 23.4112 1.01015 23.5164C1.02319 23.6215 1.05694 23.7231 1.10947 23.8151C1.16199 23.9071 1.23224 23.9878 1.31616 24.0525C1.40007 24.1172 1.49599 24.1645 1.59834 24.1919C1.7007 24.2192 1.80747 24.2261 1.91248 24.212C2.01749 24.1978 2.11865 24.163 2.21011 24.1095L7.79663 20.919C7.95652 20.8313 8.08171 20.6915 8.1515 20.5231C8.2213 20.3546 8.23152 20.1675 8.18046 19.9924C8.12941 19.8174 8.02015 19.6649 7.87074 19.5604C7.72133 19.4558 7.54072 19.4054 7.35878 19.4174V19.418Z"
         fill="#60A2D7"
      />
   </svg>
)

const Sleet = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M11.4868 6.30464C11.2383 6.32035 11.0015 6.41624 10.8122 6.57808C10.6229 6.73992 10.4912 6.95877 10.437 7.20188C10.3828 7.44498 10.409 7.69924 10.5117 7.92618C10.6143 8.15311 10.7879 8.3406 11.0062 8.46042L19.0279 13.0418C19.1593 13.1186 19.3045 13.1686 19.4553 13.1889C19.6061 13.2092 19.7594 13.1996 19.9064 13.1602C20.0534 13.1209 20.1911 13.0527 20.3116 12.9598C20.4321 12.8669 20.5329 12.7511 20.6084 12.619C20.6838 12.4868 20.7323 12.341 20.751 12.19C20.7697 12.039 20.7583 11.886 20.7174 11.7394C20.6765 11.5929 20.607 11.4558 20.5128 11.3363C20.4186 11.2168 20.3017 11.1172 20.1688 11.0432L12.1471 6.46157C11.9477 6.34422 11.7176 6.28974 11.4868 6.3052V6.30464Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M15.5696 4.00014C15.4186 4.00249 15.2695 4.03478 15.1309 4.09475C14.9923 4.15471 14.8669 4.24121 14.7618 4.34966C14.6567 4.4581 14.574 4.58627 14.5184 4.72669C14.4628 4.8671 14.4354 5.017 14.4377 5.16801V14.3355C14.4356 14.4878 14.4638 14.6391 14.5207 14.7804C14.5775 14.9218 14.6619 15.0504 14.7688 15.1589C14.8758 15.2673 15.0033 15.3536 15.1438 15.4124C15.2844 15.4712 15.4352 15.5014 15.5875 15.5014C15.7399 15.5014 15.8907 15.4712 16.0312 15.4124C16.1718 15.3536 16.2992 15.2673 16.4062 15.1589C16.5132 15.0504 16.5975 14.9218 16.6544 14.7804C16.7112 14.6391 16.7394 14.4878 16.7373 14.3355V5.16801C16.7397 5.01398 16.7112 4.86104 16.6534 4.71827C16.5955 4.57549 16.5096 4.44595 16.4007 4.33702C16.2918 4.2281 16.1621 4.1422 16.0193 4.08436C15.8765 4.02652 15.7236 3.99773 15.5696 4.00014Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M19.5401 6.30456C19.3594 6.31566 19.184 6.36923 19.0279 6.46093L11.0062 11.0423C10.8733 11.1163 10.7564 11.2159 10.6622 11.3354C10.568 11.4549 10.4985 11.592 10.4576 11.7385C10.4167 11.8851 10.4053 12.0384 10.424 12.1894C10.4428 12.3404 10.4912 12.4862 10.5666 12.6183C10.6421 12.7505 10.7429 12.8663 10.8634 12.9592C10.9839 13.0521 11.1217 13.12 11.2686 13.1593C11.4156 13.1986 11.5689 13.2086 11.7197 13.1882C11.8705 13.1679 12.0157 13.118 12.1471 13.0411L20.1688 8.45978C20.3984 8.33391 20.5781 8.13319 20.6784 7.89129C20.7786 7.64939 20.7932 7.38074 20.7199 7.12937C20.6466 6.878 20.4897 6.65904 20.2752 6.50894C20.0607 6.35884 19.8013 6.28643 19.5401 6.30372V6.30456Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M1.88159 20.941C1.67819 20.9539 1.48447 21.0323 1.32955 21.1648C1.17463 21.2972 1.0669 21.4763 1.02255 21.6752C0.9782 21.8741 0.999633 22.0822 1.08363 22.2679C1.16762 22.4536 1.30963 22.607 1.48831 22.7051L8.05237 26.4539C8.15983 26.5168 8.2787 26.5577 8.40208 26.5743C8.52546 26.5909 8.65092 26.5831 8.77119 26.5509C8.89145 26.5187 9.00415 26.4629 9.10275 26.3869C9.20135 26.3108 9.28389 26.2161 9.34561 26.108C9.40733 25.9998 9.44699 25.8805 9.4623 25.757C9.47762 25.6334 9.46828 25.5082 9.43482 25.3882C9.40137 25.2683 9.34447 25.1562 9.26741 25.0584C9.19036 24.9606 9.09468 24.8791 8.9859 24.8185L2.42184 21.0694C2.25872 20.9734 2.07044 20.9288 1.88159 20.9415V20.941Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M5.22247 19.0553C5.09891 19.0572 4.97694 19.0836 4.86352 19.1327C4.7501 19.1818 4.64746 19.2526 4.56145 19.3413C4.47545 19.43 4.40776 19.5349 4.36226 19.6498C4.31676 19.7647 4.29434 19.8874 4.29628 20.0109V27.5126C4.29457 27.6372 4.31764 27.761 4.36415 27.8767C4.41067 27.9923 4.4797 28.0976 4.56724 28.1863C4.65477 28.2751 4.75907 28.3457 4.87408 28.3938C4.98908 28.4419 5.11249 28.4666 5.23715 28.4666C5.3618 28.4666 5.48522 28.4419 5.60022 28.3938C5.71522 28.3457 5.81952 28.2751 5.90706 28.1863C5.99459 28.0976 6.06363 27.9923 6.11014 27.8767C6.15665 27.761 6.17973 27.6372 6.17802 27.5126V20.0109C6.17999 19.8849 6.15662 19.7598 6.1093 19.6429C6.06198 19.5261 5.99167 19.4201 5.90255 19.331C5.81342 19.2418 5.70729 19.1715 5.59047 19.1242C5.47364 19.0769 5.3485 19.0533 5.22247 19.0553Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8.47145 20.941C8.32364 20.9501 8.18006 20.9939 8.05239 21.0689L1.48832 24.8178C1.37955 24.8784 1.28387 24.9598 1.20681 25.0576C1.12975 25.1554 1.07285 25.2676 1.0394 25.3875C1.00595 25.5074 0.996609 25.6329 1.01192 25.7564C1.02724 25.88 1.0669 25.9993 1.12862 26.1075C1.19033 26.2156 1.27288 26.3103 1.37148 26.3863C1.47008 26.4624 1.58277 26.518 1.70304 26.5501C1.82331 26.5823 1.94876 26.5904 2.07214 26.5738C2.19553 26.5572 2.31439 26.5163 2.42185 26.4534L8.98592 22.7046C9.17379 22.6016 9.32089 22.4373 9.40289 22.2394C9.4849 22.0414 9.49691 21.8216 9.43692 21.6159C9.37693 21.4102 9.24855 21.231 9.073 21.1082C8.89744 20.9854 8.68523 20.9261 8.47145 20.9403V20.941Z"
         fill="#60A2D7"
      />
      <path
         d="M30.1464 23.4731C30.1464 25.624 28.4024 27.368 26.2515 27.368C24.1001 27.368 22.3566 25.624 22.3566 23.4731C22.3566 20.5519 26.2515 13.7358 26.2515 13.7358C26.2515 13.7358 30.1464 20.5519 30.1464 23.4731Z"
         fill="#60A2D7"
      />
   </svg>
)

const Rainy = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M30 22.9984C30 25.2073 28.209 26.9983 26 26.9983C23.7906 26.9983 22.0001 25.2073 22.0001 22.9984C22.0001 19.9984 26 12.9985 26 12.9985C26 12.9985 30 19.9984 30 22.9984Z"
         fill="#60A2D7"
      />
      <path
         d="M20 12.9999C20 15.2089 18.209 16.9999 16 16.9999C13.7905 16.9999 12 15.2089 12 12.9999C12 9.99995 16 3 16 3C16 3 20 9.99995 20 12.9999Z"
         fill="#60A2D7"
      />
      <path
         d="M9.99993 24.9993C9.99993 27.2083 8.20893 28.9993 5.99997 28.9993C3.79051 28.9993 2 27.2083 2 24.9993C2 21.9993 5.99997 14.9994 5.99997 14.9994C5.99997 14.9994 9.99993 21.9993 9.99993 24.9993Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblyThunderstormNight = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M25.2807 22.6636H25.7982C27.9674 22.6638 29.9481 21.4309 30.9056 19.4843C31.8631 17.5379 31.631 15.2164 30.3071 13.4981C28.983 11.7796 26.7974 10.9633 24.6711 11.3931C24.3914 9.99722 23.4872 8.80651 22.2177 8.16201C20.9484 7.51751 19.4535 7.49048 18.1615 8.08849C16.9922 4.86778 13.4335 3.20487 10.2128 4.37411C6.99204 5.5436 5.32913 9.10235 6.49837 12.3231C3.67709 12.412 1.44851 14.7472 1.4907 17.5695C1.53315 20.392 3.83095 22.659 6.65375 22.6636"
         fill="#A3B3BA"
      />
      <path
         d="M15.4496 14.3848H11.3103L9.2406 20.5938H13.8974L12.4361 27.8376L18.5541 18.5241H14.4148L15.4496 14.3848Z"
         fill="#FFA754"
      />
      <path
         d="M22.6939 25.7679C22.6939 26.9109 21.7672 27.8376 20.6242 27.8376C19.481 27.8376 18.5546 26.9109 18.5546 25.7679C18.5546 24.2157 20.6242 20.5938 20.6242 20.5938C20.6242 20.5938 22.6939 24.2157 22.6939 25.7679Z"
         fill="#60A2D7"
      />
   </svg>
)

const PossiblyThunderstormDay = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M25.2807 22.6636H25.7982C27.9674 22.6638 29.9481 21.4309 30.9056 19.4843C31.8631 17.5379 31.631 15.2164 30.3071 13.4981C28.983 11.7796 26.7974 10.9633 24.6711 11.3931C24.3914 9.99722 23.4872 8.80651 22.2177 8.16201C20.9484 7.51751 19.4535 7.49048 18.1615 8.08849C16.9922 4.86778 13.4335 3.20487 10.2128 4.37411C6.99204 5.5436 5.32913 9.10235 6.49837 12.3231C3.67709 12.412 1.44851 14.7472 1.4907 17.5695C1.53315 20.392 3.83095 22.659 6.65375 22.6636"
         fill="#D0DAD9"
      />
      <path
         d="M15.4496 14.3848H11.3103L9.2406 20.5938H13.8974L12.4361 27.8376L18.5541 18.5241H14.4148L15.4496 14.3848Z"
         fill="#FFA754"
      />
      <path
         d="M22.6939 25.7679C22.6939 26.9109 21.7672 27.8376 20.6242 27.8376C19.481 27.8376 18.5546 26.9109 18.5546 25.7679C18.5546 24.2157 20.6242 20.5938 20.6242 20.5938C20.6242 20.5938 22.6939 24.2157 22.6939 25.7679Z"
         fill="#60A2D7"
      />
   </svg>
)

const ChanceSnow = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 20.6636H25.308C27.4773 20.6638 29.458 19.4309 30.4155 17.4843C31.373 15.5379 31.1409 13.2164 29.817 11.4981C28.4929 9.77963 26.3073 8.96334 24.181 9.39308C23.9013 7.99722 22.9971 6.80651 21.7276 6.16201C20.4583 5.51751 18.9634 5.49048 17.6714 6.08849C16.5021 2.86778 12.9434 1.20487 9.72264 2.37411C6.50193 3.5436 4.83902 7.10235 6.00826 10.3231C3.18698 10.412 0.958398 12.7472 1.00059 15.5695C1.04303 18.392 3.34084 20.659 6.16364 20.6636"
         fill="#A3B3BA"
      />
      <g clip-path="url(#clip0)">
         <path
            d="M15.0156 8.46248C12.2585 8.46248 10.0156 10.7053 10.0156 13.4625C10.0156 16.2196 12.2585 18.4625 15.0156 18.4625C17.7728 18.4625 20.0156 16.2196 20.0156 13.4625C20.0156 10.7053 17.7728 8.46248 15.0156 8.46248Z"
            fill="white"
         />
         <path
            d="M16.7816 14.3019L15.3873 13.2562V11.1261C15.3873 10.9119 15.2142 10.7388 15 10.7388C14.7858 10.7388 14.6127 10.9119 14.6127 11.1261V13.4499C14.6127 13.5719 14.67 13.6869 14.7676 13.7597L16.3168 14.9216C16.3865 14.9739 16.4678 14.9991 16.5488 14.9991C16.6669 14.9991 16.7831 14.946 16.859 14.8438C16.9876 14.6729 16.9528 14.4301 16.7816 14.3019Z"
            fill="#A3B3BA"
         />
      </g>
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M19.8322 19.0074C19.6402 19.0195 19.4573 19.0936 19.3111 19.2186C19.1649 19.3436 19.0632 19.5127 19.0213 19.7005C18.9794 19.8883 18.9997 20.0847 19.0789 20.26C19.1582 20.4353 19.2923 20.5801 19.461 20.6727L25.6575 24.2116C25.7589 24.271 25.8711 24.3096 25.9876 24.3253C26.1041 24.3409 26.2225 24.3335 26.336 24.3031C26.4496 24.2728 26.556 24.2201 26.649 24.1483C26.7421 24.0765 26.82 23.9871 26.8783 23.885C26.9366 23.7829 26.974 23.6703 26.9885 23.5537C27.0029 23.437 26.9941 23.3188 26.9625 23.2056C26.9309 23.0924 26.8772 22.9865 26.8045 22.8942C26.7317 22.8019 26.6414 22.725 26.5387 22.6678L20.3422 19.1286C20.1882 19.038 20.0105 18.9959 19.8322 19.0078V19.0074Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M22.986 17.2273C22.8694 17.2291 22.7542 17.254 22.6472 17.3003C22.5401 17.3467 22.4432 17.4135 22.362 17.4972C22.2808 17.581 22.2169 17.68 22.174 17.7885C22.131 17.897 22.1099 18.0127 22.1117 18.1294V25.211C22.1101 25.3286 22.1318 25.4455 22.1758 25.5547C22.2197 25.6639 22.2848 25.7632 22.3675 25.847C22.4501 25.9308 22.5486 25.9974 22.6571 26.0428C22.7657 26.0883 22.8822 26.1116 22.9999 26.1116C23.1175 26.1116 23.234 26.0883 23.3426 26.0428C23.4512 25.9974 23.5496 25.9308 23.6323 25.847C23.7149 25.7632 23.7801 25.6639 23.824 25.5547C23.8679 25.4455 23.8897 25.3286 23.888 25.211V18.1294C23.8899 18.0104 23.8679 17.8923 23.8232 17.782C23.7785 17.6717 23.7121 17.5716 23.628 17.4875C23.5439 17.4033 23.4437 17.337 23.3334 17.2923C23.2231 17.2476 23.105 17.2254 22.986 17.2273Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M26.053 19.0073C25.9135 19.0159 25.778 19.0573 25.6574 19.1281L19.4609 22.6671C19.3582 22.7243 19.2679 22.8012 19.1952 22.8935C19.1224 22.9858 19.0687 23.0917 19.0371 23.2049C19.0056 23.3181 18.9967 23.4365 19.0112 23.5532C19.0257 23.6698 19.0631 23.7825 19.1214 23.8845C19.1796 23.9866 19.2575 24.076 19.3506 24.1478C19.4437 24.2196 19.5501 24.272 19.6636 24.3024C19.7772 24.3328 19.8956 24.3405 20.0121 24.3248C20.1285 24.3091 20.2407 24.2705 20.3422 24.2111L26.5387 20.6722C26.7161 20.5749 26.8549 20.4199 26.9323 20.233C27.0097 20.0462 27.0211 19.8386 26.9644 19.6445C26.9078 19.4503 26.7866 19.2812 26.6209 19.1652C26.4552 19.0493 26.2549 18.9933 26.053 19.0067V19.0073Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.4685 25.0021C14.3604 25.0089 14.2574 25.0506 14.1751 25.121C14.0928 25.1914 14.0355 25.2865 14.012 25.3922C13.9884 25.4979 13.9998 25.6085 14.0444 25.7072C14.0891 25.8059 14.1645 25.8874 14.2595 25.9395L17.7475 27.9315C17.8046 27.9649 17.8677 27.9867 17.9333 27.9955C17.9989 28.0043 18.0655 28.0002 18.1294 27.9831C18.1934 27.966 18.2532 27.9363 18.3056 27.8959C18.358 27.8555 18.4019 27.8052 18.4347 27.7477C18.4675 27.6902 18.4886 27.6268 18.4967 27.5612C18.5048 27.4955 18.4999 27.429 18.4821 27.3653C18.4643 27.3015 18.4341 27.2419 18.3931 27.19C18.3522 27.138 18.3013 27.0947 18.2435 27.0625L14.7555 25.0703C14.6689 25.0193 14.5688 24.9956 14.4685 25.0023V25.0021Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16.2437 24.0001C16.178 24.0011 16.1132 24.0151 16.0529 24.0412C15.9927 24.0673 15.9381 24.1049 15.8924 24.152C15.8467 24.1992 15.8108 24.2549 15.7866 24.316C15.7624 24.377 15.7505 24.4422 15.7515 24.5079V28.4941C15.7506 28.5603 15.7629 28.6261 15.7876 28.6876C15.8123 28.749 15.849 28.805 15.8955 28.8521C15.942 28.8993 15.9974 28.9368 16.0586 28.9623C16.1197 28.9879 16.1852 29.001 16.2515 29.001C16.3177 29.001 16.3833 28.9879 16.4444 28.9623C16.5055 28.9368 16.5609 28.8993 16.6075 28.8521C16.654 28.805 16.6907 28.749 16.7154 28.6876C16.7401 28.6261 16.7524 28.5603 16.7514 28.4941V24.5079C16.7525 24.4409 16.7401 24.3744 16.7149 24.3123C16.6898 24.2502 16.6524 24.1939 16.6051 24.1465C16.5577 24.0992 16.5013 24.0618 16.4392 24.0367C16.3772 24.0115 16.3107 23.999 16.2437 24.0001Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M17.9701 25.0021C17.8916 25.0069 17.8153 25.0302 17.7475 25.0701L14.2595 27.0621C14.2017 27.0943 14.1508 27.1376 14.1099 27.1896C14.0689 27.2415 14.0387 27.3011 14.0209 27.3649C14.0031 27.4286 13.9982 27.4953 14.0063 27.5609C14.0144 27.6266 14.0355 27.69 14.0683 27.7474C14.1011 27.8049 14.145 27.8552 14.1974 27.8956C14.2498 27.936 14.3096 27.9656 14.3735 27.9827C14.4375 27.9998 14.5041 28.0041 14.5697 27.9952C14.6352 27.9864 14.6984 27.9647 14.7555 27.9313L18.2435 25.9392C18.3433 25.8845 18.4215 25.7972 18.4651 25.692C18.5087 25.5868 18.515 25.47 18.4832 25.3607C18.4513 25.2514 18.3831 25.1562 18.2898 25.0909C18.1965 25.0257 18.0837 24.9942 17.9701 25.0017V25.0021Z"
         fill="#60A2D7"
      />
      <defs>
         <clipPath id="clip0">
            <rect width="10" height="10" fill="white" transform="translate(10 8.41888)" />
         </clipPath>
      </defs>
   </svg>
)

const ChanceSleet = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 21.6636H25.308C27.4773 21.6638 29.458 20.4309 30.4155 18.4843C31.373 16.5379 31.1409 14.2164 29.817 12.4981C28.4929 10.7796 26.3073 9.96334 24.181 10.3931C23.9013 8.99722 22.9971 7.80651 21.7276 7.16201C20.4583 6.51751 18.9634 6.49048 17.6714 7.08849C16.5021 3.86778 12.9434 2.20487 9.72264 3.37411C6.50193 4.5436 4.83902 8.10235 6.00826 11.3231C3.18698 11.412 0.958398 13.7472 1.00059 16.5695C1.04303 19.392 3.34084 21.659 6.16364 21.6636"
         fill="#A3B3BA"
      />
      <g clip-path="url(#clip0)">
         <path
            d="M15.0156 9.46248C12.2585 9.46248 10.0156 11.7053 10.0156 14.4625C10.0156 17.2196 12.2585 19.4625 15.0156 19.4625C17.7728 19.4625 20.0156 17.2196 20.0156 14.4625C20.0156 11.7053 17.7728 9.46248 15.0156 9.46248Z"
            fill="white"
         />
         <path
            d="M16.7816 15.3019L15.3873 14.2562V12.1261C15.3873 11.9119 15.2142 11.7388 15 11.7388C14.7858 11.7388 14.6127 11.9119 14.6127 12.1261V14.4499C14.6127 14.5719 14.67 14.6869 14.7676 14.7597L16.3168 15.9216C16.3865 15.9739 16.4678 15.9991 16.5488 15.9991C16.6669 15.9991 16.7831 15.946 16.859 15.8438C16.9876 15.6729 16.9528 15.4301 16.7816 15.3019Z"
            fill="#A3B3BA"
         />
      </g>
      <path
         d="M18.4286 27.2857C18.4286 28.2324 17.661 29 16.7143 29C15.7674 29 15 28.2324 15 27.2857C15 26 16.7143 23 16.7143 23C16.7143 23 18.4286 26 18.4286 27.2857Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M19.8322 20.0074C19.6402 20.0196 19.4573 20.0936 19.3111 20.2186C19.1649 20.3437 19.0632 20.5127 19.0213 20.7005C18.9794 20.8883 18.9997 21.0847 19.0789 21.26C19.1582 21.4353 19.2923 21.5801 19.461 21.6727L25.6575 25.2116C25.7589 25.271 25.8711 25.3096 25.9876 25.3253C26.1041 25.341 26.2225 25.3335 26.336 25.3031C26.4496 25.2728 26.556 25.2201 26.649 25.1483C26.7421 25.0765 26.82 24.9871 26.8783 24.885C26.9366 24.783 26.974 24.6703 26.9885 24.5537C27.0029 24.437 26.9941 24.3188 26.9625 24.2056C26.9309 24.0924 26.8772 23.9865 26.8045 23.8942C26.7317 23.8019 26.6414 23.725 26.5387 23.6678L20.3422 20.1286C20.1882 20.038 20.0105 19.9959 19.8322 20.0078V20.0074Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M22.986 18.2273C22.8694 18.2291 22.7542 18.254 22.6472 18.3003C22.5401 18.3467 22.4432 18.4135 22.362 18.4972C22.2808 18.581 22.2169 18.68 22.174 18.7885C22.131 18.897 22.1099 19.0127 22.1117 19.1294V26.211C22.1101 26.3286 22.1318 26.4455 22.1758 26.5547C22.2197 26.6639 22.2848 26.7632 22.3675 26.847C22.4501 26.9308 22.5486 26.9974 22.6571 27.0428C22.7657 27.0883 22.8822 27.1116 22.9999 27.1116C23.1175 27.1116 23.234 27.0883 23.3426 27.0428C23.4512 26.9974 23.5496 26.9308 23.6323 26.847C23.7149 26.7632 23.7801 26.6639 23.824 26.5547C23.8679 26.4455 23.8897 26.3286 23.888 26.211V19.1294C23.8899 19.0104 23.8679 18.8923 23.8232 18.782C23.7785 18.6717 23.7121 18.5716 23.628 18.4875C23.5439 18.4033 23.4437 18.337 23.3334 18.2923C23.2231 18.2476 23.105 18.2254 22.986 18.2273Z"
         fill="#60A2D7"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M26.053 20.0073C25.9135 20.0159 25.778 20.0573 25.6574 20.1281L19.4609 23.6671C19.3582 23.7243 19.2679 23.8012 19.1952 23.8935C19.1224 23.9858 19.0687 24.0917 19.0371 24.2049C19.0056 24.3181 18.9967 24.4365 19.0112 24.5532C19.0257 24.6698 19.0631 24.7825 19.1214 24.8845C19.1796 24.9866 19.2575 25.076 19.3506 25.1478C19.4437 25.2196 19.5501 25.272 19.6636 25.3024C19.7772 25.3328 19.8956 25.3405 20.0121 25.3248C20.1285 25.3091 20.2407 25.2705 20.3422 25.2111L26.5387 21.6722C26.7161 21.5749 26.8549 21.4199 26.9323 21.233C27.0097 21.0462 27.0211 20.8386 26.9644 20.6445C26.9078 20.4503 26.7866 20.2812 26.6209 20.1652C26.4552 20.0493 26.2549 19.9933 26.053 20.0067V20.0073Z"
         fill="#60A2D7"
      />
      <defs>
         <clipPath id="clip0">
            <rect width="10" height="10" fill="white" transform="translate(10 9.41888)" />
         </clipPath>
      </defs>
   </svg>
)

const ChanceRain = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.7906 20.6636H25.308C27.4773 20.6638 29.458 19.4309 30.4155 17.4843C31.373 15.5379 31.1409 13.2164 29.817 11.4981C28.4929 9.77963 26.3073 8.96334 24.181 9.39308C23.9013 7.99722 22.9971 6.80651 21.7276 6.16201C20.4583 5.51751 18.9634 5.49048 17.6714 6.08849C16.5021 2.86778 12.9434 1.20487 9.72264 2.37411C6.50193 3.5436 4.83902 7.10235 6.00826 10.3231C3.18698 10.412 0.958398 12.7472 1.00059 15.5695C1.04303 18.392 3.34084 20.659 6.16364 20.6636"
         fill="#A3B3BA"
      />
      <g clip-path="url(#clip0)">
         <path
            d="M15.0156 8.04359C12.2585 8.04359 10.0156 10.2864 10.0156 13.0436C10.0156 15.8008 12.2585 18.0436 15.0156 18.0436C17.7728 18.0436 20.0156 15.8008 20.0156 13.0436C20.0156 10.2864 17.7728 8.04359 15.0156 8.04359Z"
            fill="white"
         />
         <path
            d="M16.7816 13.883L15.3873 12.8373V10.7072C15.3873 10.493 15.2142 10.3199 15 10.3199C14.7858 10.3199 14.6127 10.493 14.6127 10.7072V13.031C14.6127 13.153 14.67 13.268 14.7676 13.3408L16.3168 14.5027C16.3865 14.555 16.4678 14.5802 16.5488 14.5802C16.6669 14.5802 16.7831 14.5271 16.859 14.4249C16.9876 14.2541 16.9528 14.0112 16.7816 13.883Z"
            fill="#A3B3BA"
         />
      </g>
      <path
         d="M19.1393 27.1742C19.1393 28.3171 18.2126 29.2438 17.0697 29.2438C15.9264 29.2438 15 28.3171 15 27.1742C15 25.6219 17.0697 22 17.0697 22C17.0697 22 19.1393 25.6219 19.1393 27.1742Z"
         fill="#60A2D7"
      />
      <path
         d="M26 24.1429C26 25.7207 24.6567 27 23 27C21.3429 27 20 25.7207 20 24.1429C20 22 23 17 23 17C23 17 26 22 26 24.1429Z"
         fill="#60A2D7"
      />
      <defs>
         <clipPath id="clip0">
            <rect width="10" height="10" fill="white" transform="translate(10 8)" />
         </clipPath>
      </defs>
   </svg>
)

const PartlyCloudyNight = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M23.8485 2.33951C22.7232 2.41909 21.6 2.82441 20.6302 3.57265C18.0731 5.54574 17.5517 9.1563 19.466 11.6373C21.3801 14.1181 25.005 14.5297 27.5622 12.5566C28.28 12.0027 28.8375 11.3197 29.2256 10.5689C28.8885 14.5997 25.6152 17.7627 21.6271 17.7627C17.4146 17.7627 14 14.2341 14 9.88136C14 5.52865 17.4146 2 21.6271 2C22.3998 2 23.1456 2.11873 23.8485 2.33951Z"
         fill="#FDD020"
      />
      <path
         d="M24.888 17.7704C24.516 17.7704 24.1449 17.8089 23.7804 17.8848C23.5055 16.4674 22.617 15.2582 21.3694 14.6037C20.122 13.9493 18.6529 13.9218 17.3832 14.5291C16.2342 11.2585 12.737 9.56988 9.5719 10.7572C6.40685 11.9448 4.77268 15.5586 5.92171 18.8292C3.14918 18.9198 0.959117 21.2908 1.00058 24.1571C1.04229 27.023 3.30038 29.3251 6.0744 29.3297H24.888C27.9768 29.3297 30.4812 26.7421 30.4812 23.5501C30.4812 20.358 27.9768 17.7704 24.888 17.7704Z"
         fill="#A3B3BA"
      />
   </svg>
)

const PartlyCloudyDay = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <g clip-path="url(#clip0)"> */}
      {/* <g clip-path="url(#clip1)"> */}
      <path
         d="M26.4134 13.6099C26.4134 17.9626 22.9986 21.4912 18.7863 21.4912C14.5738 21.4912 11.1592 17.9626 11.1592 13.6099C11.1592 9.25717 14.5738 5.72852 18.7863 5.72852C22.9986 5.72852 26.4134 9.25717 26.4134 13.6099Z"
         fill="#FDD020"
      />
      <path
         d="M18.7863 4.15254C18.5053 4.15254 18.2778 3.91728 18.2778 3.62712V1.52542C18.2778 1.23526 18.5053 1 18.7863 1C19.0671 1 19.2948 1.23526 19.2948 1.52542V3.62712C19.2948 3.91728 19.0671 4.15254 18.7863 4.15254Z"
         fill="#FDD020"
      />
      <path
         d="M30.4813 14.1348H28.4474C28.1664 14.1348 27.939 13.8996 27.939 13.6094C27.939 13.3192 28.1664 13.084 28.4474 13.084H30.4813C30.7621 13.084 30.9898 13.3192 30.9898 13.6094C30.9898 13.8996 30.7621 14.1348 30.4813 14.1348Z"
         fill="#FDD020"
      />
      <path
         d="M8.48134 13.084H6.44744C6.16639 13.084 5.93896 13.3192 5.93896 13.6094C5.93896 13.8996 6.16639 14.1348 6.44744 14.1348H8.48134C8.76214 14.1348 8.98981 13.8996 8.98981 13.6094C8.98981 13.3192 8.76214 13.084 8.48134 13.084Z"
         fill="#FDD020"
      />
      <path d="M11.6673 6.25314L9.63336 4.15144L11.6673 6.25314Z" fill="#FDD020" />
      <path
         d="M11.6674 6.77811C11.5323 6.77811 11.4032 6.7227 11.3079 6.62418L9.27398 4.52248C9.14165 4.39061 9.08877 4.19512 9.13519 4.01143C9.18187 3.82773 9.32066 3.68432 9.49843 3.63634C9.67619 3.58811 9.86513 3.64301 9.993 3.7795L12.0269 5.8812C12.1721 6.03154 12.2156 6.25756 12.1369 6.45383C12.0582 6.65009 11.873 6.77811 11.6674 6.77811Z"
         fill="#FDD020"
      />
      <path d="M25.9045 6.25314L27.9384 4.15144L25.9045 6.25314Z" fill="#FDD020" />
      <path
         d="M25.9048 6.77811C25.699 6.77811 25.5137 6.65009 25.435 6.45383C25.3563 6.25756 25.3998 6.03154 25.5453 5.8812L27.5792 3.7795C27.7068 3.64301 27.8957 3.58811 28.0735 3.63634C28.2513 3.68432 28.3903 3.82773 28.4367 4.01143C28.4831 4.19512 28.4303 4.39061 28.2982 4.52248L26.2643 6.62418C26.1689 6.7227 26.0396 6.77811 25.9048 6.77811Z"
         fill="#FDD020"
      />
      <path d="M11.6673 20.1441L9.63336 22.2458L11.6673 20.1441Z" fill="#FDD020" />
      <path
         d="M11.6674 19.6191C11.5323 19.6191 11.4032 19.6746 11.3079 19.7731L9.27398 21.8748C9.14165 22.0066 9.08877 22.2021 9.13519 22.3858C9.18187 22.5695 9.32066 22.7129 9.49843 22.7609C9.67619 22.8091 9.86513 22.7542 9.993 22.6177L12.0269 20.5161C12.1721 20.3657 12.2156 20.1397 12.1369 19.9434C12.0582 19.7472 11.873 19.6191 11.6674 19.6191Z"
         fill="#FDD020"
      />
      <path d="M25.9045 20.1441L27.9384 22.2458L25.9045 20.1441Z" fill="#FDD020" />
      <path
         d="M25.9048 19.6191C25.699 19.6191 25.5137 19.7472 25.435 19.9434C25.3563 20.1397 25.3998 20.3657 25.5453 20.5161L27.5792 22.6177C27.7068 22.7542 27.8957 22.8091 28.0735 22.7609C28.2513 22.7129 28.3903 22.5695 28.4367 22.3858C28.4831 22.2021 28.4303 22.0066 28.2982 21.8748L26.2643 19.7731C26.1689 19.6746 26.0396 19.6191 25.9048 19.6191Z"
         fill="#FDD020"
      />
      <path
         d="M24.888 20.4399C24.516 20.4399 24.1449 20.4784 23.7804 20.5544C23.5055 19.1369 22.617 17.9278 21.3694 17.2733C20.122 16.6188 18.6529 16.5914 17.3832 17.1986C16.2342 13.9281 12.737 12.2394 9.5719 13.4268C6.40685 14.6144 4.77268 18.2282 5.92171 21.4987C3.14918 21.5893 0.959117 23.9604 1.00058 26.8266C1.04229 29.6926 3.30038 31.9947 6.0744 31.9993H24.888C27.9768 31.9993 30.4812 29.4117 30.4812 26.2196C30.4812 23.0276 27.9768 20.4399 24.888 20.4399Z"
         fill="#A3D4F7"
      />
      {/* </g> */}
      {/* </g> */}
      <defs>
         <clipPath id="clip0">
            <rect width="32" height="32" fill="white" transform="translate(0 -0.000335693)" />
         </clipPath>
         <clipPath id="clip1">
            <rect width="32" height="32" fill="white" transform="translate(0 -0.000335693)" />
         </clipPath>
      </defs>
   </svg>
)

const Foggy = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.888 10.3931C24.516 10.3931 24.1449 10.4316 23.7804 10.5075C23.5055 9.09003 22.617 7.88089 21.3694 7.22642C20.122 6.57195 18.6529 6.5445 17.3832 7.15176C16.2342 3.8812 12.737 2.19256 9.5719 3.3799C6.40685 4.56749 4.77268 8.18132 5.92171 11.4519C3.14918 11.5424 0.959117 13.9135 1.00058 16.7797C1.04229 19.6457 3.30038 21.9478 6.0744 21.9524H24.888C27.9768 21.9524 30.4812 19.3648 30.4812 16.1727C30.4812 12.9807 27.9768 10.3931 24.888 10.3931Z"
         fill="#A3D4F7"
      />
      <rect x="4" y="23.9531" width="20" height="1" rx="0.5" fill="#A3D4F7" />
      <rect x="6" y="27.9531" width="20" height="1" rx="0.5" fill="#A3D4F7" />
      <rect x="12" y="25.9531" width="16" height="1" rx="0.5" fill="#A3D4F7" />
      <rect width="8" height="1" rx="0.5" transform="matrix(-1 0 0 1 11 25.9531)" fill="#A3D4F7" />
   </svg>
)

const Cloudy = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M24.888 14.3931C24.516 14.3931 24.1449 14.4316 23.7804 14.5075C23.5055 13.09 22.617 11.8809 21.3694 11.2264C20.122 10.5719 18.6529 10.5445 17.3832 11.1518C16.2342 7.8812 12.737 6.19256 9.5719 7.3799C6.40685 8.56749 4.77268 12.1813 5.92171 15.4519C3.14918 15.5424 0.959117 17.9135 1.00058 20.7797C1.04229 23.6457 3.30038 25.9478 6.0744 25.9524H24.888C27.9768 25.9524 30.4812 23.3648 30.4812 20.1727C30.4812 16.9807 27.9768 14.3931 24.888 14.3931Z"
         fill="#A3D4F7"
      />
   </svg>
)

const ClearNight = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M20.3172 4.62344C18.8038 4.83179 17.3136 5.43114 16.0065 6.43964C12.113 9.44384 11.3191 14.9412 14.2338 18.7187C17.1483 22.4959 22.6674 23.1226 26.5609 20.1184C27.0556 19.7367 27.5003 19.3148 27.8937 18.8612C26.6518 24.1071 22.0722 28 16.6129 28C10.199 28 5 22.6273 5 16C5 9.37266 10.199 4 16.6129 4C17.9081 4 19.1538 4.21912 20.3172 4.62344Z"
         fill="#FDD020"
      />
      <g clip-path="url(#clip0)">
         <path
            d="M21.3663 16.2284L22.2968 16.824C22.3145 16.8295 22.3501 16.8846 22.3441 16.9232C22.3441 16.9342 22.3441 16.9397 22.3441 16.9451L22.0002 18.0923C21.9883 18.1307 21.9765 18.1638 21.9765 18.2079C21.9765 18.2465 21.9824 18.2961 22.0237 18.3511C22.0591 18.4064 22.1479 18.4393 22.2071 18.4338C22.3196 18.4284 22.3667 18.3898 22.4201 18.3511L23.4088 17.6556C23.4147 17.6447 23.4384 17.6392 23.4619 17.6392C23.4915 17.6392 23.5094 17.6446 23.5211 17.6556L24.5631 18.3565C24.6223 18.3951 24.6638 18.4337 24.776 18.4392H24.782C24.8412 18.4392 24.9181 18.4063 24.9594 18.3511C24.9949 18.3015 25.0007 18.252 25.0007 18.2134C25.0007 18.1693 24.9949 18.1307 24.9773 18.0866L24.58 16.9342C24.58 16.9287 24.58 16.9232 24.58 16.9121C24.574 16.8791 24.6096 16.824 24.6272 16.8185L25.5518 16.2284C25.5814 16.2064 25.6168 16.1843 25.6464 16.1514C25.67 16.1182 25.7056 16.0742 25.7056 15.997C25.7115 15.9529 25.6879 15.8978 25.6582 15.8648C25.6108 15.8152 25.5636 15.7987 25.5222 15.7878C25.4807 15.7768 25.4453 15.7712 25.4039 15.7712L24.2073 15.6947C24.1955 15.6947 24.166 15.6892 24.1422 15.6728C24.1185 15.6562 24.1008 15.6287 24.1008 15.6177L23.7104 14.4541C23.6927 14.4155 23.681 14.3769 23.6512 14.3383C23.6216 14.2996 23.5624 14.2391 23.4561 14.2336C23.3496 14.2391 23.2905 14.2996 23.261 14.3383C23.2314 14.3768 23.2196 14.4154 23.2077 14.4541L22.8115 15.6177C22.8115 15.6286 22.7937 15.6561 22.77 15.6728C22.7462 15.6892 22.7169 15.6947 22.7049 15.6947L21.5084 15.7712C21.4551 15.7712 21.4078 15.7768 21.3488 15.7987C21.319 15.8097 21.2896 15.8319 21.254 15.8648C21.2246 15.8978 21.2067 15.953 21.2067 15.9971C21.2067 16.0742 21.2422 16.1183 21.2718 16.1514C21.3013 16.1843 21.3309 16.2064 21.3663 16.2284Z"
            fill="#FDD020"
         />
      </g>
      <g clip-path="url(#clip1)">
         <path
            d="M16.2024 11.337L16.7607 11.6944C16.7714 11.6977 16.7927 11.7308 16.7891 11.7539C16.7891 11.7605 16.7891 11.7638 16.7891 11.7671L16.5828 12.4554C16.5756 12.4784 16.5686 12.4983 16.5686 12.5247C16.5686 12.5479 16.5721 12.5777 16.5969 12.6107C16.6181 12.6438 16.6714 12.6636 16.7069 12.6603C16.7744 12.657 16.8027 12.6338 16.8347 12.6107L17.4279 12.1933C17.4315 12.1868 17.4457 12.1835 17.4598 12.1835C17.4776 12.1835 17.4883 12.1868 17.4953 12.1933L18.1205 12.6139C18.156 12.6371 18.1809 12.6602 18.2483 12.6635H18.2518C18.2874 12.6635 18.3335 12.6438 18.3583 12.6106C18.3796 12.5809 18.3831 12.5512 18.3831 12.528C18.3831 12.5016 18.3796 12.4784 18.369 12.4519L18.1307 11.7605C18.1307 11.7572 18.1307 11.7539 18.1307 11.7473C18.127 11.7274 18.1484 11.6944 18.159 11.6911L18.7137 11.337C18.7315 11.3238 18.7527 11.3106 18.7705 11.2908C18.7846 11.2709 18.806 11.2445 18.806 11.1982C18.8095 11.1717 18.7954 11.1387 18.7776 11.1188C18.7491 11.0891 18.7208 11.0792 18.696 11.0726C18.6711 11.0661 18.6498 11.0627 18.625 11.0627L17.907 11.0168C17.8999 11.0168 17.8822 11.0135 17.868 11.0036C17.8537 10.9937 17.8431 10.9772 17.8431 10.9706L17.6089 10.2724C17.5983 10.2493 17.5912 10.2261 17.5734 10.203C17.5556 10.1798 17.5201 10.1434 17.4563 10.1402C17.3924 10.1434 17.357 10.1798 17.3392 10.203C17.3215 10.2261 17.3144 10.2492 17.3073 10.2724L17.0695 10.9706C17.0695 10.9772 17.0589 10.9937 17.0446 11.0036C17.0304 11.0135 17.0128 11.0168 17.0056 11.0168L16.2877 11.0627C16.2557 11.0627 16.2273 11.0661 16.1919 11.0792C16.174 11.0858 16.1564 11.0991 16.135 11.1189C16.1174 11.1387 16.1067 11.1718 16.1067 11.1982C16.1067 11.2445 16.128 11.2709 16.1457 11.2908C16.1634 11.3106 16.1812 11.3238 16.2024 11.337Z"
            fill="#FDD020"
         />
      </g>
      <defs>
         <clipPath id="clip0">
            <rect width="5" height="5" fill="white" transform="translate(20.9566 14)" />
         </clipPath>
         <clipPath id="clip1">
            <rect width="3" height="3" fill="white" transform="translate(15.9566 10)" />
         </clipPath>
      </defs>
   </svg>
)

const ClearDay = () => (
   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M25.0666 16.0002C25.0666 21.0075 21.0072 25.0669 15.9999 25.0669C10.9926 25.0669 6.93323 21.0075 6.93323 16.0002C6.93323 10.9929 10.9926 6.93353 15.9999 6.93353C21.0072 6.93353 25.0666 10.9929 25.0666 16.0002Z"
         fill="#FDD020"
      />
      <path
         d="M16.0001 31.9997C15.7056 31.9997 15.4668 31.7609 15.4668 31.4663V27.1997C15.4668 26.9051 15.7056 26.6663 16.0001 26.6663C16.2947 26.6663 16.5335 26.9051 16.5335 27.1997V31.4663C16.5335 31.7609 16.2947 31.9997 16.0001 31.9997Z"
         fill="#FDD020"
      />
      <path
         d="M16.0001 5.33335C15.7056 5.33335 15.4668 5.09455 15.4668 4.80002V0.533335C15.4668 0.238803 15.7056 0 16.0001 0C16.2947 0 16.5335 0.238803 16.5335 0.533335V4.80002C16.5335 5.09455 16.2947 5.33335 16.0001 5.33335Z"
         fill="#FDD020"
      />
      <path d="M7.99988 8.00055L4.79987 4.80054L7.99988 8.00055Z" fill="#FDD020" />
      <path
         d="M8.00005 8.53354C7.85864 8.53354 7.72296 8.47728 7.62296 8.37728L4.42295 5.17727C4.22087 4.9679 4.22373 4.63534 4.42946 4.42961C4.63519 4.22388 4.96774 4.22102 5.17712 4.4231L8.37713 7.62311C8.52948 7.77572 8.57531 8.00515 8.49276 8.20437C8.4102 8.40359 8.21567 8.53354 8.00005 8.53354Z"
         fill="#FDD020"
      />
      <path d="M27.2001 27.2001L24.0001 24.0001L27.2001 27.2001Z" fill="#FDD020" />
      <path
         d="M27.1999 27.7331C27.0585 27.7331 26.9228 27.6768 26.8228 27.5768L23.6228 24.3768C23.4207 24.1674 23.4236 23.8349 23.6293 23.6292C23.835 23.4234 24.1676 23.4206 24.3769 23.6227L27.577 26.8227C27.7293 26.9753 27.7751 27.2047 27.6926 27.4039C27.61 27.6031 27.4155 27.7331 27.1999 27.7331Z"
         fill="#FDD020"
      />
      <path d="M24.0001 8.00055L27.2001 4.80054L24.0001 8.00055Z" fill="#FDD020" />
      <path
         d="M24.0001 8.53354C23.7845 8.53354 23.59 8.40359 23.5074 8.20437C23.4249 8.00515 23.4707 7.77572 23.6231 7.62311L26.8231 4.4231C27.0324 4.22102 27.365 4.22388 27.5707 4.42961C27.7764 4.63534 27.7793 4.9679 27.5772 5.17727L24.3772 8.37728C24.2772 8.47728 24.1415 8.53354 24.0001 8.53354Z"
         fill="#FDD020"
      />
      <path
         d="M31.4666 16.5335H27.2C26.9054 16.5335 26.6666 16.2947 26.6666 16.0002C26.6666 15.7056 26.9054 15.4668 27.2 15.4668H31.4666C31.7612 15.4668 32 15.7056 32 16.0002C32 16.2947 31.7612 16.5335 31.4666 16.5335Z"
         fill="#FDD020"
      />
      <path
         d="M4.80002 16.5335H0.533335C0.238803 16.5335 0 16.2947 0 16.0002C0 15.7056 0.238803 15.4668 0.533335 15.4668H4.80002C5.09455 15.4668 5.33335 15.7056 5.33335 16.0002C5.33335 16.2947 5.09455 16.5335 4.80002 16.5335Z"
         fill="#FDD020"
      />
      <path d="M4.79987 27.2001L7.99988 24.0001L4.79987 27.2001Z" fill="#FDD020" />
      <path
         d="M4.79994 27.7331C4.58432 27.7331 4.38978 27.6031 4.30723 27.4039C4.22468 27.2047 4.27051 26.9753 4.42286 26.8227L7.62287 23.6227C7.83224 23.4206 8.1648 23.4234 8.37053 23.6292C8.57626 23.8349 8.57912 24.1674 8.37704 24.3768L5.17703 27.5768C5.07703 27.6768 4.94135 27.7331 4.79994 27.7331Z"
         fill="#FDD020"
      />
   </svg>
)

export const Precipation = () => (
   <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M29.4129 8.2215C28.3875 6.1782 26.8359 4.35174 24.9947 3.03243C20.5758 -0.133997 15.0497 -0.13762 10.6257 3.03243C8.78578 4.35085 7.23496 6.17588 6.20779 8.22143C2.96471 8.62235 0.386719 11.3924 0.386719 14.7688C0.386719 18.4078 3.36491 21.3682 7.02553 21.3682H28.5948C32.2555 21.3682 35.2336 18.4077 35.2336 14.7688C35.2336 11.3942 32.6573 8.62269 29.4129 8.2215ZM28.5948 19.3175C27.309 19.3175 7.98321 19.3175 7.02553 19.3175C4.49076 19.3175 2.42853 17.277 2.42853 14.7688C2.42853 12.3287 4.43536 10.2897 6.90207 10.2235C7.29424 10.213 7.64652 9.97591 7.80707 9.61642C9.65314 5.48355 13.6731 2.70684 17.8102 2.70684C21.4447 2.70684 24.9886 4.85014 27.0467 8.17085C27.0385 8.17085 22.7034 8.16941 22.7105 8.16941C22.1469 8.16941 21.6898 8.6283 21.6896 9.19444C21.6894 9.76072 22.1464 10.22 22.7102 10.2202C27.2059 10.2217 28.6293 10.2213 28.7183 10.2236C31.185 10.2897 33.1918 12.3287 33.1918 14.7688C33.1918 17.277 31.1296 19.3175 28.5948 19.3175Z"
         fill="#484848"
      />
      <path
         d="M9.47733 23.7651C8.95372 23.5546 8.35958 23.8105 8.15022 24.3363L7.06119 27.0706C6.79295 27.7442 7.28846 28.4771 8.00878 28.4771C8.41397 28.4771 8.79737 28.2332 8.95705 27.8323L10.0461 25.0979C10.2555 24.5721 10.0009 23.9754 9.47733 23.7651Z"
         fill="#484848"
      />
      <path
         d="M18.189 23.7651C17.6654 23.5546 17.0714 23.8105 16.862 24.3363L15.7731 27.0706C15.5048 27.7442 16.0004 28.4771 16.7206 28.4771C17.1258 28.4771 17.5091 28.2332 17.6688 27.8323L18.7577 25.0979C18.9671 24.5721 18.7125 23.9754 18.189 23.7651Z"
         fill="#484848"
      />
      <path
         d="M12.7369 30.8712C12.2114 30.6653 11.6196 30.9261 11.4146 31.4537L10.3257 34.2564C10.0636 34.9307 10.5623 35.6548 11.2765 35.6548C11.6848 35.6548 12.0704 35.407 12.2278 35.0019L13.3168 32.1992C13.5218 31.6716 13.2622 31.0771 12.7369 30.8712Z"
         fill="#484848"
      />
      <path
         d="M22.5377 30.8712C22.0123 30.6653 21.4205 30.9261 21.2154 31.4537L20.1264 34.2564C19.8644 34.9307 20.3631 35.6548 21.0773 35.6548C21.4856 35.6548 21.8712 35.407 22.0287 35.0019L23.1177 32.1992C23.3227 31.6716 23.063 31.0771 22.5377 30.8712Z"
         fill="#484848"
      />
      <path
         d="M26.9009 23.7651C26.3773 23.5546 25.7833 23.8105 25.5739 24.3363L24.485 27.0706C24.2168 27.7442 24.7123 28.4771 25.4325 28.4771C25.8377 28.4771 26.221 28.2332 26.3807 27.8323L27.4696 25.0979C27.6789 24.5721 27.4243 23.9754 26.9009 23.7651Z"
         fill="#484848"
      />
   </svg>
)

export const getSvgByValue = (value) => {
   switch (value) {
      case "clear-day":
         return <ClearDay />
      case "clear-night":
         return <ClearNight />
      case "cloudy":
         return <Cloudy />
      case "foggy":
         return <Foggy />
      case "partly-cloudy-day":
         return <PartlyCloudyDay />
      case "partly-cloudy-night":
         return <PartlyCloudyNight />
      case "possibly-rainy-day":
         return <PossiblyRainyDay />
      case "possibly-rainy-night":
         return <PossiblyRainyNight />
      case "possibly-sleet-day ":
         return <PossiblySleetDay />
      case "possibly-sleet-night ":
         return <PossiblySleetNight />
      case "possibly-snow-day":
         return <PossiblySnowDay />
      case "possibly-snow-night":
         return <PossiblySnowNight />
      case "possibly-thunderstorm-day":
         return <PossiblyThunderstormDay />
      case "possibly-thunderstorm-night":
         return <PossiblyThunderstormNight />
      case "rainy":
         return <Rainy />
      case "sleet":
         return <Sleet />
      case "snow":
         return <Snow />
      case "thunderstorm":
         return <Thunderstorm />
      case "windy":
         return <Windy />
      case "chance-rain":
         return <ChanceRain />
      case "chance-sleet":
         return <ChanceSleet />
      case "chance-snow":
         return <ChanceSnow />
      default:
         return <ClearDay />
   }
}
