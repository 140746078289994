import { createSlice } from "@reduxjs/toolkit";
import {
  fetchApproveSubscriptions,
  fetchSetPlan,
  fetchUpdateCard,
  fetchUpdateCardForSubscriptions,
  fetchUpdatePlan,
  fetchValidatePromoCode,
} from "./actionCreater";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { RootState } from "../../store";
import { IUserProfile } from "../../../api/types";

interface ICouponInfo {
  name: string;
  duration: string;
}

interface IPromoCodeData {
  code: string;
  coupon: ICouponInfo;
}
interface IPaymentState {
  isPending: boolean;
  error: string;
  isSucceed: boolean;
  setupSecret: string | null;
  intentSecret: string | null;
  cardToken: string | null;
  promoCodeData: IPromoCodeData | null;
  payment_period: "A" | "M";
  technicians: any;
}

const initialState: IPaymentState = {
  isPending: false,
  error: "",
  isSucceed: false,
  setupSecret: null,
  intentSecret: null,
  cardToken: null,
  promoCodeData: null,
  payment_period: "M",
  technicians: null,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    paymentSucceed: (state) => {
      state.isSucceed = true;
      state.setupSecret = null;
      state.intentSecret = null;
    },
    setCardToken: (state, action) => {
      state.cardToken = action.payload;
    },
    deletePromoCode: (state) => {
      state.promoCodeData = null;
    },
    setPaymentPeriod: (state, action) => {
      state.payment_period = action.payload;
    },
    setTechnicians: (state, action) => {
      state.technicians = action.payload;
    },
  },
  extraReducers: {
    [fetchSetPlan.fulfilled.type]: (state, action) => {
      state.isPending = false;
      state.isSucceed = true;
      state.error = "";
    },
    [fetchSetPlan.pending.type]: (state, action) => {
      state.isPending = true;
    },
    [fetchSetPlan.rejected.type]: (state, action) => {
      state.isPending = false;
      state.isSucceed = false;
      state.error = action.payload;
    },
    [fetchUpdatePlan.fulfilled.type]: (state, action) => {
      state.isPending = false;
      state.isSucceed = true;
      state.error = "";
    },
    [fetchUpdatePlan.pending.type]: (state, action) => {
      state.isPending = true;
    },
    [fetchUpdatePlan.rejected.type]: (state, action) => {
      state.isPending = false;
      state.error = action.payload;
    },
    [fetchUpdateCard.fulfilled.type]: (state, action) => {
      state.isPending = false;
      state.setupSecret = action.payload.client_secret;
      state.error = "";
    },
    [fetchUpdateCard.pending.type]: (state, action) => {
      state.isPending = true;
    },
    [fetchUpdateCard.rejected.type]: (state, action) => {
      state.isPending = false;
      state.error = action.payload;
    },
    [fetchApproveSubscriptions.fulfilled.type]: (state, action) => {
      console.log(
        action.payload.work_order.subscription.details.latest_invoice
          .payment_intent.subscription
      );
      state.isPending = false;
      state.intentSecret =
        action.payload.work_order.subscription.details.latest_invoice.payment_intent.client_secret;
      state.error = "";
    },
    [fetchApproveSubscriptions.pending.type]: (state, action) => {
      state.isPending = true;
    },
    [fetchApproveSubscriptions.rejected.type]: (state, action) => {
      state.isPending = false;
      state.error = action.payload;
    },
    [fetchUpdateCardForSubscriptions.fulfilled.type]: (state, action) => {
      state.isPending = false;
      state.setupSecret = action.payload.client_secret;
      state.error = "";
    },
    [fetchUpdateCardForSubscriptions.pending.type]: (state, action) => {
      state.isPending = true;
    },
    [fetchUpdateCardForSubscriptions.rejected.type]: (state, action) => {
      state.isPending = false;
      state.error = action.payload;
    },
    [fetchValidatePromoCode.fulfilled.type]: (state, action) => {
      state.isPending = false;
      state.promoCodeData = action.payload;
      state.error = "";
    },
    [fetchValidatePromoCode.pending.type]: (state, action) => {
      state.isPending = true;
    },
    [fetchValidatePromoCode.rejected.type]: (state, action) => {
      state.isPending = false;
      state.error = action.payload;
    },
  },
});
export const getPaymentData = (state: RootState): IPaymentState =>
  state.payment;
export default paymentSlice.reducer;
