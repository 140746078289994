import React, {useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../config/hooks";
import {getCurrentUser} from "../../config/reducers/user/selectors";
import styled from "styled-components";
import {ImpersonationIcon} from "../../assets/icons";
import {Button} from "antd";
import {Api} from "../../api/api";
import {setCurrentUser} from "../../config/reducers/user/actions";
import Routes from "../../routes/routes";
import {useHistory} from "react-router-dom";
import {SwitchAccountModal} from "./SwitchAccountModal";

const Container = styled.div`
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 48px;
  padding: 2px 20px;
  &>div:last-child {
    margin-left: auto;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  h2 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 900;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  color: #494949;
  h5 {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
  }
  span {
    font-size: 13px;
    font-weight: 400;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  >button {
    width: 187px;
  }
`;

export const SuperUserHeader = () => {
  const user = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleStopImpersonation = useCallback(() => {
      Api.company.stopImpersonating().then(({data}) => {
         dispatch(setCurrentUser(data));
         history.replace(Routes.main.SUDashboard);
      });
   }, [dispatch, history]);

  return (user?.is_staff && user.company) ?
    <Container>
      <TitleWrapper>
        <ImpersonationIcon style={{color: "#FB4D4F", fontSize: "22px"}} />
        <h2>Impersonation Mode:</h2>
      </TitleWrapper>
      <InfoWrapper>
        <h5>{user?.company.first_name ?? ""} {user?.company.last_name ?? ""}</h5>
        <span>Company Owner</span>
      </InfoWrapper>
      <InfoWrapper>
        <h5>{user?.company.name ?? ""}</h5>
        <span>Company Name</span>
      </InfoWrapper>
      <ButtonWrapper>
        <Button onClick={() => setVisible(true)}>Switch Account</Button>
        <Button type="primary" onClick={handleStopImpersonation}>Stop Impersonating</Button>
      </ButtonWrapper>
      <SwitchAccountModal visible={isVisible} onClose={() => setVisible(false)} />
    </Container>
    : null
};