import { Modal } from "antd";
import moment from "moment";
import styled from "styled-components";
import { ISubscriptionPlan } from "../api/types";
import { SubscriptionCardPriceText } from "../containers/settings/SubscriptionCard";
import Flex from "../noui/Flex";
import Hr from "../ui/Hr";
import { Msg } from "../ui/Text";

type TProps = {
    visible: boolean;
    currentPlan: ISubscriptionPlan|null;
    targetPlan: ISubscriptionPlan|null;
    isUpgrade: boolean;
    paymentDate: string;
    last_4: string;
    onOk: () => void;
    onCancel: () => void;
}

const ModalContent = styled(Flex)`
    flex-direction: column;
    width: 100%;
`;

const MainBlock = styled(Flex)`
    margin: 16px 24px;
    flex-direction: column;
`

type TSubTitleProps = {
    isGreen?: boolean;
}

const SubTitle = styled(Msg)<TSubTitleProps>`
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.isGreen ? "#42C77B" : "#0F497B"};
`;

const Description = styled(Msg)`
    font-size: 15px;
    font-weight: 400;
    color: #202020;
    margin: 16px 0; 
`;

const ChangePlanModal:React.FC<TProps> = ({visible, currentPlan, targetPlan, isUpgrade, paymentDate, last_4, onOk, onCancel}) => {
    return (
        <Modal 
            visible={visible}
            title={`${isUpgrade ? "upgrade" : "downgrade"} to ${targetPlan?.title}`}
            okText={isUpgrade ? "Upgrade" : "Downgrade"}
            onOk={onOk}
            onCancel={onCancel}
            width={384}
        >
            <ModalContent>
                <Msg textAlign="center" fontSize={13} fontWeight={400}>You’ll get access to benefits immediately.</Msg>
                <Msg textAlign="center" fontSize={13} fontWeight={400}>All prices incl. VAT</Msg>
                <Msg textAlign="center" fontSize={18} fontWeight={900} mt="24px" style={{textTransform: "uppercase"}}>{targetPlan?.title}</Msg>
                <Flex alignItems="baseline" marginBottom="16px" justifyContent="center">
                    <SubscriptionCardPriceText 
                        isActive={false}
                        fontSize="22px"
                        fontWeight="700"
                    >
                        $
                    </SubscriptionCardPriceText>
                    <SubscriptionCardPriceText 
                        isActive={false}
                        fontSize="52px"
                        fontWeight="800"
                    >
                        {(targetPlan?.monthly_price??0).toFixed(0)}
                    </SubscriptionCardPriceText>
                    <SubscriptionCardPriceText 
                        isActive={false}
                        fontSize="15px"
                        fontWeight="400"
                    >
                        / month
                    </SubscriptionCardPriceText>
                </Flex>
                <Hr/>
                <MainBlock>
                    <Flex justifyContent="space-between">
                        <SubTitle>Credit</SubTitle>
                        <SubTitle isGreen>${(targetPlan?.monthly_price??0)-(targetPlan?.upgrade_price_month??0)}</SubTitle>
                    </Flex>
                    <Description>Credit to your purchase from the remaining time on your previous membership</Description>
                    <Hr />
                    <Flex justifyContent="space-between" marginTop="16px">
                        <SubTitle>Monthly charge</SubTitle>
                        <SubTitle>${(targetPlan?.monthly_price??0).toFixed(0)}</SubTitle>
                    </Flex>
                    <Description>Starting on {moment(paymentDate).format("MMM DD, YYYY")} on a recurring basic. Cancel anytime.</Description>
                    <Hr />
                    <Flex justifyContent="space-between" marginTop="16px">
                        <SubTitle>What you’ll pay today</SubTitle>
                        <SubTitle>${(targetPlan?.upgrade_price_month??0).toFixed(0)}</SubTitle>
                    </Flex>
                    <Description>We will get payment from <br/> **** **** **** {last_4}</Description>
                </MainBlock>
            </ModalContent>   
        </Modal>)
}

export default ChangePlanModal;