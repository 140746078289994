import React, { useEffect, useState, useCallback, useMemo } from "react"
import styled from "styled-components"
import { Api } from "../../api/api"
import { TArgCallback, TCallback } from "../../types"
import { Button, Collapse, Form, Switch } from "antd"
import { ICreateEstimate, IUserProfile } from "../../api/types"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { NavLink } from "react-router-dom"
import routes from "../../routes/routes"
import { useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import ModalPrintCheckboxes from "./pdfContent/ModalPrintCheckboxes"
const { Panel } = Collapse

const Container = styled.div`
   background: #f4f8fb;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   ${down("xs")} {
      background: #fff;
   }
   &.shareModal {
      background: none;
   }
`
const Content = styled.div`
   flex-grow: 1;
   padding: 50px 20px 20px;
   display: flex;
   flex-direction: column;
   gap: 8px;
   ${down("xs")} {
      padding: 0;
   }
   background-color: #EDEDED;
   .ant-collapse-header {
      color: #0496FF !important;
      text-decoration: underline;
   }
`
const Footer = styled.div`
   border-top: 1px solid #e6e6e6;
   text-align: right;
   padding: 20px;
`

const Card = styled.div`
   padding: 12px;
   width: 266px;
   border-radius: 4px;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   font-size: 13px;
   background: #ffffff;
   display: flex;
   flex-direction: column;
   gap: 4px;
   align-items: center;
   justify-content: stretch;
   p {
      padding: 0;
      margin: 0;
   }
   h4 {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 17px;
   }
   a {
      align-self: end;
   }
   > .ant-form-item {
      margin-bottom: 0;
   }
   .card_content {
      position: relative;
   }
   ${down("xs")} {
      width: 100%;
      .card_content {
         width: 100%;
         justify-content: space-between;
      }
   }
`
const FooterButton = styled(Button)`
   width: calc(50% - 4px);
`
const ManageButton = styled(Button)`
   border: none;
   color: #0496ff;
   padding: 0;
   border-radius: 0;
   height: auto;
   font-weight: 700;
   font-size: 13px;
   position: absolute;
   bottom: 0;
   right: 0;
   > span {
      text-decoration: underline;
   }
`

type TCardItem = {
   title: string
   description: string
   name: keyof ICreateEstimate
}

const cards: TCardItem[] = [
   {
      title: "Accept Payment",
      description: "Allow customers to pay credit card on the order page",
      name: "collect_payment",
   },
   {
      title: "Attach Receipt",
      description: "Send a copy of the invoice as an attachment",
      name: "attach_receipt",
   },
   {
      title: "Display Messages",
      description: "Allow customers to view messages on estimates (invoices)",
      name: "display_messages",
   },
   {
      title: "Display Activity Feed",
      description: "Allow customers to see the activity feed on items",
      name: "display_activity_feed",
   },
   {
      title: "Display Authorization History",
      description: "Allow customers to see authorizations made to this order",
      name: "display_authorization_history",
   },
]

export const checkIsPaymentsActive = (user: IUserProfile | null) =>
   user?.company?.stripe?.charges_enabled && user?.company?.stripe?.active

type TCardProps = {
   item: TCardItem
   user: IUserProfile | null
   isRecurring?: boolean
   showPDFSelectModal?: TCallback
   handleCheck: TArgCallback<any>
   checked: boolean
}
const CardSwitch: React.FC<TCardProps> = ({ item, user, isRecurring, showPDFSelectModal, checked, handleCheck }) => {
   return (
      <Card>
         <Flex flexDirection="row" className="card_content">
            <div className="content">
               <h4>{item.title}</h4>
               {item.name === "collect_payment" && !checkIsPaymentsActive(user) && user?.role_id === 3 ? (
                  <p>
                     Ask your business owner to setup DockWorks Payments to begin accepting credit card payments online!
                  </p>
               ) : item.name === "attach_receipt" ? (
                  <div>
                     <p>{item.description}</p>
                     <ManageButton onClick={showPDFSelectModal}>Manage</ManageButton>
                  </div>
               ) : (
                  <p>{item.description}</p>
               )}
            </div>

            <Switch
               defaultChecked={item.name == "collect_payment" || checkIsPaymentsActive(user)}
               disabled={item.name === "collect_payment" && !checkIsPaymentsActive(user)}
               checked={checked}
               onChange={(checked) => handleCheck({ name: item.name, value: checked })}
            />
         </Flex>
         {item.name === "collect_payment" && !checkIsPaymentsActive(user) && (
            <NavLink
               to={{
                  pathname: routes.settings.Payments,
               }}
            >
               <Msg color="#109CF1" fontSize="13px">
                  Get started
               </Msg>
            </NavLink>
         )}
      </Card>
   )
}

type TProps = {
   onOk: TArgCallback<any>
   onClose: TCallback
   saving?: boolean
   isRecurring?: boolean
   showPDFSelectModal?: TCallback
   workOrderNumber?: number
   shareModal?: boolean
   isSmall?: boolean
}

export const EstimateConfig: React.FC<TProps> = ({
   onOk,
   onClose,
   saving,
   isRecurring,
   workOrderNumber,
   shareModal,
   isSmall
}) => {
   const isXs = useBreakpoint(down("xs"))
   const user = useAppSelector(getCurrentUser)
   const [checkboxesPdfModal, setCheckboxesPdfModal] = useState<boolean>(false)
   const [cardsData, setCardsData] = useState<any>({
      attach_receipt: false,
      collect_payment: false,
      display_activity_feed: true,
      display_authorization_history: true,
      display_messages: true,
   })
   const [data, setData] = useState<any>()

   const filteredCards = useMemo(() => {
      return cards.filter(
         c => !isSmall ? 
         c.name !== "display_authorization_history" : 
         c.name === "display_messages" || c.name === "display_activity_feed"
      )
   }, [cards, isSmall])

   const showPDFSelectModal = () => {
      setCheckboxesPdfModal(true)
   }
   console.log("data", data)

   const loadCards = useCallback(() => {
      if (workOrderNumber) {
         Api.workOrders.getSendSettings(workOrderNumber).then(({ data }) => {
            setData(data)
            setCardsData({
               attach_receipt: data.attach_receipt,
               display_activity_feed: data.display_activity_feed,
               display_authorization_history: data.display_authorization_history,
               display_messages: data.display_messages,
               collect_payment: data.collect_payment,
            })
         })
      }
   }, [])

   useEffect(() => {
      loadCards()
   }, [loadCards])

   const handleCheck = (data: any) => {
      setCardsData({
         ...cardsData,
         [data.name]: data.value,
      })
   }

   const closeModal = () => {
      setCardsData({
         attach_receipt: data.attach_receipt,
         display_activity_feed: data.display_activity_feed,
         display_authorization_history: data.display_authorization_history,
         display_messages: data.display_messages,
         collect_payment: data.collect_payment,
      })
      onClose()
   }

   return (
      <>
         <Container className={shareModal ? "shareModal" : ""}>
            <Content>
               {isXs ? (
                  <Collapse ghost>
                     <Panel key="panel_1" header="Show options">
                        {cardsData &&
                           filteredCards.map((card) => (
                                 <div style={{ marginBottom: isXs ? "10px" : "0px" }}>
                                    <CardSwitch
                                       key={card.name}
                                       item={card}
                                       user={user}
                                       isRecurring={isRecurring}
                                       showPDFSelectModal={showPDFSelectModal}
                                       checked={cardsData[card.name]}
                                       handleCheck={handleCheck}
                                    />
                                 </div>
                              ))}
                     </Panel>
                  </Collapse>
               ) : (
                  cardsData &&
                  filteredCards.map((card) => (
                        <CardSwitch
                           key={card.name}
                           item={card}
                           user={user}
                           isRecurring={isRecurring}
                           showPDFSelectModal={showPDFSelectModal}
                           checked={cardsData[card.name]}
                           handleCheck={handleCheck}
                        />
                     ))
               )}
            </Content>
            <Footer>
               <FooterButton style={{ background: "transparent" }} onClick={closeModal}>
                  Close
               </FooterButton>
               <FooterButton style={{ marginLeft: 8 }} type="primary" loading={saving} onClick={() => onOk(cardsData)}>
                  {shareModal ? "Save" : "Send"}
               </FooterButton>
            </Footer>
         </Container>
         <ModalPrintCheckboxes setPrintModal={setCheckboxesPdfModal} showPrintModal={checkboxesPdfModal} />
      </>
   )
}
