import SubscriptionsStripeForm from "./SubscriptionsStripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeAccountId, StripeAPIKey } from "../../api";
import React, { RefObject, useRef } from "react";
import { Button } from "antd";
import { TCheckoutForm } from "../../modals/Payments/Stripe/CheckoutForm";
import { IExternalRecurring } from "../../api/types";
export type TSubscriptionsStripeForm = {
  refSubmit: RefObject<HTMLFormElement>;
  activeStatus?: number | null;
  estimate?: IExternalRecurring;
  onCancel?: () => void;
  onSubmit: () => void;
  setPaying?: () => void;
  customerStripeAccountId?: string;
};
const StripeSubscriptionsWrapper: React.FC<TSubscriptionsStripeForm> = ({
  refSubmit,
  customerStripeAccountId,
  estimate,
  activeStatus,
  onSubmit,
  onCancel,
  setPaying,
}) => {
  const stripePromise = loadStripe(StripeAPIKey, {
    stripeAccount: customerStripeAccountId
      ? customerStripeAccountId
      : StripeAccountId,
  });

  return (
    <Elements stripe={stripePromise}>
      <SubscriptionsStripeForm
        estimate={estimate}
        onSubmit={onSubmit}
        onCancel={onCancel}
        activeStatus={activeStatus}
        setPaying={setPaying}
        ref={refSubmit}
      />
    </Elements>
  );
};

export default StripeSubscriptionsWrapper;
