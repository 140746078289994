import React, { useCallback, useEffect, useState, createRef } from "react"
import { Checkbox, Form, Input, Modal, notification, Select, Spin, Tabs } from "antd"
import { Api } from "../../api/api"
import { jsPDF } from "jspdf"
import routes from "../../routes/routes"
import html2canvas from "html2canvas"
import styled from "styled-components"
import {
   getErrors,
   getReplacedTemplateMessage,
   mapOptions,
   showErrors,
   renderAddress,
   renderFormDataSend,
} from "../../utils/utils"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import { MessagesTemplateModal } from "../../containers/messages/MessagesTemplateModal"
import { useHistory } from "react-router-dom"
import { TCallback, TOption } from "../../types"
import {
   ESendType,
   ETemplateActions,
   ICreateEstimate,
   IMessageTemplate,
   ITemplateSettings,
   IWorkOrder,
} from "../../api/types"
import { useLoading } from "../../utils/hooks"
import { checkIsPaymentsActive, EstimateConfig } from "./EstimateConfig"
import { useSelector } from "react-redux"
import { getConstant, getCurrentUser } from "../../config/reducers/user/selectors"
import { SwitchButton } from "../../ui/SwitchButton"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { DepositInput, DepositInputWrapper } from "../../modals/Payments/PaymentsModal"
import { IWorkOrderWithJobs } from "../../api/types"
import PdfWorkOrder from "./pdfContent/PdfWorkOrder"
import { TextEditor } from "../../components/TextEditor/TextEditor"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { CheckboxTab, StyledCBTabs, TextMessagingWarning } from "../../modals/Messages/CheckboxTab"

import logo from "../../assets/images/dockworks_logo.png"
import { track } from "../../utils/analytics"

const StyledSwitchButtons = styled(SwitchButton)`
   border-radius: 8px;
   width: 100px;
   justify-content: start;
   .ant-checkbox {
      margin-right: 16px;
   }
   ${down("xs")} {
      width: 48%;
   }
   &.ant-checkbox-wrapper-disabled {
      span {
         color: #c8c7c7;
      }
   }
`
const HiddenPdfDoc = styled.div`
   position: absolute;
   top: 0px;
   left: 200px;
   width: 595px;
   height: 0;
   overflow: hidden;
`

const Title = styled.h2`
   font-weight: 900;
   font-size: 15px;
   margin-bottom: 10px;
   line-height: 20px;
   text-transform: uppercase;
`

const ModalWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: stretch;
   ${down("xs")} {
      flex-direction: column;
   }
`
const StyledForm = styled(Form)`
   .template-item label {
      width: 100%;
   }
`
const FormContainer = styled.div`
   padding: 16px;
   width: calc(50% + 20px);
   ${down("xs")} {
      width: 100%;
      .ant-checkbox-group {
         width: 100%;
         display: flex;
         justify-content: space-between;
      }
   }
`

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 0;
   }
`

const Label = styled.h4`
   font-weight: bold;
   font-size: 13px;
   line-height: 18px;
`

const LinkButton = styled(EmptyButton)`
   > span {
      text-decoration: underline;
   }
`

const TextEditorWrapper = styled.div`
   margin-bottom: 20px;
`

const { TextArea } = Input

type TProps = {
   visible: boolean
   onClose: TCallback
   onSuccess?: TCallback;
   workOrderNumber?: number
   isInvoice?: boolean
   isRequest?: boolean
   isRecurring?: boolean
   isEstimate?: boolean
   workOrder?: IWorkOrderWithJobs | IWorkOrder | null
   jobs?: any[]
   job?: any
}

export const SendEstimateModal: React.FC<TProps> = ({
   visible,
   onClose,
   onSuccess,
   workOrderNumber,
   isInvoice,
   isRequest,
   isRecurring,
   isEstimate,
   workOrder,
   jobs,
   job,
}) => {
   const [templates, setTemplates] = useState<{
      data: IMessageTemplate[]
      mapped: TOption[]
   }>({ data: [], mapped: [] })
   const [form] = Form.useForm<ICreateEstimate>()
   const [messagesTemplateVisible, setMessagesTemplateVisible] = useState<boolean>(false)
   const history = useHistory()
   const [loading, onCall] = useLoading()
   const tags = useSelector(getConstant("template_keys"))
   const user = useSelector(getCurrentUser)
   const isXs = useBreakpoint(down("xs"))
   const [amount, setAmount] = useState<number | undefined>()
   const [messageContent, setMessageContent] = useState<any>()
   const [messageContentHtml, setMessageContentHtml] = useState<any>()

   const componentPrintRef = createRef<HTMLDivElement>()
   const [templateSettings, setTemplateSettings] = useState<ITemplateSettings[]>([])
   const [isLoading, setIsLoading] = useState(false)
   const [currentTab, setCurrentTab] = useState<string>(ESendType.E)
   const [sendType, setSendType] = useState([ESendType.E])

   useEffect(() => {
      if (visible) {
         form.resetFields()
      }
   }, [visible, form])

   const loadTemplates = useCallback(() => {
      if (!templates.data.length) {
         onCall(
            Api.templates.getAll({ ordering: "title" }).then(({ data: dt }) => {
               const data = dt as IMessageTemplate[]
               setTemplates({ data, mapped: mapOptions(data, "title") })
            })
         ).finally()
      }
   }, [templates, onCall])

   useEffect(() => {
      loadTemplates()
   }, [loadTemplates])

   const getActionType = () => {
      if (isRecurring) {
         return ETemplateActions.ServicePlan
      }
      if (isInvoice) {
         return ETemplateActions.SendInvoice
      }
      if (isEstimate) {
         return ETemplateActions.SendEstimate
      }
      return ETemplateActions.EditEstimate
   }

   const loadTemplateSettings = async () => {
      const { data } = await Api.company.getTemplateSettings()
      setTemplateSettings(data)
   }

   const fillDefaultTemplate = () => {
      const actionType = getActionType()
      const templateID = templateSettings.find((t) => t.action === actionType)?.template
      const template = templates.data.find((t) => t.id === templateID)
      if (templateID && template && user) {
         const message = getReplacedTemplateMessage(
            template.content,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const subject = getReplacedTemplateMessage(
            template.subject,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const sms_content = getReplacedTemplateMessage(
            template.sms_content,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         form.setFieldsValue({ template: String(templateID) })
         setMessageContent(message)
         form.setFieldsValue({ content: message, subject, sms_content })
      }
   }

   useEffect(() => {
      loadTemplateSettings()
   }, [])

   useEffect(() => {
      const template = form.getFieldValue("template")
      if (visible && templateSettings.length && templates.data.length && !template) {
         fillDefaultTemplate()
      }
   }, [visible, user, templates, templateSettings])

   const handleCloseManageTemplate = () => {
      loadTemplates()
      setMessagesTemplateVisible(false)
   }

   const handleChangeTemplate = (templateId: string) => {
      const template = templates.data.find((t) => t.id === Number(templateId))
      if (template && user) {
         const message = getReplacedTemplateMessage(
            template.content,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const subject = getReplacedTemplateMessage(
            template.subject,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         const sms_content = getReplacedTemplateMessage(
            template.sms_content,
            tags,
            user,
            workOrder?.customer,
            undefined,
            workOrder
         )
         setMessageContent(message)
         form.setFieldsValue({ content: message, subject, sms_content })
      }
   }

   const updateMessage = (value: string) => {
      setMessageContentHtml(value)
   }

   useEffect(() => {
      form.setFieldsValue({ content: messageContentHtml })
   }, [messageContentHtml])

   const formatData = (fields: ICreateEstimate) => {
      const content = form.getFieldValue("content")
      const sms_content = form.getFieldValue("sms_content")
      const subject = form.getFieldValue("subject")
      return {
         ...fields,
         content,
         ...(sendType.includes(ESendType.S) && {sms_content}),
         subject,
         send_type: sendType,
         template: +fields.template,
         id: workOrderNumber || 0,
         ...(!!amount && !isInvoice && { additional_payment: +amount }),
      }
   }

   const getAction = () => {
      if (isRecurring) {
         return Api.workOrders.sendSubscription
      } else if (isRequest) {
         return Api.workOrders.sendUpdate
      } else if (isInvoice) {
         return Api.workOrders.generateInvoice
      }
      return Api.workOrders.sendUpdate
   }

   const sendEstimate = async (sendSettings: any) => {
      if (!workOrder?.required_deposit_amount) {
         track("Project Proceeded Without Deposit", {
            workorderName: workOrder?.title,
            workorderId: workOrder?.id,
            workorderCreatedBy: user?.id,
            companyId: user?.company?.id,
            customerId: workOrder?.customer.id,
            vesselCity: workOrder?.current_address?.city,
            vesselState: workOrder?.current_address?.state,
            vesselType: workOrder?.vessel?.vessel_type,
            vesselId: workOrder?.vessel?.id,
            estimatedDurationMins: workOrder?.estimated_duration,
            workorderTotal: workOrder?.total,
            amountRequested: workOrder?.required_deposit_amount,
         })
      }

      if (
         workOrder?.total &&
         workOrder?.required_deposit_amount &&
         workOrder?.total < workOrder?.required_deposit_amount
      ) {
         notification.error({
            message: "Deposit amount greater than total. Please change the deposit",
         })
         onClose()
         return
      }
      if (workOrderNumber) {
         const fields = await form.validateFields()
         const action = getAction()
         const formattedData = formatData(fields)
         if (sendType.includes(ESendType.S) && formattedData?.sms_content?.length && formattedData.sms_content.length > 120) {
            setCurrentTab(ESendType.S)
         }
         const data = await renderFormDataSend(
            {
               ...formattedData,
               ...sendSettings,
            },
            generatePdfDocument
         )

         try {
            setIsLoading(true)            
            await action(data)
            form.resetFields()
            if (isEstimate) {
               notification.success({
                  message: "Work order estimate sent to client for approval...",
               })
            }
            if (onSuccess) {
               onSuccess();
            }
            onClose()
            history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(workOrderNumber)))
         } catch (e) {
            const errors = getErrors(e)
            const nonFieldError = errors[0].errors[0]
            if (nonFieldError) {
               notification.error({ message: nonFieldError })
            }
            form.setFields(errors)
         } finally {
            setIsLoading(false)
         }
      }
   }

   // console.log("workOrder", workOrder)
   // console.log("jobs", jobs)
   // console.log("JOB", job)

   const generatePdfDocument = async () => {
      const string = componentPrintRef.current
      const doc = new jsPDF("p", "px", [595, 842])
      let blob: any = ""

      if (string) {
         await doc.html(string, {
            callback: async (pdf) => {
               let totalPages = pdf.internal.pages.length
               renderHeader(doc, string)
               for (let i = totalPages; i >= 0; i--) {
                  renderHeader(doc, string)
                  pdf.setPage(i)
               }
               blob = await pdf.output("blob")

               //pdf.output("dataurlnewwindow")
               return blob
            },
            margin: [115, 20, 25, 20],
            autoPaging: "text",
         })
      }

      return blob
   }

   const renderHeader = async (doc: any, string: any) => {
      const img = string.querySelector(".pdfLogo") as HTMLElement
      const headerLogo = user?.company?.logo ? user.company.logo.replace(/^http:\/\//i, "https://") : logo
      const width = user?.company?.logo ? img.clientWidth : 60
      const height = user?.company?.logo ? img.clientHeight : 60

      doc.addImage(headerLogo, "JPEG", 20, 20, width, height)

      doc.setFont("helvetica", "normal")
      doc.setTextColor("#202020")
      doc.text("COMPANY", 200, 35)
      doc.setFont("helvetica", "bold")
      doc.text(user?.company?.name, 200, 55)
      doc.setFont("helvetica", "normal")
      doc.text(
         `${user?.company?.address?.address1} ${user?.company?.address?.address2} ${user?.company?.address?.city}, ${user?.company?.address?.state}`,
         200,
         70
      )
      doc.text(user?.company?.phone, 200, 80)
      doc.setTextColor("#40a9ff")
      doc.text(user?.company?.email, 200, 95)

      doc.setTextColor("#202020")
      doc.text("BILLING CONTACT", 400, 35)
      doc.setFont("helvetica", "bold")
      doc.text(workOrder?.customer?.full_name, 400, 55)
      doc.setFont("helvetica", "normal")
      doc.text(workOrder?.customer?.address ? renderAddress(workOrder?.customer?.address) : "-", 400, 70)
      doc.text(
         workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")?.phone || "No data",
         400,
         80
      )
      doc.setTextColor("#40a9ff")
      doc.text(workOrder?.customer?.email, 400, 95)
   }

   const setTab = (key: string) => {
      setCurrentTab(key)
   }

   const handleTabCheck = (e: CheckboxChangeEvent, key: ESendType) => {
      if (e.target.checked) {
         setSendType((prev) => [...prev, key])
         return
      }
      setSendType((prev) => prev.filter((i) => i !== key))
   }

   const renderTabContent = () => {
      return (
         <Flex flexDirection="column" height="500px">
            <Form.Item
               name="template"
               className="fullWidthLabel"
               label={
                  <Flex justifyContent="space-between" width={"100%"}>
                     <Label>Template</Label>
                     <EmptyButton
                        fontSize="14px"
                        style={{ lineHeight: "18px" }}
                        fontWeight="700"
                        color="#109CF1"
                        width="auto"
                        onClick={() => setMessagesTemplateVisible(true)}
                     >
                        Manage list
                     </EmptyButton>
                  </Flex>
               }
               style={{ flexDirection: isXs ? "row" : "column" }}
            >
               <Select
                  onChange={handleChangeTemplate}
                  options={templates.mapped}
                  placeholder="Select a template"
                  getPopupContainer={(trigger: any) => trigger.parentElement}
               />
            </Form.Item>
            {currentTab !== ESendType.S && (
               <Form.Item name="subject" label="Subject" style={{ flexDirection: isXs ? "row" : "column" }}>
                  <Input placeholder="Type here" />
               </Form.Item>
            )}
            {currentTab === ESendType.E ? (
               <>
                  <Label style={{ marginBottom: "0px" }}>Message text</Label>
                  <TextEditorWrapper>
                     <TextEditor initialValue={messageContent} onChange={updateMessage} />
                  </TextEditorWrapper>
               </>
            ) : (
               <Form.Item name="sms_content" label="Message text" style={{ flexDirection: isXs ? "row" : "column" }}>
                  <Input.TextArea
                     rows={isXs ? 2 : 5}
                     placeholder="Type here"
                     showCount={{
                        formatter: ({ count, maxLength }) => `${count}/${maxLength} characters`,
                     }}
                     maxLength={120}
                  />
               </Form.Item>
            )}
         </Flex>
      )
   }

   return (
      <>
         <StyledModal
            footer={null}
            width={isXs ? "100%" : 660}
            visible={visible}
            onCancel={onClose}
            onOk={sendEstimate}
            destroyOnClose
            afterClose={() => setAmount(undefined)}
         >
            <StyledForm
               labelCol={{ style: { fontSize: 13, fontWeight: "bold" } }}
               requiredMark={false}
               layout="vertical"
               form={form}
               onFinish={sendEstimate}
            >
               <ModalWrapper>
                  <FormContainer>
                     <Spin spinning={loading}>
                        <Title>
                           {isRecurring
                              ? "Send to client"
                              : isRequest
                              ? "Request Payment"
                              : isInvoice
                              ? "Send Invoice"
                              : isEstimate
                              ? "Request Approval"
                              : "Request Approval"}
                        </Title>
                        {isRequest && (
                           <Flex alignItems="center" flexDirection="column" mt="20px" mb="14px">
                              <Form.Item
                                 name="additional_payment"
                                 rules={[{ required: true, message: "Please input amount" }]}
                              >
                                 <DepositInputWrapper>
                                    <DepositInput
                                       thousandSeparator={true}
                                       decimalScale={2}
                                       allowNegative={false}
                                       onValueChange={(values: any) => {
                                          const { formattedValue, value } = values
                                          setAmount(value)
                                       }}
                                    />
                                 </DepositInputWrapper>
                              </Form.Item>
                           </Flex>
                        )}
                        <StyledCBTabs
                           onChange={setTab}
                           type="card"
                           activeKey={currentTab}
                           tabBarExtraContent={
                              !user?.company?.messaging_status && <TextMessagingWarning onClose={onClose} />
                           }
                        >
                           <Tabs.TabPane
                              tab={
                                 <CheckboxTab
                                    checked={sendType.includes(ESendType.E)}
                                    onChange={(e) => handleTabCheck(e, ESendType.E)}
                                    title="Email"
                                 />
                              }
                              key={ESendType.E}
                           >
                              {renderTabContent()}
                           </Tabs.TabPane>
                           <Tabs.TabPane
                              tab={
                                 <CheckboxTab
                                    checked={sendType.includes(ESendType.S)}
                                    disabled={!user?.company?.messaging_status}
                                    onChange={(e) => handleTabCheck(e, ESendType.S)}
                                    title="SMS"
                                 />
                              }
                              disabled={!user?.company?.messaging_status}
                              key={ESendType.S}
                           >
                              {renderTabContent()}
                           </Tabs.TabPane>
                        </StyledCBTabs>
                     </Spin>
                  </FormContainer>

                  <EstimateConfig
                     onOk={sendEstimate}
                     saving={loading || isLoading}
                     onClose={onClose}
                     isRecurring={isRecurring}
                     workOrderNumber={workOrderNumber}
                  />
               </ModalWrapper>
            </StyledForm>
            <MessagesTemplateModal visible={messagesTemplateVisible} onClose={handleCloseManageTemplate} />
         </StyledModal>
         <HiddenPdfDoc>
            {visible && (
               <PdfWorkOrder
                  ref={componentPrintRef}
                  workOrder={workOrder}
                  jobs={jobs ? jobs : [job]}
                  userData={user}
                  technicianRole={false}
                  isInvoice={isInvoice}
                  isRecurring={!!workOrder?.is_recurring}
                  // recurringPrintPeriod={recurringPrintPeriod}
               />
            )}
         </HiddenPdfDoc>
      </>
   )
}
