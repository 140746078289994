import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Modal, Form, Input, Select, notification, AutoComplete, InputNumber, Button} from "antd"
import Flex from "../noui/Flex"
import {IVessel} from "../api/types"
import {states} from "../constants"
import {Api} from "../api/api"
import {getErrors, setDefaultFields, validateLatitude, validateLongtitude} from "../utils/utils"
import {down} from "styled-breakpoints"
import {GoogleMapsAPIKey} from "../api"
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import {DownOutlined, SearchOutlined} from "@ant-design/icons"
import {Msg} from "../ui/Text"
import GoogleMapReact, {ChangeEventValue, Maps} from "google-map-react";
import {MapMarker} from "./MapMarker"


type TProps = {
    vessel: IVessel
    visible: boolean
    handleClose: () => void
    loadCards: () => void
}

const FormItemRow = styled(Flex)<{ isNested?: boolean, isSingleChild?: boolean }>`
  gap: ${props => props.isNested ? "8px" : "16px"};
  margin: ${props => props.isNested ? "0" : "8px 0 0"};
  width: ${props => props.isNested ? "50%" : "100%"};

  .ant-form-item.ant-row {
    margin-bottom: 0;
  }

  ${down("xs")} {
    gap: 0;

    .ant-row {
      flex-direction: row;

      &:first-child {
        margin-right: ${props => props.isSingleChild ? '0' : props.isNested ? "8px" : "8px"};
      }
    }
  }

  .ant-form-item-label label {
    font-weight: 700;
    font-size: 13px;
    color: #494949;
  }
`

const MapWrapper = styled(Flex)`
  height: 200px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

const CustomFormItem = styled(Form.Item)`
  width: 50%;

  .ant-input-number {
    width: 100%;
  }

`

const Label = styled(Msg)`
  font-weight: 700;
  font-size: 13px;
  color: #494949;
`

const CenterMarker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2147483647;
`

const LinkButton = styled(Button)`
  border: none;
  padding: 0;
  color: #0496FF;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 700;
  margin-left: 8px;
`;

const FullViewButton = styled(Button)`
  border: none;
  padding: 0;
  margin: 16px auto 0;
`;

const LocationModal: React.FC<TProps> = ({vessel, visible, handleClose, loadCards}) => {
    const [form] = Form.useForm()
    const {address, notes, location_name, slip_number} = vessel
    const formFields = {
        ...address,
        notes,
        location_name,
        slip_number,
    }
    const {
        placePredictions,
        placesService,
        getPlacePredictions,
    } = useGoogle({
        apiKey: GoogleMapsAPIKey,
    });
    const [value, setValue] = useState("");
    const [selectedValue, setSelectedValue] = useState<any>(null)
    const [isFullView, setIsFullView] = useState(false)
    const [defaultLocation, setDefaultLocation] = useState({
        lat: vessel.address?.lat ?? 41,
        lng: vessel.address?.lng ?? -73,
    })
    const [mapCenter, setMapCenter] = useState({
        lat: vessel.address?.lat ?? 41,
        lng: vessel.address?.lng ?? -73,
    })
    const [mapsOptions] = useState({
        zoom: 16,
        mapTypeControl: true,
        zoomControl: true,
        mapTypeId: "satellite",
    })

    useEffect(() => {
        setDefaultFields(form, formFields)
        const {lat, lng} = formFields
        if (lat && lng && validateLatitude(lat) && validateLongtitude(lng)) {
            setMapCenter({lat, lng})
            setDefaultLocation({lat, lng})
        }
        setValue(vessel.address?.full_address ?? "")
        if (vessel.address) {
            setIsFullView(true)
        }
        if (!lat && !lng) {
            setDefaultFields(form, {lat: 41, lng: -73})
        }
    }, [form, vessel])

    const handleSubmit = async () => {
        const data = await form.validateFields()
        try {
            await Api.vessels.patch({
                id: vessel.id,
                address: {
                    ...data,
                    full_address: value,
                },
                notes: data?.notes,
                slip_number: data?.slip_number,
                location_name: data?.location_name,
            })
            notification.success({
                message: "Location updated successfully",
            })
            form.resetFields()
            handleClose()
            loadCards()
        } catch (e) {
            const errors = getErrors(e)
            form.setFields(errors)
        }
    }

    const selectPlace = async (_value: string) => {
        const place = placePredictions.find(p => p.description === _value)
        if (place) {
            placesService?.getDetails(
                {placeId: place.place_id},
                (details: any) => setSelectedValue(details)
            )
        }
    }

    const findComponentValue = (name: string, isLong: boolean) => {
        const _component = selectedValue?.address_components?.find((c: any) => c?.types?.includes(name));
        return isLong ? _component?.long_name : _component?.short_name;
    }

    const formatAddress = () => {
        const route = findComponentValue('route', true);
        const street_number = findComponentValue('street_number', true);
        if (route && street_number) {
            return `${street_number} ${route}`
        }
        return selectedValue?.vicinity ?? '';
    }

    const formatData = () => {
        const lat = selectedValue?.geometry?.location.lat()
        const lng = selectedValue?.geometry?.location.lng()
        const zip_code = findComponentValue('postal_code', true);
        const state = findComponentValue('administrative_area_level_1', false);
        const city = findComponentValue('locality', true) ?? findComponentValue('administrative_area_level_1', true);
        const address1 = formatAddress()
        const address2 = findComponentValue('subpremise', true)
        const full_address = value;
        const _data: any = {
            lat,
            lng,
            zip_code,
            state,
            city,
            address1,
            address2,
            full_address
        };
        form.setFieldsValue(_data);
        if (lat && lng && validateLatitude(lat) && validateLongtitude(lng)) {
            setMapCenter({lat, lng})
            setDefaultLocation({lat, lng})
        }
    }

    const updateLatitude = (lat?: number | null) => {
        if (lat && validateLatitude(lat)) {
            setMapCenter(center => ({...center, lat}))
        }
    }

    const updateLongtitude = (lng?: number | null) => {
        if (lng && validateLongtitude(lng)) {
            setMapCenter(center => ({...center, lng}))
        }
    }

    const formatPredictions = () => {
        return placePredictions.map(i => ({label: i.description, value: i.description}))
    }

    const handleAutocompleteValueChange = (_value: string) => {
        setValue(_value);
    }

    const compareCoords = (center: { lat: number, lng: number }) => {
        return (center.lat === mapCenter.lat && center.lng === mapCenter.lng)
    }

    const nonStrictCompare = (center: { lat: number, lng: number }) => {
        return (center.lat.toFixed(3) === mapCenter.lat.toFixed(3) && center.lng.toFixed(3) === mapCenter.lng.toFixed(3))
    }

    const handleMapSettingsChange = (value: ChangeEventValue) => {
        if (!compareCoords(value.center)) {
            setMapCenter(value.center)
            form.setFieldsValue({lat: value.center.lat, lng: value.center.lng})
        }
    }

    const reverseGeocode = () => {
        if (window?.google) {
            var latlng = new google.maps.LatLng(mapCenter.lat, mapCenter.lng);
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({location: latlng}, (results: any, status: any) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    notification.error({message: status});
                }
                if (status == google.maps.GeocoderStatus.OK && results?.length) {
                    const place = results[0]
                    if (place && place.formatted_address && place.place_id) {
                        setValue(place.formatted_address)
                        placesService?.getDetails(
                            {placeId: place.place_id},
                            (details: any) => setSelectedValue(details)
                        )
                    }
                }
            });
        }
    }

    const updateMarker = () => {
        const map = document.querySelector('#vesselLocationWrapper .gm-style')
        if (map) {
            if (map.querySelector('#mapCenter')) return;
            const marker = document.getElementById('mapCenter')
            if (marker) {
                map.append(marker)
            }
        }
    }

    useEffect(() => {
        getPlacePredictions({input: value});
    }, [value])

    useEffect(() => {
        if (selectedValue) {
            if (!isFullView) {
                setIsFullView(true)
            }
            formatData()
        }
    }, [selectedValue])

    useEffect(() => {
        if (visible) {
            setTimeout(updateMarker, 100)
        }
    }, [visible])

    return (
        <Modal visible={visible} onCancel={handleClose} onOk={handleSubmit} title="Set vessel location" okText="Save">
            <Form form={form} layout="vertical" style={{padding: '0 0 16px'}}>
                <Label>Search Address</Label>
                <AutoComplete
                    options={formatPredictions()}
                    value={value}
                    onChange={handleAutocompleteValueChange}
                    style={{width: "100%"}}
                    onSelect={selectPlace}

                >
                    <Input prefix={<SearchOutlined/>} placeholder="Search vessel location"/>
                </AutoComplete>
                {!isFullView ?
                    <Flex width="100%">
                        <FullViewButton onClick={() => setIsFullView(true)}>
                            {<DownOutlined/>}
                        </FullViewButton>
                    </Flex>
                    : (
                        <>
                            <FormItemRow isSingleChild>
                                <Form.Item
                                    label="Address 1"
                                    name="address1"
                                    style={{width: "100%"}}
                                >
                                    <Input placeholder="Address 1"/>
                                </Form.Item>
                            </FormItemRow>
                            <FormItemRow isSingleChild>
                                <Form.Item
                                    label="Address 2"
                                    name="address2"
                                    style={{width: "100%"}}
                                >
                                    <Input placeholder="Address 2"/>
                                </Form.Item>
                            </FormItemRow>
                            <FormItemRow>
                                <CustomFormItem
                                    label="City"
                                    name="city"
                                >
                                    <Input placeholder="Input city "/>
                                </CustomFormItem>
                                <FormItemRow isNested>
                                    <CustomFormItem label="State" name="state">
                                        <Select
                                            placeholder="Select state"
                                            showSearch
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                        >
                                            {states.map((s) => (
                                                <Select.Option value={s} key={s}>
                                                    {s}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </CustomFormItem>
                                    <CustomFormItem
                                        label="Zip Code"
                                        name="zip_code"
                                        rules={[
                                            {
                                                whitespace: true,
                                                message: "Field cannot be empty",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter zip code"/>
                                    </CustomFormItem>
                                </FormItemRow>
                            </FormItemRow>
                            <FormItemRow>
                                <CustomFormItem
                                    name="location_name"
                                    label="Location Name"
                                >
                                    <Input placeholder="Location name"/>
                                </CustomFormItem>
                                <CustomFormItem
                                    name="slip_number"
                                    label="Slip Number"
                                >
                                    <Input placeholder="Slip number"/>
                                </CustomFormItem>
                            </FormItemRow>
                            <Flex flexDirection="column" mt="8px">
                                <Flex justifyContent="space-between" alignItems='center'>
                                    <Label>Pinpoint Location</Label>
                                    {!nonStrictCompare(defaultLocation) && (
                                        <Flex alignItems='center'>
                                            <Msg color="#FB4D4F" fontSize="13px">Your address will change! </Msg>
                                            <LinkButton onClick={reverseGeocode}>Confirm?</LinkButton>
                                        </Flex>
                                    )}
                                </Flex>
                                <MapWrapper id="vesselLocationWrapper">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{key: GoogleMapsAPIKey}}
                                        center={mapCenter}
                                        zoom={mapsOptions.zoom}
                                        options={mapsOptions}
                                        onChange={handleMapSettingsChange}
                                    />
                                    <CenterMarker id="mapCenter">
                                        <MapMarker width="30px" lat={null} lng={null} children={null}/>
                                    </CenterMarker>
                                </MapWrapper>
                            </Flex>
                            <FormItemRow>
                                <CustomFormItem label="Latitude" name="lat">
                                    <InputNumber
                                        placeholder="Latitude"
                                        value={mapCenter.lat}
                                        onChange={(lat) => updateLatitude(+lat)}
                                    />
                                </CustomFormItem>
                                <CustomFormItem label="Longitude" name="lng">
                                    <InputNumber
                                        placeholder="Longitude"
                                        value={mapCenter.lng}
                                        onChange={(lng) => updateLongtitude(+lng)}
                                    />
                                </CustomFormItem>
                            </FormItemRow>
                        </>
                    )}
            </Form>
        </Modal>
    )
}

export default LocationModal;