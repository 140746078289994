import styled from "styled-components"
import { Spin, Empty, Button } from "antd"
import Flex from "../../noui/Flex"
import { useState, useEffect, useCallback } from "react"
import { Msg } from "../../ui/Text"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import { PrimaryTooltip } from "../../ui/Tooltip"
import { Api } from "../../api/api"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { down } from "styled-breakpoints"
import { ScrollWrapper } from "./ScrollWrapper"
import { TitleWrapper } from "./TitleWrapper"
import { TableWrapper } from "./TableWrapper"
import { Row } from "./Row"
import { ActionContainer } from "./ActionContainer"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import routes from "../../routes/routes"
import { useTechnicianRole } from "../../utils/hooks"
import { DefaultDateFormat } from "../../constants"

const JobsContainer = styled.div`
   position: relative;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   display: flex;
   flex-direction: column;
   background-color: #ffffff;
   & .ant-row.ant-form-item {
      margin-bottom: 0;
   }
   ${down("lg")} {
      grid-column-start: 1;
      grid-column-end: 3;
   }
`

const OpenEstimateWidget = () => {
   const [jobsList, setJobsList] = useState([])
   const [jobsElements, setJobsElements] = useState([])
   const [showMore, setShowMore] = useState(false)
   const [loading, setLoading] = useState(false)
   const technicianRole = useTechnicianRole()
   const history = useHistory()
   const isXS = useBreakpoint(down("xs"))
   const isMD = useBreakpoint(down("md"))

   const loadCards = useCallback(async () => {
      setLoading(true)
      try {
         await Api.jobs.getAll({ status: "e" }).then((resp) => {
            setJobsList(resp.data)
            setJobsElements(resp.data.slice(0, 4))
         })
         setLoading(false)
      } catch (e) {
         setLoading(false)
      }
   }, [])

   useEffect(() => {
      loadCards().finally()
   }, [loadCards])

   const currentListOfJobs = () => {
      showMore ? setJobsElements(jobsList.slice(0, 4)) : setJobsElements([...jobsList])
      setShowMore(!showMore)
   }

   const openCreateEstimate = () => {
      history.push(routes.workOrder.CreateWorkOrder)
   }

   const MobileRowContent = ({ data }) => {
      const technicianRole = useTechnicianRole()
      return (
         <Flex flexDirection="column" alignItems="start" width="100%">
            <Flex flexDirection="row" justifyContent="space-between" width="100%">
               <Flex flexDirection="column" justifyContent="space-between" width={isXS ? "70px" : "33.333%"}>
                  <Msg fontSize="12px" fontWeight="700" color="#484848" className="short-text">
                     #{data.id}
                  </Msg>
               </Flex>
               <Flex flexDirection="column" justifyContent="end" width={isXS ? "70px" : "33.333%"}>
                  <Msg fontSize="12px" fontWeight="700" color="#484848" className="short-text">
                     {`${data.customer_data.first_name} ${data.customer_data.last_name}`}
                  </Msg>
                  <Msg fontSize="12px" fontWeight="400" color="#484848" className="short-text">
                     {data.vessel_data?.name}
                  </Msg>
               </Flex>
               <Flex
                  flexDirection="column"
                  textAlign="right"
                  justifyContent="space-between"
                  width={isXS ? "70px" : "33.333%"}
               >
                  <Msg fontSize="12px" fontWeight="400" color="#F84C4E">
                     Submitted {moment(data.created).format("MM/DD")}
                  </Msg>
               </Flex>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" width="100%">
               <Msg fontSize="12px" fontWeight="400" color="#484848" className="short-text">
                  {data.title}
               </Msg>
               {!technicianRole && (
                  <Msg fontSize="12px" fontWeight="900" color="#109CF1">
                     ${(+data.total).toFixed(2)}
                  </Msg>
               )}
            </Flex>
         </Flex>
      )
   }

   return (
      <JobsContainer>
         <TitleWrapper>
            <Msg fontWeight={900} fontSize={16} color="#151F2B" mr="11px">
               {technicianRole ? "OPEN WORK ORDERS" : "OPEN ESTIMATES"}
            </Msg>
            {!isXS && !technicianRole ? (
               <PrimaryButton height="32px" onClick={openCreateEstimate} fontSize="13px" fontWeight="600">
                  Create Work Order
               </PrimaryButton>
            ) : null}
         </TitleWrapper>
         <ScrollWrapper>
            <TableWrapper>
               {!loading ? (
                  jobsElements.length > 0 ? (
                     jobsElements.map((job) => (
                        <Row
                           key={job.id}
                           style={{ gap: "0px", cursor: "pointer" }}
                           onClick={() =>
                              history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(job.work_order)))
                           }
                        >
                           {isMD ? (
                              <MobileRowContent data={job} />
                           ) : (
                              <>
                                 <Flex flexDirection="column">
                                    <Msg fontSize="13px" fontWeight="700" color="#484848">
                                       {job.order_number}
                                    </Msg>
                                    {/* <Msg fontSize='15px' fontWeight='400' color='#484848'>xbxdfb</Msg> */}
                                 </Flex>
                                 <Flex flexDirection="column" justifyContent="space-between">
                                    <PrimaryTooltip
                                       title={`${job.customer_data.first_name} ${job.customer_data.last_name}`}
                                       width="100px"
                                    >
                                       <Msg
                                          fontSize="15px"
                                          fontWeight="700"
                                          color="#484848"
                                          width="20px"
                                          className="short-text"
                                       >{`${job.customer_data.first_name} ${job.customer_data.last_name}`}</Msg>
                                    </PrimaryTooltip>
                                    <PrimaryTooltip title={job.vessel_data?.name} width="100px">
                                       <Msg
                                          fontSize="15px"
                                          fontWeight="400"
                                          color="#484848"
                                          width="20px"
                                          className="short-text"
                                       >
                                          {job.vessel_data?.name}
                                       </Msg>
                                    </PrimaryTooltip>{" "}
                                 </Flex>
                                 <Flex flexDirection="column" justifyContent="flex-end">
                                    <PrimaryTooltip title={job.title} width="100px">
                                       <Msg
                                          fontSize="15px"
                                          fontWeight="400"
                                          color="#484848"
                                          width="20px"
                                          className="short-text"
                                       >
                                          {job.title}
                                       </Msg>
                                    </PrimaryTooltip>
                                 </Flex>
                                 <Flex flexDirection="column">
                                    {job.sent ? (
                                       <Msg fontSize="15px" fontWeight="400" color="#F84C4E" mb="5px">
                                          Sent {moment(job.sent).format(DefaultDateFormat)}{" "}
                                       </Msg>
                                    ) : (
                                       <Msg fontSize="15px" fontWeight="400" color="#42C57A" mb="5px">
                                          Created {moment(job.created).format(DefaultDateFormat)}{" "}
                                       </Msg>
                                    )}
                                    {!technicianRole && (
                                       <Msg
                                          fontSize="15px"
                                          fontWeight="900"
                                          color="#109CF1"
                                          textAlign={isMD ? undefined : "end"}
                                       >
                                          ${(+job.total).toFixed(2)}
                                       </Msg>
                                    )}
                                 </Flex>
                              </>
                           )}
                        </Row>
                     ))
                  ) : (
                     <Empty />
                  )
               ) : (
                  <Spin />
               )}
            </TableWrapper>
         </ScrollWrapper>
         {jobsList.length > 4 ? (
            <EmptyButton width="100%" height="45px" onClick={currentListOfJobs}>
               <Msg fontWeight={700} fontSize={14} color="#109CF1" style={{ textDecoration: "underline" }}>
                  {showMore ? "View Less" : "View All"}
               </Msg>
            </EmptyButton>
         ) : null}
         {isXS && !technicianRole ? (
            <ActionContainer>
               <Button type="primary" onClick={openCreateEstimate}>
                  Create Work Order
               </Button>
            </ActionContainer>
         ) : null}
      </JobsContainer>
   )
}

export default OpenEstimateWidget
