import { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import { Api } from "../../../api/api";
import { notification, Spin, Select, Empty, Button, Popover } from "antd";
import Flex from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import styled from "styled-components";
import { SearchWithBorder } from "../../../noui/Search";
import GoogleMapReact from "google-map-react";
import Box from "../../../noui/Box";
import { CustomerCart } from "./CustomerCart";
import { EmptyButton } from "../../../ui/Button";
import { Marker } from "../../../containers/customers/pages/styled";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { MapPopover } from "./MapPopover";
import moment from "moment";
import { GoogleMapsAPIKey } from "../../../api";
const { Option } = Select;

const MainContainer = styled(Flex)`
  padding: 3px;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SelectFilter = styled(Select)`
  height: 34px;
  width: 138px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
    height: 34px;
    font-weight: 600;
    font-size: 14px;
    width: 138px;
    & .ant-select-selection-item {
      line-height: 32px;
    }
  }
  ${down("xs")} {
    width: 100%;
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
    }
    &:first-child {
      margin-right: 20px;
    }
  }
`;

const MapWrap = styled(Box)`
  width: 63%;
  height: 809.5px;
  border-radius: 4px;
  div {
    border-radius: 4px;
  }
  ${down("lg")} {
    width: 100%;
    height: 350px;
  }
`;

const SearchField = styled(SearchWithBorder)`
  height: 30px;
`;

const Container = styled(Flex)`
  height: 750px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #109cf1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ${down("lg")} {
    height: 350px;
  }
`;

const LocationWrapper = styled(EmptyButton)`
  height: fit-content;
  min-height: 90px;
`;

const createMultipleValueOptions = (options) =>
  Object.entries(options).map((item) => (
    <Option value={item[1].join(" ")}>{item[0]}</Option>
  ));

const dateOptions = {
  Today: [
    moment().startOf("day").toISOString(),
    moment().startOf("day").add(1, "day").toISOString(),
  ],
  "This week": [
    moment().startOf("week").toISOString(),
    moment().startOf("week").add(1, "week").toISOString(),
  ],
  "Next week": [
    moment().startOf("week").add(1, "week").toISOString(),
    moment().startOf("week").add(2, "week").toISOString(),
  ],
  "Any Time": ["", ""],
};

export const Map = () => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [vesselData, setVesselData] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [mapsOptions, setMapOptions] = useState({
    center: {
      lat: 40,
      lng: -73,
    },
    zoom: 1,
    mapTypeControl: true,
  });
  const isLg = useBreakpoint(down("lg"));
  const [date, setDate] = useState(["", ""]);

  const handleAllVesselsButtonClick = () => {
    setSelectedVessel(null);
    setSelectedJob(null);
  };

  const chooseLocation = (element) => {
    setSelectedVessel(element?.job?.vessel?.id ?? null);
  };

  const handleJobClick = (el) => {
    if (el.id !== selectedJob) {
      chooseLocation(el);
      setSelectedJob(el.id);
    } else {
      handleAllVesselsButtonClick();
    }
  };

  const filterJobs = (c) =>
    `${c.job.customer.first_name.toLowerCase()} ${c.job.customer.last_name.toLowerCase()}`.includes(
      search.toLowerCase()
    );

  const findSchedule = () => jobs.find((j) => j.id === selectedJob);

  const renderTimePicker = () => (
    <div style={{ position: "relative" }}>
      <SelectFilter
        placeholder="Select"
        defaultValue=" "
        onChange={(e) => {
          const [start, end] = e.split(" ");
          setDate([start, end]);
        }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {createMultipleValueOptions(dateOptions)}
      </SelectFilter>
    </div>
  );

  useEffect(() => {
    setLoading(true);
    Api.schedules
      .getAll({ time_after: date[0], time_before: date[1] })
      .then(({ data }) => {
        setJobs(data);
      })
      .catch((e) => {
        notification.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [date]);

  useEffect(() => {
    if (jobs.length) {
      let newVesselData = [];
      jobs
        .filter((j) => !selectedVessel || j?.job?.vessel?.id === selectedVessel)
        .filter((jobs) => jobs.address?.lat && jobs.address?.lng)
        .forEach((j) => {
          const vesselInfo = newVesselData.find(
            (job) => job.lat === j.address?.lat && job.lng === j.address?.lng
          );
          if (!vesselInfo) {
            newVesselData.push({
              lat: j.address?.lat ?? 0,
              lng: j.address?.lng ?? 0,
              jobs: [j.id],
            });
          } else {
            const updatedData = newVesselData.filter(
              (vi) => !(vi.lat === vesselInfo.lat && vi.lng === vesselInfo.lng)
            );
            updatedData.push({
              ...vesselInfo,
              jobs: [...vesselInfo.jobs, j.id],
            });
            newVesselData = updatedData;
          }
        });
      setVesselData(newVesselData);
    }
  }, [jobs, selectedVessel]);

  useEffect(() => {
    if (vesselData.length) {
      setMapOptions({
        center: {
          lat: vesselData[0].lat ?? 40,
          lng: vesselData[0].lng ?? -73,
        },
        zoom: 1,
        mapTypeControl: true,
      });
    }
  }, [vesselData]);

  return (
    <>
      <Helmet>
        <title>Project Map - DockWorks Pro</title>
      </Helmet>
      <MainContainer flexDirection="column">
        <Flex alignItems="center" mb="15px" justifyContent="space-between">
          <Flex flexDirection="row" width={isLg ? "100%" : "initial"}>
            {/* <SelectFilter value="Customers" style={{marginRight: "16px"}} /> */}
            {!isLg && renderTimePicker()}
          </Flex>
          {isLg ? (
            renderTimePicker()
          ) : (
            <Msg fontSize="15px" fontWeight="800" width="35.5%">
              JOB LIST
            </Msg>
          )}
        </Flex>
        {isLg && (
          <Flex mb="24px" flexDirection="column">
            <SearchField
              placeholder="Search here"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              onClick={handleAllVesselsButtonClick}
              style={{ marginTop: "10px" }}
            >
              Show all customers
            </Button>
          </Flex>
        )}
        <Spin spinning={loading}>
          <Flex
            justifyContent="space-between"
            flexDirection={isLg ? "column" : "row"}
          >
            <MapWrap>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
                center={mapsOptions.center}
                defaultZoom={mapsOptions.zoom}
                mapTypeId="satellite"
                options={mapsOptions}
              >
                {vesselData.length &&
                  vesselData.map((item) =>
                    item.jobs.includes(selectedJob) ? (
                      <Marker lat={item.lat} lng={item.lng}>
                        <div style={{ position: "relative" }}>
                          <Popover
                            content={
                              <MapPopover schedule={findSchedule() ?? null} />
                            }
                            visible={true}
                            trigger="click"
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                          >
                            <div></div>
                          </Popover>
                        </div>
                      </Marker>
                    ) : (
                      <Marker lat={item.lat} lng={item.lng} />
                    )
                  )}
              </GoogleMapReact>
            </MapWrap>
            <Flex
              flexDirection="column"
              width={isLg ? "100%" : "35.5%"}
              marginTop={isLg ? "15px" : "0px"}
            >
              {!isLg && (
                <Flex mb="24px" flexDirection="column">
                  <SearchField
                    placeholder="Search customer"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    onClick={handleAllVesselsButtonClick}
                    style={{ marginTop: "10px" }}
                  >
                    Show all customers
                  </Button>
                </Flex>
              )}
              <Container flexDirection="column" width="100%">
                {jobs.length > 0 && jobs.filter(filterJobs).length > 0 ? (
                  jobs.filter(filterJobs).map((el) => (
                    <LocationWrapper onClick={() => handleJobClick(el)}>
                      <CustomerCart data={el} activated={selectedJob} />
                    </LocationWrapper>
                  ))
                ) : (
                  <Empty />
                )}
              </Container>
            </Flex>
          </Flex>
        </Spin>
      </MainContainer>
    </>
  );
};
