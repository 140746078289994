import { Spin, Empty } from "antd"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import React, { useState, useEffect, useCallback, MouseEventHandler, useRef } from "react"
import { Msg } from "../../../ui/Text"
import { EmptyButton } from "../../../ui/Button"
import { PrimaryTooltip } from "../../../ui/Tooltip"
import moment from "moment"
import { Api, TSchedule } from "../../../api/api"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { TableWrapper } from "../TableWrapper"
import { Row } from "../Row"
import { ScrollWrapper } from "../ScrollWrapper"

import { AppointmentIcon, WorkOrderIcon, RecurringJobIcon, ConflictJobIcon } from "../../../assets/icons"
import { IAppointment } from "../../../api/types"
import { useHistory } from "react-router-dom"
import routes from "../../../routes/routes"
import { DefaultDateFormat, DefaultDateFormatWithShortWeekDay } from "../../../constants"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import { useAppSelector } from "../../../config/hooks"
import { parseDuration } from "../../../utils/utils"

const Wrapper = styled.div`
   width: 100%;
   padding: 0;
   ${down("md")} {
      grid-template-start: 1;
      grid-template-end: 3;
   }
`
const ImageWrapper = styled.div`
   width: 16px;
   height: 16px;
   line-height: 16px;
   > img {
      width: 100%;
      height: 100%;
   }
`
const StyledRow = styled(Row)`
   border: 1px solid transparent;
   gap: 16px;
   cursor: pointer;
   &.conflict {
      border: 1px solid #fb4d4f;
   }
`

type TJobCard = {
   id: number
   image: any
   startDate: string
   endDate: string
   vesselName: string
   customerName: string
   additionalText: string
   workOrder?: number
   conflict?: boolean
   timeLogged?: string
   job?: number
   activeClock?: number | null
   activeStarted?: string | null
}

type TActiveTiming = { id: number; timing: number }

const MobileRowContent: React.FC<{
   data: TJobCard
   timingList: number[]
   isMD: boolean
   onClockIn: MouseEventHandler<HTMLElement>
}> = ({ data, timingList, onClockIn, isMD }) => (
   <Flex flexDirection="column" alignItems="start" width="100%">
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
         <Flex flexDirection="column">
            <Msg fontSize="12px" fontWeight="600" marginBottom={"6px"} color="#42C77B">
               {moment(new Date(data.startDate)).format("ddd, MM/DD/YY")}
            </Msg>
            <Msg fontSize="12px" fontWeight="400" color="#484848">
               {moment(new Date(data.startDate)).format("LT")}
            </Msg>
         </Flex>
         <Flex flexDirection="column" textAlign="center" width="100px">
            <Msg fontSize="13px" marginBottom={"6px"} fontWeight="700" color="#494949" className="short-text">
               {data.vesselName}
            </Msg>
            <Msg fontSize="12px" fontWeight="400" color="#484848" className="short-text">
               {data.customerName}
            </Msg>
         </Flex>
         <Flex ml="auto" flexDirection="column" textAlign={"right"}>
            <Msg
               onClick={timingList.includes(data.id) ? () => {} : onClockIn}
               textDecoration="underline"
               fontSize="15px"
               fontWeight="600"
               color={timingList.includes(data.id) ? "gray" : "#109cf1"}
            >
               Clock {data.activeClock ? "Out" : "In"}
            </Msg>
            <Msg fontSize="15px" fontWeight="400" color="#484848" textAlign={isMD ? undefined : "end"}>
               {parseDuration(data.timeLogged)}
            </Msg>
         </Flex>
      </Flex>
      <Flex flexDirection="row" justifyContent="start" alignItems="end">
         <ImageWrapper>{data.image}</ImageWrapper>
         <Msg fontSize="12px" fontWeight="400" color="#484848" marginLeft="8px">
            {data.additionalText}
         </Msg>
      </Flex>
   </Flex>
)

export const UpcomingJobs: React.FC = () => {
   const [cardsList, setCardsList] = useState<TJobCard[]>([])
   const [cardElements, setCardsElements] = useState<TJobCard[]>([])
   const [showMore, setShowMore] = useState<boolean>(false)
   const [timingList, setTiming] = useState<number[]>([])
   const [activeTimings, setActiveTimings] = useState<TActiveTiming[]>([])
   const intervalRef = useRef<NodeJS.Timeout | null>(null)
   const [loading, setLoading] = useState<boolean>(false)
   const history = useHistory()
   const currentUser = useAppSelector(getCurrentUser)
   const isXS = useBreakpoint(down("xs"))
   const isMD = useBreakpoint(down("md"))

   const loadCards = useCallback(async (noLoading?: boolean) => {
      !noLoading && setLoading(true)
      try {
         const cards: TJobCard[] = []
         const conflictCards: TJobCard[] = []
         await Api.schedules
            .getAll({
               time_after: moment().startOf("day").toISOString(),
               time_before: moment().endOf("month").toISOString(),
               is_alert: false,
            })
            .then(({ data }) => {
               const schedules = data as TSchedule[]

               schedules.forEach((schedule) =>
                  cards.push({
                     id: schedule.id,
                     image: schedule.is_recurring ? <RecurringJobIcon /> : <WorkOrderIcon />,
                     startDate: schedule.start,
                     endDate: schedule.end,
                     vesselName: schedule.job?.vessel?.name,
                     customerName: schedule.job.customer.first_name + " " + schedule.job.customer.last_name,
                     additionalText: schedule.job.title,
                     workOrder: schedule.job.work_order,
                     job: schedule.job.id,
                     timeLogged: schedule.time_logged,
                     activeClock: schedule.active_clock,
                     activeStarted: schedule.active_started,
                  })
               )
            })
         await Api.schedules
            .getAll({
               time_after: moment().startOf("day").toISOString(),
               time_before: moment().endOf("month").toISOString(),
               is_alert: true,
            })
            .then(({ data }) => {
               const schedules = data as TSchedule[]

               schedules.forEach((schedule) =>
                  conflictCards.push({
                     id: schedule.id,
                     image: <ConflictJobIcon />,
                     startDate: schedule.start,
                     endDate: schedule.end,
                     vesselName: schedule.job?.vessel.name,
                     customerName: schedule.job.customer.first_name + " " + schedule.job.customer.last_name,
                     additionalText: schedule.job.title,
                     workOrder: schedule.job.work_order,
                     job: schedule.job.id,
                     conflict: true,
                     timeLogged: schedule.time_logged,
                     activeClock: schedule.active_clock,
                     activeStarted: schedule.active_started,
                  })
               )
            })
         await Api.appointments
            .getAll({
               unscheduled: false,
               scheduled_after: moment().toISOString(),
               scheduled_before: moment().endOf("month").toISOString(),
            })
            .then(({ data }) => {
               const appointments = data as IAppointment[]

               appointments.forEach((appointment) =>
                  cards.push({
                     id: appointment.id,
                     image: <AppointmentIcon />,
                     startDate: appointment.schedule.start,
                     endDate: appointment.schedule.end,
                     vesselName: appointment?.vessel.name,
                     customerName: appointment.customer.first_name + " " + appointment.customer.last_name,
                     additionalText: appointment.purpose_of_visit,
                  })
               )
            })
         setCardsList([...conflictCards, ...cards])
         setCardsElements([...conflictCards, ...cards].slice(0, 4))
      } finally {
         !noLoading && setLoading(false)
      }
   }, [])

   useEffect(() => {
      loadCards().finally()
   }, [loadCards])

   const manageActiveClocks = useCallback(() => {
      for (let element of cardElements) {
         if (element.activeStarted) {
            const now = moment()
            const started = moment(element.activeStarted)
            const diff = moment.duration(now.diff(started)).asSeconds()
            setActiveTimings((t) => [
               ...t.filter((el) => el.id !== element.id),
               { id: element.id, timing: Math.floor(diff) },
            ])
         } else {
            setActiveTimings((t) => t.filter((el) => el.id !== element.id))
         }
      }
      if (intervalRef.current) {
         clearTimeout(intervalRef.current)
      }
      intervalRef.current = setTimeout(manageActiveClocks, 1000)
   }, [cardElements])

   useEffect(() => {
      // Manage active time clock ticks
      manageActiveClocks()
      return () => {
         if (intervalRef.current) {
            clearTimeout(intervalRef.current)
            intervalRef.current = null
         }
      }
   }, [manageActiveClocks])

   const currentListOfJobs = () => {
      showMore ? setCardsElements(cardsList.slice(0, 4)) : setCardsElements(cardsList)
      setShowMore(!showMore)
   }

   const handleClockIn =
      (card: TJobCard): MouseEventHandler<HTMLElement> =>
      async (e) => {
         // Stop propagation to outer div which will open job
         e.stopPropagation()
         if (currentUser && card.job) {
            setTiming((t) => [...t, card.id])
            if (!card.activeClock) {
               // Clock In
               const start = moment().toISOString()
               await Api.timeClocks
                  .create({
                     user: currentUser?.id,
                     start,
                     job: card.job,
                  })
                  .then(({ data }) => {
                     setCardsList((cl) =>
                        cl.map((ce) => (ce?.job === data.job.id ? { ...ce, activeClock: data.id } : ce))
                     )
                     setCardsElements((cl) =>
                        cl.map((ce) =>
                           ce?.job === data.job.id
                              ? {
                                   ...ce,
                                   activeClock: data.id,
                                   activeStarted: start,
                                }
                              : ce
                        )
                     )
                  })
                  .finally(() => {
                     setTiming((t) => t.filter((el) => el !== card.id))
                  })
            } else {
               // Clock Out
               await Api.timeClocks
                  .stop(card.activeClock)
                  .then(() => {
                     setCardsList((cl) => cl.map((ce) => (ce?.job === card.job ? { ...ce, activeClock: null } : ce)))
                     setCardsElements((cl) =>
                        cl.map((ce) => (ce?.job === card.job ? { ...ce, activeClock: null } : ce))
                     )
                     loadCards(true).finally()
                  })
                  .finally(() => {
                     setTiming((t) => t.filter((el) => el !== card.id))
                  })
            }
         }
      }

   return (
      <Wrapper>
         <ScrollWrapper>
            <TableWrapper>
               {!loading ? (
                  cardElements.length > 0 ? (
                     cardElements.map((card) => (
                        <StyledRow
                           key={card.id}
                           onClick={() =>
                              card.workOrder &&
                              history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(card.workOrder)))
                           }
                           className={card.conflict ? "conflict" : ""}
                        >
                           {isMD ? (
                              <MobileRowContent
                                 isMD={isMD}
                                 timingList={timingList}
                                 onClockIn={handleClockIn(card)}
                                 data={card}
                              />
                           ) : (
                              <>
                                 <Flex style={{ alignSelf: "center" }}>{card.image}</Flex>
                                 <Flex style={{ flexBasis: "115px" }} column>
                                    <Msg fontSize="15px" fontWeight="600" color="#42C77B">
                                       {moment(new Date(card.startDate)).format(DefaultDateFormatWithShortWeekDay)}
                                    </Msg>
                                    <Msg fontSize="15px" fontWeight="400" color="#484848">
                                       {moment(new Date(card.startDate)).format("LT")}
                                    </Msg>
                                 </Flex>
                                 <Flex flexDirection="column" justifyContent="space-between" height="100%">
                                    <PrimaryTooltip title={card.vesselName} width="100px">
                                       <Msg
                                          fontSize="15px"
                                          fontWeight="700"
                                          color="#484848"
                                          width="20px"
                                          className="short-text"
                                       >
                                          {card.vesselName}
                                       </Msg>
                                    </PrimaryTooltip>
                                    <PrimaryTooltip title={card.customerName} width="100px">
                                       <Msg
                                          fontSize="15px"
                                          fontWeight="400"
                                          color="#484848"
                                          width="20px"
                                          className="short-text"
                                       >
                                          {card.customerName}
                                       </Msg>
                                    </PrimaryTooltip>
                                 </Flex>
                                 {/*{!isMD ? (
                                    <Flex flexDirection="column">
                                       <PrimaryTooltip title={card.additionalText} width="100px">
                                          <Msg
                                             fontSize="15px"
                                             fontWeight="400"
                                             color="#484848"
                                             width="20px"
                                             className="short-text"
                                          >
                                             {card.additionalText}
                                          </Msg>
                                       </PrimaryTooltip>
                                    </Flex>
                                 ) : null}*/}
                                 <Flex ml="auto" flexDirection="column">
                                    <Msg
                                       onClick={timingList.includes(card.id) ? () => {} : handleClockIn(card)}
                                       textDecoration="underline"
                                       fontSize="15px"
                                       fontWeight="600"
                                       color={timingList.includes(card.id) ? "gray" : "#109cf1"}
                                    >
                                       Clock {card.activeClock ? "Out" : "In"}
                                    </Msg>
                                    <Msg
                                       fontSize="15px"
                                       fontWeight="400"
                                       color="#484848"
                                       textAlign={isMD ? undefined : "end"}
                                    >
                                       {parseDuration(
                                          card.timeLogged,
                                          activeTimings.find((el) => el.id === card.id)?.timing
                                       )}
                                    </Msg>
                                 </Flex>
                              </>
                           )}
                        </StyledRow>
                     ))
                  ) : (
                     <Empty />
                  )
               ) : (
                  <Flex width="100%" justifyContent="center">
                     <Spin />
                  </Flex>
               )}
            </TableWrapper>
         </ScrollWrapper>
         {cardsList.length > 4 ? (
            <EmptyButton width="100%" height="45px" onClick={currentListOfJobs}>
               <Msg fontWeight={700} fontSize={14} color="#109CF1" style={{ textDecoration: "underline" }}>
                  {showMore ? "View Less" : "View All"}
               </Msg>
            </EmptyButton>
         ) : (
            ""
         )}
      </Wrapper>
   )
}
