import { Button } from "antd";
import styled from "styled-components";
import { TMAlert, TMCheck, TMDirectToTech, TMLocalNumbers, TMMessaging, TMNotification, TMPayOnTheGo, TMRealTimeUpdates } from "../../assets/icons";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";

const Container = styled(Flex)`
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    max-width: 1070px;
    margin: 48px auto;
    padding: 24px 85px 64px;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const GetStartedButton = styled(Button)`
    width: 440px;
    height: 40px;
    padding: 9px 15px;
    margin-top: 48px;
`

const Title = styled(Msg)`
    font-size: 18px;
    font-weight: 900;
    color: #494949;
    max-width: 600px;
    text-align: center;
    text-transform: uppercase;
`

const HeaderBlock = styled(Flex)`
    width: 100%;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    padding: 24px 0;
    margin: 24px 0 48px;
    >div {
        flex: 1;
        border-left: 1px solid #EDEDED;
        &:first-child {
            border-left: none;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
`

const HeaderTitle = styled(Msg)`
    font-size: 22px;
    font-weight: 700;
    color: #494949;
`

const HeaderText = styled(Msg)`
    font-size: 15px;
    font-weight: 400;
    color: #202020;
    text-align: center;
`

const MainBlock = styled(Flex)`
    flex-direction: column;
    align-items: center;
    width: 100%;
    >span {
        font-size: 15px;
        font-weight: 700;
        color: #202020;
    }
    >div {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;
        width: 100%;
        >div {
            width: calc(50% - 8px);
        }
    }
`;

const BlockLayout = styled(Flex)`
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    flex-direction: column;
    padding: 24px;
`;

type TBlockLayoutTextProps = {
    isTitle?: boolean
}

const BlockLayoutText = styled(Msg)<TBlockLayoutTextProps>`
    font-size: ${props => props.isTitle ? "18px" : "15px"};
    font-weight: ${props => props.isTitle ? "900" : "500"};
    color: ${props => props.isTitle ? "#494949" : "#202020"};
    margin-left: 8px;
`

type TBlockLayoutRowProps = {
    Icon: JSX.Element
    text: string
    isTitle?: boolean
}

const BlockLayoutRow: React.FC<TBlockLayoutRowProps> = ({Icon, text, isTitle}) => {
    return <Flex alignItems="center" marginTop={!isTitle ? "16px" : "0"}>
        {Icon}
        <BlockLayoutText isTitle={isTitle}>{text}</BlockLayoutText>
    </Flex>
}

type TProps = {
    startOnboarding: () => void
}

export const TextMessagingGetStarted: React.FC<TProps> = ({startOnboarding}) => {
    return <Container>
        <Title>
            DockWorks Messaging allows you to connect directly to your customers in real-time, all the time. 
        </Title>
        <HeaderBlock>
            <div>
                <TMNotification />
                <HeaderTitle>Notifications</HeaderTitle>
                <HeaderText>Notify your customers when their vessel is ready.</HeaderText>
            </div>
            <div>
                <TMAlert />
                <HeaderTitle>Alerts</HeaderTitle>
                <HeaderText>Alert your customers if there's an issue when it happens.</HeaderText>
            </div>
            <div>
                <TMMessaging />
                <HeaderTitle>Messaging</HeaderTitle>
                <HeaderText>Send and receive text messages from your dedicated business number.</HeaderText>
            </div>
        </HeaderBlock>
        <MainBlock>
            <Msg>Supercharge your business with real-time messaging:</Msg>
            <div>
                <BlockLayout>
                    <BlockLayoutRow Icon={<TMPayOnTheGo />} text="PAY ON-THE-GO" isTitle />
                    <BlockLayoutRow Icon={<TMCheck />} text="Get approvals and payments faster" />
                    <BlockLayoutRow Icon={<TMCheck />} text="Send payment links via text to your clients" />
                </BlockLayout>
                <BlockLayout>
                    <BlockLayoutRow Icon={<TMRealTimeUpdates />} text="REAL-TIME UPDATES" isTitle />
                    <BlockLayoutRow Icon={<TMCheck />} text="Keep customers updated on project status" />
                    <BlockLayoutRow Icon={<TMCheck />} text="Notify vessel owners that a technician is on their way" />
                </BlockLayout>
            </div>
            <div>
                <BlockLayout>
                    <BlockLayoutRow Icon={<TMDirectToTech />} text="DIRECT-TO-TECH MESSAGING" isTitle />
                    <BlockLayoutRow Icon={<TMCheck />} text="Stay connected to your team on-the-go" />
                    <BlockLayoutRow Icon={<TMCheck />} text="Send automated alerts and reminders" />
                </BlockLayout>
                <BlockLayout>
                    <BlockLayoutRow Icon={<TMLocalNumbers />} text="LOCAL NUMBERS" isTitle />
                    <BlockLayoutRow Icon={<TMCheck />} text="A dedicated messaging number for your business" />
                    <BlockLayoutRow Icon={<TMCheck />} text="Select your local area code and number" />
                </BlockLayout>
            </div>
        </MainBlock>
        <GetStartedButton type="primary" onClick={startOnboarding}>
            Get Started
        </GetStartedButton>
    </Container>
}