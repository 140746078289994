import styled from "styled-components";
import { Empty } from "antd";
import Flex from "../../noui/Flex";
import { useState, useEffect, useCallback } from "react";
import { Msg } from "../../ui/Text";
import { EmptyButton } from "../../ui/Button";
import { PrimaryTooltip } from "../../ui/Tooltip";
import moment from "moment";
import { Api } from "../../api/api";
import { down } from "styled-breakpoints";
import { TitleWrapper } from "./TitleWrapper";
import { ScrollWrapper } from "./ScrollWrapper";
import { TableWrapper } from "./TableWrapper";
import { Row } from "./Row";
import { useHistory } from "react-router";
import routes from "../../routes/routes";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { DefaultDateFormat } from "../../constants";

const JobsContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  & .ant-row.ant-form-item {
    margin-bottom: 0;
  }
  ${down("lg")} {
    grid-column-start: 1;
    grid-column-end: 3;
    min-height: 480px;
  }
`;

const OpenInvoices = () => {
  const [invoicesList, setInvoicesList] = useState([]);
  const [invoicesElements, setInvoicesElements] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isXs = useBreakpoint(down("xs"));
  const isLg = useBreakpoint(down("lg"));
  const loadCards = useCallback(async () => {
    setLoading(true);
    try {
      await Api.invoices.getAll({ completed: false }).then((res) => {
        setInvoicesList(res.data);
        setInvoicesElements(res.data.slice(0, 4));
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadCards().finally();
  }, [loadCards]);

  const MobileRowContent = ({ data }) => (
    <Flex flexDirection="column" alignItems="start" width="100%">
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          width={isLg ? "70px" : "33.333%"}
        >
          <Msg
            fontSize="12px"
            fontWeight="700"
            color="#484848"
            className="short-text"
          >
            {data.work_order.title}
          </Msg>
          <Msg
            fontSize="12px"
            fontWeight="700"
            color="#484848"
            className="short-text"
          >
            {`${data.work_order.customer.first_name ?? ""} ${
              data.work_order.customer.last_name ?? ""
            }`}
          </Msg>
          <Msg
            fontSize="12px"
            fontWeight="400"
            color="#484848"
            className="short-text"
          >
            {data.work_order.vessel.name}
          </Msg>
        </Flex>
        <Flex
          flexDirection="column"
          textAlign="center"
          justifyContent="end"
          width={isLg ? "70px" : "33.333%"}
        >
          <Msg
            fontSize="12px"
            fontWeight="400"
            color="#484848"
            className="short-text"
          >
            #{data.id}
          </Msg>
        </Flex>
        <Flex
          flexDirection="column"
          textAlign="right"
          justifyContent="space-between"
          width={isLg ? "70px" : "33.333%"}
        >
          <Msg fontSize="12px" fontWeight="400" color="#F84C4E">
            Due {moment(data.created).format("MM/DD")}
          </Msg>
          <Msg fontSize="12px" fontWeight="900" color="#109CF1">
            ${data.total.toFixed(2)}
          </Msg>
        </Flex>
      </Flex>
    </Flex>
  );

  const currentListOfInvoices = () => {
    showMore
      ? setInvoicesElements(invoicesList.slice(0, 4))
      : setInvoicesElements([...invoicesList]);
    setShowMore(!showMore);
  };

  return (
    <JobsContainer>
      <TitleWrapper>
        <Msg fontWeight={900} fontSize={16} color="#151F2B" mr="11px">
          OPEN INVOICES
        </Msg>
      </TitleWrapper>
      <ScrollWrapper>
        <TableWrapper>
          {!loading && invoicesElements.length > 0 ? (
            invoicesElements.map((invoice) => (
              <Row
                key={invoice.id}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push(
                    routes.workOrder.UpdateWorkOrder.replace(
                      ":id",
                      invoice.work_order.id
                    )
                  )
                }
              >
                {isLg ? (
                  <MobileRowContent data={invoice} />
                ) : (
                  <>
                    <Flex flexDirection="column">
                      <Msg fontSize="13px" fontWeight="700" color="#484848">
                        {invoice.work_order.title}
                      </Msg>
                    </Flex>
                    <Flex flexDirection="column" justifyContent="space-between">
                      <PrimaryTooltip
                        title={
                          invoice.work_order.customer.first_name +
                          " " +
                          invoice.work_order.customer.last_name
                        }
                        width="100px"
                      >
                        <Msg
                          fontSize="15px"
                          fontWeight="700"
                          color="#484848"
                          width="20px"
                          className="short-text"
                        >
                          {invoice.work_order.customer.first_name +
                            " " +
                            invoice.work_order.customer.last_name}
                        </Msg>
                      </PrimaryTooltip>
                      <PrimaryTooltip
                        title={invoice.work_order.vessel.name}
                        width="100px"
                      >
                        <Msg
                          fontSize="15px"
                          fontWeight="400"
                          color="#484848"
                          width="20px"
                          className="short-text"
                        >
                          {invoice.work_order.vessel.name}
                        </Msg>
                      </PrimaryTooltip>{" "}
                    </Flex>
                    <Flex flexDirection="column">
                      <Msg
                        fontSize="15px"
                        fontWeight="400"
                        color="#F84C4E"
                        mb="5px"
                      >
                        Due {moment(invoice.created).format(DefaultDateFormat)}{" "}
                      </Msg>
                    </Flex>
                    <Flex flexDirection="column">
                      <Msg
                        fontSize="15px"
                        fontWeight="900"
                        color="#109CF1"
                        textAlign="end"
                      >
                        ${invoice.total.toFixed(2)}
                      </Msg>
                    </Flex>
                  </>
                )}
              </Row>
            ))
          ) : (
            <Empty />
          )}
        </TableWrapper>
      </ScrollWrapper>
      {invoicesList.length >= 1 ? (
        <EmptyButton
          mt="auto"
          width="100%"
          height="45px"
          onClick={currentListOfInvoices}
        >
          <Msg fontWeight={600} fontSize={14} color="#109CF1">
            {showMore ? "View Less" : "View All"}
          </Msg>
        </EmptyButton>
      ) : null}
      {/*{isXS ? <ActionContainer>
      <Button type="primary" onClick={openCreateEstimate}>
        Generate Invoice
      </Button>
    </ActionContainer> : null}*/}
    </JobsContainer>
  );
};

export default OpenInvoices;
