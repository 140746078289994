import { FormInstance } from 'antd';
import React from 'react';
import { IWorkOrder } from '../../api/types';
import Flex from "../../noui/Flex";
import {Msg} from "../../ui/Text";
import { TCreateRecord } from './PaymentOption';
import {InfoItem, InfoSection, OptionInfo, InfoAmount } from './styled';

type TProps = {
  workOrder: IWorkOrder | null | undefined
  form: FormInstance<TCreateRecord>
}

export const OptionsSide:React.FC<TProps> = ({workOrder, form}) => {
  const getAmount = () => +form.getFieldValue('amount');
  const calculateRemaining = () => (workOrder?.total ?? 0) - (workOrder?.deposited ?? 0);

  return <OptionInfo pt="13px" flexDirection="column">
    <Flex flexDirection="column" pl="16px">
      <InfoSection mb="23px">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pr="16px"
          style={{width: "100%"}}
        >
          <InfoItem>Total Due</InfoItem>
          <InfoAmount>${(workOrder?.total ?? 0).toFixed(2)}</InfoAmount>
        </Flex>
      </InfoSection>
      <InfoSection flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" pr="16px">
          <InfoItem>Paid to Date</InfoItem>
          <InfoAmount>${(workOrder?.deposited ?? 0).toFixed(2)}</InfoAmount>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" pr="16px">
          <InfoItem>Payment Amount</InfoItem>
          <InfoAmount>${+(getAmount() ?? 0).toFixed(2)}</InfoAmount>
        </Flex>
      </InfoSection>
      <Flex justifyContent="space-between" alignItems="center" mt="24px" pr="16px">
        <InfoItem>Remaining</InfoItem>
        <InfoAmount>${calculateRemaining().toFixed(2)}</InfoAmount>
      </Flex>
    </Flex>
    <Flex flexDirection="column" mt="60px">
      <Msg fontSize={13} fontWeight={700} style={{textTransform: 'uppercase', textAlign: 'center'}}>Payments</Msg>
      <Flex pl="16px">
        <Msg fontSize={13} color="#828282" lineHeight="22px">No transactions yet</Msg>
      </Flex>
    </Flex>
  </OptionInfo>
};