import React from "react"
import PrivateRoute from "../components/PrivateRoute"
import Dashboard from "../containers/Dashboard"
import Business from "../containers/business/Business"
import routes from "../routes/routes"
import {Redirect, Route, Switch} from "react-router-dom"
import {TRoute} from "./types"
import {EUserRole} from "../api/types"
import {JobPage} from "./jobs/JobPage"
import {CreateEstimate} from "./jobs/CreateEstimate"
import Customers from "../containers/customers/Customers"
import CustomerList from "../containers/customers/CustomerList"
import CustomerPage from "../containers/customers/pages/CustomerPage"
import VesselPage from "../containers/customers/pages/VesselPage"
import Settings from "../containers/settings/Settings"
import GeneralSettings from "../containers/settings/GeneralSettings"
import Users from "../containers/settings/Users"
import Profile from "../containers/profile/Profile"
import WorkOrderFlow from "./WorkOrderFlow/WorkOrder"
import Vessels from "../containers/vessels/Vessels"
import {HelpPage} from "../containers/help/HelpPage"
import {Articles} from "../containers/help/Articles"
import {MainLayout} from "./MainLayout"
import {SingleArticle} from "../containers/help/SingleArticle"
import DispatchLayout from "./dispatch/DispatchLayout"
import Reports from "../containers/reports/Reports"
import ErrorPage from "./ErrorPage"
import SettingsPayments from "../containers/settings/SettingsPayments"
import SubscriptionLayout from "../containers/settings/SubscriptionLayout"
import MySubscription from "../containers/mysubscription/MySubscription"
import TextMessagingMainLayout from "../containers/settings/TextMessagingMainLayout"
import VesselContainer from "../containers/vessels/VesselContainer"
import DataImport from "../containers/settings/dataImport/DataImport"
import {SuperUserDashboard} from "../containers/sudashboard/SuperUserDashboard";

const routesList: TRoute[] = [
   {
      path: routes.main.Dashboard,
      exact: true,
      private: true,
      component: Dashboard,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.main.SUDashboard,
      exact: true,
      private: true,
      component: SuperUserDashboard,
      roles: [EUserRole.SuperUser]
   },
   {
      path: routes.business.Base,
      exact: true,
      private: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.jobs.CreateEstimate,
      private: true,
      exact: true,
      component: CreateEstimate,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.jobs.UpdateEstimate,
      private: true,
      exact: true,
      component: CreateEstimate,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.jobs.Base,
      private: true,
      exact: true,
      component: JobPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.jobs.Calendar,
      private: true,
      component: JobPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.jobs.ProjectsList,
      private: true,
      component: JobPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.jobs.StatusBoard,
      private: true,
      component: JobPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.jobs.Map,
      private: true,
      component: JobPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.jobs.TimeClocks,
      private: true,
      component: JobPage,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.business.RateCard,
      private: true,
      exact: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.business.Payments,
      private: true,
      exact: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.business.Jobs,
      private: true,
      exact: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.business.Materials,
      private: true,
      exact: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.business.CCPayments,
      private: true,
      exact: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },

   {
      path: routes.customers.Base,
      private: true,
      exact: true,
      component: Customers,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.customers.List,
      private: true,
      exact: true,
      component: CustomerList,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.vessels.Base,
      private: true,
      exact: true,
      component: Vessels,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.vessels.List,
      private: true,
      exact: true,
      component: VesselContainer,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.vessels.Map,
      private: true,
      exact: true,
      component: VesselContainer,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.customers.CustomerPage,
      private: true,
      exact: true,
      component: CustomerPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.vessels.Page,
      private: true,
      exact: true,
      component: VesselPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.settings.Base,
      private: true,
      exact: true,
      component: Settings,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.settings.Users,
      private: true,
      exact: true,
      component: Users,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.settings.General,
      private: true,
      exact: true,
      component: GeneralSettings,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.settings.Payments,
      private: true,
      exact: true,
      component: SettingsPayments,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.settings.Subscription,
      private: true,
      exact: true,
      component: SubscriptionLayout,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.settings.TextMessaging,
      private: true,
      exact: true,
      component: TextMessagingMainLayout,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.settings.DataImport,
      private: true,
      exact: true,
      component: DataImport,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.profile.UpdateProfile,
      private: true,
      exact: true,
      component: Profile,
      roles: [EUserRole.SuperUser, EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.profile.MySubscription,
      private: true,
      exact: true,
      component: MySubscription,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.business.Invoices,
      private: true,
      exact: true,
      component: Business,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.help.Base,
      private: true,
      exact: true,
      component: HelpPage,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.help.Articles,
      private: true,
      exact: true,
      component: SingleArticle,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.help.Categories,
      private: true,
      exact: true,
      component: Articles,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.workOrder.CreateWorkOrder,
      private: true,
      exact: true,
      component: WorkOrderFlow,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.workOrder.UpdateWorkOrder,
      private: true,
      component: WorkOrderFlow,
      roles: [EUserRole.Admin, EUserRole.Owner, EUserRole.Technician],
   },
   {
      path: routes.dispatch.Base,
      private: true,
      exact: true,
      component: DispatchLayout,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   {
      path: routes.reports.Base,
      private: true,
      exact: true,
      component: Reports,
      roles: [EUserRole.Admin, EUserRole.Owner],
   },
   { path: routes.errorPage.Base, component: ErrorPage },
   { path: "/", exact: true, component: Dashboard },
]

export const ComponentsLayout: React.FC = () => {
   return (
      <MainLayout>
         <Switch>
            {routesList.map(({ private: pr, roles, ...route }) => {
               return pr ? (
                  <PrivateRoute key={route.path} roles={roles} {...route} />
               ) : (
                  <Route key={route.path} {...route} />
               )
            })}
            <Redirect to={routes.errorPage.Base} />
         </Switch>
      </MainLayout>
   )
}
