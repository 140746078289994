import { Endpoint } from "./types"

type AuthRoutes = "Register" | "Login" | "ForgotPassword" | "CheckEmail" | "ResetPassword" | "SetNewPassword"
const auth: Endpoint<AuthRoutes> = {
   Register: "/registration",
   Login: "/login",
   CheckEmail: "/checkemail",
   ForgotPassword: "/forgotpassword",
   ResetPassword: "/resetpassword",
   SetNewPassword: "/set-password",
}
type AppsRoutes = "Base"
const apps: Endpoint<AppsRoutes> = {
   Base: "/apps",
}

type ProfileRoutes = "UpdateProfile" | "MySubscription"
const profile: Endpoint<ProfileRoutes> = {
   UpdateProfile: "/update-profile",
   MySubscription: "/my-subscription",
}

type Business = "RateCard" | "Base" | "Sales" | "Jobs" | "Invoices" | "Payments" | "Materials" | "CCPayments"

const business: Endpoint<Business> = {
   Base: "/business",
   RateCard: "/business/rateCard",
   Sales: "/business/sales",
   Jobs: "/business/jobs",
   Invoices: "/business/invoices",
   Payments: "/business/payments",
   Materials: "/business/materials",
   CCPayments: "/business/credit-card-payments",
}

type Customers =
   | "Base"
   | "List"
   | "VesselsList"
   | "CustomerEstimateView"
   | "CustomerInvoiceView"
   | "CustomerSubscriptionView"
   | "CustomerTermsView"
   | "CustomerPage"
   | "VesselPage"
   | "ScheduleView"
   | "StatementOfWorkView"
const customers: Endpoint<Customers> = {
   Base: "/customers",
   List: "/customers/customerList",
   VesselsList: "/customers/vesselsList",
   CustomerEstimateView: "/view-estimate/:id",
   CustomerInvoiceView: "/view-invoice/:id",
   CustomerSubscriptionView: "/view-subscription/:id",
   CustomerTermsView: "/view-terms/:id",
   CustomerPage: "/customers/customer/:id",
   VesselPage: "/customers/vessel/:id",
   ScheduleView: "/view-schedule/:id",
   StatementOfWorkView: "/work-statement/:id",
}

type Vessels = "Base" | "List" | "Page" | "Map"

const vessels: Endpoint<Vessels> = {
   Base: "/vessels",
   List: "/vessels/list",
   Map: "/vessels/map",
   Page: "/vessels/vessel/:id",
}

type Jobs =
   | "Base"
   | "ProjectsList"
   | "StatusBoard"
   | "Calendar"
   | "Map"
   | "TimeClocks"
   | "CreateEstimate"
   | "UpdateEstimate"
const jobs: Endpoint<Jobs> = {
   Base: "/jobs",
   ProjectsList: "/jobs/statusboard",
   StatusBoard: "/jobs/kanban",
   Calendar: "/jobs/calendar",
   Map: "/jobs/map",
   TimeClocks: "/jobs/timeclocks",
   CreateEstimate: "/jobs/create-estimate",
   UpdateEstimate: "/jobs/update-estimate/:id",
}

type Main = "Dashboard" | "SUDashboard"
const main: Endpoint<Main> = {
   Dashboard: "/dashboard",
   SUDashboard: "/companies-list"
}

type Registration = "Base" | "Step2" | "Step3"
const registration: Endpoint<Registration> = {
   Base: "/registration",
   Step2: "/registration/step2",
   Step3: "/registration/step3",
}

type Settings = "Base" | "Users" | "General" | "Payments" | "Subscription" | "TextMessaging" | "DataImport"

const settings: Endpoint<Settings> = {
   Base: "/settings",
   Users: "/settings/users",
   General: "/settings/generalSettings",
   Payments: "/settings/payments",
   Subscription: "/settings/subscription",
   TextMessaging: "/settings/textMessaging",
   DataImport: "/settings/dataImport"
}

type Help = "Base" | "Categories" | "Articles"
const help: Endpoint<Help> = {
   Base: "/help",
   Categories: "/help/:id",
   Articles: "/help/articles/:id",
}

type Dispatch = "Base"

const dispatch: Endpoint<Dispatch> = {
   Base: "/dispatch",
}

type WorkFlow = "CreateWorkOrder" | "UpdateWorkOrder"

const workOrder: Endpoint<WorkFlow> = {
   CreateWorkOrder: "/create-order",
   UpdateWorkOrder: "/work-order/:id",
}

type Reports = "Base"

const reports: Endpoint<Reports> = {
   Base: "/reports",
}

type ErrorPage = "Base"

const errorPage: Endpoint<ErrorPage> = {
   Base: "/error",
}

const routes = {
   auth,
   apps,
   business,
   customers,
   dispatch,
   vessels,
   jobs,
   main,
   profile,
   registration,
   settings,
   help,
   workOrder,
   errorPage,
   reports,
}
export default routes
