export const getMockTableData = (importType: string) => {
    switch (importType) {
        case 'customers':
            return [
                {
                    'key': '1',
                    'first_name': 'Cameron',
                    'last_name': 'Williamson',
                    'email': 'debra.holt@example.com',
                    'phone': '+1 347 403 3694',
                    'address1': '19731 Vanowen St',
                    'city': 'Salem (OR)',
                    'state': 'Oregon',
                    'zip_code': '45463',
                    'company_name': 'IB Vessel',
                    'job_title': 'Technician',
                    'note': '',
                },
                {
                    'key': '2',
                    'first_name': 'Cody',
                    'last_name': 'Miles',
                    'email': 'georgia.222@222.com',
                    'phone': '(907) 555-0101',
                    'address1': '7529 E. Pecan St.',
                    'city': 'Hampton (VA)',
                    'state': 'Pennsylvania',
                    'zip_code': '12473',
                    'company_name': 'Louis Vessel',
                    'job_title': 'Technician',
                    'note': '',
                },
                {
                    'key': '3',
                    'first_name': 'Annette',
                    'last_name': 'Wilson',
                    'email': 'tanya.hill@example.com',
                    'phone': '(505) 555-0125',
                    'address1': '8080 Railroad St.',
                    'city': 'Volzhsky',
                    'state': 'Vermont',
                    'zip_code': '25138',
                    'company_name': 'American Ships',
                    'job_title': 'Technician',
                    'note': '',
                },
                {
                    'key': '4',
                    'first_name': 'Robert',
                    'last_name': 'Henry',
                    'email': 'jessica.hanson@boat.com',
                    'phone': '(307) 555-0133',
                    'address1': '775 Rolling Green Rd.',
                    'city': 'Gelsenkirchen',
                    'state': 'Missouri',
                    'zip_code': '69822',
                    'company_name': 'Ninteboat',
                    'job_title': 'Admin',
                    'note': '',
                }
            ]
        case 'users':
            return [
                {
                    'key': '1',
                    'first_name': 'Cameron',
                    'last_name': 'Williamson',
                    'email': 'debra.holt@example.com',
                    'phone': '+1 347 403 3694',
                    'title': 'Title',
                    'role': 'technician',
                },
                {
                    'key': '2',
                    'first_name': 'Cody',
                    'last_name': 'Miles',
                    'email': 'georgia.222@222.com',
                    'phone': '(907) 555-0101',
                    'title': 'Title',
                    'role': 'technician',
                },
                {
                    'key': '3',
                    'first_name': 'Annette',
                    'last_name': 'Wilson',
                    'email': 'tanya.hill@example.com',
                    'phone': '(505) 555-0125',
                    'title': 'Title',
                    'role': 'technician',
                },
                {
                    'key': '4',
                    'first_name': 'Robert',
                    'last_name': 'Henry',
                    'email': 'jessica.hanson@boat.com',
                    'phone': '(307) 555-0133',
                    'title': 'Title',
                    'role': 'admin',
                },
            ]
        case 'vessels':
            return [
                {
                    'key': '1',
                    'name': 'Marina Delia',
                    'manufacturer': 'Brand 1',
                    'model': 'Tacoma SR / SR589',
                    'year': '2008',
                    'length': '922',
                    'hin': '312792P13VB',
                    'beam': '42 ft',
                    'draft': '42 ft',
                    'vessel_type': 'CO',
                    'registration_number': 'VL0605',
                    'hailing_port': 'Port 1',
                    'uscg_doc': '957153281',
                    'state_of_registration': 'LA',
                    'notes': 'Past recorded videos have to...',
                    'location_name': 'LA Main port',
                    'slip_number': '4',
                    'address1': '7529 E. Pecan St.',
                    'city': 'Kent',
                    'state': 'LA',
                    'zip_code': '12473',
                    'customer_name': 'Sam Moore'
                },
                {
                    'key': '2',
                    'name': 'Marsha',
                    'manufacturer': 'Brand 2',
                    'model': 'Tacoma SR / SR581',
                    'year': '2006',
                    'length': '274',
                    'hin': '300250446VB',
                    'beam': '42 ft',
                    'draft': '42 ft',
                    'vessel_type': 'CO',
                    'registration_number': 'VL0605',
                    'hailing_port': 'Port 1',
                    'uscg_doc': '957153281',
                    'state_of_registration': 'LA',
                    'notes': 'Past recorded videos have to...',
                    'location_name': 'LA Main port',
                    'slip_number': '4',
                    'address1': '7529 E. Pecan St.',
                    'city': 'Kent',
                    'state': 'LA',
                    'zip_code': '12473',
                    'customer_name': 'Linda Harris'
                },
                {
                    'key': '3',
                    'name': 'Marina Ren',
                    'manufacturer': 'Brand 3',
                    'model': 'Tacoma SR / SR580',
                    'year': '2017',
                    'length': '600',
                    'hin': '234895825VB',
                    'beam': '42 ft',
                    'draft': '42 ft',
                    'vessel_type': 'CO',
                    'registration_number': 'VL0605',
                    'hailing_port': 'Port 1',
                    'uscg_doc': '957153281',
                    'state_of_registration': 'LA',
                    'notes': 'Past recorded videos have to...',
                    'location_name': 'LA Main port',
                    'slip_number': '4',
                    'address1': '7529 E. Pecan St.',
                    'city': 'Kent',
                    'state': 'LA',
                    'zip_code': '12473',
                    'customer_name': 'Tom Garcia'
                },
                {
                    'key': '4',
                    'name': 'Ellison',
                    'manufacturer': 'Brand 4',
                    'model': 'Tacoma SR / TR589',
                    'year': '2018',
                    'length': '798',
                    'hin': '412962301CP',
                    'beam': '42 ft',
                    'draft': '42 ft',
                    'vessel_type': 'CO',
                    'registration_number': 'VL0605',
                    'hailing_port': 'Port 1',
                    'uscg_doc': '957153281',
                    'state_of_registration': 'LA',
                    'notes': 'Past recorded videos have to...',
                    'location_name': 'LA Main port',
                    'slip_number': '4',
                    'address1': '7529 E. Pecan St.',
                    'city': 'Kent',
                    'state': 'LA',
                    'zip_code': '12473',
                    'customer_name': 'Paul Taylor'
                }
            ]
        case 'rate_cards':
            return [
                {
                    'key': '1',
                    'name': 'Service Name 1',
                    'price': '$100',
                    'unit': 'PH',
                    'description': 'Reports that the alarm siren...',
                    'group': '',
                },
                {
                    'key': '2',
                    'name': 'Service Name 2',
                    'price': '$200',
                    'unit': 'PD',
                    'description': 'Reports that the alarm siren...',
                    'group': 'Group 1',
                },
                {
                    'key': '3',
                    'name': 'Service Name 3',
                    'price': '$300',
                    'unit': 'PF',
                    'description': 'Reports that the alarm siren...',
                    'group': 'Group 1',
                },
                {
                    'key': '4',
                    'name': 'Service Name 4',
                    'price': '$400',
                    'unit': 'PJ',
                    'description': 'Reports that the alarm siren...',
                    'group': 'Group 2',
                },
            ]
        case 'materials': 
            return [
                {
                    'key': '1',
                    'name': 'Paddle',
                    'price': '$100',
                    'unit_cost': '$100',
                    'description': 'Past recorded videos have to...',
                    'group': '',
                },
                {
                    'key': '2',
                    'name': 'Screw',
                    'price': '$200',
                    'unit_cost': '$200',
                    'description': 'Past recorded videos have to...',
                    'group': 'Group 1',
                },
                {
                    'key': '3',
                    'name': 'Screw-propeller',
                    'price': '$300',
                    'unit_cost': '$300',
                    'description': 'Past recorded videos have to...',
                    'group': 'Group 1',
                },
                {
                    'key': '4',
                    'name': 'Prop',
                    'price': '$400',
                    'unit_cost': '$400',
                    'description': 'Past recorded videos have to...',
                    'group': 'Group 2',
                },
            ]
        default: 
            return []
    }
}