import styled from "styled-components";
import { Button, ButtonProps } from "antd";
import { CalendarWrapperProps } from "./types";
import { down } from "styled-breakpoints";

export const DayName = styled.span<{ isCurrent: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props.isCurrent ? "#109CF1" : "#000000")};
`;

export const DayNumber = styled.div<{ isCurrent: boolean }>`
  font-size: 26px;
  font-weight: bold;
  color: ${(props) => (props.isCurrent ? "#FFFFFF" : "#000000")};
  background-color: ${(props) => (props.isCurrent ? "#109CF1" : "#FFFFFF")};
  border-radius: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  display: inline-block;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

export const DateTitle = styled.div<{ width: number | undefined }>`
  width: ${(props) => props.width};
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  ${down("xs")} {
    font-size: 15px;
    font-weight: 900;
  }
`;

export const CurrentDateButton: React.FunctionComponent<ButtonProps> = styled(
  Button
)`
  font-size: 13px;
  font-weight: 600;
`;

export const RadioContainer = styled.div`
  .ant-radio-button-wrapper {
    .ant-radio-button-checked {
      background-color: #dcdfe3;
    }
    span {
      color: #000000;
    }
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background-color: #dcdfe3;
    border-color: #dcdfe3;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #dcdfe3;
    border-color: #dcdfe3;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #dcdfe3;
  }
  ${down("lg")} {
    margin-top: 10px;
    width: 100%;
    > div {
      width: 100%;
      > label {
        width: 33.333%;
        text-align: center;
      }
    }
  }
`;

export const StyledButtonGroup = styled(Button.Group)`
  button {
    border: none;
  }
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

export const SettingsButton = styled(Button)`
  height: 32px;
  width: 32px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  height: 1300px;
  .rbc-calendar {
    ${(p) =>
      p.sunday !== 0 &&
      `.rbc-month-header > :nth-child(${p.sunday}),
    .rbc-month-row > .rbc-row-bg > :nth-child(${p.sunday}),
    .rbc-row-content > .rbc-row > :nth-child(${p.sunday}) {
      display: none;
    }`}
    ${(p) =>
      p.saturday !== 0 &&
      `.rbc-month-header > :nth-child(${p.saturday}),
    .rbc-month-row > .rbc-row-bg > :nth-child(${p.saturday}),
    .rbc-row-content > .rbc-row > :nth-child(${p.saturday}) {
      display: none;
    }`}
  .rbc-row-segment {
      // flex-basis: ${(p) =>
        p.sunday && p.saturday
          ? "20% !important"
          : p.sunday || p.saturday
          ? "16.666% !important"
          : "14.285% !important"};
      // max-width: ${(p) =>
        p.sunday && p.saturday
          ? "20% !important"
          : p.sunday || p.saturday
          ? "16.666% !important"
          : "14.285% !important"};
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: #109cf1;
    background: #fff;
  }
  .rbc-time-content {
    border-top: 1px solid #ddd;
  }
  ${down("lg")} {
    height: 600px;
    .rbc-label.rbc-time-header-gutter {
      width: 0px !important;
      min-width: 0px !important;
      max-width: 0px !important;
      padding: 0px;
    }
  }
  ${down("md")} {
    .rbc-calendar .rbc-time-view .rbc-time-header {
      border-left: none;
    }
    .rbc-time-header.rbc-overflowing {
      border-right: none;
    }
  }
`;

export const TemperatureText = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

export const RainBlock = styled.div`
  font-size: 22px;
  color: #109cf1;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg,
  img {
    margin-right: 5px;
  }
  img {
    height: 24px;
    width: 24px;
  }
  margin: 0 20px;
`;

export const MonthWeatherBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  span {
    font-size: 16px;
  }
`;
