import { Button, Form, Input, Modal, notification } from "antd";

import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";

import { ModalWrapper } from "./PaymentModal";
import { IExternalRecurring } from "../../api/types";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { useForm } from "antd/es/form/Form";
import StripeSubscriptionsWrapper from "../StripeSubscriptions/StripeSubscriptionsWraper";
import SaveCardItem from "../../modals/Payments/Stripe/SaveCardItem";
import { useAppSelector } from "../../config/hooks";

type TPaymentModalProps = {
  visible: boolean;
  estimate: IExternalRecurring;
  onCancel: () => void;
  onSubmit: () => void;
  amount: string;
};
const PaymentField = styled.div`
  border: 1px solid #d9d9d9;
  padding: 8px;
  border-radius: 5px;
`;
const RecurringPaymentModal: React.FC<TPaymentModalProps> = ({
  visible,
  estimate,
  onCancel,
  onSubmit,
  amount,
}) => {
  const [form] = useForm();
  const [isPaying, setPaying] = useState<boolean>(false);
  const ref = useRef<HTMLFormElement>(null);
  const stipeAccountId = estimate.company.stripe.stripe_id;
  const { isPending } = useAppSelector((state) => state.payment);
  const cardList = estimate?.payment_methods;
  // const [selectedCardId, setSelectedCardId] = useState<string>(cardList[0]?.id);
  // const [paymentButSavedCard, setPaymentButSavedCard] = useState<boolean>(
  //   cardList?.length > 0
  // );
  useEffect(() => {
    if (isPending) setPaying(true);
  }, [isPending]);
  const handleSubmit = (e: any) => {
    if (ref.current) {
      ref.current.callOnSubmit();
    }
  };

  const formatBillingPeriod = (period?: string) => {
    switch (period) {
      case "M":
        return "month";
      case "A":
        return "year";
      default:
        return "";
    }
  };

  return (
    <Modal
      title="Type of Payment"
      visible={visible}
      closable
      centered
      footer={null}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Flex width="100%" justifyContent="space-between">
          <Msg style={{ fontSize: "18px", fontWeight: 700, color: "#202020" }}>
            {estimate.work_order.recurring_config?.title}
          </Msg>
          <Msg style={{ fontSize: "18px", fontWeight: 700, color: "#202020" }}>
            {`${amount}/${formatBillingPeriod(
              estimate.work_order.recurring_config?.billing
            )}`}
          </Msg>
        </Flex>
        <Form.Item label="Amount">
          <Input disabled value={amount} />
        </Form.Item>
        {/*{paymentButSavedCard ? (*/}
        {/*  <div>*/}
        {/*    {cardList.map((item) => {*/}
        {/*      const str = item.card.brand + " " + item.card.last4;*/}
        {/*      const cardItemTittle = str[0].toUpperCase() + str.slice(1);*/}
        {/*      return (*/}
        {/*        <div onClick={() => setSelectedCardId(item.id)}>*/}
        {/*          <SaveCardItem*/}
        {/*            key={item.id}*/}
        {/*            onSelected={selectedCardId === item.id}*/}
        {/*            itemCardTittle={cardItemTittle}*/}
        {/*            isCardComponent*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*    <div*/}
        {/*      onClick={() => {*/}
        {/*        setPaymentButSavedCard(false);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <SaveCardItem*/}
        {/*        onSelected={false}*/}
        {/*        itemCardTittle={"Add new card or use bank account"}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <StripeSubscriptionsWrapper*/}
        {/*    onSubmit={onSubmit}*/}
        {/*    customerStripeAccountId={stipeAccountId}*/}
        {/*    onCancel={onCancel}*/}
        {/*    refSubmit={ref}*/}
        {/*  />*/}
        {/*)}*/}
        <StripeSubscriptionsWrapper
          customerStripeAccountId={stipeAccountId}
          estimate={estimate}
          onCancel={onCancel}
          onSubmit={onSubmit}
          setPaying={() => setPaying(true)}
          refSubmit={ref}
        />
        <ModalWrapper>
          <Button
            type="primary"
            htmlType={"submit"}
            loading={isPaying}
            style={{ width: "100%", marginTop: "24px" }}
          >
            Pay Subscription
          </Button>
        </ModalWrapper>
      </Form>
    </Modal>
  );
};

export default RecurringPaymentModal;
