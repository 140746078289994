import React, { useEffect, useState } from "react"
import moment from "moment"
import { ColumnsType } from "antd/es/table"
import { Empty, Pagination, Table } from "antd"
import styled from "styled-components"
import { DefaultDateTimeFormat } from "../../../constants"
import { usePagination } from "../../../utils/hooks"
import { formatToUSD, getOrdering } from "../../../utils/utils"
import { Modal } from "antd"
import { Api } from "../../../api/api"

import { IPaymentRecord, IWorkOrder } from "../../../api/types"
import { TCallback } from "../../../types"

const StyledModal = styled(Modal)`
   & .ant-modal-footer .ant-btn-primary {
      display: none;
   }
   .ant-modal-body {
      max-height: 600px;
      overflow: auto;
      border-radius: 4px;
   }
   p {
      font-weight: 300;
      font-size: 15px;
      margin: 12px 0;
   }
   .ant-table-content {
      &::-webkit-scrollbar {
         width: 4px;
         height: 4px;
      }

      &::-webkit-scrollbar-track {
         background: #fbfbff;
         border-radius: 8px;
         border: 1px solid #ededed;
      }

      &::-webkit-scrollbar-thumb {
         background: #0496ff;
         border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
         background: #40a9ff;
      }
   }
   table {
      margin-bottom: 15px;
   }
   tr {
      margin: 5px;
   }
`

interface IPaymentRecordWithRemaining extends IPaymentRecord {
   remaining?: number
}

export type TModalProps = {
   visible: boolean
   onClose: TCallback
   workOrder?: IWorkOrder | null | undefined
}
export type TPaymentsBlockProps = {
   data: any
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1).toLowerCase()

export const PaymentsModalList: React.FC<TModalProps> = ({ visible, onClose, workOrder }) => {
   const { data, loading, onPromise, requestPagination, pagination, onChange, sorter } = usePagination<IPaymentRecord>()

   useEffect(() => {
      onPromise(
         Api.paymentRecords.getAll({
            ...requestPagination,
            ...getOrdering(sorter),
            work_order: workOrder?.id,
            is_error: false,
         })
      )
   }, [requestPagination, sorter, onPromise])

   const getPaymentType = (type: string) => {
      switch (type) {
         case "card":
            return "Credit Card"
         case "us_bank_account":
            return "ACH"
         default:
            return type
      }
   }

   const formatPaymentType = (payment: IPaymentRecordWithRemaining) => {
      if (!payment.payment_intent) {
         if (payment.payment_type === "PE") {
            return "PayEngine"
         }
         return capitalize(payment.payment_type)
      }
      if (payment.payment_intent.payment_details.type === "card") {
         const { brand, last4 } = payment.payment_intent.payment_details.card
         const brandTittle = brand[0].toUpperCase() + brand.slice(1)
         return `${brandTittle} (${last4})`
      }
      return getPaymentType(payment.payment_intent.payment_details.type)
   }

   const columns: ColumnsType<IPaymentRecordWithRemaining> = [
      {
         title: "Payment Amount",
         dataIndex: "amount",
         key: "amount",
         sorter: true,
         render: (_, record) => `${formatToUSD(+record.amount || 0)}`,
      },
      {
         title: "Payment Date/Time",
         dataIndex: "date",
         key: "date",
         sorter: true,
         render: (_, record) => (record.date ? moment(record.date).format(DefaultDateTimeFormat) : "No data"),
      },

      {
         title: "Payment Type",
         dataIndex: "payment_type",
         key: "payment_type",
         render: (_, record) => formatPaymentType(record),
      },
      {
         title: "Transaction ID",
         dataIndex: "payment_type",
         key: "payment_type",
         render: (_, record) => (record?.payment_intent?.intent_id ? record.payment_intent?.intent_id.slice(10) : "-"),
      },
   ]

   return (
      <StyledModal visible={visible} title="Paid to-DATE" onCancel={onClose} width="740px" cancelText="Cancel">
         <Table
            className="contained"
            locale={{ emptyText: "No payments were found" }}
            dataSource={data}
            loading={loading}
            columns={columns}
            pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
            onChange={onChange}
            scroll={{ x: 650 }}
         />
         {!!pagination?.total && pagination.total > 10 && (
            <Pagination
               current={pagination.current}
               pageSize={pagination.pageSize}
               total={pagination.total}
               onChange={(p) => {
                  onChange({ ...pagination, current: p }, {}, {})
               }}
               style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "20px",
               }}
               size="small"
            />
         )}
      </StyledModal>
   )
}
