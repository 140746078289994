import React, {ReactNode} from 'react';
import {Spin} from "antd";


type TProps = {
  loading?: boolean;
  noItemsLabel?: string;
  noItems?: boolean;
  children: ReactNode;
}
export const Content: React.FC<React.PropsWithChildren<TProps>> =
  ({loading, noItemsLabel, children, noItems}) => {
    return loading
      ? <Spin style={{width: "100%"}}/> : noItems
        ? noItemsLabel
          ? <span>{noItemsLabel}</span>
          : <span>No items</span>
        : <>{children}</>;
  };