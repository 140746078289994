import { FormInstance, notification } from "antd";
import {
  TErrorResponse,
  TMappedConstant,
  TMappedError,
  TTimeSlot,
  TTimeSlotCategory,
} from "./types";
import {
  ECompanyPaymentsStatus,
  EJobPriority,
  EJobProgress,
  ENotificationStatus,
  IAddress,
  ICompany,
  IConstant,
  ICustomer,
  ICustomerShort,
  IJob,
  IOrdering,
  IStripeSubscription,
  ISubscriptionStatus,
  IUser,
  IUserProfile,
  IWorkOrder,
} from "../api/types";
import { FieldData } from "rc-field-form/es/interface";
import { SorterResult } from "antd/es/table/interface";
import { TOption } from "../types";
import { tagsReGex } from "./regex";
import { Key } from "react";
import { ColumnType } from "antd/es/table";
import moment from "moment";
import { Api, TAddress } from "../api/api";
import { vesselTypes } from "../constants";
import { InternalNamePath } from "antd/lib/form/interface";

export const getCurrentHost = () => {
  const { host, protocol } = window.location;
  return `${protocol}//${host}`;
};

export const formatTime = (seconds: number, displaySeconds: boolean) => {
  const format = (val: number) => `0${Math.floor(val)}`.slice(-2);
  let hours = seconds / 3600;
  hours = hours >= 24 ? hours % 24 : hours;
  const minutes = (seconds % 3600) / 60;
  const arrOfStats = displaySeconds
    ? [hours, minutes, seconds % 60]
    : [hours, minutes];

  return arrOfStats.map(format).join(":");
};
export const formatTimeTimeClockSum = (
  seconds: number,
  displaySeconds: boolean
) => {
  const format = (val: number) =>
    val > 99 ? Math.floor(val) : `0${Math.floor(val)}`.slice(-2);
  let hours = seconds / 3600;

  const minutes = (seconds % 3600) / 60;
  const arrOfStats = displaySeconds
    ? [hours, minutes, seconds % 60]
    : [hours, minutes];

  return arrOfStats.map(format).join(":");
};

export const renderAddress = (address: TAddress) => {
  return Object.values(address).every((v) => !v)
    ? ""
    : Object.entries(address)
        .filter(
          (v) =>
            v[1] && v[0] !== "lat" && v[0] !== "lng" && v[0] !== "full_address"
        )
        .map((v) => v[1])
        .join(", ");
};

type TCallback<T> = (el: T) => string;
export const mapOptions = <T>(
  data: T[] | undefined,
  label: keyof T | TCallback<T>,
  key?: keyof T
): TOption[] => {
  const k = key ?? ("id" as keyof T);
  return (
    data?.map((el) => {
      const text = (
        typeof label === "function" ? label(el) : el[label]
      ) as string;
      return { value: String(el[k]), text, label: text };
    }) ?? []
  );
};

type TErrors = {
  [k: string]: string[];
};
const _getErrors = (data: TErrorResponse, keys?: string): TErrors => {
  return Object.keys(data).reduce((acc, key) => {
    const err = data[key];
    const collectedKey = keys ? `${keys}.${key}` : key;

    if (
      err &&
      typeof err !== "string" &&
      Object(err) === err &&
      !(err instanceof Array)
    ) {
      acc = { ...acc, ..._getErrors(err, collectedKey) };
    } else if (err instanceof Array) {
      for (let e of err) {
        if (
          e &&
          typeof e !== "string" &&
          Object(e) === e &&
          !(e instanceof Array)
        ) {
          acc = { ...acc, ..._getErrors(e, collectedKey) };
        } else if (typeof e === "string") {
          acc[collectedKey] = acc[collectedKey]
            ? [...acc[collectedKey], e]
            : [e];
        }
      }
    } else if (typeof err === "string") {
      acc[collectedKey] = [err];
    }
    return acc;
  }, {} as TErrors);
};
export const getErrors = (e: any, acceptedKeys?: string[]): TMappedError[] => {
  if (!e?.response) {
    return [];
  }
  const { data, status }: { data: TErrorResponse; status: number } = e.response;
  if (status !== 400) {
    notification.error({ message: "Something went wrong" });
    return [];
  }
  if (data.non_field_errors) {
    notification.error({ message: data.non_field_errors.join("\n") });
    delete data["non_field_errors"];
  } else {
    const errors = _getErrors(data);
    let keys = Object.keys(errors);
    if (acceptedKeys) {
      keys = keys.filter((k) => acceptedKeys.includes(k));
    }
    return keys.map((k) => ({ name: k.split("."), errors: errors[k] }));
  }
  return [];
};
export const getFullName = (user?: IUser | ICustomer | null): string =>
  user ? `${user.first_name || ""} ${user.last_name || ""}`.trim() : "";

export const getFullAddress = (
  customer?: ICustomer | ICompany | null
): string => {
  return getAddress(customer?.address);
};

export const getInitials = (
  user?: IUser | IUserProfile | ICustomer | null
): string => {
  const { first_name = " ", last_name = " " } = user ?? {};
  return `${first_name[0]}${last_name[0]}`.trim() || "";
};

export const showErrors = (errors: TMappedError[]): void => {
  for (let err of errors) {
    notification.error({
      message: `${
        typeof err.name === "string" ? err.name : err.name[err.name.length - 1]
      }:\n${err.errors.join("\n")}`,
    });
  }
};
export const showNonFieldsErrors = (errors: TMappedError[]): void => {
  for (let err of errors) {
    if (err.name.includes("non_field_error")) {
      notification.error({ message: err.errors.join("\n") });
    }
  }
};
export const getAddress = (address?: IAddress | null): string => {
  if (!address) return "";
  const { address1, address2, city, state, zip_code } = address;
  let addrCombined = `${address1} ${address2}`.trim();
  for (let item of [city, state]) {
    if (item) {
      addrCombined = `${addrCombined}, ${item}`;
    }
  }
  if (zip_code) {
    addrCombined = `${addrCombined} ${zip_code}`;
  }
  return addrCombined;
};
export const getDefaultFields = <T>(
  data: T,
  prefix: any[] = []
): FieldData[] => {
  return Object.keys(data).reduce((acc, key) => {
    const item = data[key as keyof T];
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        acc.push(...getDefaultFields(item[i], [...prefix, key, i]));
      }
    } else if (typeof item === "object" && item !== null) {
      acc.push(...getDefaultFields(item, [...prefix, key]));
    }
    acc.push({ name: [...prefix, key], value: item, touched: false });
    return acc;
  }, [] as FieldData[]);
};
export const setDefaultFields = <T = any>(form: FormInstance<T>, data: T) => {
  form.setFields(getDefaultFields<T>(data));
};
export const mapConstants = <T = null | object>(obj?: T): TMappedConstant[] => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).map((key) => ({
    key,
    id: key,
    label: String(obj[key as keyof T]),
    text: String(obj[key as keyof T]),
    value: key,
  }));
};

export const blankPromise =
  <T>(data: T) =>
  (): Promise<{ data: T }> =>
    new Promise((resolve) => resolve({ data }));

export const getOrdering = <T>(
  results: SorterResult<T> | SorterResult<T>[]
): IOrdering => {
  if (!results || results instanceof Array) {
    return {};
  }
  const { field, order } = results;
  if (!field || !order) {
    return {};
  }
  return { ordering: `${order !== "ascend" ? "-" : ""}${field}` };
};

export const range = (start: number, end: number, step?: number): number[] => {
  const st = step || 1;
  const result: number[] = [];
  for (let i = start; i < end; i += st) {
    result.push(i);
  }
  return result;
};
export const invertedRange = (
  start: number,
  end: number,
  step?: number
): number[] => {
  const full = range(start, end);
  const inverted = range(start, end, step);
  return full.filter((n) => !inverted.includes(n));
};

const FT_TO_M_COF = 3.2808;
export const ftToM = (l?: number | string, b?: boolean): number | undefined => {
  if (!l) {
    return undefined;
  }
  let res;
  if (b) {
    res = Number(l) * FT_TO_M_COF;
  } else {
    res = Number(l) / FT_TO_M_COF;
  }
  return Number(res.toFixed(3));
};

const getTechsName = (estimate?: IJob) => {
  if (!estimate || !estimate.technicians_data.length) {
    return "";
  }
  if (estimate.technicians_data.length === 1) {
    return estimate.technicians_data[0].technician_name;
  }
  return estimate.technicians_data.reduce(
    (acc, tech, index, arr) =>
      index === arr.length - 1
        ? acc + tech.technician_name
        : acc + tech.technician_name + ", ",
    ""
  );
};
const getServicesName = (workOrder?: any) => {
  if (!workOrder || !workOrder.services.length) {
    return "";
  }
  if (workOrder.services.length === 1) {
    return workOrder.services[0].name;
  }
  return workOrder.services.reduce(
    (acc: any, service: { name: any }, index: number, arr: string | any[]) =>
      index === arr.length - 1 ? acc + service.name : acc + service.name + ", ",
    ""
  );
};

const formatSchedule = (schedule?: string, withTime?: boolean) => {
  if (!schedule) return "";
  if (withTime) return moment(schedule).format("MM/DD/YYYY [at] hh:mma");
  return moment(schedule).format("ddd, MMM DD");
};

const formatTimeStart = (time?: any) => {
  if (!time) return "";
  const dateObj = new Date();
  const dateStr = dateObj.toISOString().split("T").shift();
  const timeAndDate = moment(dateStr + " " + time).format("hh:mma");

  return timeAndDate;
};

const formatPeriod = (period?: string) => {
  switch (period) {
    case "W":
      return "week";
    case "M":
      return "month";
    default:
      return "";
  }
};

const _collectReplacements = (
  user: IUserProfile,
  customer: ICustomerShort,
  estimate?: IJob,
  workOrder?: any
): Record<string, string> => {
  // console.log("workOrder", workOrder)
  // console.log("job", estimate)
  return {
    first_name: customer.first_name ?? "",
    last_name: customer.last_name ?? "",
    full_name: customer.full_name ?? "",
    customer_company: customer.company_name ?? "",
    job_number: String(estimate?.order_number ?? ""),
    job_title: String(estimate?.title ?? ""),
    order_number: estimate?.order_number
      ? `#${String(estimate?.order_number)}`
      : `#${String(workOrder?.order_number)}` ?? "",
    order_title: workOrder?.title ?? "",
    vessel_name: estimate?.vessel_data.name ?? workOrder?.vessel?.name ?? "",
    vessel_model: estimate?.vessel_data.model ?? workOrder?.vessel?.model ?? "",
    vessel_make:
      estimate?.vessel_data.manufacturer ??
      workOrder?.vessel?.manufacturer ??
      "",
    company_name: user?.company?.name ?? "",
    company_phone: user?.company?.phone ?? "",
    company_email: user?.company?.email ?? "",
    customer_name: customer.full_name ?? "",
    related_info: "",
    scheduled_date: formatSchedule(estimate?.schedules[0]?.start),
    scheduled_datetime: formatSchedule(estimate?.schedules[0]?.start, true),
    total_due: estimate?.total ? estimate.total.toFixed(2) : "",
    technician_name: getTechsName(estimate),
    assigned_username:
      (getTechsName(estimate) || workOrder?.created_by?.full_name) ?? "",
    admin_name: user?.full_name ?? "",
    completion_date: estimate?.completed_at
      ? moment(estimate.completed_at).format("MM/DD/YYYY [at] hh:mm a")
      : "",
    service_writer_name: workOrder?.created_by.full_name ?? "",
    services: getServicesName(workOrder),
    scheduled_time: workOrder?.recurring_config?.start_time
      ? formatTimeStart(workOrder?.recurring_config?.start_time)
      : "",
    projected_total: workOrder?.recurring_config?.recurring_total_amount
      ? `$${workOrder?.recurring_config?.recurring_total_amount}`
      : "",
    recurring_frequency: workOrder?.recurring_config
      ? `${
          workOrder?.recurring_config?.times_per_period
        } times per ${formatPeriod(
          workOrder.recurring_config?.schedule_period
        )}`
      : " - ",
  };
};

export const getReplacedTemplateMessage = (
  message: string,
  tags: IConstant | null,
  user: IUserProfile,
  customer?: ICustomerShort,
  estimate?: IJob,
  workOrder?: any
): string => {
  if (!tags || !customer) {
    return message;
  }
  const replacements = _collectReplacements(
    user,
    customer,
    estimate,
    workOrder
  );

  return message.replace(tagsReGex, (match) => {
    match = match.replaceAll("#", "");
    return replacements[match] ?? "";
  });
};

export const filterColumns = <T>(
  columns: ColumnType<T>[],
  keys: Key[]
): ColumnType<T>[] => {
  if (keys && keys.length) {
    return columns.filter((c) => keys.includes(c?.key ?? ""));
  }
  return columns;
};

const timeSlots: [TTimeSlot, number][] = [
  ["minute", 60],
  ["hour", 24],
  ["day", 7],
  ["week", 4],
  ["month", 30],
  ["year", 0],
];

export const getTimeSlotCategory = (time: string): TTimeSlotCategory => {
  const created = moment(time);
  if (moment().isSame(created, "day")) {
    return "today";
  }
  if (moment().subtract(1, "day").isSame(created, "day")) {
    return "yesterday";
  }
  if (moment().isSame(created, "week")) {
    return "last week";
  }
  if (moment().isSame(created, "month")) {
    return "last month";
  }
  return "other";
};

const getSlotAndTime = (time: string): [TTimeSlot, number] => {
  const created = moment(time);
  let slot: TTimeSlot = "minute";
  let gap: number = 0;
  for (let i = 0; i < timeSlots.length; i++) {
    const [tSlot, slotSize] = timeSlots[i];
    slot = tSlot;
    gap = moment().diff(created, slot);
    if (gap < slotSize) {
      break;
    }
  }
  return [slot, gap];
};

export const getAgoTime = (time: string): string => {
  const [slot, gap] = getSlotAndTime(time);

  return `${gap} ${slot}${gap === 1 ? "" : "s"} ago`;
};

export const capitalize = (el: string): string => {
  const parts = el.split(" ");
  return parts
    .map((p) => `${p[0].toUpperCase()}${p.slice(1, p.length)}`)
    .join(" ");
};

export const downloadFile = (data: BlobPart, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const sortByDate =
  <T extends object>(k: keyof T, reverse?: boolean) =>
  (a: T, b: T): number => {
    if (moment(a[k]).isBefore(moment(b[k]))) {
      return -1 * (reverse ? -1 : 1);
    } else if (moment(a[k]).isAfter(moment(b[k]))) {
      return 1 * (reverse ? -1 : 1);
    }
    return 0;
  };

export const getTimeDiff = (date?: moment.Moment | string | null) => {
  if (!date) {
    return 0;
  }
  const d = moment(date);
  return d.diff(moment(d).startOf("day"), "minutes") / 60 ?? 0;
};

export const validateLatitude = (lat: number | undefined | null) =>
  lat && lat >= -90 && lat <= 90;
export const validateLongtitude = (lng: number | undefined | null) =>
  lng && lng >= -180 && lng <= 80;

export const getFullProgress = (progress: EJobProgress) => {
  switch (progress) {
    case EJobProgress.Estimate:
      return "Estimate";
    case EJobProgress.Schedule:
      return "Schedule";
    case EJobProgress.Start:
      return "Start";
    case EJobProgress.Work:
      return "Work";
    case EJobProgress.Complete:
      return "Complete";
    default:
      return "Estimate";
  }
};

export const getHumidity = (weather: any, date: number) =>
  Array.isArray(weather)
    ? Math.round(
        weather
          .slice(date * 24 + 7, date * 24 + 18)
          .reduce((acc: number, h: any) => acc + h.relative_humidity, 0) / 11
      )
    : null;

export const getFirstCharsOfFullName = (full_name: string) =>
  full_name
    .split(" ")
    .map((w) => w[0].toUpperCase())
    .join("");

export const formatAddress = (address?: IAddress | TAddress | null) => {
  if (!address) {
    return "";
  }
  if (address?.full_address) {
    return address.full_address;
  }
  return Object.values(address)
    .filter((field) => field && typeof field === "string")
    .join(", ");
};
export const checkNotActive = (
  subscriptionStatus: IStripeSubscription | null
) => !!(subscriptionStatus && subscriptionStatus.status !== "active");
export const getFullVesselType = (type: string) =>
  vesselTypes.find((t) => t.value === type)?.label;

export const formatPriority = (priority: EJobPriority) => {
  switch (priority) {
    case EJobPriority.Low:
      return "Low";
    case EJobPriority.Medium:
      return "Medium";
    case EJobPriority.High:
      return "High";
    case EJobPriority.Urgent:
      return "Urgent";
  }
  return "";
};
export const formatCompanyPaymentStatus = (status?: boolean) => {
  if (status) {
    return "Payment method added";
  }
  return "In Review";
};

export const getIndustries = async (profile: IUserProfile | null) => {
  const { data } = await Api.config.getServiceCategories();
  const categories = profile?.company?.service_categories;
  let res: string[] = [];
  if (categories && categories.length) {
    res = data
      .filter((opt) => categories.includes(opt.id))
      .map((opt) => opt.name);
  }
  return res;
};

const formatterUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatToUSD = (num?: number) => {
  if (!num) return `$0.00`;
  return formatterUSD.format(num);
};

export const clearFormErrors = (form: FormInstance<any>) => {
  const fields = form.getFieldsError();
  const fieldNames: InternalNamePath = [];
  fields
    .filter((f) => f.errors.length)
    .forEach((f) => fieldNames.push(f.name[0]));
  form.setFields(fieldNames.map((f) => ({ name: f, errors: [] })));
};

export const convertFileSize = (size: number, precision: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(precision) * 1 +
    " " +
    ["B", "KB", "MB", "GB", "TB"][i]
  );
};

export const isArrayContainsDuplicates = (arr: Array<any>) => {
  return arr.length !== new Set(arr).size;
};

export const renderFormDataSend = async (
  fields: any,
  generatePdfDocument?: () => any
) => {
  const formData = new FormData();

  if (fields && fields.attach_receipt && generatePdfDocument) {
    const pdf_file = await generatePdfDocument();

    if (pdf_file) {
      formData.append("pdf_file", pdf_file, "file.pdf");
    }
  }

  if (fields) {
    Object.keys(fields).map((item) => {
      if (Array.isArray(fields[item])) {
        fields[item].forEach((i: string, index: any) => {
          formData.append(`${item}[${index}]`, i);
        });
        return;
      }
      return formData.append(item, fields[item]);
    });
  }

  return formData;
};

export const formatPhoneNumber = (number?: string | null) => {
  if (!number || typeof number !== "string") return "";
  if (number.length < 10) return number;
  const start = number.slice(0, number.length - 10);
  const mid = number.slice(number.length - 10, number.length - 4);
  const last4 = number.slice(number.length - 4);
  //no country code
  if (!start) {
    return `${mid.slice(0, 3)}-${mid.slice(3)}-${last4}`;
  }
  return `${start} ${mid.slice(0, 3)}-${mid.slice(3)}-${last4}`;
};
export function getNotificationsColor(priority: string) {
  switch (priority) {
    case ENotificationStatus.High:
      return "#FBCA4D";
    case ENotificationStatus.Extreme:
      return "#fb4d4f";
    default:
      return "";
  }
}

export const phoneFormatter = (val?: string | null | number): string => {
  if (!val) {
    return "";
  }
  val = String(val);
  if (val.length < 3) {
    return val;
  }
  // Clear everything except digits
  val = val.replace(/[^\d]+/g, "");
  const parts = [1, 4, 7];
  const defaultPhoneNumberLength = 11;
  if (val.length === 10) {
    // Add country code
    val = `1${val}`;
  }
  const countryCodeDiff =
    val.length - defaultPhoneNumberLength > 0
      ? val.length - defaultPhoneNumberLength
      : 0;

  let newVal = "";
  for (let partIdx = 0; partIdx <= parts.length; partIdx++) {
    let start = !partIdx ? 0 : parts[partIdx - 1];
    let end = parts[partIdx] ? parts[partIdx] : undefined;
    if (start) {
      // if it's not first part
      start += countryCodeDiff;
    }
    if (end) {
      // if it's not last part
      end += countryCodeDiff;
    }
    const part = val.slice(start, end);
    if (part) {
      if (!newVal) {
        newVal = `+${part}`;
      } else if (partIdx === 1) {
        newVal = `${newVal} ${part}`;
      } else {
        newVal = `${newVal}-${part}`;
      }
    }
  }
  return newVal;
};

export const updatePhoneOnBlur = (form: FormInstance, key: string) => () =>
  form.setFieldsValue({
    [key]: phoneFormatter(form.getFieldsValue([key])[key]),
  });

export const clearPhone = (val?: any): any => {
  if (!val) {
    return val;
  }
  val = String(val);
  return val.replace(/[^\d]+/g, "");
};

export const validatePhone = (value?: string | null) => {
  if (value && clearPhone(value).length > 16) {
    return Promise.reject(
      new Error(
        "Phone number must be entered in the format: '+1 347-403-3694'. Up to 16 digits allowed."
      )
    );
  }
  return Promise.resolve();
};

export const parseDuration = (duration?: string|null, additionalSeconds?: number|null): string => {
  if (!duration) {
    return "00:00:00"
  }
  const parts = duration.split(" ");
  const time = parts[parts.length-1].split('.')[0].split(":");
  if (parts.length > 1) {
    const hoursInDay = 24;
    time[0] = String(Number(time[0]) + Number(parts[0]) * hoursInDay);
  }
  if (additionalSeconds) {
    const totalSeconds = (
      + (Number(time[0]) * 60 * 60)  // Hours
      + (Number(time[1]) * 60)       // Minutes
      + Number(time[2])              // Seconds
      + additionalSeconds
    );
    time[0] = String(Math.floor(totalSeconds / 60 / 60)).padStart(2, "0");
    time[1] = String(Math.floor(totalSeconds / 60) % 60).padStart(2, "0");
    time[2] = String(totalSeconds % 60).padStart(2, "0");
  }
  return `${time[0]}:${time[1]}:${time[2]}`;
}
