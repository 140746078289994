import { useState } from "react"
import { Form } from "antd"

import { CustomTextarea } from "../../../ui/Input"
import { InfoItem, InfoTitle, SaveBtn } from "./styled"
import { Content } from "../../../components/Content"
import Flex from "../../../noui/Flex"
import { useTechnicianRole } from "../../../utils/hooks"

const Notes = ({ loading, notes, noItems, onSuccess }) => {
   const [save, setSave] = useState(false)
   const [form] = Form.useForm()
   const technicianRole = useTechnicianRole()

   const patchNotes = async () => {
      setSave(true)
      const { notes } = await form.getFieldsValue()
      ;(await onSuccess) && onSuccess(notes)
      setSave(false)
   }

   return (
      <InfoItem style={{ margin: "20px 0", paddingBottom: loading ? 24 : 0 }}>
         <InfoTitle>Notes</InfoTitle>
         <Content loading={loading} noItems={noItems} noItemsLabel="No customer info">
            <Form form={form} onFinish={patchNotes}>
               <Content loading={loading} noItems={noItems} noItemsLabel="No customer info">
                  <Form.Item initialValue={notes} name="notes">
                     <CustomTextarea
                        style={{ margin: "12px 0 8px", height: "120px", overflowY: "auto" }}
                        value={notes}
                        disabled={technicianRole}
                     />
                  </Form.Item>
                  {!technicianRole && (
                     <Flex justifyContent="flex-end">
                        <Form.Item shouldUpdate>
                           {() => (
                              <SaveBtn loading={save} disabled={!form.isFieldsTouched()} htmlType="submit">
                                 Save
                              </SaveBtn>
                           )}
                        </Form.Item>
                     </Flex>
                  )}
               </Content>
            </Form>
         </Content>
      </InfoItem>
   )
}

export default Notes
