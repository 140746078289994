import React, {useEffect, useState} from 'react';
import {Api} from "../../../api/api";
import {getFullAddress, getFullName, mapOptions} from "../../../utils/utils";
import classes from "../styles.module.scss";
import {Form, FormInstance, Input, Select} from "antd";
import {AddButton} from "../AddButton";
import {CustomerModal} from "../../../containers/customers/CustomerModal";
import {DataContainer, EditButton, MoreButton, Title} from "../components";
import {useHistory} from "react-router-dom";
import routes from "../../../routes/routes";
import {ICreateJob, ICustomer, IJob, IPaginatedResponse} from "../../../api/types";
import {TArgCallback, TCallback, TOption} from "../../../types";


type TDisplayProps = {
  customer?: ICustomer;
  disabled?: boolean;
  onEdit?: TCallback;
}
const CustomerDisplay: React.FC<TDisplayProps> = ({customer}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(routes.customers.CustomerPage.replace(":id", String(customer?.id ?? "0")))
  }

  return <DataContainer>
    <Title>{getFullName(customer)}</Title>
    <p>
      {customer?.phones.length
        ? customer.phones[0].phone
        : null
      } <br/>
      {customer?.email}
    </p>
    <span>{getFullAddress(customer)}</span>

    <MoreButton type="link" onClick={handleClick}>Open customer page</MoreButton>
  </DataContainer>;
}


type TCSelectProps = {
  onSearch: TArgCallback<string>;
  options: TOption[];
  onClose: TCallback;
  onOpen: TCallback;
  visible: boolean;
  onSuccess: TArgCallback<ICustomer>;
}
const SelectCustomer: React.FC<TCSelectProps> = ({onSearch, options, onClose, onOpen, visible, onSuccess}) => {
  return <><Form.Item name="customer" rules={[{required: true, message: "Customer is required"}]} style={{marginTop: 24}}>
      <Select
        placeholder="Select a Customer"
        onSearch={onSearch}
        options={options}
        showSearch
        filterOption={false}
        notFoundContent={null}
      />
    </Form.Item>
    <AddButton onClick={onOpen}>Add new Customer</AddButton>
    <CustomerModal visible={visible} onSuccess={onSuccess} onClose={onClose}/>
  </>
}

type TProps = {
  form: FormInstance<ICreateJob>;
  estimate?: IJob;
  customer?: ICustomer;
  disabled?: boolean;
}
export const CustomerSelector: React.FC<TProps> = ({form, estimate, customer, disabled}) => {
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [options, setOptions] = useState<TOption[]>([]);

  useEffect(() => {
    Api.customers.getAll({
      page: 1, pageSize: 5
    })
      .then(({data: d}) => {
        const {results} = d as IPaginatedResponse<ICustomer[]>;
        let options = mapOptions(results, getFullName);
        if (customer) {
          if (!options.find(el => el.value.toString() === customer.id.toString())) {
            options = [...mapOptions([customer], getFullName), ...options];
          }
        }
        setOptions(options);
      })
  }, [customer]);

  const handleSearch = (search: string) => {
    Api.customers.getAll({
      page: 1, pageSize: 5, search
    })
      .then(({data: d}) => {
        const {results} = d as IPaginatedResponse<ICustomer[]>
        setOptions(mapOptions(results, getFullName))
      })
  }
  const handleSuccess = (data: ICustomer) => {
    // form.setFieldsValue({customer: data.id});
    setOptions([...options, ...mapOptions([data], getFullName)]);
  }

  const handleToggle = () => {
    setEdit(e => {
      if (e && String(form.getFieldValue("customer")) !== customer?.id.toString()) {
        // form.setFieldsValue({customer: customer?.id});
      }
      return !e;
    });
  }

  return <div className={classes.customer}>
    <h4 className={classes.title}>Customer</h4>
    <Form.Item hidden name="technician">
      <Input hidden />
    </Form.Item>
    {!disabled
      ? <EditButton onClick={handleToggle} type="link">
        {edit ? "Cancel" : "Change customer"}
      </EditButton>
      : null}
    {(!estimate?.id || !customer || edit) ? <SelectCustomer
        visible={visible}
        onSuccess={handleSuccess}
        onClose={() => setVisible(false)}
        onOpen={() => setVisible(true)}
        options={options}
        onSearch={handleSearch}
      /> :
      <CustomerDisplay
        customer={customer}
        disabled={disabled}
        onEdit={() => setEdit(true)}
      />}
  </div>;
};