import { Tooltip } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getSvgByValue } from "../../../assets/images/weatherIcons"
import Flex from "../../../noui/Flex"
import { getHumidity } from "../../../utils/utils"
import { DayName, DayNumber, RainBlock } from "./styled"
import { CustomHeaderProps, Weather } from "./types"
import { isDateCurrent, kebabCaseToNormalText } from "./utils"
import Humidity from "../../../assets/images/Humidity.svg"

const WeatherBlock = styled.div`
   margin-top: 22px;
   min-height: 135px;
   border-left: 1px solid rgba(0, 0, 0, 0.15);
   padding: 15px 18px 28px;
   width: 103%;
   @media (max-width: 1585px) {
      padding: 15px 18px 0px;
      margin-left: -2px;
      box-sizing: border-box;
   }
`

const TemperatureText = styled.div`
   font-size: 28px;
   font-weight: 600;
`
const FlexIcons = styled(Flex)`
   @media (max-width: 1585px) {
      flex-direction: column;
   }
`

const SmallDataBlock = styled.div`
   font-size: 18px;
   color: #109cf1;
   display: flex;
   flex-direction: row;
   align-items: center;
   svg {
      margin: 0 5px 0 0;
   }
   img {
      margin: 0 5px 0 10px;
      height: 24px;
      width: 24px;
   }
   @media (max-width: 1585px) {
      font-size: 14px;
      img {
         margin: 5px;
         height: 18px;
         width: 18px;
      }
   }
`

export const WeekCalendarHeader: React.FC<CustomHeaderProps> = ({ date, weather }) => {
   const [dayWeather, setDayWeather] = useState<Weather | null>(null)

   useEffect(() => {
      const today = moment().startOf("day")
      const dayDiff = moment(date).diff(today, "days")
      if (dayDiff >= 0 && dayDiff <= 9 && Array.isArray(weather.daily)) {
         setDayWeather({
            icon: weather?.daily[dayDiff]?.icon ?? "sunny",
            temperature: (weather?.daily[dayDiff]?.air_temp_high + weather?.daily[dayDiff]?.air_temp_low) / 2 ?? 0,
            precip_type: weather?.daily[dayDiff]?.precip_icon ?? "chance-rain",
            probability: weather?.daily[dayDiff]?.precip_probability ?? 0,
            humidity: getHumidity(weather?.hourly, dayDiff) ?? 0,
         })
      } else {
         setDayWeather(null)
      }
   }, [date, weather])

   return (
      <Flex flexDirection={"column"} alignItems={"center"}>
         <DayName isCurrent={isDateCurrent(date)}>{moment(date).format("ddd")}</DayName>
         <DayNumber isCurrent={isDateCurrent(date)}>{moment(date).format("DD")}</DayNumber>
         <WeatherBlock>
            {dayWeather && (
               <Flex flexDirection="column" justifyContent="space-between" alignItems="center" height="100%">
                  <Tooltip placement="topLeft" title={kebabCaseToNormalText(dayWeather.icon)}>
                     <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        marginBottom="15px"
                     >
                        {getSvgByValue(dayWeather?.icon)}
                        <TemperatureText>{`${Math.round(dayWeather?.temperature || 0)}°F`}</TemperatureText>
                     </Flex>
                  </Tooltip>
                  <FlexIcons flexDirection="row" alignItems="center" justifyContent="space-between">
                     <SmallDataBlock>
                        {getSvgByValue(dayWeather.precip_type)}
                        <span>{`${dayWeather.probability} %`}</span>
                     </SmallDataBlock>
                     <SmallDataBlock>
                        <img src={Humidity} alt="humidity" />
                        <span>{`${dayWeather.humidity} %`}</span>
                     </SmallDataBlock>
                  </FlexIcons>
               </Flex>
            )}
         </WeatherBlock>
      </Flex>
   )
}
