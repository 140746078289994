import React, { useCallback, useEffect, useState } from "react";
import { Api } from "../../../api/api";
import { Content } from "../../../components/Content";
import { Button, Form, FormInstance, Input } from "antd";
import styled from "styled-components";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { Messages } from "../../../components/Messages/Messages";
import {
  ICreateJob,
  ICreateMessage,
  IJob,
  IMessage,
  IWorkOrder,
  IWSMessage,
} from "../../../api/types";
import { useAppSelector } from "../../../config/hooks";
import { getCurrentUser } from "../../../config/reducers/user/selectors";
import moment from "moment";
import { track } from "../../../utils/analytics";
import { useWebSocket } from "../../../utils/hooks";

const Wrapper = styled.div`
  .message-form {
    position: relative;
    label {
      font-weight: 900;
      text-transform: uppercase;
      color: #0f497b;
      font-size: 16px;
    }
    .submit-button {
      .ant-form-item-control-input-content {
        text-align: right;
      }
    }
  }
`;

const { TextArea } = Input;

type TProps = {
  form: FormInstance<ICreateJob>;
  after?: boolean;
  workOrder?: IWorkOrder | null;
};
export const MessagesTab: React.FC<TProps> = ({ workOrder }) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [form] = Form.useForm<ICreateMessage>();
  const profile = useAppSelector(getCurrentUser);
  const isXs = useBreakpoint(down("xs"));

  useEffect(() => {
    loadData();
    form.resetFields();
  }, [form]);

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await Api.messages.getAll({ work_order: workOrder?.id });
      setMessages(data as IMessage[]);
    } finally {
      setLoading(false);
    }
  };

  const handleMessage = useCallback((data: IWSMessage<IMessage>) => {
    setMessages((messages) => [data.message, ...messages]);
  }, []);

  useWebSocket(String(workOrder?.id), "W", handleMessage);

  const handleSave = async (data: any) => {
    setSaving(true);
    try {
      const { data: message } = await Api.messages.create({
        ...data,
        work_order: workOrder?.id ?? 0,
      });
      track("Message Sent to Customer", {
        messageSentByCompany: profile?.company?.id,
        messageSentByUser: profile?.id,
        messageSentTo: message.customer,
        messageTimestamp: moment().toISOString(),
      });
      setMessages([message, ...messages]);
      form.resetFields();
    } catch (e) {
    } finally {
      setSaving(false);
    }
  };
  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = ({
    key,
    ...e
  }) => {
    if (key === "Enter" && form.getFieldValue("message")) {
      if (e.metaKey || e.ctrlKey) {
        form.submit();
      }
    }
  };

  return (
    <Wrapper>
      <Form
        className={"message-form"}
        layout="vertical"
        form={form}
        onFinish={handleSave}
      >
        <Form.Item name="message" label="Message">
          <TextArea
            onKeyDown={handleKeyDown}
            rows={6}
            placeholder="Enter a new message that will be sent out to your customer..."
          />
        </Form.Item>
        <Form.Item className="submit-button" shouldUpdate>
          {() => (
            <Button
              loading={saving}
              type="primary"
              onClick={form.submit}
              disabled={!form.getFieldValue("message")}
              style={{ width: isXs ? "100%" : "auto", height: "38px" }}
            >
              Send
            </Button>
          )}
        </Form.Item>
      </Form>
      <Content loading={loading} noItems={!messages.length} noItemsLabel={" "}>
        <Messages short messages={messages} jobs={[]} selectedJob={null} />
      </Content>
    </Wrapper>
  );
};
