import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import moment from "moment"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { Api } from "../../api/api"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { notification } from "antd/es"
import ReportsForm from "./ReportsForm"
import ReportsList from "./ReportsList"
import { IUserReport, IReportsRequest } from "../../api/types"
import { MainLayout } from "./styled"

type TCalendar = {
   title: any
   subtitle: any
   date: any
}
type TWorkType = {
   start: any | ""
   end: any | ""
   type: string | ""
}

const Reports: React.FC = () => {
   const [data, setData] = useState<IUserReport[] | null>()
   const [calendar, setCalendar] = useState<TCalendar[] | null>()
   const [type, setType] = useState<TWorkType>({
      start: "",
      end: "",
      type: "",
   })

   const handleGenerateReport = async (data: { employee: any; dateStart: any; dateEnd: any; workType: any }) => {
      try {
         let dataSend = {
            start_after: moment(data.dateStart._d).format("YYYY-M-DD"),
            start_before: moment(data.dateEnd._d).format("YYYY-M-DD"),
         } as IReportsRequest

         if (data.employee !== "all") {
            dataSend = {
               ...dataSend,
               user: data.employee,
            }
         }
         const res = await Api.timeClocks.getReports(dataSend)
         setData(res.data as IUserReport[])

         setType({
            start: data.dateStart,
            end: data.dateEnd,
            type: data.workType,
         })
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   useEffect(() => {
      if (type?.type && type.end && type.start) {
         const calendar = generateCalendar(type.start, type.end, type.type)
         setCalendar(calendar)
      }
   }, [type])

   const generateCalendar = useCallback(
      (start: any, end: any, workType: any) => {
         let startDate = moment(start._d)
         let endDate = moment(end._d)
         const arr: any[] = []

         if (workType === "daily") {
            while (endDate.isSameOrAfter(startDate)) {
               const data = {
                  title: startDate.format("ddd"),
                  subtitle: startDate.format("DD"),
                  date: startDate.format("YYYY-MM-DD"),
               }
               arr.push(data)
               startDate.add(1, "day")
            }
         } else if (workType === "week") {
            for (let i = startDate; i <= endDate; i.add(1, "day")) {
               const findWeek = arr.find((i) => i.subtitle === moment(startDate).isoWeek())
               if (findWeek) {
                  continue
               }

               const data = {
                  title: "Week",
                  subtitle: moment(startDate).isoWeek(),
                  date: startDate.format("YYYY-M-DD"),
               }

               arr.push(data)
            }
         } else {
            for (let i = startDate; i <= endDate; i.add(1, "day")) {
               const findMonth = arr.find((i) => i.subtitle === moment(startDate).format("MMM"))
               if (findMonth) {
                  continue
               }

               const data = {
                  title: "",
                  subtitle: moment(startDate).format("MMM"),
                  date: startDate.format("YYYY-M-DD"),
               }

               arr.push(data)
            }
         }
         return arr
      },
      [type]
   )

   const handleChangeType = (value: string) => {
      setType({
         ...type,
         type: value,
      })
   }

   const isXs = useBreakpoint(down("xs"))

   return (
      <>
         <Helmet>
            <title>Reports - DockWorks Pro</title>
         </Helmet>

         <Flex p={isXs ? "14px" : "34px"} pt="15px" flexDirection="column">
            <Msg fontWeight={800} fontSize={24} mb="18px" mt={isXs ? "14px" : "76px"}>
               Reports
            </Msg>
            <MainLayout flexDirection="column" p="20px">
               <ReportsForm
                  data={data}
                  handleGenerateReport={handleGenerateReport}
                  setData={setData}
                  handleChangeType={handleChangeType}
               />
               {data && data.length > 0 && calendar && type && (
                  <ReportsList data={data} calendar={calendar} type={type.type} endDate={type.end} />
               )}
            </MainLayout>
         </Flex>
      </>
   )
}
export default Reports
