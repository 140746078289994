import React from "react"
import styled from "styled-components"
import moment from "moment"

const WelcomeHeader = styled.h3`
   font-size: 24px;
   font-weight: 800;
`

const TodayDate: React.FC = () => {
   const currentDay = moment().format("dddd, D MMMM")
   return <WelcomeHeader>{currentDay}</WelcomeHeader>
}

export default TodayDate
