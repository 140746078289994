import { Button, Modal } from "antd"
import { useContext } from "react"
import styled from "styled-components"
import Flex from "../../../noui/Flex"

import { WarningCircleIcon } from "../../../assets/icons"
import { WOContext } from "../WorkOrderContext"

type TModalProps = {
   isError?: boolean
}

const StyledModal = styled(Modal)<TModalProps>`
   & .ant-modal-body {
      padding: 0;
   }
   p {
      margin: 0;
      font-size: 18px;
   }
   h3 {
      font-size: 15px;
      font-weight: 800;
      color: #fb4d4f;
      margin: 0 0 0 10px;
   }
   & .modal {
      &__header {
         padding: 20px 20px 18px 20px;
      }
      &__btns {
         display: flex;
         justify-content: space-between;
         padding: 18px 20px 20px 20px;
         border-top: 1px solid #ededed;
         button {
            min-width: 140px;
         }
      }
   }
`

type TProps = {
   visible: boolean
   onClose: () => void
}

const SaveChangesModal: React.FC<TProps> = ({ visible, onClose }) => {
   const Context = useContext(WOContext)

   const onCancel = () => {
      onClose()
   }

   const handleUpdate = () => {
      Context.updateJobs(true)
   }
   const handleNotSave = () => {
      Context.resetJobs(true)
   }

   return (
      <StyledModal visible={visible} footer={null} width={390} onCancel={onCancel} destroyOnClose={true}>
         <div className="modal__header">
            <Flex alignItems="center" marginBottom="25px">
               <WarningCircleIcon />
               <h3>WARNING</h3>
            </Flex>

            <p>You have unsaved updates to your project that will be lost if you leave the page.</p>
            <p>Do you want to save them first?</p>
         </div>

         <div className="modal__btns">
            <Button onClick={handleNotSave}>Continue without Saving</Button>
            <Button onClick={handleUpdate} type="primary">
               Save Updates
            </Button>
         </div>
      </StyledModal>
   )
}

export default SaveChangesModal
