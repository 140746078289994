import React, { useEffect, useMemo } from "react"
import DarkLogo from "../../../assets/images/DarkLogo.svg"
import Flex from "../../../noui/Flex"
import Box from "../../../noui/Box"
import styled from "styled-components"
import { Msg } from "../../../ui/Text"
import { Input, Form, notification } from "antd"
import { PrimaryButton } from "../../../ui/Button"
import { useHistory, useLocation } from "react-router-dom"
import SmallBoatImg from "../../../assets/images/SmallBoatImg.png"
import routes from "../../../routes/routes"
import { getErrors } from "../../../utils/utils"
import { Api } from "../../../api/api"
import { ISetPassword } from "../../../api/types"
import { useLoading } from "../../../utils/hooks"
import { authService } from "../../../utils/authService"
import { NavLink } from "react-router-dom"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { BoxWrapper, LoginFlowWrapper, StyledLockIcon } from "../../login/Login"

const StyledInput = styled(Input.Password)`
   height: 40px;
   width: 374px;
   border: 1px solid rgba(0, 0, 0, 0.15);
   outline: 0;
   border-radius: 4px;
   ${down("md")} {
      width: 100%;
   }
`

const LogoContainer = styled(Box)`
   & img {
      width: 140px;
      height: 24px;
   }
   ${down("md")} {
      margin: 0 auto;
   }
`

const BtnPr = styled(PrimaryButton)`
   &:hover {
      border: 1px solid #109cf1;
   }
`

type TForm = Pick<ISetPassword, "password"> & { confirm_password: string }
const ResetPassword: React.FC = () => {
   const { pathname, search } = useLocation()
   const [form] = Form.useForm<TForm>()
   const [saving, onSaving] = useLoading()

   const [isSetNew, { uid, token }] = useMemo(() => {
      const params = new URLSearchParams(search)
      const uid: string = params.get("uid") || ""
      const token: string = params.get("token") || ""
      return [pathname === routes.auth.SetNewPassword, { uid, token }]
   }, [pathname, search])

   const history = useHistory()
   const isMd = useBreakpoint(down("md"))

   useEffect(() => {
      authService.logout()
   }, [])

   const handleSubmit = async (data: TForm) => {
      if (data.password !== data.confirm_password) {
         form.setFields([{ name: "password", errors: ["Passwords should be the same."] }])
         return null
      }
      try {
         if (isSetNew) {
            await onSaving(Api.user.setPassword({ ...data, new_user: true, uid, token }))
         } else {
            await onSaving(Api.user.setPassword({ ...data, uid, token }))
         }

         notification.success({
            message: "New password set, please login",
         })
         history.replace(routes.auth.Login)
      } catch (e) {
         const errors = getErrors(e, ["password"])
         if (!errors.length) {
            notification.error({ message: "Invalid link, please request a different one." })
         }
         form.setFields(errors)
      }
   }
   return (
      <Flex backgroundColor={!isMd ? "none" : "#FBFBFF"}>
         <LoginFlowWrapper padding={isMd ? "0 12px" : "0"}>
            <LogoContainer p={35}>
               <NavLink to={routes.auth.Login}>
                  <img src={DarkLogo} alt="logotype" />
               </NavLink>
            </LogoContainer>
            <Flex flexDirection="column" justifyContent="flex-start" height="100%">
               <Box m={isMd ? "auto 0" : "auto"}>
                  <Flex
                     flexDirection="column"
                     justifyContent="space-between"
                     maxWidth={isMd ? "400px" : "initial"}
                     margin="auto"
                  >
                     <Flex flexDirection="column">
                        <Box mb="30px" textCenter={!!isMd}>
                           <Msg fontWeight={700} fontSize={22}>
                              {isSetNew ? "Set" : "Reset"} Your password
                           </Msg>
                        </Box>
                        <Form form={form} onFinish={handleSubmit}>
                           <Box mb="8px">
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 Password
                              </Msg>
                           </Box>
                           <Form.Item
                              name="password"
                              rules={[
                                 {
                                    required: true,
                                    message: "Please input your password!",
                                 },
                              ]}
                           >
                              <StyledInput
                                 type="password"
                                 autoComplete="new-password"
                                 placeholder="Password"
                                 prefix={<StyledLockIcon />}
                              />
                           </Form.Item>
                           <Box mb="8px" mt="15px">
                              <Msg fontWeight={700} fontSize={13} color="#484848">
                                 Repeat password
                              </Msg>
                           </Box>
                           <Form.Item
                              name="confirm_password"
                              rules={[
                                 {
                                    required: true,
                                    message: "Please input your password!",
                                 },
                                 ({ getFieldValue }) => ({
                                    validator(_, value) {
                                       if (!value || getFieldValue("password") === value) {
                                          return Promise.resolve()
                                       }
                                       return Promise.reject("The two passwords that you entered do not match!")
                                    },
                                 }),
                              ]}
                           >
                              <StyledInput
                                 type="password"
                                 autoComplete="new-password-repeat"
                                 placeholder="Password"
                                 prefix={<StyledLockIcon />}
                              />
                           </Form.Item>
                           <Box mt="30px" mb="15px">
                              <BtnPr
                                 loading={saving}
                                 width={isMd ? "100%" : "160px"}
                                 height="40px"
                                 fontWeight={600}
                                 fontSize={14}
                                 htmlType="submit"
                              >
                                 {isSetNew ? "Set" : "Reset"} password
                              </BtnPr>
                           </Box>
                        </Form>
                     </Flex>
                  </Flex>
               </Box>
            </Flex>
         </LoginFlowWrapper>
         {!isMd && (
            <BoxWrapper>
               <img src={SmallBoatImg} width="100%" height="100%" alt="background" />
            </BoxWrapper>
         )}
      </Flex>
   )
}

export default ResetPassword
