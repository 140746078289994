import { useEffect, useState } from "react"
import styled from "styled-components"
import { Spin } from "antd"
import { IInspection } from "../../api/types"
import Flex from "../../noui/Flex"
import { Api } from "../../api/api"

const PhotosBlock = styled.div`
   h3 {
      font-size: 15px;
      font-weight: bold;
      span.red {
         color: #fb4d4f;
      }
      span.green {
         color: #42c77b;
      }
   }

   @media print {
      .pagebreak {
         page-break-before: always;
      }
      .noBreak {
         page-break-inside: avoid;
      }
   }
`
export const ImageFlex = styled(Flex)`
   align-items: center;
   flex-wrap: wrap;
   margin-top: 20px;

   .img {
      width: 116px;
      height: 98px;
      border-radius: 5px;
      margin-right: 10px;
   }
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
   }
`
export const Inspection = styled(Flex)`
   align-items: normal;
   border-bottom: 1px solid #ededed;
   padding-bottom: 15px;
   margin-bottom: 15px;

   &:last-child {
      border-bottom: none;
      margin-bottom: 0;
   }
   h4 {
      color: #109cf1;
      font-size: 13px;
      font-weight: bold;
      margin-right: 5px;
   }
   h3 {
      font-size: 13px;
      font-weight: bold;
   }
   .notes {
      border: 1px solid #ededed;
      border-radius: 4px;
      min-height: 70px;
      padding: 7px;
      font-size: 13px;
      max-width: 400px;
      >p {
         overflow-wrap: break-word;
      }
   }
`
export const InspectionInfo = styled.div`
   width: 100%;
`

type TProps = { job: any }

const Photos: React.FC<TProps> = ({ job }) => {
   const [inspectionsBefore, setInspectionsBefore] = useState<IInspection[] | null>([])
   const [inspectionsAfter, setInspectionsAfter] = useState<IInspection[] | null>([])
   const [loading, setLoading] = useState(false)

   const setInspections = () => {
      if (!job || !job?.inspections) return;
      setInspectionsBefore(job.inspections.filter((i: any) => i.category === 'B'))
      setInspectionsAfter(job.inspections.filter((i: any) => i.category === 'A'))
   }

   useEffect(() => {
      setInspections()
   }, [job])

   return loading ? (
      <Flex justifyContent="center" alignItems="center">
         <Spin spinning={loading} style={{ marginTop: "40px" }}></Spin>
      </Flex>
   ) : (
      <>
         <PhotosBlock>
            <h3>
               <span className="red">Before:</span> Photos and notes
            </h3>
            {inspectionsBefore && inspectionsBefore.length > 0 ? (
               inspectionsBefore.map((i, idx) => (
                  <Inspection className="noBreak" key={i.name + idx}>
                     <h4>{`#${idx + 1}`}</h4>

                     <InspectionInfo>
                        <h3>{i.name}</h3>

                        {i.notes && (
                           <div className="notes">
                              <p>{i.notes}</p>
                           </div>
                        )}

                        <ImageFlex>
                           {i.images.map((i) => (
                              <div className="img" key={i.image}>
                                 <img src={i?.image} />
                              </div>
                           ))}
                        </ImageFlex>
                     </InspectionInfo>
                  </Inspection>
               ))
            ) : (
               <p>No data</p>
            )}
         </PhotosBlock>
         <PhotosBlock>
            <h3>
               <span className="green">During/After:</span> Photos and notes
            </h3>
            {inspectionsAfter && inspectionsAfter.length > 0 ? (
               inspectionsAfter.map((i, idx) => (
                  <Inspection className="noBreak" key={i.name + idx}>
                     <h4>{`#${idx + 1}`}</h4>

                     <InspectionInfo>
                        <h3>{i.name}</h3>
                        {i.notes && (
                           <div className="notes">
                              <p>{i.notes}</p>
                           </div>
                        )}

                        <ImageFlex>
                           {i.images.map((i) => (
                              <div className="img" key={i.image}>
                                 <img src={i?.image} />
                              </div>
                           ))}
                        </ImageFlex>
                     </InspectionInfo>
                  </Inspection>
               ))
            ) : (
               <p>No data</p>
            )}
         </PhotosBlock>
      </>
   )
}

export default Photos
