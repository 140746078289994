import {Input, InputProps} from 'antd';
import React from 'react';
import styled from 'styled-components';
import SearchIc from '../assets/images/headerIcons/Search.svg';
import {TCallback, TInputChangeHandler} from "../types";

let SearchIcon = () => <img src={SearchIc} alt="Search Icon" />;

const StyledIcon = styled(SearchIcon)`
  color: black;
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  border: 0;
  outline: 0;
`;


const StyledInputWithBorder = styled(Input)`
border: 1px solid rgba(0, 0, 0, 0.15);
box-sizing: border-box;
border-radius: 6px;
width: 100%;
`;

type TSProps = {
  placeholder?: string;
  onChange?: (e: any) => any;
}

export const Search: React.FC<TSProps> = ({placeholder, ...props}) => {
  return <StyledInput
    {...props}
    prefix={<StyledIcon />}
    placeholder={placeholder??"Search here"}
  />
}

export const SearchWithBorder: React.FC<InputProps> = ({ placeholder, disabled, onChange,  ...rest }) => {
  return <StyledInputWithBorder onChange={onChange} disabled={disabled}
    prefix={<StyledIcon />}
    placeholder={placeholder || "Search here"}
    {...rest}
  />
}


