import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Api } from "../../../api/api"
import { notification, Spin, Empty } from "antd"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import styled from "styled-components"
import { SimpleJob } from "./SimpleJob"
import { IJob, IUser } from "../../../api/types"
import { useAppSelector } from "../../../config/hooks"
import { getCurrentUser } from "../../../config/reducers/user/selectors"

const ColumnHeader = styled(Flex)`
   width: 304px;
   height: 44px;
`

const MainContainer = styled(Flex)`
   padding: 3px;
   overflow-x: auto;
   white-space: nowrap;
   padding-bottom: 12px;
   &::-webkit-scrollbar {
      height: 13px;
   }

   &::-webkit-scrollbar {
      width: 10px;
   }

   &::-webkit-scrollbar-track {
      background: #ebf1f6;
   }

   &::-webkit-scrollbar-thumb {
      background-color: #0496ff;
      border-radius: 8px;
      border: 4px solid #ebf1f6;
   }
   & {
      scrollbar-width: auto;
      scrollbar-color: #0496ff #ebf1f6;
      scrollbar-width: thin;
   }
`

const statuses = {
   E: "Open Estimates",
   T: "To Be Scheduled",
   S: "Scheduled Jobs",
   W: "In Progress",
   "C/F": "Completed",
}

export const KanbanBoard: React.FC = () => {
   const [loading, setLoading] = useState<boolean>(false)
   const [jobs, setJobs] = useState<IJob[]>([])
   const [techs, setTechs] = useState<IUser[]>([])
   const user = useAppSelector(getCurrentUser)

   const loadCards = async () => {
      try {
         setLoading(true)
         const jobs = await Api.jobs.getAll({})
         setJobs(jobs.data as IJob[])
         const techs = await Api.user.getAll({})
         setTechs(techs.data as IUser[])
      } catch (e: any) {
         notification.error(e)
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      loadCards()
   }, [])

   const getTechInfo = (job: IJob) => {
      const techsInJob = job.technicians_data.map((data) => data.technician)
      const filteredTechInfo = techs.filter((t) => techsInJob.includes(t.id))
      if (filteredTechInfo.length) {
         return filteredTechInfo[0]
      }
      return null
   }

   return (
      <>
         <Helmet>
            <title>Status Board - DockWorks Pro</title>
         </Helmet>

         {!loading ? (
            jobs.length > 0 ? (
               <MainContainer>
                  {Object.entries(statuses).map((entry) => (
                     <Flex flexDirection="column" mr="12px">
                        <ColumnHeader
                           className="little-cart"
                           justifyContent="space-between"
                           alignItems="center"
                           pr="12px"
                           pl="12px"
                        >
                           <Msg fontSize="15px" fontWeight="400">
                              {entry[1]}
                           </Msg>
                           <Msg fontSize="15px" fontWeight="800" color="#1C99FC">
                              {jobs.filter((el) => entry[0].includes(el.progress)).length}
                           </Msg>
                        </ColumnHeader>
                        {jobs.map((el) =>
                           entry[0].includes(el.progress) ? (
                              <SimpleJob
                                 jobData={el}
                                 loadCards={loadCards}
                                 techInfo={getTechInfo(el)}
                                 userInfo={user}
                              />
                           ) : (
                              ""
                           )
                        )}
                     </Flex>
                  ))}
               </MainContainer>
            ) : (
               <Empty />
            )
         ) : (
            <Spin />
         )}
      </>
   )
}
