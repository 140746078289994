import { forwardRef, useEffect, useState } from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { formatToUSD, renderAddress } from "../../../utils/utils"
import { servicesUnitTypes, vesselTypes } from "../../../constants"
import { IUser } from "../../../api/types"
import Flex from "../../../noui/Flex"
import { Api } from "../../../api/api"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import { getPdfCheckboxes } from "../../../config/reducers/pdfCheckboxes/selectors"
import Photos from "./Photos"

import logo from "../../../assets/images/dockworks_logo.png"

const Wrapper = styled.div`
   background: #fff;
   width: calc(595px - 40px);
   //padding: 20px;

   .noBreak {
      page-break-inside: avoid;
   }
   @media all {
      .noBreak {
         page-break-inside: avoid;
      }
   }

   .bold {
      font-weight: bold;
      margin-left: 5px;
   }
`
const Header = styled.header`
   padding: 0 0 10px 0;
   display: flex;
   align-items: center;

   p {
      font-size: 9px;
      margin-bottom: 0px;
      span {
         font-weight: bold;
      }
   }

   h3 {
      font-weight: normal;
      font-size: 9px;
      text-transform: uppercase;
      margin-bottom: 5px;
   }
   h4 {
      font-weight: 900;
      font-size: 9px;
      margin-bottom: 3px;
   }
   div {
      // width: 100%;
   }
   img {
      // max-width: 150px;
      // max-height: 90px;
      // width: 100%;
      // height: auto;
      // object-fit: contain;
   }
`
const Info = styled(Flex)`
   gap: 30px;
   .infoBlock {
      width: 100%;
   }
   h4 {
      font-weight: bold;
      font-size: 9px;
      text-align: center;
   }
`
const HeaderInfo = styled.div`
   margin-left: 25px;
`
const WOInfo = styled(Flex)`
   width: 100%;
   padding: 7px 15px;
   color: #fff;
   background: #0f497b;
   h3 {
      font-weight: bold;
      font-size: 9px;
      color: #fff;
      margin: 0;
   }
   p {
      font-weight: 600;
      font-size: 9px;
      margin: 0;
   }
`
const WOTitle = styled(Flex)`
   width: 100%;
   padding: 5px;
   h3 {
      font-weight: 900;
      font-size: 9px;
      color: #202020;
      margin: 0;
   }
   p {
      font-weight: 900;
      font-size: 9px;
      margin: 0;
      span {
         color: #8b8b8b;
         font-weight: normal;
      }
   }
`
const EmptyHeader = styled.div`
   // width: 100%;
   // height: 130px;
   // margin-bottom: 20px;
`
const Details = styled.div`
   margin: 10px 0;
   font-size: 9px;
   h4 {
      font-weight: normal;
      font-size: 9px;
      text-transform: uppercase;
      text-align: left;
      text-decoration: underline;
      letter-spacing: 1px;
   }
   ul {
      margin: 0 0 15px 0;
      padding-left: 25px;
      &.noListType {
         list-style-type: none;
         padding-left: 0;
         li {
            span {
               margin-right: 10px;
            }
         }
      }
      li {
         font-weight: bold;
         span {
            font-weight: normal;
         }
      }
   }
   h5 {
      color: #8b8b8b;
      font-weight: normal;
      font-size: 9px;
   }
`
const Customer = styled.div`
   width: 100%;
   //min-width: 300px;
`
const CustomerInfo = styled(Flex)`
   width: 100%;
   justify-content: space-between;
   font-size: 9px;
   > div:first-child {
      width: 120px;
   }
   .title {
      font-weight: bold;
   }
   &.leftAligned {
      justify-content: flex-start;
      > div:first-child {
         width: 90px;
      }
   }
`

const JobTitle = styled(Flex)`
   margin-top: 10px;
   padding: 5px;
   border-bottom: 1px solid #ededed;
   h3 {
      font-weight: 900;
      font-size: 11px;
      text-transform: uppercase;
      margin: 0;
   }
   p {
      color: #494949;
      font-size: 9px;
      margin: 0;
   }
`
const JobInfo = styled(Flex)`
   margin-bottom: 10px;
   padding: 5px;
   font-size: 9px;
   span {
      font-weight: bold;
   }
   p {
      margin: 0;
   }
`

const PaymentStatus = styled.div`
   border: 1px solid #ededed;
   margin-top: 10px;
   h3 {
      background: #0f497b;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 9px;
      margin: 0;
      padding: 7px;
   }
   .paymentBlock {
      width: 50%;
      padding: 10px;
      p {
         font-size: 9px;
         margin-bottom: 10px;
      }
   }
   .divider {
      width: 100%;
      height: 1px;
      background: #ededed;
      margin: 5px 0;
   }
`
const VesselHeader = styled.div`
   padding: 10px;
   background: #ededed;
`
const VesselBottom = styled.div`
   padding: 10px;
`
const VesselImg = styled.div`
   width: 100%;
   height: 135px;
   overflow: hidden;
   border-radius: 4px;
   margin-top: 10px;
   img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
   }
`

const Table = styled.div`
   margin: 10px 0;
   box-sizing: border-box;
`
const TableItem = styled.div`
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
`
const Services = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 3fr 1fr 1.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 9px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 1fr 1.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Materials = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 2fr 4fr 1fr 1.5fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 9px;
      background: #fbfbff;
      &.tech {
         grid-template-columns: 3fr 2fr 2.5fr;
      }
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .description {
      padding: 8px;
      font-size: 9px;
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Technician = styled.div`
   background: #fbfbff;
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
   padding-bottom: 4px;
   & .grid {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr 0.5fr 1fr 0.5fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 9px;
      &.tech {
         grid-template-columns: 3fr 2fr 1.5fr 1fr;
      }

      > div {
         padding: 4px 8px;
      }
      &.header {
         text-decoration: underline;
         background: #fbfbff;
         font-weight: normal;
      }
   }
`
const Scheduler = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 5.5fr 1.75fr 1.75fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 9px;
      background: #fbfbff;
      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Summary = styled(Flex)`
   justify-content: space-between;
   align-items: flex-start;
   .disclaimers {
      margin-right: 10px;
      h3 {
         text-decoration: underline;
      }
   }
   .summary {
      min-width: 220px;
      padding: 4px 10px;
      border: 1px solid #ededed;
      background: #fbfbff;
      div {
         padding: 3px 0;
      }
   }

   //float: right;
   font-size: 9px;

   & .total {
      font-weight: bold;
   }
   & .discount {
      color: #42c77b;
      font-weight: 700;
   }
`
const JobTechnicians = styled.div`
   padding: 0 10px;
   margin-bottom: 30px;
   h3 {
      font-weight: 700;
      font-size: 9px;
      text-decoration: underline;
   }
   p {
      font-weight: 400;
      font-size: 9px;
      margin-bottom: 3px;
   }
`

type Props = {
   workOrder: any
   job: any
   technicianRole: boolean
   isInvoice: boolean
}
export type Ref = HTMLDivElement

const PdfJob = forwardRef<Ref, Props>(({ workOrder, job, technicianRole, isInvoice }, ref) => {
   const [options, setOptions] = useState<IUser[]>([])
   const [vesselImg, setVesselImg] = useState<any>(null)
   const userData = useSelector(getCurrentUser)
   const dataCheckboxes = useSelector(getPdfCheckboxes)

   const [checkboxes, setCheckBoxes] = useState<any>({
      woPricing: true,
      woPhotos: false,
      vesselPhoto: true,
      beforeAfterPhotos: true,
      checkAll: false,
   })

   useEffect(() => {
      const checkboxesValue = localStorage.getItem("pdfCheckboxes")
      if (checkboxesValue && dataCheckboxes) {
         setCheckBoxes(dataCheckboxes)
      }
   }, [dataCheckboxes])

   useEffect(() => {
      Api.user.getAll({}).then(({ data: d }) => {
         const results = d as IUser[]
         setOptions(results)
      })
   }, [])

   useEffect(() => {
      if (workOrder && workOrder.vessel) {
         Api.vessels.get(workOrder?.vessel.id).then(({ data }) => {
            setVesselImg(data.image)
         })
      }
   }, [workOrder])

   const formatPrice = (price: number | undefined) =>
      price !== undefined ? "$" + price.toFixed(2) : "$" + (0).toFixed(2)

   return (
      <Wrapper ref={ref}>
         <Header className="topHeader" style={{ visibility: "hidden", height: "0" }}>
            <Flex justifyContent="center">
               <div style={{ width: "150px", height: "90px" }}>
                  <img
                     className="pdfLogo"
                     src={userData?.company?.logo ? userData.company.logo : logo}
                     width="auto"
                     height="90px"
                     alt="logo"
                  />
               </div>
            </Flex>
            <HeaderInfo>
               <Flex>
                  <Flex flexDirection="column">
                     <h3>COMPANY</h3>
                     <h4>{userData?.company?.name}</h4>
                     <p>{`${userData?.company?.address?.address1} ${userData?.company?.address?.address2} ${userData?.company?.address?.city}, ${userData?.company?.address?.state}`}</p>
                     <p>{userData?.company?.phone}</p>
                     <p style={{ color: "#0496FF" }}>{userData?.company?.email}</p>
                  </Flex>

                  <Flex alignItems="flex-start" flexDirection="column" marginLeft="55px">
                     <h3>BILLING CONTACT</h3>
                     <h4>{workOrder?.customer?.full_name}</h4>
                     <p> {workOrder?.customer?.address && renderAddress(workOrder?.customer?.address)}</p>
                     <p>
                        {workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")?.phone ||
                           "No data"}
                     </p>
                     <p style={{ color: "#0496FF" }}>{workOrder?.customer?.email}</p>
                  </Flex>
               </Flex>
            </HeaderInfo>
         </Header>
         <table>
            <thead>
               <tr>
                  <th>
                     <EmptyHeader />
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <div className="content">
                        <WOInfo alignItems="center" justifyContent="space-between">
                           <h3>WORK ORDER ESTIMATE</h3>
                           <p>Sent: {moment(workOrder?.send_config?.updated).format("ddd, M/D/YYYY h:mm A")}</p>
                        </WOInfo>
                        <WOTitle alignItems="center" justifyContent="space-between">
                           <h3>{workOrder?.title}</h3>

                           <Flex style={{ fontSize: "9px" }}>
                              <div>Work order:</div> <div className="bold">#{workOrder?.order_number}</div>
                           </Flex>
                        </WOTitle>
                        <Info justifyContent="normal">
                           <div className="infoBlock">
                              <Customer>
                                 <CustomerInfo className="leftAligned">
                                    <div>Service Writer: </div>
                                    <div className="title">{workOrder?.created_by?.full_name}</div>
                                 </CustomerInfo>
                                 <CustomerInfo className="leftAligned">
                                    <div>Created on: </div>
                                    <div className="title">
                                       {workOrder?.created
                                          ? moment(workOrder.created).format("ddd, M/D/YYYY h:mm A")
                                          : "No data"}
                                    </div>
                                 </CustomerInfo>
                              </Customer>
                              <Details>
                                 <h4>Project Details</h4>
                                 <div>{workOrder?.notes || "No notes provided"}</div>
                              </Details>
                              <Details>
                                 <h4>SCOPE OF WORK</h4>
                                 <div>
                                    <ul>
                                       <li>
                                          {job.title} <span>({job.order_number})</span>
                                       </li>
                                    </ul>
                                 </div>
                                 <h5>(Full project summary listed below)</h5>
                              </Details>
                              <Customer>
                                 <CustomerInfo className="leftAligned">
                                    <div>Due Date: </div>
                                    <div className="title">
                                       {moment(workOrder?.created).add(4, "days").format("ddd, M/D/YYYY h:mm A")}
                                    </div>
                                 </CustomerInfo>
                                 <CustomerInfo className="leftAligned">
                                    <div>Est. Duration: </div>
                                    <div className="title">{workOrder?.estimated_duration ?? "TBD"}</div>
                                 </CustomerInfo>
                              </Customer>
                           </div>
                           <div className="infoBlock" style={{ border: "1px solid #ededed" }}>
                              <VesselHeader>
                                 <CustomerInfo>
                                    <div>Vessel:</div>
                                    <div className="title" style={{ fontSize: "10px", fontWeight: "bold" }}>
                                       {workOrder?.vessel?.name}
                                    </div>
                                 </CustomerInfo>
                                 <CustomerInfo>
                                    <div>Hull ID:</div>
                                    <div className="title">{workOrder?.vessel?.hin}</div>
                                 </CustomerInfo>
                              </VesselHeader>
                              <VesselBottom>
                                 <CustomerInfo>
                                    <div>Vessel Type:</div>
                                    <div className="title">
                                       {vesselTypes.find((v) => v.value === workOrder?.vessel?.vessel_type)?.label ||
                                          "No data"}
                                    </div>
                                 </CustomerInfo>
                                 <CustomerInfo>
                                    <div>Location:</div>
                                    <div className="title">{workOrder?.vessel?.location_name}</div>
                                 </CustomerInfo>
                                 <CustomerInfo>
                                    <div>Address:</div>
                                    <div className="title">
                                       {workOrder?.vessel?.address
                                          ? renderAddress(workOrder?.vessel?.address)
                                          : "No data location"}
                                    </div>
                                 </CustomerInfo>
                                 <CustomerInfo>
                                    <div>Slip #:</div>
                                    <div className="title">{workOrder?.vessel?.slip_number}</div>
                                 </CustomerInfo>
                                 {checkboxes.vesselPhoto && vesselImg && (
                                    <VesselImg>
                                       {vesselImg && (
                                          <img src={vesselImg.replace(/^http:\/\//i, "https://")} alt="vessel" />
                                       )}
                                    </VesselImg>
                                 )}
                              </VesselBottom>
                           </div>
                        </Info>

                        {job && (
                           <div key={job?.id}>
                              <div className="noBreak">
                                 <JobTitle justifyContent="space-between">
                                    <h3>{job.title}</h3>
                                    <p>(Job {job.order_number})</p>
                                 </JobTitle>
                                 <JobInfo alignItems="center" justifyContent="space-between">
                                    <Flex style={{ fontSize: "9px" }}>
                                       <div> Start Date & Time: </div>{" "}
                                       <div className="bold">
                                          {moment(job?.schedules?.sent).format("ddd, M/D/YYYY h:mm A")}
                                       </div>
                                    </Flex>
                                    <Flex style={{ fontSize: "9px" }}>
                                       <div> Estimated Duration: </div>{" "}
                                       <div className="bold">{job?.estimated_duration ?? "TBD"}</div>
                                    </Flex>
                                 </JobInfo>
                              </div>
                              <div className="noBreak">
                                 <Table>
                                    {job?.services && job.services.length > 0 && (
                                       <Services className="noBreak">
                                          <div
                                             className={`grid header ${
                                                technicianRole || !checkboxes.woPricing ? "tech" : ""
                                             }`}
                                          >
                                             <div className="name">LABOR</div>
                                             <div className="desc">Description</div>
                                             <div className="qty">Qty</div>
                                             {checkboxes.woPricing && !technicianRole && (
                                                <div className="rate">Rate</div>
                                             )}

                                             <div className="type">Type</div>
                                             {checkboxes.woPricing && !technicianRole && (
                                                <div className="total">Total</div>
                                             )}
                                          </div>

                                          {job.services.map((item: any) => {
                                             const type = servicesUnitTypes.find((s) => s.value === item.unit_type)
                                             return (
                                                <TableItem key={item.id}>
                                                   <div
                                                      className={`grid ${
                                                         technicianRole || !checkboxes.woPricing ? "tech" : ""
                                                      }`}
                                                   >
                                                      <div className="name">{item.name}</div>
                                                      <div className="desc">{item.description}</div>
                                                      <div className="qty">{item.qty.toFixed(2)}</div>
                                                      {checkboxes.woPricing && !technicianRole && (
                                                         <div className="rate">{formatToUSD(item.unit_price)}</div>
                                                      )}

                                                      <div className="type">{type && type.label}</div>
                                                      {checkboxes.woPricing && !technicianRole && (
                                                         <div className="total" style={{ fontWeight: "bold" }}>
                                                            {formatToUSD(item.unit_price * item.qty)}
                                                         </div>
                                                      )}
                                                   </div>
                                                </TableItem>
                                             )
                                          })}
                                          {job?.technicians_data && job.technicians_data.length > 0 && (
                                             <Technician>
                                                <div className={`grid header`}>
                                                   <div />
                                                   <div>Technician(s)</div>
                                                   <div>Estimated Hours</div>
                                                   <div />
                                                   <div />
                                                   <div />
                                                </div>

                                                {job.technicians_data.map((item: any) => {
                                                   const technician = options.find(
                                                      (t) => Number(t.id) === item.technician
                                                   )
                                                   return (
                                                      <div key={item.id} className={`grid`}>
                                                         <div />
                                                         <div>{technician && technician.full_name}</div>
                                                         <div>{item.estimated_hours}</div>
                                                         <div />
                                                         <div />
                                                         <div />
                                                      </div>
                                                   )
                                                })}
                                             </Technician>
                                          )}
                                       </Services>
                                    )}
                                 </Table>
                              </div>
                              <div className="noBreak">
                                 <Table>
                                    {job?.materials && job.materials.length > 0 && (
                                       <Materials className="noBreak">
                                          <div
                                             className={`grid header ${
                                                technicianRole || !checkboxes.woPricing ? "tech" : ""
                                             }`}
                                          >
                                             <div className="name">MATERIALS</div>

                                             <div className="desc">Description</div>
                                             <div className="qty">Qty</div>
                                             {checkboxes.woPricing && !technicianRole && (
                                                <>
                                                   <div className="price">Unit price</div>
                                                   <div className="total">Total</div>
                                                </>
                                             )}
                                          </div>

                                          {job.materials.map((item: any) => {
                                             return (
                                                <TableItem key={item.id}>
                                                   <div
                                                      className={`grid ${
                                                         technicianRole || !checkboxes.woPricing ? "tech" : ""
                                                      }`}
                                                   >
                                                      <div className="name">{item.name}</div>

                                                      <div>{item.description}</div>
                                                      <div className="qty">{item.qty}</div>
                                                      {checkboxes.woPricing && !technicianRole && (
                                                         <>
                                                            <div className="price">{formatToUSD(item.unit_price)}</div>
                                                            <div className="total" style={{ fontWeight: "bold" }}>
                                                               {formatToUSD(item.unit_price * item.qty)}
                                                            </div>
                                                         </>
                                                      )}
                                                   </div>
                                                </TableItem>
                                             )
                                          })}
                                       </Materials>
                                    )}
                                 </Table>
                              </div>

                              {checkboxes.woPricing && !technicianRole && (
                                 <div className="noBreak">
                                    <Summary>
                                       <div className="disclaimers">
                                          <h3>DISCLAIMERS</h3>
                                          {isInvoice ? (
                                             <p>{userData?.company?.invoice_settings?.invoice_footer}</p>
                                          ) : (
                                             <p>{userData?.company?.invoice_settings?.estimate_footer}</p>
                                          )}
                                       </div>
                                       <div className="summary">
                                          <Flex justifyContent="space-between">
                                             <div>Labor</div>
                                             <div>{formatToUSD(job.total_labor)}</div>
                                          </Flex>
                                          <Flex justifyContent="space-between">
                                             <div>Materials</div>
                                             <div>{formatToUSD(job.total_materials)}</div>
                                          </Flex>
                                          <Flex justifyContent="space-between">
                                             <div>Subtotal</div>
                                             <div>
                                                {formatToUSD(job !== null ? job.total_labor + job.total_materials : 0)}
                                             </div>
                                          </Flex>
                                          <Flex justifyContent="space-between">
                                             <div>Taxes</div>
                                             <div>{formatToUSD(job !== null ? job.tax : 0)}</div>
                                          </Flex>
                                          <Flex justifyContent="space-between">
                                             <div>EPA fees</div>
                                             <div>{formatToUSD(job !== null ? job.epa : 0)}</div>
                                          </Flex>
                                          <Flex className="total" justifyContent="space-between">
                                             <div>Job total</div>
                                             <div>{formatToUSD(job?.total)}</div>
                                          </Flex>
                                       </div>
                                    </Summary>
                                 </div>
                              )}
                              {checkboxes.woPhotos && <Photos job={job} />}
                           </div>
                        )}
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </Wrapper>
   )
})

export default PdfJob
