import { useState, useEffect, useRef } from "react"
import { Input, Form, notification, Button, Spin, Popconfirm } from "antd"
import { DeleteFilled } from "@ant-design/icons"
import { EmptyButton } from "../../../ui/Button"
import { Api } from "../../../api/api"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import {
   BlockTitle,
   ResponsiveFlex,
   ResponsiveTextarea,
   AddButton,
   CustomDivider,
   MainUpload,
   MainUploadDiv,
   UploadBtn,
   InspectionImage,
   PhoneUpload,
   ImageFlex,
   Buttons,
   FormPhotos,
   DeleteBtn,
} from "../styled"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import LargeDelete from "../../../assets/images/ImageDeleteLarge.svg"
import { useAppSelector } from "../../../config/hooks"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import moment from "moment"
import { track } from "../../../utils/analytics"

const ImageItem = ({ data, onRemove }) => {
   const [popupVisible, setPopupVisible] = useState(false)
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))

   return (
      <InspectionImage src={data?.image ? data.image : data?.url}>
         <Popconfirm
            title="Do you want to delete this image?"
            visible={popupVisible}
            onConfirm={() => onRemove(data)}
            onCancel={() => setPopupVisible(false)}
            onVisibleChange={() => setPopupVisible(!popupVisible)}
         >
            <DeleteBtn
               onClick={() => {
                  setPopupVisible(true)
               }}
            >
               {isXs || isMd ? <img src={LargeDelete} alt="delete" /> : <DeleteFilled style={{ color: "#fff" }} />}
            </DeleteBtn>
         </Popconfirm>
      </InspectionImage>
   )
}

const InspectionBlock = ({ order, inspection, onDelete, profile, job }) => {
   const [images, setImages] = useState([])
   const [form] = Form.useForm()
   const imageUpload = useRef(null)
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))
   const [isLoading, setIsLoading] = useState(false)
   const [showMore, setShowMore] = useState(false)

   const onChange = async (e) => {
      ;[...e.target.files].forEach((file) => {
         setIsLoading(true)
         getBase64(file)
            .then((image) =>
               Api.inspections.addImage({
                  id: inspection.id,
                  images: [{ image: image }],
               })
            )
            .then(async (res) => {
               const images = await res.data.images
               setImages(images)
               track("Photo Added", {
                  workorderId: job.work_order,
                  vesselId: job.vessel_data.id,
                  vesselCity: job.address?.city,
                  vesselState: job.address?.state,
                  jobId: job.id,
                  photoUploadedBy: profile.id,
                  photoUploadedTimestamp: moment().toISOString(),
               })
            })
            .finally(() => setIsLoading(false))
      })
   }

   const onRemove = (image) => {
      Api.inspections.removeImage({ id: inspection.id, imageId: image?.id })
      const newImages = images.filter((i) => i.id !== image.id)
      setImages(newImages)
   }

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.readAsDataURL(file)
         reader.onload = () => resolve(reader.result)
         reader.onerror = (error) => reject(error)
      })
   }

   const onInspectionSave = () => {
      Api.inspections.patch({
         id: inspection.id,
         name: form.getFieldValue("name"),
         notes: form.getFieldValue("notes"),
      })
      notification.success({
         message: "Success",
         description: "Inspection successfully saved",
      })
   }

   const onInspectionDelete = () => {
      Api.inspections.delete(inspection.id)
      onDelete(inspection.id)
   }

   const handleShowMore = () => {
      setShowMore((prev) => !prev)
   }

   const filterImages = (_, index) => !(!showMore && isMd && index >= 2)

   useEffect(() => {
      setImages(inspection.images)
   }, [inspection])

   return (
      <Flex flexDirection={isXs || isMd ? "column" : "row"}>
         <Msg color="#109CF1" fontSize={13} fontWeight={700}>{`#${order}`}</Msg>
         <FormPhotos style={{ width: isXs || isMd ? "100%" : "80%" }} form={form}>
            <Flex flexDirection={"column"} ml={isXs || isMd ? "0px" : "16px"} width="100%">
               {/* <ResponsiveFlex gap={16}>
                  <Form.Item label="Name" name="name" style={{ flexGrow: 1 }}>
                     <Input placeholder="Enter the name of inspection" defaultValue={inspection.name} />
                  </Form.Item>
               </ResponsiveFlex> */}
               <Form.Item name="notes" label="Note">
                  <ResponsiveTextarea placeholder="Add your description here. You can choose to show or hide notes from the customer when you send the invoice." defaultValue={inspection.notes} />
               </Form.Item>
               <Spin spinning={isLoading}>
                  <ImageFlex>
                     {images.filter(filterImages).map((i) => (
                        <ImageItem key={i.id} data={i} onRemove={onRemove} />
                     ))}
                     {!(isXs || isMd) ? (
                        <EmptyButton onClick={() => imageUpload.current && imageUpload.current.click()}>
                           <MainUploadDiv>
                              <UploadBtn />
                           </MainUploadDiv>
                           <input
                              type="file"
                              hidden
                              ref={imageUpload}
                              onChange={onChange}
                              multiple
                              accept="image/png, image/jpeg"
                           />
                        </EmptyButton>
                     ) : (
                        <PhoneUpload onClick={() => imageUpload.current && imageUpload.current.click()}>
                           <input
                              type="file"
                              hidden
                              ref={imageUpload}
                              onChange={onChange}
                              multiple
                              accept="image/png, image/jpeg"
                           />
                        </PhoneUpload>
                     )}

                     {isMd && images.length > 2 && (
                        <Msg fontWeight={600} fontSize={14} color="#109CF1" textAlign="center" onClick={handleShowMore}>
                           {showMore ? "Show less" : "Show more"}
                        </Msg>
                     )}
                  </ImageFlex>
               </Spin>
               <Buttons flexDirection="row" margin="10px 0">
                  <Button type="primary" onClick={onInspectionSave}>
                     Save
                  </Button>
                  <Button type="primary" danger onClick={onInspectionDelete}>
                     Delete
                  </Button>
               </Buttons>
               <CustomDivider />
            </Flex>
         </FormPhotos>
      </Flex>
   )
}

export const PhotosTab = ({ job, updateInfo }) => {
   const [inspectionsBefore, setInspectionsBefore] = useState([])
   const [inspectionsAfter, setInspectionsAfter] = useState([])
   const profile = useAppSelector(getCurrentUser)

   const loadCards = async () => {
      try {
         const { data: before } = await Api.inspections.getAll({ job: job?.id, category: "B" })
         const { data: after } = await Api.inspections.getAll({ job: job?.id, category: "A" })
         setInspectionsBefore(before)
         setInspectionsAfter(after)
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   useEffect(() => {
      loadCards()
   }, [])

   const addInspection = async (type) => {
      const newInspection = {
         name: "",
         job: job.id,
         notes: "",
         category: type === "before" ? "B" : "A",
      }
      const res = await Api.inspections.create(newInspection)
      const inspection = await res.data
      track("Notes Added", {
         workorderId: job.work_order,
         vessel: job?.vessel_data?.name,
         vesselCity: job.address?.city,
         vesselState: job.address?.state,
         jobId: job.id,
         noteAddedBy: profile.id,
         noteAddedTimestamp: moment().toISOString(),
      })
      if (type === "before") {
         setInspectionsBefore([...inspectionsBefore, inspection])
      } else {
         setInspectionsAfter([...inspectionsAfter, inspection])
      }
      updateInfo()
   }

   const deleteInspection = (id, type) => {
      if (type === "before") {
         const newInspections = inspectionsBefore.filter((i) => i.id !== id)
         setInspectionsBefore(newInspections)
      } else {
         const newInspections = inspectionsAfter.filter((i) => i.id !== id)
         setInspectionsAfter(newInspections)
      }
   }

   return (
      <>
         <Flex flexDirection="column">
            <BlockTitle mb="16px">Before: Photos and notes</BlockTitle>
            {inspectionsBefore.map((i, idx) => (
               <InspectionBlock
                  key={i.id}
                  order={idx + 1}
                  inspection={i}
                  profile={profile}
                  onDelete={(id) => deleteInspection(id, "before")}
                  job={job}
               />
            ))}
            <AddButton onClick={() => addInspection("before")}>Add Note</AddButton>
         </Flex>
         <Flex flexDirection="column" mt="50px">
            <BlockTitle mb="16px">During/After: Photos and notes</BlockTitle>
            {inspectionsAfter.map((i, idx) => (
               <InspectionBlock
                  key={i.id}
                  order={idx + 1}
                  inspection={i}
                  profile={profile}
                  onDelete={(id) => deleteInspection(id, "after")}
                  job={job}
               />
            ))}
            <AddButton onClick={() => addInspection("after")}>Add Note</AddButton>
         </Flex>
      </>
   )
}
