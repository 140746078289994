import React from 'react';
import styled from "styled-components";
import {TArgCallback} from "../../types";
import {ICompanyAccessLog} from "../../api/types";
import moment from "moment/moment";

const LogsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Log = styled.div`
  border: 1px solid #EDEDED;
  padding: 6px 8px;
  gap: 4px;
  display: grid;
  grid-template-columns: 2fr 1.4fr 0.7fr;
  font-size: 11px;
  align-items: center;
  > span:first-child {
    font-size: 13px;
    color: #0496FF;
    user-select: none;
    cursor: pointer;
  }
  > span:last-child {
    margin-left: auto;
  }
`;


const getTimeDiff = (t: moment.Moment) => {
  return Math.abs(moment().diff(t, 'days'));
}

type TProps = {
  onClick: TArgCallback<number>;
  logs: ICompanyAccessLog[];
}
export const AccessLog: React.FC<TProps> = ({onClick, logs}) => {
  return <LogsWrapper>
      {logs.map((log) => {
        const daysDiff = getTimeDiff(moment(log.created));
        return <Log key={log.created}>
          <span onClick={() => onClick(log.company.id)}>{log.company.name}</span>
          <span>{(log.company.address?.city || log.company.address?.state) ? <>
            {log.company.address.city} {log.company.address.state}
          </> : null}</span>
          <span>{!daysDiff ? "Today" : `${daysDiff} day${daysDiff > 1 ? "s" : ""}`}</span>
        </Log>
      })}
  </LogsWrapper>
};