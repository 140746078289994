import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import {
  Form,
  FormInstance,
  Input,
  TimePicker,
  InputNumber,
  Button,
  Select,
} from "antd";
import { DeleteFilled, FieldTimeOutlined } from "@ant-design/icons";
import { DeleteIcon, SmallDeleteIcon } from "../../../assets/icons";
import { FormListFieldData } from "antd/es/form/FormList";
import { mapOptions, formatPriority, formatToUSD } from "../../../utils/utils";
import { EmptyButton } from "../../../ui/Button";
import PlusInCircle from "../../../assets/images/PlusInCircule.svg";

import { servicesUnitTypes, materialsUnitTypes } from "../../../constants";
import {
  IService,
  IPaginatedResponse,
  ICreateJob,
  IMaterial,
  IUpdateJob,
  EJobPriority,
  IWorkOrderWithJobs,
  ICreateJobMaterial,
} from "../../../api/types";
import { TArgCallback, TOption } from "../../../types";
import { Api } from "../../../api/api";
import Flex, { TFlexProps } from "../../../noui/Flex";
import { Msg } from "../../../ui/Text";
import { useResponsive } from "../../../utils/hooks";

import {
  AddButton,
  BlockTitle,
  CustomDivider,
  Total,
  Wrapper,
  ResponsiveTextarea,
  RemoveButton,
  BoldSpan,
  MaterialWrapper,
  WrapperServicesTechnician,
  Root,
  baseButtonMixin,
  ValueWrapper,
  AutoCompleteIcon,
  AutoCompleteContainer,
  AutoCompleteItem,
  AutoCompleteItemButton,
  InputSearch,
} from "../styled";
import { ClockIcon } from "../../../assets/images/antdIcons";
import styled, { StyledComponent, css } from "styled-components";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { useForm } from "antd/es/form/Form";

const RESPONSIVE_WIDTH_SERVICES = 1350;

const FullSizeInputNumber = styled(InputNumber)`
  width: 100%;
  input {
    width: 100%;
  }
`;
const InputStyled = styled(Input)`
  &:disabled {
    border: none;
    background: none;
    color: #000;
    padding: 0;
  }
`;
const StyledButton = styled(Button)`
  border: none;
  padding: 0px;
  width: 100%;
  text-align: right;
  svg {
    vertical-align: middle !important;
  }
`;
type TRespFlexProps = { gap?: number; wrap?: boolean };
export const ResponsiveFlex = styled(Flex)<TRespFlexProps>`
  flex-direction: row;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  flex-wrap: ${(props) => props.wrap && "wrap"};
  gap: ${(props) => (props.gap ? `${props.gap}px` : "0px")};
  //${down("lg")} {
  @media (max-width: 1350px) {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      width: 100%;
      .ant-input-number {
        width: 100%;
      }
    }
  }
` as StyledComponent<"div", any, TRespFlexProps & TFlexProps>;

type TRProps = {
  form: FormInstance<IUpdateJob>;
  remove: TArgCallback<number>;
  field: FormListFieldData;
  isTitleEditing?: boolean;
  setIsFormFieldChanged?: (value: boolean) => void;
};

type IData = {
  text: string;
  value: string;
};

const ServiceAddItem: React.FC<TPropsAdd> = ({
  addItem,
  _form,
  setIsFormFieldChanged,
}) => {
  // const [form] = Form.useForm()
  const [options, setOptions] = useState<{
    data: IService[];
    mapped: TOption[];
  }>({ data: [], mapped: [] });

  const [search, setSearch] = useState("");
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);

  const { width } = useResponsive();

  const ref = useRef<HTMLDivElement>(null);

  const isXs = useBreakpoint(down("xs"));

  useEffect(() => {
    Api.services
      .getAll({
        page: 1,
        pageSize: 10,
        active: true,
      })
      .then(({ data: d }) => {
        const { results } = d as IPaginatedResponse<IService[]>;
        const filteredData = results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  }, []);

  const handleSelect = (id: string, val: any) => {
    const value = val as TOption;
    const opt = options.data.find((el) => id === el.id.toString());

    if (opt) {
      const { price: unit_price, unit: unit_type } = opt;

      _form?.setFieldsValue({
        temp_service: { ...opt, qty: 1, unit_price, unit_type },
      });
    }
    setOptions({ data: [], mapped: [] });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleSearch = (search: string) => {
    Api.services
      .getAll({
        page: 1,
        pageSize: 5,
        search,
      })
      .then(({ data: d }) => {
        const { results } = d as IPaginatedResponse<IService[]>;
        const filteredData = results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  };

  const onTextChanged = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    setIsComponentVisible(true);
  };

  const suggestionSelected = (value: IData) => {
    setIsComponentVisible(false);
    setSearch(value.text);
    handleSelect(value.value, value);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isComponentVisible &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsComponentVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isComponentVisible]);
  //console.log("act", field.fieldKey, active)

  const isRequired = () => {
    const temp_material = _form?.getFieldValue("temp_material");
    const materials = _form?.getFieldValue("materials");
    return !(temp_material || materials?.length);
  };

  const ServiceForm = () => (
    <>
      <Form.Item
        name={["temp_service", "name"]}
        fieldKey={["name"]}
        rules={[
          { required: isRequired(), message: "Please select a service item" },
        ]}
      >
        {options.mapped && (
          <Root ref={ref}>
            <div>
              <InputSearch
                id="input"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                  onTextChanged(e);
                }}
                type={"text"}
                placeholder="Select service item"
              />
            </div>
            {options.mapped.length > 0 && isComponentVisible && (
              <AutoCompleteContainer>
                {options.mapped.map((item: any) => (
                  <AutoCompleteItem key={item.value}>
                    <AutoCompleteItemButton
                      key={item.value}
                      onClick={() => suggestionSelected(item)}
                    >
                      {item.text}
                    </AutoCompleteItemButton>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteContainer>
            )}
          </Root>
        )}
      </Form.Item>

      <Form.Item
        name={["temp_service", "qty"]}
        fieldKey={["qty"]}
        rules={[
          {
            required: isRequired(),
            type: "number",
            min: 0,
            message: "Should be positive number",
          },
        ]}
      >
        <FullSizeInputNumber
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
          placeholder="0"
        />
      </Form.Item>

      <Form.Item
        name={["temp_service", "unit_price"]}
        fieldKey={["unit_price"]}
        rules={[{ required: isRequired(), message: "Unit price is required" }]}
      >
        <FullSizeInputNumber
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
          placeholder="$0.00"
        />
      </Form.Item>

      <Form.Item
        name={["temp_service", "unit_type"]}
        fieldKey={["unit_type"]}
        rules={[{ required: isRequired(), message: "Unit type is required" }]}
      >
        <Select
          options={servicesUnitTypes}
          getPopupContainer={(trigger: any) => trigger.parentElement}
          placeholder="Per Hour"
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
        />
      </Form.Item>

      <Form.Item shouldUpdate>
        {() => (
          <Total>
            {formatToUSD(
              +_form?.getFieldValue(["temp_service", "unit_price"]) *
                +_form?.getFieldValue(["temp_service", "qty"]) || 0
            )}
          </Total>
        )}
      </Form.Item>
    </>
  );

  const handleAddItem = async () => {
    await _form?.validateFields();
    const data = await _form?.getFieldsValue();
    const _service = data?.temp_service;
    if (!_service) return;
    addItem(_service);
    if (setIsFormFieldChanged) {
      setIsFormFieldChanged(true);
    }
    // _form?.resetFields()
    // setSearch("")
  };

  return (
    <>
      {width > RESPONSIVE_WIDTH_SERVICES ? (
        <Form form={_form} style={{ marginTop: "40px" }}>
          <Wrapper>{ServiceForm()}</Wrapper>
          <Form.Item
            name={["temp_service", "description"]}
            fieldKey={["description"]}
            label="Service Description (optional) "
          >
            <ResponsiveTextarea placeholder="Type here" />
          </Form.Item>
          <AddButton onClick={handleAddItem}> Add Services Item</AddButton>
        </Form>
      ) : (
        <Form form={_form} style={{ marginTop: "20px" }}>
          <ResponsiveFlex>
            <Flex flexDirection="column">
              <Form.Item
                label="Services"
                name={["temp_service", "name"]}
                fieldKey={["name"]}
                rules={[
                  {
                    required: isRequired(),
                    message: "Please select a service item",
                  },
                ]}
              >
                {options.mapped && (
                  <Root ref={ref}>
                    <div>
                      <InputSearch
                        id="input"
                        autoComplete="off"
                        value={search}
                        placeholder="Select service item"
                        type={"text"}
                        onChange={(e) => {
                          onTextChanged(e);
                          if (setIsFormFieldChanged) {
                            setIsFormFieldChanged(true);
                          }
                        }}
                      />
                    </div>
                    {options.mapped.length > 0 && isComponentVisible && (
                      <AutoCompleteContainer>
                        {options.mapped.map((item: any) => (
                          <AutoCompleteItem key={item.value}>
                            <AutoCompleteItemButton
                              key={item.value}
                              onClick={() => suggestionSelected(item)}
                            >
                              {item.text}
                            </AutoCompleteItemButton>
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteContainer>
                    )}
                  </Root>
                )}
              </Form.Item>
              <Flex
                justifyContent="space-between"
                flexDirection="row"
                alignItems="flex-start"
              >
                <Form.Item
                  label="Qty"
                  name={["temp_service", "qty"]}
                  fieldKey={["qty"]}
                  rules={[
                    {
                      required: isRequired(),
                      type: "number",
                      min: 0,
                      message: "Should be positive number",
                    },
                  ]}
                  style={{
                    width: "calc(50% - 9px)",
                    flexDirection: isXs ? "row" : "column",
                  }}
                >
                  <FullSizeInputNumber
                    onChange={(e) => {
                      if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true);
                      }
                    }}
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Rate"
                  name={["temp_service", "unit_price"]}
                  fieldKey={["unit_price"]}
                  rules={[
                    {
                      required: isRequired(),
                      type: "number",
                      min: 0,
                      message: "Should be positive number",
                    },
                  ]}
                  style={{
                    width: "calc(50% - 9px)",
                    flexDirection: isXs ? "row" : "column",
                  }}
                >
                  <FullSizeInputNumber
                    onChange={(e) => {
                      if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true);
                      }
                    }}
                    type="number"
                    min={0}
                    placeholder="$0.00"
                  />
                </Form.Item>
              </Flex>
              <Flex
                justifyContent="space-between"
                flexDirection="row"
                alignItems="start"
              >
                <Form.Item
                  label="Unit type"
                  name={["temp_service", "unit_type"]}
                  fieldKey={["unit_type"]}
                  rules={[
                    {
                      required: isRequired(),
                      message: "Unit type is required",
                    },
                  ]}
                  style={{
                    width: "calc(50% - 9px)",
                    flexDirection: isXs ? "row" : "column",
                  }}
                >
                  <Select
                    onChange={(e) => {
                      if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true);
                      }
                    }}
                    options={servicesUnitTypes}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Per Hour"
                  />
                </Form.Item>
                <Form.Item
                  label="Total"
                  shouldUpdate
                  style={{
                    width: "calc(50% - 9px)",
                    flexDirection: isXs ? "row" : "column",
                  }}
                >
                  {() => (
                    <Total>
                      {formatToUSD(
                        +_form?.getFieldValue(["temp_service", "unit_price"]) *
                          +_form?.getFieldValue(["temp_service", "qty"]) || 0
                      )}
                    </Total>
                  )}
                </Form.Item>
              </Flex>
            </Flex>
            <Form.Item
              name={["temp_service", "description"]}
              fieldKey={["description"]}
              label="Service Description (optional)"
            >
              <ResponsiveTextarea
                onChange={(e) => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                }}
                placeholder="Type here"
              />
            </Form.Item>

            <Form.Item>
              <AddButton onClick={handleAddItem}> Add Services Item</AddButton>
            </Form.Item>
          </ResponsiveFlex>
        </Form>
      )}
    </>
  );
};

const ServiceRow: React.FC<TRProps> = ({
  field: { key, ...field },
  remove,
  form,
  isTitleEditing,
  setIsFormFieldChanged,
}) => {
  const [options, setOptions] = useState<{
    data: IService[];
    mapped: TOption[];
  }>({ data: [], mapped: [] });
  const defaultValueName = form.getFieldValue("services")[field.fieldKey];
  const [search, setSearch] = useState(
    defaultValueName ? defaultValueName.name : ""
  );
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);

  const { width } = useResponsive();

  const ref = useRef<HTMLDivElement>(null);

  const isXs = useBreakpoint(down("xs"));

  useEffect(() => {
    Api.services
      .getAll({
        page: 1,
        pageSize: 10,
        active: true,
      })
      .then(({ data: d }) => {
        const { results } = d as IPaginatedResponse<IService[]>;
        const filteredData = results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  }, []);

  const handleSelect = (id: string, val: any) => {
    const value = val as TOption;
    const opt = options.data.find((el) => id === el.id.toString());

    if (opt) {
      const {
        price: unit_price,
        unit: unit_type,
        description: description,
      } = opt;
      const { name: key } = field;
      const services = form.getFieldValue("services");
      services[key] = {
        ...services[key],
        unit_type,
        unit_price,
        description,
        qty: 1,
        name: value.label,
      };
      form.setFieldsValue({ services });
    }
    setOptions({ data: [], mapped: [] });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleSearch = (search: string) => {
    Api.services
      .getAll({
        page: 1,
        pageSize: 5,
        search,
      })
      .then(({ data: d }) => {
        const { results } = d as IPaginatedResponse<IService[]>;
        const filteredData = results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  };

  const onTextChanged = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    setIsComponentVisible(true);
  };

  const suggestionSelected = (value: IData) => {
    setIsComponentVisible(false);
    setSearch(value.text);
    handleSelect(value.value, value);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isComponentVisible &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsComponentVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isComponentVisible]);
  //console.log("act", field.fieldKey, active)

  const ServiceForm = () => (
    <>
      <Form.Item
        hidden
        {...field}
        name={[field.name, "id"]}
        fieldKey={[field.name, "id"]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, "name"]}
        fieldKey={[field.fieldKey, "name"]}
        rules={[{ required: true, message: "Please select a service item" }]}
      >
        {options.mapped && (
          <Root ref={ref}>
            <div>
              <InputSearch
                id="input"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                  onTextChanged(e);
                }}
                type={"text"}
              />
            </div>
            {options.mapped.length > 0 && isComponentVisible && (
              <AutoCompleteContainer>
                {options.mapped.map((item: any) => (
                  <AutoCompleteItem key={item.value}>
                    <AutoCompleteItemButton
                      key={item.value}
                      onClick={() => suggestionSelected(item)}
                    >
                      {item.text}
                    </AutoCompleteItemButton>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteContainer>
            )}
          </Root>
        )}
      </Form.Item>

      <Form.Item
        {...field}
        name={[field.name, "qty"]}
        fieldKey={[field.fieldKey, "qty"]}
        rules={[
          {
            required: true,
            type: "number",
            min: 0,
            message: "Should be positive number",
          },
        ]}
      >
        <FullSizeInputNumber
          onChange={() => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
        />
      </Form.Item>

      <Form.Item
        {...field}
        name={[field.name, "unit_price"]}
        fieldKey={[field.fieldKey, "unit_price"]}
        rules={[{ required: true, message: "Unit price is required" }]}
      >
        <FullSizeInputNumber
          onChange={() => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
          placeholder="$0.00"
        />
      </Form.Item>

      <Form.Item
        {...field}
        name={[field.name, "unit_type"]}
        fieldKey={[field.fieldKey, "unit_type"]}
        rules={[{ required: true, message: "Unit type is required" }]}
      >
        <Select
          onChange={() => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          options={servicesUnitTypes}
          getPopupContainer={(trigger: any) => trigger.parentElement}
        />
      </Form.Item>

      <Form.Item shouldUpdate>
        {() => (
          <Total>
            {formatToUSD(
              +form.getFieldValue(["services", field.name, "unit_price"]) *
                +form.getFieldValue(["services", field.name, "qty"]) || 0
            )}
          </Total>
        )}
      </Form.Item>
      <Form.Item {...field}>
        <StyledButton
          onClick={() => {
            remove(field.name);
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
        >
          <DeleteIcon width="14px" />
        </StyledButton>
      </Form.Item>
    </>
  );

  return (
    <>
      {width > RESPONSIVE_WIDTH_SERVICES ? (
        <>
          <Wrapper>{ServiceForm()}</Wrapper>
          <Form.Item
            name={[field.name, "description"]}
            fieldKey={[field.fieldKey, "description"]}
            label="Service Description (optional) "
          >
            <ResponsiveTextarea placeholder="Type here" />
          </Form.Item>
        </>
      ) : (
        <ResponsiveFlex>
          <Flex flexDirection="column">
            <Form.Item
              hidden
              {...field}
              name={[field.name, "id"]}
              fieldKey={[field.name, "id"]}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...field}
              label="Services"
              name={[field.name, "name"]}
              fieldKey={[field.fieldKey, "name"]}
              rules={[
                { required: true, message: "Please select a service item" },
              ]}
            >
              {options.mapped && (
                <Root ref={ref}>
                  <div>
                    <InputSearch
                      id="input"
                      autoComplete="off"
                      value={search}
                      onChange={(e) => {
                        onTextChanged(e);
                        if (setIsFormFieldChanged) {
                          setIsFormFieldChanged(true);
                        }
                      }}
                      type={"text"}
                    />
                  </div>
                  {options.mapped.length > 0 && isComponentVisible && (
                    <AutoCompleteContainer>
                      {options.mapped.map((item: any) => (
                        <AutoCompleteItem key={item.value}>
                          <AutoCompleteItemButton
                            key={item.value}
                            onClick={() => suggestionSelected(item)}
                          >
                            {item.text}
                          </AutoCompleteItemButton>
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteContainer>
                  )}
                </Root>
              )}
            </Form.Item>
            <Flex
              justifyContent="space-between"
              flexDirection="row"
              alignItems="flex-start"
            >
              <Form.Item
                {...field}
                label="Qty"
                name={[field.name, "qty"]}
                fieldKey={[field.fieldKey, "qty"]}
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Should be positive number",
                  },
                ]}
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
              >
                <FullSizeInputNumber
                  onChange={() => {
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                  type="number"
                  min={0}
                />
              </Form.Item>
              <Form.Item
                {...field}
                label="Rate"
                name={[field.name, "unit_price"]}
                fieldKey={[field.fieldKey, "unit_price"]}
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Should be positive number",
                  },
                ]}
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
              >
                <FullSizeInputNumber type="number" min={0} />
              </Form.Item>
            </Flex>
            <Flex
              justifyContent="space-between"
              flexDirection="row"
              alignItems="start"
            >
              <Form.Item
                {...field}
                label="Unit type"
                name={[field.name, "unit_type"]}
                fieldKey={[field.fieldKey, "unit_type"]}
                rules={[{ required: true, message: "Unit type is required" }]}
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
              >
                <Select
                  onChange={() => {
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                  options={servicesUnitTypes}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
              <Form.Item
                label="Total"
                shouldUpdate
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
              >
                {() => (
                  <Total>
                    {formatToUSD(
                      +form.getFieldValue([
                        "services",
                        field.name,
                        "unit_price",
                      ]) *
                        +form.getFieldValue(["services", field.name, "qty"]) ||
                        0
                    )}
                  </Total>
                )}
              </Form.Item>
            </Flex>
          </Flex>
          <Form.Item
            name={[field.name, "description"]}
            fieldKey={[field.fieldKey, "description"]}
            label="Service Description (optional)"
          >
            <ResponsiveTextarea
              onChange={() => {
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
              placeholder="Type here"
            />
          </Form.Item>

          <Form.Item {...field}>
            <RemoveButton
              type="text"
              onClick={() => {
                remove(field.name);
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
            >
              <SmallDeleteIcon />
              Remove Service Item
            </RemoveButton>
          </Form.Item>
        </ResponsiveFlex>
      )}
    </>
  );
};

const MaterialRow: React.FC<TRProps> = ({
  remove,
  form,
  field: { key, ...field },
  isTitleEditing,
  setIsFormFieldChanged,
}) => {
  const [options, setOptions] = useState<{
    data: IMaterial[];
    mapped: TOption[];
  }>({ mapped: [], data: [] });

  const [optionsSKU, setOptionsSKU] = useState<{
    data: IMaterial[];
    mapped: TOption[];
  }>({ mapped: [], data: [] });

  const defaultValueName = form.getFieldValue("materials")[field.name];
  const [search, setSearch] = useState(
    defaultValueName ? defaultValueName.name : ""
  );
  const [searchSKU, setSearchSKU] = useState(
    defaultValueName ? defaultValueName.sku : ""
  );
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);
  const [isComponentVisibleSKU, setIsComponentVisibleSKU] =
    useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const refSKU = useRef<HTMLDivElement>(null);
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));

  useEffect(() => {
    Api.materials
      .getAll({
        pageSize: 10,
        page: 1,
        active: true,
      })
      .then(({ data }) => {
        data = data as IPaginatedResponse<IMaterial[]>;
        const filteredData = data.results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearchSKU(searchSKU), 500);
    return () => clearTimeout(timeOutId);
  }, [searchSKU]);

  const handleSearch = (search: string) => {
    Api.materials
      .getAll({
        pageSize: 5,
        page: 1,
        search,
        active: true,
      })
      .then(({ data }) => {
        data = data as IPaginatedResponse<IMaterial[]>;
        const filteredData = data.results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  };
  const handleSearchSKU = (search: string) => {
    Api.materials
      .getAll({
        pageSize: 5,
        page: 1,
        search,
        active: true,
      })
      .then(({ data }) => {
        data = data as IPaginatedResponse<IMaterial[]>;

        setOptionsSKU({
          data: data.results,
          mapped: mapOptions(data.results, "sku"),
        });
      });
  };

  const handleSelect = (id: string, val: object, type: string) => {
    const value = val as TOption;
    const el =
      type === "name"
        ? options.data.find((e) => e.id.toString() === id)
        : optionsSKU.data.find((e) => e.id.toString() === id);

    if (el) {
      const materials = form.getFieldValue("materials");
      const { price, description, name, sku } = el;
      const { name: key } = field;

      if (type === "name") {
        materials[key] = {
          ...materials[key],
          unit_price: price,
          qty: 1,
          name: value.label,
          description,
          sku,
        };

        setSearchSKU(sku);
      } else {
        materials[key] = {
          ...materials[key],
          unit_price: price,
          qty: 1,
          sku: value.label,
          description,
          name,
        };

        setSearch(name);
      }

      form.setFieldsValue({ materials });
    }
    if (type === "name") {
      setOptions({ data: [], mapped: [] });
    } else {
      setOptionsSKU({ data: [], mapped: [] });
    }
  };

  const onTextChanged = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "name") {
      setSearch(value);
      setIsComponentVisible(true);
    } else {
      setSearchSKU(value);
      setIsComponentVisibleSKU(true);
    }
  };

  const suggestionSelected = (value: IData, type: string) => {
    setIsComponentVisible(false);
    if (type === "name") {
      setIsComponentVisible(false);
      setSearch(value.text);
    } else {
      setIsComponentVisibleSKU(false);
      setSearchSKU(value.text);
    }
    handleSelect(value.value, value, type);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isComponentVisible &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsComponentVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isComponentVisible]);

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isComponentVisibleSKU &&
        refSKU.current &&
        !refSKU.current.contains(e.target)
      ) {
        setIsComponentVisibleSKU(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isComponentVisibleSKU]);

  const MaterialForm = () => (
    <>
      <Form.Item
        hidden
        {...field}
        name={[field.name, "id"]}
        fieldKey={[field.name, "id"]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, "name"]}
        fieldKey={[field.fieldKey, "name"]}
        rules={[{ required: true, message: "Please select a material item" }]}
      >
        {options.mapped && (
          <Root ref={ref}>
            <div>
              <InputSearch
                id="input"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  onTextChanged(e, "name");
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                }}
                type={"text"}
              />
            </div>
            {options.mapped.length > 0 && isComponentVisible && (
              <AutoCompleteContainer>
                {options.mapped.map((item: any) => (
                  <AutoCompleteItem key={item.value}>
                    <AutoCompleteItemButton
                      key={item.value}
                      onClick={() => suggestionSelected(item, "name")}
                    >
                      {item.text}
                    </AutoCompleteItemButton>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteContainer>
            )}
          </Root>
        )}
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, "sku"]}
        fieldKey={[field.fieldKey, "sku"]}
      >
        <Root ref={refSKU}>
          <div>
            <InputSearch
              id="input"
              autoComplete="off"
              value={searchSKU}
              type={"text"}
              onChange={(e) => {
                onTextChanged(e, "sku");
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
            />
          </div>
          {optionsSKU.mapped.length > 0 && isComponentVisibleSKU && (
            <AutoCompleteContainer>
              {optionsSKU.mapped.map((item: any) => (
                <AutoCompleteItem key={item.value}>
                  <AutoCompleteItemButton
                    key={item.value}
                    onClick={() => suggestionSelected(item, "sku")}
                  >
                    {item.text}
                  </AutoCompleteItemButton>
                </AutoCompleteItem>
              ))}
            </AutoCompleteContainer>
          )}
        </Root>
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, "qty"]}
        fieldKey={[field.fieldKey, "qty"]}
        rules={[
          {
            required: true,
            type: "number",
            min: 0,
            message: "Should be positive number",
          },
        ]}
      >
        <FullSizeInputNumber
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
        />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, "unit_price"]}
        fieldKey={[field.fieldKey, "unit_price"]}
        rules={[
          {
            required: true,
            type: "number",
            min: 0,
            message: "Should be positive number",
          },
        ]}
      >
        <FullSizeInputNumber
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Total>
            {formatToUSD(
              +form.getFieldValue(["materials", field.name, "unit_price"]) *
                +form.getFieldValue(["materials", field.name, "qty"]) || 0
            )}
          </Total>
        )}
      </Form.Item>
      <Form.Item {...field}>
        <StyledButton
          onClick={() => {
            remove(field.name);
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
        >
          <DeleteIcon width="14px" />
        </StyledButton>
      </Form.Item>
    </>
  );

  return (
    <>
      {!(isXs || isMd) ? (
        <>
          <MaterialWrapper>{MaterialForm()}</MaterialWrapper>
          <Form.Item
            name={[field.name, "description"]}
            fieldKey={[field.fieldKey, "description"]}
            label="Material Description (optional)"
          >
            <ResponsiveTextarea placeholder="Type here" />
          </Form.Item>
        </>
      ) : (
        <ResponsiveFlex>
          <Flex flexDirection="column">
            <Form.Item
              hidden
              {...field}
              name={[field.name, "id"]}
              fieldKey={[field.name, "id"]}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...field}
              label="Material"
              name={[field.name, "name"]}
              fieldKey={[field.fieldKey, "name"]}
              rules={[
                { required: true, message: "Please select a material item" },
              ]}
            >
              <Root ref={refSKU}>
                <div>
                  <InputSearch
                    id="input"
                    autoComplete="off"
                    value={search}
                    type={"text"}
                    onChange={(e) => {
                      onTextChanged(e, "name");
                      if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true);
                      }
                    }}
                  />
                </div>
                {options.mapped.length > 0 && isComponentVisibleSKU && (
                  <AutoCompleteContainer>
                    {options.mapped.map((item: any) => (
                      <AutoCompleteItem key={item.value}>
                        <AutoCompleteItemButton
                          key={item.value}
                          onClick={() => suggestionSelected(item, "name")}
                        >
                          {item.text}
                        </AutoCompleteItemButton>
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteContainer>
                )}
              </Root>
            </Form.Item>
            <Flex flexDirection="row" justifyContent="space-between">
              <Form.Item
                {...field}
                name={[field.name, "sku"]}
                fieldKey={[field.fieldKey, "sku"]}
                label="SKU #"
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
              >
                <Root ref={ref}>
                  <div>
                    <InputSearch
                      id="input"
                      autoComplete="off"
                      value={searchSKU}
                      type={"text"}
                      onChange={(e) => {
                        onTextChanged(e, "sku");
                        if (setIsFormFieldChanged) {
                          setIsFormFieldChanged(true);
                        }
                      }}
                    />
                  </div>
                  {optionsSKU.mapped.length > 0 && isComponentVisible && (
                    <AutoCompleteContainer>
                      {optionsSKU.mapped.map((item: any) => (
                        <AutoCompleteItem key={item.value}>
                          <AutoCompleteItemButton
                            key={item.value}
                            onClick={() => suggestionSelected(item, "sku")}
                          >
                            {item.text}
                          </AutoCompleteItemButton>
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteContainer>
                  )}
                </Root>
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, "qty"]}
                fieldKey={[field.fieldKey, "qty"]}
                label="Qty"
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Should be positive number",
                  },
                ]}
              >
                <FullSizeInputNumber
                  onChange={(e) => {
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between">
              <Form.Item
                {...field}
                name={[field.name, "unit_price"]}
                fieldKey={[field.fieldKey, "unit_price"]}
                label="Unit price"
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Should be positive number",
                  },
                ]}
              >
                <FullSizeInputNumber
                  onChange={(e) => {
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                  type="number"
                  min={0}
                />
              </Form.Item>
              <Form.Item
                shouldUpdate
                label="Total"
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
              >
                {() => (
                  <Total>
                    {formatToUSD(
                      +form.getFieldValue([
                        "materials",
                        field.name,
                        "unit_price",
                      ]) *
                        +form.getFieldValue(["materials", field.name, "qty"]) ||
                        0
                    )}
                  </Total>
                )}
              </Form.Item>
            </Flex>
          </Flex>
          <Form.Item
            name={[field.name, "description"]}
            fieldKey={[field.fieldKey, "description"]}
            label="Material Description (optional)"
          >
            <ResponsiveTextarea
              onChange={(e) => {
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
              placeholder="Type here"
            />
          </Form.Item>
          <Form.Item {...field}>
            <RemoveButton
              type="text"
              onClick={() => {
                remove(field.name);
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
            >
              <SmallDeleteIcon />
              Remove material item
            </RemoveButton>
          </Form.Item>
        </ResponsiveFlex>
      )}
    </>
  );
};

type TProps = {
  form: FormInstance<IUpdateJob>;
  updateInfo?: () => void;
  technicianRole?: boolean;
  isRecurring?: boolean;
  isJobConfig?: boolean;
  isTitleEditing?: boolean;
  setIsFormFieldChanged?: (value: boolean) => void;
};
type TPropsAdd = {
  addItem: (data: any) => void;
  _form?: FormInstance;
  setIsFormFieldChanged?: (v: boolean) => void;
};

type TLaborProps = Omit<TProps, "isRecurring"> & {
  workOrder?: IWorkOrderWithJobs | null;
  setIsFormFieldChanged?: (value: boolean) => void;
};

const ServicesBlock: React.FC<TProps> = ({
  form,
  technicianRole,
  isRecurring,
  isTitleEditing,
  setIsFormFieldChanged,
}) => {
  const { width } = useResponsive();
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));
  const isLg = useBreakpoint(down("lg"));

  const renderUnitTypeValue = (field: number) => {
    const value = form.getFieldValue("services")[field].unit_type;

    const res = servicesUnitTypes.find((item) => item.value === value);
    if (res) {
      return <Msg>{res.label}</Msg>;
    } else {
      return null;
    }
  };

  const renderUnitPriceValue = (field: number) => {
    const value = form.getFieldValue("services")[field].unit_price;
    if (value) {
      return <Msg>${value.toFixed(2)}</Msg>;
    }
    return null;
  };

  const renderTotalValue = (field: number) => {
    const price = form.getFieldValue("services")[field].unit_price;
    const qty = form.getFieldValue("services")[field].qty;
    if (price && qty) {
      return <Msg>{formatToUSD(price * qty)}</Msg>;
    }
    return null;
  };

  const handleAdd = (data: ICreateJobMaterial) => {
    const services = form.getFieldValue("services");

    if (services) {
      form.setFieldsValue({ services: [...services, data] });
    } else {
      form.setFieldsValue({
        services: [data],
      });
    }
  };
  return (
    <div>
      <BlockTitle>Services</BlockTitle>
      {width > RESPONSIVE_WIDTH_SERVICES && (
        <>
          <Wrapper>
            <BoldSpan>Services</BoldSpan>
            <BoldSpan>Qty</BoldSpan>
            {!technicianRole && <BoldSpan>Rate</BoldSpan>}
            <BoldSpan>Unit type</BoldSpan>
            {!technicianRole && <BoldSpan>Total</BoldSpan>}

            <span />
          </Wrapper>
        </>
      )}
      {!(technicianRole || isRecurring) ? (
        <>
          <Form.List name="services">
            {(fields, { remove, add }) => (
              <>
                {fields.map((field) => (
                  <ServiceRow
                    form={form}
                    key={field.name}
                    field={field}
                    remove={remove}
                    isTitleEditing={isTitleEditing}
                    setIsFormFieldChanged={setIsFormFieldChanged}
                  />
                ))}

                {fields.length ? (
                  <Form.Item>
                    <AddButton
                      onClick={() => {
                        add();
                        if (setIsFormFieldChanged) {
                          setIsFormFieldChanged(true);
                        }
                      }}
                    >
                      Add Services Item
                    </AddButton>
                  </Form.Item>
                ) : (
                  <ServiceAddItem
                    _form={form}
                    addItem={(data) => {
                      handleAdd(data);
                      add();
                    }}
                    setIsFormFieldChanged={setIsFormFieldChanged}
                  />
                )}
              </>
            )}
          </Form.List>
        </>
      ) : (
        <Form.List name="services">
          {(fields) => (
            <>
              {fields.length > 0 ? (
                fields.map((field, index) => {
                  return (
                    <>
                      <WrapperServicesTechnician>
                        <Form.Item
                          {...field}
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                          label={isXs || isMd ? "Services" : ""}
                        >
                          {form.getFieldValue("services")[index].name}
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "qty"]}
                          fieldKey={[field.fieldKey, "qty"]}
                          label={isXs || isMd ? "Qty" : ""}
                        >
                          {form.getFieldValue("services")[index].qty}
                        </Form.Item>
                        {!technicianRole && (
                          <Form.Item
                            {...field}
                            name={[field.name, "unit_price"]}
                            fieldKey={[field.fieldKey, "unit_price"]}
                            label={isXs || isMd ? "Rate" : ""}
                          >
                            {renderUnitPriceValue(index)}
                          </Form.Item>
                        )}
                        <Form.Item
                          {...field}
                          name={[field.name, "unit_type"]}
                          fieldKey={[field.fieldKey, "unit_type"]}
                          label={isXs || isMd ? "Unit type" : ""}
                        >
                          {renderUnitTypeValue(index)}
                        </Form.Item>
                        {!technicianRole && (
                          <Form.Item
                            {...field}
                            name={[field.name, "total"]}
                            fieldKey={[field.fieldKey, "total"]}
                            label={isXs || isMd ? "Total" : ""}
                          >
                            {renderTotalValue(index)}
                          </Form.Item>
                        )}
                      </WrapperServicesTechnician>
                      <Form.Item
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        label="Service Description (optional)"
                      >
                        {form.getFieldValue("services")[index].description}
                      </Form.Item>
                    </>
                  );
                })
              ) : (
                <Msg fontSize="14px" mt="5px" display="block">
                  No services selected
                </Msg>
              )}
            </>
          )}
        </Form.List>
      )}
    </div>
  );
};

const MaterialAddItem: React.FC<TPropsAdd> = ({
  addItem,
  _form,
  setIsFormFieldChanged,
}) => {
  // const [form] = Form.useForm()
  const [options, setOptions] = useState<{
    data: IMaterial[];
    mapped: TOption[];
  }>({ mapped: [], data: [] });

  const [optionsSKU, setOptionsSKU] = useState<{
    data: IMaterial[];
    mapped: TOption[];
  }>({ mapped: [], data: [] });

  const [search, setSearch] = useState("");
  const [searchSKU, setSearchSKU] = useState("");
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);
  const [isComponentVisibleSKU, setIsComponentVisibleSKU] =
    useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const refSKU = useRef<HTMLDivElement>(null);
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));

  useEffect(() => {
    Api.materials
      .getAll({
        pageSize: 10,
        page: 1,
        active: true,
      })
      .then(({ data }) => {
        data = data as IPaginatedResponse<IMaterial[]>;
        const filteredData = data.results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearchSKU(searchSKU), 500);
    return () => clearTimeout(timeOutId);
  }, [searchSKU]);

  const handleSearch = (search: string) => {
    Api.materials
      .getAll({
        pageSize: 5,
        page: 1,
        search,
        active: true,
      })
      .then(({ data }) => {
        data = data as IPaginatedResponse<IMaterial[]>;
        const filteredData = data.results.filter((item) => item.active);
        setOptions({
          data: filteredData,
          mapped: mapOptions(filteredData, "name"),
        });
      });
  };
  const handleSearchSKU = (search: string) => {
    Api.materials
      .getAll({
        pageSize: 5,
        page: 1,
        search,
        active: true,
      })
      .then(({ data }) => {
        data = data as IPaginatedResponse<IMaterial[]>;

        setOptionsSKU({
          data: data.results,
          mapped: mapOptions(data.results, "sku"),
        });
      });
  };

  const handleSelect = (id: string, val: object, type: string) => {
    const value = val as TOption;
    const el =
      type === "name"
        ? options.data.find((e) => e.id.toString() === id)
        : optionsSKU.data.find((e) => e.id.toString() === id);
    if (el) {
      const { price, name, sku } = el;

      if (type === "name") {
        _form?.setFieldsValue({
          temp_material: { ...el, qty: 1, unit_price: price },
        });
        setSearchSKU(sku);
      } else {
        _form?.setFieldsValue({
          temp_material: { ...el, qty: 1, unit_price: price },
        });
        setSearch(name);
      }
    }
    if (type === "name") {
      setOptions({ data: [], mapped: [] });
    } else {
      setOptionsSKU({ data: [], mapped: [] });
    }
  };

  const onTextChanged = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "name") {
      setSearch(value);
      setIsComponentVisible(true);
    } else {
      setSearchSKU(value);
      setIsComponentVisibleSKU(true);
    }
  };

  const suggestionSelected = (value: IData, type: string) => {
    setIsComponentVisible(false);
    if (type === "name") {
      setIsComponentVisible(false);
      setSearch(value.text);
    } else {
      setIsComponentVisibleSKU(false);
      setSearchSKU(value.text);
    }
    handleSelect(value.value, value, type);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isComponentVisible &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsComponentVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isComponentVisible]);

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isComponentVisibleSKU &&
        refSKU.current &&
        !refSKU.current.contains(e.target)
      ) {
        setIsComponentVisibleSKU(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isComponentVisibleSKU]);

  const isRequired = () => {
    const temp_service = _form?.getFieldValue("temp_service");
    const services = _form?.getFieldValue("services");
    return !(temp_service || services?.length);
  };

  const MaterialForm = () => (
    <>
      <Form.Item
        name={["temp_material", "name"]}
        fieldKey={["name"]}
        rules={[
          { required: isRequired(), message: "Please select a material item" },
        ]}
      >
        {options.mapped && (
          <Root ref={ref}>
            <div>
              <InputSearch
                id="input"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  onTextChanged(e, "name");
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                }}
                type={"text"}
                placeholder="Select material item"
              />
            </div>
            {options.mapped.length > 0 && isComponentVisible && (
              <AutoCompleteContainer>
                {options.mapped.map((item: any) => (
                  <AutoCompleteItem key={item.value}>
                    <AutoCompleteItemButton
                      key={item.value}
                      onClick={() => suggestionSelected(item, "name")}
                    >
                      {item.text}
                    </AutoCompleteItemButton>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteContainer>
            )}
          </Root>
        )}
      </Form.Item>
      <Form.Item name={["temp_material", "sku"]} fieldKey={["sku"]}>
        <Root ref={refSKU}>
          <div>
            <InputSearch
              id="input"
              autoComplete="off"
              value={searchSKU}
              type={"text"}
              placeholder="SKU number"
              style={{ paddingRight: "5px" }}
              onChange={(e) => {
                onTextChanged(e, "sku");
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
            />
          </div>
          {optionsSKU.mapped.length > 0 && isComponentVisibleSKU && (
            <AutoCompleteContainer>
              {optionsSKU.mapped.map((item: any) => (
                <AutoCompleteItem key={item.value}>
                  <AutoCompleteItemButton
                    key={item.value}
                    onClick={() => suggestionSelected(item, "sku")}
                  >
                    {item.text}
                  </AutoCompleteItemButton>
                </AutoCompleteItem>
              ))}
            </AutoCompleteContainer>
          )}
        </Root>
      </Form.Item>
      <Form.Item
        name={["temp_material", "qty"]}
        fieldKey={["qty"]}
        rules={[
          {
            required: isRequired(),
            type: "number",
            min: 0,
            message: "Should be positive number",
          },
        ]}
      >
        <FullSizeInputNumber
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
          placeholder="0"
        />
      </Form.Item>
      <Form.Item
        name={["temp_material", "unit_price"]}
        fieldKey={["unit_price"]}
        rules={[
          {
            required: isRequired(),
            type: "number",
            min: 0,
            message: "Should be positive number",
          },
        ]}
      >
        <FullSizeInputNumber
          onChange={(e) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
          type="number"
          min={0}
          placeholder="$0.00"
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Total>
            {formatToUSD(
              +_form?.getFieldValue(["temp_material", "unit_price"]) *
                +_form?.getFieldValue(["temp_material", "qty"]) || 0
            )}
          </Total>
        )}
      </Form.Item>
    </>
  );

  const handleAddItem = async () => {
    await _form?.validateFields();
    const data = await _form?.getFieldsValue();
    const _material = data?.temp_material;
    if (!_material) return;
    addItem(_material);
    if (setIsFormFieldChanged) {
      setIsFormFieldChanged(true);
    }
    // _form?.resetFields()
    // setSearch("")
  };
  return !(isXs || isMd) ? (
    <Form form={_form} style={{ marginTop: "40px" }}>
      <MaterialWrapper>{MaterialForm()}</MaterialWrapper>
      <Form.Item
        name={["temp_material", "description"]}
        fieldKey={["description"]}
        label="Material Description (optional)"
      >
        <ResponsiveTextarea placeholder="Type here" />
      </Form.Item>
      <Form.Item>
        <AddButton onClick={handleAddItem}> Add Materials Item</AddButton>
      </Form.Item>
    </Form>
  ) : (
    <Form form={_form} style={{ marginTop: "40px" }}>
      <ResponsiveFlex>
        <Flex flexDirection="column">
          <Form.Item
            label="Material"
            name={["temp_material", "name"]}
            fieldKey={["name"]}
            rules={[
              {
                required: isRequired(),
                message: "Please select a material item",
              },
            ]}
          >
            <Root ref={ref}>
              <div>
                <InputSearch
                  id="input"
                  autoComplete="off"
                  value={search}
                  type={"text"}
                  placeholder="Select material item"
                  onChange={(e) => {
                    onTextChanged(e, "name");
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                />
              </div>
              {options.mapped.length > 0 && isComponentVisible && (
                <AutoCompleteContainer>
                  {options.mapped.map((item: any) => (
                    <AutoCompleteItem key={item.value}>
                      <AutoCompleteItemButton
                        key={item.value}
                        onClick={() => suggestionSelected(item, "name")}
                      >
                        {item.text}
                      </AutoCompleteItemButton>
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteContainer>
              )}
            </Root>
          </Form.Item>
          <Flex flexDirection="row" justifyContent="space-between">
            <Form.Item
              name={["temp_material", "sku"]}
              fieldKey={["sku"]}
              label="SKU #"
              style={{
                width: "calc(50% - 9px)",
                flexDirection: isXs ? "row" : "column",
              }}
            >
              <Root ref={refSKU}>
                <div>
                  <InputSearch
                    id="input"
                    autoComplete="off"
                    value={searchSKU}
                    type={"text"}
                    placeholder="SKU number"
                    onChange={(e) => {
                      onTextChanged(e, "sku");
                      if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true);
                      }
                    }}
                  />
                </div>
                {optionsSKU.mapped.length > 0 && isComponentVisibleSKU && (
                  <AutoCompleteContainer>
                    {optionsSKU.mapped.map((item: any) => (
                      <AutoCompleteItem key={item.value}>
                        <AutoCompleteItemButton
                          key={item.value}
                          onClick={() => suggestionSelected(item, "sku")}
                        >
                          {item.text}
                        </AutoCompleteItemButton>
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteContainer>
                )}
              </Root>
            </Form.Item>
            <Form.Item
              name={["temp_material", "qty"]}
              fieldKey={["qty"]}
              label="Qty"
              style={{
                width: "calc(50% - 9px)",
                flexDirection: isXs ? "row" : "column",
              }}
              rules={[
                {
                  required: isRequired(),
                  type: "number",
                  min: 0,
                  message: "Should be positive number",
                },
              ]}
            >
              <FullSizeInputNumber
                onChange={(e) => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                }}
                type="number"
                min={0}
                placeholder="0"
              />
            </Form.Item>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Form.Item
              name={["temp_material", "unit_price"]}
              fieldKey={["unit_price"]}
              label="Unit price"
              style={{
                width: "calc(50% - 9px)",
                flexDirection: isXs ? "row" : "column",
              }}
              rules={[
                {
                  required: isRequired(),
                  type: "number",
                  min: 0,
                  message: "Should be positive number",
                },
              ]}
            >
              <FullSizeInputNumber
                onChange={(e) => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                }}
                type="number"
                min={0}
                placeholder="$0.00"
              />
            </Form.Item>
            <Form.Item
              shouldUpdate
              label="Total"
              style={{
                width: "calc(50% - 9px)",
                flexDirection: isXs ? "row" : "column",
              }}
            >
              {() => (
                <Total>
                  {formatToUSD(
                    +_form?.getFieldValue(["unit_price"]) *
                      +_form?.getFieldValue(["qty"]) || 0
                  )}
                </Total>
              )}
            </Form.Item>
          </Flex>
        </Flex>
        <Form.Item
          name={["temp_material", "description"]}
          fieldKey={["description"]}
          label="Material Description (optional)"
        >
          <ResponsiveTextarea
            onChange={(e) => {
              if (setIsFormFieldChanged) {
                setIsFormFieldChanged(true);
              }
            }}
            placeholder="Type here"
          />
        </Form.Item>
        <Form.Item>
          <AddButton onClick={handleAddItem}> Add Materials Item</AddButton>
        </Form.Item>
      </ResponsiveFlex>
    </Form>
  );
};

const MaterialsBlock: React.FC<TProps> = ({
  form,
  technicianRole,
  isRecurring,
  isTitleEditing,
  setIsFormFieldChanged,
}) => {
  const { width } = useResponsive();
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));

  const renderUnitPriceValue = (field: number) => {
    const value = form.getFieldValue("materials")[field].unit_price;
    if (value) {
      return <Msg>${value.toFixed(2)}</Msg>;
    }
    return null;
  };

  const renderTotalValue = (field: number) => {
    const price = form.getFieldValue("materials")[field].unit_price;
    const qty = form.getFieldValue("materials")[field].qty;
    if (price && qty) {
      return <Msg>${(price * qty).toFixed(2)}</Msg>;
    }
    return null;
  };

  const handleAdd = (data: ICreateJobMaterial) => {
    const materials = form.getFieldValue("materials");

    if (materials) {
      form.setFieldsValue({ materials: [...materials, data] });
    } else {
      form.setFieldsValue({
        materials: [data],
      });
    }
  };

  return (
    <div>
      <BlockTitle>Materials</BlockTitle>
      {!(isXs || isMd) && (
        <MaterialWrapper>
          <BoldSpan>Item(s)</BoldSpan>
          <BoldSpan>SKU #</BoldSpan>
          <BoldSpan>Qty</BoldSpan>
          {!technicianRole && (
            <>
              <BoldSpan>Unit price</BoldSpan>
              <BoldSpan>Total</BoldSpan>
            </>
          )}

          <span />
        </MaterialWrapper>
      )}
      {!(technicianRole || isRecurring) ? (
        <>
          <Form.List name="materials">
            {(fields, { remove, add }) => (
              <>
                {fields.map((field) => {
                  return (
                    <MaterialRow
                      form={form}
                      field={field}
                      key={field.name}
                      remove={remove}
                      isTitleEditing={isTitleEditing}
                      setIsFormFieldChanged={setIsFormFieldChanged}
                    />
                  );
                })}
                {fields.length ? (
                  <Form.Item>
                    <AddButton
                      onClick={() => {
                        add();
                        if (setIsFormFieldChanged) {
                          setIsFormFieldChanged(true);
                        }
                      }}
                    >
                      Add Materials Item
                    </AddButton>
                  </Form.Item>
                ) : (
                  <MaterialAddItem
                    _form={form}
                    addItem={(data) => {
                      handleAdd(data);
                      add();
                    }}
                    setIsFormFieldChanged={setIsFormFieldChanged}
                  />
                )}
              </>
            )}
          </Form.List>
        </>
      ) : (
        <Form.List name="materials">
          {(fields) => (
            <>
              {fields.length > 0 ? (
                fields.map((field, index) => {
                  return !(isXs || isMd) ? (
                    <>
                      <MaterialWrapper>
                        <Form.Item
                          {...field}
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                        >
                          {form.getFieldValue("materials")[index].name}
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "sku"]}
                          fieldKey={[field.fieldKey, "sku"]}
                        >
                          {form.getFieldValue("materials")[index].sku}
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "qty"]}
                          fieldKey={[field.fieldKey, "qty"]}
                        >
                          {form.getFieldValue("materials")[index].qty}
                        </Form.Item>

                        {!technicianRole && (
                          <Form.Item
                            {...field}
                            name={[field.name, "unit_price"]}
                            fieldKey={[field.fieldKey, "unit_price"]}
                            label={isXs || isMd ? "Rate" : ""}
                          >
                            {renderUnitPriceValue(index)}
                          </Form.Item>
                        )}
                        {!technicianRole && (
                          <Form.Item
                            {...field}
                            name={[field.name, "total"]}
                            fieldKey={[field.fieldKey, "total"]}
                            label={isXs || isMd ? "Total" : ""}
                          >
                            {renderTotalValue(index)}
                          </Form.Item>
                        )}
                      </MaterialWrapper>
                      <Form.Item
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        label="Material Description (optional)"
                      >
                        {form.getFieldValue("materials")[index].description}
                      </Form.Item>
                    </>
                  ) : (
                    <ResponsiveFlex>
                      <Flex flexDirection="column">
                        <Form.Item
                          hidden
                          {...field}
                          name={[field.name, "id"]}
                          fieldKey={[field.name, "id"]}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Material"
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select a material item",
                            },
                          ]}
                        >
                          {form.getFieldValue("materials")[index].name}
                        </Form.Item>
                        <Flex
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "sku"]}
                            fieldKey={[field.fieldKey, "sku"]}
                            label="SKU #"
                            style={{
                              width: "calc(50% - 9px)",
                              flexDirection: isXs ? "row" : "column",
                            }}
                          >
                            {form.getFieldValue("materials")[index].sku}
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "qty"]}
                            fieldKey={[field.fieldKey, "qty"]}
                            label="Qty"
                            style={{
                              width: "calc(50% - 9px)",
                              flexDirection: isXs ? "row" : "column",
                            }}
                          >
                            {form.getFieldValue("materials")[index].qty}
                          </Form.Item>
                        </Flex>
                        <Flex
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          {!technicianRole && (
                            <Form.Item
                              {...field}
                              name={[field.name, "unit_price"]}
                              fieldKey={[field.fieldKey, "unit_price"]}
                              label="Unit price"
                              style={{
                                width: "calc(50% - 9px)",
                                flexDirection: isXs ? "row" : "column",
                              }}
                            >
                              {
                                form.getFieldValue("materials")[index]
                                  .unit_price
                              }
                            </Form.Item>
                          )}
                          {!technicianRole && (
                            <Form.Item
                              shouldUpdate
                              label="Total"
                              style={{
                                width: "calc(50% - 9px)",
                                flexDirection: isXs ? "row" : "column",
                              }}
                            >
                              {() => (
                                <Total>
                                  {formatToUSD(
                                    +form.getFieldValue([
                                      "materials",
                                      field.name,
                                      "unit_price",
                                    ]) *
                                      +form.getFieldValue([
                                        "materials",
                                        field.name,
                                        "qty",
                                      ]) || 0
                                  )}
                                </Total>
                              )}
                            </Form.Item>
                          )}
                        </Flex>
                      </Flex>
                      <Form.Item
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        label="Material Description (optional)"
                      >
                        {form.getFieldValue("materials")[index].description}
                      </Form.Item>
                    </ResponsiveFlex>
                  );
                })
              ) : (
                <Msg fontSize="14px" mt="5px" display="block">
                  No materials selected
                </Msg>
              )}
            </>
          )}
        </Form.List>
      )}
    </div>
  );
};

const WrapperServices = styled.div`
  & .estimated_duration {
    & .ant-picker-footer {
      display: none !important;
    }
    & .ant-picker-time-panel-column {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #fbfbff;
        border-radius: 8px;
        border: 1px solid #ededed;
      }
      &::-webkit-scrollbar-thumb {
        background: #0496ff;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #40a9ff;
      }
    }
  }
`;

const LeftIconTimePicker: any = styled(TimePicker)<any>`
  .ant-picker-input {
    flex-direction: row-reverse;
    align-items: center;
    .ant-picker-suffix {
      margin-right: 8px;
      margin-left: 0px;
    }
  }
  &.ant-picker-disabled {
    border: none;
    background: none;
    color: #000;
    padding: 0;
    & .ant-picker-suffix {
      display: none;
    }
  }
  .ant-picker-input > input[disabled] {
    color: #0496ff;
    font-weight: bold;
  }
`;

export const LaborTab: React.FC<TLaborProps> = ({
  form,
  technicianRole,
  workOrder,
  isJobConfig,
  isTitleEditing,
  setIsFormFieldChanged,
}) => {
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));
  const isLg = useBreakpoint(down("lg"));
  const isRecurring = !!workOrder?.is_recurring && !isJobConfig;

  const handleDurationSelect = (value: any) => {
    form.setFieldsValue({ estimated_duration: value });
  };

  //console.log('form.getFieldValue("priority")', form.getFieldValue("priority"))
  return (
    <WrapperServices>
      <ServicesBlock
        form={form}
        technicianRole={technicianRole}
        isRecurring={isRecurring}
        isTitleEditing={isTitleEditing}
        setIsFormFieldChanged={setIsFormFieldChanged}
      />
      <CustomDivider style={{ margin: "20px 0" }} />
      <MaterialsBlock
        form={form}
        technicianRole={technicianRole}
        isRecurring={isRecurring}
        isTitleEditing={isTitleEditing}
        setIsFormFieldChanged={setIsFormFieldChanged}
      />
      <CustomDivider />
      <ResponsiveFlex justifyContent="space-between" mt="24px" gap={24}>
        <ResponsiveFlex>
          <Msg color="#0F497B" fontWeight={800} fontSize={15} mr="16px">
            ESTIMATED DURATION:
          </Msg>
          <Form.Item
            name="estimated_duration"
            className="estimated_duration"
            style={{ marginBottom: 0 }}
          >
            <LeftIconTimePicker
              showNow={false}
              showSecond={false}
              style={{ width: "100%" }}
              format={"HH [hours] mm [mins]"}
              suffixIcon={isLg ? null : <ClockIcon />}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              placeholder={
                isRecurring
                  ? ""
                  : isLg
                  ? "Enter time estimate"
                  : "Select a time"
              }
              disabled={technicianRole || isRecurring}
              minuteStep={15}
              onSelect={(value: any) => handleDurationSelect(value)}
              onChange={() => {
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
            />
          </Form.Item>
        </ResponsiveFlex>
        <ResponsiveFlex>
          <Msg color="#0F497B" fontWeight={800} fontSize={15} mr="16px">
            PRIORITY:
          </Msg>
          <Form.Item
            name="priority"
            style={{ marginBottom: 0, width: isLg ? "100%" : "100px" }}
          >
            {!(technicianRole || isRecurring) ? (
              <Select
                placeholder="Select"
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={() => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                }}
              >
                <Select.Option value={EJobPriority.Low}>Low</Select.Option>
                <Select.Option value={EJobPriority.Medium}>
                  Medium
                </Select.Option>
                <Select.Option value={EJobPriority.High}>High</Select.Option>
                <Select.Option value={EJobPriority.Urgent}>
                  Urgent
                </Select.Option>
              </Select>
            ) : (
              <Msg color="#ff8283" fontWeight="bold">
                {form.getFieldValue("priority")}
              </Msg>
            )}
          </Form.Item>
        </ResponsiveFlex>
      </ResponsiveFlex>
    </WrapperServices>
  );
};
