import { notification } from "antd";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { authService } from "../utils/authService";
import { IUserProfile, IAddress } from "./types";
import {clearPhone, phoneFormatter} from "../utils/utils";
// axios.defaults.headers.common['TZ'] = new Date().getTimezoneOffset();

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BaseUrl: string =
  process.env.REACT_APP_ENV === "stage"
    ? "https://api.stage.dockworks.co"
    : process.env.REACT_APP_ENV === "demo"
    ? "https://api.demo.dockworks.co"
    : process.env.REACT_APP_ENV === "prod"
    ? "https://api.dockworks.co"
    : "https://api.dev.dockworks.co";

const APIUrl: string = `${BaseUrl}/api`;
export const WSUrl: string = BaseUrl.replace("https", "wss") + "/ws";
const INVALID_TOKEN: string = "token_not_valid";
const AUTH_ERRROR: string = "Authentication credentials were not provided.";
const USER_NOT_FOUND: string = "user_not_found";
const ACCESS_DENIED: string = "You are not allowed to perform this action.";

export const GoogleMapsAPIKey = "AIzaSyBLveQkJB7gL87u-3IhQ5O0gugQuUP1C7w";

export const StripeAPIKey =
  process.env.REACT_APP_ENV === "stage"
    ? "pk_test_51JUK22Jtf7CL3QnQfYBG1wNsnHAkjV9faMBAtCV4s5hOVVvanpOXb4dIijOlKGWCu3idQ0T0fkZ6LN5S0bcTKOpW00alEQRx2o"
    : process.env.REACT_APP_ENV === "demo"
    ? "pk_live_51JUK22Jtf7CL3QnQKvHxBIQY3SrvAzy1bzQ17zUeTJ0Eg5dJ7wAkZn0I2pM9aOuwPmYXeDxfgrE1hSjLcBwKFgFN00YRaNHz16"
    : process.env.REACT_APP_ENV === "prod"
    ? "pk_live_51JUK22Jtf7CL3QnQKvHxBIQY3SrvAzy1bzQ17zUeTJ0Eg5dJ7wAkZn0I2pM9aOuwPmYXeDxfgrE1hSjLcBwKFgFN00YRaNHz16"
    : "pk_test_51JUK22Jtf7CL3QnQfYBG1wNsnHAkjV9faMBAtCV4s5hOVVvanpOXb4dIijOlKGWCu3idQ0T0fkZ6LN5S0bcTKOpW00alEQRx2o";

export const StripeAccountId = "acct_1JUK22Jtf7CL3QnQ";

export const PayEnginePK =
  process.env.REACT_APP_ENV === "stage"
    ? "pk_test_SVEeYB6YvVoaSRTDS5o4GjSInvuR5whV"
    : process.env.REACT_APP_ENV === "demo"
    ? "pk_test_sBpi9Ids1akmU8kjYdEOjDil6aA5SLGq"
    : process.env.REACT_APP_ENV === "prod"
    ? "pk_prod_D2Xj3grmohkp5RTYpyDRQqIZtkX9ka0W"
    : "pk_test_BQprG3kPVrj5bgWmPl4zz3dflVwLXGfT";

export const instance: AxiosInstance = axios.create({ baseURL: APIUrl });
export const customerInstance: AxiosInstance = axios.create({
  baseURL: APIUrl,
});
instance.interceptors.request.use((value) => {
  const token = authService.getLocalToken();
  value.headers["timezone"] = userTimezone;
  if (token) {
    value.headers.Authorization = `Bearer ${authService.getLocalToken()}`;
  }
  return value;
});

const PEBaseURL =
  process.env.REACT_APP_ENV === "prod"
    ? "https://console.payengine.co/api"
    : "https://console.payengine.dev/api";

export const instancePE: AxiosInstance = axios.create({ baseURL: PEBaseURL });
instancePE.interceptors.request.use((value) => {
  value.headers.authorization = `Signature key="${PayEnginePK}",algorithm="hmac-sha256",identifier="secure-fields",signature=""`;
  return value;
});
type Data = {
  [key: string]: any;
}
const checkAndFormatPhones = (data: Data|Data[]): Data|Data[] => {
  if (!data) {
    return data;
  }
  if (Array.isArray(data)) {
    data = data.map(el =>
        el.hasOwnProperty('phone') ? {...el, phone: phoneFormatter(el.phone)} : el
    )
  } else if (data.hasOwnProperty('phone')) {
    data.phone = phoneFormatter(data.phone);
  }
  return data;
}

const REFRESH_URL = "/token/refresh/";
instance.interceptors.request.use(
  request => {
    if (request.data) {
      if (request.data.hasOwnProperty('phone')) {
        request.data.phone = clearPhone(request.data.phone);
      } else if (request.data.hasOwnProperty('phones')) {
        request.data.phones = request.data.phones?.map((el: any) => ({...el, phone: clearPhone(el.phone)}))
      }
    }
    return request;
  }
)
instance.interceptors.response.use(
  (response) => {
    if (response.headers['content-type'] === 'application/json') {
      if (response.data) {
        response.data = checkAndFormatPhones(response.data);
        if (response.data.hasOwnProperty('phones')) {
          response.data.phones = checkAndFormatPhones(response.data.phones);
        } else if (response.data.hasOwnProperty('customer')) {
          if (!response.data.customer || typeof response.data.customer !== "object") return response;
          response.data.customer.phones = checkAndFormatPhones(response.data.customer.phones);
        }
      }
    }
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      authService.getRefreshToken() &&
      !originalRequest._retry &&
      originalRequest.url !== REFRESH_URL
    ) {
      return refreshToken(originalRequest);
    } else if (
      error.response?.status === 403 &&
      (error.response.data?.detail === AUTH_ERRROR ||
        error.response.data?.code === INVALID_TOKEN ||
         error.response.data?.code === USER_NOT_FOUND) &&
      (authService.getRefreshToken() || authService.getLocalToken()) &&
      !originalRequest._retry &&
      originalRequest.url !== REFRESH_URL
    ) {
      return refreshToken(originalRequest);
    } else if (
      error.response?.status === 403 &&
      error.response.data?.detail === ACCESS_DENIED
    ) {
      notification.error({ message: ACCESS_DENIED });
    } else if (
      error.response?.status === 404 &&
      !window.location.href.includes("textMessaging")
    ) {
      window.location.href = "/error";
    }
    return Promise.reject(error);
  }
);

const checkIsCustomerView = (href: string) => {
  if (
    href.includes("view-schedule") ||
    href.includes("view-subscription") ||
    href.includes("work-statement")
  ) {
    return true;
  }
  return false;
};

const refreshToken = (originalRequest: AxiosResponse & { _retry: boolean }) => {
  const loginURL = "/login";
  originalRequest._retry = true;
  delete originalRequest.headers.Authorization;
  return authService
    .refresh()
    .then(() => {
      originalRequest.headers.Authorization = `Bearer ${authService.getLocalToken()}`;
      return instance(originalRequest);
    })
    .catch(() => {
      if (!checkIsCustomerView(window.location.href)) {
        window.location.href = loginURL;
        authService.logout();
      }
    });
};

export const weatherApiKey = "2483619ee7bc238b8c124ddbd508d99f";
export const WEATHER_API_URL =
  "https://swd.weatherflow.com/swd/rest/better_forecast?api_key=c1826fdf-5bf4-4f33-9f6d-ede43643e800&lat=29.12925&lon=-81.04066&units_temp=f&units_wind=mph&units_pressure=mb&units_distance=mi&units_precip=in&units_other=imperial&units_direction=mph";

export const formatWeatherRequest = (user: IUserProfile | null) =>
  `https://swd.weatherflow.com/swd/rest/better_forecast?api_key=c1826fdf-5bf4-4f33-9f6d-ede43643e800&lat=${
    user?.company?.address?.lat ?? 29.12925
  }&lon=${
    user?.company?.address?.lng ?? -81.04066
  }&units_temp=f&units_wind=mph&units_pressure=mb&units_distance=mi&units_precip=in&units_other=imperial&units_direction=mph`;

export const formatWeatherRequestDashboard = (user: IUserProfile | null) =>
  `https://swd.weatherflow.com/swd/rest/better_forecast?api_key=c1826fdf-5bf4-4f33-9f6d-ede43643e800&lat=${
    user?.dashboard_address?.lat ?? 29.12925
  }&lon=${
    user?.dashboard_address?.lng ?? -81.04066
  }&units_temp=f&units_wind=mph&units_pressure=mb&units_distance=mi&units_precip=in&units_other=imperial&units_direction=mph`;

export const formatWeatherRequestVessel = (address: IAddress | null) =>
  `https://swd.weatherflow.com/swd/rest/better_forecast?api_key=c1826fdf-5bf4-4f33-9f6d-ede43643e800&lat=${
    address?.lat ?? 29.12925
  }&lon=${
    address?.lng ?? -81.04066
  }&units_temp=f&units_wind=mph&units_pressure=mb&units_distance=mi&units_precip=in&units_other=imperial&units_direction=mph`;
