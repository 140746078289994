import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Dropdown,
  Empty,
  Input,
  InputNumber,
  Menu,
  notification,
  Pagination,
  Popconfirm,
  Slider,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import { Api } from "../../api/api";
import { Msg } from "../../ui/Text";
import Flex from "../../noui/Flex";
import { useLoading, usePagination } from "../../utils/hooks";
import {
  EWorkOrderStatus,
  IJob,
  IStatsManagement,
  IWorkOrder,
} from "../../api/types";
import { downloadFile, filterColumns, getOrdering } from "../../utils/utils";
import Box from "../../noui/Box";
import { Columns, JobProgress } from "../../constants";
import { ExportBtn, TableSelect } from "../../ui/TableUtils";
import { EmptyButton } from "../../ui/Button";
import { MessagesModal } from "../../modals/Messages/MessagesModal";
import { Link, useHistory } from "react-router-dom";
import routes from "../../routes/routes";
import { down } from "styled-breakpoints";
import { ColumnsSelector } from "../../modals/ColumnsSelector/ColumnsSelector";
import { ColumnsButton } from "../../components/ColumnsButton";
import { useSelector } from "react-redux";
import { getColumns } from "../../config/reducers/columns/selectors";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { TableCard } from "../../components/TableCard";
import moment from "moment";
import {
  DownOutlined,
  EllipsisOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  MWOClose,
  MWODelete,
  MWOInvoice,
  MWOMessage,
  MWOReschedule,
  MWOSchedule,
} from "../../assets/icons";

const StatusBlock = styled(Flex)`
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: visible;
  margin: 2px 2px;
  min-width: calc(33% - 16px);
  @media (max-width: 1500px) and (min-width: 1200px) {
    flex-direction: column;
  }
`;

const StatusBlockInner = styled(Flex)`
  flex-direction: column;
  @media (max-width: 1500px) and (min-width: 1200px) {
    flex-direction: row;
  }
`;
const TotalMessage = styled(Msg)`
  font-size: 32px;
  @media (max-width: 1300px) {
    font-size: 24px;
  }
`;

const StatusContainer = styled(Flex)`
  overflow-x: auto;
  gap: 16px;
  flex-grow: 1;
  padding-bottom: 5px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #fbfbff;
    border-radius: 8px;
    border: 1px solid #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background: #0496ff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #40a9ff;
  }
  ${down("lg")} {
    flex-direction: column;
    width: 100%;
  }
`;

const ShowAllBtn = styled(EmptyButton)`
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 22px 8px;
  ${down("lg")} {
    margin-bottom: 16px;
    padding: 12px 8px;
    font-size: 18px;
    width: calc(100% - 16px);
  }
`;

export const OptionButton = styled(EmptyButton)`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  height: 34px;
  margin-right: 12px;
  padding: 0 6px;
  ${down("xs")} {
    margin-right: 10px;
  }
`;

export const MenuItem = styled(Button)`
  border: none;
  padding: 0;
  height: auto;
  margin-bottom: 8px;
  > div > span {
    color: #494949;
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
  }
  &:disabled,
  &:hover:disabled {
    background-color: #ffffff;
    > div > span {
      color: #8b8b8b;
    }
  }
`;

const ActionButton = styled(EmptyButton)<{
  background?: string;
  border?: boolean;
}>`
  border-radius: 6px;
  min-width: 120px;
  background-color: ${(props) => props.background || "#fff"};
  border: ${(props) => props.border && "1px solid rgba(0, 0, 0, 0.15)"};
  width: 100%;
  height: 34px;
  text-align: center;
  ${down("xs")} {
    min-width: 100px;
  }
`;

const StyledMenu = styled(Menu)`
  padding: 15px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;

const LinkWO = styled(Link)`
  text-decoration: underline;
  color: #0496ff;
  font-size: 13px;
  font-weight: 700;
`;

const JobFilter = styled(TableSelect)`
  width: 150px;
  margin-left: 0;
  color: #494949;
  font-weight: 700;
  font-size: 13px;
`;

const FiltersWrapper = styled(Flex)`
  flex-wrap: wrap;
  > div {
    margin-top: 8px;
    margin-right: 16px;
  }
  > div:last-child {
    margin-right: 0;
  }
`;

const TotalButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    color: #494949;
    font-weight: 700;
    font-size: 13px;
  }
  > div {
    align-items: center;
    background: #ededed;
    border-radius: 4px;
    padding: 4px;
    margin: 0 12px;
    > button {
      border: none;
      padding: 0;
      margin: 0 0 0 4px;
      height: 18px;
      background: none;
    }
    > span {
      color: #494949;
      font-weight: 600;
      font-size: 11px;
    }
  }
`;

const RangeMenu = styled(Menu)`
  padding: 15px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  width: 270px;
  > div {
    width: 100%;
    .ant-slider {
      width: 100%;
    }
  }
`;

type Object = {
  [k: string]: string;
};

const statusPalette: Object = {
  T: "#FB585A",
  C: "#43CA7D",
};

type UnsafeObject = {
  [k: string]: string | null;
};

const defaultStatuses: UnsafeObject = {
  Overall: null,
  Work: "S",
  Completed: "C",
};

const renderStatus = (status: string | null, color: boolean) => (
  <Msg color={color && status !== null ? statusPalette[status] : "#202020"}>
    {Object.keys(JobProgress)
      .find((key) => JobProgress[key] === status)
      ?.split(/(?=[A-Z])/)
      .join(" ")}
  </Msg>
);

const loadJobs = async (workOrder: number) => {
  const response = await Api.jobs.getAll({ work_order: workOrder });
  return response.data as IJob[];
};

const redirectToSchedule = async (workOrder: number, history: any) => {
  const jobs = await loadJobs(workOrder);
  let anyJobUnscheduled = false;
  jobs.forEach((job) => {
    if (job.schedules.length === 0) {
      anyJobUnscheduled = true;
      history.push(
        routes.workOrder.UpdateWorkOrder.replace(":id", String(workOrder)) +
          `?job=${job.id}&tab=2`
      );
    }
  });
  if (!anyJobUnscheduled) {
    notification.info({
      message: "All jobs in workorder were scheduled",
    });
  }
};

const renderActionButton = (
  jobStatus: string,
  workOrder: IWorkOrder,
  history: any
) => {
  switch (jobStatus) {
    case "t":
      return (
        <ActionButton
          color="#fff"
          background={statusPalette.T}
          onClick={() => {
            redirectToSchedule(workOrder.id, history);
          }}
        >
          Schedule Job
        </ActionButton>
      );
    case "c":
      return (
        <ActionButton color="#fff" background={statusPalette.C}>
          Send Invoice
        </ActionButton>
      );
    default:
      return <ActionButton border>Reschedule Job</ActionButton>;
  }
};

const JobFilterOptions = [
  { label: "All Projects", value: "" },
  { label: "Unscheduled", value: "U" },
  { label: "Scheduled", value: "S" },
  { label: "In-Progress", value: "P" },
  { label: "Completed", value: "C" },
];

const ProjectTypesOptions = [
  { label: "All Types", value: "" },
  { label: "Work Order", value: "false" },
  { label: "Service Plan", value: "true" },
];

const Jobs: React.FC = () => {
  const {
    data,
    loading,
    onPromise,
    requestPagination,
    pagination,
    onChange,
    sorter,
  } = usePagination<IWorkOrder>();
  const [stats, setStats] = useState<IStatsManagement[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [columnsVisible, setColumnsVisible] = useState<boolean>(false);
  const history = useHistory();
  const selectedColumns = useSelector(getColumns(Columns.WorkOrders));
  const [customerForMessage, setCustomerForMessage] = useState<
    number | undefined
  >();
  const isMd = useBreakpoint(down("md"));
  const isLg = useBreakpoint(down("lg"));
  const [saving, onSave] = useLoading();
  const [selectedOption, setSelectedOption] = useState("");
  const [projectTypeOption, setProjectTypeOption] = useState("");
  const [search, setSearch] = useState("");
  const [dropdown, setDropdown] = useState<number | null>(null);
  const [total, setTotal] = useState<[number, number] | null>(null);
  const [limits, setLimits] = useState<[number, number]>([0, 150000]);
  const [rangeValue, setRangeValue] = useState<[number, number]>([0, 150000]);
  const [rangeDropdown, setRangeDropdown] = useState(false);
  const handleSendMessage = (job: IWorkOrder) => {
    if (job?.customer?.id) {
      setCustomerForMessage(job.customer.id);
    }
    setVisible(true);
    // TODO: Pass job's data to message modal
  };

  const handleViewOrder = (workOrder: IWorkOrder) => () => {
    history.push(
      routes.workOrder.UpdateWorkOrder.replace(":id", String(workOrder.id))
    );
  };

  const getTotal = () => {
    if (total) {
      return {
        total_min: String(total[0]),
        total_max: String(total[1]),
      };
    }
    return {};
  };

  const clearTotal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setTotal(null);
    loadLimits();
  };

  const updateTotal = () => {
    setRangeDropdown(false);
    setTotal(rangeValue);
  };

  const loadWorkOrders = () => {
    onPromise(
      Api.workOrders.getAll({
        status: selectedOption,
        is_recurring: projectTypeOption,
        search: search,
        ...getTotal(),
        ...requestPagination,
        ...getOrdering(sorter),
      })
    );
  };

  const loadStats = async () => {
    const res = await Api.stats.getManagementStats();
    const managementStats = (await res.data) as IStatsManagement[];
    setStats(managementStats);
  };

  const loadLimits = async () => {
    const { data } = await Api.workOrders.getTotalLimits();
    if (!data) return;
    const { total_min, total_max } = data;
    if (!!total_min || !!total_max) {
      setLimits([Math.floor(total_min), Math.ceil(total_max)]);
      setRangeValue([Math.floor(total_min), Math.ceil(total_max)]);
    }
  };

  const handleExport = async () => {
    const { data } = await onSave(Api.workOrders.getAll({ format: "xlsx" }));
    downloadFile(data, `WorkOrders ${moment().format("LLL")}.xlsx`);
  };

  const handleFilterChange = (value: any) => {
    setSelectedOption(value);
  };

  const handleProjectTypeChange = (value: any) => {
    setProjectTypeOption(value);
  };

  const formatStatus = (status: EWorkOrderStatus) => {
    switch (status) {
      case EWorkOrderStatus.Unscheduled:
        return "Unscheduled";
      case EWorkOrderStatus.Scheduled:
        return "Scheduled";
      case EWorkOrderStatus.Declined:
        return "Declined";
      case EWorkOrderStatus.InProgress:
        return "In-Progress";
      case EWorkOrderStatus.Completed:
        return "Completed";
      default:
        return "";
    }
  };

  const formatProjectType = (workOrder: IWorkOrder) => {
    if (workOrder.is_recurring) {
      return "Service Plan";
    }
    return "Work Order";
  };

  const deleteWO = async (workOder: IWorkOrder) => {
    await Api.workOrders.delete(workOder.id);
    loadWorkOrders();
  };

  useEffect(() => {
    if (!total) {
      setRangeValue([0, 5000]);
      return;
    }
    setRangeValue(total);
  }, [total]);

  const columns: ColumnsType<IWorkOrder> = useMemo(() => {
    return [
      {
        title: "#",
        dataIndex: "order_number",
        key: "order_number",
        sorter: true,
        render: (_, record) => {
          return (
            <LinkWO
              to={routes.workOrder.UpdateWorkOrder.replace(
                ":id",
                String(record.id)
              )}
            >
              #{record.order_number}
            </LinkWO>
          );
        },
      },
      {
        title: "Project Name",
        dataIndex: "title",
        key: "title",
        sorter: true,
        render: (_, record) => {
          return (
            <LinkWO
              to={routes.workOrder.UpdateWorkOrder.replace(
                ":id",
                String(record.id)
              )}
            >
              {record.title}
            </LinkWO>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: true,
        ellipsis: true,
        render: (_, record) => formatStatus(record.status),
      },
      {
        title: "Project Type",
        dataIndex: "is_recurring",
        key: "is_recurring",
        sorter: true,
        ellipsis: true,
        render: (_, record) => formatProjectType(record),
      },
      {
        title: "Vessel",
        dataIndex: "vessel",
        key: "vessel",
        sorter: true,
        ellipsis: true,
        render: (_, record) => record?.vessel?.name,
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        sorter: true,
        ellipsis: true,
        render: (_, record) => record.customer.full_name,
      },
      {
        title: "Project Total",
        dataIndex: "total",
        key: "total",
        sorter: true,
        render: (_, record) => `$${record.total.toFixed(2)}`,
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => (
          <Flex justifyContent="center">
            <Dropdown
              trigger={["click"]}
              onVisibleChange={() => {
                if (dropdown) {
                  setDropdown(null);
                }
              }}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              overlay={
                <StyledMenu>
                  <Flex flexDirection="column" alignItems="flex-start">
                    <MenuItem
                      onClick={() => {
                        history.push(
                          routes.workOrder.UpdateWorkOrder.replace(
                            ":id",
                            String(record.id) + "?action=message"
                          )
                        );
                        setDropdown(null);
                      }}
                    >
                      <Flex alignItems="center">
                        <MWOMessage />
                        <span>Message</span>
                      </Flex>
                    </MenuItem>
                    {record.status === EWorkOrderStatus.Unscheduled && (
                      <MenuItem
                        onClick={() => {
                          history.push(
                            routes.workOrder.UpdateWorkOrder.replace(
                              ":id",
                              String(record.id) + "?action=schedule"
                            )
                          );
                          setDropdown(null);
                        }}
                      >
                        <Flex alignItems="center">
                          <MWOSchedule />
                          <span>Schedule</span>
                        </Flex>
                      </MenuItem>
                    )}
                    {record.status === EWorkOrderStatus.Scheduled && (
                      <MenuItem
                        onClick={() => {
                          history.push(
                            routes.workOrder.UpdateWorkOrder.replace(
                              ":id",
                              String(record.id) + "?action=schedule"
                            )
                          );
                          setDropdown(null);
                        }}
                      >
                        <Flex alignItems="center">
                          <MWOReschedule />
                          <span>Reschedule Job</span>
                        </Flex>
                      </MenuItem>
                    )}
                    {record.status === EWorkOrderStatus.Completed && (
                      <MenuItem
                        onClick={() => {
                          history.push(
                            routes.workOrder.UpdateWorkOrder.replace(
                              ":id",
                              String(record.id) + "?action=invoice"
                            )
                          );
                          setDropdown(null);
                        }}
                      >
                        <Flex alignItems="center">
                          <MWOInvoice />
                          <span>Send Invoice</span>
                        </Flex>
                      </MenuItem>
                    )}
                    <Popconfirm
                      title={`Are you sure to delete ${record.title}?`}
                      onConfirm={() => deleteWO(record)}
                      onCancel={() => setDropdown(null)}
                    >
                      <MenuItem>
                        <Flex alignItems="center">
                          <MWODelete />
                          <span>Delete</span>
                        </Flex>
                      </MenuItem>
                    </Popconfirm>
                  </Flex>
                </StyledMenu>
              }
              visible={dropdown === record.id}
              placement="bottomRight"
              arrow
            >
              <EmptyButton
                onClick={() => {
                  setDropdown(record.id);
                }}
              >
                <EllipsisOutlined
                  style={{ fontSize: "18px", marginTop: "5px" }}
                />
              </EmptyButton>
            </Dropdown>
          </Flex>
        ),
      },
    ];
  }, [dropdown]);

  const formattedColumns = useMemo(
    () =>
      columns.map((c) =>
        c.key === "actions" ? { ...c, title: "Actions" } : c
      ),
    [columns]
  );

  useEffect(() => {
    loadWorkOrders();
    loadStats();
  }, [
    requestPagination,
    sorter,
    selectedOption,
    search,
    projectTypeOption,
    total,
    onPromise,
  ]);

  useEffect(() => {
    loadLimits();
  }, []);

  return (
    <>
      <Helmet>
        <title>Management - DockWorks Pro</title>
      </Helmet>
      {!!stats.length && (
        <Flex
          px="12px"
          alignItems="center"
          flexDirection={isLg ? "column-reverse" : "row"}
        >
          <StatusContainer mr={isLg ? "0px" : "16px"}>
            {Object.entries(showAll ? JobProgress : defaultStatuses)
              .filter((k) => k[0] !== "Finished")
              .map((k) => (
                <StatusBlock
                  key={k[1]}
                  justifyContent="space-between"
                  py="8px"
                  px="12px"
                >
                  <StatusBlockInner flexDirection="column">
                    {renderStatus(k[1], false)}
                    <div style={{ marginRight: "10px" }}></div>
                    <Msg fontWeight={700}>
                      <Msg>{stats.find((s) => s.key === k[1])?.jobs}</Msg> job
                    </Msg>
                  </StatusBlockInner>
                  <Flex alignItems="flex-end">
                    <Msg>Total: </Msg>
                    <TotalMessage fontWeight={700} ml="8px" mb="-5px">{`$${(
                      stats.find((s) => s.key === k[1])?.total ?? 0
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TotalMessage>
                  </Flex>
                </StatusBlock>
              ))}
          </StatusContainer>
          <ShowAllBtn onClick={() => setShowAll(!showAll)}>
            Show {showAll ? "less" : "all"} statuses
          </ShowAllBtn>
        </Flex>
      )}
      <Flex flexDirection="column" px="20px">
        <Flex flexDirection="column" maxWidth="500px" marginTop="8px">
          <Msg fontSize="15px" color="#494949">
            You can search work order or service plan by number or by project
            name
          </Msg>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mt="8px">
          <FiltersWrapper>
            <ColumnsButton onClick={() => setColumnsVisible(true)} />
            <JobFilter
              options={JobFilterOptions}
              defaultValue={selectedOption}
              onChange={handleFilterChange}
              getPopupContainer={(trigger: any) => trigger.parentElement}
            />
            <JobFilter
              options={ProjectTypesOptions}
              defaultValue={projectTypeOption}
              onChange={handleProjectTypeChange}
              getPopupContainer={(trigger: any) => trigger.parentElement}
            />
            <Flex>
              <Dropdown
                trigger={["click"]}
                onVisibleChange={() => {
                  if (rangeDropdown) {
                    setRangeDropdown(false);
                  }
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
                overlay={
                  <RangeMenu>
                    <Flex flexDirection="column" alignItems="flex-start">
                      <Msg color="#202020" fontWeight={700} fontSize="13px">
                        Price Range
                      </Msg>
                      <Slider
                        range
                        min={limits[0]}
                        max={limits[1]}
                        value={rangeValue}
                        onChange={(value) => setRangeValue(value)}
                      />
                      <Flex justifyContent="space-between" width="100%">
                        <InputNumber
                          value={rangeValue[0]}
                          step={1}
                          onChange={(value) =>
                            setRangeValue([value, rangeValue[1]])
                          }
                          precision={2}
                          min={limits[0]}
                          max={rangeValue[1] - 1}
                        />
                        -
                        <InputNumber
                          value={rangeValue[1]}
                          step={1}
                          onChange={(value) =>
                            setRangeValue([rangeValue[0], value])
                          }
                          precision={2}
                          min={rangeValue[0] + 1}
                          max={limits[1]}
                        />
                      </Flex>
                      <Button
                        type="primary"
                        onClick={updateTotal}
                        style={{ margin: "16px 0 0 auto" }}
                      >
                        Apply
                      </Button>
                    </Flex>
                  </RangeMenu>
                }
                visible={rangeDropdown}
                placement="bottomRight"
                arrow
              >
                <TotalButton onClick={() => setRangeDropdown(true)}>
                  <span>Project Total</span>
                  {total && (
                    <Flex>
                      <span>{`$${total[0]} - $${total[1]}`}</span>
                      <Button onClick={clearTotal}>
                        <MWOClose />
                      </Button>
                    </Flex>
                  )}
                  <DownOutlined />
                </TotalButton>
              </Dropdown>
            </Flex>
          </FiltersWrapper>
          {!isLg && (
            <ExportBtn loading={saving} onClick={handleExport}>
              Export
            </ExportBtn>
          )}
        </Flex>
      </Flex>
      <Box px={3}>
        {!isLg ? (
          <Table
            className="contained work-table"
            dataSource={data}
            loading={loading}
            columns={filterColumns(columns, selectedColumns)}
            pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
            onChange={onChange}
            locale={{ emptyText: "No timesheets results were found" }}
          />
        ) : (
          <Flex flexWrap="wrap" marginTop="20px">
            {!!data.length ? (
              data.map((d) => (
                <TableCard key={d.id}>
                  {filterColumns(columns, selectedColumns).map((c, i, a) =>
                    c.key !== "actions" ? (
                      <Flex key={i} justifyContent="space-between">
                        <Msg fontWeight={700}>{c.title}</Msg>
                        <Msg style={{ textAlign: "end" }}>
                          {c.render && c.render(d, d, i)}
                        </Msg>
                      </Flex>
                    ) : (
                      <Flex justifyContent="end">
                        <Msg style={{ textAlign: "end", width: "20px" }}>
                          {c.render && c.render(d, d, i)}
                        </Msg>
                      </Flex>
                    )
                  )}
                </TableCard>
              ))
            ) : (
              <Empty style={{ width: "100%" }} />
            )}
            {!!pagination?.total && pagination.total > 10 && (
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={(p) => {
                  onChange({ ...pagination, current: p }, {}, {});
                }}
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "20px",
                }}
                size="small"
              />
            )}
          </Flex>
        )}
      </Box>
      <ColumnsSelector
        name={Columns.WorkOrders}
        columns={formattedColumns}
        visible={columnsVisible}
        onClose={() => setColumnsVisible(false)}
      />

      <MessagesModal
        visible={visible}
        onClose={() => setVisible(false)}
        customer={customerForMessage}
      />
    </>
  );
};

export default Jobs;
