import React from 'react';
import {Msg} from "../ui/Text";
import {TChildren} from "../types";
import { useResponsive } from '../utils/hooks';

export const Title: React.FC<TChildren> = ({children}) => {
    const {width} = useResponsive();
    return <Msg
        fontWeight={800}
        fontSize={24}
        mb='18px' mt={width <= 576 ? '0px' : '76px'}>
        {children}
    </Msg>
};