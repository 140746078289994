import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import { NavLink, useHistory } from "react-router-dom"
import { Dropdown, Menu, Popconfirm, Table, Form, Select, Button, Collapse, Spin, Pagination } from "antd"
import { EllipsisOutlined, InfoCircleOutlined, RightOutlined } from "@ant-design/icons"
import { EditIcon } from "../../assets/icons"
import { DeleteIcon } from "../../assets/icons"
import Customers from "./Customers"
import { Msg } from "../../ui/Text"
import { ExportBtn, AddBtn } from "../../ui/TableUtils"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import { SearchWithBorder } from "../../noui/Search"
import AddCustomerImg from "../../assets/images/addCustomer.svg"
import AddCustomerBlueImg from "../../assets/images/addCustomerBlue.svg"
import { CustomerModal } from "./CustomerModal"
import { CustomerInfo } from "./MoreInfo"
import { EditCustomer } from "./EditModal"
import { useLoading, usePagination } from "../../utils/hooks"
import { ICustomer } from "../../api/types"
import { ColumnsType } from "antd/es/table"
import routes from "../../routes/routes"
import { Api } from "../../api/api"
import Box from "../../noui/Box"
import { downloadFile } from "../../utils/utils"
import { useDebounce } from "use-debounce/lib"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { TableCard } from "../../components/TableCard"
import { TableWrapper } from "./pages/styled"
import { DefaultDateFormat } from "../../constants"
import { MobileAdd } from "../vessels/VesselsList"
const { Option } = Select
const { Panel } = Collapse

const SearchField = styled(SearchWithBorder)`
   // display: inline-block;
   // margin-right: 20px;
   // & span {
   //     width: 200px;
   // }
`

const CustomersHeader = styled(Flex)`
   padding: 0px 18px;
`

export const OptionButton = styled(EmptyButton)`
   margin-right: 25px;
   font-size: 18px;
   color: rgba(0, 0, 0, 0.54);
   &.ant-btn {
      background: none !important;
   }
   svg {
      max-width: 15px;
   }
`

const StyledMenu = styled(Menu)`
   padding: 15px 16px;
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`
const FormInputWrapper = styled.div`
   .ant-form-item {
      margin-right: 16px;
      &:first-child,
      &:last-child {
         margin-left: 0;
      }
      .ant-input {
         width: 92px;
      }
      .ant-select {
         width: 140px;
      }
   }
`

const MobileFilters = styled(Collapse)`
   .ant-collapse-content-box {
      padding: 0px 16px 24px !important;
   }
`
const DeleteBtn = styled(EmptyButton)`
   width: 34px;
   height: 34px;
   margin-right: 25px;
   font-size: 18px;
   background: #f0f1f6 !important;
   border: 1px solid #e6e6e6;
   &:disabled {
      opacity: 0.5;
   }
   svg {
      width: 15px;
      height: 15px;
   }
`

const paymentStatusOptions = {
   "Paid in Full": "F",
   "Payment Due": "D",
   "Late Payment": "L",
   "View All": "",
}

const annualSpendOptions = {
   "Under $1,000": ["", "1000"],
   "$1,000-$5,000": ["1000", "5000"],
   "$5,000-$10,000": ["5000", "10000"],
   "$10,000+": ["10000", ""],
   "View All": ["", ""],
}

const nextAppointmentOptions = {
   "This Week": [moment().startOf("week").toISOString(), moment().endOf("week").toISOString()],
   "This Month": [moment().startOf("month").toISOString(), moment().endOf("month").toISOString()],
   "View All": ["", ""],
}

const lastAppointmentOptions = {
   "Last 30 Days": [moment().subtract(30, "days").toISOString(), moment().toISOString()],
   "30-90 Days": [moment().subtract(90, "days").toISOString(), moment().subtract(30, "days").toISOString()],
   "90+ Days": ["", moment().subtract(90, "days").toISOString()],
   "View All": ["", ""],
}

type TOption = {
   [key: string]: string[]
}

const CustomerList: React.FC = () => {
   const { data, loading, onPromise, requestPagination, pagination, onChange } = usePagination<ICustomer>()
   const [visible, setVisible] = useState(false)
   const [mouseEnter, setMouseEnter] = useState(false)
   const [selected, setSelected] = useState<ICustomer[]>([])
   const [dropdown, setDropdown] = useState<number | null>(null)
   const [moreInfo, setMoreInfo] = useState(false)
   const [edit, setEdit] = useState(false)
   const [infoUser, setInfoUser] = useState<ICustomer | null>(null)
   const [popupVisible, setPopupVisible] = useState(false)
   const [search, setSearch] = useState<string>("")
   const [dbSearch] = useDebounce(search, 1000)
   const isLg = useBreakpoint(down("lg"))
   const history = useHistory()
   const [lastAppointment, setLastAppointment] = useState<string[]>(["", ""])
   const [dbLastAppointment] = useDebounce(lastAppointment, 1000)

   const [nextAppointment, setNextAppointment] = useState<string[]>(["", ""])
   const [dbNextAppointment] = useDebounce(nextAppointment, 1000)

   const [paymentStatus, setPaymentStatus] = useState<string>("")
   const [annualSpend, setAnnualSpend] = useState<string[]>(["", ""])

   const [saving, onSave] = useLoading()

   const columns: ColumnsType<ICustomer> = [
      {
         title: "Full Name",
         dataIndex: "full_name",
         sorter: true,
         render: (_, record) => (
            <NavLink to={routes.customers.CustomerPage.replace(":id", String(record.id))}>{record.full_name}</NavLink>
         ),
         ellipsis: true,
      },
      {
         title: "Vessel(s)",
         dataIndex: "vessels",
         sorter: false,
         ellipsis: true,
         render: (_, record) => {
            const vesselsList = new Set<string>()
            record.vessels.forEach((vessel) => vesselsList.add(vessel?.name))
            return isLg ? (
               <Flex flexDirection="column">
                  {Array.from(vesselsList).map((i) => (
                     <Msg fontSize="14px">{i}</Msg>
                  ))}
               </Flex>
            ) : (
               <Msg fontSize="14px">{Array.from(vesselsList).join(", ")}</Msg>
            )
         },
      },
      {
         title: "Last Appointment",
         dataIndex: "last_appointment",
         sorter: true,
         render: (_, record) => (
            <Msg fontSize="14px">
               {record.last_appointment && moment(record.last_appointment).format(DefaultDateFormat)}
            </Msg>
         ),
      },
      {
         title: "Next Appointment",
         dataIndex: "next_appointment",
         sorter: true,
         render: (_, record) => (
            <Msg fontSize="14px">
               {record.next_appointment && moment(record.next_appointment).format(DefaultDateFormat)}
            </Msg>
         ),
      },
      {
         title: "Open Invoices",
         dataIndex: "open_invoices",
         sorter: true,
         render: (_, record) => (
            <Msg color="#fb4d4f" fontSize="14px">
               {record.open_invoices && `$${record.open_invoices.toFixed(2)}`}
            </Msg>
         ),
      },
      {
         title: "Actions",
         dataIndex: "actions",
         fixed: "right",
         render: (_, record) => (
            <Flex justifyContent="center">
               <Dropdown
                  trigger={["click"]}
                  onVisibleChange={() => {
                     if (dropdown) {
                        setDropdown(null)
                     }
                  }}
                  overlay={
                     <StyledMenu>
                        <Flex flexDirection="column" alignItems="flex-start" style={{ gap: "16px" }}>
                           <OptionButton
                              onClick={() => {
                                 setMoreInfo(true)
                                 setDropdown(null)
                              }}
                           >
                              <Flex alignItems="center">
                                 <InfoCircleOutlined />
                                 <Msg marginLeft="9px" fontSize="14px">
                                    More Info
                                 </Msg>
                              </Flex>
                           </OptionButton>
                           <OptionButton
                              onClick={() => {
                                 setEdit(true)
                                 setDropdown(null)
                              }}
                           >
                              <Flex alignItems="center">
                                 <EditIcon />
                                 <Msg marginLeft="9px" fontSize="14px">
                                    Edit
                                 </Msg>
                              </Flex>
                           </OptionButton>
                           <Popconfirm
                              title={`Are you sure to delete ${record.full_name}?`}
                              onConfirm={() => deleteItems(record.id)}
                              onCancel={() => setDropdown(null)}
                           >
                              <OptionButton onClick={() => setDropdown(null)}>
                                 <Flex alignItems="center">
                                    <DeleteIcon />
                                    <Msg marginLeft="9px">Delete</Msg>
                                 </Flex>
                              </OptionButton>
                           </Popconfirm>
                        </Flex>
                     </StyledMenu>
                  }
                  visible={dropdown === record.id}
                  placement="bottomRight"
                  arrow
               >
                  <EmptyButton
                     onClick={() => {
                        setDropdown(record.id)
                        setInfoUser(record)
                     }}
                  >
                     <EllipsisOutlined style={{ fontSize: "18px", marginTop: "5px" }} />
                  </EmptyButton>
               </Dropdown>
            </Flex>
         ),
      },
   ]

   const loadCards = useCallback(() => {
      onPromise(
         Api.customers.getAll({
            ...requestPagination,
            search: dbSearch,
            last_appointment_after: dbLastAppointment ? dbLastAppointment[0] : "",
            last_appointment_before: dbLastAppointment ? dbLastAppointment[1] : "",
            next_appointment_after: dbNextAppointment ? dbNextAppointment[0] : "",
            next_appointment_before: dbNextAppointment ? dbNextAppointment[1] : "",
            payment_status: paymentStatus,
            annual_spend_min: annualSpend[0],
            annual_spend_max: annualSpend[1],
         })
      )
   }, [onPromise, requestPagination, dbSearch, dbLastAppointment, dbNextAppointment, paymentStatus, annualSpend])

   useEffect(() => {
      window.addEventListener("scroll", () => setPopupVisible(false))
      loadCards()
      return () => window.removeEventListener("scroll", () => setPopupVisible(false))
   }, [loadCards])

   const rowSelection = {
      onChange: (selected: any, selectedRows: ICustomer[]) => {
         setSelected(selectedRows)
      },
      checkStrictly: false,
   }

   const deleteItems = async (id?: number) => {
      if (id) {
         await Api.customers.remove(id)
      } else {
         const requests = [...selected].map((s) => Api.customers.remove(s.id))
         await Promise.all(requests)
      }
      await loadCards()
      setSelected([])
      setDropdown(null)
   }

   const handleExport = async () => {
      const { data } = await onSave(Api.customers.download())
      downloadFile(data, `CustomersList ${moment().format("LLL")}.xlsx`)
   }
   const createPaymentStatusOptions = () =>
      Object.entries(paymentStatusOptions).map((item) => <Option value={item[1]}>{item[0]}</Option>)
   const createMultipleValueOptions = (options: TOption) =>
      Object.entries(options).map((item) => <Option value={item[1].join(" ")}>{item[0]}</Option>)

   const filters = () => {
      return (
         <Flex flexDirection="row" padding={isLg ? 0 : 18} paddingBottom={0}>
            <Form layout="vertical" style={{ width: "100%" }}>
               <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
                  <Form.Item
                     label="Last Appointment"
                     name="last_appointment"
                     style={{ width: isLg ? "100%" : "initial", margin: isLg ? "0px" : "0px 16px 16px 0px" }}
                  >
                     <Select
                        placeholder="Select"
                        defaultValue=" "
                        onChange={(e) => {
                           const [start, end] = e.split(" ")
                           setLastAppointment([start, end])
                        }}
                        style={{ width: isLg ? "100%" : "140px" }}
                     >
                        {createMultipleValueOptions(lastAppointmentOptions)}
                     </Select>
                  </Form.Item>
                  <Form.Item
                     label="Next Appointment"
                     name="next_appointment"
                     style={{ width: isLg ? "100%" : "initial", margin: isLg ? "0px" : "0px 16px 16px 0px" }}
                  >
                     <Select
                        placeholder="Select"
                        defaultValue=" "
                        onChange={(e) => {
                           const [start, end] = e.split(" ")
                           setNextAppointment([start, end])
                        }}
                        style={{ width: isLg ? "100%" : "140px" }}
                     >
                        {createMultipleValueOptions(nextAppointmentOptions)}
                     </Select>
                  </Form.Item>
                  <Form.Item
                     label="Payment Status"
                     name="payment_status"
                     style={{ width: isLg ? "100%" : "initial", margin: isLg ? "0px" : "0px 16px 16px 0px" }}
                  >
                     <Select
                        placeholder="Select"
                        defaultValue=""
                        onChange={setPaymentStatus}
                        style={{ width: isLg ? "100%" : "140px" }}
                     >
                        {createPaymentStatusOptions()}
                     </Select>
                  </Form.Item>
                  <Form.Item
                     label="Annual Spend"
                     name="annual_spend"
                     style={{ width: isLg ? "100%" : "initial", margin: isLg ? "0px" : "0px 16px 16px 0px" }}
                  >
                     <Select
                        placeholder="Select"
                        defaultValue=" "
                        onChange={(e) => {
                           const [start, end] = e.split(" ")
                           setAnnualSpend([start, end])
                        }}
                        style={{ width: isLg ? "100%" : "140px" }}
                     >
                        {createMultipleValueOptions(annualSpendOptions)}
                     </Select>
                  </Form.Item>
               </Flex>
            </Form>
         </Flex>
      )
   }

   return (
      <Customers>
         {infoUser ? (
            <>
               <EditCustomer visible={edit} setVisible={setEdit} customer={infoUser} onSuccess={loadCards} />
               <CustomerInfo visible={moreInfo} setVisible={setMoreInfo} setEdit={setEdit} customer={infoUser} />
            </>
         ) : null}
         {selected[0] && (
            <EditCustomer visible={edit} setVisible={setEdit} customer={selected[0]} onSuccess={loadCards} />
         )}
         <CustomersHeader flexDirection={isLg ? "column" : "row"} justifyContent="space-between">
            <Flex width={isLg ? "100%" : "50%"} justifyContent="space-between">
               <SearchField placeholder="Search here" onChange={({ target: { value } }) => setSearch(value)} />
            </Flex>
            <Flex justifyContent="space-between">
               {!isLg && (
                  <>
                     <Popconfirm
                        title="Are you sure to delete these users?"
                        visible={popupVisible}
                        onConfirm={() => {
                           deleteItems()
                           setPopupVisible(false)
                        }}
                        onCancel={() => setPopupVisible(false)}
                        onVisibleChange={() => setPopupVisible(!popupVisible)}
                     >
                        <DeleteBtn
                           disabled={selected.length === 0}
                           onClick={() => {
                              setPopupVisible(true)
                           }}
                        >
                           <DeleteIcon />
                        </DeleteBtn>
                     </Popconfirm>
                     <ExportBtn loading={saving} onClick={handleExport}>
                        Export
                     </ExportBtn>
                     <AddBtn
                        style={{ margin: isLg ? "10px 0px 0px 0px" : "0px 0px 0px 20px" }}
                        width={isLg ? "100%" : "186px"}
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                        onClick={(event) => {
                           // blur is required because button stays focused after click
                           event.currentTarget.blur()
                           setVisible(true)
                        }}
                     >
                        <Flex justifyContent="space-between">
                           <Msg fontWeight={600} fontSize={13} color="#fff">
                              Add New Customer
                           </Msg>
                           <img alt="" src={mouseEnter ? AddCustomerBlueImg : AddCustomerImg} />
                        </Flex>
                     </AddBtn>
                  </>
               )}
               <CustomerModal visible={visible} onSuccess={loadCards} onClose={() => setVisible(false)} />
            </Flex>
         </CustomersHeader>
         <FormInputWrapper>
            {isLg ? (
               <MobileFilters ghost>
                  <Panel header="Show filters" key="1">
                     {filters()}
                  </Panel>
               </MobileFilters>
            ) : (
               filters()
            )}
         </FormInputWrapper>
         <Box px={3}>
            {!isLg ? (
               <TableWrapper>
                  <Table
                     className="contained customers-table"
                     dataSource={data}
                     loading={loading}
                     columns={columns}
                     rowKey={(record) => record.id}
                     onChange={onChange}
                     rowSelection={rowSelection}
                     pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
                  />
               </TableWrapper>
            ) : (
               <Spin spinning={loading}>
                  <Flex flexWrap="wrap">
                     {data.map((d) => (
                        <TableCard key={d.id}>
                           <Flex flexDirection="row" alignItems="center">
                              <Flex flexDirection="column" flex="1">
                                 {columns.map((c, i) => (
                                    <Flex key={i} justifyContent="space-between">
                                       <Msg fontWeight={700}>{c.title}</Msg>
                                       <Msg style={{ textAlign: "end" }}>{c.render && c.render(d, d, i)}</Msg>
                                    </Flex>
                                 ))}
                              </Flex>
                              <Button
                                 style={{ border: "none" }}
                                 onClick={() => {
                                    history.push(routes.customers.CustomerPage.replace(":id", String(d.id)))
                                 }}
                              >
                                 <RightOutlined />
                              </Button>
                           </Flex>
                        </TableCard>
                     ))}
                     {!!pagination?.total && pagination.total > 10 && (
                        <Pagination
                           current={pagination.current}
                           pageSize={pagination.pageSize}
                           total={pagination.total}
                           onChange={(p) => {
                              onChange({ ...pagination, current: p }, {}, {})
                           }}
                           style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
                           size="small"
                        />
                     )}
                  </Flex>
               </Spin>
            )}
         </Box>
         {isLg && (
            <MobileAdd type="primary" onClick={() => setVisible(true)}>
               Add new Customer
               <img src={mouseEnter ? AddCustomerBlueImg : AddCustomerImg} alt="icon" />
            </MobileAdd>
         )}
      </Customers>
   )
}

export default CustomerList
