import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAppSelector } from "../../../config/hooks";
import { getCurrentUser } from "../../../config/reducers/user/selectors";
import CheckoutForm, { TCheckoutForm } from "./CheckoutForm";
import { Msg } from "../../../ui/Text";
import React, { useEffect, useState } from "react";
import { Api, TPaymentStripe } from "../../../api/api";
import { IExternalEstimate, IExternalRecurring } from "../../../api/types";
import { TCallback } from "../../../types";
import Flex from "../../../noui/Flex";
import { Button, notification, Spin } from "antd";
import { PrimaryButton } from "../../../ui/Button";
import { useDebounce } from "use-debounce/lib";
import { useDebouncedCallback } from "use-debounce";
import { StripeAPIKey } from "../../../api";

export type TStripeWrapper = {
  intentStripeQuery: TPaymentStripe;
  onCancel: () => void;
  onSubmit: () => void;
  onPending: (value: boolean) => void;
  disabledPay: boolean;
  totalAmount: number;
  onApprove: TCallback;
  estimate: IExternalEstimate | IExternalRecurring;
  isInvoice: boolean;
};
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;
type test1 = Awaited<Promise<string>>;
const StripeWrapper: React.FC<TStripeWrapper> = ({
  intentStripeQuery,
  estimate,
  onApprove,
  totalAmount,
  disabledPay,
  onCancel,
  onSubmit,
  onPending,
  isInvoice,
}) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const stipeAccountId = estimate.company.stripe.stripe_id;
  const stripePromise = loadStripe(StripeAPIKey, {
    stripeAccount: stipeAccountId,
  });

  const initialStripe = async (): Promise<any> => {
    try {
      const { data } = await Api.paymentRecords.payByStipe(intentStripeQuery);
      setClientSecret(data.intent.client_secret);
    } catch (e) {
      if (totalAmount < 0.5) {
        onCancel();
      }
      notification.error({ message: "The minimum amount is $0.50 US" });
    }
  };
  const debouncedSetAmount = useDebouncedCallback(initialStripe, 500);
  useEffect(() => {
    debouncedSetAmount();
  }, [estimate, intentStripeQuery]);
  return (intentStripeQuery.amount && intentStripeQuery.amount < 0.5) ||
    intentStripeQuery.amount === 0 ? (
    <PrimaryButton
      disabled
      style={{
        width: "100%",
        marginTop: "24px",
        height: "40px",
        marginBottom: "24px",
      }}
    >
      Pay {isInvoice ? "Invoice" : "Estimate"}
    </PrimaryButton>
  ) : clientSecret ? (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm
        estimate={estimate}
        onApprove={onApprove}
        disabledPay={disabledPay}
        intentStripeQuery={intentStripeQuery}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onPending={onPending}
        isInvoice={isInvoice}
        clientSecret={clientSecret}
      />
    </Elements>
  ) : (
    <Flex height="400px" justifyContent="center" alignItems="center">
      <Spin spinning={true} />
    </Flex>
  );
};

export default StripeWrapper;
