import React, { useState, useEffect } from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { TArgCallback } from "../../types"
import ClockIcon from "../../assets/images/clock-icon.svg"
import { Wrapper, Icon } from "./styled"

export const Calendar = styled(Flex)`
   position: relative;

   .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      &::-webkit-scrollbar {
         width: 8px;
      }

      &::-webkit-scrollbar-track {
         background: #fbfbff;
         border-radius: 8px;
         border: 1px solid #ededed;
      }

      &::-webkit-scrollbar-thumb {
         background: #0496ff;
         border-radius: 8px;
      }
      

      &::-webkit-scrollbar-thumb:hover {
         background: #40a9ff;
      }
   }
   .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: #1890ff;
   }

   .react-datepicker__input-container {
      input {
         outline: none;
         border: 1px solid #ccc;
         border-radius: 3px;
         padding: 4px 5px;
         width: 100%;
         &:focus {
            border-color: #40a9ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
         }
         &:disabled {
            color: rgba(0, 0, 0, 0.25);
            background: #f5f5f5;
            cursor: not-allowed;
         }
      }
   }

   .react-datepicker {
      border: none;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
   }
   .react-datepicker__header {
      background: none;
      padding: 5px 8px;
      border-bottom: 1px solid #f0f0f0;
   }
   .react-datepicker__month {
      margin: 8px;
   }
   .react-datepicker__day-name {
      font-weight: bold;
   }
   .react-datepicker__day--selected,
   .react-datepicker__day--keyboard-selected {
      background-color: #1890ff;
   }
   select {
      margin: 0 6px 0 0 !important;
   }
   .react-datepicker__input {
      &-time-container {
         padding: 9px 15px;
         margin: 0;
         background: #f5f5f5;
         height: 100%;
         border-radius: 0 0 0.3rem 0.3rem;
      }
   }
   .react-datepicker-time__input {
      .react-datepicker-time__input {
         border: 1px solid #ccc;
         padding: 2px 5px;
         border-radius: 5px;
         color: #3c3939;
         font-size: 16px;
         outline: none;
         &:focus {
            border: 1px solid #1890ff;
         }
      }
   }
   input[type="time"]::-webkit-calendar-picker-indicator {
      display: block;
   }
   .react-datepicker__day--outside-month {
      color: #ccc !important;
      pointer-events: none;
   }
`

type TProps = {
   date: any
   setDate: TArgCallback<any>
   label?: string
   disable?: boolean
}

export const TimePicker: React.FC<TProps> = ({ date, setDate, label, disable }) => {
   return (
      <Wrapper>
         {label && <h3>{label}</h3>}
         <Calendar>
            <DatePicker
               selected={date}
               onChange={(date) => setDate(date)}
               showTimeSelect
               showTimeSelectOnly
               timeIntervals={30}
               timeCaption="Time"
               dateFormat="h:mm aa"
               showPopperArrow={false}
               disabled={disable}
            />
            {!date && <Icon style={{ backgroundImage: `url(${ClockIcon})` }} />}
         </Calendar>
      </Wrapper>
   )
}
