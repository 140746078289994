import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { DatePicker, Form } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { EmptyButton, PrimaryButton } from "../../ui/Button";
import { Msg } from "../../ui/Text";
import Flex from "../../noui/Flex";
import { CustomInput, CustomTextarea } from "../../ui/Input";
import { CustomSelect } from "../../ui/Select";
import { getErrors, setDefaultFields } from "../../utils/utils";
import Trash from "../../assets/images/Delete.svg";
import moment from "moment";
import { EUserRole, ICustomer, IJob, ITask, IUser } from "../../api/types";
import { TArgCallback } from "../../types";
import { Api } from "../../api/api";
import { TCreateTaskWithMoment } from "./types";
import { DefaultTimeFormat } from "../../constants";
import { useLoading, useResponsive } from "../../utils/hooks";
import { Content } from "../../components/Content";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { CalendarPicker } from "../../components/CalendarPicker";
import { AutocompleteSelect } from "../../components/Autocomplete/AutocompleteCustomer";

const { Option } = CustomSelect;

const dateFormat: string = "MM/DD/YYYY HH:mm A";

const StyledHeader = styled(Msg)`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 15px;
  margin-left: 18px;
`;

const FormSection = styled(Form.Item)`
  margin-bottom: 8px;
`;

const CenteredBtn = styled(EmptyButton)`
  display: flex;
  align-items: center;
`;

const Description = styled(CustomTextarea)`
  height: 100px !important;
`;

const ModalCancelBtn = styled(PrimaryButton)`
  width: 92px;
  height: 34px;
  background-color: #ffffff;
  border: 0.7px solid #1d3443;
  color: #1d3443;
  ${down("xs")} {
    width: calc(50% - 8px);
  }
`;

const ModalCreateBtn = styled(PrimaryButton)`
  width: 92px;
  height: 34px;
  border: 0.7px solid #1c99fc;
  ${down("xs")} {
    width: calc(50% - 8px);
  }
`;

const JobSelect = styled(CustomSelect)<{ selected: boolean }>`
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  color: #109cf1;
`;

type TProps = {
  task: ITask;
  setMode: TArgCallback<string>;
  deleteTask: TArgCallback<number>;
};

const EditTask: React.FC<TProps> = ({ task, setMode, deleteTask }) => {
  const { id } = task;

  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [employees, setEmployees] = useState<IUser[]>([]);
  const [selectedJob, setSelectedJob] = useState<boolean>(false);
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [form] = Form.useForm<TCreateTaskWithMoment>();
  const [date, setDate] = useState<any>(null);
  const [loading, onLoad] = useLoading();
  const isXs = useBreakpoint(down("xs"));
  const { width } = useResponsive();

  useEffect(() => {
    if (date) {
      form.setFieldsValue({ due_date: moment(date) });
    }
  }, [date]);

  const fetchInfo = useCallback(async () => {
    const { data: customers } = await onLoad(Api.customers.getAll({}));
    const { data: staff } = await onLoad(Api.user.getAll({}));
    const { data: jobs } = await onLoad(Api.jobs.getAll({}));
    await setCustomers(customers as ICustomer[]);
    await setJobs(jobs as IJob[]);
    await setEmployees(staff as IUser[]);
  }, [onLoad]);

  useEffect(() => {
    fetchInfo().finally();
    if (task.id) {
      if (task.job) setSelectedJob(true);
      setDefaultFields(form, {
        ...task,
        due_date: task.due_date ? moment(task.due_date) : undefined,
      });

      if (task.due_date) {
        setDate(new Date(task.due_date));
      }
    } else {
      form.resetFields();
    }
  }, [fetchInfo, task, form]);

  const handleSubmit = async () => {
    const data = await form.getFieldsValue();
    try {
      await Api.tasks.update({
        ...data,
        id,
        due_date: data.due_date?.toISOString(),
      });
      form.resetFields();
      setMode("");
    } catch (e) {
      form.setFields(getErrors(e));
    }
  };
  const handleValidate = async () => {
    try {
      await form.getFieldsValue();
    } catch (e) {
      form.setFields(getErrors(e));
    }
  };

  const handleSelect = (v: any) => {
    if (v) {
      form.setFieldsValue({ customer: v });
    }
  };

  return (
    <Flex
      flexDirection="column"
      py="16px"
      width={isXs ? `${width - 25}px` : "400px"}
    >
      <Flex
        mb="30px"
        px={isXs ? "10px" : "18px"}
        justifyContent="space-between"
        alignItems="center"
      >
        <CenteredBtn onClick={() => setMode("")}>
          <LeftOutlined />
          <StyledHeader>Back to tasks</StyledHeader>
        </CenteredBtn>
        <Flex>
          {!loading && (
            <CenteredBtn onClick={() => deleteTask(id)}>
              <img src={Trash} alt="icon" />
            </CenteredBtn>
          )}
        </Flex>
      </Flex>
      <Flex px={isXs ? "10px" : "25px"} flexDirection="column">
        <Content loading={loading}>
          <Form layout={"vertical"} onFinish={handleValidate} form={form}>
            <FormSection
              name="title"
              label={"Title"}
              rules={[
                {
                  required: true,
                  message: "Please input a title",
                },
              ]}
            >
              <CustomInput placeholder="Type here" />
            </FormSection>
            <FormSection name="description" label="Description">
              <Description placeholder="Type here" />
            </FormSection>
            <FormSection name="job" label={"Tag Job"}>
              <JobSelect
                selected={selectedJob}
                onSelect={() => setSelectedJob(true)}
                placeholder="Select a job"
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {jobs.map((j) => (
                  <Option
                    style={{ fontWeight: 700, color: "#109CF1" }}
                    key={j.id}
                    value={j.id}
                  >{`Estimate# ${j.title}`}</Option>
                ))}
              </JobSelect>
            </FormSection>
            <FormSection name="due_date" label={"Add date"} id="task_modal">
              <CalendarPicker
                date={date}
                setDate={setDate}
                excludePastDays={true}
              />
            </FormSection>
            <FormSection name="employee" label="Add employee">
              <CustomSelect
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Type here"
              >
                {employees.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.full_name}
                  </Option>
                ))}
              </CustomSelect>
            </FormSection>
            <FormSection name="customer" label="Add customer">
              {/* <CustomSelect getPopupContainer={(trigger) => trigger.parentElement} placeholder="Type here">
                        {customers.map((c) => (
                           <Option key={c.id} value={c.id}>
                              {c.full_name}
                           </Option>
                        ))}
                     </CustomSelect> */}
              {customers && (
                <AutocompleteSelect
                  options={customers}
                  handleSelect={handleSelect}
                  loading={loading}
                  defaultCustomer={form.getFieldValue("customer")}
                  fieldName="full_name"
                  placeholder="Select a Customer"
                />
              )}
            </FormSection>
            <Flex justifyContent="flex-end" mt="25px">
              <ModalCancelBtn onClick={() => setMode("")}>
                Cancel
              </ModalCancelBtn>
              <ModalCreateBtn ml={16} onClick={handleSubmit}>
                Save
              </ModalCreateBtn>
            </Flex>
          </Form>
        </Content>
      </Flex>
    </Flex>
  );
};

export default EditTask;
