import React from 'react';
import {Form, FormInstance, Input, Tabs} from "antd";
import styled from "styled-components";
import {ServicesTab} from "./ServicesTab";
import {BeforePhotosTab} from "./BeforePhotosTab";
import {TimeClockTab} from "./TimeclockTab";
import {ICreateJob, IJob} from "../../../api/types";

const Row = styled.div`
    input {
        font-weight: bold;
        font-size: 22px;
    }
`;
const TabsWrapper = styled.div`
    margin: 32px -12px 0;
    width: calc(100% + 24px);
    .ant-tabs-nav {
        padding: 0 18px;
    }
`;

type TTab = {
  title: string;
  component: React.FC<{form: FormInstance<ICreateJob>, estimateId?: number, after?: boolean, jobId?: number}>;
}
const tabs: TTab[] = [
  {title: "Services", component: ServicesTab},
  {title: "Before Photos", component: BeforePhotosTab},
  {title: "Time Clocks", component: TimeClockTab},
  {title: "After Photos", component: BeforePhotosTab},
]

type TProps = {
  estimate?: IJob;
  form: FormInstance<ICreateJob>
}
export const MainContent: React.FC<TProps> = ({estimate, form}) => {
  return <div>
    <Row>
      <Form.Item hidden name="id">
        <Input type="hidden"/>
      </Form.Item>
      <Form.Item style={{marginBottom: 0}} name="title" rules={[{required: true, message: 'Title is required'}]}>
        <Input placeholder="Title" name="title"/>
      </Form.Item>
    </Row>
    <TabsWrapper>
      <Tabs>
        {tabs.map(t => {
          const C = t.component;
          return <Tabs.TabPane key={t.title} tab={t.title}>
            <C form={form} estimateId={estimate?.id} jobId={estimate?.id} after={t.title === "After Photos"}/>
          </Tabs.TabPane>
        })}
      </Tabs>
    </TabsWrapper>
  </div>
};