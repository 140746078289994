import { ModalWindow } from "../ui/ModalWindow"
import React from "react"
import { Msg } from "../ui/Text"
import Flex from "../noui/Flex"
import { EmptyButton } from "../ui/Button"
import styled from "styled-components"
import CheckImg from "../assets/images/check.svg"
import { ICompletions } from "../api/types"
import { useHistory } from "react-router-dom"
import { Api } from "../api/api"
import routes from "../routes/routes"

const ModalWrap = styled(ModalWindow)`
   border: 1px solid #d6dfe4;
`

const ProgressElem = styled(Flex)`
   border-bottom: 1px solid #ebeff2;
`

const MsgEl = styled(Msg)`
   border-right: 1px solid #ebeff2;
   line-height: 36px;
`

const CheckImage = styled.img`
   width: 12px;
   height: 12px;
   margin-right: 5px;
`

type TParams = {
   [k in keyof ICompletions]: string[]
}
export const parametersData: TParams = {
   logo: ["Add your shop logo", routes.settings.General],
   info: ["Company info", routes.settings.General],
   invites: ["Invite managers and admin", routes.settings.Users],
   job: ["Create first order", routes.workOrder.CreateWorkOrder],
   // quick_books: ['Sync to QuickBooks Online', routes.main.Dashboard], //exclude one
   bank: ["Connect your bank", routes.settings.Payments],
   prices: ["Add your prices", routes.business.RateCard],
   customers: ["Import customer list", routes.customers.List],
}

type TProps = {
   functionalityStatus: ICompletions
}
export const ProgressBar: React.FC<TProps> = ({ functionalityStatus }) => {
   const history = useHistory()

   return (
      <ModalWrap>
         {Object.entries(functionalityStatus).map(([key, value], index) =>
            index === 4 ? null : ( //exlude one
               <ProgressElem width="350px" height="36px">
                  <MsgEl width="230px" pl="12px">
                     {parametersData[key as keyof ICompletions][0]}
                  </MsgEl>

                  {!value ? (
                     <EmptyButton
                        width="119px"
                        color="#109CF1"
                        onClick={() => {
                           history.push(parametersData[key as keyof ICompletions][1])
                        }}
                     >
                        Set Up
                     </EmptyButton>
                  ) : (
                     <Flex width="119px" justifyContent="center" alignItems="center">
                        <CheckImage src={CheckImg} />
                        <Msg fontWeight="400" fontSize="13px" color="#BABABA">
                           Completed
                        </Msg>
                     </Flex>
                  )}
               </ProgressElem>
            )
         )}
      </ModalWrap>
   )
}
