import { up, only, down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"

export const useBreakpoints = () => {
   const isXs = useBreakpoint(down("xs"))
   const isSm = useBreakpoint(only("sm"))
   const isMd = useBreakpoint(only("md"))
   const isMdDown = useBreakpoint(down("md"))
   const isLg = useBreakpoint(only("lg"))
   const isXl = useBreakpoint(up("xl"))

   return {
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isMdDown,
   }
}
