import { NavLink } from "react-router-dom";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import Flex from "../../../noui/Flex";
import routes from "../../../routes/routes";
import { LogoContainer } from "../secondStep/RegistrationStepTwo";
import DarkLogo from "../../../assets/images/DarkLogo.svg";
import SmallBoatImg from "../../../assets/images/SmallBoatImg.png";
import Box from "../../../noui/Box";
import { Msg } from "../../../ui/Text";
import SubscriptionCollapse from "./SubscriptionCollapse";
import { useEffect, useMemo, useRef, useState } from "react";
import { ISubscriptionPlan, EUserRole } from "../../../api/types";
import { Api } from "../../../api/api";
import { Modal, Spin, Button } from "antd";
import styled from "styled-components";
import { StyledButton } from "../../settings/SubscriptionCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getCurrentUser } from "../../../config/reducers/user/selectors";
import { setCurrentUser } from "../../../config/reducers/user/actions";
import { BoxWrapper, LoginFlowWrapper } from "../../login/Login";
import { useTechnicianRole } from "../../../utils/hooks";
import { authService } from "../../../utils/authService";
import StripeSubscriptionsWrapper from "../../../layouts/StripeSubscriptions/StripeSubscriptionsWraper";
import PromoCodeField from "../../../components/payments/PromoCodeField";
import AnnualSelect from "../../../components/payments/AnnualSelect";
import { track } from "../../../utils/analytics";

import moment from "moment";
import { SuperUserHeader } from "../../../components/SuperUserHeader/SuperUserHeader";

export type TValidation = {
  errorId: string;
  errors: string[];
};

export const inputStyles = {
  fontFamily: "Nunito Sans, sans-serif",
  fontWeight: "400",
  padding: "10px",
  boxSizing: "border-box",
  fontSize: "16px",
};

export const ErrorText = styled(Msg)`
  color: red;
`;

export const TitleText = styled(Msg)`
  color: #494949;
  font-weight: 700;
  font-size: 13px;
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  .form-field input,
  .form-field iframe {
    height: 40px;
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 4px;
    padding-left: 4px;
    background-color: #ffffff;
  }
  .form-field iframe input {
    background-color: red;
  }
  .vgs-collect-container__valid ~ span {
    display: none;
  }
`;

const ConfirmButton = styled(StyledButton)`
  margin: 24px 0;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  ${down("md")} {
    width: 100%;
  }
`;

const StyledModal = styled(Modal)`
  max-width: 384px;
  p {
    font-size: 18px;
    width: 80%;
  }
`;

const LogOutBtn = styled(Button)`
  border: none;
  margin: 0 10px;
  padding: 0;
  color: #0496ff;
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
  > span {
    text-decoration: underline;
  }
`;

export const RegistrationStepThree = () => {
  const isMd = useBreakpoint(down("md"));
  const [activeStatus, setActiveStatus] = useState<number | null>(null);
  const [openStatus, setOpenStatus] = useState<boolean[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscriptionPlan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [visible, setVisible] = useState(false);
  const user = useAppSelector(getCurrentUser);

  const dispatch = useAppDispatch();
  const technicianRole = useTechnicianRole();

  const handleLogout = () => {
    authService.logout();
    window.location.href = "/login";
  };

  useEffect(() => {
    if (
      !checkInvalidStipeSubscription() ||
      !checkInvalidPESubscription() ||
      technicianRole
    ) {
      window.location.href = "/";
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(true);
    Api.config
      .getSubscriptionPlans()
      .then((response) => response.data)
      .then((plans) => {
        setSubscriptions(plans);
        setOpenStatus(plans.map((_) => false));
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (subscriptions.length) {
      setActiveStatus(user?.company?.plan?.id ?? subscriptions[0].id);
    }
  }, [subscriptions, user]);
  useEffect(() => {
    if (user?.company?.stripe_subscription?.status === "active") {
      setVisible(true);
    }
  }, [user?.company?.stripe_subscription]);

  const ref = useRef<HTMLFormElement>(null);
  const handleSubmit = async () => {
    if (ref.current) {
      ref.current.callOnSubmit();
    }
    setIsSubmiting(true);
  };

  async function onSubmit() {
    setIsSubmiting(false);
    setVisible(true);
  }

  const handleModalClose = async () => {
    const { data: profile } = await Api.user.profile();
    dispatch(setCurrentUser(profile));
    await track("New Pro User Joined", {
      proSignUp: user?.id,
      proSignUpTimestamp: moment().toISOString(),
      companyId: user?.company?.id,
      proCity: user?.dashboard_address?.city,
      proState: user?.dashboard_address?.state,
    });
    setVisible(false);
    await track("New Pro User Joined", {
      proSignUp: user?.id,
      proSignUpTimestamp: moment().toISOString(),
      companyId: user?.company?.id,
      proCity: user?.dashboard_address?.city,
      proState: user?.dashboard_address?.state,
    });
  };

  const getSelectedPlanTitle = () =>
    subscriptions.find((s) => s.id === activeStatus)?.title ?? "";

  const checkIsOverdue = () =>
    user?.company?.subscription_status?.status === "O";
  const checkInvalidPESubscription = () =>
    user?.company?.subscription_status?.status === "O" ||
    !user?.company?.subscription_status;
  const isRegister = () => user?.company?.plan === null;
  const checkInvalidStipeSubscription = () =>
    !user?.company?.stripe_subscription ||
    user?.company?.stripe_subscription?.status === "unpaid" ||
    user?.company?.stripe_subscription?.status === "canceled";

  const renderModal = () => (
    <StyledModal
      visible={visible}
      title="Confirmation"
      footer={[
        <StyledButton key="ok" onClick={handleModalClose}>
          Finish & proceed
        </StyledButton>,
      ]}
      onCancel={handleModalClose}
    >
      <p>You have successfully subscribed for {getSelectedPlanTitle()} plan</p>
    </StyledModal>
  );

  const isStaff = useMemo(
    () => Boolean(user?.is_staff && user.company),
    [user]
  );

  return (
    <Flex
      style={{
        top: isStaff ? "60px" : undefined,
        position: isStaff ? "relative" : undefined,
      }}
      backgroundColor={!isMd ? "none" : "#FBFBFF"}
    >
      <SuperUserHeader />
      <LoginFlowWrapper isStaff={isStaff} padding={isMd ? "0 12px" : "0"}>
        <LogoContainer p={35}>
          <NavLink to={routes.auth.Login}>
            <img src={DarkLogo} alt="logotype" />
          </NavLink>
        </LogoContainer>
        {user?.role_id !== EUserRole.Admin ? (
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            height="100%"
          >
            <Box m="auto" style={{ width: isMd ? "100%" : "434px" }}>
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                maxWidth={isMd ? "400px" : "initial"}
                margin="auto auto 30px auto"
              >
                <Flex flexDirection="column">
                  {checkIsOverdue() ? (
                    <>
                      <Box mb="8px">
                        <Msg fontWeight={700} fontSize={22}>
                          Continue subscription!
                        </Msg>
                      </Box>
                      <Box mb="12px">
                        <Msg fontWeight={400} fontSize={13}>
                          If you want to continue your subscription, please
                          attach your valid credit card.
                        </Msg>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box mb="24px" textCenter>
                        <Msg fontWeight={600} fontSize={15} color="#252733">
                          Step 3 of 3
                        </Msg>
                      </Box>
                      <Box mb="30px" textCenter>
                        <Msg fontWeight={700} fontSize={22}>
                          Final step!
                        </Msg>
                      </Box>
                    </>
                  )}
                  <Box mb="8px" mt="15px">
                    <Msg fontWeight={700} fontSize={15} color="#484848">
                      1. Choose your plan below:
                    </Msg>
                  </Box>
                  <AnnualSelect fromStart />
                  <Spin spinning={isLoading}>
                    <Flex flexDirection="column">
                      {subscriptions.map((s, i) => (
                        <SubscriptionCollapse
                          key={s.id}
                          {...s}
                          isActive={activeStatus === s.id}
                          setActive={() => setActiveStatus(s.id)}
                          isOpen={openStatus[i]}
                          setOpen={() =>
                            setOpenStatus(
                              openStatus.map((s, j) => (j === i ? !s : s))
                            )
                          }
                          isRegister={isRegister}
                        />
                      ))}
                    </Flex>
                  </Spin>
                  <Box mb="8px" mt="15px">
                    <Msg fontWeight={700} fontSize={15} color="#484848">
                      2. Attach your credit card
                    </Msg>
                  </Box>
                  <Spin spinning={isLoading}>
                    <StripeSubscriptionsWrapper
                      activeStatus={activeStatus}
                      onSubmit={onSubmit}
                      refSubmit={ref}
                    />
                    <PromoCodeField />
                  </Spin>
                  <ConfirmButton
                    loading={isLoading || isSubmiting}
                    onClick={handleSubmit}
                  >
                    {isRegister() ? "Confirm Plan" : "Pay & continue"}
                  </ConfirmButton>
                  <Msg fontSize="15px" color="#8B8B8B" fontWeight="bold">
                    Do you want to return to step 1?
                    <LogOutBtn onClick={handleLogout}>Log out</LogOutBtn>
                  </Msg>
                  {renderModal()}
                </Flex>
              </Flex>
            </Box>
          </Flex>
        ) : (
          <Flex
            justifyContent="flex-start"
            alignItems="flex-start"
            height="100%"
          >
            <Box m="70px auto" style={{ width: isMd ? "100%" : "420px" }}>
              <Msg
                fontWeight="bold"
                fontSize={22}
                color="#202020"
                lineHeight={1.2}
                marginBottom="25px"
                display="block"
              >
                Oops… It seems like your subscription has expired!
              </Msg>

              <Msg fontSize={14} color="#8B8B8B" display={"block"}>
                Please, contact your company owner to extend the subscription
                plan and get unlimited access to our services.
              </Msg>
            </Box>
          </Flex>
        )}
      </LoginFlowWrapper>
      {!isMd && (
        <BoxWrapper position="fixed" right="0px">
          <img src={SmallBoatImg} width="100%" height="100%" alt="background" />
        </BoxWrapper>
      )}
    </Flex>
  );
};
