import { Checkbox, Input, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Api } from "../../api/api";
import { ERecurringStatus, IJob, IWorkOrderWithJobs } from "../../api/types";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import routes from "../../routes/routes";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import { notification } from "antd/es";
import { getErrors, showNonFieldsErrors } from "../../utils/utils";
import {track} from "../../utils/analytics";

const StyledModal = styled(Modal)`
    .ant-modal-header .ant-modal-title {
        color: #FB4D4F;
    }
`;

const ModalContent = styled(Flex)`
    flex-direction: column;
    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        >label.ant-checkbox-group-item {
            border: 1px solid #EDEDED;
            border-radius: 4px;
            padding: 4px 12px;
            margin-right: 0px;
            margin-bottom: 8px;
            &.ant-checkbox-wrapper-checked {
                border: 1px solid #0496FF;
            }
            >span {
                color: #8B8B8B;
                font-weight: 300;
                font-size: 13px;
            }
        }
    }
`;

const ModalText = styled(Msg)`
    font-weight: 300;
    font-size: 18px;
`;

const MainCheckBox = styled(Checkbox)`
    margin: 16px 0 8px 13px;
    >span {
        color: #202020;
        font-weight: 400;
        font-size: 13px;
    }
`;

export type TRecurringModalProps = {
    visible: boolean;
    workOrder: IWorkOrderWithJobs|null;
    jobs: IJob[];
    onClose: () => void;
    loadData: () => void;
}

const RecurringModal: React.FC<TRecurringModalProps> = ({ visible, onClose, workOrder, jobs, loadData }) => {
    const [selectedJobs, setSelectedJobs] = useState<number[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [reason, setReason] = useState("");
    const history = useHistory();
    const profile = useAppSelector(getCurrentUser)

    const defaultOptions = useMemo(() => jobs.map(j => ({value: j.id, label: j.title})), [jobs])
    
    const isMultipleJobs = () => jobs.length >= 2
    const isTouched = () => jobs.some(j => j.is_approved)
    
    const formatOptions = () => defaultOptions.map(option => option.value)

    const handleMakeRecurring = async () => {
        await track('Service Plan Created', {
            customerId: workOrder?.customer.id,
            workorderName: workOrder?.title,
            workorderId: workOrder?.id,
            serviceplanCreatedBy: profile?.id,
            vesselId: workOrder?.vessel.id,
            vesselName: workOrder?.vessel?.name,
            vesselCity: workOrder?.current_address?.city,
            vesselState: workOrder?.current_address?.state,
            vesselType: workOrder?.vessel.vessel_type,
            vesselLengthFeet: workOrder?.vessel.length,
            planTotal: workOrder?.recurring_config?.recurring_total_amount,
            pricePerPeriod:  workOrder?.recurring_config?.recurring_payment_amount
        });
        if (!profile?.company?.stripe?.active) {
            history.push(routes.settings.Payments)
        }
        if (workOrder) {
            const clone = isMultipleJobs() || isTouched();
            const { data } = await Api.workOrders.makeRecurring({
                id: workOrder.id, 
                clone, 
                ...(isMultipleJobs() && {jobs: selectedJobs})
            })
            onClose();
            if (clone) {
                history.push(routes.workOrder.UpdateWorkOrder.replace(":id", String(data.id)));
                return;
            }
            await loadData();
        }
    }

    const handleStopRecurring = async () => {
        if (workOrder?.recurring_status !== ERecurringStatus.Approved) {
            await Api.workOrders.delete(workOrder?.id ?? 0);
            history.push(routes.main.Dashboard)
            return;
        }
        if (!reason) {
            notification.error({message: "Please input reason!"})
            return;
        }
        if (workOrder) {
            try {
                await Api.workOrders.cancelRecurring({id: workOrder.id, message: reason})
                onClose();
                await loadData();
            }
            catch (err) {
                showNonFieldsErrors(getErrors(err))
            }
        }
    }

    const onOk = async () => {
        console.log(loadData, workOrder)
        if (workOrder?.is_recurring) {
            handleStopRecurring();
            return;
        }
        if (profile?.company && !profile?.company.stripe?.charges_enabled) {
            history.push(routes.settings.Payments)
            return;
        }


        handleMakeRecurring();
    }

    const onChange = (list: CheckboxValueType[]) => {
        setSelectedJobs(list as number[]);
        setIndeterminate(!!list.length && list.length < defaultOptions.length);
        setCheckAll(list.length === defaultOptions.length);
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setSelectedJobs(e.target.checked ? formatOptions() : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const renderData = () => {
        if (workOrder?.is_recurring) {
            if (workOrder.recurring_status === ERecurringStatus.Approved) {
                return <>
                <ModalText>Are you sure want to stop your service plan?</ModalText>
                <ModalText>Please input reason below</ModalText>
                <Input.TextArea 
                    placeholder="Reason" 
                    value={reason} 
                    onChange={e => setReason(e.target.value)} 
                    style={{margin: "8px 0"}}
                    />
                </>;
            }
            return <ModalText>Are you sure want to delete your service plan?</ModalText>
        }

        if (!profile?.company?.stripe?.active) {
            return <ModalText>Please set up your payments to be able to create service plans</ModalText>;
        }
        if (!isMultipleJobs()) {
            if (!isTouched()) {
                return <ModalText><p>You are about to convert your job {workOrder?.title} to a service plan with recurring jobs and billing.</p><p> Click Proceed to create the service plan and set the recurring schedule and pricing.</p></ModalText>
            }
            return <>
                <ModalText>Work Order {workOrder?.title} can't be converted to service plan.</ModalText>
                <ModalText>Do you want to clone information from this Work Order and proceed with new service plan?</ModalText>
            </>;
        }
        else {
            return <>
                <ModalText>Work Order {workOrder?.title} can't be converted to service plan.</ModalText>
                <ModalText>You can proceed with creating a new one by selecting job(s) below.</ModalText>
                <MainCheckBox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>Select all for multiply jobs Service Plan</MainCheckBox>
                <Checkbox.Group options={defaultOptions} onChange={onChange} value={selectedJobs} />
            </>
        }
    }   

    useEffect(() => {
        setSelectedJobs([]);
    }, [jobs])

    return <StyledModal
        visible={visible}
        onOk={onOk}
        onCancel={onClose}
        title="Service Plan"
        okText={profile?.company?.stripe?.active ? "Proceed" : "To settings"}
        width={384}
    >
        <ModalContent>
            {renderData()}        
        </ModalContent>
    </StyledModal>;
}

export default RecurringModal;