import { Form, FormInstance, Input, Checkbox, Collapse } from "antd"
import styled from "styled-components"
import { Msg } from "../../ui/Text"
import { EmptyButton } from "../../ui/Button"
import Flex from "../../noui/Flex"
import { LeftOutlined } from "@ant-design/icons"
import { pricePattern } from "../../utils/regex"
import React from "react"
import { TArgCallback, TCallback, TWithMomentDates } from "../../types"
import { ECardType, ICreatePaymentRecord, IWorkOrder } from "../../api/types"
import { AmountInput, CustomDatePicker, OptionBody, OptionContainer, OptionTitle, StyledType } from "./styled"
import { OptionsSide } from "./OptionsSide"
import { CustomInput } from "../../ui/Input"
import { CustomSelect } from "../../ui/Select"
import moment from "moment"
import Warning from "../../assets/images/Warning.svg"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import NumberFormat from "react-number-format"
const { Panel } = Collapse

const WarningBlock = styled(Flex)`
   border: 1px solid #e6e6e6;
   border-radius: 4px;
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`

const dateFormat = "MM/DD/YYYY"

const types = [
   { value: "master_card", title: "Master Card" },
   { value: "visa", title: "Visa" },
   { value: "amex", title: "Amex" },
   { value: "other", title: "Other" },
]

type TSelectorProps = {
   cardType: ECardType
   onSelect: TArgCallback<ECardType>
}
const CardSelector: React.FC<TSelectorProps> = ({ cardType, onSelect }) => {
   return (
      <Flex justifyContent="space-between" style={{ gap: "8px" }}>
         {types.map((t) => (
            <StyledType
               key={t.value}
               selected={t.value === cardType}
               onClick={() => onSelect(t.value as ECardType)}
               justifyContent="center"
               alignItems="center"
            >
               <Msg fontSize={13} color={t.value === cardType ? "#109cf1" : "#828282"} lineHeight="22px">
                  {t.title}
               </Msg>
            </StyledType>
         ))}
      </Flex>
   )
}

export type TCreateRecord = TWithMomentDates<ICreatePaymentRecord, "date">
type TProps = {
   form: FormInstance<TCreateRecord>
   onSubmit: TArgCallback<TCreateRecord>
   option: string
   onClose: TCallback
   workOrder: IWorkOrder | null | undefined
}
const PaymentOption: React.FC<TProps> = ({ form, option, onClose, onSubmit, workOrder }) => {
   const isMd = useBreakpoint(down("md"))

   const handleSelectCT = (ct: ECardType) => {
      form.setFieldsValue({
         card_info: {
            ...form.getFieldValue("card_info"),
            card_type: ct,
         },
      })
   }

   const getOptions = () => {
      switch (option) {
         case "card":
            return (
               <>
                  <Form.Item name={["card_info", "last_4"]} label="Last 4 Digits">
                     <CustomInput placeholder="e.g. 1234" />
                  </Form.Item>
                  <Form.Item shouldUpdate label="Type">
                     {() => (
                        <CardSelector
                           cardType={form.getFieldValue(["card_info", "card_type"])}
                           onSelect={handleSelectCT}
                        />
                     )}
                  </Form.Item>
                  <Form.Item label="Confirmation #" name={["card_info", "confirmation_number"]}>
                     <CustomInput />
                  </Form.Item>
               </>
            )
         case "check":
            return (
               <Form.Item label="Check Number" name={"check_number"}>
                  <CustomInput placeholder="e.g. 12345678" />
               </Form.Item>
            )
         case "deposit":
            return (
               <>
                  <Flex flexDirection={isMd ? "column" : "row"} width={"100%"} style={{ gap: 12 }}>
                     <Form.Item name="account_number" label="Account number">
                        <CustomInput placeholder="xxxx1234" />
                     </Form.Item>
                     <Form.Item name="account_type" label="Account type" style={{ flexGrow: 1 }}>
                        <CustomSelect>
                           <CustomSelect.Option value="type">Account type</CustomSelect.Option>
                        </CustomSelect>
                     </Form.Item>
                  </Flex>
                  <Flex flexDirection={isMd ? "column" : "row"} width={"100%"} style={{ gap: 12 }}>
                     <Form.Item name="routing_number" label="Routing number">
                        <CustomInput placeholder="xxxx1234" />
                     </Form.Item>
                     <Form.Item name="phone" label="Phone number" style={{ flexGrow: 1 }}>
                        <CustomInput placeholder="(055) 505 5505" />
                     </Form.Item>
                  </Flex>
                  <Form.Item name="holder_name" label="Name" style={{ width: "100%" }}>
                     <CustomInput placeholder="Kyle Bunch" />
                  </Form.Item>
                  <Checkbox>Use this account information in the future</Checkbox>
                  <WarningBlock py="16px" px="20px" my="18px" alignItems="center">
                     <img src={Warning} alt="Warning" />
                     <Msg fontSize={13} ml="9px">
                        You have 10 minutes to process this transaction
                     </Msg>
                  </WarningBlock>
                  <Checkbox>
                     I have signed authorization from my customer or will read the authorization script
                  </Checkbox>
               </>
            )
      }
   }

   return (
      <OptionContainer>
         <OptionBody flexDirection="column">
            <Form form={form} onFinish={onSubmit} layout="vertical" style={{ height: "100%" }}>
               <Flex style={{ height: "100%" }} flexDirection="column">
                  <Flex justifyContent="space-between" alignItems="center">
                     <OptionTitle fontWeight={900} fontSize={15}>
                        {option !== "deposit" ? `Record ${option} payment` : "Enter bank account"}
                     </OptionTitle>
                  </Flex>
                  <EmptyButton
                     onClick={onClose}
                     style={{
                        textAlign: "left",
                        fontSize: "13px",
                        marginBottom: "12px",
                        boxShadow: "none",
                        height: "fit-content",
                     }}
                  >
                     <Msg color="#109CF1" fontWeight={700} fontSize={13} letterSpacing="1%">
                        <LeftOutlined style={{ marginRight: "4px" }} />
                        Back to Payment types
                     </Msg>
                  </EmptyButton>
                  <Flex flexDirection="column" justifyContent="space-between" style={{ height: "100%" }}>
                     <Flex
                        width={"100%"}
                        flexDirection={isMd ? "column" : "row"}
                        style={{ gap: 12, display: option === "deposit" ? "none" : "flex" }}
                     >
                        <Form.Item
                           style={{ flexGrow: 1 }}
                           rules={[
                              {
                                 required: true,
                                 message: "Date is required.",
                              },
                           ]}
                           initialValue={moment()}
                           name={"date"}
                           label="Date"
                        >
                           <CustomDatePicker format={dateFormat} />
                        </Form.Item>
                        <Form.Item
                           label="Payment Amount"
                           name="amount"
                           rules={[
                              {
                                 required: true,
                                 pattern: pricePattern,
                                 message: "Invalid price entered",
                              },
                           ]}
                        >
                           <AmountInput placeholder="0.00" prefix="$" type="number" />
                        </Form.Item>
                     </Flex>

                     {getOptions()}
                     {option !== "deposit" && (
                        <Form.Item name="note" label={"Notes"}>
                           <Input.TextArea rows={3} placeholder="e.g. Payment for entire order" />
                        </Form.Item>
                     )}
                  </Flex>
               </Flex>
            </Form>
         </OptionBody>
         {option !== "deposit" &&
            (isMd ? (
               <Collapse>
                  <Panel key="payment" header="Show details">
                     <OptionsSide workOrder={workOrder} form={form} />
                  </Panel>
               </Collapse>
            ) : (
               <OptionsSide workOrder={workOrder} form={form} />
            ))}
      </OptionContainer>
   )
}

export default PaymentOption
