import { Form, Input, notification, Select, Spin } from "antd";
import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { setCurrentUser } from "../../config/reducers/user/actions";

import {
  ContentStyled,
  PersonalInfoContainer,
  PersonalInfoWrapper,
  MainInfoContainer,
  UploadtContainerAfter,
  ImageWrapperFlex,
  UploadWrapper,
  UploadContainer,
  ImageContainer,
  UploadtContent,
  CloseLogoButton,
  MainUpload,
  UploadtContainerAfterMsg,
  SaveBtn,
  CancelBtn,
} from "./styled";
import { Msg } from "../../ui/Text";
import Flex from "../../noui/Flex";
import { Api } from "../../api/api";
import { instance } from "../../api";
import { getErrors, setDefaultFields } from "../../utils/utils";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { getCurrentUser } from "../../config/reducers/user/selectors";

const certCategories = [
  "certifications",
  "manufacturer_certifications",
  "marine_associations",
];

const Profile = () => {
  const [saving, setSaving] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [certifications, setCertifications] = useState([]);
  const [manufacturerCertifications, setManufactuerCertifications] = useState(
    []
  );
  const [marineAssociations, setMarineAssociations] = useState([]);
  const dispatch = useAppDispatch();
  const isXs = useBreakpoint(down("xs"));
  const profile = useAppSelector(getCurrentUser);

  const loadCards = async () => {
    const { data: trade } = await instance.get("/certifications");
    const { data: manufacturer } = await instance.get(
      "/manufacturer-certifications"
    );
    const { data: marine } = await instance.get("/marine-associations");
    setCertifications(trade);
    setManufactuerCertifications(manufacturer);
    setMarineAssociations(marine);
    await Api.user.profile().then(({ data: { avatar, ...data } }) => {
      setDefaultFields(form, data);
      setAvatar(avatar);
    });
  };

  useEffect(() => loadCards(), []);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      setIsImageUploading(true);
      if (!file) {
        throw new Error();
      }
      if (["image/jpeg", "image/png"].indexOf(file.type) === -1) {
        notification.error({
          message:
            "You can upload only image file. Supported image formats: jpeg, png.",
        });
        throw new Error();
      } else if (file.size > 1048576) {
        notification.error({
          message: "File size must be less than 1MB.",
        });
        throw new Error();
      }
      const baseFile = await getBase64(file);
      await Api.user.patch({ avatar: baseFile });
      await setAvatar(baseFile);
      onSuccess("Ok");
    } catch (err) {
      onError(err);
    } finally {
      setIsImageUploading(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width >= 1024) {
            notification.error({
              message: "Image width should be less than 1024px",
            });
            reject();
            return;
          } else if (img.height >= 1024) {
            notification.error({
              message: "Image height should be less than 1024px",
            });
            reject();
            return;
          }
          resolve(reader.result);
        };
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveLogo = () => {
    Api.user.patch({ id: form.getFieldValue("id"), avatar: null }).then(() => {
      setAvatar(null);
      form.setFields([{ name: "avatar", touched: false, value: null }]);
    });
  };

  const handleSubmit = async () => {
    const data = await form.validateFields();
    if (data.avatar?.file) {
      data.avatar = avatar;
    } else {
      delete data.avatar;
    }
    setSaving(true);
    try {
      const profileCertifications = [...certCategories].reduce(
        (obj, c) => ({
          ...obj,
          [c]: Array.isArray(form.getFieldValue(c))
            ? form.getFieldValue(c)
            : [form.getFieldValue(c)],
        }),
        {}
      );
      const { data: resp } = await Api.user.patch({
        ...data,
        ...profileCertifications,
      });
      dispatch(setCurrentUser(resp));
      form.resetFields();
      setDefaultFields(form, resp);
      notification.success({
        message: "Profile updated successfully",
      });
    } catch (e) {
      const errors = getErrors(e);
      form.setFields(errors);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Profile - DockWorks Pro</title>
      </Helmet>
      <Flex p={isXs ? "14px" : "34px"} pt="15px" flexDirection="column">
        <Msg
          fontWeight={800}
          fontSize={24}
          mb="18px"
          mt={isXs ? "16px" : "76px"}
        >
          Update personal info
        </Msg>
        <ContentStyled
          flexDirection="column"
          pb={!isXs ? "20px" : "0px"}
          pt={!isXs ? "30px" : "0px"}
        >
          <PersonalInfoContainer flexDirection="column" p={16}>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Msg fontWeight={800} fontSize={15} color="#484848" mb="16px">
                Personal Info
              </Msg>
              <PersonalInfoWrapper pb="32px" mt="16px">
                <MainInfoContainer
                  flexDirection="column"
                  maxWidth="440px"
                  mr="16px"
                >
                  <Form.Item
                    name="first_name"
                    label="First name"
                    rules={[
                      {
                        required: true,
                        message: "This field shall not be empty",
                      },
                    ]}
                  >
                    <Input placeholder="Type here..." />
                  </Form.Item>
                  <Form.Item
                    name="last_name"
                    label="Last name"
                    rules={[
                      {
                        required: true,
                        message: "This field shall not be empty",
                      },
                    ]}
                  >
                    <Input placeholder="Type here..." />
                  </Form.Item>
                  <Form.Item name="title" label="Title">
                    <Input placeholder="Type here..." />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Input valide email",
                      },
                    ]}
                  >
                    <Input placeholder="Type here..." />
                  </Form.Item>
                  <Form.Item name="phone" label="Phone (optional)">
                    <Input placeholder="(408) 505-5505" />
                  </Form.Item>
                  <Form.Item name="certifications" label="Trade Certification">
                    <Select placeholder="Select Trade Certification">
                      {certifications.map((c) => (
                        <Select.OptGroup label={c.name}>
                          {c.certifications.map((c) => (
                            <Select.Option key={c.id} value={c.id}>
                              {c.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="manufacturer_certifications"
                    label="Manufacturer Certification"
                  >
                    <Select placeholder="Select Manufacturer Certification">
                      {manufacturerCertifications.map((c) => (
                        <Select.Option
                          key={c.id}
                          value={c.id}
                        >{`${c.manufacturer.name} - ${c.name}`}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="marine_associations"
                    label="Association Membership"
                  >
                    <Select placeholder="Select Association Membership">
                      {marineAssociations.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </MainInfoContainer>
                <Spin spinning={isImageUploading}>
                  <UploadtContainerAfter flexDirection="column">
                    <UploadContainer>
                      <Form.Item
                        name="avatar"
                        label={<label style={{ color: "white" }}>Logo</label>}
                      >
                        {avatar ? (
                          <ImageWrapperFlex>
                            <ImageContainer image={avatar}>
                              <CloseLogoButton
                                className="cancelButton"
                                onClick={handleRemoveLogo}
                              >
                                X
                              </CloseLogoButton>
                            </ImageContainer>
                          </ImageWrapperFlex>
                        ) : (
                          <MainUpload
                            type="file"
                            name="avatar"
                            customRequest={uploadImage}
                          >
                            <UploadWrapper>
                              <UploadtContent />
                              <Msg
                                fontWeight={400}
                                fontSize={13}
                                color="#828282"
                                mt="24px"
                                center
                              >
                                Shop Photo{" "}
                              </Msg>
                              <Msg
                                fontWeight={400}
                                fontSize={13}
                                color="#828282"
                                center
                              >
                                Drag image here or click to add
                              </Msg>
                            </UploadWrapper>
                          </MainUpload>
                        )}
                      </Form.Item>
                    </UploadContainer>
                    <UploadtContainerAfterMsg
                      fontWeight={400}
                      fontSize={13}
                      style={{ textAlign: "center" }}
                      color="#484848"
                      center
                      m="auto"
                    >
                      Your photo will appear on quotes, invoices, work orders,
                      and work request forms.
                    </UploadtContainerAfterMsg>
                  </UploadtContainerAfter>
                </Spin>
              </PersonalInfoWrapper>
              <Flex justifyContent="flex-end" width="100%">
                <Form.Item shouldUpdate style={{ width: "100%" }}>
                  {() => (
                    <>
                      <NavLink to="/dashboard">
                        <CancelBtn>Cancel</CancelBtn>
                      </NavLink>
                      <SaveBtn
                        loading={saving}
                        disabled={!form.isFieldsTouched()}
                        l={16}
                        htmlType="submit"
                      >
                        Save
                      </SaveBtn>
                    </>
                  )}
                </Form.Item>
              </Flex>
            </Form>
          </PersonalInfoContainer>
        </ContentStyled>
      </Flex>
    </>
  );
};

export default Profile;
