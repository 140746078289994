import React from 'react';
import styled from "styled-components";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {TCallback} from "../../types";

const ButtonWrp = styled.span`
    margin: 12px 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`
const Label = styled.span`
    margin-left: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #109CF1;
`

type TProps = {
    onClick: TCallback;
}
export const AddButton: React.FC<React.PropsWithChildren<TProps>> = ({children, onClick}) => {
    return <ButtonWrp onClick={onClick}>
        <Button size="large" type="primary" shape="circle" icon={<PlusOutlined />} />
        <Label>{children}</Label>
    </ButtonWrp>;
};