import React, { useCallback } from "react"
import Flex from "../../../../noui/Flex"
import styled from "styled-components"
import { Msg } from "../../../../ui/Text"
import { EmptyButton } from "../../../../ui/Button"
import { useHistory } from "react-router-dom"
import routes from "../../../../routes/routes"

const CartContainer = styled(Flex)`
   border-radius: 4px;
   background: #eef7fd;
   margin: 4px 8px;
   // max-width: 128px;
`

type TProps = {
   scheduledCount: number | undefined
}

const ScheduledCard: React.FC<TProps> = ({ scheduledCount }) => {
   const history = useHistory()

   const handleEdit = useCallback(() => {
      history.push(routes.jobs.Calendar)
   }, [history])

   return (
      <CartContainer width="100%" height="153px" flexDirection="column" alignItems="center" pt="12px" pb="15px">
         <Msg fontWeight={900} fontSize={13} color="#0E497B" mb="10px">
            Jobs scheduled
         </Msg>
         <Msg fontWeight={800} fontSize={52} color="#257ABF">
            {scheduledCount}
         </Msg>
         <EmptyButton width="fit-content" onClick={handleEdit}>
            <Msg fontWeight={600} fontSize={13} color="#0E497B">
               view details
            </Msg>
         </EmptyButton>
      </CartContainer>
   )
}

export default ScheduledCard
