import React from 'react';
import UploadImgSvg from "../assets/images/UploadPhotoBig.svg";
import {ReactComponent as ListSvg} from "../assets/images/JobsList.svg"
import {ReactComponent as CardsSvg} from "../assets/images/CardsIcon.svg"
import Icon from "@ant-design/icons";
import {TIconProps} from "../types";

export const UploadImage: React.FC<TIconProps> = (props) => {
  return <Icon {...props} component={() => <img src={UploadImgSvg} className="custom-icon" alt="icon"/>}  />
};

const StopSvg = () => {
  return <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 128 128">
    <rect x="27" y="27" width="74" height="74" />
  </svg>
}

export const StopIcon: React.FC<TIconProps> = (props) => {
  return <Icon {...props} component={StopSvg} />
}

export const ListIcon: React.FC<TIconProps> = (props) => {
  return <Icon {...props} component={ListSvg} />
}
export const CardIcon: React.FC<TIconProps> = (props) => {
  return <Icon {...props} component={CardsSvg} />
}