import { useState } from "react"
import styled from "styled-components"
import { IMaterialGroup } from "../../api/types"
import Flex from "../../noui/Flex"
import { TArgCallback } from "../../types"
import { Button, Input } from "antd"
import { SearchWithBorder } from "../../noui/Search"

const LeftBlock = styled.div`
   width: 100%;
   max-width: 260px;
   margin: 75px 20px 20px 20px;
`
const ListWrapper = styled.div`
   padding: 10px 10px 10px 15px;
   margin-bottom: 15px;
`
const LeftBlockMenu = styled.div`
   width: 100%;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;

   h3 {
      padding: 8px 15px;
      border-bottom: 1px solid #ededed;
      font-size: 13px;
      font-weight: bold;
   }
   ul {
      list-style: none;
      padding: 0 15px 0 0;
      max-height: 400px;
      overflow-y: auto;
      margin: 0;

      &::-webkit-scrollbar {
         width: 12px;
      }

      &::-webkit-scrollbar-track {
         background: #ebf1f6;
         border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
         background-color: #0496ff;
         border-radius: 8px;
         border: 4px solid #ebf1f6;
      }
      & {
         scrollbar-width: auto;
         scrollbar-color: #0496ff #ebf1f6;
         scrollbar-width: thin;
      }
      li {
         font-weight: bold;
         font-size: 18px;
         margin-bottom: 10px;
         cursor: pointer;
         &.active {
            color: #096dd9;
         }
      }
   }
`
const CreateGroup = styled(Button)`
   width: 100%;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   color: #0496ff;
   font-weight: bold;
   font-size: 15px;
   border: none;
   height: 48px;
   &:hover {
      border: 1px solid #0496ff;
   }
`
const CreateGroupInput = styled(Flex)`
   width: 100%;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   padding: 5px;
   height: 48px;
   position: relative;
   &.error {
      border: 1px solid #fb4d4f;
   }
   input {
      border: none;
      outline: none;
      box-shadow: none;
      &:focus {
         border: none;
         outline: none;
         box-shadow: none;
      }
   }
   button {
      border: none;
      color: #0496ff;
      font-weight: bold;
      font-size: 13px;
   }
`
const InputError = styled.div`
   position: absolute;
   left: 3px;
   top: 105%;
   color: #fb4d4f;
   font-size: 11px;
   width: 100%;
`
const SearchField = styled(SearchWithBorder)`
   border-radius: 4px;
`
const SearchWrapper = styled.div`
   margin: 20px 15px;
`
type TNewGroup = {
   name: string | ""
   error: string | ""
}

type TProps = {
   group?: IMaterialGroup[]
   activeGroup?: IMaterialGroup | null
   handleCreateGroupe: TArgCallback<string>
   setActiveGroup: TArgCallback<any>
   setSearch: TArgCallback<any>
   type?: string
}

const Groups: React.FC<TProps> = ({ group, activeGroup, handleCreateGroupe, setActiveGroup, setSearch, type }) => {
   const [createGroup, setCreateGroup] = useState<boolean>(false)
   const [newGroup, setNewGroup] = useState<TNewGroup>({
      name: "",
      error: "",
   })

   const handleChange = (e: any) => {
      setNewGroup({
         ...newGroup,
         name: e.target.value,
         error: "",
      })
   }

   const handleCreate = () => {
      if (newGroup.name.trim().length > 0 && newGroup.name.trim().length < 26) {
         handleCreateGroupe(newGroup.name)
         setCreateGroup(false)
         setNewGroup({
            name: "",
            error: "",
         })
      } else if (newGroup.name.trim().length === 0) {
         setNewGroup({
            ...newGroup,
            error: "Please, input name!",
         })
      } else if (newGroup.name.trim().length > 25) {
         setNewGroup({
            ...newGroup,
            error: "Ensure this field has no more than 25 characters!",
         })
      }
   }

   return (
      <LeftBlock>
         <LeftBlockMenu>
            <h3>{type === "rateCard" ? "Service" : "Material"} group name</h3>
            <SearchWrapper>
               <SearchField
                  placeholder={`Search ${type === "rateCard" ? "service" : "material"} group`}
                  onChange={(e) => setSearch(e.target.value)}
               />
            </SearchWrapper>
            <ListWrapper>
               {group && group.length > 0 ? (
                  <ul>
                     {group.map((item: any) => {
                        return (
                           <li
                              className={item.id === activeGroup?.id ? "active" : ""}
                              key={item.id}
                              onClick={() => setActiveGroup(item)}
                           >
                              {item.name}
                           </li>
                        )
                     })}
                  </ul>
               ) : (
                  <ul>
                     <li>No data</li>
                  </ul>
               )}
            </ListWrapper>
         </LeftBlockMenu>
         {createGroup ? (
            <CreateGroupInput alignItems="center" className={newGroup.error ? "error" : ""}>
               <Input value={newGroup.name} onChange={handleChange} />
               <Button onClick={handleCreate}>Add</Button>
               <InputError>{newGroup.error}</InputError>
            </CreateGroupInput>
         ) : (
            <CreateGroup onClick={() => setCreateGroup(true)}>
               + Create {type === "rateCard" ? "Service" : "Material"} Group
            </CreateGroup>
         )}
      </LeftBlock>
   )
}
export default Groups
