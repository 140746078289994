import React from "react"
import Flex from "../../../../../noui/Flex"
import styled from "styled-components"
import { Msg } from "../../../../../ui/Text"
import { TCompletedItem } from "../types"

const CardContainer = styled(Flex)`
   border-radius: 4px;
   background: #e7fff1;
   // max-width: 128px;
   margin: 4px 6px;
`

type TProps = {
   cardInfo: TCompletedItem
}

const Completed: React.FC<TProps> = ({ cardInfo }) => {
   return (
      <CardContainer width="100%" height="153px" flexDirection="column" alignItems="center" pt="12px" pb="15px">
         <Msg fontWeight={900} fontSize={13} color="#1D864A" mb="10px">
            Jobs completed
         </Msg>
         <Msg fontWeight={800} fontSize={52} color="#2AA15D">
            {cardInfo.count}
         </Msg>
         <Msg fontWeight={600} fontSize={13} color="#28A95F">
            {cardInfo.title}
         </Msg>
      </CardContainer>
   )
}

export default Completed
