import { Modal } from "antd"
import { EditIcon } from "../../assets/icons"
import styled from "styled-components"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"

const StyledModal = styled(Modal)`
   .ant-modal-body {
      padding: 16px;
   }
   .ant-modal-content {
      border-radius: 4px;
   }
`

const FullName = styled(Msg)`
   text-transform: uppercase;
`

const OptionButton = styled(EmptyButton)`
   margin-left: 8px;
   font-size: 15px;
   color: rgba(0, 0, 0, 0.54);
   svg {
      vertical-align: middle;
   }
`

const Label = styled(Msg)`
   font-size: 13px;
   font-weight: 700;
   margin-bottom: 8px;
`

const InfoItem = styled(Flex)`
   padding: 5px 10px;
   border: 1px solid rgba(230, 230, 230, 1);
   border-radius: 4px;
   margin-bottom: 16px;
   word-break: break-word;
   height: 100%;
   font-size: 13px;
`

const InfoContainer = styled(Flex)`
   width: 48%;
`

const EmptyField = styled(Msg)`
   font-size: 13px;
   color: rgba(130, 130, 130, 1);
`

export const CustomerInfo = ({ customer, visible, setVisible, setEdit }) => {
   const {
      first_name,
      company_name,
      job_title,
      last_name,
      phones,
      email,
      address: { address1, city, state, zip_code },
      note,
      vessels
   } = customer

   const filterPhones = (criteria, phones) => {
      return phones
         .filter((phone) => criteria.includes(phone.phone_type))
         .map((phone) => phone.phone)
         .join(", ")
   }

   const formatVesselName = () => {
      return vessels.map(v => v.name).join(", ")
   }

   return (
      <StyledModal visible={visible} destroyOnClose width={384} onCancel={() => setVisible(false)} footer={null}>
         <Flex alignItems="center" mb="10px">
            <FullName fontSize={15} fontWeight={900}>{`${first_name} ${last_name}`}</FullName>
            <OptionButton
               onClick={() => {
                  setVisible(false)
                  setEdit(true)
               }}
            >
               <EditIcon />
            </OptionButton>
         </Flex>
         <Flex flexDirection="column">
            <Label>Vessel(s) Name</Label>
            <InfoItem>{formatVesselName()}</InfoItem>
         </Flex>
         <Flex justifyContent="space-between">
            <InfoContainer flexDirection="column">
               <Label>Primary Phone</Label>
               <InfoItem>{filterPhones(["P", "M", "T", "F"], phones) || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
            <InfoContainer flexDirection="column">
               <Label>Email</Label>
               <InfoItem>{email || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
         </Flex>
         <Flex justifyContent="space-between">
            <InfoContainer flexDirection="column">
               <Label>Work Phone</Label>
               <InfoItem>{filterPhones(["W"], phones) || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
            <InfoContainer flexDirection="column">
               <Label>Home Phone</Label>
               <InfoItem>{filterPhones(["H"], phones) || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
         </Flex>
         <Flex justifyContent="space-between">
            <InfoContainer flexDirection="column">
               <Label>Company Name</Label>
               <InfoItem>{company_name || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
            <InfoContainer flexDirection="column">
               <Label>Job Title</Label>
               <InfoItem>{job_title || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
         </Flex>
         <Flex justifyContent="space-between">
            <InfoContainer flexDirection="column">
               <Label>Street</Label>
               <InfoItem>{address1 || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
            <InfoContainer flexDirection="column">
               <Label>Zip code</Label>
               <InfoItem>{zip_code || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
         </Flex>
         <Flex justifyContent="space-between">
            <InfoContainer flexDirection="column">
               <Label>City</Label>
               <InfoItem>{city || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
            <InfoContainer flexDirection="column">
               <Label>State</Label>
               <InfoItem>{state || <EmptyField>No data</EmptyField>}</InfoItem>
            </InfoContainer>
         </Flex>
         <Flex flexDirection="column">
            <Label>Customer Notes</Label>
            <InfoItem>{note || <EmptyField>No data</EmptyField>}</InfoItem>
         </Flex>
      </StyledModal>
   )
}
