import styled from "styled-components"
import { Pagination, Spin } from "antd"
import { TArgCallback, TCallback } from "../../types"
import { IMaterialGroup } from "../../api/types"
import Flex from "../../noui/Flex"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import Groups from "./Groups"
import GroupsMobile from "./GroupsMobile"
import MaterialsListHeader from "./MaterialsListHeader"
import MaterialsTable from "./MaterialsTable"
import NoDataBlock from "./NoDataBlock"
import { SearchWithBorder } from "../../noui/Search"

const List = styled.div`
   width: calc(100% - 300px);
   margin: 20px;
`
const SearchWrapper = styled.div`
   max-width: 545px;
   margin-bottom: 20px;
`
const SearchField = styled(SearchWithBorder)`
   border-radius: 4px;
   max-width: 545px;
   margin-bottom: 20px;
`
const Table = styled.table`
   width: 100%;
   min-height: 200px;

   thead {
      border-bottom: 1px solid #ededed;
   }
   tbody {
      tr {
         border-bottom: 1px solid #ededed;
      }
   }
   th {
      padding: 8px 12px;
      font-size: 13px;
      text-align: left;
   }
   td {
      padding: 20px 12px;
   }
   td {
      vertical-align: top;
   }
`
export const TableWrapper = styled.div`
   width: 100%;
   overflow-x: auto;
   position: relative;
   padding: 0;
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;

   &::-webkit-scrollbar {
      width: 10px;
   }

   &::-webkit-scrollbar-track {
      background: #ebf1f6;
   }

   &::-webkit-scrollbar-thumb {
      background-color: #0496ff;
      border-radius: 8px;
      border: 4px solid #ebf1f6;
   }
   & {
      scrollbar-width: auto;
      scrollbar-color: #0496ff #ebf1f6;
      scrollbar-width: thin;
   }
`

type TProps = {
   group?: IMaterialGroup[]
   activeGroup?: IMaterialGroup | null
   handleCreateGroupe: TArgCallback<string>
   data: any
   handleEditMaterial: TArgCallback<any>
   handleActiveGroup: TArgCallback<any>
   handleDeleteGroup: TCallback
   handleCreateMaterial: TArgCallback<any>
   handleEditGroupName: TArgCallback<any>
   handleChangePagination: (page: number, pageSize: number | undefined) => void
   handleDeleteMaterial: TArgCallback<any>
   handleDuplicateMaterial: TArgCallback<any>
   pagination: any
   setSearchGroup: TArgCallback<any>
   setSearchMaterial: TArgCallback<any>
   columns: any
   type?: string
   loading: boolean
}
const ServicesMaterials: React.FC<TProps> = ({
   handleCreateGroupe,
   handleActiveGroup,
   handleDeleteGroup,
   handleEditGroupName,
   handleCreateMaterial,
   handleEditMaterial,
   handleChangePagination,
   handleDeleteMaterial,
   handleDuplicateMaterial,
   group,
   activeGroup,
   data,
   pagination,
   setSearchGroup,
   setSearchMaterial,
   columns,
   type,
   loading,
}) => {
   const isMd = useBreakpoint(down("md"))

   return (
      <>
         {!isMd ? (
            <Flex>
               <Groups
                  group={group}
                  handleCreateGroupe={handleCreateGroupe}
                  activeGroup={activeGroup}
                  setActiveGroup={handleActiveGroup}
                  setSearch={setSearchGroup}
                  type={type}
               />
               <List>
                  {activeGroup ? (
                     <>
                        <div>
                           <MaterialsListHeader
                              group={activeGroup}
                              deleteGroup={handleDeleteGroup}
                              editGroupName={handleEditGroupName}
                              handleCreateMaterial={handleCreateMaterial}
                              groups={group}
                              handleCreateGroupe={handleCreateGroupe}
                              type={type}
                           />
                           <SearchWrapper>
                              <SearchField
                                 placeholder={`Search ${type === "rateCard" ? "service" : "material"}`}
                                 onChange={(e) => setSearchMaterial(e.target.value)}
                              />
                           </SearchWrapper>

                           <TableWrapper>
                              <Table>
                                 <thead>
                                    <tr>
                                       {columns &&
                                          columns.length > 0 &&
                                          columns.map((item: any) => {
                                             return <th>{item.label}</th>
                                          })}

                                       <th>Enable</th>
                                       <th>Actions</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <MaterialsTable
                                       data={data}
                                       handleEditMaterial={handleEditMaterial}
                                       deleteMaterial={handleDeleteMaterial}
                                       duplicateMaterial={handleDuplicateMaterial}
                                       group={activeGroup}
                                       groups={group}
                                       handleCreateGroupe={handleCreateGroupe}
                                       columns={columns}
                                       type={type}
                                    />
                                 </tbody>
                              </Table>
                           </TableWrapper>
                        </div>
                        {!!pagination?.total && pagination.total > 10 && (
                           <Flex justifyContent="flex-end">
                              <Pagination
                                 current={pagination.current}
                                 pageSize={pagination.pageSize}
                                 total={pagination.total}
                                 onChange={(page, pageSize) => {
                                    handleChangePagination(page, pageSize)
                                 }}
                                 showSizeChanger
                                 style={{ textAlign: "right", width: "100%", marginTop: "20px" }}
                              />
                           </Flex>
                        )}
                     </>
                  ) : (
                     <NoDataBlock type={type} loading={loading} data={data} />
                  )}
               </List>
            </Flex>
         ) : (
            <GroupsMobile
               groups={group}
               handleCreateGroupe={handleCreateGroupe}
               setActiveGroup={handleActiveGroup}
               setSearch={setSearchGroup}
               data={data}
               handleEditMaterial={handleEditMaterial}
               deleteMaterial={handleDeleteMaterial}
               duplicateMaterial={handleDuplicateMaterial}
               deleteGroup={handleDeleteGroup}
               searchMaterial={setSearchMaterial}
               editGroupName={handleEditGroupName}
               group={activeGroup}
               pagination={pagination}
               handleChangePagination={handleChangePagination}
               handleCreateMaterial={handleCreateMaterial}
               type={type}
            />
         )}
      </>
   )
}
export default ServicesMaterials
