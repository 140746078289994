import { Modal, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Api, TRecurringSchedules } from "../../api/api";
import { Msg } from "../../ui/Text";

type TProps = {
    id: number
    visible: boolean
    billingDate: string
    onOk: () => void
    onCancel: () => void
}

const StyledModal = styled(Modal)`
    .ant-modal-title {
        color: #FB4D4F;
    }
    .ant-modal-body {
        margin-bottom: 16px;
    }
`

export const RecurringBillingWarningModal:React.FC<TProps> = ({id, visible, onOk, onCancel, billingDate}) => {
    const [datesCount, setDatesCount] = useState<number|null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const getDatesCount = (data: TRecurringSchedules) => data?.dates.filter(d => moment(d).isBefore(moment(billingDate), "day"))?.length;

    const loadData = async () => {
        try {
            setIsLoading(true)
            const { data } = await Api.workOrders.getRelativeSchedules(id)
            if (data) {
                setDatesCount(getDatesCount(data))
            }
        } finally {
            setIsLoading(false)
        }        
    }

    useEffect(() => {
        if (visible) {
            loadData()
        }
    }, [visible])

    return <StyledModal
        title="Warning"
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={374}
        okText="Proceed as Scheduled"
        cancelText="Edit Service Plan"
    >
        <Spin spinning={isLoading}>
        <Msg fontSize="18px" fontWeight={300} color="#202020">
            This service plan has {datesCount ?? 0} appointments scheduled before the first payment is due. 
            Do you want to proceed, or edit the billing date and/or first appointment date?
        </Msg>
        </Spin>
    </StyledModal>;
}