import { Link } from "react-router-dom";
import styled from "styled-components";
import { IVessel } from "../../api/types";
import Flex from "../../noui/Flex";
import routes from "../../routes/routes";
import { Msg } from "../../ui/Text";
import { formatAddress } from "../../utils/utils";

const PopoverContainer = styled(Flex)`
    width: 200px;
    flex-direction: column;
    padding: 4px 0;
`;

const MainText = styled(Link)`
    font-weight: 800;
    font-size: 18px;
    text-decoration: underline;
`;

const SupText = styled(Msg)`
    font-weight: 300;
    font-size: 16px;
    color: #151F2B;
`;

type TVesselMapPopoverProps = {
    vessel: IVessel;
}

const VesselMapPopover: React.FC<TVesselMapPopoverProps> = ({vessel}) => {
    return <PopoverContainer>
        <Flex flexDirection="column" marginBottom="24px">
            <MainText to={routes.vessels.Page.replace(":id", String(vessel?.id))}>{vessel?.name}</MainText>
            <SupText>{vessel.customer?.full_name}</SupText>
        </Flex>
        <SupText>{formatAddress(vessel.address)}</SupText>
    </PopoverContainer>
};

export default VesselMapPopover;