import React from "react";
import Flex from "../../noui/Flex";
import { Button } from "antd";
import {
  PaymentsHeaderText,
  PaymentsMainBlock,
  PaymentsMainBlockHeaderText,
  PaymentsMainBlockPaymentTypes,
  PaymentsMainBlockPaymentSpecs,
  PaymentsMainBlockPaymentSpecsItem,
  PaymentsMainBlockCheckBlock,
  PaymentsMainBlockCheckBlockItem,
  PaymentsMainBlockColumnHeader,
  PaymentsMainBlockColumnItem,
  PaymentsMainItemBoldText,
  PaymentsMainItemDefaultText,
  PaymentsMainItemGrayText,
} from "./styled";
import { Msg } from "../../ui/Text";
import StartedVisa from "../../assets/images/settings/StartedVisa.svg";
import StartedMasterCard from "../../assets/images/settings/StartedMasterCard.svg";
import StartedAmericanExpress from "../../assets/images/settings/StartedAmericanExpress.svg";
import StartedDiscover from "../../assets/images/settings/StartedDiscover.svg";
import StartedBankPayment from "../../assets/images/settings/StartedBankPayment.svg";
import StartedInvoice from "../../assets/images/settings/StartedInvoice.svg";
import StartedRecuring from "../../assets/images/settings/StartedRecuring.svg";
import StartedDeposite from "../../assets/images/settings/StartedDeposite.svg";
import StartedCheck from "../../assets/images/settings/StartedCheck.svg";
import StartedStripe from "../../assets/images/settings/StartedStripe.svg";
import StartedCC from "../../assets/images/settings/StartedCC.svg";
import StartedBP from "../../assets/images/settings/StartedBP.svg";
type TProps = {
  onStartClick: () => void;
  isAdmin?: boolean;
  isStripeConnected: boolean;
  stripeSetupIsStarting: boolean;
};
const PaymentsGetStarted: React.FC<TProps> = ({
  onStartClick,
  isAdmin,
  isStripeConnected,
  stripeSetupIsStarting,
}) => {
  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <PaymentsHeaderText style={{ width: "50%" }}>
        DockWorks Payments makes it easy for your business to accept credit
        cards-online and in-person-with the most competitive rates in the
        industry.
      </PaymentsHeaderText>
      <PaymentsMainBlock>
        <PaymentsMainBlockHeaderText>
          your customers can pay you online
        </PaymentsMainBlockHeaderText>
        <PaymentsMainBlockPaymentTypes>
          <img src={StartedVisa} alt="Visa" />
          <img src={StartedMasterCard} alt="MasterCard" />
          <img src={StartedAmericanExpress} alt="AmericanExpress" />
          <img src={StartedDiscover} alt="Discover" />
          <img src={StartedBankPayment} alt="BankPayment" />
        </PaymentsMainBlockPaymentTypes>
        <Msg color="#3E6680" fontSize="15px" fontWeight="700">
          Get paid by your customers using:
        </Msg>
        <PaymentsMainBlockPaymentSpecs>
          <PaymentsMainBlockPaymentSpecsItem>
            <img src={StartedInvoice} alt="Invoice" />
            <Msg color="#484848" fontSize="20px" fontWeight="800">
              Invoices
            </Msg>
            <Msg color="#000000" fontSize="15px" fontWeight="400">
              Fast payments mean better cash flow.
            </Msg>
          </PaymentsMainBlockPaymentSpecsItem>
          <PaymentsMainBlockPaymentSpecsItem>
            <img src={StartedRecuring} alt="Recurring" />
            <Msg color="#484848" fontSize="20px" fontWeight="800">
              Recurring Invoices
            </Msg>
            <Msg color="#000000" fontSize="15px" fontWeight="400">
              Get paid automatically from repeat customers.
            </Msg>
          </PaymentsMainBlockPaymentSpecsItem>
          <PaymentsMainBlockPaymentSpecsItem>
            <img src={StartedDeposite} alt="Deposits" />
            <Msg color="#484848" fontSize="20px" fontWeight="800">
              Deposits & Pre-Payments
            </Msg>
            <Msg color="#000000" fontSize="15px" fontWeight="400">
              Collect money upfront faster, so you can get to work.
            </Msg>
          </PaymentsMainBlockPaymentSpecsItem>
        </PaymentsMainBlockPaymentSpecs>
        {isAdmin ? (
          <Msg
            color="#3E6680"
            fontSize="22px"
            fontWeight="700"
            marginBottom="16px"
          >
            Ask your business owner to activate DockWorks Payments to begin
            accepting online payments!
          </Msg>
        ) : (
          <Button
            type="primary"
            style={{ marginBottom: "32px", width: "280px" }}
            onClick={onStartClick}
          >
            {isStripeConnected || stripeSetupIsStarting
              ? "Continue to Setup Stripe"
              : "Get Started"}
          </Button>
        )}
        <PaymentsMainBlockCheckBlock>
          <PaymentsMainBlockCheckBlockItem>
            <img src={StartedCheck} alt="Check" />
            <Msg fontWeight="bold" margin="0 8px 0 11px">
              No surprise fees, ever.
            </Msg>
            <Msg>No setup, monthly, or hidden fees.</Msg>
          </PaymentsMainBlockCheckBlockItem>
          <PaymentsMainBlockCheckBlockItem>
            <img src={StartedCheck} alt="Check" />
            <Msg fontWeight="bold" margin="0 8px 0 11px">
              You’re in control
            </Msg>
            <Msg>
              Customize payment methods on any invoice, and cancel anytime.
            </Msg>
          </PaymentsMainBlockCheckBlockItem>
          <PaymentsMainBlockCheckBlockItem>
            <img src={StartedCheck} alt="Check" />
            <Msg fontWeight="bold" margin="0 8px 0 11px">
              Flexible for your needs.
            </Msg>
            <Msg>Receive individual or recurring automatic payments.</Msg>
          </PaymentsMainBlockCheckBlockItem>
        </PaymentsMainBlockCheckBlock>
        <Msg color="#3E6680" fontSize="22px" fontWeight="700">
          Two ways to get your invoices paid:
        </Msg>
        <Flex flexDirection="column" marginTop="24px">
          <Flex flexDirection="row">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <img src={StartedCC} alt="Credit Card" />
                <PaymentsMainBlockColumnHeader>
                  Credit cards
                </PaymentsMainBlockColumnHeader>
              </Flex>
              <PaymentsMainBlockColumnItem>
                <Msg>
                  <PaymentsMainItemBoldText>
                    2.9% + 30¢{" "}
                  </PaymentsMainItemBoldText>
                  <PaymentsMainItemDefaultText>
                    per transaction
                  </PaymentsMainItemDefaultText>
                </Msg>
                <Msg>
                  <PaymentsMainItemGrayText>for </PaymentsMainItemGrayText>
                  <PaymentsMainItemBoldText>
                    Visa, Mastercard, Discover
                  </PaymentsMainItemBoldText>
                </Msg>
                <br />
                <Msg>
                  <PaymentsMainItemBoldText>
                    2.9% + 30¢{" "}
                  </PaymentsMainItemBoldText>
                  <PaymentsMainItemDefaultText>
                    per transaction
                  </PaymentsMainItemDefaultText>
                </Msg>
                <Msg>
                  <PaymentsMainItemGrayText>for </PaymentsMainItemGrayText>
                  <PaymentsMainItemBoldText>
                    American Express
                  </PaymentsMainItemBoldText>
                </Msg>
              </PaymentsMainBlockColumnItem>
              <PaymentsMainBlockColumnItem>
                <PaymentsMainItemGrayText>
                  2 business days to get paid out
                </PaymentsMainItemGrayText>
              </PaymentsMainBlockColumnItem>
            </Flex>
            <Flex margin="0 64px">
              <img src={StartedStripe} alt="Stripe" />
            </Flex>
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <img src={StartedBP} alt="Bank Payments" />
                <PaymentsMainBlockColumnHeader>
                  bank payments (ach)
                </PaymentsMainBlockColumnHeader>
              </Flex>
              <PaymentsMainBlockColumnItem>
                <Msg>
                  <PaymentsMainItemBoldText>0.8% </PaymentsMainItemBoldText>
                  <PaymentsMainItemDefaultText>
                    per transaction
                  </PaymentsMainItemDefaultText>
                </Msg>
                <Msg>
                  <PaymentsMainItemDefaultText>
                    with a maximum fee of $5.00
                  </PaymentsMainItemDefaultText>
                </Msg>

                <Msg>
                  <PaymentsMainItemGrayText>
                    3-4 business days to get paid out
                  </PaymentsMainItemGrayText>
                </Msg>
                <a href="https://www.dockworks.co/payments#how-it-works">
                  <PaymentsMainItemBoldText style={{ color: "#109CF1" }}>
                    How does it work?
                  </PaymentsMainItemBoldText>
                </a>
              </PaymentsMainBlockColumnItem>
            </Flex>
          </Flex>
          <Flex flexDirection="row" justifyContent="start" marginTop="24px">
            <Msg fontSize="15px" fontWeight="700">
              By continuing, you are agreeing to the DockWorks Payments
            </Msg>
            <a href="https://www.dockworks.co/payments-terms-of-service">
              <Msg
                color="#109CF1"
                fontSize="15px"
                fontWeight="700"
                marginLeft="8px"
              >
                Terms of Service
              </Msg>
            </a>
          </Flex>
        </Flex>
      </PaymentsMainBlock>
    </Flex>
  );
};
export default PaymentsGetStarted;
