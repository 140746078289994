import React, { useState, useEffect, useCallback, useMemo, useContext } from "react"
import { notification, FormInstance, Form, Button } from "antd"
import { Api, TAddress } from "../../../api/api"
import NumberFormat from "react-number-format"
import { SelectCard, SelectCardTitle, InfoRow, AutocompleteAddButton } from "../styled"
import styled from "styled-components"
import { ICustomer } from "../../../api/types"
import { CustomerModal } from "./CustomerModal"
import Flex from "../../../noui/Flex"
import { NavLink, useLocation, useHistory } from "react-router-dom"
import {phoneFormatter, renderAddress} from "../../../utils/utils"
import { MapMarkerIcon, MessageListIcon, PhoneIcon } from "../../../assets/icons"
import { Msg } from "../../../ui/Text"
import routes from "../../../routes/routes"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { CancelButton } from "./AddVessel"
import { AutocompleteSelect } from "../../../components/Autocomplete/AutocompleteCustomer"
import { WOContext } from "../WorkOrderContext"

export const StyledButton = styled(Button)`
   border: none;
   font-weight: 700;
   color: #109cf1;
   text-align: left;
   font-size: 16px;
   span {
      text-decoration: underline;
   }
`

type TProps = {
   form: FormInstance
   updateData: (key: string, value: number | null) => void
   setCustomerId: (value: number | null) => void
   selectedCustomer: ICustomer | null
   customerId: Array<number | null>
   showSaveModal: (value: boolean) => void
}

export const AddCustomer: React.FC<TProps> = ({
   form,
   updateData,
   selectedCustomer,
   customerId,
   setCustomerId,
   showSaveModal,
}) => {
   const [modal, setModal] = useState<boolean>(false)
   const [customers, setCustomers] = useState<ICustomer[]>([])
   const [customer, setSelectedCustomer] = useState<ICustomer | undefined>()
   const location = useLocation()
   const isXs = useBreakpoint(down("xs"))
   const isLg = useBreakpoint(down("lg"))
   const [lastSelectedCustomer, setLastSelectedCustomer] = useState<ICustomer | undefined>()
   const Context = useContext(WOContext)
   const history = useHistory()

   const loadCustomers = async () => {
      try {
         const { data } = await Api.customers.getAll({ ordering: "full_name" })
         const items = sortItems(data)
         setCustomers(items as ICustomer[])
      } catch (e) {
         notification.error({
            message: "Unable to upload customers",
         })
      }
   }

   const sortItems = (data: any) => {
      return data.sort(function (a: { full_name: string }, b: { full_name: string }) {
         const nameA = a.full_name.toUpperCase()
         const nameB = b.full_name.toUpperCase()
         if (nameA < nameB) {
            return -1
         }
         if (nameA > nameB) {
            return 1
         }
         return 0
      })
   }

   useEffect(() => {
      if (selectedCustomer) {
         setSelectedCustomer(selectedCustomer)
         form.setFieldsValue({ customer: selectedCustomer.id })
      }
   }, [form, selectedCustomer])

   useEffect(() => {
      loadCustomers()
   }, [])

   useEffect(() => {
      if (customer && customer.id) {
         updateData("customer", customer.id)
      }
   }, [customer])

   useEffect(() => {
      if (customerId[0] != null && !customerId[1] && customers.length > 0) {
         setSelectedCustomer(customers.find((c) => c.id === customerId[0]))
         updateData("customer", customerId[0])
      }
   }, [customerId, customers])

   const handleSelect = (v: number) => {
      const customer = customers.find((c) => c.id === v)
      setSelectedCustomer(customer)

      updateData("customer", v)
      if (customer && customer.vessels && customer.vessels.length > 0) {
         //updateData("vessel", customer?.vessels[0].id)
      }
   }

   const handleEdit = () => {
      setLastSelectedCustomer(customer)
      setSelectedCustomer(undefined)
      setCustomerId(null)
      updateData("customer", null)
      updateData("vessel", null)
   }

   const handleCancel = () => {
      setSelectedCustomer(lastSelectedCustomer)
      setLastSelectedCustomer(undefined)
   }

   const handleOpenNewCustomerModal = useCallback(() => setModal(true), [setModal])

   const InfoOverlay = () => {
      const { id, full_name, address, phones, job_title, email } = customer as ICustomer

      const formatAddress = useMemo(() => {
         if (!address) return "No address data"
         const _address = renderAddress(address as TAddress)
         return _address || "No address data";
      }, [address])

      const handleOpenCustomerHistory = (e: any) => {
         e.preventDefault()
         if (Context.jobs.length > 0) {
            showSaveModal(true)
            Context.setJActionType({ type: "customer_history", customerId: id })
         } else {
            history.push({
               pathname: `/customers/customer/${id}`,
               state: { fromWO: true },
            })
         }
      }

      return (
         <Flex flexDirection="column" justifyContent="space-between" style={{ flexGrow: 1 }}>
            <div>
               <Flex flexDirection="column">
                  <Flex flexDirection="row" justifyContent="space-between">
                     <Msg color="#494949" fontSize="18px" fontWeight={700}>
                        {full_name}
                     </Msg>
                     {location.pathname === routes.workOrder.CreateWorkOrder && (
                        <Msg
                           fontSize={16}
                           fontWeight={700}
                           onClick={handleEdit}
                           style={{ textDecoration: "underline", cursor: "pointer", color: "#0496FF" }}
                        >
                           Change
                        </Msg>
                     )}
                  </Flex>
                  <Msg>{job_title}</Msg>
                  <Flex flexDirection="column" margin="24px 0">
                     <InfoRow>
                        {!isXs && <PhoneIcon />}

                        {phones.find((p) => p.phone_type === "P") ? (
                           <span
                              style={{ color: "#0496FF", fontWeight: 700, textDecoration: "underline" }}
                           >{phoneFormatter(phones.find((p) => p.phone_type === "P")?.phone)}</span>
                        ) : (
                           "No phone number data"
                        )}
                     </InfoRow>
                     <InfoRow>
                        {!isXs && <MessageListIcon />}
                        {email ? (
                           <a
                              href={`mailto:${email}`}
                              style={{ color: "#0496FF", fontWeight: 700, textDecoration: "underline" }}
                           >
                              {email}
                           </a>
                        ) : (
                           "No email"
                        )}
                     </InfoRow>
                     <InfoRow>
                        {!isXs && <MapMarkerIcon />}
                        <Flex flexDirection="column">{formatAddress}</Flex>
                     </InfoRow>
                  </Flex>
               </Flex>
            </div>
            <StyledButton onClick={handleOpenCustomerHistory}>Open customer history</StyledButton>
            {/* <NavLink
               to={{ pathname: `/customers/customer/${id}`, state: { fromWO: true } }}
               style={{ textDecoration: "underline", fontWeight: 700 }}
            >
               Open customer history
            </NavLink> */}
         </Flex>
      )
   }

   return (
      <SelectCard marginBottom={isLg ? "16px" : "0px"} style={{ alignSelf: "normal" }}>
         <CustomerModal
            visible={modal}
            onClose={() => setModal(false)}
            onSuccess={loadCustomers}
            handleSelect={setSelectedCustomer}
         />
         <SelectCardTitle>Customer </SelectCardTitle>
         {customer ? (
            <InfoOverlay />
         ) : (
            <>
               <Form form={form}>
                  <Form.Item
                     name="customer"
                     rules={[
                        {
                           required: true,
                           message: "The field is required.",
                        },
                     ]}
                  >
                     {customers && (
                        <AutocompleteSelect
                           options={customers}
                           handleSelect={handleSelect}
                           fieldName="full_name"
                           placeholder="Select a Customer"
                           footer={
                              <AutocompleteAddButton onClick={handleOpenNewCustomerModal} text="Add new customer" />
                           }
                        />
                     )}
                  </Form.Item>
               </Form>
            </>
         )}
         {lastSelectedCustomer && !customer && <CancelButton onClick={handleCancel}>Cancel</CancelButton>}
      </SelectCard>
   )
}
