import { useState } from 'react';
import { Modal, notification } from 'antd';
import Flex from '../../../noui/Flex';
import { EmptyButton } from '../../../ui/Button';
import { useHistory } from "react-router-dom";
import routes from "../../../routes/routes";
import { Api } from '../../../api/api';


export const CardDetails = ({ id, work_order, closeDropdown, loadCards }) => {
    const [confirmVisible, setConfirmVisible] = useState(false);

    const history = useHistory();

    const details = [
        {name: 'View Order', callback: () => history.push(routes.workOrder.UpdateWorkOrder.replace(":id", work_order) + `?job=${id}`)},
        {name: 'View Inspections', callback: () => history.push(routes.workOrder.UpdateWorkOrder.replace(":id", work_order) + `?job=${id}&tab=3`)},
        {name: 'View Time Clocks', callback: () => history.push(routes.workOrder.UpdateWorkOrder.replace(":id", work_order) + `?job=${id}&tab=4`)},
        {name: 'Delete', callback: () => setConfirmVisible(true)}
    ];

    return <Flex className='little-cart' width='141px' flexDirection='column'>
        <Modal 
            visible={confirmVisible}
            title={`Delete this job?`}
            onOk={async () => {
                try {
                    await Api.jobs.delete(id);
                    loadCards();
                } catch (e) {
                    notification.error({
                        message: 'Something went wrong'
                    })
                } finally {
                    setConfirmVisible(false);
                }
            }}
            onCancel={() => setConfirmVisible(false)}
        >
            <p>Do you want to delete this job?</p>
        </Modal>
        {details.map(el => <EmptyButton width='100%' p='10px' color={el.name !== 'Delete' ? '#202020' : 'red'} onClick={el.callback ? () => {
            closeDropdown();
            el.callback();
        } : () => history.push(routes.workOrder.UpdateWorkOrder.replace(":id", work_order) + `?job=${id}`)} textAlign='left'>{el.name}</EmptyButton>)}
    </Flex>
};