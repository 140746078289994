import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import Flex from "../../noui/Flex"
import { Title } from "../../components/Title"
import { StatusTab } from "./StatusTab"
import TimeClockTab from "./TimeClockTab"
import { Container } from "../../components/Container"
import { Map } from "./mapPage/Map"
import { Calendar } from "../calendar/Calendar"
import { useLocation, Route } from "react-router-dom"
import routes from "../../routes/routes"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import { useTechnicianRole } from "../../utils/hooks"

const tabs = [
   { label: "Project List", route: routes.jobs.ProjectsList, component: <StatusTab type="table" /> },
   { label: "Status Board", route: routes.jobs.StatusBoard, component: <StatusTab type="board" /> },
   { label: "Calendar", route: routes.jobs.Calendar, component: <Calendar /> },
   { label: "Map", route: routes.jobs.Map, component: <Map /> },
   { label: "Time Clocks", route: routes.jobs.TimeClocks, component: <TimeClockTab /> },
]

const TabsWrapper = styled.div`
   .ant-radio-group {
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      > label {
         border-top: none;
         border-bottom: none;
         &:first-child {
            border-left: none;
         }
         &:last-child {
            border-right: none;
         }
      }
   }
   .ant-tabs-nav {
      padding: 0 24px;
   }
   ${down("xs")} {
      .ant-tabs-nav {
         padding: 0 8px;
      }
   }
`

export const JobPage: React.FC = () => {
   const location = useLocation()
   const [choosedTab, setChoosedTab] = useState(tabs[0].route)
   const isXs = useBreakpoint(down("xs"))
   const technicianRole = useTechnicianRole()

   useEffect(() => {
      setChoosedTab(location.pathname)
   }, [location.pathname])

   const filterTabs = technicianRole ? tabs.filter((item) => item.label !== "Time Clocks") : tabs

   return (
      <Flex p={isXs ? "14px" : "34px"} pt="15px" flexDirection="column">
         <Title>Projects</Title>
         <Route
            path="/jobs/:tab?"
            render={({ match, history }) => {
               return (
                  <Container>
                     <TabsWrapper>
                        <Tabs
                           activeKey={choosedTab}
                           onChange={(key) => {
                              setChoosedTab(key)
                              history.push(key)
                           }}
                        >
                           {filterTabs.map((t) => (
                              <Tabs.TabPane key={t.route} tab={t.label}>
                                 {t.component}
                              </Tabs.TabPane>
                           ))}
                        </Tabs>
                     </TabsWrapper>
                  </Container>
               )
            }}
         />
      </Flex>
   )
}
