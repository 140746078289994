import { TArgCallback } from "../../types"
import TableRow from "./TableRow"

type TProps = {
   data: any
   handleEditMaterial: TArgCallback<any>
   deleteMaterial: TArgCallback<any>
   duplicateMaterial: TArgCallback<any>
   group: any
   groups: any
   handleCreateGroupe: TArgCallback<any>
   columns: any
   type?: string
}

const MaterialsTable: React.FC<TProps> = ({
   data,
   handleEditMaterial,
   deleteMaterial,
   duplicateMaterial,
   group,
   groups,
   handleCreateGroupe,
   columns,
   type,
}) => {
   return (
      <>
         {data &&
            data.length > 0 &&
            data.map((item: any) => {
               return (
                  <tr key={item.id}>
                     <TableRow
                        item={item}
                        handleEditMaterial={handleEditMaterial}
                        deleteMaterial={deleteMaterial}
                        duplicateMaterial={duplicateMaterial}
                        group={group}
                        groups={groups}
                        handleCreateGroupe={handleCreateGroupe}
                        columns={columns}
                        type={type}
                     />
                  </tr>
               )
            })}
      </>
   )
}
export default MaterialsTable
