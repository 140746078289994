import styled from 'styled-components';
import {
  fontSize, FontSizeProps,
  space, SpaceProps,
  textAlign, TextAlignProps,
  display, DisplayProps,
  fontWeight, FontWeightProps,
  color, ColorProps,
  lineHeight, LineHeightProps,
  letterSpacing, LetterSpacingProps,
  opacity, OpacityProps,
  fontFamily, FontFamilyProps,
  height, HeightProps,
  width, WidthProps
} from 'styled-system';
import {PALETTE} from "../constants";


type TProps =
  & { transition?: string }
  & { textDecoration?: string }
  & FontSizeProps
  & SpaceProps
  & TextAlignProps
  & DisplayProps
  & FontWeightProps
  & ColorProps
  & LineHeightProps
  & LetterSpacingProps
  & OpacityProps
  & FontFamilyProps
  & HeightProps
  & WidthProps;


export const Msg = styled.span.attrs<TProps>(props => ({
  fontSize: props.fontSize || '16px',
  transition: `${props.transition}s` || 'all 0s ease 0s',
  color: props.color || PALETTE.Text,
  textDecoration: props.textDecoration || undefined
  // TODO: Need to add ...props here, but !check! if it doesn't break anything
}))<TProps>`
  margin: 0;
  padding: 0;
  ${(props) => (props.textDecoration ? `text-decoration: ${props.textDecoration};` : '')} 
  ${fontSize};
  ${fontWeight};
  ${space};
  ${display};
  ${textAlign};
  ${color};
  ${lineHeight};
  ${letterSpacing};
  ${fontFamily};
  ${height};
  ${width};
  ${opacity};
  transition: ${props => props.transition};
`;
