import {Form, Input} from "antd";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentData, paymentSlice} from "../../config/reducers/payment/paymentSlice";
import {useForm} from "antd/es/form/Form";
import {fetchValidatePromoCode} from "../../config/reducers/payment/actionCreater";
import {useEffect} from "react";
import {CloseOutlined} from "@ant-design/icons";
import Flex from "../../noui/Flex";
interface Prop {
    promoIsConfirmed: boolean;
}
const PromoLabel = styled.p`
  font-size: 15px;
`
const ConfirmPromoLabel = styled.div`
    color: #0496FF;
`
const PromoDescription = styled.div`
    margin-top: 8px;
`
const PostComponent = styled.div<Prop>`
   margin-left: ${(props) => (props.promoIsConfirmed ? "-20px" : "-50px")};
  margin-top: 5px;
  cursor: pointer;
  z-index: 10;
`

const PromoCodeField = ({}) => {
    const label = <PromoLabel>Have a promo code? Enter it here</PromoLabel>
    const dispatch = useDispatch()
    const [form] = useForm()
    const {error, promoCodeData} = useSelector(getPaymentData)

    const {deletePromoCode} = paymentSlice.actions
    const handleConfirmPromo = async () => {

        const {promo} = form.getFieldsValue()
       await dispatch(fetchValidatePromoCode(promo))
    }
    const handleDeletePromoCode = () => {
        dispatch(deletePromoCode())
        form.resetFields()
    }
    useEffect(()=>{form.submit()},[error])

    return (
        <Form form={form} layout="vertical" >
            <Form.Item label={label}  initialValue={promoCodeData?.code} name={"promo"} rules={[
                () => ({
                    validator(_, value) {
                        if (error) {
                            return Promise.reject(error);
                        }
                    },
                }),
            ]} >
                <Flex>
                <Input disabled={!!promoCodeData} placeholder="Enter your promo code"/>
                    <PostComponent promoIsConfirmed={!!promoCodeData}> { promoCodeData ? <CloseOutlined onClick={handleDeletePromoCode} />:<ConfirmPromoLabel onClick={handleConfirmPromo}>Apply</ConfirmPromoLabel>}</PostComponent>
                </Flex>
            </Form.Item>
            {promoCodeData && <PromoDescription>{promoCodeData.coupon.name} for {promoCodeData.coupon.duration}</PromoDescription>}
        </Form>
    );
}

export default PromoCodeField;