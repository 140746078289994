import React from 'react';
import {Msg} from '../../ui/Text';
import styled from 'styled-components';
import Flex from '../../noui/Flex';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';

const ContentStyled = styled(Flex)`
background-color: #ffffff;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
border-radius: 4px;
`

const Dispatch = ({children}) => {
  const isXs = useBreakpoint(down('xs'));
  return <Flex p={isXs ? '14px' : '34px'} pt='15px' flexDirection='column'>
    <Msg fontWeight={800} fontSize={24} mb='18px' mt={isXs ? '14px' : '76px'}>Dispatch Board</Msg>
    <ContentStyled flexDirection='column' py="20px">
      {children}
    </ContentStyled>
  </Flex>
}

export default Dispatch;
