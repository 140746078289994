import { Editor } from "@tiptap/core/dist/packages/core/src/Editor"
import Link from "@tiptap/extension-link"
import TextAlign from "@tiptap/extension-text-align"
import TextStyle from "@tiptap/extension-text-style"
import Underline from "@tiptap/extension-underline"
import { EditorContent, useEditor } from "@tiptap/react"
import {
   EditorAlignCenter,
   EditorAlignJustify,
   EditorAlignLeft,
   EditorAlignRight,
   EditorBold,
   EditorItalic,
   EditorLink,
   EditorUnderline,
   Minus,
   Plus,
} from "../../assets/icons"
import StarterKit from "@tiptap/starter-kit"
import { Button, Input, Modal } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import FontSize from "./FontSizeExport"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"

const ControlPanel = styled(Flex)`
   background: #fbfbff;
   border: 1px solid #ededed;
   box-sizing: border-box;
   border-radius: 0px 0px 4px 4px;
   padding: 7px 20px;
   margin-bottom: 24px;
   ${down("md")} {
      flex-wrap: wrap;
      > button,
      > div {
         margin-bottom: 8px;
      }
   }
   ${down("xs")} {
      padding: 7px 8px;
   }
`

type TEditorButtonProps = {
   isActive?: boolean
}

const EditorButton = styled(Button)<TEditorButtonProps>`
   margin-right: 9px;
   border: none;
   padding: 8px 16px;
   background: ${(props) => (props.isActive ? "rgba(4, 150, 255, 0.1)" : "#FFFFFF")};
   &:hover {
      background: rgba(4, 150, 255, 0.1);
      svg path,
      svg rect {
         fill: #0496ff;
      }
   }
   svg path,
   svg rect {
      fill: ${(props) => (props.isActive ? "#0496ff" : "#494949")};
   }
`

const VerticalHR = styled.div`
   height: 100%;
   width: 1px;
   background: #ededed;
   margin-right: 9px;
`

const FontSizeControl = styled(Button)`
   border: 1px solid #ededed;
   border-radius: 1px;
   background: #fbfbff;
`

const FontSizeText = styled.div`
   width: 45px;
   border: 1px solid #ededed;
   display: flex;
   justify-content: center;
   align-items: center;
`

type TTextEditorProps = {
   initialValue: string
   onChange: (value: string) => void
}

export const TextEditor: React.FC<TTextEditorProps> = ({ initialValue, onChange }) => {
   const defaultFontSize = 16
   const [fontSize, setFontSize] = useState(defaultFontSize)
   const [visible, setVisible] = useState(false)
   const [name, setName] = useState("")
   const [link, setLink] = useState("")
   const isMd = useBreakpoint
   const editor = useEditor({
      extensions: [
         StarterKit,
         Underline,
         TextAlign.configure({
            types: ["paragraph"],
         }),
         Link.configure({
            openOnClick: false,
         }),
         TextStyle,
         FontSize,
      ],
      editorProps: {
         attributes: {
            class: "editor",
         },
      },
      onSelectionUpdate({ editor }) {
         updateFormattedFontSize(editor)
      },
      onUpdate({ editor }) {
         onChange(editor.getHTML())
      },
      content: initialValue,
   })

   useEffect(() => {
      if (editor) {
         editor.commands.setContent(initialValue)
      }
   }, [initialValue])

   const getSelectedText = () =>
      editor && editor.state.doc.textBetween(editor.state.selection.from, editor.state.selection.to)

   const replaceSelectedText = (text: string) => {
      if (editor) {
         const transaction = editor.state.tr.insertText(text, editor.state.selection.from, editor.state.selection.to)
         const from = editor.state.selection.from
         const to = from + text.length
         editor.view.dispatch(transaction)
         editor.commands.setTextSelection({ from, to })
      }
   }

   const getCurrentLink = () => editor && editor.getAttributes("link").href

   const setData = () => {
      setName(getSelectedText() ?? "")
      setLink(editor?.getAttributes("link").href ?? "")
   }

   const openModal = () => {
      setData()
      setVisible(true)
   }

   const toggleLink = () => {
      replaceSelectedText(name)
      setVisible(false)
      if (link === null) {
         return
      }
      if (link === "") {
         editor?.chain().focus().extendMarkRange("link").unsetLink().run()
         return
      }
      editor?.chain().focus().extendMarkRange("link").setLink({ href: link }).run()
   }

   const getFormattedFontSize = () => `${fontSize}px`

   const updateFormattedFontSize = (editor: Editor | null) => {
      const fontSize = editor?.getAttributes("textStyle")?.fontSize
      if (fontSize) {
         setFontSize(parseInt(fontSize))
         return
      }
      setFontSize(defaultFontSize)
   }

   useEffect(() => {
      editor?.chain().focus().setFontSize(getFormattedFontSize()).run()
   }, [fontSize])

   return (
      <>
         <Modal
            title={getCurrentLink() ? "EDIT LINK" : "ADD LINK"}
            okText={getCurrentLink() ? "Edit" : "Save"}
            onCancel={() => setVisible(false)}
            onOk={toggleLink}
            visible={visible}
            width={374}
         >
            <Msg style={{ color: "#494949", fontSize: "13px", fontWeight: 700 }}>Name</Msg>
            <Input value={name} onChange={(e) => setName(e.target.value)} style={{ marginBottom: "8px" }} />
            <Msg style={{ color: "#494949", fontSize: "13px", fontWeight: 700 }}>URL</Msg>
            <Input value={link} onChange={(e) => setLink(e.target.value)} style={{ marginBottom: "8px" }} />
         </Modal>
         <EditorContent editor={editor} />
         <ControlPanel>
            <Flex marginRight="16px">
               <FontSizeControl onClick={() => setFontSize((prev) => prev + 1)}>
                  <Plus />
               </FontSizeControl>
               <FontSizeText>{fontSize}</FontSizeText>
               <FontSizeControl onClick={() => setFontSize((prev) => prev - 1)}>
                  <Minus />
               </FontSizeControl>
            </Flex>
            <EditorButton
               onClick={() => editor?.chain().focus().toggleBold().run()}
               isActive={editor?.isActive("bold")}
            >
               <EditorBold />
            </EditorButton>
            <EditorButton
               onClick={() => editor?.chain().focus().toggleItalic().run()}
               isActive={editor?.isActive("italic")}
            >
               <EditorItalic />
            </EditorButton>
            <EditorButton
               onClick={() => editor?.chain().focus().toggleUnderline().run()}
               isActive={editor?.isActive("underline")}
            >
               <EditorUnderline />
            </EditorButton>
            {!isMd && <VerticalHR />}
            <EditorButton onClick={openModal} isActive={editor?.isActive("link")}>
               <EditorLink />
            </EditorButton>
            {!isMd && <VerticalHR />}
            <EditorButton
               onClick={() => editor?.chain().focus().setTextAlign("left").run()}
               isActive={editor?.isActive({ textAlign: "left" })}
            >
               <EditorAlignLeft />
            </EditorButton>
            <EditorButton
               onClick={() => editor?.chain().focus().setTextAlign("center").run()}
               isActive={editor?.isActive({ textAlign: "center" })}
            >
               <EditorAlignCenter />
            </EditorButton>
            <EditorButton
               onClick={() => editor?.chain().focus().setTextAlign("right").run()}
               isActive={editor?.isActive({ textAlign: "right" })}
            >
               <EditorAlignRight />
            </EditorButton>
            <EditorButton
               onClick={() => editor?.chain().focus().setTextAlign("justify").run()}
               isActive={editor?.isActive({ textAlign: "justify" })}
            >
               <EditorAlignJustify />
            </EditorButton>
         </ControlPanel>
      </>
   )
}
