import styled from "styled-components";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { Button, Form, FormInstance, Input, Select } from "antd";
import { MinusCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { PALETTE, states, vesselTypes, fuelType } from "../../constants";
import { ICreateVessel } from "../../api/types";
import { TArgCallback, TCallback } from "../../types";
import React from "react";
import Flex from "../../noui/Flex";
import { ftToM } from "../../utils/utils";

const InputRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  // gap: 8px;
  & > div:first-child {
    margin-right: 8px;
  }
  & > * {
    width: calc(50% - 4px);
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item > .ant-form-item-label {
    padding: 16px 0 0;
    label {
      font-size: 13px;
    }
  }
`;

const AddButton = styled(Button)`
  font-size: 13px;
  font-weight: 700;
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin: 8px 0;
  flex-direction: row;
`;
const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  padding-top: 16px;
  border-top: 1px solid ${PALETTE.Background};
  @media screen and (max-width: 600px) {
    button {
      width: 50%;
    }
  }
`;

type TRowProps = {
  field: any;
  onRemove: TArgCallback<number | number[]>;
  idx: number;
  showRemove: boolean;
};
const EngineRow: React.FC<TRowProps> = ({
  field,
  onRemove,
  idx,
  showRemove,
}) => {
  return (
    <Flex alignItems="center" style={{ gap: "8px" }}>
      <Flex flexDirection="column">
        <Form.Item
          hidden
          {...field}
          name={[field.name, "id"]}
          fieldKey={[field.name, "id"]}
        >
          <Input type="hidden" />
        </Form.Item>
        <InputRow>
          <Form.Item
            name={[field.name, "manufacturer"]}
            fieldKey={[field.name, "manufacturer"]}
            label={`Engine Manufacturer ${idx}`}
          >
            <Input placeholder="Manufacturer" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "serial_number"]}
            fieldKey={[field.fieldKey, "serial_number"]}
            label={`Serial Number ${idx}`}
            rules={[{ message: "Please type serial number" }]}
          >
            <Input placeholder="1B795632" />
          </Form.Item>
        </InputRow>
        <InputRow>
          <Form.Item
            {...field}
            name={[field.name, "model"]}
            label={`Engine Model ${idx}`}
            fieldKey={[field.fieldKey, "model"]}
            rules={[
              {
                // required: true,
                message: "Please type model",
              },
            ]}
          >
            <Input placeholder="1300V33ED" />
          </Form.Item>
          <Form.Item
            name={[field.name, "fuel_type"]}
            fieldKey={[field.name, "fuel_type"]}
            label={`Fuel Type ${idx}`}
          >
            <Select
              placeholder="Fuel"
              getPopupContainer={(trigger: any) => trigger.parentElement}
            >
              {fuelType.map((ft) => (
                <Select.Option value={ft.value} key={ft.value}>
                  {ft.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </InputRow>
      </Flex>
      {showRemove ? (
        <Form.Item>
          <Form.Item {...field}>
            <MinusCircleOutlined
              style={{ position: "relative", top: 21 }}
              onClick={() => onRemove(field.name)}
            />
          </Form.Item>
        </Form.Item>
      ) : null}
    </Flex>
  );
};

const GeneratorRow: React.FC<TRowProps> = ({
  field,
  onRemove,
  idx,
  showRemove,
}) => {
  return (
    <Flex alignItems="center" style={{ gap: "8px" }}>
      <Flex flexDirection="column">
        <Form.Item
          hidden
          {...field}
          name={[field.name, "id"]}
          fieldKey={[field.name, "id"]}
        >
          <Input type="hidden" />
        </Form.Item>
        <InputRow>
          <Form.Item
            name={[field.name, "manufacturer"]}
            fieldKey={[field.name, "manufacturer"]}
            label={`Generator Manufacturer  ${idx}`}
          >
            <Input placeholder="Manufacturer" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "serial_number"]}
            fieldKey={[field.fieldKey, "serial_number"]}
            label={`Serial Number  ${idx}`}
            rules={[{ required: true, message: "Please type serial number" }]}
          >
            <Input placeholder="1B795632" />
          </Form.Item>
        </InputRow>
        <InputRow>
          <Form.Item
            {...field}
            name={[field.name, "model"]}
            label={`Generator Model ${idx}`}
            fieldKey={[field.fieldKey, "model"]}
            rules={[{ required: true, message: "Please type model" }]}
          >
            <Input placeholder="1300V33ED" />
          </Form.Item>
          <Form.Item
            name={[field.name, "fuel_type"]}
            fieldKey={[field.name, "fuel_type"]}
            label={`Fuel Type  ${idx}`}
          >
            <Select
              placeholder="Fuel"
              getPopupContainer={(trigger: any) => trigger.parentElement}
            >
              {fuelType.map((ft) => (
                <Select.Option value={ft.value} key={ft.value}>
                  {ft.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </InputRow>
      </Flex>
      {showRemove ? (
        <Form.Item>
          <Form.Item {...field}>
            <MinusCircleOutlined
              style={{ position: "relative", top: 21 }}
              onClick={() => onRemove(field.name)}
            />
          </Form.Item>
        </Form.Item>
      ) : null}
    </Flex>
  );
};

type TLength = "ft" | "m";
type TProps = {
  form: FormInstance<ICreateVessel>;
  isOpen: boolean;
  handleSubmit: TArgCallback<any>;
  onClose: TCallback;
  saving: boolean;
};
export const VesselForm: React.FC<TProps> = ({
  form,
  isOpen,
  handleSubmit,
  onClose,
  saving,
}) => {
  const isXs = useBreakpoint(down("xs"));
  const handleLengthTypeChange = (t: TLength) => {
    let length = form.getFieldValue("length");
    let beam = form.getFieldValue("beam");
    let draft = form.getFieldValue("draft");

    form.setFieldsValue({ length: ftToM(length, t !== "m") });
    form.setFieldsValue({ beam: ftToM(beam, t !== "m") });
    form.setFieldsValue({ draft: ftToM(draft, t !== "m") });
  };
  return (
    <StyledForm
      layout={"vertical"}
      onFinish={handleSubmit}
      id="create-course-form"
      form={form}
    >
      {isOpen ? (
        <>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={"Vessel name"}
            rules={[
              {
                required: true,
                message: "Please input Vessel name",
              },
            ]}
          >
            <Input autoComplete="off" placeholder="Reel Catch" />
          </Form.Item>

          <InputRow>
            <Form.Item
              name="vessel_type"
              label="Vessel Type"
              rules={[
                {
                  required: true,
                  message: "Please select Vessel type",
                },
              ]}
              style={{ flexGrow: 1 }}
            >
              <Select
                placeholder="Select"
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {vesselTypes.map((vt) => (
                  <Select.Option value={vt.value} key={vt.value}>
                    {vt.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="hin" label="Hull ID">
              <Input placeholder="e.g. 867530980085" />
            </Form.Item>
          </InputRow>

          <InputRow>
            <Form.Item
              name="year"
              label={"Year built"}
              rules={[
                {
                  required: true,
                  message: "Please input Year built",
                },
              ]}
            >
              <Input placeholder="2020" />
            </Form.Item>
            <Form.Item
              name="manufacturer"
              label="Manufacturer"
              rules={[
                {
                  required: true,
                  message: "Please input Manufacturer",
                },
              ]}
            >
              <Input placeholder="Boston Whaler" name="manufacturer" />
            </Form.Item>
          </InputRow>

          <InputRow>
            <Form.Item
              name="model"
              label={"Model"}
              rules={[
                {
                  required: true,
                  message: "Please input Model",
                },
              ]}
            >
              <Input placeholder="420 Outrage" />
            </Form.Item>
            <Form.Item
              name="length"
              label="Length"
              rules={[
                {
                  required: true,
                  message: "Please input Length",
                },
              ]}
            >
              <Input
                placeholder="42"
                addonAfter={
                  <Form.Item name="lengthType" className="addon-after">
                    <Select
                      defaultValue="ft"
                      onChange={handleLengthTypeChange}
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                    >
                      <Select.Option value="ft">ft</Select.Option>
                      <Select.Option value="m">m</Select.Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </InputRow>

          <InputRow>
            <Form.Item name="beam" label="Beam">
              <Input
                placeholder="42"
                addonAfter={
                  <Form.Item name="lengthType" className="addon-after">
                    <Select
                      defaultValue="ft"
                      onChange={handleLengthTypeChange}
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                    >
                      <Select.Option value="ft">ft</Select.Option>
                      <Select.Option value="m">m</Select.Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
            <Form.Item name="draft" label="Draft">
              <Input
                placeholder="42"
                addonAfter={
                  <Form.Item name="lengthType" className="addon-after">
                    <Select
                      defaultValue="ft"
                      onChange={handleLengthTypeChange}
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                    >
                      <Select.Option value="ft">ft</Select.Option>
                      <Select.Option value="m">m</Select.Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </InputRow>

          <InputRow>
            <Form.Item name="hailing_port" label={"Hailing port"}>
              <Input placeholder="Wrightsville Beach" />
            </Form.Item>
            <Form.Item name={"uscg_doc"} label="USCG Doc Number">
              <Input placeholder="e.g. 392004171" />
            </Form.Item>
          </InputRow>

          <InputRow style={{ alignItems: "flex-end" }}>
            <Form.Item
              name="state_of_registration"
              label="State of Registration"
            >
              <Select
                placeholder="State/Territory"
                showSearch
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {states.map((s) => (
                  <Select.Option value={s} key={s}>
                    {s}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                isXs ? "State Registry. Number" : "State Registration Number"
              }
              name={"registration_number"}
            >
              <Input placeholder={"NC 13524"} />
            </Form.Item>
          </InputRow>

          <Form.List name="engines">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, idx) => (
                  <EngineRow
                    idx={idx + 1}
                    showRemove={fields.length > 1}
                    field={field}
                    key={field.key}
                    onRemove={remove}
                  />
                ))}
                <Form.Item>
                  <AddButton type="link" onClick={() => add()}>
                    <PlusCircleFilled
                      style={{ color: PALETTE.Secondary.Medium, fontSize: 17 }}
                    />{" "}
                    Add Engine Model
                  </AddButton>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.List name="generators">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, idx) => (
                  <GeneratorRow
                    field={field}
                    idx={idx + 1}
                    showRemove={fields.length > 1}
                    key={field.key}
                    onRemove={remove}
                  />
                ))}
                <Form.Item>
                  <AddButton type="link" onClick={() => add()}>
                    <PlusCircleFilled
                      style={{ color: PALETTE.Secondary.Medium, fontSize: 17 }}
                    />{" "}
                    Add Generator Model
                  </AddButton>
                </Form.Item>
              </>
            )}
          </Form.List>

          <FooterRow>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={saving}>
              Save
            </Button>
          </FooterRow>
        </>
      ) : null}
    </StyledForm>
  );
};
