import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import ListDays from "./ListDays"
import ListWeekMonth from "./ListWeekMonth"

import { MainLayoutInner } from "./styled"

type TProps = {
   data?: any
   calendar?: any
   type?: any
   endDate?: any
}

const ReportsList: React.FC<TProps> = ({ data, calendar, type, endDate }) => {
   const [report, setReport] = useState<any[]>()
   const [dayTotal, setDayTotal] = useState<any>()

   const calculateDuration = useCallback(
      (d: string) => {
         if (d) {
            const parts = d.split(" ")
            const time = parts[parts.length - 1].split(":")
            const minToHour = (Number(time[1]) / 60).toFixed(2)
            const daysToHour = parts.length > 1 ? Number(parts[0]) * 24 : 0
            const secToHour = (Number(time[2]) / 3600).toFixed(2)
            const total = Number(daysToHour) + Number(time[0]) + Number(minToHour) + Number(secToHour)

            return Math.round(total * 100) / 100
         }
         return 0
      },
      [data]
   )

   const renderData = useCallback(
      (data: any[], endDate: any) => {
         let arr: any[] = []
         data.forEach((job) => {
            if (moment(job.date) > moment(endDate)) {
               return
            }
            const findJob = arr.find((item) => {
               return item.job.id === job.job.id && item.date === job.date
            })

            if (findJob) {
               const newData = {
                  ...job,
                  duration: calculateDuration(job.duration) + Math.round(findJob.duration * 100) / 100,
               }

               arr = arr.filter((item) => JSON.stringify(item) !== JSON.stringify(findJob))
               arr.push(newData)
            } else {
               const newData = {
                  ...job,
                  duration: calculateDuration(job.duration),
               }

               arr.push(newData)
            }
         })
         return arr
      },
      [data, endDate]
   )

   const filterJobs = useCallback(
      (data: any[], calendar: any[], type: string) => {
         let arr: any[] = []
         const uniqueIds = Array.from(new Set(data.map((item) => item.job.id)))

         uniqueIds.forEach((item) => {
            const findJobs = data.filter((i) => i.job.id === item)
            if (findJobs) {
               let dates: any[] = []
               calendar.forEach((c) => {
                  if (type === "daily") {
                     const findDate = findJobs.find((i) => i.date === c.date)

                     if (findDate) {
                        dates.push({
                           date: findDate.date,
                           duration: findDate.duration ? Math.round(findDate.duration * 100) / 100 : "0",
                        })
                     }
                  } else {
                     let findDate = null
                     if (type === "week") {
                        findDate = findJobs.filter((i) => moment(i.date).isoWeek() === c.subtitle)
                     } else {
                        findDate = findJobs.filter((i) => moment(i.date).format("MMM") === c.subtitle)
                     }

                     if (findDate) {
                        const duration = findDate.reduce((a, b) => +a + +b.duration, 0)
                        dates.push({
                           date: c.subtitle,
                           duration: duration ? Math.round(duration * 100) / 100 : "",
                        })
                     }
                  }
               })

               const totalPerJob = dates.reduce((a, b) => +a + +b.duration, 0)

               arr.push({
                  job: findJobs[0].job,
                  dates,
                  total: Math.round(totalPerJob * 100) / 100,
               })
            }
         })
         return arr
      },
      [data, calendar]
   )

   useEffect(() => {
      if (data && calendar && endDate) {
         const jobs = renderData(data, endDate)
         const filter = filterJobs(jobs, calendar, type)
         const dayTotal = calculateDayTotal(filter, calendar, type)
         setReport(filter)
         setDayTotal(dayTotal)
      }
   }, [data, calendar, endDate])

   const calculateDayTotal = useCallback(
      (data: any[], calendar: any[], type: any) => {
         const total: any[] = []
         calendar.forEach((item) => {
            const arr: any[] = []
            data.forEach((job) => {
               let find = null
               if (type === "daily") {
                  find = job.dates.find((i: any) => i.date === item.date)
               } else {
                  find = job.dates.find((i: any) => i.date === item.subtitle)
               }

               if (find) {
                  arr.push(find)
               }
            })

            const duration = arr.reduce((a, b) => +a + +b.duration, 0)
            total.push({
               date: item.date,
               duration: duration ? Math.round(duration * 100) / 100 : "",
            })
         })

         const totalDuration = total.reduce((a, b) => +a + +b.duration, 0)
         return {
            total,
            totalDuration: totalDuration ? Math.round(totalDuration * 100) / 100 : "",
         }
      },
      [data, calendar, type]
   )

   return (
      <MainLayoutInner>
         {type === "daily" ? (
            <ListDays data={report} calendar={calendar} dayTotal={dayTotal} />
         ) : (
            <ListWeekMonth data={report} calendar={calendar} dayTotal={dayTotal} />
         )}
      </MainLayoutInner>
   )
}

export default ReportsList
