import React, { Key, useEffect, useMemo, useState } from "react";
import { Modal, Table } from "antd";
import { TCallback } from "../../types";
import { ColumnsType, ColumnType } from "antd/es/table";
import { useAppSelector } from "../../config/hooks";
import { getColumns } from "../../config/reducers/columns/selectors";
import { TableRowSelection } from "antd/es/table/interface";
import { useDispatch } from "react-redux";
import { setColumns } from "../../config/reducers/columns/actions";

type TProps<T> = {
  visible: boolean;
  onClose: TCallback;
  columns: ColumnsType<T>;
  name: string;
};

export const ColumnsSelector = <T extends object = object>({
  visible,
  name,
  onClose,
  columns,
}: TProps<T>): React.ReactElement => {
  const selected = useAppSelector(getColumns(name));
  const [stateSelected, setSSelected] = useState<Key[]>(selected);
  const dispatch = useDispatch();

  const isActionsDisabled = useMemo(() => {
    if (columns.findIndex((c) => c.key === "actions") !== -1) {
      return !selected.includes("actions");
    }
    return false;
  }, [columns, selected]);

  useEffect(() => {
    if ((columns.length && !selected.length) || isActionsDisabled) {
      const nSelected = columns.map((c) => c.key as Key);
      dispatch(setColumns({ name, columns: nSelected }));
      setSSelected(nSelected);
    }
  }, [selected, columns, name, dispatch]);
  const handleSubmit = () => {
    dispatch(setColumns({ name, columns: stateSelected }));
    onClose();
  };
  const handleCancel = () => {
    setSSelected(selected);
    onClose();
  };
  const cols: ColumnsType<ColumnType<T>> = [
    { title: "Column", dataIndex: "title", key: "title" },
  ];
  const handleSelectionChange = (keys: React.Key[]) => {
    setSSelected(keys);
  };
  const rowSelection: TableRowSelection<ColumnType<T>> = {
    onChange: handleSelectionChange,
    selectedRowKeys: stateSelected,
    getCheckboxProps: (record) => ({
      disabled: ["order_number", "actions", "active"].includes(
        record?.key as string
      ),
      name: record.title as string,
    }),
  };

  return (
    <Modal
      title={"Select columns"}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Table
        columns={cols}
        dataSource={columns}
        size={"small"}
        pagination={false}
        rowSelection={rowSelection}
      />
    </Modal>
  );
};
