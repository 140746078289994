import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  TimePicker,
} from "antd";
import {
  IJobTechnician,
  IUpdateJob,
  IUser,
  IWorkOrderWithJobs,
} from "../../../api/types";
import { TArgCallback, TOption } from "../../../types";
import { FormListFieldData } from "antd/es/form/FormList";
import { Api } from "../../../api/api";
import { formatToUSD, getTimeDiff, mapOptions } from "../../../utils/utils";
import {
  RemoveButton,
  ResponsiveFlex,
  Total,
  Wrapper,
  WrapperTechnician,
} from "../styled";
import { CloseCircleIcon, SmallDeleteIcon } from "../../../assets/icons";
import Flex from "../../../noui/Flex";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";

export type TRProps = {
  form: FormInstance<IUpdateJob>;
  remove: TArgCallback<number>;
  field: FormListFieldData;
  technicianRole: boolean;
  workOrder?: IWorkOrderWithJobs | null;
  setIsFormFieldChanged?: (value: boolean) => void;
};

export type TFormProps = {
  options: { mapped: TOption[] };
  onSearch: TArgCallback<string>;
  onDropdownOpen: (open: boolean) => void;
  allUsers: IUser[];
} & TRProps;

const TimePickerStyled = styled(TimePicker)`
  &.ant-picker-disabled {
    border: none;
    background: none;
    color: #000;
    padding: 0;
  }
  & .ant-picker-suffix {
    display: none;
  }
  & .ant-picker-input > input[disabled] {
    color: #000;
  }
`;
const SelectStyled = styled(Select)`
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    background: none;
    color: #000;
    padding: 0;
    &:after {
      content: none;
    }
  }
  & .ant-select-arrow {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  padding: 0px;
  svg {
    vertical-align: middle !important;
  }
`;

const StyledNumber = styled(InputNumber)`
  border-radius: 4px;
  width: 100% !important;
`;

const calculateRows = (data: IJobTechnician): string => {
  const num = (data.rate ?? 0) * getTimeDiff(data.estimated_hours);
  return formatToUSD(+num);
};

const TechnicianForm: React.FC<TFormProps> = ({
  field,
  form,
  options,
  onSearch,
  remove,
  technicianRole,
  onDropdownOpen,
  allUsers,
  workOrder,
  setIsFormFieldChanged,
}) => {
  const updateRate = (tech: any) => {
    const selectedUser = allUsers.find((user) => user.id === +tech);
    if (selectedUser?.rate) {
      const data = form.getFieldsValue();
      form.setFieldsValue({
        ...data,
        technicians_data: data.technicians_data.map((d) =>
          d?.technician === tech ? { ...d, rate: selectedUser.rate } : d
        ),
      });

      if (setIsFormFieldChanged) {
        setIsFormFieldChanged(true);
      }
    }
  };

  return (
    <>
      <Form.Item
        hidden
        {...field}
        name={[field.name, "id"]}
        fieldKey={[field.name, "id"]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, "technician"]}
        fieldKey={[field.fieldKey, "technician"]}
        rules={[{ required: true, message: "Please select a technician" }]}
      >
        <SelectStyled
          showSearch
          placeholder="Select technician"
          filterOption={false}
          options={options.mapped}
          onSearch={onSearch}
          notFoundContent={null}
          disabled={technicianRole}
          getPopupContainer={(trigger: any) => trigger.parentElement}
          onDropdownVisibleChange={onDropdownOpen}
          onChange={(v) => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
            updateRate(v);
          }}
        >
          <Input />
        </SelectStyled>
      </Form.Item>
      {!technicianRole && (
        <Form.Item
          {...field}
          name={[field.name, "rate"]}
          fieldKey={[field.fieldKey, "rate"]}
          rules={[
            {
              required: false,
              type: "number",
              min: 0,
              message: "Should be positive number",
            },
          ]}
        >
          <StyledNumber
            onChange={() => {
              if (setIsFormFieldChanged) {
                setIsFormFieldChanged(true);
              }
            }}
            type="number"
            min={0}
            max={100000}
          />
        </Form.Item>
      )}

      <Form.Item
        {...field}
        name={[field.name, "estimated_hours"]}
        fieldKey={[field.fieldKey, "estimated_hours"]}
        rules={[
          {
            required: !workOrder?.is_recurring,
            message: "Estimated hours are required",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || value.format("HH:mm:ss") !== "00:00:00") {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Estimated time can't be 00:00:00")
              );
            },
          }),
        ]}
      >
        <TimePickerStyled
          getPopupContainer={(trigger: any) => trigger.parentElement}
          showNow={false}
          showSecond={false}
          style={{ width: "100%" }}
          disabled={technicianRole}
          onChange={() => {
            if (setIsFormFieldChanged) {
              setIsFormFieldChanged(true);
            }
          }}
        />
      </Form.Item>
      {!technicianRole && (
        <>
          <Form.Item shouldUpdate>
            {() => (
              <Total>
                {calculateRows(
                  form.getFieldValue(["technicians_data", field.name]) || []
                )}
              </Total>
            )}
          </Form.Item>
          <Form.Item {...field}>
            <StyledButton
              onClick={() => {
                remove(field.name);
                if (setIsFormFieldChanged) {
                  setIsFormFieldChanged(true);
                }
              }}
            >
              <CloseCircleIcon />
            </StyledButton>
          </Form.Item>
        </>
      )}
    </>
  );
};

export const TechnicianRow: React.FC<TRProps> = ({
  field,
  remove,
  form,
  technicianRole,
  workOrder,
  setIsFormFieldChanged,
}) => {
  const [options, setOptions] = useState<{
    data: IUser[];
    mapped: TOption[];
  }>({ data: [], mapped: [] });
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const isXs = useBreakpoint(down("xs"));
  const isMd = useBreakpoint(down("md"));

  useEffect(() => {
    loadData().then();
  }, []);

  const handleSearch = (search: string) => {
    loadData(search).then();
  };

  const loadData = async (search?: string) => {
    const { data } = await Api.user.getAll({ ...(search && { search }) });
    if (!search) {
      setAllUsers(data as IUser[]);
    }
    if (data) {
      const filteredOptions = filterWorkingTechs(data as IUser[]);
      if (filteredOptions) {
        setOptions({
          data: filteredOptions,
          mapped: mapOptions(filteredOptions, "full_name"),
        });
      }
    }
  };

  const filterWorkingTechs = (options: IUser[]) => {
    const formData = form.getFieldsValue();

    if (formData.technicians_data) {
      const workingTechs = formData.technicians_data
        .filter((tech) => tech)
        .map((tech) => +tech.technician);
      return options.filter((opt) =>
        workingTechs.indexOf(opt.id) === -1
          ? true
          : workingTechs.indexOf(opt.id) === field.fieldKey
      );
    }
    return null;
  };

  const filterOnDropdownOpen = (open: boolean) => {
    if (open) {
      const workingTechs = form
        .getFieldsValue()
        .technicians_data.filter((tech) => tech)
        .map((tech) => +tech.technician);
      const filteredOptions = allUsers.filter((opt) =>
        workingTechs.indexOf(opt.id) === -1
          ? true
          : workingTechs.indexOf(opt.id) === field.fieldKey
      );
      setOptions({
        data: filteredOptions,
        mapped: mapOptions(filteredOptions, "full_name"),
      });
    }
  };

  const updateRate = (tech: any) => {
    const selectedUser = allUsers.find((user) => user.id === +tech);
    if (selectedUser?.rate) {
      const data = form.getFieldsValue();
      form.setFieldsValue({
        ...data,
        technicians_data: data.technicians_data.map((d) =>
          d?.technician === tech ? { ...d, rate: selectedUser.rate } : d
        ),
      });
    }

    if (setIsFormFieldChanged) {
      setIsFormFieldChanged(true);
    }
  };

  return (
    <>
      {!(isXs || isMd) ? (
        <WrapperTechnician>
          <TechnicianForm
            form={form}
            key={field.key}
            options={options}
            onSearch={handleSearch}
            field={field}
            remove={remove}
            technicianRole={technicianRole}
            onDropdownOpen={filterOnDropdownOpen}
            allUsers={allUsers}
            workOrder={workOrder}
            setIsFormFieldChanged={setIsFormFieldChanged}
          />
        </WrapperTechnician>
      ) : (
        <ResponsiveFlex position="relative">
          <Flex
            flexDirection={technicianRole ? "row" : "column"}
            position="relative"
          >
            <Form.Item
              hidden
              {...field}
              name={[field.name, "id"]}
              fieldKey={[field.name, "id"]}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...field}
              label="Technician"
              name={[field.name, "technician"]}
              fieldKey={[field.fieldKey, "technician"]}
              rules={[
                {
                  required: !technicianRole,
                  message: "Please select a technician",
                },
              ]}
              style={{ display: isXs && technicianRole ? "block" : "flex" }}
            >
              <SelectStyled
                showSearch
                placeholder="Select technician"
                filterOption={false}
                options={options.mapped}
                onSearch={handleSearch}
                notFoundContent={null}
                disabled={technicianRole}
                getPopupContainer={(trigger: any) => trigger.parentElement}
                onDropdownVisibleChange={filterOnDropdownOpen}
                onChange={(v) => {
                  if (setIsFormFieldChanged) {
                    setIsFormFieldChanged(true);
                  }
                  updateRate(v);
                }}
              >
                <Input />
              </SelectStyled>
            </Form.Item>

            <Flex
              justifyContent="space-between"
              alignItems="flex-start"
              flexDirection="row"
              position="relative"
            >
              {!technicianRole && (
                <Form.Item
                  {...field}
                  label="Rate"
                  style={{
                    width: "calc(50% - 9px)",
                    flexDirection: isXs ? "row" : "column",
                  }}
                  name={[field.name, "rate"]}
                  fieldKey={[field.fieldKey, "rate"]}
                  rules={[
                    {
                      required: !workOrder?.is_recurring,
                      type: "number",
                      min: 0,
                      message: "Should be positive number",
                    },
                  ]}
                >
                  <InputNumber
                    onChange={() => {
                      if (setIsFormFieldChanged) {
                        setIsFormFieldChanged(true);
                      }
                    }}
                    type="number"
                    min={0}
                    max={100000}
                  />
                </Form.Item>
              )}

              <Form.Item
                {...field}
                label="Est. hours"
                style={{
                  width: technicianRole ? "100%" : "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                  minWidth: "85px",
                }}
                name={[field.name, "estimated_hours"]}
                fieldKey={[field.fieldKey, "estimated_hours"]}
                rules={[
                  {
                    required: !technicianRole && !workOrder?.is_recurring,
                    message: "Estimated hours are required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value.format("HH:mm:ss") !== "00:00:00") {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Estimated time can't be 00:00:00")
                      );
                    },
                  }),
                ]}
              >
                <TimePickerStyled
                  showNow={false}
                  showSecond={false}
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger: any) => trigger.parentElement}
                  disabled={technicianRole}
                  onChange={() => {
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                />
              </Form.Item>
            </Flex>
            {!technicianRole && (
              <Form.Item
                label="Est. Total"
                style={{
                  width: "calc(50% - 9px)",
                  flexDirection: isXs ? "row" : "column",
                }}
                shouldUpdate
              >
                {() => (
                  <Total>
                    {calculateRows(
                      form.getFieldValue(["technicians_data", field.name]) || []
                    )}
                  </Total>
                )}
              </Form.Item>
            )}

            {!technicianRole && (
              <Form.Item {...field}>
                <RemoveButton
                  type="text"
                  onClick={() => {
                    remove(field.name);
                    if (setIsFormFieldChanged) {
                      setIsFormFieldChanged(true);
                    }
                  }}
                >
                  <SmallDeleteIcon />
                  Remove technician
                </RemoveButton>
              </Form.Item>
            )}
          </Flex>
        </ResponsiveFlex>
      )}
    </>
  );
};
