import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Button, Empty, Pagination, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import moment from "moment"
import { Api } from "../../api/api"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { usePagination, useResponsive } from "../../utils/hooks"
import { IInvoice, IWorkOrder } from "../../api/types"
import { filterColumns, getOrdering } from "../../utils/utils"
import Box from "../../noui/Box"
import { Columns, DefaultDateFormat, EstimateStatuses } from "../../constants"
import { AddBtn } from "../../ui/TableUtils"
import { TableCard } from "../../components/TableCard"
import styled from "styled-components"
import AddCustomerImg from "../../assets/images/addCustomer.svg"
import AddCustomerBlueImg from "../../assets/images/addCustomerBlue.svg"
import { ColumnsSelector } from "../../modals/ColumnsSelector/ColumnsSelector"
import { ColumnsButton } from "../../components/ColumnsButton"
import { useSelector } from "react-redux"
import { getColumns } from "../../config/reducers/columns/selectors"
import { DownOutlined } from "@ant-design/icons"
import { InvoicesFiltersModal, TInvoicesFilters } from "./InvoicesFiltersModal"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import Hr from "../../ui/Hr"

export const ButtonsWrapper = styled(Flex)`
   justify-content: space-between;
   padding: 0 20px;
   margin-bottom: 0;
   flex-wrap: wrap;
   flex-direction: row;
   ${down("lg")} {
      margin-bottom: 20px;
   }
   // ${down("xs")} {
   //    justify-content: flex-end;
   //    > div {
   //       flex: 1;
   //       width: calc(50% - 8px);
   //       > button {
   //          width: 100%;
   //       }
   //    }
   // }
`

const renderStatus = (status: string) => Object.keys(EstimateStatuses).find((key) => EstimateStatuses[key] === status)

const Invoices: React.FC = () => {
   const [mouseEnter, setMouseEnter] = useState<boolean>(false)
   const [visible, setVisible] = useState<boolean>(false)
   const { data, loading, onPromise, requestPagination, pagination, onChange, sorter } = usePagination<IInvoice>()
   const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
   const selectedColumns = useSelector(getColumns(Columns.Invoices))
   const { width } = useResponsive()
   const [filters, setFilters] = useState<TInvoicesFilters>({})
   const isXs = useBreakpoint(down("xs"))

   const loadData = async () => {
      await onPromise(Api.invoices.getAll({ ...requestPagination, ...getOrdering(sorter), ...filters }))
   }

   useEffect(() => {
      loadData()
   }, [requestPagination, sorter, onPromise, filters])

   const columns: ColumnsType<IInvoice> = [
      {
         title: "Work Order #",
         dataIndex: "work_order",
         key: "work_order",
         sorter: true,
         render: (_, record) => <Msg fontWeight={700}>{`#${record.work_order.order_number}`}</Msg>,
      },
      {
         title: "Customer",
         dataIndex: "customer_data",
         key: "customer_data",
         ellipsis: true,
         render: (_, record) => `${record.work_order.customer.first_name} ${record.work_order.customer.last_name}`,
      },
      {
         title: "Vessel",
         dataIndex: "vessel_data",
         key: "vessel_data",
         ellipsis: true,
         render: (_, record) => record.work_order?.vessel?.name,
      },
      {
         title: "Work Order Name",
         dataIndex: "title",
         key: "title",
         ellipsis: true,
         render: (_, record) => record.work_order.title,
      },
      {
         title: "Status",
         dataIndex: "completed",
         key: "completed",
         sorter: true,
         render: (_, record) => (record.completed ? "Paid" : "In Progress"),
      },
      {
         title: "Estimate",
         dataIndex: "job",
         key: "job",
         render: (_, record) => `$${(+record.work_order.estimated_total || 0).toFixed(2)}`,
      },
      {
         title: "Invoice",
         dataIndex: "total",
         key: "total",
         sorter: true,
         render: (_, record) => "$" + (record.total || 0).toFixed(2),
      },
      {
         title: "Date Invoiced",
         dataIndex: "created",
         key: "created",
         sorter: true,
         render: (_, record) => moment(record.created).format(DefaultDateFormat),
      },
      {
         title: "Paid",
         dataIndex: "deposit",
         key: "deposit",
         sorter: true,
         render: (_, record) => `$${(+record.work_order.deposited || 0).toFixed(2)}`,
      },
      {
         title: "Payment Due",
         dataIndex: "schedule",
         key: "schedule",
         sorter: true,
         render: (_, record) => moment(record.created).add(5, "days").format(DefaultDateFormat),
      },
   ]

   const calculateEstimateAmount = () =>
      `$${data.reduce((acc, d) => Number(d.work_order.estimated_total) + acc, 0).toFixed(2)}`
   const calculateInvoiceAmount = () => `$${data.reduce((acc, d) => (d?.total ?? 0) + acc, 0).toFixed(2)}`
   const calculatePaidAmount = () => `$${data.reduce((acc, d) => d.work_order.deposited + acc, 0).toFixed(2)}`

   return (
      <>
         <Helmet>
            <title>Invoices - DockWorks Pro</title>
         </Helmet>
         <ButtonsWrapper>
            <Flex
               justifyContent={width < 631 ? "flex-end" : "flex-start"}
               style={{ flexGrow: isXs ? undefined : 1, marginRight: "16px" }}
            >
               <AddBtn
                  onMouseEnter={() => setMouseEnter(true)}
                  onMouseLeave={() => setMouseEnter(false)}
                  style={{ marginLeft: "0px" }}
                  onClick={() => setFiltersVisible(true)}
               >
                  <Flex justifyContent="space-between">
                     <Msg fontWeight={600} fontSize={13} mr={20} color="#ffffff">
                        Add Filter
                     </Msg>
                     <img src={mouseEnter ? AddCustomerBlueImg : AddCustomerImg} alt="icon" />
                  </Flex>
               </AddBtn>
            </Flex>
            <ColumnsButton onClick={() => setVisible(true)} />
         </ButtonsWrapper>
         <Box px={3}>
            {width > 1340 ? (
               <Table
                  className="contained"
                  dataSource={data}
                  loading={loading}
                  columns={filterColumns(columns, selectedColumns)}
                  pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
                  onChange={onChange}
                  locale={{ emptyText: "No timesheets results were found" }}
                  summary={() => (
                     <>
                        <Table.Summary.Row style={{ width: "100%" }}>
                           {filterColumns(columns, selectedColumns).map((c, i) => {
                              switch (c.title) {
                                 case "Work Order #":
                                    return (
                                       <Table.Summary.Cell index={i}>
                                          <Msg fontWeight={700}>Total</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 case "Estimate":
                                    return (
                                       <Table.Summary.Cell index={i}>
                                          <Msg fontWeight={700}>{calculateEstimateAmount()}</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 case "Invoice":
                                    return (
                                       <Table.Summary.Cell index={i}>
                                          <Msg fontWeight={700}>{calculateInvoiceAmount()}</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 case "Paid":
                                    return (
                                       <Table.Summary.Cell index={i}>
                                          <Msg fontWeight={700}>{calculatePaidAmount()}</Msg>
                                       </Table.Summary.Cell>
                                    )
                                 default:
                                    return <Table.Summary.Cell index={i} />
                              }
                           })}
                        </Table.Summary.Row>
                     </>
                  )}
               />
            ) : (
               <Flex flexWrap="wrap" style={{ marginTop: "15px" }}>
                  {!!data.length ? (
                     data.map((d) => (
                        <TableCard key={d.id}>
                           {filterColumns(columns, selectedColumns).map((c, i) => (
                              <Flex key={i} justifyContent="space-between">
                                 <Msg fontWeight={700}>{c.title}</Msg>
                                 <Msg style={{ textAlign: "end" }}>{c.render && c.render(d, d, i)}</Msg>
                              </Flex>
                           ))}
                        </TableCard>
                     ))
                  ) : (
                     <Empty style={{ width: "100%" }} />
                  )}
                  {!!data.length && (
                     <Flex flexDirection="column" paddingTop="24px" width="100%">
                        <Hr marginBottom="8px" />
                        <Flex justifyContent="space-between">
                           <Msg fontSize={18} fontWeight={700}>
                              Total
                           </Msg>
                        </Flex>
                        <Flex justifyContent="space-between">
                           <Msg fontSize={13} fontWeight={700} color="#494949">
                              Estimate
                           </Msg>
                           <Msg fontSize={13} fontWeight={400} color="#494949">
                              {calculateEstimateAmount()}
                           </Msg>
                        </Flex>
                        <Flex justifyContent="space-between">
                           <Msg fontSize={13} fontWeight={700} color="#494949">
                              Invoice
                           </Msg>
                           <Msg fontSize={13} fontWeight={400} color="#494949">
                              {calculateInvoiceAmount()}
                           </Msg>
                        </Flex>
                        <Flex justifyContent="space-between">
                           <Msg fontSize={13} fontWeight={700} color="#494949">
                              Paid
                           </Msg>
                           <Msg fontSize={13} fontWeight={400} color="#494949">
                              {calculatePaidAmount()}
                           </Msg>
                        </Flex>
                     </Flex>
                  )}
                  {!!pagination?.total && pagination.total > 10 && (
                     <Pagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={(p) => {
                           onChange({ ...pagination, current: p }, {}, {})
                        }}
                        style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
                        size="small"
                     />
                  )}
               </Flex>
            )}
         </Box>
         <InvoicesFiltersModal
            visible={filtersVisible}
            onClose={() => setFiltersVisible(false)}
            filters={filters}
            setFilters={setFilters}
         />
         <ColumnsSelector
            name={Columns.Invoices}
            columns={columns}
            visible={visible}
            onClose={() => setVisible(false)}
         />
      </>
   )
}

export default Invoices
