import { DatePicker, Form, InputNumber, Modal, Select, Spin } from "antd"
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react"
import styled from "styled-components";
import { Api } from "../../api/api";
import { ICustomer } from "../../api/types";
import Flex from "../../noui/Flex";
import { down } from 'styled-breakpoints';

type TProps = {
    visible: boolean;
    onClose: () => void;
    filters: TPaymentsFilters;
    setFilters: (filters: TPaymentsFilters) => void;
}

type TOption = {
    [key: string]: Array<string|number>
}

type TShortOption = {
    [key: string]: string
}

export type TPaymentsFilters = {
    customer?: number,
    created_after?: string,
    created_before?: string,
    payment_type?: string,
    amount_min?: number,
    amount_maxs?: number,
    remaining_min?: number,
    remaining_max?: number,
}

enum EFilterType {
    Date,
    Method,
    Amount,
    Remaining
}

const StyledForm = styled(Form)`
    .ant-row {
        margin-bottom: 0;
    }
    margin-bottom: 12px;
`;

const StyledSelect = styled(Select)`
    width: 150px;
    input {
        min-width: 100px;
    }
`;

const FilterRow = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
    >div {
        width: 48%;
    }
    .ant-input-number {
        width: 100%;
    }
    ${down('xs')} {
        .ant-form-item {
            flex-direction: row !important;
        }
    }
`;

export const PaymentsFiltersModal: React.FC<TProps> = ({visible, onClose, filters, setFilters}) => {
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [showDateRange, setShowDateRange] = useState(false);
    const [showAmountRange, setShowAmountRange] = useState(false);
    const [showRemainingRange, setShowRemainingRange] = useState(false);

    const dateFormat = "YYYY-MM-DD";

    const optionsForCreated: TOption = {
        "30 days or less": [moment().subtract(30, "days").startOf("day").format(dateFormat), moment().format(dateFormat)],
        "30 days to 6 months": [moment().subtract(6, "month").startOf("day").format(dateFormat), moment().subtract(30, "days").startOf("day").format(dateFormat)],
        "More than 6 months": ["", moment().subtract(6, "month").startOf("day").format(dateFormat)],
        "Select a Range": ["", ""]
    };

    // TODO: change ACH/Direct Deposit
    const optionsForMethod: TShortOption = {
        "Cash": "CASH",
        "Credit Card": "CARD",
        "ACH/Direct Deposit": "CHECK",
    }

    const optionsForAmount: TOption = {
        "Under $500": [0, 500],
        "$500-$2,500": [500, 2500],
        "More than $2,500": [2500, ""],
        "Select a Range": ["", ""]
    };

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            setLoading(true);
            const res = await Api.customers.getAll({});
            setCustomers(res.data as ICustomer[])
        }
        finally {
            setLoading(false);
        }
    }

    const handleFieldChange = (selected: SelectValue, filtersData: TOption|TShortOption, filterType: EFilterType, firstFieldName: string, secondFieldName?: string) => {
        switch(filterType) {
            case EFilterType.Date:
                setShowDateRange(checkIsRangeString(selected as string))
            break;
            case EFilterType.Amount:
                setShowAmountRange(checkIsRangeString(selected as string)) 
            break;
            case EFilterType.Remaining:
                setShowRemainingRange(checkIsRangeString(selected as string)) 
            break;
        }
        const data = filtersData[selected as string];
        if (Array.isArray(data)) {
            form.setFieldsValue({
                [firstFieldName]: data[0],
                ...(secondFieldName && {[secondFieldName]: data[1]}),
            });
        }
        else {
            form.setFieldsValue({
                [firstFieldName]: data,
            });
        }
    }
    
    const handleSubmit = () => {
        const newFilters = form.getFieldsValue(["customer", "created_after", "created_before", "payment_type", "amount_min", "amount_max", "remaining_min", "remaining_max"]);
        newFilters.created_after = normalizeMomentFormat(newFilters.created_after)
        newFilters.created_before = normalizeMomentFormat(newFilters.created_before)
        setFilters(newFilters)
        onClose()
    }

    const normalizeMomentFormat = (data: Moment|string) => {
        if (moment.isMoment(data)) {
            return data.format(dateFormat)
        }
        return data;
    }

    const checkIsRangeString = (option: string) => option === "Select a Range"

    return <Modal title="Payments Filters" visible={visible} onCancel={onClose} width="350px" onOk={handleSubmit}>
        <Spin spinning={loading}>
            <StyledForm form={form} layout="vertical">
                <Form.Item label="Customer Name" name="customer" initialValue={filters.customer}>
                    <StyledSelect 
                        showSearch
                        placeholder="Choose customer"
                        filterOption={(input, option) => option ? option.children.toLowerCase().includes(input.toLowerCase()) : false}
                    >
                        {customers.map(c => <Select.Option value={c.id} key={c.id}>{c.full_name}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                <Form.Item label="Payment Date">
                    <StyledSelect 
                        placeholder="Choose payment date"
                        onChange={(selected) => handleFieldChange(selected, optionsForCreated, EFilterType.Date, "created_after", "created_before")}
                    >
                        {Object.entries(optionsForCreated).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                {showDateRange &&
                <FilterRow>
                    <Form.Item label="After" name="created_after">
                        <DatePicker format={dateFormat}/>
                    </Form.Item>
                    <Form.Item label="Before" name="created_before">
                        <DatePicker format={dateFormat} />
                    </Form.Item>
                </FilterRow>
                }
                <Form.Item label="Payment Method">
                    <StyledSelect 
                        placeholder="Choose payment method"
                        onChange={(selected) => handleFieldChange(selected, optionsForMethod, EFilterType.Method, "payment_type")}
                    >
                        {Object.entries(optionsForMethod).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                <Form.Item label="Total Invoice Amount">
                    <StyledSelect 
                        placeholder="Choose total invoice amount"
                        onChange={(selected) => handleFieldChange(selected, optionsForAmount, EFilterType.Amount, "amount_min", "amount_max")}
                    >
                        {Object.entries(optionsForAmount).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                {showAmountRange &&
                <FilterRow>
                    <Form.Item label="Min" name="amount_min">
                        <InputNumber min={0}/>
                    </Form.Item>
                    <Form.Item label="Max" name="amount_max">
                        <InputNumber min={0}/>
                    </Form.Item>
                </FilterRow>
                }
                <Form.Item label="Total Remaining">
                    <StyledSelect 
                        placeholder="Choose total remaining"
                        onChange={(selected) => handleFieldChange(selected, optionsForAmount, EFilterType.Remaining, "remaining_min", "remaining_max")}
                    >
                        {Object.entries(optionsForAmount).map((option, index) => <Select.Option key={index} value={option[0]}>{option[0]}</Select.Option>)}
                    </StyledSelect>
                </Form.Item>
                {showRemainingRange &&
                <FilterRow>
                    <Form.Item label="Min" name="remaining_min">
                        <InputNumber min={0}/>
                    </Form.Item>
                    <Form.Item label="Max" name="remaining_max">
                        <InputNumber min={0}/>
                    </Form.Item>
                </FilterRow>
                }
            </StyledForm>            
        </Spin>
    </Modal>
}