import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { Api } from "../../api/api";
import styled from "styled-components";
import { Button, Spin, Collapse } from "antd";
import {
  getErrors,
  showErrors,
  renderAddress,
  sortByDate,
} from "../../utils/utils";
import { PaddedContainer, Title } from "./styled";
import { Messages } from "../../components/Messages/RecurringMessages";
import { RecurringDetails as Details } from "./RecurringDetailts";
import { Activities } from "../jobs/content/Activities";
import { EstimateApprovedModal } from "./EstimateApprovedModal";
import { TInputChangeHandler, TWithId } from "../../types";
import {
  IActivity,
  IExtendedActivity,
  IExternalEstimate,
  IExternalRecurring,
  IMessage,
  IShortJob,
  IWSMessage,
} from "../../api/types";
import { useWebSocket } from "../../utils/hooks";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import DEFAULT_LOGO from "../../assets/images/gear_logo.png";
import moment from "moment";
import { track } from "../../utils/analytics";
import { SideBarActivities } from "../jobs/content/SideBarActivities";
import { SideBarMessages } from "../../components/Messages/SideBarMessages";

interface IContainerProps {
  showSideBlock: boolean;
}
interface ISideBarContainerProps {
  isFlex: boolean;
  isHide: boolean;
}

const Container = styled.div<IContainerProps>`
  background: #ebf1f6;
  padding: 72px 24px 24px;
  min-width: 0;
  min-height: 0;
  display: grid;
  grid-template-columns: ${(props) =>
    props.showSideBlock ? "3fr 1fr" : "1fr"};
  grid-gap: 24px;
  font-size: 14px;
  .ant-divider {
    margin: 0;
  }
  ${down("sm")} {
    grid-template-columns: 1fr;
  }
`;

const Plate = styled.div`
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  &:nth-child(2) {
    margin-top: 16px;
  }
`;
const ActivitiesContainer = styled(Plate)<ISideBarContainerProps>`
  flex: ${(props) => (props.isFlex ? "1 1 40%" : "none")};
  height: ${(props) => (props.isHide ? "54px" : "100%")};
  overflow: hidden;
  position: relative;
  ${down("sm")} {
    height: ${(props) => (props.isHide ? "54px" : "50vh")};
  } ;
`;
const MessagesContainer = styled(Plate)<ISideBarContainerProps>`
  flex: ${(props) => (props.isFlex ? "1 1 60%" : "none")};
  height: ${(props) => (props.isHide ? "54px" : "100%")};
  position: relative;
  ${down("sm")} {
    height: ${(props) => (props.isHide ? "54px" : "50vh")};
  } ;
`;
const StyledPlate = styled.div`
  display: flex;
  flex-flow: column;
  ${down("sm")} {
    display: block;
  } ;
`;

const StyledSpin = styled(Spin)`
  grid-column-start: 1;
  grid-column-end: 3;
`;

const MobileContainer = styled.div`
  background: #ebf1f6;
  padding: 40px 12px 12px;
  min-height: 100vh;
`;

const MobileContent = styled(Flex)`
  flex-direction: column;
  padding: 24px 16px 16px;
  border-radius: 8px;
  background-color: #ffffff;
`;

const MobileLogo = styled.div`
  width: 100px;
  height: 50px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  margin-right: 20px;
`;

const MobileHeaderText = styled(Msg)`
  text-transform: uppercase;
  font-weight: 900;
`;

const MobileDropDownBlock = styled(Flex)`
  flex-direction: column;
  a {
    color: #202020;
  }
`;

const MobileBlock = styled(Flex)`
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  margin-top: 16px;
`;

const DropDownButton = styled(Button)`
  border: none;
  width: 16px;
  padding: 0px;
`;

const BillingContacts = styled(Flex)`
  h3 {
    font-size: 15px;
  }
  h4 {
    font-weight: 700;
  }
  h4,
  p {
    font-size: 15px;
    margin-bottom: 0;
  }
`;

const StyledCollapse = styled(Collapse)`
  & .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 0 !important;
  }
  & .ant-collapse-content-box {
    padding: 0;
  }

  & .ant-collapse-item {
    .ant-collapse-arrow {
      position: inherit !important;
      top: inherit !important;
      right: inherit !important;
      margin-left: 10px !important;
      transform: rotate(90deg) !important;
    }
    &.ant-collapse-item-active {
      .ant-collapse-arrow {
        transform: rotate(180deg) !important;
      }
    }
  }
`;

type TDropdownContent = {
  showServices: boolean;
  showActivity: boolean;
  showMessages: boolean;
};

export const CustomerRecurringLayout: React.FC = () => {
  const { id } = useParams<TWithId>();
  const [estimate, setEstimate] = useState<IExternalRecurring | null>(null);
  const [approveVisible, setApproveVisible] = useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [activeInfo, setActiveInfo] = useState<boolean>(false);
  const [jobs, setJobs] = useState<IShortJob[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [isActivitiesHide, setIsActivitiesHide] = useState<boolean>(true);
  const [isMessagesHide, setIsMessagesHide] = useState<boolean>(true);
  const messageRef = useRef<any>(null);
  const { pathname } = useLocation();
  const isXs = useBreakpoint(down("xs"));
  const [showDropdownContent, setShowDropdownContent] =
    useState<TDropdownContent>({
      showServices: false,
      showActivity: false,
      showMessages: false,
    });
  const isInvoice = useMemo(() => {
    return pathname.search("invoice") !== -1;
  }, [pathname]);
  const isRecurring = useMemo(() => {
    return pathname.search("subscription") !== -1;
  }, [pathname]);

  const viewMessage = useCallback((m: IWSMessage) => {
    setMessages((messages) => [m.message as IMessage, ...messages]);
  }, []);

  useWebSocket(String(estimate?.work_order.uuid), "E", viewMessage);

  useEffect(() => {
    if (id) {
      setLoading(true);
      const route = Api.jobs.recurringDetails;
      route({ uuid: id })
        .then(({ data }) => {
          setEstimate(data);
          const colActivities: IExtendedActivity[] = [...data.activities];
          setActivities([...colActivities.sort(sortByDate("created", true))]);
          setMessages(data.messages);
          const { jobs } = data.work_order;
          setJobs([...jobs]);
          if (jobs.length) {
            setSelectedJobId(String(jobs[jobs.length - 1].id));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, isInvoice]);
  // useEffect(() => {
  //   messageRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [messages]);

  const handleChangeMessage: TInputChangeHandler = ({ target: { value } }) => {
    setMessage(value);
  };
  const handleToggleSideBarComponent = (info: string) => {
    info === "messages"
      ? setIsMessagesHide(!isMessagesHide)
      : setIsActivitiesHide(!isActivitiesHide);
  };
  const handleSend = async () => {
    const uuid = estimate?.work_order.uuid;
    if (uuid) {
      try {
        const { data } = await Api.workOrders.sendMessage({ uuid, message });
        setMessage("");
        setMessages([data, ...messages]);
        track("Customer Sent a Message", {
          messageSentToCompany: estimate?.company.id,
          messageSentToUser: data.technician,
          messageSentBy: data.customer,
          messageTimestamp: moment().toISOString(),
        });
      } catch (e) {
        getErrors(e);
      }
    }
  };

  const handleApprove = () => {
    if (id) {
      setSaving(true);
      Api.jobs
        .approve(id)
        .then(({ data }) => {
          track("Work Order Estimate Approved", {
            workorderName: data.work_order.title,
            workorderId: data.work_order.id,
            customerId: data.work_order.customer.id,
            workorderApprovalTimestamp: moment().toISOString(),
            vesselId: data.work_order?.vessel.id,
            vesselCity: data.work_order?.current_address?.city,
            vesselState: data.work_order?.current_address?.state,
            estimatedDurationMins: data.work_order.estimated_duration,
            workorderTotal: data.work_order.total,
          });
          // setApproveVisible(true);
          setEstimate((e) => (e ? { ...e } : e));
        })
        .catch((e) => {
          showErrors(getErrors(e));
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const showSideBlock = () =>
    !!(
      estimate?.view_config?.display_activity_feed ||
      estimate?.view_config?.display_messages
    );

  const calculateItemsCount = () =>
    estimate?.work_order.jobs.reduce(
      (acc, j) => acc + j.services.length + j.materials.length,
      0
    );

  const handleServicesDropdownChange = () =>
    setShowDropdownContent({
      ...showDropdownContent,
      showServices: !showDropdownContent.showServices,
    });
  const handleActivityDropdownChange = () =>
    setShowDropdownContent({
      ...showDropdownContent,
      showActivity: !showDropdownContent.showActivity,
    });
  const handleMessagesDropdownChange = () =>
    setShowDropdownContent({
      ...showDropdownContent,
      showMessages: !showDropdownContent.showMessages,
    });

  return isXs ? (
    estimate?.company ? (
      <MobileContainer>
        <MobileContent>
          <Flex marginBottom="40px">
            <MobileLogo>
              <img src={estimate?.company?.logo ?? DEFAULT_LOGO} alt="Logo" />
            </MobileLogo>
            <Flex flexDirection="column">
              <StyledCollapse
                ghost
                expandIconPosition="right"
                onChange={() => setActiveInfo((prev) => !prev)}
              >
                <Collapse.Panel header={estimate.company.name} key="1">
                  <MobileDropDownBlock>
                    <Msg
                      fontSize="15px"
                      fontWeight="700"
                      color="#0496FF"
                      style={{ textDecoration: "underline" }}
                    >
                      {estimate.company.email}
                    </Msg>
                    {estimate?.company.address && (
                      <Msg fontSize="15px" lineHeight="20px">
                        {Object.values({
                          ...estimate.company.address,
                          lat: "",
                          lng: "",
                        })
                          .filter((v) => v !== "")
                          .join(", ")}
                      </Msg>
                    )}

                    {estimate?.company.phone && (
                      <a href={`tel:${estimate.company.phone}`}>
                        {estimate.company.phone}
                      </a>
                    )}
                  </MobileDropDownBlock>
                </Collapse.Panel>
              </StyledCollapse>
              {!activeInfo && (
                <Msg
                  fontSize="15px"
                  fontWeight="700"
                  color="#0496FF"
                  style={{ textDecoration: "underline" }}
                >
                  {estimate.company.email}
                </Msg>
              )}
            </Flex>
          </Flex>
          <BillingContacts alignItems="flex-start" flexDirection="column">
            <h3>BILLING CONTACT</h3>
            <h4>{estimate?.work_order?.customer?.full_name}</h4>
            <p>
              {estimate?.work_order?.customer?.address
                ? renderAddress(estimate?.work_order?.customer?.address)
                : "No data"}
            </p>
            <p>
              {estimate?.work_order?.customer?.phones.find(
                (p: { phone_type: string }) => p.phone_type === "P"
              )?.phone || "No data"}
            </p>
            <p style={{ color: "#0496FF" }}>
              {estimate?.work_order?.customer?.email}
            </p>
          </BillingContacts>

          <Flex flexDirection="column">
            <MobileBlock>
              <Flex flexDirection="row" justifyContent="space-between">
                <div>
                  <MobileHeaderText
                    fontSize="18px"
                    style={{ color: "#0f497b" }}
                  >
                    SERVICE PLAN ESTIMATE
                  </MobileHeaderText>
                  <p style={{ fontSize: "12px", margin: 0 }}>
                    Sent:{" "}
                    {moment(estimate?.work_order?.send_config?.updated).format(
                      "ddd, M/D/YYYY h:mm A"
                    )}
                  </p>
                </div>

                <DropDownButton
                  onClick={handleServicesDropdownChange}
                  icon={
                    showDropdownContent.showServices ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )
                  }
                />
              </Flex>
              {showDropdownContent.showServices && (
                <Details
                  isInvoice={isInvoice}
                  estimate={estimate}
                  saving={saving}
                  onApprove={handleApprove}
                  messageRef={messageRef}
                />
              )}
            </MobileBlock>
            {estimate?.view_config?.display_activity_feed && (
              <MobileBlock>
                <Flex flexDirection="row" justifyContent="space-between">
                  <MobileHeaderText fontSize="18px">
                    Job Activity
                  </MobileHeaderText>
                  <DropDownButton
                    onClick={handleActivityDropdownChange}
                    icon={
                      showDropdownContent.showActivity ? (
                        <UpOutlined />
                      ) : (
                        <DownOutlined />
                      )
                    }
                  />
                </Flex>
                {showDropdownContent.showActivity && (
                  <Activities activities={activities} />
                )}
              </MobileBlock>
            )}
            {estimate?.view_config?.display_messages && (
              <MobileBlock>
                <Flex flexDirection="row" justifyContent="space-between">
                  <MobileHeaderText fontSize="18px">Messages</MobileHeaderText>
                  <DropDownButton
                    onClick={handleMessagesDropdownChange}
                    icon={
                      showDropdownContent.showMessages ? (
                        <UpOutlined />
                      ) : (
                        <DownOutlined />
                      )
                    }
                  />
                </Flex>
                {showDropdownContent.showMessages && (
                  <Messages
                    estimate={estimate}
                    ref={messageRef}
                    jobs={jobs}
                    onSelectJob={(jobId) => setSelectedJobId(jobId)}
                    selectedJob={selectedJobId}
                    messages={messages}
                    message={message}
                    onSend={handleSend}
                    onChangeMessage={handleChangeMessage}
                  />
                )}
              </MobileBlock>
            )}
            <Details
              isInvoice={isInvoice}
              estimate={estimate}
              saving={saving}
              onApprove={handleApprove}
              messageRef={messageRef}
              isMobileActions
              openMessages={() =>
                setShowDropdownContent({
                  ...showDropdownContent,
                  showMessages: true,
                })
              }
            />
          </Flex>
        </MobileContent>
      </MobileContainer>
    ) : null
  ) : (
    <Container showSideBlock={showSideBlock()}>
      {loading ? (
        <StyledSpin />
      ) : !estimate ? (
        <p>No estimate</p>
      ) : (
        <>
          <Plate>
            <Details
              isInvoice={isInvoice}
              estimate={estimate}
              saving={saving}
              onApprove={handleApprove}
              messageRef={messageRef}
            />
          </Plate>
          {showSideBlock() && (
            <StyledPlate>
              {estimate?.view_config?.display_activity_feed && (
                <ActivitiesContainer
                  isHide={isActivitiesHide}
                  isFlex={
                    estimate?.view_config?.display_messages && !isActivitiesHide
                  }
                >
                  <Flex justifyContent={"space-between"}>
                    <PaddedContainer>
                      <Title>JOB Activity</Title>
                      {!isActivitiesHide && (
                        <Activities activities={activities} />
                      )}
                    </PaddedContainer>
                    <PaddedContainer>
                      <Msg
                        color={"#0496FF"}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          handleToggleSideBarComponent("Activities");
                        }}
                      >
                        Hide
                      </Msg>
                    </PaddedContainer>
                  </Flex>
                </ActivitiesContainer>
              )}
              {estimate?.view_config?.display_messages && (
                <MessagesContainer
                  isHide={isMessagesHide}
                  isFlex={
                    estimate?.view_config?.display_activity_feed &&
                    !isMessagesHide
                  }
                >
                  <Messages
                    estimate={estimate}
                    ref={messageRef}
                    jobs={jobs}
                    onSelectJob={(jobId) => setSelectedJobId(jobId)}
                    selectedJob={selectedJobId}
                    messages={messages}
                    message={message}
                    handleToggleSideBarComponent={handleToggleSideBarComponent}
                    isHide={!isMessagesHide}
                    onSend={handleSend}
                    onChangeMessage={handleChangeMessage}
                  />
                </MessagesContainer>
              )}
            </StyledPlate>
          )}
          <EstimateApprovedModal
            visible={approveVisible}
            onCancel={() => setApproveVisible(false)}
            onOk={() => setApproveVisible(false)}
          />
        </>
      )}
    </Container>
  );
};
