import React, { useEffect, useState } from "react"
import { Layout } from "antd"
import styled from "styled-components"
import { HeaderContent } from "../components/Header"
import { useAppDispatch } from "../config/hooks"
import { useSelector, useDispatch } from "react-redux"
import { getCurrentUser } from "../config/reducers/user/selectors"
import { setSidebarCollapse } from "../config/reducers/user/actions"
import { PALETTE } from "../constants"
import { Sidebar } from "./Sidebar/Sidebar"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"

import { getMenuItem } from "../config/reducers/menuClickReducer/selectors"
import { setMenuItem } from "../config/reducers/menuClickReducer/actions"

import { SuperUserHeader } from "../components/SuperUserHeader/SuperUserHeader"

const { Header, Content } = Layout

type TLayoutProps = {
   isAdmin: boolean
}
export const ADMIN_HEADER_HEIGHT = "60px"
const StyledLayout = styled(Layout)<TLayoutProps>`
   background-color: transparent;
   color: ${PALETTE.Text};
   height: 100vh;
   font-family: "Nunito Sans";
   &.ant-layout {
      min-height: calc(100vh - 200px ${(props) => (props.isAdmin ? `- ${ADMIN_HEADER_HEIGHT}` : "")});
      height: calc(100vh ${(props) => (props.isAdmin ? `- ${ADMIN_HEADER_HEIGHT}` : "")});
      ${(props) => (props.isAdmin ? `top: ${ADMIN_HEADER_HEIGHT};` : "")}
      position: relative;
   }
`

const HeaderStyled = styled(Header)`
   position: fixed;
   box-shadow: 0px 3px 12px -3px #101010;
   // width: calc(100% - 256px);
   z-index: 10;
   // margin-left: 256px;
   background-color: #fff;
   padding: 0 30px;
   height: 60px;
   transition: all 0.2s;
   ${down("xs")} {
      height: auto;
      position: static;
      padding: 16px;
      background: #ebf1f6;
   }
   &.ant-layout-header {
      line-height: 2;
   }
   &.nonCollapsedSider {
      margin-left: 256px;
      width: calc(100% - 256px);
      ${down("xs")} {
         margin-left: 0;
         width: 100%;
      }
   }

   &.collapsedSider {
      margin-left: 68px;
      width: calc(100% - 68px);
      ${down("xs")} {
         margin-left: 0;
         width: 100%;
         z-index: 1;
      }
   }
`

const Container = styled(Layout)`
   ${down("xs")} {
      min-height: auto;
   }
`

const ContentStyled = styled(Content)`
   background-color: #e5e5e5;
   height: auto;
   width: 100%;
   overflow-x: hidden;
   ${down("xs")} {
      background: #ebf1f6;
   }
   &.ant-layout-content {
      min-height: auto;
   }

   &.nonCollapsedSider {
      margin-left: 256px;
      ${down("xs")} {
         margin-right: 0;
         display: none;
      }
   }

   &.collapsedSider {
      margin-left: 68px;
      ${down("xs")} {
         margin-left: 0;
      }
   }
`

export const MainLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
   const [collapsed, setCollapsed] = useState<boolean>(false)
   const userData = useSelector(getCurrentUser)
   const dispatch = useAppDispatch()
   const isXS = useBreakpoint(down("xs"))
   useEffect(() => {
      if (isXS) {
         setCollapsed(true)
         dispatch(setSidebarCollapse(true))
      } else {
         setCollapsed(false)
         dispatch(setSidebarCollapse(false))
      }
   }, [isXS])

   const onCollapse = () => {
      dispatch(setSidebarCollapse(!collapsed))
      return setCollapsed(!collapsed)
   }

   return (
      <StyledLayout isAdmin={Boolean(userData?.is_staff && userData.company)}>
         <Container>
            <SuperUserHeader />
            {isXS && collapsed ? null : <Sidebar collapsed={collapsed} onCollapse={onCollapse} />}
            <HeaderStyled
               className={collapsed ? "collapsedSider" : "nonCollapsedSider"}
               // onMouseOver={() => dispatch(setMenuItem({ headerHover: true }))}
               // onMouseLeave={() => dispatch(setMenuItem({ headerHover: false }))}
               onClick={() => dispatch(setMenuItem({ headerHover: true }))}
            >
               <HeaderContent userData={userData} onMenuOpen={() => setCollapsed(false)} />
            </HeaderStyled>
            <ContentStyled id="main_block" className={collapsed ? "collapsedSider" : "nonCollapsedSider"}>
               {children}
            </ContentStyled>
         </Container>
      </StyledLayout>
   )
}
