import React, {useEffect, useState} from 'react';
import {Checkbox, List, Modal} from "antd";
import {Content} from "../../../components/Content";
import styled from "styled-components";
import {Api} from "../../../api/api";
import {TArgCallback, TModalProps} from "../../../types";
import {EUserRole, IUser} from "../../../api/types";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-list-item {
    padding: 18px 14px;
    justify-content: flex-start;
    > span {
      cursor: pointer;
      margin-left: 12px;
    }
  }
`

type TProps = TModalProps & {
  selected?: IUser;
  onSelect: TArgCallback<IUser>;
  choosedUsers?: IUser[];
}
export const TechnicianModal: React.FC<TProps> = ({visible, selected, onSelect, onClose, choosedUsers}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    setLoading(true);
    Api.user.getAll({}).then(({data}) => {
      setUsers(data as IUser[]);
    })
      .finally(() => {setLoading(false);});
  }, []);

  useEffect(() => {
    if(choosedUsers && choosedUsers.length) {
      setUsers(users.filter(u => !choosedUsers.find(cu => cu.id === u.id)))
    }
  }, [choosedUsers])

  const handleSelect = (user: IUser) => () => {
    onSelect(user);
    onClose();
  }

  return <StyledModal visible={visible} width={320} onCancel={onClose} footer={null}>
    <Content noItems={!users.length} noItemsLabel={"No technicians present"} loading={loading}>
      <List dataSource={users} renderItem={item =>
        <List.Item>
          <Checkbox checked={selected?.id === item.id} onClick={handleSelect(item)} />
          <span onClick={handleSelect(item)}>{item.full_name}</span>
        </List.Item>}
      />
    </Content>
  </StyledModal>
};