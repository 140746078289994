import React, { useEffect, useState } from "react"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import Box from "../../noui/Box"
import { AutoComplete, Form, Modal, notification } from "antd"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import styled from "styled-components"
import { getErrors, mapOptions } from "../../utils/utils"
import { Api } from "../../api/api"
import { ICreateVessel, IPaginatedResponse, IVessel } from "../../api/types"
import { TArgCallback, TCallback, TOption } from "../../types"

const StyledModal = styled(Modal)`
   & .ant-modal-body {
      padding: 16px;
   }

   & .ant-modal-footer {
      display: none;
   }
   & .ant-form-item {
      margin-bottom: 0;
   }
`

const ModalCancelBtn = styled(PrimaryButton)`
   width: 92px;
   height: 34px;
   background-color: #ffffff;
   border: 0.7px solid #1d3443;
   color: #1d3443;
   &:hover {
      border: 0.7px solid #1d3443;
   }
`

const ModalCreateBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
`

const ArrowButton = styled(EmptyButton)`
   text-align: left;
   padding: 0;
   height: 20px;
`

const ContainerMainButtons = styled(Flex)`
   border-top: 1px solid #e6e6e6;
`

const SearchContainer = styled(Flex)`
   & .ant-row.ant-form-item {
      width: 100%;
   }
`

const SearchVessels = styled(AutoComplete)`
   width: 100%;
   & .ant-row.ant-form-item {
      width: 100%;
   }
`

type TProps = {
   onClose: TCallback
   onSuccess: TArgCallback<number>
   visible: boolean
}

export const AssignVesselsModal: React.FC<TProps> = ({ visible, onClose, onSuccess }) => {
   const [saving, setSaving] = useState(false)
   const [search, setSearch] = useState("")
   const [selected, setSelected] = useState<number | null>(null)
   const [options, setOptions] = useState<TOption[]>([])
   const [form] = Form.useForm<ICreateVessel & { lengthType?: string }>()
   const [searchForm] = Form.useForm()

   useEffect(() => {
      if (visible) {
         form.resetFields()
         searchForm.resetFields()
      }
   }, [visible, form, searchForm])

   const getVesselLabel = (vessel: IVessel) => {
      return `${vessel?.name} - ${vessel?.hin}`
   }

   const onSearch = (searchText: string) => {
      setSearch(searchText)
      if (selected) {
         setSelected(null)
      }
      Api.vessels
         .getAll({
            pageSize: 5,
            page: 1,
            search,
         })
         .then(({ data: dt }) => {
            const data = dt as IPaginatedResponse<IVessel[]>
            setOptions(mapOptions(data.results, getVesselLabel))
         })
   }
   const handleSelectVessel = (val: string, o: any) => {
      const option = o as TOption
      setSearch(option.label)
      setSelected(Number(val))
      searchForm.setFields([{ name: "searchVessels", value: option.label }])
   }

   const handleSubmit = async () => {
      if (selected !== null) {
         setSaving(true)
         try {
            setSaving(false)
            onSuccess(selected)
            onClose()
         } catch (e) {
            const errors = getErrors(e)
            form.setFields(errors)
            setSaving(false)
         }
      }
   }

   return (
      <StyledModal centered visible={visible} onOk={() => {}} onCancel={onClose} okText="Create" width={384}>
         <Flex flexDirection="column">
            <Msg fontWeight={900} fontSize={15} color="#151F2B">
               ASSIGN VESSEL
            </Msg>
            <Box>
               <Form form={searchForm}>
                  <SearchContainer flexDirection="column" alignItems="center" mt="16px" mb="12px">
                     <Msg fontWeight={700} fontSize={13} color="#484848">
                        Select vessel by
                     </Msg>
                     <Form.Item name="searchVessels" id="task_modal">
                        <SearchVessels
                           //getPopupContainer={() => document.getElementById("task_modal") as HTMLElement}
                           placeholder="Enter Name, Make, Model, Hull ID"
                           options={options}
                           onSearch={onSearch}
                           value={search}
                           onSelect={handleSelectVessel}
                        />
                     </Form.Item>
                  </SearchContainer>
                  <ContainerMainButtons justifyContent="flex-end" pt="16px">
                     <ModalCancelBtn onClick={onClose}>
                        <Msg fontWeight={600} fontSize={14}>
                           Cancel
                        </Msg>
                     </ModalCancelBtn>
                     <ModalCreateBtn
                        ml={16}
                        htmlType="submit"
                        disabled={!selected}
                        loading={saving}
                        onClick={handleSubmit}
                     >
                        Save
                     </ModalCreateBtn>
                  </ContainerMainButtons>
               </Form>
            </Box>
         </Flex>
      </StyledModal>
   )
}
