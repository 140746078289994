import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IJob } from "../../../api/types";
import Flex from "../../../noui/Flex";
import { Tooltip } from "antd";
import { Msg } from "../../../ui/Text";
import { InfoCircleOutlined } from "@ant-design/icons";
type TMobileDayEventProps = {
  event: IJob;
  title: string;
};
interface Props {
  isInLine?: boolean;
  isHourDuration?: boolean;
  durationOfWork?: number;
  showInfo?: boolean;
}
interface IconWrapperProps {
  paddingLeft: number;
  durationOfWork: number;
}
const EventWrapper = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => (props.isInLine ? "" : "column")};
  justify-content: ${(props) => (props.showInfo ? "space-between" : "")};
  font-weight: 600;
  width: 100%;
  height: 100%;
`;

const TooltipWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  color: black;
  margin-top: 5px;
`;
const EventItem = styled.div`
  font-weight: 400;
  font-size: 13px;
  &:not(&:last-child) {
    margin-right: 24px;
  }
`;
const EventHourTittle = styled(EventItem)<Props>`
  display: flex;
  margin-bottom: 5px;
`;
const EventItemInfoItem = styled(EventItem)<IconWrapperProps>`
  font-size: 16px;
  margin-top: ${(props) => (props.durationOfWork === 30 ? "-1px" : "-3.5px")};
  padding-left: ${(props) => props.paddingLeft}px;
`;
const EventTittleItem = styled.div`
  white-space: nowrap;
  font-weight: 700;
  font-size: 15px;
`;
const EventItemContainer = styled.div<Props>`
  display: flex;
`;
const DayEvent: React.FunctionComponent<TMobileDayEventProps> = ({ event }) => {
  const startDate = new Date(event.schedules[0].start);
  const endDate = new Date(event.schedules[0].end);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const startDateNumber = startDate as unknown as number;
  const endDateNumber = endDate as unknown as number;
  const startTimeInMinutes = startDate.getHours() * 60 + startDate.getMinutes();
  const endTimeInMinutes = endDate.getHours() * 60 + endDate.getMinutes();
  const durationOfWork =
    Math.abs(startDateNumber - endDateNumber) / (1000 * 60);
  function formatAMPM(date: Date) {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  const showEventTittle = width > 60 || durationOfWork > 30;
  const tooltipContent = (
    <TooltipWrapper>
      <EventItem>
        <Msg fontWeight={"700"}>
          {formatAMPM(startDate) + "-" + formatAMPM(endDate)}
        </Msg>
      </EventItem>
      <EventItem>{event?.title}</EventItem>
      <EventItem>{event?.vessel_data?.name}</EventItem>
      <EventItem>{event?.address?.address1}</EventItem>
      <EventItem>{`${event?.customer_data?.first_name} ${event?.customer_data?.last_name}`}</EventItem>
    </TooltipWrapper>
  );

  return (
    <EventWrapper
      isHourDuration={durationOfWork <= 60 && durationOfWork > 30}
      isInLine={height < 50}
      durationOfWork={durationOfWork}
      showInfo={height < 50 && width < 500}
      ref={ref}
    >
      {showEventTittle && (
        <EventHourTittle
          isInLine={
            (width === 154 && height < 75) || (height <= 22 && width > 71)
          }
          durationOfWork={durationOfWork}
        >
          <EventTittleItem> {formatAMPM(startDate)}-</EventTittleItem>
          <EventTittleItem>{formatAMPM(endDate)}</EventTittleItem>
        </EventHourTittle>
      )}
      {height < 50 && width < 500 ? (
        <EventItemInfoItem
          paddingLeft={showEventTittle ? 0 : (width + 10) * 0.15}
          durationOfWork={durationOfWork}
        >
          <Tooltip color={"white"} title={tooltipContent}>
            <InfoCircleOutlined />
          </Tooltip>
        </EventItemInfoItem>
      ) : (
        <EventItemContainer durationOfWork={durationOfWork}>
          <EventItem>{event?.title}</EventItem>
          <EventItem>{event?.vessel_data?.name}</EventItem>
          {event?.address?.address1 && (
            <EventItem>{event?.address?.address1}</EventItem>
          )}
          <EventItem>{`${event?.customer_data?.first_name} ${event?.customer_data?.last_name}`}</EventItem>
        </EventItemContainer>
      )}
    </EventWrapper>
  );
};

export default DayEvent;
