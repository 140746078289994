import React from 'react';
import { GetStartedMobileBlock } from './styled';
import { Msg } from '../../ui/Text';
import StartedMobile from '../../assets/images/settings/StartedMobile.svg';

const GetStartedMobile: React.FC<{mainText: string}> = ({mainText}) => {
    return <GetStartedMobileBlock>
        <Msg fontSize="16px" textAlign="center">For the best possible experience, please visit</Msg>
        <Msg 
            fontSize="20px" 
            fontWeight="700" 
            color="#109CF1" 
            margin="12px 0 27px" 
            textAlign="center"
        >
            {mainText}
        </Msg>
        <img src={StartedMobile} alt="Visit desktop version please!" />
    </GetStartedMobileBlock>
};

export default GetStartedMobile;