import { useState, useEffect } from "react"
import { Input, Modal, Form } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { EditIcon, DeleteIcon } from "../../assets/icons"
import styled from "styled-components"
import Flex from "../../noui/Flex"
import { EmptyButton, PrimaryButton } from "../../ui/Button"
import { IMaterialGroup } from "../../api/types"
import { TCallback, TArgCallback } from "../../types"
import ModalFormEditAdd from "./ModalFormEditAdd"

const Header = styled(Flex)`
   width: 100%;
   margin-bottom: 20px;
   h3 {
      font-weight: bold;
      font-size: 22px;
      color: #202020;
      margin: 0;
   }
`
const RightBlock = styled(Flex)`
   margin-left: 25px;
`
const AddButton = styled(PrimaryButton)`
   width: 147px;
   height: 34px;
   background: #0496ff;
   border: 1px solid #0496ff;
   border-radius: 6px;
   margin-bottom: 0;

   &.save {
      width: 78px;
   }
`
const CustomButton = styled(EmptyButton)`
   color: rgba(0, 0, 0, 0.54);
   font-size: 18px;
   margin-right: 25px;
   &.ant-btn {
      background: none !important;
   }
   &.cancel {
      margin: 0 10px;
   }
`
const StyledModal = styled(Modal)`
   max-width: 350px;
   & .ant-modal-body {
      padding: 16px 80px 16px 16px;
   }

   h3 {
      font-size: 15px;
      font-weight: bold;
   }
`
const EditField = styled(Flex)`
   position: relative;
   input {
      width: 100%;
      max-width: 260px;
      min-width: 260px;
   }
`

const InputError = styled.div`
   position: absolute;
   left: 3px;
   top: 105%;
   color: #fb4d4f;
   font-size: 11px;
   width: 100%;
`

type TProps = {
   group?: IMaterialGroup | null
   deleteGroup: TCallback
   editGroupName: TArgCallback<string>
   handleCreateMaterial: TArgCallback<any>
   handleCreateGroupe: TArgCallback<any>
   groups: any
   type?: string
}

const MaterialsListHeader: React.FC<TProps> = ({
   group,
   deleteGroup,
   editGroupName,
   handleCreateMaterial,
   groups,
   handleCreateGroupe,
   type,
}) => {
   const [showDeleteModal, setShowDeleteModal] = useState(false)
   const [showAddModal, setShowAddModal] = useState(false)
   const [editName, setEditName] = useState(false)
   const [input, setInput] = useState({
      name: "",
      error: "",
   })

   useEffect(() => {
      if (group) {
         setInput({ ...input, name: group.name })
      }
   }, [group])

   const handleDelete = () => {
      setShowDeleteModal(false)
      deleteGroup()
   }

   const handleSaveName = () => {
      if (input.name.trim().length > 0 && input.name.trim().length < 26) {
         editGroupName(input.name)

         setEditName(false)
      } else if (input.name.trim().length === 0) {
         setInput({
            ...input,
            error: "Please, input name!",
         })
      } else if (input.name.trim().length > 25) {
         setInput({
            ...input,
            error: " ",
         })
      }
   }

   const handleAddMaterial = (values: any) => {
      handleCreateMaterial(values)
      setShowAddModal(false)
   }

   const clearModal = () => {
      setShowAddModal(false)
   }
   return (
      <>
         <Header justifyContent="space-between" alignItems="center">
            {!editName ? (
               <h3>{input.name}</h3>
            ) : (
               <EditField alignItems="center">
                  <Input value={input.name} onChange={(e) => setInput({ ...input, name: e.target.value })} />
                  <CustomButton
                     onClick={() => {
                        setEditName(false)
                        setInput({ ...input, name: group ? group.name : "" })
                     }}
                     className="cancel"
                  >
                     <CloseOutlined />
                  </CustomButton>
                  <AddButton className="save" onClick={handleSaveName}>
                     Save
                  </AddButton>
                  {input.error && <InputError>{input.error}</InputError>}
               </EditField>
            )}
            <RightBlock alignItems="center">
               {!editName && !group?.order && (
                  <CustomButton onClick={() => setEditName(true)}>
                     <EditIcon verticalAlign="middle" />
                  </CustomButton>
               )}

               {!group?.order && (
                  <CustomButton onClick={() => setShowDeleteModal(true)}>
                     <DeleteIcon width="16px" verticalAlign="middle" />
                  </CustomButton>
               )}

               <Form.Item style={{ marginBottom: "0" }}>
                  <AddButton onClick={() => setShowAddModal(true)}>
                     Add {type === "rateCard" ? "service" : "material"}
                  </AddButton>
               </Form.Item>
            </RightBlock>
         </Header>

         <StyledModal
            destroyOnClose
            centered
            visible={showDeleteModal}
            onOk={handleDelete}
            onCancel={() => setShowDeleteModal(false)}
            okText="Ok"
         >
            <h3>Are you sure want to delete {type === "rateCard" ? "service" : "material"} group?</h3>
         </StyledModal>
         <ModalFormEditAdd
            visible={showAddModal}
            handleSubmit={handleAddMaterial}
            clearModal={clearModal}
            activeGroup={group}
            groups={groups}
            handleCreateGroupe={handleCreateGroupe}
            type={type}
         />
      </>
   )
}
export default MaterialsListHeader
