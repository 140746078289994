import "./styles.less"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { authService } from "./utils/authService"
import { useEffect } from "react"
import { Api } from "./api/api"
import { getCurrentUser } from "./config/reducers/user/selectors"
import routes from "./routes/routes"
import { Route, Switch, useHistory } from "react-router-dom"
import { ComponentsLayout } from "./layouts/ComponentsLayout"
import { ClearLayout } from "./layouts/ClearLayout"
import { TRoute } from "./layouts/types"
import { setConstants, setCurrentUser } from "./config/reducers/user/actions"
import { useAppDispatch, useAppSelector } from "./config/hooks"
import { useTechnicianRole } from "./utils/hooks"

const cleanRoutes: TRoute[] = [
   routes.registration.Base,
   routes.registration.Step2,
   routes.registration.Step3,
   routes.auth.ForgotPassword,
   routes.auth.ResetPassword,
   routes.auth.SetNewPassword,
   routes.customers.CustomerEstimateView,
   routes.customers.CustomerInvoiceView,
   routes.customers.CustomerSubscriptionView,
   routes.customers.ScheduleView,
   routes.customers.StatementOfWorkView,
   routes.customers.CustomerTermsView,
   routes.auth.Login,
   routes.auth.CheckEmail,
].map((route) => ({ path: route, component: ClearLayout }))

const App = () => {
   const dispatch = useAppDispatch()
   const user = useAppSelector(getCurrentUser)
   const history = useHistory()
   const technicianRole = useTechnicianRole()

   useEffect(() => {
      return history.listen((location: any) => {
         //@ts-ignore
         analytics.page()
      })
   }, [history])

   useEffect(() => {
      if (authService.getLocalToken()) {
         Api.user
            .profile()
            .then(({ data }) => {
               dispatch(setCurrentUser(data))
            })
            .catch(() => {})
      }
      Api.config.getConstants().then(({ data }) => {
         dispatch(setConstants(data))
      })
   }, [dispatch])

   useEffect(() => {
      if (user && !technicianRole) {
         if (user.company === null) {
            if (user.is_staff) {
               // User is SuperAdmin and not impersonating -> Forward to dashboard
               history.replace(routes.main.SUDashboard)
            } else {
               // Generic user not completed company registration step
               history.replace(routes.registration.Step2)
            }
         } else if (checkInvalidStipeSubscription() && checkInvalidPESubscription()) {
            history.replace(routes.registration.Step3)
         }
      }
   }, [user, history])
   const checkInvalidPESubscription = () =>
      user?.company?.subscription_status?.status === "O" || user?.company?.subscription_status === null

   const checkInvalidStipeSubscription = () =>
      (user?.company && user?.company?.stripe_subscription?.status === "unpaid") ||
      user?.company?.stripe_subscription === null ||
      user?.company?.stripe_subscription?.status === "canceled"

   return (
      <HelmetProvider>
         <Helmet>
            <title>DockWorks Pro</title>
         </Helmet>
         <Switch>
            {cleanRoutes.map(({ private: _, ...route }) => (
               <Route key={route.path} {...route} />
            ))}
            <Route path="/" component={ComponentsLayout} />
         </Switch>
      </HelmetProvider>
   )
}

export default App