import { Modal, notification } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { Api } from "../../../api/api"
import { EJobProgress, IJob } from "../../../api/types"
import { Msg } from "../../../ui/Text"
import { EstimateConfig } from "../../jobs/EstimateConfig"

type TModalProps = {
   isError?: boolean
}

const StyledModal = styled(Modal)<TModalProps>`
   & .ant-modal-body {
      padding: 0;
   }
`

type TProps = {
   visible: boolean
   onClose: () => void
   workOrderNumber?: number
}

const ShareSettingsModal: React.FC<TProps> = ({ visible, onClose, workOrderNumber }) => {
   useEffect(() => {}, [])

   const onOk = async (data: any) => {
      if (workOrderNumber) {
         Api.workOrders.postSendSettings({ ...data, id: workOrderNumber })
      }
      onClose()
   }

   const onCancel = () => {
      onClose()
   }

   return (
      <StyledModal visible={visible} footer={null} width={305} onCancel={onCancel} destroyOnClose={true}>
         <EstimateConfig
            onOk={onOk}
            onClose={onCancel}
            isRecurring={false}
            workOrderNumber={workOrderNumber}
            shareModal={true}
         />
      </StyledModal>
   )
}

export default ShareSettingsModal
