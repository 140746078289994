import { Button } from "antd";
import { Msg } from "../ui/Text";
import { WarningModal } from "./PaymentWarningMessage";

type TProps = {
    visible: boolean
    onOk: ()=>void
    onCancel: ()=>void
}

const ChangePlanWarning: React.FC<TProps> = ({visible, onOk, onCancel}) => {
    return <WarningModal
        title="Warning"
        visible={visible}
        width={384}
        onOk={onOk}
        onCancel={onCancel}
        footer={[
            <Button key="submit" type="primary" onClick={onOk}>
                My subscription
            </Button>
        ]}
    >   
        <Msg>Current payment method could not be processed, please go to “My subscription” page and change credit card or add new!</Msg>          
    </WarningModal>
}

export default ChangePlanWarning;