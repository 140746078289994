import React from 'react';
import { Modal, Form, Input } from 'antd';
import {Api} from '../api/api';
import {getErrors} from "../utils/utils";

type TProps = {
    visible: boolean;
    handleClose: () => void;
}

const ChangePassword: React.FC<TProps> = ({ visible, handleClose }) => {
    const [form] = Form.useForm();

    const clearModal = () => {
        handleClose();
        form.resetFields();
    }

    const handleSubmit = async () => {
        const data = await form.validateFields();
        const {old_password, new_password} = data;
        try {
          await Api.user.changePassword({old_password, new_password});
          clearModal();
        } catch (e) {
            const errors = getErrors(e);
            form.setFields(errors);
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={clearModal}
            onOk={handleSubmit}
            title="Change Password"
            okText="Save"
            width={404}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="old_password" label="Current Password" rules={[
                    {required: true, message: 'This field is required'}
                ]}>
                    <Input.Password placeholder="Password"/>
                </Form.Item>
                <Form.Item name="new_password" label="New Password" rules={[
                    {required: true, message: 'This field is required'}
                ]}>
                    <Input.Password placeholder="Password"/>
                </Form.Item>
                <Form.Item name="confirm_password" label="Confirm New Password" rules={[
                    {required: true, message: 'This field is required'},
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('New password and confirm new password do not match'));
                        },
                    })
                ]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ChangePassword;