import { Tooltip } from 'antd';
import styled from 'styled-components';
import {
  fontSize, FontSizeProps,
  fontWeight, FontWeightProps,
  space, SpaceProps,
  width, WidthProps,
  height, HeightProps,
  color, ColorProps
} from 'styled-system';


type TProps =
  & FontSizeProps
  & FontWeightProps
  & SpaceProps
  & WidthProps
  & HeightProps
  & ColorProps;

export const PrimaryTooltip = styled(Tooltip)<TProps>`
  ${space};
  ${width};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${color};
`;