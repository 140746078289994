//@ts-nocheck 
import { useEffect, useRef, useState } from "react";

type Props = {
    defaultHeight?: number
    visibleOffset?: number
    root?: HTMLElement
}

export const RenderIfVisible: React.FC<Props> = ({
    defaultHeight = 300, visibleOffset = 1000, root = null, children
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const placeholderHeight = useRef<number>(defaultHeight);
    const intersectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (intersectionRef.current) {
            const observer = new IntersectionObserver(
                entries => {
                    if (typeof window !== undefined && window.requestIdleCallback) {
                        window.requestIdleCallback(
                            () => setIsVisible(entries[0].isIntersecting),
                            {
                                timeout: 600
                            }
                        );
                    } else {
                        setIsVisible(entries[0].isIntersecting);
                    }
                },
                { root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` }
            );
            observer.observe(intersectionRef.current);
            return () => {
                if (intersectionRef.current) {
                    observer.unobserve(intersectionRef.current);
                }
            };
        }
    }, [intersectionRef]);

    useEffect(() => {
        if (intersectionRef.current && isVisible) {
            placeholderHeight.current = intersectionRef.current.offsetHeight;
        }
    }, [isVisible, intersectionRef]);

    return (
        <div ref={intersectionRef}>
            {isVisible ? (
                <>{children}</>
            ) : (
                <div style={{ height: placeholderHeight.current }} />
            )}
        </div>
    );
};
