import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Flex from "../../../../noui/Flex";
import { Msg } from "../../../../ui/Text";
import { getSvgByValue } from "../../../../assets/images/weatherIcons";
import Humidity from "../../../../assets/images/Humidity.svg";
import { getHumidity } from "../../../../utils/utils";
import { formatWeatherRequestVessel } from "../../../../api";

type TMainContainerStatsProps = {
  address: any | null;
  nextJob: any | null;
  fullStart: string;
};

const Weather: React.FC<TMainContainerStatsProps> = ({
  nextJob,
  address,
  fullStart,
}) => {
  const [fullWeather, setWeather] = useState<any | null>(null);
  const [timeIndex, setTimeIndex] = useState<number>(0);

  useEffect(() => {
    if (address) {
      axios
        .get(formatWeatherRequestVessel(address))
        .then((w) => setWeather(w.data));
    }
  }, [address]);

  useEffect(() => {
    const duration = moment.duration(moment(fullStart).diff(moment()));
    const newTimeIndex = +duration.asHours().toFixed(0);
    setTimeIndex(newTimeIndex > 0 ? newTimeIndex : 0);
  }, [fullStart]);

  const renderForecastDate = () => {
    const date =
      fullWeather &&
      fullWeather?.forecast?.daily?.find((item: { day_start_local: any }) => {
        return moment.unix(item.day_start_local).format("L") === nextJob;
      });
    return date ? date : null;
  };
  const renderForecastHumidityIndex = () => {
    const date =
      fullWeather &&
      fullWeather?.forecast?.hourly?.findIndex((item: { time: any }) => {
        return moment.unix(item.time).format("L") === nextJob;
      });

    return date !== 1 ? date : null;
  };

  const weather = renderForecastDate();
  const humidity = renderForecastHumidityIndex();
  // console.log("fullWeather", fullWeather)
  return (
    weather &&
    humidity !== -1 && (
      <Flex flexDirection="column">
        <Msg fontSize="15px" fontWeight="bold" margin="0 10px 0 0">
          Weather
        </Msg>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          style={{ flexGrow: 1 }}
        >
          <Flex flexDirection="column" alignItems="start">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Flex flexDirection="row">
                {getSvgByValue(fullWeather?.forecast?.hourly[timeIndex]?.icon)}
                <Flex flexDirection="column" marginLeft="5px">
                  <Msg fontSize={11} fontWeight={600}>
                    {fullWeather?.forecast?.hourly[timeIndex]?.conditions}
                  </Msg>
                  <Msg color="#257ABF" fontWeight={700}>
                    {
                      fullWeather?.forecast?.hourly[timeIndex]
                        ?.precip_probability
                    }
                    %
                  </Msg>
                </Flex>
              </Flex>
              <Flex alignItems="baseline" marginLeft="15px">
                <img src={Humidity} style={{ width: "24px", height: "24px" }} />

                <Msg color="#257ABF" fontWeight={700}>
                  {fullWeather?.forecast?.hourly[timeIndex]?.relative_humidity}%
                </Msg>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  );
};

export default Weather;
