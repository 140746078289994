import React, { useState, useEffect, useMemo } from "react"
import moment, { unitOfTime } from "moment"
import { PlusOutlined } from "@ant-design/icons"
import { Table, notification, Tooltip, Button, Form, Pagination, Spin, Popconfirm, Dropdown } from "antd"
import { ColumnsType } from "antd/lib/table"
import { Api } from "../../../api/api"
import { usePagination, useLoading } from "../../../utils/hooks"
import { IUser, ITimeClock, IJob, EUserRole, EJobProgress } from "../../../api/types"
import { filterColumns, formatTime, formatTimeTimeClockSum, getInitials, downloadFile } from "../../../utils/utils"
import { TableSelect, ExportBtn } from "../../../ui/TableUtils"
import { ColumnsSelector } from "../../../modals/ColumnsSelector/ColumnsSelector"
import { Columns } from "../../../constants"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../../config/reducers/user/selectors"
import { getColumns } from "../../../config/reducers/columns/selectors"
import { useResponsive } from "../../../utils/hooks"
import Flex from "../../../noui/Flex"
import Box from "../../../noui/Box"
import { Msg } from "../../../ui/Text"
import { TimeInput } from "../../../ui/TimeInput"
import { AddTimeManually } from "../../../modals/AddTimeManually"
import { TechnicianModal } from "../../jobs/content/TechnicianModal"
import {
   BlockTitle,
   AddTechnician,
   TechnicianWrapper,
   StatsWrapper,
   InitialsWrapper,
   TechnicianItem,
   TechnicianName,
   BottomTechnician,
   NotificationComplete,
   StyledMenu,
   OptionButton,
} from "../styled"
import { EmptyButton } from "../../../ui/Button"
import { DeleteIcon, EditIcon } from "../../../assets/icons"
import { EllipsisOutlined } from "@ant-design/icons"

import { TCallback } from "../../../types"
import { TableCard } from "../../../components/TableCard"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import { track } from "../../../utils/analytics"

const stats = [
   { label: "Today", value: "day" },
   { label: "This week", value: "week" },
   { label: "This month", value: "month" },
]

type TCProps = {
   estimate: number | undefined
   technician: IUser
   loadCards: TCallback
   timeClocks: ITimeClock[]
   jobProgress?: string
}

const StyledTableSelect = styled(TableSelect)`
   margin-left: 0px;
`
const StyledButton = styled(Button)`
   svg {
      font-size: 16px;
      vertical-align: bottom;
      color: #757575;
   }
   &:hover {
      svg {
         color: #0496ff;
      }
   }
`

const TechnicianCard: React.FC<TCProps> = ({ estimate, technician, loadCards, timeClocks, jobProgress }) => {
   const [started, setStarted] = useState<boolean>(false)
   const [timeValue, setTimeValue] = useState<number>(0)
   const [clockedIn, setClockedIn] = useState<string>("")
   const [showModal, setShowModal] = useState<boolean>(false)
   const [isHidden, setIsHidden] = useState<boolean>(false)
   const [startTime, setStartTime] = useState<string | undefined>()
   const [startId, setStartId] = useState<number | undefined>()
   const isXs = useBreakpoint(down("xs"))

   useEffect(() => {
      let timer: any
      if (started) {
         timer = setTimeout(async () => {
            if (document.hidden && !isHidden) {
               setIsHidden(true)
            } else if (!document.hidden && isHidden && startTime && startId) {
               const active = await Api.timeClocks.get(startId)
               if (!active.data.stop) {
                  setTimeValue(moment.duration(moment().diff(moment(startTime))).asSeconds())
                  setIsHidden(false)
                  return
               }
               setStarted(false)
               setClockedIn("")
               setTimeValue(0)
               if (active.data.stop) {
                  await loadCards()
               }
               return
            }
            setTimeValue(timeValue + 1)
         }, 1000)
      } else {
         setTimeValue(0)
      }
      return () => {
         clearTimeout(timer)
      }
   }, [started, timeValue])

   useEffect(() => {
      const newActive = timeClocks.find((clock) => clock.stop === null)
      setStartTime(newActive?.start)
      setStartId(newActive?.id)
      if (newActive) {
         setStarted(true)
         setClockedIn(newActive.start)
         setTimeValue(moment.duration(moment().diff(moment(newActive.start))).asSeconds())
      } else {
         setStarted(false)
         setClockedIn("")
         setTimeValue(0)
      }
   }, [timeClocks])

   const getWorkTime = (format: unitOfTime.StartOf) =>
      timeClocks
         .filter((t) => moment(t.start).toDate() > moment().startOf(format).toDate())
         .reduce((acc, b) => acc + (b.stop ? moment(b.stop).unix() - moment(b.start).unix() : 0), 0)

   const handleStart = async () => {
      try {
         const timeClock = await Api.timeClocks.start({
            job: estimate,
            user: technician.id,
         })
         const res = timeClock.data
         track("Clocked In", {
            workorderId: res.job.work_order,
            vesselId: res.job?.vessel.id,
            technician: res.user.id,
            jobId: res.job.id,
            clockInTimestamp: res.start,
         })
         setStarted(true)
         setClockedIn(new Date().toISOString())
         setStartTime(timeClock.data.start)
         setStartId(timeClock.data.id)
         await loadCards()
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const handleStop = async () => {
      try {
         const activeTimeClock = timeClocks.find((t) => t.stop === null)
         const { data } = await Api.timeClocks.stop(activeTimeClock?.id ?? timeClocks[0].id)
         track("Clocked Out", {
            workorderId: data.job.work_order,
            vesselId: data.job?.vessel.id,
            technician: data.user.id,
            jobId: data.job.id,
            clockInTimestamp: data.start,
            timeLoggedHours: moment(data.stop).diff(moment(data.start), "hours"),
         })
         setStarted(false)
         setClockedIn("")
         await loadCards()
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   return (
      <TechnicianItem px="24px" pt="18px" pb="26px">
         <AddTimeManually
            visible={showModal}
            handleClose={() => setShowModal(false)}
            displayJobs={false}
            technician={technician}
            estimateId={estimate}
            loadCards={loadCards}
         />
         <InitialsWrapper>
            <Msg fontSize={18} fontWeight={900}>
               {getInitials(technician).replace(/\s/g, "")}
            </Msg>
         </InitialsWrapper>
         <Tooltip title={technician.full_name}>
            <Flex
               flexDirection="column"
               alignItems="center"
               style={{
                  fontSize: "15px",
                  fontWeight: 900,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  height: "55px",
               }}
            >
               <TechnicianName mt="8px">{technician.full_name}</TechnicianName>
            </Flex>
         </Tooltip>
         <TimeInput
            step="1"
            active={started}
            value={started ? formatTimeTimeClockSum(timeValue, true) : "--:--:--"}
            //type="time"
            bordered={false}
            name="time"
            disabled={true}
         />
         {started && (
            <Msg
               fontSize={15}
               textAlign="center"
               width="100%"
               display="block"
               marginBottom="10px"
            >{`Clocked in ${moment(clockedIn).format("hh:mm a")}`}</Msg>
         )}
         <Button
            type="primary"
            danger={started}
            onClick={started ? handleStop : handleStart}
            style={{ borderRadius: "6px", width: !isXs ? "65%" : "95%", height: "34px" }}
            disabled={jobProgress === EJobProgress.Complete}
         >
            Clock {started ? "out" : "in"}
         </Button>
         <BottomTechnician>
            <Button
               type="text"
               onClick={() => setShowModal(true)}
               disabled={started || jobProgress === EJobProgress.Complete}
            >
               <Msg color="rgba(4, 150, 255, 1)" style={{ textDecoration: "underline" }}>
                  Add time manually
               </Msg>
            </Button>
            <StatsWrapper>
               {stats.map((s) => (
                  <Flex flexDirection="column" alignItems="center">
                     <Msg fontSize={13} color="rgba(130, 130, 130, 1)">
                        {s.label}
                     </Msg>
                     <Msg fontSize={13}>
                        {formatTimeTimeClockSum(getWorkTime(s.value as unitOfTime.StartOf), false)}
                     </Msg>
                  </Flex>
               ))}
            </StatsWrapper>
         </BottomTechnician>
      </TechnicianItem>
   )
}

type TProps = {
   job: IJob | null
   technicianRole: boolean
   updateJobById: (id: number) => void
   setIsFormFieldChanged?: (value: boolean) => void
}

export const TimeClock: React.FC<TProps> = ({ job, technicianRole, updateJobById, setIsFormFieldChanged }) => {
   const userData = useSelector(getCurrentUser)
   const [form] = Form.useForm()
   const [users, setUsers] = useState<IUser[]>([])
   const [technicians, setTechnicians] = useState<IUser[]>([])
   const [showModal, setShowModal] = useState<boolean>(false)
   const [selectColumns, setSelectColumns] = useState<boolean>(false)
   const [addManually, setAddManually] = useState<boolean>(false)
   const [selectedTimeClock, setSelectedTimeClock] = useState<ITimeClock | null>(null)
   const { data, loading, onPromise, requestPagination, pagination, onChange } = usePagination<ITimeClock>()
   const [saving, onSave] = useLoading()
   const { width } = useResponsive()
   const filtered = useSelector(getColumns(Columns.TimeClockTab))
   const isLg = width < 1400

   const [isLoading, setIsLoading] = useState(false)
   const updateTable = () => job && onPromise(Api.timeClocks.getAll({ ...requestPagination, job: job?.id }))
   const getRate = (record: ITimeClock) =>
      (
         ((job?.technicians_data.find((t) => t.technician === record.user.id)?.rate ?? 0) *
            moment(record.stop).diff(moment(record.start), "seconds")) /
         3600
      ).toFixed(2)

   useEffect(() => {
      setIsLoading(true)
      Api.user
         .getAll({})
         .then(({ data }) => {
            setUsers(data as IUser[])
         })
         .finally(() => setIsLoading(false))
   }, [job?.progress])

   useEffect(() => {
      if (!isLoading && users.length && !loading) {
         let techs: number[] = []
         job?.technicians_data.forEach((t) => {
            if (!techs.includes(+t.technician)) {
               techs.push(+t.technician)
            }
         })
         data.forEach((d) => {
            if (!techs.includes(d.user.id)) {
               techs.push(d.user.id)
            }
         })
         setTechnicians(users.filter((u) => techs.includes(u.id)))
      }
   }, [isLoading, users, loading])

   useEffect(() => {
      updateTable()
   }, [requestPagination, onPromise, job])

   const loadCards = () => {
      updateTable()
      if (job?.id) {
         updateJobById(job.id)
      }
   }

   const handleDeleteTimeClock = async (id: number) => {
      await onSave(Api.timeClocks.delete(id))
      loadCards()
   }

   const handleEdit = (timeClock: ITimeClock) => {
      setSelectedTimeClock(timeClock)
      setAddManually(true)
   }

   const handleCloseTimeManually = () => {
      setSelectedTimeClock(null)
      setAddManually(false)
   }

   const columns: ColumnsType<ITimeClock> = useMemo(
      () => [
         {
            title: "#",
            key: "order_number",
            dataIndex: "order_number",
            sorter: true,
            render: (_, record) => `${record.job.order_number}`,
         },
         {
            title: "Order Name",
            key: "title",
            sorter: true,
            dataIndex: "title",
            render: (_, record) => record.job.title,
         },
         {
            title: "Technician",
            key: "technician",
            sorter: true,
            dataIndex: "technician",
            render: (_, record) => record.user.full_name,
         },
         {
            title: "Start",
            key: "start",
            sorter: true,
            dataIndex: "start",
            render: (_, record) => moment(record.start).format("L, hh:mm a"),
         },
         {
            title: "Stop",
            key: "stop",
            sorter: true,
            dataIndex: "stop",
            render: (_, record) => (record.stop ? moment(record.stop).format("L, hh:mm a") : ""),
         },
         {
            title: "Duration",
            key: "stop",
            sorter: true,
            dataIndex: "stop",
            render: (_, record) => {
               const start = moment(record.start)
               const stop = moment(record.stop)
               const hourDiff = stop.diff(start, "hours")
               const minutesDiff = stop.diff(start, "minutes") % 60
               const secondsDiff = stop.diff(start, "seconds") % 60
               return (
                  (hourDiff ? `${hourDiff} hours ` : "") +
                  (minutesDiff ? `${minutesDiff} minutes ` : "") +
                  (secondsDiff ? `${secondsDiff} seconds` : "")
               )
            },
         },
         {
            title: "Total",
            key: "total",
            sorter: true,
            dataIndex: "total",
            render: (_, record) => (record.stop ? `$${getRate(record)}` : ""),
         },
         {
            title: "Actions",
            dataIndex: "id",
            key: "id",
            fixed: "right",
            render: (_, record) => (
               <Flex justifyContent="center" position="relative">
                  <Dropdown
                     trigger={["hover"]}
                     // getPopupContainer={(trigger: any) => trigger.parentElement}
                     overlay={() => (
                        <StyledMenu>
                           <Flex flexDirection="column" alignItems="flex-start">
                              <OptionButton onClick={() => handleEdit(record)} marginBottom="16px">
                                 <Flex alignItems="center">
                                    <EditIcon />
                                    <Msg marginLeft="9px">Edit</Msg>
                                 </Flex>
                              </OptionButton>
                              <Popconfirm
                                 title={`Are you sure want to delete this record?`}
                                 onConfirm={() => handleDeleteTimeClock(record.id)}
                                 trigger={["click"]}
                              >
                                 <OptionButton>
                                    <Flex alignItems="center">
                                       <DeleteIcon />
                                       <Msg marginLeft="9px">Delete</Msg>
                                    </Flex>
                                 </OptionButton>
                              </Popconfirm>
                           </Flex>
                        </StyledMenu>
                     )}
                     placement="bottomRight"
                     arrow
                  >
                     <EmptyButton onClick={(e) => e.preventDefault}>
                        <EllipsisOutlined style={{ fontSize: "18px", marginTop: "5px" }} />
                     </EmptyButton>
                  </Dropdown>
               </Flex>
            ),
         },
      ],
      []
   )

   const onSelect = (value: IUser) => {
      if (job) {
         setTechnicians([...technicians, value])
      } else {
         notification.error({
            message: "You must create job first",
         })
      }
   }

   const filterTechs = () => {
      const filteredTechs: IUser[] = []
      technicians.forEach((t) => {
         if (!filteredTechs.find((tech) => tech.id === t.id)) {
            filteredTechs.push(t)
         }
      })
      return filteredTechs
   }

   const handleFilters = async () => {
      const { technician } = await form.validateFields()
      const params = {
         ...(technician && { user: technician }),
         ...(job && { job: job.id }),
         ...requestPagination,
      }
      onPromise(Api.timeClocks.getAll(params))
   }

   const handleExport = async () => {
      const { data } = await onSave(Api.timeClocks.download())
      downloadFile(data, `TimeClocksList ${moment().format("LLL")}.xlsx`)
   }

   return (
      <Flex flexDirection="column">
         <TechnicianModal
            choosedUsers={technicians}
            visible={showModal}
            onSelect={onSelect}
            onClose={() => setShowModal(false)}
         />
         <BlockTitle mb="16px">Time clocks</BlockTitle>
         <Spin spinning={isLoading}>
            {job?.progress === EJobProgress.Complete && (
               <NotificationComplete>
                  <h3>Job is completed</h3>
                  <p>
                     {technicianRole
                        ? "This job has been marked complete. If you think your time has been reported incorrectly, contact your administrator."
                        : "This job has been marked complete. You can manually add, edit, or remove time. If you need to clock time for this work order, create a new job below."}
                  </p>
               </NotificationComplete>
            )}
            <TechnicianWrapper>
               {job &&
                  technicians.map((t) => {
                     if (!technicianRole) {
                        return (
                           <TechnicianCard
                              key={t.id}
                              loadCards={loadCards}
                              timeClocks={data.filter((d) => d.user.id === t.id)}
                              technician={t}
                              estimate={job.id}
                              jobProgress={job.progress}
                           />
                        )
                     } else {
                        if (t.id === userData?.id) {
                           return (
                              <TechnicianCard
                                 key={t.id}
                                 loadCards={loadCards}
                                 timeClocks={data.filter((d) => d.user.id === t.id)}
                                 technician={t}
                                 estimate={job.id}
                                 jobProgress={job.progress}
                              />
                           )
                        }
                     }
                     return null
                  })}
               {job?.progress === EJobProgress.Complete ||
                  (!technicianRole && technicians.length < users.length && (
                     <AddTechnician onClick={() => setShowModal(true)} />
                  ))}
            </TechnicianWrapper>
         </Spin>
         {!technicianRole && (
            <Box px="4px" mt="20px">
               <Flex justifyContent="space-between" alignItems="center">
                  <Flex alignItems="center">
                     <Form form={form}>
                        <Flex>
                           <Form.Item name="technician" style={{ marginTop: "16px" }}>
                              <StyledTableSelect
                                 placeholder={"All technicians"}
                                 onSelect={handleFilters}
                                 getPopupContainer={(trigger: any) => trigger.parentElement}
                              >
                                 <TableSelect.Option value={0}>All technicians</TableSelect.Option>
                                 {filterTechs().map((t) => (
                                    <TableSelect.Option key={t.id} value={t.id}>
                                       {t.full_name}
                                    </TableSelect.Option>
                                 ))}
                              </StyledTableSelect>
                           </Form.Item>
                        </Flex>
                     </Form>
                     {width > 768 && (
                        <Button style={{ height: "34px", marginLeft: "20px" }} onClick={() => setSelectColumns(true)}>
                           Add Columns <PlusOutlined />
                        </Button>
                     )}
                  </Flex>
                  <Flex>
                     <ExportBtn
                        style={{ marginRight: 0, marginLeft: isLg ? "16px" : "0px" }}
                        loading={saving}
                        onClick={handleExport}
                     >
                        Export
                     </ExportBtn>
                  </Flex>
               </Flex>
               <ColumnsSelector
                  name={Columns.TimeClockTab}
                  columns={columns}
                  visible={selectColumns}
                  onClose={() => setSelectColumns(false)}
               />
               <AddTimeManually
                  loadCards={loadCards}
                  displayJobs={true}
                  //jobs={jobs}
                  visible={addManually}
                  handleClose={handleCloseTimeManually}
                  technicians={technicians}
                  selectedTimeClock={selectedTimeClock}
               />
               {!isLg ? (
                  <Table
                     columns={filterColumns(columns, filtered)}
                     className="contained timeclocks-table"
                     dataSource={data}
                     loading={loading}
                     pagination={(pagination?.total ?? 0) > 10 ? pagination : false}
                     onChange={onChange}
                     locale={{ emptyText: "No timesheets results were found" }}
                  />
               ) : (
                  <Flex flexWrap="wrap">
                     {data.map((d) => (
                        <TableCard key={d.id}>
                           {columns.map((c, i) => (
                              <Flex key={i} justifyContent="space-between">
                                 <Msg fontWeight={700}>{c.title}</Msg>
                                 <Msg style={{ textAlign: "end" }}>{c.render && c.render(d, d, i)}</Msg>
                              </Flex>
                           ))}
                        </TableCard>
                     ))}
                     {!!pagination?.total && pagination.total > 10 && (
                        <Pagination
                           current={pagination.current}
                           pageSize={pagination.pageSize}
                           total={pagination.total}
                           onChange={(p) => {
                              onChange({ ...pagination, current: p }, {}, {})
                           }}
                           style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
                           size="small"
                        />
                     )}
                  </Flex>
               )}
            </Box>
         )}
      </Flex>
   )
}
