import React, { useEffect, useState, createRef, useLayoutEffect, useContext, useCallback } from "react"
import { useReactToPrint } from "react-to-print"
import { jsPDF } from "jspdf"
import { useLocation, useHistory } from "react-router-dom"
import { HELP_PAGE_URL } from "../../constants"
import styled from "styled-components"
import { Button, Dropdown, Form, Input, Menu, notification, Popconfirm, Tabs, Tooltip, Affix, Modal } from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { CreateServicePlanIcon, EditIcon } from "../../assets/icons"
import { Api } from "../../api/api"
import DeleteIcon from "../../assets/images/delete_icon.svg"
import PrintIcon from "../../assets/images/print_icon.svg"
import { LaborTab } from "./tabs/ServicesTab"
import { TimeClock } from "./tabs/TimeClock"
import { DateTimeTab } from "./tabs/DateTime"
import { PhotosTab } from "./tabs/Photos"

import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { AssignButton, InfoContainer, OrderInfoHeader, OrderInfoWrapper, ResponsiveFlex, TabsWrapper } from "./styled"
import { TechnicianModal } from "../jobs/content/TechnicianModal"
import { SendEstimateModal } from "../jobs/SendEstimateModal"
import {
   EJobPriority,
   EJobProgress,
   IJob,
   IJobTechData,
   IJobTechnician,
   IUpdateJob,
   IUser,
   IWorkOrderWithJobs,
   ITimeClock,
   ERecurringStatus,
} from "../../api/types"
import { TArgCallback, TCallback } from "../../types"
import { SideContent } from "./components/SideContent"
import { ProgressSteps } from "./components/Steps"
import { JobProgress } from "../../constants"
import { useLoading, useQuery, useResponsive } from "../../utils/hooks"
import moment from "moment"
import Technician from "../../assets/images/Technician.svg"
import Schedule from "../../assets/images/Schedule.svg"
import { ApprovedAlert } from "./Alerts"
import { ScheduleModal } from "./ScheduleModal"
import { EmptyButton } from "../../ui/Button"
import { MessagesTab } from "../jobs/content/MessagesTab"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import { formatPriority, formatToUSD, getErrors, renderAddress, renderFormDataSend } from "../../utils/utils"
import { StyledButton } from "../../containers/settings/SubscriptionCard"
import { useAppSelector } from "../../config/hooks"
import { getCurrentUser } from "../../config/reducers/user/selectors"

import { useDispatch, useSelector } from "react-redux"
import { getMenuItem } from "../../config/reducers/menuClickReducer/selectors"
import { setMenuItem } from "../../config/reducers/menuClickReducer/actions"

import { track } from "../../utils/analytics"
import PrintEstimate from "./PrintDocuments/PrintJob"
import ModalPrintCheckboxes from "./PrintDocuments/ModalPrintCheckboxes"
import { SendScheduleModal } from "../jobs/SendScheduleModal"
import { CompleteModal } from "./CompleteModal/CompleteModal"
import { JobProgressModal } from "./JobProgressModal"
import PdfJob from "../jobs/pdfContent/PdfJob"
import logo from "../../assets/images/dockworks_logo.png"
import { WOContext } from "./WorkOrderContext"

function getCoords(elem: any) {
   if (!elem) return
   let box = elem.getBoundingClientRect()
   return box.top + window.pageYOffset
}

export const tabs = [
   { key: "1", title: "Service & Materials", component: LaborTab },
   { key: "2", title: "Technicians & Scheduler", component: DateTimeTab },
   { key: "3", title: "Photos & Notes", component: PhotosTab },
   { key: "4", title: "Time Clocks", component: TimeClock },
]

type TProps = {
   job: IJob
   estimateSent?: boolean
   updateInfo: () => any
   isDespositPaid: boolean
   technicianRole: boolean
   workOrder?: IWorkOrderWithJobs | null
   isInvoice: boolean
   isCurrentSPJob?: boolean
   lastWOJob?: boolean
   updateJobById: (id: number) => void
   onAddJob: TCallback
   openRecurringModal: TCallback
   activeTabSecond: string
   jobRecurringPrice?: number | null
   showSaveModal: (value: boolean) => void
}
type PrintBlocks = {
   woPricing: boolean
   woPhotos: boolean
   vesselPhoto: boolean
   checkAll: boolean
}

const StyledMenu = styled(Menu)`
   li {
      white-space: normal;
   }
`

const OptionButton = styled(EmptyButton)`
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   height: 34px;
   margin-right: 12px;
   padding: 0 6px;
`
const StyledInput = styled(Input)`
   &:disabled {
      background: #fff;
      color: #202020;
      border: none;
      font-size: 20px;
   }
`

export const SaveButton = styled(StyledButton)`
   margin: 0;
   width: 100px;
`

const RecurringStatusBlock = styled(Flex)`
   width: 100%;
   text-align: right;
   padding-right: 8px;
   > span {
      width: 100%;
   }
`
const HiddenPdfDoc = styled.div`
   position: absolute;
   top: 0px;
   left: 200px;
   width: 595px;
   height: 0;
   overflow: hidden;
`
const CreateServicePlanBtn = styled(Button)`
   border: none;
   color: #0496ff;
   font-size: 15px;
   font-weight: bold;
   text-decoration: underline;
   svg {
      margin-right: 5px;
   }
   ${down("xs")} {
      padding: 0;
      > div {
         font-size: 13px;
      }
   }
`

export const WorkOrderInfo: React.FC<TProps> = ({
   job,
   estimateSent,
   updateInfo,
   technicianRole,
   isDespositPaid,
   workOrder,
   isInvoice,
   isCurrentSPJob,
   lastWOJob,
   updateJobById,
   onAddJob,
   openRecurringModal,
   activeTabSecond,
   showSaveModal,
   jobRecurringPrice,
}) => {
   const [showMore, setShowMore] = useState<boolean>(!workOrder?.is_recurring)
   const [form] = Form.useForm<IUpdateJob>()
   const [estimate, setEstimate] = useState<IJob | null>(null)
   const [saving, onSave] = useLoading()
   const [saved, setSaved] = useState<boolean>(false)
   const [technician, setTechnician] = useState<IUser | null>(null)
   const [schedule, setSchedule] = useState<string>("")
   const [showTechModule, setShowTechModule] = useState<boolean>(false)
   const [timeModalVisible, setShowTimeModal] = useState<boolean>(false)
   const [showEstimateModal, setShowEstimateModal] = useState<boolean>(false)
   const [activeTab, setActiveTab] = useState<string>("1")
   const [estimateDuration, setEstimateDuration] = useState<string | null>(null)
   const [priority, setPriority] = useState<string | null>(null)
   const [techniciansData, setTechniciansData] = useState<string[]>([])
   const [isJobCompleted, setIsJobCompleted] = useState<boolean>(job.status === "c")
   const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false)
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))
   const isLg = useBreakpoint(down("lg"))
   let query = useQuery()
   const [showControls, setShowControls] = useState(true)
   const [isBooked, setIsBooked] = useState(job.progress !== EJobProgress.Schedule)
   const [proceedModalVisible, setProceedModalVisible] = useState<boolean>(false)
   const [isFinalized, setIsFinalized] = useState(false)
   const profile = useAppSelector(getCurrentUser)
   const menuClick = useSelector(getMenuItem)
   const location = useLocation()
   const history = useHistory()
   const dispatch = useDispatch()
   const Context = useContext(WOContext)
   const componentPrintRef = createRef<HTMLDivElement>()
   const componentPdfRef = createRef<HTMLDivElement>()
   const [printBlocks, setPrintBlocks] = useState<PrintBlocks>({
      woPricing: true,
      woPhotos: false,
      vesselPhoto: true,
      checkAll: false,
   })
   const [showPrintModal, setPrintModal] = useState(false)
   const [scheduleModal, setScheduleModal] = useState(false)
   const [isServicePlanScheduleSaved, setIsServicePlanScheduleSaved] = useState(false)
   const [completeModal, setCompleteModal] = useState(false)
   const [jobProgressModal, setJobProgressModal] = useState(false)
   const [bookWarningModal, setBookWarningModal] = useState(false)
   const [isFormFieldChanged, setIsFormFieldChanged] = useState(false)

   useEffect(() => {
      const type = location?.state?.type
      if (!type || !type?.includes("tab_")) return
      const _tab = type[type.length - 1]
      setActiveTab(_tab)
      history.replace()
   }, [location])

   const handleScroll = () => {
      if (getCoords(document.getElementById(String(job.id))) + 150 <= window.scrollY + window.innerHeight) {
         setShowControls(true)
      } else if (getCoords(document.getElementById(String(job.id))) + 150 > window.scrollY + window.innerHeight) {
         setShowControls(false)
      }
   }

   useEffect(() => {
      if (isLg && !isXs) {
         const block = document.getElementById("main_block")
         block?.addEventListener("scroll", handleScroll)
         return () => block?.removeEventListener("scroll", handleScroll)
      } else {
         window.addEventListener("scroll", handleScroll)
         return () => window.removeEventListener("scroll", handleScroll)
      }
   }, [isXs, isLg])

   useEffect(() => {
      if (activeTabSecond === "2" && estimate?.is_approved === false) {
         setActiveTab("2")
         setIsBooked(false)
      }
   }, [activeTabSecond])

   useLayoutEffect(() => {
      const jobId = query.get("job")
      const tab = query.get("tab")
      if (jobId && +jobId === job.id) {
         const jobBlock = document.getElementById(jobId)
         if (jobBlock) {
            jobBlock.scrollIntoView({ behavior: "smooth" })
         }
      }
      if (tab !== null && jobId !== null && +jobId === job.id) {
         setActiveTab(tab)
      } else {
         if (checkRecurringIsNotApproved()) {
            setActiveTab("2")
            return
         }
         switch (job.progress) {
            case EJobProgress.Estimate:
               setActiveTab("1")
               break
            case EJobProgress.Schedule:
               setActiveTab("2")
               break
            case EJobProgress.Start:
               setActiveTab("3")
               break
            case EJobProgress.Work:
               setActiveTab("4")
               break
            case EJobProgress.Complete:
               setActiveTab("3")
               break
            default:
               setActiveTab("1")
               break
         }
      }
   }, [])

   useLayoutEffect(() => {
      if (isCurrentSPJob && job.id) {
         const jobBlock = document.getElementById(String(job.id))
         const isMessage = query.get("message")
         const action = query.get("action")
         if (jobBlock) {
            setShowMore(true)
            jobBlock.scrollIntoView({ behavior: "smooth" })
            if (isMessage === "true" || action === "message") {
               setActiveTab("5")
            } else if (action === "schedule") {
               setActiveTab("2")
            }
         }
      }
   }, [isCurrentSPJob])

   useLayoutEffect(() => {
      if (lastWOJob && job.id) {
         const jobBlock = document.getElementById(String(job.id))
         const action = query.get("action")
         if (jobBlock) {
            jobBlock.scrollIntoView({ behavior: "smooth" })
            if (action === "message") {
               setActiveTab("5")
            } else if (action === "schedule") {
               setActiveTab("2")
            }
         }
      }
   }, [lastWOJob])

   useEffect(() => {
      if (isFormFieldChanged) {
         Context.setJobsData(job.id, activeTab)
      }
   }, [isFormFieldChanged])

   const calculateDuration = (d: string | null): moment.Moment | null => {
      if (!d) {
         return null
      }
      // TODO: Calc days?
      const parts = d.split(" ")
      const time = parts[parts.length - 1].split(":")
      return moment().hour(Number(time[0])).minute(Number(time[1])).second(0)
   }

   const checkRecurringIsNotApproved = () => workOrder?.is_recurring && !estimate?.schedules[0]?.approved_at

   const updateForm = async () => {
      await form.resetFields()
      const estimated_duration = calculateDuration(job.estimated_duration)
      const schedules = job.schedules.map((s) => ({
         ...s,
         start: moment(s.start),
         end: moment(s.end),
      }))
      const technicians_data = job.technicians_data.map((t) => {
         let estimated_hours = calculateDuration(t.estimated_hours)
         return {
            ...t,
            technician: String(t.technician),
            estimated_hours,
         }
      })
      await form.setFieldsValue({
         ...job,
         ...(technicianRole && { priority: formatPriority(job.priority) }),
         estimated_duration,
         schedules,
         technicians_data,
      })
      setEstimate(job)
      if (workOrder && workOrder.is_recurring) {
         const { services, materials } = workOrder
         const estimated_duration = calculateDuration(workOrder.estimated_duration)
         await form.setFieldsValue({ services, materials, estimated_duration })
      }
   }

   useEffect(() => {
      updateForm()
   }, [job, form, showMore])

   const getActiveStep = (): number => {
      if (job?.id) {
         if (workOrder?.is_declined) {
            return 1
         }
         if (checkRecurringIsNotApproved() && estimate?.progress === JobProgress.Start) {
            return 2
         }
         if (estimate?.progress === JobProgress.Finished) {
            return 6
         } else if (estimate?.progress === JobProgress.Complete) {
            return 5
         } else if (estimate?.progress === JobProgress.Work) {
            return 4
         } else if (estimate?.progress === JobProgress.Start) {
            return 3
         } else if (estimate?.progress === JobProgress.Schedule) {
            return 2
         }
         return 1
      }
      return 1
   }

   const updateComponent = async () => {
      const { data } = await Api.jobs.get(estimate?.id || 0)
      setEstimate(data)
   }

   const startJob = async () => {
      try {
         const { data } = await Api.jobs.start(job.id)
         track("Job Started", {
            workorderId: data.work_order,
            customerId: data.customer_data.id,
            vesselId: data?.vessel_data?.id,
            jobName: data.title,
            jobId: data.id,
            jobStartedBy: profile?.id,
            jobStartedTimestamp: moment().toISOString(),
         })
         if (workOrder?.is_recurring) {
            await updateInfo()
            setActiveTab("4")
            return
         }
         await updateComponent()
         setActiveTab("4")
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const completeJob = async (formData: any) => {
      try {
         let dataSend = null
         if (formData) {
            dataSend = await renderFormDataSend({ ...formData, jobId: job.id }, generatePdfDocument)
         } else {
            dataSend = await renderFormDataSend({ jobId: job.id })
         }

         const { data } = await Api.jobs.finish(dataSend)
         track("Work Order Completed", {
            workorderId: data.work_order,
            customerId: data.customer_data.id,
            vesselId: data?.vessel_data?.id,
            workorderCompletedBy: profile?.id,
            workorderCompletedTimestamp: moment().toISOString(),
            workorderTotalUSD: data.total,
         })
         await updateComponent()
         setIsJobCompleted(true)
         notification.success({
            message: "Congratulations, this job has been completed...",
         })
         await updateComponent()
         updateInfo()
         setCompleteModal(false)
      } catch (e) {
         const errors = getErrors(e)
         const nonFieldError = errors[0].errors[0]
         if (nonFieldError) {
            notification.error({ message: nonFieldError })
            return
         }
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const generatePdfDocument = async () => {
      const string = componentPdfRef.current
      const doc = new jsPDF("p", "px", [595, 842])
      let blob: any = ""

      if (string) {
         await doc.html(string, {
            callback: async (pdf) => {
               let totalPages = pdf.internal.pages.length
               renderHeader(doc, string)
               for (let i = totalPages; i >= 0; i--) {
                  renderHeader(doc, string)
                  pdf.setPage(i)
               }
               blob = await pdf.output("blob")

               //pdf.output("dataurlnewwindow")
               return blob
            },
            margin: [115, 20, 25, 20],
            autoPaging: "text",
         })
      }

      return blob
   }

   const renderHeader = async (doc: any, string: any) => {
      const img = string.querySelector(".pdfLogo") as HTMLElement
      const headerLogo = profile?.company?.logo ? profile.company.logo.replace(/^http:\/\//i, "https://") : logo
      const width = profile?.company?.logo ? img.clientWidth : 60
      const height = profile?.company?.logo ? img.clientHeight : 60

      const addressCompany = profile?.company?.address ? renderAddress(profile?.company?.address) : "-"
      const addressCustomer = workOrder?.customer?.address ? renderAddress(workOrder?.customer?.address) : "-"

      doc.addImage(headerLogo, "JPEG", 20, 20, width, height)

      doc.setFont("helvetica", "normal")
      doc.setTextColor("#202020")
      doc.text("COMPANY", 200, 35)
      doc.setFont("helvetica", "bold")
      doc.text(profile?.company?.name, 200, 55, {
         maxWidth: 170,
      })
      doc.setFont("helvetica", "normal")
      doc.text(addressCompany, 200, 70, {
         maxWidth: 170,
         charSpace: 0,
      })
      doc.text(profile?.company?.phone, 200, addressCompany.length > 35 ? 95 : 80)
      doc.setTextColor("#40a9ff")
      doc.text(profile?.company?.email, 200, addressCompany.length > 35 ? 110 : 95, {
         maxWidth: 170,
      })

      doc.setTextColor("#202020")
      doc.text("BILLING CONTACT", 400, 35)
      doc.setFont("helvetica", "bold")
      doc.text(workOrder?.customer?.full_name, 400, 55, {
         maxWidth: 170,
      })
      doc.setFont("helvetica", "normal")
      doc.text(addressCustomer, 400, 70, {
         maxWidth: 170,
         charSpace: 0,
      })
      doc.text(
         workOrder?.customer?.phones.find((p: { phone_type: string }) => p.phone_type === "P")?.phone || "No data",
         400,
         addressCustomer.length > 35 ? 95 : 80
      )
      doc.setTextColor("#40a9ff")
      doc.text(workOrder?.customer?.email, 400, addressCustomer.length > 35 ? 110 : 95)
   }

   const bookAppointment = async () => {
      const formData = await form.validateFields()

      const technicians_data = formData.technicians_data
      const schedules = formData.schedules

      // if (!technicians_data?.length) {
      //    notification.error({ message: "Please select technician!" })
      //    return
      // }

      // if (!schedules?.length) {
      //    notification.error({ message: "Please select schedule!" })
      //    return
      // }

      try {
         const { data } = await onSave(
            Api.jobs.patch({
               id: job.id,
               schedules,
               technicians_data: technicians_data.map((td: IJobTechnician) => ({
                  ...td,
                  estimated_hours: td.estimated_hours ? formatDuration(moment(td.estimated_hours)) : null,
               })),
            })
         )
         track("Job Scheduled", {
            workorderId: data.workOrder,
            customerId: data.customer_data.id,
            vesselId: data?.vessel_data?.id,
            jobCreatedBy: data.created_by,
            jobName: data.title,
            jobId: data.id,
            priority: formatPriority(data.priority),
            estimatedDurationMins: data.estimated_duration,
            jobScheduledBy: profile?.id,
            ...(data?.schedules?.length &&
               data?.technicians_data?.length && {
                  jobScheduledTimestamp: data.schedules[data.schedules.length - 1].start,
                  jobAssignedTo: data.technicians_data.map((t: IJobTechData) => t.id).join(", "),
                  jobScheduledTime: data.schedules[data.schedules.length - 1].end,
               }),
         })
         setSaved(true)
         setIsBooked(true)
         setActiveTab("3")
         await updateComponent()
         await updateInfo()
         notification.success({
            message: "The job is ready to start following an inspection",
         })
      } catch (err: any) {
         if (err?.response?.data?.non_field_error?.length) {
            err.response.data.non_field_error.forEach((e: string) => {
               notification.error({ message: e })
            })
         } else {
            notification.error({ message: "Something went wrong" })
         }
      }
   }

   const handleBookWarningOk = () => {
      bookAppointment()
      setBookWarningModal(false)
   }

   const handleBookWarningCancel = () => {
      setBookWarningModal(false)
   }

   const handleBookAppointmentClick = () => {
      if (!job.is_approved) {
         setBookWarningModal(true)
         return
      }
      bookAppointment()
   }

   const updateSPJobSchedule = async () => {
      const formData = await form.validateFields()

      const technicians_data = formData.technicians_data
      const schedules = formData.schedules
      try {
         const { data } = await onSave(
            Api.jobs.patch({
               id: job.id,
               schedules,
               technicians_data: technicians_data.map((td: IJobTechnician) => ({
                  ...td,
                  estimated_hours: td.estimated_hours ? formatDuration(moment(td.estimated_hours)) : null,
               })),
            })
         )
         notification.success({ message: "Schedule updated!" })
         setIsServicePlanScheduleSaved(true)
      } catch (err: any) {
         if (err?.response?.data?.non_field_error?.length) {
            err.response.data.non_field_error.forEach((e: string) => {
               notification.error({ message: e })
            })
         } else {
            notification.error({ message: "Something went wrong" })
         }
      }
   }

   const proceedWithoutApproval = async () => {
      try {
         await Api.jobs.proceed(job.id)
         notification.success({ message: "The estimate has been approved" })
         setActiveTab("2")
         await updateComponent()
         setIsBooked(false)
         updateInfo()
      } catch (e) {
         notification.error({
            message: "Job is already approved",
         })
      }
   }

   const handleFinalize = () => {
      if (estimate?.is_approved === false) {
         notification.success({ message: "Job estimate finalized..." })
      }
      setIsFinalized(true)

      setShowEstimateModal(true)
   }

   const requestScheduleConfirmation = () => {
      setScheduleModal(true)
   }

   const proceedWithoutScheduleApproval = async () => {
      //TODO
      if (job?.schedules[0]?.uuid) {
         await Api.workOrders.approveSchedule(job?.schedules[0]?.uuid)
         await updateComponent()
         setActiveTab("3")
      }
   }

   const isScheduleApproved = () => {
      return !!job?.schedules?.[0]?.approved_at
   }

   const getActionButtons = () => {
      switch (activeTab) {
         case "2":
            if (checkRecurringIsNotApproved()) {
               //TODO
               return (
                  <>
                     <Button
                        type="primary"
                        loading={saving}
                        onClick={updateSPJobSchedule}
                        disabled={isScheduleApproved()}
                     >
                        Save Schedule
                     </Button>
                     <Dropdown.Button
                        type="primary"
                        overlay={
                           <StyledMenu getPopupContainer={(trigger: any) => trigger.parentElement}>
                              <Menu.Item onClick={requestScheduleConfirmation}>Request Confirmation</Menu.Item>
                              <Menu.Item onClick={proceedWithoutScheduleApproval}>Proceed without approval</Menu.Item>
                           </StyledMenu>
                        }
                        disabled={!isServicePlanScheduleSaved}
                        onClick={requestScheduleConfirmation}
                     >
                        Request Confirmation
                     </Dropdown.Button>
                  </>
               )
            }
            return null
         // <Button type="primary" loading={saving} onClick={bookAppointment} disabled={isBooked}>
         //    {!isBooked ? "Book Appointment" : "Booked"}
         // </Button>
         case "3":
            return null
         //    <Button
         //       type="primary"
         //       disabled={estimate?.progress !== JobProgress.Start}
         //       loading={saving}
         //       onClick={startJob}
         //    >
         //       {estimate?.progress === JobProgress.Work || estimate?.progress === JobProgress.Complete
         //          ? "Started"
         //          : "Start job"}
         //    </Button>
         case "4":
            return (
               <Button
                  type="primary"
                  disabled={estimate?.progress === JobProgress.Finished}
                  loading={saving}
                  onClick={handleShowModalComplete}
               >
                  {estimate?.progress !== JobProgress.Finished ? "Complete job" : "Completed"}
               </Button>
            )
         case "5":
            // Messages
            return null

         default:
            return null
      }
   }

   const formatDuration = (data: moment.Moment) =>
      `${data.hours() >= 10 ? data.hours() : `0${data.hours()}`}:${
         data.minutes() >= 10 ? data.minutes() : `0${data.minutes()}`
      }:00`

   const updateSidebarData = (data: IUpdateJob) => {
      if (data.estimated_duration) {
         setEstimateDuration(formatDuration(moment(data.estimated_duration)))
      }
      if (data.priority) {
         setPriority(data.priority)
      }
   }

   const handleSave = async () => {
      let data = await form.validateFields()

      if (!data.services?.length && data?.temp_service) {
         const { temp_service, ..._jobData } = data
         const _services = !!temp_service.name ? [{ ...temp_service }] : []
         data = { ..._jobData, services: _services }
      }

      if (!data.materials?.length && data?.temp_material) {
         const { temp_material, ..._jobData } = data
         const _materials = !!temp_material.name ? [{ ...temp_material }] : []
         data = { ..._jobData, materials: _materials }
      }

      const technicians_data = data.technicians_data

      await onSave(
         Api.jobs.update({
            ...data,
            id: job.id,
            estimated_duration: data.estimated_duration ? formatDuration(moment(data.estimated_duration)) : null,
            ...(!job.is_approved && { schedules: [], technicians_data: [] }),
            ...(technicians_data &&
               technicians_data.length > 0 && {
                  technicians_data: technicians_data.map((td: IJobTechnician) => ({
                     ...td,
                     estimated_hours: td.estimated_hours ? formatDuration(moment(td.estimated_hours)) : null,
                  })),
               }),
         })
      )
      setSaved(true)
      updateSidebarData(data)
      await updateComponent()
      updateInfo()
   }

   const assignTechnician = (value: IUser) => {
      setTechnician(value)
      setShowTechModule(false)
      notification.success({
         message: "Technician assigned",
      })
   }

   const scrollToAddSchedule = (isSchedule: boolean) => {
      setActiveTab("2")
      const techOrSchedule = isSchedule ? "sched" : "tech"
      const addButton = document.getElementById(`${job.id}_${techOrSchedule}_add`)
      const tabs = document.getElementById(`${job.id}_tabs`)
      if (tabs !== null) {
         tabs.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
         })
      }
      if (addButton !== null) {
         addButton.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
         })
         addButton.click()
      }
   }

   const updateJobTitle = async (e: any) => {
      const newTitle = e.target.value
      if (newTitle !== job.title) {
         await Api.jobs.patch({ id: job.id, title: newTitle })
         updateInfo()
      }
      setIsTitleEditing(false)
   }

   const handlePrint = useReactToPrint({
      content: () => componentPrintRef.current,
      onAfterPrint: () => {
         setPrintBlocks({
            woPricing: true,
            woPhotos: false,
            vesselPhoto: true,
            checkAll: false,
         })
      },
   })

   const handleUnlockBookButton = (job: IJob) => {
      setIsBooked(false)
   }

   const renderRecurringJobStatus = () => {
      const progress = estimate?.progress
      const start = estimate?.schedules?.[0]?.start
      const completed = estimate?.completed_at
      if (progress && start) {
         const start_date = moment(start)
         const today_date = moment()
         if (progress === EJobProgress.Complete) {
            if (completed) {
               return (
                  <Msg fontWeight={700} fontSize={isLg ? "15px" : "22px"} color="#42C77B">
                     Completed at {moment(completed).format("MM/DD/yy")}
                  </Msg>
               )
            }
         } else if (progress === EJobProgress.Work || progress === EJobProgress.UnFinished) {
            return (
               <Msg fontWeight={700} fontSize={isLg ? "15px" : "22px"} color="#0496FF">
                  In progress
               </Msg>
            )
         } else if (start_date.isSameOrBefore(today_date, "days") && progress === EJobProgress.Start) {
            return (
               <Msg fontWeight={700} fontSize={isLg ? "15px" : "22px"} color="#FB4D4F">
                  Need to complete
               </Msg>
            )
         } else if (start_date.isAfter(today_date, "days")) {
            return (
               <Msg fontWeight={700} fontSize={isLg ? "15px" : "22px"} color="#257ABF">
                  Upcoming at {start_date.format("MM/DD/yy")}
               </Msg>
            )
         }
      }
      return null
   }

   const renderDuration = () => {
      if (workOrder && workOrder.is_recurring) {
         return workOrder.estimated_duration
      } else {
         return estimateDuration
      }
   }

   useEffect(() => {
      if (Context.isCompleteRequested && isCurrentSPJob) {
         handleShowModalComplete()
         Context.requestComplete(false)
      }
   }, [Context.isCompleteRequested, isCurrentSPJob])

   useEffect(() => {
      if (Context.jobsUpdate) {
         const findJobNotValid = Context.jobs.find((item) => item.update === false)

         const findJob = Context.jobs.find((item) => item.id === job.id)
         if (findJobNotValid) {
            if (findJob && findJob.update === false) {
               checkIsValidForm(Context.jobs)
            }
         } else {
            updateJobOnSave(findJob)
         }
      }
   }, [Context.jobs, Context.jobsUpdate])

   useEffect(() => {
      if (Context.jobsReset) {
         const findJob = Context.jobs.find((item) => item.id === job.id)

         if (findJob) {
            resetJobForm(findJob)
         }
      }
   }, [Context.jobsReset, Context.jobs])

   const handleAction = (actionType: any) => {
      if (actionType.type === "openReccuringModal") {
         openRecurringModal()
         Context.setJActionType({})
      }
      if (actionType.type === "addNewJob") {
         onAddJob()
         Context.setJActionType({})
      }
      if (actionType.type === "tab") {
         if (job.id === actionType.job.id) {
            setActiveTab(actionType.job.tab)
            Context.setJActionType({})
         }
      }
      if (actionType.type === "save_draft") {
         notification.success({ message: "The job is saved" })
      }
      if (actionType.type === "vessel_details") {
         history.push({
            pathname: `/vessels/vessel/${actionType.vesselId}`,
            state: { fromWO: true },
         })
      }
      if (actionType.type === "customer_history") {
         history.push({
            pathname: `/customers/customer/${actionType.customerId}`,
            state: { fromWO: true },
         })
      }
      if (actionType.type === "route") {
         if (menuClick.menuRoute === HELP_PAGE_URL) {
            window.location.assign(menuClick.menuRoute)
         } else {
            history.push(menuClick.menuRoute)
         }
         dispatch(setMenuItem({ showModal: false }))
         Context.setJActionType({})
      }
   }

   const updateJobOnSave = async (job: any) => {
      if (job) {
         try {
            if (job.tab === "1") {
               await handleSave()
            } else if (job.tab === "2") {
               await bookAppointment()
            }
            showSaveModal(false)
            setIsFormFieldChanged(false)
            Context.clearUpdateJobs()
            Context.updateJobs(false)

            if (menuClick.menuRoute !== "work-order") {
               handleAction({ type: "route" })
            } else {
               handleAction(Context.actionType)
            }
            dispatch(setMenuItem({ showModal: false }))
         } catch (e) {
            dispatch(setMenuItem({ showModal: false }))
            console.log("e", e)
         }
      }
   }
   // console.log("jobs", Context.jobs)
   // console.log("menu", menuClick)
   const resetJobForm = async (job: any) => {
      if (job) {
         try {
            await updateForm()
            showSaveModal(false)
            setIsFormFieldChanged(false)
            Context.clearUpdateJobs()
            Context.resetJobs(false)

            if (menuClick.menuRoute !== "work-order") {
               handleAction({ type: "route" })
            } else {
               handleAction(Context.actionType)
            }
            dispatch(setMenuItem({ showModal: false }))
         } catch (e) {
            dispatch(setMenuItem({ showModal: false }))
            console.log("e", e)
         }
      }
   }

   const checkIsValidForm = async (jobs: any[]) => {
      const findJob = jobs.find((item) => item.id === job.id)
      if (findJob) {
         try {
            await form.validateFields()
            const dataUpdate = [
               ...jobs.filter((item) => item.id !== findJob.id),
               {
                  ...findJob,
                  update: true,
               },
            ]
            // console.log("dataUpdate", dataUpdate)
            Context.setJobs(dataUpdate)
            showSaveModal(false)
         } catch (e) {
            showSaveModal(false)
            const jobBlock = document.getElementById(String(job.id))

            if (jobBlock) {
               jobBlock.scrollIntoView({ behavior: "smooth" })
            }

            Context.updateJobs(false)
         }
      }
   }

   useEffect(() => {
      const url = location.pathname.split("/")
      const urlCurrent = url[1]

      if (urlCurrent === "work-order") {
         dispatch(setMenuItem({ menuRoute: urlCurrent }))
      }
   }, [])

   useEffect(() => {
      if (Context.jobs.length > 0) {
         dispatch(setMenuItem({ showModal: true }))
      } else {
         dispatch(setMenuItem({ showModal: false }))
      }
   }, [Context.jobs])

   useEffect(() => {
      if (menuClick.actionClick) {
         showSaveModal(true)
         dispatch(setMenuItem({ actionClick: false }))
      }
   }, [menuClick.actionClick])

   useEffect(() => {
      if (Context.actionType.type === "save_draft" && Context.jobs.length > 0) {
         checkIsValidForm(Context.jobs)
         Context.updateJobs(true)
      }
   }, [Context.actionType.type, Context.jobs])

   useEffect(() => {
      if (menuClick.headerHover && menuClick.showModal) {
         showSaveModal(true)
         dispatch(setMenuItem({ headerHover: false }))
      }
   }, [menuClick.headerHover, menuClick.showModal])

   const handleShowModalComplete = async () => {
      const res = await Api.timeClocks.getAll({ job: job?.id })
      const timeClocks = res.data as ITimeClock[]
      const newActive = timeClocks.find((clock) => clock.stop === null)
      if (newActive) {
         setJobProgressModal(true)
      } else {
         setCompleteModal(true)
      }
   }

   const showCompleteModal = async () => {
      let promises: any[] = []
      const res = await Api.timeClocks.getAll({ job: job?.id })
      const timeClocks = res.data as ITimeClock[]
      const activeTimeClocks = timeClocks.filter((t) => t.stop === null)
      if (activeTimeClocks && activeTimeClocks.length > 0) {
         promises = activeTimeClocks.map(async (item: any) => {
            return await Api.timeClocks.stop(item?.id ?? item[0].id)
         })
      }
      await Promise.all(promises)
      //await updateComponent()
      updateInfo()
      setCompleteModal(true)
      setJobProgressModal(false)
   }

   const handleOpenReccuring = () => {
      if (Context.jobs.length > 0) {
         showSaveModal(true)
         Context.setJActionType({
            type: "openReccuringModal",
         })
      } else {
         openRecurringModal()
      }
   }
   const handleAddAnotherJob = () => {
      if (Context.jobs.length > 0) {
         showSaveModal(true)
         Context.setJActionType({
            type: "addNewJob",
         })
      } else {
         onAddJob()
      }
   }
   const handleSetActiveTab = (activeKey: string) => {
      if (Context.jobs.length > 0) {
         showSaveModal(true)
         Context.setJActionType({
            type: "tab",
            job: {
               id: job.id,
               tab: activeKey,
            },
         })
      } else {
         setActiveTab(activeKey)
      }
   }

   return (
      <>
         <Form
            form={form}
            layout="vertical"
            id={`${job.id}`}
            onFieldsChange={(values) => {
               setSaved(false)
               setTechniciansData(form.getFieldsValue()?.technicians_data?.map((tech: any) => tech?.technician))
            }}
            requiredMark={false}
         >
            <OrderInfoWrapper
               isRecurring={!!workOrder?.is_recurring}
               mt={workOrder?.is_recurring && !isLg ? "0px" : "24px"}
            >
               <OrderInfoHeader>
                  <Flex justifyContent="space-between" alignItems="center">
                     <SendEstimateModal
                        isInvoice={job.status === "c" && activeTab === "4"}
                        isEstimate={!job.is_approved}
                        visible={showEstimateModal}
                        onSuccess={job.progress === EJobProgress.Declined ? updateInfo : undefined}
                        onClose={() => setShowEstimateModal(false)}
                        workOrderNumber={job.work_order}
                        workOrder={workOrder}
                        job={job}
                     />
                     <SendScheduleModal
                        visible={scheduleModal}
                        onClose={() => setScheduleModal(false)}
                        workOrderNumber={job.work_order}
                        schedule={job?.schedules[0]?.id}
                        workOrder={workOrder}
                        job={job}
                     />
                     {completeModal && (
                        <CompleteModal
                           visible={completeModal}
                           onClose={() => setCompleteModal(false)}
                           onSubmit={completeJob}
                           job={job}
                           workOrder={workOrder}
                        />
                     )}
                     {jobProgressModal && (
                        <JobProgressModal
                           visible={jobProgressModal}
                           onClose={() => setJobProgressModal(false)}
                           onSubmit={showCompleteModal}
                           job={job}
                           workOrder={workOrder}
                        />
                     )}

                     {job ? (
                        !isLg ? (
                           <ResponsiveFlex
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ flexGrow: 1 }}
                              mr="36px"
                              width="90%"
                           >
                              <Flex alignItems="center" width="100%">
                                 {isTitleEditing ? (
                                    <Input
                                       style={{ fontSize: 20 }}
                                       defaultValue={job.title}
                                       onPressEnter={updateJobTitle}
                                       onBlur={updateJobTitle}
                                    />
                                 ) : (
                                    <Tooltip title={job.title}>
                                       <Flex maxWidth={technicianRole ? "100%" : "90%"} style={{ overflow: "hidden" }}>
                                          <Msg fontWeight={900} fontSize={24}>
                                             {job.title}
                                          </Msg>
                                       </Flex>
                                    </Tooltip>
                                 )}
                                 {!technicianRole && (
                                    <Button
                                       type="text"
                                       onClick={() => {
                                          setIsTitleEditing((prev) => !prev)
                                       }}
                                       style={{ margin: "0 0 0 10px" }}
                                    >
                                       <EditIcon verticalAlign="middle" />
                                    </Button>
                                 )}
                              </Flex>
                              {isLg && (!estimate || estimate?.progress !== EJobProgress.Estimate) && (
                                 <Flex alignItems="center">
                                    <TechnicianModal
                                       onSelect={assignTechnician}
                                       visible={showTechModule}
                                       onClose={() => setShowTechModule(false)}
                                    />
                                    <ScheduleModal
                                       onClose={() => setShowTimeModal(false)}
                                       visible={timeModalVisible}
                                       payload={job}
                                    />
                                    <AssignButton type="text" onClick={() => scrollToAddSchedule(true)}>
                                       <img src={Schedule} alt="" />
                                       {schedule ? moment(schedule).format("L [at] LT") : "+ Schedule work"}
                                    </AssignButton>
                                    <AssignButton type="text" onClick={() => scrollToAddSchedule(false)}>
                                       <img src={Technician} alt="" />
                                       {technician ? technician?.full_name : "+ Assign worker"}
                                    </AssignButton>
                                 </Flex>
                              )}
                              <Flex flexDirection="row" alignItems="center" width="100%" justifyContent="flex-end">
                                 {estimate && workOrder?.is_recurring && (
                                    <Flex marginRight="16px" width="300px" textAlign="right">
                                       {renderRecurringJobStatus()}
                                    </Flex>
                                 )}
                                 {!technicianRole && estimate && (
                                    <>
                                       {!workOrder?.is_recurring && (
                                          <CreateServicePlanBtn onClick={handleOpenReccuring}>
                                             <Flex alignItems="center">
                                                <CreateServicePlanIcon /> Create a service plan
                                             </Flex>
                                          </CreateServicePlanBtn>
                                       )}

                                       <Tooltip title="Print job">
                                          <OptionButton
                                             style={{
                                                width: "35px",
                                                height: "35px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                             }}
                                             onClick={() => setPrintModal(true)}
                                          >
                                             <img src={PrintIcon} alt="Print job" />
                                          </OptionButton>
                                       </Tooltip>
                                       {!workOrder?.is_recurring && (
                                          <Tooltip title="Delete Job">
                                             <Popconfirm
                                                title="Are you sure want to delete job?"
                                                onConfirm={async () => {
                                                   await Api.jobs.delete(estimate?.id)
                                                   await updateInfo()
                                                }}
                                             >
                                                <OptionButton
                                                   style={{
                                                      width: "35px",
                                                      height: "35px",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                   }}
                                                >
                                                   <img src={DeleteIcon} alt="Delete job" />
                                                </OptionButton>
                                             </Popconfirm>
                                          </Tooltip>
                                       )}
                                    </>
                                 )}

                                 <Flex flexDirection={!isLg ? "column" : "row"} alignItems={!isLg ? "start" : "center"}>
                                    {!technicianRole && (
                                       <Msg color="#0F497B" fontSize={24} fontWeight={800}>
                                          {formatToUSD(jobRecurringPrice ?? estimate?.total)}
                                       </Msg>
                                    )}

                                    <Msg
                                       color="#828282"
                                       marginLeft={!isLg ? "0px" : "10px"}
                                    >{`Job ${estimate?.order_number}`}</Msg>
                                 </Flex>
                              </Flex>
                           </ResponsiveFlex>
                        ) : technicianRole ? (
                           <Flex flexDirection="column">
                              <StyledInput
                                 defaultValue={job.title}
                                 onBlur={updateJobTitle}
                                 disabled={technicianRole ? true : false}
                              />
                              <Msg
                                 color="#828282"
                                 marginLeft={!isLg ? "0px" : "10px"}
                              >{`Job ${estimate?.order_number}`}</Msg>
                           </Flex>
                        ) : (
                           <Flex alignItems="center" width="80%">
                              {isTitleEditing ? (
                                 <Input
                                    style={{ fontSize: 20 }}
                                    defaultValue={job.title}
                                    onPressEnter={updateJobTitle}
                                    onBlur={updateJobTitle}
                                 />
                              ) : (
                                 <Tooltip title={job.title}>
                                    <Flex maxWidth={technicianRole ? "100%" : "90%"} style={{ overflow: "hidden" }}>
                                       <Msg fontWeight={900} fontSize={20}>
                                          {job.title}
                                       </Msg>
                                    </Flex>
                                 </Tooltip>
                              )}
                              {!technicianRole && (
                                 <Button
                                    type="text"
                                    onClick={() => {
                                       setIsTitleEditing((prev) => !prev)
                                    }}
                                    style={{ margin: "0 0 0 10px" }}
                                 >
                                    <EditIcon verticalAlign="middle" />
                                 </Button>
                              )}
                           </Flex>
                        )
                     ) : (
                        <Form.Item
                           name="title"
                           style={{
                              marginBottom: 0,
                              width: isLg ? "100%" : "calc(66% + 8px)",
                           }}
                        >
                           <Input style={{ fontWeight: 900, fontSize: "24px" }} placeholder="Enter job name" />
                        </Form.Item>
                     )}
                     <Button type="text" onClick={() => setShowMore(!showMore)}>
                        {!showMore ? <DownOutlined /> : <UpOutlined />}
                     </Button>
                  </Flex>
                  {isLg && estimate && !technicianRole && (
                     <>
                        <Flex justifyContent="space-between" alignItems="center">
                           {!workOrder?.is_recurring ? (
                              <CreateServicePlanBtn onClick={openRecurringModal}>
                                 <Flex alignItems="center">
                                    <CreateServicePlanIcon /> Create a service plan
                                 </Flex>
                              </CreateServicePlanBtn>
                           ) : (
                              <Flex></Flex>
                           )}
                           <Msg color="#0F497B" fontSize={20} fontWeight={800} margin="0 8px 0 auto">
                              {formatToUSD(jobRecurringPrice ?? estimate.total)}
                           </Msg>
                        </Flex>
                        <Flex
                           flexDirection="row"
                           alignItems="center"
                           justifyContent="space-between"
                           marginTop={isXs ? "4px" : "16px"}
                        >
                           <Flex>
                              <Tooltip title="Print job">
                                 <OptionButton
                                    style={{
                                       width: "35px",
                                       height: "35px",
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                    }}
                                    onClick={() => setPrintModal(true)}
                                 >
                                    <img src={PrintIcon} alt="Print job" />
                                 </OptionButton>
                              </Tooltip>
                              {!workOrder?.is_recurring && (
                                 <Popconfirm
                                    title="Are you sure want to delete job?"
                                    onConfirm={async () => {
                                       await Api.jobs.delete(estimate.id)
                                       await updateInfo()
                                    }}
                                 >
                                    <OptionButton
                                       style={{
                                          width: "35px",
                                          height: "35px",
                                          marginRight: "8px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                       }}
                                    >
                                       <img src={DeleteIcon} alt="Delete job" />
                                    </OptionButton>
                                 </Popconfirm>
                              )}
                           </Flex>
                           <Msg color="#8B8B8B" fontSize={15}>
                              Job {estimate.order_number}
                           </Msg>
                        </Flex>
                        {estimate && workOrder?.is_recurring && (
                           <RecurringStatusBlock>{renderRecurringJobStatus()}</RecurringStatusBlock>
                        )}
                     </>
                  )}
               </OrderInfoHeader>
               {showMore && (
                  <Flex flexDirection="column">
                     <InfoContainer>
                        <TabsWrapper>
                           <ProgressSteps activeStep={getActiveStep()} isError={workOrder?.is_declined} />
                           <ApprovedAlert
                              activeStep={getActiveStep()}
                              isApproved={estimate?.is_approved}
                              isFinalized={isFinalized}
                           />
                           <Tabs
                              id={`${job.id}_tabs`}
                              onChange={(activeKey) => handleSetActiveTab(activeKey)}
                              activeKey={activeTab}
                           >
                              {tabs.map((t) => {
                                 const C = t.component

                                 return (
                                    <Tabs.TabPane key={t.key} tab={t.title}>
                                       <C
                                          form={form}
                                          job={job}
                                          updateInfo={updateInfo}
                                          technicianRole={technicianRole}
                                          setIsBooked={handleUnlockBookButton}
                                          technicians={techniciansData}
                                          workOrder={workOrder}
                                          updateJobById={updateJobById}
                                          setIsFormFieldChanged={setIsFormFieldChanged}
                                       />
                                    </Tabs.TabPane>
                                 )
                              })}
                           </Tabs>
                        </TabsWrapper>
                        <SideContent
                           form={form}
                           job={job}
                           priority={priority}
                           estimateDuration={renderDuration()}
                           technicianRole={technicianRole}
                           isRecurring={!!workOrder?.is_recurring}
                           workOrder={workOrder}
                           handleAddAnotherJob={handleAddAnotherJob}
                        />
                     </InfoContainer>
                     {isLg
                        ? !technicianRole && (
                             <Affix offsetBottom={0}>
                                {showControls && (
                                   <Flex
                                      alignItems="center"
                                      padding="10px 16px"
                                      mt={isXs ? "24px" : "36px"}
                                      mb={isXs ? "0px" : "34px"}
                                      justifyContent="space-between"
                                      style={{
                                         gap: "16px",
                                         boxShadow: "0px 0px 6px rgba(50, 50, 50, 0.2)",
                                      }}
                                      backgroundColor="#FFFFFF"
                                   >
                                      {!(
                                         activeTab === "1" &&
                                         workOrder?.is_recurring &&
                                         workOrder.recurring_status === ERecurringStatus.Approved
                                      ) && (
                                         <>
                                            {getActionButtons()}
                                            {/* {!(activeTab === "4" && isJobCompleted) &&
                                               activeTab !== "3" &&
                                               activeTab !== "2" && (
                                                  <SaveButton loading={saving} onClick={handleSave} disabled={saved}>
                                                     {saved ? "Saved" : "Save"}
                                                  </SaveButton>
                                               )} */}
                                         </>
                                      )}
                                   </Flex>
                                )}
                             </Affix>
                          )
                        : !technicianRole && (
                             <Flex
                                alignItems="center"
                                pl="30px"
                                pr="0px"
                                mt="36px"
                                mb="34px"
                                justifyContent="start"
                                style={{ gap: "16px" }}
                                backgroundColor="#FFFFFF"
                             >
                                {!(
                                   activeTab === "1" &&
                                   workOrder?.is_recurring &&
                                   workOrder.recurring_status === ERecurringStatus.Approved
                                ) && (
                                   <>
                                      {getActionButtons()}
                                      {/* {activeTab === "1" && (
                                         <SaveButton loading={saving} onClick={handleSave} disabled={saved}>
                                            {saved ? "Saved" : "Save"}
                                         </SaveButton>
                                      )} */}
                                   </>
                                )}
                             </Flex>
                          )}
                  </Flex>
               )}
               <Modal
                  visible={bookWarningModal}
                  title={null}
                  okText="Yes"
                  onOk={handleBookWarningOk}
                  onCancel={handleBookWarningCancel}
                  width={374}
               >
                  <Flex flexDirection="column" padding="16px 4px">
                     <Msg fontWeight={700}>This job has not been approved by the customer.</Msg>
                     <Msg fontWeight={700}>Proceed without approval?</Msg>
                  </Flex>
               </Modal>
            </OrderInfoWrapper>
         </Form>
         <ModalPrintCheckboxes
            handlePrint={handlePrint}
            setPrintModal={setPrintModal}
            showPrintModal={showPrintModal}
            checkboxes={printBlocks}
            setPrintBlocks={setPrintBlocks}
            technicianRole={technicianRole}
            job={true}
         />
         {showPrintModal && (
            <>
               <div style={{ display: "none" }}>
                  <PrintEstimate
                     ref={componentPrintRef}
                     workOrder={workOrder}
                     job={job}
                     technicianRole={technicianRole}
                     woPricing={printBlocks.woPricing}
                     woPhotos={printBlocks.woPhotos}
                     vesselPhoto={printBlocks.vesselPhoto}
                     isInvoice={isInvoice}
                  />
               </div>
               <HiddenPdfDoc>
                  <PdfJob
                     ref={componentPdfRef}
                     workOrder={workOrder}
                     job={job}
                     technicianRole={false}
                     isInvoice={false}
                  />
               </HiddenPdfDoc>
            </>
         )}
      </>
   )
}
