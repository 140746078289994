import React, { useState, useEffect } from "react"
import moment from "moment"
import { notification, Button, Spin } from "antd"
import { Api } from "../../../../api/api"
import { IUser, IMyJob, ITimeClock } from "../../../../api/types"
import { formatTimeTimeClockSum } from "../../../../utils/utils"
import Flex from "../../../../noui/Flex"
import { Msg } from "../../../../ui/Text"
import { TimeInput } from "../../../../ui/TimeInput"
import { AddTimeManuallyBoard } from "../../../../modals/AddTimeManuallyBoard"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import { track } from "../../../../utils/analytics"

export const TechnicianItem = styled(Flex)`
   flex-direction: column;
   align-items: center;
   width: 100%;
   height: 100%;
   max-width: 150px;
   margin-left: 15px;
   justify-content: center;
   button {
      &:disabled {
         opacity: 0.6;
      }
   }
   input {
      padding: 0;
      font-size: 22px;
   }
   ${down("md")} {
      max-width: 100%;
      margin-left: 0;
      margin-top: 25px;
   }
`

type TCProps = {
   nextJob: IMyJob | undefined
   technician: IUser
}

const TimerBlock: React.FC<TCProps> = ({ nextJob, technician }) => {
   const [timeClocks, setTimeClocks] = useState<ITimeClock[]>()
   const [timer, setTimer] = useState<any>({
      timerId: null,
      started: false,
      startTime: null,
   })
   const [loading, setLoading] = useState<boolean>(false)
   const [timeValue, setTimeValue] = useState<number>(0)
   const [showModal, setShowModal] = useState<boolean>(false)

   useEffect(() => {
      if (nextJob) {
         Api.timeClocks
            .getAll({ job: nextJob.job.id })
            .then(({ data }) => {
               setTimeClocks(data as ITimeClock[])
            })
            .catch((e) => {
               console.log("error", e)
            })
      }
   }, [nextJob])

   useEffect(() => {
      const newActive = timeClocks?.find((clock) => clock.stop === null)
      if (newActive) {
         setTimer({
            started: true,
            timerId: newActive.id,
            startTime: newActive.start,
         })
      } else {
         setTimer({
            started: false,
            timerId: null,
            startTime: null,
         })
      }
   }, [timeClocks])

   useEffect(() => {
      setLoading(true)
      setTimeout(() => {
         const timeSpent = renderTimeSpent(timeClocks)
         setTimeValue(timeSpent)
         setLoading(false)
      }, 1000)
   }, [timeClocks])

   const renderTimeSpent: any = (clocks: any) => {
      let timeSpent: any = 0
      // const { time_clocks } = nextJob?.job
      if (clocks && clocks.length > 0) {
         //console.log("time", nextJob.job.time_clocks)
         clocks.forEach((item: any) => {
            const end = item.stop ? moment(item.stop) : moment(new Date().toISOString())
            const start = moment(item.start)
            const diff = end.diff(start, "seconds")

            if (diff && diff > 0) {
               timeSpent += diff
            }
         })
      }

      return timeSpent
   }

   const handleStart = async () => {
      try {
         const res = await Api.timeClocks.create({
            job: nextJob?.job?.id,
            user: technician.id,
            start: new Date().toISOString(),
         })
         const data = res.data
         track("Clocked In", {
            workorderId: data.job.work_order,
            vesselId: data.job?.vessel.id,
            technician: data.user.id,
            jobId: data.job.id,
            clockInTimestamp: data.start,
         })
         const timerId = res.data.id

         setTimer({
            timerId,
            started: true,
            startTime: res.data.start,
         })
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const updateTimer = (data: any) => {
      const end = moment(data.stop)
      const start = moment(data.start)
      const diff = end.diff(start, "seconds")
      setTimeValue((prev) => prev + diff)
   }

   const handleStop = async () => {
      try {
         const { data } = await Api.timeClocks.stop(timer.timerId)
         track("Clocked Out", {
            workorderId: data.job.work_order,
            vesselId: data.job?.vessel.id,
            technician: data.user.id,
            jobId: data.job.id,
            clockInTimestamp: data.start,
            timeLoggedHours: moment(data.stop).diff(moment(data.start), "hours"),
         })
         setTimer({
            timerId: null,
            started: false,
            startTime: null,
         })
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   useEffect(() => {
      let timeOut: any
      if (timer.started) {
         timeOut = setTimeout(async () => {
            setTimeValue((prev) => prev + 1)
         }, 1000)
      }
      return () => {
         clearTimeout(timeOut)
      }
   }, [timer.started, timeValue])

   const disableBtnTime = () => {
      let disable = false
      const dateNow = new Date().toISOString()
      if (!nextJob) {
         disable = true
      } else {
         if (nextJob.start < dateNow && nextJob.end < dateNow) {
            disable = true
         }
      }
      return disable
   }

   return (
      <TechnicianItem>
         {loading ? (
            <Spin />
         ) : (
            <Flex justifyContent="flex-end" flexDirection="column" height="100%" alignItems="center">
               <AddTimeManuallyBoard
                  visible={showModal}
                  handleClose={() => setShowModal(false)}
                  technician={technician}
                  estimateId={nextJob?.job?.id}
                  jobs={nextJob?.job?.services!}
                  updateTimer={updateTimer}
               />
               {timer.started && (
                  <Flex flexDirection="column" justifyContent="center" alignItems="center">
                     <Msg fontSize="15px">Clocked in</Msg>
                     <Msg fontSize="15px">{moment(timer.startTime).format("LT")}</Msg>
                  </Flex>
               )}

               <TimeInput
                  step="1"
                  active={timer.started}
                  value={timeValue > 0 ? formatTimeTimeClockSum(timeValue, true) : "--:--:--"}
                  //type="time"
                  bordered={false}
                  name="time"
               />
               <Button
                  type="primary"
                  danger={timer.started}
                  onClick={timer.started ? handleStop : handleStart}
                  style={{ borderRadius: "6px", width: "90%", margin: "15px 0" }}
                  // disabled={disableBtnTime()}
               >
                  Clock {timer.started ? "out" : "in"}
               </Button>
               {!timer.started ? (
                  <Button type="text" onClick={() => setShowModal(true)} disabled={!nextJob || timer.started}>
                     <Msg color="rgba(4, 150, 255, 1)" style={{ textDecoration: "underline" }}>
                        Add time manually
                     </Msg>
                  </Button>
               ) : (
                  <div style={{ height: "32px" }}></div>
               )}
            </Flex>
         )}
      </TechnicianItem>
   )
}

export default TimerBlock
