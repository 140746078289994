import { Spin } from "antd"
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from "react"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { Api } from "../../api/api"
import {
   IErrorStatus,
   ITelgorithmBrand,
   ITelgorithmBrandResponse,
   ITelgorithmBrandStatus,
   ITelgorithmPhone,
} from "../../api/types"
import Settings from "./Settings"
import { TextMessagingAfterVerify } from "./TextMessagingAfterVerify"
import { TextMessagingFinal } from "./TextMessagingFinal"
import { TextMessagingGetStarted } from "./TextMessagingGetStarted"
import { TextMessagingMobile } from "./TextMessagingMobile"
import TextMessagingOnBoarding from "./TextMessagingOnBoarding"
import { TextMessagingVerifyWaiting } from "./TextMessagingVerifyWaiting"
import { track } from "../../utils/analytics"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../config/reducers/user/selectors"
import moment from "moment"

const TextMessagingMainLayout = () => {
   const [status, setStatus] = useState("UNSTARTED")
   const [brand, setBrand] = useState<ITelgorithmBrandResponse | null>(null)
   const [brandStatus, setBrandStatus] = useState<ITelgorithmBrandStatus | IErrorStatus[] | null>(null)
   const [isLoading, setIsLoading] = useState(false)
   const isLg = useBreakpoint(down("lg"))
   const user = useSelector(getCurrentUser)
   const loadData = async () => {
      try {
         setIsLoading(true)
         const { data: _brand } = await Api.telgorithm.getBrand()
         setBrand(_brand)
         const { data: _status } = await Api.telgorithm.getBrandStatus()
         setBrandStatus(_status)
      } catch (e) {
         console.log(e)
      } finally {
         setIsLoading(false)
      }
   }

   const startOnboarding = async () => {
      await track("Text Messaging Setup Started", {
         messagingSetupBy: user?.id,
         companyId: user?.company?.id,
         messagingSetupStartTimestamp: moment().toISOString(),
      })
      setStatus("INPROGRESS")
   }

   const finishOnBoarding = () => {
      // setStatus("VERYFYING")
      loadData()
   }

   const goToPhoneSetup = () => {
      setStatus("SETPHONE")
   }

   const returnToEditing = () => {
      setStatus("EDITING")
   }

   const setPhones = async () => {
      const corporatePhone = brand?.corporate_phone as string
      setStatus("FINISHED")
      loadData()
      track("Text Messaging Setup Completed", {
         messagingSetupBy: user?.id,
         companyId: user?.company?.id,
         textNumber: corporatePhone,
         messagingSetupFinishTimestamp: moment().toISOString(),
      })
   }

   const deleteBrand = async () => {
      await Api.telgorithm.deleteBrand()
      setBrand(null)
      setStatus("UNSTARTED")
   }

   const updateBrand = async (data: any) => {
      const isProd = process.env.REACT_APP_ENV === "prod"
      await Api.telgorithm.updateBrand({ ...data, mock: false })
      loadData()
   }

   const setStep = () => {
      if (!brand) {
         setStatus("UNSTARTED")
         return
      } else if (brand && !brand.identityStatus) {
         setStatus("VERIFYING")
         return
      } else if (brand && brand.identityStatus === "UNVERIFIED") {
         setStatus("UNVERIFIED")
         return
      } else if (brand && brand.identityStatus === "VERIFIED" && !brand.corporate_phone) {
         setStatus("VERIFIED")
         return
      } else {
         setStatus("FINISHED")
      }
   }

   useEffect(() => {
      loadData()
   }, [])

   useEffect(() => {
      setStep()
   }, [brand, brandStatus])

   const renderContent = () => {
      if (isLg) {
         return <TextMessagingMobile />
      }
      if (brandStatus && (brandStatus as IErrorStatus[])?.length) {
         const _status = brandStatus as IErrorStatus[]
         if (_status[0].code === 535) {
            return <TextMessagingVerifyWaiting />
         }
      }
      switch (status) {
         case "UNSTARTED":
            return <TextMessagingGetStarted startOnboarding={startOnboarding} />
         case "INPROGRESS":
            return <TextMessagingOnBoarding finishOnBoarding={finishOnBoarding} />
         case "VERIFYING":
            return <TextMessagingVerifyWaiting />
         case "VERIFIED":
            return <TextMessagingAfterVerify success={true} handleContinue={goToPhoneSetup} />
         case "UNVERIFIED":
            return (
               <TextMessagingAfterVerify
                  brandStatus={brandStatus as ITelgorithmBrandStatus}
                  success={false}
                  handleContinue={returnToEditing}
               />
            )
         case "EDITING":
            return (
               <TextMessagingOnBoarding
                  brand={brand}
                  brandStatus={brandStatus as ITelgorithmBrandStatus}
                  finishOnBoarding={finishOnBoarding}
               />
            )
         case "SETPHONE":
            return <TextMessagingOnBoarding finishOnBoarding={setPhones} isThirdStep />
         case "FINISHED":
            return <TextMessagingFinal brand={brand} deleteBrand={deleteBrand} updateBrand={updateBrand} />
         default:
            return null
      }
   }

   return (
      <>
         <Helmet>
            <title>Text Messaging Settings - DockWorks Pro</title>
         </Helmet>

         <Settings>
            {isLoading ? <Spin spinning={isLoading} style={{ width: "100%", height: "300px" }} /> : renderContent()}
         </Settings>
      </>
   )
}

export default TextMessagingMainLayout
