import { Progress } from "antd"
import React from "react"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { IStats } from "../../../api/types"
import styled from "styled-components";

const StyledProgress = styled(Progress)`
  .ant-progress-circle {
    transform: scaleX(-1);
  }
`;

type TProps = {
   statsData: IStats | null
}
const UpcomingJobs: React.FC<TProps> = ({ statsData }) => {
   const { jobs_rejected, jobs_approved } = statsData || { jobs_rejected: 0, jobs_approved: 0 }
   const total = jobs_approved + jobs_rejected;
   return (
      <Flex justifyContent="space-between" className="little-cart" width="195px" p="10.17px" flexDirection="column">
         <Msg fontWeight={400} fontSize={13} color="#494949">
            Open Estimates
         </Msg>
         <Flex mt="13px" mb="17.76px" justifyContent="center">
            <StyledProgress
               type="circle"
               percent={total ? (jobs_approved / total) * 100 : 0}
               width={110.49}
               trailColor="#FF4E50"
               className="progress"
               format={() => (
                  <Flex flexDirection="column" alignItems="center">
                     <Msg fontWeight={800} fontSize={24} color="#151F2B" mb="5px">
                        {total}
                     </Msg>
                     <Msg fontWeight={400} fontSize={15} color="#151F2B">
                        Estimates
                     </Msg>
                  </Flex>
               )}
            />
         </Flex>
         <Flex width="100%" justifyContent="space-around" alignItems="center">
            <Flex alignItems="center">
               <Msg fontWeight={900} fontSize={"15px"} color="#44CD7F" mr="5px">
                  {jobs_approved}
               </Msg>
               <Msg fontWeight={400} fontSize={"14px"} color="#151F2B">
                  Approved
               </Msg>
            </Flex>
            <Flex alignItems="center">
               <Msg fontWeight={900} fontSize={"15px"} color="#FF4E50" mr="5px">
                  {jobs_rejected}
               </Msg>
               <Msg fontWeight={400} fontSize={"14px"} color="#151F2B">
                  Rejected
               </Msg>
            </Flex>
         </Flex>
      </Flex>
   )
}

export default UpcomingJobs
