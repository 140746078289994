import React, { useCallback, useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"

import { Input, Spin } from "antd"
import Flex from "../../noui/Flex"
import { DownOutlined } from "@ant-design/icons"

export const Root = styled.div`
   position: relative;
   width: 100%;
`
export const baseButtonMixin = css`
   background: none;
   border: none;
   padding: 0;
`
export const ValueWrapper = styled.input`
   width: 100%;
   padding-left: 8px;
   padding-right: 32px;
   height: 32px;
   box-sizing: border-box;
   border-radius: 2px;
   border: 1px solid #d9d9d9;
   line-height: 32px;
`
export const AutoCompleteIcon = styled.span`
   position: absolute;
   top: 0;
   right: 0;
   height: 32px;
   width: 32px;
   transition: all 150ms linear;
   transform: ${(props: any) => (props.isOpen ? "rotate(0.5turn)" : "none")};
   transform-origin: center;
   display: flex;

   svg {
      margin: auto;
   }

   ${ValueWrapper}:focus + & {
      color: ${(props: any) => props.color || "109cf1"};
      fill: ${(props: any) => props.fill || "109cf1"};
   }
`
export const AutoCompleteContainer = styled.div<{withFooter?: boolean, isEmpty?: boolean}>`
   background: #fff;
   padding: ${props => props.withFooter ? "8px 0 0" : "8px 0"};
   min-width: 100%;
   position: absolute;
   top: ${props => props.isEmpty ? "69%" : "100%"};
   left: 0;
   box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
   border-radius: 4px;
   margin: 0;
   box-sizing: border-box;
   overflow-y: auto;
   z-index: 1;
`
const AutoCompleteContent = styled.ul`
   max-height: 190px;
   list-style-type: none;
   padding-left: 0;
   overflow: auto;
   &::-webkit-scrollbar {
      width: 4px;   
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;      
   }
   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;   
   }
   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
`
export const AutoCompleteItem = styled.li`
   padding: 0 24px;
   width: 100%;
   box-sizing: border-box;
   &:hover {
      background-color: #ebf4ff;
   }
`
export const AutoCompleteFooter = styled.div`
   padding: 0 24px;
   width: 100%;
   box-sizing: border-box;
   background-color: #FBFBFF;
`
export const AutoCompleteItemButton = styled.button`
   ${baseButtonMixin} width: 100%;
   line-height: 32px;
   text-align: left;
   &:active {
      outline: none;
      color: #0076f5;
   }
`
export const Input1 = styled(ValueWrapper)`
   transition: border-color 150ms linear;

   &:focus {
      border-color: #109cf1;
      outline: none;

      + ${AutoCompleteIcon} {
         color: ${(props: any) => props.color || "109cf1"};
         fill: ${(props: any) => props.fill || "109cf1"};
      }
   }
`

type TProps = {
   options: any
   handleSelect: (id: any) => void
   fieldName: string
   placeholder: string
   loading?: boolean
   defaultCustomer?: number
   footer?: JSX.Element
}

type IData = {
   [fieldName: string]: string
   id: string
}

export const AutocompleteSelect: React.FC<TProps> = ({ options, handleSelect, fieldName, placeholder, loading, defaultCustomer, footer }) => {
   const ref = useRef<HTMLDivElement>(null)
   const [search, setSearch] = useState({
      text: "",
      suggestions: [],
   })
   const [isComponentVisible, setIsComponentVisible] = useState(false)
   const [errorMessage, setErrorMessage] = useState("")

   const onTextChanged = (e: any) => {
      const value = e.target.value
      let suggestions = options
      if (value.length > 0) {
         suggestions = options
            .sort()
            .filter((v: IData) => v[fieldName].toLocaleLowerCase().includes(value.toLocaleLowerCase()))
      }
      setIsComponentVisible(true)
      setSearch({ suggestions, text: value })
      if (suggestions.length === 0) {
         setErrorMessage("No results")
      } else {
         setErrorMessage("")
      }
   }

   const suggestionSelected = (value: IData) => {
      setIsComponentVisible(false)

      setSearch({
         text: value[fieldName],
         suggestions: options,
      })
      handleSelect(value.id)
   }

   const { suggestions } = search

   useEffect(() => {
      setSearch({
         ...search,
         suggestions: options,
      })
   }, [options])

   useEffect(() => {
      if (options && options.length > 0) {
         const findCustomer = options.find((item: { id: any }) => item.id === defaultCustomer)
         if (findCustomer) {
            setSearch({
               ...search,
               text: findCustomer[fieldName],
            })
         }
      }
   }, [defaultCustomer, options])

   useEffect(() => {
      const checkIfClickedOutside = (e: { target: any }) => {
         // If the menu is open and the clicked target is not within the menu,
         // then close the menu
         if (isComponentVisible && ref.current && !ref.current.contains(e.target)) {
            setIsComponentVisible(false)
         }
      }

      document.addEventListener("mousedown", checkIfClickedOutside)

      return () => {
         // Cleanup the event listener
         document.removeEventListener("mousedown", checkIfClickedOutside)
      }
   }, [isComponentVisible])

   const handleFocusInput = useCallback(() => {
      setIsComponentVisible(prev => !prev)
   }, [setIsComponentVisible])

   return (
      <Root ref={ref}>
         <div>
            <Input
               id="input"
               autoComplete="off"
               value={search.text}
               onChange={onTextChanged}
               type={"text"}
               onFocus={handleFocusInput}
               placeholder={placeholder}
               suffix={<DownOutlined/>}
               // style={inputStyle}
            />
            {errorMessage && <p style={{ color: "#e20b0b", fontSize: "12px", margin: 0 }}>{errorMessage}</p>}
         </div>
         {loading && isComponentVisible ? (
            <AutoCompleteContainer>
               <Flex justifyContent="center">
                  <Spin spinning={loading}></Spin>
               </Flex>
            </AutoCompleteContainer>
         ) : (
            isComponentVisible && (
               <AutoCompleteContainer withFooter={!!footer} isEmpty={!suggestions.length}>
                  <AutoCompleteContent>
                  {suggestions.map((item: IData) => (
                     <AutoCompleteItem key={item.id}>
                        <AutoCompleteItemButton key={item.id} onClick={() => suggestionSelected(item)}>
                           {item[fieldName]}
                        </AutoCompleteItemButton>
                     </AutoCompleteItem>
                  ))}
                  </AutoCompleteContent>
                  {!!footer && (
                     <AutoCompleteFooter>
                        {footer}
                     </AutoCompleteFooter>
                  )}
               </AutoCompleteContainer>
            )
         )}
      </Root>
   )
}
