import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { TCallback } from "../../types"
import { Button, Checkbox, Divider, Skeleton, Switch } from "antd"
import { PALETTE } from "../../constants"
import { ENotificationCategory, INotificationSettings } from "../../api/types"
import { useLoading } from "../../utils/hooks"
import { Api } from "../../api/api"
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import Flex from "../../noui/Flex"
import { Msg } from "../../ui/Text"
import { ArrowBackBlueIcon } from "../../assets/icons"
import NotificationOpen from "../../assets/images/NotificationOpen.svg"
import NotificationClose from "../../assets/images/NotificationClose.svg"

const Wrapper = styled.div`
   width: 650px;
   h4 {
      font-weight: 900;
      font-size: 15px;
      text-transform: uppercase;
      padding: 16px 0 0;
      margin: 0;
   }
   ${down("md")} {
      width: 100%;
      z-index: 1080;
   }
   ${down("xs")} {
      width: 100vw;
   }
`
const Container = styled.div`
   padding: 15px 16px 0 16px;
`
const WrapItems = styled.div`
   overflow-y: auto;
   max-height: 560px;
   &::-webkit-scrollbar {
      width: 8px;
   }

   @media (max-height: 770px) {
      max-height: 360px;
   }
   @media (max-height: 690px) {
      max-height: 320px;
   }
   @media (max-height: 600px) {
      max-height: 220px;
   }
   @media (max-height: 450px) {
      max-height: 150px;
   }

   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }

   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }

   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
`
const BackLink = styled.span`
   cursor: pointer;
   font-size: 14px;
   color: ${PALETTE.PrimaryText};
   font-weight: bold;
   margin-top: 2px;
   display: block;
   svg {
      vertical-align: middle;
      width: 7px;
   }
   &:hover {
      text-decoration: underline;
   }
`
const Footer = styled.div`
   display: flex;
   align-items: center;
   padding-bottom: 16px;
   justify-content: flex-end;
   gap: 8px;
`
const Row = styled.div`
   display: grid;
   grid-template-columns: 1fr repeat(3, 80px);
   gap: 8px;
   padding: 0 16px;
   ${down("xs")} {
      grid-template-columns: 1fr repeat(3, 55px);
   }
`
const Header = styled.div<{ center?: boolean }>`
   display: flex;
   justify-self: ${({ center }) => (center ? "center" : "flex-start")};
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   gap: 8px;
   > h5 {
      margin: 0;
      font-size: 13px;
      font-weight: 700;
   }
   > p {
      color: #828282;
      font-size: 12px;
      padding: 0;
      margin: 0;
   }
   &.disable {
      opacity: 0.5;
      cursor: not-allowed;
      h5 {
         cursor: not-allowed !important;
      }
   }
`

const SmallDivider = styled(Divider)`
   margin: 16px 0;
`

const CenterCol = styled.div`
   justify-self: center;
`

type THeaderCol = {
   title: string
   subtitle?: string
}

enum EColumnTypes {
   SMS = "SMS",
   Email = "Email",
   InApp = "In-App",
}

const columns: THeaderCol[] = [
   { title: "Notification Type", subtitle: "Receive SMS, email and in-app notifications." },
   { title: EColumnTypes.SMS },
   { title: EColumnTypes.Email },
   { title: EColumnTypes.InApp },
]

type TProps = {
   onBack: TCallback
}
export const NotificationPreferences: React.FC<TProps> = ({ onBack }) => {
   const [settings, setSettings] = useState<INotificationSettings[]>([])
   const [saving, onSave] = useLoading()
   const isMd = useBreakpoint(down("md"))
   const [expandedPanels, setExpandedPanels] = useState<string[]>([])

   useEffect(() => {
      onSave(
         Api.notificationSettings.get().then(({ data }) => {
            setSettings(data)
         })
      ).finally()
   }, [onSave])

   const handleChange =
      (name: ENotificationCategory, k: keyof INotificationSettings) =>
      ({ target: { checked } }: CheckboxChangeEvent) => {
         const nSettings = [...settings]
         const idx = nSettings.findIndex((el) => el.category === name)
         if (idx !== -1) {
            nSettings[idx] = { ...nSettings[idx], [k]: checked }
         }
         setSettings(nSettings)
      }

   const handleChangeSwitch = (name: ENotificationCategory, k: keyof INotificationSettings) => (checked: boolean) => {
      const nSettings = [...settings]
      const idx = nSettings.findIndex((el) => el.category === name)
      if (idx !== -1) {
         nSettings[idx] = { ...nSettings[idx], [k]: checked }
      }
      setSettings(nSettings)
   }

   const handleSave = () => {
      const promises = []
      for (let i = 0; i < settings.length; i++) {
         const setting = settings[i]
         promises.push(Api.notificationSettings.update(setting))
      }
      onSave(Promise.all(promises)).finally()
   }

   const handlePanelButtonClick = (category: string) => {
      if (expandedPanels.includes(category)) {
         setExpandedPanels(expandedPanels.filter((p) => p !== category))
         return
      }
      setExpandedPanels([...expandedPanels, category])
   }

   const handleIsAllItemsSelected = (type: string) => {
      switch (type) {
         case EColumnTypes.Email:
            return settings.every((s) => s.email)
         case EColumnTypes.SMS:
            return settings.every((s) => s.sms)
         case EColumnTypes.InApp:
            return settings.every((s) => s.in_app)
      }
   }

   const handleControlGroup = (type: string) => {
      switch (type) {
         case EColumnTypes.Email:
            return setSettings(settings.map((s) => ({ ...s, email: !handleIsAllItemsSelected(EColumnTypes.Email) })))
         case EColumnTypes.SMS:
            return setSettings(settings.map((s) => ({ ...s, sms: !handleIsAllItemsSelected(EColumnTypes.SMS) })))
         case EColumnTypes.InApp:
            return setSettings(settings.map((s) => ({ ...s, in_app: !handleIsAllItemsSelected(EColumnTypes.InApp) })))
      }
   }

   return (
      <Wrapper>
         <Container>
            <h4>Preferences</h4>
            <BackLink onClick={onBack}>
               <ArrowBackBlueIcon /> Back To Notifications
            </BackLink>
            <Msg fontSize="14px" color="#828282">
               Receive SMS, email and in-app notifications.
            </Msg>
         </Container>
         {!isMd && (
            <>
               <SmallDivider />
               <Row>
                  {columns.map((col, idx) =>
                     col?.subtitle ? (
                        <Header key={col.title} center={Boolean(idx)}>
                           <h5>{col.title}</h5>
                           <p>{col.subtitle ?? ""}</p>
                        </Header>
                     ) : (
                        <Header key={col.title} center>
                           <h5 style={{ textAlign: "center", width: "100%" }}>{col.title}</h5>
                           <h5
                              style={{ color: "#109CF1", cursor: "pointer" }}
                              onClick={() => handleControlGroup(col.title)}
                           >
                              {handleIsAllItemsSelected(col.title) ? "Deselect All" : "Select All"}
                           </h5>
                        </Header>
                     )
                  )}
               </Row>
               <SmallDivider />
            </>
         )}
         <Skeleton active={saving} round loading={saving}>
            <WrapItems>
               {settings
                  .filter((c) => c.category !== "SAU")
                  .map((setting) =>
                     isMd ? (
                        <Flex
                           flexDirection="column"
                           width="calc(100% - 24px)"
                           padding="16px 12px"
                           margin="10px 12px"
                           style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)", borderRadius: "4px" }}
                        >
                           <Flex
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                              // marginBottom="10px"
                           >
                              <span>{setting.category_display}</span>
                              <Button
                                 style={{ border: "none" }}
                                 onClick={() => {
                                    handlePanelButtonClick(setting.category)
                                 }}
                              >
                                 <img
                                    src={
                                       expandedPanels.includes(setting.category) ? NotificationClose : NotificationOpen
                                    }
                                 />
                              </Button>
                           </Flex>
                           {expandedPanels.includes(setting.category) && (
                              <Flex flexDirection="row" justifyContent="space-between" marginTop={"15px"}>
                                 <Flex flexDirection="column" alignItems="start">
                                    <span style={{ fontSize: "14px", fontWeight: 700, marginBottom: "7px" }}>
                                       {EColumnTypes.SMS}
                                    </span>
                                    <Switch
                                       onChange={handleChangeSwitch(setting.category, "sms")}
                                       checked={setting.sms}
                                    />
                                 </Flex>
                                 <Flex flexDirection="column" alignItems="start">
                                    <span style={{ fontSize: "14px", fontWeight: 700, marginBottom: "7px" }}>
                                       {EColumnTypes.Email}
                                    </span>
                                    <Switch
                                       onChange={handleChangeSwitch(setting.category, "email")}
                                       checked={setting.email}
                                    />
                                 </Flex>
                                 <Flex flexDirection="column" alignItems="start">
                                    <span style={{ fontSize: "14px", fontWeight: 700, marginBottom: "7px" }}>
                                       {EColumnTypes.InApp}
                                    </span>
                                    <Switch
                                       onChange={handleChangeSwitch(setting.category, "in_app")}
                                       checked={setting.in_app}
                                    />
                                 </Flex>
                              </Flex>
                           )}
                        </Flex>
                     ) : (
                        <React.Fragment key={setting.category}>
                           <Row>
                              <div>{setting.category_display}</div>
                              <CenterCol>
                                 <Checkbox onChange={handleChange(setting.category, "sms")} checked={setting.sms} />
                              </CenterCol>
                              <CenterCol>
                                 <Checkbox onChange={handleChange(setting.category, "email")} checked={setting.email} />
                              </CenterCol>
                              <CenterCol>
                                 <Checkbox
                                    onChange={handleChange(setting.category, "in_app")}
                                    checked={setting.in_app}
                                 />
                              </CenterCol>
                           </Row>
                           <SmallDivider />
                        </React.Fragment>
                     )
                  )}
            </WrapItems>
         </Skeleton>
         <Container>
            <Footer>
               {!isMd && <Button onClick={onBack}>Cancel</Button>}
               <Button type="primary" loading={saving} onClick={handleSave} style={{ width: isMd ? "100%" : "auto" }}>
                  Save
               </Button>
            </Footer>
         </Container>
      </Wrapper>
   )
}
