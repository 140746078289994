import React, { useEffect, useState } from "react"
import moment from "moment"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { Modal, Checkbox, Button, Form } from "antd"
import { Api } from "../../../api/api"
import { ESendType, IJob, ITimeClock, IWorkOrderWithJobs } from "../../../api/types"
import { TArgCallback, TCallback } from "../../../types"
import Photos from "../PrintDocuments/Photos"
import { WarningCircleIcon } from "../../../assets/icons"
import { CloseOutlined } from "@ant-design/icons"
import { ConfigNotifySettings } from "./ConfigNotifySettings"

const StyledModal = styled(Modal)`
   .ant-modal-header {
      display: none;
   }
   .ant-modal-body {
      max-height: 700px;
      overflow: auto;
      border-radius: 4px;
      padding: 0;

      &::-webkit-scrollbar {
         width: 8px;
      }

      &::-webkit-scrollbar-track {
         background: #fbfbff;
         border-radius: 8px;
         border: 1px solid #ededed;
      }

      &::-webkit-scrollbar-thumb {
         background: #0496ff;
         border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
         background: #40a9ff;
      }
   }
`
const Wrapper = styled(Flex)``

const Title = styled(Flex)`
   margin-bottom: 20px;
   h3 {
      font-weight: 900;
      font-size: 15px;
      color: #202020;
      text-transform: uppercase;
   }
`
const Header = styled(Flex)`
   border-bottom: 1px solid #ededed;
   margin-bottom: 15px;
   padding-bottom: 5px;
   margin-top: 15px;
   h3 {
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      color: #202020;
      margin-bottom: 0;
   }
   p {
      font-weight: 400;
      font-size: 13px;
      color: #494949;
      margin-bottom: 0;
   }
`
const Table = styled.div`
   margin: 10px 0;
   box-sizing: border-box;
`
const TableItem = styled.div`
   border-left: 1px solid #ededed;
   border-right: 1px solid #ededed;
   border-bottom: 1px solid #ededed;
`
const Services = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 3fr 5fr 1fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;

      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Materials = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 3fr 5fr 1fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;

      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .description {
      padding: 8px;
      font-size: 12px;
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
`
const Technician = styled.div`
   & .grid {
      display: grid;
      grid-template-columns: 3fr 5fr 1fr;
      grid-gap: 16px;
      min-width: 0;
      font-size: 12px;
      background: #fbfbff;

      > div {
         padding: 8px;
      }
      &.header {
         background: #ededed;
         font-weight: bold;
      }
   }
   & .time {
      font-size: 24px;
      color: #42c77b;
      span {
         font-size: 20px;
      }
   }
`
const Warning = styled.div`
   background: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   padding: 15px;
   margin: 10px 0;
   h3 {
      font-weight: 900;
      font-size: 15px;
      color: #fb4d4f;
      svg {
         vertical-align: middle;
         margin-right: 5px;
      }
   }
   p {
      font-weight: 300;
      font-size: 15px;
      margin-bottom: 0;
   }
`
const ConfigSend = styled.div`
   background: #fbfbff;
   padding: 15px;
   width: 305px;
   h3 {
      font-weight: 900;
      font-size: 15px;
      color: #202020;
      text-transform: uppercase;
   }
   p {
      font-weight: 300;
      font-size: 15px;
      margin-bottom: 0;
   }
`
const JobInfo = styled.div`
   width: 550px;
   position: relative;
`
const JobInfoInner = styled.div`
   padding: 15px;
`
const JobInfoFooter = styled(Flex)`
   position: sticky;
   bottom: 0;
   left: 0;
   width: 100%;
   padding: 15px;
   background: #fff;
   border-top: 1px solid #ededed;
   button {
      margin-left: 10px;
   }
`
const ButtonClose = styled(Button)`
   border: none;
   padding: 0;
   margin: 0;
   height: 14px;
   width: 14px;
   background: none;
   svg {
      color: #c2cfe0;
   }
`

export type TModalProps = {
   visible: boolean
   onClose: TCallback
   job?: IJob
   workOrder?: IWorkOrderWithJobs | null | undefined
   onSubmit: TArgCallback<any>
}

export const CompleteModal: React.FC<TModalProps> = ({ visible, onClose, job, onSubmit, workOrder }) => {
   const [timeClocks, setTimeClocks] = useState<any[]>([])
   const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false)
   const [cardsCheckboxes, setCardsCheckboxes] = useState<any>({
      attach_receipt: false,
      display_activity_feed: true,
      display_messages: true,
   })
   const [form] = Form.useForm<any>()
   const [sendType, setSendType] = useState([ESendType.E])

   const loadCards = async () => {
      const res = await Api.timeClocks.getAll({ job: job?.id })
      const timeClocks = res.data as ITimeClock[]
      setTimeClocks(timeClocks)
   }

   const filterTimeClocks = () => {
      let result: any = {}
      let data: any = {}
      let arr = [...timeClocks]
      const uniqueTechnicians = Array.from(new Set(arr.map((item: any) => item.user.id)))

      uniqueTechnicians.forEach((tech) => {
         result = {
            ...result,
            [tech]: [],
         }
      })

      arr.forEach((item) => {
         uniqueTechnicians.forEach((tech) => {
            if (item.user.id === tech) {
               result = {
                  ...result,
                  [tech]: [...result[tech], item],
               }
            }
         })
      })

      Object.keys(result).forEach((item) => {
         let timeSpent: any = null
         result[item].forEach((timeClock: any) => {
            const start = moment(timeClock.start)
            const end = moment(timeClock.stop)
            const diff = end.diff(start)

            timeSpent += diff
         })

         const hourDiff = Math.trunc(moment.duration(timeSpent).asHours())
         const minutesDiff = moment.duration(timeSpent).minutes()
         const secondsDiff = moment.duration(timeSpent).seconds()

         data = {
            ...data,
            [item]: {
               technician: result[item][0].user.full_name,
               timeSpent: {
                  h: hourDiff,
                  m: minutesDiff,
                  s: secondsDiff,
               },
            },
         }
      })

      return data

      //console.log("data", data)
   }

   useEffect(() => {
      loadCards()
   }, [])

   useEffect(() => {
      form.setFieldsValue(cardsCheckboxes)
   }, [])

   // console.log("pay", job)
   console.log("wo", workOrder)

   const renderServices = () => {
      let services: any[] | undefined = []
      if (workOrder && workOrder.is_recurring) {
         services = workOrder.services
      } else {
         services = job?.services
      }
      return services ? services : []
   }

   const renderMaterials = () => {
      let materials: any[] | undefined = []
      if (workOrder && workOrder.is_recurring) {
         materials = workOrder.materials
      } else {
         materials = job?.materials
      }
      return materials ? materials : []
   }

   const renderTime = (value: any) => {
      const hours = value.h < 10 ? "0" + value.h : value.h
      const minutes = value.m < 10 ? "0" + value.m : value.m
      const seconds = value.s < 10 ? "0" + value.s : value.s
      return `${hours}:${minutes}:${seconds}`
   }

   const technicians = filterTimeClocks()

   const handleCheck = (data: any) => {
      setCardsCheckboxes({
         ...cardsCheckboxes,
         [data.name]: data.value,
      })
   }
   const handleSubmit = async () => {
      const formData = await form.validateFields()
      const content = form.getFieldValue('content')
      const sms_content = form.getFieldValue('sms_content')
      const subject = form.getFieldValue('subject')
      const _data = {
         ...formData,
         content,
         ...(sendType.includes(ESendType.S) && {sms_content}),
         subject,
         send_type: sendType
      }
      if (notifyCustomer) {
         onSubmit(_data)
      } else {
         onSubmit(null)
      }
   }
   return (
      <StyledModal
         visible={visible}
         title="Complete job"
         onCancel={onClose}
         onOk={handleSubmit}
         width={notifyCustomer ? 855 : 550}
         okText="Complete"
         closable={false}
         footer={null}
      >
         <Wrapper justifyContent="space-between" alignItems="normal">
            <JobInfo>
               <JobInfoInner>
                  <Title justifyContent="space-between" alignItems="flex-start">
                     <h3>Complete job</h3>
                     <ButtonClose onClick={onClose}>
                        <CloseOutlined />
                     </ButtonClose>
                  </Title>
                  {workOrder?.is_recurring && (
                     <Checkbox
                        checked={notifyCustomer}
                        onChange={(e) => {
                           setNotifyCustomer(e.target.checked)
                           form.setFieldsValue({ template: null })
                        }}
                     >
                        Notify customer
                     </Checkbox>
                  )}

                  <Header justifyContent="space-between">
                     <h3>{job?.title}</h3>
                     <p>(Job {job?.order_number})</p>
                  </Header>
                  <Table>
                     {renderServices() && renderServices().length > 0 && (
                        <Technician className="noBreak">
                           <div className="grid header">
                              <div className="name">Technician</div>
                              <div className="desc">Clocked Time</div>
                           </div>

                           {Object.keys(technicians).length > 0 &&
                              Object.keys(technicians).map((item: any) => {
                                 return (
                                    <TableItem key={item.id}>
                                       <div className="grid">
                                          <div className="name">{technicians[item].technician}</div>
                                          <div className="time">{renderTime(technicians[item].timeSpent)}</div>
                                       </div>
                                    </TableItem>
                                 )
                              })}
                        </Technician>
                     )}
                  </Table>
                  <Table>
                     {renderServices() && renderServices().length > 0 && (
                        <Services className="noBreak">
                           <div className="grid header">
                              <div className="name">LABOR</div>
                              <div className="desc">Description</div>
                              <div className="qty">Qty</div>
                           </div>

                           {renderServices().map((item: any) => {
                              return (
                                 <TableItem key={item.id}>
                                    <div className="grid">
                                       <div className="name">{item.name}</div>
                                       <div className="desc">{item.description}</div>
                                       <div className="qty">{item.qty.toFixed(2)}</div>
                                    </div>
                                 </TableItem>
                              )
                           })}
                        </Services>
                     )}
                  </Table>
                  <Table>
                     {renderMaterials() && renderMaterials().length > 0 && (
                        <Materials className="noBreak">
                           <div className="grid header">
                              <div className="name">MATERIALS</div>
                              <div className="desc">Description</div>
                              <div className="qty">Qty</div>
                           </div>

                           {renderMaterials().map((item: any) => {
                              return (
                                 <TableItem key={item.id}>
                                    <div className="grid">
                                       <div className="name">{item.name}</div>
                                       <div>{item.description}</div>
                                       <div className="qty">{item.qty}</div>
                                    </div>
                                 </TableItem>
                              )
                           })}
                        </Materials>
                     )}
                  </Table>
                  <Photos job={job} />
                  <Warning>
                     <h3>
                        <WarningCircleIcon /> WARNING
                     </h3>
                     <p>
                        By marking this job complete, technicians will no longer be able to clock in and clock out of
                        the job and you will not be able to add time manually.
                     </p>
                  </Warning>
               </JobInfoInner>
               <JobInfoFooter justifyContent="flex-end">
                  <Button onClick={onClose}>Cancel</Button>
                  <Button onClick={handleSubmit} type="primary">
                     Complete
                  </Button>
               </JobInfoFooter>
            </JobInfo>
            {notifyCustomer && (
               <ConfigSend>
                  <Title justifyContent="space-between" alignItems="flex-start">
                     <h3>Select template</h3>
                     <ButtonClose onClick={() => setNotifyCustomer(false)}>
                        <CloseOutlined />
                     </ButtonClose>
                  </Title>
                  <Form
                     labelCol={{ style: { fontSize: 13, fontWeight: "bold" } }}
                     requiredMark={false}
                     layout="vertical"
                     form={form}
                     //onFinish={sendEstimate}
                  >
                     <ConfigNotifySettings
                        checkboxes={cardsCheckboxes}
                        handleCheck={handleCheck}
                        job={job}
                        workOrder={workOrder}
                        customer={workOrder?.customer}
                        form={form}
                        sendType={sendType}
                        setSendType={setSendType}
                     />
                  </Form>
               </ConfigSend>
            )}
         </Wrapper>
      </StyledModal>
   )
}
