import React from "react";
import styled from "styled-components";
import { IJob } from "../../../api/types";
import Flex from "../../../noui/Flex";

type TMobileDayEventProps = {
  event: IJob;
  title: string;
};

const EventWrapper = styled(Flex)`
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
`;
const EventItem = styled.span``;
const MobileMonthEvent: React.FunctionComponent<TMobileDayEventProps> = ({
  event,
}) => {
  return (
    <EventWrapper>
      <EventItem>
        {event?.vessel_data?.name ||
          `${event?.customer_data?.first_name} ${event?.customer_data?.last_name}` ||
          event?.title}
      </EventItem>
    </EventWrapper>
  );
};

export default MobileMonthEvent;
