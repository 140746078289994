import { Button, Modal, Spin } from "antd";
import moment from "moment";
import { loadPayengine } from "payengine";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PayEnginePK } from "../../api";
import { Api } from "../../api/api";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { setCurrentUser } from "../../config/reducers/user/actions";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import Flex from "../../noui/Flex";
import Hr from "../../ui/Hr";
import { Msg } from "../../ui/Text";
import { capitalize } from "../../utils/utils";
import {
  ErrorText,
  FormWrapper,
  inputStyles,
  TitleText,
  TValidation,
} from "../registration/thirdStep/RegistrationStepThree";
import {
  CurrentSubscriptionBlock,
  CurrentSubscriptionBlockButton,
} from "./styled";
import { ReactComponent as WarningMySubscription } from "../../assets/images/WarningMySubscription.svg";
import StripeSubscriptionsWrapper from "../../layouts/StripeSubscriptions/StripeSubscriptionsWraper";
import {LoadingOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {getPaymentData} from "../../config/reducers/payment/paymentSlice";

const WarningBlock = styled(Flex)`
  background-color: #fb4d4f;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  svg {
    margin: 0 8px 0 12px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 20px;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fb4d4f;
    clear: both;
  }
`;

type TCurrentSubscriptionProps = {
  planName: string;
  nextBillingDate: string;
  last4: string;
  cardBrand: string;
};

const CurrentSubscription: React.FC<TCurrentSubscriptionProps> = ({
  planName,
  nextBillingDate,
  last4,
  cardBrand,
}) => {
  const [deactivationModalVisible, setDeactivationModalVisible] =
    useState(false);
  const [changeModalVisible, setChangeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {error, promoCodeData} = useSelector(getPaymentData)

  const [isChanging, setIsChanging] = useState<boolean>(false)
  const [secureForm, setSecureForm] = useState<any>(null);
  // const [timeValue, setTimeValue] = useState(0);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getCurrentUser);
  const [cardValidation, setCardValidation] = useState<TValidation[]>([
    { errorId: "card_holder", errors: [] },
    { errorId: "card_number", errors: [] },
    { errorId: "card_exp", errors: [] },
    { errorId: "card_cvc", errors: [] },
  ]);

  const handleDeactivate = () => setDeactivationModalVisible(true);
  const handleChange = () => setChangeModalVisible(true);
  const ref = useRef<HTMLFormElement>(null);

  const cardBrandTittle =
    cardBrand.length > 0 ? cardBrand[0].toUpperCase() + cardBrand.slice(1) : "";

  const handleSubmit = () => {
    if (ref.current) {
      ref.current.callOnSubmit();
    }
    setIsLoading(true)

  };

  async function onSubmit() {
    setIsChanging(true)
    setChangeModalVisible(false);
    setIsLoading(false)
    setTimeout(async ()=>{
      const newUser = await (await Api.user.profile()).data;
      await dispatch(setCurrentUser(newUser))
      setIsChanging(false)},4000)

  }

  const clearErrors = () => {
    setCardValidation(cardValidation.map((v) => ({ ...v, errors: [] })));
  };

  const handleCancel = () => {
    clearErrors();
    setChangeModalVisible(false);
  };

  const checkIsInTrial = () => {
    const trialDate = user?.company?.stripe_subscription?.trial_end;
    if (user?.company?.stripe_subscription?.status === "trialing") {
      return moment(trialDate).isAfter(moment());
    }
    return false;
  };

  const getDayDiff = () => {
    const trialDate = user?.company?.stripe_subscription?.trial_end;
    if (trialDate) {
      return moment(trialDate).diff(moment(), "days");
    }
    return 0;
  };

  const getTrialLength = () => {
    const days = user?.company?.plan?.trial_period ?? 0;
    if (days === 1) {
      return "1 day";
    }
    return `${days} days`;
  };

  return (
    <>
      <CurrentSubscriptionBlock>
        <Modal
          visible={deactivationModalVisible}
          title="Subscribtion deactivation"
          okText="Update"
          width={384}
          onCancel={() => setDeactivationModalVisible(false)}
          onOk={() => setDeactivationModalVisible(false)}
          confirmLoading={isLoading}
        >
          <Msg fontSize={18} fontWeight={300} color="#202020">
            Are you sure that you want to deactivate your subscription?
          </Msg>
        </Modal>
        <Modal
          visible={changeModalVisible}
          title="Change payment method"
          okText="Apply"
          width={384}
          onCancel={handleCancel}
          onOk={handleSubmit}
          confirmLoading={isLoading}
        >
          <FormWrapper>
            <StripeSubscriptionsWrapper onSubmit={onSubmit} refSubmit={ref} />
          </FormWrapper>
        </Modal>
        <Flex flexDirection="row" justifyContent="space-between">
          <Msg fontWeight={700} fontSize={13} color="#202020">{`${capitalize(
            planName
          )} ${
            checkIsInTrial() ? `Trial period (${getTrialLength()})` : ""
          }`}</Msg>
          {checkIsInTrial() && (
            <Msg fontWeight={700} fontSize={13} color="#202020">
              {getDayDiff()
                ? `${getDayDiff()} days left`
                : "less than one day left"}
            </Msg>
          )}
        </Flex>
        <Hr margin="10px 0 14px" />
        <Flex justifyContent="space-between" width="100%">
          <Msg fontWeight={400} fontSize={13} color="#202020">
            Next billing date: {moment(nextBillingDate).format("MMM DD, YYYY")}
          </Msg>
          {/* <CurrentSubscriptionBlockButton onClick={handleDeactivate}>
                Deactivate
            </CurrentSubscriptionBlockButton> */}
        </Flex>
        <Flex justifyContent="space-between" width="100%" marginTop="16px">
          <Msg fontWeight={400} fontSize={13} color="#202020">
            {last4
              ? `Billed with ${cardBrandTittle} **** **** **** ${
                  last4
                }`
              : "No active cards!"}
          </Msg>
          {isChanging?<Msg><LoadingOutlined /> Loading</Msg>:<CurrentSubscriptionBlockButton onClick={handleChange}>
            Change
          </CurrentSubscriptionBlockButton>}
        </Flex>
        <Msg fontWeight={400} fontSize={13} color="#202020" marginTop="16px">
          Active discount: {promoCodeData?<Msg color="green" fontSize="13px">{promoCodeData.coupon.name} for {promoCodeData.coupon.duration}</Msg>:" —"}
        </Msg>
      </CurrentSubscriptionBlock>
      {(checkIsInTrial() ||
        user?.company?.stripe_subscription?.status === "past_due") && (
        <WarningBlock>
          <WarningMySubscription />
          <Msg color="#FFFFFF" fontSize={11} fontWeight={400}>
            {checkIsInTrial()
              ? "After the end of the free period, you will be charged."
              : "Current payment method could not be processed, please click “Edit” and change credit card or add new!"}
          </Msg>
        </WarningBlock>
      )}
    </>
  );
};

export default CurrentSubscription;
