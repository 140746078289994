import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Api } from "../../api/api";
import { IExternalEstimate, IExternalInfo } from "../../api/types";
import Flex from "../../noui/Flex";
import { TWithId } from "../../types";

const CompanyLogo = styled.img`
    object-fit: contain;
    max-width: fit-content;
    max-height: 120px;
    margin-bottom: 12px;
`

export const CustomerTermsLayout: React.FC = () => {
    const { id } = useParams<TWithId>()
    const [estimate, setEstimate] = useState<IExternalInfo | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        loadData()
    }, [id])

    const loadData = async () => {
        if (id) {
            try {   
                setIsLoading(true)
                const { data } = await Api.company.getExternalInfo(id)
                setEstimate(data)
            } catch (err) {
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }
    }

    if (!estimate) {
        return null
    }

    return (
        <Spin spinning={isLoading}>
        <Flex width="100%" justifyContent="center">
            <Flex flexDirection="column" maxWidth="700px">
                {estimate.logo && <CompanyLogo src={estimate.logo} />}
                <div dangerouslySetInnerHTML={{__html: estimate.custom_terms_conditions}} />
            </Flex>
        </Flex>
        </Spin>
    )
}