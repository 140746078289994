import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 12px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  min-width: 100%;
  box-sizing: border-box;
  float: left;
  >div {
    margin-top: 12px;
    &:first-child {
      margin-top: 0px;
    }
  }
`;