import styled, { StyledFunction } from "styled-components"
import { NavLink } from "react-router-dom"
import { LeftOutlined } from "@ant-design/icons"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { EmptyButton, PrimaryButton } from "../../../ui/Button"
import { keyframes } from "styled-components"
import PhoneInput from "react-phone-number-input"
import { down } from "styled-breakpoints"
import { Button } from "antd"
import "react-phone-number-input/style.css"

// Common
export const WholeWidth = styled(Flex)`
   width: 100%;
`

export const SaveBtn = styled(PrimaryButton)`
   width: 80px;
   height: 34px;
   border: 0.7px solid #1c99fc;
`

export const ContentStyled = styled(Flex)`
   background-color: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
   border-radius: 4px;
`

type TWidthProp = {
   width: number
}
export const InfoSection = styled(Flex)<TWidthProp>`
   flex-direction: column;
   width: ${(props) => `${props.width}%`};
`

export const InfoItem = styled(ContentStyled)`
   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
   padding: 16px 20px 32px 22px;
   padding-bottom: ${(props) => (props.bottom ? "0px" : "32px")};
   flex-direction: column;
   ${down("md")} {
      padding: 16px 12px 6px 12px;
   }
   .ant-input[disabled] {
      background: none;
      border: none;
      color: #000;
   }
   &::-webkit-scrollbar {
      width: 8px;
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }
   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }
   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
`

export const VesselItem = styled(InfoItem)`
   padding-bottom: 0;
   max-height: 765px;
   overflow-y: auto;
   &::-webkit-scrollbar {
      width: 12px;
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }
   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }
   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
`

export const InfoTitle = styled(Msg)`
   font-size: 15px;
   font-weight: 900;
   text-transform: uppercase;
`

type TTopProp = {
   top?: boolean
}
export const InfoText = styled(Msg)<TTopProp>`
   font-size: 16px;
   margin-top: ${(props) => (props.top ? "4px" : 0)};
   word-break: break-word;
`

export const LeftArrow = styled(LeftOutlined)`
   font-size: 20px;
   margin-right: 6px;
`

export const BackButton = styled(NavLink)`
   color: #0099ff;
   font-size: 18px;
   font-weight: 800;
`

export const StepBackButton = styled(Button)`
   color: #0099ff;
   font-size: 18px;
   font-weight: 800;
   border: none;
   ${down("lg")} {
      font-size: 16px;
   }
   ${down("xs")} {
      font-size: 14px;
   }
`

export const EditButton = styled(EmptyButton)`
   color: #109cf1;
   font-weight: 700;
   width: fit-content;
   &:hover > span {
      text-decoration: underline;
   }
   ${down("xs")} {
      > span {
         text-decoration: underline;
      }
   }
`

// Customer
export const CardItem = styled(InfoItem)`
   padding: 12px 16px 15px;
   margin-bottom: 12px;
   justify-content: space-between;
   flex-direction: row;
   align-items: center;
`

// Vessel
export const VesselInfo = styled(Flex)`
   flex-direction: column;
   margin-top: 16px;
   // margin-right: 60px;
   // ${down("xs")} {
   //   margin-right: 10px;
   // }
`

export const VesselText = styled(Msg)`
   font-size: 13px;
`

export const ServiceItem = styled(CardItem)`
   padding-top: 16px;
   overflow-wrap: break-word;
`

export const CalendarBtn = styled(EmptyButton)`
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 6px;
   margin-left: 8px;
   width: 34px;
`

export const VesselPhoto = styled(Flex)<{ src?: string }>`
   background-image: url(${(props) => props.src});
   width: 180px;
   height: 140px;
   border-radius: 4px;
   position: relative;
   z-index: 1;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   ${down("xs")} {
      width: 100px;
      height: 70px;
   }
`

export const Gallery = styled.li`
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   gap: 16px;
   & > li {
      display: block;
      position: relative;
      height: 68px;
   }
   & > li > div:first-child {
      width: 100%;
      height: 68px;
   }
   & > li img {
      height: 100%;
      width: 100%;
      object-fit: cover;
   }
   ${down("xs")} {
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
   }
`
export const GalleryVessel = styled.ul`
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   gap: 16px;
   margin: 0;
   padding: 0;
   & > li {
      display: block;
      position: relative;
      height: 68px;
   }
   & > li > div:first-child {
      width: 100%;
      height: 68px;
   }
   & > li img {
      height: 100%;
      width: 100%;
      object-fit: cover;
   }
   ${down("xs")} {
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
   }
`

export const GalleryPhoto = styled.img`
   display: block;
   width: 68px;
   height: 68px;
   border-radius: 4px;
   margin-right: 12px;
   :nth-child(4n) {
      margin: 0;
   }
`

export const DateSelector = styled(Flex)<{ focus?: boolean }>`
   border-radius: 4px;
   border: 1px solid rgba(230, 230, 230, 1);
   cursor: pointer;
   padding: 5px 10px;
   border-color: ${(props) => (props.focus ? "#40a9ff" : "rgba(230, 230, 230, 1)")};
   transition: all ease 0.4s;
`

export const MapWrap = styled(Flex)`
   height: 265px;
   width: 100%;
   border-radius: 4px;
   overflow: hidden;
`

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 153, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 153, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 153, 255, 0);
  }
`

export const Marker: any = styled.div`
   width: 13px;
   height: 13px;
   border-radius: 50%;
   border: 1px solid #fff;
   background: #0099ff;
   box-shadow: 0 0 0 0 rgba(0, 153, 255, 1);
   // transform: scale(1);
   // animation: ${pulse} 2s infinite;
`

export const UserAvatar = styled(Flex)<{ src?: string }>`
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background-color: #828282;
   background-image: ${(props) => `url(${props.src})`};
   background-position: center;
   background-size: cover;
`

export const ImageItem = styled.li`
   position: relative;
   .ant-image-mask .ant-image-mask-info {
      display: none !important;
   }
   img {
      border-radius: 4px;
   }
`

export const DeleteImage = styled.div`
   position: absolute;
   right: 0;
   bottom: 0;
   border-bottom-right-radius: 4px;
   border-top-left-radius: 4px;
   background-color: red;
   color: #fff;
   font-size: 13px;
   padding: 3px;
   &:hover {
      cursor: pointer;
   }
`

export const StyledPhoneNumberSmall = styled(PhoneInput)<{ fullWidth?: boolean }>`
   margin-left: ${(props) => (props.fullWidth ? "0" : "10px")};
   .PhoneInputCountry {
      margin-right: 16px;
   }
   .PhoneInputInput {
      width: 150px !important;
      margin-right: 5px;
      border: 1px solid #d9d9d9;
      padding: 4px 8px;
      background-color: white;
      border-radius: 4px;
      outline: none;

      &:focus-visible,
      &:hover,
      &:active {
         border: 1px solid #1c99fc !important;
      }
   }
   .PhoneInputCountryIcon {
      height: 32px;
      width: 35px;
      box-shadow: none !important;
      background: none !important;
      line-height: 32px;
      img {
         border: 1px solid #e6e6e6;
         height: auto;
         display: inline-block;
      }
   }
   ${down("xs")} {
      margin-left: 10px;
      .PhoneInputInput {
         width: 100%;
         margin-right: 5px;
      }
   }
`

export const AssignVesselButton = styled(Button)`
   margin-right: 12px;
   background-color: #109cf1;
   height: 34px;
   ${down("lg")} {
      width: calc(50% - 6px);
   }
`

export const TableWrapper = styled.div`
   .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      padding-bottom: 0px !important;
   }
   .ant-checkbox-checked .ant-checkbox-inner::after {
      display: block;
   }
   .vesselOwner,
   .vesselName {
      min-width: 130px;
   }
`

export const AddAvatar = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 200px;
   width: 100%;
   border: 1px solid #e6e6e6;
   border-radius: 4px;
`

export const AddAvatarText = styled.p`
   color: #828282;
   font-size: 13px;
   font-weight: 400;
   margin: 0;
`

export const VesselAvatar = styled.div`
   // height: 200px;
   width: 100%;
   border-radius: 4px;
   overflow: hidden;
   position: relative;
   > img {
      width: 100%;
      // height: 100%;
   }
`

export const CustomerPageVesselAvatar = styled.div`
   // height: 200px;
   width: 228px;
   min-width: 228px;
   margin: 16px 16px 0 0;
   border-radius: 8px;
   overflow: hidden;
   > img {
      width: 100%;
      // height: 100%;
   }
   ${down("lg")} {
      margin: 0 0 14px;
      // height: 124px;
      width: 140px;
      min-width: 140px;
   }
`
export const CustomerPageTextBlock = styled(Flex)`
   span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }
`
