import { createReducer } from "@reduxjs/toolkit"
import { setMenuItem } from "./actions"
import { Key } from "react"

type TState = {
   showModal: boolean
   menuRoute: string | ""
   actionClick: boolean
   headerHover: boolean
}

const initialState: TState = {
   showModal: false,
   menuRoute: "",
   actionClick: false,
   headerHover: false,
}

export const menuClickReducer = createReducer(initialState, (builder) =>
   builder.addCase(setMenuItem, (state, { payload }) => {
      const nState = { ...state, ...payload }

      return nState
   })
)
