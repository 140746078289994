import React, { useEffect, useMemo, useState } from "react";
import { Form, FormInstance } from "antd";
import { IJob, IUpdateJob, IUser } from "../../../api/types";
import { AddButton, BlockTitle } from "../styled";
import Flex from "../../../noui/Flex";
import { ScheduleRow } from "./ScheduleRow";
import { TCallback } from "../../../types";
import Timeline from "../../dispatch/JobsTimeline/Timeline";
import { Api, TSchedule } from "../../../api/api";
import moment from "moment/moment";
import { paymentSlice } from "../../../config/reducers/payment/paymentSlice";
import { useDispatch } from "react-redux";

type TProps = {
  form: FormInstance<IUpdateJob>;
  job: IJob;
  technicianRole: boolean;
  setIsBooked: any;
  setIsFormFieldChanged?: (value: boolean) => void;
  technicians: string[];
};
export const ScheduleBlock: React.FC<TProps> = ({
  form,
  job,
  technicianRole,
  setIsBooked,
  setIsFormFieldChanged,
  technicians,
}) => {
  const handleAdd = (add: TCallback) => {
    add();
    setIsBooked(job);
    if (setIsFormFieldChanged) {
      setIsFormFieldChanged(true);
    }
  };

  const [techniciansData, setTechniciansData] = useState<string[]>([]);
  useEffect(() => {
    if (form.getFieldsValue().technicians_data) {
      setTechniciansData(
        form
          .getFieldsValue()
          .technicians_data.map((tech: any) => tech?.technician)
      );
    }
  }, [technicians, form.getFieldsValue().technicians_data]);

  return (
    <Flex flexDirection="column" style={{ minHeight: "400px" }}>
      <BlockTitle>Scheduler</BlockTitle>
      <Form
        form={form}
        onFieldsChange={() => {
          if (form.getFieldsValue().technicians_data) {
            setTechniciansData(
              form
                .getFieldsValue()
                .technicians_data.map((tech: any) => tech?.technician)
            );
          }
        }}
      >
        <Form.List name="schedules">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <ScheduleRow
                  field={field}
                  job={job}
                  technicians={techniciansData}
                  remove={remove}
                  key={field.key}
                  technicianRole={technicianRole}
                  form={form}
                  setIsFormFieldChanged={setIsFormFieldChanged}
                />
              ))}

              {!technicianRole && (
                <Form.Item>
                  <AddButton
                    id={`${job.id}_sched_add`}
                    onClick={() => handleAdd(add)}
                  >
                    Schedule Work
                  </AddButton>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </Form>
    </Flex>
  );
};
