import styled from "styled-components"
import Flex from "../../../noui/Flex"
import Box from "../../../noui/Box"
import React, { useState, useMemo, useEffect } from "react"
import { Msg } from "../../../ui/Text"
import { EmptyButton } from "../../../ui/Button"
import moment from "moment"
import { down } from "styled-breakpoints"
import { getSvgByValue } from "../../../assets/images/weatherIcons"
import { Api } from "../../../api/api"
import { IUpcomingJob } from "../../../api/types"
import Humidity from "../../../assets/images/Humidity.svg"
import { getHumidity } from "../../../utils/utils"

const Wrapper = styled.div`
   margin: 10px 20px;
`

const WeekData = styled(Box)`
   border: 1px solid rgba(0, 0, 0, 0.15);
   box-sizing: border-box;
   border-radius: 6px;
   padding: 6px;
   font-weight: 600;
   font-size: 13px;
   color: #000000;
` as typeof Box

const ArrowLeft = styled(EmptyButton)`
   width: 18.53px;
   height: 18.53px;
   border-left: 4px solid #757575;
   border-top: 4px solid #757575;
   box-sizing: border-box;
   transform: rotate(-45deg);
   border-radius: 0;
`

const ArrowRight = styled(EmptyButton)`
   width: 18.53px;
   height: 18.53px;
   border-right: 4px solid #757575;
   border-bottom: 4px solid #757575;
   box-sizing: border-box;
   transform: rotate(-45deg);
   border-radius: 0;
`

const CartWrapper = styled(Flex)`
   border-radius: 4px;
   width: 100%;
   height: 62px;
   min-width: 60px;

   &.lastDayCart {
      background-color: #f0f0f0;
      opacity: 0.5;
      margin-top: 19px;
   }

   &.todayCart {
      background-color: #109cf1;
      height: 78px;
      & span {
         color: #ffffff !important;
      }
   }

   &.nextDayCart {
      background-color: #f0f0f0;
      margin-top: 19px;
      & span {
         color: #484848 !important;
      }
   }
` as typeof Flex

const WeekWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 12px;
   ${down("md")} {
      overflow-x: auto;
   }
`

const DayContainer = styled(Flex)`
   &.today {
      background: #e6faff;
      border-radius: 4px;
      & span {
         color: #109cf1;
      }
      & span:nth-child(3) {
         color: #484848;
      }
   }
   &.notToday {
      border: 0;
   }
   &.lastDay {
      & span.date {
         // color: #f84c4e;
      }
   }
   .icon {
      width: 100%;
      height: 100%;
   }
` as typeof Flex

const WeatherBlock = styled.div`
   height: 150px;
   display: flex;
   flex-direction: column;
   align-items: center;
`

type TDirection = "f" | "b"

type TCalendarViewProps = {
   weather: any[] | undefined
   hourlyWeather: any[] | undefined
}

const CalendarView: React.FC<TCalendarViewProps> = ({ weather, hourlyWeather }) => {
   const [currentDay, setCurrentDay] = useState<moment.Moment>(moment().startOf("day"))
   const [upcomingJobs, setUpcomingJobs] = useState<IUpcomingJob[]>([])
   const [dayDiff, setDayDiff] = useState(0)

   const week = useMemo(() => {
      return moment.weekdays().map((_, idx) => moment(currentDay).add(idx, "days"))
   }, [currentDay])

   useEffect(() => {
      setDayDiff(moment(currentDay).diff(moment(), "days"))
   }, [currentDay])

   useEffect(() => {
      Api.jobs.upcomingJobs({ date: currentDay.toISOString() }).then(({ data }) => {
         setUpcomingJobs(data)
      })
   }, [currentDay])

   const handleChangeWeek = (direction: TDirection) => () => {
      if (direction === "f") {
         setCurrentDay(moment(currentDay).add(1, "week"))
      } else {
         setCurrentDay(moment(currentDay).subtract(1, "week"))
      }
   }

   const actualWeekDay = (idx: number) => (dayDiff === 0 ? idx + dayDiff : idx + dayDiff + 1)

   return (
      <Wrapper className="little-cart">
         <Flex p="8px" justifyContent="space-between" mb="12px">
            <Flex alignItems="center" justifyContent="space-between" width="100%" p="0px 16px">
               <ArrowLeft disabled={moment().isSame(currentDay, "day")} onClick={handleChangeWeek("b")}>
                  {" "}
               </ArrowLeft>
               <WeekData>{`Week ${currentDay.week()}, ${currentDay.format("MMMM, YYYY")}`}</WeekData>
               <ArrowRight onClick={handleChangeWeek("f")}> </ArrowRight>
            </Flex>
         </Flex>
         <WeekWrapper>
            {week.map((el, idx) => (
               <DayContainer
                  key={el.toISOString()}
                  className={
                     moment().format("l") === el.format("l")
                        ? "today"
                        : el.format("ddd") === "Sun"
                        ? "lastDay"
                        : "notToday"
                  }
                  width="66px"
                  p="12px 6px"
                  justifyContent="center"
               >
                  <Flex flexDirection="column" alignItems="center">
                     <Msg className="date" fontSize="15px" fontWeight="700" textAlign="center">
                        {el.format("D")}
                     </Msg>
                     <Msg className="date" fontSize="15px" fontWeight="700" textAlign="center">
                        {el.format("ddd")}
                     </Msg>
                     <WeatherBlock>
                        {actualWeekDay(idx) >= 0 && actualWeekDay(idx) < 10 && weather?.length && (
                           <>
                              <Msg>
                                 {weather[actualWeekDay(idx)]?.air_temp_low !== undefined &&
                                 weather[actualWeekDay(idx)]?.air_temp_high !== undefined
                                    ? Math.round(
                                         (weather[actualWeekDay(idx)].air_temp_high +
                                            weather[actualWeekDay(idx)].air_temp_low) /
                                            2
                                      ) + "°F"
                                    : "-"}
                              </Msg>
                              <Flex width="60px" height="60px" justifyContent="center" alignItems="center">
                                 {getSvgByValue(weather[actualWeekDay(idx)]?.icon)}
                              </Flex>
                              <Msg>
                                 {weather[actualWeekDay(idx)]?.precip_probability !== undefined
                                    ? parseInt(weather[actualWeekDay(idx)]?.precip_probability) + "%"
                                    : "-"}
                              </Msg>{" "}
                              <Flex width="60px" height="60px" justifyContent="center" alignItems="center">
                                 <img src={Humidity} style={{ width: "24px", height: "24px" }} />
                              </Flex>
                              <Msg>{weather !== undefined ? getHumidity(hourlyWeather, idx) + "%" : "-"}</Msg>
                           </>
                        )}
                     </WeatherBlock>
                     <CartWrapper
                        p="0 10px"
                        mt="10px"
                        flexDirection="column"
                        justifyContent="center"
                        className={
                           moment().isSame(el, "date")
                              ? "todayCart"
                              : moment().isBefore(el)
                              ? "lastDayCart"
                              : "nextDayCart"
                        }
                     >
                        <Msg fontSize="18px" fontWeight="700">
                           {upcomingJobs.map((elem) => (moment(elem.date).isSame(el, "date") ? elem.jobs_count : ""))}
                        </Msg>
                        <Msg fontSize="13px" fontWeight="400">
                           {moment(el).isSame(moment(), "date") ? "jobs today" : "jobs"}
                        </Msg>
                     </CartWrapper>
                  </Flex>
               </DayContainer>
            ))}
         </WeekWrapper>
      </Wrapper>
   )
}

export default CalendarView
