import React from "react"
import styled from "styled-components"
import Flex from "../../../noui/Flex"
import { Msg } from "../../../ui/Text"
import { EmptyButton } from "../../../ui/Button"
import CloseImg from "../../../assets/images/progressBar/close.svg"
import { ICard } from "./types"
import { TArgCallback } from "../../../types"
import { ICompletions } from "../../../api/types"
import { parametersData } from "../../../modals/ProgressBar"
import { useHistory } from "react-router-dom"

const CardContainer = styled.div`
   height: 104px;
   padding: 10px 16px;
   flex-grow: 1;
   max-width: 185px;
   min-width: 176px;
   flex-direction: column;
   display: flex;
   margin-left: 16px;
   &:first-child {
      margin-left: 0px;
   }
   @media (max-width: 600px) {
      max-width: 153px;
      min-width: 153px;
      padding: 7px;
   }
`

const CloseImage = styled.img`
   width: 100%;
   height: 100%;
   margin-top: -11.67px;
`

const FunctionalityImage = styled.img`
   width: 20px;
   height: 16px;
`

type TProps = {
   el: [keyof ICompletions, boolean]
   cardData: ICard
   deleteCard: TArgCallback<keyof ICompletions>
}
const SingleCard: React.FC<TProps> = ({ cardData, deleteCard, el }) => {
   const history = useHistory()

   const handleClickCard = () => {
      const route = parametersData[el[0]][1] ?? "/dashboard"
      history.push(route)
   }

   const handleDeleteCard = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      deleteCard(el[0])
   }

   return cardData ? (
      <CardContainer className="little-cart" onClick={handleClickCard}>
         <Flex width="100%" justifyContent="flex-end">
            <EmptyButton width="11.67px" height="11.67px" onClick={handleDeleteCard}>
               <CloseImage src={CloseImg} alt="Close" />
            </EmptyButton>
         </Flex>
         <Flex alignItems="center" flexDirection="column">
            <FunctionalityImage src={cardData.img} />
            <Msg fontWeight={900} fontSize={11} color="#494949" mt={12}>
               {cardData.title}
            </Msg>
            <Msg fontWeight={300} fontSize={11} color="#494949">
               {cardData.description}
            </Msg>
         </Flex>
      </CardContainer>
   ) : null // exclude one
}

export default SingleCard
