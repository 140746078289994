import styled from "styled-components"
import { CompanyInfoContainer, SaveBtn, ContainerTitle, SaveBtnWrapper } from "./styled"
import BoatBtnWhite from "../../assets/images/SailboatWhite.svg"
import ShipBtnBlack from "../../assets/images/BoatBlack.svg"
import KaterBtnBlack from "../../assets/images/KaterBlack.svg"
import BoatBtnBlack from "../../assets/images/SailboatBlack.svg"
import ShipBtnWhite from "../../assets/images/BoatWhite.svg"
import KaterBtnWhite from "../../assets/images/KaterWhite.svg"
import ShipOtherBlack from "../../assets/images/CommercialBlack.svg"
import ShipOtherWhite from "../../assets/images/CommercialWhite.svg"
import { Msg } from "../../ui/Text"
import Flex from "../../noui/Flex"
import { Form, Checkbox } from "antd"
import { down } from "styled-breakpoints"

const StyledCheckboxGroup = styled(Checkbox.Group)`
   display: flex;
   flex-direction: row;
   align-items: center;
   // gap: 16px;
   margin-bottom: 16px;
   flex-wrap: wrap;
   > label {
      width: 107px;
      height: 74px;
      color: #252733;
      border: 1px solid #dadada;
      border-radius: 2px;
      box-sizing: border-box;
      transition: all 0.2s;
      &.ant-checkbox-wrapper-checked {
         background: #109cf1;
         color: #fff;
      }
      &:hover {
         border-color: #109cf1;
      }
      > span:last-child {
         width: 100%;
         height: 100%;
         font-size: 10px;
         font-weight: 700;
         display: flex;
         flex-flow: column nowrap;
         text-align: center;
         justify-content: center;
         align-items: center;
         padding: 0;
      }
      img {
         height: 35px;
         max-width: 47px;
         margin-bottom: 8px;
      }
      margin: 0 8px 8px 0;
   }
   .ant-checkbox {
      display: none;
   }
   ${down("xs")} {
      justify-content: center;
      > label {
         width: 45%;
      }
   }
`

const boats = [
   { value: "SB", label: "Sailboat", image: BoatBtnBlack, checkedImage: BoatBtnWhite },
   { value: "PB", label: "Powerboat", image: ShipBtnBlack, checkedImage: ShipBtnWhite },
   { value: "PW", label: "Personal Watercraft", image: KaterBtnBlack, checkedImage: KaterBtnWhite },
   { value: "OT", label: "Commercial", image: ShipOtherBlack, checkedImage: ShipOtherWhite },
]

export const VesselTypeForm = ({ form, onFinish, saving }) => {
   return (
      <CompanyInfoContainer flexDirection="column" p={16}>
         <Form layout="vertical" onFinish={onFinish} form={form}>
            <ContainerTitle>Vessel Type</ContainerTitle>
            <Form.Item noStyle shouldUpdate>
               {({ getFieldValue }) => (
                  <Form.Item
                     name="vessel_types"
                     rules={[{ required: true, message: "Please select at least one." }]}
                     shouldUpdate
                  >
                     <StyledCheckboxGroup>
                        {boats.map((boat) => (
                           <Checkbox value={boat.value} key={boat.value}>
                              <img
                                 src={
                                    (getFieldValue("vessel_types") || []).includes(boat.value)
                                       ? boat.checkedImage
                                       : boat.image
                                 }
                                 alt={boat.value}
                              />
                              <span>{boat.label}</span>
                           </Checkbox>
                        ))}
                     </StyledCheckboxGroup>
                  </Form.Item>
               )}
            </Form.Item>
            <SaveBtnWrapper justifyContent="flex-end">
               <Form.Item shouldUpdate>
                  {() => (
                     <SaveBtn loading={saving} disabled={!form.isFieldsTouched()} l={16} htmlType="submit">
                        Save
                     </SaveBtn>
                  )}
               </Form.Item>
            </SaveBtnWrapper>
         </Form>
      </CompanyInfoContainer>
   )
}
