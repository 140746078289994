import { Api, TPaymentStripe } from "../../api/api";
import { TCallback } from "../../types";
import {IExternalEstimate, IExternalRecurring, ISetPlan} from "../../api/types";
import React, {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { TCheckoutForm } from "../../modals/Payments/Stripe/CheckoutForm";
import { Form, Input, notification } from "antd";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import { useForm } from "antd/es/form/Form";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { getErrors } from "../../utils/utils";
import { useAppSelector } from "../../config/hooks";
import { getCurrentUser } from "../../config/reducers/user/selectors";
import dispatch from "../dispatch/Dispatch";
import { setCurrentUser } from "../../config/reducers/user/actions";
import {useSelector} from "react-redux";
import {getPaymentData} from "../../config/reducers/payment/paymentSlice";

interface Props {
  onInputFocus?: boolean;
}
export type TStripeFormProps = {
  estimate?: IExternalRecurring;
  activeStatus?: number | null;
  onCancel?: () => void;
  setPaying?: () => void;
  onSubmit: () => void;
};
const PaymentComponent = styled.div<Props>`
  border: 1px solid ${(props) => (props.onInputFocus ? "#57a8e9" : "#d9d9d9")};
  border-radius: 5px;
  padding: 8px;
  outline: 0;
  box-shadow: 0 0 0
    ${(props) => (props.onInputFocus ? "2px rgba(87,168,233, .2)" : "0")};
`;
const SubscriptionsStripeForm = forwardRef<unknown, TStripeFormProps>(
  (props, ref) => {
    const [form] = useForm();
    const elements = useElements();
    const stripe = useStripe();
    const {payment_period} = useSelector(getPaymentData)
    const user = useAppSelector(getCurrentUser);
    const { promoCodeData} = useAppSelector(getPaymentData)
    const [cardId, setCardId] = useState<string>();
    const isRegister = () =>
      user?.company?.plan === null &&
      user?.company?.stripe_subscription === null;
    const { onSubmit, onCancel, estimate, activeStatus, setPaying } = props;
    useImperativeHandle(ref, () => ({
      callOnSubmit() {
        form.submit();
        if (setPaying) {
          setPaying();
        }
      },
    }));

    const handleOnSubmit = async () => {
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements?.getElement(CardNumberElement);
      const cardHolderName = form.getFieldValue("cardHolderName");
      if (cardElement) {
        await stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: cardHolderName,
            },
          })
          .then((result) => {
            if (result.error) {
              notification.error({ message: result.error.message });
            } else {
              setCardId(result.paymentMethod.id);
            }
          });
      }
    };


    const onSubmitStripeForm = async () => {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements?.getElement(CardNumberElement);
      const cardExpiryElement = elements?.getElement(CardExpiryElement);
      const cardCvcElement = elements?.getElement(CardCvcElement)
      const cardHolderName = form.getFieldValue("cardHolderName");
      if (estimate) {
        if (cardId && cardElement) {
          if (!estimate.work_order.subscription) {
            const { data } = await Api.workOrders.approveSubscriptions({
              uuid: estimate.uuid,
              cardId: cardId,
            });
            await stripe.confirmCardPayment(
              data.work_order.subscription.details.latest_invoice.payment_intent
                .client_secret,
              {
                payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: cardHolderName,
                  },
                },
              }
            );
          } else {
            if (estimate?.work_order?.recurring_config?.uuid) {
              const { data } = await Api.workOrders.updateCardForSubscriptions({
                uuid: estimate?.work_order?.uuid,
                cardId: cardId,
              });
              await stripe.confirmCardSetup(data.client_secret, {
                payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: cardHolderName,
                  },
                },
              });
            }
          }
           form.resetFields()
           cardElement.clear()
           cardExpiryElement?.clear()
           cardCvcElement?.clear()
           onSubmit();
        }
      } else {
        if (cardId) {
          setSubscription(cardId);
        }
      }
    };
    const setSubscription = async (cardId: string) => {
      if (isRegister()) {
        try {
          if (activeStatus) {
            const data: ISetPlan = {
              plan: activeStatus,
              cardId: cardId,
              plan_period: payment_period,
            };
            if(promoCodeData){data.promotion_code = promoCodeData.code}
            try {
              const response = await Api.company.setPlan(data);
            } catch (e: any) {
              const error = e.response.data.non_field_error[0].split(":")[1];
              notification.error({ message: error });
            }
          }
        } catch (e: any) {
          console.log(e);
        } finally {
          onSubmit();
        }
      } else {
        if (activeStatus) {
          try {
            await Api.company.updatePlan({
              plan: activeStatus,
              cardId: cardId,
              plan_period: payment_period,
            });
          } catch (e: any) {
            notification.error({ message: "Something went wrong!" });
          } finally {

            onSubmit();
          }
        }
        try {
          if (!stripe || !elements) {
            return;
          }
          const cardElement = elements?.getElement(CardNumberElement);
          const cardHolderName = form.getFieldValue("cardHolderName");
          const { data } = await Api.company.updateCard({ cardId: cardId });
          if (cardElement && data.client_secret) {
            await stripe.confirmCardSetup(data.client_secret, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: cardHolderName,
                },
              },
            });
          }
        } catch (e) {
          console.log(e);
        } finally {
          form.resetFields()
          elements?.getElement(CardNumberElement)?.clear()
          elements?.getElement(CardExpiryElement)?.clear()
          elements?.getElement(CardCvcElement)?.clear()
          onSubmit();
        }
      }
    };

    useEffect(() => {
      onSubmitStripeForm();
    }, [cardId, estimate]);
    return (
      <Form layout="vertical" form={form} onFinish={handleOnSubmit}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Card holder name is required",
            },
          ]}
          label="Card holder name"
          name={"cardHolderName"}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Card number">
          <PaymentComponent>
            <CardNumberElement />
          </PaymentComponent>
        </Form.Item>
        <Form.Item
          label="Expiration"
          style={{ display: "inline-block", width: "calc(50% )" }}
        >
          <PaymentComponent>
            <CardExpiryElement />
          </PaymentComponent>
        </Form.Item>
        <Form.Item
          label="CVC"
          style={{ display: "inline-block", width: "calc(50% )" }}
        >
          <PaymentComponent>
            <CardCvcElement />
          </PaymentComponent>
        </Form.Item>
      </Form>
    );
  }
);

export default SubscriptionsStripeForm;
