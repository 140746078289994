
import React, { useState, useCallback } from 'react';
import Flex from "../../noui/Flex";
import Box from '../../noui/Box';
import styled from "styled-components";
import { THelpCategories } from './types';
import { Msg } from '../../ui/Text';
import { EmptyButton } from '../../ui/Button';
import { useHistory } from "react-router-dom";
import routes from "../../routes/routes";

const SearchContainer = styled(Box)`
    padding: 20px 16px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
`;

const Description = styled(Msg)`
white-space: normal;
text-align: left
// overflow: hidden;
// text-overflow: ellipsis;
`;

type TProps = {
    categories: THelpCategories;
    categoriesImages: any;
    id: number;
}

export const Categorie: React.FC<TProps> = ({ categories, categoriesImages, id }) => {

    const history = useHistory();

    const handleEdit = useCallback((id) => () => {
        history.push(routes.help.Categories.replace(":id", id));
    }, [history]);


    return <EmptyButton width='49%' height='160px' onClick={handleEdit(categories.id)} mb='22px'>
        <Flex p='34px' pt='15px' className='little-cart' height='100%' mb='22px'>
            <Flex>
                <img src={categoriesImages[id].image}></img>
            </Flex>
            <Flex flexDirection='column' height='100%' width='100%' justifyContent='center' alignItems='flex-start' ml='7%'>
                <Msg fontWeight='800' fontSize='15px'>{categories.name}</Msg>
                <Description fontWeight='600' fontSize='13px' width='90%'>{categories.description}</Description>
            </Flex>
        </Flex>
    </EmptyButton>
};