import {
  Modal,
  Form,
  Input,
  Radio,
  Button,
  Spin,
  Divider,
  notification,
  InputNumber,
} from "antd";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { loadPayengine } from "payengine";
import { PayEnginePK } from "../../api";
import styled from "styled-components";
import { Api, TPaymentStripe } from "../../api/api";
import Flex from "../../noui/Flex";
import { Msg } from "../../ui/Text";
import Plaid from "../../assets/images/Plaid.svg";
import { TCallback } from "../../types";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { IExternalEstimate, IExternalRecurring } from "../../api/types";
import moment from "moment";
import { track } from "../../utils/analytics";
import { FormWrapper } from "../../containers/registration/thirdStep/RegistrationStepThree";
import { getErrors } from "../../utils/utils";
import { useForm } from "antd/es/form/Form";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { useAppDispatch } from "../../config/hooks";
import StripeWrapper from "../../modals/Payments/Stripe/StripeWrapper";

export type TPaymentModalProps = {
  visible: boolean;
  estimate: IExternalEstimate | IExternalRecurring;
  onCancel: () => void;
  onSubmit: () => void;
  onPending: (value: boolean) => void;
  amount: number;
  invoiceUID: string;
  invoiceName: string;
  isInvoice: boolean;
  onApprove: TCallback;
  setBlockedBy3DS: (v: boolean) => void;
  totalAmount: number;
};

export enum EPaymentTypes {
  BankAccount = "BA",
  CreditCard = "CC",
}

export const ModalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  .form-field input,
  .form-field iframe {
    height: 40px;
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 4px;
    padding-left: 4px;
    font-size: 15px;
  }
  .form-field iframe input {
    background-color: red;
  }
  .ant-divider {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const HeaderText = styled(Msg)`
  color: #151f2b;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
`;

export const ErrorText = styled(Msg)`
  color: red;
`;

export const TitleText = styled(Msg)`
  color: #494949;
  font-weight: bold;
  font-size: 15px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const inputStyles = {
  fontFamily: "Nunito Sans, sans-serif",
  fontWeight: "400",
  padding: "10px",
  boxSizing: "border-box",
  fontSize: "15px",
};

export type TValidation = {
  errorId: string;
  errors: string[];
};
export type SizeType = Parameters<typeof Form>[0]["size"];

const PaymentModal: React.FC<TPaymentModalProps> = ({
  visible,
  estimate,
  onCancel,
  amount,
  onSubmit,
  invoiceUID,
  isInvoice,
  invoiceName,
  onPending,
  onApprove,
}) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [disabledPay, setDisabledPay] = useState(false);
  const [amountData, setAmountData] = useState<number | SizeType>(amount);
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const intentStripeQuery = {
    amount: amountData,
    ...(isInvoice ? { invoice: invoiceUID } : { estimate: invoiceUID }),
  };
  const onFormLayoutChange = ({ size }: { size: SizeType }): void => {
    setComponentSize(size);
    setAmountData(size);
  };
  const getMaxPrice = () =>
    estimate.work_order.total - estimate.work_order.deposited;
  const tittle = `Payment`;
  return (
    <Modal
      title={tittle}
      visible={visible}
      closable
      centered
      footer={null}
      onCancel={onCancel}
    >
      {amountData && amountData < 0.5 && (
        <Msg color={"#FB4D4F"}>
          You can’t pay because amount is too low, please change it
        </Msg>
      )}

      <Form
        layout="vertical"
        form={form}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
      >
        <Form.Item label={"To"}>
          <Input disabled={true} value={estimate.company.name} />
        </Form.Item>
        <Form.Item label={isInvoice ? "Invoice ID" : "Estimate ID"}>
          <Input disabled={true} value={invoiceName} />
        </Form.Item>
        <Form.Item
          label="Amount"
          initialValue={amount}
          name="size"
          rules={[
            {
              required: true,
              message: "Amount is required",
            },
            () => ({
              validator(_, value) {
                if (value < 0.5) {
                  setDisabledPay(true);
                  return Promise.reject(`The minimum amount is $0.50 US`);
                }
                // if (value < amount) {
                //   setDisabledPay(true);
                //   return Promise.reject(
                //     `Minimum value should be greater than or equal to ${amount}`
                //   );
                // }
                if (value > amount) {
                  setDisabledPay(true);
                  return Promise.reject(
                    `Maximum value should be less than or equal to ${amount.toFixed(
                      2
                    )}`
                  );
                }
                setDisabledPay(false);
                return Promise.resolve();
              },
            }),
          ]}
        >
          <StyledInputNumber step="0.01" />
        </Form.Item>
      </Form>
      <StripeWrapper
        intentStripeQuery={intentStripeQuery}
        disabledPay={disabledPay}
        estimate={estimate}
        onApprove={onApprove}
        onPending={onPending}
        onCancel={onCancel}
        totalAmount={estimate.work_order.total}
        onSubmit={onSubmit}
        isInvoice={isInvoice}
      />
    </Modal>
  );
};

export default PaymentModal;
