import React, {useEffect, useState} from 'react';
import classes from "../styles.module.scss";
import {FormInstance} from "antd";
import {EUserRole, ICreateJob, IUser} from "../../../api/types";
import {TechnicianModal} from "../content/TechnicianModal";
import {Api} from "../../../api/api";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../../config/reducers/user/selectors";

type TProps = {
  form: FormInstance<ICreateJob>;
}
export const TechnicianSelector: React.FC<TProps> = ({form}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [user, setUser] = useState<IUser|null>(null);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    const user: number|null = form.getFieldValue("technician");
    if (user) {
      Api.user.get(user)
        .then(({data}) => setUser(data));
    }
  }, [form]);
  useEffect(() => {
    if (currentUser?.role_id === EUserRole.Technician) {
      setUser(currentUser as IUser);
    }
  }, [currentUser]);

  const handleSelect = (user: IUser) => {
    // form.setFieldsValue({technician: user.id});
    setUser(user);
  }

  const handleOpen = () => {
    if (currentUser?.role_id !== EUserRole.Technician) {
      setVisible(true);
    }
  }

  return <li>
    <span className={classes.title}>Technician</span>
    <span className={classes.link} onClick={handleOpen}>{user ? user.full_name : "Add Technician"}</span>
    <TechnicianModal onSelect={handleSelect} visible={visible} selected={user||undefined} onClose={() => setVisible(false)}/>
  </li>
};