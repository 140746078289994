import { useState, useEffect } from "react"
import _ from "lodash"
import { Switch, Modal } from "antd"
import Flex from "../../noui/Flex"
import { EmptyButton } from "../../ui/Button"
import styled from "styled-components"
import { TArgCallback } from "../../types"
import ModalFormEditAdd from "./ModalFormEditAdd"
import { servicesUnitTypes } from "../../constants"

const Row = styled.div`
   border-bottom: 1px solid #ededed;
   margin-bottom: 20px;
   padding-bottom: 5px;
`
const TdInner = styled.div`
   position: relative;
   p {
      position: absolute;
      top: 100%;
      left: 0;
      margin: 0;
      color: #ff4d4f;
      min-width: 80px;
   }
   a {
      width: 100%;
      text-align: center;
   }
   h3 {
      margin: 0 7px 0 0;
      font-weight: bold;
      font-size: 13px;
   }
`

const OptionButton = styled(EmptyButton)`
   background: #ffffff;
   border: 1px solid #ededed;
   box-sizing: border-box;
   border-radius: 6px;
   padding: 5px 15px;
   height: 34px;
   width: 100%;
   font-weight: 600;
   font-size: 14px;
   &.delete {
      color: #fb4d4f;
   }
`

const StyledModal = styled(Modal)`
   max-width: 350px;
   & .ant-modal-body {
      padding: 16px 80px 16px 16px;
   }

   h3 {
      font-size: 15px;
      font-weight: bold;
   }
`

type TProps = {
   item: any
   handleEditMaterial: TArgCallback<any>
   deleteMaterial: TArgCallback<any>
   duplicateMaterial: TArgCallback<any>
   group: any
   groups: any
   handleCreateGroupe: TArgCallback<any>
   type?: string
}

const TableRowMobile: React.FC<TProps> = ({
   item,
   handleEditMaterial,
   deleteMaterial,
   duplicateMaterial,
   group,
   groups,
   handleCreateGroupe,
   type,
}) => {
   const [showDeleteModal, setShowDeleteModal] = useState(false)
   const [showEditModal, setShowEditModal] = useState(false)

   const handleSwitchChange = (e: any) => {
      handleEditMaterial({
         ...item,
         active: e,
         group,
      })
   }

   const handleDelete = () => {
      setShowDeleteModal(false)
      deleteMaterial(item)
   }

   const clearModal = () => {
      setShowEditModal(false)
   }

   const findUnit = (value: string) => {
      if (value) {
         const item = servicesUnitTypes.find((item) => item.value === value)
         return item?.label
      }
      return null
   }

   return (
      <Row>
         <Flex alignItems="center" marginBottom={"15px"}>
            <TdInner style={{ width: "100%", textDecoration: "underline", fontWeight: "bold" }}>{item.name}</TdInner>
            <Switch checked={item.active} onChange={handleSwitchChange} style={{ marginLeft: "15px" }} />
         </Flex>

         {type === "rateCard" ? (
            <Flex flexDirection="column" marginBottom={"15px"}>
               <TdInner>${item.price.toFixed(2)}</TdInner>
               <TdInner>{findUnit(item.unit)}</TdInner>
               <TdInner>{item.description}</TdInner>
            </Flex>
         ) : (
            <Flex flexDirection="column" marginBottom={"15px"}>
               <TdInner>{item.sku}</TdInner>
               <TdInner>${item.unit_cost.toFixed(2)}</TdInner>
               <TdInner>${item.price.toFixed(2)}</TdInner>
               <TdInner>{item.description}</TdInner>
            </Flex>
         )}

         <Flex
            marginBottom={"15px"}
            alignItems="center"
            justifyContent="space-between"
            style={{ display: "inlineFlex", gap: "15px", width: "100%" }}
         >
            <OptionButton onClick={() => duplicateMaterial(item)}>Duplicate</OptionButton>
            <OptionButton onClick={() => setShowEditModal(true)}>Edit</OptionButton>
            <OptionButton className="delete" onClick={() => setShowDeleteModal(true)}>
               Delete
            </OptionButton>
         </Flex>

         <StyledModal
            destroyOnClose
            centered
            visible={showDeleteModal}
            onOk={handleDelete}
            onCancel={() => setShowDeleteModal(false)}
            okText="Delete"
         >
            <h3>Are you sure want to delete {item.name}?</h3>
         </StyledModal>
         <ModalFormEditAdd
            visible={showEditModal}
            handleSubmit={handleEditMaterial}
            clearModal={clearModal}
            activeGroup={group}
            groups={groups}
            handleCreateGroupe={handleCreateGroupe}
            values={item}
            edit={true}
            type={type}
         />
      </Row>
   )
}
export default TableRowMobile
