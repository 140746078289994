import { Tooltip } from "antd"
import { memo } from "react"
import { ImportNotification } from "../../../assets/icons"
import Flex from "../../../noui/Flex"

type TProps = {
    title: string
}

const ErrorTooltip: React.FC<TProps> = ({title}) => (
    <Flex>
        <Tooltip 
            color="#EDEDED" 
            overlayInnerStyle={{color: '#494949', width: '170px'}} 
            title={title} 
            getPopupContainer={(trigger: any) => trigger.parentElement}
        >
            <Flex>
                <ImportNotification isError/>
            </Flex>
        </Tooltip>
    </Flex>
)

export default memo(ErrorTooltip);