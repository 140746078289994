import React from "react"
import styled from "styled-components"
import Flex from "../noui/Flex"
import ErrorPageBoat from "../assets/images/ErrorPageBoat.svg"
import { Button } from "antd"
import { useHistory } from "react-router-dom"
import routes from "../routes/routes"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { useAppSelector } from "../config/hooks"
import { getCurrentUser } from "../config/reducers/user/selectors"

const ContentStyled = styled(Flex)`
   background-color: #ffffff;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
   border-radius: 4px;
   ${down("xs")} {
      align-items: center;
   }
`

const ImageBlock = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 60%;
   > img {
      width: 100%;
   }
   ${down("xs")} {
      width: 100%;
      top: 32%;
   }
`

const ErrorBlock = styled.div`
   position: absolute;
   bottom: 53%;
   color: #ebf1f7;
   width: 100%;
   text-align: center;
   line-height: 33%;
   font-size: 20vw;
   font-weight: bold;
   ${down("xs")} {
      font-size: 40vw;
      bottom: 71%;
   }
`

const TextBlock = styled.div`
   position: absolute;
   top: 60%;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   ${down("xs")} {
      width: 80%;
      top: 42%;
   }
`

const MainText = styled.span`
   font-size: 24px;
   font-weight: bold;
`

const SubText = styled.span`
   font-size: 16px;
   margin-top: 8px;
`

const StyledButton = styled(Button)`
   margin-top: 24px;
`

const ErrorPage = () => {
   const history = useHistory()
   const user = useAppSelector(getCurrentUser)
   const isXs = useBreakpoint(down("xs"))
   const handleClick = () => {
      if (user?.is_staff && !user.company) {
         history.push(routes.main.SUDashboard)
      } else {
         history.push(routes.main.Dashboard)
      }
   }

   return (
      <Flex
         p={isXs ? "14px" : "34px"}
         pt="15px"
         pb="20px"
         flexDirection="column"
         mb={isXs ? "0px" : "12px"}
         mt={isXs ? "10px" : "76px"}
      >
         <ContentStyled
            flexDirection="column"
            py="20px"
            style={{ height: "calc(100vh - 123px)", position: "relative" }}
         >
            <ErrorBlock>404</ErrorBlock>
            <ImageBlock>
               <img src={ErrorPageBoat} alt={"Error happened"} />
            </ImageBlock>
            <TextBlock>
               <MainText>OOPS, SORRY WE CAN’T FIND THAT PAGE!</MainText>
               <SubText>Either something went wrong or the page doesn’t exist anymore.</SubText>
               <StyledButton onClick={handleClick} type="primary">
                  Back to Home Page
               </StyledButton>
            </TextBlock>
         </ContentStyled>
      </Flex>
   )
}

export default ErrorPage
