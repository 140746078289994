import React, { useState, useEffect, useCallback } from "react"
import { Helmet } from "react-helmet-async"
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom"
import NumberFormat from "react-number-format"
import { Button, notification, Spin } from "antd"
import moment from "moment"
import { Msg } from "../../../ui/Text"
import Flex from "../../../noui/Flex"
import { PrimaryButton, EmptyButton } from "../../../ui/Button"
import { phonesToDisplay, vesselTypes } from "../../../constants"
import { EditCustomer } from "../EditModal"
import { VesselsModal } from "../../vessels/VesselsModal"
import { Content } from "../../../components/Content"
import Notes from "./Notes.js"
import {
   AssignVesselButton,
   ContentStyled,
   BackButton,
   LeftArrow,
   InfoSection,
   InfoItem,
   InfoTitle,
   InfoText,
   EditButton,
   WholeWidth,
   CardItem,
   VesselInfo,
   VesselText,
   VesselItem,
   CustomerPageVesselAvatar,
   CustomerPageTextBlock,
   StepBackButton,
} from "./styled"
import routes from "../../../routes/routes"
import { EJobProgress, ICustomer, IJob, IPaymentRecord, IVessel } from "../../../api/types"
import { Api } from "../../../api/api"
import { getAddress, getFullName, getFullProgress } from "../../../utils/utils"
import { useTechnicianRole } from "../../../utils/hooks"
import { AppointmentModal } from "../../../modals/Appointment"
import { AssignVesselsModal } from "../AssignVesselModal"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { down } from "styled-breakpoints"
import MobileMap from "./MobileMap"
import MobileVessel from "./MobileVessel"
import PaymentMethod from "./PaymentMethod"

type TVesselWithJobs = IVessel & { jobs: IJob[] }

const CustomerPage: React.FC = () => {
   const [editCustomer, setEditCustomer] = useState<boolean>(false)
   const [editVessel, setEditVessel] = useState<boolean>(false)
   const [customer, setCustomer] = useState<ICustomer | null>(null)
   const [vessels, setVessels] = useState<TVesselWithJobs[]>([])
   const [payments, setPayments] = useState<IPaymentRecord[]>([])
   const [selectedVessel, setSelectedVessel] = useState<IVessel | null>(null)
   const [loading, setLoading] = useState<boolean>(false)
   const [vesselLoading, setVesselLoading] = useState<boolean>(false)
   const [paymentsLoading, setPaymentsLoading] = useState<boolean>(false)
   const [showMoreServices, setShowMoreServices] = useState<null | number>(null)
   const { id: customerId } = useParams<{ id?: string }>()
   // const [visible, setVisible] = useState<boolean>(false)
   const [assignVesselModalVisible, setAssignVesselModalVisible] = useState<boolean>(false)
   const technicianRole = useTechnicianRole()
   const history = useHistory()
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))
   const isLg = useBreakpoint(down("lg"))
   const location = useLocation()

   const loadCustomer = async () => {
      setLoading(true)
      try {
         const { data } = await Api.customers.get(Number(customerId))
         setCustomer(data)
      } finally {
         setLoading(false)
      }
   }

   const loadVessels = async (customer: ICustomer) => {
      setVesselLoading(true)
      try {
         const vessels = await Promise.all(
            customer.vessels.map(async (v) => {
               const { data: vesselData } = await Api.vessels.get(v.id)
               const { data: jobs } = await Api.jobs.getAll({ vessel: v.id })
               return {
                  ...vesselData,
                  jobs: jobs as IJob[],
               }
            })
         )
         setVessels(vessels)
      } finally {
         setVesselLoading(false)
      }
   }

   const loadPayments = async () => {
      setPaymentsLoading(true)
      try {
         if (!technicianRole) {
            const { data: payments } = await Api.paymentRecords.getAll({
               customer: Number(customerId),
               is_error: false,
            })
            setPayments(payments as IPaymentRecord[])
         }
      } finally {
         setPaymentsLoading(false)
      }
   }

   const loadCards = useCallback(async () => {
      loadCustomer()
   }, [customerId])

   useEffect(() => {
      loadCards().finally()
   }, [loadCards])

   useEffect(() => {
      if (customer) {
         loadVessels(customer)
         loadPayments()
      }
   }, [customer])

   const renderVesselInfo = (data: IVessel) => ({
      "Vessel Type": vesselTypes.find((v) => v.value === data.vessel_type)?.label || "No data",
      Length: `${data.length} ft`,
      Beam: data.beam ? `${data.beam} ft` : `No data`,
      Draft: data.draft ? `${data.draft} ft` : `No data`,
      Model: data.model,
      "Number of engines": data.number_of_engines,
      "Registration number": data.registration_number,
      "Hailing port": data.hailing_port,
      "Year built": data.year,
      Manufacturer: data.manufacturer,
      "Engine model": data.engines?.length ? data.engines[0].model : "No engines",
      "Hull ID": data.hin,
      "USCG Number": data.uscg_doc,
   })

   const editNotes = async (notes: string) => {
      try {
         await Api.customers.patch({ note: notes, id: Number(customerId) })
         await loadCards()
         notification.success({
            message: "Notes saved successfully",
         })
      } catch (e) {
         notification.error({
            message: "Something went wrong",
         })
      }
   }

   const handleEdit = (v: TVesselWithJobs) => {
      setSelectedVessel(v)
      setEditVessel(true)
   }

   return (
      <>
         <Helmet>
            <title>Customers - DockWorks Pro</title>
         </Helmet>

         <Flex p={isLg ? "14px" : "34px"} pt="15px" flexDirection="column">
            {!isXs && (
               <Msg fontWeight={800} fontSize={24} mb="18px" mt="76px">
                  Customers
               </Msg>
            )}
            <ContentStyled flexDirection="column" py="20px" px={isLg ? "14px" : isXs ? "4px" : "24px"}>
               <Flex
                  flexDirection={isLg ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isLg ? "start" : "center"}
                  mb="16px"
               >
                  {!technicianRole && (
                     <StepBackButton onClick={() => history.go(-1)}>
                        <Flex alignItems="center">
                           <LeftArrow />
                           Back to {location?.state?.fromWO ? "Work Order" : "Customers List"}
                        </Flex>
                     </StepBackButton>
                  )}

                  {!technicianRole && (
                     <Flex flexWrap="wrap" mt={isLg ? 14 : 0}>
                        <AssignVesselButton
                           type="primary"
                           onClick={() => setAssignVesselModalVisible(true)}
                           disabled={loading}
                        >
                           Assign Vessel
                        </AssignVesselButton>
                        <AssignVesselsModal
                           onClose={() => setAssignVesselModalVisible(false)}
                           onSuccess={async (vessel: number) => {
                              await Api.customers.assignVessel({ id: Number(customerId), vessel: vessel })
                              await loadCards()
                           }}
                           visible={assignVesselModalVisible}
                        />
                        <NavLink
                           to={{
                              pathname: routes.workOrder.CreateWorkOrder,
                              state: { customer },
                           }}
                           style={{ width: isLg ? "calc(50% - 6px)" : "initial" }}
                        >
                           <PrimaryButton fontSize={13} height={34} width="100%">
                              New Estimate
                           </PrimaryButton>
                        </NavLink>
                        {/* <PrimaryButton
                        fontSize={13}
                        height={34}
                        ml={isLg ? 0 : 12}
                        mt={isLg ? 12 : 0}
                        onClick={() => setVisible(true)}
                        width={isLg ? "100%" : "initial"}
                     >
                        Schedule Appointment
                     </PrimaryButton>
                     <AppointmentModal visible={visible} onClose={() => setVisible(false)} /> */}
                     </Flex>
                  )}
               </Flex>
               <Flex justifyContent="space-between">
                  <InfoSection width={isLg ? 100 : 35}>
                     <InfoItem mb="16px">
                        <WholeWidth justifyContent="space-between">
                           <InfoTitle>Customer</InfoTitle>
                           {!technicianRole && <EditButton onClick={() => setEditCustomer(true)}>Edit</EditButton>}
                        </WholeWidth>
                        <Content loading={loading} noItems={!Boolean(customer)} noItemsLabel="No customer info">
                           <Flex>
                              {customer ? (
                                 <EditCustomer
                                    customer={customer}
                                    visible={editCustomer}
                                    setVisible={setEditCustomer}
                                    onSuccess={loadCards}
                                 />
                              ) : null}
                              <Flex flexDirection="column">
                                 <InfoText top fontWeight={700}>
                                    {getFullName(customer)}
                                 </InfoText>
                                 <InfoText top={Boolean(getAddress(customer?.address))}>
                                    {getAddress(customer?.address)}
                                 </InfoText>
                                 <InfoText top={Boolean(customer?.phones && customer.phones.length)}>
                                    {customer?.phones &&
                                       customer.phones.map((p, i, a) => (
                                          <Msg fontSize={16} key={p.id}>
                                             <span style={{ color: "#0496FF", fontWeight: 700 }}>
                                                {p.phone}
                                             </span>{" "}
                                             ({phonesToDisplay[p.phone_type]}){i < a.length - 1 ? "," : ""}
                                             <br />
                                          </Msg>
                                       ))}
                                 </InfoText>
                                 <InfoText top={Boolean(customer?.email)}>{customer?.email}</InfoText>
                              </Flex>
                              <Flex />
                           </Flex>
                        </Content>
                     </InfoItem>
                     <MobileMap vessels={vessels} />
                     <Notes
                        notes={customer?.note}
                        loading={loading}
                        noItems={!Boolean(customer)}
                        onSuccess={editNotes}
                     />
                     <PaymentMethod customer={customer} loading={loading} />
                     {!!vessels.length && isLg && (
                        <Flex flexDirection="row" flexWrap="wrap" width="100%" style={{ gap: "20px" }}>
                           {selectedVessel && (
                              <VesselsModal
                                 visible={editVessel}
                                 onClose={() => {
                                    setEditVessel(false)
                                 }}
                                 payload={selectedVessel}
                                 onSuccess={loadCards}
                              />
                           )}
                           {vessels.map((v) => (
                              <MobileVessel vessel={v} handleEdit={() => handleEdit(v)} />
                           ))}
                        </Flex>
                     )}
                     {!technicianRole && (
                        <InfoItem mt="16px" style={{ maxHeight: "300px", overflow: "auto" }}>
                           <InfoTitle>Payment history</InfoTitle>
                           <Flex flexDirection="column" mt="16px">
                              <Content
                                 loading={loading || paymentsLoading}
                                 noItems={!payments.length}
                                 noItemsLabel="No payments"
                              >
                                 {payments.map((p) => (
                                    <CardItem key={p.id} style={{ alignItems: "start" }}>
                                       <Msg fontSize={13} fontWeight={700}>{`#${p.id}`}</Msg>
                                       <Msg fontSize={13}>{p.note || "No note"}</Msg>
                                       <Flex flexDirection="column" alignItems="flex-end">
                                          <Msg fontSize={13}>{`Due ${moment(p.date).format("M/D/YYYY")}`}</Msg>
                                          <Msg fontSize={15} color="#109CF1" fontWeight={700}>{`$${p.amount}`}</Msg>
                                       </Flex>
                                    </CardItem>
                                 ))}
                              </Content>
                           </Flex>
                        </InfoItem>
                     )}
                  </InfoSection>
                  {!isLg && (
                     <InfoSection width={60}>
                        {selectedVessel && (
                           <VesselsModal
                              visible={editVessel}
                              onClose={() => {
                                 setEditVessel(false)
                              }}
                              payload={selectedVessel}
                              onSuccess={loadCards}
                           />
                        )}
                        <Content
                           loading={loading || vesselLoading}
                           noItems={!vessels.length}
                           noItemsLabel="This customer has no vessels"
                        >
                           {vessels.map((v, i, a) => (
                              <VesselItem key={v.id} mb={i < a.length ? "23px" : 0}>
                                 <WholeWidth justifyContent="space-between">
                                    <NavLink
                                       to={{
                                          pathname: routes.vessels.Page.replace(":id", String(v.id)),
                                       }}
                                    >
                                       <InfoTitle style={{ color: "#109CF1" }}>{v.name}</InfoTitle>
                                    </NavLink>
                                    {!technicianRole && <EditButton onClick={() => handleEdit(v)}>Edit</EditButton>}
                                 </WholeWidth>
                                 <Flex height="300px" width="100%">
                                    {v.image && (
                                       <CustomerPageVesselAvatar>
                                          <img src={v.image} alt="Vessel avatar" />
                                       </CustomerPageVesselAvatar>
                                    )}
                                    <Flex flexWrap="wrap" flexDirection="column" width="100%">
                                       {Object.entries(renderVesselInfo(v)).map((e) => (
                                          <VesselInfo key={e[0]}>
                                             <VesselText fontWeight={700}>{e[0]}</VesselText>
                                             <VesselText>{e[1] || "No data"}</VesselText>
                                          </VesselInfo>
                                       ))}
                                    </Flex>
                                 </Flex>
                                 <Flex flexDirection="column" mt="22px">
                                    <Msg fontSize={15} fontWeight={600}>
                                       Service history
                                    </Msg>
                                    {v.jobs.length ? (
                                       (showMoreServices === v.id ? v.jobs : v.jobs.slice(0, 5)).map((s, i) => (
                                          <CardItem key={s.id} mt={i === 0 ? 12 : undefined}>
                                             <CustomerPageTextBlock width="35%">
                                                <NavLink
                                                   to={{
                                                      pathname: routes.workOrder.UpdateWorkOrder.replace(
                                                         ":id",
                                                         String(s.work_order)
                                                      ),
                                                   }}
                                                >
                                                   <Msg fontSize={13} color="#109CF1">
                                                      {s.title}
                                                   </Msg>
                                                </NavLink>
                                             </CustomerPageTextBlock>
                                             <Flex justifyContent="space-between" width="60%">
                                                <CustomerPageTextBlock width="32%">
                                                   <Msg fontSize={13} color="#828282">{`Service: ${s.title}`}</Msg>
                                                </CustomerPageTextBlock>
                                                <CustomerPageTextBlock width="32%">
                                                   <Msg fontSize={13} color="#828282">{`Status: ${getFullProgress(
                                                      s.progress
                                                   )}`}</Msg>
                                                </CustomerPageTextBlock>
                                                <CustomerPageTextBlock width="32%">
                                                   <Msg fontSize={13} color="#828282">
                                                      {moment(s.created).format("M/D/YYYY")}
                                                   </Msg>
                                                </CustomerPageTextBlock>
                                             </Flex>
                                          </CardItem>
                                       ))
                                    ) : (
                                       <Msg fontSize={14} fontWeight={400} pb="10px">
                                          No data
                                       </Msg>
                                    )}
                                    <EmptyButton
                                       width="100%"
                                       height="45px"
                                       style={{ display: v.jobs.length < 5 ? "none" : "block" }}
                                       onClick={() => setShowMoreServices(showMoreServices ? null : v.id)}
                                    >
                                       <Msg fontWeight={600} fontSize={14} color="#109CF1">
                                          {showMoreServices === v.id ? "View less" : "View all history"}
                                       </Msg>
                                    </EmptyButton>
                                 </Flex>
                              </VesselItem>
                           ))}
                        </Content>
                     </InfoSection>
                  )}
               </Flex>
            </ContentStyled>
         </Flex>
      </>
   )
}

export default CustomerPage
