import moment from "moment"
import React, { useState } from "react"
import { NavLink, useHistory } from "react-router-dom"
import { down } from "styled-breakpoints"
import { useBreakpoint } from "styled-breakpoints/react-styled"
import { IJob, IVessel } from "../../../api/types"
import Flex from "../../../noui/Flex"
import routes from "../../../routes/routes"
import { Msg } from "../../../ui/Text"
import { useTechnicianRole } from "../../../utils/hooks"
import { getFullProgress } from "../../../utils/utils"
import { CardItem, CustomerPageVesselAvatar, InfoItem, InfoTitle } from "./styled"

type TVessel = IVessel & { jobs: IJob[] }

type TMobileVesselProps = {
   vessel: TVessel
   handleEdit: () => void
}

const MobileVessel: React.FC<TMobileVesselProps> = ({ vessel, handleEdit }) => {
   const [showHistory, setShowHistory] = useState(false)
   const history = useHistory()
   const isXs = useBreakpoint(down("xs"))
   const isMd = useBreakpoint(down("md"))
   const vesselInfo = {
      Manufacturer: vessel?.manufacturer,
      Model: vessel?.model,
      Length: `${vessel?.length} ft`,
      "Engine model": vessel?.engines?.length
         ? vessel?.engines[0].model
            ? vessel?.engines[0].model
            : "No data"
         : "No engines",
      "Number of engines": vessel?.number_of_engines,
      "USCG Number": vessel?.uscg_doc || "No data",
      "Year built": vessel?.year,
      "Port of call": vessel?.hailing_port || "No data",
      "Hull ID": vessel?.hin,
   }
   const technicianRole = useTechnicianRole()

   const handleToggleHistory = () => setShowHistory((prev) => !prev)

   return (
      <InfoItem style={{ width: !isMd ? "calc(50% - 10px)" : "100%" }}>
         <NavLink style={{ fontWeight: "bold" }} to={routes.vessels.Page.replace(":id", String(vessel?.id))}>
            {vessel?.name}
         </NavLink>
         <Flex flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column">
               {Object.entries(vesselInfo)
                  .filter((v, i, a) => i >= 0 && i <= a.length / 2 + 1)
                  .map((v) => (
                     <Flex flexDirection="column">
                        <Msg fontSize="14px" fontWeight="700">
                           {v[0]}
                        </Msg>
                        <Msg fontSize="14px" color="#828282">
                           {v[1]}
                        </Msg>
                     </Flex>
                  ))}
            </Flex>
            <Flex flexDirection="column">
               {vessel?.image && (
                  <CustomerPageVesselAvatar>
                     <img src={vessel.image} alt="Vessel avatar" />
                  </CustomerPageVesselAvatar>
               )}
               {Object.entries(vesselInfo)
                  .filter((v, i, a) => i > a.length / 2 + 1)
                  .map((v) => (
                     <Flex flexDirection="column">
                        <Msg fontSize="14px" fontWeight="700">
                           {v[0]}
                        </Msg>
                        <Msg fontSize="14px" color="#828282">
                           {v[1]}
                        </Msg>
                     </Flex>
                  ))}
            </Flex>
         </Flex>
         <Flex flexDirection="row" justifyContent="space-between" width="100%">
            <Msg onClick={handleToggleHistory} color="#0496FF">
               {showHistory ? "Hide history" : "View history"}
            </Msg>
            {!technicianRole && (
               <Msg onClick={handleEdit} color="#0496FF">
                  Edit
               </Msg>
            )}
         </Flex>
         {showHistory && (
            <>
               <Msg fontSize={15} fontWeight={600}>
                  Service history
               </Msg>
               <Flex flexDirection="column" style={{ overflowY: "scroll", height: "250px" }}>
                  {vessel?.jobs.length ? (
                     vessel?.jobs.map((s, i) => (
                        <CardItem key={s.id} mt={i === 0 ? 12 : undefined}>
                           <Msg
                              fontSize={13}
                              color="#109CF1"
                              style={{ width: "45%", wordBreak: "break-word" }}
                              onClick={() => {
                                 history.push(
                                    routes.workOrder.UpdateWorkOrder.replace(":id", String(s.work_order)) +
                                       `?job=${s.id}`
                                 )
                              }}
                           >
                              {s.title}
                           </Msg>
                           <Flex justifyContent="space-between" style={{ flexGrow: 1 }}>
                              {!isXs && <Msg fontSize={13} color="#828282">{`Service: ${s.title}`}</Msg>}
                              <Msg fontSize={13} color="#828282">{`Status: ${getFullProgress(s.progress)}`}</Msg>
                              <Msg fontSize={13} color="#828282">
                                 {moment(s.created).format("M/D/YYYY")}
                              </Msg>
                           </Flex>
                        </CardItem>
                     ))
                  ) : (
                     <Msg fontSize={14} fontWeight={400} pb="10px">
                        No data
                     </Msg>
                  )}
               </Flex>
            </>
         )}
      </InfoItem>
   )
}

export default MobileVessel
